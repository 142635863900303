import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IVideo } from '@medlogic/shared/shared-interfaces';
import { ITutorialState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import * as TutorialActions from './tutorial.actions';

export const tutorialFeatureKey = 'tutorial';

export const adapterTutorial: EntityAdapter<IVideo> = createEntityAdapter<IVideo>({
  selectId: instance => instance.id,
});

export const initialStateTutorial: ITutorialState = adapterTutorial.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
  changeItem: null
});


export const reducer = createReducer(
  initialStateTutorial,

  on(TutorialActions.loadTutorials, state => state),
  on(TutorialActions.loadTutorialsSuccess, (state, action) => adapterTutorial.setAll(action.videos, { ...state })),
  on(TutorialActions.loadTutorialsFailure, (state, action) => state),
  on(TutorialActions.clearTutorials, () => ({ ...initialStateTutorial }))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterTutorial.getSelectors();