export enum EnBubbleEvent {
  listEdit,
  listDelete,
  listNew,
  listSaveAndNew,
  listDuplicate,
  activitySave,
  activityComplete,
  activityDelete,
  isLoading,
  historyBack,
  historyRemoveLast,
  alertDialog,
  windowDialog,
  tabChange,
  gotoErpGrip,
  ErpRefresh,
  navigateNextBack,
  debug,
  validateTabComponents,
  openAttachments, // Valida os componentes da Aba.
  afterSavedMessage,
  componentesChanged,
  afterPrint
}
