<div class="container">
  <!-- [class.grid-2]="showList()" -->
  <div class="top-bar">
    <button mat-button type="button" class="btn btn-back" (click)="onCloseClick($event)">
      <mat-icon class="icn-back">arrow_back_ios</mat-icon>VOLTAR
    </button>
  </div>

  <ng-container [ngSwitch]="true">

    <!-- IMAGE PREVIEW -->
    <div class="preview" *ngSwitchCase="[ENFILEUPLOADMODE.preview].includes(fileUploadMode)">

      <lib-cmp-img-preview (download)="onDownload($event)" (back)="onPreviewBack($event)" [doc]="selectedDocument">
      </lib-cmp-img-preview>

    </div>

    <div class="single" *ngSwitchCase="[ENFILEUPLOADMODE.single].includes(fileUploadMode)">

      <lib-cmp-file-upload-selector (afterFileUpload)="afterFileUpload($event)"
        (afterOpenWebcam)="afterOpenWebcam($event)" [data]="data" [enFileUploadMode]="fileUploadMode">
      </lib-cmp-file-upload-selector>

      <lib-cmp-img-preview *ngIf="!isEmpty" [showBack]="false" (download)="onDownload($event)" (back)="onPreviewBack($event)"
        [doc]="selectedDocument">
      </lib-cmp-img-preview>

    </div>

    <!-- FILE LIST AND UPLOAD OPTIONS -->
    <div class="list" *ngSwitchDefault>

      <lib-cmp-file-upload-selector (afterFileUpload)="afterFileUpload($event)"
        (afterOpenWebcam)="afterOpenWebcam($event)" [data]="data" [enFileUploadMode]="fileUploadMode">
      </lib-cmp-file-upload-selector>

      <div class="table">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element">
              {{ element.DocumentoNo }} </td>
          </ng-container>

          <ng-container matColumnDef="documento">
            <th mat-header-cell *matHeaderCellDef> Documento </th>
            <td mat-cell *matCellDef="let element">
              {{ element.DocumentoNomeExibicao }} </td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon>{{ extractIconName(element) }} </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="tamanho">
            <th mat-header-cell *matHeaderCellDef> Tamanho </th>
            <td mat-cell *matCellDef="let element">
              {{ element.Tamanho }} </td>
          </ng-container>

          <ng-container matColumnDef="versao">
            <th mat-header-cell *matHeaderCellDef> Versão </th>
            <td mat-cell *matCellDef="let element">
              {{ element.Versao }} </td>
          </ng-container>

          <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef> Ações </th>
            <td mat-cell *matCellDef="let element">

              <button style="margin-right: 8px;" class="btn btn-primary" type="button" *ngIf="canPreview(element)"
                (click)="onPreview(element)">
                Visualizar
              </button>

              <button style="margin-right: 8px;" class="btn btn-primary" type="button" (click)="onDownload(element)">
                Baixar
              </button>

              <button class="btn btn-danger" type="button" (click)="onDelete(element)">
                Excluir
              </button>

            </td>
          </ng-container>

          <ng-container matColumnDef="noRecords">
            <td mat-footer-cell *matFooterCellDef>
              Nenhum documento encontrado.
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="(dataSource && dataSource.data.length) ? displayedColumns : []"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="(dataSource && dataSource.data.length) ? [] : ['noRecords']" colspan="6">
          </tr>
        </table>
      </div>

    </div>

  </ng-container>

</div>