import { toArray, map, publishReplay, refCount, groupBy, mergeMap, catchError } from 'rxjs/operators';
import { IHistoricoCompraEstoque } from '@medlogic/shared/shared-interfaces';
import { IEstoqueMateriais } from '@medlogic/shared/shared-interfaces';
import { observable, Observable } from 'rxjs';
import { of } from 'rxjs';
import { EnStockStatus, LocalLibService } from '@medlogic/shared/shared-interfaces';
import {
  UnsubscribeOnDestroyAdapter, IServiceProvider,
  GlobalService, LogService, IForm
} from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

/** Transformada em classe abstrata, não injetável, para forçar o uso da EstoqueMateriaisCustomizedServices. */
export abstract class EstoqueMateriaisService extends UnsubscribeOnDestroyAdapter implements IServiceProvider {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_832,V_27987,V_27988,V_27989,V_27993,V_27994,V_27995,V_27997,V_27998,V_28018,V_28019,V_28020,V_28021,V_28022,V_28023,V_29828,V_30267,V_30268,V_30269,V_30296,V_30313,V_30321,V_30339,V_30350,V_32855,V_34382,V_34383,V_34718,V_30412,V_100306,V_100323,V_100851,V_100852,V_100853,V_100854,V_101098,V_101099,V_101213,V_28014,V_28012,V_28452';
  private variavelGrid = 'V_28008';
  // tslint:disable-next-line: max-line-length
  private lstVariaveisGrid = 'V_832,V_27987,V_27993,V_27997,V_27999,V_28000,V_28001,V_28004,V_28012,V_28014,V_28018,V_28023,V_30269,V_30350,V_34687,V_34705,V_34718,V_100306,V_100321,V_100322,V_100325,V_100326,V_100406,V_101702,V_28452';

  recurrences: IEstoqueMateriais[] = new Array<IEstoqueMateriais>();

  codigoVariavelNo = 27987; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 2295;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected lib: LocalLibService,
    protected log: LogService) {
    super();
  }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IEstoqueMateriais> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IEstoqueMateriais> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IEstoqueMateriais> {
    try {
      if (
        (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
        || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
        || (!this.cadastrosCache)
        // (startDate.getTime() !== this.currentDtInicial.getTime())
        // || (endDate.getTime() !== this.currentDtFinal.getTime())
        // || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastroGrid(cadastroNo, startDate, endDate, lstVariables);
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount(),
          catchError((err, obs) => {
          console.log(err);
          return obs;
            })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected mapTo = (lsthistoricoCompraEstoque: IHistoricoCompraEstoque[] = null) => map((c: any) => {
    return this.toAttribute(c, lsthistoricoCompraEstoque);
  })

  /* Realiza o mapeamento das variáveis. */
  toAttribute(c: any, lsthistoricoCompraEstoque: IHistoricoCompraEstoque[] = null): IEstoqueMateriais {
    try {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
        idPaciente: c.V_29828,
        uniNegocio: c.V_30313,
        cascataTipoMAterialCodHosp: c.V_32855,
        // tempoUso 830
        dosagem: c.V_832, // || c.V_100853
        // orientacoes 2952
        // posologia 3530
        codigo: c.V_27987,
        titulo: c.V_27988,
        habilitado: this.lib.getBoolean(c.V_27989),
        centroCusto: c.V_27993,
        estoque: c.V_27994,
        estoqueMinimo: this.glb.getTypedValue(c.V_27995).value,
        unidademedida: c.V_27997,
        dataUltimaCompra: this.glb.getTypedValue(c.V_27998).value,
        tipoMaterial: c.V_28018,
        // duracaoUnitariaDias 101098
        consumoDiario: c.V_28019,
        dataProximaCompra: this.glb.getTypedValue(c.V_28020).value,
        hoje: this.glb.getTypedValue(c.V_28021).value,
        duracaoTotal: c.V_28022,
        unidadeNegocio: c.V_28023,
        // codHospede 30027
        medicamento: c.V_30267,
        medicamentoControlado: c.V_30268,
        medicamento2: c.V_30269,
        codigoPacienteNomePaciente: c.V_30296,
        codPacienteNomedoPacienteCodMedicamento: c.V_30321,
        // quantUtilizada 30333
        tipoMedicamentosCodPaciente: c.V_30339,
        itens: c.V_30350,
        // material 30412
        quantidadeEmbalagem: c.V_34718,
        lsthistoricoCompraEstoque,
        status: EnStockStatus.None,
        isSelected: false,
        isHistoryVisible: false,
        idMedicamento: c.V_100306,
        ultimoLote: c.V_100851,
        dataUltimaValidade: this.glb.getTypedValue(c.V_100852).value,
        concatUniNegocioSIM: c.V_34382,
        concatUniNegocioNAO: c.V_34383,
        materialID: c.V_30412,
        codCenCusto: c.V_100323,
        consumoDiario2: c.V_101098,
        gOTASPMl: c.V_101099,
        dataAtual: this.glb.getTypedValue(c.V_101213).value,

        dose: c.V_100853,
        dosageValue: c.V_100853,

        dosageUnity: c.V_100854,
        unidadeDose: c.V_100854,

        apresentacao: c.V_27997,
        fornecedorNome: c.V_28014,

        lastPrice: c.V_28012,
        lastInvoiceNumber: c.V_28452
      } as IEstoqueMateriais;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
   * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
   */
  protected getFiltered(
    cadastroNo: number,
    strFilter: string,
    startDate: Date = null,
    endDate: Date = null,
    isFilterAnd: boolean = true,
    lstVariables: string = null): Observable<IEstoqueMateriais> {
    try {
      this.cadastroSrv.dtInicial = startDate ?
        this.glb.dateToYYYYMMddThhmmss(startDate) :
        this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      lstVariables = lstVariables || this.lstVariaveis;
      const obs$ = this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
        // obs$.subscribe(s => console.log('getFiltered', s));
        return obs$;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  // save(ano: number, estoqueMateriais: IEstoqueMateriais, id?: number): Observable<any> {
  save<T>(ano: number, estoqueMateriais: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(estoqueMateriais as unknown as IEstoqueMateriais).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(estoque: IEstoqueMateriais): IForm[] {
    try {
      const forms = new Array<IForm>();
      if (estoque.idPaciente) {
        forms.push({ VariavelNo: 29828, ValorDado: estoque.idPaciente || '' });
      }
      // { VariavelNo: 29828, ValorDado: estoqueMateriaisServices.codigoPaciente || '' },
      if (estoque.uniNegocio) {
        forms.push({ VariavelNo: 30313, ValorDado: estoque.uniNegocio || '' });
      }
      if (estoque.cascataTipoMAterialCodHosp) {
        forms.push({ VariavelNo: 32855, ValorDado: estoque.cascataTipoMAterialCodHosp || '' });
      }
      if (estoque.dose) {
        forms.push({ VariavelNo: 100853, ValorDado: estoque.dose || '' });
      }

      if (estoque.unidadeDose) {
        forms.push({ VariavelNo: 100854, ValorDado: estoque.unidadeDose || '' });
      }
      // if (estoque.tempoUso) {
      //   forms.push({ VariavelNo: 830, ValorDado: estoque.tempoUso || '' });
      // }
      if (estoque.dosagem) {
        forms.push({ VariavelNo: 832, ValorDado: estoque.dosagem || '' });
      }
      // if (estoque.orientacoes) {
      //   forms.push({ VariavelNo: 2952, ValorDado: estoque.orientacoes || '' });
      // }
      // if (estoque.posologia) {
      //   forms.push({ VariavelNo: 3530, ValorDado: estoque.posologia || '' });
      // }
      if (estoque.codigo) {
        forms.push({ VariavelNo: 27987, ValorDado: estoque.codigo || '' });
      }
      if (estoque.titulo) {
        forms.push({ VariavelNo: 27988, ValorDado: estoque.titulo || '' });
      }
      if (estoque.habilitado) {
        forms.push({ VariavelNo: 27989, ValorDado: estoque.habilitado ? 'SIM' : 'NÃO' });
      }
      if (estoque.centroCusto) {
        forms.push({ VariavelNo: 27993, ValorDado: estoque.centroCusto || '' });
      }
      if (estoque.estoque) {
        forms.push({ VariavelNo: 27994, ValorDado: estoque.estoque || '' });
      }
      if (estoque.estoqueMinimo) {
        forms.push({ VariavelNo: 27995, ValorDado: estoque.estoqueMinimo || '' });
      }
      if (estoque.unidademedida) {
        forms.push({ VariavelNo: 27997, ValorDado: estoque.unidademedida || '' });
      }
      if (estoque.dataUltimaCompra) {
        forms.push({
          VariavelNo: 27998,
          ValorDado: this.glb.RetornarAllXsdDate(estoque.dataUltimaCompra || new Date())
        });
      }
      if (estoque.tipoMaterial) {
        forms.push({ VariavelNo: 28018, ValorDado: estoque.tipoMaterial || '' });
      }
      if (estoque.consumoDiario) {
        forms.push({ VariavelNo: 28019, ValorDado: estoque.consumoDiario || '' });
      }
      // if (estoque.duracaoUnitariaDias) {
      //   forms.push({ VariavelNo: 101098, ValorDado: estoque.duracaoUnitariaDias || '' });
      //   forms.push({ VariavelNo: 28019, ValorDado: estoque.duracaoUnitariaDias || '' });
      // }
      if (estoque.dataProximaCompra) {
        forms.push({
          VariavelNo: 28020,
          ValorDado:
            this.glb.RetornarAllXsdDate(estoque.dataProximaCompra || new Date()) || ''
        });
      }
      if (estoque.hoje) {
        forms.push({
          VariavelNo: 28021,
          ValorDado: this.glb.RetornarAllXsdDate(estoque.hoje || new Date())
        });
      }
      if (estoque.duracaoTotal) {
        forms.push({ VariavelNo: 28022, ValorDado: estoque.duracaoTotal || '' });
      }

      if (estoque.apresentacao) {
        forms.push({ VariavelNo: 27997, ValorDado: estoque.apresentacao || '' });
      }
      // if (estoque.tipoMaterial) {
      //   forms.push({ VariavelNo: 28018, ValorDado: estoque.tipoMaterial || '' });
      // }

      if (estoque.uniNegocio) {
        forms.push({ VariavelNo: 28023, ValorDado: estoque.uniNegocio || '' });
      }
      // if (estoque.codHospede) {
      //   forms.push({ VariavelNo: 30027, ValorDado: estoque.codHospede || '' });
      // }
      if (estoque.medicamento) {
        forms.push({ VariavelNo: 30267, ValorDado: estoque.medicamento || '' });
      }
      if (estoque.medicamentoControlado) {
        forms.push({ VariavelNo: 30268, ValorDado: estoque.medicamentoControlado || '' });
      }
      if (estoque.medicamento2) {
        forms.push({ VariavelNo: 30269, ValorDado: estoque.medicamento || '' });
      }
      if (estoque.codigoPacienteNomePaciente) {
        forms.push({ VariavelNo: 30296, ValorDado: estoque.codigoPacienteNomePaciente || '' });
      }
      if (estoque.codPacienteNomedoPacienteCodMedicamento) {
        forms.push({
          VariavelNo: 30321,
          ValorDado: estoque.codPacienteNomedoPacienteCodMedicamento || ''
        });
      }
      // if (estoque.quantUtilizada) {
      //   forms.push({ VariavelNo: 30333, ValorDado: estoque.quantUtilizada || '' });
      // }
      if (estoque.tipoMedicamentosCodPaciente) {
        forms.push({
          VariavelNo: 30339,
          ValorDado: estoque.tipoMedicamentosCodPaciente || ''
        });
      }
      if (estoque.itens) {
        forms.push({ VariavelNo: 30350, ValorDado: estoque.itens || '' });
      }
      if (estoque.materialID) {
        forms.push({ VariavelNo: 30412, ValorDado: estoque.materialID || '' });
      }
      if (estoque.quantidadeEmbalagem) {
        forms.push({
          VariavelNo: 34718,
          ValorDado: (estoque.quantidadeEmbalagem || 0).toString()
        });
      }
      if (estoque.lsthistoricoCompraEstoque) {
        forms.push({
          VariavelNo: 28008,
          ValorDado: this.convertToValorTexto(estoque.lsthistoricoCompraEstoque || [])
        });
      }
      if (estoque.ultimoLote) {
        forms.push({
          VariavelNo: 100851,
          ValorDado: estoque.ultimoLote
        });
      }
      if (estoque.dataUltimaValidade) {
        forms.push({
          VariavelNo: 100852,
          ValorDado: this.glb.RetornarAllXsdDate(estoque.dataUltimaValidade || new Date())
        });
      }
      if (estoque.unidadeNegocio) {
        forms.push({ VariavelNo: 28023, ValorDado: estoque.unidadeNegocio || '' });
      }
      if (estoque.codigoPacienteNomePaciente) {
        forms.push({ VariavelNo: 30296, ValorDado: estoque.codigoPacienteNomePaciente || '' });
      }
      if (estoque.concatUniNegocioSIM) {
        forms.push({ VariavelNo: 34382, ValorDado: estoque.concatUniNegocioSIM || '' });
      }
      if (estoque.concatUniNegocioNAO) {
        forms.push({ VariavelNo: 34383, ValorDado: estoque.concatUniNegocioNAO || '' });
      }
      if (estoque.materialID) {
        forms.push({ VariavelNo: 30412, ValorDado: estoque.materialID || '' });
      }
      if (estoque.idMedicamento) {
        forms.push({ VariavelNo: 100306, ValorDado: estoque.idMedicamento || '' });
      }
      if (estoque.codCenCusto) {
        forms.push({ VariavelNo: 100323, ValorDado: estoque.codCenCusto || '' });
      }
      if (estoque.dose || estoque.dosageValue) {
        forms.push({ VariavelNo: 100853, ValorDado: estoque.dose || estoque.dosageValue || '' });
      }
      if (estoque.unidadeDose || estoque.dosageUnity) {
        forms.push({ VariavelNo: 100854, ValorDado: estoque.unidadeDose || estoque.dosageUnity?.toString() || '' });
      }
      if (estoque.consumoDiario2) {
        forms.push({ VariavelNo: 101098, ValorDado: estoque.consumoDiario2 || '' });
      }
      if (estoque.gOTASPMl) {
        forms.push({ VariavelNo: 101099, ValorDado: estoque.gOTASPMl || '' });
      }
      if (estoque.dataAtual) {
        forms.push({ VariavelNo: 101213, ValorDado: this.glb.RetornarAllXsdDate(estoque.dataAtual || new Date()) });
      }
      if (estoque.lastPrice) {
        forms.push({ VariavelNo: 28012, ValorDado: estoque.lastPrice.toString() || '0' });
      }
      if (estoque.lastInvoiceNumber) {
        forms.push({ VariavelNo: 28452, ValorDado: estoque.lastInvoiceNumber || '' });
      }

      return forms;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  // insertIfNotExist(ano: number, estoqueMateriais: IEstoqueMateriais, compareFieldName: string = 'titulo'): Observable<boolean> {
  insertIfNotExist<T>(ano: number, item: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], item[compareFieldName])) >= 0);
            if (!founded) {
              return this.save<IEstoqueMateriais>(ano, item as unknown as IEstoqueMateriais, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }

  protected getFromCadastroGrid(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string): Observable<IEstoqueMateriais> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      // Resgata os dados que duplicam os registros para cada item do grid e os agrupa por OcorrenciaNo
      lstVariables = lstVariables || this.lstVariaveis;
      const grouped = this.cadastroSrv
        .getCadastroComGridFiltro(cadastroNo, -1, lstVariables, this.variavelGrid, this.lstVariaveisGrid, '')
        .pipe(
          publishReplay(),
          refCount(),
          groupBy((g: any) => g.OcorrenciaNo)
        );
      // Criação do Observable que será o formato de retorno
      return new Observable(observer => {
        let estoqueMateriais: IEstoqueMateriais;
        // Subscrição para os grupos
        grouped
          .subscribe(group => { // Subscrição para acessar os dados de cada grupo
            group
              .pipe(
                toArray()
              ).subscribe(item => {
                // Todos os elementos do grupo são iguais, exceto pelos campos do grid
                // por esse motivo, é possível utilizar como base para os demais dados qualquer elementos
                const c = item[0];
                // Criação de um array com os percentuais e valores por unidade de negócio
                const lsthistoricoCompraEstoque: IHistoricoCompraEstoque[] = item.length <= 0 ? null :
                  item.map(m => (
                    {
                      dosagem: m.V_832,
                      codigoItemEstoque: m.V_27987,
                      centroCusto: m.V_27993,
                      unidademedida: m.V_27997,
                      codigo: m.V_27999,
                      quantidade: m.V_28000,
                      habilitado: this.lib.getBoolean(m.V_28001),
                      dataCompra: this.glb.ddMMYYYYThhmmssToDate(m.V_28004),
                      preco: m.V_28012,
                      fornecedorNome: m.V_28014,
                      tipoMaterial: m.V_28018,
                      unidadeNegocio: m.V_28023,
                      medicamento: m.V_30269,
                      material: m.V_30350,
                      codigoPedido: m.V_34687,
                      pedidoConferido: this.lib.getBoolean(m.V_34705),
                      quantidadeEmbalagem: m.V_34718,
                      idMedicamento: m.V_100306,
                      lote: m.V_100321,
                      validoAte: this.glb.ddMMYYYYThhmmssToDate(m.V_100322),
                      idCentroCusto: m.V_100325,
                      codPacienteNomedoPacienteCodMedicamento: m.V_100326,
                      quantidadePacotes: m.V_100406,
                      titulo: m.V_101702,
                      invoiceNumber: m.V_28452,
                    } as IHistoricoCompraEstoque));
                estoqueMateriais = ({
                  id: group.key, // Para exibir a OcorrenciaNo ao inves do Código do cadastro
                  ocorrenciaNo: c.OcorrenciaNo,
                  dosagem: c.V_832,
                  // entradaSaida: c.V_28008, // GRID apontando para o cadastro HISTÓRICO DE COMPRAS
                  codigo: c.V_27987,
                  titulo: c.V_27988,
                  habilitado: this.lib.getBoolean(c.V_27989),
                  centroCusto: c.V_27993,
                  estoque: c.V_27994,
                  estoqueMinimo: c.V_27995,
                  unidademedida: c.V_27997,
                  dataUltimaCompra: c.V_27998,
                  tipoMaterial: c.V_28018,
                  consumoDiario: c.V_28019,
                  dataProximaCompra: c.V_28020,
                  hoje: this.glb.ddMMYYYYThhmmssToDate(c.V_28021),
                  duracaoTotal: c.V_28022,
                  unidadeNegocio: c.V_28023,
                  idPaciente: c.V_29828,
                  medicamento: c.V_30267,
                  medicamentoControlado: c.V_30268,
                  medicamento2: c.V_30269,
                  codigoPacienteNomePaciente: c.V_30296,
                  uniNegocio: c.V_30313,
                  codPacienteNomedoPacienteCodMedicamento: c.V_30321,
                  tipoMedicamentosCodPaciente: c.V_30339,
                  itens: c.V_30350,
                  cascataTipoMAterialCodHosp: c.V_32855,
                  concatUniNegocioSIM: c.V_34382,
                  concatUniNegocioNAO: c.V_34383,
                  quantidadeEmbalagem: c.V_34718,
                  materialID: c.V_30412, // variavel anterior 100236 não existe mais
                  idMedicamento: c.V_100306,
                  codCenCusto: c.V_100323,
                  ultimoLote: c.V_100851,
                  dataUltimaValidade: c.V_100852,
                  lastPrice: c.V_28012,
                  dose: c.V_100853,
                  dosageValue: c.V_100853,

                  dosageUnity: c.V_100854,
                  unidadeDose: c.V_100854,

                  consumoDiario2: c.V_101098,
                  gOTASPMl: c.V_101099,
                  dataAtual: this.glb.ddMMYYYYThhmmssToDate(c.V_101213),
                  lsthistoricoCompraEstoque,
                  apresentacao: c.V_27997,
                  fornecedorNome: c.V_28014,
                  lastInvoiceNumber: c.V_28452
                } as IEstoqueMateriais);
                // Uma vez que o item é formado, é emitido, mas o complete só será chamado quando todos os itens forem concluídos
                observer.next(estoqueMateriais);
              });
          },
            (er) => console.log('EstoqueMateriais.getFromCadastroGrid: ', er),
            () => {
              observer.complete();
            });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadastroGrid', error.message);
    }
    return of(null);
  }

  protected convertToValorTexto(historicoCompraEstoque: IHistoricoCompraEstoque[]): string {
    try {
      let valorTexto = '<![CDATA[<Items>';
      if (historicoCompraEstoque) {
        historicoCompraEstoque.forEach(f => {
          valorTexto += `<Item><index>${f.index}</index>`;
          if (f.dosagem) { valorTexto += `<V_832>${f.dosagem}</V_832>`; }
          if (f.codigoItemEstoque) { valorTexto += `<V_27987>${f.codigoItemEstoque}</V_27987>`; }
          if (f.centroCusto) { valorTexto += `<V_27993>${f.centroCusto}</V_27993>`; }
          if (f.unidademedida) { valorTexto += `<V_27997>${f.unidademedida}</V_27997>`; }
          if (f.codigo) { valorTexto += `<V_27999>${f.codigo}</V_27999>`; }
          if (f.quantidade) { valorTexto += `<V_28000>${f.quantidade}</V_28000>`; }
          if (f.habilitado) { valorTexto += `<V_28001>${f.habilitado}</V_28001>`; }
          if (f.dataCompra) { valorTexto += `<V_28004>${this.glb.RetornarAllXsdDate(f.dataCompra)}</V_28004>`; }
          if (f.preco) { valorTexto += `<V_28012>${f.preco}</V_28012>`; }
          if (f.fornecedorNome) { valorTexto += `<V_28014>${f.fornecedorNome}</V_28014>`; }
          if (f.tipoMaterial) { valorTexto += `<V_28018>${f.tipoMaterial}</V_28018>`; }
          if (f.unidadeNegocio) { valorTexto += `<V_28023>${f.unidadeNegocio}</V_28023>`; }
          if (f.medicamento) { valorTexto += `<V_30269>${f.medicamento}</V_30269>`; }
          if (f.material) { valorTexto += `<V_30350>${f.material}</V_30350>`; }
          if (f.codigoPedido) { valorTexto += `<V_34687>${f.codigoPedido}</V_34687>`; }
          if (f.pedidoConferido) { valorTexto += `<V_34705>${f.pedidoConferido}</V_34705>`; }
          if (f.quantidadeEmbalagem) { valorTexto += `<V_34718>${f.quantidadeEmbalagem}</V_34718>`; }
          if (f.idMedicamento) { valorTexto += `<V_100306>${f.idMedicamento}</V_100306>`; }
          if (f.lote) { valorTexto += `<V_100321>${f.lote}</V_100321>`; }
          if (f.validoAte) { valorTexto += `<V_100322>${this.glb.RetornarAllXsdDate(f.validoAte)}</V_100322>`; }
          if (f.idCentroCusto) { valorTexto += `<V_100325>${f.idCentroCusto}</V_100325>`; }
          if (f.codPacienteNomedoPacienteCodMedicamento) {
            valorTexto += `<V_100326>${f.codPacienteNomedoPacienteCodMedicamento}</V_100326>`;
          }
          if (f.quantidadePacotes) { valorTexto += `<V_100406>${f.quantidadePacotes}</V_100406>`; }
          if (f.invoiceNumber) { valorTexto += `<V_28452>${f.invoiceNumber}</V_28452>`; }
          if (f.titulo) { valorTexto += `<V_101702>${f.titulo}</V_101702>`; }
          valorTexto += '</Item>';
        });
      }
      valorTexto += '</Items>]]>';
      return valorTexto;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertToValorTexto', error.message);
    }
    return '';
  }


}
