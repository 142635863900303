export interface IEvolution {
	ocorrenciaNo: number;
	idPessoa: any;
	idEscola: any;
	codigo: any;
	titulo: any;
	habilitado: boolean;
	nomeCompleto: string;
	nomeEducadorProfissionalResponsavel: string;
	nascimento: any;
	responsavelLegal: any;
	grauparentesco: any;
	naoConseguePrestarMuitaAtencaodetalhesOuCometeErrosPorDescuidoNosTrabalhosescolaOuTarefas: string;
	temDificuidademateratencaoEmDefesasOuAtividadeslazer: string;
	pareceNaoEstarOuvindoQuandofalaDiretamenteComEle: string;
	naoSegueInstrucoesAtefimnaoTernimaDeveresescolaTarefasOuObrigacoes: string;
	temDificuldadeorganizarTarefasatividades: string;
	observacoes: string;
	evitaNaoGostaOuenvolveContravontadeEmTarefasQueExigemEsforcoMentalProlongado: string;
	perdeCoisasNecessariosatividadesExBrinquedosDeveresescolaLapisOuLivros: string;
	distraicomEstimulosExternos: string;
	eEsquecidoEmAtividadesdiadia: string;
	mexeComAsMaosOuOsPesOuremexeNaCadeira: string;
	sailugarNaSalaaulaOuEmOutrasSituacoesEmQueesperaQueFiqueSentado: string;
	correumLadooutroOuSobreDemaisNaoCoisasEmSituacoesEmQueIstoinapropriado: string;
	temDificuidadeEmBrincarOuEnvolveremAtividadeslazerformaCalma: string;
	naoouFrequentementeEstaMilPorHora: string;
	falaEmExcesso: string;
	respondeAsPerguntasformaPrecipitadaAntesDelasTeremSidoTerminadas: string;
	temDificuldadeesperarSuaVez: string;
	data: Date;
	score: string;
}
