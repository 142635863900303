import { EvolutionCustomService } from '@medlogic/medlogic/medlogic-data-access';
import {
  IPatient,
  IMedlogicEvolution,
  AppLogService,
} from '@medlogic/shared/shared-interfaces';
import { IFlashObj } from '../../../assist/interfaces/iflash-obj';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { groupBy, toArray, mergeMap, map, take } from 'rxjs/operators';
import {
  UnsubscribeOnDestroyAdapter,
  GlobalService,
} from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { Observable, of, zip } from 'rxjs';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Store } from '@ngrx/store';
import { NavigationService } from '../../service/navigation.service';

import {
  isPatientLoading,
  selectedPatient,
  selectTenantState,
} from '@medlogic/medlogic/medlogic-state';

@Component({
  selector: 'app-evolution-timeline',
  templateUrl: './evolution-timeline.component.html',
  styleUrls: ['./evolution-timeline.component.css'],
})
export class EvolutionTimelineComponent extends UnsubscribeOnDestroyAdapter
  implements OnInit {
  defaultFormControls: any; // Armazena os valores para preenchimento de um lançamento baseado em recorrência
  atividadeNo: number;
  ocorrenciaNo: number;
  isRecurrent = false;
  flashObjs: IFlashObj[];
  description = 'Evolução no Tempo';
  isEdit = true;
  evolutionNo: number;

  public get isMobile(): boolean {
    return this.glb.isMobile();
  }

  public get patient(): IPatient {
    return this.cnf.selectedPatient;
  }
  evolution$: Observable<any>;
  // evolution$: Observable<any> = this.store.select(selectEvolutionAll).pipe(

  //         flatMap((res) => {
  //               console.log(res);
  //               return res;
  //         }),
  //         groupBy((g: IMedlogicEvolution) => this.glb.DateToddMMYYYY(this.glb.getTypedValue(g?.dataAvaliacao).value)),
  //         mergeMap(group => {
  //           return zip(of(group.key), of(0), group.pipe(this.mapTo(), toArray()));
  //         }),
  //         toArray(),
  //         map(m => {
  //           const ordered = m.sort((a, b) => this.glb.compareDates(this.glb.getTypedValue(b[0]).value, this.glb.getTypedValue(a[0]).value));
  //           let count = 0;
  //           ordered.forEach(e => {
  //             count++; // Necessário para formatação na esquerda, ou direita.
  //             e[1] = count;
  //           });
  //           return ordered;
  //         }),
  // );

  selectedPatient$: Observable<IPatient> = this.store.select(selectedPatient);
  selectedPatient: number;
  cadEvolution: number;
  isLoading$: Observable<boolean> = this.store.select(isPatientLoading);

  constructor(
    private log: AppLogService,
    private store: Store<IAppMedlogicState>,
    private cnf: ConfigPwaMedLogicService,
    private route: ActivatedRoute,
    private glb: GlobalService,
    private nav: NavigationService,
    private evolutionSrv: EvolutionCustomService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.nav.addToHistory(
        this.route.snapshot.url.map((m) => m.path),
        '[evolution-timeline] EvolutionTimeLine'
      );
      this.getUrlParams(this.route);
      this.store.select(selectedPatient).pipe(take(1)).subscribe(res => this.selectedPatient = res?.codigo);
      this.store.select(selectTenantState).pipe(take(1)).subscribe(res => this.cadEvolution = res?.selectedTenant?.cadEvolutionNo);
      this.refresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Carrega os dados. */
  refresh(): void {
    try {
      this.evolution$ = this.evolutionSrv.getByCodigoPaciente(this.cadEvolution, this.selectedPatient.toString())
        .pipe(
          groupBy((g: IMedlogicEvolution) => this.glb.DateToddMMYYYY(g?.dataAvaliacao)),
          mergeMap(group => {
            return zip(of(group.key), of(0), group.pipe(this.mapTo(), toArray()));
          }),
          toArray(),
          map(m => {
            const ordered = m.sort((a, b) => this.glb.compareDates(this.glb.getTypedValue(b[0]).value, this.glb.getTypedValue(a[0]).value));
            let count = 0;
            ordered.forEach(e => {
              count++; // Necessário para formatação na esquerda, ou direita.
              e[1] = count;
            });
            return ordered;
          }),
        );

      // this.store.dispatch(loadEvolutionsBySelectedPatient());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Operador personalizado. Mapeia para o objeto que será exibido na tela. */
  protected mapTo = () =>
    map((item: any) => {
      try {
        return {
          hora: this.glb.dateTohhmmss((this.glb.getTypedValue(item?.dataAvaliacao).value), false),
          evolucao: item?.evolucaoDescritiva,
          conduta: item?.condutaEvolucao,
          blIntercorrencia: item?.houveIntercorrencias,
          profissional: item?.executorAvaliacao,
          intensidade: item?.intensidade,
          ultimaEvacuacaoOcorreurmais2Dias:
            item?.ultimaEvacuacaoOcorreurmais2Dias,
        };
      } catch (error) {
        this.log.Registrar(this.constructor.name, 'mapTo', error.message);
      }
      return null;
    });

  /* Extrai os parâmetros passados na url/rota */
  protected getUrlParams(route: ActivatedRoute): void {
    try {
      // Os parametros estão sendo passados diretamente aos componentes
      // Para aguardar o carregamento do config
      // this.subs.sink = route.params
      //   .subscribe((params: Params) => {
      //     if (params.key) {
      //       this.evolutionNo = +params.key;
      //     } else {
      //       if (this.cnf.evolutionCadastroNo) {
      //         this.evolutionNo = this.cnf.evolutionCadastroNo;
      //       }
      //     }
      //     this.nav.addToHistory(this.nav.getRouteEvolutionTimeline(this.evolutionNo), 'evolution-timeline');
      //     this.refresh(this.evolutionNo, this.cnf.selectedPatient);
      //   });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /* Permitirá que os controles internos chamem uma rotina de recarregamento e limpeza do cache.
  * É útil, por exemplo, ao cadastrar um grid de rateio, forçar o recarregamento.
  * mas essa função não faz retornar para o Grid, apenas prepara para quando o usuário retornar esteja correto.
  */
  onRefresh(args: any): void {
    try {
      this.cacheCleanAndRefresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onRefresh', error.message);
    }
  }

  /* Limpa o cache de dados e recarrega. */
  protected cacheCleanAndRefresh(): void {
    try {
      this.refresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cacheClean', error.message);
    }
  }

  /* Retorna para a página anterior. */
  onBack(args: any): void {
    try {
      this.nav.gotoRoot(this.cnf.tenantId);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }


}
