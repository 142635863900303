import { Injectable } from '@angular/core';
import { routeQualification } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';


@Injectable({ providedIn: 'root' })
export class FhirQualificationService extends FhirGenericService {
  protected url = routeQualification;

}
