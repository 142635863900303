
import { refCount, publishReplay, map, filter, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IVitalSign } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class VitalSignService {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_2230,V_28595,V_28610,V_28611,V_28612,V_28616,V_28626,V_28772,V_28777,V_28841,V_28862,V_28980,V_28981,V_29977,V_31914,V_31915,V_31916,V_31917,V_31918,V_31919,V_31920,V_31921,V_31922,V_29997,V_34340';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IVitalSign[] = new Array<IVitalSign>();

  cadastroNo = 9538;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IVitalSign> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        filter(f => {
          return this.glb.isBetweenIgnoreTime(f.dataavaliacao, startDate, endDate);
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IVitalSign> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IVitalSign {
    try {
      return {
        frequenciaCardiacaBpm: c.V_31914,
        frequenciaRespiratoriaICP: c.V_31915,
        glicemiaCapilarEmJejumMlDl: c.V_31916,
        posPrandialMlDl: c.V_31917,
        glicose: c.V_31918,
        pADiastolicaRef80: c.V_31919,
        pASistolicaRef120: c.V_31920,
        saturacaoOxigenioSO: c.V_31921,
        temperaturaTax: c.V_31922,
        codigoPaciente: c.V_2230,
        // dataavaliacao: this.glb.YYYYMMddThhmmssToDate(c.V_28595),
        dataavaliacao: this.glb.getTypedValue(c.V_28595).value,
        executorAvaliacao: c.V_34340,
        laudo: c.V_28616,
        codigo: c.V_28610,
        titulo: c.V_28611,
        habilitado: c.V_28612.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_28612) ? true : false,
        oCORRENCIA: c.V_28626,
        codPacCAT: c.V_28772,
        codPacOcorrenciaCAT: c.V_28777,
        executorMedicaoData: c.V_28862,
        codPacPressao: c.V_28980,
        cODHOSPDATA: c.V_28981,
        tecnicoResponsavel: c.V_29977
      } as IVitalSign;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(vitalSign: IVitalSign, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(vitalSign).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(vitalSign: IVitalSign): IForm[] {
    try {
      return [
        { VariavelNo: 2230, ValorDado: vitalSign.codigoPaciente || '' },
        { VariavelNo: 28595, ValorDado: this.glb.ddMMYYYYThhmmssToDate(vitalSign.dataavaliacao) },
        { VariavelNo: 28610, ValorDado: vitalSign.codigo || '' },
        { VariavelNo: 28611, ValorDado: vitalSign.titulo || '' },
        { VariavelNo: 28612, ValorDado: vitalSign.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 28616, ValorDado: vitalSign.laudo || '' },
        { VariavelNo: 28626, ValorDado: vitalSign.oCORRENCIA || '' },
        { VariavelNo: 28772, ValorDado: vitalSign.codPacCAT || '' },
        { VariavelNo: 28777, ValorDado: vitalSign.codPacOcorrenciaCAT || '' },
        { VariavelNo: 34340, ValorDado: vitalSign.executorAvaliacao || '' },
        { VariavelNo: 28862, ValorDado: vitalSign.executorMedicaoData || '' },
        { VariavelNo: 28980, ValorDado: vitalSign.codPacPressao || '' },
        { VariavelNo: 28981, ValorDado: vitalSign.cODHOSPDATA || '' },
        { VariavelNo: 29977, ValorDado: vitalSign.tecnicoResponsavel || '' },
        { VariavelNo: 31914, ValorDado: vitalSign.frequenciaCardiacaBpm || '' },
        { VariavelNo: 31915, ValorDado: vitalSign.frequenciaRespiratoriaICP || '' },
        { VariavelNo: 31916, ValorDado: vitalSign.glicemiaCapilarEmJejumMlDl || '' },
        { VariavelNo: 31917, ValorDado: vitalSign.posPrandialMlDl || '' },
        { VariavelNo: 31918, ValorDado: vitalSign.glicose || '' },
        { VariavelNo: 31919, ValorDado: vitalSign.pADiastolicaRef80 || '' },
        { VariavelNo: 31920, ValorDado: vitalSign.pASistolicaRef120 || '' },
        { VariavelNo: 31921, ValorDado: vitalSign.saturacaoOxigenioSO || '' },
        { VariavelNo: 31922, ValorDado: vitalSign.temperaturaTax || '' }
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Retorna um título bem formatado para cada campo.  */
  getRotulo(field: string): string {
    try {
      const mapR = {
        codigoPaciente: '#NAOEXIBIR#',
        dataavaliacao: '#NAOEXIBIR#',
        codigo: '#NAOEXIBIR#',
        titulo: '#NAOEXIBIR#',
        habilitado: '#NAOEXIBIR#',
        laudo: 'Laudo',
        oCORRENCIA: '#NAOEXIBIR#',
        codPacCAT: '#NAOEXIBIR#',
        codPacOcorrenciaCAT: '#NAOEXIBIR#',
        executorAvaliacao: '#NAOEXIBIR#',
        executorMedicaoData: '#NAOEXIBIR#',
        codPacPressao: '#NAOEXIBIR#',
        cODHOSPDATA: '#NAOEXIBIR#',
        tecnicoResponsavel: 'Profissional',
        frequenciaCardiacaBpm: 'Frequência Cardíaca (BPM)',
        frequenciaRespiratoriaICP: 'Frequência Respiratória (ICP)',
        glicemiaCapilarEmJejumMlDl: '(Glicemia Capilar em Jejum (ml/dl)',
        posPrandialMlDl: 'Glicemia pós-prandial (ml/dl)',
        glicose: 'Glicose',
        pADiastolicaRef80: 'Pressão Diastólica (ref: 80)',
        pASistolicaRef120: 'Pressão Sistólica (ref: 120)',
        saturacaoOxigenioSO: 'Saturação de Oxigênio',
        temperaturaTax: 'Temperatura'
      };
      if (mapR.hasOwnProperty(field)) {
        return mapR[field];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRotulo', error.message);
    }
    return '';
  }

}
