<ng-container *ngLet="loginState$ | async as loginState">

  <!-- <div class="grid" [class.logged]="loginState === 'Logged'"> -->
  <div class="grid">

    <div class="blank"></div>

    <!-- Row 1 -->
    <div class="logo">
      <img src="/assets/img/logo-medlogic-vetorizada.png" title="logo MedLogic" />
    </div>


    <ng-container *ngIf="['Login', 'Logged'].includes(loginState)">

      <!-- Row 2 -->
      <div class="user">
        <mat-form-field>
          <input matInput autofocus placeholder="Usuário" id="usuario" name="usuario" [(ngModel)]="login"
            (keyup.enter)="setFocus(passwordInput)">
        </mat-form-field>
      </div>

      <!-- Row 3 -->
      <div class="password">
        <mat-form-field>
          <input matInput #passwordInput type="{{showPassword ? 'text' : 'password'}}" placeholder="Senha" id="senha" name="senha"
            [(ngModel)]="password" (keyup.enter)="onConfirm(login, password)">
            <mat-icon class="icone" (click)="visibilityPassword()">{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
          </mat-form-field>
      </div>

      <!-- Row 4 -->
      <div class="button">
        <button mat-raised-button color="#008B95" (click)="onConfirm(login, password)"
          [disabled]="isLoading$ | async">Entrar</button>
      </div>


    </ng-container>

    <!-- <div>
      <button mat-raised-button color="#008B95" (click)="onBiometricRegisterConfirm(login)"
        [disabled]="isLoading$ | async">Cadastrar Biometria</button>
    </div>

    <div>
      <button mat-raised-button color="#008B95" (click)="onBiometricConfirm(login)"
        [disabled]="isLoading$ | async">Autenticação Biométrica</button>
    </div> -->

    <!-- DESATIVADO: O usuário precisa ter um token válido e o mesmo expira por tempo. -->
    <!-- <ng-container *ngIf="loginState === 'Logged'">

      <div class="greeting">
        <h1>Olá {{ userName$ | async }}</h1>
      </div>

      <div class="enter">
        <button mat-raised-button (click)="doEnter($event, loginState)">Entrar</button>
      </div>

      <div class="change">
        <button mat-raised-button (click)="doChangeUser($event)">Trocar usuário</button>
      </div>

    </ng-container> -->

    <!-- Row 5 -->
    <div *ngIf="loginState !== 'Loading'" class="version">
      <span>{{ version }}</span>
    </div>

    <!-- Row alternative during loading -->
    <div *ngIf="loginState === 'Loading'" class="loading">
      <ui-loading-spinner></ui-loading-spinner>
    </div>

  </div>

</ng-container>
