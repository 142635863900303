import { IEstoqueMateriais } from '@medlogic/shared/shared-interfaces';
import { EntityState } from '@ngrx/entity';

export interface IStockState extends EntityState<IEstoqueMateriais> {
    // additional entities state properties
    error: any;
    selectedId: number | null;
    changeItem: {
        updateStock: boolean;
        medicationId: number;
        dailyQuantity: number;
        message: string;
    };
    changeItems: {
        updateStock: boolean;
        medicationId: number;
        dailyQuantity: number;
        message: string;
    }[];
}
