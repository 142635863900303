import { EnVideoType } from '../enum/en-video-type.enum';

export interface IVideo {
    id: number;
    src: string;
    name: string;
    description: string;
    type: EnVideoType;
    group: string;
}
