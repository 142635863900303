import { animate, style, transition, trigger, state } from '@angular/animations';

export const slideInOut = trigger('slideInOut', [

    state('in', style({ marginLeft: '*', width: '60%' })),
    state('out', style({ marginLeft: '-100%', width: '60%' })),

    transition('* => out', [
        style({ marginLeft: '*' }),
        animate('1s', style({ marginLeft: '-100%' }))
    ]),
    transition('out => in', [
        style({ marginLeft: '*' }),
        animate('1s', style({ marginLeft: 'auto' }))
    ]),
]);
