import { Injectable } from '@angular/core';
import { FhirGenericService } from './fhir-generic.service';
import { IActivityDetail, routeActivityDetails } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FhirActivityDetailService extends FhirGenericService {

  protected url = routeActivityDetails;

  getDashboardActivitiesById(prontuario: string): Observable<IActivityDetail[] | null> {
    const url = `${this.getUrl()}getDashboardActivities?prontuario=${prontuario}`;
    try {
       const $result = this.http.get<IActivityDetail[]>(url);
       return $result;
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'getActivityDetailByType', error.message);
    }
    return of(null);
  }

  getRondaByPatient(prontuario: string): Observable<IActivityDetail[] | null> {
    const url = `${this.getUrl()}getRondaByPatient?prontuario=${prontuario}`;
    try {
       const $result = this.http.get<IActivityDetail[]>(url);
       return $result;
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'getRondaByPatient', error.message);
    }
    return of(null);
  }

  deleteActivityDetailsByActivity(medlogicActivityId : string): boolean{
   const url = `${this.getUrl()}ActivityDetails/deleteByActivity?id=${medlogicActivityId}`;
   try {
     this.http.delete<boolean>(url).pipe().subscribe();
     return true;
   } catch (error: any) {
     this.log.Registrar(this.constructor.name, '', error.message);
   }
   return false;
 }

 cancelActivityDetailsByActivity(medlogicActivityId : string): boolean{
   const url = `${this.getUrl()}cancelByActivity?id=${medlogicActivityId}`;
   console.log(url)
   try {
     this.http.put<boolean>(url, "").pipe().subscribe();
     return true;
   } catch (error: any) {
     this.log.Registrar(this.constructor.name, '', error.message);
   }
   return false;
 }
}
