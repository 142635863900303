import { createAction, props } from '@ngrx/store';
import { IIvcf } from '@medlogic/shared/shared-interfaces';

// TODO: Each model need to have its specific Action, Reducer, Effects.
export enum IvcfActionTypes {
  LoadIvcf = '[Ivcf Component] Load Ivcf by ono',
  FetchSuccessIvcf = '[Ivcf API] Fetch Success Ivcf ', // Set the Ivcf  to the Store, after Effects
  FetchErrorIvcf = '[Ivcf API] Fetch Error Ivcf ',
  SetIvcf = '[Page Ivcf Component] Set Ivcf ',
  SaveIvcf = '[Page Ivcf Component] Save Ivcf',
  SaveSuccessIvcf = '[Page Ivcf Component] Save Success Ivcf',
  SaveFailIvcf = '[Page Ivcf Component] Save Fail Ivcf',
  UpdateIvcf = '[Page Ivcf Component] Update Ivcf',
  SaveAllIvcf = '[Page Ivcf Component] Save All Ivcf',
  ResetIvcf = '[Page Ivcf Component] Reset Ivcf',
  CleanIvcf = '[States Management Service] Clean Ivcf',
  // Category
  SetCategoriaIvcf = '[Page Ivcf Component] Set Categoria Ivcf',
  // Other states
  LoadIvcfsAndResultados = '[Page Ivcf Component] Load Ivcfs and Resultados',
}

export const loadIvcf = createAction(IvcfActionTypes.LoadIvcf, props<{ ivcfId: number, isProspectIvcf: boolean }>());
export const loadIvcfsAndResultados = createAction(IvcfActionTypes.LoadIvcfsAndResultados);
export const setIvcf = createAction(IvcfActionTypes.SetIvcf, props<{ ivcf: IIvcf }>());
export const fetchSuccessIvcf = createAction(IvcfActionTypes.FetchSuccessIvcf, props<{ ivcf: IIvcf }>());
export const fetchErrorIvcf = createAction(IvcfActionTypes.FetchErrorIvcf);
export const saveIvcf = createAction(IvcfActionTypes.SaveIvcf, props<{ ivcf: IIvcf }>());
export const saveSuccessIvcf = createAction(IvcfActionTypes.SaveSuccessIvcf, props<{ ivcf: IIvcf }>());
export const saveFailIvcf = createAction(IvcfActionTypes.SaveFailIvcf, props<{ ivcf: IIvcf }>());
export const updateIvcf = createAction(IvcfActionTypes.UpdateIvcf, props<{ ivcf: IIvcf }>());
export const saveAllIvcf = createAction(IvcfActionTypes.SaveAllIvcf, props<{ ivcf: IIvcf }>());
export const resetIvcf = createAction(IvcfActionTypes.ResetIvcf);
export const cleanIvcf = createAction(IvcfActionTypes.CleanIvcf);
