export enum EnPassoTipo {
	ctrTarefa = 1,
	ctrInicio = 2,
	ctrSubProcesso = 3,
	ctrFim = 4,
	ctrCondicional = 5,
	ctrEventoIntegracao = 6,
	ctrAtividadeMultipla = 7,
	ctrMultiplosPassos = 16 // não existe um componente com este nome, e sim uma sinalização para Interface que tem vários passo,
}
