export interface IHumanName {
    use: string;
    text: string;
    family: string;
    given: string;
    prefix: string;
    suffix: string;
    period: string;
    tenantId: number;
    language: string;
}