import { VitalSignCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { addVitalSign } from '@medlogic/medlogic/medlogic-state';
import { NavigationPwaService } from './../../service/navigation-pwa.service';
import {
  selectVitalSignsDefaultControls,
  selectVitalSignSelectedId
} from '@medlogic/medlogic/medlogic-state';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IFlashObj } from '../../../assist/interfaces/iflash-obj';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  UnsubscribeOnDestroyAdapter, GlobalService,
  EnTheme, IAtividadeComponenteDAL, ILogin, AppLogService
} from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ml-vital-sign',
  templateUrl: './vital-sign.component.html',
  styleUrls: ['./vital-sign.component.css']
})
export class VitalSignComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  defaultFormControls: any; // Armazena os valores para preenchimento de um lançamento baseado em recorrência
  atividadeNo: number;
  enTheme = EnTheme.default;
  processoNo: number;
  isRecurrent = false;
  flashObjs: IFlashObj[];
  description = 'Sinais Vitais';
  isEdit = true;
  isReadOnly = false;
  public get isMobile(): boolean {
    return this.global.isMobile();
  }
  ocorrenciaNo: number;
  ocorrenciaNo$: Observable<number> = this.store.pipe(select(selectVitalSignSelectedId));
  usuarioNo$: Observable<number> = this.store.pipe(select(state => (state?.loginPWA?.selectedLogin as ILogin)?.usuarioLogadoNo));
  atividadeNo$: Observable<number> = this.store.pipe(select(state => state?.tenant?.selectedTenant?.cadVitalSignsNo));
  defaultFormControls$: Observable<{ [key: string]: any }> = this.store.pipe(select(selectVitalSignsDefaultControls));

  constructor(
    private log: AppLogService,
    private store: Store<IAppMedlogicState>,
    private route: ActivatedRoute,
    private global: GlobalService,
    private nav: NavigationPwaService,
    private vitalSignSrv: VitalSignCustomService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.nav.addToHistory(this.route.snapshot.url.map(m => m.path), '[vitalsign] VitalSign');
      this.getUrlParams(this.route);
      this.ocorrenciaNo$.subscribe(s => this.ocorrenciaNo = s);
      if (this.ocorrenciaNo && this.ocorrenciaNo > 0) {
        this.isReadOnly = true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Carrega os dados. */
  // refresh(vitalsignNo: number): void {
  //   try {
  // Carregamento da Atividade
  // this.ocorrenciaNo = -1; // Nova ocorrência
  // this.atividadeNo = vitalsignNo;
  // this.defaultFormControls = this.getDefaultFormControls(
  //   this.cnf.OcorrenciaNo.value,
  //   this.cnf.selectedPatient,
  //   this.cnf.usuarioLogadoNo,
  //   this.cnf.usuarioLogadoNome
  // );
  // let dtStart: Date = new Date(new Date().getFullYear(), 0, 1);
  // let dtEnd: Date = this.lib.addMonthsUTC(new Date(), 3);
  // //Registros
  // let observableEntries =
  //   this
  //     .entrySrv
  //     .getAll(vitalsignNo, dtStart, dtEnd, 1);
  // observableEntries
  //   .toArray().map(v => v.sort((a, b) => (b.dateDue && a.dateDue) ?
  //     b.dateDue.getTime() - a.dateDue.getTime() : 1))
  //   .subscribe(items => {
  //     this.entries = items;
  //     // console.log('entries', this.entries);
  //   });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'refresh', error.message);
  //   }
  // }

  /* Extrai os parâmetros passados na url/rota */
  protected getUrlParams(route: ActivatedRoute): void {
    try { // Os parametros estão sendo passados diretamente aos componentes
      // Para aguardar o carregamento do config
      // this.subs.sink = route.params
      //   .subscribe((params: Params) => {
      //     if (params.key) {
      //       this.vitalsignNo = +params.key;
      //     } else {
      //       if (this.cnf.vitalSignCadastroNo) {
      //         this.vitalsignNo = this.cnf.vitalSignCadastroNo;
      //       }
      //     }
      //     this.refresh(this.vitalsignNo);
      //   });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /* Permitirá que os controles internos chamem uma rotina de recarregamento e limpeza do cache.
  * É útil, por exemplo, ao cadastrar um grid de rateio, forçar o recarregamento.
  * mas essa função não faz retornar para o Grid, apenas prepara para quando o usuário retornar esteja correto.
  */
  onRefresh(args: any): void {
    try {
      this.cacheCleanAndRefresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onRefresh', error.message);
    }
  }

  /* Limpa o cache de dados e recarrega. */
  protected cacheCleanAndRefresh(): void {
    try {
      // this.entrySrv.clearCache();
      // this.refresh(this.cnf.entryCadastroNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cacheClean', error.message);
    }
  }

  onBack(args: any): void {
    try {
      this.nav.gotoVitalSignList();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  onGeBack(args: any): void {
    try {
      this.nav.gotoVitalSignList();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  eventBubble($event: any): void {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'eventBubble', error.message);
    }
  }

  afterCompleted($event: any): void {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterCompleted', error.message);
    }
  }

  afterSaved(obj: {
    AtividadeNo: number;
    OcorrenciaNo: number;
    ProcessoNo: number;
    TarefaNo: number;
    Componentes: IAtividadeComponenteDAL[];
    fg: FormGroup;
  }): void {
    try {
      // Não é possível retornar automaticamente, se houver
      // operações de grid, edição ==de combo, imprimir, anexos, chamam o salvar.
      const objVitalSign = obj.Componentes
        .reduce((a, b) => {
          a[`V_${b.VariavelNo}`] = b.VariavelNo === 28595 ? b.ValorData : b.Valor;
          return a;
        }, {});
      this.subs.sink = this.vitalSignSrv.mapObjToVitalSign(objVitalSign)
        .subscribe(vitalSign => {
          this.store.dispatch(addVitalSign({ vitalSign }));
          this.nav.gotoVitalSignList();
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSaved', error.message);
    }
  }


}
