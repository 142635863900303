import { confirmAllMedications, selectPatientAllActiveWithPendingMedications, setPatient } from '@medlogic/medlogic/medlogic-state';
import { confirmMedication } from '@medlogic/medlogic/medlogic-state';
import { selectTenantIsUpdateStockOnApp } from '@medlogic/medlogic/medlogic-state';
import {
  selectMedicationByPatientGroupedByPeriod,
  selectedPatient
} from '@medlogic/medlogic/medlogic-state';
import { IAppMedlogicState, IMedicationPeriod } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { ActivatedRoute, Params } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppLogService, IMedicationCheckin, IPatient } from '@medlogic/shared/shared-interfaces';
import { IMonth } from '../../../assist/interface/imonth';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { catchError, filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  HomeView, MaterialDialogService,
  CustomerConfigService
} from '@medlogic/shared/gecore';
import { IMedication } from '@medlogic/shared/shared-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MedicationDialogComponent } from '../../components/medication-dialog/medication-dialog.component';
import { AppProvider } from '../../../module/core/service/app.provider';
import { LocalMsgPtBR } from '../../../module/core/service/local-msg-ptBR.service';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { select, Store } from '@ngrx/store';

/** Atenção: os dados da medicação são carregados na tela inicial: card-patient. */
@Component({
  selector: 'ml-card-medication',
  templateUrl: './card-medication.component.html',
  styleUrls: ['./card-medication.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardMedicationComponent extends HomeView implements OnInit {

  // @Input() patient: IPatient; // armazena o paciente da medicação selecionada
  patient$: Observable<IPatient> = this.store.pipe(select(selectedPatient));
  lstConfig: any[] = [];
  medication: IMedication = {} as IMedication; // armazena a medicação selecionada

  // medicationPeriods = new Array<IMedicationPeriod>(); // armazena a medicação selecionada, agrupada em períodos.
  medicationPeriods$: Observable<IMedicationPeriod[]> = this.store.pipe(select(selectMedicationByPatientGroupedByPeriod)); // armazena a medicação selecionada, agrupada em períodos.
  patients$: Observable<IPatient[]> = this.store.select(selectPatientAllActiveWithPendingMedications);

  months: IMonth[];
  selectedMonth: any = 0;
  today: string;
  currentTime: Date = new Date();
  isLoading = true;
  cadCheckMedicamentosNo = -1;
  cadStockNo = -1;
  savingItems = new Array<IMedication>();
  // isUpdateStockOnApp = this.appPrv.enStockChangeMode && (this.appPrv.enStockChangeMode === EnStockChangeMode.checkin);
  isUpdateStockOnApp$: Observable<boolean> = this.store.pipe(select(selectTenantIsUpdateStockOnApp));
  public showMedication: number = 1
  public get isSaving(): boolean {
    return (this.savingItems && this.savingItems.length > 0);
  }

  public get currentMonth(): IMonth {
    try {
      if (this.months !== undefined) {
        return this.months.find(f => f.value === this.selectedMonth);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'currentMonth', error.message);
    }
    return null;
  }

  constructor(
    private store: Store<IAppMedlogicState>,
    private matDialogSrv: MaterialDialogService,
    private matDialog: MatDialog,
    protected appPrv: AppProvider,
    protected msg: LocalMsgPtBR,
    protected nav: NavigationPwaService,
    log: AppLogService,
    glb: GlobalService,
    cnf: ConfigPwaMedLogicService,
    route: ActivatedRoute,
    modelComponent: ModelComponent,
    custCnf: CustomerConfigService,
  ) {
    super(log, glb, cnf, route, nav, modelComponent, custCnf, msg);
    try {
      // if (!this.patient) {
      //   this.patient = this.cnf.selectedPatient;
      //   if (!this.patient) {
      //     this.loginSrv.logoff();
      //   }
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  ngOnInit(): void {
    try {
      // TODO: Tentativa de não recarregar os dados, uma vez que sã carregados na tela inicial.
      // OBS: No entanto, a tela inicial não desdobra os medicamentos conforme frequência. Também não carrega os check-ins.
      // this.reloadData();
      // super.ngOnInit();
      this.getUrlParams();
      this.isLoading = false;
      // FIXME: Esse item deverá ser transferido para o effects
      // this.medicationPeriods$.subscribe(medications =>
      //   this.checkMedicationThatIsPending(medications, this.currentTime)
      // );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  private reloadData(): void {
    try {
      // TODO: Tentativa de não recarregar os dados, uma vez que sã carregados na tela inicial.
      // this.store.dispatch(clearMedications());
      // this.store.dispatch(loadMedicationsBySelectedPatient({ numOfDaysToCheckOcurrency: 10 }));
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'reloadData', error.message);
    }
  }

  /* Não utilizado, pois, o parametro necessário virá da url. */
  protected setCadParams(): void {
    // throw new Error("Method not implemented.");
  }

  /* Carrega os parâmetros passados pela Url */
  protected getUrlParams(): void {
    try {
      // Os parametros estão sendo passados diretamente aos componentes
      this.route.params
        .subscribe((params: Params) => {
          if (params.cadCheckMedicamentosNo) {
            this.cadCheckMedicamentosNo = +params.cadCheckMedicamentosNo;
          }
          if (params.cadStockNo) {
            this.cadStockNo = +params.cadStockNo;
          }
          this.refresh();
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  protected setCurrentRoute(): void {
    try {
      this.currentRoute = this.nav.getRouteCardMedication2(this.cnf.modeloConfigNo, this.cadStockNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCurrentRoute', error.message);
    }
  }

  /* Carrega os campos na tela.  */
  protected refresh(): void {
    try {
      const dt = new Date();
      this.today = `${this.glb.DiaDaSemana(dt.getDay())}, ${dt.getDate()} de ${this.glb.NomeDoMes(dt)} de ${dt.getFullYear()}`;
      this.setTimer();
      // FIXME: Transferir para effects
      // this.fillMedicationPeriod();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  // /* Organiza as medicações conforme o período de ministração. */
  // protected fillMedicationPeriod(): void {
  //   try {
  //     this.medicationPeriods = [
  //       // tslint:disable: max-line-length
  //       { enMedicationPeriod: EnMedicationPeriod.stop, title: 'Interromper', filter: this.filterStopMedication, styleCss: 'stop' } as IMedicationPeriod,
  //       { enMedicationPeriod: EnMedicationPeriod.SOS, title: 'SOS', filter: this.filterSOS, styleCss: 'sos' } as IMedicationPeriod,
  //       { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Manhã', startHour: 0, endHour: 11, styleCss: 'morning' } as IMedicationPeriod,
  //       { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Tarde', startHour: 12, endHour: 18, styleCss: 'afternoon' } as IMedicationPeriod,
  //       { enMedicationPeriod: EnMedicationPeriod.timeInterval, title: 'Noite', startHour: 19, endHour: 24, styleCss: 'night' } as IMedicationPeriod,
  //     ];
  //     this.medicationPeriods.forEach(e =>
  //       e.medications = this.patient.medications
  //         .filter(f => {
  //           if (f.stopMedication) {
  //             return this.filterStopMedication(f, e.enMedicationPeriod);
  //           } else if (f.isSOS) {
  //             return this.filterSOS(f, e.enMedicationPeriod);
  //           } else {
  //             return this.filterStandard(f, e.startHour, e.endHour, e.enMedicationPeriod);
  //           }
  //         })
  //         .sort((a, b) => (this.glb.getHora(a.prescribedTime) - this.glb.getHora(b.prescribedTime)) || (this.glb.CompararIgnorarCapitulacaoEAcentuacao(a.medicationName, b.medicationName)))
  //     );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'fillMedicationPeriod', error.message);
  //   }
  // }

  // /* Expressão padrão, com base no horário.
  //  * Também não entrarão as medicações de SOS e interrupção.
  //  */
  // protected filterStandard(medication: IMedication, startHour: number, endHour: number, enPeriod: EnMedicationPeriod): boolean {
  //   try {
  //     // startHour = startHour === 0 ? 24 : startHour;
  //     // endHour = endHour === 0 ? 24 : endHour;
  //     return (enPeriod === EnMedicationPeriod.timeInterval) &&
  //       !medication.stopMedication && !medication.isSOS &&
  //       (this.glb.getHora(medication.prescribedTime) >= startHour &&
  //         this.glb.getHora(medication.prescribedTime) <= endHour);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'filterStandard', error.message);
  //   }
  // }

  // /* Expressão personalizada de filtro para SOS. */
  // protected filterSOS(medication: IMedication, enPeriod: EnMedicationPeriod): boolean {
  //   try {
  //     return (enPeriod === EnMedicationPeriod.SOS) && medication.isSOS && !medication.stopMedication;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'filterSOS', error.message);
  //   }
  // }

  // /* Expressão personalizada de filtro para Interromper Medicação. */
  // protected filterStopMedication(medication: IMedication, enPeriod: EnMedicationPeriod): boolean {
  //   try {
  //     return (enPeriod === EnMedicationPeriod.stop) && medication.stopMedication;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'filterStopMedication', error.message);
  //   }
  // }

  /* Exibe uma janela para permitir confirmar data e hora da medicação.
   * Se houver instruções de interrupção, apenas exibirá alerta.
   * Se o medicamento estiver em processo de salvamento, ou se já estiver salvo, não irá abrir.
   * isUpdateStockOnApp: indica se o estoque deve ser atualizado após o checkin.
   */
  showDialog(parMedication: IMedication, patient: IPatient, isUpdateStockOnApp: boolean): void {
    try {
      const medication = { ...parMedication };
      // const canCheckin = !this.wasTaken(medication) && !this.checkIsSaving(medication);
      const canCheckin = true; // Desativado para que seja possível sobrescrever um item já salvo.
      if (medication.stopMedication) {
        this.matDialogSrv.openDialogAlert(this.msg.STOP_MEDICATION);
      } else if (canCheckin) {
        medication.medicationDate = new Date();
        this.medication = medication;
        // this.patient = patient;
        const width = '100%';
        const height = '100vh';
        const dialogRef = this.matDialog.open(MedicationDialogComponent, {
          width,
          height,
          minWidth: width,
          minHeight: height,
          data: {
            medication,
            patient,
            width,
            height,
            isUpdateStockOnApp
          }
        });
        this.subs.sink = dialogRef.afterClosed()
          .subscribe(res => {
            try {
              if (res) {
                this.store.dispatch(confirmMedication(res));
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'showDialog.afterClose', error.message);
            }
          });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showDialog', error.message);
    }
  }

  onCheckinAll(checkinMedications: IMedication[], patient: IPatient, updateStock: boolean): void {
    try {
      if (checkinMedications?.length > 0) {
        const listOfNamesMedication = []
        let checkinFiltrado: IMedication[] = []
        const medicationNames = checkinMedications.map(m => m.medicationName).join(', ');
        checkinMedications.map(m => listOfNamesMedication.push({ name: m.medicationName, time: m.prescribedTime }))

        this.matDialogSrv.openDialogConfirm(`${this.msg.CONFIRM_ALL_MEDICATION} ${medicationNames}`, listOfNamesMedication)
          .subscribe(({ wasConfirmed, dialogSelecteds }) => {
            if (dialogSelecteds) {
              checkinFiltrado = checkinMedications.filter((elemento, indice) => dialogSelecteds.includes(indice));
            }
            if (wasConfirmed) {
              const medications = checkinFiltrado.map(medication => ({
                ...medication,
                medicationDate: new Date(),
                took: true,
                wasChanged: true,
              }));

              this.store.dispatch(confirmAllMedications({ medications, patient, observation: '', updateStock }));
            }

          },);
      }
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onCheckinAll', error.message);
    }
  }

  allMedicationsHaveBeenChecked(medications: IMedication[]): boolean {
    try {
      return medications?.length > 0 && medications.every(medication => !this.wasChecked(medication));
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'allMedicationsHaveBeenChecked', error.message);
    }
    return false;
  }

  /* Usuário confirma a ministração da medicação.
  * Tenta salvar na hora. Caso não consiga, enfilera.
  */
  // confirmMedication(item: { medication: IMedication, patient: IPatient, observation: string, updateStock: boolean }): void {
  //   try {
  //     if (item) {
  //       this.medication.took = item?.medication?.took;
  //       const checkin = this.mapToIMedicationCheckin(item?.medication, item?.patient, item?.observation);
  //       this.medication.status = checkin.status;
  //       // Necessário impedir o voltar caso esteja salvando, de forma online, ou perderá o item?.
  //       this.savingItems.push(item?.medication);
  //       // Pipe principal
  //       // FIXME: Transferir para effects e pegar o usuário do state
  //       this.subs.sink = this.medicationCheckinSrv
  //         .save(this.cadCheckMedicamentosNo, checkin, item?.patient, item?.medication, null, -1, true)
  //         .pipe(
  //           this.afterSaveMedication(item),
  //           this.stockChange(this.cadStockNo, item?.updateStock, item?.medication?.medicationId, item?.medication?.presentation === 'GOTAS' ? -item?.medication?.dailyQuantityGotas : -item?.medication?.dailyQuantity, this.msg.STOCK_CHANGE_BY_APP),
  //           this.error()
  //         ).subscribe();
  //     } else {
  //       this.medication.took = false;
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'confirmMedication', error.message);
  //     this.isLoading = false;
  //   }
  // }



  // /* Operador personalizado para realizar a baixa no estoque
  // * caso esteja configurado no idoso bem cuidado do cliente.
  // * updateStock: funciona como uma trava, se for false retornará false
  // * diretamente sem modificar o estoque.
  // */
  // protected stockChange = (cadStockNo: number, blUpdateStock: boolean, medicationId: number, quantity: number, description: string) => mergeMap((wasSaved: boolean) => {
  //   if (!blUpdateStock) {
  //     return of(false);
  //   }
  //   const showMessage = () => tap((success: boolean) => {
  //     const msg = success ? 'Realizada a baixa do item no estoque com sucesso!' : 'Houve falha na tentativa de baixar o estoque!';
  //     this.snackBar.open(msg, null, {
  //       duration: 2000,
  //       panelClass: 'snackbar'
  //     });
  //   });
  //   const updateStock = () => mergeMap((stock: IEstoqueMateriais) => {
  //     return stock && stock.codigo ?
  //       this.stockSrv.updateStockQuantity(stock.codigo, quantity, stock.ultimoLote, description) :
  //       of(false);
  //   });
  //   if (wasSaved && this.appPrv.enStockChangeMode === EnStockChangeMode.checkin) {
  //     return this.stockSrv
  //       .getByMedicationId(cadStockNo, medicationId)
  //       .pipe(
  //         updateStock(),
  //         showMessage(),
  //         defaultIfEmpty(false),
  //         this.error()
  //       );
  //   } else {
  //     return of(false);
  //   }
  // })

  // /* Irá verificar se o item está sendo salvo e o removerá da lista. */
  // protected removeIfIsSaving(medication: IMedication): void {
  //   try {
  //     const indexOf = this.savingItems.findIndex(f => +f.medicationId === +medication.medicationId);
  //     if (indexOf >= 0) {
  //       this.savingItems.splice(indexOf, 1);
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'removeIfIsSaving', error.message);
  //   }
  // }

  // /* Mapeio para o objeto necessário para salvar. */
  // mapToIMedicationCheckin(medication: IMedication, patient: IPatient, observation: string): IMedicationCheckin {
  //   try {
  //     // Atenção: para compor o ID é necessário considerar a data e hora prescrita e não do checkin, senão não será possível correlacionar corretamente como o item prescrito.
  //     const datePrescricao = moment(medication.medicationDate); // .format("DD/MM/YYYY");
  //     const horaPrescricao = medication.prescribedTime;
  //     const horaCheckin = moment(medication.medicationDate).format('HH:mm');
  //     const titulo = this.medicationCheckinSrv.generateMedicationTitle(patient, medication);
  //     const key = this.medicationCheckinSrv.generateKey(patient.codigo, medication, medication.medicationDate);
  //     const medicationCheckin = {
  //       cascataCheckIDClienteIDMedicamentoDataAtual: key,
  //       identificacao: key,
  //       horaPrescricao,
  //       diaPrescricao: datePrescricao.day().toString(),
  //       dataCheckin: medication.medicationDate,
  //       horaCheckin,
  //       status: medication.took ? 'S' : 'NJ',
  //       // O texto Tomou, que é necessário para que o campo seja considerado true, é redefinido na imedication-checkin
  //       observacoes: observation,
  //       statusmedicacao: titulo,
  //       // codigo: -1, O código não pode ser fornecido para que seja calculado automaticamente no serviço
  //       titulo,
  //       habilitado: true,
  //       codigoMedicamentoPaciente: medication.medicationId + '_' + patient.nomeHospede,
  //       profissional: this.cnf.usuarioLogadoNome,
  //       codigoPaciente: patient.codigo,
  //       Id_Paciente: patient.codigo,
  //       Id_Medicamento: medication.medicationId.toString(),
  //       IdMedicamento_IdPaciente: `${medication.medicationId}_${patient.codigo}`,
  //       tomou: medication.took
  //     } as IMedicationCheckin;
  //     return medicationCheckin;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'mapToIMedicationCheckin', error.message);
  //     return null;
  //   }
  // }

  /* Usuário desiste de registrar a medicação. */
  cancelMedication(event): void {
    try {
      // this.modalDialog.displayDialog = false;
      // this.msgs.push({ severity: 'error', summary: 'Cancelamento', detail: 'Registro cancelado pelo usuário.' });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cancelMedication', error.message);
    }
  }

  /* Ativa o relógio */
  protected setTimer(): void {
    try {
      setInterval(() => {
        this.currentTime = new Date();
      }, 1000);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setTimer', error.message);
    }
  }

  /* Click no botão voltar. */
  onBack($event: any): void {
    try {
      this.nav.gotoCardPatient();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  /* Indica se o paciente tomou/rejeitou a medicação.
  * Unifica o status que já vem do serviço com a possibilidade de ter sido marcado e ainda não sincronizado.
  * S: tomou
  * SH: tomou fora de horário.
  * Portanto, se for NJ, significa que o usuário não tomou, mas ainda não foi sincronizado.
  */
  wasTaken(medication: IMedication): boolean {
    try {
      // return !['N', '-'].includes(medication.status.toUpperCase());
      return ['S', 'SH'].includes(medication?.status?.toUpperCase() || '');
      // Não funcionou considerar a lista de checkins, pois, quando volta do diálogo ainda não é acrescentado item na lista.
      // const checkinsToDate = this.getCheckinsToDate(medication);
      //  ||
      //   (checkinsToDate?.pop()?.tomou === true || false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'wasTaken', error.message);
    }
  }

  /* Indica se foi realizado o checkin seja de tomou ou rejeitou.
  * A diferença do wasChecked para o wasTaken é que o wasChecked indica se o checkin foi realizado,
  * mas não importa se foi tomou ou rejeitou, ou seja, se o profissional marcou.
  * S: tomou
  * SH: tomou fora de horário.
  * NJ: Não tomou com justificativa (rejeitou).
  */
  wasChecked(medication: IMedication): boolean {
    try {
      const checkinsToDate = this.getCheckinsToDate(medication);
      return ['S', 'SH', 'NJ'].includes(medication?.status?.toUpperCase() || '') ||
        checkinsToDate?.length > 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'wasChecked', error.message);
    }
  }

  getCheckinObservation(medication: IMedication): string {
    try {
      const latestCheckinsToDate = this.getCheckinsToDate(medication).pop();
      return latestCheckinsToDate?.observacoes || '';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCheckinObservation', error.message);
    }
  }

  private getCheckinsToDate(medication: IMedication): IMedicationCheckin[] {
    try {
      return medication
        ?.lstCheckin
        ?.filter(f => this.glb.isEqualIgnoreTime(this.glb.getTypedValue(f.dataCheckin).value, this.currentTime))
        ?.sort((a, b) => a.medicationCheckinId - b.medicationCheckinId);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCheckinsToDate', error.message);
    }
    return [];
  }

  /* Retorna a dosagem, ou vazio se "Não Informado".  */
  getDosage(medication: IMedication): string {
    try {
      return this.glb.isEqual(medication.dosage, 'Não Informado') ? '' : medication.dosage;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDosage', error.message);
    }
  }

  /* Retorna a quantidade, ou vazio se "Não Informado".  */
  getQtd(medication: IMedication): any {
    try {
      return this.glb.isEqual(medication.dailyQuantity, 'Não Informado') ? '' : medication.dailyQuantity;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDosage', error.message);
    }
  }

  /* Retorna a apresentação da medicação, ou vazio se "Não Informado".  */
  getApresentacao(medication: IMedication): any {
    try {
      return this.glb.isEqual(medication.presentation, 'Não Informado') ? '' : medication.presentation;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDosage', error.message);
    }
  }

  /* Retorna as instruções, priorizando a de interrupção.  */
  getInstructions(medication: IMedication): string {
    try {
      if (medication.stopMedication) {
        return medication.stopInstructions && !this.glb.isEqual(medication.stopInstructions, 'Não Informado') ? medication.stopInstructions : this.msg.STOP_NO_ADDITIONAL_INSTRUCTIONS;
      } else {
        return medication.instruction && !this.glb.isEqual(medication.instruction, 'Não Informado') ? medication.instruction : '';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getInstructions', error.message);
    }
  }

  /* Checa se é o item atual que está sendo salvo. */
  checkIsSaving(medication: IMedication): boolean {
    try {
      // if (!this.isSaving) {
      //   return false;
      // }
      // if (this.savingItems && this.savingItems.length > 0) {
      //   const find = this.savingItems.find(f => +f.medicationId === +medication.medicationId);
      //   return find !== undefined;
      // }
      return medication?.isSaving;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isSaving', error.message);
    }
    return false;
  }

  protected error = () => catchError((e, res) => { // erro
    console.log(e);
    this.matDialogSrv.openDialogAlert('Erro ao salvar checkin!');
    return res;
  })

  onPatientSelected(patient: IPatient): void {
    try {
      if (patient?.codigo) {
        this.store.dispatch(setPatient({ selectedId: +patient.codigo }));
        this.reloadData();
      }
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onPatientSelected', error.message);
    }
  }

  conferPeriod(period: IMedicationPeriod) {
    let filtrado = period?.medications?.filter((medication) => {
      return medication.status === 'S';
    })
    return filtrado.length != 0 ? "disable" : ""
  }

  getPeriodClass(period: IMedicationPeriod) {
    let classe: string = period?.styleCss || 'default';
    let disableClass: string = this.conferPeriod(period);

    // Adiciona um espaço antes de "disable" apenas se a classe não for vazia
    if (disableClass) {
      classe += " " + disableClass;
    }
    return classe;
  }

  functionSelect(event) {
    if (event.value == "Todos") {
      this.showMedication = 0
    }

    if (event.value == "Cuidados") {
      this.showMedication = 2
    }

    if (event.value == "Medicamentos") {
      this.showMedication = 1
    }
  }

  getMedicationsByType(type: number, period: IMedicationPeriod): IMedication[] {
    return type == 0
      ? period?.medications
      : type == 1
        ? period?.exceptCuidados
        : period?.cuidadosMedication
  }
}
