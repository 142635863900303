export interface IDocumento {
  AtividadeNo: number;
  AtividadeNome: string;
  CaminhoFisico: string;
  ConfiguracaoAnexo: number;
  DocNo: string;
  DocumentoNo: number;
  DocumentoNome: string;
  DocumentoNomeExibicao: string;
  DtCriacao: Date;
  DtModificacao: Date;
  Extensao: string;
  NotForEmail: boolean;
  OcorrenciaNo: number;
  SwfUrl: string;
  Tamanho: number;
  TipoDocumentoNo: number;
  TipoDocumentoNome: string;
  Url: string;
  UsuarioNo: number;
  Versao: number;
}
