import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { initialStateStock } from './stock.reducer';
import {
  addStock, addStocks, clearStocks,
  deleteStock, deleteStocks, loadStocks,
  loadStocksBySelectedPatient,
  loadStocksSuccess,
  updateStock, updateStocks, upsertStock, upsertStocks
} from './stock.actions';

const KEY = 'MEDLOGIC_STOCK_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  loadStocks,
  loadStocksSuccess,
  loadStocksBySelectedPatient,
  addStock,
  upsertStock,
  addStocks,
  upsertStocks,
  updateStock,
  updateStocks,
  deleteStock,
  deleteStocks,
  clearStocks
].map(m => m.type.toString());

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialStateStock);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
