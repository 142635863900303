import { IssuerIdentification } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IInterventionMedication, IPrescriptionMedication } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';

export enum PrescriptionActionTypes {
  LoadPrescription = '[Prescription Component] Load Prescription',
  SavePrescription = '[Prescription Component] Save Prescription',
  SavePrescriptionServerSide = '[Page Prescription Component] Save Prescription on Server Side',
  UpdatePrescription = '[Prescription Component] Update Prescription',
  UpdatePrescriptionMedications = '[Stock Effects] Update Medications of Prescription',
  DeletePrescriptionMedication = '[Medication Effects] Delete Medication of Prescription',
  FetchSuccessPrescription = '[Prescription API] Fetch Success Prescription',
  FetchErrorPrescription = '[Prescription API] Fetch Error Prescription',
  SaveSucessPrescription = '[Prescription Component] Save Succes Prescription',
  SaveFailPrescription = '[Prescription Component] Save Fail Prescription',
  SetPrescription = '[Prescription Component] Set Prescription',
  SetPrescriptionCreateIfNotExistsAndLoadMedications = '[Page Prescription Component] Set Prescription, Create it if not exists',
  SetPrescriptionCostCenter = '[Prescription Component] Set Prescription CostCenter',
  // SelectPrescription = '[Prescription Component] Select Prescription',
  // UpdatePrescription = '[Prescription Component] Update Prescription',
  GetByDate = '[Code Validation Component] Get By Code',
  FetchSuccessPrescriptionByCode = '[Prescription API] Fetch Success Prescription By Code',
  ClearPrescription = '[Page Prescription Component] Clear Prescription',

  LoadIssuer = '[Prescription Print Component] Load Issuer', // Dados do prescritor/instituição para receitas controladas
  SetIssuer = '[Prescription Print Component] Set Issuer',
}

export const loadPrescription = createAction(
  PrescriptionActionTypes.LoadPrescription,
  props<{ dtStart: Date, numOfDays: number }>()
);
export const getPrescriptionsByDate = createAction(PrescriptionActionTypes.GetByDate, props<{ dtStart: Date, numOfDays: number }>());
export const clearPrescription = createAction(PrescriptionActionTypes.ClearPrescription);

export const savePrescription = createAction(PrescriptionActionTypes.SavePrescription);
export const savePrescriptionServerSide = createAction(PrescriptionActionTypes.SavePrescriptionServerSide);

export const updatePrescription = createAction(PrescriptionActionTypes.UpdatePrescription, props<{ medication: IInterventionMedication }>());
export const updatePrescriptionMedications = createAction(PrescriptionActionTypes.UpdatePrescriptionMedications, props<{ medications: IInterventionMedication[] }>());
export const deletePrescriptionMedication = createAction(PrescriptionActionTypes.DeletePrescriptionMedication, props<{ ono: number }>());

export const setPrescription = createAction(PrescriptionActionTypes.SetPrescription, props<{ prescription: IPrescriptionMedication }>());
export const setPrescriptionCreateIfNotExistsAndLoadMedications = createAction(PrescriptionActionTypes.SetPrescriptionCreateIfNotExistsAndLoadMedications, props<{ prescription: IPrescriptionMedication }>());
export const setPrescriptionCostCenter = createAction(PrescriptionActionTypes.SetPrescriptionCostCenter, props<{ centroCusto: string }>());
export const fetchSuccessPrescription = createAction(PrescriptionActionTypes.FetchSuccessPrescription, props<{ allPrescriptions: IPrescriptionMedication[] }>());
// export const selectPrescription = createAction(PrescriptionActionTypes.SelectPrescription, props<{ selectedPrescription: IListItem }>());
export const fetchErrorPrescription = createAction(PrescriptionActionTypes.FetchErrorPrescription);
// export const updatePrescription = createAction(PrescriptionActionTypes.UpdatePrescription, props<{ task: IListItem }>());
// export const fetchSuccessPrescriptionByCode = createAction(PrescriptionActionTypes.FetchSuccessPrescriptionByCode,
// props<{ selectedPrescription: IListItem }>());

export const saveSuccessPrescription = createAction(PrescriptionActionTypes.SaveSucessPrescription, props<{ prescription: IPrescriptionMedication, showMessage: boolean }>());
export const saveFailPrescription = createAction(PrescriptionActionTypes.SaveFailPrescription, props<{ prescription: IPrescriptionMedication }>());

export const loadIssuer = createAction(PrescriptionActionTypes.LoadIssuer);
export const setIssuer = createAction(PrescriptionActionTypes.SetIssuer, props<{ issuer: IssuerIdentification }>());