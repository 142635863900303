// ***** Tenant: Representa um ambiente do MedLogic. Normalmente, um ambiente por cliente
// *** customerId: Em alguns casos, multiplos clientes estão no mesmo ambiente (tenant). Nesse caso, preencher customerId.
// export interface ITenantState {
//   loggedUserName: string;
//   userRole: EnUserRole;
//   tenantId: number;
//   enTheme: EnTheme;
//   selectedTenant: ITenant;
//   // Atenção: não deve ser confundido com selectedTenant.customerAno que representa a AtividadeNo.
//   customerId: number; // Para aplicações multi-customers no mesmo ambientes (mesmo tenant) esse valor deverá ser definido.
//   identification1: any;
//   identification2: any;
//   token: string;
//   isDebug: boolean;
// }

export interface IListItem {
  id: any;
  identification1?: any;
  identification2?: any;
  topLeft?: string;
  bottomLeft?: string;
  topRight?: string;
  bottomRight?: string;
  color?: any;
  imgUrl?: string;

  // opcionais
  ono?: number;
  tno?: number;
  ano?: number;
  title?: string;
  relationshipId?: number;
}

// export interface ITenant {
//   genericAno?: number;
//   genericIdentification?: Array<IListItem>;
//   customerAno?: number;
//   employeeAno?: number;
//   schoolAno?: number;
//   covidAno?: number;
//   enTheme?: EnTheme;
//   customerPno?: number;
//   covidPno?: number;
//   employeePno?: number;
//   schoolPno?: number;
//   prescriptionAno?: number;
//   prescriptionPno?: number;
//   userName: string;
// }

export interface ISchool {
  ocorrenciaNo: number;
  codigo: any;
  nomeescola: any;
  habilitado: boolean;
  uF: string;
  cIdade: any;
}
