import { IFhirPatient } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setFhirPatient = createAction(
  '[FhirPatient/API] Set FhirPatient',
  props<{ selectedId: number }>()
);
export const loadFhirPatients = createAction(
  '[FhirPatient/API] Load FhirPatients'
);

export const loadFhirPatientsSuccess = createAction(
  '[FhirPatient/API] Load FhirPatients Success',
  props<{ patients: IFhirPatient[] }>()
);

export const patientFail = createAction(
  '[FhirPatient/API] FhirPatient Fail',
  props<{ error: string }>()
);

export const addFhirPatient = createAction(
  '[FhirPatient/API] Add FhirPatient',
  props<{ patient: IFhirPatient }>()
);

export const addFhirPatients = createAction(
  '[FhirPatient/API] Add FhirPatients',
  props<{ patients: IFhirPatient[] }>()
);

export const upsertFhirPatient = createAction(
  '[FhirPatient/API] Upsert FhirPatient',
  props<{ patient: IFhirPatient }>()
);

export const upsertFhirPatients = createAction(
  '[FhirPatient/API] Upsert FhirPatients',
  props<{ patients: IFhirPatient[] }>()
);

export const updateFhirPatient = createAction(
  '[FhirPatient/API] Update FhirPatient',
  props<{ patient: Update<IFhirPatient> }>()
);

export const updateFhirPatients = createAction(
  '[FhirPatient/API] Update FhirPatients',
  props<{ patients: Update<IFhirPatient>[] }>()
);

export const deleteFhirPatient = createAction(
  '[FhirPatient/API] Delete FhirPatient',
  props<{ id: string }>()
);

export const deleteFhirPatients = createAction(
  '[FhirPatient/API] Delete FhirPatients',
  props<{ ids: string[] }>()
);

export const clearFhirPatients = createAction(
  '[FhirPatient/API] Clear FhirPatients'
);
export const clearSelectedFhirPatient = createAction(
  '[Card FhirPatient] Clear Selected FhirPatients'
);
