import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IVideo } from '@medlogic/shared/shared-interfaces';
import { map, toArray, concatMap, publishReplay, refCount, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  // static_videos = [
  //   {
  //     id: 1,
  //     src: './assets/videos/Apresentação-Inicial.mp4',
  //     name: 'Medlogic: Apresentação',
  //     description: 'Medlogic é uma plataforma'
  //       + ' completa e nesse vídeo conhecemos um pouco como ele funciona.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 2,
  //     src: './assets/videos/Módulo-Admissão.mp4',
  //     name: 'Admissão',
  //     description: 'Módulo onde tudo começa, responsável por cadastrar nossos idosos nos sistema.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 3,
  //     src: './assets/videos/Módulo-IVCF20.mp4',
  //     name: 'IVCF20',
  //     description: 'O Índice de Vulnerabilidade Clínico-Funcional (IVCF-20) é essêncial para um cuidado'
  //       + ' mais humanizado e preciso.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 4,
  //     src: './assets/videos/Módulo-LivroDeVisita.mp4',
  //     name: ' Livro de Visitas',
  //     description: 'Módulo onde registramos aquelas pessoas que visitam nossos idosos.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 5,
  //     src: './assets/videos/Módulo-AvaliaçãoInterdisciplinar.mp4',
  //     name: 'Avaliação Interdisciplinar',
  //     description: 'É aqui que iremos gerar o Plano de Cuidados do nosso idoso. E utilizar protocolos de diferentes especialidades médicas.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 6,
  //     src: './assets/videos/Módulo-Prescrição.mp4',
  //     name: 'Prescrição',
  //     description: 'Módulo usado para registrar os medicamentos prescritos.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 7,
  //     src: './assets/videos/Módulo-EstoqueDeMateriais.mp4',
  //     name: 'Estoque de Materiais',
  //     description: 'Em Estoque de Materiais iremos fazer o controle de nosso estoque.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 8,
  //     src: './assets/videos/Módulo-Financeiro.mp4',
  //     name: 'Financeiro',
  //     description: 'Um módulo completetamente desenvolvido para que se tenha controle total das finanças.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 9,
  //     src: './assets/videos/AppMedlogic-GERAL-RESUMIDO.mp4',
  //     name: 'App Medlogic: Apresentação Geral',
  //     description: 'Uma breve apresentação de nosso aplicativo. A rotina do seu residencial na palma da sua mão.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 10,
  //     src: './assets/videos/AppMedlogic-GERAL-ComMódulos.mp4',
  //     name: 'App Medlogic: Funcionalidades',
  //     description: 'Apresetnação completa de nosso aplicativo! Conheça cada uma das funcionalidades desse App fantástico.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 11,
  //     src: './assets/videos/Módulo-MapaDeCuidado-GERAL.mp4',
  //     name: 'Mapa de Cuidados: Apresentação Geral',
  //     description: 'Módulo de visualização de várias informações importantes e geração de relatórios.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 12,
  //     src: './assets/videos/Módulo-MapaDeCuidado-MapaDeMedicamentos.mp4',
  //     name: 'Mapa de Medicação',
  //     description: 'Acompanhemento dos registros (checkin) de medicamentos realizados pelo aplicativo.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 13,
  //     src: './assets/videos/Módulo-MapaDeCuidado-Aprazamento.mp4',
  //     name: 'Aprazamento',
  //     description: 'Acompanhamento de checkin por período e possibilidade de impressão.',
  //     type: EnVideoType.mp4,
  //     group: 'Assistencial'
  //   },
  //   {
  //     id: 14,
  //     src: './assets/videos/Módulo-MapaDeCuidado-Evolução.mp4',
  //     name: 'Evolução',
  //     description: 'Geração de relatórios e visualização das informações registradas no aplicativo'
  //       + ' de modo geral e individual.',
  //     type: EnVideoType.mp4,
  //     group: 'Financeiro'
  //   },
  //   {
  //     id: 15,
  //     src: './assets/videos/Módulo-MapaDeCuidado-Separação.mp4',
  //     name: 'Separação',
  //     description: 'De maneira simples e ágil é possível fazer a separação de um medicamento já realizando'
  //       + ' o desconto em seu Estoque.',
  //     type: EnVideoType.mp4,
  //     group: 'Financeiro'
  //   },
  //   {
  //     id: 16,
  //     src: './assets/videos/Módulo-MapaDeCuidado-Prescrição.mp4',
  //     name: 'Prescrição',
  //     description: 'Módulo criado para auxiliar a equipe médica possibilitando a criação'
  //       + ' de uma prescrição com dois clicks.',
  //     type: EnVideoType.mp4,
  //     group: 'Financeiro'
  //   }
  // ];
  constructor(private http: HttpClient) { }

  getAll(): Observable<IVideo[]> {
    // return of(this.static_videos);
    const res = this.http.get<IVideo>('./assets/tutorial.json').pipe(mergeMap(async (f) => f as unknown as IVideo[]), publishReplay(), refCount());
    res.subscribe(s => console.log(s));
    return res;
  }


}
