import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { PersonActionTypes } from './person.actions';
import { initialState } from './person.reducer';

const KEY = 'MEDLOGIC_PERSON_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  PersonActionTypes.LoadPersons,
  PersonActionTypes.SetPerson,
  PersonActionTypes.LoadAllPersonRelationships,
];

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialState);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
