import { IConta } from './iconta';

export interface ICliente {
  ClienteNo: number;
  ClienteNome: string;
  RazaoSocial: string;
  CNPJ: string;
  InscricaoEstadual: string;
  Endereco: string;
  Numero: string;
  Complemento: string;
  Bairro: string;
  Cidade: string;
  CEP: string;
  EstadoNo: number;
  Referencia: string;
  Telefone: string;
  Fax: string;
  Contato: string;
  CelularContato: string;
  Email: string;
  blInativo: boolean;
  OrderNo: number;
  Internet: string;
  Observacao: string;
  dtCadastro: Date;
  dtModificacao: Date;
  dtUltimoAcesso: Date;
  TotalAcesso: number;
  Pais: string;
  ContaNo: number;
  blFullAdm: boolean;
  ObjConta: IConta;
  TotalOcorrencia: number;
}
