import { Pipe, PipeTransform } from '@angular/core';
import { LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { IBasic } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../service/lib.service';

@Pipe({
  name: 'libVisibleTabs'
})
export class VisibleTabsPipe implements PipeTransform {
  constructor(private log: LogService, private lib: LibService) { }

  transform(tabs: Array<IBasic>, componentes: Array<IAtividadeComponenteDAL>): Array<IBasic> {
    try {
      if (!tabs) {
        return [];
      }
      return tabs.filter(f => this.lib.isTabVisible(componentes, f));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return null;
  }
}
