<mat-form-field>
  <input matInput class="date" [matDatepicker]="picker" placeholder="Selecione uma data" [(ngModel)]="date">
  <mat-datepicker-toggle class="date" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker class="date" #picker></mat-datepicker>
</mat-form-field>

<mat-table [dataSource]="estoqueMateriais" class="mat-elevation-z8">

  <ng-container matColumnDef="itens">
    <mat-header-cell *matHeaderCellDef> Item </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.itens }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="estoque">
    <mat-header-cell *matHeaderCellDef> Estoque </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ (element.estoque || 0) | number:'3.2-2':'pt' }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="unidademedida">
    <mat-header-cell *matHeaderCellDef> Unidade Medida </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.unidademedida }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="uso">
    <mat-header-cell *matHeaderCellDef> Utilização </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <!-- <mat-slider #slider id="slider" min="0" class="slider" [max]="element.estoque" step="0.5" [(value)]="element.uso" (change)="onUsoChange(element, slider)"></mat-slider> -->
      <input type="text" numberInput [allowDecimal]="true" decimalSymbol="," [maxValue]="element.estoque" #useInput class="useInput" [(ngModel)]="element.uso" (change)="onUsoChange(element)" (click)="useInput.select()">
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="estoqueRemanescente">
    <mat-header-cell *matHeaderCellDef> Novo estoque </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class="estoqueRemanescente" [class.red]="element.estoqueRemanescente <= element.estoqueMinimo">{{ element.estoqueRemanescente | number:'3.2-2':'pt' }}</span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<button mat-raised-button class="btn" color="primary" (click)="onConfirmClick($event)">Confirmar</button>