import { EnEmailMessageStatus, GlobalService, IEmailMessage, IUtilsEmailState } from '@medlogic/shared/shared-interfaces';
import { EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as EmailsActions from './state-utils-email.actions';

export const utilsEmailFeatureKey = 'utilsEmail';

const glb = new GlobalService();

const getUpdate = (message: IEmailMessage, enEmailMessageStatus: EnEmailMessageStatus): Update<IEmailMessage> => ({ changes: {...message, dtSend: new Date(), enEmailMessageStatus }, id: message?.id || glb.getGUID() });
const getItem = (message: IEmailMessage):IEmailMessage => ({ ...message, id: message?.id || glb.getGUID() });

export const adapterEmail: EntityAdapter<IEmailMessage> = createEntityAdapter<IEmailMessage>({
  selectId: instance => instance?.id
});

export const initialStateEmail: IUtilsEmailState = adapterEmail.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: undefined,
  selectedEmail: undefined,
  allEmails: undefined
});

export const reducer = createReducer(
  initialStateEmail,
  on(EmailsActions.sendEmail,
    (state, { message }) => adapterEmail.addOne(getItem(message), state)
  ),
  on(EmailsActions.sendEmailFail,
    (state, { error }) => ({ ...state, error })
  ),
  on(EmailsActions.sendEmailSuccess,
    (state, { message }) => adapterEmail.updateOne(getUpdate(message, EnEmailMessageStatus.success), state)
  ),
  on(EmailsActions.cleanEmails, () => ({ ...initialStateEmail }))
);

// {
//   ...message,
//     dtSend: new Date(),
//     enEmailMessageStatus: EnEmailMessageStatus.error
//   } as Update<IEmailMessage>

// {update: { id: action.update.id, changes: message }})

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterEmail.getSelectors();
