import { EmptyItemsComponent } from './empty-items/empty-items.component';
import { MaterialModule } from '@medlogic/shared/material';
import { LoadingComponent } from './loading/loading.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoadingLetteringComponent } from './loading-lettering/loading-lettering.component';
import { EmptyComponent } from './empty/empty.component';

const COMPONENTS = [
  LoadingComponent,
  LoadingSpinnerComponent,
  LoadingLetteringComponent,
  EmptyComponent,
  EmptyItemsComponent
];

const MODULES = [
  CommonModule,
  MaterialModule
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [MODULES],
  exports: [COMPONENTS]
})
export class UiLoadingModule { }
