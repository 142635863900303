import { filterShowMobileAndModulesMenu } from '@medlogic/shared/utils';
import { EnOrdemProcesso } from '@medlogic/shared/shared-interfaces';
import { routeGetSubProcessByUser, error } from '@medlogic/shared/shared-interfaces';
import { EnModuleMode } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import {
  LogService, IConfigModule, ConfigJsonService,
  IModule, EnModulo, IModuleInfo
} from '@medlogic/shared/shared-interfaces';
import { BasePageService } from './base-page.service';
import { Injectable } from '@angular/core';
import { IProcess } from '@medlogic/shared/shared-interfaces';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { switchMap, map, filter, flatMap } from 'rxjs/operators';
import { EnContentType } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { EnAgrupamentoProcessos } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';

@Injectable()
export class ProcessService {

  ORDERNO_TO_HIDE_PROCESS = 99;
  ORNERNO_TO_HIDE_MENU = 3;
  ORDERNO_TO_SHOW_MENU = 2;
  BASE_URL_WEBAPI = '';

  // TODO: API
  // private urlGetSubProcessByUser = `Process/GetSubProcessByUser?ocorrenciaNo={0}`;
  private urlGetSubProcessByUser = `${routeGetSubProcessByUser}?ocorrenciaNo={0}`;
  private processByUser = new Array<IProcess>();
  private modulos = new Array<IModule>();
  private baseUrl: string;
  private userName: string;
  private propsAtividadeResumidaByProcesso: any[];
  private processIsModule: IProcess[];
  private processNotModule: IProcess[];
  private processLoaded = false;
  private activityLoaded = false;
  private isLoading = false;

  constructor(
    private basepage: BasePageService,
    private cnfJson: ConfigJsonService,
    private cnf: ConfigStateService,
    private log: LogService,
    private glb: GlobalService
    // private activitSrv: ActivityService
  ) { }

  /* Resgata os dados dos processos que o usuário pode acessar.
  * Inclusive o id das respectivas primeiras atividades.
  * Retornará os processos que são módulos.
  */
  getDataProcess(isMockup: boolean = false, type: string = ''): Observable<IModule> {
    try {
      if (isMockup) {
        return this.getDataProcessMockup();
      }
      this.isLoading = true;
      const afterLoaded = () => tap((p: any) => {
        this.processLoaded = true;
        this.isLoading = false;
      });
      switch (type) {
        case 'MENU':
          return this.getSubProcessByUser(-1)
            .pipe(
              // this.getDataAtividades(),
              this.loadProcessFilter(),
              this.filterShowMobileAndModulesMenu$(),
              this.processToModule(),
              afterLoaded(),
              this.error()
            );
        case 'HTML5':
          return this.getSubProcessByUser(-1)
            .pipe(
              // this.getDataAtividades(),
              this.loadProcessFilter(),
              this.filterShowMobileAndModulesHTML5(this.cnfJson.modules),
              this.processToModule(),
              afterLoaded(),
              this.error()
            );
        default:
          return this.getSubProcessByUser(-1)
            .pipe(
              // this.getDataAtividades(),
              this.loadProcessFilter(),
              this.filterShowMobileAndModules(this.cnfJson.modules),
              this.processToModule(),
              afterLoaded(),
              this.error()
            );
      }

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDataProcess', error.message);
    }
  }

  /* Retorna a lista de processos que um usuário tem permissão para acessar.
  * O id da primeira atividade de cada processo também é retornada.
  */
  getSubProcessByUser(ocorrenciaNo: number = -1): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGetSubProcessByUser, ocorrenciaNo);
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {}, 2, EnContentType.Json)
        .pipe(
          flatMap(f => f),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSubProcessByUser', error.message);
    }
  }

  private error = () => catchError((err, obs) => {
    console.log(err);
    this.isLoading = false;
    return of(err);
  });

  private getDataProcessMockup(): Observable<IModule> {
    try {
      return of([
        {
          UrlIcon: '',
          Label: 'Módulo I',
          Type: EnModulo.Processo,
          ObjInfo: ({
            AtividadeNo: 1,
            ProcessoNo: 1,
            UsuarioLogadoNo: 1,
            userName: 'userName',
            baseUrl: 'baseUrl'
          } as IModuleInfo),
          LabelMiniInfo: 'este é o mI',
          enModuleMode: EnModuleMode.singleRecord,
          IsActive: true,
          ComplementaryVar1: 1111,
          ComplementaryVar2: 2222,
          ComplementaryVar3: 3333,
          ComplementaryVar4: 4444,
          ComplementaryVar5: 5555
        } as IModule,
        {
          UrlIcon: '',
          Label: 'Módulo II',
          Type: EnModulo.Processo,
          ObjInfo: ({
            AtividadeNo: 1,
            ProcessoNo: 1,
            UsuarioLogadoNo: 1,
            userName: 'userName',
            baseUrl: 'baseUrl'
          } as IModuleInfo),
          LabelMiniInfo: 'este é o mII',
          enModuleMode: EnModuleMode.singleRecord,
          IsActive: true,
          ComplementaryVar1: 1111,
          ComplementaryVar2: 2222,
          ComplementaryVar3: 3333,
          ComplementaryVar4: 4444,
          ComplementaryVar5: 5555
        } as IModule,
        {
          UrlIcon: '',
          Label: 'Módulo III',
          Type: EnModulo.Processo,
          ObjInfo: ({
            AtividadeNo: 1,
            ProcessoNo: 1,
            UsuarioLogadoNo: 1,
            userName: 'userName',
            baseUrl: 'baseUrl'
          } as IModuleInfo),
          LabelMiniInfo: 'este é o mIII',
          enModuleMode: EnModuleMode.singleRecord,
          IsActive: true,
          ComplementaryVar1: 1111,
          ComplementaryVar2: 2222,
          ComplementaryVar3: 3333,
          ComplementaryVar4: 4444,
          ComplementaryVar5: 5555
        } as IModule,
        {
          UrlIcon: '',
          Label: 'Módulo IV',
          Type: EnModulo.Processo,
          ObjInfo: ({
            AtividadeNo: 1,
            ProcessoNo: 1,
            UsuarioLogadoNo: 1,
            userName: 'userName',
            baseUrl: 'baseUrl'
          } as IModuleInfo),
          LabelMiniInfo: 'este é o mIV',
          enModuleMode: EnModuleMode.singleRecord,
          IsActive: true,
          ComplementaryVar1: 1111,
          ComplementaryVar2: 2222,
          ComplementaryVar3: 3333,
          ComplementaryVar4: 4444,
          ComplementaryVar5: 5555
        } as IModule,
      ]).pipe(
        flatMap(f => f)
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDataProcessMockup', error.message);
    }
    return of(null);
  }

  // getDataAtividades = () => (processByUser: Array<IProcess>) => {
  //   try {
  //     if (processByUser && processByUser.length > 0) {
  //       const arrPrimeirasAtividades = processByUser.map((process) => process.PrimeiraAtividadeNo);
  //       const strArrPrimeiraAtividade = arrPrimeirasAtividades.toString();
  //       return this.activitSrv
  //         .GetListActivityEnabledByProccess(strArrPrimeiraAtividade)
  //         .pipe(
  //           // (result) => {
  //           //     const groupAtividade = _.groupBy(result, 'ProcessoNo');
  //           //     this.propsAtividadeResumidaByProcesso = _.map(groupAtividade, function (group: any) {
  //           //       return {
  //           //         ProcessoNo: group[0].ProcessoNo,
  //           //         items: group
  //           //       } as IProcessGroup;
  //           //     });
  //           // },
  //           // this.activityLoaded = true;
  //         );
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getDataAtividades', error.message);
  //   }
  // }

  /* Operador personalizado que converte os processos em módulos. */
  private processToModule = () => map((process: IProcess) => {
    if (process?.IconNo) {
      process.urlIcon = `${this.cnfJson.baseUrlIconProcess}${process?.IconNo}.png`;
    } else {
      process.urlIcon = `${this.cnfJson.baseUrlIntranet}Content/imagens/assignment_white.png`;
    }
    const module = {
      Label: process?.ProcessoNome,
      UrlIcon: process?.urlIcon,
      Type: EnModulo.Processo,
      OrderNo: process?.OrderNo,
      IsActive: !process?.blInativo,
      ComplementaryVar1: process?.VariavelComplementar1No,
      ComplementaryVar2: process?.VariavelComplementar2No,
      ComplementaryVar3: process?.VariavelComplementar3No,
      ComplementaryVar4: process?.VariavelComplementar4No,
      ComplementaryVar5: process?.VariavelComplementar5No,
      ObjInfo: {
        AtividadeNo: process?.PrimeiraAtividadeNo,
        ProcessoNo: process?.ProcessoNo,
        UsuarioLogadoNo: this.cnf.usuarioLogadoNo,
        userName: this.cnf.usuarioNome,
        baseUrl: this.cnfJson.baseUrl
      } as IModuleInfo,
      ObjProcess: process
    } as IModule;
    return module;
  })

  /* Operador personalizado para separar Módulos de Processos vinculados a um usuário. */
  private loadProcessFilter = () => switchMap((processByUser: IProcess): Observable<IProcess> => {
    processByUser.isModule = (
      processByUser?.AgrupamentoProcessosNo === EnAgrupamentoProcessos.Individual ||
      processByUser?.AgrupamentoProcessosNo === EnAgrupamentoProcessos.Todos
    );
    processByUser.showOnMobile = (
      processByUser?.AgrupamentoProcessosNo === EnAgrupamentoProcessos.VariaveisIndentificacao ||
      processByUser?.AgrupamentoProcessosNo === EnAgrupamentoProcessos.Todos
    );
    return of(processByUser);
  })

  /* Operador que irá mostrar apenas os processos que devem ser exibidos nos dispositivos
  * móveis (configuração de Agrupamento no BD), mas também que não tenham restrição no config. */
  private filterShowMobileAndModules = (modules: IConfigModule[]) => filter((processByUser: IProcess): boolean => {
    const module = modules.find(f =>
      this.glb.isEqual(f?.name, processByUser?.ProcessoNome)
    );
    const canCreate = module?.canCreate || true;
    const canRead = module?.canRead || true;
    return processByUser?.showOnMobile &&
      (canCreate || canRead) &&
      !processByUser?.blInativo &&
      (processByUser?.OrderNo !== this.ORDERNO_TO_HIDE_PROCESS) &&
      (processByUser?.OrderNo != this.ORNERNO_TO_HIDE_MENU);
  })


  /* Operador que irá mostrar apenas os processos que devem ser exibidos nos dispositivos
* móveis (configuração de Agrupamento no BD), mas também que não tenham restrição no config. */
  private filterShowMobileAndModulesHTML5 = (modules: IConfigModule[]) => filter((processByUser: IProcess): boolean => {
    const module = modules.find(f =>
      this.glb.isEqual(f?.name, processByUser?.ProcessoNome)
    );
    const canCreate = module?.canCreate || true;
    const canRead = module?.canRead || true;
    return processByUser?.showOnMobile &&
      (canCreate || canRead) &&
      !processByUser?.blInativo &&
      (processByUser?.OrderNo !== this.ORDERNO_TO_HIDE_PROCESS)
  })

  /* Operador que irá mostrar apenas os processos que devem ser exibidos nos dispositivos
  * móveis (configuração de Agrupamento no BD), mas também que não tenham restrição no config. */
  // private filterShowMobileAndModulesMenu = () => filter((processByUser: IProcess): boolean => {
  //   // return !processByUser?.blInativo &&
  //   //   (processByUser?.OrderNo === this.ORDERNO_TO_SHOW_MENU);
  //   return !processByUser?.blInativo &&
  //     [EnAgrupamentoProcessos.Individual, EnAgrupamentoProcessos.Todos].includes(processByUser?.AgrupamentoProcessosNo) &&
  //     [EnOrdemProcesso.SomenteSistemaEObedeceAgrupamento, EnOrdemProcesso.SistemaEAppEObedeceAgrupamento].includes(processByUser?.OrderNo);
  // })
  private filterShowMobileAndModulesMenu$ = () =>
    filter((processByUser: IProcess): boolean =>
      filterShowMobileAndModulesMenu(processByUser)
    );

}
