import { deleteById } from '@medlogic/shared/utils';
import { IServiceProvider, IForm, error } from '@medlogic/shared/shared-interfaces';
import { IExameResultado } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Observable } from 'rxjs';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { LocalLibService } from '@medlogic/shared/shared-interfaces';
import { publishReplay } from 'rxjs/operators';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import {
  GlobalService,
  LogService,
  ConfigJsonService,
} from '@medlogic/shared/shared-interfaces';

export abstract class ExameResultadoService implements IServiceProvider {

  private lstVariaveis = 'V_2230,V_28595,V_28610,V_28611,V_28612,V_28613,V_28614,V_28615,V_28616,V_28617,V_28618,V_28619,V_28620,V_28621,V_28622,V_28624,V_28626,V_28668,V_28669,V_28680,V_28691,V_28693,V_28694,V_28695,V_28696,V_28697,V_28698,V_28777,V_28598';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IExameResultado[] = new Array<IExameResultado>();

  codigoVariavelNo = 28610; // 28626; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 2264;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigJsonService,
    protected lib: LocalLibService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IExameResultado> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IExameResultado[]> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate)
        .pipe(
          toArray(),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IExameResultado> {
    try {
      if (
        (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
        || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
        || (!this.cadastrosCache)
        // (startDate.getTime() !== this.currentDtInicial.getTime())
        // || (endDate.getTime() !== this.currentDtFinal.getTime())
        // || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables, startDate, endDate)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected mapTo = () => map((c: any) => {
    return {
      ocorrenciaNo: c.OcorrenciaNo,
      codigoPaciente: c.V_2230,
      dataExame: this.glb.getTypedValue(c.V_28595).value,
      codigo: c.V_28610,
      titulo: c.V_28611,
      habilitado: this.lib.getBoolean(c.V_28612),
      categoria: c.V_28613,
      exame: c.V_28614,
      resultado: c.V_28615,
      laudo: c.V_28616,
      valorreferenciaMaximo: c.V_28617,
      valorreferenciaMinimo: c.V_28618,
      alertaMaior: c.V_28619,
      rASTREAMENTO: c.V_28620,
      codPacCAT: c.V_28621,
      frequenciaCardiaca: c.V_28622,
      alertaMenor: c.V_28624,
      oCORRENCIA: c.V_28626,
      mAXIMO: c.V_28668,
      mINIMO: c.V_28669,
      altoOuBaixo: c.V_28680,
      nORMAIS: c.V_28691,
      alertaNormal: c.V_28693,
      alto: c.V_28694,
      baixo: c.V_28695,
      normal: c.V_28696,
      mSG1: c.V_28697,
      mSG2: c.V_28698,
      codPacOcorrenciaCAT: c.V_28777,
      idExame: c.V_28598
    } as IExameResultado;
  })

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
  * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date = null, endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IExameResultado> {
    try {
      this.cadastroSrv.dtInicial = startDate ? this.glb.dateToYYYYMMddThhmmss(startDate) : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd, startDate, endDate)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  save<T = IExameResultado>(ano: number, resultado: T, uno: number, id?: number): Observable<T> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(resultado as unknown as IExameResultado).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo)
        .pipe(
          map(newId => {
            return ({ ...resultado, codigo: newId, ocorrenciaNo: newId } as T);
          }),
          error()
        )
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  deleteById(ano: number, id: number): Observable<number> {
    try {
      this.cadastroNo = ano;
      return this.cadastroSrv.deleteCadastro(this.cadastroNo, id)
        .pipe(
          map(deletedId => {
            return deletedId;
          }),
          error()
        )
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteById', error.message);
    }
    return of(null);
  }

  protected mapToForm(resultado: IExameResultado): IForm[] {
    try {
      return [
        { VariavelNo: 2230, ValorDado: resultado?.codigoPaciente || '' },
        { VariavelNo: 28595, ValorDado: this.glb.ddMMYYYYThhmmssToDate(resultado?.dataExame) },
        { VariavelNo: 28610, ValorDado: resultado?.codigo || '' },
        { VariavelNo: 28611, ValorDado: resultado?.titulo || '' },
        { VariavelNo: 28612, ValorDado: resultado?.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 28613, ValorDado: resultado?.categoria },
        { VariavelNo: 28614, ValorDado: resultado?.exame },
        { VariavelNo: 28615, ValorDado: resultado?.resultado },
        { VariavelNo: 28616, ValorDado: resultado?.laudo },
        { VariavelNo: 28617, ValorDado: resultado?.valorreferenciaMaximo },
        { VariavelNo: 28618, ValorDado: resultado?.valorreferenciaMinimo },
        { VariavelNo: 28619, ValorDado: resultado?.alertaMaior },
        { VariavelNo: 28620, ValorDado: resultado?.rASTREAMENTO },
        { VariavelNo: 28621, ValorDado: resultado?.codPacCAT },
        { VariavelNo: 28622, ValorDado: resultado?.frequenciaCardiaca },
        { VariavelNo: 28624, ValorDado: resultado?.alertaMenor },
        { VariavelNo: 28626, ValorDado: resultado?.oCORRENCIA },
        { VariavelNo: 28668, ValorDado: resultado?.mAXIMO },
        { VariavelNo: 28669, ValorDado: resultado?.mINIMO },
        { VariavelNo: 28680, ValorDado: resultado?.altoOuBaixo },
        { VariavelNo: 28691, ValorDado: resultado?.nORMAIS },
        { VariavelNo: 28693, ValorDado: resultado?.alertaNormal },
        { VariavelNo: 28694, ValorDado: resultado?.alto },
        { VariavelNo: 28695, ValorDado: resultado?.baixo },
        { VariavelNo: 28696, ValorDado: resultado?.normal },
        { VariavelNo: 28697, ValorDado: resultado?.mSG1 },
        { VariavelNo: 28698, ValorDado: resultado?.mSG2 },
        { VariavelNo: 28777, ValorDado: resultado?.codPacOcorrenciaCAT },
        { VariavelNo: 28598, ValorDado: resultado?.idExame },
      ].filter(f => f.ValorDado !== null && f.ValorDado !== undefined);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist<T>(ano: number, resultado: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], resultado[compareFieldName])) >= 0);
            if (!founded) {
              return this.save<IExameResultado>(ano, resultado as unknown as IExameResultado, uno).pipe(map((m: IExameResultado) => !!m?.ocorrenciaNo));
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }


}
