import { WebService } from '@medlogic/shared/shared-data-access';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { InputMaskType } from '../enum/input-mask-type';
import { LibService } from './lib.service';
import { CalculatorService } from './calculator.service';
import { ExpressionService } from './expression.service';
import { Injectable } from '@angular/core';
// https://www.npmjs.com/package/expr-eval
import { Parser, Expression } from 'expr-eval';
import { ExpressionServerFunctionService } from './expression-server-function.service';
import { Subject } from 'rxjs';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { EnTypedValue } from '@medlogic/shared/shared-interfaces';
/* Importante destacar que há uma mudança no padrão de formulas do GE (antigo) com o novo formato baseado na biblioteca expr-eval.
* O GE era mais flexível, permitia a simples concatenação de valores, sem necessidade de um fórmula.
* Também diferença na separação de parâmetros usando , ao invés de ;
* Observa-se também incompatibilidades nos operadores lógicos, cujo GE aceitava uma grande diversidade de formatos,
* enquanto o motor atual é menos flexível.
*/
@Injectable()
export class CalculadoraService {
  SEPARATOR_CHAR_START = '#';
  SEPARATOR_CHAR_FINISH = '#';
  MATH_SIMBOL = '=';
  protected isCondition = false; // Será utilizado para controlar quando é uma CalculadoraCondition

  lstOfErrorCtrl = new Array<IAtividadeComponenteDAL>();

  constructor(
    protected log: LogService,
    protected global: GlobalService,
    protected webService: WebService,
    protected expression: ExpressionService,
    protected expAllFunc: ExpressionServerFunctionService,
    // Sempre chamar o serviço mais recente, e que por sua vez herdará de todos os outros em cascata
    protected calculatorSrv: CalculatorService,
    protected lib: LibService,
    protected cnf: ConfigStateService,
    protected cnfJson: ConfigJsonService,
  ) { }

  /*
  * Retorna o resultado do cálculo de uma fórmula. Retorna null se não for uma fórmula.
  * Substitui as variáveis da fórmula pelos respectivos valores.
  */
  protected calculateSimple(
    ctrl: IAtividadeComponenteDAL,
    lstCtrlReferenciados: IAtividadeComponenteDAL[],
    supostaFormula: string
  ): any {
    try {
      return this.calcularFormula(ctrl, lstCtrlReferenciados, supostaFormula);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculate', error.message);
    }
    return null;
  }

  /*Verifica se realmente é uma fórmula e retorna o valor calculado, substituindo o valor de variáveis e funções se existentes. */
  calculate(
    ctrl: IAtividadeComponenteDAL,
    lstCtrlReferenciados: IAtividadeComponenteDAL[],
    supostaFormula: string
  ): any {
    try {
      if (this.global.ChecaAspas(supostaFormula)) {
        return this.global.RemoverAspas(supostaFormula);
      } else {
        return this.calcularFormula(ctrl, lstCtrlReferenciados, supostaFormula);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculate', error.message);
      // throw new Error(`error.message formula: ${supostaFormula}`);
      // Para que o erro possa ser capturado pelo método que chamou o calculate.
    }
    return null;
  }

  /*Necessário para converter o padrão do GE para o do expr- */
  protected cleanFormula(formula: string, replaceComma: boolean = true): string {
    try {
      // Caracter separador dos parâmetros
      if (replaceComma) {
        formula = this.global.ReplaceAll(formula, ';', ',');
      }
      formula = this.removeStartEqual(formula);
      formula = this.replaceVariableSeparators(formula);
      // Necessário retornar eventual símbolo de #VAZIO# ao padrão original
      formula = this.global.ReplaceAll(formula, 'VVAZIOV', '#VAZIO#');
      formula = this.global.ReplaceAll(formula, '#SELF#', 'VSELFV');
      // Substitue as fórmulas de GRID por um novo padrão
      formula = this.global.ReplaceAll(formula, 'GRID.', 'GRID_');
      // Substitue as fórmulas de SERVER para o novo padrão
      formula = this.replaceSERVERFunction(formula, true);
      // Expressões lógicas
      // FIXME: Essa lógica precisa ser aprimorada para o uso de Regex
      const arrLogic = [
        { from: ' AND ', to: ' and ' },
        { from: ' OR ', to: ' or ' },
        { from: ' && ', to: ' and ' },
        // { from: ' && ', to: ' && ' },
        { from: ' & ', to: ' and ' },
        { from: ' || ', to: ' or ' },
        // { from: ' || ', to: ' || ' },
        { from: ' | ', to: ' or ' },
        { from: '<=', to: '<igual' }, // Necessário devido a substituição de todos os =
        { from: '>=', to: '>igual' }, // Necessário devido a substituição de todos os =
        { from: '!=', to: '<>' }, // Necessário devido a substituição de todos os =
        { from: '==', to: '=' },
        // Técnica para fazer com que todos os símbolos de = passem para ==. Necessário garantir que nenhum = seja parte de um == antes.
        { from: '=', to: '==' },
        { from: '<>', to: '!=' }, // Necessário devido a substituição de todos os =
        { from: '>igual', to: '>=' }, // Necessário devido a substituição de todos os =
        { from: '<igual', to: '<=' }, // Necessário devido a substituição de todos os =
        { from: 'igual', to: '=' } // artifício para retornar o estágio da SERVER FUNCTION
      ];
      arrLogic.forEach((e) => (formula = this.global.ReplaceAll(formula, e.from, e.to)));
      return formula;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanFormula', error.message);
    }
  }

  // Substitue o caracter separador por uma string qualquer, pois é um caracter especial inválido para a eval.
  // Irá modificar #[Número de Variável]# por V[Número de Variável]V.
  // formula = this.global.ReplaceAll(formula, this.SEPARATOR_CHAR_START, 'V');
  // TODO: Pode ser aqui o problema com o firefox
  private replaceVariableSeparators(formula: string) {
    try {
      const regex = new RegExp(`${this.SEPARATOR_CHAR_START}(\\d*?)${this.SEPARATOR_CHAR_START}`, 'g');
      const matches = formula.match(regex);
      if (matches && matches.length > 0) {
        matches.forEach(e => {
          formula = formula.replace(e, this.global.ReplaceAll(e, this.SEPARATOR_CHAR_START, 'V'));
        });
      }
      return formula;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'replaceVariableSeparators', error.message);
    }
    return formula;
  }

  /*Substitui o padrão antigo pelo novo.
  *  replaceEqualSimbol: Esse é um artifício para que o símbolo de igual, na substituição que ocorrerá depois, não seja substituído por ==
  */
  protected replaceSERVERFunction(formula: string, replaceEqualSimbol: boolean): string {
    try {
      const isServerFunc = formula.indexOf('SERVER.') >= 0;
      if (isServerFunc) {
        formula = this.global.ReplaceAll(formula, 'SERVER.', 'SERVER_');
        if (replaceEqualSimbol) {
          // FIXME: Se a lógica de substituição for aprimorada, esse item deverá ser removido
          // Esse é um artifício para que o símbolo de igual, na substituição que ocorrerá depois, não seja substituído por ==
          formula = this.global.ReplaceAll(formula, '=', 'igual');
        }
        // Se a url não estiver entre aspas, acrescentar
        // tslint:disable-next-line: quotemark
        const hasQuotes = formula.indexOf('("') >= 0 || formula.indexOf("('") >= 0;
        if (!hasQuotes) {
          formula = formula.replace('(', '("').replace(')', '")');
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'replaceSERVERFunction', error.message);
    }
    return formula;
  }

  /*Remove o símbolo inicial de =, caso haja. */
  protected removeStartEqual(formula: string): string {
    try {
      return formula.trim().substr(0, 1) === '=' ? formula.substr(1) : formula;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'removeStartEqual', error.message);
    }
    return formula;
  }

  /*
  * Essa é a função principal que processará as substituições e os cálculos
  * Process as funções.
  * Se for identificado um erro na fórmula, sempre retornará vazio ou então false no caso de condição.
  */
  protected calcularFormula(
    ctrl: IAtividadeComponenteDAL,
    lstCtrlReferenciados: IAtividadeComponenteDAL[],
    supostaFormula: string
  ): any {
    try {
      // Caso tenha dado um erro para o controle, será adicionado a uma lista. Não tentará processar novamente.
      // TODO: Verificar se o mesmo controle pode ter condicional e fórmula,
      // pois o erro em uma fórmula, nesse caso, impediria o funcionamento do outro.
      if (this.lstOfErrorCtrl.includes(ctrl)) {
        return this.isCondition ? false : '';
      }
      let result;
      if (!this.isValid(supostaFormula)) {
        return supostaFormula;
      } else {
        const parser = new Parser({
          operators: {
            // These default to true, but are included to be explicit
            add: true,
            concatenate: true,
            conditional: true,
            divide: true,
            factorial: true,
            multiply: true,
            power: true,
            remainder: true,
            subtract: true,
            logical: true,
            comparison: true
          }
        });
        const server = supostaFormula.indexOf('SERVER') >= 0;
        // tslint:disable-next-line: no-var-keyword
        var form = this.replaceOldSCRIPT(supostaFormula, lstCtrlReferenciados);
        form = this.cleanFormula(form);
        if (this.isSimpleReplaceAndNotIsMathOrBoolean(form)) {
          // Se for um condicional, não tentará entrar em simples concatenação. this instanceof CalculadoraConditionService
          result = this.evaluateSimpleConcat(form, lstCtrlReferenciados, true, 'V');
        } else {
          // A fórmula contém uma função assíncrona
          let exp: any = parser.parse(form);
          if (this.isAsyncFunction(supostaFormula)) {
            const hasArgs = exp.tokens.filter(f => f.type === 'IVAR' || f.type === 'INUMBER').length > 1;
            const quote = hasArgs ? ',' : '';
            // Deverá acrescentar um parâmetro na fórmula, para receber o controle
            form = form.replace('(', `(CTRL${quote}`);
            exp = parser.parse(form);
          }
          // Deve iniciar com todas as funções existentes
          // É necessário pegar a classe mais filha, que importará a função das ancestrais
          let params = this.expAllFunc.getAllFunctions(exp, lstCtrlReferenciados, ctrl);
          // Acrescentar o próprio controle para permitir referencias, bem como atualização asíncrona atraves de AsyncValue
          if (!ctrl.AsyncValue) {
            ctrl.AsyncValue = new Subject();
          }
          params.CTRL = ctrl;
          // Acrescenta um token para cada variável referenciada
          if (lstCtrlReferenciados) {
            lstCtrlReferenciados.forEach((p) => {
              try {
                const parName = `V${p.VariavelNo}V`;
                params[parName] = this.extractValue(p);
              } catch (error) {
                this.log.Registrar(this.constructor.name, 'calcularFormula.forEach', error.message);
              }
            });
          }
          // Acrescenta um token para a palavra SELF
          if (supostaFormula?.indexOf('#SELF#')) {
            params.VSELFV = this.extractValue(ctrl) || '';
          }
          params = this.convertNotDefinedVariablesInStrings(exp, params);
          result = exp.evaluate(params);
          if (this.cnfJson.showFormulasRendering) {
            this.cnf.setListFormulaRendering(
              `<strong>${supostaFormula}</strong>: <br />${exp
                .symbols()
                .join(', ')}  <br />  <i>${form}</i> <br /><strong><i>Resultado: ${result}</strong></i>`
            );
          }
        }
        return this.formatIfIsMasked(result, ctrl.InputMaskType);
      }
    } catch (error) {
      const detail = `erro: ${error.message} formula: ${supostaFormula}, modificada: ${form}`;
      this.lstOfErrorCtrl.push(ctrl);
      this.log.Registrar(this.constructor.name, 'calcularFormula', detail);
      // throw new Error(detail);
      // Como não foi possível evoluir a fórmula, tentativa de apenas substituir variáveis.
      form = this.evaluateSimpleConcat(form, lstCtrlReferenciados); // , this.expGridFunc.getFunctionArray()
      return form;
    }
  }

  /*Se houver uma máscara aplicada, formatará o número adequadamente para evitar conflito com máscaras que
   * esperam números separados por vírgula.
   */
  formatIfIsMasked(result: any, inputMaskType: string): any {
    try {
      if (typeof result === 'number') {
        const mask = InputMaskType.toEnum(inputMaskType);
        switch (mask) {
          case InputMaskType.Numeric:
          case InputMaskType.Real:
          case InputMaskType.Numeric2:
          case InputMaskType.Numeric3:
          case InputMaskType.Numeric4:
          case InputMaskType.Percent2:
            return this.global.ConvertToBrazilianNumber(result);
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fromatIfIsMasked', error.message);
    }
    return result;
  }

  /*O padrão antigo de fórmulas do GE permitia a simples concatenação de itens e substituição de variáveis, sem a necessidade de uma função.
  * Esse método checará se a fórmula NÃO contém uma função.
  * Também verificará se não se trata de uma fórmula matemática ou expressão booleana.
  */
  protected isSimpleReplaceAndNotIsMathOrBoolean(formula: string): boolean {
    try {
      const lstFunctions = this.expAllFunc.getFunctionArray();
      let isSimpleRepl = true;
      if (this.isMath(formula) || this.isCondition) {
        return false;
      }

      lstFunctions.every((e) => {
        if (formula.indexOf(`${e.name}(`) >= 0) {
          isSimpleRepl = false;
          return false;
        }
        return true;
      });
      return isSimpleRepl;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isSimpleReplace', error.message);
    }
    return false;
  }

  /*As fórmulas do GE permitem que uma string fornecida como parâmetro não esteja entre aspas.
  * No processamento da fórmula, uma string sem aspas tentaria ser renderizada como variável.
  */
  protected convertNotDefinedVariablesInStrings(expr: Expression, definitions: any): any {
    try {
      const variables = expr.variables({ withMembers: true });
      variables.forEach((v) => {
        try {
          if (!definitions.hasOwnProperty(v)) {
            definitions[v] = `${v}`;
          }
        } catch (error) {
          this.log.Registrar(
            this.constructor.name,
            'convertNotDefinedVariablesInStrings.forEach',
            error.message
          );
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertNotDefinedVariablesInStrings', error.message);
    }
    return definitions;
  }

  /*Algumas fórmulas são do tipo SERVER. Para essas, há um tratamento específico. */
  protected isServerFunction(formula: string): boolean {
    try {
      const test = this.removeStartEqual(formula).toUpperCase().trim();
      return test.startsWith('SERVER');
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isServerFunction', error.message);
    }
    return false;
  }

  /*Algumas fórmulas devem retornar resultado de fórmula assíncrona. Para essas, há um tratamento específico. */
  protected isAsyncFunction(formula: string): boolean {
    try {
      let test = this.removeStartEqual(formula).toUpperCase().trim();
      test = this.cleanFormula(test);
      const asyncFn = this.expAllFunc.getFunctionArray().filter((f) => f.isAsync).map((m) => m.name); // [ 'SERVER', 'PROC' ];
      let testBoolean = false;
      asyncFn.every((e) => {
        if (test.startsWith(e)) {
          testBoolean = true;
          return false;
        } else {
          return true;
        }
      });
      return testBoolean;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isServerFunction', error.message);
    }
    return false;
  }

  /*Checa se é uma fórmula matemática.
  * Pode conter fórmulas do tipo V233V, ou seja, número da variável separado por V.
  */
  protected isMath(formula: string): boolean {
    try {
      const replaceVarPorZero = formula.replace(/(V(\d*)V)/gi, '0');
      const isNumOrSymbol = this.global.IsNumericOrMathSimbol(replaceVarPorZero, true);
      const hasMathSymbol = this.global.hasMathSymbol(formula);
      return isNumOrSymbol && hasMathSymbol;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isMath', error.message);
    }
    return false;
  }

  /*No GE Flash há um padrão para script que usa colchetes. SCRIPT[]. Essa
  * função substitui para que seja conhecido como uma função.
  */
  protected replaceOldSCRIPT(formula: string, lstCtrlReferenciados: IAtividadeComponenteDAL[]): string {
    try {
      const finded = formula.indexOf('SCRIPT[');
      if (finded >= 0) {
        let body = formula.replace(/(=SCRIPT\[)(.*)(\])/g, '$2');
        body = this.cleanFormula(body, false);
        let repl = this.evaluateSimpleConcat(body, lstCtrlReferenciados, false); // this.expGridFunc.getFunctionArray()
        repl = this.replaceForEachAS3(repl);
        try {
          // tslint:disable: no-eval
          // tslint:disable-next-line: no-var-keyword
          var res = eval(repl);
        } catch (e) {
          // Se der erro, tentará substituir o último return
          const n = repl.lastIndexOf('return ');
          repl = repl.slice(0, n) + repl.slice(n).replace('return ', '');
          res = eval(repl);
        }
        return res.toString();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'replaceOldSCRIPT', error.message);
    }
    return formula;
  }

  /*Substituirá o for each do AS3 para o padrão de javascript. */
  private replaceForEachAS3(formula: string): string {
    try {
      const res = formula.replace(/(\sin\s)/, ' of ').replace(/(for each)/, 'for');
      return res;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'replaceForEachAS3', error.message);
    }
  }

  /*O GE permitia concatenar strings simplesmente colocando itens e variáveis um ao lado do outro, sem função ou sinal.
  * Esse método é uma tentativa de reproduzir o mesmo cenário, quando nenhuma fórmula for encontrada na expressão.
  * Recebe um array que deve ter uma propriedade name com o nome das funções.
  * irá substituir as variáveis por valor e remover aspas.
  * No final das substituições também é necessário evoluir a expressão, pois pode se tratar de fórmula matemática.
  */
  protected evaluateSimpleConcat(
    formula: string,
    lstCtrlReferenciados: IAtividadeComponenteDAL[],
    removeQuotes: boolean = true,
    separator: string = 'V'
  ): string {
    try {
      if (lstCtrlReferenciados) {
        const keyValues = lstCtrlReferenciados.map((m) => {
          const item = { name: `${separator}${m.VariavelNo}${separator}`, value: this.extractValue(m) };
          return item;
        });
        keyValues.forEach((f) => {
          try {
            const regex = new RegExp(f.name, 'g');
            const value = this.global.IsDate(f.value)
              ? this.global.dateToddMMYYYY(this.global.getTypedValue(f.value).value)
              : f.value;
            formula = formula.replace(regex, value);
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'evaluateSimpleConcat.forEach', error.message);
          }
        });
      }
      if (removeQuotes) {
        formula = formula.replace(/\"/g, '');
      }
      return this.removeStartEqual(formula);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'evaluateSimpleConcat', error.message);
    }
  }

  /*Necessário para extrair corretamente o valor, conforme as características.
  * Também irá considerar a existência de uma máscara para limpar o valor.
  */
  protected extractValue(p: IAtividadeComponenteDAL): any {
    try {
      let cleanValue: string;
      const value = p.Valor || p.ValorTexto || (this.lib.isFormula(p.ValorDefault) ? '' : p.ValorDefault);
      if (value.name) {
        // Significa que retornou um objeto - valor de Combobox
        cleanValue = value.name;
      } else {
        cleanValue = value;
      }
      cleanValue = InputMaskType.cleanMaskSymbolAndConvertToNumber(cleanValue, p.InputMaskType);
      const typed = this.global.getTypedValue(cleanValue, 'en', 'en');
      // TODO: verificar se não interferiu no retorno para controles de data.
      switch (typed.type) {
        case EnTypedValue.Date:
          cleanValue = typed.string;
          break;
        default:
          cleanValue = typed.value;
          break;
      }
      return cleanValue;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractValue', error.message);
    }
    return null;
  }

  /*Para ser sobrescrito pela calculadora de condição que precisa validar se é uma condição. */
  protected isValid(supostaFormula: string): boolean {
    try {
      return this.lib.isFormula(supostaFormula);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isValid', error.message);
    }
    return false;
  }

  /*Extrai apenas a fórmula, removendo o símbolo = de identificação. */
  protected getFormula(valor: string): string {
    try {
      valor = this.global.RemoverAspas(valor);
      if (valor.trim().substr(0, 1) === '=') {
        return valor.trim().substr(1);
      } else {
        return valor.trim();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFormula', error.message);
    }
  }

  /*
  * Os controles que contém fórmulas, terão o ValorTexto sempre atualizado
  * mas o ValorDefault sempre conterá a fórmula. Portanto, é necessário testar
  * se o ValorDefault é uma fórmula e, se for, retornar sempre a fórmula.
  * */
  getValorOuFormula(ctrl: IAtividadeComponenteDAL, currentValue: any): any {
    try {
      if (this.lib.isFormula(ctrl.ValorDefault)) {
        return ctrl.ValorDefault;
      } else if (currentValue) {
        return currentValue;
      } else {
        return this.global.IsNullOrEmpty(ctrl.ValorTexto)
          ? ctrl.ValorDefault
          : this.global.AcrescentarAspas(ctrl.ValorTexto);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValorOuFormula', error.message);
    }
  }

  /*Extrai a lista de controles que foram referenciados por uma fórmula
  * Usado por AtividadeComponenteDAL */
  extractVars(valor: string): number[] {
    try {
      if (!this.lib.isFormulaOrCondition(valor)) {
        return null;
      } else {
        const formula = this.getFormula(valor);
        return this.splitFormula(formula);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractVars', error);
    }
    return null;
  }

  /*
  * Identifica os itens que são fórmulas e coloca cada item que é fórmula numa posição do array
  * ATENÇÃO: SE HOUVER NÚMEROS ISOLADOS, QUE NÃO SÃO FÓRMULAS, PODERÁ DAR ERRO.
  * */
  protected splitFormula(formula: string): any[] {
    try {
      if (this.global.IsNullOrEmpty(formula)) {
        return null;
      }
      const vars: any[] = formula.match(/(#\d*#)/g);
      if (vars) {
        const varsNum = vars.map((m) => {
          const num = this.global.ReplaceAll(m, '#', '');
          return this.global.isNumeric(num) ? +num : num;
        });
        return varsNum;
      } else {
        return null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'splitFormula', error.message);
    }
  }

  /*Cria um código hash contendo todos os valores dos controles referenciados.
  * Caso esses controles não tenham sido modificados, a hash poderá ser usada
  * para evitar recálculos sucessivos de um valor que não se modificou. */
  getHash(lstCtrlReferenciados: IAtividadeComponenteDAL[]): number {
    try {
      if (!lstCtrlReferenciados || lstCtrlReferenciados.length <= 0) {
        // Esse símbolo é importante, pois, como é diferente de nulo, considerará que o hash foi calculado.
        return this.global.hashFromString(this.global.GE_EMPTY);
      }
      const result = lstCtrlReferenciados
        .map((m) => m.Valor || (this.lib.getValorTextoOrData(m) || '').toString())
        .reduce((a, b) => a.concat(b), '');
      return this.global.hashFromString(result);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getHash', error.message);
    }
    return null;
  }

  /*Verifica se houve modificacação de um ou mais valores dos controles referenciados,
  * com base na hash.
  */
  wasChanged(hash: number, lstCtrlReferenciados: IAtividadeComponenteDAL[]): boolean {
    try {
      if (!hash) {
        return true;
      }
      const currentHash = this.getHash(lstCtrlReferenciados);
      return !this.global.isEqual(currentHash, hash);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'wasChanged', error.message);
    }
    return true;
  }
}
