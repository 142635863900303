import { Observable } from 'rxjs';

export interface IServiceProvider {
    // Properties
    cadastroNo: number;
    currentDtInicial: Date;
    currentDtFinal: Date;
    cadastrosCache: Observable<any> | null;
    // Methods
    getAll(ano: number, startDate?: Date, endDate?: Date): Observable<any>;
    loadArray(ano: number): Observable<any>;
    clearCache(): void;
    insertIfNotExist<T>(ano: number, account: T, uno: number, compareFieldName: string): Observable<boolean>;
    save<T>(ano: number, item: T, uno: number, id?: number, token?: string): Observable<any>;
}