import { ConfigStateService } from '@medlogic/shared/state-config';
import { GlobalService, IAtividadeComponenteDAL, IDado, LocalLibService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ObjectDAL } from './object-dal';
import { WebService } from './web.service';


@Injectable()
export class SharedDadoDAL extends ObjectDAL {
  constructor(webService: WebService, log: LogService, global: GlobalService,   protected lib: LocalLibService,
    private cnf: ConfigStateService) {
    super(webService, log, global);
  }

  setAll(
    dados: IDado[],
    isVersion: boolean,
    uno: number = -1,
    executeNow: boolean = true): Observable<any> {
    try {
      const method = 'setDado';
      const result = this.webService
        .connect(method, [
          { name: '_strXml', value: this.toXml(dados) },
          { name: '_isVersion', value: isVersion }
        ])
        .pipe(
          // Necessário notificar o serviço sobre a finalização do salvamento para geração do XML
          map((m) => {
            const savedItem = dados[0];
            this.notificarConclusaoSalvamento(savedItem.AtividadeNo, savedItem.OcorrenciaNo);
            return savedItem;
          })
        );
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setAll', error.message);
    }
  }

  /* Atenção: Ele é apenas um método de transformação e não vai, portanto, a um serviço. */
  mapToDados(
    componentes: IAtividadeComponenteDAL[],
    tarefaNo: number,
    ocorrenciaNo: number,
    usuarioNo: number
  ): IDado[] {
    try {
      return componentes.map<IDado>(
        (c) =>
          ({
            VariavelNo: c.VariavelNo,
            TarefaNo: tarefaNo,
            UsuarioNo: usuarioNo,
            DtRegistro: this.global.RetornarAllXsdDateTime(new Date()),
            OcorrenciaNo: ocorrenciaNo,
            Versao: c.Versao,
            ValorTexto: this.lib.getValorTextoOrData(c, true),
            ValorData: this.lib.getValorData(c.ValorData, true),
            ValorInteiro: c.ValorInteiro || '',
            ValorNumero: c.ValorNumero || '',
            // ValorBit: c.ValorBit,
            // ValorXml: c.ValorT,
            AtividadeNo: c.AtividadeNo
            // Calculos: c.Calcu,
          } as IDado)
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToDados', error.message);
    }
    return null;
  }

  /* Cria a tag de uma propriedade do dado, mas somente se o valor não for nulo ou vazio. */
  protected addElement(dado: IDado, tagName: string): any {
    try {
      const elem = dado[tagName];
      if (this.global.IsNullOrEmpty(elem)) {
        return '';
      }
      return `<${tagName}>${elem}</${tagName}>`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addElement', error.message);
    }
  }

  /* Cria o XML no formato necessário para envio ao serviço, tag ExecucaoDados. */
  protected toXml(dados: IDado[]): string {
    try {
      let strXml = '<ExecucaoDados>';
      // percorre a lista de dados
      dados.forEach((d) => {
        try {
          // Somente adicionará o elemento caso tenha havido modificação no dado (seja texto ou data)
          if (!this.global.IsNullOrEmpty(d.ValorTexto) || !this.global.IsNullOrEmpty(d.ValorData)) {
            // forma um dado
            // tslint:disable: quotemark
            let add =
              "<ExecucaoDado xmlns:xsi='http://www.w3.org/2001/XMLSchema-instance' xmlns:xsd='http://www.w3.org/2001/XMLSchema'>";
            for (const propertyName in d) {
              if (propertyName) {
                // para percorrer as propriedades do objeto
                const newData = this.addElement(d, propertyName);
                if (!this.global.IsNullOrEmpty(newData)) {
                  if (typeof newData === 'string') {
                    add += newData;
                  }
                }
              }
            }
            add += '</ExecucaoDado>';
            strXml += add;
          }
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'toXml.forEach', error.message);
        }
      });
      strXml += '</ExecucaoDados>';
      return strXml;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toXml', error.message);
    }
    return null;
  }

  /* Necessário para geração do XML */
  notificarConclusaoSalvamento(cadastroNo: number, ocorrenciaNo: number): void {
    try {
      const method = 'CadastroNotificarConclusaoSalvamento';
      this.subs.sink = this.webService
        .connect(method, [
          { name: 'CadastroNo', value: cadastroNo },
          { name: 'OcorrenciaNo', value: ocorrenciaNo }
        ])
        .subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'notificarConclusaoSalvamento', error.message);
    }
  }

  // ******* ERP
  // setSingle(
  //     variavelNo: number,
  //     usuarioNo: number,
  //     ocorrenciaNo: number,
  //     atividadeNo: number,
  //     valorTexto: string,
  //     valorData?: Date): Observable<any> {
  //     try {
  //         let dadoDAL = <IDado>{
  //             VariavelNo: variavelNo,
  //             TarefaNo: -1,
  //             UsuarioNo: usuarioNo,
  //             DtRegistro: this.global.RetornarAllXsdDateTime(new Date()),
  //             OcorrenciaNo: ocorrenciaNo,
  //             Versao: null,
  //             ValorTexto: valorTexto,
  //             ValorData: this.global.RetornarAllXsdDateTime(valorData),
  //             ValorInteiro: null,
  //             ValorNumero: null,
  //             ValorBit: null,
  //             ValorXml: null,
  //             AtividadeNo: atividadeNo,
  //             Calculos: null
  //         }
  //         return this.setAll([dadoDAL], true);
  //     } catch (error) {
  //         this.log.Registrar("DadoDalService", 'setSingle', error.message);
  //     }
  // }
}
