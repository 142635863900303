<div class="ibox-content">
  <br />
  <br />

  <a class="btn btn-success btn-facebook btn-outline" href="javascript:void(0);" (click)="onBack()">
    <i class="fa fa-chevron-left"> </i> Voltar
  </a>
  <br />
  <br />
  <!-- Paciente -->
  <div class="ibox-content text-center">
    <img alt="image" class="img-circle" src="http://fakeimg.pl/120x120/">
  </div>
  <div class="ibox-content text-center">
    <h2>{{ patient.nomeHospede }}</h2>
    <h3>{{ getAge(patient.nascimento)  }} anos</h3>
  </div>

  <!-- Metas Terapeuticas -->
  <div class="ibox-content">
    <h2>Metas Terapêuticas</h2>
    <br />
    <h3>Prioridade 1:</h3>
    <h4>Intervenções capazes de reduzir o declínio funcional + Intervenções capazes de confortar o paciente e/ou família
    </h4>
    <table class="table table-hover table-mail">
      <tbody>
        <tr class="unread">
          <td class="mail-ontact">ABORDAGEM DO QUADRO DE DISPNEIA AOS MÍNIMOS ESFORÇOS</td>
          <td class="mail-subject">Paciente com queixa de dispneia aos mínimos esforços, sem dispneia paroxística
            noturna ou ortopneia. História de
            TEP após internação por atropelamento (sic) - TEP secundária à imobilidade durante internação? FEz uso de
            varfarina
            por 1 ano. A fim de esclarecer a etiologia da dispneia, solicitamos: ... Radiografia de tórax: pendente. ...
            Ecocardiograma transtorácico: pendente. * Porém a paciente não compareceu à consulta de retorno para
            avaliação
            dos resultados de exames e instituição de medidas terapêuticas adequadas. Avaliar possibilidade de
            equivalente
            anginoso.
          </td>
        </tr>
        <tr class="unread">
          <td class="mail-ontact">ABORDAGEM DO TRANSTORNO DE HUMOR</td>
          <td class="mail-subject">Paciente com critérios de depressão, sem tratamento, sendo o episódio atual o
            primeiro episódio da doença. Iniciamos
            nortriptilina 25mg, com progressão para 50mg de acordo com tolerância da paciente. Para melhor
            acompanhamento,
            solicitamos: ... ECG: pendente. Sugerimos que seja avaliado a tolerância, resposta e possíveis
            contra-indicações
            após avaliação do ECG da paciente.</td>
        </tr>
      </tbody>
    </table>
    <h3>Prioridade 2:</h3>
    <h4>Intervenções curativas ou capazes de modificar a história natural das doenças, reduzindo o risco de declínio
      funcional</h4>
    <table class="table table-hover table-mail">
      <tbody>
        <tr class="unread">
          <td class="mail-ontact">DESPRESCRIÇÃO</td>
          <td class="mail-subject">Suspendemos o uso de furosemida por não haver indicação precisa para o seu uso até o
            momento. Paciente mantém queixa
            de dispneia aos mínimos esforços, sem sinais de congestão sistêmica, sem diagnóstico de insuficiência
            cardíaca
            bem estabelecido. Iniciamos hidroclorotiazida 25mg MID para controle pressórico através de ação diurética
            menos
            potente.
          </td>
        </tr>
        <tr class="unread">
          <td class="mail-ontact">ABORDAGEM DAS COMORBIDADES</td>
          <td class="mail-subject">Solicitamos exames laboratoriais: pendentes. Solicitamos cobrar resultados. .. Cobrar
            resultado de DMO. Se osteoporose,
            avaliar tratamento com bisfosfonato apenas se clearance de creatinina for maior que 30. Recomendamos o uso
            por
            5 anos,com repetição da densitometria óssea a cada 2 anos. Avaliar suplementação com carbonato de cálcio e
            vitamina
            D3.
          </td>
        </tr>
      </tbody>
    </table>
    <h3>Prioridade 3:</h3>
    <h4>Intervenções preventivas e direcionadas a fatores de risco</h4>
    <table class="table table-hover table-mail">
      <tbody>
        <tr class="unread">
          <td class="mail-ontact">PREVENÇÃO DE QUEDAS</td>
          <td class="mail-subject">Avaliar necessidade de adaptações ambientais.</td>
        </tr>
        <tr class="unread">
          <td class="mail-ontact">SUPORTE SOCIAL</td>
          <td class="mail-subject">Como a paciente não compareceu à consulta de retorno, avaliar se o suporte familiar é
            adequado.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Timeline -->
  <div class="ibox-content inspinia-timeline">
    <div *ngFor="let item of items" class="timeline-item">
      <div class="row">
        <div class="col-xs-3 date">
          <i class="fa" [class.fa-frown-o]="item.enCareType==0" [class.fa-heart-o]="item.enCareType==1"
            [class.fa-flag-o]="item.enCareType==2"></i> {{ item.time }}
          <br>
          <small class="text-navy">{{ getInterval(item.date) }}</small>
        </div>
        <div class="col-xs-7 content no-top-border">
          <p class="m-b-xs" [class.text-warning]="item.enCareType==0" [class.text-danger]="item.enCareType==1"
            [class.text-info]="item.enCareType==2"><strong>
              <h3>{{ item.title }}</h3>
            </strong></p>
          <p>{{ item.description }}
          </p>
          <p>
            <img *ngIf="item.imgUrl" [src]="item.imgUrl" alt="" width="150" />
          </p>
        </div>
      </div>
    </div>
  </div>

</div>