import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { GenericActionTypes } from './generic.actions';
import { initialState } from './generic.reducer';

const KEY = 'MEDLOGIC_GENERIC_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  GenericActionTypes.LoadGeneric,
  GenericActionTypes.FetchSuccessGeneric,
  GenericActionTypes.SetGeneric,
  GenericActionTypes.SetGenericById,
  GenericActionTypes.SetAllGenerics,
  GenericActionTypes.LoadGenericByPerson,
  GenericActionTypes.UpdateGeneric,
];

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialState);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
