import { Injectable } from '@angular/core';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';

@Injectable()
export class CardPatientProvider {

    patients: IPatient[] = new Array<IPatient>();
    horarioCall: any;
    checkCall: any;
    intervMed: any;
    isOffLineMode = false;

    constructor(private glb: GlobalService, private log: LogService) { }

    /* Irá zerar o valor de todas as propriedades. */
    clearCache(): void {
        try {
            this.patients = new Array<IPatient>();
            this.horarioCall = this.checkCall = this.intervMed = null;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'clearCache', error.message);
        }
    }


}
