import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

/* Wrapper criado para incorporar os métodos de cookie,
* pois, dado atualização do angular, foi mudado o pacote e
esse método preserva a utilização anterior. */
@Injectable({
  providedIn: 'root'
})
export class CustomCookieService {

  constructor(
    private cookieSrv: CookieService,
    // private log: LogService referência circular
  ) { }

  /** Insere um objeto no cookie. */
  putObject(key: string, obj: any, expireDays: number = -1): void {
    try {
      const value = JSON.stringify(obj);
      if (expireDays >= 0) {
        this.cookieSrv.set(key, value, expireDays);
      } else {
        this.cookieSrv.set(key, value);
      }
    } catch (error) {
      // this.log.Registrar(this.constructor.name, 'putObject', error.message);
    }
  }

  /** Retorna o conteúdo do cookie. */
  get(key: string): string {
    try {
      return this.cookieSrv.get(key);
    } catch (error) {
      // this.log.Registrar(this.constructor.name, 'get', error.message);
    }
    return null;
  }

  /** Checa a existência de uma chave no cookie. */
  check(key: string): boolean {
    try {
      return this.cookieSrv.check(key);
    } catch (error) {
      // this.log.Registrar(this.constructor.name, 'check', error.message);
    }
    return false;
  }

  /** Retorna um valor no formato de objeto.  */
  getObject(key: string): any {
    try {
      const obj = JSON.parse(this.cookieSrv.get(key));
      return obj;
    } catch (error) {
      // this.log.Registrar(this.constructor.name, 'getObject', error.message);
    }
    return null;
  }

  /** Exclui todos os itens registrados em cookie. */
  deleteAll(): void {
    try {
      this.cookieSrv.deleteAll();
    } catch (error) {
      // this.log.Registrar(this.constructor.name, 'deleteAll', error.message);
    }
  }

  /** Remove o conteúdo do cookie. */
  delete(key: string): void {
    try {
      this.cookieSrv.delete(key);
    } catch (error) {
      // this.log.Registrar(this.constructor.name, 'delete', error.message);
    }
  }

}
