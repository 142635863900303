import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProcessActionTypes, fetchErrorProcess, fetchSuccessProcess } from '../actions/process.actions';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IModule } from '@medlogic/shared/shared-interfaces';
import { ProcessService } from '@medlogic/shared/shared-data-access';


@Injectable()
export class ProcessEffects {

  constructor(
    private actions$: Actions,
    private processSrv: ProcessService // TODO: ContaService changed to the API
  ) { }

  loadProcessList$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProcessActionTypes.LoadProcess),
      mergeMap(() => this.processSrv.getDataProcess()
        .pipe(
          toArray(),
          map((allProcesses: IModule[]) => {
            return fetchSuccessProcess({ allProcesses });
          }),
          catchError((e: any) => {
            console.log(e);
            return of(fetchErrorProcess());
          })
        )
      )
    )
  );


}
