import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UiSnackbarNotificationComponent } from '../ui-snackbar-notification/ui-snackbar-notification.component';

/* @classdesc
 * Serviço para métodos genéricos de abertura de snackbar do material.
*/
@Injectable({
  providedIn: 'root'
})
export class SnackbarNotificationService {

  constructor(
    protected log: LogService,
    private _snackBar: MatSnackBar
  ) {
  }

  /* Abre uma janela de diálogo com uma mensagem.
    * Retorna um Observable de fechamento.
    */
  open(messageHtml: string, title: string = '', durationInSeconds: number = 3, btnOK: string = 'OK', width: string = '450px'): Observable<any> {
    try {
      const dialogRef = this._snackBar.openFromComponent(UiSnackbarNotificationComponent, {
        data: { title, messageHtml, btnOk: btnOK },
        duration: durationInSeconds * 1000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      });
      return dialogRef.afterDismissed();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'open', error.message);
    }
    return of(null);
  }


}
