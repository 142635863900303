export enum EnActivityDetailNo {
 codigo = '108718',
 titulo = '108719',
 habilitado = '108720',
 tipoDeAtividade = '108721',
 residente = '108748',
 ocorrenciaPaciente = '109164',
 medlogicId = '109163',
 nomeDaAtividade = '108722',
 ocorrenciaAtvTablet = '108746',
 milleniumId = '108723',
 descricaoDaAtividade = '108724',
 observacoes = '108725',
 nomeDoProfissional = '108726',
 profOcorrência = '108751',
 drt = '108749',
 dataDaAtividade = '108728',
 publicoAlvo = '108730',
 andar = '109159',
 periodo = '108731',
 tipo = '109143',
 inicio = '108727',
 fim = '109144',
 domingo = '108732',
 segunda = '108733',
 terca = '108734',
 quarta = '108735',
 quinta = '108736',
 sexta = '108737',
 sabado = '108738',
 horarios = '108729',
 local = '108739',
 intervalo = '109279',

 // to be defined
 medlogicActivityId = 'ono',
 erpId = 'erpId',
 edit = 'edit',
 ono = 'ono',
}
