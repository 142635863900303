import { Injectable } from '@angular/core';
import { IResponsibleFamily } from '../interface/iresponsible-family';
import { GlobalService, LogService, IForm, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { CadastroService, WebService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from './config-pwa-medlogic.custom.service';
import { Observable, of } from 'rxjs';
import { map, publishReplay, refCount, toArray } from 'rxjs/operators';

@Injectable()
export class ResponsibleFamilyService extends UnsubscribeOnDestroyAdapter { // extends BaseService {

  private lstVariaveis = 'V_387,V_394,V_395,V_396,V_397,V_399,V_402,V_405,V_2193,V_2197,V_2198,V_2614,V_3292,V_3293,V_3335,V_28051,V_28936,V_28937,V_28938,V_28942,V_28943,V_28944,V_28945,V_28946,V_28947,V_29014,V_29015,V_29016,V_29018,V_29019,V_31965,V_31966,V_34274';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IResponsibleFamily[] = new Array<IResponsibleFamily>();

  // codigoVariavelNo = 28936; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 2270;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected cnf: ConfigPwaMedLogicService,
    protected glb: GlobalService,
    protected log: LogService,
    protected ws: WebService
  ) {
    super();
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IResponsibleFamily> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IResponsibleFamily> {
    try {
      if (
        (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
        || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
        || (!this.cadastrosCache)
        // (startDate.getTime() !== this.currentDtInicial.getTime())
        // || (endDate.getTime() !== this.currentDtFinal.getTime())
        // || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return null;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para
      // que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis)
        .pipe(
          map(c => ({
            ocorrenciaNo: c.ocorrenciaNo,
            nomeHospede: c.V_387,
            ruaAv: c.V_394,
            complemento: c.V_395,
            numero: c.V_396,
            bairro: c.V_397,
            estado: c.V_399,
            idade: c.V_402,
            sexo: c.V_405,
            cEP: c.V_2193,
            receberNotificacoesPorSMS: c.V_2197.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_2197) ? true : false,
            receberNotificacoesPormail: c.V_2198.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_2198) ? true : false,
            cidade: c.V_2614,
            telefoneFixo: c.V_3292,
            celular: c.V_3293,
            endereco: c.V_3335,
            prontuarioHosp: c.V_28051,
            codigo: c.V_28936,
            titulo: c.V_28937,
            habilitado: c.V_28938.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_28938) ? true : false,
            nome: c.V_28942,
            parentesco: c.V_28943,
            status: c.V_28944,
            descreva: c.V_28945,
            status1: c.V_28946,
            eMail: c.V_28947,
            rG: c.V_29014,
            cPF: c.V_29015,
            observacoes: c.V_29016,
            endereco2: c.V_29018,
            identificacao: c.V_29019,
            tipoRG: c.V_31965,
            uF: c.V_31966,
            familiarReferencia: c.V_34274.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_34274) ? true : false,
          } as IResponsibleFamily)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected getFiltered(cadastroNo: number, startDate: Date, endDate: Date): Observable<IResponsibleFamily> {
    try {
      return this.getWithCache(cadastroNo, startDate, endDate);
      // .filter(f => !f.isRecurrent)
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(ano: number, responsibleFamily: IResponsibleFamily, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const uno: number = this.cnf.usuarioLogadoNo;
      const forms: IForm[] = this.mapToForm(responsibleFamily).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(responsibleFamily: IResponsibleFamily): IForm[] {
    try {
      return [
        { VariavelNo: 387, ValorDado: responsibleFamily.nomeHospede || '' },
        { VariavelNo: 394, ValorDado: responsibleFamily.ruaAv || '' },
        { VariavelNo: 395, ValorDado: responsibleFamily.complemento || '' },
        { VariavelNo: 396, ValorDado: responsibleFamily.numero || '' },
        { VariavelNo: 397, ValorDado: responsibleFamily.bairro || '' },
        { VariavelNo: 399, ValorDado: responsibleFamily.estado || '' },
        { VariavelNo: 402, ValorDado: responsibleFamily.idade || '' },
        { VariavelNo: 405, ValorDado: responsibleFamily.sexo || '' },
        { VariavelNo: 2193, ValorDado: responsibleFamily.cEP || '' },
        { VariavelNo: 2197, ValorDado: responsibleFamily.receberNotificacoesPorSMS ? 'SIM' : 'NÃO' },
        { VariavelNo: 2198, ValorDado: responsibleFamily.receberNotificacoesPormail ? 'SIM' : 'NÃO' },
        { VariavelNo: 2614, ValorDado: responsibleFamily.cidade || '' },
        { VariavelNo: 3292, ValorDado: responsibleFamily.telefoneFixo || '' },
        { VariavelNo: 3293, ValorDado: responsibleFamily.celular || '' },
        { VariavelNo: 3335, ValorDado: responsibleFamily.endereco || '' },
        { VariavelNo: 28051, ValorDado: responsibleFamily.prontuarioHosp || '' },
        { VariavelNo: 28936, ValorDado: responsibleFamily.codigo || '' },
        { VariavelNo: 28937, ValorDado: responsibleFamily.titulo || '' },
        { VariavelNo: 28938, ValorDado: responsibleFamily.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 28942, ValorDado: responsibleFamily.nome || '' },
        { VariavelNo: 28943, ValorDado: responsibleFamily.parentesco || '' },
        { VariavelNo: 28944, ValorDado: responsibleFamily.status || '' },
        { VariavelNo: 28945, ValorDado: responsibleFamily.descreva || '' },
        { VariavelNo: 28946, ValorDado: responsibleFamily.status || '' },
        { VariavelNo: 28947, ValorDado: responsibleFamily.eMail || '' },
        { VariavelNo: 29014, ValorDado: responsibleFamily.rG || '' },
        { VariavelNo: 29015, ValorDado: responsibleFamily.cPF || '' },
        { VariavelNo: 29016, ValorDado: responsibleFamily.observacoes || '' },
        { VariavelNo: 29018, ValorDado: responsibleFamily.endereco2 || '' },
        { VariavelNo: 29019, ValorDado: responsibleFamily.identificacao || '' },
        { VariavelNo: 31965, ValorDado: responsibleFamily.tipoRG || '' },
        { VariavelNo: 31966, ValorDado: responsibleFamily.uF || '' },
        { VariavelNo: 34274, ValorDado: responsibleFamily.familiarReferencia ? 'SIM' : 'NÃO' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }


  /** OBS: Usado apenas pelo card-prescription */
  getFromCadastroFiltro(cadastroNo: number, filtro: string, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true)
        .pipe(
          map(c => this.toAtribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  toAtribute(c) {
    try {
      const item = {
        ocorrenciaNo: c.ocorrenciaNo,
        nomeHospede: c.V_387,
        ruaAv: c.V_394,
        complemento: c.V_395,
        numero: c.V_396,
        bairro: c.V_397,
        estado: c.V_399,
        idade: c.V_402,
        sexo: c.V_405,
        cEP: c.V_2193,
        receberNotificacoesPorSMS: c.V_2197.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_2197) ? true : false,
        receberNotificacoesPormail: c.V_2198.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_2198) ? true : false,
        cidade: c.V_2614,
        telefoneFixo: c.V_3292,
        celular: c.V_3293,
        endereco: c.V_3335,
        prontuarioHosp: c.V_28051,
        codigo: c.V_28936,
        titulo: c.V_28937,
        habilitado: c.V_28938.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_28938) ? true : false,
        nome: c.V_28942,
        parentesco: c.V_28943,
        status: c.V_28944,
        descreva: c.V_28945,
        status1: c.V_28946,
        eMail: c.V_28947,
        rG: c.V_29014,
        cPF: c.V_29015,
        observacoes: c.V_29016,
        endereco2: c.V_29018,
        identificacao: c.V_29019,
        tipoRG: c.V_31965,
        uF: c.V_31966,
        familiarReferencia: c.V_34274.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_34274) ? true : false,
      } as IResponsibleFamily;
      return item;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAtribute', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist(ano: number, responsibleFamily: IResponsibleFamily, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return new Observable(observer => {
        this.subs.sink = this.getFromCadastro(ano, null, null)
          .pipe(toArray())
          .subscribe(items => {
            const founded = items.findIndex(f => this.glb.isEqual(f[compareFieldName], responsibleFamily[compareFieldName])) >= 0;
            observer.next(founded);
            if (!founded) {
              this.save(ano, responsibleFamily)
                .subscribe(s => observer.complete());
            } else {
              observer.complete();
            }
          });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
  }


}
