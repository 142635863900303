import { ConfigStateService } from '@medlogic/shared/state-config';
import {
  EnActivityType,
  IKeyValue,
  IFrameDialogComponent,
} from '@medlogic/shared/gecore';
import { EnMessageSeverity } from '@medlogic/shared/gecore';
import { IMessage } from '@medlogic/shared/gecore';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import {
  GlobalService,
  IAtividadeComponenteDAL,
  ConfigJsonService,
} from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { IUrl } from '@medlogic/shared/gecore';
import {
  UnsubscribeOnDestroyAdapter,
  EnRptType,
} from '@medlogic/shared/shared-interfaces';
import { DocGenerateDialogComponent } from '../../../shared/dialog/doc-generate-dialog/doc-generate-dialog.component';
import { NavigationService } from '../../../shared/service/navigation.service';
import { LibService } from '../../../shared/service/lib.service';
import { FormGroup } from '@angular/forms';
import { IBubble } from '../../../shared/interface/ibubble';
import { CalculatorService } from '../../../shared/service/calculator.service';
import { DadoDAL } from '../../../shared/model/dal/dado-dal';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { EnBubbleEvent } from '../../../shared/enum/en-bubble-event.enum';
import { IAtividade } from '../../../shared/interface/iatividade';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '@medlogic/shared/gecore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MedLogicNavigationService } from '@medlogic/medlogic/medlogic-navigation';

type NewType = IAtividadeComponenteDAL[];

// Barra de botões da Atividade.
@Component({
  selector: 'lib-button-bar',
  templateUrl: './button-bar.component.html',
  styleUrls: ['./button-bar.component.css'],
})
export class ButtonBarComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, OnChanges
{
  @Input() atividade: IAtividade;
  @Input() componentes: IAtividadeComponenteDAL[];
  @Input() enTheme = EnTheme.default;
  @Input() tarefaNo: number;
  @Input() formErrors: any[];
  @Input() msgs: IMessage[] = []; // Preencha essa variável para que seja exibido uma div no rodapé.
  @Input() activityType: EnActivityType;
  @Input() saveInList: boolean;
  @Input() addToHistory = true;
  @Input() formGroup: FormGroup;
  @Input() isMobile: boolean;
  @Input() isLoading = false;
  @Input() saved: boolean;
  @Input() canShowSavedMessages: boolean;
  @Input() printOnly: string[]; // Se preenchido, somente listará para impressão os documentos com o título especificado
  @Input() isEditMode: boolean;
  @Input() isReadOnly: boolean;
  @Input() showBackButton = true;

  public get showButtonBar(): boolean {
    return this.hasPrint || this.hasDelete || this.hasAttachment;
  }

  public get hasPrint(): boolean {
    if (this.cnfJson.showOnlySaveButton) {
      return false;
    }
    return this.atividade.ShowPrint;
  }

  public get textPrint(): string {
    return this.atividade.TextPrint;
  }

  public get hasAttachment(): boolean {
    if (this.cnfJson.showOnlySaveButton) {
      return false;
    }
    return this.atividade.ShowAttachment;
  }

  public get textAttachment(): string {
    return this.atividade.TextAttachment;
  }

  // Essa lógica faz com que o estado mude mais de uma vez antes da renderização da página, o que gera erro.
  // public get hasDelete(): boolean {
  // 	return this.atividade.ShowSave && this.hasDeleteBtn() && !this.config.isReadOnly;
  // }

  private _hasDelete: boolean;
  public get hasDelete(): boolean {
    return this._hasDelete;
  }
  public set hasDelete(v: boolean) {
    this._hasDelete = v;
  }

  public get textDelete(): string {
    return this.msg.BUTTON_DELETE;
  }

  attachmentCount = 1;

  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView. */
  @Output() eventBubble: EventEmitter<IBubble> = new EventEmitter<IBubble>();
  onEventBubble($event: any) {
    this.eventBubble.emit($event);
  }

  constructor(
    public config: ConfigStateService,
    public cnfJson: ConfigJsonService,
    protected log: LogService,
    protected dadoDAL: DadoDAL,
    protected global: GlobalService,
    protected calc: CalculatorService,
    protected lib: LibService,
    protected nav: NavigationService,
    protected matDialog: MatDialog,
    protected msg: MsgPtBR,
    protected snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private nav2: MedLogicNavigationService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.hasDelete =
        this.isEditMode &&
        this.atividade?.ShowSave &&
        this.hasDeleteBtn() &&
        !this.config.isReadOnly && !this.isReadOnly;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  ngOnChanges() {
    try {
      if (this.saved) {
        if (this.canShowSavedMessages) {
          this.showSaveMessage(true);
        }
        this.afterSaved();
        this.saved = false;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnChanges', error.message);
    }
  }

  /* Exibe uma mensagem, que desaparece automaticamente após um tempo.
   * Também previne que a mesma mensagem seja exibida novamente, ao mesmo tempo que a outra identica.
   */
  protected showMessage(msg: IMessage, interval: number = 3000): void {
    try {
      const prevMessage = this.msgs.filter(
        (f) =>
          this.global.isEqual(f.detail, msg.detail) &&
          this.global.isEqual(f.summary, msg.summary)
      );
      if (!prevMessage || prevMessage.length <= 0) {
        if (this.msgs && this.msgs.length > 0) {
          this.snackBar.open(
            this.msgs.map((m) => m.detail).join(' '),
            this.msgs[0].summary,
            {
              duration: interval,
              verticalPosition: 'top',
            }
          );
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showMessage', error.message);
    }
  }

  /* Mensagem de tentativa de salvamento. */
  protected showSaveMessage(isSuccess: boolean): void {
    try {
      if (isSuccess) {
        this.showMessage({
          severity: EnMessageSeverity.success,
          summary: this.msg.ALERT_CONFIRMATION_TITLE,
          detail: this.msg.ALERT_ACTIVITY_SAVED,
        });
      } else {
        this.showMessage({
          severity: EnMessageSeverity.error,
          summary: this.msg.ALERT_WARNING_TITLE,
          detail: this.msg.ALERT_ACTIVITY_SAVE_FAILED,
        });
      }
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'showSaveMessage',
        error.message
      );
    }
  }

  /* Avisa o controle que a mensagem já foi exibida, para zerar o saved. */
  protected afterSaved(): void {
    try {
      this.eventBubble.emit({
        $event: null,
        bubbleEvent: EnBubbleEvent.afterSavedMessage,
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSaved', error.message);
    }
  }

  /* Identifica se a atividade atual é do tipo lista. */
  protected isListDetail(): boolean {
    try {
      const result = this.activityType === EnActivityType.ListDetail; // && (this.saveInList.toString() === 'true')) ||
      // (this.activityType != EnActivityType.ListDetail);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasSaveInList', error.message);
    }
    return false;
  }

  /* Resgata os valores inseridos no formGroup e armazena em um novo item.  */
  protected getValueFormGroup(newItem: any): any {
    try {
      if (!newItem) {
        newItem = {
          index: -1,
          label: '',
        };
      }
      const values = this.formGroup.getRawValue();
      for (const clm in values) {
        if (clm !== 'index') {
          if (
            this.lib.getVariavelNoFromId(clm) !== this.config.listIdVariableNo
          ) {
            newItem[clm] = this.lib.getGEValue(values[clm]);
          }
          if (
            this.lib.getVariavelNoFromId(clm) ===
            this.config.listTitleVariableNo
          ) {
            newItem.label = values[clm];
          }
        }
      }
      return newItem;
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'getValueFormGroup',
        error.message
      );
    }
  }

  /* Exibe mensagem de confirmação e, se confirmado, exclui o item do Cadastro permanentemente. */
  onDelete($event: any): void {
    try {
      this.isLoading = true;
      const message = {
        firstButtonLabel: 'Não',
        title: 'Confirmação',
        icon: 'fa-times',
        text: `Você tem certeza que quer excluir esse item? Essa exclusão NÃO poderá ser desfeita!`,
        acceptFunc: (result: boolean) => {
          try {
            if (result) {
              // TODO: Encontrar uma forma de limpar o cache
              this.eventBubble.emit({
                $event,
                bubbleEvent: EnBubbleEvent.listDelete,
                params: {
                  ano: this.config.ModeloAtividadeNo,
                  ono: this.config.OcorrenciaNo.value,
                  pno: this.atividade.ProcessoNo,
                },
              } as IBubble);
              // Retorna para o grid
              this.eventBubble.emit({
                $event,
                bubbleEvent: EnBubbleEvent.gotoErpGrip,
              } as IBubble);
            }
          } catch (error) {
            this.log.Registrar(
              this.constructor.name,
              'onDelete.acceptFunc',
              error.message
            );
          }
        },
      };
      this.onAlertDialog($event, message, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDelete', error.message);
    }
  }

  /* Emite um evento para a Atividade View para exibição de uma janela de Alerta. */
  protected onAlertDialog(
    $event: any,
    message: any,
    hasConfirmButton: boolean
  ): void {
    try {
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.alertDialog,
        params: { message, hasConfirmButton },
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAlertDialog', error.message);
    }
  }

  /* Clique no botão anexos. */
  onAttachment($event: any): void {
    try {
      // TODO: Mudar para > 0 depois de implementado o retorno do número de arquivos
      const url = this.cnfJson.attachmentUrl(
        this.config.OcorrenciaNo.value,
        this.config.ModeloAtividadeNo,
        this.config.usuarioLogadoNo,
        '60vh'
      );
      if (this.attachmentCount >= 0) {
        this.eventBubble.emit({
          $event,
          bubbleEvent: EnBubbleEvent.openAttachments,
          params: {
            url,
            ono: this.config.OcorrenciaNo.value,
            ano: this.config.ModeloAtividadeNo,
            tno: this.config.TarefaNo,
            width: '100%',
            height: '100vh',
          },
        } as IBubble);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAttachment', error.message);
    }
  }

  /* Clique no botão imprimir.  */
  onPrint($event: any): void {
    try {
      if (!this.isReadOnly) {
        this.eventBubble.emit({
          $event,
          bubbleEvent: EnBubbleEvent.activitySave,
        } as IBubble);
      }
      let var1 = '';
      let var2 = '';
      try {
        var1 = this.componentes.filter(
          (f) => f.VariavelNo === this.atividade.CalcVariavelIdentificacao1No
        )[0].ValorTexto;
        var2 = this.componentes.filter(
          (f) => f.VariavelNo === this.atividade.CalcVariavelIdentificacao2No
        )[0].ValorTexto;
      } catch {}
      this.imprimir(
        this.config.OcorrenciaNo.value,
        this.config.usuarioLogadoNo,
        this.config.ModeloAtividadeNo,
        -1,
        this.config.TarefaNo,
        this.config.processoNo,
        var1,
        var2,
        this.atividade.UrlPrint,
        '0',
        this.componentes
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onPrint', error.message);
    }
  }

  /* Irá abrir uma modal e renderizar uma url da intranet em iframe. */
  protected imprimir(
    ocorrenciaNo: number,
    usuarioLogadoNo: number,
    atividadeNo: number,
    atividadeMultiplaNo: number,
    tarefaNo: number,
    processoNo: number,
    complemento1: string,
    complemento2: string,
    urlPrint: string,
    tabIndex: string,
    componentes: NewType
  ): void {
    try {
      const urls = this.montarUrlRelatorio(
        urlPrint.indexOf('?') === -1 ? '?' : '&',
        ocorrenciaNo,
        usuarioLogadoNo,
        atividadeNo,
        atividadeMultiplaNo,
        tarefaNo,
        processoNo,
        complemento1,
        complemento2,
        urlPrint
      );
      const bottomSheet = this.bottomSheet.open(BottomSheetComponent, {
        data: { urls },
      });
      this.subs.sink = bottomSheet
        .afterDismissed()
        .subscribe((selectedItem) => {
          const width = '100vw';
          const height = '450px';
          const index = selectedItem
            ? urls.findIndex((f) =>
                this.global.isEqual(f.title, selectedItem.title)
              )
            : -1;
          if (index >= 0) {
            const rpt = urls[index];
            this.generateRptAccordingType(componentes, rpt, width, height);
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'method', error.message);
    }
  }

  private generateRptAccordingType(
    componentes: NewType,
    rpt: IUrl,
    width: string,
    height: string
  ) {
    try {
      let dialogRef: MatDialogRef<
        DocGenerateDialogComponent | IFrameDialogComponent,
        any
      >;
      const url = rpt?.url.split('?')[0];
      switch (rpt?.enRptType) {
        case EnRptType.WordTemplate:
          const replaceVariables = componentes.map(
            (m) =>
              ({
                key: m.VariavelNo,
                value: (m.Valor || '').toString(),
              } as IKeyValue)
          );
          const fileName = `${rpt?.title}.docx`;
          const ctrBarcode = componentes
            .filter((f) => f.Valor)
            .find((f) => f.Valor.toString().toUpperCase().includes('BARCODE'));
          const ctrQrcode = componentes
            .filter((f) => f.Valor)
            .find((f) => f.Valor.toString().toUpperCase().includes('QRCODE'));
          const barcodeNum = ctrBarcode
            ? ctrBarcode.Valor.toString().split(';')[1]
            : null;
          const arrQr = ctrQrcode?.Valor?.toString().split(';');
          const qrcodeUrl = ctrQrcode
            ? this.global.urlJoin([arrQr[1], barcodeNum, arrQr[2], arrQr[3]])
            : null;
          const isDark = this.enTheme === EnTheme.black;
          dialogRef = this.matDialog.open(DocGenerateDialogComponent, {
            width,
            height,
            data: {
              url,
              fileName,
              barcodeNum,
              qrcodeUrl,
              replaceVariables,
              isDark,
            },
          });
          break;
        case EnRptType.InternalUrl:
          this.nav2.navigateTo([rpt?.url]);
          break;
        default:
          height = '100%';
          dialogRef = this.matDialog.open(IFrameDialogComponent, {
            width,
            height,
            id: 'rpt-custom',
            minWidth: width,
            maxWidth: width,
            minHeight: height,
            maxHeight: height,
            data: {
              url,
              width,
              height,
            },
          });
          break;
      }
      if (dialogRef) {
        this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
          this.eventBubble.emit({
            $event: null,
            bubbleEvent: EnBubbleEvent.afterPrint,
          } as IBubble);
        });
      }
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'generateRptAccordingType',
        error.message
      );
    }
  }

  /* Define a url do relatório baseado em parâmetros. */
  protected montarUrlRelatorio(
    tipoQuery: any,
    ocorrenciaNo: number,
    usuarioLogadoNo: number,
    atividadeNo: number,
    atividadeMultiplaNo: number,
    tarefaNo: number,
    processoNo: number,
    complemento1: string,
    complemento2: string,
    urlPrint: string
  ): IUrl[] {
    try {
      const lstUrlPrint = urlPrint.split(';');
      // Aba$NomeRelatorio$Url;Aba$NomeRelatorio$Url - Para Permitir configurar varios relatórios por aba em uma mesma atividade
      if (lstUrlPrint.length > 0) {
        const lst = lstUrlPrint.map((item) => {
          const lstUrlPrintSplit = item.split('$');
          const title = lstUrlPrintSplit[1];
          urlPrint = lstUrlPrintSplit[2];
          const enRptType = this.getRptType(urlPrint);
          const url = this.getUrlRelatorio(
            enRptType,
            tipoQuery,
            urlPrint,
            ocorrenciaNo,
            usuarioLogadoNo,
            atividadeNo,
            atividadeMultiplaNo,
            tarefaNo,
            processoNo,
            complemento1,
            complemento2
          );
          return { title, url, enRptType } as IUrl;
        });
        let lstPrint = this.global.distinctBy<IUrl>(lst, 'title');
        lstPrint =
          this.printOnly?.length > 0
            ? lstPrint.filter((f) =>
                this.printOnly
                  .map((m) => m.toUpperCase())
                  .includes(f.title.toUpperCase())
              )
            : lstPrint;
        return lstPrint;
      } else {
        const enRptType = this.getRptType(urlPrint);
        return [{ title: urlPrint, url: urlPrint, enRptType } as IUrl];
      }
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'montarUrlRelatorio',
        error.message
      );
    }
    return null;
  }

  protected getUrlRelatorio(
    enRptType: EnRptType,
    tipoQuery: any,
    urlPrint: string,
    ocorrenciaNo: number,
    usuarioLogadoNo: number,
    atividadeNo: number,
    atividadeMultiplaNo: number,
    tarefaNo: number,
    processoNo: number,
    complemento1: string,
    complemento2: string
  ): string {
    try {
      const token = this.config.baseUsuarioToken;
      switch (enRptType) {
        case EnRptType.Custom:
          return urlPrint;
        case EnRptType.InternalUrl:
          // return `${urlPrint}/${ocorrenciaNo}/${usuarioLogadoNo}/${atividadeNo}/${atividadeMultiplaNo}/${tarefaNo}/${processoNo}/${encodeURI(complemento1.replace(/\//, '-'))}/${encodeURI(complemento2.replace(/\//g, '-'))}/${encodeURI(token)}`;
          // Token desnecessário
          complemento1 = complemento1
            ? encodeURI(complemento1.replace(/\//g, '-'))
            : '-1';
          complemento2 = complemento2
            ? encodeURI(complemento2.replace(/\//g, '-'))
            : '-1';
          return `${urlPrint}/${ocorrenciaNo}/${usuarioLogadoNo}/${atividadeNo}/${atividadeMultiplaNo}/${tarefaNo}/${processoNo}/${complemento1}/${complemento2}`;
        default:
          tipoQuery = urlPrint?.indexOf('?') === -1 ? '?' : '&';
          urlPrint = `${urlPrint}${tipoQuery}`;
          let urlRelatorio = '';
          if (urlPrint?.indexOf('?') < 0) {
            urlRelatorio = `${urlPrint}?ocrNo=${ocorrenciaNo}&usoNo=${usuarioLogadoNo}&atvNo=${atividadeNo}&atvmNo=${atividadeMultiplaNo}&trfNo=${tarefaNo}&proNo=${processoNo}&cpm1=${complemento1}&cpm2=${complemento2}&Token=${token}`;
          } else {
            urlRelatorio = `${urlPrint}ocrNo=${ocorrenciaNo}&usoNo=${usuarioLogadoNo}&atvNo=${atividadeNo}&atvmNo=${atividadeMultiplaNo}&trfNo=${tarefaNo}&proNo=${processoNo}&cpm1=${complemento1}&cpm2=${complemento2}&Token=${token}`;
          }
          return urlRelatorio;
      }
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'getUrlRelatorio',
        error.message
      );
    }
    return null;
  }

  private getRptType(urlPrint: string): EnRptType {
    try {
      const url = urlPrint?.toUpperCase() || '';
      if (url.includes('RPTGERARDOCOCORRENCIA') || url.includes('.DOC')) {
        return EnRptType.WordTemplate;
      } else if (url.includes('.ASPX')) {
        return EnRptType.ReportView;
        // rpt/ É a url base para os relatórios que deve ser configurada na rota do app.
      } else if (
        url.includes(this.cnfJson.baseUrl.toUpperCase()) ||
        url.includes('RPT/')
      ) {
        return EnRptType.Custom;
      } else if (!url.startsWith('http')) {
        return EnRptType.InternalUrl;
      }
      return EnRptType.ExternalUrl;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRptType', error.message);
    }
  }

  // TODO: também deve ser validado se o usuário possui permissão para excluir o item do Cadastro.
  /* Se a Ocorrência não tiver sido criada, não exibir o botão de exclusão.
   * Há ainda uma validação das configurações do módulo, no config.json.
   */
  protected hasDeleteBtn(): boolean {
    try {
      // FIXME: Essa lógica está fixa, botão Excluir será sempre exibido.
      return true;
      // if (this.cnfJson.showOnlySaveButton) {
      //   return false;
      // }
      // if (this.atividade.ShowDelete !== null) {
      //   return this.atividade.ShowDelete;
      // }
      // const modAdmissao = this.cnfJson.modules.find((f) => f.name === 'ADMISSÃO');
      // const blDelete = modAdmissao && modAdmissao.canDelete !== undefined ? modAdmissao.canDelete : true;
      // return this.config.OcorrenciaNo.value > 0 && blDelete;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasDeleteBtn', error.message);
    }
    return false;
  }

  /* Verifica se o tema black deve ser aplicado. */
  isBlack(): boolean {
    try {
      return this.enTheme === EnTheme.black;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isBlack', error.message);
    }
    return false;
  }

  onBack($event: any): void {
    try {
      // TODO: Talvez devesse dar um clean no generic para evitar reaproveitamento errôneo.
      this.nav2.navigateBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }
}
