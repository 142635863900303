import { IAtividade } from '../../interface/iatividade';
import { Observable } from 'rxjs';
import { ObjectDAL } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { map } from 'rxjs/operators';
import { WebService } from '@medlogic/shared/shared-data-access';
import { of } from 'rxjs';
@Injectable()
export class AtividadeDAL extends ObjectDAL {
  constructor(webService: WebService, log: LogService, global: GlobalService) {
    super(webService, log, global);
  }

  /*Retorna uma atividade. */
  get(atividadeNo: number): Observable<IAtividade> {
    try {
      // return this.getWithCache<IAtividade>(
      //     this.getAtividadeCall,
      //     atividadeNo
      // );
      return this.getAtividadeCall(atividadeNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'get', error.message);
    }
    return null;
  }

  /*Retorna a primeira atividade de um processo. */
  getPrimeiraAtividade(usuarioLogadoNo: number, processoNo: number): Observable<IAtividade> {
    try {
      return this.getWithCache<IAtividade>(this.getPrimeiraAtividadeCall, usuarioLogadoNo, processoNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getPrimeiraAtividade', error.message);
    }
    return of(null);
  }

  //
  protected getAtividadeCall(atividadeNo: number): Observable<IAtividade> {
    try {
      const method = 'getAtividade';
      const atividade = this.webService.connectWithCache<IAtividade>(method, [
        { name: 'atividadeNo', value: atividadeNo }
      ]);
      return atividade;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getatividade', error.message);
    }
    return of(null);
  }

  // <getPrimeiraAtividade xmlns="GE/ws/">
  //   <token>dKde-38aK-43D3ew-d85Qw</token>
  //   <usuarioLogadoNo>116</usuarioLogadoNo>
  //   <processoNo>146</processoNo>
  // </getPrimeiraAtividade>
  /* Retorna a primeira atividade de um processo. */
  protected getPrimeiraAtividadeCall(usuarioLogadoNo: number, processoNo: number): Observable<IAtividade> {
    try {
      const method = 'getPrimeiraAtividade';
      const atividade = this.webService.connectWithCache<IAtividade>(method, [
        { name: 'usuarioLogadoNo', value: usuarioLogadoNo },
        { name: 'processoNo', value: processoNo }
      ]);
      return atividade;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getPrimeiraAtividade', error.message);
    }
    return of(null);
  }

  /*Retorna uma atividade de um processo específico. */
  getAtividadePorAtividadeNoProcessoNo(
    usuarioNo: number,
    atividadeNo: number,
    processoNo: number,
    clienteId: number = -1
  ): Observable<IAtividade> {
    try {
      return this.getWithCache<IAtividade>(
        this.getAtividadePorAtividadeNoProcessoNoCall,
        usuarioNo,
        atividadeNo,
        processoNo,
        clienteId
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAtividadePorAtividadeNoProcessoNo', error.message);
    }
    return of(null);
  }

  // A Atividade não se modifica em função da Ocorrencia, o modelo é exatamente o mesmo
  //     <getAtividadePorAtividadeNoProcessoNo xmlns="GE/ws/">
  //     <token>dKde-38aK-43D3ew-d85Qw</token>
  //     <usuarioLogadoNo>116</usuarioLogadoNo>
  //     <atividadeNo>429</atividadeNo>
  //     <processoNo>146</processoNo>
  //     <clienteId>-1</clienteId>
  // </getAtividadePorAtividadeNoProcessoNo>
  protected getAtividadePorAtividadeNoProcessoNoCall(
    usuarioNo: number,
    atividadeNo: number,
    processoNo: number,
    clienteId: number = -1
  ): Observable<IAtividade> {
    try {
      const method = 'getAtividadePorAtividadeNoProcessoNo';
      const atividade = this.webService.connect<any>(method, [
        { name: 'usuarioLogadoNo', value: usuarioNo },
        { name: 'atividadeNo', value: atividadeNo },
        { name: 'processoNo', value: processoNo },
        { name: 'clienteId', value: clienteId }
      ]);
      return atividade;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAtividadePorAtividadeNoProcessoNoCall', error.message);
    }
    return of(null);
  }

  //   <getAtividadePorTarefaNo xmlns="GE/ws/">
  //   <token>dKde-38aK-43D3ew-d85Qw</token>
  //   <tarefaNo>79551</tarefaNo>
  //   <usuarioLogadoNo>303</usuarioLogadoNo>
  // </getAtividadePorTarefaNo>
  /* Utilizado no Multiatividade. */
  getAtividadePorTarefaNo(usuarioNo: number, tarefaNo: number): Observable<IAtividade> {
    try {
      const method = 'getAtividadePorTarefaNo';
      const atividade = this.webService.connect<any>(method, [
        { name: 'tarefaNo', value: tarefaNo },
        { name: 'usuarioLogadoNo', value: usuarioNo }
      ]);
      return atividade;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAtividadePorTarefaNo', error.message);
    }
    return of(null);
  }

  /*Retorna uma multi-atividade de um processo específico. */
  getAtividadeMultiplaByTarefaNo(
    usuarioNo: number,
    atividadeNo: number,
    processoNo: number,
    tarefaNo: number = -1
  ): Observable<IAtividade[]> {
    try {
      return this.getWithCache(
        this.getAtividadeMultiplaByTarefaNoCall,
        usuarioNo,
        atividadeNo,
        processoNo,
        tarefaNo
      ).pipe(map((m: any) => m.Atividade));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAtividadeMultiplaByTarefaNo', error.message);
    }
    return of(null);
  }

  // A Atividade não se modifica em função da Ocorrencia, o modelo é exatamente o mesmo
  //   <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"
  //   xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
  //   <soap:Body>
  //     <getAtividadeMultiplaByTarefaNo xmlns="GE/ws/">
  //       <token>dKde-38aK-43D3ew-d85Qw</token>
  //       <atividadeNo>9882</atividadeNo>
  //       <processoNo>517</processoNo>
  //       <tarefaNo>79551</tarefaNo>
  //     </getAtividadeMultiplaByTarefaNo>
  //   </soap:Body>
  // </soap:Envelope>
  protected getAtividadeMultiplaByTarefaNoCall(
    usuarioNo: number,
    atividadeNo: number,
    processoNo: number,
    tarefaNo: number = -1
  ): Observable<IAtividade> {
    try {
      const method = 'getAtividadeMultiplaByTarefaNo';
      const atividade = this.webService.connect<any>(method, [
        { name: 'atividadeNo', value: atividadeNo },
        { name: 'processoNo', value: processoNo },
        { name: 'tarefaNo', value: tarefaNo }
      ]);
      return atividade;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAtividadeMultiplaByTarefaNoCall', error.message);
    }
    return of(null);
  }

  /*Retorna uma atividade de uma multi-atividade de um processo específico. */
  getAtividadeByAtividadeMultipla(
    usuarioNo: number,
    atividadeNo: number,
    atividadeMultiplaNo: number,
    processoNo: number,
    ocorrenciaNo: number = -1
  ): Observable<IAtividade> {
    try {
      return this.getWithCache(
        this.getAtividadeByAtividadeMultiplaCall,
        usuarioNo,
        atividadeNo,
        atividadeMultiplaNo,
        processoNo,
        ocorrenciaNo
      );
      // ).pipe(map((m: any) => m.Atividade));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAtividadeByAtividadeMultipla', error.message);
    }
    return of(null);
  }

  // A Atividade não se modifica em função da Ocorrencia, o modelo é exatamente o mesmo
  // 	<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"
  //   xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
  //   <soap:Body>
  //     <getAtividadeByAtividadeMultipla xmlns="GE/ws/">
  //       <token>dKde-38aK-43D3ew-d85Qw</token>
  //       <atividadeNo>2247</atividadeNo>
  //       <atividadeMultiplaNo>9882</atividadeMultiplaNo>
  //       <processoNo>517</processoNo>
  //       <ocorrenciaNo>526821</ocorrenciaNo>
  //     </getAtividadeByAtividadeMultipla>
  //   </soap:Body>
  // </soap:Envelope>
  protected getAtividadeByAtividadeMultiplaCall(
    usuarioNo: number,
    atividadeNo: number,
    atividadeMultiplaNo: number,
    processoNo: number,
    ocorrenciaNo: number = -1
  ): Observable<IAtividade> {
    try {
      const method = 'getAtividadeByAtividadeMultipla';
      const atividade = this.webService.connect<any>(method, [
        { name: 'atividadeNo', value: atividadeNo },
        { name: 'atividadeMultiplaNo', value: atividadeMultiplaNo },
        { name: 'processoNo', value: processoNo },
        { name: 'ocorrenciaNo', value: ocorrenciaNo }
      ]);
      return atividade;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAtividadeByAtividadeMultiplaCall', error.message);
    }
    return of(null);
  }


}
