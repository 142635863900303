import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IListItem, ISmartList, LogService } from '@medlogic/shared/shared-interfaces';
import { fadeAnimation } from '@medlogic/shared/utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'ui-smart-list-calendar',
  templateUrl: './smart-list-calendar.component.html',
  styleUrls: ['./smart-list-calendar.component.css'],
  animations: [fadeAnimation]
})
export class SmartListCalendarComponent implements OnInit {

  @Input() groups: ISmartList[];
  @Input() search: string;
  @Input() imgUrl: string;
  @Input() enTheme = EnTheme.black;

  @Output() itemClick = new EventEmitter<any>();

  ENTHEME = EnTheme;

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  onItemClick(item: IListItem<any>): void {
    try {
      this.itemClick.emit(item);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onItemClick', error.message);
    }
  }
}
