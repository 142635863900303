import {
  // DropdownModule,
  // RatingModule,
  // ConfirmationService,
  // SliderModule,
  // CalendarModule,
  // InputTextareaModule,
  // EditorModule,
  // SharedModule,
  // ConfirmDialogModule,
  // MessagesModule,
  // TooltipModule,
  // GMapModule,
  // OverlayPanelModule,
  // TreeTableModule,
  // SelectButtonModule,
  // FieldsetModule,
  // PanelModule,
  // ListboxModule,
  // DialogModule,
  // TableModule
} from 'primeng';
import { Optional, SkipSelf, NgModule } from '@angular/core';
import { ToggleButtonModule } from 'primeng/togglebutton';

const NGMODULES = [
  // TreeTableModule,
  ToggleButtonModule,
  // DropdownModule,
  // RatingModule,
  // SliderModule,
  // CalendarModule,
  // InputTextareaModule,
  // EditorModule,
  // SharedModule,
  // // DataTableModule,
  // ConfirmDialogModule,
  // MessagesModule,
  // TooltipModule,
  // GMapModule,
  // OverlayPanelModule,
  // SelectButtonModule,
  // FieldsetModule,
  // PanelModule,
  // ListboxModule,
  // DialogModule,
  // TableModule
];



@NgModule({
  imports: [
    NGMODULES
  ],
  exports: [
    NGMODULES
  ],
  providers: [
    // ConfirmationService
  ]
})
export class PrimeNgModule {
  /* Para previnir de multiplas importações desse módulo, que deve ser singleton */
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: PrimeNgModule
  ) {
    if (parentModule) {
      throw new Error('PrimeNgModule is already loaded. Import it in the AppModule only');
    }
  }
}
