import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ActivityEffects } from './+state/activity.effects';

import * as fromActivity from './+state/activity.reducer';

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromActivity.activityFeatureKey, fromActivity.reducer),
  EffectsModule.forFeature([ActivityEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES]
})
export class StateActivityModule { }
