import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogService, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetConfirmComponent } from '../../dialog/bottom-sheet-confirm/bottom-sheet-confirm.component';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'lib-cmp-webcam',
  templateUrl: './cmp-webcam.component.html',
  styleUrls: ['./cmp-webcam.component.css']
})
export class CmpWebcamComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('video', { static: true })
  public video: ElementRef;

  @ViewChild('canvas', { static: true })
  public canvas: ElementRef;

  message = 'Nenhuma imagem selecionada!';
  action = 'OK';
  context: any;
  @Output() dadosOut: EventEmitter<string> = new EventEmitter<string>();
  @Output() back = new EventEmitter();

  public captures: any[] = [];
  constructor(
    private bottomSheet: MatBottomSheet,
    private glb: GlobalService,
    private log: LogService,
    public snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit() {
  }

  public ngAfterViewInit() {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.play();
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngAfterViewInit', error.message);
    }
  }

  /* Método para captar a foto da webcam. */
  capture($event: any): void {
    try {
      this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, 640, 480);
      this.context = this.canvas.nativeElement.toDataURL('image/png');
      this.subs.sink = this.bottomSheet
        .open(BottomSheetConfirmComponent)
        .afterDismissed()
        .subscribe(wasConfirmed => {
          if (wasConfirmed) {
            this.uploadFotoCtrImage($event);
          } else {
            this.context = null;
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'capture', error.message);
    }
  }

  /* Método para converter a imagem em uma string que é aceita pelo servidor. */
  uploadFotoCtrImage($event: any): void {
    try {
      let pic: string;
      let dados: any;
      pic = this.context;
      if (pic) {
        const dataBlob = this.base64toBlob(pic.split(',')[1], 'image/png');
        dados = { imageData: dataBlob, fileName: `photo-${this.glb.dateToYYYYMMddThhmmss(new Date())}.png` };
        this.dadosOut.emit(dados);
      } else {
        this.snackBar.open(this.message, this.action, {
          duration: 3000, horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'UploadFotoCtrImage', error.message);
    }
  }

  base64toBlob(base64Data: string, contentType: string): Blob {
    try {
      contentType = contentType || '';
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'base64toBlob', error.message);
    }
    return null;
  }

  onBack($event: any): void {
    try {
      this.turnoff();
      this.back.emit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  ngOnDestroy(): void {
    try {
      this.turnoff();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnDestroy', error.message);
    }
  }

  protected turnoff(): void {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = this.video.nativeElement.srcObject;
        stream.getTracks()
          .forEach((track: any) => {
            track.stop();
            stream.removeTrack(track);
          });
        this.video.nativeElement.srcObject = null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'turnoff', error.message);
    }
  }


}
