import { Injectable } from '@angular/core';
import { routeDayOfWeek } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirDayOfWeekService extends FhirGenericService {

  protected url = routeDayOfWeek;


}
