import { createReducer, on, Action } from '@ngrx/store';
import { loadExames, setExame, fetchSuccessExame, fetchErrorExame, saveSuccessExame, saveFailExame, saveAllExame, saveExame, resetExame, setCategoriaExame, loadExamesAndResultados, cleanExame } from './exame.actions';
import { IExame, IExameState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { update } from '@medlogic/shared/utils';

export const initialState: IExameState = {
  exame: null,
  exames: [],
  error: null,
  categoria: null
};

const exameReducerObj = createReducer(initialState,
  on(loadExames, (state, payload) => ({
    ...state,
    bannerTitle: 'Exames',
    exame: null,
    exames: []
  })),
  on(loadExamesAndResultados, (state, payload) => ({
    ...state,
    bannerTitle: 'Exames',
    exame: null,
    exames: []
  })),
  on(resetExame, (state, payload) => initialState),
  on(fetchSuccessExame, (state, payload) => ({
    ...state, // FIXME: necessário, pois o retorno vazio está trazendo um array com um elemento null
    exames: payload?.exames[0] ? payload?.exames : [],
    error: null
  })),
  on(setExame, (state, payload) => ({
    ...state,
    exame: payload?.exame,
    error: null
  })),
  on(saveExame, (state, payload) => ({
    ...state,
    exame: payload?.exame,
    error: null
  })),
  on(fetchErrorExame, (state, payload) => ({
    ...state,
    exames: null,
    error: 'invalid-token'
  })),
  on(saveAllExame, (state, payload) => ({
    ...state, // Aproximação otimista, ou seja, altera o estado mesmo antes do retorno do serviço.
    exames: update<IExame>(state?.exames, payload?.exame),
    exame: payload?.exame,
    error: null
  })),
  on(saveSuccessExame, (state, payload) => {
    return ({
      ...state,
      exames: update<IExame>(state?.exames, payload?.exame),
      exame: payload?.exame,
      error: null
    });
  }),
  on(saveFailExame, (state, payload) => ({
    ...state,
    exames: update<IExame>(state?.exames, payload?.exame),
    exame: payload?.exame,
    error: 'fail-on-save--exame'
  })),
  on(setCategoriaExame, (state, payload) => ({
    ...state,
    categoria: payload?.categoria
  })),
  on(cleanExame, (state, payload) => ({ ...initialState }))
);

export function exameReducer(state: IExameState | undefined, action: Action) {
  return exameReducerObj(state, action);
}
