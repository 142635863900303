import { IAppMedlogicState, IPerson } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { withLatestFrom } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  PersonActionTypes,
  fetchErrorPersons,
  fetchSuccessPersons,
} from './person.actions';
import {
  PersonCustomService,
} from '@medlogic/medlogic/medlogic-data-access';
import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';


@Injectable()
export class PersonEffects {

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private personSrv: PersonCustomService, // TODO: ContaService changed to the API
  ) { }

  loadRelationships$ = createEffect(() => this.actions$
    .pipe(
      ofType(PersonActionTypes.LoadAllPersonRelationships),
      withLatestFrom(this.store),
      switchMap(([never, state]) => [
        setIsLoading({ isLoading: false })
      ]),
      catchError((e: any) => {
        console.log(e);
        this.store.dispatch(setIsLoading({ isLoading: false }));
        return of(fetchErrorPersons(null));
      })
    ));

  loadPersons$ = createEffect(() => this.actions$
    .pipe(
      ofType(PersonActionTypes.LoadPersons),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        // TODO: Há oportunidade de otimização: estão sendo carregados todos os idosos, inclusive os inativos e filrado na tela. O ideal seria outro effect, para trazer os inativos.
        return this.personSrv.getAuthorizedPersons(state?.tenant?.selectedTenant?.personAno, state?.tenant?.selectedTenant?.cadAutorizacaoCuidadoNo, true)
          .pipe(
            first(),
            switchMap((persons: IPerson[]) => [
              persons ? fetchSuccessPersons({ persons }) : fetchErrorPersons(null),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              this.store.dispatch(setIsLoading({ isLoading: false }));
              return of(fetchErrorPersons(null));
            })
          );
      })
    )
  );

  protected firstUpperCase(str: string): string {
    try {
      const siglas = ['SIGLA'];
      if (siglas.includes(str.toUpperCase())) {
        return str.toUpperCase();
      }
      return this.glb.primeiraMaiuscula(str);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'firstUpperCase', error.message);
    }
    return str;
  }


}
