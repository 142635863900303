import { ITarefa } from '../interface/itarefa';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'libTaskSearch'
})
export class TaskSearchPipe implements PipeTransform {

  transform(tasks: Array<ITarefa>, keyword: string): Array<ITarefa> {
    if (!tasks) { return tasks; };
    return tasks.filter(f =>
      f.date.indexOf(keyword) >= 0 ||
      f.time.indexOf(keyword) >= 0 ||
      f.executorNome.indexOf(keyword) >= 0 ||
      f.identificacao1.indexOf(keyword) >= 0 ||
      f.identificacao2.indexOf(keyword) >= 0 ||
      f.name.indexOf(keyword) >= 0 ||
      f.processoNome.indexOf(keyword) >= 0 ||
      f.remetenteNome.indexOf(keyword) >= 0 ||
      f.status.indexOf(keyword) >= 0
    );
  }

}
