import { Router } from '@angular/router';
import { IRoute } from '@medlogic/shared/shared-interfaces';

export abstract class BaseNavigationService {
  // Armazena o histório de rotass
  static history: IRoute[] = new Array<IRoute>();
  /* Se true, irá impedir que a próxima rota seja adicionada no histório de navegação. Útil para Duplicar e SalvarENovo. */
  preventAddToHistory = false;

  private _cadRootConfigNo: number;
  public get cadRootConfigNo(): number {
    let sessionCadPacienteNo;
    try {
      sessionCadPacienteNo = parseInt(sessionStorage.getItem('cadPacienteNo'), 10);
    } catch (e) {
    }
    return this._cadRootConfigNo || sessionCadPacienteNo;
  }
  public set cadRootConfigNo(v: number) {
    this._cadRootConfigNo = v;
    try {
      sessionStorage.setItem('cadPacienteNo', v.toString());
    } catch (e) {
    }
  }

  constructor(protected router: Router) { }

  // GERAL

  /* Retorna a condição anterior.
   * Atenção: não se trata de um location.back nativo, pois a página
   * anterior, pode ter criar uma Ocorrencia por exemplo. A rota anterior é de uma nova
   * atividade enquanto deve voltar consultando a atividade criada.
   */
  goBack(position: number = 1): void {
    try {
      let route: IRoute;
      for (let i = 0; i <= position; i++) {
        route = BaseNavigationService.history.pop();
      }
      if (route) {
        this.router.navigate(route.route);
      }
      // this.location.back();
    } catch (error) {
      console.log(this.constructor.name, 'goBack', error.message);
    }
  }

  /* Removerá a última (mais recente) posição armazenada no histórico. */
  removeLastHistory(): void {
    try {
      BaseNavigationService.history.pop();
    } catch (error) {
      console.log(this.constructor.name, 'removeLastHistory', error.message);
    }
  }
  /* Removerá todos os itens do histórico. */
  removeAllHistory(): void {
    try {
      BaseNavigationService.history = [];
    } catch (error) {
      console.log(this.constructor.name, 'removeLastHistory', error.message);
    }
  }

  /* Toda vez que entrar numa página, deverá ser acrescentado o caminho necessário para retornar para ela, recarregando os dados.
  * Se a rota já estiver no histórico, impedirá o acréscimo, mas colocará o item no topo da lista.
  */
  addToHistory(route: any[], title: string): void {
    try {
      if (this.preventAddToHistory) {
        this.preventAddToHistory = false;
      } else {
        const index = BaseNavigationService.history.map((m) => m.route[0] || m.route).indexOf(route[0] || route);
        if (index < 0) {
          BaseNavigationService.history.push({ title, route });
        } else { // Como já existe, apenas move o item para o topo da fila.
          const elem = BaseNavigationService.history[index];
          BaseNavigationService.history.splice(index, 1);
          BaseNavigationService.history.push(elem);
        }
      }
    } catch (error) {
      console.log(this.constructor.name, 'addToHistory', error.message);
    }
  }

  /* Retorna a lista de rotas que está no histórico. */
  getHistory(): IRoute[] {
    try {
      if (BaseNavigationService.history) {
        return BaseNavigationService.history;
      }
    } catch (error) {
      console.log(this.constructor.name, 'addToHistory', error.message);
    }
    return [];
  }

  /* Define se existe um histórico para voltar. */
  hasHistory(): boolean {
    try {
      // Um elemento corresponde à própria página.
      return BaseNavigationService.history.length > 1;
    } catch (error) {
      console.log(this.constructor.name, 'hasHistory', error.message);
    }
  }


}
