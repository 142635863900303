import { createAction, props } from '@ngrx/store';
import { IVideo } from '@medlogic/shared/shared-interfaces';

export const loadTutorials = createAction(
  '[Tutorial] Load Tutorials'
);

export const clearTutorials = createAction(
  '[Tutorial] Clear Tutorials'
);

export const loadTutorialsSuccess = createAction(
  '[Tutorial] Load Tutorials Success',
  props<{ videos: IVideo[] }>()
);

export const loadTutorialsFailure = createAction(
  '[Tutorial] Load Tutorials Failure',
  props<{ error: any }>()
);
