import { createAction, props } from '@ngrx/store';
import { IWebSocketContract } from '@medlogic/medlogic/medlogic-shared-interfaces';

export const listenWebsockets = createAction(
  '[Page Prescription] Listen Websockets',
  props<{ key: string }>()
);

export const emitWebsockets = createAction(
  '[Page Prescription] ConnectAndEmit Websockets',
  props<{ key: string, message: any }>()
);

export const clearWebsockets = createAction(
  '[Page Prescription] Clear Websockets'
);

export const listenWebsocketsSuccess = createAction(
  '[Websocket Effect] Listen Websockets Success',
  props<{ connection: IWebSocketContract }>()
);

export const emitWebsocketsFailure = createAction(
  '[Websocket Effect] Connect Websockets Failure',
  props<{ error: any }>()
);

export const emitWebsocketsSuccess = createAction(
  '[Websocket Effect] Connect Websockets Success',
  props<{ connection: IWebSocketContract }>()
);

export const listenWebsocketsFailure = createAction(
  '[Websocket Effect] Listen Websockets Failure',
  props<{ error: any }>()
);
