import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[libIsReadOnly]'
})
export class IsReadOnlyDirective {

  constructor(private el: ElementRef) {
  }

  @Input('libIsReadOnly')
  set blocked(blocked: boolean) {
    this.el.nativeElement.disabled = blocked;
  }

}
