export interface IExameResultado {
    ocorrenciaNo: number;
    codigoPaciente: any;
    idExame: number;
    dataExame: Date;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    categoria: string;
    exame: string;
    resultado: any;
    laudo: string;
    valorreferenciaMaximo: any;
    valorreferenciaMinimo: any;
    alertaMaior: string;
    rASTREAMENTO: any;
    codPacCAT: any;
    frequenciaCardiaca: any;
    alertaMenor: string;
    oCORRENCIA: any;
    mAXIMO: any;
    mINIMO: any;
    altoOuBaixo: any;
    nORMAIS: any;
    alertaNormal: string;
    alto: any;
    baixo: any;
    normal: any;
    mSG1: string;
    mSG2: string;
    codPacOcorrenciaCAT: any;
}
