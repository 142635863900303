import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { IListItem } from '../../interface/ilist-item';

@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.css']
})
export class GenericListComponent {

  @Input() items$: Observable<IListItem[]>;
  @Input() search: string;
  @Output() itemClick = new EventEmitter<any>();

  constructor(
    private log: LogService
  ) { }


  onItem_click(item: IListItem): void {
    try {
      this.itemClick.emit(item);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onItem_click', error.message);
    }
  }


}
