export interface IComment {
  ocorrenciaNo: number;
  codigo: any;
  titulo: any;
  habilitado: boolean;
  nomeAutor: any;
  data: Date;
  conteudo: string;
  idPessoa: any;
  idPost: any;
}
