<div class="grid-pain">
    <h3 class="title">Dor:</h3>
    <div class="low">
        <ng-container *ngFor="let i of [0,1,2]">
            <span *ngIf="isSelected(i)">{{ i }}</span>
        </ng-container>
    </div>
    <div class="moderate">
        <ng-container *ngFor="let i of [3,4,5,6,7]">
            <span *ngIf="isSelected(i)">{{ i }}</span>
        </ng-container>
    </div>
    <div class="high">
        <ng-container *ngFor="let i of [8,9,10]">
            <span *ngIf="isSelected(i)">{{ i }}</span>
        </ng-container>
    </div>
</div>