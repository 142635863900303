import { ConfigStateService } from '@medlogic/shared/state-config';
import { InputMaskType } from '../enum/input-mask-type';
import { FormGroup } from '@angular/forms';
import { IReplace } from '../interface/ireplace';
import { ICadastroListaDAL } from '../interface/icadastro-lista-dal';
import { FilterComponentesByTabPipe } from '../pipe/filter-componentes-by-tab.pipe';
import { IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { EnTypedValue } from '@medlogic/shared/shared-interfaces';
import { ITypedValue } from '@medlogic/shared/shared-interfaces';
import { IBasic } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

import * as xml2js from 'xml2js';
import * as processors from 'xml2js/lib/processors';

@Injectable({ providedIn: 'root' })
export class LibService {
  MAX_LINHA = 450;
  MAX_COLUNA = 950;
  LAST_TOP_INVISIBLE_POSITION = 10;
  DEFAULT_LEFT_INVISIBLE_POSITION = 1100;
  COMPONENT_HEIGHT = 40;

  CTRDATE = 'EDITORATIVIDADE.CTRDATE';
  CTRLABEL = 'EDITORATIVIDADE.CTRLABEL';
  CTRTEXTBOXLABELED = 'EDITORATIVIDADE.CTRTEXTBOXLABELED';
  CTRMULTILINETEXTBOXLABELED = 'EDITORATIVIDADE.CTRMULTILINETEXTBOXLABELED';
  CTRMULTILINETEXTBOXHTMLLABELED = 'CTRMULTILINETEXTBOXHTMLLABELED'; // Esse controle está sendo gravado no bd de forma despadronizada.
  CTRCOMBOBOX = 'EDITORATIVIDADE.CTRCOMBOBOX';
  CTRIMAGELIST = 'EDITORATIVIDADE.CTRIMAGELIST';
  CTRRADIOBUTTON = 'EDITORATIVIDADE.CTRRADIOBUTTON';
  CTRBUTTON = 'EDITORATIVIDADE.CTRBUTTON';
  CTRRATINGSTAR = 'EDITORATIVIDADE.CTRRATINGSTAR';
  CTRRATINGPROGRESS = 'EDITORATIVIDADE.CTRRATINGPROGRESS';
  CTRVIDEO = 'EDITORATIVIDADE.CTRVIDEO';
  CTRMAPA = 'EDITORATIVIDADE.CTRMAPA';
  CTRCHECKBOXLIST = 'EDITORATIVIDADE.CTRCHECKBOXLIST';
  CTRGRID = 'EDITORATIVIDADE.CTRGRID';

  // https://www.npmjs.com/package/expr-eval
  arrayConditionSimbol: IReplace[] = new Array(
    { search: '==', replace: '==' },
    { search: '>', replace: '>' },
    { search: '<', replace: '<' },
    { search: '<=', replace: '<=' },
    { search: '>=', replace: '>=' },
    { search: '=', replace: '==' },
    { search: '&', replace: ' and ' },
    { search: ' OR ', replace: ' or ' },
    { search: ' AND ', replace: ' and ' },
    { search: '&&', replace: ' and ' },
    { search: '\\|', replace: ' or ' },
    { search: '\\|\\|', replace: ' or ' },
    { search: '\\^', replace: ' or ' },
    { search: 'NOT', replace: '!' },
    { search: '\\!', replace: '!' },
    { search: '<>', replace: '!=' },
    { search: '!=', replace: '!=' },
    { search: '&&&&', replace: ' and ' },
    { search: '&&&', replace: ' and ' },
    { search: '\\|\\|\\|\\|', replace: ' or ' },
    { search: '\\|\\|\\|', replace: ' or ' },
    { search: '\\!==', replace: '!=' },
    { search: '===', replace: '==' },
    { search: '>==', replace: '>=' },
    { search: '<==', replace: '<=' }
  ); // Substituição de &&&& necessária pois se o usuário usar && serão substituídos por &&&& no replace de &.

  constructor(private global: GlobalService, private log: LogService,
    private cnfJson: ConfigJsonService,
    private cnf: ConfigStateService) { }

  // MÉTODOS ESPECÍFICOS ******************
  /*Retornar o ValorTexto conforme o local e estrutura de armazenamento de cada componente.
   * O ValorTexto seguirá ao padrão da máscara, ou seja, se forem máscaras ptBR, manterá em ptBR.
   * Retornará o ValorData se for um CTRDATE.
   * Retornará os itens do grid empacotados num XML compatível com o ValorTexto.
   * canBeEmpty: Irá controlar se é possível que o item esteja vazio: ocorre no caso de itens de um grid serem excluídos, por exemplo.
   */
  getValorTextoOrData(ctrl: IAtividadeComponenteDAL, isToSave: boolean = false, canBeEmpty: boolean = false): any {
    try {
      let value: any;
      switch (ctrl.Type.toUpperCase()) {
        case this.CTRGRID:
          const itemsFromCadastroAdicional = this.getGridItems(
            ctrl.lstCadastroAdicional,
            ctrl.VariavelRetornoNo,
            ctrl.TypeRegister
          );
          if (!itemsFromCadastroAdicional && !canBeEmpty) {
            return this.global.ConvertToCData(ctrl.ValorTexto);
          } else {
            return itemsFromCadastroAdicional || '<Items></Items>';
          }
        case this.CTRDATE:
        case 'EDITORATIVIDADE.DATETIME':
          return this.getValorData(ctrl.ValorData, isToSave);
        case this.CTRTEXTBOXLABELED:
          if (isToSave) {
            value = this.global.ConvertToCData(ctrl.ValorTexto) || '';
          } else {
            value = ctrl.ValorTexto != null || ctrl.ValorTexto !== undefined
              ? ctrl.ValorTexto
              : ctrl.ValorDefault ? (this.isFormula(ctrl.ValorDefault) ? '' : ctrl.ValorDefault) : '';
          }
          return InputMaskType.cleanMaskSymbolAndConvertToPtBr(value, ctrl.InputMaskType);
        default:
          if (isToSave) {
            return this.global.ConvertToCData(ctrl.ValorTexto) || '';
          } else {
            value = ctrl.ValorTexto
              ? ctrl.ValorTexto
              : ctrl.ValorDefault ? (this.isFormula(ctrl.ValorDefault) ? '' : ctrl.ValorDefault) : '';
            if (value && value.name) {
              // Valor em combobox
              return value.name;
            } else {
              return value;
            }
          }
      }
    } catch (error) {
      console.log(this.constructor.name, 'getValorTextoOrData', error.message);
    }
    return '';
  }

  /* Checa se o valor é uma fórmula matemática, ou então uma condicional */
  isFormulaOrCondition(valor: any): boolean {
    try {
      return this.isFormula(valor) || this.isCondition(valor);
    } catch (error) {
      console.log(this.constructor.name, 'isFormulaOrCondition', error.message);
    }
    return false;
  }

  /* Verifica se o valor corresponde a uma fórmula */
  isFormula(valor: any): boolean {
    try {
      if (this.global.IsNullOrEmpty(valor)) {
        return false;
      }
      valor = valor.toString();
      if (this.global.ChecaAspas(valor)) {
        valor = this.global.RemoverAspas(valor);
      }
      return valor.trim().substr(0, 1) === '=';
    } catch (error) {
      console.log(this.constructor.name, 'isFormula', error);
    }
    return false;
  }

  /* Verifica se o valor corresponde a uma fórmula condicional */
  isCondition(valor: any): boolean {
    try {
      let isCondition = false;
      if (this.global.IsNullOrEmpty(valor)) {
        return false;
      }
      valor = valor.toString();
      if (this.global.ChecaAspas(valor)) {
        valor = this.global.RemoverAspas(valor);
      }
      // Utilizado some ao invés de forEach para permitir interromper o loop uma vez que a condição for encontrada
      this.arrayConditionSimbol.some((item, i, ar) => {
        if (valor.indexOf(item.search) >= 0 || valor.toUpperCase().indexOf(item.search.toUpperCase()) >= 0) {
          isCondition = true;
          return true; // Apenas para encerrar o loop some
        }
      });
      return isCondition;
    } catch (error) {
      console.log(this.constructor.name, 'isCondition', error);
    }
    return false;
  }

  /*Retorna o xml de itens para compor o ValorTexto de um Grid.
   * typeRegister == 1: Id / 2: Titulo / 3: enabled
   */
  getGridItems(items: any[], variavelRetornoNo: number, typeRegister: number): string {
    try {
      if (!items || items.length <= 0) {
        return null;
      }
      let strItems = '<Items>';
      if (items && items.length > 0) {
        items.forEach((item) => {
          strItems += '<Item>';
          for (const clmName in item) {
            if (clmName !== '$') {
              const tagName = clmName === 'OcorrenciaNo' ? 'index' : clmName;
              let value;
              if (typeRegister === 1) {
                // Força a gravação do número da Ocorrência no campo de código.
                value = !item.index ? '' : item.index.name || item.index;
                strItems += `<${tagName}>${value}</${tagName}>`;
              } else {
                // Não é a propriedade de id/index
                // Porque se for combobox, o valor é um objeto
                value = !item[clmName] ? '' : item[clmName].name || item[clmName];
                const typed = this.global.getTypedValue(value);
                if (typed.type === EnTypedValue.Date) {
                  value = this.global.RetornarAllXsdDateTime(typed.value);
                }
                strItems += `<${tagName}>${value}</${tagName}>`;
              }
              if (tagName === `V_${variavelRetornoNo.toString()}` && !item.label) {
                // Acrescenta a tag label, caso não exista
                strItems += `<label>${value}</label>`;
              }
            }
          }
          strItems += '</Item>';
        });
      }
      strItems += '</Items>';
      return this.global.ConvertToCData(strItems);
    } catch (error) {
      console.log(this.constructor.name, 'getGridItems', error.message);
    }
    return '';
  }

  /* Acrescenta um elemento no array somente se ele não existir,
  * baseado na comparação do fieldNameToCompare( que deve existir tanto nos elementos do array quanto no el) */
  addIfNotExist(array: any[], el: any, fieldNameToCompare: string): boolean {
    try {
      if (array.findIndex((f) => this.global.isEqual(f[fieldNameToCompare], el[fieldNameToCompare])) < 0) {
        array.push(el);
        return true;
      }
      return false;
    } catch (error) {
      console.log(this.constructor.name, 'addIfNotExist', error.message);
    }
  }

  /* retorna um id para o controle baseado na variavelNo */
  getId(id: number): string {
    try {
      if (!this.global.isNumeric(id)) {
        return '';
      }
      return `V_${id}`;
    } catch (error) {
      console.log(this.constructor.name, 'getId', error.message);
    }
  }

  /*
  * Converte uma lista de componentes num objeto do padrão do ReactiveForms.
  * forceIdAsOcorrenciaNo: Se TypeRegister == 1, o valor considerado será o número da ocorrenciaNo.
  * Desta maneira, irá forçar que todos os campos códigos tenham exatamente o valor da ocorrencia.
  */
  mapComponentesToFormControl(
    componentes: IAtividadeComponenteDAL[],
    forceIdAsOcorrenciaNo: boolean = true
  ): any {
    try {
      const obj = {};
      if (componentes && componentes.length > 0) {
        componentes.forEach((ctrl) => {
          if (ctrl.TypeRegister === 1 && forceIdAsOcorrenciaNo) {
            obj[this.getId(ctrl.VariavelNo)] =
              ctrl.OcorrenciaNo > 0 ? ctrl.OcorrenciaNo : this.global.getTypedValue(ctrl.Valor).value;
          } else {
            obj[this.getId(ctrl.VariavelNo)] = this.global.getTypedValue(ctrl.Valor).value;
          }
        });
      }
      return obj;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapComponentesToFormControl', error.message);
    }
    return null;
  }

  /* Faz o processo inverso de getId: a partir do id retorna a VariavelNo */
  getVariavelNoFromId(id: string): number {
    try {
      if (!id) {
        return null;
      }

      return parseInt(id.toString().replace('V_', ''), 10);
    } catch (error) {
      console.log(this.constructor.name, 'getVariavelNoFromId', error.message);
    }
    return null;
  }

  /* A lista de itens do cadastro adicional são empacotados nesse formato pelo XML. */
  getItemDoCadastro(m: ICadastroListaDAL): any[] {
    try {
      // m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro
      if (m && m.lstCadastroAdicional !== undefined) {
        if (m.lstCadastroAdicional.diffgram !== undefined) {
          if (m.lstCadastroAdicional.diffgram.DocumentElement !== undefined) {
            if (m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro !== undefined) {
              return m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro;
            }
          }
        }
      }
    } catch (error) {
      console.log(this.constructor.name, 'getItemDoCadastro', error.message);
    }
    return null;
  }

  /*Se o items tiver apenas um item e for um objeto ao invés de um array, coloca o elemento num array.
   * Algumas estruturas, como interações de for, datatable, etc, no html dependem de trabalhar com arrays,
   * mas o retorno do serviço às vezes traz objetos únicos como arrays.
   * Agora, se o array for null ou só contiver elemementos null, retornará null
   */
  toArray(items: any): any[] {
    try {
      if (!items) {
        return null;
      }
      // Se o retorno tiver apenas um elemento, é necessário convertê-lo para um array
      if (items instanceof Array) {
        if (!items.find((f) => f !== null)) {
          return null;
        }
        return items;
      } else {
        return [items];
      }
    } catch (error) {
      console.log(this.constructor.name, 'toArray', error.message);
    }
    return null;
  }

  /* Verifica se o controle tem cascata ativado. */
  isCascade(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return !this.global.IsNullOrEmpty(ctrl.VariavelComparacaoNo) && ctrl.VariavelComparacaoNo > 0;
    } catch (error) {
      console.log(this.constructor.name, 'isCascade', error.message);
    }
  }

  /* Verifica se a aba está visível, conforme a visibilidade dos componentes contidos na aba. */
  isTabVisible(componentes: IAtividadeComponenteDAL[], tab: IBasic): boolean {
    try {
      const tabComponents: IAtividadeComponenteDAL[] = new FilterComponentesByTabPipe(this.log).transform(
        componentes,
        tab
      );
      const visible = tabComponents.filter((f) => this.isCtrlVisible(f));
      return visible && visible.length > 0;
    } catch (error) {
      console.log(this.constructor.name, 'isTabVisible', error.message);
    }
    return false;
  }

  isCtrlVisible(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      const isVisible = ctrl.IsVisible;
      const showInMobile = this.global.isMobile() ? ctrl.ShowInMobile : true;
      return ((isVisible && showInMobile) || this.cnfJson.showInvisible);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isCtrlVisible', error.message);
    }
    return false;
  }

  /*Extrai o valor data, com as devidas validações se o dado é existente. Retorna null caso não seja.
   * O retorno é no formato Xsd para ser enviada ao xml se isToSave = true.
   * Senão retorna data.
   */
  getValorData(valorData: any, isToSave: boolean): any {
    try {
      if (!this.global.IsDate(valorData)) {
        return isToSave ? '' : new Date();
      }
      const date = this.global.getTypedValue(valorData).value;
      if (isToSave) {
        return this.global.RetornarAllXsdDateTime(date);
      } else {
        return this.global.FormatarData(date);
      }
    } catch (error) {
      console.log(this.constructor.name, 'getValorData', error.message);
    }
    return null;
  }

  /* Cria um formControl a partir dos valores do item */
  getDefaultFormControls(selectedItem: IAtividadeComponenteDAL, formControl: any = null): any {
    try {
      formControl = formControl || [];
      formControl[this.getId(selectedItem.VariavelNo)] = this.getValorTextoOrData(selectedItem);
      return formControl;
    } catch (error) {
      console.log(this.constructor.name, 'getDefaultFormControls', error.message);
    }
  }

  /* Retorna, de maneira unificada, as variaveis do grid e da atividade */
  getDefaultFromGridAndActivity(gridSelectedItem: any, formGroup: FormGroup): any {
    try {
      let formControl = {};
      // Preenche a propriedade que passará os valores preenchidos para a próxima Atividade.
      formControl = gridSelectedItem ? this.getDefaultFormControlsFromGrid(gridSelectedItem) : formControl;
      // Junta com as demais variáveis presentes na atividade
      formControl = Object.assign({}, formControl, this.getOtherActivityVariables(formGroup));
      return formControl;
    } catch (error) {
      console.log(this.constructor.name, 'getDefaultFromGridAndActivity', error.message);
    }
  }

  /* Cria um formControl a partir dos valores do item */
  getDefaultFormControlsFromGrid(selectedItem: any): any {
    try {
      const formControl = {};
      for (const clm in selectedItem) {
        if (this.global.Left(clm, 2) === 'V_') {
          formControl[clm] = selectedItem[clm];
        }
      }
      return formControl;
    } catch (error) {
      console.log(this.constructor.name, 'getDefaultFormControls', error.message);
    }
  }

  /*Acrescenta os valores de todas as demais variáveis da atividade, pois,
   * caso haja variáveis no novo item ou no editado, como a mesma VariavelNo,
   * tais itens deverão ser preenchidos.
  */
  getOtherActivityVariables(formGroup: FormGroup): any {
    try {
      return formGroup.getRawValue();
    } catch (error) {
      console.log(this.constructor.name, 'getOtherActivityVariables', error.message);
    }
  }

  /*Esse método irá formatar adequadamente o valor conforme o tipo do dado, identificado dinamicamente a partir de uma string.
   * defaultValue: será utilizado caso o valor seja nulo ou vazio.
   */
  getGEValue(
    value: any,
    ctrl?: IAtividadeComponenteDAL,
    defaultValue: any = null,
    locationOutput: string = 'pt-br'
  ): any {
    try {
      // Se for uma fórmula, retornar a própria fórmula
      if (this.isFormula(value)) {
        return value;
      }

      const typedValue = this.global.getTypedValue(value, locationOutput);
      if (ctrl) {
        switch (ctrl.Type.toUpperCase()) {
          case this.CTRDATE:
            value = typedValue.value || defaultValue;
            break;
          default:
            const enMask = InputMaskType.toEnum(ctrl?.InputMaskType);
            value = value ? this.getStringValue(typedValue, enMask, defaultValue) : defaultValue ? defaultValue : '0';
            break;
        }
        return value;
      } else {
        return value = value ? this.getStringValue(typedValue, defaultValue) : defaultValue ? defaultValue : '0';

      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getGEValue', error.message);
    }
    return null;
  }

  /* Utilizado em getGEValue para não repetir a mesma lógica de extração do dado conforme o tipo identificado dinamicamente da string. */
  protected getStringValue(typedValue: ITypedValue, inputMaskType: number, defaultValue: any = ''): string {
    try {
      let value = defaultValue;
      if (typedValue.type === EnTypedValue.Date) {
        value = this.global.DateToddMMYYYY(typedValue.value);
      } else if (
        [InputMaskType.Integer, InputMaskType.Percent, InputMaskType.Percent2].includes(inputMaskType)
      ) {
        value = typedValue.value;
      } else {
        value = typedValue.string || defaultValue;
      }
      return value;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getStringValue', error.message);
    }
    return defaultValue;
  }

  // beforeChangeValues = {};
  /*Modifica o ValorTexto ou ValorData do controle, conforme o tipo de dados.
  * Atenção: irá alterar o valor do ctrl.
  * defaultValue será definido conforme o tipo de controle, e somente se value == null.
  * // O Valor padrão para CTRDATE é a data do dia e para os demais é ''.
  * Se fc fornecido, irá também atualizar o valor e marcar como dirty.
  * também alimentará um objeto com foco em manter o estado anterior dos controles.
  */
  setGEValue(
    value: any,
    ctrl: IAtividadeComponenteDAL,
    setDefaultValue: boolean = false,
    fg: FormGroup = null,
    updateFormControl: boolean = true
  ): void {
    try {
      let formValue = null;
      if (ctrl) {
        switch (ctrl.Type.toUpperCase()) {
          case this.CTRDATE:
            const typedValorTexto = this.global.getTypedValue(ctrl.ValorTexto);
            if (!value && ctrl.ValorTexto && typedValorTexto.type === EnTypedValue.Date) {
              ctrl.ValorData = typedValorTexto.value;
            } else {
              ctrl.ValorData =
                !value && setDefaultValue ? new Date() : this.global.getTypedValue(value).value;
            }
            if (!ctrl.ValorData) {
              ctrl.ValorData = typedValorTexto.value;
            }
            ctrl.ValorDataMMddyyyy = this.global.DateToMMddYYYY(ctrl.ValorData);
            ctrl.Valor = this.global.dateToYYYYMMddThhmmss(ctrl.ValorData);
            ctrl.ValorTexto = null;
            formValue = ctrl.ValorData;
            break;
          default:
            const vlr = this.global.getTypedValue(value);
            ctrl.ValorTexto = !value && setDefaultValue ? '' : vlr.string;
            ctrl.Valor = !value && setDefaultValue ? '' : vlr.value;
            if (ctrl.InputMaskType === 'Date') {
              ctrl.ValorTexto = this.global.DateToddMMYYYY(vlr.value);
              ctrl.Valor = ctrl.ValorTexto;
            }
            ctrl.ValorData = null;
            ctrl.ValorDataMMddyyyy = null;
            formValue = ctrl.ValorTexto;
            break;
        }
        if (updateFormControl && fg) {
          const clm = this.getId(ctrl.VariavelNo);
          const control = fg.get(clm);
          if (control) {
            // this.beforeChangeValues[clm] = control?.value;
            // Deve marcar como dirty, mas não settar o valor que será definido em AtividadeComponente.onChangeRecursive
            // control?.markAsDirty();
            // Marcar como Dirty gera erro por ter modificado o ng-Pristine do form durante o ciclo de renderização.
            // Redefinição do valor, necessária para que valores da parent Activity sejam carregados.
            control?.setValue(formValue, { onlySelf: true, emitEvent: true });
          }
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getGEValue', error.message);
    }
  }

  /* Verifica se o posicionamento do controle está fora da região de visibilidade. */
  isOutsideDisplayArea(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return ctrl.Linha > this.MAX_LINHA || ctrl.Coluna > this.MAX_COLUNA;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isOutsideDisplayArea', error.message);
    }
    return false;
  }

  /*Os controles fora da região de display serão automaticamente reposicionados para facilitar a visualização de debug.
   * Toda vez que resgatar uma posição, irá incrementar a última posição.
  */
  getNextOutsideDisplayAreaPosition(): { top: number; left: number } {
    try {
      const lastTopPos = this.LAST_TOP_INVISIBLE_POSITION;
      this.LAST_TOP_INVISIBLE_POSITION += this.COMPONENT_HEIGHT;
      return { top: lastTopPos, left: this.DEFAULT_LEFT_INVISIBLE_POSITION };
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getNextOutsideDisplayAreaPosition', error.message);
    }
  }

  /*Converte o ValorTexto numa lista.
   * TODO: Verificar o funcionamento quando houver mais de um grid na Atividade.
   * O problema é que o item que retorna da atividade nova não é
   * reacrescentado no ValorTexto de imediato. Se o item for limpo,
   * o novo substituirá o anterior. Se nao for limpo, mesmo apos uma exclusao, um novo item retornará com os excluídos.
   */
  transformValorTextoInLstCadastroAdicional(ctrl: IAtividadeComponenteDAL): Observable<any> {
    try {
      const valorTextoXML = ctrl.ValorTexto.replace('<![CDATA[', '').replace(']]>', '').replace(/\"/gi, '');
      return new Observable((observer) => {
        xml2js.parseString(
          valorTextoXML,
          {
            explicitArray: true,
            tagNameProcessors: [processors.stripPrefix],
            valueProcessors: [
              processors.parseNumbers,
              processors.parseBooleans,
              (value: any) => this.parseDate(value)
            ]
          },
          (err: { message: string; }, result: { Items: { Item: any; }; }) => {
            try {
              if (err) {
                this.log.Registrar(
                  this.constructor.name,
                  'transformValorTextoInLstCadastroAdicional.err',
                  err.message
                );
                observer.next(null);
                observer.complete();
              } else {
                const items = !result ? [] : result.Items.Item || [];
                // TODO: (CORRIGIR NO SERVIÇO) O serviço está trazendo a tag
                // label duplicada, sendo um elemento vazio, que acaba se tornando um array com dois elementos.
                if (items) {
                  items.forEach((f: { [x: string]: any; label: string[]; }) => {
                    try {
                      // f.label = [f.label[1]];
                      f.label = [this.extractLabel(ctrl, f)];
                      for (const clm in f) {
                        if (clm) {
                          const value = f[clm];
                          const typed = this.global.getTypedValue(value);
                          // TODO: modificar para o resgate do valor traz impacto para as máscaras e apresentação dos campos?
                          f[clm] = typed.value;
                        }
                      }
                    } catch (error) {
                      this.log.Registrar(
                        this.constructor.name,
                        'transformValorTextoInLstCadastroAdicional.forEach',
                        error.message
                      );
                    }
                  });
                }
                observer.next(items);
                observer.complete();
              }
            } catch (error) {
              this.log.Registrar(
                this.constructor.name,
                'transformValorTextoInLstCadastroAdicional.observable',
                error.message
              );
              observer.next(null);
              observer.complete();
            }
          }
        );
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transformValorTextoInLstCadastroAdicional', error.message);
    }
    return of(null);
  }

  /* extrai o valor da label em várias situações. */
  extractLabel(ctrl: IAtividadeComponenteDAL, formControl: any): string {
    try {
      let label: string;
      if (formControl.hasOwnProperty('label')) {
        label = Array.isArray(formControl.label) ? formControl.label[0] : formControl.label;
      } else {
        const lblVarNo = this.getId(ctrl.LstCamposAdicionais.CamposAdicionais[0].VariavelNo);
        label = this.global.getTypedValue(formControl[lblVarNo]).string;
      }
      return label;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractLabel', error.message);
    }
  }

  /* Se for uma data, transforma a string no tipo data  */
  parseDate(value: any): any {
    try {
      return this.global.getTypedValue(value).string;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'parseDate', error.message);
    }
  }

  /* Retorna um valor booleano a partir da comparação de um valor do tipo 'SIM'. */
  getBoolean(value: string, yesValue: string = 'SIM'): boolean {
    try {
      return this.global.getBoolean(value, yesValue);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getBoolean', error.message);
    }
  }


}
