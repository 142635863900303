<mat-toolbar color="basic">

  <span class="example-spacer"></span>

  <mat-icon class="example-icon" (click)="onCloseClick()">close</mat-icon>

  <span class="bar-title">
    {{ patient?.nomeHospede }}
  </span>

</mat-toolbar>

<div class="grid-medication ">
  <div [class.disableMed]="absoluteValid" >
    Você confirma o checkin de:
  </div>

  <div class="title">

    <h2>{{ medication?.medicationName }} {{ medication?.dosage }}</h2>

  </div>

  <!-- O calendário irá flutuar sobre a tela, padrão touch -->
  <div class="calendar" [class.disableMed]="absoluteValid">

    <p-calendar #datePicker [(ngModel)]="medication.medicationDate" [showTime]="showTime" [showIcon]="true"
      dateFormat="dd/mm/yy" [locale]="ptBr" [inline]="false" [autoZIndex]="false" hourFormat="24" [touchUI]="false"
      [hideOnDateTimeSelect]="false" [readonlyInput]="false" [maxDate]="maxDateValue" [style]="{'z-index': '9999'}" [inputId]="'dateInput'" (onFocus)="onBlurDate()">

      <p-footer>
        <button mat-raised-button (click)="onClickCalendarOK($event)">OK</button>
      </p-footer>

    </p-calendar>

    <span *ngIf="isData">
      <mat-card>Selecione uma data válida!</mat-card>
    </span>

  </div>

  <div *ngIf="isUpdateStockOnApp" [class.disableMed]="absoluteValid">

    <p-toggleButton [onLabel]="'Foi ministrado'" offLabel="Não foi ministrado" [onIcon]="'fa fa-check'"
      offIcon="fa fa-times" iconPos="right" [(ngModel)]="medication.took" (onChange)="onRejectChange($event)">
    </p-toggleButton>

    <p-toggleButton [onLabel]="'Baixar no estoque'" offLabel="Não baixar no estoque" [onIcon]="'fa fa-check'"
      offIcon="fa fa-times" iconPos="right" [(ngModel)]="updateStock">
    </p-toggleButton>

  </div>

  <div class="obs" [class.disableMed]="absoluteValid">

    <span class="error" *ngIf="!isValid() || absoluteValid">Descrição obrigatória se medicação rejeitada ou data diferente da
      atual:</span>

    <mat-form-field class="full-width">

      <textarea matInput rows="6" [class.error]="!isValid()" placeholder="Observações"
        [(ngModel)]="observation"></textarea>

    </mat-form-field>

  </div>
  <!-- <div>
    <button type="button" class="btn btn-primary btn-block" (click)="onClickItemConfirm($event)">
      <i class="fa fa-check mr"></i>
      <span class="bold">Confirmar</span>
    </button>
  </div> -->
  <mat-toolbar class="bottom-bar">
    <button type="button" mat-raised-button (click)="onClickItemConfirm($event)"
      [disabled]="!isValid() || absoluteValid">Confirmar</button>
    <button type="button" class="cancelar" mat-raised-button (click)="onCloseClick()">Cancelar</button>
  </mat-toolbar>

</div>