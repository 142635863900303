
import { EntityState } from '@ngrx/entity';
import { IEmailMessage } from './iemail-message';


export interface IUtilsEmailState extends EntityState<IEmailMessage> {
    // additional entities state properties
    error: any | undefined;
    selectedId: number | undefined;
    selectedEmail: IUtilsEmailState | undefined;
    allEmails: IUtilsEmailState[] | undefined;
}
