import { createReducer, on, Action } from '@ngrx/store';
import {
  loginAndLoadRoot, successLogin, failLogin, successLogOut,
  failLogOut, setLoginState, setCustomerId
} from './login.actions';
import { ILoginState, EnLoginState, ILogin } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export const adapterLogin: EntityAdapter<ILogin> = createEntityAdapter<ILogin>({
  selectId: instance => instance.usuarioLogadoNo,
});

export const initialStateLogin: ILoginState = adapterLogin.getInitialState({
  // additional entity state properties
  userName: undefined,
  password: undefined,
  selectedLogin: undefined,
  enLoginState: EnLoginState.Login,
  message: undefined,
  msg: undefined,
  isFirstLogin: false,
});

const loginReducerObj = createReducer(initialStateLogin,
  on(loginAndLoadRoot, (state, payload) => ({
    ...state,
    ...payload,
    enLoginState: EnLoginState.Authentication,
    msg: undefined
  })),
  on(successLogin, (state, payload) => {
    return ({
      ...state,
      enLoginState: EnLoginState.Logged,
      selectedLogin: payload?.selectedLogin
    });
  }),
  on(failLogin, (state, payload) => {
    return ({
      ...state,
      selectedLogin: undefined,
      enLoginState: EnLoginState.LoginFail,
      ...payload?.selectedLogin
    });
  }),
  on(successLogOut, (state, payload) => {
    return ({
      ...initialStateLogin,
      enLoginState: EnLoginState.Login
    });
  }),
  on(failLogOut, (state, payload) => ({
    ...state,
    enLoginState: EnLoginState.LoginFail
  })),
  on(setLoginState, (state, payload) => ({
    ...state,
    enLoginState: payload?.enLoginState
  })),
  on(setCustomerId, (state, payload) => ({
    ...state,
    selectedLogin: { ...state?.selectedLogin, customerId: payload?.customerId } as ILogin
  }))
);

export function loginReducer(state: ILoginState | undefined, action: Action) {
  return loginReducerObj(state, action);
}
