export interface IEstoqueMaterial {
  ocorrenciaNo: number;
dosagem:  any;
codigo:  any;
titulo:  any;
habilitado:  boolean;
centroCusto:  string;
estoque:  any;
estoqueMinimo:  any;
unidademedida:  string;
dataUltimaCompra:  any;
preco:  any;
fornecedor:  any;
tipoMaterial:  string;
consumoDiario:  any;
dataProximaCompra:  any;
hoje:  Date;
duracaoTotal:  any;
unidadeNegocio:  string;
nF:  any;
idPaciente:  any;
medicamento:  any;
medicamentoControlado:  any;
medicamento2:  any;
codigoPacienteNomePaciente:  any;
uniNegocio:  any;
codPacienteNomedoPacienteCodMedicamento:  any;
tipoMedicamentosCodPaciente:  any;
itens:  string;
materialID:  any;
cascataTipoMAterialCodHosp:  any;
concatUniNegocioSIM:  any;
concatUniNegocioNAO:  any;
quantidadeEmbalagem:  any;
idMedicamento:  any;
codCenCusto:  any;
ultimoLote:  any;
dataUltimaValidade:  any;
dose:  any;
unidadeDose:  string;
consumoDiario2:  any;
gOTASPMl:  any;
dataAtual:  Date;
guide:  any;
}
