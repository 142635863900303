import { Injectable } from '@angular/core';
import { routCoding } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirCodingService extends FhirGenericService {

  protected url = routCoding;

}
