import { DialogConfirmComponent } from '@medlogic/shared/ui/dialog/ui-dialog-confirm';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { UiDialogAlertComponent } from '@medlogic/shared/ui/dialog/ui-dialog-alert';
import { Observable } from 'rxjs';
import { of } from 'rxjs';


/* @classdesc
 * Serviço para métodos genéricos de abertura de janelas do material.
*/
@Injectable()
export class MaterialDialogService {

  constructor(
    protected log: LogService,
    protected dialog: MatDialog
  ) {
  }

  /* Abre uma janela de diálogo com uma mensagem.
  * Retorna um Observable de fechamento.
  */
  openDialogAlert(title: string, messageHtml: string = '', btnOK: string = 'OK', width: string = '450px', maxHeight: string = '350px'): Observable<any> {
    try {
      const dialogRef = this.dialog.open(UiDialogAlertComponent, {
        width,
        maxHeight,
        data: { title, messageHtml, btnOk: btnOK }
      });
      return dialogRef.afterClosed();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'openDialogAlert', error.message);
    }
    return of(null);
  }

  openDialogConfirm(title: string, options?: { name: string, time: string }[]): Observable<any> {
    try {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: { title, options },
      });
      return dialogRef.afterClosed();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'openDialogConfirm', error.message);
    }
    return of(null);
  }


}
