export enum EnIvcfDisability {
    idade = 'Idade',
    percepcao = 'Auto-Percepção da Saúde',
    avdi = 'AVD Instrumental',
    avdb = 'AVD Básica',
    cognicao = 'Cognição',
    humor = 'Humor',
    mobilidade = 'Mobilidade',
    comunicacao = 'Comunicação',
    comorbidade = 'Comorbidade Múltipla',
    mmss = 'MMSS',
    sarcopenia = 'Sarcopenia (Nutrição)',
    marcha = 'Marcha (Quedas)',
    continencia = 'Continência Esfincteriana',
    visao = 'Visão',
    audicao = 'Audição',
    polifarmacia = 'Polifarmácia (5 ou mais drogas/dia)',
    polipatologia = 'Polipatologia (5 ou mais doenças)',
    internacao = 'Internação Recente (menos 6 meses)'
}
