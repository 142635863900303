import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { initialStateMedication } from './medication.reducer';
import {
  addMedication, addMedications, clearMedications,
  confirmMedication,
  deleteMedication, deleteMedications, loadMedications,
  loadMedicationsByPrescription,
  loadMedicationsBySelectedPatient,
  loadMedicationsSuccess,
  loadRawMedications,
  loadRawMedicationsSuccess,
  updateMedication, updateMedications, upsertMedication, upsertMedications
} from './medication.actions';

const KEY = 'MEDLOGIC_MEDICATION_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  // FIXME: Tentativa de resolver o problema de não reconhecer de imediato medicações incluídas ou excluídas.
  // loadRawMedications,
  // loadRawMedicationsSuccess,
  loadMedications,
  loadMedicationsSuccess,
  loadMedicationsBySelectedPatient,
  loadMedicationsByPrescription,
  addMedication,
  upsertMedication,
  addMedications,
  upsertMedications,
  updateMedication,
  updateMedications,
  deleteMedication,
  deleteMedications,
  clearMedications,
  confirmMedication
]?.map(m => m.type.toString());

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialStateMedication);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
