export interface ISnapIv {
  ocorrenciaNo: number;
	dtCadastro:  Date;
	idPessoa:  any;
	escola:  any;
	idEscola:  any;
	codigo:  any;
	titulo:  any;
	habilitado:  boolean;
	nomeEducadorProfissionalResponsavel:  string;
	dataNascimento:  any;
	responsavelLegal:  any;
	grauparentesco:  any;
	naoConseguePrestarMuitaAtencaodetalhesOuCometeErrosPorDescuidoNosTrabalhosescolaOuTarefas:  string;
	temDificuldademanteratencaoEmTarefasOuAtividadeslazer:  string;
	pareceNaoEstarOuvindoQuandofalaDiretamenteComEle:  string;
	naoSegueInstrucoesAtefimnaoTerminaDeveresescolaTarefasOuObrigacoes:  string;
	temDificuldadeorganizarTarefasatividades:  string;
	conclusaosugestaoconduta:  string;
	evitaNaoGostaOuenvolveContravontadeEmTarefasQueExigemEsforcoMentalProlongado:  string;
	perdeCoisasNecessariasasAtividadesExBrinquedosDeveresescolaLapisOuLivros:  string;
	distraicomEstimulosExternos:  string;
	eEsquecidoEmAtividadesdiadia:  string;
	mexeComAsMaosOuOsPesOuremexeNaCadeira:  string;
	sailugarNaSalaaulaOuEmOutrasSituacoesEmQueesperaQueFiqueSentado:  string;
	correumLadooutroOuSobeDemaisNasCoisasEmSituacoesEmQueIstoinapropriado:  string;
	temDificuidadeEmBrincarOuEnvolveremAtividadeslazerformaCalma:  string;
	naoouFrequentementeEstaMilPorHora:  string;
	falaEmExcesso:  string;
	respondeAsPerguntasformaPrecipitadaAntesDelasTeremSidoTerminadas:  string;
	temDificuldadeesperarSuaVez:  string;
	usuarioExecutor:  any;
	idEducador:  any;
	idProfissional:  any;
	score:  any;
	interpretacaoDadosSNAPIV:  string;
	interpretacaoDadosFuncionalidade:  string;
	interrompeOsOutrosOuintrometeExMetenasConversasJogos:  string;
	matematicaCalculos:  string;
	score1:  any;
	leituraCompreensaotexto:  string;
	score2:  any;
	escritaOrtografia:  string;
	score3:  any;
	aprenderCoisasForaescolaDiadia:  string;
	score4:  any;
	seRelacionarComOsPais:  string;
	score5:  any;
	conhecerPessoasNovasFazerAmigos:  string;
	score6:  any;
	entenderOsSentimentosOuEmocoesoutrasPessoas:  string;
	score7:  any;
	entenderAsPropriasEmocoessentimentos:  string;
	score8:  any;
	participaatividadesrotinasDomesticas:  string;
	score9:  any;
	participaatividadesEscolares:  string;
	score10:  any;
	consegueprepararorganizarasTarefascasa:  string;
	score11:  any;
	participaatividadesComoGruposIgrejasAssociacoes:  string;
	score12:  any;
	controlarurinafezes:  string;
	score13:  any;
	tomarBanhoescovarOsDentes:  string;
	score14:  any;
	escolhertrocarroupa:  string;
	score15:  any;
	utilizaComSegurancaOsGarfosFacademaisUtensilios:  string;
	score16:  any;
	andarUmaDistanciaMedia_10Quarteiroes:  string;
	score17:  any;
	saltarpularDuranteBrincadeiras:  string;
	score18:  any;
	praticarAlgumEsporteComoFutebolOuBasquete:  string;
	score19:  any;
	cortarPapelUsandoTesoura:  string;
	score20:  any;
	seExpressarBemAtravesfala:  string;
	score21:  any;
	compreenderqueAsPessoasDizem:  string;
	score22:  any;
	temUmBomVocabularioSabeMuitasPalavras:  string;
	score23:  any;
	seComunicarPorMeiomensagensOuTexto:  string;
	score24:  any;
	pontuacaoFCogT:  any;
	pontuacaoFRIT:  any;
	pontuacaoFAVDT:  any;
	pontuacaoFAutoT:  any;
	pontuacaoFMovT:  any;
	pontuacaoFComT:  any;
	dP:  any;
	scoreZCognicao:  any;
	media:  any;
	scoreZRelacoes:  any;
	scoreZAtividades:  any;
	scoreZAutocuidado:  any;
	scoreZMobilidade:  any;
	scoreZComunicacao:  any;
	percentilCognicao:  any;
	percentilRelacoes:  any;
	percentilAtividades:  any;
	percentilAutocuidado:  any;
	percentilMobilidade:  any;
	percentilComunicacao:  any;
	descontrola:  string;
	discuteComAdultos:  string;
	desafiaAtivamenteOurecusaatenderPedidosOuRegrasadultos:  string;
	fazCoisaspropositoQueIncomodamOutrasPessoas:  string;
	culpaOsOutrosPelosSeusErrosOuMauComportamento:  string;
	eIrritavelOuFacilmenteIncomodadoPelosOutros:  string;
	eZangadoressentido:  string;
	eMaldosoOuVingativo:  string;
	pt01Desatencao:  any;
	pt02Desatencao:  any;
	pt03Desatencao:  any;
	pt04Desatencao:  any;
	pt05Desatencao:  any;
	pt06Desatencao:  any;
	pt07Desatencao:  any;
	pt08Desatencao:  any;
	pt09Desatencao:  any;
	pt10Hiperatividade:  any;
	pt11Hiperatividade:  any;
	pt12Hiperatividade:  any;
	pt13Hiperatividade:  any;
	pt14Hiperatividade:  any;
	pt15Hiperatividade:  any;
	pt16Hiperatividade:  any;
	pt17Hiperatividade:  any;
	pt18Hiperatividade:  any;
	pt19OposicaoDesafio:  any;
	pt20OposicaoDesafio:  any;
	pt21OposicaoDesafio:  any;
	pt22OposicaoDesafio:  any;
	pt23OposicaoDesafio:  any;
	pt24OposicaoDesafio:  any;
	pt25OposicaoDesafio:  any;
	pt26OposicaoDesafio:  any;
	sNAPDESATENCAO:  any;
	sNAPHIPERATIVIDADE:  any;
	sNAPOPOSICAODESAFIO:  any;
	genero:  any;
	sNAPDControleM:  any;
	sNAPDControleDP:  any;
	sNAPDTDAHM:  any;
	sNAPDTDAHDP:  any;
	sNAPHIControleM:  any;
	sNAPHIControleDP:  any;
	sNAPHITDAHM:  any;
	sNAPHITDAHDP:  any;
	sNAPODDControleM:  any;
	sNAPODDControleDP:  any;
	sNAPODDTDAHM:  any;
	sNAPODDTDAHDP:  any;
	scoreZPacienteDesatencao:  any;
	scoreZPacienteHiperAtividade:  any;
	scoreZPacienteOposicaoDesafio:  any;
	scoreZTDAHDesatencao:  any;
	scoreZTDAHHiperAtividade:  any;
	scoreZTDAHOposicaoDesafio:  any;
	graficoDesatencaoTDAH:  any;
	graficoDesatencaoPACIENTE:  any;
	graficoHiperatividadeTDAH:  any;
	graficoHiperatividadePACIENTE:  any;
	graficoODDTDAH:  any;
	graficoODDPACIENTE:  any;
	pontuacaoFTotal:  any;
	scoreZFuncGeral:  any;
	percentilFuncGeral:  any;
	sNAPSD1:  any;
	sNAPSD2:  any;
	sNAPSD3:  any;
	sNAPSD4:  any;
	sNAPSD5:  any;
	sNAPSD6:  any;
	sNAPSD7:  any;
	sNAPSD8:  any;
	sNAPSD9:  any;
	sNAPSH10:  any;
	sNAPSH11:  any;
	sNAPSH12:  any;
	sNAPSH13:  any;
	sNAPSH14:  any;
	sNAPSH15:  any;
	sNAPSH16:  any;
	sNAPSH17:  any;
	sNAPSH18:  any;
	sNAPSOD19:  any;
	sNAPSOD21:  any;
	sNAPSOD22:  any;
	sNAPSOD23:  any;
	sNAPSOD24:  any;
	sNAPSOD25:  any;
	sNAPSOD26:  any;
	sNAPSOD27:  any;
	sintDesa:  any;
	sintHip:  any;
	sintODD:  any;
	impCog:  any;
	impIS:  any;
	impAVD:  any;
	impAuto:  any;
	impMov:  any;
	impCom:  any;
	comp2areas:  any;
  inicioAntes12Anos:  any;
  
}
