import { createAction, props } from '@ngrx/store';
import { IModule } from '@medlogic/shared/shared-interfaces';

// TODO: Each model need to have its specific Action, Reducer, Effects.
export enum ProcessActionTypes {
  LoadProcess = '[Process Component] Load Process',
  FetchSuccessProcess = '[Process API] Fetch Success Process', // Set the Process to the Store, after Effects
  FetchErrorProcess = '[Process API] Fetch Error Process',
  // SetProcessId = '[Process Component] Set Process Id',
  SetProcess = '[Process Component] Set Process',
  SelectProcess = '[Process Component] Select Process'
}

export const loadProcesses = createAction(ProcessActionTypes.LoadProcess);
export const setProcess = createAction(ProcessActionTypes.SetProcess, props<{ allProcesses: IModule[] }>());
// export const setProcessId = createAction(ProcessActionTypes.SetProcessId, props<{ processId: number }>());

// tslint:disable: max-line-length
export const fetchSuccessProcess = createAction(ProcessActionTypes.FetchSuccessProcess, props<{ allProcesses: IModule[] }>());
export const selectProcess = createAction(ProcessActionTypes.SelectProcess, props<{ selectedProcess: IModule }>());
export const fetchErrorProcess = createAction(ProcessActionTypes.FetchErrorProcess);
