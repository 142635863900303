import { ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { WebService } from '@medlogic/shared/shared-data-access';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';

@Injectable()
export class BaseService extends UnsubscribeOnDestroyAdapter {
  constructor(protected glb: GlobalService, protected log: LogService, protected ws: WebService,
    private cnf: ConfigJsonService) {
    super();
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Necessário para geração do XML.
   * Retorna a própria ocorrenciaNo.
   */
  notificarConclusaoSalvamento(cadastroNo: number, ocorrenciaNo: number): Observable<number> {
    try {
      const method = 'CadastroNotificarConclusaoSalvamento';
      return this.ws
        .connect(method, [
          { name: 'CadastroNo', value: cadastroNo },
          { name: 'OcorrenciaNo', value: ocorrenciaNo }
        ])
        .pipe(map((m) => ocorrenciaNo));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'notificarConclusaoSalvamento', error.message);
    }
  }


}
