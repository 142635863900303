import { EmailService } from './service/email.service';
import { WsTrackerService } from './service/ws-tracker.service';
import { ModelComponentService } from './service/model-component.service';
import { CacheService } from './service/cache-service.service';
import { UsuarioDAL } from './service/usuario-dal';
import { ProcessService } from './service/process.service';
import { WebService } from './service/web.service';
import { BasePageService } from './service/base-page.service';
import { AsyncLocalStorageCacheService } from './service/async-local-storage-cache.service';
import { CadastroService } from './service/cadastro.service';
import { GenericCustomService } from './service/generic.custom.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectDAL } from './service/object-dal';
import { ModelComponent } from './service/model-component';
import { ModelService } from './service/model.service';
import { HttpTokenInterceptorModule } from './http-token-interceptor.module';
import { RegisterService } from './service/register.service';
import { SharedExecucaoTarefaDAL } from './service/shared-execucao-tarefa-dal';
import { SharedDadoDAL } from './service/shared-dado-dal';
import { CadTenantService } from './service/cad-tenant.service';
import { QueueService } from './service/queue.service';
import { RedisService } from './service/redis.service';

const SERVICES = [
  GenericCustomService,
  CadastroService,
  AsyncLocalStorageCacheService,
  BasePageService,
  WebService,
  ProcessService,
  ObjectDAL,
  UsuarioDAL,
  CacheService,
  ModelService,
  ModelComponent,
  ModelComponentService,
  WsTrackerService,
  RegisterService,
  SharedExecucaoTarefaDAL,
  SharedDadoDAL,
  CadTenantService,
  QueueService,
  RedisService,
  EmailService
];

const MODULES = [
  CommonModule,
  HttpTokenInterceptorModule
];


@NgModule({
  imports: [MODULES],
  providers: [SERVICES],
})
export class DataAccessModule { }
