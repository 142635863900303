import { createAction, props } from '@ngrx/store';
import { IExameResultado } from '@medlogic/medlogic/medlogic-shared-interfaces';

// TODO: Each model need to have its specific Action, Reducer, Effects.
export enum ExameResultadoActionTypes {
  LoadExameResultados = '[ExameResultado Component] Load ExameResultado ',
  FetchSuccessExameResultado = '[ExameResultado API] Fetch Success ExameResultado ', // Set the ExameResultado  to the Store, after Effects
  FetchErrorExameResultado = '[ExameResultado API] Fetch Error ExameResultado ',
  SetExameResultado = '[ExameResultado ListView Component] Set ExameResultado ',
  SaveExameResultado = '[PageExame Component] Save ExameResultado',
  SaveSuccessExameResultado = '[PageExame Component] Save Success ExameResultado',
  SaveFailExameResultado = '[PageExame Component] Save Fail ExameResultado',
  UpdateExameResultado = '[PageExame Component] Update ExameResultado',
  ResetExameResultado = '[PageExame Component] Reset ExameResultado',
  DeleteExameResultado = '[PageExame Component] Delete ExameResultado',
  DeleteSuccessExameResultado = '[PageExame Component] Delete Success ExameResultado',
  DeleteFailExameResultado = '[PageExame Component] Delete Fail ExameResultado',
  CleanExameResultado = '[States Management Service] Clean ExameResultado',
}

export const loadExameResultados = createAction(ExameResultadoActionTypes.LoadExameResultados);
export const fetchSuccessExameResultado = createAction(ExameResultadoActionTypes.FetchSuccessExameResultado, props<{ resultados: IExameResultado[] }>());
export const fetchErrorExameResultado = createAction(ExameResultadoActionTypes.FetchErrorExameResultado, props<{ resultados: IExameResultado[] }>());
export const setExameResultado = createAction(ExameResultadoActionTypes.SetExameResultado, props<{ resultado: IExameResultado }>());
export const saveExameResultado = createAction(ExameResultadoActionTypes.SaveExameResultado, props<{ resultado: IExameResultado, guid: (string | number) }>());
export const saveSuccessExameResultado = createAction(ExameResultadoActionTypes.SaveSuccessExameResultado, props<{ resultado: IExameResultado, guid: (string | number) }>());
export const saveFailExameResultado = createAction(ExameResultadoActionTypes.SaveFailExameResultado, props<{ resultado: IExameResultado }>());
export const updateExameResultado = createAction(ExameResultadoActionTypes.UpdateExameResultado, props<{ resultado: IExameResultado }>());
export const resetExameResultado = createAction(ExameResultadoActionTypes.ResetExameResultado);
export const deleteExameResultado = createAction(ExameResultadoActionTypes.DeleteExameResultado, props<{ resultadoId: number }>());
export const deleteSuccessExameResultado = createAction(ExameResultadoActionTypes.DeleteSuccessExameResultado, props<{ deletedId: number }>());
export const deleteFailExameResultado = createAction(ExameResultadoActionTypes.DeleteFailExameResultado, props<{ deletedId: number }>());
export const cleanExameResultado = createAction(ExameResultadoActionTypes.CleanExameResultado);
