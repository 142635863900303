import { LogService } from '@medlogic/shared/shared-interfaces';


const log = new LogService();

const BASE_PATH = '/assets/img';

export const swapImagePerson = (img: HTMLImageElement | EventTarget, sexo: string = 'generic'): void => {
    try {
        if (!img) {
            return;
        }

        switch (sexo?.toLocaleLowerCase()) {
            default:
            case 'generic':
                (img as HTMLImageElement).src = `${BASE_PATH}/person.png`;
                break;
            case 'male':
            case 'masculino':
                (img as HTMLImageElement).src = `${BASE_PATH}/male.png`;
                break;
            case 'female':
            case 'feminino':
                (img as HTMLImageElement).src = `${BASE_PATH}/female.png`;
                break;
        }
    } catch (error) {
        log.Registrar('pwa-util', 'swapImagePerson', error.message);
    }
}

export const swapImageProcess = (img: HTMLImageElement | EventTarget): void => {
    try {
        if (!img) {
            return;
        }
        (img as HTMLImageElement).src = `${BASE_PATH}/task.png`;
    } catch (error) {
        log.Registrar('pwa-util', 'swapImageProcess', error.message);
    }


}
