export interface IPermission {
    Create: boolean;
    Read: boolean;
    Update: boolean;
    Delete: boolean;
    Redirect: boolean;
    Save: boolean;
    Finish: boolean;
    Cancel: boolean;
}
