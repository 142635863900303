import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProgressEffects } from './+state/progress.effects';

import * as fromProgress from './+state/progress.reducer';

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromProgress.progressesFeatureKey, fromProgress.reducer),
  EffectsModule.forFeature([ProgressEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
})
export class StateProgressModule { }
