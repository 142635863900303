<!-- <mat-toolbar><button mat-button (click)="onNoClick()"><i class="fa fa-close"> Fechar</i></button>

 -->
<!-- <div class="container1">
  <button class="btn" (click)="onNoClick()">
    <i class="fa fa-close"> Fechar</i>
  </button>
</div> -->
<div class="container">

  <mat-toolbar color="basic">
    <span class="example-spacer"></span>
    <mat-icon class="example-icon" (click)="onNoClick()">close</mat-icon>
  </mat-toolbar>
  
  <form class="example-form">
    <textarea [(ngModel)]="value" rows="17" [readonly]="isReadOnly" [ngModelOptions]="{standalone: true}"></textarea>
  </form>
  
  <button mat-raised-button (click)="onConfirmClick($event)">OK</button>

</div>