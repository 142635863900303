import { IRouterState } from '@medlogic/shared/shared-interfaces';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import * as fromRouter from '@ngrx/router-store';

@Injectable()
export class CustomSerializer implements fromRouter.RouterStateSerializer<IRouterState> {

  serialize(routerState: RouterStateSnapshot): IRouterState {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;
    return { url, queryParams, params };
  }


}
