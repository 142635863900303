import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { CostCenterActionTypes } from './cost-center.actions';
import { initialStateCostCenter } from './cost-center.reducer';

const KEY = 'MEDLOGIC_COSTCENTER_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  CostCenterActionTypes.LoadCostCenter,
  CostCenterActionTypes.FetchSuccessSetCostCenter,
  CostCenterActionTypes.FetchSuccessSetCostCenters,
  CostCenterActionTypes.SetCostCenter,
];

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialStateCostCenter);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
