export interface IPersonRelationship {
    ocorrenciaNo: number;
    idPessoa: number;
    codigo: any;
    titulo: string;
    habilitado: boolean;
    status: string;
    idUsuario: number;
    // novos
    nome: string;
    idEscola: number;
    email: string;
    usuarioExecutor: string;
    dtCadastro: Date;
}
