
import { map, refCount, publishReplay, toArray } from 'rxjs/operators';
import { ICustomerConfig } from '../interface/icustomer-config.interface';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { IForm } from '../interface/iform';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';

/* Essa classe é correspondente ao CAD IDOSO BEM CUIDADO. */
@Injectable({
  providedIn: 'root'
})
export class CustomerConfigService extends UnsubscribeOnDestroyAdapter {

  // tslint:disable-next-line: max-line-length
  private lstVariaveis = 'V_4658,V_27907,V_30363,V_30364,V_30455,V_30470,V_31575,V_31923,V_31952,V_31953,V_31970,V_31971,V_31972,V_31973,V_32637,V_32638,V_32639,V_32640,V_32641,V_32642,V_32643,V_32644,V_32645,V_32646,V_32895,V_32897,V_32898,V_32899,V_32900,V_34313,V_34367,V_34368,V_34369,V_34998,V_34999';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: ICustomerConfig[] = new Array<ICustomerConfig>();

  cadastroNo = 9733;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected log: LogService) {
    super();
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<ICustomerConfig> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<ICustomerConfig> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(c => {
            try {
              return {
                cADBANCO: c.V_4658,
                cADBENEFICIARIO: c.V_27907,
                cadIntervecoesMedicamentos: c.V_30363,
                cadEstoqueMateriais: c.V_30364,
                cadPaciente: c.V_30455,
                cadCheckMedicamentos: c.V_30470,
                cadIntervencoesHorario: c.V_31575,
                cadCheckSinaisVitais: c.V_31923,
                cadRotinasCuidadoHospede: c.V_31952,
                cadCheckinRotinaCuidado: c.V_31953,
                cadSinaisVitaisSinglePage: c.V_31970,
                cADENTRADAFLUXODECAIXA: c.V_31971,
                cADESTOQUEDEMATERIAIS: c.V_31972,
                cADESCALAS: c.V_31973,
                codigo: c.V_32637,
                titulo: c.V_32638,
                habilitado: c.V_32639.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_32639) ? true : false,
                cadBeneficiario: c.V_32640,
                cadCentroCusto: c.V_32641,
                cadUnidade: c.V_32642,
                cadUnidadeNegocio: c.V_32643,
                cadItemEstoque: c.V_32644,
                cADENTRADAFLUXODECAIXA2: c.V_32645,
                cadDescricao: c.V_32646,
                cadCategoria: c.V_32895,
                cliente: c.V_32897,
                fornecedor: c.V_32898,
                funcionario: c.V_32899,
                produto: c.V_32900,
                cadEvolucaoSinglePage: c.V_34313,
                cADFONTE: c.V_34367,
                cADMEIOPAGAMENTO: c.V_34368,
                cadTipoMaterial: c.V_34369,
                cADCLASSE: c.V_34998,
                cADPERIODICIDADE: c.V_34999
              } as ICustomerConfig;
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'getFromCadastro.map', error.message);
            }
          }),
          publishReplay(),
          refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadastro', error.message);
    }
    return null;
  }

  protected getFiltered(cadastroNo: number, startDate: Date, endDate: Date): Observable<ICustomerConfig> {
    try {
      return this.getWithCache(cadastroNo, startDate, endDate);
      // .filter(f => !f.isRecurrent)
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save(ano: number, customerConfig: ICustomerConfig, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const uno: number = this.cnf.usuarioLogadoNo;
      const forms: IForm[] = this.mapToForm(customerConfig).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(customerConfig: ICustomerConfig): IForm[] {
    try {
      return [
        { VariavelNo: 4658, ValorDado: customerConfig.cADBANCO || '' },
        { VariavelNo: 27907, ValorDado: customerConfig.cADBENEFICIARIO || '' },
        { VariavelNo: 30363, ValorDado: customerConfig.cadIntervecoesMedicamentos || '' },
        { VariavelNo: 30364, ValorDado: customerConfig.cadEstoqueMateriais || '' },
        { VariavelNo: 30455, ValorDado: customerConfig.cadPaciente || '' },
        { VariavelNo: 30470, ValorDado: customerConfig.cadCheckMedicamentos || '' },
        { VariavelNo: 31575, ValorDado: customerConfig.cadIntervencoesHorario || '' },
        { VariavelNo: 31923, ValorDado: customerConfig.cadCheckSinaisVitais || '' },
        { VariavelNo: 31952, ValorDado: customerConfig.cadRotinasCuidadoHospede || '' },
        { VariavelNo: 31953, ValorDado: customerConfig.cadCheckinRotinaCuidado || '' },
        { VariavelNo: 31970, ValorDado: customerConfig.cadSinaisVitaisSinglePage || '' },
        { VariavelNo: 31971, ValorDado: customerConfig.cADENTRADAFLUXODECAIXA || '' },
        { VariavelNo: 31972, ValorDado: customerConfig.cADESTOQUEDEMATERIAIS || '' },
        { VariavelNo: 31973, ValorDado: customerConfig.cADESCALAS || '' },
        { VariavelNo: 32637, ValorDado: customerConfig.codigo || '' },
        { VariavelNo: 32638, ValorDado: customerConfig.titulo || '' },
        { VariavelNo: 32639, ValorDado: customerConfig.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 32640, ValorDado: customerConfig.cadBeneficiario || '' },
        { VariavelNo: 32641, ValorDado: customerConfig.cadCentroCusto || '' },
        { VariavelNo: 32642, ValorDado: customerConfig.cadUnidade || '' },
        { VariavelNo: 32643, ValorDado: customerConfig.cadUnidadeNegocio || '' },
        { VariavelNo: 32644, ValorDado: customerConfig.cadItemEstoque || '' },
        { VariavelNo: 32645, ValorDado: customerConfig.cADENTRADAFLUXODECAIXA || '' },
        { VariavelNo: 32646, ValorDado: customerConfig.cadDescricao || '' },
        { VariavelNo: 32895, ValorDado: customerConfig.cadCategoria || '' },
        { VariavelNo: 32897, ValorDado: customerConfig.cliente || '' },
        { VariavelNo: 32898, ValorDado: customerConfig.fornecedor || '' },
        { VariavelNo: 32899, ValorDado: customerConfig.funcionario || '' },
        { VariavelNo: 32900, ValorDado: customerConfig.produto || '' },
        { VariavelNo: 34313, ValorDado: customerConfig.cadEvolucaoSinglePage || '' },
        { VariavelNo: 34367, ValorDado: customerConfig.cADFONTE || '' },
        { VariavelNo: 34368, ValorDado: customerConfig.cADMEIOPAGAMENTO || '' },
        { VariavelNo: 34369, ValorDado: customerConfig.cadTipoMaterial || '' },
        { VariavelNo: 34998, ValorDado: customerConfig.cADCLASSE || '' },
        { VariavelNo: 34999, ValorDado: customerConfig.cADPERIODICIDADE || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist(ano: number, customerConfig: ICustomerConfig, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return new Observable(observer => {
        this.subs.sink = this.getFromCadastro(ano, null, null).pipe(
          toArray())
          .subscribe(items => {
            const founded = items.findIndex(f => this.glb.isEqual(f[compareFieldName], customerConfig[compareFieldName])) >= 0;
            observer.next(founded);
            if (!founded) {
              this.subs.sink = this.save(ano, customerConfig)
                .subscribe(s => observer.complete());
            } else {
              observer.complete();
            }
          });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
  }


}
