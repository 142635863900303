import { ISmartList } from '@medlogic/shared/shared-interfaces';
import { Component, Input, Output, EventEmitter, OnInit, ContentChild, TemplateRef } from '@angular/core';
import { LogService, IListItem } from '@medlogic/shared/shared-interfaces';
import { fadeAnimation } from '@medlogic/shared/utils';

@Component({
  selector: 'ui-smart-list',
  templateUrl: './smart-list.component.html',
  styleUrls: ['./smart-list.component.css'],
  animations: [fadeAnimation]
})
export class SmartListComponent implements OnInit {

  @Input() groups: ISmartList[];
  @Input() search: string;

  @Output() itemClick = new EventEmitter<any>();

  @ContentChild('content', { static: false }) contentRef: TemplateRef<any>;

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  onItemClick(item: IListItem<any>): void {
    try {
      this.itemClick.emit(item);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onItemClick', error.message);
    }
  }


}
