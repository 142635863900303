export interface ISmartSearch {
  ProcessoNome: string;
  SolicitanteNome: string;
  TarefaNo: number;
  TarefaStatusNo: number;
  VariavelIdentificacao1: string;
  VariavelIdentificacao2: string;
  VariavelIdentificacao3: string;
  HasOcorrenciaAtual: boolean;
  PapelExecutorNo: number;
  UsuarioExecutorTarefaNo: number;
  UsuarioExecutorNome: string;
  AtividadeType: string;
  CanEditTarefa: boolean;
  OcorrenciaNo: number;
  ProcessoNo: number;
  OcorrenciaStatusNo: number;
  DtInicio: Date;
  DtConclusao: Date;
  UsuarioResponsavelNo: number;
  UsuarioExecutorNo: number;
  AtividadeNo: number;
  AtividadeNome: string;
  DtLimiteAlerta: Date;
  DtValidacao: Date;
  IsUrgent: boolean;
  DtUrgent: Date;
  OcorrenciaNoGuid: any;
}
