import { IScheduled } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setScheduled = createAction(
  '[Scheduled/API] Set Scheduled',
  props<{ selectedId: string }>()
);
export const loadScheduleds = createAction(
  '[Scheduled/API] Load Scheduleds'
);

export const loadScheduledsSuccess = createAction(
  '[Scheduled/API] Load Scheduleds Success',
  props<{ scheduleds: IScheduled[] }>()
);

export const scheduledFail = createAction(
  '[Scheduled/API] Scheduled Fail',
  props<{ error: string }>()
);

export const addScheduled = createAction(
  '[Scheduled/API] Add Scheduled',
  props<{ scheduled: IScheduled }>()
);

export const addScheduleds = createAction(
  '[Scheduled/API] Add Scheduleds',
  props<{ scheduleds: IScheduled[] }>()
);

export const upsertScheduled = createAction(
  '[Scheduled/API] Upsert Scheduled',
  props<{ scheduled: IScheduled }>()
);

export const upsertScheduleds = createAction(
  '[Scheduled/API] Upsert Scheduleds',
  props<{ scheduleds: IScheduled[] }>()
);

export const updateScheduled = createAction(
  '[Scheduled/API] Update Scheduled',
  props<{ scheduled: Update<IScheduled> }>()
);

export const updateScheduleds = createAction(
  '[Scheduled/API] Update Scheduleds',
  props<{ scheduleds: Update<IScheduled>[] }>()
);

export const deleteScheduled = createAction(
  '[Scheduled/API] Delete Scheduled',
  props<{ id: string }>()
);

export const deleteScheduleds = createAction(
  '[Scheduled/API] Delete Scheduleds',
  props<{ ids: string[] }>()
);

export const clearScheduleds = createAction(
  '[Scheduled/API] Clear Scheduleds'
);
export const clearSelectedScheduled = createAction(
  '[Card Scheduled] Clear Selected Scheduleds'
);