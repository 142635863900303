import { metaReducers } from './+state/prescription.metareducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrescriptionCustomService, PrescriptionMedicationCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PrescriptionEffects } from './+state/prescription.effects';
import { prescriptionReducer } from './+state/prescription.reducer';

const MODULES = [
  CommonModule
];

const STATE_MODULES = [
  StoreModule.forFeature('prescription', prescriptionReducer, { metaReducers }),
  EffectsModule.forFeature([PrescriptionEffects]),
];

const SERVICES = [
  PrescriptionCustomService,
  PrescriptionMedicationCustomService
]

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})


export class StatePrescriptionModule { }
