import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { WebService } from '@medlogic/shared/shared-data-access';
import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ILoginModel } from '../interface/ilogin-model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  urlGetUsuario = 'Role/GetDataUser';

  urlAuthWebApiBase = '/NetCoreWebApi/api/';
  urlRegister = 'ApplicationUser/Register';

  constructor(
    private basepage: BasePageService,
    private cnfJson: ConfigJsonService,
    private log: LogService,
    private ws: WebService) { }


  getDadosUsuario(): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGetUsuario);
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDadosUsuario', error.message);
    }
    return of(null);
  }

  register(login: string, password: string, userName: string, email: string): Observable<any> {
    try {
      const data = {
        Login: login,
        UsuarioSenha: password,
        UsuarioNome: userName,
        Email: email
      } as ILoginModel;
      const url = this.basepage.format(`${this.cnfJson.baseUrl}${this.urlAuthWebApiBase}${this.urlRegister}`);
      return this.basepage.post(url, data);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'register', error.message);
    }
    return of(null);
  }


}
