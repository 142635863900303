<mat-toolbar>
  <span class="example-spacer"></span>
  <button mat-button type="button" (click)="onCloseClick($event)">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<h2>
  {{ title }}
</h2>
<div *ngIf="options" class="divCheckboxs">
  <h1>Desmarque o item que ainda NÃO foi checkado:</h1>
  <div class="checkboxes">
    <div *ngFor="let medication of options; let i = index" class="divCheckbox">
      <input
        class="checkboxMedication"
        type="checkbox"
        name="{{ medication.name }}"
        id="{{ medication.name }}-{{ i }}"
        value="{{ medication.name }}"
        checked="true"
      />
      <label class="labelCheckbox" for="{{ medication.name }}-{{ i }}">
        {{ medication.name }} - {{ medication.time }}
      </label>
    </div>
  </div>
</div>
<mat-toolbar>
  <button mat-button type="button" (click)="onCancelClick($event)">
    <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">cancel</mat-icon>
    <span>Cancelar</span>
  </button>

  <button mat-button type="button" (click)="onConfirmClick($event)">
    <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
    <span>Confirmar</span>
  </button>
</mat-toolbar>
