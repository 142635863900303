import { Injectable } from '@angular/core';
import { routeCarePlanCategory } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';


@Injectable({ providedIn: 'root' })
export class FhirCarePlanCategoryService extends FhirGenericService {

  protected url = routeCarePlanCategory;

}
