export const fieldsMapHistoricoCompra = {

    // V_27999: { name: 'historicoCompraEstoqueId', type: 'number'},
    // V_27999: { name: 'index', type: 'string'},

    historicoCompraEstoqueId: { name: 'historicoCompraEstoqueId', type: 'number' },
    // tenantId
    // tenantId: { name: 'tenantId', type: 'number' },
    OcorrenciaNo: { name: 'ocorrenciaNo', type: 'string' },
   
    V_832: { name: 'dosagem', type: 'string'},
    V_30350: { name: 'material', type: 'string'},
    // V_27993: { name: 'centroCusto', type: 'string'},
    V_27993: { name: 'CentroCusto', type: 'string', relationshipField: 'name', relationshipFieldId: 'centroCustoId' },

    V_27997: { name: 'unidademedida', type: 'string'},
    V_27999: { name: 'codigo', type: 'number'},
    V_28000: { name: 'quantidade', type: 'number'},
    V_28001: { name: 'habilitado', type: 'boolean'},
    V_28004: { name: 'data', type: 'Date'},
    V_28012: { name: 'preco', type: 'number'},
    V_28014: { name: 'fornecedorNome', type: 'string'},
    V_28023: { name: 'unidadeNegocio', type: 'string'},
    V_30269: { name: 'medicamentoany', type: 'string'},
    V_100326: { name: 'codPacienteNomedoPacienteCodMedicamento', type: 'string'},
    V_34687: { name: 'codigoPedido', type: 'number'},
    V_34705: { name: 'pedidoConferido', type: 'boolean'},
  
    V_27987: { name: 'estoqueMaterialEstoqueMaterialId', type: 'number'},
    // V_27987: { name: 'codigoItemEstoque', type: 'number'},
    
  
    V_101702: { name: 'titulo', type: 'string'},
    V_100306: { name: 'idMedicamento', type: 'number'},
    V_100321: { name: 'lote', type: 'string'},
    V_100322: { name: 'dtValidade', type: 'Date'},
    V_34718: { name: 'quantidadePorEmbalagem', type: 'number'},
    V_10406: { name: 'quantidadeDeEmbalagens', type: 'number'},
    // XXX: { name: 'dataCompra', type: 'Date'},
    V_28018: { name: 'tipoMaterial', type: 'string'},
    // XXX: { name: 'quantidadeEmbalagem', type: 'number'},
    // XXX: { name: 'validoAte', type: 'Date'},
    // XXX: { name: 'idCentroCusto', type: 'number'},
    // XXX: { name: 'quantidadePacotes', type: 'number'},
    // XXX: { name: 'createdBy', type: 'string'},
    // XXX: { name: 'updatedBy', type: 'string'},
    // XXX: { name: 'createdAt', type: 'Date'},
    // XXX: { name: 'updatedAt', type: 'Date'},
    V_100854: { name: 'dosageUnityId', type: 'number' },
    V_100853: { name: 'dosageValue', type: 'number' },
    V_27994: { name:  'estoque', type: 'number' },
    V_28452: { name:  'invoiceNumber', type: 'string' },
    
}
