import { EmailService } from '@medlogic/shared/shared-data-access';
import { IEmailMessage } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { sendEmailSuccess, sendEmailFail } from './state-utils-email.actions';
import { of } from 'rxjs';

import * as EmailsActions from './state-utils-email.actions';


@Injectable()
export class UtilsEmailEffects {

  constructor(
    private actions$: Actions,
    private emailSrv: EmailService
  ) { }

  send$ = createEffect(() => this.actions$
    .pipe(
      ofType(EmailsActions.sendEmail),
      mergeMap(({ message }) => this.emailSrv.send(message)),
      switchMap((message: IEmailMessage) => {
        return message ?
          [
            sendEmailSuccess({ message }),
          ] :
          [sendEmailFail({ error: 'error-sendEmail' })];
      }),
      catchError((e: unknown) => {
        console.log(e);
        return of(sendEmailFail({ error: 'error-sendEmail' }));
      })
    )
  );


}
