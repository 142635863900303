<!-- Mockup para uma sensação aprimorada de carregamento -->
<ng-container>
  
  <ng-container [ngSwitch]="module" *ngIf="isLoadingSmooth">

    <ng-container *ngSwitchCase="'ATIVIDADE'">

      <div class="title" [class.black]="enTheme === ENTHEME.black" [class.is-desktop]="!isMobile" [@simpleFadeAnimation]="'in'"></div>

      <div class="container" *ngIf="isMobile" [class.black-container]="enTheme === ENTHEME.black"
        [@simpleFadeAnimation]="'in'">

        <ng-container *ngFor="let item of (items$ | async)">
          <div class="mock label" [class.black]="item?.isBlack"></div>
          <div class="mock input" [class.black]="item?.isBlack"></div>
        </ng-container>

      </div>

      <div class="container" *ngIf="!isMobile" [@simpleFadeAnimation]="'in'">
        <div class="mock tabs" [class.black]="enTheme === ENTHEME.black"></div>
        <div class="mock tab" [class.black]="enTheme === ENTHEME.black"></div>
        <div class="mock action" [class.black]="enTheme === ENTHEME.black"></div>
      </div>

    </ng-container>

  </ng-container>

</ng-container>
<!-- FIM Mockup -->