import { LibService } from '@medlogic/shared/geform';
import { Injectable } from '@angular/core';
import {
  LogService,
  ConfigJsonService, GlobalService
} from '@medlogic/shared/shared-interfaces';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';

@Injectable({
  providedIn: 'root'
})
export abstract class IvcfService {

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnfJson: ConfigJsonService,
    protected log: LogService,
    protected lib: LibService,
    protected basepage: BasePageService
  ) {
  }


}
