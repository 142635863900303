import { IView } from './iview';
import { IComment } from './icomment';
export interface IPost {
  ocorrenciaNo: number;
  codigo: any;
  titulo: any;
  habilitado: boolean;
  conteudo: string;
  data: Date;
  nomeAutor: any;
  imgUrl: any,
  legendaImagem: any;
  idPessoa: any;
  //
  comentarios: IComment[];
  views: IView[];
}
