<div>
  <div class="top-bar">
    <button mat-button type="button" (click)="onBack($event)">
      <mat-icon class="icn-back">close</mat-icon>
      <span>CANCELAR</span>
    </button>
  </div>
  <div class="tab-content" style="min-height:580px">
    <div class="tab-pane fade in active" id="webCam">
      <div class="row">
        <div class="col-md-6" [class.hidden]="context">
          <div class="box">
            <div class="box-header with-border">
              <!-- <h3 class="box-title">Câmera</h3> -->
            </div>
            <div class="box-body">
              <video #video id="video_ctrImage" width="640" height="480" autoplay></video>
              <button mat-icon-button (click)="capture($event)"><mat-icon>donut_large</mat-icon>CAPTURAR</button>
            </div>
          </div>
        </div>
        <div class="col-md-6" [class.hidden]="!context">
          <div class="box">
            <!-- <div class="box-header with-border">
              <h3 class="box-title">Confirmar a imagem?</h3>
            </div> -->
            <div class="box-body">
              <div style="width:640px; height:480px">
                <canvas #canvas id="canvas_ctrImage" width="640" height="480"></canvas>
              </div>
              <br />
              <div class="col-md-6">
                <!-- <button mat-raised-button (click)="uploadFotoCtrImage($event)">CONFIRMAR</button>
                <button mat-raised-button (click)="context = null">CANCELAR</button> -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
