import { IKeyValue } from '@medlogic/shared/shared-interfaces';

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/* Classe utilizada para a passagem de parâmetros de uma atividade para outra, tanto
 * de uma pai para a filha (grid -> edição de item do grid), como o retorno da filha
 * para a pai. Prevê a edição/inclusão/exclusão de itens.
 *
 */
@Injectable()
export class StoreProviderService {
  /* As Atividades devem subcrever as stores para receberem as respectivas atualizações.
  * A identificação do item, uma vez que uma store terá uma coleção, se dará pela ocorrenciaNo e atividadeNo.
  */

  activityStore = new Subject<IKeyValue>();
}
