<div #header>
  <div>
    <button type="button" (click)="onBack()">
      < Voltar</button> <h1>{{date}}</h1>
  </div>
  <div>
    <input type="text" [(value)]="keyword">
  </div>
</div>
<div class="table-responsive">
  <!-- TODO: Converter para material -->
  <!-- <table class="table table-striped table-bordered" [libTable]="[header, footer]" [mfData]="tasks | libTaskSearch:search" #mf="mfDataTable">
    <thead #head>
      <tr>
        <th>Hora</th>
        <th>Tarefa</th>
        <th>Identificação I</th>
        <th>Identificação II</th>
        <th>Data</th>
        <th>Executor</th>
        <th>Ocorrência</th>
        <th>Processo</th>
        <th>Remetente</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let task of mf.data">
        <td>{{ task.hora }}</td>
        <td>{{ task.nome }}</td>
        <td>{{ task.identificacao1 }}</td>
        <td>{{ task.identificacao2 }}</td>
        <td>{{ task.data }}</td>
        <td>{{ task.executorNome }}</td>
        <td>{{ task.ocorrenciaNo }}</td>
        <td>{{ task.processoNome }}</td>
        <td>{{ task.remetente }}</td>
        <td>{{ task.status }}</td>
      </tr>
    </tbody>
  </table> -->
</div>
<div #footer>
  <div class="ibox-content">
    <button class="btn btn-primary"><i class="fa fa-hourglass-start"></i>Novo</button>
    <button class="btn btn-primary"><i class="fa fa-tasks"></i>Todas Tarefas</button>
    <button class="btn btn-primary"><i class="fa fa-check"></i>Concluídas</button>
  </div>
</div>
