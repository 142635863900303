import { IGeneric, IPerson } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { IListItem } from '@medlogic/shared/shared-interfaces';

export enum GenericActionTypes {
  LoadGeneric = '[Generic Component] Load Generic',
  ReloadGeneric = '[Prescription Effect] Reload Generic',
  LoadGenericByPerson = '[Generic Component] Load Generic By Person',
  FetchSuccessGeneric = '[Generic API] Fetch Success Generic', // Set the Generic to the Store, after Effects
  FetchErrorGeneric = '[Generic API] Fetch Error Generic',
  SetAllGenerics = '[Generic Component] Set All Generics',
  SetGeneric = '[Generic Component] Set Generic',
  SetGenericById = '[Page Generic List Component] Set Generic By id',
  UpdateGeneric = '[Generic Component] Update Generic',
  FetchSuccessGenericById = '[Generic API] Fetch Success Generic By Code', // Set the Generic to the Store, after Effects
  CleanGeneric = '[Page Generic List] Clean Generic State', // Set the Generic to the Store, after Effects
  CleanGenericId = '[Page Generic List] Clean Generic Id', // Necessário, pois senão poderia gerar transporte incorreto da ocorrencia entre atividades.
  // GetById = '[Code Validation Component] Get By Code',
}

export const loadGeneric = createAction(
  GenericActionTypes.LoadGeneric,
  props<{ genericAno: number, identification: IListItem<IGeneric>, genericLabel: string }>()
);
export const reloadGeneric = createAction(GenericActionTypes.ReloadGeneric);
export const loadGenericByPerson = createAction(
  GenericActionTypes.LoadGenericByPerson,
  props<{ genericAno: number, genericLabel: string, person: IPerson }>()
);
export const setAllGenerics = createAction(GenericActionTypes.SetAllGenerics, props<{ allGenerics: IListItem<IGeneric>[] }>());
export const fetchSuccessGeneric = createAction(GenericActionTypes.FetchSuccessGeneric, props<{ allGenerics: IListItem<IGeneric>[] }>());
export const setGeneric = createAction(GenericActionTypes.SetGeneric, props<{ selectedGeneric: IListItem<IGeneric> }>());
export const setGenericById = createAction(GenericActionTypes.SetGenericById, props<{ id: number, tno: number }>());
export const fetchErrorGeneric = createAction(GenericActionTypes.FetchErrorGeneric);
export const updateGeneric = createAction(GenericActionTypes.UpdateGeneric, props<{ generic: IListItem<IGeneric> }>());
export const cleanGeneric = createAction(GenericActionTypes.CleanGeneric);
export const cleanGenericId = createAction(GenericActionTypes.CleanGenericId);
// export const getGenericById = createAction(GenericActionTypes.GetById, props<{ id: string }>());
export const fetchSuccessGenericById = createAction(GenericActionTypes.FetchSuccessGenericById,
  props<{ allGenerics: IListItem<IGeneric>[] }>());
