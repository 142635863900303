import { Pipe, PipeTransform } from '@angular/core';
import { IMedication } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'medicationPendingPipe'
})
export class MedicationPendingPipe implements PipeTransform {

  constructor(
    private log: LogService) { }

 /* Retorna a lista de medicação que ainda não receberão checkin, mas que deveriam ser tomados.
   * O status já resolve se deveria ter sido tomado na data.
  */
  transform(items: IMedication[]): IMedication[] {
    try {
      if (!items || items.length <= 0) { return items; }
      return items.filter(f => !f.took && f.status !== '-');
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return items;
  }


}
