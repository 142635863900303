import { Directive, Input, ElementRef, Renderer2 } from '@angular/core'

@Directive({
  selector: '[libTasksOfDay]'
})
export class TasksOfDayDirective {

  private _numOfTasks: any;
  @Input('libTasksOfDay') public get numOfTasks(): any {
    return this._numOfTasks;
  }

  public set numOfTasks(v: any) {
    this._numOfTasks = v;
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  //Para resolver o problema do - na atualização, procurar um evento que não seja o Init
  ngOnInit(): void {
    this.applyStyles(this.numOfTasks);
  }

  applyStyles(numOfTasks: number): void {
    if (numOfTasks > 0) {
      this.renderer.setStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setStyle(this.el.nativeElement, 'font-size', '36px');
      this.renderer.setStyle(this.el.nativeElement, 'background-color', 'cornflowerblue');
    }
  }
}
