import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { CalculadoraConditionService } from '../shared/service/calculadora-condition.service';
import { LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[libTabSize]'
})
export class TabSizeDirective implements OnInit {

  private el: HTMLElement;
  private topMargin = 500;

  constructor(
    el: ElementRef,
    private log: LogService,
    private calc: CalculadoraConditionService
  ) { this.el = el.nativeElement; }

  @Input('libTabSize') componentes: Array<IAtividadeComponenteDAL>;
  @Input() isMobile: boolean;

  ngOnInit(): void {
    try {
      if (this.isMobile) {
        const visibleComponentHeights = this.componentes
          .filter(f => this.calc.isVisibleCtrl(f) && f.ShowInMobile)
          .reduce((cumulated, ctrl) => cumulated + ctrl.Altura, 0);
        this.el.style.height = `${visibleComponentHeights + this.topMargin}px`;
        this.el.style.padding = '0';
        this.el.style.margin = '0';
        this.el.style.fontSize = '16px';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

}
