import { ConfigStateService } from '@medlogic/shared/state-config';
import { NavigationService } from '../../shared/service/navigation.service';
import { ITarefa } from '../../shared/interface/itarefa';
import { Component, OnInit } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { DisplayObject } from '@medlogic/shared/gecore';

@Component({
  selector: 'lib-agenda-view',
  templateUrl: './agenda-view.component.html',
  styleUrls: ['./agenda-view.component.css']
})
export class AgendaViewComponent extends DisplayObject implements OnInit {
  week: Array<any> = [];
  times: Array<string> = [];
  tasks: Array<ITarefa> = [];
  keyword: Event | string = '';
  date: Date;
  control: any;

  constructor(
    private log: LogService,
    private config: ConfigStateService,
    private global: GlobalService,
    protected navigation: NavigationService,
    protected msg: MsgPtBR
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.navigation.addToHistory(this.navigation.getRouteSchedule(), this.msg.MODULE_NAME_AGENDA);
      this.refresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /*Recarrega todos os dados */
  protected refresh(): void {
    try {
      this.loadWeeks();
      this.loadTime();
      this.loadTasks();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /*Identifica e é uma hora, ou fração  */
  isMainTime(time: string): boolean {
    try {
      return this.global.Right(time, 2) === '00';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isMainTime', error.message);
    }
  }

  /*Carrega as semanas, a partir do domingo do dia atual.  */
  protected loadWeeks(): void {
    try {
      const today = new Date();
      const sundayDiff = today.getDay();
      const sunday = this.global.DateAdd('d', sundayDiff, today);
      let currentDate = sunday;
      for (let i = 0; i < 7; i++) {
        const day = this.global.DiaDaSemana(currentDate.getDay());
        const date = this.global.DateToddMMYYYY(currentDate);
        this.week.push({ day, date });
        currentDate = this.global.DateAdd('d', 1, currentDate);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadWeeks', error.message);
    }
  }

  /*Carrega as linhas com os intervalos de horas na primeira coluna */
  protected loadTime(): void {
    try {
      const startTime = this.config.startTime;
      const endTime = this.config.endTime;
      const interval = this.config.intervalMin;
      const currentTime = startTime;
      for (let i = startTime; i <= endTime; i = i + interval / 60) {
        this.times.push(this.global.FormatarHora(i));
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadTime', error.message);
    }
  }

  /*Carrega todas as tarefas da semana  */
  protected loadTasks(): void {
    try {
      this.tasks = [
        { date: '24/02/2017', numOfTasks: 2, time: '10:00' },
        { date: '26/02/2017', numOfTasks: 4, time: '12:00' },
        { date: '27/02/2017', numOfTasks: 3, time: '10:00' },
        { date: '28/02/2017', numOfTasks: 1, time: '14:00' },
        { date: '30/02/2017', numOfTasks: 1, time: '16:00' },
        { date: '02/03/2017', numOfTasks: 2, time: '16:00' }
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadTasks', error.message);
    }
  }

  /* A partir da lista de todas as tarefas, retorna o número de tarefas do dia. */
  getTasksOf(date: string, time: string): string {
    try {
      const numOf = this.tasks
        .filter((t) => t.date === date && t.time === time)
        .map((m) => m.numOfTasks)
        .reduce((a, b) => a + b, 0);
      return numOf > 0 ? this.global.Right('0' + numOf, 2) : '';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getTasksOf', error.message);
    }
  }

  /*Abre a lista de tarefas */
  gotoTask(date: string, time: string): void {
    try {
      this.navigation.gotoTask(date, time);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'gotoTask', error.message);
    }
  }

  onBack(): void { }
}
