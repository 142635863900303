import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { DadoDAL } from './dado-dal';
import { Injectable } from '@angular/core';
import { ObjectDAL } from '@medlogic/shared/shared-data-access';
import { mergeMap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { WebService } from '@medlogic/shared/shared-data-access';

@Injectable({
  providedIn: 'root'
})
export class ExecucaoTarefaDAL extends ObjectDAL {
  //  <ExecucaoTarefa_DeleteCadastro xmlns="GE/ws/">
  //   <token>string</token>
  //   <OcorrenciaNo>long</OcorrenciaNo>
  //   <AtividadeNo>long</AtividadeNo>
  // </ExecucaoTarefa_DeleteCadastro>

  constructor(webService: WebService, log: LogService, global: GlobalService, protected dadoDAL: DadoDAL) {
    super(webService, log, global);
  }

  /* Exclui um item no cadastro. Atenção, como retorna Observable é necessário dar um subscribe para que seja executado. */
  deleteCadastro(ocorrenciaNo: number, cadastroNo: number): Observable<any> {
    try {
      const method = 'ExecucaoTarefa_DeleteCadastro';
      const result = this.webService.connect(method, [
        { name: 'OcorrenciaNo', value: ocorrenciaNo },
        { name: 'AtividadeNo', value: cadastroNo }
      ]).pipe(
        // Necessário notificar o serviço sobre a finalização da exclusão para geração do XML
        mergeMap((res) => {
          if (res && +res > 0) {
            this.dadoDAL.notificarConclusaoSalvamento(cadastroNo, ocorrenciaNo);
          }
          return of(res); // Devolve o mesmo observable.
        }),
        catchError((error) => of(null))
      );
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteCadastro', error.message);
    }
  }

  /*Exclui vários itens do mesmo cadastro de uma vez.
     * Na verdade fará várias chamadas individuais ao serviço,
     * mas notificará a finalização somente após todos terem sido concluídos.
     * TODO: Ainda não há suporte suficiente do serviço para executar dessa forma.
     */
  // deleteCadastros(
  //     lstOcorrenciaNo: number[],
  //     cadastroNo: number
  // ): Observable<any> {
  //     try {
  //         const method = "ExecucaoTarefa_DeleteCadastro";

  //         let result = Observable.create(observer => {
  //             //Percorre todos os itens e vai gerando emissões assíncronas dos resultados.
  //             lstOcorrenciaNo.forEach(o => {
  //                 let del = this.webService.connect(method,
  //                     [
  //                         { name: "OcorrenciaNo", value: o },
  //                         { name: "AtividadeNo", value: cadastroNo }
  //                     ]);
  //                 observer.next(del);
  //             });
  //             //Após percorrer a lista inteira, finaliza a sequencia de observables
  //             observer.complete();
  //         });
  //         //Necessário notificar o serviço sobre a finalização da exclusão para geração do XML
  //         //Esse resultado será chamado somente quanto TODOS os métodos retornarem.
  //         //TODO: Falta criar uma maneira de notificar todos de uma única vez
  //         result
  //             .subscribe(c =>
  //                 c,
  //             (er) => this.log.Registrar(this.constructor.name, '', er),
  //             () => this.dadoDAL.notificarConclusaoSalvamento(cadastroNo, ocorrenciaNo)
  //             );
  //         return result;
  //     } catch (error) {
  //         this.log.Registrar(this.constructor.name, 'deleteCadastro', error.message);
  //     }
  // }
}
