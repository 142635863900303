export interface IInternacoes {
  ocorrenciaNo: number;
	iDentificacao1:  any;
	iDentificacao2:  any;
	dataAlta:  Date;
	dataInternacao:  Date;
	motivo:  string;
	local:  any;
	leito:  any;
	dataAlta2:  any;
	diagnosticoFinal:  string;
	desfecho:  string;
	retornoHospitalar:  boolean;
	dataRetorno:  any;
	executor:  any;
	diasPermanenciaHospitalar:  any;
	codigo:  any;
	titulo:  any;
	habilitado:  boolean;
	idoso:  string;
	prontuarioEinstein:  any;
	prontuarioMEDLOGIC:  any;
	prontuarioEinstein2:  string;
	testeCalcData:  any;
	dTENTRADA:  any;
	dTALTA:  any;
	recebeuAlta:  boolean;
	dataRetorno2:  Date;
	retornoMaisRecente:  any;
}
