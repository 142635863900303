import { InventoryViewComponent } from './kitchen/view/inventory/inventory.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthChildGuardService } from '@medlogic/shared/gecore';
import { PageNotFoundViewComponent } from '@medlogic/shared/geform';

// Rota teste da dimensão Funcionalidade: http://localhost:4200/kitchen/inventory/9733
const appRoutes: Routes = [
  {
    path: 'kitchen',
    canActivateChild: [AuthChildGuardService],
    children: [
      { path: 'inventory/:modeloConfigNo', component: InventoryViewComponent },
      { path: '', redirectTo: '/kitchen/inventory/9733', pathMatch: 'prefix' },
      { path: '**', component: PageNotFoundViewComponent },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppKitchenRoutingModule { }
