import { StateWebsocketModule } from './state-websocket/state-websocket.module';
import { StateCostCenterModule } from './state-cost-center/state-cost-center.module';
import { StateIvcfModule } from './state-ivcf/state-ivcf.module';
import { StateVitalSignModule } from './state-vital-sign/state-vital-sign.module';
import { StateFrailtyModule } from './state-frailty/state-frailty.module';
import { StateMedlogicModule } from './state-medlogic/state-medlogic.module';
import { MedlogicDataAccessModule } from '@medlogic/medlogic/medlogic-data-access';
import { StateExameModule } from './state-exame/state-exame.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatePersonModule } from './state-person/state-person.module';
import { StateLoginModule } from './state-login/state-login.module';
import { StateExameResultadoModule } from './state-exame-resultado/state-exame-resultado.module';
import { StateMedlogicTenantModule } from './medlogic-state-tenant/medlogic-state-tenant.module';
import { StateEvolutionModule } from './state-evolution/state-evolution.module';
import { StatePatientModule } from './state-patient/state-patient.module';
import { StateMedicationModule } from './state-medication/state-medication.module';
import { StateStockModule } from './state-stock/state-stock.module';
import { StatePrescriptionModule } from './state-prescription/state-prescription.module';
import { StateHorariosModule } from './state-horarios/state-horarios.module';
import { StateTutorialModule } from './state-tutorial/state-tutorial.module';

const MODULES = [
  CommonModule,
  StateExameModule,
  StateExameResultadoModule,
  StateLoginModule,
  StatePersonModule,
  StateMedlogicModule,
  StateMedlogicTenantModule,
  MedlogicDataAccessModule,
  StateFrailtyModule,
  StatePatientModule,
  StateEvolutionModule,
  StateMedicationModule,
  StateStockModule,
  StateVitalSignModule,
  StateIvcfModule,
  StatePrescriptionModule,
  StateHorariosModule,
  StateCostCenterModule,
  StateTutorialModule,
  StateWebsocketModule
];
@NgModule({
  imports: [MODULES],
})

export class MedlogicStateModule { }
