import { FormGroup } from '@angular/forms';
import { IAtividadeComponenteDAL } from './iatividade-componente-dal';

export interface IOcorrencia {
    AtividadeNo: number;
    OcorrenciaNo: number;
    TarefaNo: number;
    ProcessoNo?: number;
    Componentes?: IAtividadeComponenteDAL[];
    fg?: FormGroup;
    isEditMode?: boolean;
}
