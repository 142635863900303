<div class="grid" *ngIf="(items$ | async) as items; else loading" [class.black]="enTheme === ENTHEME.black">

  <ng-container *ngIf="(items?.length > 0); else empty">

    <span *ngIf="(getShowOnlyFirst(items, showOnlyFirst) && !isSingleRegister)">Lançamento mais recente:</span>

    <ui-list-item
      *ngFor="let item of items | itemSearch: search | firstOno:getShowOnlyFirst(items, showOnlyFirst):isSingleRegister"
      [listItem]="item" [enTheme]="enTheme" (click)="onItemClick(item)">
    </ui-list-item>

    <button mat-button type="button" *ngIf="(getShowOnlyFirst(items, showOnlyFirst) && !isSingleRegister)"
      (click)="showAll($event)">Exibir todos os items ({{
      items?.length }})</button>

  </ng-container>

</div>

<ng-template #loading>
  <ui-empty-items [numOfItems]="3"></ui-empty-items>
</ng-template>

<ng-template #empty>
  <h2>Nenhum registro encontrado.</h2>
</ng-template>