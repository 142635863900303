import { IEstoqueMateriais, IInterventionMedication, IPatient } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const loadStocks = createAction(
  '[card Patient] Load Stocks',
  props<{ numOfDaysToCheckOcurrency: number }>()
);

export const loadStocksBySelectedPatient = createAction(
  '[Card Stock] Load Stocks by Selected Patient',
  props<{ numOfDaysToCheckOcurrency: number }>()
);

export const loadStocksSuccess = createAction(
  '[Stock/API] Load Stocks Success',
  props<{ stocks: IEstoqueMateriais[] }>()
);

export const saveStocksSuccess = createAction(
  '[Stock/API] Save Stocks Success',
  props<{ stock: IEstoqueMateriais }>()
);

export const stockFail = createAction(
  '[Stock/API] Stock Fail',
  props<{ error: string }>()
);

export const addStock = createAction(
  '[Stock/API] Add Stock',
  props<{ stock: IEstoqueMateriais }>()
);

export const upsertStock = createAction(
  '[Stock/API] Upsert Stock',
  props<{ stock: IEstoqueMateriais }>()
);

export const addStocks = createAction(
  '[Stock/API] Add Stocks',
  props<{ stocks: IEstoqueMateriais[] }>()
);

export const addMedicationStock = createAction(
  'SISTEMA [Stock/API] Add Medication Stock',
  props<{ medication: IInterventionMedication }>()
);

export const checkMedicationStock = createAction(
  'SISTEMA [Stock/API] Check Medication Stock',
  props<{ medications: IInterventionMedication[] }>()
);

export const checkStockItem = createAction(
  '[Stock/API] Check Medication',
  props<{ medication: IInterventionMedication }>()
);

export const addStockItem = createAction(
  '[Stock/API] Add Medication',
  props<{ medication: IInterventionMedication }>()
);

export const upsertStocks = createAction(
  '[Stock/API] Upsert Stocks',
  props<{ stocks: IEstoqueMateriais[] }>()
);

export const updateStock = createAction(
  '[Stock/API] Update Stock',
  props<{ stock: Update<IEstoqueMateriais> }>()
);

export const updateStocks = createAction(
  '[Stock/API] Update Stocks',
  props<{ stocks: Update<IEstoqueMateriais>[] }>()
);

export const deleteStock = createAction(
  '[Stock/API] Delete Stock',
  props<{ id: string }>()
);

export const deleteStocks = createAction(
  '[Stock/API] Delete Stocks',
  props<{ ids: string[] }>()
);

export const clearStocks = createAction(
  '[Stock/API] Clear Stocks'
);
export const stockChange = createAction(
  '[Medication/API] Change Stock',
  props<{
    updateStock: boolean,
    medicationId: number,
    dailyQuantity: number,
    message: string
  }>()
);

export const stockChanges = createAction(
  '[Medication/API] Change All Medications Stock',
  props<{
    stock: {
      updateStock: boolean,
      medicationId: number,
      dailyQuantity: number,
      message: string
    }[]
  }>()
);

export const stockChangeSuccess = createAction(
  '[Stock/API] Change Stock Success'
);

export const confirmStock = createAction(
  '[Card Stock] Confirm Stock',
  props<{
    stock: IEstoqueMateriais, patient: IPatient, observation: string, updateStock: boolean
  }>()
);
