import { ConfigStateService } from '@medlogic/shared/state-config';
import { Control } from '../../model/control';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, ConfigJsonService, IAtividadeComponenteDAL, EnTheme } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraService } from '../../service/calculadora.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { FormGroup } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-label',
  templateUrl: './ctr-label.component.html',
  styleUrls: ['./ctr-label.component.css']
})
export class CtrLabelComponent extends Control implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  public get isUrl(): string {
    return this.ctrl && this.ctrl?.ValorTexto && this.ctrl?.ValorTexto.toString().startsWith('http');
  }

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR
  ) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }

  // ngOnInit() { }
}
