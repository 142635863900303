import { IShift, EnShift, AppLogService } from '@medlogic/shared/shared-interfaces';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shift'
})
export class ShiftPipePipe implements PipeTransform {

  constructor(
    private log: AppLogService
  ) { }

  transform(shifts: IShift[], selectedShift: EnShift): IShift[] {
    try {
      return shifts?.filter((shift: IShift) => (selectedShift == EnShift.all) || (shift.shiftName == selectedShift));
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return shifts;
  }


}
