import { IVitalSign } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const loadVitalSigns = createAction(
  '[card VitalSign] Load VitalSigns'
);
export const setVitalSign = createAction(
  '[card VitalSign List] Set VitalSigns',
  props<{ selectedId: number }>()
);
export const loadVitalSignsByPeriod = createAction(
  '[card VitalSign] Load VitalSigns By Period',
  props<{ dtStart: Date, dtEnd: Date }>()
);

export const loadVitalSignsBySelectedPatient = createAction(
  '[Card VitalSign] Load VitalSigns by Selected Patient',
  props<{ dtStart: Date, dtEnd: Date }>()
);

export const loadVitalSignsSuccess = createAction(
  '[VitalSign/API] Load VitalSigns Success',
  props<{ vitalSigns: IVitalSign[] }>()
);

export const vitalSignFail = createAction(
  '[VitalSign/API] VitalSign Fail',
  props<{ error: string }>()
);

export const addVitalSign = createAction(
  '[VitalSign/API] Add VitalSign',
  props<{ vitalSign: IVitalSign }>()
);

export const upsertVitalSign = createAction(
  '[VitalSign/API] Upsert VitalSign',
  props<{ vitalSign: IVitalSign }>()
);

export const addVitalSigns = createAction(
  '[VitalSign/API] Add VitalSigns',
  props<{ vitalSigns: IVitalSign[] }>()
);

export const upsertVitalSigns = createAction(
  '[VitalSign/API] Upsert VitalSigns',
  props<{ vitalSigns: IVitalSign[] }>()
);

export const updateVitalSign = createAction(
  '[VitalSign/API] Update VitalSign',
  props<{ vitalSign: Update<IVitalSign> }>()
);

export const updateVitalSigns = createAction(
  '[VitalSign/API] Update VitalSigns',
  props<{ vitalSigns: Update<IVitalSign>[] }>()
);

export const deleteVitalSign = createAction(
  '[VitalSign/API] Delete VitalSign',
  props<{ id: string }>()
);

export const deleteVitalSigns = createAction(
  '[VitalSign/API] Delete VitalSigns',
  props<{ ids: string[] }>()
);

export const clearVitalSigns = createAction(
  '[VitalSign/API] Clear VitalSigns'
);
export const vitalSignChange = createAction(
  '[Medication/API] Change VitalSign',
  props<{
    updateVitalSign: boolean,
    medicationId: number,
    dailyQuantity: number,
    message: string
  }>()
);
export const vitalSignChangeSuccess = createAction(
  '[VitalSign/API] Change VitalSign Success'
);

export const confirmVitalSign = createAction(
  '[Card VitalSign] Confirm VitalSign',
  props<{
    vitalSign: IVitalSign, patient: IVitalSign, observation: string, updateVitalSign: boolean
  }>()
);
