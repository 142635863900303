export interface IDataComemorativa {
  ocorrenciaNo: number;
codigo:  any;
titulo:  any;
habilitado:  boolean;
descricao:  any;
dia:  any;
mes:  any;
ano:  any;
dataFixaAconteceTodosOsAnosNAMESMADATA:  boolean;
data:  Date;
  }
