import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { EnTheme, IModule, UnsubscribeOnDestroyAdapter, AppLogService } from '@medlogic/shared/shared-interfaces';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppMedlogicPwaCuidadoState } from '../../ngrx/states/app-state';
import { loadProcesses, selectProcess } from '../../ngrx/actions/process.actions';
import { tap } from 'rxjs/operators';
import { selectAllProcesses } from '../../ngrx/selectors/process.selectors';
import { LocalNavigationService } from '../../service/local-navigation.service';

@Component({
  selector: 'app-process-list-view',
  templateUrl: './process-list-view.component.html',
  styleUrls: ['./process-list-view.component.css']
})
export class ProcessListViewComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() enTheme = EnTheme.default;

  usrProcess$: Observable<IModule[]> = this.store.select(state => selectAllProcesses(state));

  constructor(
    private nav: LocalNavigationService,
    private log: AppLogService,
    protected route: ActivatedRoute,
    private store: Store<AppMedlogicPwaCuidadoState>,
  ) {
    super();
  }

  ngOnInit(): void {
    try {
      this.subs.sink = this.getParams().subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Extrai os parâmetros passados na url/rota, ou através do componente. */
  protected getParams(): Observable<Params> {
    try {
      // Os parametros estão sendo passados diretamente aos componentes
      return this.route.params
        .pipe(
          tap((params: Params) => {
            if (params.theme) {
              this.enTheme = params.theme;
            }
            this.refresh();
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
    return of(null);
  }

 /* Carregamento principal. */
  protected refresh(): void {
    try {
      this.store.dispatch(loadProcesses());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

 /* Clique em um dos processos, que são carregados dinamicamente. */
  onClickProcess(selectedProcess: IModule, enTheme: EnTheme): void {
    try {
      this.store.dispatch(selectProcess({ selectedProcess }));
      this.nav.gotoRouteContaHome(true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClickProcess', error.message);
    }
  }


}
