import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICostCenterState } from '@medlogic/shared/shared-interfaces';

import * as fromCostCenter from './cost-center.reducer';


export const selectCostCenterState = createFeatureSelector<ICostCenterState>(fromCostCenter.costCentersFeatureKey);
export const selectCostCenterAlls = createSelector(selectCostCenterState, fromCostCenter.selectAll);

export const selectCostCenterAllSortByOrder = createSelector(
  selectCostCenterAlls,
  (medications) => medications?.sort((a, b) => (a.titulo as string).localeCompare(b.titulo))
);