import { IQueue } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';

export const queue = createAction(
  '[Card Queue] Queue a new item',
  props<{ queue: IQueue }>()
);

export const dequeue = createAction(
  '[Card Queue] Dequeue the first item'
);

export const dequeueFi = createAction(
  '[Card Queue] Dequeue the first item'
);

export const clearQueue = createAction(
  '[Queue/API] Clear Queue'
);

export const setQueueIsExecuting = createAction(
  '[Queue/API] Set Queue is Executing',
  props<{ isExecuting: boolean }>()
);

export const executeQueue = createAction(
  '[Queue/API] Execute Queue'
);

export const queueFail = createAction(
  '[Queue/API] Queue Fail'
);
