import { createAction, props } from '@ngrx/store';
import { ILogin } from '@medlogic/shared/shared-interfaces';

// This action is necessary to get the Ids for each module, according with the logged user.
export enum LoginActionTypes {
  DoLoginAndLoadRoot = '[PWA Login View] Do Login and Load First Route',
  doBiometricLoginAndLoadRoot = '[PWA Login View] Do Biometric Login and Load First Route',
  SuccessLogin = '[Login API] Set Login state', // Set the Login to the Store, after Effects
  FailLogin = '[Login View] Login Fail'
}

export const doLoginAndLoadRoot = createAction(LoginActionTypes.DoLoginAndLoadRoot, props<{ userName: string, password: string }>());
export const doBiometricLoginAndLoadRoot = createAction(LoginActionTypes.DoLoginAndLoadRoot, props<{ userName: string, password: string }>());
export const successLogin = createAction(LoginActionTypes.SuccessLogin, props<{ selectedLogin: ILogin | { message: string } }>());
export const failLogin = createAction(LoginActionTypes.FailLogin, props<{ selectedLogin: ILogin | { message: string } }>());
export const clearLogin = createAction(
  '[Local Login] Clear Login'
);
