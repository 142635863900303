//import { IScheduledTimingState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IScheduledTiming } from '@medlogic/shared/shared-interfaces';
import { IScheduledTimingState } from './scheduledTiming-state';

import * as ScheduledTimingActions from './scheduledTiming.actions';

export const scheduledTimingsFeatureKey = 'scheduledTiming';

export const adapterScheduledTiming: EntityAdapter<IScheduledTiming> = createEntityAdapter<IScheduledTiming>({
  selectId: instance => instance.scheduledtimingIdentifier,
  // sortComparer: sortOnOcurrencyAndNames
});

export const initialStateScheduledTiming: IScheduledTimingState = adapterScheduledTiming.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedIdentifier: null,
});

//TODO: Validar este trecho
// FIXME: Falta incluir regras de negócio de ordenação para os alertas
// function usersSortFn(a: IScheduledTiming, b: IScheduledTiming) {
//   return a?.scheduledtimingIdentifier.localeCompare(b?.scheduledtimingIdentifier);
// }

// function sortOnOcurrencyAndNames(a: IScheduledTiming, b: IScheduledTiming, c: IActivityDetail, d: IActivityDetail) {
//   if (a.scheduledtimingIdentifier === b.scheduledtimingIdentifier && c.activityIdentifier === d.activityIdentifier) {
//     return d.activity < d.activity ? -1 : 1;
//   } else {
//     return d.activity ? -1 : 1;
//   }
// }

export const reducer = createReducer(
  initialStateScheduledTiming,
  on(ScheduledTimingActions.addScheduledTiming,
    (state, action) => adapterScheduledTiming.addOne(action.scheduledTiming, state)
  ),
  on(ScheduledTimingActions.setScheduledTiming,
    (state, action) => ({ ...state, selectedIdentifier: action.selectedId })
  ),
  on(ScheduledTimingActions.upsertScheduledTiming,
    (state, action) => adapterScheduledTiming.upsertOne(action.scheduledTiming, state)
  ),
  on(ScheduledTimingActions.addScheduledTimings,
    (state, action) => adapterScheduledTiming.addMany(action.scheduledTimings, state)
  ),
  on(ScheduledTimingActions.upsertScheduledTimings,
    (state, action) => adapterScheduledTiming.upsertMany(action.scheduledTimings, state)
  ),
  on(ScheduledTimingActions.updateScheduledTiming,
    (state, action) => adapterScheduledTiming.updateOne(action.scheduledTiming, state)
  ),
  on(ScheduledTimingActions.updateScheduledTimings,
    (state, action) => adapterScheduledTiming.updateMany(action.scheduledTimings, state)
  ),
  on(ScheduledTimingActions.deleteScheduledTiming,
    (state, action) => adapterScheduledTiming.removeOne(action.id, state)
  ),
  on(ScheduledTimingActions.deleteScheduledTimings,
    (state, action) => adapterScheduledTiming.removeMany(action.ids, state)
  ),
  on(ScheduledTimingActions.loadScheduledTimingsSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterScheduledTiming.upsertMany(action.scheduledTimings, state)
    (state, action) => adapterScheduledTiming.setAll(action.scheduledTimings, state)
  ),
  on(ScheduledTimingActions.scheduledTimingFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(ScheduledTimingActions.clearScheduledTimings,
    state => initialStateScheduledTiming
  ),
  on(ScheduledTimingActions.clearSelectedScheduledTiming,
    state => ({ ...state, selectedIdentifier: null })
  ),
  on(ScheduledTimingActions.loadScheduledTimings,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterScheduledTiming.getSelectors();


export const getSelectedId = (state: IScheduledTiming) => state.scheduledtimingIdentifier;
