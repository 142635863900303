import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';
import { LocalLibService } from './local-lib.service';
import { IListItem } from '../interface/ilist-item';
import { GlobalService, LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';

@Injectable()
export class GenericCustomService extends GenericService {

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnf: ConfigPwaMedLogicService,
    lib: LocalLibService,
    log: LogService) {
    super(cadastroSrv, glb, cnf, lib, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  map(componentes: IAtividadeComponenteDAL[]): Observable<IListItem> {
    try {
      let cs = { OcorrenciaNo: componentes[0].OcorrenciaNo, index: componentes[0].OcorrenciaNo };
      cs = {
        ...cs, ...componentes.reduce((obj, ctrl) => {
          obj[`V_${ctrl.VariavelNo}`] = ctrl.Valor;
          return obj;
        }, {})
      };
      return of(cs).pipe(this.mapTo(componentes[0].AtividadeNo));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'map', error.message);
    }
    return of(null);
  }

  // /* Get the account by the verification code.
  // */
  // getByCode(ano: number, verificationCode: string): Observable<IListItem> {
  //   try {
  //     this.cadastroNo = ano;
  //     const startDate = new Date(1900, 0, 1);
  //     const endDate = new Date(2500, 0, 1);
  //     const strFilter = `V_103185:BARCODE;${verificationCode}`;
  //     return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getByCode', error.message);
  //   }
  //   return of(null);
  // }

}
