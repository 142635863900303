import { ILogState } from '@medlogic/shared/shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromLog from './log.reducer';

export const selectLogState = createFeatureSelector<ILogState>(fromLog.logsFeatureKey);
export const selectLogAll = createSelector(selectLogState, fromLog.selectAll);

export const selectLogMessages = createSelector(
  selectLogAll,
  logs => logs.map(m => m.msg)
);

export const isLogLoading = createSelector(
  selectLogAll,
  logs => logs?.length <= 0,
);

export const selectedLog = createSelector(selectLogState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const selectedLogId = createSelector(selectLogState, state => {
  return state?.selectedId > 0 ? state?.selectedId : null;
});
