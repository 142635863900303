import { ISchool } from '../ischool';

export interface ISchoolUser extends ISchool {
    ocorrenciaNo: number;
    dtRegistro: Date;
    idPaciente: any;
    idEscola: any;
    habilitado: boolean;
    titulo: any;
    usuario: any;
    usuarioNome: any;
    codigo: any;
}
