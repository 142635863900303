import { ConfigStateService } from '@medlogic/shared/state-config';
import { Control } from '../../model/control';
import { Component, AfterViewInit, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { LogService, ConfigJsonService, IAtividadeComponenteDAL, EnTheme } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraService } from '../../service/calculadora.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { FormGroup } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-radio-button',
  templateUrl: './ctr-radio-button.component.html',
  styleUrls: ['./ctr-radio-button.component.css']
})
export class CtrRadioButtonComponent extends Control implements AfterViewInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  ENTHEME = EnTheme;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  @ViewChildren('toggle') toggle: any[];

  public get radioType(): string {
    // FIXME: tipo de controle hardcodded para protótipo do Virtual Assistant
    // if (!this.isMobile()) {
    //   return 'Default';
    // } else if (
    //   this.ctrl?.lstValue && this.ctrl?.lstValue?.string && this.ctrl?.lstValue?.string.length === 2 &&
    //   (
    //     (this.global.isEqual(this.ctrl?.lstValue?.string[0], 'SIM') &&
    //       this.global.isEqual(this.ctrl?.lstValue?.string[1], 'NÃO')) ||
    //     (this.global.isEqual(this.ctrl?.lstValue?.string[0], 'NÃO') &&
    //       this.global.isEqual(this.ctrl?.lstValue?.string[1], 'SIM'))
    //   )
    // ) {
    //   return 'YesNo';
    // }
    return 'Options';
  }

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }

  ngAfterViewInit() {
    try {
      setTimeout(() =>
        this.setInitialValue(this.toggle, this.radioType, this.ctrl),
        500
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  isChecked(ctrl: IAtividadeComponenteDAL, value: string): boolean {
    try {
      return this.global.isEqual(value, this.lib.getValorTextoOrData(ctrl));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isChecked', error.message);
    }
    return false;
  }

  /* Verifica se o valor é vazio GE (inclui não informado) */
  isEmpty(value: string): boolean {
    try {
      return this.global.IsNullOrEmptyGE(value);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isEmpty', error.message);
    }
    return false;
  }

  onClick(ctrl: IAtividadeComponenteDAL, item: string): void {
    try {
      // ctrl.ValorTexto = item;
      // ctrl.Valor = item;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClick', error.message);
    }
  }

  /* Quando o controle for do tipo YesNo e o usuário alterar o status. */
  onToggleChange($event: MatButtonToggleChange, ctrl: IAtividadeComponenteDAL): void {
    try {
      this.dispatchOptionsCtrlChange(ctrl, $event.value, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onToggleChange', error.message);
    }
  }

  /* Quando o controle for do tipo YesNo e o usuário alterar o status. */
  onSlideChange($event: any, ctrl: IAtividadeComponenteDAL): void {
    try {
      this.dispatchYesNoCtrlChange(ctrl, $event.checked, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSlideChange', error.message);
    }
  }

  /* Fará com que as fórmulas e visibilidades condicionais sejam calculadas. */
  private dispatchYesNoCtrlChange(ctrl: IAtividadeComponenteDAL, isChecked: boolean, markAsTouched: boolean = false): void {
    try {
      const id = this.getId(ctrl);
      const value = isChecked ? 'SIM' : 'NÃO';
      ctrl.ValorTexto = value;
      const control = this.formGroup.get(id);
      control?.markAsDirty();
      if (markAsTouched) {
        control?.markAsTouched();
      }
      control?.setValue(value, { emitEvent: true });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'dispatchYesNoCtrlChange', error.message);
    }
  }

  /* Fará com que as fórmulas e visibilidades condicionais sejam calculadas. */
  private dispatchOptionsCtrlChange(ctrl: IAtividadeComponenteDAL, value: string, markAsTouched: boolean = false): void {
    try {
      const id = this.getId(ctrl);
      ctrl.ValorTexto = value;
      const control = this.formGroup.get(id);
      control?.markAsDirty();
      if (markAsTouched) {
        control?.markAsTouched();
      }
      control?.setValue(value, { emitEvent: true });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'dispatchOptionsCtrlChange', error.message);
    }
  }

  /* Define o valor inicial do controle visual, conforme o tipo.
  * Apenas o checkbox precisa de setup manual, os demais são definidos normalmente pelo formControlName.
  */
  protected setInitialValue(lstToggle: any[], radioType: string, ctrl: IAtividadeComponenteDAL): void {
    try {
      const ctrlToggle: any = lstToggle.filter(f => {
        return f.name === this.getId(ctrl);
      })[0];
      switch (radioType) {
        case 'YesNo':
          if (ctrlToggle) {
            ctrlToggle.checked = this.global.isEqual(ctrl.ValorTexto, 'SIM');
            // Necessário para forçar o cálculo de fórmulas e visibilidades condicionais
            this.dispatchYesNoCtrlChange(ctrl, ctrlToggle.checked);
          }
          break;
        case 'Options':
          // Necessário para forçar o cálculo de fórmulas e visibilidades condicionais
          this.dispatchOptionsCtrlChange(ctrl, ctrl.ValorTexto);
          break;
        default:
          break;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setInitialValue', error.message);
    }
  }


}
