import { IActivityContributor } from './iactivity-contributor';
import { IActivityDetail } from './iactivity-detail';

export interface IContributorOnActivity {
    contributorOnActivityIdentifier: string;
    activityContributorIdentifier: string;
    activityContributor: IActivityContributor[];
    activityDetailIdentifier: string;
    // activityDetail: IActivityDetail[];
    assignedAt: Date;
}
