import { VitalSignCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import {
  GlobalService, MsgPtBR
} from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';
import {
  vitalSignFail,
  loadVitalSigns, addVitalSigns, loadVitalSignsBySelectedPatient
} from './vital-sign.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class VitalSignEffects {

  private showMessage = () => tap((success: boolean) => {
    const msg = success ? this.msg.STOCK_UPDATE_SUCCESS : this.msg.STOCK_UPDATE_FAIL;
    this.snackBar.open(msg, null, {
      duration: 2000,
      panelClass: 'snackbar'
    });
  });

  private getAllBySelectedPatient$ = (dtStart: Date = null, dtEnd: Date = null) => mergeMap(([never, state]) => {
    return this.vitalSignSrv.getByIdAndPeriodWithOcorrences(
      state?.tenant?.selectedTenant?.cadVitalSignsNo,
      state?.patient?.selectedId,
      state?.tenant?.tenantId,
      dtStart,
      dtEnd
    ).pipe(
      // toArray(),
      switchMap(({vitalSigns }) => [
        vitalSigns ? addVitalSigns({ vitalSigns }) : vitalSignFail(null),
        setIsLoading({ isLoading: false })
      ]),
      catchError((e: any) => {
        console.log(e);
        return of(vitalSignFail(null));
      })
    )
  });

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private snackBar: MatSnackBar,
    private vitalSignSrv: VitalSignCustomService, // TODO: ContaService changed to the API
    private msg: MsgPtBR,
  ) { }

  /* Operador personalizado para realizar a baixa no estoque
  * caso esteja configurado no idoso bem cuidado do cliente.
  * updateVitalSign: funciona como uma trava, se for false retornará false
  * diretamente sem modificar o estoque. */
  loadVitalSigns$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadVitalSigns),
      withLatestFrom(this.store),
      this.getAllBySelectedPatient$()
    )
  );

  loadVitalSignsBySelectedPatient$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadVitalSignsBySelectedPatient),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        const { dtStart, dtEnd } = state?.vitalSign;
        return of([never, state]).pipe(this.getAllBySelectedPatient$(dtStart, dtEnd));
      })
    )
  );


}
