import { Injectable } from '@angular/core';
import { routePatient } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirPatientService extends FhirGenericService {

  protected url = routePatient;

}
