import { Injectable } from '@angular/core';
import { FhirGenericService } from './fhir-generic.service';
import { routeCarePlanActivity } from '@medlogic/shared/shared-interfaces';

@Injectable({ providedIn: 'root' })
export class FhirCarePlanActivityService extends FhirGenericService {

  protected url = routeCarePlanActivity;


}
