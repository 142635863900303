import { IScheduled } from '@medlogic/shared/shared-interfaces';
//import { IScheduledState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IScheduledState } from './scheduled-state';
import * as ScheduledActions from './scheduled.actions';

export const scheduledsFeatureKey = 'scheduled';


export const adapterScheduled: EntityAdapter<IScheduled> = createEntityAdapter<IScheduled>({
  selectId: instance => instance.scheduledIdentifier,
  // sortComparer: sortOnOcurrencyAndNames
});

export const initialStateScheduled: IScheduledState = adapterScheduled.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
});

// FIXME: Falta incluir regras de negócio de ordenação para os alertas
function usersSortFn(a: IScheduled, b: IScheduled) {
  return a?.scheduledIdentifier.localeCompare(b?.scheduledIdentifier);
}


export const reducer = createReducer(
  initialStateScheduled,
  on(ScheduledActions.addScheduled,
    (state, action) => adapterScheduled.addOne(action.scheduled, state)
  ),
  on(ScheduledActions.setScheduled,
    (state, action) => ({ ...state, selectedIdentifier: action.selectedId })
  ),
  on(ScheduledActions.upsertScheduled,
    (state, action) => adapterScheduled.upsertOne(action.scheduled, state)
  ),
  on(ScheduledActions.addScheduleds,
    (state, action) => adapterScheduled.addMany(action.scheduleds, state)
  ),
  on(ScheduledActions.upsertScheduleds,
    (state, action) => adapterScheduled.upsertMany(action.scheduleds, state)
  ),
  on(ScheduledActions.updateScheduled,
    (state, action) => adapterScheduled.updateOne(action.scheduled, state)
  ),
  on(ScheduledActions.updateScheduleds,
    (state, action) => adapterScheduled.updateMany(action.scheduleds, state)
  ),
  on(ScheduledActions.deleteScheduled,
    (state, action) => adapterScheduled.removeOne(action.id, state)
  ),
  on(ScheduledActions.deleteScheduleds,
    (state, action) => adapterScheduled.removeMany(action.ids, state)
  ),
  on(ScheduledActions.loadScheduledsSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterScheduled.upsertMany(action.scheduleds, state)
    (state, action) => adapterScheduled.setAll(action.scheduleds, state)
  ),
  on(ScheduledActions.scheduledFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(ScheduledActions.clearScheduleds,
    state => initialStateScheduled
  ),
  on(ScheduledActions.clearSelectedScheduled,
    state => ({ ...state, selectedIdentifier: null })
  ),
  on(ScheduledActions.loadScheduleds,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterScheduled.getSelectors();


export const getSelectedId = (state: IScheduled) => state.scheduledIdentifier;