import { ID3Data } from './../id3data';
import { IChart } from '../ichart';

export interface IRptScreeningTdahTod {
    logoUrl: string;
    title: string;
    personName: string;
    age: number;
    schoolYear: number;
    responsibleName: string;
    evaluationDate: Date;
    symptomTitle: string;
    symptomSubtitle: string;
    symptomNote: string;
    symptomReport: string;
    symptomInattention: string;
    symptomHyperactivity: string;
    symptomODD: string;
    before12years: string;
    functionalImpairment: string;
    functionalityTitle: string;
    functionalitySubtitle: string;
    functionalityNote: string;
    functionalityReport: string;
    conclusion: string;
    adhdChartPatient: ID3Data[];
    adhdChartReference: ID3Data[];
    functionalityChart: IChart;
}

