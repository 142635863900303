export class IDialogData{
    title: string;
    messageHtml: string;
    items?: any[];
    item?: any;
    costCenter?: string;
    btnOk?: string;
    icon?: string;
    behaviour?: boolean;
}
