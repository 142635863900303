import { createReducer, on, Action } from '@ngrx/store';
import { loadPersons, setPerson, fetchSuccessPersons, fetchErrorPersons, clearPerson } from './person.actions';
import { IPersonState } from '@medlogic/medlogic/medlogic-shared-interfaces';

export const initialState: IPersonState = {
  person: null,
  persons: undefined,
  error: null
};

const personReducerObj = createReducer(initialState,
  on(loadPersons, (state, payload) => ({
    ...state,
    bannerTitle: 'PROCESSOS',
    person: null
  })),
  on(fetchSuccessPersons, (state, payload) => ({
    ...state,
    persons: payload?.persons[0] ? payload?.persons : undefined,
    error: null
  })),
  on(setPerson, (state, payload) => ({
    ...state,
    person: payload?.person,
    error: null
  })),
  on(clearPerson, (state, payload) => initialState),
  // on(loadPersonRelationships, (state, payload) => ({
  //   ...state,
  //   person: payload.person
  // })),
  // on(setPerson, (state, payload) => ({
  //   ...state,
  //   allPersons: payload.allPersons,
  //   error: null
  // })),
  on(fetchErrorPersons, (state, payload) => ({
    ...state,
    persons: undefined,
    error: 'invalid-token'
  })),
);

export function personReducer(state: IPersonState | undefined, action: Action) {
  return personReducerObj(state, action);
}
