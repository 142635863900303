<div [class.isMobile]="isMobile">
  <div class="top-bar" [class.isMobile]="isMobile">
    <app-btn-back (backButtonClicked)="onBack($event)"></app-btn-back>
  </div>
</div>

<div class="vital-sign-container" [class.isMobile]="isMobile">
  <lib-cmp-atividade
    id="vital"
    [usuarioNo]="usuarioNo$ | async"
    [saveInList]="true"
    [ocorrenciaNo]="ocorrenciaNo$ | async"
    [atividadeNo]="atividadeNo$ | async"
    [processoNo]="processoNo"
    [defaultFormControls]="defaultFormControls$ | async"
    [isReadOnly]="isReadOnly"
    [isMobile]="isMobile"
    [enTheme]="enTheme"
    (eventBubble)="eventBubble($event)"
    (onErpRefresh)="onRefresh($event)"
    (onBack)="onGeBack($event)"
    (eventAfterCompleted)="afterCompleted($event)"
    (afterSaved)="afterSaved($event)"
  ></lib-cmp-atividade>
</div>
