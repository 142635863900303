import { MedlogicTenantService } from '@medlogic/medlogic/medlogic-data-access';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { errorTenant, goRoot, setTenant, successTenant, TenantActionTypes } from './medlogic-tenant.actions';
import { ITenantState } from '@medlogic/shared/shared-interfaces';

// FIXME: Não está correto, para a arquitetura do projeto, esse acesso direto ao arquivo, como também não estaria acessar nesse local como lib.
import { LocalNavigationService } from 'libs/medlogic-pwa/medlogic-pwa-cuidado-full/src/lib/module/core/service/local-navigation.service';

import * as fromLogin from '../../state-login/+state/login.actions';

@Injectable()
export class TenantEffects {

  loadTenant$ = createEffect(() => this.actions$
    .pipe(
      ofType(TenantActionTypes.LoadTenant),
      mergeMap((state: ITenantState) => {
        return this.tenantService.get(state, false)
          .pipe(
            map(selectedTenant => {
              return setTenant({ selectedTenant });
            }),
            catchError((e) => {
              console.log(e);
              return of(errorTenant());
            })
          );
      })
    )
  );

  // private loadRoot = () => mergeMap((action: any) => {
  //   this.nav2.gotoRoot();
  //   return of(action);
  // })

  loadTenantGoRoot$ = createEffect(() => this.actions$
    .pipe(
      ofType(TenantActionTypes.LoadTenantGoRoot),
      mergeMap((state: ITenantState) => {
        return this.tenantService.get(state, false)
          .pipe(
            switchMap((selectedTenant) => {
              if (!!selectedTenant) {
                return [setTenant({ selectedTenant }), goRoot()];
              }
              return [errorTenant(), fromLogin.logOut()];
            }),
            catchError((e) => {
              console.log(e);
              return of(errorTenant());
            })
          );
      })
    )
  );

  goRoot$ = createEffect(() => this.actions$
    .pipe(
      ofType(TenantActionTypes.GoRoot),
      mergeMap((state: ITenantState) => {
        this.nav2.gotoRoot();
        return of(successTenant());
      }),
      catchError((e) => {
        console.log(e);
        return of(errorTenant());
      })
    )
  );

  constructor(
    private actions$: Actions,
    private tenantService: MedlogicTenantService,
    private nav2: LocalNavigationService
  ) { }


  // private fillUserRole$ = (isDebug: boolean, selectedLogin: any) => mergeMap((selectedTenant: ITenant) => {
  //   return this.schoolSrv
  //     .getByUser(selectedTenant.schoolAno, selectedTenant.userName, isDebug)
  //     .pipe(
  //       take(1), // Se houver mais de um cadastro, que seria irregular, retorna apenas o primeiro
  //       map((school: ISchool) => {
  //         return school ?
  //           ({ selectedLogin, school, selectedTenant, customerId: +school.codigo, identification1: school.codigo, identification2: school.nomeescola }) :
  //           ({ selectedLogin, selectedTenant });
  //       }),
  //       catchError((err, obs) => {
  //         console.log(err);
  //         return obs;
  //       })
  //     );
  // })

  // private cadTenant$ = () => mergeMap(([action, state]) => {
  //   // Atenção: essa chamada, depende que o state tenant.token esteja preenchido,
  //   // o que ocorre na chamada anterior.
  //   return this.cadTenant.getCadTenantConfig()
  //     .pipe(
  //       map(tenant => {
  //         return ({
  //           ...state?.tenant?.login,
  //           tenantId: tenant?.AtividadeNo,
  //         } as ILogin);
  //       }
  //       )
  //     )
  // });


}
