import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from './base-page.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable()
export class ModelService {

  urlGetSubProcessByUser = 'Model/GetModelComponent?_atividadeNo={0}&_tabNo={1}&_ocorrenciaNo={2}';

  constructor(
    private basepage: BasePageService,
    private log: LogService
  ) { }

  GetModelComponent(AtividadeNo: number, TabNo: number = -1, OcorrenciaNo: number = -1): Observable<any> {
    try {
      const urlGet = this.basepage.format(
        this.urlGetSubProcessByUser,
        AtividadeNo,
        TabNo,
        OcorrenciaNo
      );
      return this.basepage
        .baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'GetModelComponent', error.message);
    }
    return of(null);
  }


}
