import { toArray, refCount, publishReplay, map, groupBy, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  convertGridHorariosToText, convertToValorTextoHorarios, EnTypedValue, error,
  IIntervecoesMedicamentosHorarios, IInterventionMedication, IMedicationCheckin,
  toAttributeIntervention,
  INTERVENTION_LSTVARIAVEIS, INTERVENTION_LSTVARIAVEIS_GRID,
  INTERVENTION_LSTVARIAVEIS_GRID_HORARIOS, INTERVENTION_VARIAVEL_GRID,
  INTERVENTION_VARIAVEL_GRID_HORARIOS, LocalLibService, convertCheckinToValorTexto, mapToFormIntervention, mapToMedicationCheckin
} from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { UnsubscribeOnDestroyAdapter, GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

/**** ATENÇÃO ESSA CLASSE FOI PERSONALIZADA. NÃO SUBSTITUIR APÓS GERAÇÃO. */
@Injectable()
export class InterventionMedicationService extends UnsubscribeOnDestroyAdapter {
  // tslint:disable-next-line: max-line-length
  // private lstVariaveis = 'V_828,V_830,V_832,V_2949,V_2952,V_3530,V_27993,V_29828,V_29834,V_29838,V_29949,V_30212,V_30213,V_30214,V_30263,V_30264,V_30266,V_30270,V_30271,V_30272,V_30296,V_28018,V_30307,V_30308,V_30320,V_30330,V_30339,V_30356,V_30367,V_30401,V_31571,V_32859,V_32860,V_34906,V_104628,V_31967,V_31968,V_30396,V_32857,V_32858,V_32861,V_32862,V_32939,V_35051,V_101103,V_104223,V_32935,V_34709';
  // private variavelGrid = 'V_30277';
  // private lstVariaveisGrid = 'V_30264,V_30273,V_30274,V_30275,V_30276,V_30330,V_30331,V_30351,V_30264,V_30352,V_30353,V_30401,V_31927,V_34166';
  // private variavelGridHorarios = 'V_30396';
  // private lstVariaveisGridHorarios = 'V_29828,V_30263,V_30392,V_30393,V_30394,V_30395,V_30401';

  private lstVariaveis = INTERVENTION_LSTVARIAVEIS;
  private variavelGrid = INTERVENTION_VARIAVEL_GRID;
  private lstVariaveisGrid = INTERVENTION_LSTVARIAVEIS_GRID;
  private variavelGridHorarios = INTERVENTION_VARIAVEL_GRID_HORARIOS;
  private lstVariaveisGridHorarios = INTERVENTION_LSTVARIAVEIS_GRID_HORARIOS;

  recurrences: IInterventionMedication[] = new Array<IInterventionMedication>();
  codigoVariavelNo = 30212;
  cadastroNo = 4318;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache = new Observable<any>();

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected lib: LocalLibService,
    protected log: LogService
  ) {
    super();
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IInterventionMedication> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(ano, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(): Observable<any> {
    try {
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(this.cadastroNo), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IInterventionMedication> {
    if (
      startDate.getTime() !== this.currentDtInicial.getTime() ||
      endDate.getTime() !== this.currentDtFinal.getTime() ||
      !this.cadastrosCache || (lstVariables !== this.lstVariaveis)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      lstVariables = lstVariables || this.lstVariaveis;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastro(cadastroNo, lstVariables, startDate, endDate).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  /* Conversão para o tipo de objeto principal. */
  protected toAttribute(c: any, lstmedicationCheckin: any[] = null): IInterventionMedication {
    try {
      return toAttributeIntervention(c, lstmedicationCheckin);
      // return {
      //   ocorrenciaNo: c.OcorrenciaNo,
      //   codigo: c.V_30212, // OK
      //   titulo: c.V_30213, // OK
      //   // medicamento: c.V_828, // Não tem na versão gerada
      //   tempoUso: c.V_830, // OK
      //   dosagem: c.V_832, // OK
      //   via: c.V_2949, // OK
      //   orientacoes: c.V_2952, // OK
      //   posologia: c.V_3530, // OK
      //   centrocusto: c.V_27993, // OK
      //   codigoPaciente: c.V_29828, // Não tem. mas tem coPAcienteNOVO: c.V_31969
      //   apresentacao: c.V_29834, // OK
      //   dataPRESCRICAO: this.glb.ddMMYYYYThhmmssToDate(c.V_30330), // OK
      //   codigoPacienteNomePaciente: c.V_30296, // Não tem. Tem idMedicamentoIdPaciente: c.V_30401
      //   IdMedicamento_IdPaciente: c.V_30401, // OK
      //   tIPOMaterial: c.V_28018, // OK
      //   uNIDADEDENEGOCIO: c.V_30307, // Não tem
      //   horaprimeiraDose: c.V_30367, // OK
      //   codigoMedicamentoPaciente: c.V_30401, // OK
      //   Id_Paciente: c.V_29828, // OK
      //   Id_Medicamento: c.V_30263, // OK
      //   // codigomedicamento: c.V_30263, // Não tem
      //   dataEVOLUCAO: this.glb.ddMMYYYYThhmmssToDate(c.V_31571), // OK
      //   interroperMedicacao: this.glb.getBoolean(c.V_32859), // OK
      //   interrupcaoMotivo: c.V_32860, // OK mas chama motivoOrientacoes
      //   medicamentoControlado: c.V_32857, // OK
      //   medicametnoPrescrito: c.V_30356, // OK
      //   ordem: c.V_104628, // OK
      //   codigoHospede: c.V_32939, // OK mas chama codHospedTelaPrescricao
      //   Id_Prescricao: c.V_32935, // // OK
      //   habilitado: this.glb.getBoolean(c.V_30214), // OK
      //   lstmedicationCheckin,
      //   intervaloHoras: c.V_34906 || 0,
      //   quantUtilizadaD: this.glb.getSomeNumber('-1', c.V_29838, c.V_101103),
      //   quantUtilizadaGota: this.glb.getSomeNumber('-1', c.V_104223),
      //   hojedia: this.glb.ddMMYYYYThhmmssToDate(c.V_29949),
      //   cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: c.V_30264,
      //   centroCustoIDCentroCusto: c.V_30266,
      //   tIPOMedicamentos: c.V_30270,
      //   tipomedicamento: c.V_30271,
      //   dia: c.V_30272,
      //   validCadastro: c.V_30308,
      //   codPacienteNomedoPacienteCodMedicamento: c.V_30320,
      //   tipoMedicamentosCodPaciente: c.V_30339,
      //   // alteracao barbara
      //   horarios: c.V_30396,
      //   calc__isSOS: this.isSOS(c),
      //   // descricao: any;
      //   dataInicio: this.glb.ddMMYYYYThhmmssToDate(c.V_31967),
      //   dataFim: this.glb.ddMMYYYYThhmmssToDate(c.V_31968),
      //   profissional: c.V_32858,
      //   tempoEstimado: c.V_32861,
      //   quantTempoEstimado: c.V_32862,
      //   //  codMedPacTM: any;
      //   // cascataTmatHospe: any;
      //   intervaloDS: c.V_35051,
      //   materialId: c.V_34709
      // } as IInterventionMedication;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Checa se a posologia é SOS. */
  // protected isSOS(item: any): boolean {
  //   try {
  //     // return this.glb.isEqual(item.V_3530, 'SOS');
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'isSOS', error.message);
  //   }
  // }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}` */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IInterventionMedication> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)) // publishReplay não pode ser usado, ou não reconhecerá medicamentos adicionados.
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /*Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  // save(interventionMedication: IInterventionMedication, uno: number, id?: number): Observable<any> {
  //   try {
  //     const forms: IForm[] = this.mapToForm(interventionMedication).filter((f) => f.ValorDado);
  //     return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'save', error.message);
  //   }
  //   return of(null);
  // }

  /* Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  save<T>(ano: number, interventionMedication: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(interventionMedication as unknown as IInterventionMedication).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo)
        .pipe(
          map(newId => {
            return ({ ...interventionMedication, codigo: newId, ocorrenciaNo: newId } as T);
          }),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(interventionMedication: IInterventionMedication): IForm[] {
    try {
      return mapToFormIntervention(interventionMedication);
      // return interventionMedication ? [
      //   { VariavelNo: 828, ValorDado: interventionMedication.medicamento || '' },
      //   { VariavelNo: 830, ValorDado: interventionMedication.tempoUso || '' },
      //   { VariavelNo: 832, ValorDado: interventionMedication.dosagem || '' },
      //   { VariavelNo: 2949, ValorDado: interventionMedication.via || '' },
      //   { VariavelNo: 2952, ValorDado: interventionMedication.orientacoes || '' },
      //   { VariavelNo: 3530, ValorDado: interventionMedication.posologia || '' },
      //   { VariavelNo: 27993, ValorDado: interventionMedication.centrocusto || '' },
      //   { VariavelNo: 29828, ValorDado: interventionMedication.codigoPaciente || '' },
      //   { VariavelNo: 29834, ValorDado: interventionMedication.apresentacao || '' },
      //   { VariavelNo: 29838, ValorDado: interventionMedication.quantUtilizadaD || '' },
      //   { VariavelNo: 104223, ValorDado: interventionMedication.quantUtilizadaGota || '' },
      //   { VariavelNo: 29949, ValorDado: this.getDate(interventionMedication.hojedia) },
      //   { VariavelNo: 30212, ValorDado: interventionMedication.codigo || '' },
      //   { VariavelNo: 30213, ValorDado: interventionMedication.titulo || '' },
      //   { VariavelNo: 30214, ValorDado: this.glb.getReverseBoolean(interventionMedication.habilitado) },
      //   // { VariavelNo: 30263, ValorDado: interventionMedication.codigomedicamento || '' },
      //   {
      //     VariavelNo: 30264,
      //     ValorDado: interventionMedication.cascataCheckIDClienteIDMedicamentoDataEVOLUCAO || ''
      //   },
      //   { VariavelNo: 30266, ValorDado: interventionMedication.centroCustoIDCentroCusto || '' },
      //   { VariavelNo: 30270, ValorDado: interventionMedication.tIPOMedicamentos || '' },
      //   { VariavelNo: 30271, ValorDado: interventionMedication.tipomedicamento || '' },
      //   { VariavelNo: 30272, ValorDado: interventionMedication.dia || '' },
      //   { VariavelNo: 30296, ValorDado: interventionMedication.codigoPacienteNomePaciente || '' },
      //   { VariavelNo: 28018, ValorDado: interventionMedication.tIPOMaterial || '' },
      //   { VariavelNo: 30307, ValorDado: interventionMedication.uNIDADEDENEGOCIO || '' },
      //   { VariavelNo: 30308, ValorDado: interventionMedication.validCadastro || '' },
      //   { VariavelNo: 30320, ValorDado: interventionMedication.codPacienteNomedoPacienteCodMedicamento || '' },
      //   { VariavelNo: 30330, ValorDado: this.getDate(interventionMedication.dataPRESCRICAO) },
      //   { VariavelNo: 30339, ValorDado: interventionMedication.tipoMedicamentosCodPaciente || '' },
      //   { VariavelNo: 30356, ValorDado: interventionMedication.medicametnoPrescrito || '' },
      //   { VariavelNo: 30367, ValorDado: interventionMedication.horaprimeiraDose || '' },
      //   { VariavelNo: 30401, ValorDado: interventionMedication.codigoMedicamentoPaciente || '' },
      //   { VariavelNo: 31571, ValorDado: this.getDate(interventionMedication.dataEVOLUCAO) },
      //   { VariavelNo: 30277, ValorDado: this.convertToValorTexto(interventionMedication.lstmedicationCheckin) },
      //   { VariavelNo: 29828, ValorDado: interventionMedication.Id_Paciente || '' },
      //   { VariavelNo: 30263, ValorDado: interventionMedication.Id_Medicamento || '' },
      //   { VariavelNo: 30401, ValorDado: interventionMedication.IdMedicamento_IdPaciente || '' },
      //   { VariavelNo: 32859, ValorDado: this.glb.getReverseBoolean(interventionMedication.interroperMedicacao) },
      //   { VariavelNo: 32860, ValorDado: interventionMedication.interrupcaoMotivo || '' },
      //   // cal__isSOS
      //   // intervaloHoras
      //   // horarios
      //   { VariavelNo: 30396, ValorDado: this.convertToValorTextoHorarios(interventionMedication.lsthorariosGrid) },
      //   { VariavelNo: 31967, ValorDado: this.getDate(interventionMedication.dataInicio) },
      //   { VariavelNo: 31968, ValorDado: this.getDate(interventionMedication.dataFim) },
      //   { VariavelNo: 32857, ValorDado: interventionMedication.medicamentoControlado },
      //   { VariavelNo: 32858, ValorDado: interventionMedication.profissional || '' },
      //   { VariavelNo: 32861, ValorDado: interventionMedication.tempoEstimado || '' },
      //   { VariavelNo: 32862, ValorDado: interventionMedication.quantTempoEstimado || '' },
      //   { VariavelNo: 32939, ValorDado: interventionMedication.codigoHospede || '' },
      //   { VariavelNo: 35051, ValorDado: interventionMedication.intervaloDS || '' },
      //   { VariavelNo: 32935, ValorDado: interventionMedication.Id_Prescricao || '' },
      //   { VariavelNo: 104628, ValorDado: interventionMedication.ordem || 0 },
      //   { VariavelNo: 34709, ValorDado: interventionMedication.materialId || '' }
      // ] : null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  protected getDate(value: string | Date): string {
    try {
      const dt = this.glb.getTypedValue(value);
      if (dt.type === EnTypedValue.Date) {
        return this.glb.dateToYYYYMMddThhmmss(dt.value);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDate', error.message);
    }
    return null;
  }

  protected getFromCadastroGrid(
    cadastroNo: number,
    startDate: Date,
    endDate: Date
  ): Observable<IInterventionMedication> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      // Resgata os dados que duplicam os registros para cada item do grid e os agrupa por OcorrenciaNo
      const grouped = this.cadastroSrv
        .getCadastroComGridFiltro(
          cadastroNo,
          -1,
          this.lstVariaveis,
          this.variavelGrid,
          this.lstVariaveisGrid,
          ''
        )
        .pipe(publishReplay(), refCount(), groupBy((g) => g.OcorrenciaNo));
      // Criação do Observable que será o formato de retorno
      return new Observable((observer) => {
        let interventionMedication: IInterventionMedication;
        // Subscrição para os grupos
        this.subs.sink = grouped.subscribe(
          (group) => {
            // Subscrição para acessar os dados de cada grupo
            this.subs.sink = group.pipe(toArray()).subscribe((item) => {
              // Todos os elementos do grupo são iguais, exceto pelos campos do grid
              // por esse motivo, é possível utilizar como base para os demais dados qualquer elementos
              const c = item[0];
              // Criação de um array com os percentuais e valores por unidade de negócio
              const lstmedicationCheckin: IMedicationCheckin[] =
                item.length <= 0
                  ? null
                  : item.map(
                    (m) => mapToMedicationCheckin(m)
                  );
              interventionMedication = {
                ...toAttributeIntervention(c, lstmedicationCheckin),
                id: group.key, // Para exibir a OcorrenciaNo ao inves do Código do cadastro
                // ocorrenciaNo: c.OcorrenciaNo,
                // medicamento: c.V_828,
                // tempoUso: c.V_830,
                // dosagem: c.V_832,
                // via: c.V_2949,
                // orientacoes: c.V_2952,
                // posologia: c.V_3530,
                // centrocusto: c.V_27993,
                // codigoPaciente: c.V_29828,
                // apresentacao: c.V_29834,
                // quantUtilizadaD: c.V_29838,
                // quantUtilizadaGota: c.V_104223,
                // hojedia: this.glb.ddMMYYYYThhmmssToDate(c.V_29949),
                // codigo: c.V_30212,
                // titulo: c.V_30213,
                // habilitado: this.glb.getBoolean(c.V_30214),
                // // codigomedicamento: c.V_30263,
                // cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: c.V_30264,
                // centroCustoIDCentroCusto: c.V_30266,
                // tIPOMedicamentos: c.V_30270,
                // tipomedicamento: c.V_30271,
                // dia: c.V_30272,
                // codigoPacienteNomePaciente: c.V_30296,
                // tIPOMaterial: c.V_28018,
                // uNIDADEDENEGOCIO: c.V_30307,
                // validCadastro: c.V_30308,
                // codPacienteNomedoPacienteCodMedicamento: c.V_30320,
                // dataPRESCRICAO: this.glb.ddMMYYYYThhmmssToDate(c.V_30330),
                // tipoMedicamentosCodPaciente: c.V_30339,
                // medicametnoPrescrito: c.V_30356,
                // horaprimeiraDose: c.V_30367,
                // codigoMedicamentoPaciente: c.V_30401,
                // dataEVOLUCAO: this.glb.ddMMYYYYThhmmssToDate(c.V_31571),
                // lstmedicationCheckin,
                // Id_Paciente: c.V_29828,
                // Id_Medicamento: c.V_30263,
                // IdMedicamento_IdPaciente: c.V_30401,
                // interroperMedicacao: this.glb.getBoolean(c.V_32859),
                // interrupcaoMotivo: c.V_32860,
                // calc__isSOS: this.isSOS(c),
                // intervaloHoras: c.V_34906 || 0,
                // ordem: c.V_104628 || 0,
                // // alteracao barbara
                // horarios: c.V_30396,
                // // descricao: any;
                // dataInicio: c.V_31967,
                // dataFim: c.V_31968,
                // medicamentoControlado: c.V_32857,
                // profissional: c.V_32858,
                // tempoEstimado: c.V_32861,
                // quantTempoEstimado: c.V_32862,
                // // codMedPacTM: any;
                // // cascataTmatHospe: any;
                // codigoHospede: c.V_32939,
                // intervaloDS: c.V_35051,
                // Id_Prescricao: c.V_32935,
                // lsthorariosGrid: [],
                // materialId: c.V_34709
              } as IInterventionMedication;
              // Uma vez que o item é formado, é emitido, mas o complete só será chamado quando todos os itens forem concluídos
              observer.next(interventionMedication);
            });
          },
          (er) => console.log('InterventionMedication.getFromCadastroGrid: ', er),
          () => {
            observer.complete();
          }
        );
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadastroGrid', error.message);
    }
  }

  protected convertToValorTexto(medicationCheckin: IMedicationCheckin[]): string {
    try {
      return convertCheckinToValorTexto(medicationCheckin);
      // let valorTexto = '<![CDATA[<Items>';
      // if (medicationCheckin) {
      //   medicationCheckin.forEach((f) => {
      //     valorTexto += `<Item><index>${f.codigo}</index>`;
      //     if (f.cascataCheckIDClienteIDMedicamentoDataAtual)
      //       valorTexto += `<V_30264>${f.cascataCheckIDClienteIDMedicamentoDataAtual}</V_30264>`;
      //     if (f.horaPrescricao) valorTexto += `<V_30273>${f.horaPrescricao}</V_30273>`;
      //     if (f.horaCheckin) valorTexto += `<V_34166>${f.horaCheckin}</V_34166>`;
      //     if (f.status) valorTexto += `<V_30274>${f.status}</V_30274>`;
      //     if (f.observacoes) valorTexto += `<V_30275>${f.observacoes}</V_30275>`;
      //     if (f.statusmedicacao) valorTexto += `<V_30276>${f.statusmedicacao}</V_30276>`;
      //     if (f.dataCheckin) valorTexto += `<V_30330>${f.dataCheckin}</V_30330>`;
      //     if (f.diaPrescricao) valorTexto += `<V_30331>${f.diaPrescricao}</V_30331>`;
      //     if (f.codigo) valorTexto += `<V_30351>${f.codigo}</V_30351>`;
      //     if (f.identificacao) valorTexto += `<V_30264>${f.identificacao}</V_30264>`;
      //     if (f.titulo) valorTexto += `<V_30352>${f.titulo}</V_30352>`;
      //     if (f.habilitado) valorTexto += `<V_30353>${f.habilitado}</V_30353>`;
      //     if (f.codigoMedicamentoPaciente) valorTexto += `<V_30401>${f.codigoMedicamentoPaciente}</V_30401>`;
      //     if (f.profissional) valorTexto += `<V_31927>${f.profissional}</V_31927>`;
      //     if (f.Id_Paciente) valorTexto += `<V_29828>${f.Id_Paciente}</V_29828>`;
      //     if (f.Id_Medicamento) valorTexto += `<V_30263>${f.Id_Medicamento}</V_30263>`;
      //     if (f.IdMedicamento_IdPaciente) valorTexto += `<V_30401>${f.IdMedicamento_IdPaciente}</V_30401>`;
      //     valorTexto += '</Item>';
      //   });
      // }
      // valorTexto += '</Items>]]>';
      // return valorTexto;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertToValorTexto', error.message);
    }
    return '';
  }

  /** OBS: Usado apenas pelo card-prescription */
  getFromCadastroFiltro(cadastroNo: number, filtro: string, startDate: Date, endDate: Date): Observable<IInterventionMedication> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true)
        .pipe(map((c) => this.toAttribute(c, [])), publishReplay(), refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  getActiveMedicationFiltered(cadastroNo: number, filtro: string, startDate: Date, endDate: Date): Observable<IInterventionMedication> {
    try {
      const td = new Date();
      return this.getFromCadastroFiltro(cadastroNo, filtro, startDate, endDate)
        .pipe(
          filter(f =>
            !f.interroperMedicacao &&
            (
              this.glb.isBetweenIgnoreTime(td, this.glb.getTypedValue(f.dataInicio).value, this.glb.getTypedValue(f.dataFim).value) ||
              this.glb.isEqual(f.tempoUso, 'permanente')
            )
          )
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getActiveMedicationFiltered', error.message);
    }
    return of(null);
  }

  toAtributeGrid(m): IMedicationCheckin {
    try {
      // const item = {
      //   cascataCheckIDClienteIDMedicamentoDataAtual: m.V_30264,
      //   horaPrescricao: m.V_30273,
      //   horaCheckin: m.V_34166,
      //   status: m.V_30274,
      //   observacoes: m.V_30275,
      //   statusmedicacao: m.V_30276,
      //   dataCheckin: this.glb.ddMMYYYYThhmmssToDate(m.V_30330),
      //   diaPrescricao: m.V_30331,
      //   codigo: m.V_30351,
      //   identificacao: m.V_30264,
      //   titulo: m.V_30352,
      //   habilitado: this.glb.getBoolean(m.V_30353),
      //   codigoMedicamentoPaciente: m.V_30401,
      //   Id_Paciente: m.V_29828,
      //   Id_Medicamento: m.V_30263,
      //   IdMedicamento_IdPaciente: m.V_30401
      // } as IMedicationCheckin;
      // return item;
      return mapToMedicationCheckin(m);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAtributeGrid', error.message);
    }
    return null;
  }

  protected getFromCadastroGridHorarios(cadastroNo: number, startDate: Date, endDate: Date): Observable<IInterventionMedication> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      // Resgata os dados que duplicam os registros para cada item do grid e os agrupa por OcorrenciaNo
      const grouped = this.cadastroSrv
        .getCadastroComGridFiltro(cadastroNo, -1, this.lstVariaveis, this.variavelGrid, this.lstVariaveisGrid, '')
        .pipe(
          publishReplay(),
          refCount(),
          groupBy(g => g.OcorrenciaNo)
        );
      // Criação do Observable que será o formato de retorno
      return new Observable(observer => {
        let intervetionMedication: IInterventionMedication;
        this.subs.sink = grouped.subscribe(
          (group) => {
            // Subscrição para acessar os dados de cada grupo
            this.subs.sink = group.pipe(toArray()).subscribe((item) => {
              // Todos os elementos do grupo são iguais, exceto pelos campos do grid
              // por esse motivo, é possível utilizar como base para os demais dados qualquer elementos
              const c = item[0];
              // Criação de um array com os percentuais e valores por unidade de negócio
              const lsthorariosGrid: IIntervecoesMedicamentosHorarios[] = item.length <= 0 ? null :
                item.map(m => (
                  {
                    codigo: c.V_30392,
                    titulo: c.V_30393,
                    habilitado: c.V_30394.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_30394) ? true : false,
                    hora: c.V_30395,
                    Id_Paciente: c.V_29828,
                    Id_Medicamento: c.V_30263,
                    IdMedicamento_IdPaciente: c.V_30401,
                    codigoMedicamentoPaciente: c.V_30401
                  } as IIntervecoesMedicamentosHorarios));
              intervetionMedication = ({
                ...toAttributeIntervention(c, null, lsthorariosGrid),
                id: group.key, // Para exibir a OcorrenciaNo ao inves do Código do cadastro
                // ocorrenciaNo: c.OcorrenciaNo,
                // medicamento: c.V_828,
                // tempoUso: c.V_830,
                // dosagem: c.V_832,
                // via: c.V_2949,
                // orientacoes: c.V_2952,
                // posologia: c.V_3530,
                // centrocusto: c.V_27993,
                // codigoPaciente: c.V_29828,
                // apresentacao: c.V_29834,
                // quantUtilizadaD: c.V_29838,
                // quantUtilizadaGota: c.V_104223,
                // hojedia: this.glb.ddMMYYYYThhmmssToDate(c.V_29949),
                // codigo: c.V_30212,
                // titulo: c.V_30213,
                // habilitado: this.glb.getBoolean(c.V_30214),
                // // codigomedicamento: c.V_30263, Não tem na geração atual, é Id_medicamento
                // cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: c.V_30264,
                // centroCustoIDCentroCusto: c.V_30266,
                // tIPOMedicamentos: c.V_30270,
                // tipomedicamento: c.V_30271,
                // dia: c.V_30272,
                // codigoPacienteNomePaciente: c.V_30296,
                // tIPOMaterial: c.V_28018,
                // uNIDADEDENEGOCIO: c.V_30307,
                // validCadastro: c.V_30308,
                // codPacienteNomedoPacienteCodMedicamento: c.V_30320,
                // dataPRESCRICAO: this.glb.ddMMYYYYThhmmssToDate(c.V_30330),
                // tipoMedicamentosCodPaciente: c.V_30339,
                // medicametnoPrescrito: c.V_30356,
                // horaprimeiraDose: c.V_30367,
                // codigoMedicamentoPaciente: c.V_30401,
                // dataEVOLUCAO: this.glb.ddMMYYYYThhmmssToDate(c.V_31571),
                // lstmedicationCheckin: [],
                // Id_Paciente: c.V_29828,
                // Id_Medicamento: c.V_30263,
                // IdMedicamento_IdPaciente: c.V_30401,
                // interroperMedicacao: this.glb.getBoolean(c.V_32859),
                // interrupcaoMotivo: c.V_32860,
                // calc__isSOS: this.isSOS(c),
                // intervaloHoras: c.V_34906 || 0,
                // ordem: c.V_104628 || 0,
                // // alteracao barbara
                // horarios: c.V_30396,
                // // descricao: any;
                // dataInicio: c.V_31967,
                // dataFim: c.V_31968,
                // medicamentoControlado: c.V_32857,
                // profissional: c.V_32858,
                // tempoEstimado: c.V_32861,
                // quantTempoEstimado: c.V_32862,
                // // codMedPacTM: any;
                // // cascataTmatHospe: any;
                // codigoHospede: c.V_32939,
                // intervaloDS: c.V_35051,
                // Id_Prescricao: c.V_32935,
                // materialId: c.V_34709,
                // lsthorariosGrid
              } as IInterventionMedication);
              // Uma vez que o item é formado, é emitido, mas o complete só será chamado quando todos os itens forem concluídos
              observer.next(intervetionMedication);
            });
          },
          (er) => console.log('IntervetionMedication.getFromCadastroGrid: ', er),
          () => {
            observer.complete();
          });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadastroGrid', error.message);
    }
    return of(null);
  }

  protected convertToValorTextoHorarios(horariosGrid: IIntervecoesMedicamentosHorarios[]): string {
    try {
      return convertToValorTextoHorarios(horariosGrid);
      // let valorTexto = '<![CDATA[<Items>';
      // if (horariosGrid) {
      //   horariosGrid.forEach(f => {
      //     valorTexto += `<Item><index>${f.codigo}</index>`;
      //     if (f.Id_Paciente) valorTexto += `<V_29828>${f.Id_Paciente}</V_29828>`;
      //     if (f.Id_Medicamento) valorTexto += `<V_30263>${f.Id_Medicamento}</V_30263>`;
      //     if (f.codigo) valorTexto += `<V_30392>${f.codigo}</V_30392>`;
      //     if (f.titulo) valorTexto += `<V_30393>${f.titulo}</V_30393>`;
      //     if (f.habilitado) valorTexto += `<V_30394>${f.habilitado}</V_30394>`;
      //     if (f.hora) valorTexto += `<V_30395>${f.hora}</V_30395>`;
      //     if (f.IdMedicamento_IdPaciente) valorTexto += `<V_30401>${f.IdMedicamento_IdPaciente}</V_30401>`;
      //     valorTexto += '</Item>';
      //   });
      // }
      // valorTexto += '</Items>]]>';
      // return valorTexto;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertToValorTexto', error.message);
    }
    return '';
  }

  convertGridHorariosToText(lsthorariosGrid: IIntervecoesMedicamentosHorarios[]): string {
    try {
      return convertGridHorariosToText(lsthorariosGrid);
      // return lsthorariosGrid?.map(m => m.hora).join(', ');
      // let valorTexto = '<![CDATA[<Items>';
      // if (horariosGrid) {
      //   horariosGrid.forEach(f => {
      //     valorTexto += `<Item><index>${f.codigo}</index>`;
      //     if (f.Id_Paciente) valorTexto += `<V_29828>${f.Id_Paciente}</V_29828>`;
      //     if (f.Id_Medicamento) valorTexto += `<V_30263>${f.Id_Medicamento}</V_30263>`;
      //     if (f.codigo) valorTexto += `<V_30392>${f.codigo}</V_30392>`;
      //     if (f.titulo) valorTexto += `<V_30393>${f.titulo}</V_30393>`;
      //     if (f.habilitado) valorTexto += `<V_30394>${f.habilitado}</V_30394>`;
      //     if (f.hora) valorTexto += `<V_30395>${f.hora}</V_30395>`;
      //     if (f.IdMedicamento_IdPaciente) valorTexto += `<V_30401>${f.IdMedicamento_IdPaciente}</V_30401>`;
      //     valorTexto += '</Item>';
      //   });
      // }
      // valorTexto += '</Items>]]>';
      // return valorTexto;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertGridHorariosToText', error.message);
    }
    return '';
  }


}
