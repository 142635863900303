import { SnackbarNotificationService } from './service/snackbar-notification.service';
import { MaterialModule } from '@medlogic/shared/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSnackbarNotificationComponent } from './ui-snackbar-notification/ui-snackbar-notification.component';

@NgModule({
  declarations: [UiSnackbarNotificationComponent],
  imports: [CommonModule, MaterialModule],
  exports: [UiSnackbarNotificationComponent],
  providers: [SnackbarNotificationService],
  entryComponents: [UiSnackbarNotificationComponent]
})
export class UiSnackbarNotificationModule { }
