import { Injectable } from '@angular/core';
import { routeReference } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';


@Injectable({ providedIn: 'root' })
export class FhirReferenceService extends FhirGenericService {
  protected url = routeReference;

}
