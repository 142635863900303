export interface IView {
  ocorrenciaNo: number;
  codigo: any;
  titulo: any;
  habilitado: boolean;
  data: Date;
  nome: any;
  idPessoa: any;
  idPost: any;
}
