import { ConfigStateService } from '@medlogic/shared/state-config';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { LibService } from '../service/lib.service';
import { IBubble } from '../interface/ibubble';
import { CalculadoraConditionService } from '../service/calculadora-condition.service';
import { ExecucaoTarefaDAL } from './dal/execucao-tarefa-dal';
import { NavigationService } from '../service/navigation.service';
import { Router } from '@angular/router';
import { CadastroListaDAL } from './dal/cadastro-lista-dal';
import { CalculadoraService } from '../service/calculadora.service';
import { ICadastro } from '../interface/icadastro';
import { OnInit, EventEmitter, Output, Injectable } from '@angular/core';
import { Control } from './control';
import { EnBubbleEvent } from '../enum/en-bubble-event.enum';
import { MatDialog } from '@angular/material/dialog';
import { ICadastroListaDAL } from '../interface/icadastro-lista-dal';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { AtividadeCadastroDetailDialogComponent } from '../../view/atividade-cadastro-detail-dialog/atividade-cadastro-detail-dialog.component';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { EnListControlType } from '@medlogic/shared/gecore';
import { SelectItem } from 'primeng/api';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { GeFormProviderService } from '../service/ge-form-provider.service';
import { IAtividadeCadastroDetailDialog } from '../interface/iatividade-cadastro-detail-dialog';

@Injectable()
export abstract class ListControl extends Control implements OnInit {

  selectedValue: string;
  items: SelectItem[];
  cadastros: ICadastro[];

  // private _lstCadastroAdicional: Array<any>;

  /*Modificado para ficar compatível com a edição de detalhe em janela de diálogo.
  */
  public get lstCadastroAdicional(): any[] {
    // if (this.ctrl?.lstCadastroAdicional && this.ctrl?.lstCadastroAdicional.length > 0) {
    return this.ctrl?.lstCadastroAdicional;
    // } else {
    // 	return this._lstCadastroAdicional;
    // }
  }

  // public set lstCadastroAdicional(v: Array<any>) {
  // 	this._lstCadastroAdicional = v;
  // }

  /*receberá as chaves necessárias para uma limpeza específica do cache no futuro */
  cacheKeys: any = {};

  message: any = {
    firstButtonLabel: 'Não',
    title: 'Confirmação',
    icon: 'fa-times',
    text: '',
    acceptFunc: () => {
      // método de exclusão
    }
  };

  hasConfirmButton = true;
  valorFiltro = '';
  // Avisará que os dados foram carregados
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onDataLoaded: EventEmitter<IAtividadeComponenteDAL> = new EventEmitter<IAtividadeComponenteDAL>();

  constructor(
    global: GlobalService,
    log: LogService,
    lib: LibService,
    cnf: ConfigStateService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR,
    protected cadastroListaDAL: CadastroListaDAL,
    protected cnfJson: ConfigJsonService,
    protected router: Router,
    protected navigation: NavigationService,
    protected execucaoTarefa: ExecucaoTarefaDAL,
    protected geFormPrv: GeFormProviderService,
  ) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }

  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit() {
    try {
      if (this.lib.isCascade(this.ctrl)) {
        this.valorFiltro = this.getValueFrom(this.ctrl?.VariavelAssociadaNo);
      }
      this.registerCadastroDependents(this.ctrl);
      this.refresh();
      this.subscribeToCascade(this.ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }


  /* Verifica se o controle tem o cascata configurado e subscreve a mudança no formulário para carregamento dos itens
  * Modificará this.valorFiltro com o novo valor.
  */
  protected subscribeToCascade(ctrl: IAtividadeComponenteDAL): void {
    try {
      if (this.lib.isCascade(ctrl)) {
        this.subs.sink = this.formGroup?.valueChanges.subscribe((f) => {
          try {
            const formControls: any = this.formGroup?.getRawValue();
            // Não é possível usar o f, pois, ele não inclui os controles disabled.
            for (const clm in formControls) {
              if (clm === this.lib.getId(ctrl?.VariavelAssociadaNo)) {
                const newValue = formControls[clm];
                // TODO: tentativa de recarregar o cadastro mediante solicitação de atividadeComponente.updateAllListCtrlFromSameCadastroNo
                // const touched = Object
                //   .values(this.formGroup.controls)
                //   .filter(fc => fc.touched || fc.dirty);
                if (newValue !== this.valorFiltro) { // } || (touched && touched.length > 0)) {
                  this.valorFiltro = newValue;
                  this.refresh();
                }
              }
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'subscribeToCascade.valueChanges', error.message);
          }
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'subscribeToCascade', error.message);
    }
  }

  /* Resgata os itens para uma Combobox. */
  protected getCadastros(
    usuarioNo: number,
    ctrl: IAtividadeComponenteDAL,
    valorFiltro: string
  ): Observable<ICadastro[]> {
    try {
      const isCascade = this.lib.isCascade(ctrl);
      let cadastroListaDAL: Observable<any>;
      switch (this.getListControlType(ctrl)) {
        case EnListControlType.Direct:
          cadastroListaDAL = of(
            ctrl.lstValue?.string.map((m, i) => {
              return {
                CampoNome: m,
                Valor: m,
                id: m // o valor, nessa lista, é a Ocorrencia
              } as ICadastro;
            })
          );
          break;
        case EnListControlType.List:
        case EnListControlType.None:
        case EnListControlType.User:
          if (isCascade) {
            // Se o cascata está ativo
            this.cacheKeys = {
              method: 'getCadastroFiltrado',
              params: [
                +this.ctrl?.CadastroNo,
                +this.ctrl?.VariavelComparacaoNo,
                valorFiltro,
                +this.ctrl?.VariavelRetornoNo,
                +this.ctrl?.AtividadeComponenteNo
              ]
            };
            cadastroListaDAL = this.cadastroListaDAL
              .getFiltered(
                +this.ctrl?.CadastroNo,
                +this.ctrl?.VariavelComparacaoNo,
                valorFiltro,
                +this.ctrl?.VariavelRetornoNo,
                +this.ctrl?.AtividadeComponenteNo
              )
              .pipe(
                map((m) => {
                  try {
                    const items = m ? this.lib.toArray(this.lib.getItemDoCadastro(m)) : null;
                    return !items
                      ? null
                      : items.map((i) => {
                        const id = this.lib.getId(ctrl.VariavelRetornoNo);
                        return {
                          CampoNome: i[id],
                          Valor: id,
                          id: i.OcorrenciaNo
                        } as ICadastro;
                      });
                  } catch (error) {
                    this.log.Registrar(this.constructor.name, 'getCadastros.map', error.message);
                  }
                  return null;
                })
              );
          } else {
            // Sem cascata
            this.cacheKeys = {
              method: 'getCadastroLista',
              params: [
                +this.ctrl?.CadastroNo,
                usuarioNo,
                +this.ctrl?.VariavelRetornoNo,
                this.ctrl?.Type,
                +this.ctrl?.AtividadeComponenteNo
              ]
            };
            cadastroListaDAL = this.cadastroListaDAL
              .getAll(
                +this.ctrl?.CadastroNo,
                usuarioNo,
                +this.ctrl?.VariavelRetornoNo,
                this.ctrl?.Type,
                +this.ctrl?.AtividadeComponenteNo
              )
              .pipe(
                map((m) => {
                  try {
                    // <ICadastro[]>
                    const items = this.lib.toArray(m.lstCadastro.Cadastro);
                    return !items
                      ? items
                      : items.map((i) => {
                        return {
                          CampoNome: i.CampoNome,
                          Valor: i.Valor,
                          id: i.Valor // o valor, nessa lista, é a Ocorrencia
                        } as ICadastro;
                      });
                  } catch (error) {
                    this.log.Registrar(
                      this.constructor.name,
                      'getCadastros sem cascata map',
                      error.message
                    );
                  }
                  return null;
                })
              );
          }
          break;
      }
      return cadastroListaDAL;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadastros', error.message);
    }
  }

  /*Limpa o cache específico baseado no método chamado e nas chaves.
  * Antes de uma edição/adição/exclusão de item, chamar esse parâmetro
  * para evitar que os dados de cache sejam carregados na volta.
  */
  protected cleanCache(): void {
    try {
      if (!this.global.IsNullOrEmpty(this.cacheKeys)) {
        this.cadastroListaDAL.cleanCache(this.cacheKeys.method, this.cacheKeys.params);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCache', error.message);
    }
  }

  /* Retorna o tipo de controle se é associadado a cadastro, se é uma lista dos colaboradores ou um cadastramento direto na combobox. */
  protected getListControlType(ctrl: IAtividadeComponenteDAL): EnListControlType {
    try {
      const isAssociatedToList = ctrl?.CadastroNo > 0;
      if (isAssociatedToList) {
        return EnListControlType.List;
      }
      const isDirect = !isAssociatedToList && ctrl?.lstValue;
      if (isDirect) {
        return EnListControlType.Direct;
      }
      const isUsers = !isAssociatedToList && !isDirect;
      if (isUsers) {
        return EnListControlType.User;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getListControlType', error.message);
    }
    return EnListControlType.None;
  }

  /* Os itens de um Grid são resgatados através desse método. */
  protected getCadastroAdicional(
    cadastroNo: number,
    usuarioLogadoNo: number,
    variavelRetornoNo: number,
    type: string,
    atividadeComponenteNo: number,
    ctrl: IAtividadeComponenteDAL,
    valorFiltro: string,
    isCascade: boolean
  ): Observable<ICadastroListaDAL[]> {
    try {
      let cadastroListaDAL$: Observable<ICadastroListaDAL>;
      if (isCascade) {
        if (this.global.isNullOrEmpty(valorFiltro)) {
          return of(null);
        } else {
          // Se o cascata está ativo
          this.cacheKeys = {
            method: 'getCadastroFiltrado',
            params: [
              +cadastroNo,
              +ctrl.VariavelComparacaoNo,
              valorFiltro,
              +ctrl.VariavelRetornoNo,
              +ctrl.AtividadeComponenteNo
            ]
          };
          cadastroListaDAL$ = this.cadastroListaDAL.getFiltered(
            +cadastroNo,
            +ctrl?.VariavelComparacaoNo,
            valorFiltro,
            +ctrl?.VariavelRetornoNo,
            +ctrl?.AtividadeComponenteNo
          );
          return cadastroListaDAL$?.pipe(
            map((m) => this.lib.toArray(this.lib.getItemDoCadastro(m))),
            mergeMap((items) => {
              this.isLoading = false;
              return of(items);
            }),
            catchError((e) => {
              this.isLoading = false;
              this.log.Registrar(this.constructor.name, 'getCadastros throwError', e.message);
              return of(null);
            })
          );
        }
      } else {
        // Sem cascata
        this.cacheKeys = {
          method: 'getCadastroLista',
          params: [+cadastroNo, usuarioLogadoNo, +variavelRetornoNo, type, +atividadeComponenteNo]
        };
        cadastroListaDAL$ = this.cadastroListaDAL.getAll(
          cadastroNo,
          usuarioLogadoNo,
          variavelRetornoNo,
          type,
          atividadeComponenteNo
        );
        return cadastroListaDAL$?.pipe(
          map((m) => this.lib.toArray(this.lib.getItemDoCadastro(m))),
          mergeMap((items) => {
            this.isLoading = false;
            return of(items);
          })
        );
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadastros', error.message);
    }
  }

  protected refresh(): void {
    try {
      this.subs.sink = this.getCadastros(+this.cnf.usuarioLogadoNo, this.ctrl, this.valorFiltro)
        .subscribe(
          (s) => {
            this.cadastros = s;
          },
          (er) => console.log(er),
          () => {
            try {
              this.fillItems(this.cadastros);
              // this.selectItemIfBackFromEditOrNew(this.ctrl?.AtividadeCadastroNo, this.items, this.formGroup);
              // Necessário para notificar a AtividadeComponent sobre o carregamento dos itens.
              this.onDataLoaded.emit(this.ctrl);
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'refresh.getCadastros', error.message);
            }
            this.isLoading = false;
          }
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /*Selecionará o item da combobox, caso seja o retorno de uma edição ou novo item.
     * Também irá preencher lstCadastroAdicional.
     * Esse método é chamado em dois cenários:
     * 1) Editar/Novo de uma combobox, quando abre a edição do cadastro que possui uma ou mais combobox
     * 1.1) Nesse cenário, tentará verificar se há uma ou mais variáveis já preenchidas, que correspondem a cada uma das combobox,
     * que deverão ter o valor herdado da Atividade chamadora.
     *
     * 2) Retorno do Editar/Novo, onde, os valores modificados irão atualizar a combobox, mas também
     * a lstCadastroAdicional da Atividade chamadora.
    */
  // O TRANSPORTE DE VALORES NÃO UTILIZA MAIS A ESTRUTURA DE config.listItem
  // protected selectItemIfBackFromEditOrNew(
  // 	cadastroAssociadoNo: number,
  // 	items: SelectItem[],
  // 	formGroup: FormGroup
  // ): void {
  // 	try {
  // 		const selItem = this.config.listItem[cadastroAssociadoNo];
  // 		const id = this.lib.getId(this.ctrl?.VariavelNo);
  // 		// #region Cenário 1) Preenchimento dos valores selecionados das combos na Atividade chamada
  // 		// FIXME: Falta identificar uma validação para saber se é realmente cenário 1.
  //  Caso contrário, variáveis com mesmo Id na chamada irão sobrescrever na chamadora.
  // 		if (selItem && selItem.hasOwnProperty(id)) {
  // 			const control = formGroup.get(id);
  // 			if (control) {
  // 				control?.setValue(selItem[id], { onlySelf: true, emitEvent: true });
  // 				control?.markAsDirty();
  // 				this.formGroup.updateValueAndValidity({ emitEvent: true });
  // 			}
  // 		}
  // 		// #endregion
  // 		// #region Cenário 2) Retorno dos valores para a Atividade Chamadora
  // 		if (selItem && selItem.id === id && this.ctrl?.lstCadastroAdicional) {
  // 			// #region Preencher lstCadastroAdicional (necessário para funcionamento de fórmulas como PROC)
  // 			const cadAdicional = this.ctrl.lstCadastroAdicional.find((f) => selItem.index === f.OcorrenciaNo);
  // 			if (cadAdicional) {
  // 				// Edição de um item de cadastro adicional existente
  // 				for (const clm in cadAdicional) {
  // 					if (selItem.hasOwnProperty(clm)) {
  // 						cadAdicional[clm] = selItem[clm];
  // 					}
  // 				}
  // 			} else if (this.ctrl?.lstCadastroAdicional.length > 0) {
  // 				// Criação de novo item, pois, o elemento é recém criado. usará o primeiro elemento de lstCadastroAdicional como modelo de campos
  // 				const camposAdicionais =
  // 					this.ctrl?.LstCamposAdicionais && this.ctrl?.LstCamposAdicionais.CamposAdicionais
  // 						? this.ctrl?.LstCamposAdicionais.CamposAdicionais
  // 						: new Array();
  // 				const newCadAdicional = {};
  // 				camposAdicionais.forEach((f) => {
  // 					const id2 = this.lib.getId(f.VariavelNo);
  // 					if (selItem.hasOwnProperty(id2)) {
  // 						newCadAdicional[id2] = selItem[id2];
  // 					}
  // 				});
  // 				this.ctrl?.lstCadastroAdicional.push(newCadAdicional);
  // 			}
  // 			//#endregion
  // 			// #region Seleciona o item da Combobox, conforme modificação.
  // 			const control = formGroup.get(selItem.id);
  // 			// Necessário zerar o valor e depois gravá-lo novamente para forçar o
  // change mesmo se o valor não tiver mudado (necessário para fórmulas de proc que usam variáveis adicionais)
  // 			const newValue = !this.global.isNullOrEmpty(selItem.value) ? selItem.value : selItem[id] || '';
  // 			control?.markAsDirty();
  // 			control?.setValue('', { onlySelf: true, emitEvent: true });
  // 			this.formGroup.updateValueAndValidity({ emitEvent: true });
  // 			// Grava o valor correto
  // 			control?.setValue(newValue, { onlySelf: true, emitEvent: true });
  // 			control?.markAsDirty();
  // 			this.formGroup.updateValueAndValidity({ emitEvent: true });
  // 			this.config.listItem[cadastroAssociadoNo] = null;
  // 			this.selectTab(this.ctrl);
  // 			// #endregion
  // 		}
  // 		// #endregion
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'selectItemIfBackFromEditOrNew', error.message);
  // 	}
  // }

  /* Seleciona a aba que contém o componente. */
  protected selectTab(ctrl: IAtividadeComponenteDAL): void {
    try {
      // Muda para a aba do grid
      this.eventBubble.emit({
        bubbleEvent: EnBubbleEvent.tabChange,
        params: { tabIndex: ctrl?.TabIndex }
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'selectTab', error.message);
    }
  }

  /* Popula this.items que é a propriedade utilizada para popular a combobox. */
  protected fillItems(cadastros: any[]): void {
    try {
      if (cadastros) {
        this.items = cadastros.map(
          (m) => ({
            label: m?.CampoNome,
            value: m?.CampoNome,
            id: m?.id
          } as SelectItem)
        );
        // Adiciona Não Informado, mas somente se não for somente leitura.
        if (this.ctrl.IsEnable && (!this.items[0] || !this.global.isEqual(this.items[0]?.label, this.global.NOITEM_TEXT))) {
          this.selectItemIfHasJustOne(this.ctrl, this.items);
          if (this.items.length > 1) {
            const firstItem = { label: this.global.NOITEM_TEXT, value: this.global.NOITEM_TEXT } as SelectItem;
            this.items = [firstItem].concat(this.items);
          }
        }
      } else {
        this.items = null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillItems', error.message);
    }
  }

  // Seleciona o item existente, se só houver um
  protected selectItemIfHasJustOne(ctrl: IAtividadeComponenteDAL, items: SelectItem[]): void {
    try {
      if (items.length === 1) {
        ctrl.Valor = items[0].value;
        ctrl.ValorTexto = items[0].value;
        const formCtrl = this.formGroup.get(this.getId(ctrl));
        if (formCtrl) {
          formCtrl.setValue(ctrl?.ValorTexto, { onlySelf: false, emitEvent: true });
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'selectItemIfHasJustOne', error.message);
    }
  }

  /* Navega para a página da Atividade Cadastro correspondente, com botão de voltar habilitado.
  * Tem que salvar a Atividade atual antes, ou os dados se perdem.
  */
  onEdit($event: any, anoMain: number, anoChild: number, ono: number, listvno: number, readOnly: boolean, saveInList: boolean): Observable<IAtividadeCadastroDetailDialog> {
    try {
      if (this.ctrl?.CanReadItem || readOnly) {
        this.cleanCache();
        // O bubble deve propagar antes do openListDetailEditDialog, pois irá salvar a atividade anterior.
        this.eventBubble.emit({
          $event,
          bubbleEvent: EnBubbleEvent.listEdit,
          params: { ano: anoChild, ono, listvno, isReadOnly: !readOnly, saveInList, backAfterComplete: false }
        } as IBubble);
        return this.openListDetailEditDialog(
          anoMain,
          anoChild,
          ono,
          listvno,
          readOnly,
          saveInList,
          this.ctrl);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onItemClick', error.message);
    }
    return of(null);
  }

  /* Abre a edição da atividade filha (detail), num dialog. */
  openListDetailEditDialog(
    anoMain: number,
    anoChild: number,
    ono: number,
    listvno: number,
    isReadOnly: boolean,
    saveInList: boolean,
    ctrl: IAtividadeComponenteDAL
  ): Observable<IAtividadeCadastroDetailDialog> {
    try {
      const minWidth = '100vw';
      const width = '100vw';
      const height = '100%';
      const panelClass = this.enTheme === EnTheme.default ? 'my-centered-dialog' : 'my-centered-dialog-black';
      const enTheme = this.enTheme;
      const isMobile = this.isMobile;
      // const currentAno = this.config.ModeloAtividadeNo;
      const dialogRef = this.dialog
        .open(AtividadeCadastroDetailDialogComponent, {
          minWidth,
          width,
          height,
          panelClass,
          data: {
            ano: anoChild,
            ono,
            listvno,
            isReadOnly,
            saveInList,
            defaultFormControls: this.cnf.getDefaultFormControls(anoChild),
            lstCadastroAdicional: ctrl?.lstCadastroAdicional,
            enTheme,
            isMobile,
            ctrl
          }
        });
      return dialogRef.afterClosed()
        .pipe(
          mergeMap((result: IAtividadeCadastroDetailDialog) => {
            // Extremamente importante: Fará com que o parâmetro retorne para a
            //  ocorrencia da Atividade que chamou o diálogo, caso contrário, o salvar
            // salvará os componentes da chamadora com o OcorrenciaNo da atividade do diálogo
            // this.config.fillActivityParams(currentAno, this.ctrl?.OcorrenciaNo, listvno, isReadOnly, saveInList);
            this.cnf.returnToPreviousState();
            if (result) {
              this.updateValorTextoAndLstCadastroAdicional(
                result?.lstCadastroAdicional,
                ctrl,
                result?.formGroup?.value
              );
              // Necessário: caso contrário, quando o usuário volta para o grid e entra
              // novamente o novo valor não será carregado exceto se recarregar a página
              this.cleanCache();
            }
            return of(result);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'openListDetailEditDialog', error.message);
    }
    return of(null);
  }


  /* It will register together all ListControls that point to same CadastroNo.
  * It is necessary to updateCadastroDependents.
  */
  protected registerCadastroDependents(ctrl: IAtividadeComponenteDAL): void {
    try {
      if (ctrl?.CadastroNo > 0) {
        const obj = {};
        obj[ctrl?.CadastroNo] = [this as ListControl];
        this.geFormPrv.cadastroDependents$.next(obj);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'registerCadastroDependents', error.message);
    }
  }

  /* It reloads the data from all listcontrols that point to same CadastroNo.
  * For example: If the user updates a grid item, when it is back to the main activity,
  * all combobox that points to same Cadastro will be reloaded.
  */
  protected updateCadastroDependends(ctrl: IAtividadeComponenteDAL, values: { [key: string]: any }, refreshControls: boolean = true): void {
    try {
      if (!values) {
        return;
      }

      const item = this.geFormPrv.cadastroDependents$?.value[ctrl?.CadastroNo];
      item?.
        filter(f => f.variavelNo !== ctrl.VariavelNo)?.
        forEach((e: ListControl) => {
          if (refreshControls) {
            e.refresh();
          }
          const { listTitleVariableNo, listIdVariableNo, listEnabledVariableNo } = e.cadastroListaDAL.cnf;
          const labelVno = `V_${e.cacheKeys.params[3]}`;
          const newItem = {
            label: values[labelVno],// values[`V_${listTitleVariableNo}`],
            title: values[labelVno], // values[`V_${listTitleVariableNo}`],
            value: values[labelVno], // values[`V_${listIdVariableNo}`],
            disabled: values[`V_${listEnabledVariableNo}`] !== 'Sim'
          } as SelectItem;
          e.items.push(newItem as unknown as SelectItem);
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateCadastroDependends', error.message);
    }
  }

  /* Botão para criar um novo item.
  * Salva a Atividade antes.
  */
  protected onNew($event: any, anoMain: number, anoChild: number, listvno: number, saveInList: boolean): Observable<IAtividadeCadastroDetailDialog> {
    try {
      this.cleanCache();
      // O bubble deve propagar antes do openListDetailEditDialog, pois irá salvar a atividade anterior.
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.listNew,
        params: { ano: anoChild, listvno, saveInList, addToHistory: true }
      } as IBubble);
      return this.openListDetailEditDialog(anoMain, anoChild, -1, listvno, false, saveInList, this.ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onNew', error.message);
    }
    return of(null);
  }

  /* Emite um evento para a Atividade View para exibição de uma janela de Alerta. */
  protected onAlertDialog($event: any, message: any, hasConfirmButton: boolean): void {
    try {
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.alertDialog,
        params: { message, hasConfirmButton }
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAlertDialog', error.message);
    }
  }

  /* Exclui os itens do grid/combo. Remoção do cadastro opcional.
  * Items é a lista completa de itens que está vinculada aos controles visuais.
  * Retorna a lista sem os itens excluídos.
  * ATENÇÃO: Esse método é utilizado tanto pela ComboBox quanto pelo Grid os quais
  * possuem estruturas dos items DIFERENTES.
  */
  protected removeDeleteItems(
    items: any[],
    selectedItems: any[],
    campoOcorrenciaNo: string,
    removeFromCadastro: boolean,
    atividadeCadastroNo: number
  ): any[] {
    try {
      this.cleanCache();
      // Primeiro remove apenas da coleção de itens na memória
      const itemsLeft = items;
      selectedItems?.forEach((f) => {
        try {
          const index = itemsLeft?.indexOf(f);
          if (index > 0) {
            itemsLeft?.splice(index, 1);
          }
          // Executar a exclusão no serviço
          if (removeFromCadastro && atividadeCadastroNo > 0) {
            this.subs.sink = this.execucaoTarefa.deleteCadastro(f[campoOcorrenciaNo], atividadeCadastroNo).subscribe();
          }
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'deleteItems.forEach', error.message);
        }
      });
      return itemsLeft;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteItems', error.message);
    }
  }

  /* Realiza a atualização das propriedades de lista e valor.
  * ATENÇÃO: Também atualizará o valor do formControl correspondente.
  */
  protected updateValorTextoAndLstCadastroAdicional(
    items: any[],
    ctrl: IAtividadeComponenteDAL,
    formGroupValue: any = null,
    canBeEmpty: boolean = false
  ): void {
    try {
      // this.lstCadastroAdicional = items;
      ctrl.lstCadastroAdicional = items;
      ctrl.Valor = this.lib.getValorTextoOrData(ctrl, false, canBeEmpty);
      ctrl.ValorTexto = ctrl?.Valor || '';
      // Para forçar o change que recalculará eventuais formulas de grid (que dependem dessa lista carregada)
      // ctrl.Valor = null; // Necessário para que o change funcione
      // Necessário zerar o valor texto, ou se forem excluídos todos os itens,
      // a lógica em lib.getValorTextoOrData fará com que o ValorTexto seja novamente carregado.
      // ctrl.ValorTexto = '<Items></Items>';
      const formCtrl = this.formGroup.get(this.getId(ctrl));
      if (formCtrl) {
        formCtrl.setValue(ctrl?.ValorTexto, { onlySelf: false, emitEvent: true });
        // Marcar como dirty gera problema de ExpressionChangedAfterItHasBeenCheckedError.
        // A princípio, a ausência dessa linha não prejudicou formulas de grid.
        // FIXME: O problema é que comentar trouxe impacto para a visibilidade condicional (financeiro recorrencia/pago)
        // formCtrl.markAsDirty();
      }
      // Para forçar o change que recalculará eventuais formulas de grid (que dependem dessa lista carregada)
      this.onDataLoaded.emit(ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateValorTextoAndLstCadastroAdicional', error.message);
    }
  }


}
