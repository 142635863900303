import { EnClipType } from './../enum/en-clip-type';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[uiClipType]'
})
export class ClipTypeDirective implements OnInit {

  @Input('uiClipType') enClipType: EnClipType;

  private el: HTMLElement;

  constructor(
    elRef: ElementRef,
    private log: LogService,
  ) { this.el = elRef.nativeElement; }


  ngOnInit(): void {
    try {
      const className = this.enClipType;
      this.el.className = className;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }


}
