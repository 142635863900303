import { EnumFormulaElementType } from '../enum/enum-formula-element-type.enum';
import { Injectable } from '@angular/core';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
@Injectable()
export class ExpressionService {

  constructor(private global: GlobalService, private log: LogService) { }

  // tslint:disable: variable-name
  protected OCORRENCIA_expression: RegExp = /(OCORRENCIA\(\))/;
  protected MIN_expression: RegExp = /(MIN\(.+?\))/;
  protected MAX_expression: RegExp = /(MAX\(.+?\))/;
  // tslint:disable-next-line: max-line-length
  protected IF_expression: RegExp = /(IF\(.+?;.+?;.+?\))/; // /(IF\(.+?;(?=".*?").+?;(?=".*?").+?\))/;//desconsiderará o fechamento de parenteses se entre aspas
  protected PROC_expression: RegExp = /(PROC\(.+?;.+?\))/; // /(PROC\(.*?;.*?\))/; ///(PROC\(.*?\))(?!\".*?\")/;
  protected DateAddAlternative_expression: RegExp = /(DAT\&\&ADD\(\s*?".*?"\s*?;\s*?\-*\d*\s*;.*?\))/;
  protected DateAdd_expression: RegExp = /(DATEADD\(\s*?".*?"\s*?;\s*?\-*\d*\s*;.*?\))/;
  protected HourAdd_expression: RegExp = /(HOURADD\(.*;.*\))/;
  protected DayOfWeek_expression: RegExp = /(DAYOFWEEK\(.*;.*?\))/;
  protected WeekOfYear_expression: RegExp = /(WEEKOFYEAR\(.*\))/;
  protected DateDiffAlternative_expression: RegExp = /(DAT\&\&DIFF\(.+?;.+?;.+?\))/; // /(DAT\&\&DIFF\(.*?;.*?\))/;
  protected DateDiff_expression: RegExp = /(DATEDIFF\(.+?;.+?;.+?\))/; // /(DATEDIFF\(.*?;.*?\))/;
  protected YO_expression: RegExp = /(YO\(.*?\))/;
  protected Number_expression: RegExp = /([\d\.\,]+)/;
  protected Operator_expression: RegExp = /(\S)/;
  protected string_expression: RegExp = /(\".*?\")/;
  protected Formula_expression: RegExp = /(\#\d*\#)/;
  protected Parenthesis_expression: RegExp = /(\(.*?\))/;
  protected CountNotes_expression: RegExp = /(COUNTNOTES\(\))/;
  protected NoteChanged_expression: RegExp = /(NOTECHANGED\(\))/;
  protected FileChanged_expression: RegExp = /(FILECHANGED\(\))/;
  protected CountFiles_expression: RegExp = /(COUNTFILES\(\))/;
  protected CountStr_expression: RegExp = /(COUNTSTR\(.*;.*\))/;
  protected Left_expression: RegExp = /(LEFT\(.*;.*\))/;
  protected Right_expression: RegExp = /(RIGHT\(.*;.*\))/;
  protected Middle_expression: RegExp = /(MIDDLE\(.*;.*;.*\))/;
  protected Server_SumAlternative: RegExp = /(S\&\&RV\&\&R\.SUM\(.*?\))(?!\".*?\")/;
  protected Server_Sum: RegExp = /(SERVER\.SUM\(.*?\))(?!\".*?\")/;
  // tslint:disable: max-line-length
  protected Server_WsAlternative2: RegExp = /(S\&RV\&R\.WS\(.*?\)$)(?!\".*?\")/; // /S\&RV\&R\.WS\(.*?[\)\s]$/;///(S\&RV\&R\.WS\(.*?\))(?!\".*?\")/;//fica desta forma após o CorrigirDistorcaoFormulaDaSubstituicaoDeCaracteres
  protected Server_WsAlternative: RegExp = /(S\&\&RV\&\&R\.WS\(.*?\)$)(?!\".*?\")/; // /S\&\&RV\&\&R\.WS\(.*?[\)\s]$/;///(S\&\&RV\&\&R\.WS\(.*?\))(?!\".*?\")/;
  protected Server_Ws: RegExp = /(SERVER\.WS\(.*?\)$)(?!\".*?\")/; // /SERVER\.WS\(.*?[\)\s]$/;///(SERVER\.WS\(.*?\))(?!\".*?\")/;
  protected Server_GetValue: RegExp = /(SERVER\.GETVALUE\(.*?\)$)(?!\".*?\")/; // /SERVER\.WS\(.*?[\)\s]$/;///(SERVER\.WS\(.*?\))(?!\".*?\")/;
  protected Server_CountFilesFromUser: RegExp = /(SERVER\.COUNTFILES\.FROMUSER\(.*?\)$)(?!\".*?\")/; // /SERVER\.WS\(.*?[\)\s]$/;///(SERVER\.WS\(.*?\))(?!\".*?\")/;
  protected Server_CountNotesFromUser: RegExp = /(SERVER\.COUNTNOTES\.FROMUSER\(.*?\)$)(?!\".*?\")/; // /SERVER\.WS\(.*?[\)\s]$/;///(SERVER\.WS\(.*?\))(?!\".*?\")/;
  protected Server_GetIdentity: RegExp = /(SERVER\.GETIDENTITY\(.*?\)$)(?!\".*?\")/; // /SERVER\.WS\(.*?[\)\s]$/;///(SERVER\.WS\(.*?\))(?!\".*?\")/;
  protected Server_CheckExist: RegExp = /(SERVER\.CHECKEXIST\(.*?\)$)(?!\".*?\")/;
  protected Server_WsListAlternative2: RegExp = /(SERVER\.WS\.List\(.*?\))(?!\".*?\")/;
  protected Server_WsListAlternative: RegExp = /(S\&\&RVER\.WS\.List\(.*?\))(?!\".*?\")/;
  protected Server_WsList: RegExp = /(SERVER\.WS\.LIST\(.*?\))(?!\".*?\")/;
  protected SCRIPT_expression: RegExp = /(SCRIPT\[.*?\])/;
  protected SumHours_expression: RegExp = /(SUMHOURS\(.*?\))/;
  protected MinDate_expression: RegExp = /(MINDATE\(.*?\))/;
  protected MaxDate_expression: RegExp = /(MAXDATE\(.*?\))/;
  protected MinHour_expression: RegExp = /(MINHOUR\(.*?\))/;
  protected MaxHour_expression: RegExp = /(MAXHOUR\(.*?\))/;
  protected Overtime_expression: RegExp = /(OVERTIME\(.*?\))/;
  protected Absent_expression: RegExp = /(ABSENT\(.*?\))/;
  protected NightHour_expression: RegExp = /(NIGHTHOUR\(.*?\))/;
  protected LastDayOfMonth_expression: RegExp = /(LASTDAYOFMONTH\(.*?\))/;
  protected CountSelected_expression: RegExp = /(COUNTSELECTED\(.*?\))/;

  protected Grid_SumIf: RegExp = /(GRID\.SUMIF\(.*?\))(?!\".*?\")/;
  protected Grid_Sum: RegExp = /(GRID\.SUM\(.*?\))(?!\".*?\")/;
  protected Grid_Count: RegExp = /(GRID\.COUNT\(.*?\))(?!\".*?\")/;
  protected Grid_Mean: RegExp = /(GRID\.MEAN\(.*?\))(?!\".*?\")/;
  protected Grid_Product: RegExp = /(GRID\.PRODUCT\(.*?\))(?!\".*?\")/;
  protected Grid_Greater: RegExp = /(GRID\.GREATER\(.*?\))(?!\".*?\")/;
  protected Grid_Lower: RegExp = /(GRID\.LOWER\(.*?\))(?!\".*?\")/;
  protected Grid_Last: RegExp = /(GRID\.LAST\(.*?\))(?!\".*?\")/;
  protected Grid_First: RegExp = /(GRID\.FIRST\(.*?\))(?!\".*?\")/;

  protected Javascript_expression: RegExp = /(JAVASCRIPT\(.*?\))(?!\".*?\")/;
  protected Today_expression: RegExp = /(TODAY\(.*?\))(?!\".*?\")/;
  protected FormatDateTime_expression: RegExp = /(FORMATDATETIME\(.*?\))(?!\".*?\")/;


  /**
   * Retorna um Array com todas as expressões regulares de funções
   */
  getFullExpressionArray(): Array<RegExp> {
    // Atenção: Nesta nova lógica, os itens genéricos de números, strings,
    // parenteses, etc NÃO devem ser inclusos. Apenas a separação das funções serão testadas
    const _expressions: Array<RegExp> = [
      this.IF_expression,
      this.OCORRENCIA_expression,
      this.MIN_expression,
      this.MAX_expression,
      this.PROC_expression,
      this.DateAdd_expression,
      this.HourAdd_expression,
      this.DateAddAlternative_expression,
      this.DateDiff_expression,
      this.DateDiffAlternative_expression,
      this.DayOfWeek_expression,
      this.WeekOfYear_expression,
      this.YO_expression,
      this.SCRIPT_expression,
      this.SumHours_expression,
      this.MinDate_expression,
      this.MaxDate_expression,
      this.MinHour_expression,
      this.MaxHour_expression,
      this.Overtime_expression,
      this.Absent_expression,
      this.NightHour_expression,
      this.LastDayOfMonth_expression,
      this.CountSelected_expression,
      this.Javascript_expression,
      this.Today_expression,
      this.FormatDateTime_expression,
      this.CountNotes_expression,
      this.CountFiles_expression,
      this.NoteChanged_expression,
      this.FileChanged_expression,
      this.CountStr_expression,
      this.Left_expression,
      this.Right_expression,
      this.Middle_expression,
      this.Grid_SumIf,
      this.Grid_Sum,
      this.Grid_Count,
      this.Grid_Mean,
      this.Grid_Product,
      this.Grid_Greater,
      this.Grid_Lower,
      this.Grid_Last,
      this.Grid_First,
      // Grid Functions
      // Server and generic functions (all others need to be before)
      this.Server_Sum,
      this.Server_SumAlternative,
      this.Server_Ws,
      this.Server_WsAlternative,
      this.Server_WsList,
      this.Server_GetValue,
      this.Server_GetIdentity,
      this.Server_CheckExist,
      this.Server_WsListAlternative,
      this.Server_CountFilesFromUser,
      this.Server_CountNotesFromUser,
      this.Formula_expression];
    return _expressions;
  }

  getElementType(element: string): EnumFormulaElementType {
    try {
      element = this.corrigirDistorcaoFormulaDaSubstituicaoDeCaracteres(element);
      if (this.MIN_expression.test(element)) {
        return EnumFormulaElementType.MIN;
      } else if (this.MAX_expression.test(element)) {
        return EnumFormulaElementType.MAX;
      } else if (this.PROC_expression.test(element)) {
        return EnumFormulaElementType.PROC;
      } else if ((this.DateAdd_expression.test(element) === true) || (this.DateAddAlternative_expression.test(element) === true)) {
        return EnumFormulaElementType.DataAdd;
      } else if (this.DateDiff_expression.test(element) === true) {
        return EnumFormulaElementType.DataDiff;
      } else if (this.HourAdd_expression.test(element) === true) {
        return EnumFormulaElementType.HourAdd;
      } else if (this.DayOfWeek_expression.test(element) === true) {
        return EnumFormulaElementType.DayOfWeek;
      } else if (this.WeekOfYear_expression.test(element) === true) {
        return EnumFormulaElementType.WeekOfYear;
      } else if (this.YO_expression.test(element) === true) {
        return EnumFormulaElementType.YO;
      } else if (this.SCRIPT_expression.test(element) === true) {
        return EnumFormulaElementType.SCRIPT;
      } else if (this.SumHours_expression.test(element) === true) {
        return EnumFormulaElementType.SumHours;
      } else if (this.MinDate_expression.test(element) === true) {
        return EnumFormulaElementType.MinDate;
      } else if (this.MaxDate_expression.test(element) === true) {
        return EnumFormulaElementType.MaxDate;
      } else if (this.MinHour_expression.test(element) === true) {
        return EnumFormulaElementType.MinHour;
      } else if (this.MaxHour_expression.test(element) === true) {
        return EnumFormulaElementType.MaxHour;
      } else if (this.Overtime_expression.test(element) === true) {
        return EnumFormulaElementType.Overtime;
      } else if (this.Absent_expression.test(element) === true) {
        return EnumFormulaElementType.Absent;
      } else if (this.NightHour_expression.test(element) === true) {
        return EnumFormulaElementType.NightHour;
      } else if (this.LastDayOfMonth_expression.test(element) === true) {
        return EnumFormulaElementType.LastDayOfMonth;
      } else if (this.CountSelected_expression.test(element) === true) {
        return EnumFormulaElementType.CountSelected;
      } else if (this.Javascript_expression.test(element) === true) {
        return EnumFormulaElementType.Javascript;
      } else if (this.Today_expression.test(element) === true) {
        return EnumFormulaElementType.Today;
      } else if (this.FormatDateTime_expression.test(element) === true) {
        return EnumFormulaElementType.FormatDateTime;
      } else if (this.CountNotes_expression.test(element) === true) {
        return EnumFormulaElementType.CountNotes;
      } else if (this.CountFiles_expression.test(element) === true) {
        return EnumFormulaElementType.CountFiles;
      } else if (this.NoteChanged_expression.test(element) === true) {
        return EnumFormulaElementType.NoteChanged;
      } else if (this.FileChanged_expression.test(element) === true) {
        return EnumFormulaElementType.FileChanged;
      } else if (this.CountStr_expression.test(element) === true) {
        return EnumFormulaElementType.CountStr;
      } else if (this.Left_expression.test(element) === true) {
        return EnumFormulaElementType.Left;
      } else if (this.Right_expression.test(element) === true) {
        return EnumFormulaElementType.Right;
      } else if (this.Middle_expression.test(element) === true) {
        return EnumFormulaElementType.Middle;
        // GRID FUNCTIONS
      } else if (this.Grid_SumIf.test(element) === true) {
        return EnumFormulaElementType.GridSumIf;
      } else if (this.Grid_Sum.test(element) === true) {
        return EnumFormulaElementType.GridSum;
      } else if (this.Grid_Count.test(element) === true) {
        return EnumFormulaElementType.GridCount;
      } else if (this.Grid_Mean.test(element) === true) {
        return EnumFormulaElementType.GridMean;
      } else if (this.Grid_Product.test(element) === true) {
        return EnumFormulaElementType.GridProduct;
      } else if (this.Grid_Greater.test(element) === true) {
        return EnumFormulaElementType.GridGreater;
      } else if (this.Grid_Lower.test(element) === true) {
        return EnumFormulaElementType.GridLower;
      } else if (this.Grid_Last.test(element) === true) {
        return EnumFormulaElementType.GridLast;
      } else if (this.Grid_First.test(element) === true) {
        return EnumFormulaElementType.GridFirst;

      } else if (this.IF_expression.test(element)) { // O IF precisa ser testado depois, ou se antecipa ao GRID.SUMIF
        return EnumFormulaElementType.IF;
        // ATENÇÃO: OS ITENS MAIS GENÉRICOS TEM QUE FICAR NO FINAL, OU OS MENOS GENÉRICOS NÃO SERÃO RECONHECIDOS
      } else if ((this.Server_Sum.test(element) === true) || (this.Server_SumAlternative.test(element) === true)) {
        return EnumFormulaElementType.ServerSum;
      } else if ((this.Server_WsList.test(element) === true)
        || (this.Server_WsListAlternative.test(element) === true) || (this.Server_WsListAlternative2.test(element) === true)) {
        return EnumFormulaElementType.ServerWsList;
      } else if ((this.Server_Ws.test(element) === true) ||
        (this.Server_WsAlternative.test(element) === true) || (this.Server_WsAlternative2.test(element) === true)) {
        return EnumFormulaElementType.ServerWs;
      } else if (this.Server_GetValue.test(element) === true) {
        return EnumFormulaElementType.ServerGetValue;
      } else if (this.Server_GetIdentity.test(element) === true) {
        return EnumFormulaElementType.ServerGetIdentity;
      } else if (this.Server_CheckExist.test(element) === true) {
        return EnumFormulaElementType.ServerCheckExist;
      } else if (this.Server_CountFilesFromUser.test(element) === true) {
        return EnumFormulaElementType.ServerCountFilesFromUser;
      } else if (this.Server_CountNotesFromUser.test(element) === true) {
        return EnumFormulaElementType.ServerCountNotesFromUser;
      } else if (this.Number_expression.test(element) === true) {
        return EnumFormulaElementType.Number;
      } else if (this.string_expression.test(element) === true) {
        return EnumFormulaElementType.String;
      } else if (this.OCORRENCIA_expression.test(element) === true) {
        return EnumFormulaElementType.OCORRENCIA;
      } else if (this.Operator_expression.test(element) === true) {
        return EnumFormulaElementType.Operator;
      } else {
        return EnumFormulaElementType.None;
      }
    } catch (error) {
      this.log.Registrar('Expression', 'GetElementType', 'Error: ' + error.message);
    }
    return EnumFormulaElementType.None;
  }

  // A susbtituição dos caracteres devido a tentativa de resgatar as
  // fórmulas está trazendo algumas distorções como a troca de E por && e = por == por exemplo
  protected corrigirDistorcaoFormulaDaSubstituicaoDeCaracteres(formula: string): string {
    try {
      formula = this.global.ReplaceAll(formula, '&&', '&');
      formula = this.global.ReplaceAll(formula, '==', '=');
    } catch (error) {
      this.log.Registrar('Expression', 'CorrigirDistorcaoFormulaDaSubstituicaoDeCaracteres', 'Error: ' + error.message);
    }
    return formula;
  }

}
