import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFileUploadDialog } from '../../interface/IFileUploadDialog';
import { LogService, ConfigJsonService, IFileUploadResponse } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { EnFileUploadMode } from '../../enum/EnFileUploadMode';
import { IDocumento } from '@medlogic/shared/shared-interfaces';
import { error } from '../../service/error';
import { FileUploadService } from '../../service/file-upload.service';
import { tap } from 'rxjs/operators';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'lib-cmp-file-upload-selector',
  templateUrl: './cmp-file-upload-selector.component.html',
  styleUrls: ['./cmp-file-upload-selector.component.css']
})
export class CmpFileUploadSelectorComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() enFileUploadMode: EnFileUploadMode;
  @Input() data: IFileUploadDialog;
  @Input() acceptFiles = '.pdf, .xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .pps, .ppsx, .txt, .xml, .zip, .rar';

  @Output() afterFileUpload = new EventEmitter<IDocumento>();
  @Output() afterOpenWebcam = new EventEmitter<File>();

  selectedDocument: IDocumento;
  fileToUpload: File = null;
  isLoading = false;
  progress: number;

  constructor(
    private cnfJson: ConfigJsonService,
    private log: LogService,
    private glb: GlobalService,
    private fileUploadSrv: FileUploadService,
  ) {
    super();
  }

  ngOnInit() { }

  handleFileInput($event: any, data: IFileUploadDialog): void {
    try {
      if ($event && $event.target && $event.target.files) {
        this.fileToUpload = $event.target.files.item(0);

        if (this.fileToUpload.type.includes('image')) {
          this.resizeImage(this.fileToUpload, data, (resizedFile) => {
            // Chame a função uploadFileToActivity passando o arquivo redimensionado e o objeto data
            const url = URL.createObjectURL(resizedFile);
            this.fileToUpload = resizedFile;
            this.uploadFileToActivity(resizedFile, data);
          });
        } else
          this.uploadFileToActivity(this.fileToUpload, data);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'handleFileInput', error.message);
    }
  }

  resizeImage(file: File, data: IFileUploadDialog, callback: (resizedFile: File) => void): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.onload = () => {
        // Defina as dimensões desejadas para a imagem redimensionada
        const maxWidth = 800;
        const maxHeight = 600;

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        // Desenhe a imagem redimensionada no contexto do canvas
        ctx.drawImage(img, 0, 0, width, height);

        // Converta o canvas de volta para um Blob
        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], file.name, { type: 'image/jpeg' });
          callback(resizedFile);
        }, 'image/jpeg', file.size >= 100000 ? 0.3 : 0.7); // O último parâmetro é a qualidade da imagem (de 0 a 1)
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }
  uploadFileFromWebcam($event: any): void {
    try {
      const fileName = $event.fileName; // this.glb.removeDiacritics($event.fileName);
      this.enFileUploadMode = this.data.enFileUploadMode;
      this.fileToUpload = new File([$event.imageData], fileName, { type: 'image/png' });
      this.uploadFileToActivity($event, this.data);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'uploadFileFromWebcam', error.message);
    }
  }

  onWebcamClick($event: any): void {
    try {
      this.fileToUpload = null;
      this.enFileUploadMode = EnFileUploadMode.webcam;
      this.afterOpenWebcam.emit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onWebcam', error.message);
    }
  }

  onPreviewBack($event: any): void {
    try {
      this.selectedDocument = null;
      this.enFileUploadMode = EnFileUploadMode.list;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onPreviewBack', error.message);
    }
  }

  uploadFileToActivity($event: any, data: IFileUploadDialog): void {
    try {
      this.isLoading = true;
      const containerTag = `${this.cnfJson.project.toLowerCase()}`;
      const saveFileToStorage$ = this.fileUploadSrv.postFile(this.fileToUpload, data.ano, data.ono, data.vno, containerTag);
      // main pipe
      this.subs.sink = saveFileToStorage$
        .pipe(
          // this.saveFileReferenceToDB$(data),
          this.finalizeAccordingFileUploadMode$(data.enFileUploadMode),
          error()
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'uploadFileToActivity', error.message);
    }
  }

  protected finalizeAccordingFileUploadMode$ = (enFileUploadMode: EnFileUploadMode) =>
    tap((res: IFileUploadResponse) => {
      this.isLoading = false;
      this.afterFileUpload.emit(res?.documento);
    })

  // A Api node está se responsabilizando por salvar o container e a referência no GE.
  // protected saveFileReferenceToDB$ = (data: IFileUploadDialog) =>
  //   mergeMap((file: IFileUploadResponse | any) => {
  //     if (file?.status === 'progress') {
  //       this.progress = file?.msg;
  //     } else if (!file?.hasOwnProperty('ok') || file?.ok === true) {
  //       const oFile = this.glb.extractFileNameFromPath(file?.path);
  //       const caminhoFisicoSemArquivo = file?.path?.replace(oFile?.fileName, '');
  //       const nomeArquivoSemExtensao = oFile?.fileName?.replace(oFile?.extension, '').slice(0, -1);
  //       const extensao = `.${oFile?.extension}`;
  //       const tamanho = file?.size;
  //       const urlSemArquivo = caminhoFisicoSemArquivo;
  //       return this.fileUploadSrv
  //         .setDocument(data?.ono,
  //           data?.ano,
  //           data?.uno,
  //           caminhoFisicoSemArquivo,
  //           nomeArquivoSemExtensao,
  //           extensao,
  //           tamanho,
  //           urlSemArquivo
  //         ).pipe(map(documentId => ({ DocumentoNo: documentId, CaminhoFisico: file.path } as IDocumento)));
  //     }
  //     return of(null);
  //   })


}
