import { ExecucaoTarefaDAL } from '@medlogic/shared/geform';
import { routeGetRawMedication, IMedication, LocalLibService, routeGetHistoryMedication, ITherapeuticSafe, routeGetTherapeuticByNames, IForm, error } from '@medlogic/shared/shared-interfaces';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { map, mergeMap, Observable, of } from 'rxjs';
import { InterventionMedicationService } from './intervention-medication.service';
import { IInterventionMedication, LogService, GlobalService, EnRequestType } from '@medlogic/shared/shared-interfaces';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InterventionMedicationCustomService extends InterventionMedicationService {

  urlGetRawMedication = routeGetRawMedication;
  urlGetHistoryMedication = routeGetHistoryMedication;
  urlGetTherapeuticSafeByNames = `${routeGetTherapeuticByNames}?names={0}`;

  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    lib: LocalLibService,
    log: LogService,
    private basepage: BasePageService,
    private taskSrv: ExecucaoTarefaDAL
  ) {
    super(http, cadastroSrv, glb, lib, log);
  }

  /* Retorna todos os itens do histórico de compras através do id do item. */
  getByCodigoPaciente(ano: number, codigoPaciente: number, dtStart: Date = null, dtEnd: Date = null): Observable<IInterventionMedication> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_29828:${codigoPaciente}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCodigoPaciente', error.message);
    }
    return of(null);
  }

  /* Retorna todos os medicamentos relacionados a uma prescrição específica. */
  getByPrescription(ano: number, idPrescription: number, dtStart: Date = null, dtEnd: Date = null): Observable<IInterventionMedication> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_32935:${idPrescription}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByPrescription', error.message);
    }
    return of(null);
  }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IInterventionMedication> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  /* Carrega uma lista bruta de medicamentos. */
  getRawAll(): Observable<IMedication[]> {
    try {
      const urlGet = this.basepage.format(this.urlGetRawMedication);
      return this.basepage.baseDados<IMedication[]>(EnRequestType.Get, urlGet, {}, 3);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRawAll', error.message);
    }
    return of(null);
  }

  /* Carrega o histórico anonimizado de prescrições de medicamentos. */
  getHistoryAll(): Observable<IMedication[]> {
    try {
      const urlGet = this.basepage.format(this.urlGetHistoryMedication);
      return this.basepage.baseDados<IMedication[]>(EnRequestType.Get, urlGet, {}, 3);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getHistoryAll', error.message);
    }
    return of(null);
  }

  /* Traz a avaliação de adequabilidade terapêutica pelo nome dos medicamentos. */
  getTherapeuticSafeByNames(medicationNames: string): Observable<ITherapeuticSafe[]> {
    try {
      const urlGet = this.basepage.format(this.urlGetTherapeuticSafeByNames, medicationNames);
      return this.basepage.baseDados<ITherapeuticSafe[]>(EnRequestType.Get, urlGet, {}, 3);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getTherapeuticSafeByNames', error.message);
    }
    return of(null);
  }

  /* OVERRIDE
  * Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  save<T>(ano: number, interventionMedication: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(interventionMedication as unknown as IInterventionMedication).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo)
        .pipe(
          mergeMap(newId =>
            this.getTherapeuticSafeByNames(interventionMedication['medicamento'])
              .pipe(
                map(therapeutics => ({ color: therapeutics && therapeutics[0] && therapeutics[0].cor ? therapeutics[0].cor : null, newId }))
              )
          ),
          map(({ color, newId }) => {
            return ({ ...interventionMedication, codigo: newId, ocorrenciaNo: newId, cor: color } as T);
          }),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  deleteByOno(cno: number, ono: number): Observable<boolean> {
    try {
      return this.taskSrv.deleteCadastro(ono, cno);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteByOno', error.message);
    }
    return of(null);
  }

  isColor(medication: IMedication | any, color: string): boolean {
    try {
      return this.glb.isEqual((medication.cor || ''), color);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'isColor', error.message);
    }
    return false;
  }


}
