import { Component, OnInit, Input } from '@angular/core';
import { EnClipType } from '../enum/en-clip-type';

@Component({
  selector: 'ui-smart-list-badge-score',
  templateUrl: './smart-list-badge-score.component.html',
  styleUrls: ['./smart-list-badge-score.component.css']
})
export class SmartListBadgeScoreComponent implements OnInit {

  @Input() score: number;
  @Input() enClipType = EnClipType.circle;

  ENCLIPTYPE = EnClipType;
  
  constructor() { }

  ngOnInit() {
  }

}
