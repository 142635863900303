import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ICarePlanState } from './carePlan-state';
import * as fromCarePlan from './carePlan.reducer';

export const selectCarePlanState = createFeatureSelector<ICarePlanState>(fromCarePlan.carePlanFeatureKey);
export const selectCarePlanAll = createSelector(selectCarePlanState, fromCarePlan.selectAll);

// export const selectCarePlanAllActive = createSelector(
//   selectCarePlanAll,
//   carePlans => carePlans
//     .filter(carePlan => carePlan.status /*&& !carePlan.pacienteFalecido*/)
//     .sort((a, b) => a?.status?.localeCompare(b?.status))
// );

export const selectedCarePlan = createSelector(selectCarePlanState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const isCarePlanLoading = createSelector(
  selectCarePlanAll,
  carePlans => carePlans?.length <= 0,
);

export const selectedCarePlanIdentifier = createSelector(selectCarePlanState, state => {
  return (state?.selectedId || 0) > 0 ? state?.selectedId : null;
});
