import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';

@Injectable()
export class TasksService {
  urlGetTasksByData = 'Tasks/GetTasksByData?ocorrenciaNo={0}';

  constructor(private basepage: BasePageService, private log: LogService) { }

  getGetTasksByData(ocorrenciaNo: number) {
    try {
      const urlGet = this.basepage.format(this.urlGetTasksByData, ocorrenciaNo);
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getGetTasksByData', error.message);
    }
  }
}
