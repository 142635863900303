
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { MedicationService } from './medication.service';
import { GlobalService, IMedicationList, LocalLibService, LogService } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { of } from 'rxjs';

@Injectable()
export class MedicationCustomService extends MedicationService {

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnf: ConfigStateService,
    lib: LocalLibService,
    log: LogService) {
    super(cadastroSrv, glb, cnf, lib, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna todos os itens do histórico de compras através do id do item. */
  getByNomeMedicamento(ano: number, nomeMedicamento: number, dtStart: Date = null, dtEnd: Date = null): Observable<IMedicationList> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_30347:${nomeMedicamento}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByNomeMedicamento', error.message);
    }
    return of(null);
  }

}
