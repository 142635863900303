export interface IPasso {
  TarefaNo: number;
  OcorrenciaNo: number;
  OcorrenciaStatusNo: number;
  AtividadeNo: number;
  TarefaNome: string;
  TarefaComplemento1: string;
  TarefaComplemento2: string;
  UsuarioNo: number;
  PapelNo: number;
  DtInicio: string;
  enPassoTipo: number;
  EnAtribuicaoTipo: number;
  ParalelismoId: number;
  AgendamentoUsuarioNo: number;
  AgendamentoListaUsuarioPapelNo: any;
  blAtividadeContinua: boolean;
  CalcblDataTarefa: boolean;
  CalcblDestinatario: boolean;
  CalcblHoraTarefa: boolean;
  CalcVariavelIdentificacao1No: number;
  CalcVariavelIdentificacao2No: number;
  CalcVariavelIdentificacao3No: number;
  Versao: number;
  IsOpenFind: boolean;
  IsSaveRegister: boolean;
  blDataHoraTarefa: boolean;

  LstUsuarioPapel: Array<any>;
  CalcAtribuicaoDestinatarioTitulo: string;
  CalcAtribuicaoTitulo: string;
  LstPassos: Array<IPasso>;
  VariableIdentify: string;
  VariablesFind: string;
}
