import { IForm } from '@medlogic/shared/shared-interfaces';


export interface IPostDropMedicationStockRequest {
    ano: number;
    forms: [{
        id: number,
        form: IForm[]
    }]
}
