import { CadastroService } from "@medlogic/shared/shared-data-access";
import { IBusinessUnit, IForm, IServiceProvider, GlobalService, LocalLibService, LogService, error } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from "@medlogic/shared/state-config";
import { Observable, of } from "rxjs";
import { map, mergeMap, publishReplay, refCount, toArray } from "rxjs/operators";

export abstract class BusinessUnitService implements IServiceProvider {

  private lstVariaveis = 'V_4156,V_4157,V_4158';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

   recurrences: Array<IBusinessUnit> = new Array<IBusinessUnit>();

   codigoVariavelNo = -1; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
   cadastroNo = 2287;
   currentDtInicial = new Date();
   currentDtFinal = new Date();
   cadastrosCache: Observable<any>;

   constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected lib: LocalLibService,
    protected log: LogService) {
       try {
       } catch (error) {
           this.log.Registrar(this.constructor.name, 'constructor', error.message);
       }
   }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IBusinessUnit> {
      try {
        this.cadastroNo = ano;
        startDate = startDate || new Date(1900, 0, 1);
        endDate = endDate || new Date(2500, 0, 1);
        return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
      } catch (error) {
        this.log.Registrar(this.constructor.name, 'getSome', error.message);
      }
      return of(null);
  }

   getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IBusinessUnit> {
       try {
          this.cadastroNo = ano;
          startDate = startDate || new Date(1900, 0, 1);
          endDate = endDate || new Date(2500, 0, 1);
          return this.getWithCache(this.cadastroNo, startDate, endDate);
       } catch (error) {
           this.log.Registrar(this.constructor.name, 'getAll', error.message);
       }
       return of(null);
   }

   /* Método utilizado para popular uma lista com os itens ativos. */
   loadArray(ano: number): Observable<any> {
      try {
          this.cadastroNo = ano;
          const propLabel = 'titulo';
          const propValue = 'codigo';
          const propEnabled = 'habilitado';
          return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
      } catch (error) {
          this.log.Registrar(this.constructor.name, 'loadArray', error.message);
      }
   }

   /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
   clearCache(): void {
       try {
           this.cadastrosCache = null;
       } catch (error) {
           this.log.Registrar(this.constructor.name, 'clearCache', error.message);
       }
   }

   protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IBusinessUnit> {
      try {           if (
              (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
              || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
              || (!this.cadastrosCache)
               // (startDate.getTime() !== this.currentDtInicial.getTime())
              // || (endDate.getTime() !== this.currentDtFinal.getTime())
              // || (!this.cadastrosCache)
          ) {
              this.currentDtInicial = startDate;
              this.currentDtFinal = endDate;
              this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
          } else {
              console.log('retorno do cache');
          }
          return this.cadastrosCache;
       } catch (error) {
           this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
       }
       return of(null);
   }

   protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
       try {
           this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
           this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
           lstVariables = lstVariables || this.lstVariaveis;
           console.log('Recarregando dados...');
           // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
           return this.cadastroSrv
              .getCadastro(cadastroNo, lstVariables, startDate, endDate)
              .pipe(
                  this.mapTo(),
                  publishReplay(),
                  refCount()
              );
       } catch (error) {
           this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
       }
       return of(null);
   }

  protected mapTo = () => map((c: any) => {
       return {
          ocorrenciaNo: c.OcorrenciaNo, 
 sigla: c.V_4156,
 titulo: c.V_4157,
 habilitado: this.lib.getBoolean(c.V_4158),

       } as IBusinessUnit;
  })

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
  * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date = null, endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IBusinessUnit> {
      try {
          this.cadastroSrv.dtInicial = startDate ? this.glb.dateToYYYYMMddThhmmss(startDate) : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
          this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
          lstVariables = lstVariables || this.lstVariaveis;
          return this.cadastroSrv
            .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd, startDate, endDate)
            .pipe(
              this.mapTo(),
              publishReplay(),
              refCount()
            );
      } catch (error) {
         this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
      }
      return of(null);
  }

   /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save<T>(ano: number, businessUnit: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(businessUnit as unknown as IBusinessUnit).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo, true)
        .pipe(
          map(newId => ({ ...businessUnit, codigo: newId, ocorrenciaNo: newId } as T)),
          error()
        );

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

   protected mapToForm(businessUnit: IBusinessUnit): Array<IForm> {
       try {
           return [
              { VariavelNo: 4156, ValorDado: businessUnit.sigla || '' },
{ VariavelNo: 4157, ValorDado: businessUnit.titulo || '' },
{ VariavelNo: 4158, ValorDado: businessUnit.habilitado ? 'SIM' : 'NÃO' },

           ];
       } catch (error) {
           this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
       }
       return null;
   }

   /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
   insertIfNotExist<T>(ano: number, businessUnit: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], businessUnit[compareFieldName])) >= 0);
            if (!founded) {
              return this.save(ano, businessUnit as unknown as IBusinessUnit, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }
}

