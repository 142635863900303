import { EnUserRole } from '../../enum/en-user-role.enum';

export interface IValidateTokenResult {
  token: string;
  nome: string;
  email: string;
  crc: string;
  ocorrenciaNo: number;
  enUserRole: EnUserRole;
  exp: number;
  iat: number;
  msg?: string;
}
