export const prescriptionDosageUnity = [
    "%",
    "µg",
    "µg/mL",
    "cg/g",
    "cg/mL",
    "g",
    "g/10mL",
    "g/1mL",
    "g/25mL",
    "g/2mg",
    "g/5mL",
    "g/g",
    "g/mL",
    "mcg",
    "mcg/dose",
    "mcg/mL",
    "mg",
    "mg/1,1mL",
    "mg/10mL",
    "mg/1mL",
    "mg/2,5mL",
    "mg/30mL",
    "mg/5mL",
    "mg/dose",
    "mg/g",
    "mg/mL",
    "mL",
    "mL/mL",
    "mUI",
    "pcc",
    "U/g",
    "UI",
    "UI/g",
    "UI/mL",
    "UI/mLX4mL",
    "UTR/g",
    "X",
    "NA"
];
