import { ConfigStateService } from '@medlogic/shared/state-config';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { OcorrenciaDAL } from '../../shared/model/dal/ocorrencia-dal';
import { AtividadeDAL } from '../../shared/model/dal/atividade-dal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AtividadeComponenteDAL } from '../../shared/model/dal/atividade-componente-dal';
import { CalculadoraService } from '../../shared/service/calculadora.service';
import { DadoDAL } from '../../shared/model/dal/dado-dal';
import { CalculatorService } from '../../shared/service/calculator.service';
import { ValidatorService } from '../../shared/service/validator.service';
import { ActionService } from '../../shared/service/action.service';
import { CalculadoraConditionService } from '../../shared/service/calculadora-condition.service';
import { NavigationService } from '../../shared/service/navigation.service';
import { LibService } from '../../shared/service/lib.service';
import { ExecucaoTarefaDAL } from '../../shared/model/dal/execucao-tarefa-dal';
import { UsuarioDAL } from '@medlogic/shared/shared-data-access';
import { ProcessoDAL } from '../../shared/model/dal/processo-dal';
import { MatDialog } from '@angular/material/dialog';
import { AtividadeCadastroViewComponent } from '../../view/atividade-cadastro-view/atividade-cadastro-view.component';
import { WsTrackerService } from '@medlogic/shared/shared-data-access';
import { MsgPtBR, EnTheme } from '@medlogic/shared/shared-interfaces';
import { LogService, IAtividadeComponenteDAL, IOcorrencia, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { EnActivityType } from '@medlogic/shared/gecore';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { EnBubbleEvent } from '../../shared/enum/en-bubble-event.enum';
import { of } from 'rxjs';
import { IPasso } from '../../shared/interface/ipasso';
import { mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IBubble } from '../../shared/interface/ibubble';
import { TabComponent } from '../../view/partial/tab/tab.component';

/** Atividade criada para ser usada como um componente.
 * Os parâmetros são, portanto, passados pelo componente ao invés de na url.
 */
@Component({
  selector: 'lib-cmp-atividade',
  templateUrl: './cmp-atividade.component.html',
  styleUrls: ['./../../view/atividade-view/atividade-view.component.css']
  // changeDetection: ChangeDetectionStrategy.Default // TODO: Sem essa opção herdará onPush do app-addhere e afetará o update do blue-print e talvez de controles de fórmula
})
export class CmpAtividadeComponent extends AtividadeCadastroViewComponent implements OnInit {

  @Input() usuarioNo: number;
  @Input() token: string;
  @Input() processoNo = -1;
  @Input() atividadeNo: number;
  @Input() ocorrenciaNo: number;
  @Input() tarefaNo = -1;
  @Input() defaultFormControls: any;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;
  @Input() canShowSavedMessages = true;
  @Input() saveInList: boolean; // Determina se deve salvar o resultado no cadastro
  @Input() isReadOnly: boolean;
  @Input() printOnly: string[]; // Se preenchido, somente listará para impressão os documentos com o título especificado
  @Input() readOnlyExcept: number[]; // Se preenchido, todos os campos serão somente leitura exceto as variáveis listadas

  @Output() eventAfterCompleted = new EventEmitter<any>();
  @Output() afterSaved = new EventEmitter<any>();
  @Output() emitBack = new EventEmitter<any>();
  @Output() emitErpRefresh = new EventEmitter<any>();
  @Output() eventBubble = new EventEmitter<IBubble>();

  @ViewChild(TabComponent, { static: true }) viewTabComponent;


  constructor(
    global: GlobalService,
    config: ConfigStateService,
    cnfJson: ConfigJsonService,
    route: ActivatedRoute,
    log: LogService,
    ocorrenciaDAL: OcorrenciaDAL,
    atividadeDAL: AtividadeDAL,
    fb: FormBuilder,
    atividadeComponenteDAL: AtividadeComponenteDAL,
    calc: CalculadoraService,
    dadoDAL: DadoDAL,
    calculator: CalculatorService,
    validator: ValidatorService,
    action: ActionService,
    calcCond: CalculadoraConditionService,
    navigation: NavigationService,
    lib: LibService,
    confirmationService: ConfirmationService,
    wsTracker: WsTrackerService,
    execucaoTarefa: ExecucaoTarefaDAL,
    usuarioDAL: UsuarioDAL,
    processoDAL: ProcessoDAL,
    matDialog: MatDialog,
    msg: MsgPtBR,
    messageService: MessageService,
  ) {
    super(
      global,
      config,
      cnfJson,
      route,
      log,
      ocorrenciaDAL,
      atividadeDAL,
      fb,
      atividadeComponenteDAL,
      calc,
      dadoDAL,
      calculator,
      validator,
      action,
      calcCond,
      navigation,
      lib,
      confirmationService,
      wsTracker,
      execucaoTarefa,
      usuarioDAL,
      processoDAL,
      matDialog,
      msg,
      messageService,
    );
    this.activityType = EnActivityType.List;
  }

  /* override  */
  ngOnInit(): void {
    super.ngOnInit();
  } catch(error: { message: string; }) {
    this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
  }

  // override
  /* Extrai os parâmetros de Input, ao invés de por meio de rota. */
  getFromInput(): void {
    try {
      if (this.usuarioNo) {
        this.config.usuarioLogadoNo = +this.usuarioNo;
      }
      if (this.token) {
        this.config.baseUsuarioToken = this.token;
      }
      super.getFromInput();
      // const ano = +this.atividadeNo;
      // const pno = this.processoNo && +this.processoNo > 0 ? +this.processoNo : -1;
      // const ono = +this.ocorrenciaNo;
      // const isReadOnly = this.readOnly;
      // const saveInList = this.saveInList;
      // const cleanPrevState = this.activityType !== EnActivityType.ListDetail;
      // this.config.fillActivityParams(
      //   ano,
      //   ono,
      //   null,
      //   isReadOnly,
      //   saveInList,
      //   cleanPrevState
      // );
      // this.subs.sink = this.refresh(
      //   EnumAtividadeTipo.Editar,
      //   this.config.ModeloAtividadeNo,
      //   this.config.OcorrenciaNo.value,
      //   this.config.usuarioLogadoNo,
      //   this.defaultFormControls || this.config.getDefaultFormControls(this.config.ModeloAtividadeNo),
      //   false,
      //   this.config.processoNo
      // ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromInput', error.message);
    }
  }

  /* override
  /* Busca dados do serviço e preenche a propriedade componentes e também a tabs. */
  // protected fillControls(
  //   atividadeTipo: EnumAtividadeTipo,
  //   atividadeNo: number,
  //   ocorrenciaNo: number,
  //   usuarioLogadoNo: number,
  //   defaultFormControls: any,
  //   processoNo?: number,
  //   tarefaNo?: number
  // ): Observable<IAtividadeComponenteDAL[]> {
  //   try {
  //     return super.fillControls(atividadeTipo, atividadeNo, ocorrenciaNo, usuarioLogadoNo, defaultFormControls, processoNo, tarefaNo)
  //       .pipe(
  //         tap((c: IAtividadeComponenteDAL[]) => {
  //           this.atividade.ShowAttachment = false;
  //           this.atividade.ShowSaveAndNew = false;
  //           this.atividade.ShowSave = false;
  //           this.atividade.ShowDelete = false;
  //           this.atividade.ShowDuplicate = false;
  //           this.atividade.ShowComplete = true;
  //         })
  //       );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'fillControls', error.message);
  //   }
  // }

  /* override
  * Evento chamado após a o salvamento da atividade. Poderá ser sobrescrito.
  */
  protected afterSave(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    fg: FormGroup,
    isEditMode: boolean
  ): Observable<IOcorrencia> {
    try {
      // Chamaria doActivityComplete duas vezes
      // const ifCompleted = () => mergeMap((o: IOcorrencia) => {
      //   this.isLoading = false;
      //   return !this.formGroup.invalid && (o && o.OcorrenciaNo > 0) ?
      //     this.doActivityComplete(tno, ano, ono, uno, pno) :
      //     of(o);
      // });
      const error = () => catchError((err, obs) => {
        console.log(err);
        this.isLoading.next(false);
        return of(err);
      });
      return super.afterSave(enBubbleEvent, tno, ano, ono, uno, pno, componentes, fg, isEditMode)
        .pipe(
          // ifCompleted(),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave', error.message);
    }
    return of(null);
  }

  /* override.
  * Realiza a conclusão da Atividade.
  * Nesse componente, deverá emitir um evento a ser capturado por quem utilizar esse componente. */
  protected doActivityComplete(tno: number, ano: number, ono: number, uno: number, pno: number): Observable<{ passo: IPasso, ocorrencia: IOcorrencia }> {
    try {
      this.isLoadingAfterComplete = false;
      this.isLoading.next(false);
      return this.ocorrenciaDAL
        .setOcorrenciaNotificarConclusaoSalvamento(pno, ono, uno, ano)
        .pipe(
          mergeMap((result) => {
            // TODO: checar porque Variaveis de identificação não estão sendo preenchidas
            const varId1 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao1No}`);
            const varId2 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao2No}`);
            this.atividade.CalcVariavelIdentificacao1Valor = varId1 ? varId1.value : -1;
            this.atividade.CalcVariavelIdentificacao2Valor = varId2 ? varId2.value : -1;
            this.atividade.OcorrenciaNo = ono;
            this.atividade.TarefaNo = tno;
            return this.processoDAL
              .getProximoPasso(this.atividade, pno, uno, tno, ono)
              .pipe(
                map((passo) => {
                  // FIXME: Idealmente, essa deveria ser uma chamada assíncrona, retornando também observable
                  // this.executeNextStep(this.atividade, proximoPasso);
                  this.isLoadingAfterComplete = false;
                  // Já será chamado no afterComplete
                  // this.emitAfterCompleted(tno, ano, ono, uno, pno);
                  const ocorrencia = {
                    AtividadeNo: ano,
                    OcorrenciaNo: ono,
                    TarefaNo: tno,
                    ProcessoNo: pno,
                    Componentes: this.componentes,
                    fg: this.formGroup,
                    isEditMode: this.isEditMode
                  } as IOcorrencia;
                  return { passo, ocorrencia };
                })
              );
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doActivityComplete', error.message);
    }
    return of(null);
  }


}
