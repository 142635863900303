import { IQueueState } from '@medlogic/shared/shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromQueue from './queue.reducer';

export const selectQueueState = createFeatureSelector<IQueueState>(fromQueue.queuesFeatureKey);
export const selectQueueAll = createSelector(
 //selectQueueState,
  fromQueue.selectAll,
  (queues) => {
    return queues?.length > 0 ? queues : undefined;
  }
);


// export const selectQueueAll = (state: IQueueState) => state?.ids?.length > 0 ? ([... state?.ids].map((id) => state?.entities[id])) : undefined;

export const isQueueEmpty = createSelector(
  selectQueueAll,
  queues => queues?.length <= 0,
);
export const selectQueueCount = createSelector(
  selectQueueAll,
  queues => {
   return queues?.length || 0
  }
);

export const selectedQueue = createSelector(selectQueueState, state => {
  if (state?.ids?.length <= 0) {
    return null;
  }
  const lastId = state?.ids[state?.ids?.length - 1];
  return lastId ? state?.entities[lastId] : null;
});
