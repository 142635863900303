import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'lib-bottom-sheet-confirm',
  templateUrl: './bottom-sheet-confirm.component.html',
  styleUrls: ['./bottom-sheet-confirm.component.css']
})
export class BottomSheetConfirmComponent implements OnInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<any>) { }

  onConfirm(event: MouseEvent): void {
    this._bottomSheetRef.dismiss(true);
    event.preventDefault();
  }

  onCancel(event: MouseEvent): void {
    this._bottomSheetRef.dismiss(false);
    event.preventDefault();
  }

  ngOnInit() {
  }

}
