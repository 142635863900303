
import { publishReplay, map, refCount } from 'rxjs/operators';
import { IMedicationCheckin } from '@medlogic/shared/shared-interfaces';
import { HttpClient } from '@angular/common/http';
import { IFlashObj } from '../interfaces/iflash-obj';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../pwa/service/config-pwa-medlogic.custom.service';

import * as moment from 'moment';

@Injectable()
export class CheckMedicamentos {

  private lstVariaveis = 'V_30264,V_30273,V_30274,V_30275,V_30276,V_30330,V_30331,V_30351,V_30352,V_30353,V_30401,V_31927,V_34166';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IMedicationCheckin[] = new Array<IMedicationCheckin>();

  cadastroNo = -1;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigPwaMedLogicService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  getAll(cadastroNo: number, startDate?: Date, endDate?: Date): Observable<IMedicationCheckin> {
    try {
      this.cadastroNo = cadastroNo;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getFromCadastro(cadastroNo, startDate, endDate);
      // return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(cadastroNo: number): Observable<any> {
    try {
      this.cadastroNo = cadastroNo;
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(this.cadastroNo), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IMedicationCheckin> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  getFromCadastroFiltro(cadastroNo: number, filtro: string, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true).pipe(
          map(c => this.toAtribute(c)),
          publishReplay(),
          refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(c => this.toAtribute(c)),
          publishReplay(),
          refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  toAtribute(c) {
    try {
      const item = {
        cascataCheckIDClienteIDMedicamentoDataAtual: c.V_30264,
        horaPrescricao: c.V_30273,
        status: c.V_30274,
        observacoes: c.V_30275,
        statusmedicacao: c.V_30276,
        dataCheckin: this.glb.ddMMYYYYThhmmssToDate(c.V_30330),
        diaPrescricao: c.V_30331,
        codigo: c.V_30351,
        identificacao: c.V_30264,
        titulo: c.V_30352,
        habilitado: c.V_30353.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_30353) ? true : false,
        codigoMedicamentoPaciente: c.V_30401,
        profissional: c.V_31927,
        horaCheckin: c.V_34166
      } as IMedicationCheckin;
      return item;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAtribute', error.message);
      return null;
    }
  }

  protected getFiltered(cadastroNo: number, startDate: Date, endDate: Date): Observable<IMedicationCheckin> {
    try {
      return this.getWithCache(cadastroNo, startDate, endDate);
      // .filter(f => !f.isRecurrent)
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(ano: number, checkMedicamentos: IMedicationCheckin, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const uno = this.cnf.usuarioLogadoNo;
      const forms: IForm[] = this.mapToForm(checkMedicamentos).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, ano, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(checkMedicamentos: IMedicationCheckin): IForm[] {
    try {
      return [
        { VariavelNo: 30264, ValorDado: checkMedicamentos.cascataCheckIDClienteIDMedicamentoDataAtual || '' },
        { VariavelNo: 30273, ValorDado: checkMedicamentos.horaPrescricao || '' },
        { VariavelNo: 30274, ValorDado: checkMedicamentos.status },
        { VariavelNo: 30275, ValorDado: checkMedicamentos.observacoes || '' },
        { VariavelNo: 30276, ValorDado: checkMedicamentos.statusmedicacao || '' },
        { VariavelNo: 30330, ValorDado: this.glb.ddMMYYYYThhmmssToDate(checkMedicamentos.dataCheckin) },
        { VariavelNo: 30331, ValorDado: checkMedicamentos.diaPrescricao || '' },
        { VariavelNo: 30351, ValorDado: checkMedicamentos.codigo || '' },
        { VariavelNo: 30264, ValorDado: checkMedicamentos.identificacao || '' },
        { VariavelNo: 30352, ValorDado: checkMedicamentos.titulo || '' },
        { VariavelNo: 30353, ValorDado: checkMedicamentos.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 30401, ValorDado: checkMedicamentos.codigoMedicamentoPaciente },
        { VariavelNo: 31927, ValorDado: checkMedicamentos.profissional },
        { VariavelNo: 34166, ValorDado: checkMedicamentos.horaCheckin }
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  convertFlashObj(checkMedicamentos: IMedicationCheckin): IFlashObj[] {
    try {
      const flashObj = [
        { variavelNo: 30264, valor: checkMedicamentos.cascataCheckIDClienteIDMedicamentoDataAtual || '' },
        { variavelNo: 30273, valor: checkMedicamentos.horaPrescricao || '' },
        { variavelNo: 30274, valor: checkMedicamentos.status },
        { variavelNo: 30275, valor: checkMedicamentos.observacoes || '' },
        { variavelNo: 30276, valor: checkMedicamentos.statusmedicacao || '' },
        { variavelNo: 30330, valor: moment(checkMedicamentos.dataCheckin).format('DD/MM/YYYY') },
        { variavelNo: 30331, valor: checkMedicamentos.diaPrescricao || '' },
        { variavelNo: 30351, valor: checkMedicamentos.codigo || '' },
        { variavelNo: 30264, valor: checkMedicamentos.identificacao || '' },
        { variavelNo: 30352, valor: checkMedicamentos.titulo || '' },
        { variavelNo: 30353, valor: checkMedicamentos.habilitado ? 'SIM' : 'NÃO' },
        { variavelNo: 30401, valor: checkMedicamentos.codigoMedicamentoPaciente },
        { VariavelNo: 31927, ValorDado: checkMedicamentos.profissional },
        { VariavelNo: 34166, ValorDado: checkMedicamentos.horaCheckin }
      ] as IFlashObj[];
      return flashObj;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertFlashObj', error.message);
    }
  }

  convertIForm(entry: IMedicationCheckin): IForm[] {
    try {
      const form = this.convertFlashObj(entry)
        .map(m => ({
          VariavelNo: m.variavelNo,
          ValorDado: m.valor || ''
        } as IForm));
      return form;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertIForm', error.message);
    }
  }


}
