import { IListItem, IGeneric } from '@medlogic/shared/shared-interfaces';
import { IGenericState } from '@medlogic/shared/shared-interfaces';
import { createSelector } from '@ngrx/store';


export const selectAllGenerics = (state: IGenericState) => state?.allGenerics ? state.allGenerics : undefined;
export const selectedGeneric = (state: IGenericState) => state;

export const selectGenericById = (id: number) => createSelector(
  selectAllGenerics,
  allGenerics => {
    if (allGenerics && id) {
      return allGenerics.find(f => f.id && +f.id === +id);
    }
    return null;
  });

// Para que se faça o preenchimento de propriedades padrão
export const selectDefaultFormControl = createSelector(
  selectedGeneric,
  selected => {
    return selected.selectedGeneric ? Object
      .keys(selected.identification)
      .reduce((obj, key) => {
        obj[getId(selected.identification[key])] = selected.selectedGeneric[key];
        return obj;
      }, {}) : null;
  }
);

function getId(vno: any): string {
  return isNaN(vno) ? null : `V_${vno}`;
}

export const selectAllGenericsAsListItems = createSelector(
  selectAllGenerics,
  (allGenerics) => {
    if (allGenerics) {
      return allGenerics;
      // .map(m => (
      //   {
      //     id: m.codigo,
      //     topLeft: m.nomeEmpresa,
      //     bottomLeft: m.conta,
      //     topRight: m.bENEFICIARIO,
      //     bottomRight: m.dataAberturaConta,
      //     color: EnColor.green
      //   } as IListItem
      // ));
    }
    return undefined;
  });

export const selectAllGenericsAsListItemsByKeyword = createSelector(
  selectAllGenericsAsListItems,
  (allItems: IListItem<IGeneric>[], props: { keyword: string }) => {
    if (allItems === undefined) { return undefined; }

    if (allItems?.length > 0) {
      return allItems.filter(f =>
        (props?.keyword || '') === '' ||
        f.topLeft?.includes(props?.keyword) ||
        f.topRight?.includes(props?.keyword) ||
        f.bottomLeft?.includes(props?.keyword) ||
        f.bottomRight?.includes(props?.keyword) ||
        f.ono?.toString().includes(props?.keyword)
      ).sort((a, b) => a && b && a.ono && b.ono ? Number(a.ono) - Number(b.ono) : 0);
    } else {
      return [];
    }
  });
