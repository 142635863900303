import { EmergencyDataComponent } from './pwa/view/emergency-data/emergency-data.component';
import { CardPatientComponent } from './pwa/view/card-patient/card-patient.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LongitudinalHistoryComponent } from './pwa/view/longitudinal-history/longitudinal-history.component';
import { CardMedicationComponent } from './pwa/view/card-medication/card-medication.component';
import { PatientTimelineComponent } from './pwa/view/patient-timeline/patient-timeline.component';
import { VitalSignComponent } from './pwa/view/vital-sign/vital-sign.component';
import { EvolutionComponent } from './pwa/view/evolution/evolution.component';
import { AuthChildGuardService } from '@medlogic/shared/gecore';
import { PageNotFoundViewComponent } from '@medlogic/shared/geform';

const appRoutes: Routes = [
  {
    path: 'assist',
    canActivateChild: [AuthChildGuardService],
    children: [
      // History
      { path: 'history/:key/:patientCod/:patientName/:date', component: LongitudinalHistoryComponent }, // Histórico Longitudinal
      // Mapa de Cuidado
      { path: 'medication/:cadCheckMedicamentosNo', component: CardMedicationComponent },
      { path: 'carecard/:modeloConfigNo', component: CardPatientComponent },
      { path: 'carecard', component: CardPatientComponent },
      { path: 'caretimeline', component: PatientTimelineComponent },
      { path: 'vitalsign/:key', component: VitalSignComponent },
      { path: 'evolution/:key', component: EvolutionComponent },
      { path: 'emergencydata/:key', component: EmergencyDataComponent },
      // Redirect
      { path: '', redirectTo: 'login', pathMatch: 'prefix' },
      { path: '**', component: PageNotFoundViewComponent },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppAssistRoutingModule { }
