<ng-container *ngrxLet="activityMode$ as activityMode">
  <div class="header-container">

    <!-- Linha 1 -->
    <div class="flex">
      <button mat-icon-button type="button" (click)="onMenuItemClick('back')">
        <mat-icon>arrow_back</mat-icon>
        Voltar
      </button>

      <div class="v-divider"></div>
    </div>
    <ng-container *ngrxLet="scheduledActivities$ as scheduledActivities">
      <div class="flex">
        <button mat-icon-button
          [matBadge]="scheduledActivities" matBadgePosition="below" matBadgeColor="warn"
          type="button" [disabled]="activityMode == ENACTIVITYMODE.CarePlan"
            (click)="onMenuItemClick(ENACTIVITYMODE.CarePlan)">
            <mat-icon svgIcon="rotina"></mat-icon>
            Programadas
          </button>

          <div class="v-divider"></div>
      </div>
    </ng-container>

    <div class="flex">
      <ml-ui-care-plan-avatar></ml-ui-care-plan-avatar>

      <div class="v-divider"></div>
    </div>

    <!-- Material Button with Business List alt Icon -->
    <ng-container *ngrxLet="extraActivities$ as extraActivities">
      <div class="flex">
        <button mat-icon-button
          [matBadge]="extraActivities" matBadgePosition="below"
          type="button" [disabled]="activityMode == ENACTIVITYMODE.Extra"
            (click)="onMenuItemClick(ENACTIVITYMODE.Extra)">
            <mat-icon svgIcon="nao-programado"></mat-icon>
            Não Programadas
          </button>

          <div class="v-divider"></div>
      </div>
    </ng-container>

    <ml-ui-care-plan-timer></ml-ui-care-plan-timer>
    <!-- FIM Linha 1 -->

    <!-- Linha 2 -->
    <div class="top"></div>

    <!-- Linha 3 -->
    <ml-ui-care-plan-shift-bar></ml-ui-care-plan-shift-bar>

    <!-- Linha 4 -->
    <div class="divider-container">
      <div class="divider"></div>
    </div>

  </div>
</ng-container>
