import { ConfigStateService } from '@medlogic/shared/state-config';
import { FormGroup } from '@angular/forms';
import { InputMaskType } from '../../enum/input-mask-type';
import { CalculadoraService } from '../../service/calculadora.service';
import { AutoCalcControl } from '../../model/auto-calc-control';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';
// Referência sobre o componente de máscara
// https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#pipe

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-textbox-label',
  templateUrl: './ctr-textbox-label.component.html',
  styleUrls: ['./ctr-textbox-label.component.scss']
})
export class CtrTextBoxLabelComponent extends AutoCalcControl implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  // tslint:disable-next-line: variable-name
  private _isMasked = false;
  public get isMasked(): boolean {
    return this._isMasked;
  }
  public set isMasked(v: boolean) {
    this._isMasked = v;
  }

  ENTHEME = EnTheme;

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR
  ) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }

  ngOnInit(): void {
    try {
      this.setIsMasked(this.ctrl);
      const id = this.lib.getId(this.ctrl.VariavelNo);
      // this.subscribeToMaskSanitize(this.ctrl, this.formGroup);
      // const c = this.formGroup.get(this.getId(this.ctrl));
      // c.valueChanges.subscribe((changed) => {
      // 	this.stringfyIfIsMasked(this.ctrl, this.formGroup);
      // });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /*As máscaras do ngInput requerem texto ou número como entrada. Esse método reconfigura os valores conforme a máscara.
   * FIXME: Esse método está gerando recursão infinida, pois é chamado em valuechanges e redefine o valor.
   * o emitEvent: false evita a recursão, mas impede a edição do item que é sempre alterado para o valor anterior.
   */
  // protected stringfyIfIsMasked(ctrl: IAtividadeComponenteDAL, formGroup: FormGroup): void {
  // 	try {
  // 		if (this.isMasked && !this.global.IsNullOrEmptyGE(ctrl?.ValorTexto)) {
  // 			const enMask = InputMaskType.toEnum(ctrl?.InputMaskType);
  // 			const c = formGroup.get(this.getId(ctrl));
  // 			const typed = this.global.getTypedValue(ctrl?.ValorTexto);
  // 			switch (enMask) {
  // 				case InputMaskType.Date:
  // 				case InputMaskType.DateTime:
  // 					c.setValue(typed.string, { onlySelf: true, emitEvent: false });
  // 					break;
  // 				case InputMaskType.Integer:
  // 					c.setValue(typed.value, { onlySelf: true, emitEvent: false });
  // 					break;
  // 			}
  // 		}
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'stringfyIfIsMasked', error.message);
  // 	}
  // }

  /*Necessário para que o valor a ser armazenado desconsidere as máscaras
   * ATENÇÃO: Observar se essa mudança não dispara o change novamente. */
  // protected subscribeToMaskSanitize(ctrl: IAtividadeComponenteDAL, formGroup: FormGroup): void {
  //   try {
  //     if (this.isMasked) {
  //       this.subs.sink = formGroup?.valueChanges?.subscribe((obj) => {
  //         try {
  //           // let value = obj[this.getId(ctrl)];
  //           // TODO: Tentativa de modificação, pois, campos calculados podem não ter o valor preenchido no obj, mas o terão no ctrl?.Valor
  //           const value = ctrl?.Valor;
  //           // Verifica se é um número para então remover a pontuação de milhar e vírgula se não tiver casas decimais
  //           const enMask = InputMaskType.toEnum(ctrl?.InputMaskType);
  //           const typed = this.global.getTypedValue(value, 'pt-BR');
  //           switch (enMask) { // A ausência de break ou return fará com que todas as linhas sejam executadas
  //             // Todos receberão dados em inglês e precisam ser convertidos para português
  //             case InputMaskType.Percent:
  //             case InputMaskType.Percent2:
  //             case InputMaskType.Real:
  //             case InputMaskType.Numeric:
  //             case InputMaskType.Numeric2:
  //             case InputMaskType.Numeric3:
  //             case InputMaskType.Numeric4:
  //             case InputMaskType.Integer:
  //               // let newValue = value;
  //               // if (value) {
  //               //   value.toString().replace(/\./, '');
  //               //   if (this.global.Right(value.toString(), 1) === ',') {
  //               //     newValue = newValue.toString().replace(',', '');
  //               //   } else {
  //               //     newValue = newValue.toString().replace(',', '.');
  //               //   }
  //               // }
  //               obj[this.getId(ctrl)] = typed?.value;
  //               break;
  //             case InputMaskType.Date:
  //               obj[this.getId(ctrl)] = this.global.DateToYYYYMMdd(typed?.value);
  //               break;
  //             default:
  //               // Remove tudo que não for número ou , ou . ou + ou -
  //               obj[this.getId(ctrl)] = value ? value.toString().replace(/[^\,|.|\d|\+|\-]/g, '') : '';
  //               break;
  //           }
  //           // FIXME: Aparentemente, sem o acréscimo dessa linha, esse método faz nada, pois o valor de obj não estão definindo o valor do form group. 
  //           // E com essa linha dá loop infinito, pois o setValue promove change.
  //           // this.setValue(obj[this.getId(ctrl)]);
  //         } catch (error) {
  //           this.log.Registrar(
  //             this.constructor.name,
  //             'subscribeToMaskSanitize.valueChanges',
  //             error.message
  //           );
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'subscribeToMaskSanitize', error.message);
  //   }
  // }

  protected applyDefaultMasksRules(): void {
    try {
      // if (this.global.IsNullOrEmptyGE(ctrl?.ValorTexto)) {
      //   switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
      //     case InputMaskType.Date:
      //       ctrl?.ValorTexto = this.currentValue = this.global.DateToddMMYYYY(new Date);
      //       break;
      //     case InputMaskType.DateTime:
      //       ctrl?.ValorTexto = this.currentValue = this.global.FormatDateHour(new Date, "dd/mm/yyyy hh:mm");
      //       break;
      //   }
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyDefaultMasksRules', error.message);
    }
  }

  /*Verifica se há uma configuração de máscara para o controle no GE. */
  protected setIsMasked(ctrl: IAtividadeComponenteDAL): void {
    try {
      this.isMasked = this.getMask(ctrl) !== null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isMasked', error.message);
    }
  }

  getMask(ctrl: IAtividadeComponenteDAL): string {
    try {
      return InputMaskType.getNgxMask(ctrl?.InputMaskType, 2, ctrl?.MaxLength);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMask', error.message);
    }
    return '';
  }

  /* Os campos de data deverão manter os caracteres. */
  dropChars(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return ![
        // todos os tipos que puderem ser iniciado por zero precisam manter os chars.
        InputMaskType.Date,
        InputMaskType.DateTime,
        InputMaskType.CPF,
        InputMaskType.CNPJ,
        InputMaskType.TimeHHHMM,
        InputMaskType.TimeHHMM,
        InputMaskType.TimeHHMMSS,
        InputMaskType.CEP,
        InputMaskType.Password,
        InputMaskType.PhoneWithDDD,
        InputMaskType.PhoneWithDDDInternational,
        InputMaskType.PhoneWithDDDNoPrefix,
        InputMaskType.PhoneWithDDDNoPrefixInternational,
      ].includes(InputMaskType.toEnum(ctrl?.InputMaskType));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'dropChars', error.message);
    }
    return false;
  }

  /* Retorna a máscara no padrão do ngInput. */
  // getMask(ctrl: IAtividadeComponenteDAL): any {
  //   try {
  //     const mask = InputMaskType.getMask(ctrl?.InputMaskType, ctrl?.MaxLength);
  //     if (mask) {
  //       const enMask = InputMaskType.toEnum(ctrl?.InputMaskType);
  //       let pipe: any;
  //       switch (enMask) { // A ausência de break ou return fará com que todas as linhas sejam executadas
  //         case InputMaskType.DateTime:
  //           pipe = InputMaskType.getDatePipe('dd/mm/yyyy hh:MM');
  //           break;
  //         case InputMaskType.Date:
  //           pipe = InputMaskType.getDatePipe();
  //           break;
  //         // Todos receberão dados em inglês e precisam ser convertidos para português
  //         case InputMaskType.Percent:
  //         case InputMaskType.Percent2:
  //         case InputMaskType.Real:
  //         case InputMaskType.Numeric:
  //         case InputMaskType.Numeric2:
  //         case InputMaskType.Numeric3:
  //         case InputMaskType.Numeric4:
  //           pipe = InputMaskType.getNumberEnToPtBr('pt-br');
  //           break;
  //         default:
  //           return { mask };
  //       }
  //       return {
  //         mask,
  //         pipe
  //       };
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getMask', error.message);
  //   }
  //   return null;
  // }

  /*Retorna o tipo de input conforme a máscara configurada no controle. */
  getInputType(ctrl: IAtividadeComponenteDAL, isMasked: boolean = false): string {
    try {
      // ngxMask depende do type text.
      // if (isMasked && InputMaskType.toEnum(ctrl?.InputMaskType) !== InputMaskType.Password) {
      if (isMasked) {
        return 'text';
      }
      switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
        case InputMaskType.DateTime:
          return 'datetime-local';
        case InputMaskType.Date:
          return 'date';
        case InputMaskType.TimeHHHMM:
        case InputMaskType.TimeHHMM:
        case InputMaskType.TimeHHMMSS:
          return 'time';
        case InputMaskType.Email:
          return 'email';
        // Todos receberão dados em inglês e precisam ser convertidos para português
        case InputMaskType.Integer:
        case InputMaskType.Percent:
        case InputMaskType.Percent2:
        case InputMaskType.Real:
        case InputMaskType.Numeric:
        case InputMaskType.Numeric2:
        case InputMaskType.Numeric3:
        case InputMaskType.Numeric4:
          return 'number';
        case InputMaskType.Phone:
        case InputMaskType.PhoneWithDDD:
        case InputMaskType.PhoneWithDDDInternational:
        case InputMaskType.PhoneWithDDDNoPrefix:
        case InputMaskType.PhoneWithDDDNoPrefixInternational:
          return 'tel';
        case InputMaskType.Password:
          return 'password';
        default:
          return 'text';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getInputType', error.message);
    }
    return 'text';
  }

  getPrefix(ctrl: IAtividadeComponenteDAL): string {
    try {
      switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
        case InputMaskType.Real:
          return 'R$';
        case InputMaskType.Dolar:
          return '$';
        default:
          return '';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getPrefix', error.message);
    }
    return '';
  }

  getSuffix(ctrl: IAtividadeComponenteDAL): string {
    try {
      switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
        case InputMaskType.Percent:
        case InputMaskType.Percent2:
          return '%';
        default:
          return '';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSuffix', error.message);
    }
    return '';
  }


}
