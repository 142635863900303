import { of } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LibService } from '@medlogic/shared/geform';
import { publishReplay } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { IServiceProvider, GlobalService, LogService, IForm, IMedlogicProfessional } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../pwa/service/config-pwa-medlogic.custom.service';

export abstract class ProfessionalService implements IServiceProvider {

  // tslint:disable-next-line: max-line-length
  private lstVariaveis = 'V_405,V_29166,V_29193,V_29194,V_29195,V_29230,V_29231,V_29240,V_29792,V_29793,V_29994,V_30007,V_31965,V_32950,V_33160,V_33496,V_101584,V_101585,V_101586,V_101587,V_101588,V_101589,V_101590,V_101591,V_101592,V_101673,V_101674,V_101675,V_101678,V_101679,V_102458,V_102459,V_102460';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IMedlogicProfessional[] = new Array<IMedlogicProfessional>();

  codigoVariavelNo = 29193; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 16620;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigPwaMedLogicService,
    protected lib: LibService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna somente as variáveis desejadas.
   * lstVariables do tipo: 'V_3332,V_32223'
   */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IMedlogicProfessional> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IMedlogicProfessional> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IMedlogicProfessional> {
    try {
      if (
        (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
        || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
        || (!this.cadastrosCache)
        // (startDate.getTime() !== this.currentDtInicial.getTime())
        // || (endDate.getTime() !== this.currentDtFinal.getTime())
        // || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected mapTo = () => map((c: any) => {
    return {
      ocorrenciaNo: c.OcorrenciaNo,
      sexo: c.V_405,
      getUsuarioToken: c.V_29166,
      codigo: c.V_29193,
      titulo: c.V_29194,
      habilitado: this.lib.getBoolean(c.V_29195),
      dataAvaliacao: this.glb.ddMMYYYYThhmmssToDate(c.V_29230),
      avaliador: c.V_29231,
      observacoesPositivasMelhorias: c.V_29240,
      perfilProfissional: c.V_29792,
      experienciaProfissional: c.V_29793,
      informacoesImportantes: c.V_29994,
      indicacaoQuemIndicouempresa: c.V_30007,
      tipoRegistro: c.V_31965,
      regiaoEpecialidade: c.V_32950,
      retorno: c.V_33160,
      nomeCPF: c.V_33496,
      cumprimentotarefas: c.V_101584,
      uniforme: c.V_101585,
      pontualidade: c.V_101586,
      tratamentoComIdoso: c.V_101587,
      comportamentolideranca: c.V_101588,
      conhecimentoTeorico: c.V_101589,
      temProatividade: c.V_101590,
      conhecimentoPratico: c.V_101591 || c.V_101592,
      numeroRegistro: c.V_101673,
      login: c.V_101674,
      telefone: c.V_101675,
      regiao: c.V_101678,
      especialidade: c.V_101679,
      nome: c.V_102458,
      dataNascimento: c.V_102459,
      eMail: c.V_102460,

    } as IMedlogicProfessional;
  })

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
   * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
   */
  protected getFiltered(
    cadastroNo: number,
    strFilter: string,
    startDate: Date = null,
    endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IMedlogicProfessional> {
    try {
      this.cadastroSrv.dtInicial = startDate ?
        this.glb.dateToYYYYMMddThhmmss(startDate) :
        this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  // save(ano: number, professional: IMedlogicProfessional, id?: number): Observable<any> {
  save<T>(ano: number, item: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      uno = uno || this.cnf.usuarioLogadoNo;
      const forms: IForm[] = this.mapToForm(item as unknown as IMedlogicProfessional).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(professional: IMedlogicProfessional): IForm[] {
    try {
      return [
        { VariavelNo: 405, ValorDado: professional.sexo || '' },
        { VariavelNo: 29166, ValorDado: professional.getUsuarioToken || '' },
        { VariavelNo: 29193, ValorDado: professional.codigo || '' },
        { VariavelNo: 29194, ValorDado: professional.titulo || '' },
        { VariavelNo: 29195, ValorDado: professional.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 29230, ValorDado: this.glb.ddMMYYYYThhmmssToDate(professional.dataAvaliacao) },
        { VariavelNo: 29231, ValorDado: professional.avaliador || '' },
        { VariavelNo: 29240, ValorDado: professional.observacoesPositivasMelhorias || '' },
        { VariavelNo: 29792, ValorDado: professional.perfilProfissional || '' },
        { VariavelNo: 29793, ValorDado: professional.experienciaProfissional || '' },
        { VariavelNo: 29994, ValorDado: professional.informacoesImportantes || '' },
        { VariavelNo: 30007, ValorDado: professional.indicacaoQuemIndicouempresa || '' },
        { VariavelNo: 31965, ValorDado: professional.tipoRegistro || '' },
        { VariavelNo: 32950, ValorDado: professional.regiaoEpecialidade || '' },
        { VariavelNo: 33160, ValorDado: professional.retorno || '' },
        { VariavelNo: 33496, ValorDado: professional.nomeCPF || '' },
        { VariavelNo: 101584, ValorDado: professional.cumprimentotarefas || '' },
        { VariavelNo: 101585, ValorDado: professional.uniforme || '' },
        { VariavelNo: 101586, ValorDado: professional.pontualidade || '' },
        { VariavelNo: 101587, ValorDado: professional.tratamentoComIdoso || '' },
        { VariavelNo: 101588, ValorDado: professional.comportamentolideranca || '' },
        { VariavelNo: 101589, ValorDado: professional.conhecimentoTeorico || '' },
        { VariavelNo: 101590, ValorDado: professional.temProatividade || '' },
        { VariavelNo: 101591, ValorDado: professional.conhecimentoPratico || '' },
        { VariavelNo: 101592, ValorDado: professional.conhecimentoPratico || '' },
        { VariavelNo: 101673, ValorDado: professional.numeroRegistro || '' },
        { VariavelNo: 101674, ValorDado: professional.login || '' },
        { VariavelNo: 101675, ValorDado: professional.telefone || '' },
        { VariavelNo: 101678, ValorDado: professional.regiao || '' },
        { VariavelNo: 101679, ValorDado: professional.especialidade || '' },
        { VariavelNo: 102458, ValorDado: professional.nome || '' },
        { VariavelNo: 102459, ValorDado: professional.dataNascimento || '' },
        { VariavelNo: 102460, ValorDado: professional.eMail || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  // insertIfNotExist(ano: number, professional: IMedlogicProfessional, compareFieldName: string = 'titulo'): Observable<boolean> {
  insertIfNotExist<T>(ano: number, item: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap((items: any) => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], item[compareFieldName])) >= 0);
            if (!founded) {
              return this.save(ano, item as unknown as IMedlogicProfessional, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }


}
