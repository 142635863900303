import { ICliente } from './icliente';
import { IPapel } from './ipapel';

export interface IUsuario {
  UsuarioNo: number;
  UsuarioNome: string;
  Email: string;
  blReceberNotificacaoEmail: boolean;
  blRecberNotificacaoRecado: boolean;
  blInativo: boolean;
  UsuarioSubstitutoNo: number;
  dtCadastro: Date;
  dtModificacao: Date;
  dtUltimoAcesso: Date;
  TotalAcesso: number;
  UsuarioSenha: string;
  ClienteNo: number;
  UsuarioFoto: string;
  Login: string;
  blAlterarSenha: boolean;
  UsuarioSenhaAuto: string;
  EmailSecundario: string;
  EmailNotificacao: string;
  Twitter: string;
  UsuarioAD: string;
  ObjCliente: ICliente;
  ColPapel: IPapel[];
  ClienteNome: string;
  CalcProcessoUnicoNo: number;
  CalcPodeIniciarProcesso: boolean;
  CalcPodeEditarAtividade: boolean;
  CalcVariavelComplementar1No: number;
  CalcVariavelComplementar2No: number;
  CalcVersaonumbererfaceWeb: string;
  CalcForcarNovaVersao: boolean;
  CalcPodeAbrirTarefaAlheia: boolean;
  CalcPodeReatribuirTarefa: boolean;
  CalcPodeEfetuarLogOff: boolean;
  CalcPodeCancelarTarefa: boolean;
  DtNascimentoAbrev: string;
  DtNascimento: Date;
}
