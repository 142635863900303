import { EnComponentsView } from '../enum/en-components-view';
import { IListItem } from './ilist-item';

export interface IConfigModule {
  name: string; // Nome exato do Cadastro. É utilizado como id de comparação.
  id: number | number[]; // Se for um módulo personalizado, esse id será um número sequencial definido no próprio config.json. Senão, será o id da atividade no dev, apenas como referência, pois o nome é usado como identificador.
  canEdit?: boolean;
  canCreate?: boolean;
  canDelete?: boolean;
  canCreateIfEmpty?: boolean;
  canRead?: boolean;
  mode?: EnComponentsView;
  identification?: IListItem<any>;
  isCustomerModule: boolean; // indica se é o módulo que define o cliente atrelado ao usuário logado.
  derivedFormName?: string; // Preenchido apenas se for um módulo personalizado para indicar qual é o cadastro relacionado de onde a listagem genérica será carregada.
  cadNoName?: string;
  roles?: string[];
  sendEmailReportToFamilyAfterSave?: boolean;
}
