import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { IFlashObj } from '../../shared/interface/iflash-obj';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { IAtividade } from '../../shared/interface/iatividade';
import { tap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { NavigationService } from '../../shared/service/navigation.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-generic-activity-view',
  templateUrl: './generic-activity-view.component.html',
  styleUrls: ['./generic-activity-view.component.css']
})
export class GenericActivityViewComponent extends UnsubscribeOnDestroyAdapter implements OnInit {


  defaultFormControls: any; // Armazena os valores para preenchimento de um lançamento baseado em recorrência
  atividadeNo: number;
  ocorrenciaNo: number;
  processoNo = 1096;
  isRecurrent = false;
  flashObjs: IFlashObj[];
  description = 'Atividade';
  isEdit = true;
  enTheme = EnTheme.black;
  public get isMobile(): boolean {
    return this.global.isMobile();
  }

  constructor(
    private log: LogService,
    private cnf: ConfigStateService,
    private route: ActivatedRoute,
    private global: GlobalService,
    private nav: NavigationService) {
    super();
  }

  ngOnInit() {
    try {
      this.getUrlParams(this.route);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /** Carrega os dados. */
  refresh(genericActivityNo: number, ono: number): Observable<IAtividade> {
    try {
      return of(null)
        .pipe(
          tap(a => {
            // Carregamento da Atividade
            this.atividadeNo = genericActivityNo;
            this.defaultFormControls = this.getDefaultFormControls(
              this.cnf.OcorrenciaNo.value,
              this.cnf.selectedPatient,
              this.cnf.usuarioLogadoNo,
              this.cnf.usuarioLogadoNome
            );
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /** Injeta os valores de variáveis, auto preenchendo o formulário. */
  getDefaultFormControls(ono: number, patient: IPatient, professionalNo: number, professionalName: string): any {
    try {
      const dfc = {
        V_28626: ono,
        V_2230: patient.codigo,
        V_387: patient.nomeHospede,
        V_391: patient.nascimento,
        V_101593: new Date(),
        V_29977: professionalName,
        V_28841: professionalNo,
        // 'V_34202': patient.nomeHospede
      };
      return dfc;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDefaultFormControls', error.message);
    }
  }

  /** Extrai os parâmetros passados na url/rota */
  protected getUrlParams(route: ActivatedRoute): void {
    try { // Os parametros estão sendo passados diretamente aos componentes
      // Para aguardar o carregamento do config
      this.subs.sink = route.params
        .pipe(
          mergeMap((params: Params) => {
            if (params.key) {
              this.atividadeNo = +params.key;
            }
            if (params.ono) {
              this.ocorrenciaNo = +params.ono;
            }
            if (params.theme) {
              this.enTheme = params.theme;
            }
            return this.refresh(this.atividadeNo, this.ocorrenciaNo);
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }


  /* Permitirá que os controles internos chamem uma rotina de recarregamento e limpeza do cache.
  * É útil, por exemplo, ao cadastrar um grid de rateio, forçar o recarregamento.
  * mas essa função não faz retornar para o Grid, apenas prepara para quando o usuário retornar esteja correto.
  */
  onRefresh(args): void {
    try {
      this.cacheCleanAndRefresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onRefresh', error.message);
    }
  }

  /** Limpa o cache de dados e recarrega. */
  protected cacheCleanAndRefresh(): void {
    try {
      this.subs.sink = this.refresh(this.atividadeNo, this.ocorrenciaNo).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cacheClean', error.message);
    }
  }

  onBack(args): void {
    try {
      this.nav.goBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  onGeBack(args): void {
    try {
      this.nav.goBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  /** Evento chamado pelo componente da atividade, após Salvar. */
  onAfterCompleted($event: any): void {
    try {
      console.log($event);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAfterCompleted', error.message);
    }
  }

}
