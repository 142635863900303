import { setIsLoading } from '@medlogic/medlogic/medlogic-state';
import { error, IAppState } from '@medlogic/shared/shared-interfaces';
import { GenericCustomService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';
import {
  GenericActionTypes, fetchErrorGeneric,
  fetchSuccessGeneric,
  loadGenericByPerson,
} from './generic.actions';
import { EnUserRole } from '@medlogic/shared/shared-interfaces';


@Injectable()
export class GenericEffects {

  constructor(
    private actions$: Actions,
    private store: Store<IAppState | any>,
    private genericService: GenericCustomService
  ) { }


  loadGeneric$ = createEffect(() => this.actions$
    .pipe(
      ofType<any>(GenericActionTypes.LoadGeneric),
      withLatestFrom(this.store),
      mergeMap(([action, state]) => {
        // Nesse caso, o customerId representa uma estrutura de agrupamento dos registros, como as escolas da addhere.
        const customerId = state?.tenant?.customerId;
        const isAdmOrNullCustomerId = state?.tenant?.userRole === EnUserRole.administrator || customerId <= 0;
        return isAdmOrNullCustomerId ?
          this.genericService.getAllWithLabel(action?.genericAno, action?.genericLabel).pipe(toArray(), error()) :
          this.genericService.getById(action?.genericAno, customerId, action?.identification?.id, state?.tenant?.isDebug).pipe(toArray(), error());
      }),
      switchMap((allGenerics) => [
        allGenerics ?
          fetchSuccessGeneric({ allGenerics: [...allGenerics] }) :
          fetchErrorGeneric(),
        setIsLoading({ isLoading: false })
      ]),
      catchError((e) => {
        console.log(e);
        this.store.dispatch(setIsLoading({ isLoading: false }));
        return of(fetchErrorGeneric());
      })
    )
  );

  reloadGeneric$ = createEffect(() => this.actions$
    .pipe(
      ofType<any>(GenericActionTypes.ReloadGeneric),
      withLatestFrom(this.store),
      switchMap(([action, state]) => [
        loadGenericByPerson({ genericAno: state?.generic?.genericAno, genericLabel: state?.generic?.genericLabel, person: state?.person?.person }),
      ]),
      catchError((e) => {
        console.log(e);
        this.store.dispatch(setIsLoading({ isLoading: false }));
        return of(fetchErrorGeneric());
      })
    )
  );

  loadGenericByPerson$ = createEffect(() => this.actions$
    .pipe(
      ofType<any>(GenericActionTypes.LoadGenericByPerson),
      withLatestFrom(this.store),
      mergeMap(([action, state]) => {
        return this.genericService.getByPerson(action?.genericAno, action?.genericLabel, action?.person?.prontuario).pipe(toArray(), error());
      }),
      switchMap((allGenerics) => [
        allGenerics ?
          fetchSuccessGeneric({ allGenerics: [...allGenerics] }) :
          fetchErrorGeneric(),
        setIsLoading({ isLoading: false })
      ]),
      catchError((e) => {
        console.log(e);
        this.store.dispatch(setIsLoading({ isLoading: false }));
        return of(fetchErrorGeneric());
      })
    )
  );


}
