import { fadeAnimation } from '@medlogic/shared/utils';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css'],
  animations: [fadeAnimation]
})
export class EmptyComponent implements OnInit {

  @Input() title = 'Nenhum registro encontrado.';
  @Input() subTitle = 'Utilize o botão + para cadastrar.';
  @Input() imgUrl = '/assets/img/icn/empty-books.png';
  @Input() showImage = true;
  isVisible = false;

  constructor() { }

  ngOnInit() {
    setTimeout(() => this.isVisible = true, 1000);
  }

}
