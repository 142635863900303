import { createAction, props } from '@ngrx/store';
import { IOption } from '../../interface/ioption';
import { IAccount } from '../../interface/iaccount';

// Application Level Action
export const onAppLoad = createAction('[Home Component] onAppLoad',
  props<{
    selectedOption: IOption, optionList: IOption[], bannerTitle: string
  }>());

export const onNavigate = createAction('[Home Component] onNavigate',
  props<{ previousPath: IOption[] }>());

// Option View Action
export const onOptionsRender = createAction('[OptionViewComponent] onOptionRender',
  props<{ optionList: IOption[], bannerTitle: string }>());

export const onOptionSelect = createAction('[OptionViewComponent] onOptionSelect',
  props<{ currentOption: IOption }>());

// General Condition View Action
export const onAccountSelect = createAction('[AccountViewComponent] onAccountSelect',
  props<{ selectedAccount: IAccount }>());

// Date Change Action
export const onDateChange = createAction('[DatePickerComponent] onDateChange', props<{ fromDate: any, toDate: any }>());

export const onLoading = createAction('[App Component] Loading', props<{ isLoading: boolean }>());
