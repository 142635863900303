import { ConfigStateService } from '@medlogic/shared/state-config';
import { IDado } from '../interface/idado';
import { DadoDAL } from '../model/dal/dado-dal';
import { Injectable } from '@angular/core';
import { OcorrenciaDAL } from '../model/dal/ocorrencia-dal';
import { mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { LogService, IAtividadeComponenteDAL, IOcorrencia } from '@medlogic/shared/shared-interfaces';
import { IIniciarNova } from '../interface/iiniciar-nova';

@Injectable()
export class ActionService {
  constructor(private dadoDAL: DadoDAL, private log: LogService, private ocorrenciaDAL: OcorrenciaDAL, private cnf: ConfigStateService) { }

  /*Salva todos os dados, a partir dos componentes.
     * Outra maneira de fazer seria resgatar os valores de formGroup.
     * Apesar de disparar as validações, salvará mesmo se houver campos pendentes, pois
     * as ações de grid mudam de página e os dados precisam ser salvos para o retorno.
     */
  salvar(
    componentes: Array<IAtividadeComponenteDAL>,
    tarefaNo: number,
    ocorrenciaNo: number,
    usuarioNo: number,
    processoNo: number = -1
  ): Observable<IOcorrencia> {
    try {
      const dados: Array<IDado> = this.dadoDAL.mapToDados(componentes, tarefaNo, ocorrenciaNo, usuarioNo);
      const ocorrencia = this.dadoDAL.setAll(dados, true).pipe(
        map((dado) => {
          return {
            OcorrenciaNo: dado.OcorrenciaNo,
            TarefaNo: dado.TarefaNo,
            AtividadeNo: dado.AtividadeNo,
            ProcessoNo: processoNo,
            Componentes: componentes
          } as IOcorrencia;
        })
      );
      return ocorrencia;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'salvar', error.message);
    }
    return of(null);
  }

  /*Semelhante ao salvar, no entanto, se não houver ocorrência, criará uma antes de salvar os dados.
   * Retorna um objeto que contém a nova ocorrencia, mas também a tarefa.
   */
  criarOcorrenciaESalvar(
    componentes: Array<IAtividadeComponenteDAL>,
    tarefaNo: number,
    ocorrenciaNo: number,
    usuarioNo: number,
    processoNo: number
  ): Observable<any> {
    try {
      if (ocorrenciaNo <= 0) {
        // Criar ocorrencia
        return this.ocorrenciaDAL.IniciarNova(usuarioNo, processoNo).pipe(
          mergeMap((ocorrencia: IIniciarNova) => {
            try {
              this.cnf.OcorrenciaNo.next(ocorrencia.OcorrenciaNo);
              return this.salvar(
                componentes,
                ocorrencia.TarefaNo,
                ocorrencia.OcorrenciaNo,
                usuarioNo,
                processoNo
              ).pipe(
                map(
                  (result) =>
                    result
                      ? {
                        OcorrenciaNo: ocorrencia.OcorrenciaNo,
                        TarefaNo: ocorrencia.TarefaNo,
                        AtividadeNo: ocorrencia.AtividadeNo
                      } as IOcorrencia
                      : null
                )
              );
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'salvar.subscribe', error.message);
            }
          })
        );
      } else {
        // Ocorrência já existente
        // TODO: Como saber a tarefa?
        return this.salvar(componentes, tarefaNo, ocorrenciaNo, usuarioNo, processoNo);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'criarOcorrenciaESalvar', error.message);
    }
    return of(null);
  }
}
