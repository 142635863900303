<div [formGroup]="formGroup" class="form-group" [class.has-error]="!isValid(formGroup, ctrl)" [libCtrConfigUI]="ctrl"
[altura]="ctrl?.Altura" [isMobile]="isMobile">

  <!-- <lib-title-label #lblTitle [formGroup]="formGroup" [enTheme]="enTheme" [ctrl]="ctrl"></lib-title-label> -->

  <mat-spinner [diameter]="18" *ngIf="isLoading"></mat-spinner>

  <div *ngIf="!isLoading" class="input-group" [class.is-mobile]="isMobile">

    <ng-container [ngSwitch]="isSearchMode">

      <!-- <p-dropdown *ngSwitchCase="true" [id]="getId(ctrl)" [name]="getId(ctrl)" [formControlName]="getId(ctrl)"
        styleClass="form-control combobox" [style]="getDropdownStyle(ctrl, btnAction)"
        [panelStyle]="{'width': getWidth(ctrl, btnAction)}" panelStyleClass="combobox-panel" [filter]="true"
        [options]="items" (completeMethod)="search($event)" #dropdown [tabIndex]="getTabIndex(ctrl)"
        [libCtrMobileConfigUI]="ctrl">

        <ng-template let-item pTemplate="selectedItem">
          <span class="selected-item" [libCtrMobileConfigUI]="ctrl">{{ item.label }}</span>
        </ng-template>

        <ng-template let-item2 pTemplate="item">
          <div [libCtrMobileConfigUI]="ctrl">{{ item2.label }}</div>
        </ng-template>
      </p-dropdown> -->

      <mat-form-field *ngSwitchCase="true" [id]="'formField'+getId(ctrl)" [class.full-width]="isMobile"
        [class.is-desktop]="!isMobile">

        <input type="text" [placeholder]="ctrl?.ShowRotulo ? ctrl?.Rotulo : ''" aria-label="Number" matInput
          [formControl]="getFormControlFrom(ctrl)" [matAutocomplete]="auto" [tabIndex]="getTabIndex(ctrl)" #dropdown
          [libCtrMobileConfigUI]="ctrl" (keydown.esc)="resetValue(ctrl)" [required]="ctrl?.RequiredField">

        <mat-autocomplete [id]="'auto_'+getId(ctrl)" autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let item of filteredOptions$ | async" [value]="item">
            {{ item }}
          </mat-option>
        </mat-autocomplete>

        <mat-error
          *ngIf="!getFormControlFrom(ctrl).hasError('forceSelection') && getFormControlFrom(ctrl).hasError('required')">
          Opção obrigatória!</mat-error>

        <mat-error
          *ngIf="getFormControlFrom(ctrl).hasError('forceSelection') && !getFormControlFrom(ctrl).hasError('required')">
          Selecione uma das opções existentes!
        </mat-error>

      </mat-form-field>



      <ng-container *ngSwitchDefault>

        <mat-form-field appearance="fill" [id]="'formField'+getId(ctrl)" [class.full-width]="isMobile"
          [class.is-desktop]="!isMobile">

          <mat-label *ngIf="ctrl?.ShowRotulo">{{ ctrl?.Rotulo }}</mat-label>

          <select title="opcoes" [id]="'select_'+getId(ctrl)" matNativeControl [required]="ctrl?.RequiredField"
            [formControlName]="getId(ctrl)" [tabIndex]="getTabIndex(ctrl)" #dropdown [libCtrMobileConfigUI]="ctrl">
            <option *ngFor="let item of items" [value]="item?.label">{{ item?.label }}</option>
          </select>

          <mat-error *ngIf="getFormControlFrom(ctrl).hasError('required')">Opção obrigatória!</mat-error>

        </mat-form-field>

      </ng-container>



    </ng-container>

    <!--Menu de opções -->
    <div class="input-group-btn" *ngIf="showMenu(ctrl, dropdown || null)" [class.open]="isMenuOpened">

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Opções" type="button">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu" [class.black]="enTheme === ENTHEME.black">

        <button mat-menu-item *ngIf="canCreate(ctrl)" type="button" [class.black]="enTheme === ENTHEME.black"
          (click)="onNewItem($event, ctrl?.AtividadeNo, ctrl?.CadastroNo, ctrl?.VariavelNo, ctrl?.CanAddInCadastro)">
          <mat-icon>add</mat-icon>
          <span>Novo</span>
        </button>

        <button mat-menu-item *ngIf="canEdit(ctrl, dropdown || null)" type="button" [class.black]="enTheme === ENTHEME.black"
          (click)="onEditSelected($event, ctrl?.AtividadeNo, ctrl?.CadastroNo, dropdown || null, ctrl, isReadOnly(ctrl), ctrl?.CanAddInCadastro)">
          <mat-icon>edit</mat-icon>
          <span>Editar</span>
        </button>

        <button mat-menu-item *ngIf="canDelete(ctrl, dropdown || null)" type="button" [class.black]="enTheme === ENTHEME.black"
          (click)="onDeleteItem($event, ctrl?.AtividadeCadastroNo, dropdown || null, ctrl?.CanAddInCadastro)">
          <mat-icon>delete</mat-icon>
          <span>Excluir</span>
        </button>

      </mat-menu>

      <!-- <button data-toggle="dropdown" #btnAction class="btn btn-white dropdown-toggle" type="button"
        [attr.aria-expanded]="isMenuOpened" (click)="isMenuOpened=!isMenuOpened" [libCtrMobileConfigUI]="ctrl"><i
          class="fa fa-wrench"></i></button>

      <ul class="dropdown-menu pull-right" (mouseleave)="onMouseLeave()" [libCtrMobileConfigUI]="ctrl">
        <li *ngIf="canCreate(ctrl, dropdown)"><a href="javascript:void(0)"
            (click)="onNewItem($event, ctrl?.AtividadeNo, ctrl?.CadastroNo, ctrl?.VariavelNo, ctrl?.CanAddInCadastro)">Novo</a>
        </li>
        <li *ngIf="canEdit(ctrl, dropdown)"><a href="javascript:void(0)"
            (click)="onEditSelected($event, ctrl?.AtividadeNo, ctrl?.CadastroNo, dropdown, ctrl, isReadOnly(ctrl), ctrl?.CanAddInCadastro)">Editar</a>
        </li>
        <li *ngIf="canDelete(ctrl, dropdown)" class="divider"></li>
        <li *ngIf="canDelete(ctrl, dropdown)"><a href="javascript:void(0)"
            (click)="onDeleteItem($event, ctrl?.AtividadeCadastroNo, dropdown, ctrl?.CanAddInCadastro)">Excluir</a></li>
      </ul> -->

    </div>


  </div>
</div>