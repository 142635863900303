import { of } from 'rxjs';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IIvcf } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IvcfCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { IvcfActionTypes, fetchErrorIvcf, fetchSuccessIvcf } from './ivcf.actions';


@Injectable()
export class IvcfEffects {

  constructor(
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private ivcfSrv: IvcfCustomService,
  ) { }

  loadIvcfs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IvcfActionTypes.LoadIvcf),
      mergeMap((action: any) => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            const cadNo = action.isProspectIvcf ? state?.tenant?.selectedTenant?.cadProspectIvcfNo: state?.tenant?.selectedTenant?.cadIvcfNo;
            return this.ivcfSrv.getIvcf(
              cadNo,
              state?.ivcf?.ivcfId
            );
          }),
        )
      }),
      switchMap((ivcf: IIvcf) => [
        ivcf
          ? fetchSuccessIvcf({ ivcf })
          : fetchErrorIvcf(),
      ]),
      catchError((e: any) => {
        console.log(e);
        return of(fetchErrorIvcf());
      })
    )
  );


}
