export interface IEvolucaoFisioterapia {
  ocorrenciaNo: number;
nomePaciente:  string;
nascimento:  Date;
nomeMae:  any;
identificacao1:  any;
identificacao2:  any;
prontuario:  any;
dataAniversario:  any;
dataEvolucao:  Date;
responsavelID:  any;
responsavelNome:  any;
admissao:  string;
cPF:  string;
idade:  any;
endereco:  string;
telefone:  string;
celular:  string;
email:  string;
codigo:  any;
titulo:  any;
habilitado:  boolean;
nomeIdoso:  string;
pai:  string;
quarto:  string;
funcaoMotoraMMSSDireito:  string;
funcaoMotoraMMSSEsquerdo:  string;
funcaoMotoraMMIIEsquerdo:  string;
funcaoMotoraMMIIDireito:  string;
avaliacaoMobilidadeNoLeitoRolarDireita:  string;
avaliacaoMobilidadeNoLeitoRolarEsquerda:  string;
pontetrocafralda:  string;
timedUpAndGo:  any;
usoCadeira:  string;
testeSentaLevantaForcaMMII:  any;
data:  Date;
titulacaoPressao:  any;
fluxoOxigenio:  string;
tipoaparelho:  any;
equipamento:  string;
seOutrosDescreva:  string;
data2:  Date;
fluxoOxigenio2:  string;
seOutrosDescreva2:  string;
outros:  string;
data3:  Date;
fisioterapeuta:  string;
localatendimento:  string;
seOutrosDescreva3:  string;
bANDAGEMDataColocacao:  Date;
localAplicacao:  any;
dataTroca:  Date;
dataEvolucao2:  any;
numeroConselho:  string;
todasAsDependenciasRIAE:  string;
dispositivomarcha:  string;
areasExternasRIAE:  string;
dispositivomarcha2:  string;
areasInternasRIAE:  string;
dispositivomarcha3:  string;
andarmoradia:  string;
dispositivomarcha4:  string;
foraandarmoradia:  string;
dispositivomarcha5:  string;
dentroquarto:  string;
dispositivomarcha6:  string;
data4:  Date;
origem:  string;
numero:  any;
todosOsMomentos:  string;
auxilioDispositivo:  string;
auxilioDispositivo2:  string;
sairEntrarNoLeito:  string;
cadeiraCadeira:  string;
auxilioDispositivo3:  string;
outros2:  string;
periodoutilizacaoContinuo:  boolean;
periodoutilizacaoAlimentacao:  boolean;
periodoutilizacaoNoturno:  boolean;
periodoutilizacaoBanho:  boolean;
periodoutilizacaoDeambulacao:  boolean;
fisioterapiaMotora:  boolean;
fisioterapiaOrtopedica:  boolean;
procedimentoCirurgicoFratura:  boolean;
mSE:  boolean;
recomendacoes:  string;
mSD:  boolean;
recomendacoes2:  string;
respiratoria:  boolean;
analgesica:  boolean;
grupo:  boolean;
descreva:  string;
outros3:  string;
data5:  Date;
fisioterapeutaHomeCareResponsavel:  any;
cREFITO:  any;
fisioterapiaMotora2:  boolean;
fisioterapiaOrtopedica2:  boolean;
procedimentoCirurgicoFratura2:  boolean;
mSE3:  boolean;
recomendacoes3:  string;
mIE2:  boolean;
recomendacoes4:  string;
respiratoria2:  boolean;
analgesica2:  boolean;
outros4:  string;
localatendimento2:  string;
seOutrosDescreva4:  string;
tipocontrato:  string;
telefone2:  any;
mIE:  boolean;
mID:  boolean;
mSD2:  boolean;
mID2:  boolean;
subirdescerEscadas:  string;
tipo:  string;
usobengala:  string;
bIPAP:  boolean;
titulacao:  string;
titulacao2:  string;
bIPAP2:  boolean;
coluna:  boolean;
recomendacoes5:  string;
costelas:  boolean;
recomendacoes6:  string;
coluna2:  boolean;
recomendacoes7:  string;
costelas2:  boolean;
recomendacoes8:  string;
dt:  any;
dt2:  any;
dtroca:  any;
profissionalOcorrencia:  any;
dataCPAP:  any;
dataO2:  any;
dataFiosioRiae:  any;
dataFisioExterno:  any;
usoOxigenio:  boolean;
  }
