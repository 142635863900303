export const fieldsMapPrescriptionMedication = {
    prescriptionMedicationId: { name: 'prescriptionMedicationId', type: 'number' },
    ocorrenciaNo: { name: 'ocorrenciaNo', type: 'string' },
    V_30212: { name: 'prescriptionMedicationId', type: 'string' },
    V_3328: { name: 'identificacao1', type: 'string' },  // Concatena as variáveis V_387, V_1608 e V_391. A variável V_391 foi adicionada porque por algum motivo este campo, as vezes, só é preenchido quando ela está presnte na concatenação. 
    V_3329: { name: 'identificacao2', type: 'string' }, // Concatena as variáveis V_28051 e V_391.
    V_28051: { name: 'Id_Paciente', type: 'number' }, // topRight
    V_32936: { name: 'titulo', type: 'string' },
    V_32937: { name: 'habilitado', type: 'boolean' },
    V_33964: { name: 'createdBy', type: 'string' },  // Exibe o RG de quem executou a atividade	
    V_30354: { name: 'InterventionMedication', type: 'string', relationshipField: 'name', relationshipFieldId: 'interventionMedicationId' }, // Este é o grid de medicamentos. Não tenho certeza de esta é a forma correta de descrever este campo, mas segui o que já existia anteriormente.

    // Mapeamentos de variáveis duplicadas
    // V_32935: {name: 'codigo', type: 'number' },
    V_32935: { name: 'prescriptionMedicationId', type: 'number' },
    // V_32939: {name: 'codHospedeTelaPrescricao', type: 'number' }, // Recebe o valor da V_28051 e é utilizado para preencher campo de mesmo nome na tela de Prescrição.
    V_32939: { name: 'createdBy', type: 'string' },  // Exibe o RG de quem executou a atividade	
    V_33963: {name: 'createdBy', type: 'string' }, // bottomLeft Exibe o nome de quem executou a atividade


    V_387: { name: 'residente', type: 'string' }, // topLeft
    V_32938: { name: 'dataNascimento', type: 'Date' }, // bottomRight Campo usado para realizar a configuração de exibição do processo, assim como V_387, V_28051 e V_1608.
    V_1608: { name: 'nomeMae', type: 'string' },
    V_27993: {name: 'centroCusto', type: 'string', }, // Recebe o valor da V_387 e é utilizado para preencher o Centro de Custo na tela de Prescrição.
}
