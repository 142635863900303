import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppMedlogicPwaCuidadoState } from '../states/app-state';
import { Observable } from 'rxjs';
import {
  GenericActionTypes, fetchErrorGeneric,
  fetchSuccessGeneric,
} from '../actions/generic.actions';
import { IListItem } from '../../interface/ilist-item';
import { GenericCustomService } from '../../service/generic.custom.service';


@Injectable()
export class GenericEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppMedlogicPwaCuidadoState>,
    private genericService: GenericCustomService // TODO: ContaService changed to the API
  ) { }

  // TODO: There is an specific id, for each Model, according with the logged user.
  ano$ = this.store.select(state => this.getId(state));
  balance$ = this.store.select(state => this.getId(state, 'balanceId'));

  loadGeneric$ = createEffect(() => this.actions$
    .pipe(
      ofType<any>(GenericActionTypes.LoadGeneric),
      mergeMap((action: any) => this.genericService.getAll(action.genericId).pipe(toArray())),
      map((allGenerics) => {
        return fetchSuccessGeneric({ allGenerics: [...allGenerics] });
      }),
      catchError((e) => {
        console.log(e);
        return of(fetchErrorGeneric());
      })
    )
  );


  // getGenericByCode$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(GenericActionTypes.GetByCode),
  //     mergeMap((action: any) => {
  //       return this.ano$
  //         .pipe(
  //           this.getByCodeAndTransform$(action.validationCode),
  //           catchError((e) => {
  //             console.log(e);
  //             return of(fetchErrorGeneric());
  //           })
  //         );
  //     })
  //   )
  // );

  private getAndTransform$ = (genericId: number) => mergeMap(() => {
    return this.genericService.getAll(genericId)
      .pipe(
        toArray(),
        map((allGenerics: IListItem[]) => fetchSuccessGeneric({ allGenerics })),
      );
  })

  // private getByCodeAndTransform$ = (validationCode: string) => mergeMap((genericId: number) => {
  //   return this.genericService.getByCode(genericId, validationCode)
  //     .pipe(
  //       toArray(),
  //       map((allGenerics: IListItem[]) => fetchSuccessGenericByCode({ selectedGeneric: allGenerics[0] })),
  //     );
  // })


  private getId(state: AppMedlogicPwaCuidadoState, propName: string = 'accountId'): number { // 'genericId'
    // TODO: Attention: the property must be changed accordinly the desired Id (in this case contaId).
    return (state && state.tenant && state.tenant.selectedTenant && state.tenant.selectedTenant[propName]) ?
      state.tenant.selectedTenant[propName] :
      -1;
  }

  private error = () => catchError((e): Observable<Action> => {
    console.log(e);
    return of(fetchErrorGeneric());
  })


}
