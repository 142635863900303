import { Action, createReducer, on } from '@ngrx/store';
import {
  onAppLoad,
  onDateChange,
  onNavigate,
  onOptionSelect,
  onOptionsRender,
  onLoading
} from '../actions/app.action';
import { IState } from '../../interface/istate';


export const initialState: IState = {
  bannerTitle: 'MedLogic App',
  contaList: [],
  selectedAccount: null,
  selectedOption: null,
  selectedFromDate: null,
  selectedToDate: null,
  optionList: [],
  previousPath: [],
  isLoading: false
};

const appReducerObj = createReducer(
  initialState,
  on(onAppLoad, (state, payload) => ({
    ...state,
    bannerTitle: 'HOME',
    selectedOption: null,
    optionList: [],
    previousPath: []
  })),
  on(onOptionSelect, (state: IState, payload) => ({
    ...state,
    bannerTitle: payload.currentOption.name,
    selectedOption: payload.currentOption
  })),
  on(onDateChange, (state: IState, payload) => ({
    ...state,
    selectedFromDate: payload.fromDate,
    selectedToDate: payload.toDate
  })),
  on(onNavigate, (state: IState, payload) => ({
    ...state,
    previousPath: payload.previousPath
  })),
  on(onOptionsRender, (state: IState, payload) => ({
    ...state,
    optionList: payload.optionList,
    bannerTitle: payload.bannerTitle
  })),
  on(onLoading, (state: IState, payload) => ({
    ...state,
    isLoading: payload.isLoading
  }))
);

export function appReducer(state: IState | undefined, action: Action) {
  return appReducerObj(state, action);
}
