import { IFrequency } from './ifrequency';
import { IEventTime } from './ievent-time';
import { IScheduledTiming } from './ischeduled-timing';

export interface IScheduledTimingRepeat {
    scheduledTimingRepeatIdentifier: string;
    count: number;
    countMax: number;
    duration: number;
    durationMax: number;
    durationUnit: string;
    frequencyIdentifier: string;
    frequency: IFrequency[];
    frequencyMax: number;
    period: number;
    periodMax: number;
    periodUnit: string;
    dayOfWeek: string;
    timeOfDay: string;
    when: string;
    eventTime: IEventTime[];
    offset: number;
    tenantId: number;
    language: string;
    scheduledTiming: IScheduledTiming[];
}