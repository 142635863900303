<div class="container">

  <button mat-icon-button aria-label="Arrastar">
    <mat-icon>drag_indicator</mat-icon>
  </button>

  <button mat-icon-button aria-label="Novo componente">
    <mat-icon>add</mat-icon>
  </button>

  <ml-ui-care-plan-cell></ml-ui-care-plan-cell>

</div>