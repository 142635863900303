import { metaReducers } from './+state/exame.metareducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExameCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ExameEffects } from './+state/exame.effects';
import { exameReducer } from './+state/exame.reducer';

const MODULES = [
  CommonModule
];

const STATE_MODULES = [
  StoreModule.forFeature('exame', exameReducer, {metaReducers}),
  EffectsModule.forFeature([ExameEffects]),
];

const SERVICES = [
  ExameCustomService
]

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})


export class StateExameModule {}
