export const fieldsMapMedicationCheckin = {
    medicationCheckinId: { name: 'medicationCheckinId', type: 'number' },
    ocorrenciaNo: { name: 'ocorrenciaNo', type: 'number' },

    V_30351: { name: 'codigo', type: 'number' },
    V_30352: { name: 'titulo', type: 'string' },
    V_30353: { name: 'habilitado', type: 'boolean' },
    // V_30274: { name: 'tomou', type: 'boolean' }, // Mas é um texto Tomou ou Não Tomou
    V_30274: { name: 'status', type: 'string' },
    V_30330: { name: 'dataCheckin', type: 'date' },
    V_34166: { name: 'horaCheckin', type: 'string' },
    V_30275: { name: 'observacoes', type: 'string' },
    V_30276: { name: 'statusmedicacao', type: 'string' },
    V_29828: { name: 'Id_Paciente', type: 'number' },
    V_30263: { name: 'Id_Medicamento', type: 'number', isForeignKey: true }, // Será definido através do relacionamento
    V_30354: { name: 'InterventionMedication', type: 'string', relationshipField: 'name', relationshipFieldId: 'interventionMedicationId' },

    V_30331: { name: 'diaPrescricao', type: 'string' },

    // V_31927: { name: 'profissional', type: 'string' },
    V_31927: { name: 'createdBy', type: 'string' },

    //V_30264: { name: 'identificacao', type: 'string' },
    V_30264: { name: 'cascataCheckIDClienteIDMedicamentoDataAtual', type: 'string' },

    V_30273: { name: 'horaPrescricao', type: 'string' },

    V_30401: { name: 'IdMedicamento_IdPaciente', type: 'string' },
    // V_30401: { name: 'codigoMedicamentoPaciente', type: 'string' },

    V_31969: { name: 'codigoPaciente', type: 'number' },

    // Falta o tomou, statusmedicacao, createdBy, createdAt
}
