import { ConfigStateService } from '@medlogic/shared/state-config';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { NavigationService } from '../../service/navigation.service';
import { CadastroListaDAL } from '../../model/dal/cadastro-lista-dal';
import { CalculadoraService } from '../../service/calculadora.service';
import { ICampoAdicional } from '../../interface/icampo-adicional';
import { ListControl } from '../../model/list-control';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { ExecucaoTarefaDAL } from '../../model/dal/execucao-tarefa-dal';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ICadastroListaDAL } from '../../interface/icadastro-lista-dal';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { EnTypedValue } from '@medlogic/shared/shared-interfaces';
import { ITypedValue } from '@medlogic/shared/shared-interfaces';
import { StoreProviderService } from '@medlogic/shared/utils';
import { EnMaterialIcon } from '@medlogic/shared/gecore';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GeFormProviderService } from '../../service/ge-form-provider.service';
import { FormGroup } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-grid',
  templateUrl: './ctr-grid.component.html',
  styleUrls: ['./ctr-grid.component.css']
})
export class CtrGridComponent extends ListControl implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ENTHEME = EnTheme;
  // textBtnNew = 'Novo';
  // textBtnDelete = 'Excluir';

  public get lstCadastroAdicional(): any[] {
    return this.ctrl?.lstCadastroAdicional;
  }

  dataSource: MatTableDataSource<ICadastroListaDAL> = null;

  constructor(
    global: GlobalService,
    log: LogService,
    lib: LibService,
    cnf: ConfigStateService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    cadastroListaDAL: CadastroListaDAL,
    config: ConfigJsonService,
    router: Router,
    navigation: NavigationService,
    execucaoTarefa: ExecucaoTarefaDAL,
    dialog: MatDialog,
    msg: MsgPtBR,
    geFormPrv: GeFormProviderService,
    protected sp: StoreProviderService,
    private ref: ChangeDetectorRef
  ) {
    super(
      global,
      log,
      lib,
      cnf,
      calc,
      calcCond,
      dialog,
      msg,
      cadastroListaDAL,
      config,
      router,
      navigation,
      execucaoTarefa,
      geFormPrv
    );
  }
  columns: ICampoAdicional[];
  search = '';
  currentValorTexto: string;

  textBtnNew = this.msg.BTN_NEW;
  textBtnDelete = this.msg.BUTTON_DELETE;
  displayedColumns = [];

  selection = new SelectionModel<any>(true, []);

  /*Override */
  ngOnInit() {
    try {
      super.ngOnInit();
      if (this.dataSource) {
        this.dataSource.sort = this.sort;
      }
      // Necessário, pois, o ValorTexto será modificado depois do carregamento inicial no caso de carga de valor de cookie por exemplo
      this.subs.sink = this.formGroup
        .valueChanges
        .subscribe(s => {
          const gridValue = s[this.lib.getId(this.ctrl?.VariavelNo)];
          if (gridValue && gridValue !== this.currentValorTexto) {
            this.refresh();
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /*Override */
  protected refresh(): void {
    try {
      this.columns = this.getColumns(this.ctrl);
      this.displayedColumns = ['select', ...this.columns.map(m => m.ColumnName)];
      // this.displayedColumns = this.columns.map(m => m.ColumnName);
      this.currentValorTexto = this.ctrl?.ValorTexto;
      const ano = this.cnf.ModeloAtividadeNo;
      this.subs.sink = this.getValorTextoOrLoadCadastroAdicional(ano, this.ctrl)
        .subscribe(
          (items) => {
            try {
              // Se for um retorno de um item do grid, já terá ocorrido o subscribe do
              // subscribeToItemsUpdate, que popula lstCadastroAdicional.
              // if (!(this.lstCadastroAdicional && this.lstCadastroAdicional.length > 0)) {
              this.updateValorTextoAndLstCadastroAdicional(items, this.ctrl);
              this.dataSource = new MatTableDataSource<ICadastroListaDAL>(items);
              // this.subscribeToItemsUpdate(ano, this.ctrl, items);
              // }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'refresh.loaded', error.message);
            }
            this.isLoading = false;
          }
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /*Subscreve para receber atualizações de atividades filhas (edição/inserção/exclusão de itens). */
  // private subscribeToItemsUpdate(ano: number, ctrl: IAtividadeComponenteDAL, initialItems: any): void {
  // 	try {
  // 		// Necessário resgatar a ocorrenciano no momento do acesso. Se vier por parâmetro,
  // será a ocorrenciano da atividade pai e não a da filha que é desejada.
  // 		this.sp.activityStore
  // 			.pipe(
  // 				filter((keyValues) => {
  // 					const key = this.cnf.generateKey(
  // 						ctrl.AtividadeCadastroNo,
  // 						this.config.OcorrenciaNo.value,
  // 						ctrl.VariavelNo
  // 					);
  // 					return keyValues.key === key;
  // 				}),
  // 				map((m) => (m ? m.value : null))
  // 			)
  // 			.subscribe((items) => {
  // 				try {
  // 					if (items) {
  // 						const newItems = this.addGridItemFromMemory(this.ctrl, initialItems, [ items ]);
  // 						this.updateValorTextoAndLstCadastroAdicional(newItems, this.ctrl);
  // 						this.selectTab(this.ctrl);
  // 					}
  // 				} catch (error) {
  // 					this.log.Registrar(this.constructor.name, 'refresh.activityStore.subscribe', error.message);
  // 				}
  // 			});
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'subscribeToItemsUpdate', error.message);
  // 	}
  // }

  /*Se houver ValorTexto, desempacota no formato do grid.
   * // Em seguida, checa se config.gridItems tem item a ser carregado, que é preenchido no caso de edição de um grid,
   * // na qual o item editado é preenchido em gridItems.
  * Caso contrário, faz validação para ver se é associado a cadastro e carrega do serviço, pois, senão, deve ficar em branco.
  */
  protected getValorTextoOrLoadCadastroAdicional(
    ano: number,
    ctrl: IAtividadeComponenteDAL
  ): Observable<ICadastroListaDAL[]> {
    try {
      let obs: Observable<any> = of(null);
      const isCascade = this.lib.isCascade(ctrl);
      if (!this.global.IsNullOrEmpty(ctrl.ValorTexto)) {
        obs = this.lib.transformValorTextoInLstCadastroAdicional(ctrl);
        // Esses dados já serão acrescentados no refresh.subscribe
        // } else if (!this.global.IsArrayNullOrEmpty(this.config.gridItems[ctrl.AtividadeCadastroNo])) {
        // 	obs = observableOf(this.addGridItemFromMemory(ctrl, null));
      } else if (ctrl.CanAddInCadastro) {
        // Somente carregará os dados de Cadastro, caso a propriedade indique essa necessidade.
        obs = this.getCadastroAdicional(
          +ctrl.CadastroNo,
          +this.cnf.usuarioLogadoNo,
          +ctrl.VariavelRetornoNo,
          ctrl.Type,
          +ctrl.AtividadeComponenteNo,
          ctrl,
          this.valorFiltro,
          isCascade
        );
      }
      // A princípio, o ValorTexto nunca deverá conter item não desejado, protegido pelo Cascata.
      // if (isCascade) { // TODO: Assegurar que o item seja filtrado, pois, em algum momento,
      //  está preenchendo ValorTexto com todos os itens do cadastro.
      //   obs.mergeMap(m => {
      //     return m.filter(f => f.VariavelRetornoNo === ctrl.VariavelAssociadaNo);
      //   });
      // }
      return obs;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValorTextoOrLoadCadastroAdicional', error.message);
    }
    return of(null);
  }

  /*Verifica se existe um item modificado (retorno de edição de item de grid) e atualiza o valor caso haja
  * Quando um item de grid é acrescentado ou editado, a propriedade config.gridItems é preenchida.
  * A partir dela é que se deve criar ou modificar o grid.
  * atividadeCadastroAssociadaNo: É o cadastro associado ao grid.
  * gridItemsFromChildActivity: são os itens de transporte de edição/inserção de item, que estão retornando para o grid principal.
  */
  // protected addGridItemFromMemory(
  // 	ctrl: IAtividadeComponenteDAL,
  // 	items: Array<any>,
  // 	gridItemsFromChildActivity: any
  // ): Array<any> {
  // 	try {
  // 		if (!this.global.IsArrayNullOrEmpty(gridItemsFromChildActivity)) {
  // 			gridItemsFromChildActivity.forEach((gridItem) => {
  // 				try {
  // 					if (+ctrl.VariavelNo === +gridItem.gridVariavelNo) {
  // 						gridItem['label'] = this.lib.extractLabel(this.ctrl, gridItem);
  // 						// Novo item
  // 						items = items || [];
  // 						const find = items.find(
  // 							// tslint:disable: no-bitwise
  // 							// tslint:disable-next-line: triple-equals
  // 							(f) => (f.index | f.OcorrenciaNo) == (gridItem.OcorrenciaNo | gridItem.index)
  // 						);
  // 						if (!find) {
  // 							// Novo item
  // 							items.push(gridItem);
  // 						} else {
  // 							// Edição de item
  // 							items.forEach((f) => {
  // 								try {
  // 									if ((f.index | f.OcorrenciaNo) === (gridItem.OcorrenciaNo | gridItem.index)) {
  // 										for (const clm in f) {
  // 											if (clm && isNaN(f)) {
  // 												// f Não pode ser um campo puramente numérico, pois irá compor uma tag xml no futuro, que não pode iniciar com número.
  // 												const value = gridItem[clm];
  // 												f[clm] = this.global.getTypedValue(value).string;
  // 											}
  // 										}
  // 									}
  // 								} catch (error) {
  // 									this.log.Registrar(
  // 										this.constructor.name,
  // 										'addGridItemFromMemory.forEach.forEach',
  // 										error.message
  // 									);
  // 								}
  // 							});
  // 						}
  // 					}
  // 				} catch (error) {
  // 					this.log.Registrar(this.constructor.name, 'addGridItemFromMemory.forEach', error.message);
  // 				}
  // 			});
  // 			// O problema é que o item que retorna da atividade nova não é
  // reacrescentado no ValorTexto de imediato.
  // Se o item for limpo, o novo substituirá o anterior. Se nao for limpo, mesmo apos uma exclusao, um novo item retornará com os excluídos.
  // 			// this.config.getGridItems(this.ctrl?.AtividadeCadastroNo) = null;
  // Não pode ser zerado, ou somente o mais recente item é incorporado
  // 		}
  // 		return items;
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'addGridItemFromMemory', error.message);
  // 	}
  // 	return null;
  // }

  protected getColumns(ctrl: IAtividadeComponenteDAL): ICampoAdicional[] {
    try {
      return ctrl.LstCamposAdicionais.CamposAdicionais.filter((f) => {
        return f.IsVisible && f.Largura > 0;
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getColumns', error.message);
    }
  }

  getValue(col: ICampoAdicional, item: IAtividadeComponenteDAL): any {
    try {
      if (item && item[this.getClmName(col)]) {
        const value = item[this.getClmName(col)];
        let typed: ITypedValue;
        if (value && value.name) {
          typed = this.global.getTypedValue(value.name, 'en-US', 'pt-BR', 2);
        } else {
          typed = this.global.getTypedValue(value, 'en-US', 'pt-BR', 2);
        }
        switch (typed.type) {
          case EnTypedValue.Date:
            return this.global.FormatarData(typed.value);

          case EnTypedValue.Dollar:
          case EnTypedValue.Float:
          case EnTypedValue.Real:
          case EnTypedValue.Percent:
            return typed.string;

          case EnTypedValue.Integer:
            return typed.value;

          default:
            return typed.string;
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValue', error.message);
    }
    return null;
  }

  protected onCheckAll(items: any[], value: any): void {
    try {
      items.forEach((item) => (item.isSelected = value.checked));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onChecked', error.message);
    }
  }

  /* Quando um item é selecionado/desselecionado. */
  protected onCheck(item: any, checkbox: any): void {
    try {
      if (item.isSelected === undefined) {
        item.isSelected = true;
      } else {
        item.isSelected = checkbox.checked;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onCheck', error.message);
    }
  }

  hasItems(): boolean {
    try {
      return this.lstCadastroAdicional ? this.lstCadastroAdicional.length > 0 : false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasItems', error.message);
    }
  }

  protected getClmName(col: ICampoAdicional): string {
    try {
      return `V_${col.VariavelNo}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getClmName', error.message);
    }
  }

  onSearchChange(keyword: string): void {
    try {
      this.search = keyword;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSearchChange', error.message);
    }
  }

  onSearchEsc(event: any): void {
    try {
      this.search = '';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSearchEsc', error.message);
    }
  }

  /*Override  */
  isReadOnlyGrid(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return super.isReadOnly(ctrl) || !ctrl.CanEditItem;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isReadOnlyGrid', error.message);
    }
    return false;
  }

  /*Edita o item.
  * Preenche a propriedade que passará os valores preenchidos para a próxima Atividade.
  */
  onEditItem(
    $event: any,
    anoMain: number,
    anoChild: number,
    selectedItem: any,
    saveInList: boolean,
    gridVariavelNo: number,
    isReadOnly: boolean,
    readOnly: boolean
  ): void {
    try {
      // Sendo somente leitura, fará nada.
      if (isReadOnly) {
        return;
      }

      // Preenche a propriedade que passará os valores preenchidos para a próxima Atividade.
      // Junta com as demais variáveis presentes na atividade
      this.cnf.setDefaultFormControls(
        anoChild,
        this.lib.getDefaultFromGridAndActivity(selectedItem, this.formGroup)
      );
      // Essa propriedade deverá ser atualizada, para que, ao retornar para a tela
      //  do Grid que chamou a edição, os respectivos valores estejam atualizados.
      selectedItem.gridVariavelNo = gridVariavelNo;
      // this.config.setGridItem(ano, selectedItem);
      const ono = selectedItem.OcorrenciaNo || selectedItem.index || selectedItem.ocorrenciaNo;
      if (!this.global.isNullOrEmpty(ono)) {
        this.subs.sink = this.onEdit(
          $event,
          anoMain,
          anoChild,
          ono,
          gridVariavelNo,
          readOnly,
          saveInList).subscribe(res => this.updateGridUI(this.ctrl, res?.values));
      } else {
        this.subs.sink = this.openDialog(
          this.msg.CTR_GRID_EDIT_INVALID_INDEX_TITLE,
          this.msg.CTR_GRID_EDIT_INVALID_INDEX,
          'OK',
          '40vw',
          EnMaterialIcon.error_outline
        ).subscribe();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onEditItem', error.message);
    }
  }

  /*Acrescenta um item no grid. */
  onNewItem($event: any, anoMain: number, anoChild: number, saveInList: boolean, gridVariavelNo: number): void {
    try {
      const newItem = {
        index: -1,
        label: '',
        gridVariavelNo
      };
      // Essa lógica de preenchimento das colunas estava forçando um valor zerado no formulário de muitos itens. Qual o motivo desse item?
      // this.columns.forEach((f) => {
      // 	newItem[this.lib.getId(f.VariavelNo)] = '';
      // });
      // Preenche a propriedade que passará os valores preenchidos para a próxima Atividade.
      // Junta com as demais variáveis presentes na atividade.
      this.cnf.setDefaultFormControls(anoChild, this.lib.getDefaultFromGridAndActivity(newItem, this.formGroup));
      // Essa propriedade deverá ser atualizada, para que, ao retornar para a tela do
      // Grid que chamou a edição, os respectivos valores estejam atualizados.
      // this.config.setGridItem(ano, newItem);
      this.subs.sink = this.onNew(
        $event,
        anoMain,
        anoChild,
        gridVariavelNo,
        saveInList)
        .subscribe(res => {
          this.updateGridUI(this.ctrl, res?.values);
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onNewItem', error.message);
    }
  }

  private updateGridUI(ctrl: IAtividadeComponenteDAL, values: { [key: string]: any }): void {
    try {
      {
        // Ainda não entendi o motivo, mas essa linha é necessária para que o grid seja atualizado
        this.dataSource.filter = this.search;
        // Não surtiu efeito como o filter
        this.ref.detectChanges();
        this.updateCadastroDependends(ctrl, values);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateGridUI', error.message);
    }
  }

  /*Abre uma janela de confirmação com a quantidade de itens. Se confirmado, exclui os itens do Grid e do BD. */
  onDeleteItems($event: any, addInCadastro: boolean) {
    try {
      // const selectedItems = !this.lstCadastroAdicional
      //   ? []
      //   : this.lstCadastroAdicional.filter((f) => f.isSelected || false);
      const selectedItems = this.selection.selected;
      const count = selectedItems.length;
      if (count > 0) {
        const esse = count > 1 ? 'esses' : 'esse';
        const item = count > 1 ? 'items' : 'item';
        this.hasConfirmButton = true;
        this.message = {
          firstButtonLabel: 'Não',
          title: 'Confirmação',
          icon: 'fa-times',
          text: `Você tem certeza que quer excluir ${esse} ${count} ${item}`,
          acceptFunc: (result: boolean) => {
            try {
              if (result) {
                // Método de exclusão.
                this.ctrl.lstCadastroAdicional = this.removeDeleteItems(
                  this.lstCadastroAdicional,
                  selectedItems,
                  'index',
                  addInCadastro,
                  this.ctrl?.AtividadeCadastroNo
                );
                this.updateValorTextoAndLstCadastroAdicional(this.lstCadastroAdicional, this.ctrl, null, true);
                this.dataSource = new MatTableDataSource<ICadastroListaDAL>(this.lstCadastroAdicional);
                this.updateGridUI(this.ctrl, null);
                // this.ctrl.lstCadastroAdicional = this.lstCadastroAdicional;
                // this.ctrl.Valor = null; // Necessário para que o change funcione
                // this.ctrl.ValorTexto = '<Items></Items>';
                // Necessário zerar o valor texto, ou se forem excluídos todos os
                // itens, a lógica em lib.getValorTextoOrData fará com que o ValorTexto seja novamente carregado.
                // // Para forçar o change que recalculará eventuais formulas de grid (que dependem dessa lista carregada)
                // this.onDataLoaded.emit(this.ctrl);
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'acceptFunc', error.message);
            }
          }
        };
      } else {
        this.hasConfirmButton = false;
        this.message = {
          firstButtonLabel: 'OK',
          title: 'Atenção',
          icon: 'fa-times',
          text: `Nenhum item selecionado!`,
          // acceptFunc: () => {}
        };
      }
      this.onAlertDialog($event, this.message, this.hasConfirmButton);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDelete', error.message);
    }
  }

  /*OVERRIDE */
  protected removeDeleteItems(
    items: any[],
    selectedItems: any[],
    campoOcorrenciaNo: string,
    removeFromCadastro: boolean,
    atividadeCadastroNo: number
  ): any[] {
    try {
      // TODO: Verificar se, no novo conceito de limpar logo após a leitura, se haverá algum item.
      // const objGrid = this.config.getGridItems(atividadeCadastroNo, true);
      const objGrid = this.lstCadastroAdicional;
      // Exclui dados de comunicação com as edições dos itens. Somente para grid
      if (objGrid) {
        selectedItems?.forEach((f) => {
          try {
            const item = objGrid.find(
              (g) => g.gridVariavelNo === this.ctrl?.VariavelNo && g.index === f.index
            );
            if (item) {
              const idx = objGrid.indexOf(item);
              objGrid.splice(idx, 1);
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'deleteItems', error.message);
          }
        });
      }
      return super.removeDeleteItems(
        items,
        selectedItems,
        campoOcorrenciaNo,
        removeFromCadastro,
        atividadeCadastroNo
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteItems', error.message);
    }
  }

  /*Retornar o número de colunas do grid, mas considerando que haverá uma coluna a mais para o checkbox. */
  getColumnsLength(): number {
    try {
      const inc = this.hasItems() ? 1 : 0;
      return this.columns.length + inc;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getColumnsLength', error.message);
    }
    return 0;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource && this.dataSource.data ? this.dataSource.data.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else if (this.dataSource && this.dataSource.data && this.selection) {
      this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  /** calculate the displayed columns according screen size.  */
  getDisplay(displayedColumns: string[], offsetWidth: number): string[] {
    try {
      if (offsetWidth <= 480) {
        // const len = Math.min(2, displayedColumns.length - 1);
        return displayedColumns.slice(0, 2);
      }
      return displayedColumns;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDisplay', error.message);
    }
    return [];
  }


}
