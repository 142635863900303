import { IPersonRelationship } from './iperson-relationship';

export interface IPersonEducator extends IPersonRelationship {
	idEducador: any;
	// ocorrenciaNo: number;
	// idPessoa: any;
	// codigo: any;
	// titulo: any;
	// habilitado: boolean;
	// status: any;
	// idUsuario: number;
}
