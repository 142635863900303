import { IRelationship } from './addhere/irelationship';

export interface IProfessional extends IRelationship {
  // ocorrenciaNo: number;
  // idEscola: any;
  // codigo: any;
  // titulo: any;
  // habilitado: boolean;
  // nome: any;
  // email: string;
  // // calculados
  // status?: string;
}



