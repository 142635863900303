import { Component, OnInit, Input } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { EnClipType } from '../enum/en-clip-type';

@Component({
  selector: 'ui-smart-list-badge-image',
  templateUrl: './smart-list-badge-image.component.html',
  styleUrls: ['./smart-list-badge-image.component.css']
})
export class SmartListBadgeImageComponent implements OnInit {

  @Input() imageUrl: string;
  @Input() enClipType = EnClipType.circle;

  ENCLIPTYPE = EnClipType;
  readonly BASE_PATH = '/assets/img';

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
  }

  swapImage(img: any): void {
    try {
      if (!img) {
        return;
      }
      img.src = `${this.BASE_PATH}/person.png`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'swapImage', error.message);
    }
  }


}
