import { ConfigStateService } from '@medlogic/shared/state-config';
import { LibService } from './lib.service';
import { CalculatorService } from './calculator.service';
import { IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { JavascriptLib } from './javascript-lib.service';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { WebService } from '@medlogic/shared/shared-data-access';
import { EnTypedValue } from '@medlogic/shared/shared-interfaces';
import { CurrencyService } from './currency.service';
import { EnLanguage } from '../enum/en-language.enum';

import * as expr from 'expr-eval';


// TODO: Avaliar a biblioteca para aprimorar e tornar mais segura a execuç!ao de funções
// https://www.npmjs.com/package/expr-eval

// Atenção: Usar Regex para dar um split e restruturar essa classe. Dividir cada elemento num item de um array.
// (IF\[.*\])|(IF\(.*\))|(\#\d*\#)| "[^"\r\n]*"|(>=) http://gskinner.com/RegExr/

/**
 * Atenção: Para criar uma nova função é necessário:
 * 1) ExpressionFunction
 * 1.1)  Acrescentar a função em this.arrayFunctionOpen
 * 1.2) Acrescentar o fechamento da função em this.arrayFunctionClose
 * 1.3) Acrescentar a identificação da função em identificaESubstituiFuncao
 * 1.4) Criar o método que processa a função do tipo ProcessarFuncao[Nome_da_funcao]
 *
 * 2) EnumFormulaElementType
 * 2.1) Incluir uma enumeração para representar a função
 *
 * 3) Expression
 * 3.1) Acrescentar uma variável de regexp
 * 3.2) Acrescentar essa variável no array _expressions
 * 3.3) Acrescentar o teste da regexp em GetFullExpressionArray
 *
 * 4) CalculadoraRoot
 * 4.1) Acrescentar no switchcase o direcionamento para a função em CalculadoraRoot.processaElemento
 * author Daniel
 *
 */
@Injectable()
export class ExpressionFunctionService extends UnsubscribeOnDestroyAdapter {
  // TODO: ATENÇÃO: A expressão IF não poderá aceitar ( ou ) dentro de um ou mais parâmetros.
  protected arrayFunction: any[] = [
    // Lógica
    { name: 'IF', method: this.ProcessarFuncaoIF },
    { name: 'SWITCH', method: this.ProcessarFuncaoSWITCH },
    // Data e hora
    { name: 'DATEADD', method: this.ProcessarFuncaoDateAdd },
    { name: 'DATEADDYMD', method: this.ProcessarFuncaoDateAddYMD },
    { name: 'YO', method: this.ProcessarFuncaoYearsOld },
    { name: 'DATEDIFF', method: this.ProcessarFuncaoDATEDIFF },
    { name: 'HOURADD', method: this.ProcessarFuncaoADDHOUR },
    { name: 'DAYOFWEEK', method: this.ProcessarFuncaoDayOfWeek },
    { name: 'SUMHOURS', method: this.ProcessarFuncaoSumHours },
    { name: 'MAXDATE', method: this.ProcessarFuncaoMaxDate }, //
    { name: 'MINDATE', method: this.ProcessarFuncaoMinDate }, //
    { name: 'MINHOUR', method: this.ProcessarFuncaoMinMaxHour }, //
    { name: 'MAXHOUR', method: this.ProcessarFuncaoMinMaxHour }, //
    { name: 'OVERTIME', method: this.ProcessarFuncaoOvertime }, //
    { name: 'ABSENT', method: this.ProcessarFuncaoAbsent }, //
    { name: 'NIGHTHOUR', method: this.ProcessarFuncaoNightHour }, //
    { name: 'LASTDAYOFMONTH', method: this.ProcessarFuncaoLastDayOfMonth }, //
    { name: 'WEEKOFYEAR', method: this.ProcessarFuncaoWeekOfYear }, //
    { name: 'TODAY', method: this.ProcessarFuncaoToday }, //
    { name: 'FORMATDATETIME', method: this.ProcessarFuncaoFormatDateTime }, //
    // Grupo
    { name: 'MIN', method: this.ProcessarFuncaoMIN },
    { name: 'MAX', method: this.ProcessarFuncaoMAX },
    // Especiais
    { name: 'PROC', method: this.ProcessarFuncaoPROC, paramMethod: this.addParamVars, isAsync: true },
    { name: 'OCORRENCIA', method: this.ProcessarFuncaoOCORRENCIA, paramMethod: this.addParamVars, isAsync: true },
    { name: 'SCRIPT', method: this.ProcessarFuncaoScript }, // manter para compatibilidade com AS3.
    { name: 'COUNTITEMS', method: this.ProcessarFuncaoCountItems }, //
    { name: 'JAVASCRIPT', method: this.ProcessarFuncaoJavascript }, //
    { name: 'COUNTNOTES', method: this.ProcessarFuncaoCountNotes }, //
    { name: 'COUNTFILES', method: this.ProcessarFuncaoCountFiles }, //
    { name: 'NOTECHANGED', method: this.ProcessarFuncaoNoteChanged }, //
    { name: 'FILECHANGED', method: this.ProcessarFuncaoFileChanged }, //
    // STRING
    { name: 'COUNTSTR', method: this.ProcessarFuncaoCountStr }, //
    { name: 'LEFT', method: this.ProcessarFuncaoLeft }, //
    { name: 'RIGHT', method: this.ProcessarFuncaoRight }, //
    { name: 'MIDDLE', method: this.ProcessarFuncaoMiddle }, //
    { name: 'CONCAT', method: this.ProcessarFuncaoConcat }, //
    { name: 'REPLACE', method: this.ProcessarFuncaoReplace }, //
    { name: 'HASH', method: this.ProcessarFuncaoHash }, //
    { name: 'RANDOM', method: this.ProcessarFuncaoRandom }, //
    { name: 'RANDOMDIG', method: this.ProcessarFuncaoRandomDig }, //
    { name: 'QRCODE', method: this.ProcessarFuncaoQRCode }, //
    { name: 'BARCODE', method: this.ProcessarFuncaoBarCode }, //
    { name: 'ALWAYSUPDATE', method: this.ProcessarFuncaoAlwaysUpdate, paramMethod: this.addParamVars, isAsync: true }, //
    { name: 'CURRENCY', method: this.ProcessarFuncaoCurrency, paramMethod: this.addParamVars, isAsync: true }, //
    { name: 'NUMTOWORDS', method: this.ProcessarFuncaoNumToWords }, //
    { name: 'ISEMPTY', method: this.ProcessarFuncaoIsEmpty }, //
    // Matemática
    { name: 'DISTNORMP', method: this.ProcessarFuncaoDistNormP }, //
  ];

  constructor(
    protected log: LogService,
    protected global: GlobalService,
    protected config: ConfigStateService,
    protected cnfJson: ConfigJsonService,
    protected calculator: CalculatorService,
    protected lib: LibService,
    protected jsLib: JavascriptLib,
    protected ws: WebService,
    protected currencySrv: CurrencyService
  ) {
    super();
  }

  /* Necessário para permitir que as classes herdeiras sobrescrevam e adicionem seus respectivos arrays */
  getFunctionArray(): any[] {
    try {
      return this.arrayFunction;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFunctionArray', error.message);
    }
  }

  /* Retorna um objeto com referencias para todas as funções disponíveis */
  getAllFunctions(
    exp: expr.Expression,
    lstCtrlReferenciados: IAtividadeComponenteDAL[],
    ctrl: IAtividadeComponenteDAL
  ): any {
    try {
      const funcs = {};
      this.getFunctionArray().forEach((m) => {
        try {
          funcs[m.name] = (...args) => {
            return m.method.apply(this, args);
          }; // Envelope necessário para que o escopo de this seja preservado na função chamada.
          // Adiciona os métodos especiais, caso haja
          const variables = exp.variables();
          if (variables) {
            const paramMethod = m.paramMethod;
            if (paramMethod !== undefined) {
              paramMethod(funcs, exp, lstCtrlReferenciados, ctrl);
            }
          }
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getAllFunctions.forEach', error.message);
        }
      });
      return funcs;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAllFunctions', error.message);
    }
  }

  /*Verifica se todos os parâmetros fornecidos como argumentos são diferente de nulo, undefined ou vazio, ou vazio GE.
   * Mas se args for vazio, também será considerado válido, pois, pode haver cenário de chamada de função sem parâmetros.
   * */
  protected validateParams(acceptEmpty: boolean, ...args): boolean {
    if (!args[0] || args[0].length <= 0) { return true; }

    let isValid = true;
    try {
      args.every((e) => {
        if (this.global.isNullOrEmpty(e)) {
          isValid = false;
          return false;
        }
        if (this.global.IsNullOrEmptyGE(e, true)) {
          if (!acceptEmpty || (acceptEmpty && e === '')) {
            isValid = false;
            return false;
          }
        }
        return true;
      });
      return isValid;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'validateParams', error.message);
    }
    return false;
  }

  // TODO: Atenção: por enquanto, não será possível IF dentro de IF, ou função dentro de função
  // O problema é que quando um IF está dentro de outro, por exemplo, gera dois caracteres de fim )
  // E o programa acaba considerando que o parenteses da função interna é o final da externa e o da externa fica sem fim

  // Processará a função IF e retornará uma fórmula atualizada, substituindo a
  // função pelo resultado Then, ou Else conforme Condition
  ProcessarFuncaoIF(condition: string, strThen: string, strElse: string): string {
    try {
      if (!this.validateParams(false, condition) && !this.validateParams(true, strThen, strElse)) { return ''; }
      return this.calculator.evalCodition(condition) ? strThen : strElse;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoIF', error.message);
    }
    return '';
  }

  /* Permite receber pares de condição/valor se verdadeiro. O último "solteiro" pode ser apenas o valor default,
   * ou seja, se nenhuma das condições anteriores for satisfeita.
   * SWITCH([Condição 1]; [Valor se 1 verdadeiro]; [Condição 2]; [Valor se 2 verdadeiro]; [Valor Default]).
   * SWITCH(#2233#="GOTAS"; #454#*10; #2233#="COMPRIMIDO"; #454#*10; "0").
   */
  ProcessarFuncaoSWITCH(...args): string {
    try {
      const length = args.length;
      const isPar = length % 2 === 0;
      if (length < 2) {
        return 'Número insuficiente de parâmetros';
      }
      for (let i = 0; i < length; i = i + 2) {
        const condition = this.calculator.evalCodition(args[i]);
        if (condition && i < (!isPar && (length - 1))) {
          return this.calculator.evalFormula(args[i + 1].toString());
        }
      }
      if (!isPar) {
        return this.calculator.evalFormula(args[length - 1].toString());
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoIF', error.message);
    }
    return '';
  }

  // Complementa o objeto de parametros com variáveis especiais.
  // Nesse caso, a lista de controles referenciados e o valor selecionado, que são necessários para a proc
  protected addParamVars(
    funcObj: any,
    exp: expr.Expression,
    lstCtrlReferenciados: IAtividadeComponenteDAL[],
    ctrl: IAtividadeComponenteDAL
  ): void {
    try {
      const variables = exp.variables();
      // Acrescenta uma propriedade contendo o próprio controle
      // variables['CTRL'] = ctrl; Não funcionou, neste ponto
      if (variables) {
        // Necessário para PROC
        if (variables.length > 1) {
          // O primeiro parâmetro é o nome da função
          variables.forEach((v) => {
            if (v.substr(0, 2) === 'V_') {
              funcObj[v] = { lstCtrlReferenciados, index: v };
            }
          });
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractVars', error.message);
    }
  }

  // Processará a função DATEADD e retornará uma fórmula atualizada, substituindo a
  // função pelo resultado da soma de uma data.
  // Sintaxe: DATEADD("d"; 5; "XX/XX/XXXX")
  // d: dia
  // m: mês
  // y: ano
  // A data pode ser substituída por TODAY ou HOJE e será adicionado a partir da data do dia.
  // ATENÇÃO: Se usar TODAY() - com parentesis - não funciona. Da erro na captura da regularexpression
  ProcessarFuncaoDateAdd(datePart: string, amount: number, strDate: string): string {
    try {
      if (!this.validateParams(false, datePart, amount, strDate)) { return null; }
      let date: Date;
      if (
        strDate.toString().toUpperCase() === 'TODAY()' ||
        strDate.toString().toUpperCase() === 'HOJE()' ||
        strDate.toString().toUpperCase() === 'TODAY' ||
        strDate.toString().toUpperCase() === 'HOJE'
      ) {
        date = new Date(); // Pega a data do dia
      } else {
        const typed = this.global.getTypedValue(strDate);
        if (typed.type === EnTypedValue.Date) {
          date = typed.value;
        } else { return null; }
      }
      return this.global.FormatarData(this.global.DateAdd(datePart, amount, date));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoADDDATE', error.message);
    }
    return null;
  }

  /* Adiciona um número de anos e/ou meses e/ou dias à data escolhida.
  * Sintaxe: DATEADDYMD(1, 1, 1, "XX/XX/XXXX") para adicionar um ano, um mês e um dia.
  */
  ProcessarFuncaoDateAddYMD(numOfYears: number, numOfMonths: number, numOfDays: number, strDate: string): string {
    try {
      if (!this.validateParams(false, numOfYears, numOfMonths, numOfDays, strDate)) { return null; }
      let date: Date;
      strDate = this.global.RemoverAspas(strDate);
      if (
        strDate.toString().toUpperCase() === 'TODAY()' ||
        strDate.toString().toUpperCase() === 'HOJE()' ||
        strDate.toString().toUpperCase() === 'TODAY' ||
        strDate.toString().toUpperCase() === 'HOJE'
      ) {
        date = new Date(); // Pega a data do dia
      } else {
        const typed = this.global.getTypedValue(strDate);
        if (typed.type === EnTypedValue.Date) {
          date = typed.value;
        } else { return null; }
      }
      date = this.global.DateAdd('y', numOfYears, date);
      date = this.global.DateAdd('m', numOfMonths, date);
      date = this.global.DateAdd('d', numOfDays, date);
      return this.global.FormatarData(date);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoADDDATE', error.message);
    }
    return null;
  }

  // Adiciona duas horas
  // uma das horas pode ser negativa
  // Se usar a expressão NOW() pegará a hora atual (conforme relógio do computador do usuário e não do servidor!)
  // Sintaxe: HOURADD(01:00; -00:30) Resultado 00:30 ou HOURADD(01:00; NOW()) Resultado hora atual + 1 hora
  ProcessarFuncaoADDHOUR(strBase: string, strAdicao: string): string {
    try {
      if (!this.validateParams(false, strBase, strAdicao)) {
        return null;
      }
      // Expressao NOW()
      if (
        strBase.toUpperCase() === 'NOW()' ||
        strBase.toUpperCase() === 'AGORA()' ||
        strBase.toUpperCase() === 'NOW' ||
        strBase.toUpperCase() === 'AGORA'
      ) {
        const now: Date = new Date();
        strBase = this.global.FormatarHora(now.getHours() + now.getMinutes() / 60);
      } else {
        const typed = this.global.getTypedValue(strBase);
        if (typed.type === EnTypedValue.Date) {
          strBase = typed.value;
        } else { return null; }
      }
      if (
        strAdicao.toString().toUpperCase() === 'NOW()' ||
        strAdicao.toString().toUpperCase() === 'AGORA()' ||
        strAdicao.toString().toUpperCase() === 'NOW' ||
        strAdicao.toString().toUpperCase() === 'AGORA'
      ) {
        const nowAdd: Date = new Date();
        strAdicao = this.global.FormatarHora(nowAdd.getHours() + nowAdd.getMinutes() / 60);
      } else {
        const typed2 = this.global.getTypedValue(strAdicao);
        if (typed2.type === EnTypedValue.Date) {
          strAdicao = typed2.value;
        } else { return null; }
      }
      return this.global.AddHour(strBase, strAdicao);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoADDHOUR', error.message);
    }
    return null;
  }

  // Retorna o dia da semana sendo domingo 1
  // É possível especificar um parâmetro:
  // DAYOFFWEEK("t";01/01/2000) retorna o dia em texto Exemplo: Domingo, Segunda-feira, etc
  // DAYOFFWEEK("n";01/01/2000) retorna o número baseado em 1
  // Aceita a expressão TODAY como data para resgatar a data do dia
  // DAYOFFWEEK("t": TODAY)
  ProcessarFuncaoDayOfWeek(key: string, strDate: string): string {
    try {
      if (!this.validateParams(false, key, strDate)) { return null; }
      let date: Date;
      if (
        strDate.toString().toUpperCase() === 'TODAY()' ||
        strDate.toString().toUpperCase() === 'HOJE()' ||
        strDate.toString().toUpperCase() === 'TODAY' ||
        strDate.toString().toUpperCase() === 'HOJE'
      ) {
        date = new Date(); // Pega a data do dia
      } else {
        const typed = this.global.getTypedValue(strDate);
        if (typed.type === EnTypedValue.Date) {
          date = typed.value;
        } else { return null; }
      }
      const day = date.getDay() + 1;
      switch (key) {
        case 'N':
          return day.toString();
        default:
        case 'T':
          return this.global.DiaDaSemana(day - 1);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoDayOfWeek', error.message);
    }
    return null;
  }

  /* Processa a idade (tempo em anos de uma data a partir da data atual). 
  * Atenção: espera chegada no formato dd/MM/yyyy ou Date.
  */
  ProcessarFuncaoYearsOld(date: string | Date): number {
    try {
      // let data = new Date();
      // if (date.toString().length > 7) {
      //   const day = parseInt(date.toString().substring(0, 2), 10);
      //   const month = parseInt(date.toString().substring(2, 4), 10);
      //   const year = parseInt(date.toString().substring(4, date.toString().length), 10);
      //   data = new Date(year, month - 1, day);
      // }
      if (!this.validateParams(false, date)) { return null; }
      const typed = this.global.getTypedValue(date);
      if (typed.type === EnTypedValue.Date) {
        return this.global.GetYearsOld(typed.value);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoYearsOld', error.message);
    }
    return null;
  }

  /* Renderiza um Script AS3.
  //Atenção: Pode gerar brechas de segurança!!!
  //Caso use bibliotecas, necessário acrescentar os import */
  ProcessarFuncaoScript(arrayParam: any[]): string {
    try {
      if (arrayParam.length > 0) {
        let script = '';
        arrayParam.forEach((par) => (script += par.value + ';'));
        script = this.global.RemoverAspas(script.replace(';;', ';').replace('==', '='));
        // tslint:disable-next-line: no-eval
        const result: any = eval(script);
        return result.toString();
      } else {
        return null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoScript', error.message);
    }
    return null;
  }

  /* Soma várias horas passadas como parâmetro no formato hh:MM
  //tipos: m retorna um inteiro em minutos, s retorna um inteiro em segundos,
  // h retorna um double de horas fracionadas, hhMM retorna string hh:MM
  */
  ProcessarFuncaoSumHours(tipo: string, ...args): string {
    try {
      if (!this.validateParams.apply(this, [true, tipo].concat(args))) { return null; }
      if (args.length > 0) {
        const hours: any[] = new Array();
        const script = '';
        args.forEach((a) => {
          const strHora: string = a;
          if (this.global.IsHourHHHMM(strHora)) {
            hours.push(strHora);
          }
        });
        const sumHours: string = this.global.SumHoursHHMM(tipo, hours).toString();
        return sumHours;
      } else {
        return null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoSumHours', error.message);
    }
    return null;
  }

  /*
   * Recebe um array de datas no padrao ddMMYYYY e retorna a menor ou maior entre elas
   * @param arrayParam
   * @param _isMin determina se retornará a Data Mínima ou Máxima
   * @return menor data ddMMYYYY
   *
   */
  ProcessarFuncaoMinDate(...arrayParam): string {
    // _isMin: boolean = true
    try {
      if (!this.validateParams.apply(this, [false].concat(arrayParam))) { return null; }
      if (arrayParam.length > 0) {
        const dts: any[] = new Array();
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < arrayParam.length; i++) {
          const strDt: string = this.extractParam(arrayParam[i]); // this.global.RemoverAspas(arrayParam[i].value);
          const typed = this.global.getTypedValue(strDt);
          if (typed.type === EnTypedValue.Date) {
            dts.push(typed.value);
          }
        }
        const dt: Date = true ? this.global.MINDATE(dts) : this.global.MAXDATE(dts);
        return this.global.DateToddMMYYYY(dt);
      } else {
        return null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoMinDate', error.message);
    }
    return null;
  }

  ProcessarFuncaoMaxDate(...arrayParam): string {
    // _isMin: boolean = true
    try {
      if (!this.validateParams.apply(this, [false].concat(arrayParam))) { return null; }
      if (arrayParam.length > 0) {
        const dts: any[] = new Array();
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < arrayParam.length; i++) {
          const strDt: string = this.extractParam(arrayParam[i]); // this.global.RemoverAspas(arrayParam[i].value);
          const typed = this.global.getTypedValue(strDt);
          if (typed.type === EnTypedValue.Date) {
            dts.push(typed.value);
          }
        }
        const dt: Date = false ? this.global.MINDATE(dts) : this.global.MAXDATE(dts);
        return this.global.DateToddMMYYYY(dt);
      } else {
        return null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoMinDate', error.message);
    }
    return null;
  }

  /*
   * Recebe um array de horas no padrao hhmm e retorna a menor ou maior entre elas
   * @param arrayParam
   * @param _isMin determina se retornará a hora Mínima ou Máxima
   * @return menor data hhmm
   *
   */
  ProcessarFuncaoMinMaxHour(arrayParam: any[], isMin: boolean = true): string {
    try {
      if (!this.validateParams(false, arrayParam)) { return null; }
      if (arrayParam.length > 0) {
        const hrs: any[] = new Array();
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < arrayParam.length; i++) {
          const strHr: string = this.extractParam(arrayParam[i]); // this.global.RemoverAspas(arrayParam[i].value);
          if (this.global.IsHourHHHMM(strHr)) {
            hrs.push(strHr);
          }
        }
        const hr: string = isMin ? this.global.MINHOUR(hrs) : this.global.MAXHOUR(hrs);
        return hr;
      } else {
        return null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoMinMaxHour', error.message);
    }
    return null;
  }

  /*
   * Calcula o número de horas extra
   * @param _tipo: define o tipo de retorno pode ser HHMM, h para hora fracionada, m para minutos
   * @param _hhMMTempoExpediente: Tempo total do expediente oficial diário a ser
   * cumprido// ATENÇÃO: Se _permitirCompensacao = false esse parâmetro deverá
   * conter o horário de saída do expediente e não o tempo total de expediente
   * @param _hhMMTempoTolerancia: Tempo de tolerância quanto a entrada e/ou saída
   * @param _hhMMTempoRegistradoAlmoco: Tempo total registrado pelo funcionário como almoço
   * @param _hhMMTempoRegistradoEntrada: Hora registrada pelo funcionário de entrada
   * @param _hhMMTempoRegistradoSaida: Hora registrada pelo funcionário de saída
   * @param _permitirCompensacao: Indica se é permitido o regime de Compensação.
   *  Se for permitido as horas de atraso "compensam" as horas após o expediente.
   * Sem Compensação registra tanto a falta quanto horas extras.
   * @return
   *
   */
  ProcessarFuncaoOvertime(
    tipo: string,
    saidaHHMM: string,
    entradaHHMM: string,
    expedienteHHMM: string,
    toleranciaHHMM: string,
    almocoHHMM: string,
    permitirCompensacao: boolean
  ): string {
    try {
      // let _permitirCompensacao: boolean = this.extractParam(arrayParam[6], true) == "TRUE";
      if (
        !this.validateParams(
          false,
          tipo,
          entradaHHMM,
          saidaHHMM,
          expedienteHHMM,
          toleranciaHHMM,
          almocoHHMM,
          permitirCompensacao
        )
      ) {
        return null;
      }
      const hr: string = this.global
        .OVERTIME(
          tipo,
          entradaHHMM,
          saidaHHMM,
          expedienteHHMM,
          toleranciaHHMM,
          almocoHHMM,
          permitirCompensacao
        )
        .toString();
      return hr;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoOvertime', error.message);
    }
    return null;
  }

  /*
   * Calcula o número de horas falta
   * @param _tipo: define o tipo de retorno pode ser HHMM, h para hora fracionada, m para minutos
   * @param _hhMMTempoExpediente: Tempo total do expediente oficial diário a ser
   *  cumprido// ATENÇÃO: Se _permitirCompensacao = false esse parâmetro deverá conter
   *  o horário de entrada do expediente e não o tempo total de expediente
   * @param _hhMMTempoTolerancia: Tempo de tolerância quanto a entrada e/ou saída
   * @param _hhMMTempoRegistradoAlmoco: Tempo total registrado pelo funcionário como almoço
   * @param _hhMMTempoRegistradoEntrada: Hora registrada pelo funcionário de entrada
   * @param _hhMMTempoRegistradoSaida: Hora registrada pelo funcionário de saída
   * @param _permitirCompensacao: Indica se é permitido o regime de Compensação.
   * Se for permitido as horas de atraso "compensam" as horas após o expediente.
   * Sem Compensação registra tanto a falta quanto horas extras.
   * @return
   *
   */
  ProcessarFuncaoAbsent(
    tipo: string,
    saidaHHMM: string,
    entradaHHMM: string,
    expedienteHHMM: string,
    toleranciaHHMM: string,
    almocoHHMM: string,
    permitirCompensacao: boolean
  ): string {
    try {
      // let _permitirCompensacao: boolean = this.extractParam(arrayParam[6], true) == "TRUE";
      if (
        !this.validateParams(
          false,
          tipo,
          entradaHHMM,
          saidaHHMM,
          expedienteHHMM,
          toleranciaHHMM,
          almocoHHMM,
          permitirCompensacao
        )
      ) {
        return null;
      }
      const hr: string = this.global
        .ABSENT(
          tipo,
          entradaHHMM,
          saidaHHMM,
          expedienteHHMM,
          toleranciaHHMM,
          almocoHHMM,
          permitirCompensacao
        )
        .toString();
      return hr;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoAbsent', error.message);
    }
    return null;
  }

  /*
   * Calcula o número de horas para cálculo de adicional noturno
   * @param _tipo: define o tipo de retorno pode ser HHMM, h para hora fracionada, m para minutos
   * @param _hhMMTempoRegistradoSaida: Hora registrada pelo funcionário de saída
   * @param _hhMMHoraInicioAdicionalNoturno: Contabilizar como adicional noturno a partir deste horário
   * @return
   *
   */
  ProcessarFuncaoNightHour(tipo: string, saidaHHMM: string, horaInicioAdicionalNoturno: string): string {
    try {
      if (!this.validateParams(false, tipo, saidaHHMM, horaInicioAdicionalNoturno)) { return null; }
      const hr: string = this.global.NIGHTHOUR(tipo, saidaHHMM, horaInicioAdicionalNoturno).toString();
      return hr;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoNightHour', error.message);
    }
    return null;
  }

  /*
   * Retorna o último dia do mês extraído por _strDate conforme o _tipoRetorno
   * @param _tipoRetorno "ddMMyyyy" retorna uma data dd/MM/yyyy  "MMddyyyy"
   * para MM/dd/yyyy, "yyyyMMdd" para yyyy-MM-dd, dd apenas o número do dia, MMYYYY para MM/yyyy
   * @param _strDate pode ser uma data, pode ser um número do mês e pode ser a palavra-chave TODAY (para pegar a data do dia)
   * @return
   *
   */
  ProcessarFuncaoLastDayOfMonth(tipo: string, strDate: string): string {
    try {
      if (!this.validateParams(false, tipo, strDate)) { return null; }
      let dt;
      const typed = this.global.getTypedValue(strDate);
      if (typed.type === EnTypedValue.Date) {
        dt = typed.value;
      } else { return null; }
      const hr: string = this.global.LastDayOfMonth(tipo, dt).toString();
      return hr;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoLastDayOfMonth', error.message);
    }
    return null;
  }

  /*
   *Inicialmente projetado para contar os itens de um checkboxlist, mas também seria útil para um Grid.
   * @param arrayParam
   * @return
   * ATENÇÃO: OS ITENS SELECIONADOS SERÁ MAIS DIFÍCIL IDENTIFICAR, POIS, ELES NÃO SÃO RESGATADOS ATRAVÉS DO CTRL.VALOR
   */
  ProcessarFuncaoCountItems(selectedItems: boolean, ...arrayParam): string {
    try {
      if (!this.validateParams.apply(this, [false, selectedItems].concat(arrayParam))) { return null; }
      if (arrayParam.length > 0) {
        if (
          this.extractParam(arrayParam[0], true) === this.global.EMPTY_CHAR.toUpperCase() ||
          this.extractParam(arrayParam[0], true) === this.global.NOITEM_TEXT.toUpperCase()
        ) {
          return '0';
        } else {
          const items: any[] = arrayParam[0].value.split(';');
          return items.length.toString();
        }
      } else {
        return '-1';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoCountItems', error.message);
    }
    return null;
  }

  /*  Processa a diferença entre duas datas
  // exemplo de uso
  //let dt1:Date = new Date(2006, 1, 22, 23, 40, 1);
  //let dt2:Date = new Date(2008, 2, 22, 23, 50, 50);
  //dateDiff(dt1, dt2);
  //dateDiff(dt1, dt2, "t"); Retorna um texto da diferenças em dias, horas, minutos
  //dateDiff(dt1, dt2, "d"); Retorna um número em dias fracionados
  //dateDiff(dt1, dt2, "h"); Retorna um número em horas fracionadas
  //dateDiff(dt1, dt2, "m"); Retorna um número em minutos fracionados
  //dateDiff(dt1, dt2, "s"); Retorna um número em segundos
  */
  ProcessarFuncaoDATEDIFF(key: string, strDate1: string, strDate2: string): string {
    try {
      if (!this.validateParams(false, key, strDate1, strDate2)) { return null; }
      key = this.global.RemoverAcentos(key);
      let date1: Date;
      let date2: Date;
      if (
        strDate1.toString().toUpperCase() === 'TODAY()' ||
        strDate1.toString().toUpperCase() === 'HOJE()' ||
        strDate1.toString().toUpperCase() === 'TODAY' ||
        strDate1.toString().toUpperCase() === 'HOJE'
      ) {
        date1 = new Date(); // Pega a data do dia
      } else {
        const typed = this.global.getTypedValue(strDate1);
        if (typed.type === EnTypedValue.Date) {
          date1 = typed.value;
        } else { return null; }
      }
      if (
        strDate2.toString().toUpperCase() === 'TODAY()' ||
        strDate2.toString().toUpperCase() === 'HOJE()' ||
        strDate2.toString().toUpperCase() === 'TODAY' ||
        strDate2.toString().toUpperCase() === 'HOJE'
      ) {
        date2 = new Date(); // Pega a data do dia
      } else {
        const typed2 = this.global.getTypedValue(strDate2);
        if (typed2.type === EnTypedValue.Date) {
          date2 = typed2.value;
        } else { return null; }
      }
      return this.global.dateDiff(date1, date2, key);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoDATEDIFF', error.message);
    }
    return null;
  }

  /*
   * Retorna um número inteiro que representa o número da semana, no ano, para a data fornecida.
   * Aceita a expressão TODAY para pegar a data do dia
   * @param arrayParam
   * @return
   *
   */
  ProcessarFuncaoWeekOfYear(strDate: string): string {
    try {
      if (!this.validateParams(false, strDate)) { return null; }
      let date: Date;
      if (strDate.toString().toUpperCase() === 'TODAY()' || strDate.toString().toUpperCase() === 'HOJE()') {
        date = new Date(); // Pega a data do dia
      } else {
        const typed = this.global.getTypedValue(strDate);
        if (typed.type === EnTypedValue.Date) {
          date = typed.value;
        } else { return null; }
      }
      return this.global.WeekOfYear(date).toString();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoWeekOfYear', error.message);
    }
    return null;
  }

  /*
   * Permite executar e resgatar o resultado, como string, de uma função javascript.
   * Atenção: essa função deve ser declarada, ou referenciada, na página html que contém o Flash.
   * @param arrayParam O primeiro parâmetro deve ser o nome da função e os demais,
   * os parâmetros (podem ser variáveis da tela a serem substituídas)
   * @return
   *
   */
  ProcessarFuncaoJavascript(...arrayParam): string {
    try {
      if (!this.validateParams(false, arrayParam)) { return null; }

      const jsMethodOrJsScript = arrayParam[0];
      arrayParam.splice(0, 1);
      if (!this.global.isNullOrEmpty(jsMethodOrJsScript)) {
        if (this.jsLib[jsMethodOrJsScript]) {
          if (arrayParam.length > 0) {
            return this.jsLib[jsMethodOrJsScript].apply(this, arrayParam);
          } else {
            return this.jsLib[jsMethodOrJsScript]();
          }
        }
      } else {
        // tslint:disable-next-line: no-eval
        return eval(jsMethodOrJsScript);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoJavascript', error.message);
    }
    return '';
  }

  /*
   *Retorna o dia de hoje, conforme parâmetros
   * @param arrayParam
   * dd/mm/yyyy hh:mm:ss
   * dd/mm/yyyy
   * dd/mm
   * d dia do mês numérico
   * D Dia por extenso
   * m número do mês de 1 a 12
   * M mês por extenso
   * h hora
   * m minuto
   * s segundo
   * hh:mm
   * mm:ss
   * y ano numérico
   * w dia da semana de 1 a 7
   * W dia da semana por extenso
   * As combinações são aceitas, e podem ser misturadas com caracteres
   * Exemplo: W ", " d " de " M " de " y  Resultado: segunda-feira, 01 de janeiro de 2015
   * @return
   * OBS: as partes que são strings deve estar entre aspas para que não haja risco de serem substituídas.
   *
   */
  ProcessarFuncaoToday(formato: string = 'dd/mm/yyyy'): string {
    try {
      const dt = this.global.FormatDateHour(new Date(), formato);
      return dt;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoToday', error.message);
    }
    return null;
  }

  /*
   * Atenção: A data deve ser fornecida no formato dd/mm/yyyy hh:mm:ss
   * O primeiro parâmetro deve ser uma data válida e o segundo uma string de formatação, conforme opções abaixo:
   * Pode usar a palavra chave TODAY para pegar a data/hora atual automaticamente
   * @param arrayParam
   * dd/mm/yyyy hh:mm:ss
   * dd/mm/yyyy
   * dd/mm
   * d dia do mês numérico
   * D Dia por extenso
   * m número do mês de 1 a 12
   * M mês por extenso
   * h hora
   * m minuto
   * s segundo
   * hh:mm
   * mm:ss
   * y ano numérico
   * w dia da semana de 1 a 7
   * W dia da semana por extenso
   * As combinações são aceitas, e podem ser misturadas com caracteres
   * Exemplo: W ", " d " de " M " de " y  Resultado: segunda-feira, 01 de janeiro de 2015
   * @return
   * OBS: as partes que são strings deve estar entre aspas para que não haja risco de serem substituídas.
   *
   */
  ProcessarFuncaoFormatDateTime(dtStr: string, formato: string): string {
    try {
      if (!this.validateParams(false, dtStr, formato)) {
        return null;
      }
      let dt: Date;
      if (typeof dtStr === 'string' && this.global.trim(dtStr).toUpperCase() === 'TODAY') {
        // Data de hoje
        dt = new Date();
      } else if (this.global.IsDate(dtStr)) {
        // Data fornecida
        if (dtStr.toString().indexOf(':') < 0) {
          // Acrescenta uma hora caso não exista
          dtStr = dtStr + ' 00:00:00';
        }
        const typed = this.global.getTypedValue(dtStr);
        if (typed.type === EnTypedValue.Date) {
          dt = typed.value;
        } else {
          return 'Data inválida!';
        }
      } else {
        return 'Data inválida!';
      }
      return this.global.FormatDateHour(dt, formato);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoFormatDateTime', error.message);
    }
    return null;
  }

  /*
   *Resgata o número de Notas anexas na Ocorrencia.
   * @param arrayParam
   * @return
   *
   */
  public ProcessarFuncaoCountNotes(): string {
    try {
      // return this.config.CountNotes.toString();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoCountNotes', error.message);
    }
    return null;
  }

  /*
   *Retornará false no carregamento inicial.
   * Quando o valor for modificado, retornará o número para o qual foi modificado.
   * @return
   *
   */
  public ProcessarFuncaoFileChanged(): string {
    try {
      return 'false';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoFileChanged', error.message);
    }
    return null;
  }

  /*
   *Retornará false no carregamento inicial.
   * Quando o valor for modificado, retornará o número para o qual foi modificado.
   * @return
   *
   */
  public ProcessarFuncaoNoteChanged(): string {
    try {
      return 'false';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoNoteChanged', error.message);
    }
    return null;
  }

  /*
   * Resgata o número de Files anexas na Ocorrencia.
   * @param arrayParam
   * @return
   *
   */
  public ProcessarFuncaoCountFiles(): string {
    try {
      // return this.config.instance.CountFiles.toString();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoCountFiles', error.message);
    }
    return null;
  }

  /*
   *Conta o número de vezes que uma String a ser procurada é encontrada numa outra string
   * @param arrayParam
   * COUNTSTR(#444#; "Teste")
   * Contará o número de vezes que Teste é encontrado na variável.
   * @return
   *
   */
  ProcessarFuncaoCountStr(str: string, strToBeFounded: string) {
    try {
      if (!str || !strToBeFounded) { return -1; }

      if (!this.validateParams(false, str, strToBeFounded)) { return null; }
      str = this.global.RemoverAspas(str);
      strToBeFounded = this.global.RemoverAspas(strToBeFounded);
      return this.global.CountChar(str, strToBeFounded);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoCountStr', error.message);
    }
    return -1;
  }

  /*
   *Retorna o número de carateres mais a esquerda de uma string.
   * @param arrayParam
   * =LEFT(#444#; 2)
   * Retornará os dois primeiros caracteres da variável fornecida.
   * @return
   *
   */
  ProcessarFuncaoLeft(str: string, count: number): string {
    try {
      if (!this.validateParams(false, str, count)) { return null; }
      return this.global.Left(str, count);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoLeft', error.message);
    }
    return null;
  }

  /*
   *Retorna o número de carateres mais a direita de uma string.
   * @param arrayParam
   * =RIGHT(#444#; 2)
   * Retornará os dois últimos caracteres da variável fornecida.
   * @return
   *
   */
  ProcessarFuncaoRight(str: string, count: number): string {
    try {
      if (!this.validateParams(false, str, count)) { return null; }
      return this.global.Right(str, count);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoRight', error.message);
    }
    return null;
  }

  /*
   *Retorna o número de carateres mais entre as posições de início e fim (inclusive elas) de uma string.
   * @param arrayParam
   * =MIDDLE(#444#; 2; 5)
   * Retornará os caracteres na posição 2, 3, 4 e 5 da variável fornecida.
   * ATENÇÃO: a primeira posição é zero.
   * @return
   *
   */
  ProcessarFuncaoMiddle(str: string, indexStart: number, indexEnd: number): string {
    try {
      if (!this.validateParams(false, str, indexStart, indexEnd)) {
        return null;
      }
      indexEnd += 1;
      return str.substring(indexStart, indexEnd);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoMiddle', error.message);
    }
    return null;
  }

  /* Concatena todos os parametros. */
  ProcessarFuncaoConcat(separador: string, ...args): string {
    try {
      if (!this.validateParams.apply(this, [false].concat(args))) {
        return '';
      }
      return args.join(separador);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoConcat', error.message);
    }
    return null;
  }

  /* Substitui todas as ocorrências de uma string por outra. */
  ProcessarFuncaoReplace(originalText: string, textToSearch: string, textToReplace: string): string {
    try {
      if (!this.validateParams(true, originalText, textToSearch, textToReplace)) {
        return '';
      }
      return this.global.ReplaceAll(originalText, textToSearch, textToReplace);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoReplace', error.message);
    }
    return originalText;
  }

  /* Cria uma hash, ou seja, uma identidade digital utilizando todos os parâmetros fornecidos.
   * Retorna -1 em caso de erro.
  */
  ProcessarFuncaoHash(...args): number {
    try {
      return Array.from(args)
        // tslint:disable-next-line: no-bitwise
        .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoReplace', error.message);
    }
    return -1;
  }

  /* Returns a random number with the exact number of digits given as param.
   * chars: include symbols to indicate the desired standard:
   * a: lowercase letters
   * A: uppercase letters
   * 0: numbers
   * !: simbols
   * example: RANDOMDIG(10, "aA0!")
  */
  ProcessarFuncaoRandomDig(numOfDigits: number, chars: string = '0'): string {
    try {
      return this.randomString(numOfDigits, chars);
      // Array.from({ length: numOfDigits }, () => Math.floor(Math.random() * 9)));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoRandomDig', error.message);
    }
    return '';
  }

  /** Generate random string.
   * chars: include symbols to indicate the desired standard:
   * a: lowercase letters
   * A: uppercase letters
   * #: numbers
   * !: simbols
   * example: "aA0!"
   */
  protected randomString(length: number, chars: string): string {
    try {
      let mask = '';
      if (chars.indexOf('a') > -1) { mask += 'abcdefghijklmnopqrstuvwxyz'; }
      if (chars.indexOf('A') > -1) { mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; }
      if (chars.indexOf('0') > -1) { mask += '0123456789'; }
      if (chars.indexOf('!') > -1) { mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\'; }
      let result = '';
      for (let i = length; i > 0; --i) { result += mask[Math.floor(Math.random() * mask.length)]; }
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'randomString', error.message);
    }
    return '';
  }

  /** Returns a random number between min and max, included. */
  ProcessarFuncaoRandom(min: number, max: number): number {
    try {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoRandom', error.message);
    }
    return -1;
  }

  /* Marks the field to the QR Code generation.
  * addBaseUrl true will take the project baseUrl and add it to the beginning of the url.
  * if generateId it will generate a random hash and add it to the code mark.
  * If addOcorrenciaNo will add the current OcorrenciaNo to the end.
  */
  ProcessarFuncaoQRCode(url: string, addBaseUrl: boolean, generateId: boolean, addOcorrenciaNo: boolean): string {
    try {
      const baseUrl = addBaseUrl ? this.cnfJson.baseUrlIntranet : '';
      url = this.global.urlJoin([baseUrl, url]);
      // FIXME: Por algum motivo na substituição de variáveis # está se tornando V. Necessário retornar pois isso
      // é o que evita problemas com o roteamento app angular (SPA)
      url = url.replace('/V/', '/#/');
      const id = generateId ? `${this.ProcessarFuncaoHash(this.ProcessarFuncaoRandomDig(10))}` : '-1';
      const ono = addOcorrenciaNo ? `${this.config.OcorrenciaNo.value}` : '-1';
      return `QRCODE;${url};${ono};${id}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoQRCode', error.message);
    }
    return '';
  }

  /* Marks the field to BarCode Generation. If  codeNum not setted, it will automatically generates
  * a EAN13 number.
  */
  ProcessarFuncaoBarCode(url: string, codeNum: string = null): string {
    try {
      codeNum = codeNum || this.ProcessarFuncaoRandomDig(13);
      return `BARCODE;${codeNum}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoReplace', error.message);
    }
    return '';
  }

  /** It is used at the Coditional ReadOnly. If this formula is used, the
   * ctrl need to be checked to isAlwaysUpdate and the formula must be always
   * evaluated, even if there is initial value.
   */
  ProcessarFuncaoAlwaysUpdate(ctrl: IAtividadeComponenteDAL): string {
    try {
      ctrl.isAlwaysUpdate = true;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoReplace', error.message);
    }
    return this.global.NAO_RECALCULAR_ITEM;
  }

  /* Convert Real time currencies.
   * It uses: http://openexchangerates.github.io/money.js/
   * from and to must follow:
   * https://docs.openexchangerates.org/docs/supported-currencies
   * sample =CURRENCY(1000, "BRL", "USD")
   */
  public ProcessarFuncaoCurrency(ctrl: IAtividadeComponenteDAL, value: number, strFrom: any, to: any): string {
    try {
      if (!this.validateParams(false, value, strFrom, to)) {
        return '';
      } else {
        this.subs.sink = this.currencySrv
          .convert(value, strFrom, to)
          .subscribe(currency => {
            ctrl.AsyncValue.next(this.global.ConvertToBrazilianNumber(currency));
          });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoCurrency', error.message);
    }
    return '';
  }

  /** Converte um número para o valor por extenso. */
  public ProcessarFuncaoNumToWords(value: number, enLanguage: EnLanguage = EnLanguage.ptBR): string {
    try {
      const varValue = this.global.getTypedValue(value).value;
      return this.currencySrv.numberToWords(varValue, enLanguage);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoNumToWords', error.message);
    }
    return '';
  }

  /** Checa se o valor é vazio */
  public ProcessarFuncaoIsEmpty(value: string): boolean {
    try {
      return this.global.isNullOrEmpty(value);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoIsEmpty', error.message);
    }
    return false;
  }

  /* Cálculo da Distribuição Normal Padrão: média = 0 e desvio padrão 1 */
  public ProcessarFuncaoDistNormP(z: number): string {
    try {
      const phiMax = 1 / Math.sqrt(2 * Math.PI);
      const teta = Math.exp(-1 / 2 * Math.pow(z, 2));
      return (phiMax * teta).toString();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoDistNormP', error.message);
    }
    return '';
  }

  /// Considerará +x% como adicionar x% ao resultado (multiplicará por 1.x/100)
  /// e -x% como reduzir x% ao resultado (multiplicará por 1-x/100
  public parsePercentual(formula: string): string {
    try {
      if (!this.validateParams(false, formula)) {
        return null;
      }
      while (formula.indexOf('%') >= 0) {
        let operadorEncontrado = false;
        const indexPercentual = formula.indexOf('%');
        let percentual = '';
        let valor = 0;
        for (let i = indexPercentual; i >= 0; i--) {
          if (formula.substr(i, 1) === '+') {
            operadorEncontrado = true;
            percentual = formula.substring(i, indexPercentual);
            valor = 1 + Number(formula.substring(i, indexPercentual + 1)) / 100;
            formula = formula.replace(percentual, '*' + valor.toString());
          } else if (formula.substr(i, 1) === '-') {
            operadorEncontrado = true;
            percentual = formula.substring(i, indexPercentual + 1);
            valor = 1 - Number(formula.substring(i + 1, indexPercentual)) / 100;
            formula = formula.replace(percentual, '*' + valor.toString());
          }
        }
      }
      return formula;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ParsePercentual', error.message);
    }
    return '';
  }

  /* _lstControlesReferenciados espera um único controle igual à combobox da qual se extrairá a lista detalhada */
  ProcessarFuncaoPROC(ctrl: IAtividadeComponenteDAL, value: any, obj: any): string {
    try {
      if (!this.validateParams(false, ctrl, value, obj)) {
        return null;
      }
      const ref = obj.lstCtrlReferenciados[0];
      const ca = this.global.alwaysReturnArray(ref.lstCadastroAdicional);
      if (ca && ca.length > 0) {
        const filter = ca.find((f) => {
          const index = `V_${ref.VariavelRetornoNo}`;
          return this.global.isEqual(f[index], value);
        });
        if (filter) {
          ctrl.AsyncValue.next(filter[obj.index]);
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoPROC', error.message);
    }
    return this.global.NAO_RECALCULAR_ITEM;
  }

  // _lstControlesReferenciados espera um único controle igual à combobox da qual se extrairá a lista detalhada
  ProcessarFuncaoMIN(...arrayParam): string {
    try {
      if (!this.validateParams.apply(this, [false].concat(arrayParam))) {
        return null;
      }
      if (arrayParam.length > 0) {
        const obj: number[] = arrayParam.map((item2, index, array) => {
          return this.global.ConvertToAmericanNumber(item2.toString(), 2);
        });
        const item = obj.sort((a, b) => a - b)[0];
        return item.toString(); // this.global.ConvertToBrazilianNumber(_obj.toString(), 2);
      }
      return this.global.EMPTY_CHAR;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoMIN', error.message);
    }
    return this.global.EMPTY_CHAR;
  }

  ProcessarFuncaoMAX(...arrayParam): string {
    try {
      if (!this.validateParams.apply(this, [false].concat(arrayParam))) {
        return null;
      }
      if (arrayParam.length > 0) {
        const obj: number[] = arrayParam.map((item2, index, array) => {
          return this.global.ConvertToAmericanNumber(item2.toString(), 2);
        });
        const item = obj.sort((a, b) => a - b);
        const max: number = item[obj.length - 1];
        return max.toString(); // this.global.ConvertToBrazilianNumber(_max.toString(), 2);
      }
      return this.global.EMPTY_CHAR;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoMAX', error.message);
    }
    return this.global.EMPTY_CHAR;
  }

  /* Retorna o número da Ocorrência ativa. */
  public ProcessarFuncaoOCORRENCIA(ctrl: IAtividadeComponenteDAL): string {
    try {
      const ono$ = this.config.OcorrenciaNo;
      // Delayed subscription to give time to AtividadeView.updateComponentesFromFormControls subscribe the ctrl.
      setTimeout(() =>
        this.subs.sink = ono$.subscribe(ono => {
          ctrl.AsyncValue.next(ono);
        }), 500);
      return ono$.value.toString();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoOCORRENCIA', error.message);
    }
    return this.global.EMPTY_CHAR;
  }

  /*
   *Extrai o parâmetro, removendo as aspas e em seguida removendo espaços antes e depois (trim).
   * @param _objParam
   * @param _blToUpperCase
   * @return
   * ATENÇÃO: AS CONDIÇÕES NÃO DEVEM ELIMINAR AS ASPAS EM FUNÇÃO DO CENÁRIO "Não informado"!=="Não informado"
   */
  protected extractParam(
    objParam: any,
    blToUpperCase: boolean = false,
    blRemoveStartAndEndQuotes: boolean = true
  ): string {
    try {
      let param: string = objParam.value ? this.global.trim(objParam.value) : this.global.trim(objParam);
      if (blRemoveStartAndEndQuotes) {
        param = this.global.trim(this.global.RemoverAspas(param));
      }
      if (blToUpperCase) {
        return param.toUpperCase();
      } else {
        return param;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractParam', 'Error: ' + error.message);
    }
    return null;
  }
}
