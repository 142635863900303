import { RedisService } from '@medlogic/shared/shared-data-access';
import { SnackbarNotificationService } from '@medlogic/shared/ui/ui-snackbar-notification';
import { GlobalService, IAppState } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LogService } from '@medlogic/shared/shared-interfaces';

import * as LogActions from './log.actions';


@Injectable()
export class LogEffects {

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppState>,
    private notificationSrv: SnackbarNotificationService,
    private redisSrv: RedisService,
  ) { }

  openAlert$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(LogActions.openAlert),
        mergeMap((action) => this.notificationSrv.open(action.messageHtml, action.title)),
        // tap((action) => alert(`${action.messageHtml}, 'Prescrição salva'`)),
        switchMap((res: any) => [
          ...[LogActions.openAlertSuccess({ res })],
        ]),
        catchError(error => of(LogActions.openAlertFailure({ error })))
      );
  });

  addLog$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(LogActions.addLog),
        mergeMap((action) => this.redisSrv.postLogPrescription(+action.log.id, action.log.msg)),
        switchMap((res: any) => [
          ...[LogActions.addLogSuccess()],
        ]),
        catchError(error => of(LogActions.openAlertFailure({ error })))
      );
  });

  // loadLogs$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(loadLogs),
  //     withLatestFrom(this.store),
  //     mergeMap(([never, state]) => {
  //       return this.logSrv.getAll(state?.tenant?.selectedTenant?.cadPacienteNo)
  //         .pipe(
  //           toArray(),
  //           mergeMap(logs => {
  //             // FIXME: ATENÇÃO: Esse cálculo está errado, pois, precisa considerar as medicações que se repetem em horários diferentes
  //             return this.intervMedSrv.getMedicationCount(state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo)
  //               .pipe(
  //                 map(count => {
  //                   return logs.map(m => ({ ...m, calcMedicationsCount: count[+m.codigo] } as ILog));
  //                 }));
  //           }),
  //           switchMap((logs: ILog[]) => [
  //             logs ? loadLogsSuccess({ logs }) : logFail(null),
  //             setIsLoading({ isLoading: false })
  //           ]),
  //           catchError((e: any) => {
  //             console.log(e);
  //             return of(logFail(null));
  //           })
  //         );
  //     })
  //   )
  // );


}
