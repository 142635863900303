import { VitalSignCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { toArray, filter } from 'rxjs/operators';
import { IVitalSign } from '@medlogic/shared/shared-interfaces';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../pwa/service/config-pwa-medlogic.custom.service';

@Injectable()
export class IntervencoesVitalSigns {

    constructor(
        protected glb: GlobalService,
        protected cnf: ConfigPwaMedLogicService,
        protected log: LogService,
        protected vitalSignSrv: VitalSignCustomService,
    ) {
        try {
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'constructor', error.message);
        }
    }

    /* Para cada um dos pacientes, insere a lista de Vital Signs registrados, para o período escolhido.
      * Será populada a propriedade lstIntervencoesVitalSigns de cada um dos pacientes.
      */
    insertVitalSignsForPatients(ano: number, patient: IPatient, startDt: Date, endDt: Date): Observable<IVitalSign[]> {
        try {
            // Necessário preservar o valor para que o retorno da função Assíncrona não pegue dado de outro paciente do loop.
            const patientCod = patient.codigo;
            return this.vitalSignSrv
                .getAll(ano, startDt, endDt).pipe(
                    filter(f =>
                        this.glb.isEqual(f.codigoPaciente, patientCod)
                    ),
                    toArray());
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'insertVitalSignsForPatients', error.message);
        }
    }

    /* Retorna S para tomou, N para Não tomou, - não está prescrito nesse dia. */
    getStatus(patient: IPatient, item: IVitalSign, date: Date): string {
        try {
            if (this.glb.isEqualIgnoreTime(item.dataavaliacao, date)) {
                return 'S';
            } else {
                return '-';
            }
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getStatus', error.message);
        }
    }

    /* Gera a chave que identifica o paciente e sinal vital. */
    generateKey(patientCodigo: string, vitalSign: IVitalSign): string {
        try {
            const data = this.glb.RetornarAllXsdDate(vitalSign.dataavaliacao);
            return `${patientCodigo}_${data}`;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'generateKey', error.message);
        }
    }


}
