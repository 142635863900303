import { Injectable } from '@angular/core';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { GlobalService, ICostCenter, LocalLibService, LogService } from '@medlogic/shared/shared-interfaces';
import { CostCenterService } from './cost-center.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class CostCenterCustomService extends CostCenterService {

    constructor(
      cadastroSrv: CadastroService,
      glb: GlobalService,
      cnf: ConfigStateService,
      lib: LocalLibService,
      log: LogService) {
           super(cadastroSrv, glb, cnf, lib, log);
        try {
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'constructor', error.message);
        }
    }

    getByName(ano: number, costCenterName: string): Observable<ICostCenter[]> {
        try {
            // Alguns pacientes não estão sendo encontrados pelo nome.
            const strFilter = `V_27991:${costCenterName}`;
            return this.getFiltered(ano, strFilter);
        } catch (error) {
             this.log.Registrar(this.constructor.name, 'getByName', error.message);
        }
        return of(null);
    }

    getByPatientId(ano: number, patientId: number): Observable<ICostCenter[]> {
        try {
            const strFilter = `V_30022:${patientId}`;
            return this.getFiltered(ano, strFilter);
        } catch (error) {
             this.log.Registrar(this.constructor.name, 'getByPatientId', error.message);
        }
        return of(null);
    }


}
