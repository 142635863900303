import { Injectable } from '@angular/core';
import { BehaviorSubjectCadastro } from './behavior-subject-cadastro';

@Injectable({
  providedIn: 'root'
})
export class GeFormProviderService {

  cadastroDependents$ = new BehaviorSubjectCadastro(null);

}
