import { ConfigStateService } from '@medlogic/shared/state-config';
import { Component, OnInit, Input } from '@angular/core';
import { EnumAtividadeTipo } from '../../shared/enum/enum-atividade-tipo.enum';
import { EnBubbleEvent } from '../../shared/enum/en-bubble-event.enum';
import { mergeMap, map, tap, catchError } from 'rxjs/operators';
import { OcorrenciaDAL } from '../../shared/model/dal/ocorrencia-dal';
import { AtividadeDAL } from '../../shared/model/dal/atividade-dal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AtividadeComponenteDAL } from '../../shared/model/dal/atividade-componente-dal';
import { CalculadoraService } from '../../shared/service/calculadora.service';
import { DadoDAL } from '../../shared/model/dal/dado-dal';
import { CalculatorService } from '../../shared/service/calculator.service';
import { ValidatorService } from '../../shared/service/validator.service';
import { ActionService } from '../../shared/service/action.service';
import { CalculadoraConditionService } from '../../shared/service/calculadora-condition.service';
import { NavigationService } from '../../shared/service/navigation.service';
import { LibService } from '../../shared/service/lib.service';
import { ExecucaoTarefaDAL } from '../../shared/model/dal/execucao-tarefa-dal';
import { UsuarioDAL } from '@medlogic/shared/shared-data-access';
import { ProcessoDAL } from '../../shared/model/dal/processo-dal';
import { MatDialog } from '@angular/material/dialog';
import { AtividadeCadastroViewComponent } from '../atividade-cadastro-view/atividade-cadastro-view.component';
import { IPasso } from '../../shared/interface/ipasso';
import { iif, Observable } from 'rxjs';
import { of } from 'rxjs';
import { EnMaterialIcon } from '@medlogic/shared/gecore';
import { WsTrackerService } from '@medlogic/shared/shared-data-access';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { LogService, IOcorrencia, IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { EnActivityType } from '@medlogic/shared/gecore';
import { ActivatedRoute, Params } from '@angular/router';
import { IAtividade } from '../../shared/interface/iatividade';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'lib-atividade-stand-alone',
  templateUrl: './atividade-stand-alone.component.html',
  styleUrls: ['./../atividade-view/atividade-view.component.css']
})
export class AtividadeStandAloneComponent extends AtividadeCadastroViewComponent implements OnInit {

  @Input() processoNo: number;

  // static ngInjectableDef = undefined;
  versaoInterfaceWeb = 'POR_ENQUANTO_NAO_UTILIZADO';
  isLoadingAfterComplete = false;

  constructor(
    global: GlobalService,
    config: ConfigStateService,
    cnfJson: ConfigJsonService,
    route: ActivatedRoute,
    log: LogService,
    ocorrenciaDAL: OcorrenciaDAL,
    atividadeDAL: AtividadeDAL,
    fb: FormBuilder,
    atividadeComponenteDAL: AtividadeComponenteDAL,
    calc: CalculadoraService,
    dadoDAL: DadoDAL,
    calculator: CalculatorService,
    validator: ValidatorService,
    action: ActionService,
    calcCond: CalculadoraConditionService,
    navigation: NavigationService,
    lib: LibService,
    confirmationService: ConfirmationService,
    wsTracker: WsTrackerService,
    execucaoTarefa: ExecucaoTarefaDAL,
    usuarioDAL: UsuarioDAL,
    processoDAL: ProcessoDAL,
    matDialog: MatDialog,
    msg: MsgPtBR,
    messageService: MessageService
  ) {
    super(
      global,
      config,
      cnfJson,
      route,
      log,
      ocorrenciaDAL,
      atividadeDAL,
      fb,
      atividadeComponenteDAL,
      calc,
      dadoDAL,
      calculator,
      validator,
      action,
      calcCond,
      navigation,
      lib,
      confirmationService,
      wsTracker,
      execucaoTarefa,
      usuarioDAL,
      processoDAL,
      matDialog,
      msg,
      messageService
    );
  }

  /* override  */
  ngOnInit(): void {
    try {
      super.ngOnInit();
      this.activityType = EnActivityType.StandAlone;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  // override
  /* Extrai os parâmetros passados na url/rota */
  protected getUrlParams(): void {
    try {
      // TODO: ATENÇÃO: Houve atualização em AtividadeView.getUrlParams avaliar se deve ser atualizad

      // Os parametros estão sendo passados diretamente aos componentes
      this.subs.sink = this.route.params.subscribe((params: Params) => {
        try {
          if (params.uno) {
            this.config.usuarioLogadoNo = +params.uno;
          }
          this.saveInList = this.saveInList !== null ? this.saveInList : (params.saveinlist !== null ? params.saveInList : false);
          this.config.isReadOnly = this.isReadOnly ? this.isReadOnly : (params.readonly) ? params.readonly === 'true' : this.config.isReadOnly;
          this.config.processoNo = this.processoNo ? this.processoNo : (params.pno ? +params.pno : null);
          // Necessário zerar a ocorrencia se não for fornecida, pois, é um requisito para se criar nova.
          this.config.OcorrenciaNo.next(this.ocorrenciaNo ? this.ocorrenciaNo : (params.ono ? +params.ono : -1));
          this.getPrimeiraAtividadeAndRefresh(this.config.usuarioLogadoNo, this.config.processoNo);
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getUrlParams.subscribe', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /* Descobre a atividade relacionada ao processo.  */
  protected getPrimeiraAtividadeAndRefresh(usuarioNo: number, processoNo: number): void {
    try {
      this.isLoading.next(true);
      // Primeiro fará o login do usuário
      this.subs.sink = this.usuarioDAL
        .getDoLoginUsuarioNoChecarVersao(usuarioNo, this.versaoInterfaceWeb)
        .pipe(
          mergeMap((usuario) => {
            return this.atividadeDAL.getPrimeiraAtividade(usuario.UsuarioNo, processoNo);
          }),
          mergeMap((atividade) => {
            return this.atividadeDAL.getAtividadePorAtividadeNoProcessoNo(
              atividade.UsuarioNo,
              atividade.AtividadeNo,
              processoNo
            );
          }),
          mergeMap((a) => {
            this.isLoading.next(false);
            this.updateRodapeAndProperties(this.config.OcorrenciaNo.value, processoNo, -1, a);
            // O refresh não pode ser no ngOnInit, pois, quando a rota não muda,
            // mas apenas os parâmetros da rota, esse método não é chamado novamente.
            // Se o parâmetro for atualizado, atualiza o carregamento da tela
            return this.refresh(
              EnumAtividadeTipo.Editar,
              this.config.ModeloAtividadeNo,
              this.config.OcorrenciaNo.value,
              this.config.usuarioLogadoNo,
              this.defaultFormControls || this.config.getDefaultFormControls(a.AtividadeNo)
            );
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getPrimeiraAtividadeAndRefresh', error.message);
    }
  }

  /* override
    * Recarrega a tela */
  protected refresh(
    enAtividadeTipo: EnumAtividadeTipo,
    ano: number,
    ono: number,
    uno: number,
    defaultFormControls: any,
    addToHistory: boolean = true,
    pno: number = -1,
    tno: number = -1
  ): Observable<IAtividade> {
    try {
      this.tabActivedId = 0;
      return super.refresh(enAtividadeTipo, ano, ono, uno, defaultFormControls, true, pno, tno);
      // this.fillControls(enAtividadeTipo, ano, ono, uno, defaultFormControls).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /*
   * override
   * Principal diferença é a criação da ocorrência, caso não tenha sido criada.
   * Salva todos os dados, a partir dos componentes.
   * Outra maneira de fazer seria resgatar os valores de formGroup.
   * Apesar de disparar as validações, salvará mesmo se houver campos pendentes, pois
   * as ações de grid mudam de página e os dados precisam ser salvos para o retorno.
   * ATENÇÃO: Sempre que o dado for salvo é necessário limpar o cache de carregamento do mesmo dado, caso haja.
   * Caso contrário, na tentativa de recarregar o dado por uma segunda vez, trará os dados da primeira.
   * processoNo poderá ser nulo uma vez que os cadastros não são associados a processos.
   */
  salvar(
    enBubbleEvent: EnBubbleEvent,
    componentes: IAtividadeComponenteDAL[],
    tarefaNo: number,
    ocorrenciaNo: number,
    usuarioNo: number,
    processoNo: number
  ): Observable<IOcorrencia> {
    try {
      if (ocorrenciaNo <= 0 && this.config.OcorrenciaNo.value <= 0) {
        return this.ocorrenciaDAL.IniciarNova(usuarioNo, processoNo).pipe(
          mergeMap((s) => {
            try {
              this.config.OcorrenciaNo.next(s.OcorrenciaNo);
              this.config.TarefaNo = s.TarefaNo;
              this.tarefaNo = s.TarefaNo;
              this.ocorrenciaNo = s.OcorrenciaNo;
              this.isLoadingAfterComplete = enBubbleEvent === EnBubbleEvent.activityComplete;
              return super.salvar(
                enBubbleEvent,
                componentes,
                this.config.TarefaNo,
                this.config.OcorrenciaNo.value,
                usuarioNo,
                processoNo
              );
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'salvar.mergeMap', error.message);
            }
            return of(null);
          })
        );
      } else {
        this.isLoadingAfterComplete = enBubbleEvent === EnBubbleEvent.activityComplete;
        return super.salvar(enBubbleEvent, componentes, tarefaNo, ocorrenciaNo, usuarioNo, processoNo);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'salvar', error.message);
      this.isLoadingAfterComplete = false;
    }
    return of(null);
  }

  /* override
   * Evento chamado após a conclusão do salvamento da atividade. Poderá ser sobrescrito. */
  protected afterSave(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    fg: FormGroup,
    isEditMode: boolean
  ): Observable<IOcorrencia> {
    try {
      if (enBubbleEvent === EnBubbleEvent.activityComplete) {
        if (this.formGroup.invalid) {
          return this.showInvalidFormMessage()
            .pipe(
              mergeMap((res) => {
                if (res) {
                  return of({ OcorrenciaNo: ono, TarefaNo: tno, AtividadeNo: ano, fg, isEditMode } as IOcorrencia);
                } else {
                  return of(null);
                }
              })
            );
        } else {
          // Concluir a atividade
          return this.doActivityComplete(tno, ano, ono, uno, pno)
            .pipe(
              mergeMap((passo) => super.afterSave(enBubbleEvent, tno, ano, ono, uno, pno, componentes, fg, isEditMode))
            );
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave override', error.message);
    }
  }

  /* Realiza a conclusão da Atividade. */
  protected doActivityComplete(tno: number, ano: number, ono: number, uno: number, pno: number): Observable<{ passo: IPasso, ocorrencia: IOcorrencia }> {
    try {
      this.isLoadingAfterComplete = true;
      // TODO: checar porque Variaveis de identificação não estão sendo preenchidas
      const varId1 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao1No}`);
      const varId2 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao2No}`);
      this.atividade.CalcVariavelIdentificacao1Valor = varId1 ? varId1.value : -1;
      this.atividade.CalcVariavelIdentificacao2Valor = varId2 ? varId2.value : -1;
      this.atividade.OcorrenciaNo = ono;
      this.atividade.TarefaNo = tno;

      const openDialog$ = (passo: IPasso) =>   // Significa que há uma mensagem configurada após a tarefa
        this.onWindowDialog(
          this.atividade.CalcAtribuicaoTitulo,
          this.atividade.CalcAtribuicaoMensagemPublico,
          '600px',
          EnMaterialIcon.check
        ).afterClosed().pipe(map(() => passo));

      const emitAfterCompleted$ = (passo: IPasso) => {
        this.isLoadingAfterComplete = false;
        // FIXME: POTENCIALMENTE IMPACTANTE NA CONCLUSÃO E RISCO DE DUPLICAÇÃO
        const obj = {
          values: this.formGroup.getRawValue(),
          tno,
          ano,
          ono,
          uno,
          pno
        };
        this.eventAfterCompleted.emit(obj);
        return of(passo);
      }

      const error$ = () => catchError((err, obs) => {
        console.log(err);
        return of(err);
      });

      return this.ocorrenciaDAL.setOcorrenciaNotificarConclusaoSalvamento(pno, ono, uno, ano)
        .pipe(
          mergeMap(() => this.processoDAL.getProximoPasso(this.atividade, pno, uno, tno, ono)
            .pipe(
              mergeMap(passo => iif(
                () => !!this.atividade.CalcAtribuicaoMensagemPublico,
                openDialog$(passo),
                emitAfterCompleted$(passo)
              )),
              map(passo => ({ passo, ocorrencia: null })),
              error$()
            )
          ),
          error$()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doActivityComplete', error.message);
    }
    return of(null);
  }
}
