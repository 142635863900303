import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IRotina,
  LocalLibService,
} from '@medlogic/shared/shared-interfaces';
import {
  map,
  publishReplay,
  refCount,
  filter,
  catchError,
  toArray,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import {
  CadastroService,
  ModelComponent,
} from '@medlogic/shared/shared-data-access';
import { RotinaService } from './rotina.service';

const error = () =>
  catchError((err, obs) => {
    console.log(err);
    return of(err);
  });

@Injectable({
  providedIn: 'root',
})
export class RotinaCustomService extends RotinaService {
  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    log: LogService,
    protected lib: LocalLibService,
    protected modelComponent: ModelComponent
  ) {
    super(http, cadastroSrv, glb, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  periodCheck = (
    date: Date,
    start: Date,
    end: Date,
    frequency: string,
    intervalo: number
  ): boolean => {


    const ddMMYYYYDate = this.glb.dateToddMMYYYY(date);

    /// if (start.toJSON() == "2023-04-24T11:00:00.000Z") console.log(date, start, end, frequency, intervalo, ddMMYYYYDate);

    if (!start && !end) {
      // check if unscheduled progress
      return false;
    }
    if (!this.glb.isBetweenIgnoreTime(date, start, end)) return false;
    if (this.glb.dateToddMMYYYY(date) === this.glb.dateToddMMYYYY(start))
      return true;
    if (this.glb.dateToddMMYYYY(date) === this.glb.dateToddMMYYYY(end))
      return true;
    switch (frequency) {
      case 'Intercalado':
        for (
          let i = 0;
          i <= this.glb.dateDiffDays(start, date);
          i += intervalo + 1
        ) {
          const result = this.glb.addDays(start, i);
          // console.log(this.glb.dateToddMMYYYY(result));
          if (ddMMYYYYDate === this.glb.dateToddMMYYYY(result)) return true;
        }
        return false;
      case 'Diário':
        return this.glb.isBetweenIgnoreTime(date, start, end);
      case 'Semanal':
        for (let i = 0; i <= this.glb.dateDiffDays(start, date); i += 7) {
          const result = this.glb.addDays(start, i);
          if (ddMMYYYYDate === this.glb.dateToddMMYYYY(result)) return true;
        }
        break;
      case 'Quinzenal':
        for (let i = 0; i <= this.glb.dateDiffDays(start, date); i += 15) {
          const result = this.glb.addDays(start, i);
          if (ddMMYYYYDate === this.glb.dateToddMMYYYY(result)) return true;
        }
        break;
      case 'Mensal':
        for (
          let i = 0;
          i <=
          (date.getFullYear() - start.getFullYear()) * 12 +
            (date.getMonth() - start.getMonth());
          i += 1
        ) {
          const result = this.glb.addMonthsUTC(start, i); // o que acontece se adicionar um mês no dia 31 quando o próximo mês não tem 31 dias?, vai dar invalid date provavelmente, e não vai ser adicionado ao array possibleDates
          // TODO: arrumar a lógica para casos mensais
          if (ddMMYYYYDate === this.glb.dateToddMMYYYY(result)) return true;
        }
        break;
      default:
        return false;
    }
    return false;
  };

  getByFloor(ano: number, floor, dtStart: Date = null, dtEnd: Date = null): any {
    this.cadastroNo = ano;
    const startDate = dtStart || new Date(1900, 0, 1);
    const endDate = dtEnd || new Date(2500, 0, 1);
    const filterStr = `V_109159:${floor}`;
    return this.getFiltered(this.cadastroNo, filterStr, startDate, endDate).pipe(
      filter((x: any) => x.tipoAtividade == 'Coletiva' && this.periodCheck(new Date(), new Date(x.inicio), new Date(x.fim), x.periodo, x.aCadaXDiasXaQuantidadediasseremPulados)),
      // generate one entry for each hour
      map((x: any) => {
        const hourRegex = /([0-2][0-3]:[0-5][0-9])|([0-1][0-9]:[0-9][0-9])|(^[0-9]:[0-9]+)/g; // source: https://stackoverflow.com/questions/21520502/need-regex-for-matching-hhmm-and-hhmm-time-format
        const cleanHours = x.horarios.match(hourRegex);
        const possibleDates = cleanHours.map((hour) => {
          const date = new Date();
          date.setHours(parseInt(hour.split(':')[0]));
          date.setMinutes(parseInt(hour.split(':')[1]));
          return date;
        }
        );
        return possibleDates.map((date) => ({
          ...x,
          date,
          }));
      }),
      // flatten array
      mergeMap((x: any) => x),
      toArray(),
    );
  }

  getByMedlogicId(ano: number, codigoPaciente: string, dtStart: Date = null, dtEnd: Date = null): Observable<any> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_108718:${codigoPaciente}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate) || of(null);

      // .pipe(
      //   tap((x) => console.log("tap: ", patientId, x)),
      //   filter(x => x.tipoAtividade == 'Individual'),
      //   map(x => ({
      //     ...x,
      //     date: new Date(),
      //     start: new Date(x.inicio),
      //     end: new Date(x.inicio),
      //     frequency: x.periodo,
      //     intervalo: x.aCadaXDiasXaQuantidadediasseremPulados
      //  })),
      //   filter((x) => this.periodCheck(x.date, x.start, x.end, x.frequency, x.intervalo)),
      //   // now calculate the closest to now()
      //   map((x) => {
      //     const now = new Date();
      //     const hourRegex = /([0-2][0-3]:[0-5][0-9])|([0-1][0-9]:[0-9][0-9])|(^[0-9]:[0-9]+)/g; // source: https://stackoverflow.com/questions/21520502/need-regex-for-matching-hhmm-and-hhmm-time-format
      //     const cleanHours = x.horarios.match(hourRegex);

      //     if (cleanHours.length > 1) {
      //       return cleanHours.reduce(
      //         (a, b) => {
      //           const aDate = new Date(x.date);
      //           const bDate = new Date(x.date);
      //           aDate.setHours(parseInt(a.split(':')[0]));
      //           aDate.setMinutes(parseInt(a.split(':')[1]));
      //           a = {...a, date: aDate};
      //           bDate.setHours(parseInt(b.split(':')[0]));
      //           bDate.setMinutes(parseInt(b.split(':')[1]));
      //           b = {...b, date: bDate};
      //           return Math.abs(aDate.getTime() - now.getTime()) < Math.abs(bDate.getTime() - now.getTime()) ? a : b;
      //         }, cleanHours[0]
      //     );
      //     } else if (cleanHours.length == 1) {
      //       return cleanHours[0];
      //     } else {
      //       return null;
      //       }
      //     }),
      // )
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByIdAndPeriod', error.message);
      return of(null)
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<any> {
    try {
       this.cadastroNo = ano;
       startDate = startDate || new Date(1900, 0, 1);
       endDate = endDate || new Date(2500, 0, 1);
       return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        toArray(),
       );
    } catch (error) {
        this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
}


public getCadastro(cadastroNo) {
  return this.cadastroSrv.getCadastro(cadastroNo, this.lstVariaveis).pipe(
    map((c) => this.toAttribute(c)),
    publishReplay(),
    refCount()
  );
}


  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`.
   * Também filtra especificamente a dataavaliacao dentro do período.
   */
  protected getFiltered(
    cadastroNo: number,
    strFilter: string,
    startDate: Date,
    endDate: Date
  ): Observable<IRotina> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map((c) => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }


  /* OBS: Usado apenas pelo card-prescription. */
  getFromCadastroFiltro(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastroComFiltro(
          cadastroNo,
          this.lstVariaveis,
          filtro,
          true,
          startDate,
          endDate
        )
        .pipe(
          map((c) => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'getFromCadatro',
        error.message
      );
    }
    return of(null);
  }

  /* Check se está fora da referência. */
  protected isOutOfRef(value: number, minRef: number, maxRef: number): boolean {
    try {
      return value < minRef || value > maxRef;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isOutOfRef', error.message);
    }
    return false;
  }

  mapObjToRotina(obj: {
    [key: string]: string | Date | number;
  }): Observable<IRotina> {
    try {
      return of(obj).pipe(this.mapTo());
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'mapObjToVitalSign',
        error.message
      );
    }
    return of(null);
  }

  private mapTo = () => map((c: any) => this.toAttribute(c));

}
