import { Injectable } from '@angular/core';
import {
  GlobalService, LogService,
  LocalLibService,
  ConfigJsonService,
  UnsubscribeOnDestroyAdapter,
} from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FhirCadastroService extends UnsubscribeOnDestroyAdapter {

  constructor(
    protected glb: GlobalService,
    protected cnf: ConfigJsonService,
    protected lib: LocalLibService,
    protected log: LogService,
    private http: HttpClient) {
    super();
    try { //
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  get(url: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<ArrayBuffer> {
    return this.http.get(url, options);
  }

 

}
