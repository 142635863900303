import { ConfigStateService } from '@medlogic/shared/state-config';
import { Injectable } from '@angular/core';
import { LibService } from './lib.service';
import { JavascriptLib } from './javascript-lib.service';
import { ExpressionGridFunctionService } from './expression-grid-function.service';
import { HttpClient } from '@angular/common/http';
import { LogService, ConfigJsonService, IAtividadeComponenteDAL, EnRequestType, EnContentType, routeGetWSServer } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { CalculatorService } from './calculator.service';
import { BasePageService, WebService } from '@medlogic/shared/shared-data-access';
import { CurrencyService } from './currency.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

// tslint:disable: ban-types
interface IFunction {
  name: string;
  method: Function;
  paramMethod: Function;
  isAsync?: boolean;
}

/* Esse padrão foi criado apenas para que seja possível separar as implementações
 * de diferentes tipos de funções. Mas, na execução, será gerado um array único,
 * concatenado, contendo todos os métodos.
 */
@Injectable()
export class ExpressionServerFunctionService extends ExpressionGridFunctionService {
  /*override */
  protected arrayServerFunction: Array<IFunction> = [
    {
      name: 'SERVER_GETIDENTITY',
      method: this.ProcessarFuncaoServerGetIdentity,
      paramMethod: this.addParamVars,
      isAsync: true
    },
    { name: 'SERVER_WS', method: this.ProcessarFuncaoServerWS, paramMethod: this.addParamVars, isAsync: true }
  ];
  protected urlGetWSServer = `${routeGetWSServer}?url={0}`;
  constructor(
    log: LogService,
    global: GlobalService,
    config: ConfigStateService,
    cnfJson: ConfigJsonService,
    calculator: CalculatorService,
    lib: LibService,
    jsLib: JavascriptLib,
    ws: WebService,
    private basepage: BasePageService,
    protected http: HttpClient,
    currencySrv: CurrencyService
  ) {
    super(log, global, config, cnfJson, calculator, lib, jsLib, ws, currencySrv);
  }

  /*override
   * Retorna uma lista consolidada com todos os nomes de funções.
   * Necessário para permitir que as classes herdeiras sobrescrevam e adicionem seus respectivos arrays */
  getFunctionArray(): Array<IFunction> {
    try {
      return super.getFunctionArray().concat(this.arrayServerFunction);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFunctionArray', error.message);
    }
  }

  /*Função para resgatar um identificador único, incremental.
  * Pegará o último (e não o maior), valor registrado na variável fornecida
  // como parâmetro e retornará o valor encontrado acrescido de uma unidade.
  * SERVER.GETIDENTITY(PROCESSONO; V_IDENTITY; EXPRESSAO_FILTRO; DEFAULT_VALUE)
  * PROCESSONO: Número do processo que será pesquisado.
  * V_IDENTITY: Contém o número da variável da qual se resgatará o valor mais recente e acrescentará 1.
  * EXPRESSAO_FILTRO: Uma expressão do tipo NumeroVariavel: Valor.
  * DEFAULT_VALUE: Caso não retorne alguma coisa do serviço, como quando por
  * // exemplo, há itens relacionados #VAZIO# ou #NAO INFORMADO#, retornar o DEFAULT_VALUE.
  * Por exemplo, se no Processo houver uma variável que significa Categoria, de número 1234 o filtro ficaria:
  * 1234:#1222#
  * Sendo #1222# uma referência a um controle na tela, do tipo Combobox, que contém a categoria.
  * @param arrayParam
  * @param _evtRetorno
  */
  protected ProcessarFuncaoServerGetIdentity(ctrl: IAtividadeComponenteDAL, arrayParam: Array<any>): string {
    try {
      if (arrayParam.length > 0) {
        let defaultValue = '1';
        if (arrayParam.length > 3) {
          if (arrayParam[3]) {
            defaultValue = this.extractParam(arrayParam[3]);
          }
        }
        if (
          this.extractParam(arrayParam[0]) === this.global.EMPTY_CHAR ||
          this.extractParam(arrayParam[1]) === this.global.EMPTY_CHAR ||
          this.extractParam(arrayParam[2]) === this.global.EMPTY_CHAR ||
          this.extractParam(arrayParam[0]) === this.global.NOITEM_TEXT ||
          this.extractParam(arrayParam[1]) === this.global.NOITEM_TEXT ||
          this.extractParam(arrayParam[2]) === this.global.NOITEM_TEXT
        ) {
          return defaultValue;
        } else {
          const ocorrenciaNo = this.config.OcorrenciaNo.value;
          const processoNo = this.extractParam(arrayParam[0]);
          const vIdentity = this.extractParam(arrayParam[1]);
          let expressionFilter = '';
          if (arrayParam[2]) {
            expressionFilter = this.extractParam(arrayParam[2]);
          }
          // const _urlWithParams:String = Config.instance.BASE_URL_API;
         let urlWithParams = this.cnfJson.baseUrlWebAPIService; // + "../WebApiService/api/";
          this.subs.sink = this.ws.get(urlWithParams).subscribe((result) => ctrl.AsyncValue.next(result));


          // var _urlWithParams:String = "http://hml.ge.gerenteeletronico.com.br/WebApiService_hm/api/";
          urlWithParams +=
            'Funcao?pProcessoNo=' +
            processoNo +
            '&pOcorrenciaAtualNo=' +
            ocorrenciaNo +
            '&pLstVariaveisRetorno=' +
            vIdentity +
            '&pVariavelGrid=&pLstVariaveisGridRetorno=&pFiltro=' +
            expressionFilter +
            '&pTipoOperacao=DESC';
          // Global.Mensagem(null, _urlWithParams);
         this.subs.sink = this.ws.get(urlWithParams).subscribe((result) => ctrl.AsyncValue.next(result));

        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ProcessarFuncaoServerGetIdentity', 'Error: ' + error.message);
    }
    return this.global.NAO_RECALCULAR_ITEM;
  }

  /*
   * Consome um webservice passando parâmetros no padrão REST e retorna o retorno.
   * url rest, get para chamada da função que deverá retornar um único valor no formato string
   * defaultValue: Um valor default a ser retornado caso haja chamada ainda incompleta ou inválida. Se não especificado retornará #VAZIO#
   * tagOrIndex: número da tag, ou index de retorno caso retorne uma lista
   * @param arrayParam
   * @param _evtRetorno
   *
   */
  protected ProcessarFuncaoServerWS(ctrl: IAtividadeComponenteDAL, param: Array<any>): string {
    try {
      let urlWithParams = '';
      const arrayParam = this.global.alwaysReturnArray(param);
      if (arrayParam.length > 0) {
        urlWithParams = this.extractParam(arrayParam[0]);
        let defaultValue = this.global.EMPTY_CHAR;
        if (arrayParam[1]) {
          defaultValue = this.extractParam(arrayParam[1]);
        }
        urlWithParams = this.global.UrlEncode(urlWithParams);
        if (arrayParam[2]) {
          const tagOrIndex: any = this.extractParam(arrayParam[2]);
          if (isNaN(tagOrIndex)) {
            // ExpressionServerWS.GetServerWsByChildTagName(_urlWithParams, _evtRetorno, _defaultValue, _tagOrIndex as String);
          } else {
            // ExpressionServerWS.GetServerWsByChildIndex(_urlWithParams, _evtRetorno, _defaultValue, _tagOrIndex as int);
          }
        } else {
          // ExpressionServerWS.GetServerWs(_urlWithParams, _defaultValue, _evtRetorno);
        }
      } else {
        ctrl.AsyncValue.next('');
      }



      // this.subs.sink = this.http.get(param.toString()).subscribe((result) => {
      //   console.log(result);
      //   ctrl.AsyncValue.next(result)
      // } );

      this.subs.sink = this.getServerWs(param.toString()).subscribe((result) => {
        ctrl.AsyncValue.next(result)
      } );



    } catch (error) {
      this.log.Registrar('ExpressionServerFunction', 'ProcessarFuncaoServerWS', error.message);
    }
    return this.global.NAO_RECALCULAR_ITEM;
  }

  getServerWs(corpo: string): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGetWSServer, corpo);
      return this.basepage
        .baseDadosReplay(EnRequestType.Get, urlGet, {}, 2, EnContentType.XML)
        .pipe(
          map(m => (m as any))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSubProcessByUser', error.message);
    }
  }

  // protected function ProcessarFuncaoServerWSList(arrayParam: Array, _evtRetorno: Function): void {
  //   try {
  //     if (arrayParam.length > 0) {
  //       var _urlWithParams: String = extractParam(arrayParam[0]);
  //       _urlWithParams = Global.UrlEncode(_urlWithParams);
  //       var _tagListName: String = "";
  //       if (arrayParam[1]) {
  //         _tagListName = extractParam(arrayParam[1]);
  //       }
  //       ExpressionServerWS.GetServerWsList(_urlWithParams, _evtRetorno, _tagListName);
  //     } else {
  //       _evtRetorno(null);
  //     }
  //   } catch (error: Error) {
  //     this.log.Registrar("ExpressionServerFunction", "ProcessarFuncaoServerWSList", error.message);
  //   }
  // }

  /*
   *Conta o número de notas adicionadas por um usuário específico
   * SERVER.COUNTNOTES.FROMUSER(#USUARIONO#; #OCORRENCIANO#)
   * @param arrayParam
   * @param _evtRetorno
   *
   */
  // protected function ProcessarFuncaoCountNotesFromUser(arrayParam: Array, _evtRetorno: Function): void {
  //   try {
  //     if (arrayParam.length == 2) {
  //       if ((extractParam(arrayParam[0]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[1]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[0]) == Config.instance.NOITEM_TEXT)
  //         || (extractParam(arrayParam[1]) == Config.instance.NOITEM_TEXT)
  //       ) {
  //         _evtRetorno(null);
  //       } else {
  //         var _usuarioNo: String = extractParam(arrayParam[0]);
  //         var _ocorrenciaAtualNo: String = extractParam(arrayParam[1]);
  //         var _urlWithParams: String = Config.instance.BASE_URL +
  // "../WebApiService/api/Nota?ocorrenciaNo=" + _ocorrenciaAtualNo + "&UsuarioNo=" + _usuarioNo
  //         ExpressionServerWS.GetServerWs(_urlWithParams, null, _evtRetorno);
  //       }
  //     } else {
  //       _evtRetorno(null);
  //     }
  //   } catch (error: Error) {
  //     this.log.Registrar("ExpressionServerFunction", "ProcessarFuncaoCountNotesFromUser", error.message);
  //   }
  // }
  /*
   *Conta o número de notas adicionadas por um usuário específico
   * SERVER.COUNTFILES.FROMUSER(#USUARIONO#; #OCORRENCIANO#)
   * @param arrayParam
   * @param _evtRetorno
   *
   */
  // protected function ProcessarFuncaoCountFilesFromUser(arrayParam: Array, _evtRetorno: Function): void {
  //   try {
  //     if (arrayParam.length == 2) {
  //       if ((extractParam(arrayParam[0]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[1]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[0]) == Config.instance.NOITEM_TEXT)
  //         || (extractParam(arrayParam[1]) == Config.instance.NOITEM_TEXT)
  //       ) {
  //         _evtRetorno(null);
  //       } else {
  //         var _usuarioNo: String = extractParam(arrayParam[0]);
  //         var _ocorrenciaAtualNo: String = extractParam(arrayParam[1]);
  //         var _urlWithParams: String = Config.instance.BASE_URL +
  // "../WebApiService/api/Documento?ocorrenciaNo=" + _ocorrenciaAtualNo + "&UsuarioNo=" + _usuarioNo
  //         ExpressionServerWS.GetServerWs(_urlWithParams, null, _evtRetorno);
  //       }
  //     } else {
  //       _evtRetorno(null);
  //     }
  //   } catch (error: Error) {
  //     this.log.Registrar("ExpressionServerFunction", "ProcessarFuncaoCountFilesFromUser", error.message);
  //   }
  // }

  /*
   *Função para resgatar um identificador único, incremental.
   * Pegará o último (e não o maior), valor registrado na variável
   * fornecida como parâmetro e retornará o valor encontrado acrescido de uma unidade.
   * SERVER.GETIDENTITY(PROCESSONO; V_IDENTITY; EXPRESSAO_FILTRO; DEFAULT_VALUE)
   * PROCESSONO: Número do processo que será pesquisado.
   * V_IDENTITY: Contém o número da variável da qual se resgatará o valor mais recente e acrescentará 1.
   * EXPRESSAO_FILTRO: Uma expressão do tipo NumeroVariavel: Valor.
   * DEFAULT_VALUE: Caso não retorne alguma coisa do serviço,
   * como quando por exemplo, há itens relacionados #VAZIO# ou #NAO INFORMADO#, retornar o DEFAULT_VALUE.
   * Por exemplo, se no Processo houver uma variável que significa Categoria, de número 1234 o filtro ficaria:
   * 1234:#1222#
   * Sendo #1222# uma referência a um controle na tela, do tipo Combobox, que contém a categoria.
   * @param arrayParam
   * @param _evtRetorno
   *
   */
  // protected function ProcessarFuncaoServerGetIdentity(arrayParam: Array, _evtRetorno: Function): void {
  //   try {
  //     if (arrayParam.length > 0) {
  //       var _defaultValue: String = "1";
  //       if (arrayParam.length > 3) {
  //         if (arrayParam[3]) {
  //           _defaultValue = extractParam(arrayParam[3]);
  //         }
  //       }
  //       if ((extractParam(arrayParam[0]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[1]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[2]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[0]) == Config.instance.NOITEM_TEXT)
  //         || (extractParam(arrayParam[1]) == Config.instance.NOITEM_TEXT)
  //         || (extractParam(arrayParam[2]) == Config.instance.NOITEM_TEXT)
  //       ) {
  //         _evtRetorno(_defaultValue);
  //       } else {
  //         var _ocorrenciaNo: int = Config.instance.OcorrenciaNo;
  //         var _processoNo: String = extractParam(arrayParam[0]);
  //         var _vIdentity: String = extractParam(arrayParam[1]);
  //         var _expressionFilter: String;
  //         if (arrayParam[2]) {
  //           _expressionFilter = extractParam(arrayParam[2]);
  //         }
  //         //						var _urlWithParams:String = Config.instance.BASE_URL_API;
  //         var _urlWithParams: String = Config.instance.BASE_URL + "../WebApiService/api/";
  //         //						var _urlWithParams:String = "http://hml.ge.gerenteeletronico.com.br/WebApiService_hm/api/";
  //         _urlWithParams += "Funcao?pProcessoNo=" + _processoNo +
  // "&pOcorrenciaAtualNo=" + _ocorrenciaNo + "&pLstVariaveisRetorno=" +
  // _vIdentity + "&pVariavelGrid=&pLstVariaveisGridRetorno=&pFiltro=" + _expressionFilter + "&pTipoOperacao=DESC";
  //         //						Global.Mensagem(null, _urlWithParams);
  //         ExpressionServerWS.GetServerWs(_urlWithParams, _defaultValue, _evtRetorno, false);
  //       }
  //     } else {
  //       _evtRetorno(null);
  //     }
  //   } catch (error: Error) {
  //     this.log.Registrar("ExpressionServerFunction", "ProcessarFuncaoServerWSList", error.message);
  //   }
  // }

  /*
   *Retorna o último valor de uma variável, para um determinado processo, considerando TODAS AS OCORRÊNCIAS do processo.
   * @param arrayParam
   * ProcessoNo, VariavelNo, tipoOperacao("MAX", "MIN", "FIRST", "LAST", "MEAN")
   * @return
   * ATENÇÃO: Será necessário inserir uma regra de segurança que impeça o retorno de variável de um Processo de outro cliente
   */
  // private function ProcessarFuncaoServerGetValue(arrayParam: Array, _evtRetorno: Function): void {
  //   try {
  //     if (arrayParam.length == 3) {
  //       if ((extractParam(arrayParam[0]) == Config.EMPTY_CHAR) ||
  // (extractParam(arrayParam[1]) == Config.EMPTY_CHAR) || (extractParam(arrayParam[2]) == Config.EMPTY_CHAR)) {
  //         _evtRetorno(null);
  //       } else {
  //         var _processoNo: String = extractParam(arrayParam[0]);
  //         var _variavelNo: String = extractParam(arrayParam[1]);
  //         _variavelNo = _variavelNo.replace("V_", "");
  //         var _tipoOperacao: String = extractParam(arrayParam[2]);
  //         var _urlWithParams: String = Config.instance.BASE_URL + "../WebApiService/api/Funcao?ProcessoNo=" + _processoNo
  //           + "&VariavelNo=" + _variavelNo
  //           + "&TipoOperacao=" + _tipoOperacao
  //           + "&UsuarioNo=" + Config.instance.UsuarioLogadoNo;
  //         ExpressionServerWS.GetServerWs(_urlWithParams, null, _evtRetorno);
  //       }
  //     } else {
  //       _evtRetorno(null);
  //     }
  //   } catch (error: Error) {
  //     this.log.Registrar("ExpressionServerFunction", "ProcessarFuncaoServerGetValue", error.message);
  //   }
  //   _evtRetorno(null);
  // }

  /*
   *Retorna o último valor de uma variável, para um determinado processo, considerando TODAS AS OCORRÊNCIAS do processo.
   * @param arrayParam
   * SERVER.CHECKEXIST(ProcessoNo, OcorrenciaAtualNo, CheckOcorrenciaAtualNo, Filtros, NumeroRegistros)
   * @return
   * ATENÇÃO: Será necessário inserir uma regra de segurança que impeça o retorno de variável de um Processo de outro cliente
   */
  // private function ProcessarFuncaoServerCheckExist(arrayParam: Array, _evtRetorno: Function): void {
  //   try {
  //     if (arrayParam.length == 5) {
  //       if ((extractParam(arrayParam[0]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[1]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[2]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[3]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[4]) == Config.EMPTY_CHAR)
  //         || (extractParam(arrayParam[0]) == Config.instance.NOITEM_TEXT)
  //         || (extractParam(arrayParam[1]) == Config.instance.NOITEM_TEXT)
  //         || (extractParam(arrayParam[2]) == Config.instance.NOITEM_TEXT)
  //         || (extractParam(arrayParam[3]) == Config.instance.NOITEM_TEXT)
  //         || (extractParam(arrayParam[4]) == Config.instance.NOITEM_TEXT)
  //       ) {
  //         _evtRetorno(null);
  //       } else {
  //         var _processoNo: String = extractParam(arrayParam[0]);
  //         var _ocorrenciaAtualNo: String = extractParam(arrayParam[1]);
  //         var _checkOcorrenciaAtualNo: String = extractParam(arrayParam[2]);
  //         var _filtros: String = extractParam(arrayParam[3]);
  //         var _numeroRegistros: String = extractParam(arrayParam[4]);
  //         var _urlWithParams: String = Config.instance.BASE_URL + "../WebApiService/api/Funcao?pProcessoNo=" + _processoNo
  //           + "&pOcorrenciaAtualNo=" + _ocorrenciaAtualNo
  //           + "&pCheckOcorrenciaAtualNo=" + _checkOcorrenciaAtualNo
  //           + "&pFiltro=" + _filtros
  //           + "&pNumRegistros=" + _numeroRegistros;
  //         ExpressionServerWS.GetServerWs(_urlWithParams, null, _evtRetorno);
  //       }
  //     } else {
  //       _evtRetorno(null);
  //     }
  //   } catch (error: Error) {
  //     this.log.Registrar("ExpressionServerFunction", "ProcessarFuncaoServerGetValue", error.message);
  //   }
  //   _evtRetorno(null);
  // }

  // private  limpa(_str): String {
  //   return Global.RemoverAspas(Global.Trim(_str));
  // }
}
