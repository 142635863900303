
export interface IPrescription {
    ocorrenciaNo: number;
    medicamentoTerapia: string;
    tempoUso: string;
    dosagem: any;
    vIA: string;
    orientacoes: string;
    posologia: string;
    centrocusto: string;
    tipoMaterial: string;
    idPaciente: any;
    apresentacao: any;
    quantPorHorario: any;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    idMedicamento: any;
    cascataCheckIdMedicamentoIdPacienteDataEVOLUCAO: any;
    centroCustoIDCentroCusto: any;
    codigoPacienteNomePaciente: any;
    codPacienteNomedoPacienteCodMedicamento: any;
    dataPrescricao: Date;
    tipoMedicamentosCodPaciente: any;
    medicamentoPrescrito: any;
    horaprimeiraDose: any;
    idMedicamentoIdPaciente: any;
    dataEvolucao: Date;
    dataInicio: Date;
    dataFim: Date;
    codPAcienteNOVO: any;
    medicamentoControlado: boolean;
    profissional: string;
    interromperMedicacaoTerapia: boolean;
    motivoOrientacoes: string;
    tempoEstimado: any;
    quantTEstimado: any;
    mEDICAMENTOINAPROPRIADOPARAIDOSOS: string;
    exame: string;
    mEDICAMENTOINAPROPRIADOPELOCLCR: string;
    codHospedeTelaPrescricao: any;
    sCRIPTPARAVERIFICARQUALOTIPODAPOSOLOGIAESELECIONARQUALHORARIOEXIBIRSEOHORARIODA1DOSEOUOPERSONALIZADO: string;
    validade: Date;
    materialID: any;
    iNTERVALODS: any;
    gOTASPMl: any;
    consumoDiario: any;
    // Calculados
    strDtPrescricao?: string;
}

