import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'ui-snackbar-notification',
  templateUrl: './ui-snackbar-notification.component.html',
  styleUrls: ['./ui-snackbar-notification.component.css']
})
export class UiSnackbarNotificationComponent {

  htmlMessage = '';
  title = '';

  constructor(
    public dialogRef: MatSnackBarRef<UiSnackbarNotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.htmlMessage = this.data?.messageHtml;
    this.title = this.data?.title;
  }

  closeClick($event: any): void {
    this.dialogRef.dismiss();
  }


}
