export interface ISavingStatus {
	
	saveSuccessPrescription: boolean;
	checkMedicationStock: boolean;
		//
		addMedicationStock: boolean;
			stockChangeSuccess: boolean;
			// updateMedication
			addInterventionMedication: boolean;
				// updateMedication
				updatePrescription: boolean;
					// saveSuccessPrescription: boolean; // Mensagem exibida
					setMedicationsWasChanged: boolean;

		checkMedicationIntervention: boolean;
			// updateMedication
			// updatePrescription -> exibirá mensagem
		
			// ou
		
			// addInterventionMedication -> exibirá mensagem
		
		updateMedication: boolean;
		
		// ou

		// addMedicationStock: boolean;
		checkStockItem: boolean;
			// stockChangeSuccess: boolean;
			addStockItem: boolean;
				// stockChangeSuccess: boolean;

}
