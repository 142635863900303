import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { WebService } from '@medlogic/shared/shared-data-access';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TarefaService {
  tarefaUrl = './assets/tarefa.json';
  urlTarefa = 'Tarefa?Token=OcorrenciaNo={0}';
  isTestFile = true;
  dtInicial: any;
  dtFinal: any;

  constructor(
    private basepage: BasePageService,
    private log: LogService,
    private ws: WebService,
    private http: HttpClient
  ) { }

  /*
  * Buscar dados de um cadastro do GE
  * @param  {int} ono - Ex: 1
  * @param  {Function} callback - Ex: callback(valorRetorno)
  */
  getTarefa(ono: number): Observable<any> {
    try {
      if (this.isTestFile) {
        return this.getFromFile(this.tarefaUrl);
      }
      const urlGet = this.basepage.format(this.urlTarefa, ono);
      // console.log(urlGet);
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getTarefa', error.message);
    }
    return null;
  }

  /* Resgata os dados do arquivo de teste. */
  protected getFromFile(tarefaUrl: string): Observable<any> {
    try {
      const result = this.http.get(tarefaUrl);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromFile', error.message);
    }
  }

  /* Altera o status da tarefa para concluída. */
  SetMudarAtribuicaoConcluirTarefaAtual(
    tarefaAtualNo: number,
    usuarioLogadoNo: number,
    tarefaNo: number,
    dtAgendamento: Date,
    usuarioDestinatarioNo: number,
    uno: number
  ): Observable<any> {
    try {
      const method = 'setMudarAtribuicaoConcluirTarefaAtual';
      const result = this.ws.connect(method, [
        { name: '_tarefaAtualNo', value: tarefaAtualNo },
        { name: 'UsuarioLogadoNo', value: uno },
        { name: '_tarefaNo', value: tarefaNo },
        { name: '_dtAgendamento', value: dtAgendamento },
        { name: '_usuarioDestinatarioNo', value: usuarioDestinatarioNo }
      ]);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'SetMudarAtribuicaoConcluirTarefaAtual', error.message);
    }
  }
}
