import { map } from 'rxjs/operators';
import { LibService } from '../../service/lib.service';
import { GlobalService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { CadastroListaDAL } from './cadastro-lista-dal';
import { CalculadoraService } from '../../service/calculadora.service';
import { ObjectDAL } from '@medlogic/shared/shared-data-access';
import { EnumAtividadeTipo } from '../../enum/enum-atividade-tipo.enum';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebService } from '@medlogic/shared/shared-data-access';
import { IBasic } from '@medlogic/shared/shared-interfaces';
import { error } from '../../service/error';

@Injectable()
export class AtividadeComponenteDAL extends ObjectDAL {
  constructor(
    webService: WebService,
    log: LogService,
    global: GlobalService,
    protected lib: LibService,
    protected calc: CalculadoraService,
    protected cadastroListaDAL: CadastroListaDAL
  ) {
    super(webService, log, global);
  }

  /*Retorna todos os componentes da atividade (ou da aba caso especificada),
  * ordenados por TabIndex e depois TabOrder. */
  getAll(
    atividadeTipo: EnumAtividadeTipo,
    atividadeNo: number,
    ocorrenciaNo: number,
    usuarioLogadoNo: number,
    atividadeDALTabsNo: number = -1
  ): Observable<IAtividadeComponenteDAL[]> {
    try {
      return this.getWithCache<IAtividadeComponenteDAL[]>(
        this.getComponentes,
        atividadeTipo,
        atividadeNo,
        ocorrenciaNo,
        usuarioLogadoNo,
        atividadeDALTabsNo
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return null;
  }

  /* Retorna todos os componentes de uma ocorrência específica.
  * Se atividadeDALTabsNo = -1 traz todos os componentes de todas as abas */
  protected getComponentes(
    atividadeTipo: EnumAtividadeTipo,
    atividadeNo: number,
    ocorrenciaNo: number,
    usuarioLogadoNo: number,
    atividadeDALTabsNo: number = -1
  ): Observable<IAtividadeComponenteDAL> {
    try {
      const method: string =
        atividadeTipo === EnumAtividadeTipo.Criar ? 'getComponentesNewData' : 'getComponentesEditData';
      return new Observable((observable) => {
        // TODO: ainda não descobri o porque, mas se usa cache para esse método a tela não carrega quando os dados estão no cache
        // possivelmente, o resultado da tela depende de algo assincrono a ser carregado, por exemplo o setOcorrencia
        this.subs.sink = this.webService
          .connectWithCache<IAtividadeComponenteDAL[]>(
            method,
            [
              { name: '_modeloAtividadeNo', value: atividadeNo },
              { name: '_modeloAtividadeTabsNo', value: atividadeDALTabsNo },
              { name: '_ocorrenciaNo', value: ocorrenciaNo },
              { name: 'UsuarioNo', value: usuarioLogadoNo }
            ],
            'Componente'
          )
          .subscribe((c: any) => {
            try {
              // Foi necessário dar subscribe para então realizar o map, pois,
              // o webservice retornar uma única emissão, com um array de elementos
              // Também observa-se a passagem dos parâmetros numa função chamada
              // por => ao invés da função direto para preservar o escopo de this, dentro da função chamada
              // let transformed = c.map((item, index, items) => this.mapToIAtividadeComponenteDAL(item, index, c));
              // ATENÇÃO: A lstControlesReferenciadosPorFormula precisa, necessariamente, referenciar a mesma instância da coleção de itens
              // pois esse campo é utilizado posteriormente para atualizar as fórmulas e,
              // se houver uma transformação ou cópia que aponte para
              // instancias diferentes dos próprios controles, o change das fórmulas pára de funcionar.
              if (c) {
                c.forEach((e: IAtividadeComponenteDAL) => {
                  try {
                    this.replaceSelfReference(e);
                    this.fillLstCadastroAdicional(e, usuarioLogadoNo);
                    e.lstControlesReferenciadosPorFormula = this.getComponentesReferenciados(
                      e.ValorDefault,
                      c
                    );
                    e.lstControlesReferenciadosCustomValidation = this.getComponentesReferenciados(
                      e.Validation,
                      c
                    );
                    e.lstControlesReferenciadosCustomAlert = this.getComponentesReferenciados(
                      e.Alert,
                      c
                    );
                    e.lstControlesReferenciadosReadOnly = this.getComponentesReferenciados(
                      e.ConditionReadOnly,
                      c
                    );
                    e.lstControlesReferenciadosVisibility = this.getComponentesReferenciados(
                      e.ConditionVisible,
                      c
                    );
                  } catch (error) {
                    this.log.Registrar(
                      this.constructor.name,
                      'getComponentes.subscribe.forEach',
                      error.message
                    );
                  }
                });
              }
              observable.next(c);
              observable.complete();
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'getComponentes.subscribe', error.message);
            }
          });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getComponentes', error.message);
    }
  }

  /* Caso alguma fórmula faça uma referência ao próprio controle, precisará ser substituído por #SELF# */
  replaceSelfReference(ctrl: IAtividadeComponenteDAL): void {
    try {
      const fieldsToReplace = ['Alert', 'ValorDefault', 'Rotulo', 'ConditionReadOnly', 'ConditionVisible'];
      fieldsToReplace
        .forEach(key => {
          if (ctrl[key]?.toString()?.startsWith('=')) {
            ctrl[key] = this.global.ReplaceAll(ctrl[key], `#${ctrl?.VariavelNo}#`, '#SELF#');
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'replaceSelfReference', error.message);
    }
  }

  /*Limpa o cache de edição.  */
  cleanCacheGetComponentesEditData(
    atividadeNo: number,
    ocorrenciaNo: number,
    usuarioLogadoNo: number,
    atividadeDALTabsNo: number = -1
  ): void {
    try {
      this.cleanCache('getComponentesEditData', [
        atividadeNo,
        atividadeDALTabsNo,
        ocorrenciaNo,
        usuarioLogadoNo
      ]);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCacheGetComponentesEditData', error.message);
    }
  }

  /*Necessário às vezes, pois, Quando se modifica um item de uma combo ou grid, por exemplo, podem ter
     * campos adicionais acessados por meio de fórmulas. Se as listas não forem recarregadas,
     * o valor principal poderá ser atualizado no retorno, mas os campos adicionais não.
     */
  cleanAllCaches(): void {
    try {
      this.webService.cleanAllCache();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanAllCaches', error.message);
    }
  }

  /*Preenche a propriedade lstCadastroAdicional que é exclusiva dos ListControl.
     * Esse preenchimento deve acontecer apenas para a Combobox, uma vez que ela sempre é carregada de cadastro,
     * então já pode ser populada no início.
     * É um pré-requisito para o funcionamento do PROC.
     * No entanto, o GRID NÃO pode ser carregado com esse valor desde o início,
     * pois, pode tanto representar dados associados a um cadastro quanto ser desvinculado.
     */
  protected fillLstCadastroAdicional(ctrl: IAtividadeComponenteDAL, usuarioLogadoNo: number): void {
    try {

      if (
        this.global.isEqual(ctrl.TypeCadastro, 'CADASTRO') &&
        (ctrl.Type.toUpperCase() === this.lib.CTRCOMBOBOX)
      ) {
        this.subs.sink = this.cadastroListaDAL
          .getAll(
            ctrl.CadastroNo,
            usuarioLogadoNo,
            ctrl.VariavelRetornoNo,
            ctrl.Type,
            ctrl.AtividadeComponenteNo
          )
          .pipe(
            map((m) => {
              return this.lib.getItemDoCadastro(m);
            }),
            error()
          )
          .subscribe((s) => {
            ctrl.lstCadastroAdicional = s;
          });
      } else {
        ctrl.lstCadastroAdicional = [];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillCadastroLista', error.message);
    }
  }

  /*Retorna uma lista de controles que estão referenciados numa suposta fórmula.
    // Caso o valor não seja uma fórmula retornará null */
  public getComponentesReferenciados(
    supostaFormula: string,
    items: IAtividadeComponenteDAL[]
  ): IAtividadeComponenteDAL[] {
    try {
      const vars: number[] = this.calc.extractVars(supostaFormula);
      if (vars) {
        return items.filter((i) => vars.find((v) => v === i.VariavelNo));
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getComponentesReferenciados', error);
    }
    return null;
  }

  /*Extrai, da própria lista de componentes, os nomes das Abas.
     * Também já calcula a visibilidade da aba.
     */
  nomesAbasDiferentes(lstAtividadeComponenteDAL: IAtividadeComponenteDAL[]): IBasic[] {
    try {
      const arrayNomes = new Array<IBasic>();
      if (lstAtividadeComponenteDAL.length > 0) {
        const sortedLstAtividadeCompoenteDAL: IAtividadeComponenteDAL[] = lstAtividadeComponenteDAL.sort((a: IAtividadeComponenteDAL, b: IAtividadeComponenteDAL) => {
          return a.TabIndex - b.TabIndex;
        });
        for (const componente of sortedLstAtividadeCompoenteDAL) {
          // let tabName: string = componente.TabIndex + "#" + componente.TabName;
          if (arrayNomes.findIndex((f) => f.id === componente.TabIndex) < 0) {
            const tab = { id: componente.TabIndex, name: componente.TabName } as IBasic;
            tab.enabled = this.lib.isTabVisible(lstAtividadeComponenteDAL, tab);
            arrayNomes.push(tab);
          }
        }
      }
      return arrayNomes;
    } catch (error) {
      this.log.Registrar('AtividadeComponenteDAL', 'nomesAbasDiferentes', 'Error: ' + error.message);
    }
    return null;
  }

  // protected mapToIAtividadeComponenteDAL (item: IAtividadeComponenteDAL,
  // index: number, items: Array<IAtividadeComponenteDAL>): IAtividadeComponenteDAL {
  //     try {
  //         let componente = <IAtividadeComponenteDAL>{
  //             AtividadeComponenteNo: item.AtividadeComponenteNo,
  //             AtividadeNo: item.AtividadeNo,
  //             Type: item.Type,
  //             EnCtrType: item.EnCtrType,
  //             Linha: item.Linha,
  //             Coluna: item.Coluna,
  //             Largura: item.Largura,
  //             Altura: item.Altura,
  //             Nome: item.Nome,
  //             TabIndex: item.TabIndex,
  //             TabOrder: item.TabOrder,
  //             TabName: item.TabName,
  //             PropriedadeNome: item.PropriedadeNome,
  //             Rotulo: item.Rotulo,
  //             ValorDefault: item.ValorDefault,
  //             Posicao: item.Posicao,
  //             OcorrenciaNo: item.OcorrenciaNo,
  //             VariavelNo: item.VariavelNo,
  //             Versao: item.Versao,
  //             IsEnable: item.IsEnable,// === undefined ? false : item.IsEnable.toUpperCase() == "TRUE" ? true : false,
  //             IsVisible: item.IsVisible, // === undefined ? false : item.IsVisible.toUpperCase() == "TRUE" ? true : false,
  //             ConditionVisible: item.ConditionVisible,
  //             AtividadeCadastroNo: item.AtividadeCadastroNo || null,
  //             TypeRegister: item.TypeRegister || null,
  // Indica se o campo é uma das propriedades obrigatórios num cadastro códigoitem., títuloitem., ou habilitado
  //             RequiredField: item.RequiredField, // === undefined ? false : item.RequiredField.toUpperCase() == "TRUE" ? true : false,
  //             InputMaskType: item.InputMaskType,
  //             MaxLength: item.MaxLength,
  //             VariavelAssociadaNo: item.VariavelAssociadaNo,
  // Número da variável referenciada (deverá estar na mesma Atividade) e que deve ter o change monitorado
  //             VariavelComparacaoNo: item.VariavelComparacaoNo,
  // Variável do próprio cadastro que alimenta a lista do controleitem., que será usada para comparar com o valor para fazer o filtro
  //             VariavelRetornoNo: item.VariavelRetornoNo,//Número da variável que retornará com o valor preenchido do Cadastro
  //             CadastroNo: item.CadastroNo, //Número do Cadastro que contém a lista de itens. Deve ser retornado na chamada do método
  //             EnTipoLista: item.EnTipoLista,//Armazena o tipo de lista associada ao controle
  //             ShowRotulo: item.ShowRotulo,// === undefined ? false : item.ShowRotulo.toUpperCase() == "TRUE" ? true : false,
  //             ConditionReadOnly: item.ConditionReadOnly,
  //             CustomProperty: item.CustomProperty,
  //             wasDecripted: item.wasDecripted,// === undefined ? false : item.wasDecripted.toUpperCase() == "TRUE" ? true : false,
  //             ValorTexto: item.ValorTexto || '',
  //             ValorData: item.ValorData,// === undefined ? null : this.global.ddMMYYYYThhmmssToDate(item.ValorData),
  //             ValorDataMMddyyyy: item.ValorDataMMddyyyy || null,
  //             ValorNumero: item.ValorNumero || null,
  //             ValorInteiro: item.ValorInteiro || null,
  //             lstValue: item.lstValue,
  //             lstKey: item.lstKey,
  //             lstItems: item.lstItems,
  //             lstCadastroAdicional: item.lstCadastroAdicional,
  //             LstCamposAdicionais: item.LstCamposAdicionais,
  // sSerá populada apenas no caso de Grid ou Combobox com propriedades como
  // Largura e visibilidade para cada coluna
  //             LstAtividadeComponente: item.LstAtividadeComponente,
  //             ShowSearch: item.ShowSearch,// === undefined ? false : item.ShowSearch.toUpperCase() == "TRUE" ? true : false,
  //             ShowAttachmentButton: item.ShowAttachmentButton,
  // === undefined ? false : item.ShowAttachmentButton.toUpperCase() == "TRUE" ? true : false,
  //             ShowAdvancedGridButton: item.ShowAdvancedGridButton,
  // === undefined ? false : item.ShowAdvancedGridButton.toUpperCase() == "TRUE" ?
  // true : false,//Modificar para iniciar em false quando a propriedade for criada no Studio e retornada no getComponentes
  //             //propriedades Mapa
  //             MapaIsEnable: item.MapaIsEnable,// === undefined ? false : item.MapaIsEnable.toUpperCase() == "TRUE" ? true : false,
  //             ShowZoomControl: item.ShowZoomControl,
  // === undefined ? false : item.ShowZoomControl.toUpperCase() == "TRUE" ? true : false,
  //             ShowStreetViewControl: item.ShowStreetViewControl,
  // === undefined ? false : item.ShowStreetViewControl.toUpperCase() == "TRUE" ? true : false,
  //             ShowScaleControl: item.ShowScaleControl,
  // === undefined ? false : item.ShowScaleControl.toUpperCase() == "TRUE" ? true : false,
  //             ShowMapTypeControl: item.ShowMapTypeControl,
  // === undefined ? false : item.ShowMapTypeControl.toUpperCase() == "TRUE" ? true : false,
  //             //Propriedades SearchCombobox
  //             AutoComplete: item.AutoComplete,// === undefined ? false : item.AutoComplete.toUpperCase() == "TRUE" ? true : false,
  //             CanAddItem: item.CanAddItem,// === undefined ? false : item.CanAddItem.toUpperCase() == "TRUE" ? true : false,
  //             CanAddInCadastro: item.CanAddInCadastro,
  // === undefined ? false : item.CanAddInCadastro.toUpperCase() == "TRUE" ? true : false,
  //             PartialSearch: item.PartialSearch,// === undefined ? false : item.PartialSearch.toUpperCase() == "TRUE" ? true : false,
  //             MainSearchFieldName: item.MainSearchFieldName,
  //             SearchFields: item.SearchFields || null,
  //             RowHeight: item.RowHeight,
  //             ColumnWidth: item.ColumnWidth,
  //             //Propriedades de CheckboxList
  //             NumberOfColumn: item.NumberOfColumn || null,
  //             //Propridades do Grid
  //             CanExcludeItem: item.CanExcludeItem,// === undefined ? false : item.CanExcludeItem.toUpperCase() == "TRUE" ? true : false,
  //             CanEditItem: item.CanEditItem,// === undefined ? false : item.CanEditItem.toUpperCase() == "TRUE" ? true : false,
  //             CanReadItem: item.CanReadItem,// === undefined ? false : item.CanReadItem.toUpperCase() == "TRUE" ? true : false,
  //             ShowInMobile: item.ShowInMobile,// === undefined ? false : item.ShowInMobile.toUpperCase() == "TRUE" ? true : false,
  //             CanEditMultLines: item.CanEditMultLines,
  // === undefined ? false : item.CanEditMultLines.toUpperCase() == "TRUE" ? true : false,
  //             EstruturaGrid: item.EstruturaGrid || null,
  //             //Propriedades de alerta e validação customizada
  //             Validation: item.Validation,//Fórmula de CustomValidation
  //             ValidationMessage: item.ValidationMessage,//Mensagem a ser exibida caso não passe na validação
  //             ToolTipMessage: item.ToolTipMessage,//Mensagem de explicação do campo
  //             Alert: item.Alert,//Fórmula de CustomAlert
  //             AlertMessage: item.AlertMessage,//Mensagem a ser exibida caso não passe na validação
  //             AlertColor: item.AlertColor,//Fórmula de CustomAlert
  //             IsShowAlertMessage: item.IsShowAlertMessage,// === undefined ? false :
  // item.IsShowAlertMessage.toUpperCase() == "TRUE" ? true : false,//Mensagem a ser exibida caso não passe na validação
  //             //Propriedade de controle do tipo lista
  //             UrlWebApiRest: item.UrlWebApiRest,
  //             isEncrypted: item.isEncrypted,// === undefined ? false :
  // item.isEncrypted.toUpperCase() == "TRUE" ? true : false,//Indica se os campos foram criptografados.
  //             encryptedKeyword: item.encryptedKeyword,
  //             //Campos Calculados
  //             lstControlesReferenciadosPorFormula: null//this.getComponentesReferenciados(item, items)
  //         }
  //         return componente;
  //     } catch (error) {
  //         console.log("error", error);
  //         this.log.Registrar(this.constructor.name, 'mapToIAtividadeComponenteDAL', error.message);
  //     }
  //     return null;
  // }
}
