import { IPayload } from './api/ipayload';

export interface ILoginWithUserResponse extends IPayload {
  Session: string;
  audience: string;
  sub: string;
  message?: string;
  imgUrl?: string;
}

export interface ILoginWithUserRequest {
  Login: string;
  Password: string;
}
