import { createReducer, on } from '@ngrx/store';
import { IGenericState } from '../states/igeneric-state';
import {
  loadGeneric,
  setGeneric,
  selectGeneric,
  fetchSuccessGeneric,
  updateGeneric,
  fetchSuccessGenericByCode,
  getGenericByCode,
} from '../actions/generic.actions';

// TODO: Remember to import the Effects and Reducer at app.module
// EffectsModule.forRoot([ContaEffects]),
// StoreModule.forRoot({  app: appReducer, conta: contaReducer, conta: contaReducer }),
export const initialState = {};

// tslint:disable-next-line: variable-name
const _genericReducer = createReducer(initialState,
  on(loadGeneric, (state, payload) => ({
    ...state,
    bannerTitle: 'CONTA',
    selectedGeneric: null
  })),
  on(fetchSuccessGeneric, (state, payload) => ({
    ...state,
    allGenerics: payload.allGenerics
  })),
  on(selectGeneric, (state, payload) => ({
    ...state,
    selectedGeneric: payload.selectedGeneric
  })),
  on(setGeneric, (state, payload) => ({
    ...state,
    allGenerics: payload.allGenerics
  })),
  on(updateGeneric, (state: IGenericState, payload) => {  // Note: the updateGeneric will not update the service, but just the state
    const find = state.allGenerics.find(f => +f.id === +payload.generic.id);
    if (find) {
      Object.assign(find, payload.generic);
      return ({
        ...state,
        allGenerics: state.allGenerics
      });
    }
    return state;
  }),
  on(getGenericByCode, (state: IGenericState, payload) => ({
    ...state,
    validationCode: payload.validationCode
  })),
  on(fetchSuccessGenericByCode, (state: IGenericState, payload) => ({
    ...state,
    selectedGeneric: payload.selectedGeneric
  }))
);

export function genericReducer(state, action) {
  return _genericReducer(state, action);
}
