<div class="grid" *ngLet="(numOfMedications$ | async) as numOfMedications">

  <img alt="image" class="img-circle pic" [class.miniImg]="showMiniImg" width="60" src="{{ patient?.foto }}" (error)="swapImage($event.target, patient)">

  <h2 class="m-b-xs" [class.miniBox]="showMiniImg" class="name">
    <strong [class.miniFont]="showMiniImg">{{ patient?.nomeHospede }}</strong>
  </h2>

  <div class="font-bold age" *ngIf="!showMiniImg">{{ getAge(patient?.nascimento) }} anos</div>

  <mat-icon class="warning" *ngIf="patient?.calcHasOccurrence">announcement</mat-icon>

  <mat-icon class="arrow">keyboard_arrow_right</mat-icon>

  <span *ngIf="(hasMedication$ | async)">
    <mat-icon class="medication">local_drink</mat-icon><span *ngIf="numOfMedications > 0">{{ numOfMedications }}</span>
  </span>

</div>