<div #header>
  <div>
    <button type="button" (click)="onBack()">< Voltar</button>
    <h1>{{date}}</h1>
  </div>
  <div>
    <input type="text" [(value)]="keyword">
  </div>
</div>
<!-- <table class="table table-striped table-bordered" [libTable]="[header, footer, thead, tbody]"> -->
<table class="table table-striped table-bordered">
  <thead #thead>
    <tr>
      <th>HORA</th>
      <th *ngFor="let day of week">
        <h2>{{ day.day }}</h2>
        <h4>{{ day.date }}</h4>
      </th>
    </tr>
  </thead>
  <tbody  #tbody>
    <!-- [libTable]="[footer, -20]" -->
    <tr *ngFor="let time of times">
      <td class="hour">
        <h1 *ngIf="isMainTime(time)">{{ time }}</h1>
        <h2 *ngIf="!isMainTime(time)">{{ time }}</h2>
      </td>
      <td *ngFor="let day2 of week" class="day" [libTasksOfDay]="getTasksOf(day2.date, time)" (click)="gotoTask(day2.date, time)"><strong>{{ getTasksOf(day2.date, time) }}</strong></td>
    </tr>
  </tbody>
  <tfoot #footer>
    <tr>
      <td colspan="8">
        <div class="ibox-content">
          <button class="btn btn-primary"><i class="fa fa-hourglass-start"></i>Novo</button>
          <button class="btn btn-primary"><i class="fa fa-tasks"></i>Todas Tarefas</button>
          <button class="btn btn-primary"><i class="fa fa-check"></i>Concluídas</button>
        </div>
      </td>
    </tr>
  </tfoot>
</table>
