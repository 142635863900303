// import { IMedication } from './medlogic/imedication';
// import { IVitalSign } from './ivital-sign';

export interface IReference {
    identifier: string;
    type: string;
    display: string;
    system: string;
    tenantId: number;
    language: string;

}