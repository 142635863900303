import { IMedicalTest } from './imedical-test';

export interface ICellRow {
    i: number;
    j: number;
    value: (string | number);
    date: Date;
    objTest: IMedicalTest;
    idResult?: (string | number);
}
