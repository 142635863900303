import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EnPaperOrientation } from '@medlogic/shared/shared-interfaces';
import { IDialogData } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'app-paper-dialog',
  templateUrl: './paper-dialog.component.html',
  styleUrls: ['./paper-dialog.component.css']
})
export class PaperDialogComponent {
  lng: any;
  disabledButton = false;
  fg: FormGroup;
  window: any = window;

  constructor(
    protected log: LogService,
    protected fb: FormBuilder,
    public dialogRef: MatDialogRef<PaperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {
    try {
      this.fg = fb.group({
        paperOrientation: EnPaperOrientation.Landscape
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Click no botão de confirmação. */
  onYesClick(value: any): void {
    try {
      this.dialogRef.close(this.fg.get('paperOrientation').value);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onYesClick', error.message);
    }
  }
  /* Click no botão de fechar. */
  onCloseClick(): void {
    try {
      this.dialogRef.close(null);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onYesClick', error.message);
    }
  }
}
