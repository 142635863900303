import { IQueue, IQueueState, LogService } from '@medlogic/shared/shared-interfaces';
import { selectQueueAll, setQueueIsExecuting } from '@medlogic/shared/state-queue';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Observable } from 'rxjs';
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'ml-stock-sync-dialog',
  templateUrl: './stock-sync-dialog.component.html',
  styleUrls: ['./stock-sync-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockSyncDialogComponent implements OnInit {

  queues$: Observable<IQueue[]> = this.store.select(state => selectQueueAll(state?.queue));

  constructor(
    protected log: LogService,
    private store: Store<IAppMedlogicState>,
    public dialogRef: MatDialogRef<StockSyncDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    try {
      this.store.dispatch(setQueueIsExecuting({ isExecuting: false }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Click no botão de fechar. */
  onCloseClick(): void {
    try {
      this.dialogRef.close(null);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onYesClick', error.message);
    }
  }


}
