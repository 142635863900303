import { IInterventionMedication } from './iintervention-medication';
import { ISavingStatus } from './isaving-status';

export interface IPrescriptionMedication {
	ocorrenciaNo: number;
	residente: string;
	dataNascimento: any;
	nomemae: any;
	identificacao1: any;
	identificacao2: any;
	centroCusto: any;
	prontuario: any;
	idPaciente: any;
	codigo32935: any; // é o mesmo que a ocorrenciaNo
	titulo: any;
	habilitado: boolean;
	nascimento: Date;
	codHospedeTelaPrescricao: any;
	codNome: any;
	codigoHospede: any;
	codigoNome: any;
	responsavelNome: any;
	// responsavelRG: any;
	// codHospede: any;
	lstmedicationGrid: IInterventionMedication[];
	isSaving?: boolean;
	codRg: string;
	savingStatus?: {
		[medicationId: number]: ISavingStatus
	}
}
