import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe extends DatePipe implements PipeTransform {
  private datePipe: DatePipe;
  constructor() {
    super(null);
    // Create a new DatePipe instance with the desired locale
    this.datePipe = new DatePipe('pt-BR');
  }

  transform(
    value: Date | string | number,
    format?: string,
    timezone?: string,
    locale: string = 'pt-BR'
  ): any {
    try {
      return this.datePipe.transform(value, format, 'UTC', locale);
    } catch (error: any) {
      return '';
    }
  }
}
