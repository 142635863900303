import { metaReducers } from './+state/generic.metareducer';
import { SharedUtilsModule } from '@medlogic/shared/utils';
import { MaterialModule } from '@medlogic/shared/material';
import { UiLoadingModule } from '@medlogic/shared/ui/ui-loading';

import { ListItemComponent } from './list-item/list-item.component';
import { GenericListComponent } from './generic-list/generic-list.component';

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { genericReducer } from './+state/generic.reducer';
import { GenericEffects } from './+state/generic.effects';

const COMPONENTS = [
  ListItemComponent,
  GenericListComponent
];

const MODULES = [
  CommonModule,
  SharedUtilsModule,
  UiLoadingModule,
  MaterialModule,
];

const STATE_MODULES = [
  StoreModule.forFeature('generic', genericReducer, { metaReducers }),
  EffectsModule.forFeature([GenericEffects]),
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [MODULES, STATE_MODULES],
  exports: [COMPONENTS],
  providers: [],
})
export class UiGenericListModule { }
