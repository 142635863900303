import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { MedlogicActionTypes } from './medlogic.actions';
import { initialMedlogicState } from './medlogic.reducers';

const KEY = 'MEDLOGIC_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  MedlogicActionTypes.LoadApp,
  MedlogicActionTypes.SetSearch,
  MedlogicActionTypes.SetIsLoading,
  MedlogicActionTypes.SetIsMobile,
  MedlogicActionTypes.SetSearch,
  MedlogicActionTypes.SetViewMode,
];

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialMedlogicState);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
