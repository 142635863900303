<div class="grid" *ngIf="(items$ | async) as items; else loading">
  <ng-container *ngIf="items && items.length > 0; else empty">
    <app-list-item *ngFor="let item of items | itemSearch: search" [listItem]="item" (click)="onItem_click(item)">
    </app-list-item>
  </ng-container>
</div>
<ng-template #loading>
  <ui-loading-spinner></ui-loading-spinner>
  <h2>Aguarde...</h2>
</ng-template>
<ng-template #empty>
  <h2>Nenhum registro encontrado.</h2>
</ng-template>
