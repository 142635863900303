export interface IPerson {
    ocorrenciaNo: number;
    nomeResidente: string;
    nascimento: any;
    pesoAtualKg: any;
    alturaM: any;
    iMC: any;
    dIAGNOSTICODASDOENCASOUCONDICOESCRONICASDESAUDE: string;
    nomemae: any;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    sexo: string;
    estadoCivil: string;
    estado: string;
    cor: string;
    possuiBeneficio: boolean;
    religiao: string;
    data: Date;
    profissao: any;
    idade: any;
    naturalidade: any;
    paisNascimento: any;
    nomeSocial: any;
    cPF: any;
    rG: any;
    escolaridade: string;
    enfermeiroReferencia: any;
    medicoReferencia: any;
    telefone: any;
    planoSaude: string;
    alturaQuadrado: any;
    classificacao: string;
    identificacao1: any;
    identificacao2: any;
    temAlgumaAlergiamaiorGravidade: boolean;
    seSimQual: any;
    temAlgumaDeficienciaVisualAuditivaFisicaOuOutra: boolean;
    seSimQual2: any;
    nomepai: any;
    unidadeNegocio: string;
    vencimento: Date;
    categoria: string;
    fonte: any;
    pagamento: any;
    valor: any;
    descricao: string;
    status: any;
    banco: string;
    observacao: string;
    parcela: any;
    totalParcelas: any;
    tipo: any;
    pago: boolean;
    titulo2: any;
    dataRegistro: Date;
    meioPagamento: string;
    classe: any;
    beneficiario: string;
    recorrente: boolean;
    dataInicio2: Date;
    periodicidade: string;
    tiporecorrencia: boolean;
    diaVencimento: any;
    bancoPadrao: any;
    categoriaPadrao: any;
    fontePadrao: any;
    beneficiarioPadrao: any;
    criptografarValor: boolean;
    valorCriptografado: any;
    centroCusto: any;
    prontuario: any;
    situacaoHospede: boolean;
    nF: any;
    pacienteFalecido: boolean;
    dataObito: any;
    anoObito: any;
    usaFralda: boolean;
    comeSozinho: boolean;
    cadeirante: boolean;
    traqueostomizado: boolean;
    ulceradecubito: boolean;
    sondaGastrica: boolean;
    sondaNaso: boolean;
    comUlceraPosHospitalizacao: boolean;
    hospital: any;
    fraturaColofemur: any;
    diabetico: boolean;
    usaInsulina: boolean;
    menores60: any;
    dataAdmissao: any;
    anoAdmissao: any;
    admissaoAntes2000: any;
    hospitalSim: any;
    agendamento: any;
    urlLogo: any;
    prontuarioSoma: any;
    totalCadastro: any;
    pRTO: any;
    identificacoes: any;
    endereco: any;
    numero: any;
    bairro: any;
    cidade: any;
    telefone2: any;
    estado2: string;
    celular: any;
    eMail: any;
    quarto: any;
    getUsuarioToken: any;
    regiao: string;
    trato: any;
    educacao: any;
    referencia: any;
    perfilCliente: string;
    informacoesImportantes: string;
    retornoWebApiCentroCusto: any;
    indicacaoQuemIndicouempresa: string;
    integradoFinanceiro2: any;
    ofxId: any;
    dataInicio: any;
    complemento: any;
    eNDERECOCOMPLETO: any;
    dataDesligamento: any;
    dataInativacao: Date;
    motivodesligamento: string;
    graudependencia: string;
    tipoBeneficio: string;
    nBeneficio: any;
    livro: any;
    folha: any;
    termo: any;
    mesaniversario: any;
    dataAniversario: any;
    mesStatus: any;
    eInterditado: boolean;
    equipeatendimento: string;
    motivoencaminhamentoaInstituicao: string;
    cNPJ: any;
    integradoFinanceiro: any;
    cliente: string;
    dataAniversarioContrato: Date;
    pagaDecimoTerceiro: boolean;
    numeroParcelas2: any;
    dataInicioPagamento2: Date;
    dataFimPagamento2: any;
    pagaEnxoval: boolean;
    numeroParcelas: any;
    dataInicioPagamento: Date;
    dataFimPagamento: any;
    calcDataFimPagamento2: any;
    calcDataFimPagamento3: any;
    dtNascimento: Date;
    dtAdmissao: Date;
    classificacao2: any;
    imgUrl: string;
}
