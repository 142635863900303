import { EnClipType } from './../enum/en-clip-type';
export interface ISmartListScore<T = any> {
    score: number;
    enClipType: EnClipType;
    topLeft: string;
    bottomLeft: string;
    id: number;
    obj: T;
    iconName?: string;
    iconColor?: string;
}