import { ConfigStateService } from '@medlogic/shared/state-config';
import { LibService } from '../service/lib.service';
import { CalculadoraConditionService } from '../service/calculadora-condition.service';
import { CalculadoraService } from '../service/calculadora.service';
import { Control } from './control';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class AutoCalcControl extends Control {

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }


}
