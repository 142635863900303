import { IAccount } from '../interface/iaccount';
import { LibService } from '@medlogic/shared/geform';
import { GlobalService, LogService, IForm, IServiceProvider } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { Observable, of } from 'rxjs';
import { publishReplay, refCount, map, toArray, mergeMap, groupBy } from 'rxjs/operators';


export abstract class AccountService implements IServiceProvider {
  // tslint:disable: max-line-length
  private lstVariaveis = 'V_387,V_391,V_1608,V_2230,V_2231,V_2232,V_2274,V_2278,V_3328,V_3329,V_28051,V_28065,V_28868,V_28958,V_28960,V_28970,V_28982,V_29006,V_29007,V_29008,V_29009,V_29010,V_29011,V_29012,V_29013,V_29166,V_30028,V_34273,V_100966,V_102952,V_102953,V_102954,V_102968,V_102971,V_102972,V_103083,V_103084,V_103085,V_103086,V_103087,V_103091,V_103092,V_103093,V_103094,V_103095,V_103096,V_103097,V_103098,V_103099,V_103101,V_103129,V_103130,V_103131,V_103132,V_103133,V_103134,V_103135,V_103136,V_103137,V_103138,V_103139,V_103140,V_103141,V_103148,V_103149,V_103151,V_103152,V_103155,V_103156,V_103157,V_103158,V_103159,V_103164,V_103165,V_103185,V_103186,V_103187,V_103188,V_103189,V_103190,V_103193,V_103194,V_103195,V_103196,V_103197,V_103198,V_103199,V_103200,V_103201,V_103202,V_103203,V_103229';
  private variavelGrid = 'V_29613';
  private lstVariaveisGrid = 'V_387,V_28051,V_102973,V_102974,V_102975,V_102976,V_102977,V_102978,V_102980,V_102981,V_103116,V_103117,V_103118,V_103119,V_103120,V_103121,V_103122,V_103123,V_103124,V_103125,V_103126,V_103144,V_103150,V_103191';

  recurrences: IAccount[] = new Array<IAccount>();

  codigoVariavelNo = 2230; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 17734;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigPwaMedLogicService,
    protected lib: LibService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IAccount> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IAccount> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IAccount> {
    try {
      if (
        (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
        || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
        || (!this.cadastrosCache)
        // (startDate.getTime() !== this.currentDtInicial.getTime())
        // || (endDate.getTime() !== this.currentDtFinal.getTime())
        // || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastroGrid(cadastroNo, startDate, endDate, lstVariables);
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected mapTo = () => map((c: any) => {
    return {
      ocorrenciaNo: c.OcorrenciaNo,
      nomeEmpresa: c.V_387,
      dataAberturaConta: c.V_391,
      nomeConsultor: c.V_1608,
      codigo: c.V_2230,
      titulo: c.V_2231,
      habilitado: this.lib.getBoolean(c.V_2232),
      idade: c.V_2274,
      documento: c.V_2278,
      identificacao1: c.V_3328,
      identificacao2: c.V_3329,
      conta: c.V_28051,
      situacaoCliente: this.lib.getBoolean(c.V_28065),
      agendamento: c.V_28868,
      prontuarioSoma: c.V_28958,
      totalCadastro: c.V_28960,
      pRTO: c.V_28970,
      identificacoes: c.V_28982,
      endereco: c.V_29006,
      numero: c.V_29007,
      bairro: c.V_29008,
      cidade: c.V_29009,
      telefone: c.V_29010,
      estado: c.V_29011,
      celular: c.V_29012,
      eMail: c.V_29013,
      getUsuarioToken: c.V_29166,
      integradoCustodia: c.V_30028,
      complemento: c.V_34273,
      dataInativacao: this.glb.ddMMYYYYThhmmssToDate(c.V_100966),
      loginCliente: c.V_102952,
      loginFinal: c.V_102953,
      tipoDocumento: c.V_102954,
      cEP: c.V_102968,
      passaporteConsultor: c.V_102971,
      emailConsultor: c.V_102972,
      informeproprietariocustodia: c.V_103083,
      proprietarioCustodia: this.lib.getBoolean(c.V_103084),
      cotacaoDolarAmericano: c.V_103085,
      cotacaoEuro: c.V_103086,
      rOTULO: c.V_103087,
      moedaCustodia: c.V_103091,
      valorCustodia: c.V_103092,
      valorEmReais: c.V_103093,
      valorEmDolar: c.V_103094,
      valorEmEuros: c.V_103095,
      tipoitemCustodia: c.V_103096,
      descricaoItem: c.V_103097,
      taxCambioDolar: c.V_103098,
      taxCambioEuro: c.V_103099,
      retornocustodia: c.V_103101,
      selecionarRepresentante: c.V_103129,
      empresaContaRepresentante: c.V_103130,
      endereco2: c.V_103131,
      numero2: c.V_103132,
      complemento2: c.V_103133,
      bairro2: c.V_103134,
      cidade2: c.V_103135,
      cEP2: c.V_103136,
      telefone2: c.V_103137,
      celular2: c.V_103138,
      pASSAPORTE: c.V_103139,
      estado2: c.V_103140,
      numeroSerial: c.V_103141,
      nacionalidade: c.V_103148,
      email: c.V_103149,
      designacao: c.V_103151,
      bENEFICIARIO: c.V_103152,
      empresaConta: c.V_103155,
      transationNO: c.V_103156,
      aMOUNT: c.V_103157,
      iSSUEDATE: this.glb.ddMMYYYYThhmmssToDate(c.V_103158),
      mATURITYDATE: c.V_103159,
      mOEDASIGLA: c.V_103164,
      valorPorExtenso: c.V_103165,
      barcode: c.V_103185,
      qRCode: c.V_103186,
      numeroSerialMinMax: c.V_103187,
      qRCodeURLBaseURLGenerateID: c.V_103188,
      qRCodeURLBaseURLGenerateIDOcorrenciaNo: c.V_103189,
      consultorAtivo: c.V_103190,
      end: c.V_103193,
      endCompleto: c.V_103194,
      bENEFICIARIOENDERECO: c.V_103195,
      n: c.V_103196,
      comp: c.V_103197,
      bairro3: c.V_103198,
      cidade3: c.V_103199,
      estado3: c.V_103200,
      cep: c.V_103201,
      endCompleto2: c.V_103202,
      bGNO: c.V_103203,
      tituloBeneficiario: c.V_103229
    } as IAccount;
  })

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
  * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date = null, endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IAccount> {
    try {
      this.cadastroSrv.dtInicial = startDate ? this.glb.dateToYYYYMMddThhmmss(startDate) : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  // save(ano: number, account: IAccount, id?: number): Observable<any> {
  save<T>(ano: number, account: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      uno = uno || this.cnf.usuarioLogadoNo;
      const forms: IForm[] = this.mapToForm(account as unknown as IAccount).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(account: IAccount): IForm[] {
    try {
      return [
        { VariavelNo: 387, ValorDado: account.nomeEmpresa || '' },
        { VariavelNo: 391, ValorDado: account.dataAberturaConta || '' },
        { VariavelNo: 1608, ValorDado: account.nomeConsultor || '' },
        { VariavelNo: 2230, ValorDado: account.codigo || '' },
        { VariavelNo: 2231, ValorDado: account.titulo || '' },
        { VariavelNo: 2232, ValorDado: account.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 2274, ValorDado: account.idade || '' },
        { VariavelNo: 2278, ValorDado: account.documento || '' },
        { VariavelNo: 3328, ValorDado: account.identificacao1 || '' },
        { VariavelNo: 3329, ValorDado: account.identificacao2 || '' },
        { VariavelNo: 28051, ValorDado: account.conta || '' },
        { VariavelNo: 28065, ValorDado: account.situacaoCliente ? 'SIM' : 'NÃO' },
        { VariavelNo: 28868, ValorDado: account.agendamento || '' },
        { VariavelNo: 28958, ValorDado: account.prontuarioSoma || '' },
        { VariavelNo: 28960, ValorDado: account.totalCadastro || '' },
        { VariavelNo: 28970, ValorDado: account.pRTO || '' },
        { VariavelNo: 28982, ValorDado: account.identificacoes || '' },
        { VariavelNo: 29006, ValorDado: account.endereco || '' },
        { VariavelNo: 29007, ValorDado: account.numero || '' },
        { VariavelNo: 29008, ValorDado: account.bairro || '' },
        { VariavelNo: 29009, ValorDado: account.cidade || '' },
        { VariavelNo: 29010, ValorDado: account.telefone || '' },
        { VariavelNo: 29011, ValorDado: account.estado || '' },
        { VariavelNo: 29012, ValorDado: account.celular || '' },
        { VariavelNo: 29013, ValorDado: account.eMail || '' },
        { VariavelNo: 29166, ValorDado: account.getUsuarioToken || '' },
        { VariavelNo: 30028, ValorDado: account.integradoCustodia || '' },
        { VariavelNo: 34273, ValorDado: account.complemento || '' },
        { VariavelNo: 100966, ValorDado: this.glb.ddMMYYYYThhmmssToDate(account.dataInativacao) },
        { VariavelNo: 102952, ValorDado: account.loginCliente || '' },
        { VariavelNo: 102953, ValorDado: account.loginFinal || '' },
        { VariavelNo: 102954, ValorDado: account.tipoDocumento || '' },
        { VariavelNo: 102968, ValorDado: account.cEP || '' },
        { VariavelNo: 102971, ValorDado: account.passaporteConsultor || '' },
        { VariavelNo: 102972, ValorDado: account.emailConsultor || '' },
        { VariavelNo: 103083, ValorDado: account.informeproprietariocustodia || '' },
        { VariavelNo: 103084, ValorDado: account.proprietarioCustodia ? 'SIM' : 'NÃO' },
        { VariavelNo: 103085, ValorDado: account.cotacaoDolarAmericano || '' },
        { VariavelNo: 103086, ValorDado: account.cotacaoEuro || '' },
        { VariavelNo: 103087, ValorDado: account.rOTULO || '' },
        { VariavelNo: 103091, ValorDado: account.moedaCustodia || '' },
        { VariavelNo: 103092, ValorDado: account.valorCustodia || '' },
        { VariavelNo: 103093, ValorDado: account.valorEmReais || '' },
        { VariavelNo: 103094, ValorDado: account.valorEmDolar || '' },
        { VariavelNo: 103095, ValorDado: account.valorEmEuros || '' },
        { VariavelNo: 103096, ValorDado: account.tipoitemCustodia || '' },
        { VariavelNo: 103097, ValorDado: account.descricaoItem || '' },
        { VariavelNo: 103098, ValorDado: account.taxCambioDolar || '' },
        { VariavelNo: 103099, ValorDado: account.taxCambioEuro || '' },
        { VariavelNo: 103101, ValorDado: account.retornocustodia || '' },
        { VariavelNo: 103129, ValorDado: account.selecionarRepresentante || '' },
        { VariavelNo: 103130, ValorDado: account.empresaContaRepresentante || '' },
        { VariavelNo: 103131, ValorDado: account.endereco || '' },
        { VariavelNo: 103132, ValorDado: account.numero || '' },
        { VariavelNo: 103133, ValorDado: account.complemento || '' },
        { VariavelNo: 103134, ValorDado: account.bairro || '' },
        { VariavelNo: 103135, ValorDado: account.cidade || '' },
        { VariavelNo: 103136, ValorDado: account.cEP || '' },
        { VariavelNo: 103137, ValorDado: account.telefone || '' },
        { VariavelNo: 103138, ValorDado: account.celular || '' },
        { VariavelNo: 103139, ValorDado: account.pASSAPORTE || '' },
        { VariavelNo: 103140, ValorDado: account.estado || '' },
        { VariavelNo: 103141, ValorDado: account.numeroSerial || '' },
        { VariavelNo: 103148, ValorDado: account.nacionalidade || '' },
        { VariavelNo: 103149, ValorDado: account.email || '' },
        { VariavelNo: 103151, ValorDado: account.designacao || '' },
        { VariavelNo: 103152, ValorDado: account.bENEFICIARIO || '' },
        { VariavelNo: 103155, ValorDado: account.empresaConta || '' },
        { VariavelNo: 103156, ValorDado: account.transationNO || '' },
        { VariavelNo: 103157, ValorDado: account.aMOUNT || '' },
        { VariavelNo: 103158, ValorDado: this.glb.ddMMYYYYThhmmssToDate(account.iSSUEDATE) },
        { VariavelNo: 103159, ValorDado: account.mATURITYDATE || '' },
        { VariavelNo: 103164, ValorDado: account.mOEDASIGLA || '' },
        { VariavelNo: 103165, ValorDado: account.valorPorExtenso || '' },
        { VariavelNo: 103185, ValorDado: account.barcode || '' },
        { VariavelNo: 103186, ValorDado: account.qRCode || '' },
        { VariavelNo: 103187, ValorDado: account.numeroSerialMinMax || '' },
        { VariavelNo: 103188, ValorDado: account.qRCodeURLBaseURLGenerateID || '' },
        { VariavelNo: 103189, ValorDado: account.qRCodeURLBaseURLGenerateIDOcorrenciaNo || '' },
        { VariavelNo: 103190, ValorDado: account.consultorAtivo || '' },
        { VariavelNo: 103193, ValorDado: account.end || '' },
        { VariavelNo: 103194, ValorDado: account.endCompleto || '' },
        { VariavelNo: 103195, ValorDado: account.bENEFICIARIOENDERECO || '' },
        { VariavelNo: 103196, ValorDado: account.n || '' },
        { VariavelNo: 103197, ValorDado: account.comp || '' },
        { VariavelNo: 103198, ValorDado: account.bairro || '' },
        { VariavelNo: 103199, ValorDado: account.cidade || '' },
        { VariavelNo: 103200, ValorDado: account.estado || '' },
        { VariavelNo: 103201, ValorDado: account.cep || '' },
        { VariavelNo: 103202, ValorDado: account.endCompleto || '' },
        { VariavelNo: 103203, ValorDado: account.bGNO || '' },
        { VariavelNo: 103229, ValorDado: account.tituloBeneficiario || '' },
        { VariavelNo: 29613, ValorDado: this.convertToValorTexto(account.lstsocio) },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  // insertIfNotExist(ano: number, item: IAccount, compareFieldName: string = 'titulo'): Observable<boolean> {
  insertIfNotExist<T>(ano: number, item: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], item[compareFieldName])) >= 0);
            if (!founded) {
              return this.save<IAccount>(ano, item as unknown as IAccount, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }

  protected getFromCadastroGrid(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IAccount> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      // Resgata os dados que duplicam os registros para cada item do grid e os agrupa por OcorrenciaNo
      const grouped = this.cadastroSrv
        .getCadastroComGridFiltro(cadastroNo, -1, this.lstVariaveis, this.variavelGrid, this.lstVariaveisGrid, '')
        .pipe(
          publishReplay(),
          refCount(),
          groupBy(g => g.OcorrenciaNo)
        );
      // Criação do Observable que será o formato de retorno
      return new Observable(observer => {
        let account: IAccount;
        // Subscrição para os grupos
        grouped
          .subscribe(group => { // Subscrição para acessar os dados de cada grupo
            group.pipe(toArray())
              .subscribe(item => {
                // Todos os elementos do grupo são iguais, exceto pelos campos do grid
                // por esse motivo, é possível utilizar como base para os demais dados qualquer elementos
                const c = item[0];
                // Criação de um array com os percentuais e valores por unidade de negócio
                const lstsocio: any[] = item.length <= 0 ? null :
                  item.map(m => (
                    {
                      nomeEmpresa: m.V_387,
                      conta: m.V_28051,
                      codigo: m.V_102973,
                      titulo: m.V_102974,
                      habilitado: this.lib.getBoolean(m.V_102975),
                      nome: m.V_102976,
                      tipoDocumento: m.V_102977,
                      documento: m.V_102978,
                      passaporte: m.V_102980,
                      email: m.V_102981,
                      endereco: m.V_103116,
                      numero: m.V_103117,
                      complemento: m.V_103118,
                      bairro: m.V_103119,
                      cidade: m.V_103120,
                      estado: m.V_103121,
                      cEP: m.V_103122,
                      telefone: m.V_103123,
                      celular: m.V_103124,
                      eMail: m.V_103125,
                      representante: this.lib.getBoolean(m.V_103126),
                      designacao: m.V_103144,
                      nacionalidade: m.V_103150,
                      empresaContaRepresentante: m.V_103191
                    } as any));
                account = ({
                  id: group.key, // Para exibir a OcorrenciaNo ao inves do Código do cadastro
                  ocorrenciaNo: c.OcorrenciaNo,
                  nomeEmpresa: c.V_387,
                  dataAberturaConta: c.V_391,
                  nomeConsultor: c.V_1608,
                  codigo: c.V_2230,
                  titulo: c.V_2231,
                  habilitado: this.lib.getBoolean(c.V_2232),
                  idade: c.V_2274,
                  documento: c.V_2278,
                  identificacao1: c.V_3328,
                  identificacao2: c.V_3329,
                  conta: c.V_28051,
                  situacaoCliente: this.lib.getBoolean(c.V_28065),
                  agendamento: c.V_28868,
                  prontuarioSoma: c.V_28958,
                  totalCadastro: c.V_28960,
                  pRTO: c.V_28970,
                  identificacoes: c.V_28982,
                  endereco: c.V_29006,
                  numero: c.V_29007,
                  bairro: c.V_29008,
                  cidade: c.V_29009,
                  telefone: c.V_29010,
                  estado: c.V_29011,
                  celular: c.V_29012,
                  eMail: c.V_29013,
                  getUsuarioToken: c.V_29166,
                  integradoCustodia: c.V_30028,
                  complemento: c.V_34273,
                  dataInativacao: this.glb.ddMMYYYYThhmmssToDate(c.V_100966),
                  loginCliente: c.V_102952,
                  loginFinal: c.V_102953,
                  tipoDocumento: c.V_102954,
                  cEP: c.V_102968,
                  passaporteConsultor: c.V_102971,
                  emailConsultor: c.V_102972,
                  informeproprietariocustodia: c.V_103083,
                  proprietarioCustodia: this.lib.getBoolean(c.V_103084),
                  cotacaoDolarAmericano: c.V_103085,
                  cotacaoEuro: c.V_103086,
                  rOTULO: c.V_103087,
                  moedaCustodia: c.V_103091,
                  valorCustodia: c.V_103092,
                  valorEmReais: c.V_103093,
                  valorEmDolar: c.V_103094,
                  valorEmEuros: c.V_103095,
                  tipoitemCustodia: c.V_103096,
                  descricaoItem: c.V_103097,
                  taxCambioDolar: c.V_103098,
                  taxCambioEuro: c.V_103099,
                  retornocustodia: c.V_103101,
                  selecionarRepresentante: c.V_103129,
                  empresaContaRepresentante: c.V_103130,
                  endereco2: c.V_103131,
                  numero2: c.V_103132,
                  complemento2: c.V_103133,
                  bairro2: c.V_103134,
                  cidade2: c.V_103135,
                  cEP2: c.V_103136,
                  telefone2: c.V_103137,
                  celular2: c.V_103138,
                  pASSAPORTE: c.V_103139,
                  estado2: c.V_103140,
                  numeroSerial: c.V_103141,
                  nacionalidade: c.V_103148,
                  email: c.V_103149,
                  designacao: c.V_103151,
                  bENEFICIARIO: c.V_103152,
                  empresaConta: c.V_103155,
                  transationNO: c.V_103156,
                  aMOUNT: c.V_103157,
                  iSSUEDATE: this.glb.ddMMYYYYThhmmssToDate(c.V_103158),
                  mATURITYDATE: c.V_103159,
                  mOEDASIGLA: c.V_103164,
                  valorPorExtenso: c.V_103165,
                  barcode: c.V_103185,
                  qRCode: c.V_103186,
                  numeroSerialMinMax: c.V_103187,
                  qRCodeURLBaseURLGenerateID: c.V_103188,
                  qRCodeURLBaseURLGenerateIDOcorrenciaNo: c.V_103189,
                  consultorAtivo: c.V_103190,
                  end: c.V_103193,
                  endCompleto: c.V_103194,
                  bENEFICIARIOENDERECO: c.V_103195,
                  n: c.V_103196,
                  comp: c.V_103197,
                  bairro3: c.V_103198,
                  cidade3: c.V_103199,
                  estado3: c.V_103200,
                  cep: c.V_103201,
                  endCompleto2: c.V_103202,
                  bGNO: c.V_103203,
                  tituloBeneficiario: c.V_103229,
                  lstsocio
                } as IAccount);
                // Uma vez que o item é formado, é emitido, mas o complete só será chamado quando todos os itens forem concluídos
                observer.next(account);
              });
          },
            (er) => console.log('Account.getFromCadastroGrid: ', er),
            () => {
              observer.complete();
            });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadastroGrid', error.message);
    }
    return of(null);
  }

  protected convertToValorTexto(socio: any[]): string {
    try {
      let valorTexto = '<![CDATA[<Items>';
      if (socio) {
        socio.forEach(f => {
          valorTexto += `<Item><index>${f.codigo}</index>`;
          if (f.nomeEmpresa) { valorTexto += `<V_387>${f.nomeEmpresa}</V_387>`; }
          if (f.conta) { valorTexto += `<V_28051>${f.conta}</V_28051>`; }
          if (f.codigo) { valorTexto += `<V_102973>${f.codigo}</V_102973>`; }
          if (f.titulo) { valorTexto += `<V_102974>${f.titulo}</V_102974>`; }
          if (f.habilitado) { valorTexto += `<V_102975>${f.habilitado}</V_102975>`; }
          if (f.nome) { valorTexto += `<V_102976>${f.nome}</V_102976>`; }
          if (f.tipoDocumento) { valorTexto += `<V_102977>${f.tipoDocumento}</V_102977>`; }
          if (f.documento) { valorTexto += `<V_102978>${f.documento}</V_102978>`; }
          if (f.passaporte) { valorTexto += `<V_102980>${f.passaporte}</V_102980>`; }
          if (f.email) { valorTexto += `<V_102981>${f.email}</V_102981>`; }
          if (f.endereco) { valorTexto += `<V_103116>${f.endereco}</V_103116>`; }
          if (f.numero) { valorTexto += `<V_103117>${f.numero}</V_103117>`; }
          if (f.complemento) { valorTexto += `<V_103118>${f.complemento}</V_103118>`; }
          if (f.bairro) { valorTexto += `<V_103119>${f.bairro}</V_103119>`; }
          if (f.cidade) { valorTexto += `<V_103120>${f.cidade}</V_103120>`; }
          if (f.estado) { valorTexto += `<V_103121>${f.estado}</V_103121>`; }
          if (f.cEP) { valorTexto += `<V_103122>${f.cEP}</V_103122>`; }
          if (f.telefone) { valorTexto += `<V_103123>${f.telefone}</V_103123>`; }
          if (f.celular) { valorTexto += `<V_103124>${f.celular}</V_103124>`; }
          if (f.eMail) { valorTexto += `<V_103125>${f.eMail}</V_103125>`; }
          if (f.representante) { valorTexto += `<V_103126>${f.representante}</V_103126>`; }
          if (f.designacao) { valorTexto += `<V_103144>${f.designacao}</V_103144>`; }
          if (f.nacionalidade) { valorTexto += `<V_103150>${f.nacionalidade}</V_103150>`; }
          if (f.empresaContaRepresentante) { valorTexto += `<V_103191>${f.empresaContaRepresentante}</V_103191>`; }
          valorTexto += '</Item>';
        });
      }
      valorTexto += '</Items>]]>';
      return valorTexto;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertToValorTexto', error.message);
    }
    return '';
  }


}
