export interface IRotina {
  ocorrenciaNo: number;
codigo:  any;
titulo:  any;
habilitado:  boolean;
tipoAtividade:  string;
nomeAtividade:  string;
codigoAtividade:  string;
descricaoAtividade:  string;
observacoes:  string;
nomeProfissional:  string;
inicio:  Date;
dataAtividade:  any;
horarios: any;
publicoAlvo:  string;
periodo:  string;
domingo:  boolean;
segundaFeira:  boolean;
tercaFeira:  boolean;
quartaFeira:  boolean;
quintaFeira:  boolean;
sextaFeira:  boolean;
sabado:  boolean;
local:  any;
oCorrenciaAtvTablet:  any;
residente:  string;
dRT:  string;
profOcorrencia:  any;
tipo:  string;
fim:  Date;
informeAndar:  string;
medlogicId:  any;
oCorrenciaPaciente:  any;
aCadaXDiasXaQuantidadediasseremPulados:  any;
alerta:  any;
prontuarioEinstein:  string;
medlogicId2:  string;
jsonBottonLeft2:  any;
jsonBottonLeft1:  any;
jsonBottonLeftAndarSelecionado:  any;
date?: any;
  }
