import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IFhirPatientState } from './patient-state';
import * as fromFhirPatient from './patient.reducer';

export const selectFhirPatientState = createFeatureSelector<IFhirPatientState>(fromFhirPatient.patientsFeatureKey);
export const selectFhirPatientAll = createSelector(selectFhirPatientState, fromFhirPatient.selectAll);

// export const selectFhirPatientAllActive = createSelector(
//   selectFhirPatientAll,
//   patients => patients
//     .filter(patient => patient.situacaoPaciente /*&& !patient.pacienteFalecido*/)
//     .sort((a, b) => a?.active?.localeCompare(b?.active))
// );

export const selectedFhirPatient = createSelector(selectFhirPatientState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const isFhirPatientLoading = createSelector(
  selectFhirPatientAll,
  patients => patients?.length <= 0,
);

export const selectedFhirPatientIdentifier = createSelector(selectFhirPatientState, state => {
  return (state?.selectedId || 0) > 0 ? state?.selectedId : null;
});
