import { IFrailty } from './ifrailty';
import { IAudio } from '@medlogic/shared/shared-interfaces';

export interface IFrailtyState {
    frailty: IFrailty,
    frailtys: IFrailty[],
    error?: any,
    categoria: string,
    audios: IAudio[],
    audio: IAudio,
    isPlaying: boolean
}
