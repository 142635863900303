<ng-container [ngSwitch]="wasDeletedAndIsEmpty">
  <!--Apenas exibirá uma mensagem caso a Atividade atual tenha sido excluída e o histório de navegação esteja vazio. -->
  <div *ngSwitchCase="true">
    <div class="alert alert-success">
      <h1 class="success">O item foi excluído com sucesso!</h1>
    </div>
  </div>

  <div
    id="tab-component"
    [class.readOnly]="isReadOnly"
    [class.isMobile]="isMobile"
    [class.idoso]="idoso"
    [class.showOnlySaveButton]="showOnlySaveButton"
    *ngSwitchCase="false"
    [class.black-bg]="enTheme === ENTHEME.black"
  >
    <lib-button-bar
      [isReadOnly]="isReadOnly"
      [activityType]="activityType"
      [atividade]="atividade"
      [isLoading]="isLoading"
      [isMobile]="isMobile"
      [formGroup]="formGroup"
      [saveInList]="saveInList"
      [saved]="saved"
      [canShowSavedMessages]="canShowSavedMessages"
      [addToHistory]="addToHistory"
      [formErrors]="formErrors"
      [componentes]="componentes"
      [tarefaNo]="tarefaNo"
      [msgs]="msgs"
      [enTheme]="enTheme"
      [printOnly]="printOnly"
      [isEditMode]="isEditMode"
      (eventBubble)="onEventBubble($event)"
      [showBackButton]="showBackButton"
    ></lib-button-bar>

    <div
      class="tabs-content"
      [class.tabs-content-mobile]="isMobile"
      [class.black-bg]="enTheme === ENTHEME.black"
      [class.debug]="isDebug"
      [style.zoom]="zoomFactor"
      (window:resize)="onResize()"
    >
      <!-- Nomes das Abas -->
      <!-- <ul *ngIf="!isMobile" class="nav nav-tabs form-group">
        <ng-container *ngFor="let tab of tabs">
          <li *ngIf="tab.enabled" [class.active]="isActived(tab)" class="tab"
            [class.has-error]="!validateTabComponents(tab.id, componentes, formGroup)">
            <a data-toggle="tab" href="javascript:void(0)" [attr.aria-expanded]="isActived(tab)"
              (click)="onTabClick(tab)">
              <label class="control-label">{{ tab.name }}</label>
            </a>
          </li>
        </ng-container>
      </ul> -->

      <!-- Formulário -->

      <ng-container [ngSwitch]="isMobile">
        <!-- <ng-container *ngSwitchCase="true"> -->
        <ng-container>
          <div class="tab-content">
            <div
              *ngFor="let tab2 of tabs | libVisibleTabs: componentes"
              [id]="'tab-' + tab2.id"
              class="tab-pane"
              [class.active]="isActived(tab2)"
            >
              <div
                [class]="
                  'panel-body is-mobile' + (isReadOnly ? ' read-only' : '')
                "
              >
                <label
                  class="categ-title"
                  [class.black]="enTheme === ENTHEME.black"
                  >{{ tab2.name }}</label
                >

                <lib-atividade
                  *ngIf="formGroup && componentes"
                  [tab]="tab2"
                  [componentes]="componentes | libFilterComponentesByTab: tab2"
                  [componentesAllTabs]="componentes"
                  [formErrors]="formErrors"
                  [formGroup]="formGroup"
                  (errorNotify)="errorNotify($event)"
                  (changesNotify)="changesNotify($event)"
                  (eventBubble)="onEventBubble($event)"
                  [isMobile]="isMobile"
                  [isLoading]="isLoading"
                  [enTheme]="enTheme"
                ></lib-atividade>
              </div>

              <div></div>
            </div>
          </div>
        </ng-container>

        <!-- <ng-container *ngSwitchCase="false">
          <mat-tab-group>
            <mat-tab
              *ngFor="let tab2 of tabs | libVisibleTabs: componentes"
              [label]="isMobile ? '' : tab2?.name"
              [id]="'tab-' + tab2.id"
              [class.has-error]="
                !validateTabComponents(tab2.id, componentes, formGroup)
              "
              (click)="onTabClick(tab2)"
            >
              <div class="panel-body">
                <lib-atividade
                  *ngIf="formGroup && componentes"
                  [tab]="tab2"
                  [componentes]="componentes | libFilterComponentesByTab: tab2"
                  [componentesAllTabs]="componentes"
                  [formErrors]="formErrors"
                  [formGroup]="formGroup"
                  (errorNotify)="errorNotify($event)"
                  (changesNotify)="changesNotify($event)"
                  (eventBubble)="onEventBubble($event)"
                  [isMobile]="isMobile"
                  [isLoading]="isLoading"
                  [enTheme]="enTheme"
                ></lib-atividade>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-container> -->
      </ng-container>
    </div>

    <!-- Barra de botões -->
    <div
      #btnbar
      [class]="'btnbar' + (isReadOnly ? ' read-only' : '') + (isFullSceen? ' fullScreenBtn': ' notFullScreenBtn')"
      [class.is-mobile]="isMobile"
      [class.navbar-dialog]="isDialog"
      [ngSwitch]="activityType"
    >
      <!-- StandAlone, usado no app ou acesso direto via web  -->
      <div *ngSwitchCase="enActivityType.StandAlone">
        <lib-next-action-bar
          [activityType]="activityType"
          [atividade]="atividade"
          [formGroup]="formGroup"
          [saveInList]="saveInList"
          [saved]="saved"
          [addToHistory]="addToHistory"
          [formErrors]="formErrors"
          [componentes]="componentes"
          [tabs]="tabs"
          [isMobile]="isMobile"
          [tabActivedId]="tabActivedId"
          [isLoading]="isLoading"
          [tarefaNo]="tarefaNo"
          [msgs]="msgs"
          [enTheme]="enTheme"
          (eventBubble)="onEventBubble($event)"
        >
        </lib-next-action-bar>
      </div>

      <!-- Desktop -->
      <div *ngSwitchDefault [class]="">
        <lib-action-bar
          [activityType]="activityType"
          [isEdit]="isEditMode"
          [atividade]="atividade"
          [isLoading]="isLoading"
          [isMobile]="isMobile"
          [formGroup]="formGroup"
          [saveInList]="saveInList"
          [saved]="saved"
          [canShowSavedMessages]="canShowSavedMessages"
          [addToHistory]="addToHistory"
          [formErrors]="formErrors"
          [componentes]="componentes"
          [tarefaNo]="tarefaNo"
          [msgs]="msgs"
          [enTheme]="enTheme"
          (eventBubble)="onEventBubble($event)"
        ></lib-action-bar>

        <lib-status-bar
          [backAtividadeNo]="backAtividadeNo"
          [backOcorrenciaNo]="backOcorrenciaNo"
          [isMobile]="isMobile"
          [ocorrenciaNo]="ocorrenciaNo"
          [enTheme]="enTheme"
          [atividadeNome]="atividadeNome"
          [atividadeNo]="atividadeNo"
          (eventBubble)="onEventBubble($event)"
        ></lib-status-bar>
      </div>
    </div>
  </div>
</ng-container>
