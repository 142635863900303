import { IActivityDetail } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setActivityDetail = createAction(
  '[ActivityDetail/API] Set ActivityDetail',
  props<{ selectedId: number }>()
);
export const loadActivityDetails = createAction(
  '[ActivityDetail/API] Load ActivityDetails'
);

export const loadActivityDetailsSuccess = createAction(
  '[ActivityDetail/API] Load ActivityDetails Success',
  props<{ activityDetails: IActivityDetail[] }>()
);

export const loadActivityDetailSuccess = createAction(
  '[ActivityDetail/API] Load ActivityDetail Success',
  props<{ activityDetail: IActivityDetail }>()
);

export const activityDetailFail = createAction(
  '[ActivityDetail/API] ActivityDetail Fail',
  props<{ error: string }>()
);

export const addActivityDetail = createAction(
  '[ActivityDetail/API] Add ActivityDetail',
  props<{ activityDetail: IActivityDetail }>()
);

export const addActivityDetails = createAction(
  '[ActivityDetail/API] Add ActivityDetails',
  props<{ activityDetails: IActivityDetail[] }>()
);

export const upsertActivityDetail = createAction(
  '[ActivityDetail/API] Upsert ActivityDetail',
  props<{ activityDetail: IActivityDetail }>()
);

export const upsertActivityDetails = createAction(
  '[ActivityDetail/API] Upsert ActivityDetails',
  props<{ activityDetails: IActivityDetail[] }>()
);

export const updateActivityDetail = createAction(
  '[ActivityDetail/API] Update ActivityDetail',
  props<{ activityDetail: Update<IActivityDetail> }>()
);

export const updateActivityDetails = createAction(
  '[ActivityDetail/API] Update ActivityDetails',
  props<{ activityDetails: Update<IActivityDetail>[] }>()
);

export const deleteActivityDetail = createAction(
  '[ActivityDetail/API] Delete ActivityDetail',
  props<{ id: string }>()
);

export const deleteActivityDetails = createAction(
  '[ActivityDetail/API] Delete ActivityDetails',
  props<{ ids: string[] }>()
);

export const clearActivityDetails = createAction(
  '[ActivityDetail/API] Clear ActivityDetails'
);
export const clearSelectedActivityDetail = createAction(
  '[Card ActivityDetail] Clear Selected ActivityDetails'
);
