export enum EnCurrency {
  UnitedArabEmiratesDirham = 'AED',
  AfghanAfghani = 'AFN',
  AlbanianLek = 'ALL',
  ArmenianDram = 'AMD',
  NetherlandsAntilleanGuilder = 'ANG',
  AngolanKwanza = 'AOA',
  ArgentinePeso = 'ARS',
  AustralianDollar = 'AUD',
  ArubanFlorin = 'AWG',
  AzerbaijaniManat = 'AZN',
  BosniaHerzegovinaConvertibleMark = 'BAM',
  BarbadianDollar = 'BBD',
  BangladeshiTaka = 'BDT',
  BulgarianLev = 'BGN',
  BahrainiDinar = 'BHD',
  BurundianFranc = 'BIF',
  BermudanDollar = 'BMD',
  BruneiDollar = 'BND',
  BolivianBoliviano = 'BOB',
  BrazilianReal = 'BRL',
  BahamianDollar = 'BSD',
  Bitcoin = 'BTC',
  BhutaneseNgultrum = 'BTN',
  BotswananPula = 'BWP',
  BelarusianRuble = 'BYN',
  BelarusianRublepre2016 = 'BYR',
  BelizeDollar = 'BZD',
  CanadianDollar = 'CAD',
  CongoleseFranc = 'CDF',
  SwissFranc = 'CHF',
  ChileanUnitofAccountUF = 'CLF',
  ChileanPeso = 'CLP',
  ChineseYuanOffshore = 'CNH',
  ChineseYuan = 'CNY',
  ColombianPeso = 'COP',
  CostaRicanColón = 'CRC',
  CubanConvertiblePeso = 'CUC',
  CubanPeso = 'CUP',
  CapeVerdeanEscudo = 'CVE',
  CzechRepublicKoruna = 'CZK',
  DjiboutianFranc = 'DJF',
  DanishKrone = 'DKK',
  DominicanPeso = 'DOP',
  AlgerianDinar = 'DZD',
  EstonianKroon = 'EEK',
  EgyptianPound = 'EGP',
  EritreanNakfa = 'ERN',
  EthiopianBirr = 'ETB',
  Euro = 'EUR',
  FijianDollar = 'FJD',
  FalklandIslandsPound = 'FKP',
  BritishPoundSterling = 'GBP',
  GeorgianLari = 'GEL',
  GuernseyPound = 'GGP',
  GhanaianCedi = 'GHS',
  GibraltarPound = 'GIP',
  GambianDalasi = 'GMD',
  GuineanFranc = 'GNF',
  GuatemalanQuetzal = 'GTQ',
  GuyanaeseDollar = 'GYD',
  HongKongDollar = 'HKD',
  HonduranLempira = 'HNL',
  CroatianKuna = 'HRK',
  HaitianGourde = 'HTG',
  HungarianForint = 'HUF',
  IndonesianRupiah = 'IDR',
  IsraeliNewSheqel = 'ILS',
  Manxpound = 'IMP',
  IndianRupee = 'INR',
  IraqiDinar = 'IQD',
  IranianRial = 'IRR',
  IcelandicKróna = 'ISK',
  JerseyPound = 'JEP',
  JamaicanDollar = 'JMD',
  JordanianDinar = 'JOD',
  JapaneseYen = 'JPY',
  KenyanShilling = 'KES',
  KyrgystaniSom = 'KGS',
  CambodianRiel = 'KHR',
  ComorianFranc = 'KMF',
  NorthKoreanWon = 'KPW',
  SouthKoreanWon = 'KRW',
  KuwaitiDinar = 'KWD',
  CaymanIslandsDollar = 'KYD',
  KazakhstaniTenge = 'KZT',
  LaotianKip = 'LAK',
  LebanesePound = 'LBP',
  SriLankanRupee = 'LKR',
  LiberianDollar = 'LRD',
  LesothoLoti = 'LSL',
  LibyanDinar = 'LYD',
  MoroccanDirham = 'MAD',
  MoldovanLeu = 'MDL',
  MalagasyAriary = 'MGA',
  MacedonianDenar = 'MKD',
  MyanmaKyat = 'MMK',
  MongolianTugrik = 'MNT',
  MacanesePataca = 'MOP',
  MauritanianOuguiyapre2018 = 'MRO',
  MauritanianOuguiya = 'MRU',
  MalteseLira = 'MTL',
  MauritianRupee = 'MUR',
  MaldivianRufiyaa = 'MVR',
  MalawianKwacha = 'MWK',
  MexicanPeso = 'MXN',
  MalaysianRinggit = 'MYR',
  MozambicanMetical = 'MZN',
  NamibianDollar = 'NAD',
  NigerianNaira = 'NGN',
  NicaraguanCórdoba = 'NIO',
  NorwegianKrone = 'NOK',
  NepaleseRupee = 'NPR',
  NewZealandDollar = 'NZD',
  OmaniRial = 'OMR',
  PanamanianBalboa = 'PAB',
  PeruvianNuevoSol = 'PEN',
  PapuaNewGuineanKina = 'PGK',
  PhilippinePeso = 'PHP',
  PakistaniRupee = 'PKR',
  PolishZloty = 'PLN',
  ParaguayanGuarani = 'PYG',
  QatariRial = 'QAR',
  RomanianLeu = 'RON',
  SerbianDinar = 'RSD',
  RussianRuble = 'RUB',
  RwandanFranc = 'RWF',
  SaudiRiyal = 'SAR',
  SolomonIslandsDollar = 'SBD',
  SeychelloisRupee = 'SCR',
  SudanesePound = 'SDG',
  SwedishKrona = 'SEK',
  SingaporeDollar = 'SGD',
  SaintHelenaPound = 'SHP',
  SierraLeoneanLeone = 'SLL',
  SomaliShilling = 'SOS',
  SurinameseDollar = 'SRD',
  SouthSudanesePound = 'SSP',
  SãoToméandPríncipeDobrapre2018 = 'STD',
  SãoToméandPríncipeDobra = 'STN',
  SalvadoranColón = 'SVC',
  SyrianPound = 'SYP',
  SwaziLilangeni = 'SZL',
  ThaiBaht = 'THB',
  TajikistaniSomoni = 'TJS',
  TurkmenistaniManat = 'TMT',
  TunisianDinar = 'TND',
  TonganPaʻanga = 'TOP',
  TurkishLira = 'TRY',
  TrinidadandTobagoDollar = 'TTD',
  NewTaiwanDollar = 'TWD',
  TanzanianShilling = 'TZS',
  UkrainianHryvnia = 'UAH',
  UgandanShilling = 'UGX',
  UnitedStatesDollar = 'USD',
  UruguayanPeso = 'UYU',
  UzbekistanSom = 'UZS',
  VenezuelanBolívarFuerte = 'VEF',
  VietnameseDong = 'VND',
  VanuatuVatu = 'VUV',
  SamoanTala = 'WST',
  CFAFrancBEAC = 'XAF',
  Silvertroyounce = 'XAG',
  Goldtroyounce = 'XAU',
  EastCaribbeanDollar = 'XCD',
  SpecialDrawingRights = 'XDR',
  CFAFrancBCEAO = 'XOF',
  PalladiumOunce = 'XPD',
  CFPFranc = 'XPF',
  PlatinumOunce = 'XPT',
  YemeniRial = 'YER',
  SouthAfricanRand = 'ZAR',
  ZambianKwachapre2013 = 'ZMK',
  ZambianKwacha = 'ZMW',
}
