import { EnLoginState, IContact, EnMaterialIcon } from '@medlogic/shared/shared-interfaces';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';

// export const selectLogin = (state: IAppMedlogicState) => state.login;

export const selectLoginState = (state: IAppMedlogicState) => {
    // FIXME: forçando o login state para sempre ter que digitar o login, em função da expiração do token.
    return EnLoginState.Login
    // state?.login?.enLoginState || EnLoginState.Login;
}

export const selectContact = () => ({
    icon: EnMaterialIcon.contact_support,
    title: 'Contato',
    name: 'Daniel Melo',
    email: 'daniel@medlogic.com.br',
    phone: '+55 (31) 9.9102-7900',
    whatsapp: '5531991027900',
    website: 'https://www.medlogic.com.br/'
} as IContact);