import { EnTheme, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';

export interface IListDetailParam {
  ano: number;
  ono: number;
  listvno: number;
  isReadOnly: boolean;
  saveInList: boolean;
  defaultFormControls: any;
  lstCadastroAdicional: any[];
  enTheme: EnTheme;
  isMobile: boolean;
  ctrl: IAtividadeComponenteDAL;
}
