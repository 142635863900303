<ng-container *ngLet="isLoadingMedications$ | async as isLoadingMedications">
  <div class="grid-container">
    <!-- Busca -->
    <div class="top-bar" [class.is-loading]="isLoadingMedications">
      <button
        mat-icon-button
        *ngIf="!isLoadingMedications"
        (click)="refreshClick($event)"
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <div *ngIf="isLoadingMedications" class="loading">
        <div class="loadingio-spinner-reload-ab7iotzfzyq">
          <div class="ldio-6zr9xpiqa9q">
            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <mat-form-field>
        <input
          matInput
          type="text"
          placeholder="Pesquisar"
          (click)="onSearchClick($event)"
          (blur)="onBlurClick($event)"
          [(ngModel)]="keyword"
          [ngModelOptions]="{ standalone: true }"
          (keyup.esc)="keyword = ''"
          autofocus
        />

        <button
          mat-button
          *ngIf="keyword"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearSearch()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div *ngIf="showTime">
        <h3>{{ today }}</h3>
        <h2>
          <i class="fa fa-clock-o mr"></i
          >{{ currentTime$ | async | date: 'mediumTime' }}
        </h2>
      </div>
    </div>

    <div class="scroll">
      <div class="wrapper wrapper-content animated fadeInRight">
        <ui-loading [isLoading]="isLoading$ | async"> </ui-loading>

        <div
          class="grid-flex"
          *ngIf="patients$ | async as patients; else empty"
        >
          <!-- Paciente selecionado -->
          <ng-container
            *ngIf="
              showPatient && selectedPatient$ | async as selectedPatient;
              else patientList
            "
          >
            <app-btn-back
              (backButtonClicked)="onActionBackToList()"
            ></app-btn-back>

            <ml-patient-header
              [patient]="selectedPatient"
              [showMiniImg]="showMiniImg"
            >
            </ml-patient-header>

            <ml-patient-action
              [patient]="selectedPatient"
              [cadEvolutionNo]="cadEvolutionNo"
              [cadVitalSignsNo]="cadVitalSignsNo"
              [cadCheckMedicamentosNo]="cadCheckMedicamentosNo"
              [cadStockNo]="cadEstoqueMateriaisNo"
              [cadResponsavelFamiliaNo]="cadResponsavelFamiliaNo"
              [showVitalSigns]="showVitalSigns"
              [showPatientReport]="showPatientReport"
              [showMedicationCheckin]="showMedicationCheckin"
              [showDailyEvaluation]="showDailyEvaluation"
              [showCarePlan]="showCarePlan"
              [showCarePlanReport]="showCarePlanReport"
              [showCustomModules]="showCustomModules"
            >
            </ml-patient-action>
          </ng-container>

          <!-- Lista de pacientes -->
          <ng-template #patientList>
            <ng-container
              *ngFor="let patient of patients | patientPipe: keyword"
            >
              <ml-patient-list
                [patient]="patient"
                [showMiniImg]="showMiniImg"
                (click)="onPatientClick(patient)"
              >
              </ml-patient-list>
            </ng-container>
          </ng-template>

          <!-- <div *ngFor="let patient of pv.patients | patientPipe:keyword" class="col-lg-3">
          <div class="contact-box center-version card" [class.miniCard]="showMiniImg">
            <app-patient-header [patient]="patient" [showMiniImg]="showMiniImg"></app-patient-header>
            <app-patient-action [patient]="patient" [cadEvolutionNo]="cadEvolutionNo" [cadVitalSignsNo]="cadVitalSignsNo" [cadCheckMedicamentosNo]="cadCheckMedicamentosNo" [cadResponsavelFamiliaNo]="cadResponsavelFamiliaNo" ></app-patient-action>
          </div>
        </div> -->
          <!-- Fim pacientes -->
        </div>

        <ng-template #empty>
          <h2>Nenhum registro encontrado.</h2>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
