export interface IIntervecoesMedicamentosHorarios {
    ocorrenciaNo: number;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    hora: any;
    codigoMedicamentoPaciente: any;
    Id_Paciente: number;
    Id_Medicamento: number;
    IdMedicamento_IdPaciente: string;
}
