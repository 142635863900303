
import { map, mergeMap, catchError } from 'rxjs/operators';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { IQueueItem } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable()
export class AsyncLocalStorageCacheService extends UnsubscribeOnDestroyAdapter {


  constructor(
    private log: LogService,
    private localStorage: LocalStorage,
    private glb: GlobalService,
    private cnf: ConfigStateService
  ) {
    super();
  }

  /* Adiciona um item na fila de chamadas.
   * Há várias filas simultaneas, uma para cada nome.
   */
  public queueItem(queueName: string, item: IQueueItem): Observable<any> {
    try {
      return this.localStorage.getItem(queueName)
        .pipe(
          mergeMap((queue2: any) => {
            if (queue2 && queue2.length > 0) { // fila já existente, acrescentar o item
              queue2.push(item);
            } else { // nova fila, criar
              queue2 = [item];
            }
            return this.localStorage.setItem(queueName, queue2);
          }),
          mergeMap(insertedItem =>
            this.updatePending(queueName) // Para atualizar o contador de pendentes.
          ),
          catchError((err, obs) => {
            this.log.Registrar(this.constructor.name, 'queueItem.pipe', err.message);
            return obs;
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'queueItem', error.message);
    }
  }

  /* Atualiza a propriedade no config que armazena o número de itens pendentes de sincronismo.  */
  updatePending(queueName: string): Observable<boolean> {
    try {
      return this.getNumOfPending(queueName)
        .pipe(
          mergeMap((s: number) => {
            this.cnf.pending = s;
            return of(s >= 0);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updatePending', error.message);
    }
    return of(null);
  }

  /* Atualiza o contador de itens sincronizados com sucesso.
   * count: número de itens atualizados a serem acrescentados.
  */
  updateSaved(count: number = 1): Observable<number> {
    try {
      return of(this.cnf.saved++);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateSaved', error.message);
    }
    return of(null);
  }

  /* Retorna o número de itens pendentes na fila (queue). */
  protected getNumOfPending(queueName: string): Observable<number> {
    try {
      const count = this.getQueue(queueName).pipe(
        map(m => m?.length || 0));
      return count;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getNumOfPending', error.message);
    }
  }

  /* Retorna uma fila específica. */
  public getQueue(queueName: string): Observable<any> {
    try {
      return this.localStorage.getItem(queueName);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getQueue', error.message);
    }
    return of(null);
  }

  /* Limpa a fila com o nome. */
  public queueClear(queueName: string): void {
    try {
      this.localStorage.removeItem(queueName);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'queueClear', error.message);
    }
  }

  /* Limpa um único item da fila com uma chave específica. */
  public queueClearItem(queueName: string, key: string): Observable<boolean> {
    try {
      return this.getQueue(queueName)
        .pipe(
          mergeMap(queueItems => {
            try {
              if (queueItems?.length > 0) { // fila já existente, acrescentar o item nessa fila
                const find = queueItems.findIndex(f => f.hasOwnProperty('key') ? this.glb.isEqual(f.key, key) : false);
                queueItems.splice(find, 1);
                if (queueItems.length > 0) {
                  return this.localStorage.setItem(queueName, queueItems)
                    .pipe(
                      mergeMap(insertedItem =>
                        this.updatePending(queueName)
                      ),
                      mergeMap(insertedItem =>
                        this.updateSaved().pipe(map(m => m > 0))
                      )
                    );
                } else {
                  return this.localStorage.removeItem(queueName)
                    .pipe(
                      mergeMap(removedItem =>
                        this.updatePending(queueName)
                      ),
                      mergeMap(removedItem =>
                        this.updateSaved().pipe(map(m => m > 0))
                      )
                    );
                }
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'queueClearItem.subscribe', error.message);
            }
            return of(null);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'queueClearItem', error.message);
    }
  }

  /* Limpa todas as filas existentes. */
  public clearAllQueue(): void {
    try {
      this.localStorage.clear();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearAllCache', error.message);
    }
  }

}
