import { ConfigStateService } from '@medlogic/shared/state-config';
import { Directive, Input, ElementRef, Renderer2, OnInit, OnChanges } from '@angular/core';
import { LibService } from '../service/lib.service';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[libCtrPosition]'
})
export class CtrPositionDirective implements OnInit, OnChanges {
  // tslint:disable-next-line: variable-name
  private _ctrl: any;
  @Input('libCtrPosition')
  public get ctrl(): any {
    return this._ctrl;
  }
  public set ctrl(v: any) {
    this._ctrl = v;
  }

  @Input() deltaY = 25;

  @Input() positionIsMobile = false;
  @Input() height?: string = null;

  @Input() deltaX = 7;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private log: LogService,
    private lib: LibService,
    private cnfJson: ConfigJsonService
  ) { }

  /*Para resolver o problema do - na atualização, procurar um evento que não seja o Init. */
  ngOnInit(): void {
    try {
      // this.applyStyles(this.ctrl, this.deltaX, this.deltaY, this.isMobile, this.height);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  ngOnChanges(): void {
    this.applyStyles(this.ctrl, this.deltaX, this.deltaY, this.positionIsMobile, this.height);
  }

  applyStyles(
    ctrl: IAtividadeComponenteDAL,
    deltaX: number,
    deltaY: number,
    isMobile: boolean,
    height?: string
  ): void {
    try {
      if (ctrl) {
        if (!isMobile) {
          if (this.lib.isOutsideDisplayArea(ctrl) && this.cnfJson.showInvisible) {
            const outsidePos = this.lib.getNextOutsideDisplayAreaPosition();
            this.renderer.setStyle(this.el.nativeElement, 'top', `${outsidePos.top}px`);
            this.renderer.setStyle(this.el.nativeElement, 'left', `${outsidePos.left}px`);
          } else {
            this.renderer.setStyle(this.el.nativeElement, 'top', ctrl.Linha + deltaY + 'px');
            this.renderer.setStyle(this.el.nativeElement, 'left', ctrl.Coluna + deltaX + 'px');
          }
          this.renderer.setStyle(this.el.nativeElement, 'position', 'absolute');
          this.renderer.addClass(this.el.nativeElement, 'isMobile');
          // TabOrder não pode ser definido no componente angular, pois o foco deve ser específico no controle de edição
          // this.renderer.setElementAttribute(this.el.nativeElement, "tabIndex", ctrl.TabOrder.toString());
        } else {
          this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
          this.renderer.setStyle(this.el.nativeElement, 'top', '0');
          this.renderer.setStyle(this.el.nativeElement, 'left', '0');
          this.renderer.removeClass(this.el.nativeElement, 'isMobile');
          if (!height) {
            this.renderer.setStyle(this.el.nativeElement, 'height', ctrl.Altura + 'px');
          }
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyStyles', error.message);
    }
  }
}
