import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ScheduledTimingEffects } from './+state/scheduledTiming.effects';

import * as fromScheduledTiming from './+state/scheduledTiming.reducer';

// const MODULES = [
//   // CommonModule
// ];

const STATE_MODULES = [
  StoreModule.forFeature(fromScheduledTiming.scheduledTimingsFeatureKey, fromScheduledTiming.reducer),
  EffectsModule.forFeature([ScheduledTimingEffects])
];

@NgModule({
  imports: [STATE_MODULES]
})
export class StateScheduledTimingModule { }
