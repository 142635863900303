<mat-toolbar color="basic">

  <span class="example-spacer"></span>
  <mat-icon class="example-icon" (click)="onNoClick()">close</mat-icon>

</mat-toolbar>

<div class="grid" [class.black]="isDark">

  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

  <div class="icn">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  </div>

  <h2>{{ title }}</h2>

  <a *ngIf="!isLoading" [href]="(href | safeUrl)" [download]="fileName">{{ fileName }}</a>
  
  <div class="btn-bar">
    <button mat-raised-button (click)="onNoClick()">FECHAR</button>
  </div>

</div>