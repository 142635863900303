import { EnActivityType } from '@medlogic/shared/shared-interfaces';
import { EnActivityMode, EnCrud, EnShift, IActivityTypeCodeableConcept } from '@medlogic/shared/shared-interfaces';
import { IActivity } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setShift = createAction(
  '[Activity/API] Set Shift',
  props<{ shift: EnShift }>()
);

export const setCrud = createAction(
  '[Activity/API] Set the CRUD operation',
  props<{ crud: EnCrud, activityType: EnActivityType, activityName: string }>()
);

export const setActivity = createAction(
  '[Activity/API] Set Activity',
  props<{ selectedId: string }>()
);

export const setActivityType = createAction(
  '[Activity/API] Set ActivityType',
  props<{ selectedActivityType: EnActivityType }>()
);

export const loadActivities = createAction(
  '[Activity/API] Load Activities'
);

export const loadActivityTypes = createAction(
  '[Activity/API] Load Activity Types'
);

export const loadActivitiesSuccess = createAction(
  '[Activity/API] Load Activities Success',
  props<{ activities: IActivity[] }>()
);

export const activityFail = createAction(
  '[Activity/API] Activity Fail',
  props<{ error: string }>()
);

export const loadActivityTypesSuccess = createAction(
  '[Activity/API] Load Activity Types Success',
  props<{ activityTypes: IActivityTypeCodeableConcept[] }>()
);

export const activityTypesFail = createAction(
  '[Activity/API] Activity Types Fail',
  props<{ error: string }>()
);

export const addActivity = createAction(
  '[Activity/API] Add Activity',
  props<{ activity: IActivity }>()
);

export const addActivities = createAction(
  '[Activity/API] Add Activities',
  props<{ activities: IActivity[] }>()
);

export const upsertActivity = createAction(
  '[Activity/API] Upsert Activity',
  props<{ activity: IActivity }>()
);

export const upsertActivities = createAction(
  '[Activity/API] Upsert Activities',
  props<{ activities: IActivity[] }>()
);

export const updateActivity = createAction(
  '[Activity/API] Update Activity',
  props<{ activity: Update<IActivity> }>()
);

export const updateActivities = createAction(
  '[Activity/API] Update Activities',
  props<{ activities: Update<IActivity>[] }>()
);

export const deleteActivity = createAction(
  '[Activity/API] Delete Activity',
  props<{ id: string }>()
);

export const deleteActivities = createAction(
  '[Activity/API] Delete Activities',
  props<{ ids: string[] }>()
);

export const clearActivities = createAction(
  '[Activity/API] Clear Activities'
);
export const clearSelectedActivity = createAction(
  '[Card Activity] Clear Selected Activities'
);

export const setActivityMode = createAction(
  '[Activity/API] Set Activity Mode',
  props<{ selectedActivityMode: EnActivityMode }>()
);

export const setIsLoadingActivity = createAction(
  '[Activity/API] Set isLoadingActivity',
  props<{ isLoading: boolean }>()
);

