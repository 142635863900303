import { metaReducers } from './+state/cost-center.metareducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CostCenterEffects } from './+state/cost-center.effects';

import * as fromCostCenter from './+state/cost-center.reducer';

const MODULES = [
  CommonModule
];

const STATE_MODULES = [
  StoreModule.forFeature(fromCostCenter.costCentersFeatureKey, fromCostCenter.reducer, { metaReducers }),
  EffectsModule.forFeature([CostCenterEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
})
export class StateCostCenterModule {}
