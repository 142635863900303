import { FormGroup } from '@angular/forms';
import { LibService } from '../../../shared/service/lib.service';
import { CalculadoraConditionService } from '../../../shared/service/calculadora-condition.service';
import { Component, OnInit, Input } from '@angular/core';
import { LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { DisplayObject } from '@medlogic/shared/gecore';
import { EnTheme } from '@medlogic/shared/shared-interfaces';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-title-label',
  templateUrl: './title-label.component.html',
  styleUrls: ['./title-label.component.scss']
})
export class TitleLabelComponent extends DisplayObject implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup: FormGroup;
  @Input() enTheme = EnTheme.default;
  @Input() isMobile = false;

  showAlert = false;
  // alertColor = 'black';
  // customValidationColor = 'black';

  public get customValidationColor(): string {
    return this.enTheme === EnTheme.black ? 'rgb(66 233 251)' : '#ed5565';
  }

  // tslint:disable-next-line: variable-name
  private _alertColor: string;
  public get alertColor(): string {
    if (!this._alertColor) {
      return this.enTheme === EnTheme.black ? 'rgb(66 233 251)' : '#ed5565';
    }
    return this._alertColor;
  }
  public set alertColor(v: string) {
    this._alertColor = v;
  }

  ENTHEME = EnTheme;

  constructor(
    global: GlobalService,
    private log: LogService,
    protected calcCond: CalculadoraConditionService,
    protected lib: LibService
  ) {
    super();
    // this.isMobile = global.isMobile();
  }

  ngOnInit() {
    try {
      this.configAlert(this.ctrl);
      this.subscribeToChanges(this.formGroup, this.ctrl);
      // this.ctrl.Rotulo = this.ctrl.RequiredField ? `${this.ctrl.Rotulo}*` : this.ctrl.Rotulo;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Se inscreve a modificações no formulário */
  protected subscribeToChanges(fg: FormGroup, ctrl: IAtividadeComponenteDAL): void {
    try {
      this.subs.sink = fg.valueChanges.subscribe((v) => this.configAlert(ctrl));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'subscribeToChanges', error.message);
    }
  }

  /* Configura a validação por fórmula */
  showCustomValidation(ctrl: IAtividadeComponenteDAL, formGroup: FormGroup): boolean {
    try {
      return ctrl?.IsShowValidationMessage && this.isControlInvalid(ctrl, formGroup);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showCustomValidation', error.message);
    }
    return false;
  }

  /* Verifica se o controle é valido. */
  isControlInvalid(ctrl: IAtividadeComponenteDAL, formGroup: FormGroup): boolean {
    try {
      if (formGroup && (typeof formGroup?.get === 'function')) {
        const control = formGroup?.get(this.lib.getId(ctrl?.VariavelNo));
        if (control) {
          return control?.invalid;
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isControlInvalid', error.message);
    }
    return false;
  }

  /* Configura o alerta, caso existente */
  protected configAlert(ctrl: IAtividadeComponenteDAL): void {
    try {
      if (ctrl?.IsShowAlertMessage) {
        if (
          this.calcCond.wasChanged(
            ctrl?.hasLstControlesReferenciadosCustomAlert,
            ctrl?.lstControlesReferenciadosCustomAlert
          )
        ) {
          ctrl.hasLstControlesReferenciadosCustomAlert = this.calcCond.getHash(
            ctrl?.lstControlesReferenciadosCustomAlert
          );
          this.showAlert = this.calcCond.calculate(
            ctrl,
            ctrl?.lstControlesReferenciadosCustomAlert,
            ctrl?.Alert
          );
          this.alertColor = ctrl?.AlertColor;
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'configAlert', error.message);
    }
  }

  /* retorna um id para o controle baseado na variavelNo */
  getId(ctrl: IAtividadeComponenteDAL): string {
    try {
      return this.lib.getId(ctrl?.VariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getId', error.message);
    }
  }

  /* Checa se o tema black está ativo. */
  isBlack(): boolean {
    try {
      return this.enTheme === EnTheme.black && !this.isControlInvalid(this.ctrl, this.formGroup);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isBlack', error.message);
    }
    return false;
  }

  /* Checa se o tema black está ativo e se há erros de validação. */
  isBlackError(): boolean {
    try {
      return this.enTheme === EnTheme.black && this.isControlInvalid(this.ctrl, this.formGroup);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isBlackError', error.message);
    }
    return false;
  }


}
