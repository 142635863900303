import { IActivityDetail, IProgress } from '@medlogic/shared/shared-interfaces';

export interface IPostFhirActivityDetailRequest {
    activityDetail: IActivityDetail;
    progress: IProgress;
}

export interface IPostFhirActivityDetailResponse {
    activityDetail: IActivityDetail;
    progress: IProgress;
}
