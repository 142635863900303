import { IPatientState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as PatientActions from './patient.actions';

export const patientsFeatureKey = 'patient';


export const adapterPatient: EntityAdapter<IPatient> = createEntityAdapter<IPatient>({
  selectId: instance => +instance.codigo,
  sortComparer: sortOnOcurrencyAndNames
});

export const initialStatePatient: IPatientState = adapterPatient.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
});

// FIXME: Falta incluir regras de negócio de ordenação para os alertas
function usersSortFn(a: IPatient, b: IPatient) {
  return a?.nomeHospede.localeCompare(b?.nomeHospede);
}

function sortOnOcurrencyAndNames(a: IPatient, b: IPatient) {
  if (a.calcHasOccurrence === b.calcHasOccurrence) {
    return a.nomeHospede < b.nomeHospede ? -1 : 1;
  } else {
    return a.calcHasOccurrence ? -1 : 1;
  }
}

export const reducer = createReducer(
  initialStatePatient,
  on(PatientActions.addPatient,
    (state, action) => adapterPatient.addOne(action.patient, state)
  ),
  on(PatientActions.setPatient,
    (state, action) => ({ ...state, selectedId: action.selectedId })
  ),
  on(PatientActions.upsertPatient,
    (state, action) => adapterPatient.upsertOne(action.patient, state)
  ),
  on(PatientActions.addPatients,
    (state, action) => adapterPatient.addMany(action.patients, state)
  ),
  on(PatientActions.upsertPatients,
    (state, action) => adapterPatient.upsertMany(action.patients, state)
  ),
  on(PatientActions.updatePatient,
    (state, action) => adapterPatient.updateOne(action.patient, state)
  ),
  on(PatientActions.updatePatients,
    (state, action) => adapterPatient.updateMany(action.patients, state)
  ),
  on(PatientActions.deletePatient,
    (state, action) => adapterPatient.removeOne(action.id, state)
  ),
  on(PatientActions.deletePatients,
    (state, action) => adapterPatient.removeMany(action.ids, state)
  ),
  on(PatientActions.loadPatientsSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterPatient.upsertMany(action.patients, state)
    (state, action) => adapterPatient.setAll(action.patients, state)
  ),
  on(PatientActions.patientFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(PatientActions.clearPatients,
    state => initialStatePatient
  ),
  on(PatientActions.clearSelectedPatient,
    state => ({ ...state, selectedId: null })
  ),
  on(PatientActions.loadPatients,
    state => ({ ...state })
  ),
  on(PatientActions.loadPatientsWithMedications,
    state => ({ ...state })
  ),
  on(PatientActions.loadPatientsWithAlerts,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterPatient.getSelectors();


export const getSelectedId = (state: IPatientState) => state.selectedId;