import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IActivity, IActivityTypeCodeableConcept } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { withLatestFrom, mergeMap, catchError, of, map, tap, switchMap } from 'rxjs';
import { loadActivities, loadActivitiesSuccess, activityFail, loadActivityTypes, loadActivityTypesSuccess, activityTypesFail, setIsLoadingActivity } from './activity.actions';
import { FhirActivityService } from '../../service/fhir-activity.service';
import { FhirActivityTypeService } from '../../service/fhir-activity-type.service';


@Injectable()
export class ActivityEffects {

  constructor(
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private activitySrv: FhirActivityService,
    private activityTypeSrv: FhirActivityTypeService,
  ) { }




  loadActivities$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadActivities),
      withLatestFrom(this.store), // TODO
      mergeMap(([action, state]) => {
        return this.activitySrv.getActivitiesByPatientId<IActivity[]>(state.patient.selectedId)
      }),
      switchMap((activities: IActivity[] | null) => {
        return activities ? [loadActivitiesSuccess({ activities }), setIsLoadingActivity({ isLoading: false })] : [activityFail({ error: '' }), setIsLoadingActivity({ isLoading: false })]
        }
      ),
      catchError((e: any) => {
        console.log(e);
        return of(activityFail({ error: e?.message || e }));
      }),
    )
  );

  loadActivityTypes$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadActivityTypes),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => this.activityTypeSrv.getAll<IActivityTypeCodeableConcept[]>()),
      map((activityTypes: IActivityTypeCodeableConcept[] | null) =>
        activityTypes ? loadActivityTypesSuccess({ activityTypes }) : activityTypesFail({ error: '' })
        // setIsLoading({ isLoading: false })
      ),
      catchError((e: any) => {
        console.log(e);
        return of(activityTypesFail({ error: e?.message || e }));
      })
    )
  );
}
