export enum EnActivityType {
  Fralda = 'Fralda',
  Banho = 'Banho',
  Alimentacao = 'Alimentacao',
  Vestuario = 'Vestuario',
  HigieneOral = 'Higiene Oral',
  HigieneIntima = 'Higiene Intima',
  MudancaDecubito = 'Mudanca Decubito',
  Acompanhamento = 'Acompanhamento',
  Deambulacao = 'Deambulacao',
  Ronda = 'Ronda',
  Transferencia = 'Transferencia',
  AsseioPessoal = 'Asseio Pessoal',
  //Tipos do Banco de Dados
  Individual = 'Individual',
  Coletiva = 'Coletiva',
  // quickfix to differentiate scheduled and unscheduled before progress dispatch of activity detail
  Unscheduled = 'Unscheduled',
  Scheduled = 'Scheduled',
}
