import { createAction, props } from '@ngrx/store';
import { ILogin, EnLoginState } from '@medlogic/shared/shared-interfaces';

export enum LoginActionTypes {
    DoLoginAndLoadRoot = '[Login View] LOGIN_AND_LOAD_FIRST_ROUTE',
    DoWebUserLogin = '[Login View] LOGIN_WEBUSER',
    SuccessLogin = '[Login effects] LOGIN_SUCCESS', // Set the Login to the Store, after Effects
    FailLogin = '[Login effects] LOGIN_FAIL',
    LogOut = '[Login View] LOGOUT',
    SuccessLogOut = '[Login effects] LOGOUT_SUCCESS',
    FailLogOut = '[Login effects] LOGOUT_FAIL',
    SetLoginState = '[Login effects] SET_LOGIN_STATE',
    SetCustomerId = '[Login effects] SET_LOGIN_CUSTOMER_ID'
}

export const loginAndLoadRoot = createAction(LoginActionTypes.DoLoginAndLoadRoot, props<{ userName: string, password: string }>());
export const webUserLogin = createAction(LoginActionTypes.DoWebUserLogin, props<{ userName: string, password: string }>());
export const successLogin = createAction(LoginActionTypes.SuccessLogin, props<{ selectedLogin: ILogin }>());
export const failLogin = createAction(LoginActionTypes.FailLogin, props<{ selectedLogin: ILogin | { message: string } }>());
export const setLoginState = createAction(LoginActionTypes.SetLoginState, props<{ enLoginState: EnLoginState }>());
export const setCustomerId = createAction(LoginActionTypes.SetCustomerId, props<{ customerId: number }>());

export const logOut = createAction(LoginActionTypes.LogOut);
export const successLogOut = createAction(LoginActionTypes.SuccessLogOut);
export const failLogOut = createAction(LoginActionTypes.FailLogOut);
