<div [formGroup]="formGroup" class="form-group" [class.is-mobile]="isMobile"
  [class.has-error]="!isValid(formGroup, ctrl)">

  <mat-form-field appearance="fill" class="matForm" [style.width]="getWidth(ctrl)">

    <mat-label [class.black]="enTheme === 'black'">{{ ctrl?.Rotulo }}</mat-label>

    <input matInput [name]="getId(ctrl)" [matDatepicker]="picker" [class.is-mobile]="isMobile" class="dtPicker"
       #dtInput [formControl]="date" [class.black]="enTheme === 'black'">

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

    <mat-datepicker touchUi #picker></mat-datepicker>

  </mat-form-field>

</div>