import { IMedlogicEvolution } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const loadEvolutions = createAction(
  '[Evolution/API] Load Evolutions'
);

export const setEvolution = createAction(
  '[card Evolution List] Set Evolutions',
  props<{ selectedId: number }>()
);

export const loadEvolutionsSuccess = createAction(
  '[Evolution/API] Load Evolutions Success',
  props<{ evolutions: IMedlogicEvolution[] }>()
);

export const loadEvolutionsByPeriod = createAction(
  '[card Evolution] Load Evolutions By Period',
  props<{ dtStart: Date, dtEnd: Date }>()
);

export const loadEvolutionsBySelectedPatient = createAction(
  '[Evolution/API] Load Evolutions by Selected Patient',
  props<{ dtStart: Date, dtEnd: Date }>()
);



export const evolutionFail = createAction(
  '[Evolution/API] Evolution Fail',
  props<{ error: string }>()
);

export const addEvolution = createAction(
  '[Evolution/API] Add Evolution',
  props<{ evolution: IMedlogicEvolution }>()
);

export const upsertEvolution = createAction(
  '[Evolution/API] Upsert Evolution',
  props<{ evolution: IMedlogicEvolution }>()
);

export const addEvolutions = createAction(
  '[Evolution/API] Add Evolutions',
  props<{ evolutions: IMedlogicEvolution[] }>()
);




export const upsertEvolutions = createAction(
  '[Evolution/API] Upsert Evolutions',
  props<{ evolutions: IMedlogicEvolution[] }>()
);

export const updateEvolution = createAction(
  '[Evolution/API] Update Evolution',
  props<{ evolution: Update<IMedlogicEvolution> }>()
);

export const updateEvolutions = createAction(
  '[Evolution/API] Update Evolutions',
  props<{ evolutions: Update<IMedlogicEvolution>[] }>()
);

export const deleteEvolution = createAction(
  '[Evolution/API] Delete Evolution',
  props<{ id: string }>()
);

export const deleteEvolutions = createAction(
  '[Evolution/API] Delete Evolutions',
  props<{ ids: string[] }>()
);

export const clearEvolutions = createAction(
  '[Evolution/API] Clear Evolutions'
);

export const sendEvolutionEmail = createAction(
  '[Evolution] Send Evolution Email',
  props<{ evolution: IMedlogicEvolution }>()
);
