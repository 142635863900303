import { Injectable } from '@angular/core';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LocalLibService } from '@medlogic/shared/utils';
import { FrailtyService } from './frailty.service';

@Injectable()
export class FrailtyCustomService extends FrailtyService {

    constructor(
        cadastroSrv: CadastroService,
        glb: GlobalService,
        cnf: ConfigStateService,
        lib: LocalLibService,
        log: LogService) {
        super(cadastroSrv, glb, cnf, lib, log);
    }



}
