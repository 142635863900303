<mat-toolbar color="basic">

  <span class="example-spacer"></span>

  <mat-icon class="example-icon" (click)="onNoClick()">close</mat-icon>

</mat-toolbar>

<div id="container">

  <iframe [src]="url | safe"></iframe>
  
</div>


<!-- <router-outlet name='iframe'></router-outlet> -->