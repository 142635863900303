import { LogService } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-smart-list-badge-calendar',
  templateUrl: './smart-list-badge-calendar.component.html',
  styleUrls: ['./smart-list-badge-calendar.component.css']
})
export class SmartListBadgeCalendarComponent implements OnInit {

  @Input() date: Date;

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
  }

  isWeekDay(date: Date, weekDayNum: number): boolean {
    try {
      return date.
      getDay() === weekDayNum;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isWeekDay', error.message);
    }
    return false;
  }

}
