import { IPractitionerState } from './state-practitioner/+state/practitioner-state';
import { IFhirPatientState } from './state-patient/+state/patient-state';
import { ICarePlanState } from './state-carePlan/+state/carePlan-state';
import { IActivityState } from './state-activity/+state/activity-state';
import { IActivityDetailState } from './state-activityDetail/+state/activityDetail-state';
import { IScheduledState } from './state-scheduled/+state/scheduled-state';
import { IProgressState } from './state-progress/+state/progress-state';

export interface IAppMedlogicFhirState {
    activity: IActivityState;
    activityDetail: IActivityDetailState;
    carePlan: ICarePlanState;
    patient: IFhirPatientState;
    practitioner: IPractitionerState;
    scheduled: IScheduledState;
    progress: IProgressState
}
