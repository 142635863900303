import { IActivity } from './iactivity';
import { ICarePlan } from './icare-plan';

export interface ICarePlanActivity {
    carePlanActivityIdentifier: string;
    activityIdentifier: string;
    activity: IActivity[];
    carePlanIdentifier: string;
    carePlan: ICarePlan[];
    description: string;
    tenantId: number;
    language: string;
}