import { IPractitioner } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IPractitionerState } from './practitioner-state';

import * as PractitionerActions from './practitioner.actions';

export const practitionersFeatureKey = 'practitioners';


export const adapterPractitioner: EntityAdapter<IPractitioner> = createEntityAdapter<IPractitioner>({
  selectId: instance => instance.practitionerIdentifier,
  sortComparer: sortOnOcurrencyAndNames
});

export const initialStatePractitioner: IPractitionerState = adapterPractitioner.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
});

// FIXME: Falta incluir regras de negócio de ordenação para os alertas
function usersSortFn(a: IPractitioner, b: IPractitioner) {
  return a?.practitionerName.localeCompare(b?.practitionerName);
}

function sortOnOcurrencyAndNames(a: IPractitioner, b: IPractitioner) {
  if (a.active === b.active) {
    return a.practitionerName < b.practitionerName ? -1 : 1;
  } else {
    return a.active ? -1 : 1;
  }
}

export const reducer = createReducer(
  initialStatePractitioner,
  on(PractitionerActions.addPractitioner,
    (state, action) => adapterPractitioner.addOne(action.practitioner, state)
  ),
  on(PractitionerActions.setPractitioner,
    (state, action) => ({ ...state, selectedIdentifier: action.selectedId })
  ),
  on(PractitionerActions.upsertPractitioner,
    (state, action) => adapterPractitioner.upsertOne(action.practitioner, state)
  ),
  on(PractitionerActions.addPractitioners,
    (state, action) => adapterPractitioner.addMany(action.practitioners, state)
  ),
  on(PractitionerActions.upsertPractitioners,
    (state, action) => adapterPractitioner.upsertMany(action.practitioners, state)
  ),
  on(PractitionerActions.updatePractitioner,
    (state, action) => adapterPractitioner.updateOne(action.practitioner, state)
  ),
  on(PractitionerActions.updatePractitioners,
    (state, action) => adapterPractitioner.updateMany(action.practitioners, state)
  ),
  on(PractitionerActions.deletePractitioner,
    (state, action) => adapterPractitioner.removeOne(action.id, state)
  ),
  on(PractitionerActions.deletePractitioners,
    (state, action) => adapterPractitioner.removeMany(action.ids, state)
  ),
  on(PractitionerActions.loadPractitionersSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterPractitioner.upsertMany(action.practitioners, state)
    (state, action) => adapterPractitioner.setAll(action.practitioners, state)
  ),
  on(PractitionerActions.practitionerFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(PractitionerActions.clearPractitioners,
    state => initialStatePractitioner
  ),
  on(PractitionerActions.clearSelectedPractitioner,
    state => ({ ...state, selectedIdentifier: null })
  ),
  on(PractitionerActions.loadPractitioners,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterPractitioner.getSelectors();


export const getSelectedId = (state: IPractitioner) => state.practitionerIdentifier;