import { metaReducers } from './+state/storage.metareducer';
import { SharedInterfacesNgModule } from '@medlogic/shared/shared-interfaces';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './+state/login.effects';
import { StoreModule } from '@ngrx/store';
import { loginReducer } from './+state/login.reducer';

@NgModule({
  imports: [
    CommonModule,
    SharedInterfacesNgModule,
    EffectsModule.forFeature([
      LoginEffects,
    ]),
    // NgrxRouterStoreModule,
    StoreModule.forFeature('login', loginReducer, { metaReducers }),
  ],

})
export class StateLoginModule { }
