import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import{ IProductCodeableConcept, UnsubscribeOnDestroyAdapter  } from '@medlogic/shared/shared-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { UiDialogAlertComponent } from '@medlogic/shared/ui/dialog/ui-dialog-alert';
import { catchError, EMPTY, filter, map, mergeMap, Observable, of, pipe, switchMap, tap } from 'rxjs';
import { FhirPatientIconsService, IAppMedlogicFhirState } from '@medlogic/fhir';
import { selectedPatientId } from '@medlogic/medlogic/medlogic-state';
import { select, Store } from '@ngrx/store';


@Component({
  selector: 'ml-ui-care-plan-general-indicator',
  templateUrl: './ui-care-plan-general-indicator.component.html',
  styleUrls: ['./ui-care-plan-general-indicator.component.css']
})
export class UiCarePlanGeneralIndicatorComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnChanges {

  @Input() patientsIcons : IProductCodeableConcept[] | {display: string, description: string}[] | null = [];

  selectedPatientId$: Observable<number> = this.store.pipe(select(selectedPatientId));

  constructor(
    private matDialog: MatDialog,
    private fhirPatientIconsSrv: FhirPatientIconsService,
    private store: Store<IAppMedlogicFhirState>,
    ) {
      super()
    }

  ngOnInit() {
   }

   ngOnChanges(changes: SimpleChanges) {
    if (changes.patientsIcons &&
      changes.patientsIcons.currentValue &&
      changes.patientsIcons.currentValue[0].display === "residente") {
      this.subs.sink = this.selectedPatientId$.pipe(
        mergeMap( medlogicId => this.fhirPatientIconsSrv.getBehaviourStatus(medlogicId)),
      ).subscribe(behaviourIcon => this.patientsIcons[0] = this.buildIconByDisplay(behaviourIcon.display) || { display: 'residente', description: "Residente Ativo" });
    }
  }

  buildIconByDisplay(display: string) {
    if (display === 'residente-normal') return { display: 'residente-normal', description: "Residente Normal" }
    if (display === 'residente-alteracao-leve') return { display: 'residente-alteracao-leve', description: "Residente Alteração Leve" }
    if (display === 'residente-agressivo') return { display: 'residente-agressivo', description: "Residente Agressivo" }
    return null
  }

  alertaIcone(icon: { display: string, description: string }): void{

    if (icon.display === "residente" ||icon.display === "residente-normal" || icon.display === "residente-alteracao-leve" || icon.display === "residente-agressivo" ) {
      const dialogRef = this.matDialog.open(UiDialogAlertComponent, {
        width: '400px',
        maxWidth: '520px',
        height: '300px',
        data: {
          messageHtml: `<h2>${icon.description}</h2>`,
          behaviour: true,
          title: "Comportamento do Residente",
        }
      })
      this.subs.sink = dialogRef.afterClosed().pipe(
        filter(display => display === "residente-normal" || display === "residente-alteracao-leve" || display === "residente-agressivo" ),
        switchMap(iconDisplay => this.selectedPatientId$.pipe(
          switchMap(medlogicId => this.fhirPatientIconsSrv.postBehaviourStatus(medlogicId, iconDisplay)),
        ))
        ).subscribe( behaviourIcon => {
         this.patientsIcons[0] = this.buildIconByDisplay(behaviourIcon.display as any as string)
         });

    } else {
      this.matDialog.open(UiDialogAlertComponent, {
            width: '400px',
            maxWidth: '520px',
            height: '300px',
            data: {
              messageHtml: `<h2>${icon.description}</h2>`,
              title: "Descrição do ícone"
            }
     })
    }
  }

}
