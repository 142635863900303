import { IAudio, EnColor } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { IFrailty } from '@medlogic/medlogic/medlogic-shared-interfaces';

// TODO: Each model need to have its specific Action, Reducer, Effects.
export enum FrailtyActionTypes {
  LoadFrailtys = '[Frailty Component] Load Frailty ',
  FetchSuccessFrailty = '[Frailty API] Fetch Success Frailty ', // Set the Frailty  to the Store, after Effects
  FetchErrorFrailty = '[Frailty API] Fetch Error Frailty ',
  SetFrailty = '[Page Frailty Component] Set Frailty ',
  SaveFrailty = '[Page Frailty Component] Save Frailty',
  SaveSuccessFrailty = '[Page Frailty Component] Save Success Frailty',
  SaveFailFrailty = '[Page Frailty Component] Save Fail Frailty',
  UpdateFrailty = '[Page Frailty Component] Update Frailty',
  SaveAllFrailty = '[Page Frailty Component] Save All Frailty',
  ResetFrailty = '[Page Frailty Component] Reset Frailty',
  // Category
  SetCategoriaFrailty = '[Page Frailty Component] Set Categoria Frailty',
  // Other states
  LoadFrailtysAndResultados = '[Page Frailty Component] Load Frailtys and Resultados',
  CleanFrailty = '[States Management Service] Clean Frailty',
  // Audio
  PushAudio = '[Page Frailty Component] Push Audio',
  PopAudio = '[Page Frailty Component] Pop Audio',
  Play = '[Page Frailty Component] Play Audio',
  Stop = '[Page Frailty Component] Stop Audio',
  Pause = '[Page Frailty Component] Pause Audio'
}

export const loadFrailtys = createAction(FrailtyActionTypes.LoadFrailtys);
export const loadFrailtysAndResultados = createAction(FrailtyActionTypes.LoadFrailtysAndResultados);
export const setFrailty = createAction(FrailtyActionTypes.SetFrailty, props<{ frailty: IFrailty }>());
export const fetchSuccessFrailty = createAction(FrailtyActionTypes.FetchSuccessFrailty, props<{ frailtys: IFrailty[] }>());
export const fetchErrorFrailty = createAction(FrailtyActionTypes.FetchErrorFrailty, props<{ frailtys: IFrailty[] }>());
export const saveFrailty = createAction(FrailtyActionTypes.SaveFrailty, props<{ frailty: IFrailty }>());
export const saveSuccessFrailty = createAction(FrailtyActionTypes.SaveSuccessFrailty, props<{ frailty: IFrailty }>());
export const saveFailFrailty = createAction(FrailtyActionTypes.SaveFailFrailty, props<{ frailty: IFrailty }>());
export const updateFrailty = createAction(FrailtyActionTypes.UpdateFrailty, props<{ frailty: IFrailty }>());
export const saveAllFrailty = createAction(FrailtyActionTypes.SaveAllFrailty, props<{ frailty: IFrailty }>());
export const resetFrailty = createAction(FrailtyActionTypes.ResetFrailty);
export const cleanFrailty = createAction(FrailtyActionTypes.CleanFrailty);
// Audio
export const pushAudio = createAction(FrailtyActionTypes.PushAudio, props<{ audio: IAudio }>());
export const popAudio = createAction(FrailtyActionTypes.PopAudio, props<{ enColor: EnColor }>());
export const playAudio = createAction(FrailtyActionTypes.Play, props<{ enColor: EnColor }>());
export const stopAudio = createAction(FrailtyActionTypes.Stop);
export const pauseAudio = createAction(FrailtyActionTypes.Pause);
