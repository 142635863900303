import { ConfigStateService } from '@medlogic/shared/state-config';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { error } from './error';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

@Injectable()
export class TokenService {

  // url = `${this.cnf.baseUrlDocGen}/api/HttpToken`; // 'http://localhost:7071/api/HttpToken';
  // securityKey = '?code=W9jwPOAqMc37u9BaUyVt7ii2HYVP8bUm3mTHkMAi93iMSQUOmPDPhA==';
  // get apiUrl(): string {
  //   return `${this.url}${this.securityKey}`;
  // }
  private HTTP_TOKEN_API_BASE_URL = 'HttpToken?code=';

  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Data-Type': 'application/json'
  });

  // https://brasilbrazil-doc-generator.azurewebsites.net/api/HttpToken?code=W9jwPOAqMc37u9BaUyVt7ii2HYVP8bUm3mTHkMAi93iMSQUOmPDPhA==
  constructor(
    private http: HttpClient,
    private glb: GlobalService,
    private cnf: ConfigStateService,
    private cnfJson: ConfigJsonService,
    private log: LogService) { }

  // tslint:disable: variable-name
  generateAndSendToken(
    from_email: string,
    email: string,
    subject: string,
    html_content: string,
    validForSec: number = 600): Observable<any> {
    try {
      const apiUrl = this.glb.urlJoin([this.cnfJson.baseUrlDocGen, `${this.HTTP_TOKEN_API_BASE_URL}${this.cnfJson.apiDocGenCode}`]);
      const data = new FormData();
      data.append('validForSec', validForSec.toString());
      data.append('from_email', from_email);
      data.append('email', email);
      data.append('subject', subject);
      data.append('html_content', html_content);
      return this.http.post(
        apiUrl,
        data
      ).pipe(
        map((res: any) => res && res.wasSent),
        error()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'generateToken', error.message);
    }
    return of(null);
  }

  validateToken(token: string): Observable<any> {
    try {
      const apiUrl = this.glb.urlJoin([this.cnfJson.baseUrlDocGen, `${this.HTTP_TOKEN_API_BASE_URL}${this.cnfJson.apiDocGenCode}`]);
      const headers = this.headers.append(
        'Authorization',
        `Bearer ${token}`
      );
      return this.http.post(
        apiUrl,
        {},
        { responseType: 'json', headers }
      ).pipe(
        map((res: any) => res && res.token && (res.token === 'OK'))
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'validateToken', error.message);
    }
    return of(null);
  }


}
