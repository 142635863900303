export interface IHandsontableColumn {
    data: string;
    type: string;
    source?: any;
    language?: string;
    allowEmpty?: boolean;
    allowHtml?: boolean;
    manualColumnMove?: boolean;
    manualColumnResize?: boolean;
    renderer?: Function;
    strict?: boolean;
    delimiters?: any;
    validator?: Function;
    allowInvalid?: boolean;
    numericFormat?: { pattern?: string, culture?: string, delimiters?: any };
    dateFormat?: string;
    correctFormat?: boolean;
    defaultDate?: string;
    datePickerConfig?: any;
    readOnly?: boolean;
}
