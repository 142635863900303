import { IVitalSignState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IVitalSign } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as VitalSignActions from './vital-sign.actions';

export const vitalSignsFeatureKey = 'vitalSign';

export const adapterVitalSign: EntityAdapter<IVitalSign> = createEntityAdapter<IVitalSign>({
  selectId: instance => instance.codigo,
});

export const initialStateVitalSign: IVitalSignState = adapterVitalSign.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
  changeItem: null,
  dtStart: null,
  dtEnd: null
});

export const reducer = createReducer(
  initialStateVitalSign,
  on(VitalSignActions.addVitalSign,
    (state, action) => adapterVitalSign.addOne(action.vitalSign, state)
  ),
  on(VitalSignActions.loadVitalSigns,
    (state, action) => ({ ...state })
  ),
  on(VitalSignActions.setVitalSign,
    (state, action) => ({ ...state, selectedId: action?.selectedId })
  ),
  on(VitalSignActions.loadVitalSignsByPeriod,
    (state, action) => ({ ...state, dtStart: action?.dtStart, dtEnd: action?.dtEnd })
  ),
  on(VitalSignActions.loadVitalSignsBySelectedPatient,
    (state, action) => ({ ...state, dtStart: action?.dtStart, dtEnd: action?.dtEnd })
  ),
  on(VitalSignActions.upsertVitalSign,
    (state, action) => adapterVitalSign.upsertOne(action?.vitalSign, state)
  ),
  on(VitalSignActions.addVitalSigns,
    (state, action) => adapterVitalSign.addMany(action?.vitalSigns, state)
  ),
  on(VitalSignActions.upsertVitalSigns,
    (state, action) => adapterVitalSign.upsertMany(action?.vitalSigns, state)
  ),
  on(VitalSignActions.updateVitalSign,
    (state, action) => adapterVitalSign.updateOne(action?.vitalSign, state)
  ),
  on(VitalSignActions.updateVitalSigns,
    (state, action) => adapterVitalSign.updateMany(action?.vitalSigns, state)
  ),
  on(VitalSignActions.deleteVitalSign,
    (state, action) => adapterVitalSign.removeOne(action?.id, state)
  ),
  on(VitalSignActions.deleteVitalSigns,
    (state, action) => adapterVitalSign.removeMany(action?.ids, state)
  ),
  on(VitalSignActions.loadVitalSignsSuccess,
    (state, action) => adapterVitalSign.setAll(action?.vitalSigns, { ...state })
  ),
  on(VitalSignActions.vitalSignChange,
    (state, action) => ({
      ...state,
      changeItem: { updateVitalSign: action?.updateVitalSign, medicationId: action?.medicationId, dailyQuantity: action?.dailyQuantity, message: action?.message },
      error: null
    })
  ),
  on(VitalSignActions.vitalSignChangeSuccess,
    (state, action) => ({
      ...state,
      changeItem: null,
      error: null
    })
  ),
  on(VitalSignActions.vitalSignFail,
    (state, action) => ({
      ...state,
      changeItem: null,
      error: action?.error
    })
  ),
  on(VitalSignActions.clearVitalSigns,
    state => initialStateVitalSign
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterVitalSign.getSelectors();
