import { EntityState } from '@ngrx/entity';
import { EnLoginState } from './../enum/en-login-state.enum';
import { ILogin } from './ilogin';

export interface ILoginState extends EntityState<ILogin | { message: string }> {
  userName: string | undefined;
  password: string | undefined;
  selectedLogin: ILogin | { message: string } | undefined;
  enLoginState: EnLoginState;
  message?: string;
  msg?: string; // analisar se pode ser removido e unificar em message
  isFirstLogin: boolean;
}
