import { IssuerIdentification } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IPrescriptionMedication } from '@medlogic/shared/shared-interfaces';


export interface IPrescriptionState {
  selectedPrescription: IPrescriptionMedication;
  allPrescriptions: IPrescriptionMedication[];
  Id: string;
  prescriptionId: number;
  identification: IPrescriptionMedication;
  showMessage: boolean;
  issuer?: IssuerIdentification;
}
