<div class="activities-container" *ngIf="!(doneLoading$ | async); else loading">

  <span>{{ activityModeTitle }}</span>

  <ng-container *ngIf="activitiesByShift?.length; else empty">

  <div *ngFor="let shift of activitiesByShift" class="shift">

      <ng-container *ngIf="(shift.activities)?.length">

          <span class="subtitle">Turno {{ shift?.shiftName }}</span>

          <ml-ui-care-plan-activity *ngFor="let activity of shift.activities" [activity]="activity"
              (activityClick)="onActivityClick($event)">
          </ml-ui-care-plan-activity>

      </ng-container>

  </div>

</ng-container>

</div>


<ng-template #empty>
  <h2>Nenhuma atividade encontrada.</h2>
</ng-template>

<ng-template #loading>
  <!-- <ui-loading-spinner></ui-loading-spinner> -->
  <span>Carregando...</span>
</ng-template>
