import { refCount, publishReplay, filter, map } from 'rxjs/operators';
import { IMedicationCheckin } from '@medlogic/shared/shared-interfaces';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { IMedication } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

import * as moment from 'moment';

@Injectable()
export class MedicationCheckinService {
  private lstVariaveis = 'V_31969,V_30264,V_30273,V_30274,V_30275,V_30276,V_30330,V_30331,V_30351,V_30352,V_30353,V_30401,V_31927,V_29828,V_30263,V_34166';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IMedicationCheckin[] = new Array<IMedicationCheckin>();

  cadastroNo = 4693;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService
  ) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IMedicationCheckin> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(): Observable<any> {
    try {
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(this.cadastroNo), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IMedicationCheckin> {
    if (
      startDate.getTime() !== this.currentDtInicial.getTime() ||
      endDate.getTime() !== this.currentDtFinal.getTime() ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis, startDate, endDate)
        .pipe(
          map(c => this.toAttribute(c)),
          filter((f) => this.glb.isBetweenIgnoreTime(f.dataCheckin, startDate, endDate)), // TODO: Esse filtro deveria ser desnecessário. getCadastro deveria receber as datas como filtro. Mas não está.
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  /* Mapeia para o objeto principal. */
  protected toAttribute(c: any): IMedicationCheckin {
    try {
      return {
        codigo: c.V_30351,
        titulo: c.V_30352,
        habilitado: this.glb.getBoolean(c.V_30353),
        status: c.V_30274.toUpperCase(), // !== 'TOMOU' || this.glb.IsNullOrEmpty(c.V_30274) ? false : true,
        dataCheckin: this.glb.ddMMYYYYThhmmssToDate(c.V_30330),
        horaCheckin: c.V_34166,
        observacoes: c.V_30275,
        statusmedicacao: c.V_30276,
        Id_Paciente: c.V_29828,
        Id_Medicamento: c.V_30263,
        IdMedicamento_IdPaciente: c.V_30401,
        diaPrescricao: c.V_30331,
        profissional: c.V_31927,
        cascataCheckIDClienteIDMedicamentoDataAtual: c.V_30264,
        // Segundo levantamento do Rony em 29/jan esses campos não existem mais
        horaPrescricao: c.V_30273,
        identificacao: c.V_30264,
        codigoMedicamentoPaciente: c.V_30401,
        codigoPaciente: c.V_31969,
        // ***
      } as IMedicationCheckin;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}` */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IMedicationCheckin> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true, startDate, endDate)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  /*Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(
    ano: number,
    medicationCheckin: IMedicationCheckin,
    patient: IPatient,
    medication: IMedication,
    uno: number,
    id?: number,
    executeNow: boolean = true
  ): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(medicationCheckin).filter(
        (f) => !this.glb.IsNullOrEmpty(f.ValorDado)
      );
      const key = id || this.generateKey(patient.codigo, medication, medicationCheckin.dataCheckin);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, key, executeNow);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(medicationCheckin: IMedicationCheckin): IForm[] {
    try {
      return [
        { VariavelNo: 30264, ValorDado: medicationCheckin.cascataCheckIDClienteIDMedicamentoDataAtual || '' },
        { VariavelNo: 34166, ValorDado: medicationCheckin.horaCheckin || '' },
        { VariavelNo: 30274, ValorDado: medicationCheckin.status },
        { VariavelNo: 30275, ValorDado: medicationCheckin.observacoes || '' },
        { VariavelNo: 30276, ValorDado: medicationCheckin.statusmedicacao || '' },
        { VariavelNo: 30330, ValorDado: this.glb.dateToYYYYMMddThhmmss(medicationCheckin.dataCheckin) },
        { VariavelNo: 30331, ValorDado: medicationCheckin.diaPrescricao || '' },
        { VariavelNo: 30351, ValorDado: medicationCheckin.codigo || '' },
        { VariavelNo: 30352, ValorDado: medicationCheckin.titulo || '' },
        { VariavelNo: 30353, ValorDado: medicationCheckin.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 31927, ValorDado: medicationCheckin.profissional || '' },
        { VariavelNo: 29828, ValorDado: medicationCheckin.Id_Paciente || -1 },
        { VariavelNo: 30263, ValorDado: medicationCheckin.Id_Medicamento || -1 },
        { VariavelNo: 30401, ValorDado: medicationCheckin.IdMedicamento_IdPaciente || -1 },
        // Segundo levantamento do Rony em 29/jan esses campos não existem mais
        { VariavelNo: 30273, ValorDado: medicationCheckin.horaPrescricao || '' },
        { VariavelNo: 30264, ValorDado: medicationCheckin.identificacao || '' },
        { VariavelNo: 30401, ValorDado: medicationCheckin.codigoMedicamentoPaciente || '' },
        { VariavelNo: 31969, ValorDado: medicationCheckin.codigoPaciente || -1 },
        // ***
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Gera a chave que identifica o paciente e medicação. */
  generateKey(patientCodigo: string, medication: IMedication, dateCheckin: Date): string {
    try {
      const dateString = moment(dateCheckin).format('DD/MM/YYYY');
      const hora = medication.prescribedTime;
      return `${patientCodigo}_${medication.medicationId}_${dateString}_${hora}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'generateKey', error.message);
    }
  }

  /* Gera o título da medicação.
  * É diferenciado se tomou ou não.
  */
  generateMedicationTitle(patient: IPatient, medication: IMedication): string {
    try {
      const dateString = moment(medication.medicationDate).format('DD/MM/YYYY');
      const hora = moment(medication.medicationDate).format('HH:mm'); // medication.prescribedTime;
      return medication.took ?
        `${patient.nomeHospede} tomou '${medication.medicationName} ${medication.dosage}' em ${dateString} às ${hora} hs` :
        `${patient.nomeHospede} rejeitou a '${medication.medicationName} ${medication.dosage}' em ${dateString} às ${hora} hs`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'generateMedicationTitle', error.message);
    }
  }


}
