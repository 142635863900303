import { IUsuario } from './iusuario';

export interface ILogin {
    token?: string;
    usuarioLogadoNo: number;
    usuarioLogadoNome?: string;
    tenantId?: number;
    message?: string;
    usuario?: IUsuario;
    isAdm?: boolean;
    customerId?: number;
    isFirstLogin?: boolean;
    selectedLogin?: ILogin;
    roles?: string[];
    imgUrl?: string;
  }
  