import { IProductCodeableConcept } from './iproduct-codeable-concept';
import { IFhirPatient } from './ifhir-patient';


export interface IProductCodeablePatientIcons {
  patientIdentifier: string;
  productIconIdentifier: string;
  patient: IFhirPatient;
  productCodeableConcept: IProductCodeableConcept[];
  display: string;
}
