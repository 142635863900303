import { Injectable } from '@angular/core';
import { GlobalService, IListItem, LogService, EnTypedValue } from '@medlogic/shared/shared-interfaces';

import { Screenfull } from 'screenfull';
import * as screenfull from 'screenfull';

@Injectable({ providedIn: 'root' })
export class LocalLibService {
  MAX_LINHA = 450;
  MAX_COLUNA = 950;
  LAST_TOP_INVISIBLE_POSITION = 10;
  DEFAULT_LEFT_INVISIBLE_POSITION = 1100;
  COMPONENT_HEIGHT = 40;

  CTRDATE = 'EDITORATIVIDADE.CTRDATE';
  CTRLABEL = 'EDITORATIVIDADE.CTRLABEL';
  CTRTEXTBOXLABELED = 'EDITORATIVIDADE.CTRTEXTBOXLABELED';
  CTRMULTILINETEXTBOXLABELED = 'EDITORATIVIDADE.CTRMULTILINETEXTBOXLABELED';
  CTRMULTILINETEXTBOXHTMLLABELED = 'EDITORATIVIDADE.CTRMULTILINETEXTBOXHTMLLABELED';
  CTRCOMBOBOX = 'EDITORATIVIDADE.CTRCOMBOBOX';
  CTRIMAGELIST = 'EDITORATIVIDADE.CTRIMAGELIST';
  CTRRADIOBUTTON = 'EDITORATIVIDADE.CTRRADIOBUTTON';
  CTRRATINGSTAR = 'EDITORATIVIDADE.CTRRATINGSTAR';
  CTRRATINGPROGRESS = 'EDITORATIVIDADE.CTRRATINGPROGRESS';
  CTRVIDEO = 'EDITORATIVIDADE.CTRVIDEO';
  CTRMAPA = 'EDITORATIVIDADE.CTRMAPA';
  CTRCHECKBOXLIST = 'EDITORATIVIDADE.CTRCHECKBOXLIST';
  CTRGRID = 'EDITORATIVIDADE.CTRGRID';

  constructor(
    private global: GlobalService,
    private log: LogService,
    ) { }

  /** Verifica se o valor corresponde a uma fórmula */
  isFormula(valor: any): boolean {
    try {
      if (this.global.IsNullOrEmpty(valor)) {
        return false;
      }
      valor = valor.toString();
      if (this.global.ChecaAspas(valor)) {
        valor = this.global.RemoverAspas(valor);
      }
      return valor.trim().substr(0, 1) === '=';
    } catch (error) {
      console.log(this.constructor.name, 'isFormula', error);
    }
    return false;
  }

  /* Retorna o xml de itens para compor o ValorTexto de um Grid.
   * typeRegister == 1: Id / 2: Titulo / 3: enabled
   */
  getGridItems(items: any[], variavelRetornoNo: number, typeRegister: number): string {
    try {
      if (!items || items.length <= 0) {
        return null;
      }
      let strItems = '<Items>';
      if (items && items.length > 0) {
        items.forEach((item) => {
          strItems += '<Item>';
          for (const clmName in item) {
            if (clmName !== '$') {
              const tagName = clmName === 'OcorrenciaNo' ? 'index' : clmName;
              let value;
              if (typeRegister === 1) {
                // Força a gravação do número da Ocorrência no campo de código.
                value = !item.index ? '' : item.index.name || item.index;
                strItems += `<${tagName}>${value}</${tagName}>`;
              } else {
                // Não é a propriedade de id/index
                // Porque se for combobox, o valor é um objeto
                value = !item[clmName] ? '' : item[clmName].name || item[clmName];
                const typed = this.global.getTypedValue(value);
                if (typed.type === EnTypedValue.Date) {
                  value = this.global.RetornarAllXsdDateTime(typed.value);
                }
                strItems += `<${tagName}>${value}</${tagName}>`;
              }
              if (tagName === `V_${variavelRetornoNo.toString()}` && !item.label) {
                // Acrescenta a tag label, caso não exista
                strItems += `<label>${value}</label>`;
              }
            }
          }
          strItems += '</Item>';
        });
      }
      strItems += '</Items>';
      return this.global.ConvertToCData(strItems);
    } catch (error) {
      console.log(this.constructor.name, 'getGridItems', error.message);
    }
    return '';
  }

  /* Acrescenta um elemento no array somente se ele não existir,
  * baseado na comparação do fieldNameToCompare( que deve existir tanto
  * nos elementos do array quanto no el) */
  addIfNotExist(array: any[], el: any, fieldNameToCompare: string): boolean {
    try {
      if (array.findIndex((f) => this.global.isEqual(f[fieldNameToCompare], el[fieldNameToCompare])) < 0) {
        array.push(el);
        return true;
      }
      return false;
    } catch (error) {
      console.log(this.constructor.name, 'addIfNotExist', error.message);
    }
  }

  /** retorna um id para o controle baseado na variavelNo */
  getId(id: number): string {
    try {
      if (!this.global.isNumeric(id)) {
        return '';
      }
      return `V_${id}`;
    } catch (error) {
      console.log(this.constructor.name, 'getId', error.message);
    }
  }

  /** Faz o processo inverso de getId: a partir do id retorna a VariavelNo */
  getVariavelNoFromId(id: string): number {
    try {
      if (!id) {
        return null;
      }
      return parseInt(id.toString().replace('V_', ''), 10);
    } catch (error) {
      console.log(this.constructor.name, 'getVariavelNoFromId', error.message);
    }
    return null;
  }

  /** A lista de itens do cadastro adicional são empacotados nesse formato pelo XML. */
  getItemDoCadastro(m: any): any[] { // ICadastroListaDAL
    try {
      // m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro
      if (m && m.lstCadastroAdicional !== undefined) {
        if (m.lstCadastroAdicional.diffgram !== undefined) {
          if (m.lstCadastroAdicional.diffgram.DocumentElement !== undefined) {
            if (m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro !== undefined) {
              return m.lstCadastroAdicional.diffgram.DocumentElement.ItemDoCadastro;
            }
          }
        }
      }
      return null;
    } catch (error) {
      console.log(this.constructor.name, 'getItemDoCadastro', error.message);
    }
  }

  /* Se o items tiver apenas um item e for um objeto ao invés de um array, coloca o elemento num array.
   * Algumas estruturas, como interações de for, datatable, etc, no html dependem de trabalhar com arrays,
   * mas o retorno do serviço às vezes traz objetos únicos como arrays.
   * Agora, se o array for null ou só contiver elemementos null, retornará null
   */
  toArray(items: any): any[] {
    try {
      if (!items) {
        return null;
      }
      // Se o retorno tiver apenas um elemento, é necessário convertê-lo para um array
      if (items instanceof Array) {
        if (!items.find((f) => f !== null)) {
          return null;
        }
        return items;
      } else {
        return [items];
      }
    } catch (error) {
      console.log(this.constructor.name, 'toArray', error.message);
    }
    return null;
  }

  /** Cria um formControl a partir dos valores do item */
  getDefaultFormControlsFromGrid(selectedItem: any): any {
    try {
      const formControl = {};
      for (const clm in selectedItem) {
        if (this.global.Left(clm, 2) === 'V_') {
          formControl[clm] = selectedItem[clm];
        }
      }
      return formControl;
    } catch (error) {
      console.log(this.constructor.name, 'getDefaultFormControls', error.message);
    }
  }

  /* Os controles fora da região de display serão automaticamente reposicionados para facilitar a visualização de debug.
   * Toda vez que resgatar uma posição, irá incrementar a última posição.
  */
  getNextOutsideDisplayAreaPosition(): { top: number; left: number } {
    try {
      const lastTopPos = this.LAST_TOP_INVISIBLE_POSITION;
      this.LAST_TOP_INVISIBLE_POSITION += this.COMPONENT_HEIGHT;
      return { top: lastTopPos, left: this.DEFAULT_LEFT_INVISIBLE_POSITION };
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getNextOutsideDisplayAreaPosition', error.message);
    }
  }

  /** Se for uma data, transforma a string no tipo data  */
  parseDate(value: any): any {
    try {
      return this.global.getTypedValue(value).string;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'parseDate', error.message);
    }
  }

  /** Retorna um valor booleano a partir da comparação de um valor do tipo 'SIM'. */
  getBoolean(value: string, yesValue: string = 'SIM'): boolean {
    try {
      return this.global.getBoolean(value, yesValue);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getBoolean', error.message);
    }
  }

  protected primeiraMaiuscula(name: string): string {
    try {
      name = this.global.removeConjuncao(name);
      return `${name.split(' ').map(m => m.substr(0, 1).toUpperCase()).join('.')}.`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getInitials', error.message);
    }
    return name;
  }

  getCustomerIdVnoFromModules(): string {
    try {
      const vno = this.getCustomerIdsFromModules().id;
      return `V_${vno}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCustomerIdFromModules', error.message);
    }
    return null;
  }

  getCustomerIdentification1VnoFromModules(): string {
    try {
      const vno = this.getCustomerIdsFromModules().identification1;
      return `V_${vno}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCustomerIdentification1VnoFromModules', error.message);
    }
    return null;
  }

  getCustomerIdentification2VnoFromModules(): string {
    try {
      const vno = this.getCustomerIdsFromModules().identification2;
      return `V_${vno}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCustomerIdentification2VnoFromModules', error.message);
    }
    return null;
  }

  getCustomerIdsFromModules(): IListItem<any> {
    try {
      // return this.cnf.modules.find(f => f.isCustomerModule).identification;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCustomerIdsFromModules', error.message);
    }
    return null;
  }

  /* Ativa/desativa fullscreen se estiver disponível.
  * Por motivo de segurança do navegador, deve ser chamado a partir de um evento do usuário.
  */
  toogleFullscreen(forceEnable: boolean = true): void {
    try {
      const sf = screenfull as Screenfull;
      if (sf.isEnabled && ((forceEnable && !sf.isFullscreen) || !forceEnable)) {
        sf.toggle();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toogleFullscreen', error.message);
    }
  }


}
