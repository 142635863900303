import { EnMaterialIcon } from '../../enum/en-material-icon.enum';

export interface IContact {
    icon: EnMaterialIcon;
    title: string;
    name: string;
    email: string;
    phone: string;
    whatsapp: string;
    website: string;
}
