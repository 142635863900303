
import { refCount, publishReplay, map, filter, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IEvolucaoFono } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class EvolucaoFonoService {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_387,V_391,V_1608,V_3328,V_3329,V_28051,V_101175,V_101305,V_101570,V_101575,V_101578,V_101579,V_101580,V_101581,V_104082,V_104088,V_108035,V_108041,V_108391,V_108392,V_108396,V_108397,V_108398,V_108399,V_108400,V_108401,V_108402,V_108403,V_108404,V_108405,V_108406,V_108503,V_108509,V_108510,V_108512,V_108513,V_108514,V_108515,V_108516,V_108517,V_108518,V_108519,V_108520,V_108521,V_108522,V_108523,V_108524,V_108525,V_108526,V_108527,V_108528,V_108529,V_108530,V_108531,V_108532,V_108533,V_108534,V_108535,V_108536,V_109066,V_109169,V_109172,V_108391,V_108396,V_108398,V_108516,V_101305,V_108509,V_108406,V_109169,V_109172,V_108517,V_108400,V_109315,V_109316,V_108392';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: Array<IEvolucaoFono> = new Array<IEvolucaoFono>();

  cadastroNo = 24665;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IEvolucaoFono> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        filter(f => {
          return this.glb.isBetweenIgnoreTime(f.dtEvolucao, startDate, endDate);
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IEvolucaoFono> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IEvolucaoFono {
    try {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
        nomeIdoso: c.V_387,
        nascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_391),
        nomeMae: c.V_1608,
        identificacao1: c.V_3328,
        identificacao2: c.V_3329,
        prontuario: c.V_28051,
        dataAniversario: c.V_101175,
        dtEvolucao: this.glb.ddMMYYYYThhmmssToDate(c.V_101305),
        admissao: c.V_101570,
        idade: c.V_101575,
        endereco: c.V_101578,
        telefone: c.V_101579,
        celular: c.V_101580,
        email: c.V_101581,
        pai: c.V_104082,
        quarto: c.V_104088,
        local: c.V_108035,
        tipo: c.V_108041,
        usoespessanteTablet: this.glb.getBoolean(c.V_108391),
        tipoConsistenciaTablet: c.V_108392,
        utensiliosingestaoliquidos: c.V_108396,
        seOutrosDescreva: c.V_108397,
        utensiliosalimentacao: c.V_108398,
        seOutrosDescreva1: c.V_108399,
        suspenderDietaTablet: this.glb.getBoolean(c.V_108400),
        motivo: c.V_108401,
        dataInicio: this.glb.ddMMYYYYThhmmssToDate(c.V_108402),
        horaInicio: c.V_108403,
        dataFim: this.glb.ddMMYYYYThhmmssToDate(c.V_108404),
        horaFim: c.V_108405,
        observacoes: c.V_108406,
        sigla: c.V_108503,
        ofertaVO: c.V_108509,
        seOutrosDescreva2: c.V_108510,
        consistencia: c.V_108512,
        tipo2: c.V_108513,
        localRefeicao: c.V_108514,
        descreva: c.V_108515,
        escalaFOIS: c.V_108516,
        proporcaoEspessanteMedida: c.V_108517,
        oRIENTACAOFONOAUDIOLOGAESPESSANTE: c.V_108518,
        usoBandagem: this.glb.getBoolean(c.V_108519),
        local3: c.V_108520,
        trocaBandagem: this.glb.ddMMYYYYThhmmssToDate(c.V_108521),
        pilha: c.V_108522,
        tipo3: c.V_108523,
        modelo: c.V_108524,
        observacoes2: c.V_108525,
        proteseAuditiva: this.glb.getBoolean(c.V_108526),
        proteseDentaria: this.glb.getBoolean(c.V_108527),
        observacoes3: c.V_108528,
        nome: c.V_108529,
        cRF: c.V_108530,
        comunicacaoForma: c.V_108531,
        descreva2: c.V_108532,
        dataOcorrencia: c.V_108533,
        codigo: c.V_108534,
        titulo: c.V_108535,
        habilitado: this.glb.getBoolean(c.V_108536),
        conduta: c.V_109066,
        houveMudancadietaTablet: this.glb.getBoolean(c.V_109169),
        refeicaoComAdaptacaoTablet: this.glb.getBoolean(c.V_109172),
        dataEvolucao: this.glb.ddMMYYYYThhmmssToDate(c.V_101305),
        usoEspessante: this.glb.getBoolean(c.V_108391),
        utensiliosLiquido: c.V_108396,
        utensiliosAlimentacao: c.V_108398,
        fois: c.V_108516,
        ofertaOral: c.V_108509,
        campoAberto: c.V_108406,
        mudancaDieta: c.V_109169,
        refeicaoAdaptada: this.glb.getBoolean(c.V_109172),
        proporcaoEspessante: c.V_108517,
        suspenderDieta: c.V_108400,
        comunicacao: c.V_109315,
        campoAberto3: c.V_109316,
        consistenciaEspessante: c.V_108392,
        ingestaoLiquidos: c.V_108396,
        ingestaoDescricao: c.V_108397,
      } as IEvolucaoFono;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(movimentacao: IEvolucaoFono, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(evolucaoFono: IEvolucaoFono): Array<IForm> {
    try {
        return [
          { VariavelNo: 387, ValorDado: evolucaoFono.nomeIdoso || '' },
          { VariavelNo: 391, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFono.nascimento) },
          { VariavelNo: 1608, ValorDado: evolucaoFono.nomeMae || '' },
          { VariavelNo: 3328, ValorDado: evolucaoFono.identificacao1 || '' },
          { VariavelNo: 3329, ValorDado: evolucaoFono.identificacao2 || '' },
          { VariavelNo: 28051, ValorDado: evolucaoFono.prontuario || '' },
          { VariavelNo: 101175, ValorDado: evolucaoFono.dataAniversario || '' },
          { VariavelNo: 101305, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFono.dtEvolucao) },
          { VariavelNo: 101570, ValorDado: evolucaoFono.admissao || '' },
          { VariavelNo: 101575, ValorDado: evolucaoFono.idade || '' },
          { VariavelNo: 101578, ValorDado: evolucaoFono.endereco || '' },
          { VariavelNo: 101579, ValorDado: evolucaoFono.telefone || '' },
          { VariavelNo: 101580, ValorDado: evolucaoFono.celular || '' },
          { VariavelNo: 101581, ValorDado: evolucaoFono.email || '' },
          { VariavelNo: 104082, ValorDado: evolucaoFono.pai || '' },
          { VariavelNo: 104088, ValorDado: evolucaoFono.quarto || '' },
          { VariavelNo: 108035, ValorDado: evolucaoFono.local || '' },
          { VariavelNo: 108041, ValorDado: evolucaoFono.tipo || '' },
          { VariavelNo: 108391, ValorDado: evolucaoFono.usoespessanteTablet ? 'SIM' : 'NÃO' },
          { VariavelNo: 108392, ValorDado: evolucaoFono.tipoConsistenciaTablet || '' },
          { VariavelNo: 108396, ValorDado: evolucaoFono.utensiliosingestaoliquidos || '' },
          { VariavelNo: 108397, ValorDado: evolucaoFono.seOutrosDescreva || '' },
          { VariavelNo: 108398, ValorDado: evolucaoFono.utensiliosalimentacao || '' },
          { VariavelNo: 108399, ValorDado: evolucaoFono.seOutrosDescreva1 || '' },
          { VariavelNo: 108400, ValorDado: evolucaoFono.suspenderDietaTablet ? 'SIM' : 'NÃO' },
          { VariavelNo: 108401, ValorDado: evolucaoFono.motivo || '' },
          { VariavelNo: 108402, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFono.dataInicio) },
          { VariavelNo: 108403, ValorDado: evolucaoFono.horaInicio || '' },
          { VariavelNo: 108404, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFono.dataFim) },
          { VariavelNo: 108405, ValorDado: evolucaoFono.horaFim || '' },
          { VariavelNo: 108406, ValorDado: evolucaoFono.observacoes || '' },
          { VariavelNo: 108503, ValorDado: evolucaoFono.sigla || '' },
          { VariavelNo: 108509, ValorDado: evolucaoFono.ofertaVO || '' },
          { VariavelNo: 108510, ValorDado: evolucaoFono.seOutrosDescreva || '' },
          { VariavelNo: 108512, ValorDado: evolucaoFono.consistencia || '' },
          { VariavelNo: 108513, ValorDado: evolucaoFono.tipo || '' },
          { VariavelNo: 108514, ValorDado: evolucaoFono.localRefeicao || '' },
          { VariavelNo: 108515, ValorDado: evolucaoFono.descreva || '' },
          { VariavelNo: 108516, ValorDado: evolucaoFono.escalaFOIS || '' },
          { VariavelNo: 108517, ValorDado: evolucaoFono.proporcaoEspessanteMedida || '' },
          { VariavelNo: 108518, ValorDado: evolucaoFono.oRIENTACAOFONOAUDIOLOGAESPESSANTE || '' },
          { VariavelNo: 108519, ValorDado: evolucaoFono.usoBandagem ? 'SIM' : 'NÃO' },
          { VariavelNo: 108520, ValorDado: evolucaoFono.local || '' },
          { VariavelNo: 108521, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoFono.trocaBandagem) },
          { VariavelNo: 108522, ValorDado: evolucaoFono.pilha || '' },
          { VariavelNo: 108523, ValorDado: evolucaoFono.tipo || '' },
          { VariavelNo: 108524, ValorDado: evolucaoFono.modelo || '' },
          { VariavelNo: 108525, ValorDado: evolucaoFono.observacoes || '' },
          { VariavelNo: 108526, ValorDado: evolucaoFono.proteseAuditiva ? 'SIM' : 'NÃO' },
          { VariavelNo: 108527, ValorDado: evolucaoFono.proteseDentaria ? 'SIM' : 'NÃO' },
          { VariavelNo: 108528, ValorDado: evolucaoFono.observacoes || '' },
          { VariavelNo: 108529, ValorDado: evolucaoFono.nome || '' },
          { VariavelNo: 108530, ValorDado: evolucaoFono.cRF || '' },
          { VariavelNo: 108531, ValorDado: evolucaoFono.comunicacaoForma || '' },
          { VariavelNo: 108532, ValorDado: evolucaoFono.descreva || '' },
          { VariavelNo: 108533, ValorDado: evolucaoFono.dataOcorrencia || '' },
          { VariavelNo: 108534, ValorDado: evolucaoFono.codigo || '' },
          { VariavelNo: 108535, ValorDado: evolucaoFono.titulo || '' },
          { VariavelNo: 108536, ValorDado: evolucaoFono.habilitado ? 'SIM' : 'NÃO' },
          { VariavelNo: 109066, ValorDado: evolucaoFono.conduta || '' },
          { VariavelNo: 109169, ValorDado: evolucaoFono.houveMudancadietaTablet ? 'SIM' : 'NÃO' },
          { VariavelNo: 109172, ValorDado: evolucaoFono.refeicaoComAdaptacaoTablet ? 'SIM' : 'NÃO' },
              ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

}
