import { IRelationship } from './irelationship';
export interface IResponsible extends IRelationship {
  // ocorrenciaNo: number;
  // idEscola:  any;
  // codigo:  any;
  // titulo:  any;
  // habilitado:  boolean;
  // nomeResponsavel:  any;
  // email: string;
  // enPersonType: EnPersonType;
  // // calculados
  // status?: string;
}
