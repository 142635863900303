import { IKeyValue } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { EnWsTrackStatus } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { interval } from 'rxjs';
import { of } from 'rxjs';

/* Gera e persiste um rastro das chamadas a webservice.
* As possibilidades de status são:
* open: quando um método é chamado
* completed: quando o método retorna com sucesso
* error: quando o método não retorna, ou retorna com erro.
*/
@Injectable()
export class WsTrackerService {
  wsCalls: IKeyValue[] = [];

  private _countId = 0;
  public get countId(): number {
    this._countId++;
    return this._countId;
  }
  public set countId(v: number) {
    this._countId = v;
  }

  constructor(
    private log: LogService,
    private glb: GlobalService) { }

  /* Adiciona a referência para uma chamada  */
  addCall(key: string): void {
    try {
      this.wsCalls.push({ key, value: EnWsTrackStatus.open } as IKeyValue);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addCall', error.message);
    }
  }

  /* Atualiza o status de uma chamada */
  updateCall(key: string, status: EnWsTrackStatus): void {
    try {
      const filtered = this.wsCalls.filter((f) => f.key === key);
      if (filtered && filtered.length) {
        // Algumas chamadas são tão rápidas (simultâneas) que geram mais de um item com a mesma chave - setDados, por exemplo.
        filtered.forEach((f) => (f.value = status));
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateCall', error.message);
    }
  }

  /* Zera o array que controla as chamadas */
  cleanCalls(): void {
    try {
      this.wsCalls = [];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCalls', error.message);
    }
  }

  /* Emitirá, de tempos em tempos, uma contagem dos itens carregados,
  * (com status = open)
  * Quando a contagem zera, significa que todos os itens foram chamados.
  */
  callCount(cleanCallsAfterCompleteAll: boolean = true): Observable<number> {
    try {
      return interval(200).pipe(
        map((x) => {
          const filtered = this.wsCalls.filter((f) => f.value === EnWsTrackStatus.open);
          const count = filtered ? filtered.length : 0;
          if (cleanCallsAfterCompleteAll && count <= 0) {
            this.cleanCalls();
          }
          return count;
        })
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'callCount', error.message);
    }
    return of(null);
  }

  getKey(params: any[], method: string): string {
    try {
      return this.glb.Left(params[0].value || params[0], 1) === '<'
        ? `${method}_${this.countId}` // chamada de set dados
        : `${this.getBaseKey(method, params)}_${this.countId}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getKey', error.message);
    }
    return null;
  }

  /* Gera uma chave, baseado no conjunto de parametros.  */
  getBaseKey(method: string, params: any[]): string {
    try {
      if (params && params.length > 0) {
        // TODO: deveria ser excluído AtividadeComponenteNo, ou então o cache valeria apenas para o próprio componente, independente se aponta para o mesmo cadastro.
        // no entanto, como não dá tempo para carregar o dado antes das outras chamadas, todos os itens ficam vazios.
        // return `${method}_${params.filter(f => f.name !== 'AtividadeComponenteNo').map((p) => p.value || p).join('_')}`;
        return `${method}_${params.map((p) => p.value || p).join('_')}`;
      } else {
        return `${method}`;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getKey', error.message);
    }
    return null;
  }


}
