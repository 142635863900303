import { BrowserModule } from '@angular/platform-browser';
import { UiDialogConfirmModule } from '@medlogic/shared/ui/dialog/ui-dialog-confirm';
import { UiLoadingModule } from '@medlogic/shared/ui/ui-loading';
import { MaterialModule } from '@medlogic/shared/material';
import { GeCoreModule } from '@medlogic/shared/gecore';
import { TabSizeDirective } from './directive/tab-size.directive';
import { AtividadeCadastroDetailDialogComponent } from './view/atividade-cadastro-detail-dialog/atividade-cadastro-detail-dialog.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AtividadeComponenteDAL } from './shared/model/dal/atividade-componente-dal';
import { CadastroListaDAL } from './shared/model/dal/cadastro-lista-dal';
import { DadoDAL } from './shared/model/dal/dado-dal';
import { AtividadeDAL } from './shared/model/dal/atividade-dal';
import { ExecucaoTarefaDAL } from './shared/model/dal/execucao-tarefa-dal';
import { ValidatorService } from './shared/service/validator.service';
import { LibService } from './shared/service/lib.service';
import { CalculadoraService } from './shared/service/calculadora.service';
import { CalculatorService } from './shared/service/calculator.service';
import { CalculadoraConditionService } from './shared/service/calculadora-condition.service';
import { ExpressionService } from './shared/service/expression.service';
import { ExpressionFunctionService } from './shared/service/expression-function.service';
import { ExpressionGridFunctionService } from './shared/service/expression-grid-function.service';
import { AgendaViewComponent } from './view/agenda-view/agenda-view.component';
import { TitleLabelComponent } from './view/partial/title-label/title-label.component';
import { StatusBarComponent } from './view/partial/status-bar/status-bar.component';
import { ActionBarComponent } from './view/partial/action-bar/action-bar.component';
import { AtividadeComponent } from './view/partial/atividade/atividade.component';
import { AtividadeViewComponent } from './view/atividade-view/atividade-view.component';
import { CtrTextBoxLabelComponent } from './shared/ctrl/ctr-textbox-label/ctr-textbox-label.component';
import { CtrMapaComponent } from './shared/ctrl/ctr-mapa/ctr-mapa.component';
import { CtrVideoComponent } from './shared/ctrl/ctr-video/ctr-video.component';
import { CtrRatingProgressComponent } from './shared/ctrl/ctr-rating-progress/ctr-rating-progress.component';
import { CtrRatingStarComponent } from './shared/ctrl/ctr-rating-star/ctr-rating-star.component';
import { CtrRadioButtonComponent } from './shared/ctrl/ctr-radio-button/ctr-radio-button.component';
// tslint:disable-next-line: max-line-length
import { CtrMultilineTextboxHTMLLabelComponent } from './shared/ctrl/ctr-multiline-textbox-htmllabel/ctr-multiline-textbox-htmllabel.component';
import { CtrMultilineTextboxLabelComponent } from './shared/ctrl/ctr-multiline-textbox-label/ctr-multiline-textbox-label.component';
import { CtrPositionDirective } from './shared/directive/ctr-position.directive';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TabComponent } from './view/partial/tab/tab.component';
import { FilterComponentesByTabPipe } from './shared/pipe/filter-componentes-by-tab.pipe';
import { CtrConfigUIDirective } from './shared/directive/ctr-config-ui.directive';
import { CtrlGridSearchPipe } from './shared/pipe/ctrl-grid-search.pipe';
import { CtrlIsVisibleDirective } from './shared/directive/ctrl-is-visible.directive';
import { CtrlIsReadOnlyDirective } from './shared/directive/ctrl-is-read-only.directive';
import { CtrlGridColumnDirective } from './shared/directive/ctrl-grid-column.directive';
import { CtrlGridTBodyDirective } from './shared/directive/ctrl-grid-tbody.directive';
import { CtrLabelComponent } from './shared/ctrl/ctr-label/ctr-label.component';
import { CtrComboboxComponent } from './shared/ctrl/ctr-combobox/ctr-combobox.component';
import { CtrImagePlayerComponent } from './shared/ctrl/ctr-image-player/ctr-image-player.component';
import { CtrCheckboxListComponent } from './shared/ctrl/ctr-checkbox-list/ctr-checkbox-list.component';
import { CtrButtonComponent } from './shared/ctrl/ctr-button/ctr-button.component';
import { CtrDateComponent } from './shared/ctrl/ctr-date/ctr-date.component';
import { CtrGridComponent } from './shared/ctrl/ctr-grid/ctr-grid.component';
import { VisibleAndRequiredValidatorDirective } from './shared/directive/visible-and-required-validator.directive';
import { IsReadOnlyDirective } from './shared/directive/is-read-only.directive';
import { TasksOfDayDirective } from './shared/directive/tasks-of-day.directive';
import { TarefaViewComponent } from './view/tarefa-view/tarefa-view.component';
import { TaskSearchPipe } from './shared/pipe/task-search.pipe';
import { TableDirective } from './shared/directive/table.directive';
import { TextMaskModule } from 'angular2-text-mask';
import { BarRatingModule } from 'ngx-bar-rating';
import { ActionService } from './shared/service/action.service';
import { NavigationService } from './shared/service/navigation.service';
import { OcorrenciaDAL } from './shared/model/dal/ocorrencia-dal';
import { AtividadeCadastroViewComponent } from './view/atividade-cadastro-view/atividade-cadastro-view.component';
import { AtividadeStandAloneComponent } from './view/atividade-stand-alone/atividade-stand-alone.component';
import { NextActionBarComponent } from './view/partial/next-action-bar/next-action-bar.component';
import { DebugComponent } from './view/partial/debug/debug.component';
import { ProcessoDAL } from './shared/model/dal/processo-dal';
import { StudioLayoutDirective } from './directive/studio-layout.directive';
import { CtrMobileConfigUIDirective } from './shared/directive/ctr-mobile-config-ui.directive';
import { JavascriptLib } from './shared/service/javascript-lib.service';
import { RatingComponent } from './view/partial/rating/rating.component';
import { ZeraMarginDirective } from './directive/zera-margin-padding.directive';
import { ExpressionServerFunctionService } from './shared/service/expression-server-function.service';
import { MultilineDialogComponent } from './shared/ctrl/ctr-multiline-textbox-label/multiline-dialog/multiline-dialog.component';
import { FilterCtrGridByKeywordPipe } from './pipe/filter-ctr-grid-by-keyword.pipe';
import { AtividadeProcessoComponent } from './view/atividade-processo/atividade-processo.component';
import { BlueprintComponent } from './view/blueprint/blueprint.component';
import { HideElemIfWidthDirective } from './shared/directive/hide-elem-if-width.directive';
import { PageNotFoundViewComponent } from './view/page-not-found-view/page-not-found-view.component';
import { CmpAtividadeComponent } from './component/cmp-atividade/cmp-atividade.component';
import { UserProcessComponent } from './view/partial/user-process/user-process.component';
import { GenericActivityViewComponent } from './view/generic-activity-view/generic-activity-view.component';
import { AtividadeMultiProcessoComponent } from './view/atividade-multi-processo/atividade-multi-processo.component';
import { CadastroListViewComponent } from './view/cadastro-list-view/cadastro-list-view.component';
import { CmpCadastroListComponent } from './component/cmp-cadastro-list/cmp-cadastro-list.component';
import { CtrlMatGridSearchPipe } from './shared/pipe/ctrl-mat-grid-search.pipe';
import { VisibleTabsPipe } from './shared/pipe/visible-tabs.pipe';
import { DocGeneratorService } from './shared/service/doc-generator.service';
import { DocGenerateDialogComponent } from './shared/dialog/doc-generate-dialog/doc-generate-dialog.component';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { CurrencyService } from './shared/service/currency.service';
import { FileUploadDialogComponent } from './shared/dialog/file-upload-dialog/file-upload-dialog.component';
import { FileUploadService } from './shared/service/file-upload.service';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { RatingModule } from 'primeng/rating';
import { SliderModule } from 'primeng/slider';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EditorModule } from 'primeng/editor';
import { SharedModule, ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { TooltipModule } from 'primeng/tooltip';
import { GMapModule } from 'primeng/gmap';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { ButtonBarComponent } from './view/partial/button-bar/button-bar.component';
import { CmpImgPreviewComponent } from './shared/component/cmp-img-preview/cmp-img-preview.component';
import { CmpWebcamComponent } from './shared/component/cmp-webcam/cmp-webcam.component';
import { BottomSheetConfirmComponent } from './shared/dialog/bottom-sheet-confirm/bottom-sheet-confirm.component';
import { CmpFileUploadSelectorComponent } from './shared/component/cmp-file-upload-selector/cmp-file-upload-selector.component';
import { GeFormProviderService } from './shared/service/ge-form-provider.service';
import { LoadingDialogComponent } from './shared/dialog/loading-dialog/loading-dialog.component';
import { TokenService } from './shared/service/token.service';
import { SendEmailService } from './shared/service/send-email.service';

import { NgxMaskModule, IConfig } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const maskConfig: Partial<IConfig> = {
  validation: false,
};

const COMPONENTS = [
  // Pipe
  CtrlGridSearchPipe,
  CtrlMatGridSearchPipe,
  FilterComponentesByTabPipe,
  TaskSearchPipe,
  VisibleTabsPipe,
  SafeUrlPipe,
  // FilterEntryWithoutRateioPipe,
  // Directive
  CtrConfigUIDirective,
  CtrlIsVisibleDirective,
  CtrlIsReadOnlyDirective,
  CtrlGridColumnDirective,
  CtrlGridTBodyDirective,
  CtrPositionDirective,
  TableDirective,
  ZeraMarginDirective,
  TabSizeDirective,
  StudioLayoutDirective,
  CtrMobileConfigUIDirective,
  HideElemIfWidthDirective,
  // Controles
  TabComponent,
  CtrMultilineTextboxLabelComponent,
  MultilineDialogComponent,
  CtrLabelComponent,
  CtrMultilineTextboxHTMLLabelComponent,
  CtrComboboxComponent,
  CtrImagePlayerComponent,
  CtrRadioButtonComponent,
  CtrCheckboxListComponent,
  CtrButtonComponent,
  CtrRatingStarComponent,
  CtrRatingProgressComponent,
  CtrVideoComponent,
  CtrMapaComponent,
  CtrDateComponent,
  CtrTextBoxLabelComponent,
  CtrGridComponent,
  // Atividade
  AtividadeViewComponent,
  AtividadeComponent,
  CmpAtividadeComponent,
  AtividadeCadastroViewComponent,
  AtividadeCadastroDetailDialogComponent,
  ActionBarComponent,
  ButtonBarComponent,
  StatusBarComponent,
  // Custom Validators
  VisibleAndRequiredValidatorDirective,
  IsReadOnlyDirective,
  TitleLabelComponent,
  AgendaViewComponent,
  TasksOfDayDirective,
  TarefaViewComponent,
  AtividadeStandAloneComponent,
  AtividadeProcessoComponent,
  AtividadeMultiProcessoComponent,
  NextActionBarComponent,
  DebugComponent,
  RatingComponent,
  FilterCtrGridByKeywordPipe,
  BlueprintComponent,
  PageNotFoundViewComponent,
  AtividadeComponent,
  UserProcessComponent,
  GenericActivityViewComponent,
  CadastroListViewComponent,
  CmpCadastroListComponent,
  DocGenerateDialogComponent,
  FileUploadDialogComponent,
  CmpImgPreviewComponent,
  CmpWebcamComponent,
  CmpFileUploadSelectorComponent,
  BottomSheetConfirmComponent,
  LoadingDialogComponent
];

const NGPRIME_MODULES = [
  // ng Prime
  TextMaskModule,
  DropdownModule,
  InputMaskModule,
  RatingModule,
  SliderModule,
  CalendarModule,
  InputTextareaModule,
  EditorModule,
  SharedModule,
  // DataTableModule,
  ConfirmDialogModule,
  MessagesModule,
  TooltipModule,
  GMapModule,
  OverlayPanelModule,
  // GrowlModule,
  BarRatingModule,
  // SpinKit,
  ToastModule
];

@NgModule({
  declarations: [
    COMPONENTS,
  ],
  imports: [
    // FIXME: realizada importação direta do arquivo. Estabelecer dependência entre bibliotecas dificulta o debug.
    // GeCoreModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    UiLoadingModule,
    UiDialogConfirmModule,
    NgxMaskModule.forRoot(maskConfig),
    // NgSpinKitModule, // Não entendi o porque não reconheceu da exportação de GeCoreModule
    // GE
    NGPRIME_MODULES
  ],
  exports: [
    COMPONENTS,
    GeCoreModule,
    NGPRIME_MODULES
  ],
  providers: [
    AtividadeComponenteDAL,
    CadastroListaDAL,
    DadoDAL,
    OcorrenciaDAL,
    AtividadeDAL,
    ExecucaoTarefaDAL,
    ProcessoDAL,
    // Services
    ValidatorService,
    LibService,
    // Fórmulas e Funções
    CalculadoraService,
    CalculatorService,
    CalculadoraConditionService,
    JavascriptLib,
    ExpressionService,
    ExpressionFunctionService,
    ExpressionGridFunctionService,
    ExpressionServerFunctionService,
    ConfirmationService,
    NavigationService,
    ActionService,
    DocGeneratorService,
    CurrencyService,
    FileUploadService,
    MessageService,
    GeFormProviderService,
    TokenService,
    SendEmailService,
    // HotTableRegisterer
  ],
  entryComponents: [
    // Necessário para que possam ser instanciados numa janela de diálogo do Google Material.
    AtividadeCadastroDetailDialogComponent,
    MultilineDialogComponent,
    DocGenerateDialogComponent,
    FileUploadDialogComponent,
    BottomSheetConfirmComponent,
    LoadingDialogComponent
  ]
})
export class GeFormModule {
  /* Para previnir de multiplas importações desse módulo, que deve ser singleton */
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: GeFormModule
  ) {
    if (parentModule) {
      throw new Error('GeFormModule is already loaded. Import it in the AppModule only');
    }
  }


}
