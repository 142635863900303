import { EnStockChangeMode } from './../enum/medlogic/en-stock-change-mode.enum';
import { EntityState } from '@ngrx/entity';
import { ITenant } from './itenant';
import { EnUserRole } from '../enum/en-user-role.enum';
import { EnTheme } from '../enum/en-theme.enum';
import { ILogin } from './ilogin';

// ***** Tenant: Representa um ambiente do MedLogic. Normalmente, um ambiente por cliente
// *** customerId: Em alguns casos, multiplos clientes estão no mesmo ambiente (tenant). Nesse caso, preencher customerId.
export interface ITenantState extends EntityState<ITenant> {
  loggedUserName: string;
  tenantId: number;
  enTheme: EnTheme;
  selectedTenant: ITenant;
  userRole: EnUserRole;
  // Atenção: não deve ser confundido com selectedTenant.customerAno que representa a AtividadeNo.
  customerId: number; // Para aplicações multi-customers no mesmo ambientes (mesmo tenant) esse valor deverá ser definido.
  identification1: any;
  identification2: any;
  token: string;
  isDebug: boolean;
  userId: number;
  ono: number;
  login: ILogin;
  error: any;
  selectedId: number | null;
  enStockChangeMode: EnStockChangeMode;
  imgUrl?: string;
}
