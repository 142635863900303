import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

export interface IChart {
    barChartOptions: ChartOptions;
    barChartLabels: Label[];
    barChartType: ChartType;
    barChartLegend: boolean;
    barChartPlugins: string[];
    barChartData: ChartDataSets[];
}
