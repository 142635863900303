
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IIntervecoesMedicamentosHorarios, IInterventionMedication } from '@medlogic/shared/shared-interfaces';


export const loadHorarios = createAction(
  '[card Patient] Load Horarios',
  props<{ numOfDaysToCheckOcurrency: number }>()
);


export const loadHorariosBySelectedPatient = createAction(
  '[Card Horario] Load Horarios by Selected Patient',
  props<{ numOfDaysToCheckOcurrency: number }>()
);

export const loadHorariosByPrescription = createAction(
  '[Page Prescription] Load Horarios by Prescription Id',
  props<{ prescriptionId: number }>()
);

export const loadHorariosSuccess = createAction(
  '[Horario/API] Load Horarios Success',
  props<{ horarios: IIntervecoesMedicamentosHorarios[] }>()
);


export const horariosFail = createAction(
  '[Horario/API] Horario Fail',
  props<{ error: string }>()
);



export const addHorarios = createAction(
  '[Horario/API] Add Horarios',
  props<{ horarios: IIntervecoesMedicamentosHorarios }>()
);


export const upsertHorario = createAction(
  '[Horario/API] Upsert Horario',
  props<{ horario: IIntervecoesMedicamentosHorarios }>()
);

export const addHorariosandInsert = createAction(
  '[Horario/API] Add Horarios',
  props<{ horario: IIntervecoesMedicamentosHorarios }>()
);

export const checkHorarios = createAction(
  '[Stock/API] Check Horarios',
  props<{ medication: IInterventionMedication }>()
);


export const upsertHorarios = createAction(
  '[Horario/API] Upsert Horarios',
  props<{ horarios: IIntervecoesMedicamentosHorarios[] }>()
);

export const updateHorario = createAction(
  '[Horario/API] Update Horario',
  props<{ horario: Update<IIntervecoesMedicamentosHorarios> }>()
);

export const updateHorarios = createAction(
  '[Horario/API] Update Horarios',
  props<{ horarios: Update<IIntervecoesMedicamentosHorarios>[] }>()
);

export const deleteHorario = createAction(
  '[Horario/API] Delete Horario',
  props<{ id: string }>()
);

export const deleteHorarios = createAction(
  '[Horario/API] Delete Horarios',
  props<{ ids: string[] }>()
);

export const deleteHorariosBy = createAction(
  '[Horario/API] Delete Horarios',
  props<{ patientId: number, medicationId: number }>()
);

export const saveHorariosSuccess = createAction(
  '[Stock/API] Save Horario Success'
);

export const cleanHorarios = createAction(
  '[States Management Service] Clean Horarios'
);

