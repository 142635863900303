import { ConfigStateService } from '@medlogic/shared/state-config';
import { ObjectDAL } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { WebService } from '@medlogic/shared/shared-data-access';

@Injectable()
export class OcorrenciaDAL extends ObjectDAL {
  constructor(webService: WebService, log: LogService, global: GlobalService,
    private cnf: ConfigStateService) {
    super(webService, log, global);
  }

  //  <setOcorrencia xmlns="GE/ws/">
  //   <token>dKde-38aK-43D3ew-d85Qw</token>
  //   <_strXml>
  //     <Ocorrencias>
  //       <Ocorrencia>
  //         <OcorrenciaStatusNo>2</OcorrenciaStatusNo>
  //         <DtInicio>2017-02-01T19:02:30</DtInicio>
  //         <UsuarioResponsavelNo>415</UsuarioResponsavelNo>
  //         <UsuarioExecutorNo>415</UsuarioExecutorNo>
  //       </Ocorrencia>
  //     </Ocorrencias>
  //   </_strXml>
  // </setOcorrencia>

  setAll(ocorrenciaStatusNo: number, usuarioLogadoNo: number): Observable<any> {
    try {
      const method = 'setOcorrencia';
      const result = this.webService.connect(method, [
        { name: '_strXml', value: this.getStrXML(ocorrenciaStatusNo, usuarioLogadoNo) }
      ]);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setAll', error.message);
    }
  }

  protected getStrXML(ocorrenciaStatusNo: number, usuarioLogadoNo: number): string {
    try {
      return `<Ocorrencias>
          <Ocorrencia>
            <OcorrenciaStatusNo>${ocorrenciaStatusNo}</OcorrenciaStatusNo>
            <DtInicio>${this.global.RetornarAllXsdDateTime(new Date())}</DtInicio>
            <UsuarioResponsavelNo>${usuarioLogadoNo}</UsuarioResponsavelNo>
            <UsuarioExecutorNo>${usuarioLogadoNo}</UsuarioExecutorNo>
          </Ocorrencia>
        </Ocorrencias>`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getStrXML', error.message);
    }
    return null;
  }

  /*Irá notificar que o salvamento terminou, para permitir geração do XML. */
  setOcorrenciaNotificarConclusaoSalvamento(pno: number, ono: number, uno: number, ano: number): Observable<any> {
    try {
      //     <OcorrenciaNotificarConclusaoSalvamento xmlns="GE/ws/">
      //     <token>dKde-38aK-43D3ew-d85Qw</token>
      //     <ProcessoNo>146</ProcessoNo>
      //     <OcorrenciaNo>326995</OcorrenciaNo>
      //     <UsuarioLogadoNo>116</UsuarioLogadoNo>
      //     <atividadeNo>429</atividadeNo>
      //   </OcorrenciaNotificarConclusaoSalvamento>
      const method = 'OcorrenciaNotificarConclusaoSalvamento';
      const result = this.webService.connect(method, [
        { name: 'ProcessoNo', value: pno },
        { name: 'OcorrenciaNo', value: ono },
        { name: 'UsuarioLogadoNo', value: uno },
        { name: 'atividadeNo', value: ano }
      ]);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'OcorrenciaNotificarConclusaoSalvamento', error.message);
    }
  }

  /*Inicia uma nova Ocorrência. */
  IniciarNova(
    uno: number,
    pno: number,
    clienteId: string = '',
    complemento1: string = '',
    complemento2: string = ''
  ): Observable<any> {
    try {
      //     <IniciarNova xmlns="GE/ws/">
      //     <token>dKde-38aK-43D3ew-d85Qw</token>
      //     <usuarioLogadoNo>116</usuarioLogadoNo>
      //     <processoNo>146</processoNo>
      //     <clienteId/>
      //     <complemento1/>
      //     <complemento2/>
      //   </IniciarNova>
      const method = 'IniciarNova';
      const result = this.webService.connect(method, [
        { name: 'usuarioLogadoNo', value: uno },
        { name: 'processoNo', value: pno },
        { name: 'clienteId', value: clienteId },
        { name: 'complemento1', value: complemento1 },
        { name: 'complemento2', value: complemento2 }
      ]);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'IniciarNova', error.message);
    }
  }

  // tslint:disable-next-line: max-line-length
  //     <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
  //   <soap:Body>
  //     <IniciarEditar xmlns="GE/ws/">
  //       <token>dKde-38aK-43D3ew-d85Qw</token>
  //       <usuarioLogadoNo>303</usuarioLogadoNo>
  //       <processoNo>218</processoNo>
  //       <ocorrenciaNo>222280</ocorrenciaNo>
  //       <tarefaNo>50874</tarefaNo>
  //     </IniciarEditar>
  //   </soap:Body>
  // </soap:Envelope>

  /*Inicia uma nova Ocorrência. */
  IniciarEditar(uno: number, pno: number, ono: number, tno: number): Observable<any> {
    try {
      const method = 'IniciarEditar';
      const result = this.webService.connect(method, [
        { name: 'usuarioLogadoNo', value: uno },
        { name: 'processoNo', value: pno },
        { name: 'ocorrenciaNo', value: ono },
        { name: 'tarefaNo', value: tno }
      ]);
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'IniciarEditar', error.message);
    }
  }


}
