import { EnPosology } from '../../enum/medlogic/en-posology.enum';
import { IEstoqueMateriais } from './iestoque-materiais';
import { IIntervecoesMedicamentosHorarios } from './iintervencoes-medicamentos-horarios';
import { IMedicationCheckin } from './imedication-checkin';

export interface IMedication {
    guid: string;
    medicationId: number;
    patientId?: number; // Inserido após mudança para bd relacional
    ocorrenciaNo: number;
    medicationName: string;
    date: string; // data em String
    prescription: string; // Campo de texto comentando a prescrição, por exemplo, "Em caso de dor", "Após almoço"
    prescribedTime: string; // Hora prescrita para tomar
    medicationDate: Date; // Data que o medicamento foi ministrado
    dosage: string; // Dose 10 mg, 10 gotas, etc COMPLETA
    dosageValue: number; // Dose 10 mg, 10 gotas, etc APENAS O NÚMERO
    dosageUnity: string; // Dose 10 mg, 10 gotas, etc  APENAS A UNIDADE
    took: boolean; // Se tomou ou não
    lstCheckin: IMedicationCheckin[];
    lstHorarios?: IIntervecoesMedicamentosHorarios[];
    access: string;
    presentation: string;
    instruction: string;
    // Campos calculados
    status: string;/*  S para tomou, N para Não tomou, - não está prescrito nesse dia. */
    // frequency: string; // Duas vezes ao dia, a cada dois dias, dia sim dia não, etc
    // Atenção: A frequencia não será um campo. Caso esteja prescrito duas vezes ao dia, deverão ser criados dois registros por dia. E assim, sucessivamente.
    isChecked?: boolean; // Se foi marcado na separação para ser impresso
    material: string; // Tipo de material, exemplo 1 - MEDICAÇÃO
    businessUnit: string;
    costCenter: string;
    unity: string; // Apresentação: comprimido, gotas, etc
    codPacienteNomedoPacienteCodMedicamento: string;
    dailyQuantity: number; // Quantidade de comprimidos (por exemplo) tomados em cada ministração - Quantidade por horário
    dailyQuantityGotas: number;
    // Itens calculados do estoque
    stock?: IEstoqueMateriais;
    quantity?: number;
    dtStart?: Date;
    dtEnd?: Date;
    enPosology?: EnPosology;
    intervaloDS?: any;
    stockSourceQuantity?: number;
    stockSourceLastBatch?: string;
    stockSourceLastDueDate?: Date;
    stockSource?: IEstoqueMateriais;
    // Pwa
    isSOS: boolean;
    stopMedication: boolean;
    stopInstructions: string;
    // Para a observação de medicação
    observation?: string;
    // qtdUtilizada: number;
    isSaving?: boolean;
    isEditing?: boolean;
    isNew?: boolean; // Indica item recém-criado.
    // Novos para lista de medicamentos
    registrationNum?: string;
    dtRegister?: Date | string;
    industry?: string;
    substance?: string;
    isRestricted?: boolean;
    prescriptionId?: number;
    medicationTitle?: string;
    order?: number;
    tempoUso?: string;
    horarios?: string;
    controlado?: string;
    wasChanged: boolean;
    estimatedTime: string;
    materialId: number;
    cor?: string;
}
