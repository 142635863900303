import { LibService } from '@medlogic/shared/geform';
import { Injectable } from '@angular/core';
import {
  EnRequestType, IIvcf, LogService,
  ConfigJsonService, GlobalService, routeGetIvcf
} from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';
import { IvcfService } from './ivcf.service';

@Injectable({
  providedIn: 'root'
})
export class IvcfCustomService extends IvcfService {

  urlGetIvcf = `${routeGetIvcf}?cadIvcfNo={0}&ono={1}`;

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnfJson: ConfigJsonService,
    log: LogService,
    lib: LibService,
    basepage: BasePageService
  ) {
    super(cadastroSrv, glb, cnfJson, log, lib, basepage);
  }


  getIvcf(
    cadIvcfNo: number,
    ono: number,
  ): Observable<IIvcf> {
    try {
      const urlGet = this.basepage.format(this.urlGetIvcf,
        cadIvcfNo,
        ono,
      );
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getIvcf', error.message);
    }
    return of(null);
  }


}
