import { animate, style, transition, trigger, state, query, stagger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [

  transition(':enter', [
    style({ opacity: 0 }),
    animate('1s', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    animate('1s', style({ opacity: 0 }))
  ])
  // // the "in" style determines the "resting" state of the element when it is visible.
  // state('in', style({ opacity: 1 })),

  // // fade in when created. this could also be written as transition('void => *')
  // transition(':enter', [
  //   style({ opacity: 0 }),
  //   animate(400)
  // ]),

  // // fade out when destroyed. this could also be written as transition('void => *')
  // transition(':leave',
  //   animate(200, style({ opacity: 0 })))
  // transition(':enter, * => 0, * => -1', []),
  // transition(':increment', [
  //   query(':enter', [
  //     style({ opacity: 0, width: '0px' }),
  //     stagger(50, [
  //       animate('300ms ease-out', style({ opacity: 1, width: '*' })),
  //     ]),
  //   ], { optional: true })
  // ]),
  // transition(':decrement', [
  //   query(':leave', [
  //     stagger(50, [
  //       animate('300ms ease-out', style({ opacity: 0, width: '0px' })),
  //     ]),
  //   ])
  // ]),
]);
