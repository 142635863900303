import { IPractitioner } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setPractitioner = createAction(
  '[Practitioner/API] Set Practitioner',
  props<{ selectedId: string }>()
);
export const loadPractitioners = createAction(
  '[Practitioner/API] Load Practitioners'
);

export const loadPractitionersSuccess = createAction(
  '[Practitioner/API] Load Practitioners Success',
  props<{ practitioners: IPractitioner[] }>()
);

export const practitionerFail = createAction(
  '[Practitioner/API] Practitioner Fail',
  props<{ error: string }>()
);

export const addPractitioner = createAction(
  '[Practitioner/API] Add Practitioner',
  props<{ practitioner: IPractitioner }>()
);

export const addPractitioners = createAction(
  '[Practitioner/API] Add Practitioners',
  props<{ practitioners: IPractitioner[] }>()
);

export const upsertPractitioner = createAction(
  '[Practitioner/API] Upsert Practitioner',
  props<{ practitioner: IPractitioner }>()
);

export const upsertPractitioners = createAction(
  '[Practitioner/API] Upsert Practitioners',
  props<{ practitioners: IPractitioner[] }>()
);

export const updatePractitioner = createAction(
  '[Practitioner/API] Update Practitioner',
  props<{ practitioner: Update<IPractitioner> }>()
);

export const updatePractitioners = createAction(
  '[Practitioner/API] Update Practitioners',
  props<{ practitioners: Update<IPractitioner>[] }>()
);

export const deletePractitioner = createAction(
  '[Practitioner/API] Delete Practitioner',
  props<{ id: string }>()
);

export const deletePractitioners = createAction(
  '[Practitioner/API] Delete Practitioners',
  props<{ ids: string[] }>()
);

export const clearPractitioners = createAction(
  '[Practitioner/API] Clear Practitioners'
);
export const clearSelectedPractitioner = createAction(
  '[Card Practitioner] Clear Selected Practitioners'
);
