import { ConfigStateService } from '@medlogic/shared/state-config';
import { Injectable } from '@angular/core';
import { PrescriptionService } from './prescription.service';
import { GlobalService, LogService, LocalLibService } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

@Injectable()
export class PrescriptionCustomService extends PrescriptionService {

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnf: ConfigStateService,
    lib: LocalLibService,
    log: LogService) {
    super(cadastroSrv, glb, cnf, lib, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }


}