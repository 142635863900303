import { AppMedlogicPwaCuidadoState } from '../states/app-state';
import { createSelector } from '@ngrx/store';
import { IListItem } from '../../interface/ilist-item';

export const selectAllGenerics = (state: AppMedlogicPwaCuidadoState) => state.generic.allGenerics;

export const selectGenericById = (id: string) => createSelector(
  selectAllGenerics,
  allGenerics => {
    if (allGenerics && id) {
      return allGenerics.find(f => f.id === id);
    }
    return null;
  });


export const selectAllGenericsAsListItems = createSelector(
  selectAllGenerics,
  (allGenerics: []) => {
    if (allGenerics) {
      return allGenerics;
      // .map(m => (
      //   {
      //     id: m.codigo,
      //     topLeft: m.nomeEmpresa,
      //     bottomLeft: m.conta,
      //     topRight: m.bENEFICIARIO,
      //     bottomRight: m.dataAberturaConta,
      //     color: EnColor.green
      //   } as IListItem
      // ));
    }
    return [];
  });

export const selectAllGenericsAsListItemsByKeyword = createSelector(
  selectAllGenericsAsListItems,
  (allItems: IListItem[], props: { keyword: string }) => {
    if (allItems) {
      return allItems.filter(f =>
        (props.keyword || '') === '' ||
        f.topLeft.includes(props.keyword) ||
        f.topRight.includes(props.keyword) ||
        f.bottomLeft.includes(props.keyword) ||
        f.bottomRight.includes(props.keyword)
      );
    } else {
      return [];
    }
  });
