import { Injectable } from '@angular/core';
import { routePatientIcons } from '@medlogic/shared/shared-interfaces';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { FhirGenericService } from './fhir-generic.service';


@Injectable({ providedIn: 'root' })
export class FhirPatientIconsService extends FhirGenericService {

  protected url = routePatientIcons;



  getIconsByPatientIdentifier(patientIdentifier: string): Observable<any> {
    const url = `${this.getUrl()}getAll?patientIdentifier=${patientIdentifier}`;
    try {
      const $result = this.http.get<any>(url);
      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getIconsByPatientIdentifier', error.message);
    }
    return of(null);
  }

  getBehaviourStatus(medlogicId: number): Observable<{ display: string } | null> {
    const url = `${this.getUrl()}getBehaviourStatus?medlogicId=${medlogicId}`;

    return this.http.get<{ display: string }>(url).pipe(
      map((display: { display: string }) => {
        if (display.display === "residente") {
          return { display: "residente-normal" };
        }

        return display;
      }),
      catchError(err => {
        console.log("error: ", err);
        return of(null);
      })
    );
  }

  postBehaviourStatus(medlogicId: number, iconDisplay: string): Observable<{ display: string } | null> {
    const url = `${this.getUrl()}postBehaviourStatus?medlogicId=${medlogicId}`;

    return this.http.post<{ display: string }>(url, { iconDisplay }).pipe(
      catchError(err => {
        console.log("error: ", err);
        return of(null);
      })
    );


  }


}
