import { catchError } from 'rxjs/operators';
import { ConfigStateService } from '@medlogic/shared/state-config';
// import { AddhereNavigationService } from '@medlogic/addhere/addhere-navigation';
import { UnsubscribeOnDestroyAdapter, LogService, EnRequestType, ILoginWithUserResponse } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BasePageService } from './base-page.service';

// Esse serviço é responsável pela chamada http. Se utilizado o da '@medlogic/shared/shared-interfaces';
// faria chamada por meio de Axios, que é a implementação da API
@Injectable({
  providedIn: 'root'
})
export class LoginService extends UnsubscribeOnDestroyAdapter {

  // TODO: API
  urlPost = 'Account/Login';
  urlPostLoginWithUser = `Account/LoginWithUser`;

  constructor(
    private basepage: BasePageService,
    private log: LogService,
    // private nav: AddhereNavigationService,
    private cnf: ConfigStateService,
  ) {
    super();
  }

  /* Semelhante ao getLogin, no entanto também retorna o usuarioNo, além do token.
  * Atenção: os parâmetros token e usuarioLogadoNo já serão armazenados no config.service.
  */
  getLoginWithUser(userName: string, password: string): Observable<ILoginWithUserResponse | { message: string }> {
    try {
      const loginPost$: Observable<ILoginWithUserResponse> = this.basepage.baseDados(EnRequestType.Post, this.urlPostLoginWithUser,
        {
          password,
          userName
        });
      return loginPost$
        .pipe(
          catchError((err, obs) => of(err))
        )
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLoginWithUser', error.message);
    }
    return of(null);
  }

  /* Realiza o logoff do usuário.
  * Atenção: Não limpa os caches. Há um método para isso em pwa-navigation-view.
  */
  logoff(): void {
    try {
      this.cnf.reset();
      // this.nav.navigateToLogin();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'logoff', error.message);
    }
  }


}
