import { EnPersonType } from '@medlogic/shared/shared-interfaces';
import { EnStatusAction } from './../enum/en-status-action';
export interface ISmartListAction<T = any> {
    topLeft: string;
    bottomLeft: string;
    topRight: string;
    id: number;
    obj: T;
    iconName: string;
    iconColor: string;
    statusIconName: string;
    statusIconColor: string;
    enStatusAction: EnStatusAction;
    actionTitle: string;
    enPersonType: EnPersonType;
}
