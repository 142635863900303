import { createReducer, on } from '@ngrx/store';
import { loadCustomer, setCustomer, selectCustomer, fetchSuccessCustomer } from '../actions/customer.actions';

export const initialState = {};

// tslint:disable-next-line: variable-name
const _customerReducer = createReducer(initialState,
  on(loadCustomer, (state, payload) => ({
    ...state,
    bannerTitle: 'CLIENTES',
    selectedCustomer: null
  })),
  on(fetchSuccessCustomer, (state, payload) => ({
    ...state,
    contas: payload.allCustomers
  })),
  on(selectCustomer, (state, payload) => ({
    ...state,
    selectedCustomer: payload.selectedCustomer
  })),
  on(setCustomer, (state, payload) => ({
    ...state,
    allCustomers: payload.allCustomers
  })),
);

export function customerReducer(state, action) {
  return _customerReducer(state, action);
}
