import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IScheduledState } from './scheduled-state';
import * as fromScheduled from './scheduled.reducer';


export const selectScheduledState = createFeatureSelector<IScheduledState>(fromScheduled.scheduledsFeatureKey);
export const selectScheduledAll = createSelector(selectScheduledState, fromScheduled.selectAll);


//TODO: NECESSÁRIO VALIDAR ESTE TRECHO DE CÓDIGO
// export const selectScheduledAllActive = createSelector(
//   selectScheduledAll, selectActivityDetailAll,
//   scheduleds => scheduleds,
//   activityDetails => activityDetails
//     .filter(scheduleds => scheduleds.scheduledIdentifier, activityDetails => ['43A6FD48-3429-ED11-AB4B-0AF88D461817' ,'C1E76F51-3429-ED11-AB4B-0AF88D461817'].includes(activityDetails.activityStatusIdentifier))
//     .sort((a, b) => a?.scheduledIdentifier?.localCompare(b?.scheduledIdentifier))
//     // .filter(scheduled => scheduled.identificacaoScheduled && activityDetails => ['43A6FD48-3429-ED11-AB4B-0AF88D461817' ,'C1E76F51-3429-ED11-AB4B-0AF88D461817'].includes(activityDetail.activityStatusIdentifier)
//     // .sort((a, b) => a?.scheduledIdentifier?.localeCompare(b?.scheduledIdentifier))
// );

export const selectedScheduled = createSelector(selectScheduledState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const isScheduledLoading = createSelector(
  selectScheduledAll,
  scheduleds => scheduleds?.length <= 0,
);

export const selectedScheduledIdentifier = createSelector(selectScheduledState, state => {
  return (state?.selectedId || 0) > 0 ? state?.selectedId : null;
});
