
import { refCount, publishReplay, map, filter, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { GlobalService, IForm, LogService } from '@medlogic/shared/shared-interfaces';
import { IEvolucaoTerapiaOcupacional } from "../../../../shared/shared-interfaces/src/lib/interface/ievolucao-terapia-ocupacional"

export abstract class EvolucaoTerapiaOcupacionalService {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_108717,V_108752,V_108753,V_108754,V_108755,V_108757,V_108759,V_108766,V_108779,V_101305,V_109303,V_109304,V_109305,V_109311,V_109312,V_109313,V_109314';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: Array<IEvolucaoTerapiaOcupacional> = new Array<IEvolucaoTerapiaOcupacional>();

  cadastroNo = 24560;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IEvolucaoTerapiaOcupacional> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate).pipe(
        filter(f => {
          return this.glb.isBetweenIgnoreTime(f.dataEvolucao, startDate, endDate);
        }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IEvolucaoTerapiaOcupacional> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IEvolucaoTerapiaOcupacional {
    try {
      return {
        dataEvolucao: this.glb.ddMMYYYYThhmmssToDate(c.V_101305),
        usoAlmofada: this.glb.getBoolean(c.V_108717),
        almofada: c.V_108752,
        procedencia: c.V_108753,
        numero: c.V_108754,
        dataManutencao: this.glb.ddMMYYYYThhmmssToDate(c.V_108755),
        dataEmprestimo: this.glb.ddMMYYYYThhmmssToDate(c.V_108757),
        status: c.V_108759,
        tipoColchao: c.V_108766,
        totalMIF: c.V_108779,
        higieneOralUsoProtese: c.V_109303,
        pentear: c.V_109304,
        barbaMaquiagem: c.V_109305,
        auxilio: c.V_109311,
        superiores: c.V_109312,
        inferiores: c.V_109313,
        campoAberto: c.V_109314,
      } as IEvolucaoTerapiaOcupacional;
    } catch (error) {
      this.log.Registrar(this.constructor.name, "toAttribute", error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(movimentacao: IEvolucaoTerapiaOcupacional, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(evolucaoTerapiaOcupacional: IEvolucaoTerapiaOcupacional): Array<IForm> {
    try {
        return [
          { VariavelNo: 108717, ValorDado: evolucaoTerapiaOcupacional.usoAlmofada ? 'SIM' : 'NÃO' },
          { VariavelNo: 108752, ValorDado: evolucaoTerapiaOcupacional.almofada || '' },
          { VariavelNo: 108753, ValorDado: evolucaoTerapiaOcupacional.procedencia || '' },
          { VariavelNo: 108754, ValorDado: evolucaoTerapiaOcupacional.numero || '' },
          { VariavelNo: 108755, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoTerapiaOcupacional.dataManutencao) },
          { VariavelNo: 108757, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoTerapiaOcupacional.dataEmprestimo) },
          { VariavelNo: 108759, ValorDado: evolucaoTerapiaOcupacional.status || '' },
          { VariavelNo: 108766, ValorDado: evolucaoTerapiaOcupacional.tipoColchao || '' },
          { VariavelNo: 108779, ValorDado: evolucaoTerapiaOcupacional.totalMIF || '' },
          { VariavelNo: 101305, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolucaoTerapiaOcupacional.dataEvolucao) },
              ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

}
