import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { WebService } from '@medlogic/shared/shared-data-access';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable()
export class CustomizeService {
  urlGetStyleCliente = 'Customize/GetStyle';

  constructor(
    private basepage: BasePageService,
    private log: LogService,
    private ws: WebService
  ) { }

  getStyleCliente(): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGetStyleCliente);
      const result = this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getStyleCliente', error.message);
    }
    return of(null);
  }


}
