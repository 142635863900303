import { ConfigStateService } from '@medlogic/shared/state-config';
import { CalculatorService } from './calculator.service';
import { CalculadoraService } from './calculadora.service';
import { Injectable } from '@angular/core';
import { ExpressionService } from './expression.service';
import { LibService } from './lib.service';
import { ExpressionServerFunctionService } from './expression-server-function.service';
import { LogService, ConfigJsonService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { WebService } from '@medlogic/shared/shared-data-access';

@Injectable()
export class CalculadoraConditionService extends CalculadoraService {
  constructor(
    log: LogService,
    global: GlobalService,
    webService: WebService,
    expression: ExpressionService,
    expServerFunc: ExpressionServerFunctionService,
    calculatorSrv: CalculatorService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
  ) {
    super(log, global, webService, expression, expServerFunc, calculatorSrv, lib, cnf, cnfJson);
    this.isCondition = true; // Será utilizado para controlar quando é uma CalculadoraCondition
  }

  // @Override
  // Retorna o resultado do cálculo de uma fórmula. Retorna null se não for uma fórmula.
  // Substitui as variáveis da fórmula pelos respectivos valores.
  protected calculateSimple(
    ctrl: IAtividadeComponenteDAL,
    lstCtrlReferenciados: Array<IAtividadeComponenteDAL>,
    supostaFormula: string
  ): any {
    try {
      // let formula = this.getFormula(supostaFormula);
      // formula = this.replaceVarByValue(formula, lstCtrlReferenciados);
      const formula = this.cleanCondition(supostaFormula);
      return this.calculate(ctrl, lstCtrlReferenciados, formula);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculate', error.message);
    }
    return null;
  }

  // Procurará por eventuais símbolos alternativos na expressão lógica e os substituirá pelos corretos.
  // IMPORTANTE: Os arrays devem ser ordenados de forma que os simbolos compostos apareçam depois dos simples que usam os mesmos elementos.
  // caso contrário a rotina de replace substituirá de forma incorreta mais de uma vez o mesmo símbolo.
  // Exemplo: <  deve vir antes de <= no array. Assim, quando procurar novamente por <= não encontrará < e ao contrário encontraria.
  // Também tornará todas maiúsculas para evitar que diferenças de captulação promovam diferença nas comparações
  protected cleanCondition(formula: string): string {
    try {
      this.lib.arrayConditionSimbol.forEach((item, i, ar) => {
        // TODO: REVER
        // ATENÇÃO: PARTE DESSA EXPRESSÃO FOI ALTERADA. PODE HAVER PROBLEMA COM A SUBSTITUIÇÃO
        // const exp = '([\\s\\w\\)])(' + item.search + ')(?=["\\s\\w\\(])';
        const exp = '([\\s\\w\\)])(' + item.search + ')(["\\s\\w\\(])'; // / arrayConditionSimbol[i]/gi; //g = todos i = case insensitive
        const pattern: RegExp = new RegExp(exp, 'g');
        // Não substituirã o símbolo de igual caso faça parte de outro símbolo: <=, >= ou !=
        formula = formula.replace(pattern, item.replace);
      });
      this.cleanEmptyVariableValue(formula, false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCondition', error.message);
    }
    return formula;
  }

  /*Remove o caracter de controle que identifica quando o valor de uma variável na tela foi encontrado vazio */
  protected cleanEmptyVariableValue(formula: string, isMath: boolean): string {
    try {
      if (isMath) {
        formula = this.global.ReplaceAll(formula, this.global.EMPTY_CHAR, '0');
        formula = this.global.ReplaceAll(formula, '"', '');
      } else {
        formula = this.global.ReplaceAll(formula, this.global.EMPTY_CHAR, '0'); // Config.instance.NOITEM_TEXT);
      }
    } catch (error) {
      this.log.Registrar('CalculadoraRoot', 'cleanEmptyVariableValue', error.message);
    }
    return formula;
  }

  /*Calcula a visibilidade condicional de um controle */
  isVisibleCtrl(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      let isVisible = false;
      if (
        !this.global.IsNullOrEmpty(ctrl.ConditionVisible) &&
        this.wasChanged(ctrl.hashLstControlesReferenciadosVisibility, ctrl.lstControlesReferenciadosVisibility)
      ) {
        ctrl.hashLstControlesReferenciadosVisibility = this.getHash(ctrl.lstControlesReferenciadosVisibility);
        isVisible = this.calculateSimple(ctrl, ctrl.lstControlesReferenciadosVisibility, ctrl.ConditionVisible);
      } else {
        isVisible = ctrl.IsVisible;
      }
      // Visibilidade conforme o posicionamento na tela (para remover a área de controles de cálculo)
      isVisible = isVisible && !this.lib.isOutsideDisplayArea(ctrl);
      return isVisible;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isVisibleCtrl', error.message);
    }
    return false;
  }

  /*Calcula a ativação/somente leitura condicional de um controle.
   * Se o controle não estiver definido, retornará false, para que não interfira na proteção do controle nesse caso.
   */
  isReadOnly(ctrl: IAtividadeComponenteDAL): boolean {
    let isReadOnly = false;
    if (!ctrl) {
      return false;
    }

    try {
      if (
        !this.global.IsNullOrEmpty(ctrl.ConditionReadOnly) &&
        this.wasChanged(ctrl.hashLstControlesReferenciadosReadOnly, ctrl.lstControlesReferenciadosReadOnly)
      ) {
        ctrl.hashLstControlesReferenciadosReadOnly = this.getHash(ctrl.lstControlesReferenciadosReadOnly);
        isReadOnly = !this.calculateSimple(
          ctrl,
          ctrl.lstControlesReferenciadosReadOnly,
          ctrl.ConditionReadOnly
        );
      } else {
        isReadOnly = !ctrl.IsEnable;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isReadOnly', error.message);
    }
    return isReadOnly;
  }

  /*override
   * Para ser sobrescrito pela calculadora de condição que precisa validar se é uma condição */
  protected isValid(supostaFormula: string): boolean {
    try {
      return this.lib.isCondition(supostaFormula);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isValid', error.message);
    }
    return false;
  }
}
