import { routeGetRawMedication, IIntervecoesMedicamentosHorarios } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { LogService, GlobalService, } from '@medlogic/shared/shared-interfaces';
import { HttpClient } from '@angular/common/http';
import { IntervencoesMedicamentosHorariosService } from './intervecoes-medicamentos-horario.service';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { ExecucaoTarefaDAL } from '@medlogic/shared/geform';
import { mergeMap, reduce } from 'rxjs/operators';

@Injectable()
export class IntervencoesMedicamentosHorariosCustomService extends IntervencoesMedicamentosHorariosService {

  urlGetRawMedication = routeGetRawMedication;

  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnf: ConfigStateService,
    log: LogService,
    private taskSrv: ExecucaoTarefaDAL
  ) {
    super(http, cadastroSrv, glb, cnf, log);
  }

  /* Retorna todos os itens do histórico de compras através do id do item. */
  getByCodigoMedicationPaciente(ano: number, codigoMedicamentoPaciente: string, dtStart: Date = null, dtEnd: Date = null): Observable<IIntervecoesMedicamentosHorarios> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_30401:${codigoMedicamentoPaciente}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCodigoMedication', error.message);
    }
    return of(null);
  }

  /* Executará todos os deletes em sequencia e só retornará true se todos forem bem sucedidos. */
  deleteAllBy(ano: number, patientId: number, medicationId: number): Observable<boolean> {
    try {
      this.cadastroNo = ano;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const filter = `V_29828:${patientId},V_30263:${medicationId}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate)
        .pipe(
          mergeMap(horario => this.deleteByOno(ano, horario.ocorrenciaNo || horario.codigo)),
          reduce((acc, value, index) => acc && value, true)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteAllByPrescription', error.message);
    }
    return of(null);
  }

  deleteByOno(cno: number, ono: number): Observable<boolean> {
    try {
      return this.taskSrv.deleteCadastro(ono, cno);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteByOno', error.message);
    }
    return of(null);
  }


}
