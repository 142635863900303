import { CalculadoraConditionService } from '../service/calculadora-condition.service';
import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { GlobalService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';



// NÃO ESTÁ SENDO UTILIZADO. AO INVÉS DISSO, APLICADA A DIRETIVA NATIVA DO ANGULAR READONLY DIRETAMENTE.
// ATENÇÃO: A POSIÇÃO QUE A DIRETIVA É APLICADA NO HTML REPRESENTA A ORDEM QUE SERÁ PROCESSADA.
// NESSE CASO, DEVE SER APLICADA SOMENTE APÓS O CALCULO DO VALOR, SENÃO, TENTA PEGAR A FÓRMULA DA CONDIÇÃO, POIS, NÃO FOI AINDA PROCESSADA.
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[libCtrlIsReadOnly]'
})
export class CtrlIsReadOnlyDirective {


  // tslint:disable-next-line: variable-name
  private _ctrl: any;
  @Input('libCtrlIsReadOnly') public get ctrl(): any {
    return this._ctrl;
  }

  public set ctrl(v: any) {
    this._ctrl = v;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private global: GlobalService,
    private calc: CalculadoraConditionService,
    private log: LogService) {
  }

  // Para resolver o problema do - na atualização, procurar um evento que não seja o Init
  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {
  }

  // Como ctrl não é alterado e sim um valor da propriedade, o angular considera que não houve mudança
  // portanto, é necessário criar uma rotina de checagem da mudança personalizada/
  // Esse método é chamado sempre. Depois investigar métodos mais eficientes, pois, será chamado todas as vezes para todos os controles
  // Uma alternativa seria fazer com que o ctrl fosse associado a outro objeto, toda vez que houvesse o OnChanges
  // Nesse caso, seria possível capturar o ngOnChanges apenas o controle que mudou
  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewChecked(): void { // ngDoCheck
    this.applyStyles(this.ctrl);
  }

  applyStyles(ctrl: IAtividadeComponenteDAL): void {
    try {
      // if (
      // 	this.calc.wasChanged(
      // 		ctrl.hashLstControlesReferenciadosReadOnly,
      // 		ctrl.lstControlesReferenciadosReadOnly
      // 	)
      // ) {
      //   ctrl.hashLstControlesReferenciadosReadOnly = this.calc.getHash(ctrl.lstControlesReferenciadosReadOnly);
      // TODO: A lógica foi transferida para o calculadora-condition para que sequer tente recalcular a fórmula
      this.applyIsReadOnly(ctrl);
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyStyles', error.message);
    }
  }

  protected applyIsReadOnly(ctrl: IAtividadeComponenteDAL): void {
    try {
      let isReadOnly = false;
      if (!this.global.IsNullOrEmpty(ctrl.ConditionReadOnly)) {
        isReadOnly = !this.calc.calculate(ctrl, ctrl.lstControlesReferenciadosReadOnly, ctrl.ConditionReadOnly);
      } else {
        isReadOnly = !ctrl.IsEnable;
      }
      if (isReadOnly) {
        this.renderer.setAttribute(this.el.nativeElement, 'readonly', 'true');
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'readonly', null);
        this.renderer.setStyle(this.el.nativeElement, 'background-color', '#FF0000');
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyIsVisible', error.message);
    }
  }
}
