import { ICarePlan } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setCarePlan = createAction(
  '[CarePlan/API] Set CarePlan',
  props<{ selectedId: number }>()
);
export const loadCarePlans = createAction(
  '[CarePlan/API] Load CarePlans'
);

export const loadCarePlansSuccess = createAction(
  '[CarePlan/API] Load CarePlans Success',
  props<{ carePlans: ICarePlan[] }>()
);

export const carePlanFail = createAction(
  '[CarePlan/API] CarePlan Fail',
  props<{ error: string }>()
);

export const addCarePlan = createAction(
  '[CarePlan/API] Add CarePlan',
  props<{ carePlan: ICarePlan }>()
);

export const addCarePlans = createAction(
  '[CarePlan/API] Add CarePlans',
  props<{ carePlans: ICarePlan[] }>()
);

export const upsertCarePlan = createAction(
  '[CarePlan/API] Upsert CarePlan',
  props<{ carePlan: ICarePlan }>()
);

export const upsertCarePlans = createAction(
  '[CarePlan/API] Upsert CarePlans',
  props<{ carePlans: ICarePlan[] }>()
);

export const updateCarePlan = createAction(
  '[CarePlan/API] Update CarePlan',
  props<{ carePlan: Update<ICarePlan> }>()
);

export const updateCarePlans = createAction(
  '[CarePlan/API] Update CarePlans',
  props<{ carePlans: Update<ICarePlan>[] }>()
);

export const deleteCarePlan = createAction(
  '[CarePlan/API] Delete CarePlan',
  props<{ id: string }>()
);

export const deleteCarePlans = createAction(
  '[CarePlan/API] Delete CarePlans',
  props<{ ids: string[] }>()
);

export const clearCarePlans = createAction(
  '[CarePlan/API] Clear CarePlans'
);
export const clearSelectedCarePlan = createAction(
  '[Card CarePlan] Clear Selected CarePlans'
);
