
import { IIntervecoesMedicamentosHorarios } from '@medlogic/shared/shared-interfaces';
import { EntityState } from '@ngrx/entity';



export interface IHorariosState extends EntityState<IIntervecoesMedicamentosHorarios> {
    // additional entities state properties
    error: any;
    selectedId: number | null;
    selectedHorario: IIntervecoesMedicamentosHorarios;
    allHorarios: IIntervecoesMedicamentosHorarios[];
}
