import { metaReducers } from './+state/storage.metareducer';
import { EvolutionCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EvolutionEffects } from './+state/evolution.effects';
import { StateMedlogicTenantModule } from '../medlogic-state-tenant/medlogic-state-tenant.module';
import * as fromEvolution from './+state/evolution.reducer';

const SERVICES = [
  EvolutionCustomService
];

const MODULES = [
  CommonModule,
  // TODO: mover para shared
  StateMedlogicTenantModule
];

const STATE_MODULES = [
  StoreModule.forFeature(fromEvolution.evolutionsFeatureKey, fromEvolution.reducer),
  EffectsModule.forFeature([EvolutionEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})
export class StateEvolutionModule { }
