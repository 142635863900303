import { Injectable } from '@angular/core';
import { EnStockStatus } from '@medlogic/shared/shared-interfaces';
import { IHistoricoCompraEstoque } from '@medlogic/shared/shared-interfaces';
import { LibService } from '@medlogic/shared/geform';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { IEstoqueMateriais } from '@medlogic/shared/shared-interfaces';
import { IMedication } from '@medlogic/shared/shared-interfaces';
import { GlobalService, LogService, IBasic, IColumn, EnTypedValue } from '@medlogic/shared/shared-interfaces';

import * as processors from 'xml2js/lib/processors';
import * as xml2js from 'xml2js';
@Injectable()
export class LibErpService {

  DateDiff = {
    inDays: (d1: Date, d2: Date) => {
      if (!this.global.isValidDate(d1) || !this.global.isValidDate(d2)) {
        return 0;
      }

      const t2 = d2.getTime();
      const t1 = d1.getTime();
      const result = (t2 - t1) / (24 * 3600 * 1000);
      return result === -0 ? -result : result; // Não irá arredondar, mas descartar o inteiro.
    },
    inWeeks: (d1, d2) => {
      const t2 = d2.getTime();
      const t1 = d1.getTime();
      const result = Math.trunc((t2 - t1) / (24 * 3600 * 1000 * 7));
      return result === -0 ? -result : result; // Não irá arredondar, mas descartar o inteiro.
    },
    inMonths: (d1, d2) => {
      const d1Y = d1.getFullYear();
      const d2Y = d2.getFullYear();
      const d1M = d1.getMonth();
      const d2M = d2.getMonth();
      return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    },
    inYears: (d1, d2) => {
      return d2.getFullYear() - d1.getFullYear();
    }
  };

  private replace: any[] = [
    { de: 'Á', para: 'A' },
    { de: 'É', para: 'E' },
    { de: 'Í', para: 'I' },
    { de: 'Ó', para: 'O' },
    { de: 'Ú', para: 'U' },
    { de: 'À', para: 'A' },
    { de: 'È', para: 'E' },
    { de: 'Ì', para: 'I' },
    { de: 'Ò', para: 'O' },
    { de: 'Ù', para: 'U' },
    { de: 'Ä', para: 'A' },
    { de: 'Ë', para: 'E' },
    { de: 'Ï', para: 'I' },
    { de: 'Ö', para: 'O' },
    { de: 'Ü', para: 'U' },
    { de: 'Ã', para: 'A' },
    { de: 'Õ', para: 'O' },
    { de: 'Â', para: 'A' },
    { de: 'Ê', para: 'E' },
    { de: 'Î', para: 'I' },
    { de: 'Ô', para: 'O' },
    { de: 'Û', para: 'U' },
    { de: 'Ç', para: 'C' }
  ];

  constructor(private global: GlobalService, private log: LogService, private lib: LibService) { }

  addMonthsUTC(date: Date, count: number) {
    try {
      // return date
      if (!date) {
        return null;
      }
      const returnDate = new Date(date.valueOf());
      return new Date(returnDate.getFullYear(), returnDate.getMonth() + +count, returnDate.getDate());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addMonthsUTC', error.message);
    }
  }

  addYearsUTC(date: Date, count: number) {
    try {
      if (!date) {
        return null;
      }
      const returnDate = new Date(date.valueOf());
      return new Date(returnDate.getFullYear() + +count, returnDate.getMonth(), returnDate.getDate());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addMonthsUTC', error.message);
    }
  }

  addDays(date: Date, days: number): Date | null {
    try {
      if (!date) {
        return null;
      }
      if (!this.global.IsDate(date)) {
        return null;
      }
      const returnDate = new Date(date.valueOf());
      returnDate.setDate(returnDate.getDate() + +days);
      return returnDate;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addDays', error.message);
    }
    return null;
  }

  isBetween(dt: any, dtStart: any, dtEnd: any): boolean {
    try {
      return new Date(dt) >= new Date(dtStart) && new Date(dt) <= new Date(dtEnd);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isBetween', error.message);
    }
  }

  getMonthName(date: Date, locale: string = 'pt-BR'): string {
    try {
      const month = date.toLocaleString(locale, { month: 'short' });
      return month;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMonthName', error.message);
    }
    return null;
  }

  getMonthYear(dt: Date, locale: string = 'pt-BR'): string {
    try {
      if (!dt) {
        return null;
      }
      if (!this.global.IsDate(dt)) {
        return null;
      }
      const date: Date = new Date(dt.valueOf());
      return this.getMonthName(date, locale) + '/' + date.getFullYear();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMonthYear', error.message);
    }
    return null;
  }

  getDayMonth(dt: any, locale: string = 'pt-BR'): string {
    try {
      const date: Date = new Date(dt);
      const day: string = '0' + date.getDate();
      return this.getRight(day, 2) + '/' + this.getMonthName(date, locale);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDayMonth', error.message);
    }
  }

  getRight(str: string, num: number): string {
    try {
      return str.slice(-num);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRight', error.message);
    }
  }

  getLeft(str: string, num: number): string {
    try {
      return str.slice(0, num);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLeft', error.message);
    }
  }

  getGUID(): string {
    try {
      const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      };
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getGUID', error.message);
    }
  }

  /* Verifica se a data fornecida esta no mês/ano atual. */
  isInCurrentMonth(date: Date): boolean {
    try {
      if (!date) {
        return null;
      }
      if (!this.global.IsDate(date)) {
        return null;
      }
      const dt: Date = new Date(date.valueOf());
      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear();
      return dt.getMonth() === month && dt.getFullYear() === year;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isInCurrentMonth', error.message);
    }
  }

  /* Atenção: Janeiro = 0. */
  getLastDayOfMonth(year: number, month: number): Date | null {
    try {
      // O parâmetro +1 fará com que pegue o próximo mês e o dia zero o último dia do mês anterior
      const dt: Date = new Date(year, month + 1, 0);
      return dt;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLastDayOfMonth', error.message);
    }
  }

  getDaysOfMonth(year: number, month: number): IBasic[] {
    try {
      const days: IBasic[] = [];
      const dt: Date = this.getLastDayOfMonth(year, month);
      const lastDay = dt.getDate();
      for (let i = 1; i <= lastDay; i++) {
        days.push({ id: i, name: i.toString() });
      }
      return days;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDaysOfMonth', error.message);
    }
  }

  getDaysMonthOfMonth(year: number, month: number): IBasic[] {
    try {
      const days: IBasic[] = [];
      const dt: Date = this.getLastDayOfMonth(year, month);
      const lastDay = dt.getDate();
      for (let i = 1; i <= lastDay; i++) {
        const monthName: string = this.getMonthName(dt);
        days.push({ id: i, name: this.getRight('0' + i, 2) + '/' + monthName });
      }
      return days;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDaysMonthOfMonth', error.message);
    }
  }

  getMonthNames(locale: string = 'pt-BR'): IBasic[] {
    try {
      const months: IBasic[] = [];
      for (let i = 0; i <= 11; i++) {
        const date = new Date(2016, i, 1);
        months.push({ id: i, name: this.getMonthName(date, locale) });
      }
      return months;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMonthNames', error.message);
    }
  }

  dateToYYYYMMddThhmmss(strDate: any): string {
    try {
      const sep = '-';
      const sepH = ':';
      const dt: Date = new Date(strDate);
      return (
        dt.getFullYear() +
        sep +
        (dt.getMonth() + 1) +
        sep +
        dt.getDate() +
        'T' +
        dt.getHours() +
        sepH +
        dt.getMinutes() +
        sepH +
        dt.getSeconds()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'dateToYYYYMMddThhmmss', error.message);
    }
  }

  dateToddMMYYYY(strDate: any): string {
    try {
      if (strDate === undefined || strDate === '' || strDate === null) {
        return '';
      }
      const sep = '/';
      const dt: Date = new Date(strDate);
      return this.addL0(dt.getDate()) + sep + this.addL0(dt.getMonth() + 1) + sep + dt.getFullYear();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'dateToddMMYYYY', error.message);
    }
  }

  dateToYYYYMMdd(strDate: any, sep: string = '/'): string {
    try {
      if (strDate === undefined || strDate === '' || strDate === null) {
        return '';
      }
      const dt: Date = new Date(strDate);
      return dt.getFullYear() + sep + this.addL0(dt.getMonth() + 1) + sep + this.addL0(dt.getDate());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'dateToYYYYMMdd', error.message);
    }
  }

  /* Adiciona um zero a esquerda. */
  addL0(str: any, numOfDig: number = 2): string {
    try {
      if (typeof str !== 'string') {
        str = str.toString();
      }
      return this.getRight('0' + str, 2);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addL0', error.message);
    }
  }

  YYYYMMddThhmmssToDate(dt: string): Date | null {
    try {
      const sep = '-';
      const sepH = ':';
      const year: number = parseFloat(dt.substr(0, 4));
      const month: number = parseFloat(dt.substr(5, 2)) - 1;
      const day: number = parseFloat(dt.substr(8, 2));
      const h: number = parseFloat(dt.substr(11, 2));
      const min: number = parseFloat(dt.substr(14, 2));
      const sec: number = parseFloat(dt.substr(17, 2));
      return new Date(year, month, day, h, min, sec);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'YYYYMMddThhmmssToDate', error.message);
    }
  }

  ddMMYYYYThhmmssToDate(dt: string): Date | null {
    try {
      if (!dt) {
        return null;
      }
      if (dt === '') {
        return null;
      }
      if (dt === undefined) {
        return null;
      }
      if (dt.substr === undefined) {
        return null;
      }
      const sep = '-';
      const sepH = ':';
      const day: number = parseFloat(dt.substr(0, 2));
      const month: number = parseFloat(dt.substr(3, 2)) - 1;
      const year: number = parseFloat(dt.substr(6, 4));
      if (dt.length <= 10) {
        // Somente data
        return new Date(year, month, day);
      } else {
        // Data e hora
        const h: number = parseFloat(dt.substr(11, 2));
        const min: number = parseFloat(dt.substr(14, 2));
        const sec: number = parseFloat(dt.substr(17, 2));
        return new Date(year, month, day, h, min, sec);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ddMMYYYYThhmmssToDate', error.message);
    }
  }

  /*Compara se duas datas são iguais, ignorando data e hora.
     * Se uma das datas for inválida, retornará false.
     */
  isSameDate(date1: Date, date2: Date): boolean {
    try {
      if (!this.global.isValidDate(date1) || !this.global.isValidDate(date2)) {
        return false;
      }

      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isSameDate', error.message);
    }
  }

  /*Checa se é uma data válida
    // deve ser um campo no formato data
    // ou uma string do tipo dd/mm/yyyy */
  IsDate(_supostaData: any): boolean {
    try {
      if (this.isNullOrEmpty(_supostaData)) {
        return false;
      }
      if (typeof _supostaData.getMonth === 'function') {
        return true;
      } else {
        const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
        const dt = new Date(_supostaData.replace(pattern, '$3-$2-$1'));
        return this.FormatarData(dt) === _supostaData;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'IsDate', error.message);
    }
    return false;
  }

  /* Além de formatar no padrão dd/mm/yyyy corrige o mês que, no js é baseado em zero. */
  FormatarData(_data: Date): string {
    try {
      // const _strMes: string = parseInt((_data.getMonth() + 1).toString(), 10).toString();
      // const _zeroMes: string = _strMes.length === 1 ? '0' : '';
      // const _strDia: string = _data.getDate().toString();
      // const _zeroDia: string = _strDia.length === 1 ? '0' : '';
      // return _zeroDia + _strDia + '/' + _zeroMes + _strMes + '/' + _data.getFullYear();
      const typed = this.global.getTypedValue(_data);
      return typed.string;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'FormatarData', error.message);
    }
  }

  /*Converte número em português para padrão computacional
    //Se não for possível converter, retorna default. */
  convertNumber(str: any, defaultValue: number = 0): number {
    let num = defaultValue;
    try {
      if (!this.global.isNullOrEmpty(str)) {
        const typed = this.global.getTypedValue(str);
        num = typed.value;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertNumber', error.message);
    }
    return isNaN(num) ? defaultValue : num;
  }

  // Verifica se o valor é SIM, true, 1, string, ou booleano
  convertBoolean(str: any): boolean {
    try {
      if (typeof str === 'boolean') {
        return str;
      }
      if (typeof str === 'number') {
        return str <= 0 ? false : true;
      }
      if (typeof str === 'string') {
        if (str.toUpperCase() === 'SIM' || str.toUpperCase() === 'TRUE' || str.toUpperCase() === 'VERDADEIRO') {
          return true;
        }
      }
      return false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertBoolean', error.message);
    }
  }

  isEqual(str1: string, str2: string): boolean {
    try {
      return this.tratar(str1) === this.tratar(str2);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isEqual', error.message);
    }
  }

  contem(str1: string, str2: string): boolean {
    try {
      return this.tratar(str1).indexOf(this.tratar(str2)) >= 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'contem', error.message);
    }
  }

  isNullOrEmpty(str: any): boolean {
    try {
      if (str === undefined) {
        return true;
      }
      if (str === null) {
        return true;
      }
      if (str.toString() === '') {
        return true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isNullOrEmpty', error.message);
    }
    return false;
  }

  /* Utilizar esse método dos dois lados da expressão de comparação para ficar caseinsensitive e desconsiderar acentos */
  tratar(str: string): string {
    try {
      if (!str) {
        return str;
      }
      str = str.toLocaleUpperCase();
      for (const obj of this.replace) {
        str = str.replace(obj.de, obj.para);
      }
      return str;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'tratar', error.message);
    }
    return '';
  }

  // MÉTODOS ESPECíFICOS AO PROJETO *********************

  // getEnClassType(enClass: string): EnClassType {
  //   try {
  //     switch (enClass) {
  //       default:
  //         return EnClassType.None;

  //       case EnClass[EnClass.Insumos].toUpperCase():
  //       case EnClass[EnClass.ComissaoMarketingVendas].toUpperCase():
  //       case EnClass[EnClass.ReceitaAvulsa].toUpperCase(): // Exibição também das receitas de natureza variável, para permitir comparação do saldo RV - CV
  //         return EnClassType.Variavel;

  //       case EnClass[EnClass.Compras].toUpperCase():
  //       case EnClass[EnClass.DespesasRecorrentes].toUpperCase():
  //       case EnClass[EnClass.Folha].toUpperCase():
  //       case EnClass[EnClass.Depreciacao].toUpperCase():
  //       case EnClass[EnClass.ReceitaRecorrente].toUpperCase(): // Exibição também das receitas de natureza fixa, para permitir comparação do saldo RF - CF
  //         return EnClassType.Recorrente;

  //       case EnClass[EnClass.Aplicacoes].toUpperCase():
  //       case EnClass[EnClass.Investimento].toUpperCase():
  //         return EnClassType.ReInvestimento;

  //       case EnClass[EnClass.ImpostosEncargos].toUpperCase():
  //         return EnClassType.Imposto;

  //       case EnClass[EnClass.AporteSocios].toUpperCase():
  //         return EnClassType.Aporte;

  //       case EnClass[EnClass.ProlaboreDistribuicaoLucros].toUpperCase():
  //         return EnClassType.Lucro;

  //       case EnClass[EnClass.ContratacaoEmprestimo].toUpperCase():
  //       case EnClass[EnClass.PgtoEmprestimos].toUpperCase():
  //       case EnClass[EnClass.PgtoJuros].toUpperCase():
  //         return EnClassType.Divida;
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getEnClassType', error.message);
  //   }
  // }

  /* Retorna uma string com o nome de coluna que pode ser MES/ANO, ANO ou DIA/MES. */
  getClm(effectiveDate, periodTypeId): string {
    try {
      let column = '';
      switch (periodTypeId) {
        default:
        case 1:
          column = this.getDayMonth(effectiveDate);
          break;
        case 2:
          column = this.getMonthYear(effectiveDate);
          break;
        case 3:
          column = new Date(effectiveDate).getFullYear().toString();
          break;
      }
      return column;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getClm', error.message);
    }
  }

  // Retorna "-" se não informado
  getNaoInformadoMask(str: string): string {
    try {
      return this.isGEEmpty(str) ? '-' : str;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getNaoInformadoMask', error.message);
    }
  }

  isGEEmpty(str: string): boolean {
    try {
      if (!str) {
        return true;
      }

      return (
        str.toUpperCase() === 'NÃO INFORMADO' ||
        str === '' ||
        str.toUpperCase() === '#VAZIO#' ||
        str === undefined
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isGEEmpty', error.message);
    }
  }

  /*Retorna um status com base na comparação entre data de vencimento e pagamento.
     * Se a data de vencimento for inválida, retornará None.
     */
  // getStatus(isPending: boolean, dtPayment: Date, dtDue: Date): EnStatus {
  //   try {
  //     if (!this.global.isValidDate(dtDue)) {
  //       return EnStatus.None;
  //     }

  //     const dtToday: Date = new Date();
  //     if (isPending) {
  //       if (!dtDue) {
  //         return EnStatus.AVencer;
  //       }
  //       if (this.DateDiff.inDays(dtToday, dtDue) >= 0) {
  //         return EnStatus.AVencer;
  //       } else {
  //         return EnStatus.Atrasado;
  //       }
  //     } else if (dtPayment && dtDue && this.DateDiff.inDays(dtDue, dtPayment) > 0) {
  //       return EnStatus.PagoEmAtraso;
  //     } else {
  //       return EnStatus.Pago;
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getStatus', error.message);
  //   }
  //   return EnStatus.None;
  // }


  /* Método genérico para lidar com os diferentes cenários de soma. */
  // protected executeSUM(entries: Array<IEntry>, bunitName: string, monthId: any, yearId: any, datePeriod: IDatePeriod, filter: { (value: IEntry, index: number, array: IEntry[]): any }): number {
  //   try {
  //     if (!entries || !(entries.length > 0)) {
  //       return 0;
  //     }
  //     const filtered = entries.filter(filter);
  //     const filteredMapped = filtered.map((n) => this.getSharedValue(n, bunitName));
  //     return filteredMapped.reduce((a, b, index, array) => a + b, 0);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'executeSUM', error.message);
  //   }
  // }

  /* Checa se o período está completamente preenchido. */
  // periodIsValid(datePeriod: IDatePeriod): boolean {
  //   try {
  //     return datePeriod && datePeriod.begin && datePeriod.begin.isValid() && datePeriod.end && datePeriod.end.isValid();
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'periodIsValid', error.message);
  //   }
  //   return false;
  // }

  /* Calcula o saldo total acumulado, do período anterior ao filtro atual, considerando apenas os lançamentos pagos.
   * Transporte de saldo.
   */
  // getSUMBalanceTransportation(entries: Array<IEntry>, bunitName: string, monthId: any, yearId: any, datePeriod: IDatePeriod): number {
  //   try {
  //     if (((+yearId < 0 || +monthId < 0) && !this.periodIsValid(datePeriod)) || !entries) {
  //       return 0;
  //     }
  //     let lastDayOfPreviousMonth: Date;
  //     if (this.periodIsValid(datePeriod)) {
  //       // Necessário clonar, ou a biblioteca moment irá mutar o objeto e cada vez que subtract for chamado, irá decrementar um mês.
  //       const endDate = datePeriod.end.clone();
  //       lastDayOfPreviousMonth = endDate.subtract(1, 'months').endOf('month').toDate();
  //     } else {
  //       lastDayOfPreviousMonth = new Date(yearId, monthId, 0); // O dia zero, faz com que seja resgatada a o último dia do mês anterior
  //     }
  //     return this.executeSUM(entries, bunitName, monthId, yearId, datePeriod, this.filterSUMBalanceTransportation(bunitName, lastDayOfPreviousMonth));
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getSUMBalanceTransportation', error.message);
  //   }
  // }

  // isValidDate(d: any) {
  //   return d instanceof Date && !isNaN(d);
  // }

  /* Faz uma checagem de uma data em relação aos períodos ou definição de mês e ano.
    */
  // checkPeriod(dtToCheck: Date, monthId: number, yearId: number, datePeriod: IDatePeriod): boolean {
  //   try {
  //     if (this.periodIsValid(datePeriod)) {
  //       return (
  //         dtToCheck && this.IsDate(dtToCheck) &&
  //         (dtToCheck >= datePeriod.begin.toDate() || !datePeriod.begin) &&
  //         (dtToCheck <= datePeriod.end.toDate() || !datePeriod.end)
  //       );
  //     } else {
  //       return (
  //         dtToCheck && this.IsDate(dtToCheck) &&
  //         ((dtToCheck.getMonth() === +monthId) || +monthId === -1) &&
  //         ((dtToCheck.getFullYear() === +yearId) || +yearId === -1)
  //       )
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'checkPeriod', error.message);
  //   }
  //   return false;
  // }

  // filterSUMBalanceTransportation = (bunitName: string, lastDayOfPreviousMonth: Date) => (obsEntries: Observable<IEntry>) => {
  //   try {
  //     return obsEntries.pipe(
  //       filter((entry: any) => {
  //         return this.filterRateio(entry, bunitName) &&
  //           !entry.isPending &&
  //           !entry.isRecurrent &&
  //           this.global.isValidDate(entry.datePayment) &&
  //           entry.datePayment <= lastDayOfPreviousMonth;
  //       })
  //     );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getSUMBalance.filter', error.message);
  //   }
  //   return of(null);
  // }

  // protected filterSUMDespesas = (bunitName: string, includeForecast: boolean, monthId: number, yearId: number, datePeriod: IDatePeriod) => (entry: IEntry) => {
  //   try {
  //     return (
  //       this.checkPeriod(entry.dateDue, monthId, yearId, datePeriod) &&
  //       entry.value < 0 &&
  //       this.filterRateio(entry, bunitName) &&
  //       this.filterForecast(entry, includeForecast)
  //     );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'filterSUMDespesas', error.message);
  //   }
  //   return false;
  // }

  // protected filterSUMReceitas = (bunitName, includeForecast, monthId: number, yearId: number, datePeriod: IDatePeriod) => (entry) => {
  //   try {
  //     return (
  //       this.checkPeriod(entry.dateDue, monthId, yearId, datePeriod) &&
  //       entry.value > 0 &&
  //       this.filterRateio(entry, bunitName) &&
  //       this.filterForecast(entry, includeForecast)
  //     );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'filterSUMREceitas', error.message);
  //   }
  //   return false;
  // }

  // protected filterSUMBalanceTransportation = (bunitName, lastDayOfPreviousMonth) => (entry) => {
  //   try {
  //     return (
  //       this.filterRateio(entry, bunitName) &&
  //       !entry.isPending &&
  //       !entry.isRecurrent &&
  //       entry.datePayment <= lastDayOfPreviousMonth
  //     );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getSUMBalance.filter', error.message);
  //   }
  //   return false;
  // }

  // getSUMDespesas(entries: Array<IEntry>, bunitName: string, includeForecast: boolean, monthId: number, yearId: number, datePeriod: IDatePeriod): number {
  //   try {
  //     return this.executeSUM(entries, bunitName, monthId, yearId, datePeriod, this.filterSUMDespesas(bunitName, includeForecast, monthId, yearId, datePeriod))
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getSUMDespesas', error.message);
  //   }
  // }

  // getSUMReceitas(entries: Array<IEntry>, bunitName: string, includeForecast: boolean, monthId: number, yearId: number, datePeriod: IDatePeriod): number {
  //   try {
  //     return this.executeSUM(entries, bunitName, monthId, yearId, datePeriod, this.filterSUMReceitas(bunitName, includeForecast, monthId, yearId, datePeriod))
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getSUMReceitas', error.message);
  //   }
  // }

  ///* Retorna somente o valor referente a parcela do rateio, caso rateado, o valor. */
  // protected getSharedValue(entry: IEntry, bunitName: string): number {
  //   try {
  //     if (entry.valuesByBunits && entry.valuesByBunits.length > 0) {
  //       const find = entry.valuesByBunits.find((f) => f.bunit === bunitName);
  //       if (find) {
  //         return this.extractNumberWithoutSimbols(find.value);
  //       }
  //     }
  //     return this.global.ConvertToAmericanNumber(entry.value, 2);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getSharedValue', error.message);
  //   }
  // }

  /* Utilizado nos indicadores para retornar a soma total de lançamentos. */
  // getSUM(entries: Array<IEntry>, bunitName: string, includeForecast: boolean): number {
  //   try {
  //     if (!entries) {
  //       return 0;
  //     }

  //     return entries
  //       .filter((entry) => this.filterRateio(entry, bunitName) && this.filterForecast(entry, includeForecast))
  //       .map((n) => this.getSharedValue(n, bunitName))
  //       .reduce((a, b, index, array) => a + b, 0);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getSUM', error.message);
  //   }
  // }

  /* Utilizado nos indicadores para retornar apenas os custos relacionados a folha de pagamento. */
  // getSUMPayRoll(entries: Array<IEntry>, bunitName: string): number {
  //   try {
  //     if (!entries) {
  //       return 0;
  //     }

  //     return entries
  //       .filter(
  //         (entry) =>
  //           this.isEqual(entry.enClass, EnClass[EnClass.Folha]) && this.filterRateio(entry, bunitName)
  //       )
  //       .map((n) => n.value)
  //       .reduce((a, b, index, array) => a + b, 0);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getSUMPayRoll', error.message);
  //   }
  // }


  /* Converte para padrão americano e também remove o símbolos de moeda e percentual. */
  protected extractNumberWithoutSimbols(strValue: any): number {
    try {
      if (!strValue) {
        return 0;
      }
      let value = strValue.toString().replace(/[^.\d-]/g, '');
      value = this.ConvertToAmericanNumber(value);
      return value;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractNumberWithoutSimbols', error.message);
    }
  }

  /*Retornará true se:
     * 1) Não é rateio e é da unidade de negócio desejada: entry.businessUnit.name === bunitName
     * 1.1) O "0" também retornará true, pois, ocorre no detalhamento de um item do fluxo de caixa com a unidade TODAS selecionada.
     * 2) É rateio e existe uma parcela associada a unidade de negócio desejada.
     * 3) bunitName é vazio para representar o total. Nesse caso, deve retornar true.
     * O objetivo é que a Unidade de Negócio RATEIO não entre na totalização.
     * No entanto, as despesas rateadas são definidas como rateio e a alocação resolvida no grid.
     */
  // filterRateio(entry: IEntry, bunitName: string): boolean {
  //   try {
  //     if (this.global.isNullOrEmpty(bunitName) || bunitName === '0') {
  //       // 3) Totalização. Rateio será mantido pois o cálculo do total trará o valor rateado.
  //       return true;
  //     } else if (this.global.isEqual(entry.businessUnit.name, bunitName)) {
  //       // 1) Não é rateio e é a unidade desejada.
  //       return true;
  //     } else if (this.global.isEqual(entry.businessUnit.name, 'RATEIO')) {
  //       // 2) É rateio e existe parcela associada a unidade.
  //       return entry.valuesByBunits.findIndex((f) => this.global.isEqual(f.bunit, bunitName)) >= 0;
  //     }
  //     return false;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'filterRateio', error.message);
  //   }
  // }

  /* Indica se as projeções serão incluídas no resultado ou se apenas os lançamentos confirmados como pagos. */
  // filterForecast(entry: IEntry, includeForecast: boolean): boolean {
  //   try {
  //     if (includeForecast) {
  //       return true;
  //     }
  //     return !entry.isPending && !entry.isRecurrent;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'filterForecast', error.message);
  //   }
  // }

  /*lastHistory deve ser o último item do histórico de compras do item.
     * Necessário, pois, se o último - ou seja, o mais recente - item tiver um número de pedido,
     * Significa que existe um pedido já realizado para o item.
     * // TODO: Será necessário atualizar esse item para verificar se já não foi dado entrada do pedido,
     * pois nesse caso o status deve considerar as condições originais.
     */
  getStockStatus(isMinimum: boolean, dtDue: Date, lastHistory: IHistoricoCompraEstoque): EnStockStatus {
    try {
      const dtToday: Date = new Date();
      const dtWarning: Date = !this.global.isNullOrEmpty(dtDue) ? this.addDays(dtDue, -7) : dtToday;
      if (!dtToday || !dtWarning) {
        return EnStockStatus.None;
      }

      if (!this.global.isNullOrEmpty(lastHistory) && !lastHistory.pedidoConferido && lastHistory.codigoPedido) {
        // Significa que há um pedido de compra ainda não conferido
        return EnStockStatus.Pedido;
      } else if (!isMinimum) {
        // Significa que não há um pedido de compra, ou que há, mas já foi conferido
        if (dtToday.getTime() >= dtWarning.getTime()) {
          return EnStockStatus.Comprar;
        } else {
          return EnStockStatus.EmEstoque;
        }
      } else {
        return EnStockStatus.Critico;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getStockStatus', error.message);
    }
    return EnStockStatus.None;
  }

  /*
  * Adiciona um tempo a uma data
  * @param datepart y adicionará anos, m meses, d dias
  * @param number
  * @param date
  * @return
  */
  DateAdd(datepart: string = '', number: number = 0, date: Date = null): Date | null {
    try {
      if (date == null) {
        /* Default to current date. */
        date = new Date();
      }
      const returnDate = new Date(date.valueOf());

      // trace("ReturnDate:"+returnDate);
      // trace("datePart:"+datepart);
      switch (this.RemoverAspas(datepart).toLowerCase()) {
        case 'y':
          returnDate.setFullYear(+returnDate.getFullYear() + +number);
          // returnDate["fullYear"] += number;
          break;
        case 'm':
          returnDate.setMonth(+returnDate.getMonth() + +number);
          // returnDate["month"] += number;
          break;
        case 'w':
          returnDate.setDate(+returnDate.getDate() + +number * 6);
          // returnDate["date"] += number * 6;
          break;
        case 'd':
          returnDate.setDate(+returnDate.getDate() + +number);
          break;
        default:
          /* Unknown date part, do nothing. */
          break;
      }
      return returnDate;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'DateAdd', error.message);
    }
  }

  /*
     * Se a string estiver entre aspas (início e fim) remove, senão retorna a própria string.
     * Não remove aspas no meio da string.
     * @param _str
     * @return
     */
  RemoverAspas(_str: string): string {
    try {
      if (this.ChecaAspas(_str)) {
        return this.RemoveLastChar(this.RemoveFirstChar(_str));
      } else {
        return _str;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'RemoverAspas', error.message);
    }
  }

  /*
  * Remove o último caracter de uma string (não altera a string apenas retorna outra sem o caracter)
  * @param _string
  * @return
  */
  RemoveLastChar(_string: string): string {
    try {
      return _string.substring(0, _string.length - 1);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'RemoveLastChar', error.message);
    }
  }

  /*@method RemoveFirstChar
     * Retorna uma string com o primeiro caracter da _string fornecida removido.
     */
  RemoveFirstChar(_string: string): string {
    try {
      return _string.substring(1);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'RemoveFirstChar', error.message);
    }
  }

  /* Retorna true se _str estiver entre aspas (aspas no primeiro e no último caracter) */
  ChecaAspas(_str: string): boolean {
    try {
      if (this.isNullOrEmpty(_str)) {
        return false;
      }
      _str = _str.toString();
      const _firstChar: string = _str.substr(0, 1);
      const _lastChar: string = _str.substr(_str.length - 1, 1);
      const _result: boolean = _firstChar === _lastChar && _firstChar === '"';
      return _result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ChecaAspas', error.message);
    }
  }

  Mensagem(_objContainer: any, _mensagem: string): void {
    try {
      console.log('Lib.Mensagem: ', _mensagem);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'Mensagem', error.message);
    }
  }

  /* retorna um id para o controle baseado na variavelNo */
  getId(id: number): string {
    try {
      return `V_${id}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getId', error.message);
    }
  }

  /* Retorna um valor booleano a partir da comparação de um valor do tipo 'SIM'. */
  getBoolean(value: string, yesValue: string = 'SIM'): boolean {
    try {
      return this.lib.getBoolean(value, yesValue);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getBoolean', error.message);
    }
  }

  /*
   * Tenta converter um número para o padrão americano. Tentará identificar se já está no padrão americano, baseado no número de casas decimais depois do ponto.
   * @param _number
   * @return
   *
   */
  ConvertToAmericanNumber(_number: string, _numDecimalPlacesIn: number = 2): number {
    try {
      if (this.IsAmericanNumber(_number, _numDecimalPlacesIn)) {
        // Significa que existe um único ponto (potencialmente já está no padrão americano). Se for não tem ponto e se for maior mais de um ponto
        return parseFloat(_number);
      }
      const _convertedNumber: number = parseFloat(_number.toString().replace('.', '').replace(',', '.'));
      return _convertedNumber;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ConvertToAmericanNumber', error.message);
    }
  }

  IsAmericanNumber(_number: string, _numDecimalPlaces: number = 2): boolean {
    try {
      if (this.isNullOrEmpty(_number)) {
        return false;
      }
      const _array: any[] = _number.toString().split('.');
      if (_array.length === 2) {
        // Significa que existe um único ponto (potencialmente já está no padrão americano). Se for não tem ponto e se for maior mais de um ponto
        // if (_array[1].length <= _numDecimalPlaces) { Essa validação não está boa, pois, é difícil estabelecer por antecedência o número de casas decimais de um número que não se sabe se está no padrão computacional.
        return true;
        // }
      }
      return false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'IsAmericanNumber', error.message);
    }
  }

  /*
   * Tenta converter um número para o padrão brasileiro. Tentará
   * identificar se já está no padrão americano, baseado no número de casas decimais depois do ponto.
   * @param _number
   * @param _numDecimalPlacesIn: número de casas decimais esperado
   * * (usado na comparação para determinar se está no padrão americano ou brasileiro) na entrada do dado
   * @param _numDecimalPlacesOut: Número de casas decimais com que o número será retornado
   * @return
   */
  ConvertToBrazilianNumber(
    _number: string,
    _numDecimalPlacesIn: number = 2,
    _numDecimalPlacesOut: number = 2
  ): string {
    try {
      if (!this.IsAmericanNumber(_number, _numDecimalPlacesIn)) {
        _number = this.ConvertToAmericanNumber(_number, _numDecimalPlacesIn).toString();
      }
      _number = Number(_number).toFixed(_numDecimalPlacesOut);
      const _convertedNumber: string = _number.toString().replace(',', '').replace('.', ',');
      return _convertedNumber;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ConvertToBrazilianNumber', error.message);
    }
  }

  /* Faz o processo inverso de getId: a partir do id retorna a VariavelNo */
  getVariavelNoFromId(id: string): number {
    try {
      return parseInt(id.toString().replace('V_', ''), 10);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getVariavelNoFromId', error.message);
    }
    return null;
  }

  /* Retorna as colunas que se deseja tornar visível pelo nome do header. */
  getVisibleColumns(visibleHeaders: string[]): IColumn[] {
    try {
      return this.getColumns().filter((f) =>
        visibleHeaders.map((m) => m.toUpperCase()).includes(f.header.toUpperCase())
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getVisibleColumns', error.message);
    }
    return null;
  }

  /* Gera as colunas, completas, dos lançamentos.  */
  getColumns(): IColumn[] {
    try {
      return [
        {
          header: 'Id',
          fieldName: 'id',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Pago?',
          fieldName: 'isPending',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Data Efetiva',
          fieldName: 'effectiveDate',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Vencimento',
          fieldName: 'dateDue',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Pagamento',
          fieldName: 'datePayment',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Status',
          fieldName: 'enStatus',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Categoria',
          fieldName: 'subCategory.name',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Centro Custo',
          fieldName: 'costCenter',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Descrição',
          fieldName: 'description',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Parcela',
          fieldName: 'numOfInstallment',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Cadastrado em',
          fieldName: 'dateRegistration',
          canSort: true,
          isVisible: false
        },
        {
          header: 'Banco',
          fieldName: 'bank.name',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Fonte',
          fieldName: 'source.name',
          canSort: true,
          cssClass: 'col-2',
          isVisible: true
        },
        {
          header: 'Beneficiário',
          fieldName: 'recipient',
          canSort: true,
          isVisible: true
        },
        {
          header: 'Observações',
          fieldName: 'observation',
          canSort: false,
          cssClass: 'col-3',
          isVisible: true
        },
        {
          header: 'Rateio',
          fieldName: 'bunitName',
          canSort: false,
          cssClass: 'col-3',
          isVisible: true
        },
        {
          header: 'Valor',
          fieldName: 'value',
          canSort: true,
          isVisible: true
        }
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getColumns', error.message);
    }
    return null;
  }

  /*
   * Transforma o valortexto que contenha um xml de grid para um objeto.
   */
  getFromValorTextoGrid(ValorTextoXML: string): Observable<any> {
    try {
      if (this.isNullOrEmpty(ValorTextoXML)) {
        return of(null);
      }

      ValorTextoXML = ValorTextoXML.replace('<![CDATA[', '').replace(']]>', '');
      return new Observable((observer) => {
        xml2js.parseString(
          ValorTextoXML,
          {
            explicitArray: true,
            tagNameProcessors: [processors.stripPrefix],
            valueProcessors: [
              processors.parseNumbers,
              processors.parseBooleans,
              (value) => this.lib.parseDate(value)
            ]
          },
          (err, result) => {
            try {
              if (err) {
                throw new Error(`${this.constructor.name}, 'getFromValorTextoGrid',
								${err}`);
              }
              const items = !result ? [] : result.Items.Item || [];
              if (items) {
                items.forEach((f) => {
                  try {
                    for (const clm in f) {
                      if (clm) {
                        const typed = this.global.getTypedValue(f[clm]);
                        f[clm] = typed.value;
                      }
                    }
                  } catch (error) {
                    this.log.Registrar(
                      this.constructor.name,
                      'getFromValorTextoGrid.forEach',
                      error.message
                    );
                  }
                });
              }
              observer.next(items);
              observer.complete();
            } catch (error) {
              this.log.Registrar(
                this.constructor.name,
                'getFromValorTextoGrid.observable',
                error.message
              );
              observer.next(null);
              observer.complete();
            }
          }
        );
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromValorTextoGrid', error.message);
    }
  }

  /* Baseado numa entry modificada, irá localizá-la na lista principal e atualizar os valores. */
  // updateEntry(newEntry: IEntry, entries: Array<IEntry>): void {
  //   try {
  //     let finded = entries.find((f) => this.global.isEqual(f.id, newEntry.id));
  //     if (finded) {
  //       finded = this.global.cloneObj(newEntry);
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'updateEntry', error.message);
  //   }
  // }

  /* Baseado num id, irá localizá-la na lista principal e removê-la. */
  // deleteEntry(entryId: number, entries: Array<IEntry>): void {
  //   try {
  //     const findIndex = entries.findIndex((f) => this.global.isEqual(f.id, entryId));
  //     if (findIndex >= 0) {
  //       entries.splice(findIndex, 1);
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'deleteEntry', error.message);
  //   }
  // }

  /* Verifica se a medicação do Estoque de Materiais é equivalente considerando centro de custo, dosagem, apresentação e nome do medicamento. */
  isEquivalentMedication(stockItem: IEstoqueMateriais, costCenter: string, medication: IMedication): boolean {
    try {
      return (
        this.global.isEqual(stockItem.centroCusto, costCenter) &&
        this.global.isEqual(stockItem.itens, medication.medicationName) &&
        this.global.isEqual(this.global.RemoveAll(stockItem.dosagem, ' '), this.global.RemoveAll(medication.dosage, ' ')) &&
        this.global.isEqual(stockItem.unidademedida, medication.presentation)
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isEquivalentMedication', error.message);
    }
    return false;
  }

  /* Calcula a data efetiva: se pago é a data de pagamento, senão data de vencimento.  */
  getEffectiveDate(datePayment: Date, dateDue: Date, isPending: boolean, isRecurrency: boolean): Date {
    try {
      if (isRecurrency) {
        return dateDue;
      }
      const typed = this.global.getTypedValue(datePayment);
      return (typed.type === EnTypedValue.Date && typed.value && !isPending ? datePayment : dateDue);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getEffectiveDate', error.message);
    }
    return null;
  }


}
