<!-- <label class="">
  <div class="icheckbox_square-green" [class.checked]="includeNormal" style="position: relative;">
    <input type="checkbox" value="" style="position: absolute; opacity: 0;" (click)="includeNormalClick($event)">
    <ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background-color: rgb(255, 255, 255); border: 0px; opacity: 0; background-position: initial initial; background-repeat: initial initial;"></ins>
  </div>
  <i></i>Incluir respostas negativas</label>
<label class="control-label">
  <span class="fa fa-info-circle" [pTooltip]="tooltipIncludeNormal" tooltipPosition="right"></span>
</label> -->
<div class="ibox-content" style="display: block;">
  <!-- <div>
    <button class="btn" type="button" (click)="onBackClick($event)">
      < Voltar</button>
  </div> -->
  <div class="col-lg-12">
    <i class="fa fa-heart-o mr icon">
      <span class="title">Sinais Vitais</span>
    </i>
    <h3>{{ patientName }}</h3>
  </div>
  <div class="col-lg-12">
    <!-- [expandableRows]="false"  -->
    <!-- <p-table *ngIf="isLoaded" [value]="rows" rowGroupMode="rowspan"
      [style]="[{'margin-top':'50px'}, {'text-align': 'right'}]" [resizableColumns]="true" [rowHover]="true"
      emptyMessage="Nenhum registro encontrado!">
      <p-headerColumnGroup>
        <p-row>
          <p-column [style]="{'font-size': 'xx-large', 'padding': '20px'}" [header]="strDate"
            [colspan]="columns ? (columns.length * 2) : 1"></p-column>
        </p-row>
        <p-row>
          <p-column *ngFor="let clm of columns" [style]="{'font-size': 'x-large', 'padding': '15px'}"
            [header]="clm.header" colspan="2"></p-column>
        </p-row>
        <p-row>
          <ng-container *ngFor="let clm2 of columns">
            <p-column [filter]="false" filterMatchMode="contains" filterPlaceholder="Procurar..." header="Sinal Vital"
              sortable="true" [style]="{'white-space': 'normal'}"></p-column>
            <p-column header="Medida" sortable="false" [filter]="false" filterMatchMode="contains"
              filterPlaceholder="Procurar..." [style]="{'white-space': 'normal'}"></p-column>
          </ng-container>
        </p-row>
      </p-headerColumnGroup>
      <ng-container *ngFor="let clm3 of columns">
        <p-column [style]="{'font-size': 'large', 'font-weight': '700', 'width': '120px'}"
          [field]="getKey(clm3, 'Rotulo')"></p-column>
        <p-column [style]="{'font-size': 'large', 'word-wrap': 'normal' }" [field]="getKey(clm3, 'ValorTexto')">
        </p-column>
      </ng-container>
    </p-table> -->
  </div>
</div>
