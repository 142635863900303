import { IAtividadeComponenteDAL, IOcorrencia } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { EnBubbleEvent } from '../../shared/enum/en-bubble-event.enum';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { AtividadeCadastroViewComponent } from '../atividade-cadastro-view/atividade-cadastro-view.component';
import { Params } from '@angular/router';
import { EnumAtividadeTipo } from '../../shared/enum/enum-atividade-tipo.enum';
import { IAtividade } from '../../shared/interface/iatividade';
import { IPasso } from '../../shared/interface/ipasso';
import { EnAtribuicaoTipo } from '../../shared/enum/enum-atribuicao-tipo.enum';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { EnMaterialIcon, EnActivityType } from '@medlogic/shared/gecore';
import { DecisionDialogComponent } from '@medlogic/shared/gecore';
import { FormGroup } from '@angular/forms';

/* Essa é a atividade tradicional do GE, associada a um ou mais processos. */
@Component({
  selector: 'lib-atividade-processo',
  templateUrl: './atividade-processo.component.html',
  styleUrls: ['./../atividade-view/atividade-view.component.css']
})
export class AtividadeProcessoComponent extends AtividadeCadastroViewComponent implements OnInit {
  enAtividadeTipo: EnumAtividadeTipo;

  /* override  */
  ngOnInit(): void {
    try {
      super.ngOnInit();
      this.activityType = EnActivityType.ProcessActivity;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  // override
  /* Extrai os parâmetros passados na url/rota */
  protected getUrlParams(): void {
    try {
      // TODO: ATENÇÃO: Houve atualização em AtividadeView.getUrlParams avaliar se deve ser atualizad

      // Os parametros estão sendo passados diretamente aos componentes
      this.subs.sink = this.route.params
        .pipe(
          mergeMap((params: Params) => {
            if (params.uno) {
              this.config.usuarioLogadoNo = +params.uno;
            }
            if (params.pno) {
              this.config.processoNo = +params.pno;
            }
            if (params.tno) {
              this.config.TarefaNo = +params.tno;
            }
            if (params.readonly) {
              this.config.isReadOnly = params.readonly === 'true';
            }
            if (params.saveInList) {
              this.saveInList = params.saveInList;
            }
            // Necessário zerar a ocorrencia se não for fornecida, pois, é um requisito para se criar nova.
            this.config.OcorrenciaNo.next(params.ono ? +params.ono : -1);
            const enAtividadeTipo = this.config.OcorrenciaNo.value > 0 ? EnumAtividadeTipo.Editar : EnumAtividadeTipo.Criar;
            return this.refresh(
              enAtividadeTipo,
              -1,
              this.config.OcorrenciaNo.value,
              this.config.usuarioLogadoNo,
              null,
              true,
              this.config.processoNo,
              this.config.TarefaNo
            );
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /*override
   * Descobre a atividade relacionada ao processo.  */
  protected refresh(
    enAtividadeTipo: EnumAtividadeTipo,
    ano: number,
    ono: number,
    uno: number,
    defaultFormControls: any,
    addToHistory: boolean = true,
    pno: number = -1,
    tno: number = -1
  ): Observable<IAtividade> {
    try {
      this.isEditMode = ono > 0;
      const error = () => catchError((err, obs) => {
        console.log(err);
        this.isLoading.next(false);
        return of(err);
      });
      const obsAtividade = this.obsGetAtividadeAndIniciar(enAtividadeTipo, uno, pno, ono, tno, ano);
      const getAtividades = () => mergeMap((atividadeInicio: IAtividade) => {
        if (ano <= 0) {
          // a rota processactivity terá atividadeInicio.AtividadeNo, enquanto a multiatividade já receberá ano
          ano = atividadeInicio.AtividadeNo;
        }
        return forkJoin([
          of(atividadeInicio),
          this.atividadeDAL.getAtividadePorAtividadeNoProcessoNo(uno, ano, pno)
        ]);
      });
      const updateFooterHistoryAndControls = () => mergeMap(([atividadeInicio, atividadeProcesso]) => {
        this.updateRodapeAndProperties(ono, pno, tno, atividadeProcesso, atividadeInicio);
        if (addToHistory) {
          this.addToHistory(uno, pno, ono, tno, this.saveInList, false);
        }
        // Disparará o load dos controles somente após a ocorrencia ter sido criada e atividade carregada.
        defaultFormControls = defaultFormControls || this.config.getDefaultFormControls(ano);
        return this.fillControls(enAtividadeTipo, ano, ono, uno, defaultFormControls, pno, tno, this.readOnlyExcept, this.isEditMode)
          .pipe(
            mergeMap((controls: IAtividadeComponenteDAL[]) => {
              return of(atividadeProcesso);
            })
          );
      });
      // Pipe principal
      return obsAtividade
        .pipe(
          getAtividades(),
          updateFooterHistoryAndControls(),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Adicionará ao histórico, mas variando a rota conforme se for uma nova ocorrência, ou se edição.  */
  protected addToHistory(
    uno: number,
    pno: number,
    ono: number,
    tno: number,
    saveInList: boolean,
    isReadOnly: boolean
  ): void {
    try {
      const route =
        ono > 0
          ? this.navigation.getRouteProcessActivityListEdit(uno, pno, ono, tno, saveInList, isReadOnly)
          : this.navigation.getRouteProcessActivityListNew(uno, pno, saveInList);
      this.navigation.addToHistory(
        route,
        this.atividade && this.atividade.Nome ? this.atividade.Nome : this.msg.MODULE_NAME_PROCESSO
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addToHistory', error.message);
    }
  }

  /*override
     * Semelhante ao salvar, necessário sobrescrever para impedir o retorno automático para a página anterior.
  */
  concluir(
    enBubbleEvent: EnBubbleEvent,
    componentes: IAtividadeComponenteDAL[],
    tarefaNo: number,
    ocorrenciaNo: number,
    usuarioNo: number,
    processoNo: number,
    atividadeNo: number
  ): Observable<{ passo: IPasso, ocorrencia: IOcorrencia }> {
    try {
      // Disso depende a possibilidade de segurar a continuídade no caso, por exemplo, de um formulário não validado
      return this.salvar(enBubbleEvent, componentes, tarefaNo, ocorrenciaNo, usuarioNo, processoNo)
        .pipe(
          mergeMap((ocorrencia) =>
            this.beforeComplete(
              enBubbleEvent,
              tarefaNo,
              ocorrenciaNo,
              usuarioNo,
              processoNo,
              atividadeNo,
              componentes,
              this.formGroup
            )
          ),
          mergeMap((ocorrencia) => {
            try {
              if (ocorrencia) {
                if (enBubbleEvent === EnBubbleEvent.activityComplete) {
                  if (this.formGroup.invalid) {
                    return this.showInvalidFormMessage()
                      .pipe(
                        mergeMap((res) => {
                          if (res) {
                            return of({
                              passo: null, ocorrencia: {
                                OcorrenciaNo: ocorrencia.OcorrenciaNo,
                                TarefaNo: ocorrencia.TarefaNo,
                                AtividadeNo: this.atividade.AtividadeNo
                              } as IOcorrencia
                            });
                          } else {
                            return of(null);
                          }
                        })
                      );
                  } else {
                    // Formulário válido
                    // Concluir a atividade
                    return this.doActivityComplete(
                      ocorrencia.TarefaNo,
                      this.atividade.AtividadeNo,
                      ocorrencia.OcorrenciaNo,
                      usuarioNo,
                      processoNo
                    ).pipe(
                      map((res: { passo: IPasso, ocorrencia: IOcorrencia }) => {
                        // return {
                        //   OcorrenciaNo: res?.passo?.OcorrenciaNo,
                        //   AtividadeNo: res?.passo?.AtividadeNo,
                        //   TarefaNo: res?.passo?.TarefaNo
                        // } as IOcorrencia;
                        return res;
                      })
                    );
                  }
                }
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'concluir.mergeMap', error.message);
            }
            return of(null);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'concluir', error.message);
    }
    return of(null);
  }

  /*Salva todos os dados, a partir dos componentes.
     * Diferente de um cadastro, a atividade quando associada a um processo
     * precisa ter uma ocorrência criada antes de salvar os dados.
   */
  salvar(
    enBubbleEvent: EnBubbleEvent,
    componentes: IAtividadeComponenteDAL[],
    tarefaNo: number,
    ocorrenciaNo: number,
    usuarioNo: number,
    processoNo: number
  ): Observable<IOcorrencia> {
    try {
      if (this.formGroup.invalid) {
        // Emitir algum aviso, mas salvar
      }
      return this.action.criarOcorrenciaESalvar(componentes, tarefaNo, ocorrenciaNo, usuarioNo, processoNo)
        .pipe(
          mergeMap((result) => {
            try {
              if (result) {
                this.saved = true;
                this.updateRodapeAndProperties(
                  result.OcorrenciaNo,
                  processoNo,
                  result.TarefaNo,
                  this.atividade
                );
                this.atividadeComponenteDAL.cleanCacheGetComponentesEditData(
                  result.AtividadeNo,
                  result.OcorrenciaNo,
                  usuarioNo
                );
                return this.afterSave(
                  enBubbleEvent,
                  result.TarefaNo,
                  result.AtividadeNo,
                  result.OcorrenciaNo,
                  usuarioNo,
                  processoNo,
                  this.componentes,
                  this.formGroup,
                  this.isEditMode
                );
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'salvar.salvar', error.message);
            }
            return of(null);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'salvar', error.message);
    }
    return of(null);
  }

  /* Irá distinguir os comportamentos iniciais que são diferentes para edição e novo. */
  protected obsGetAtividadeAndIniciar(
    enAtividadeTipo: EnumAtividadeTipo,
    uno: number,
    pno: number,
    ono: number,
    tno: number,
    ano: number
  ): Observable<IAtividade> {
    try {
      switch (enAtividadeTipo) {
        case EnumAtividadeTipo.Editar:
          return this.ocorrenciaDAL.IniciarEditar(uno, pno, ono, tno);
        default:
          return this.atividadeDAL.getPrimeiraAtividade(uno, pno);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'obsGetAtividadeAndIniciar', error.message);
    }
    return of(null);
  }

  /*override
  * Evento chamado após a conclusão do salvamento da atividade. Poderá ser sobrescrito. */
  protected afterSave(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    fg: FormGroup,
    isEditMode: boolean
  ): Observable<IOcorrencia> {
    try {
      // if (enBubbleEvent === EnBubbleEvent.activityComplete) {
      // 	if (this.formGroup.invalid) {
      // 		// Formulário inválido
      // 		this.showInvalidFormMessage();
      // 	} else {
      // 		// Concluir a atividade
      // 		this.doActivityComplete(tno, ano, ono, uno, pno);
      // 		// super.afterSave(enBubbleEvent, tno, ano, ono, uno, pno, componentes, fg );
      // 	}
      // }
      return of({ OcorrenciaNo: ono, AtividadeNo: ano, TarefaNo: tno });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave override', error.message);
    }
  }

  /* Realiza a conclusão da Atividade. */
  protected doActivityComplete(tno: number, ano: number, ono: number, uno: number, pno: number): Observable<{ passo: IPasso, ocorrencia: IOcorrencia }> {
    try {
      this.isLoadingAfterComplete = true;
      const obsPasso$ = this.ocorrenciaDAL.setOcorrenciaNotificarConclusaoSalvamento(pno, ono, uno, ano)
        .pipe(
          mergeMap((result) => {
            try {
              // TODO: checar porque Variaveis de identificação não estão sendo preenchidas
              const varId1 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao1No}`);
              const varId2 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao2No}`);
              this.atividade.CalcVariavelIdentificacao1Valor = varId1 ? varId1.value : -1;
              this.atividade.CalcVariavelIdentificacao2Valor = varId2 ? varId2.value : -1;
              this.atividade.OcorrenciaNo = ono;
              this.atividade.TarefaNo = tno;
              return this.processoDAL.getProximoPasso(this.atividade, pno, uno, tno, ono).pipe(
                map((proximoPasso) => {
                  // FIXME: Idealmente, essa deveria ser uma chamada assíncrona, retornando também observable
                  this.executeNextStep(this.atividade, proximoPasso);
                  this.isLoadingAfterComplete = false;
                  this.emitAfterCompleted(tno, ano, ono, uno, pno);
                  return proximoPasso;
                })
              );
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'afterSave.mergeMap', error.message);
            }
            return of(null);
          })
        );
      return obsPasso$.pipe(map(passo => ({ passo, ocorrencia: null })));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doActivityComplete', error.message);
    }
  }

  /* Executa o próximo passo, caso exista.
   * Há várias possibilidades: 1) Abrir uma janela (ainda não implementado) para perguntar pelo destinatário, data e hora
   * 2) Abrir uma tarefa para o mesmo usuário
   * 3) Apenas concluir e retornar
   * 4) Abrir uma janela com uma mensagem e relatório final...
  */
  protected executeNextStep(atividade: IAtividade, passo: IPasso): void {
    try {
      if (atividade.CalcAtribuicaoMensagemPublico) {
        this.executeStepEndMessage();
      } else if (!passo) {
        this.executeStepLast();
      } else if (passo.CalcblDataTarefa || passo.CalcblDestinatario || passo.CalcblHoraTarefa) {
        this.executeStepSchedule(passo);
      } else {
        this.executeStepOpenImediately(passo);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeNextStep', error.message);
    }
  }

  /* Significa que há uma mensagem configurada após a tarefa. */
  private executeStepEndMessage(): void {
    try {
      this.onWindowDialog(
        this.atividade.CalcAtribuicaoTitulo,
        this.atividade.CalcAtribuicaoMensagemPublico,
        '600px',
        EnMaterialIcon.check
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeStepEndMessage', error.message);
    }
  }

  /* Abre janela para verificar se o usuário deseja abrir uma próxima tarefa. */
  private executeStepOpenImediately(passo: IPasso): void {
    try {
      const dialogRef = this.matDialog
        .open(DecisionDialogComponent, {
          width: '350px',
          height: '260px',
          data: {
            title: this.msg.DIALOG_TITLE_EXECUTE_NEXT_STEP,
            btnYes: this.msg.BTN_YES,
            btnNo: this.msg.BTN_NO,
            yesResult: true,
            noResult: false
          }
        });
      this.subs.sink = dialogRef.afterClosed()
        .pipe(
          mergeMap((result) => {
            if (result) {
              return this.refresh(
                EnumAtividadeTipo.Editar,
                passo.AtividadeNo,
                passo.OcorrenciaNo,
                this.config.usuarioLogadoNo,
                null,
                false,
                this.config.processoNo,
                passo.TarefaNo
              );
            } else {
              this.navigation.goBack();
              return of(null);
            }
          })
        ).subscribe();
      // Notificar a mudança do status da Tarefa antes de modificá-la para a próxima
      // AtividadeStatusObserver.instance.notifyObservers([
      // 	EnumObservableType.AtividadeStatus,
      // 	_tarefaAtualNo,
      // 	Config.instance.Complemento1,
      // 	Config.instance.Complemento2
      // ]);
      // _funcProximaAcao(_enAtividadeTipo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeStepOpenImediately', error.message);
    }
  }

  /*Abre a janela de Agendamento (destinatário, data e hora) e modificará a atribuição da Tarefa já gerada.
   * Atribuição definida em passo automático ou condicional, portanto,
   * Papel destinatário só conhecido neste momento, após rodar o próximo passo
   */
  private executeStepSchedule(passo: IPasso): void {
    try {
      this.atividade.CalcblDataTarefa = passo.CalcblDataTarefa;
      this.atividade.CalcblDestinatario = passo.CalcblDestinatario;
      this.atividade.CalcblHoraTarefa = passo.CalcblHoraTarefa;
      // Necessário preencher os rótulos da janela, uma vez que eles não eram conhecidos antes do processamento do próximo passo
      this.atividade.CalcAtribuicaoDestinatarioTitulo = passo.CalcAtribuicaoDestinatarioTitulo;
      this.atividade.CalcAtribuicaoTitulo = passo.CalcAtribuicaoTitulo;
      // Para que caso o usuário use o botão cancelar da janela de atribuição, volte para cá quando concluir novamente
      this.atividade.EnAtribuicaoTipo = EnAtribuicaoTipo.ConclusaoPosRetornoProximoPasso;
      // if (_funcaoCarregarAtribuicao) {
      // 	_funcaoCarregarAtribuicao(
      // 		_container,
      // 		passo.LstUsuarioPapel,
      // 		this.atividade,
      // 		passo.TarefaNo,
      // 		atribuicao_completed,
      // 		null
      // 	);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeStepSchedule', error.message);
    }
  }

  /* Já que não há um próximo passo, retornará para a tela anterior. */
  private executeStepLast(): void {
    try {
      this.enAtividadeTipo = EnumAtividadeTipo.FinalizacaoVoltar;
      // TODO: Deve chamar tarefa.SetMudarAtribuicaoConcluirTarefaAtual
      this.navigation.goBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeStepLast', error.message);
    }
  }
}
