export enum EnTypedValue {
    None,
    // wwwMMMDDYYYYhhmmssGMTLOC, // Thu Mar 16 2017 00:00:00 GMT-0300 (BRT)
    // MMDDYYYYhhmmss,
    // MMDDYYYY,
    // DDMMYYYY,
    // DDMMYYYYhhmmss,
    // YYYYMMDDhhmmss,
    // YYYYMMDD,
    Date,
    Float,
    Integer,
    Real,
    Dollar,
    Percent,
    Null
}
