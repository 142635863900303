import { EnStockChangeMode } from '@medlogic/shared/shared-interfaces';
import { createReducer, on, Action } from '@ngrx/store';
import { ITenantState, EnTheme, EnUserRole, ITenant } from '@medlogic/shared/shared-interfaces';
import {
  setTenant, setTenantId, loadTenant, setTenantToken,
  setTenantOno, setTenantCustomerId, setTenantTheme
} from './medlogic-tenant.actions';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { loadTenantGoRoot, clearTenant } from './medlogic-tenant.actions';

export const tenantFeatureKey = 'tenant';

export const adapterTenant: EntityAdapter<ITenant> = createEntityAdapter<ITenant>({
  selectId: instance => instance.tenantId,
});

export const initialStateTenant: ITenantState = adapterTenant.getInitialState({
  // additional entity state properties
  customerId: null,
  loggedUserName: null,
  tenantId: null,
  selectedTenant: null,
  enTheme: EnTheme.default,
  userRole: EnUserRole.customer,
  identification1: null,
  identification2: null,
  token: null,
  userId: null,
  ono: null,
  isDebug: false, // TODO: true: carregará dados de mockup
  // TODO: talvez uma alternativa seria testar o switchmap no login e reduzir o login além de disparar a ação do tenantToken.
  // Mas não há garantias que o login seria reduzido antes da chamada do effect do tenant.
  login: null, // Essa propriedade é duplicada em relação ao estado de login, mas durante o login tenant e login se "misturam"
  enStockChangeMode: EnStockChangeMode.none,
  error: undefined,
  selectedId: null,
  imgUrl: null
});


export const reducer = createReducer(initialStateTenant,
  on(loadTenant, (state, payload) => ({
    ...state,
    tenantId: payload?.tenantId,
    customerId: payload?.customerId,
    loggedUserName: payload?.loggedUserName,
    userRole: payload?.userRole,
    enTheme: payload?.enTheme,
    token: payload?.token,
    userId: payload?.userId,
    login: payload?.login
  })),

  on(loadTenantGoRoot, (state, payload) => ({
    ...state,
    tenantId: payload?.tenantId,
    customerId: payload?.customerId,
    loggedUserName: payload?.loggedUserName,
    userRole: payload?.userRole,
    enTheme: payload?.enTheme,
    token: payload?.token,
    userId: payload?.userId,
    login: payload?.login,
    imgUrl: payload?.imgUrl
  })),
  on(setTenant, (state: any, payload: any) => {
    return ({
      ...state,
      selectedTenant: payload?.selectedTenant,
      customerId: payload?.customerId,
      identification1: payload?.identification1,
      identification2: payload?.identification2
    });
  }),
  on(setTenantId, (state, payload) => {
    return ({
      ...state,
      tenantId: payload?.tenantId
    });
  }),
  on(setTenantToken, (state, payload) => {
    return ({
      ...state,
      token: payload?.token,
      login: payload?.login
    });
  }),
  on(setTenantOno, (state, payload) => {
    return ({
      ...state,
      ono: payload?.ono
    });
  }),
  on(setTenantCustomerId, (state, payload) => {
    return ({
      ...state,
      customerId: payload?.customerId
    });
  }),
  on(setTenantTheme, (state, payload) => {
    return ({
      ...state,
      enTheme: payload?.enTheme
    });
  }),
  on(clearTenant, (state, payload) => {
    return ({
      ...initialStateTenant
    });
  })
);

// export function tenantReducer(state: ITenantState | undefined, action: Action) {
//   return tenantReducerObj(state, action);
// }

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterTenant.getSelectors();
