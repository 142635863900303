<mat-toolbar color="basic">
  <span class="example-spacer"></span>
  <mat-icon class="example-icon" (click)="onNoClick()">close</mat-icon>
</mat-toolbar>
<div class="icn">
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  <h1>{{ data.title }}</h1>
</div>
<div class="msg" [innerHTML]="data.messageHtml"></div>
<div class="btn-bar">
  <button mat-raised-button (click)="onNoClick()">OK</button>
</div>
