import { IPatient } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setPatient = createAction(
  '[Patient/API] Set Patient',
  props<{ selectedId: number }>()
);
export const loadPatients = createAction(
  '[Patient/API] Load Patients'
);
export const loadPatientsWithMedications = createAction(
  '[Card Patient] Load Patients with Medications'
);
export const loadPatientsWithAlerts = createAction(
  '[Card Patient] Load Patients with Alerts'
);

export const loadPatientsSuccess = createAction(
  '[Patient/API] Load Patients Success',
  props<{ patients: IPatient[] }>()
);

export const patientFail = createAction(
  '[Patient/API] Patient Fail',
  props<{ error: string }>()
);

export const addPatient = createAction(
  '[Patient/API] Add Patient',
  props<{ patient: IPatient }>()
);

export const upsertPatient = createAction(
  '[Patient/API] Upsert Patient',
  props<{ patient: IPatient }>()
);

export const addPatients = createAction(
  '[Patient/API] Add Patients',
  props<{ patients: IPatient[] }>()
);

export const upsertPatients = createAction(
  '[Patient/API] Upsert Patients',
  props<{ patients: IPatient[] }>()
);

export const updatePatient = createAction(
  '[Patient/API] Update Patient',
  props<{ patient: Update<IPatient> }>()
);

export const updatePatients = createAction(
  '[Patient/API] Update Patients',
  props<{ patients: Update<IPatient>[] }>()
);

export const deletePatient = createAction(
  '[Patient/API] Delete Patient',
  props<{ id: string }>()
);

export const deletePatients = createAction(
  '[Patient/API] Delete Patients',
  props<{ ids: string[] }>()
);

export const clearPatients = createAction(
  '[Patient/API] Clear Patients'
);
export const clearSelectedPatient = createAction(
  '[Card Patient] Clear Selected Patients'
);
