import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers } from './+state/storage.metareducer';
import { TutorialEffects } from './+state/tutorial.effects';

import * as fromTutorial from './+state/tutorial.reducer';

const SERVICES = [
];

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromTutorial.tutorialFeatureKey, fromTutorial.reducer, { metaReducers }),
  EffectsModule.forFeature([TutorialEffects]),
];

@NgModule({
  imports: [
    MODULES,
    STATE_MODULES,
  ],
  providers: [SERVICES]
})
export class StateTutorialModule { }
