import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsStoreService } from './service/js-store.service';
import { TbQueueService } from './service/tb-queue.service';
import { TbKeyValueService } from './service/tb-key-value.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    JsStoreService,
    TbQueueService,
    TbKeyValueService
  ]
})
export class SharedSharedJsstoreModule { }
