import { map, refCount, publishReplay, toArray } from 'rxjs/operators';
import { IHistoricoCompraEstoque } from '@medlogic/shared/shared-interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { UnsubscribeOnDestroyAdapter, GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class HistoricoCompraEstoqueService extends UnsubscribeOnDestroyAdapter {

  // tslint:disable-next-line: max-line-length
  public lstVariaveis = 'V_832,V_30350,V_27993,V_27997,V_27999,V_28000,V_28001,V_28004,V_28012,V_28014,V_28018,V_28023,V_30269,V_100326,V_34687,V_34705,V_27987,V_100306,V_100321,V_100322,V_34718,V_101702,V_28452';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IHistoricoCompraEstoque[] = new Array<IHistoricoCompraEstoque>();

  cadastroNo = 2296;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService
  ) {
    super();
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IHistoricoCompraEstoque> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
    return of(null);
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IHistoricoCompraEstoque> {
    try {
      if (
        startDate.getTime() !== this.currentDtInicial.getTime() ||
        endDate.getTime() !== this.currentDtFinal.getTime() ||
        !this.cadastrosCache
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastro(cadastroNo, this.lstVariaveis).pipe(
        map(
          (c) =>
            this.toAtribute(c)
        ),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  private toAtribute(c: any): IHistoricoCompraEstoque {
    return {
      // index?:any
      titulo: c.V_101702,
      dosagem: c.V_832,
      material: c.V_30350, // c.V_30350,
      centroCusto: c.V_27993,
      unidademedida: c.V_27997,
      codigo: c.V_27999,
      quantidade: this.glb.getTypedValue(c.V_28000).value,
      habilitado: c.V_28001.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_28001) ? true : false,
      data: this.glb.getTypedValue(c.V_28004).value,
      preco: c.V_28012,
      fornecedorNome: c.V_28014,
      tipoMaterial: c.V_28018,
      unidadeNegocio: c.V_28023,
      medicamento: c.V_30269,
      codPacienteNomedoPacienteCodMedicamento: c.V_100326, // c.V_100326,
      codigoPedido: c.V_34687,
      pedidoConferido: this.glb.IsNullOrEmpty(c.V_34705)
        ? false
        : c.V_34705.toUpperCase() === 'SIM' ? true : false,
      codigoItemEstoque: c.V_27987,
      idMedicamento: c.V_100306, // V_34809
      lote: c.V_100321,
      dtValidade: this.glb.getTypedValue(c.V_100322).value,
      quantidadePorEmbalagem: c.V_34718,
      quantidadeDeEmbalagens: c.V_10406, // quantidadePacotes
      invoiceNumber: c.V_28452
    } as IHistoricoCompraEstoque;
  }

  protected getFiltered(cadastroNo: number, filter: string, startDate: Date, endDate: Date): Observable<IHistoricoCompraEstoque> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, filter, true)
        .pipe(map(c => {
          return this.toAtribute(c);
        }), publishReplay(), refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /*Insere ou atualiza o item.
       * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
       */
  save(ano: number, historicoCompraEstoque: IHistoricoCompraEstoque, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(historicoCompraEstoque).filter((f) => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(historicoCompraEstoque: IHistoricoCompraEstoque): IForm[] {
    try {
      return [
        { VariavelNo: 832, ValorDado: historicoCompraEstoque.dosagem || '' },
        { VariavelNo: 101702, ValorDado: historicoCompraEstoque.titulo || '' },
        { VariavelNo: 30350, ValorDado: historicoCompraEstoque.material || '' },
        { VariavelNo: 27993, ValorDado: historicoCompraEstoque.centroCusto || '' },
        { VariavelNo: 27997, ValorDado: historicoCompraEstoque.unidademedida || '' },
        { VariavelNo: 27999, ValorDado: historicoCompraEstoque.codigo || '' },
        { VariavelNo: 28000, ValorDado: historicoCompraEstoque.quantidade || '' },
        { VariavelNo: 28001, ValorDado: historicoCompraEstoque.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 28004, ValorDado: this.glb.ddMMYYYYThhmmssToDate(historicoCompraEstoque.data) },
        { VariavelNo: 28012, ValorDado: historicoCompraEstoque.preco || '' },
        { VariavelNo: 28014, ValorDado: historicoCompraEstoque.fornecedorNome || '' },
        { VariavelNo: 28018, ValorDado: historicoCompraEstoque.material || '' },
        { VariavelNo: 28023, ValorDado: historicoCompraEstoque.unidadeNegocio || '' },
        { VariavelNo: 30269, ValorDado: historicoCompraEstoque.medicamento || '' },
        { VariavelNo: 100326, ValorDado: historicoCompraEstoque.codPacienteNomedoPacienteCodMedicamento || '' },
        { VariavelNo: 34687, ValorDado: historicoCompraEstoque.codigoPedido || '' },
        {
          VariavelNo: 34705,
          ValorDado:
            this.glb.isNullOrEmpty(historicoCompraEstoque.pedidoConferido) ||
              historicoCompraEstoque.pedidoConferido === false
              ? 'NÃO'
              : 'SIM'
        },
        { VariavelNo: 27987, ValorDado: historicoCompraEstoque.codigoItemEstoque || '' },
        { VariavelNo: 100306, ValorDado: historicoCompraEstoque.idMedicamento || '' },
        { VariavelNo: 100321, ValorDado: historicoCompraEstoque.lote || '' },
        { VariavelNo: 100322, ValorDado: historicoCompraEstoque.dtValidade || '' },
        { VariavelNo: 34718, ValorDado: historicoCompraEstoque.quantidadePorEmbalagem || '' },
        { VariavelNo: 100406, ValorDado: historicoCompraEstoque.quantidadeDeEmbalagens || '' },
        { VariavelNo: 28452, ValorDado: historicoCompraEstoque.invoiceNumber || '' }
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist(
    ano: number,
    historicoCompraEstoque: IHistoricoCompraEstoque,
    uno: number,
    compareFieldName: string = 'titulo'
  ): Observable<boolean> {
    try {
      return new Observable((observer) => {
        this.subs.sink = this.getFromCadastro(ano, null, null).pipe(toArray()).subscribe((items) => {
          const founded =
            items.findIndex((f) =>
              this.glb.isEqual(f[compareFieldName], historicoCompraEstoque[compareFieldName])
            ) >= 0;
          observer.next(founded);
          if (!founded) {
            this.subs.sink = this.save(ano, historicoCompraEstoque, uno).subscribe((s) => observer.complete());
          } else {
            observer.complete();
          }
        });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }


}
