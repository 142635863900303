<mat-list *ngIf="groups?.length > 0; else empty">

  <ng-container *ngFor="let group of groups">

    <div mat-subheader class="title">{{ group?.title }}</div>

    <ng-container *ngIf="group?.items?.length > 0; else empty">

      <mat-list-item *ngFor="let item of group?.items" [@fadeAnimation]="'in'" (click)="onItemClick(item)">
        <ng-container *ngIf="contentRef" [ngTemplateOutlet]="contentRef" [ngTemplateOutletContext]="{$implicit:item}">
        </ng-container>
      </mat-list-item>

      <mat-divider></mat-divider>

    </ng-container>

  </ng-container>

</mat-list>


<ng-template #empty>
  <ui-empty></ui-empty>
</ng-template>