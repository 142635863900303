import { error, LogService, IQueue, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { BasePageService } from './base-page.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  constructor(
    private log: LogService,
    private basepage: BasePageService,
    private cnfJson: ConfigJsonService,
  ) { }

  /* retorna o número de itens sincronizados com sucesso. */
  executeQueue(queue: IQueue): Observable<string> {
    try {
      return queue?.url ? this.getWebApiService(queue?.url)
        .pipe(
          map(m => {

             return !!m ? queue?.key : null;
           // return !!m ? 1 : 0;
          }),
          error()
        ) : of(0);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeQueue', error.message);
    }
    return of(null);
  }


  protected getWebApiService(url: string): Observable<any> {
    try {
      url = `${this.cnfJson.baseUrlAPI}${url}`;
      return this.basepage
        .get(url)

      // const fetch = require('node-fetch');
      // const retorno = await fetch(
      //   url,
      //   { method: 'GET' }
      // ).catch(error => console.log(error));
      // return retorno;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWebApiService', error.message);
    }
    return of(null);
  }


}
