export const prescriptionAccess = [
    { viaNome: "ORAL" },
    { viaNome: "AA" },
    { viaNome: "ENDOVENOSO" },
    { viaNome: "ENTERAL" },
    { viaNome: "GTT" },
    { viaNome: "INALATÓRIO" },
    { viaNome: "INTRAMUSCULAR" },
    { viaNome: "INTRAVENOSA" },
    { viaNome: "OFTALMOLÓGICA" },
    { viaNome: "RETAL" },
    { viaNome: "SUBCUTÂNEA" },
    { viaNome: "SUBLINGUAL" },
    { viaNome: "TÓPICO" },
];
