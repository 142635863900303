import { ConfigStateService } from '@medlogic/shared/state-config';
import { Control } from '../../model/control';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CalculadoraService } from '../../service/calculadora.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MultilineDialogComponent } from './multiline-dialog/multiline-dialog.component';
import { GlobalService, ConfigJsonService, IDialogData, EnTheme, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { FormGroup } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-multiline-textbox-label',
  templateUrl: './ctr-multiline-textbox-label.component.html',
  styleUrls: ['./ctr-multiline-textbox-label.component.css']
})
export class CtrMultilineTextboxLabelComponent extends Control implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  public get isCompactVersion(): boolean {
    return this.ctrl ? this.ctrl?.Altura <= 20 : false;
  }

  public get hasContent(): boolean {
    return this.ctrl ? !this.global.isNullOrEmpty(this.ctrl?.ValorTexto) : false;
  }

  public get value(): string {
    const id = this.getId(this.ctrl);
    return this.formGroup.get(id).value;
  }

  public get isHtml(): boolean {
    try {
      if (!this.value) {
        return false;
      }
      return !this.global.IsNullOrEmpty(this.value) && (this.value.toString()).startsWith('<html>');
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isHtml', error.message);
    }
    return false;
  }

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }

  /* Botão de ampliar exibido quando a altura do componente é menor igual a 20.
   * Ao invés de exibir a textarea apenas exibe um botão com recurso de ampliação.
   */
  onAmpliar_Click($event: any): void {
    try {
      const dataDialog = {
        item: { value: this.ctrl?.ValorTexto, readOnly: this.ctrl?.CanEditItem }
      } as IDialogData;
      this.onWindowExecutionDialog(dataDialog, '95vw', '50vh');
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAmpliar_Click', error.message);
    }
  }

  /* Abre a janela de ampliação, em formato editável, ou somente leitura. */
  protected onWindowExecutionDialog(dataDialog: IDialogData, width: string, height: string): void {
    try {
      const dialogRef = this.dialog.open(
        MultilineDialogComponent, {
        height,
        width,
        data: dataDialog
      });
      this.subs.sink = dialogRef
        .afterClosed()
        .subscribe(newValue => {
          try {
            if (newValue) {
              this.setValue(newValue);
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'onWindowExecutionDialog.subscribe', error.message);
          }
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onWindowExecutionDialog', error.message);
    }
  }


}
