import { ConfigStateService } from '@medlogic/shared/state-config';
import { Injectable } from '@angular/core';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { CookieService } from 'ngx-cookie-service';

import * as moment_ from 'moment';
const moment = moment_; // Necessário dado a problema de compilação

declare var $: any;

/* Biblioteca de funções em javascript com objetivo de substituir o arquivo ge-global.js */
@Injectable()
export class JavascriptLib {

  arrSessionStorage;

  constructor(
    private global: GlobalService,
    private log: LogService,
    private cnf: ConfigStateService,
    private cookieSrv: CookieService) { }


  // AddTimeChrony() {
  //     var url = UrlIntranet + "Account/GetTimeCookie/";
  //     $.get(url, null, (data) => {
  //         try {
  //             if (data != "") {

  //                 var itensCompleto = data.split(';');

  //                 var tempoTotal = itensCompleto[1];
  //                 if (tempoTotal <= 0)
  //                     tempoTotal = 30;

  //                 // console.log('Tempo Decorrido: ' + itensCompleto[0]);
  //                 // console.log('Tempo Total em horas : ' + itensCompleto[1]);

  //                 var itens = itensCompleto[0].split(':');
  //                 var _segun = itens[2].split('.');
  //                 var Hora = parseInt(itens[0]);
  //                 var Minuto = parseInt(itens[1]);
  //                 var Segundo = parseInt(_segun[0]);

  //                 var tempoDecorrido = ((Hora * 60) + Minuto);

  //                 // console.log('Tempo Decorrido em Minutos: ' + tempoDecorrido);

  //                 var rate = tempoDecorrido / tempoTotal;
  //                 var clazz = "";
  //                 if (rate >= 0 && rate < 0.25)
  //                     clazz = "tR7_8";
  //                 if (rate >= 0.25 && rate < 0.50)
  //                     clazz = "tR5_8";
  //                 if (rate >= 0.50 && rate < 0.75)
  //                     clazz = "tR3_8";
  //                 if (rate >= 0.75)
  //                     clazz = "tR1_8";
  //                 if (rate == 0)
  //                     clazz = "tR0_8"

  //                 var baseClazz = "UISessionTimeout";
  //                 var clockClazz = baseClazz + clazz;
  //                 $('#TimeLogin').removeClass(baseClazz + 'tR7_8');
  //                 $('#TimeLogin').removeClass(baseClazz + 'tR5_8');
  //                 $('#TimeLogin').removeClass(baseClazz + 'tR3_8');
  //                 $('#TimeLogin').removeClass(baseClazz + 'tR1_8');
  //                 $('#TimeLogin').addClass(clockClazz);
  //                 $('#TimeLogin').chrony({
  //                     hour: Hora, minute: Minuto, second: Segundo, displayHours: true,
  //                     finish: () {
  //                         console.log("LogOff");
  //                         window.location = UrlIntranet + "Account/LogOff/";
  //                         alert('Tempo de sessao excedido, e necessario fazer login novamente.');
  //                     }
  //                 });

  //             } else {
  //                 console.log("LogOff");
  //                 window.location = UrlIntranet + "Account/LogOff/";
  //             }
  //         } catch (e) {
  //             console.log(e.message);
  //         }

  //     });
  // }

  /**
   * Converte um número de bytes em formato legível
   *
   * @param integer bytes     Número de bytes para converter
   * @param integer precision Número de dígitos após o separador decimal
   * @return string
   */
  bytesToSize(bytes, precision) {
    try {
      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;
      const gigabyte = megabyte * 1024;
      const terabyte = gigabyte * 1024;

      if ((bytes >= 0) && (bytes < kilobyte)) {
        return bytes + ' B';

      } else if ((bytes >= kilobyte) && (bytes < megabyte)) {
        return (bytes / kilobyte).toFixed(precision) + ' KB';

      } else if ((bytes >= megabyte) && (bytes < gigabyte)) {
        return (bytes / megabyte).toFixed(precision) + ' MB';

      } else if ((bytes >= gigabyte) && (bytes < terabyte)) {
        return (bytes / gigabyte).toFixed(precision) + ' GB';

      } else if (bytes >= terabyte) {
        return (bytes / terabyte).toFixed(precision) + ' TB';

      } else {
        return bytes + ' B';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'bytesToSize', error.message);
    }
    return null;
  }

  getYear(dt) {
    try {
      dt = dt.replace('\"', '').replace('\"', '');
      const dateSplit = dt.split('/');

      const year = dateSplit[2].substring(0, 4);
      return year;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getYear', error.message);
    }
    return null;
  }

  getUsuarioToken(environment: { production: boolean }): string {
    try {
      let token = this.cookieSrv.get('UsuarioToken');
      if (!token && environment && !environment.production) {
        token = this.cnf.baseUsuarioToken;
      }
      return token;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUsuarioToken', error.message);
    }
    return null;
  }

  getDateSubtract(type: string, fator: string, dt: any) {
    try {
      dt = dt.replace('\"', '').replace('\"', '');
      type = type.replace('\"', '').replace('\"', '');
      let dateFinal: any = moment(dt, 'DD/MM/YYYY').format('DD/MM/YYYY');
      switch (type) {
        case
          type = 'D':
          dateFinal = moment(dt, 'DD/MM/YYYY').subtract(fator, 'day').format('DD/MM/YYYY');
          break;
        case
          type = 'M':
          dateFinal = moment(dt, 'DD/MM/YYYY').subtract(fator, 'month').format('DD/MM/YYYY');
          break;
        case
          type = 'Y':
          dateFinal = moment(dt, 'DD/MM/YYYY').subtract(fator, 'year').format('DD/MM/YYYY');
          break;
        default:
          dateFinal = new Date(dt);
          break;
      }
      return dateFinal;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDateSubtract', error.message);
    }
    return null;
  }

  getDateDiff(dateStart, dateEnd, returnType) {
    try {
      dateStart = dateStart.replace('\"', '').replace('\"', '');
      dateEnd = dateEnd.replace('\"', '').replace('\"', '');
      returnType = returnType.replace('\"', '').replace('\"', '');
      const thisTime = moment(dateEnd, 'DD/MM/YYYY');
      const prevTime = moment(dateStart, 'DD/MM/YYYY');
      let returnTime = 0;
      switch (returnType) {
        case 'Y':
        case 'y':
          returnTime = moment(thisTime).diff(prevTime, 'years');
          break;
        case 'MM':
        case 'M':
          returnTime = moment(thisTime).diff(prevTime, 'months');
          break;
        case 'W':
        case 'w':
          returnTime = moment(thisTime).diff(prevTime, 'weeks');
          break;
        case 'D':
        case 'd':
          returnTime = moment(thisTime).diff(prevTime, 'days');
          break;
        case 'H':
        case 'h':
          returnTime = moment(thisTime.add(-3, 'hours')).diff(prevTime, 'hours');
          break;
        case 'mm':
        case 'm':
          returnTime = moment(thisTime).diff(prevTime, 'minutes');
          break;
        case 'S':
        case 's':
          returnTime = moment(thisTime).diff(prevTime, 'seconds');
          break;
        default:
      }
      return returnTime;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDateDiff', error.message);
    }
    return null;
  }

  validaDat(campo, valor) {
    try {
      const date = valor;
      let ardt = new Array();
      const ExpReg = new RegExp('(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}');
      ardt = date.split('/');
      let erro = false;
      if (date.search(ExpReg) === -1) {
        erro = true;
      } else if (((ardt[1] === 4) || (ardt[1] === 6) || (ardt[1] === 9) || (ardt[1] === 11)) && (ardt[0] > 30)) {
        erro = true;
      } else if (ardt[1] === 2) {
        if ((ardt[0] > 28) && ((ardt[2] % 4) !== 0)) {
          erro = true;
        }
        if ((ardt[0] > 29) && ((ardt[2] % 4) === 0)) {
          erro = true;
        }
      }
      if (erro) {
        // alert(valor + " não é uma data válida!!!");
        // bootbox.alert(valor + "não é uma data válida!!!");
        $(campo).css('border', 'solid 1px red');
        campo.focus();
        campo.value = '';
        return false;
      } else {
        $(campo).css('border', 'none');
      }
      return true;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'validaDat', error.message);
    }
    return null;
  }

  retira_acentos(palavra): string {
    let nova = '';
    try {
      const comAcento = 'áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ';
      const semAcento = 'aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC';
      for (let i = 0; i < palavra.length; i++) {
        if (comAcento.search(palavra.substr(i, 1)) >= 0) {
          nova += semAcento.substr(comAcento.search(palavra.substr(i, 1)), 1);
        } else {
          nova += palavra.substr(i, 1);
        }
      }
    } catch (e) {
      console.log(e.message);
      console.log(palavra);
    }
    return nova;
  }


  montarUrlRelatorio(
    tipoQuery,
    ocorrenciaNo,
    usuarioLogadoNo,
    atividadeNo,
    atividadeMultiplaNo,
    tarefaNo,
    processoNo,
    Complemento1,
    Complemento2,
    UrlPrint,
    TabIndex): string {
    try {
      const lstUrlPrint = UrlPrint.split(';');
      if (lstUrlPrint.length > 1 && TabIndex !== undefined) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < lstUrlPrint.length; i++) {
          const lstUrlPrintSplit = lstUrlPrint[i].split('$');
          if (lstUrlPrintSplit[0] === TabIndex) {
            UrlPrint = lstUrlPrintSplit[2];
            tipoQuery = UrlPrint.indexOf('?') === -1 ? '?' : '&';
          }
        }
      }
      const token = this.cookieSrv.get('UsuarioToken');
      let urlRelatorio = '';
      // tslint:disable: max-line-length
      if (UrlPrint.indexOf('?') < 0) {
        urlRelatorio = `${UrlPrint}${tipoQuery}ocrNo=${ocorrenciaNo}&usoNo=${usuarioLogadoNo}&atvNo=${atividadeNo}&atvmNo=${atividadeMultiplaNo}&trfNo=${tarefaNo}&proNo=${processoNo}&cpm1=${Complemento1}&cpm2=${Complemento2}&Token=${token}`;
      } else {
        urlRelatorio = `${UrlPrint}${tipoQuery}ocrNo=${ocorrenciaNo}&usoNo=${usuarioLogadoNo}&atvNo=${atividadeNo}&atvmNo=${atividadeMultiplaNo}&trfNo=${tarefaNo}&proNo=${processoNo}&cpm1=${Complemento1}&cpm2=${Complemento2}&Token=${token}`;
      }
      return urlRelatorio;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'montarUrlRelatorio', error.message);
    }
    return null;
  }

  imgExist(imageUrl) {
    try {
      const http = new XMLHttpRequest();
      http.open('HEAD', imageUrl, false);
      http.send();
      return http.status !== 404;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'imgExist', error.message);
    }
    return null;
  }

  getHexaRandom() {
    try {
      const letters = '0123456789ABCDEF'.split('');
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getHexaRandom', error.message);
    }
    return null;
  }

  // HideLoadResultRDV(msg) {
  //     $('iframe').iframeAutoHeight({
  //         debug: false,
  //         minHeight: $(window).height() * 0.8
  //     });
  //     console.log(msg);
  // }

  // KeyUpFilter(textSearch, textFilter) {
  //     var length = textFilter.length > 0;

  //     var text = retira_acentos(textFilter);

  //     var expression = new RegExp(textSearch, "ig");

  //     if (length && text.search(expression) < 0)
  //         return false;
  //     else
  //         return true;
  // }


  isMobile() {
    try {
      const ua = navigator.userAgent.toLowerCase();
      let uMobile = '';
      // === REDIRECIONAMENTO PARA iPhone, Windows Phone, Android, etc. ===
      // Lista de substrings a procurar para ser identificado como mobile WAP
      uMobile = '';
      uMobile += 'iphone;ipod;windows phone;android;iemobile 8';
      // Sapara os itens individualmente em um array
      const vUmobile = uMobile.split(';');
      // percorre todos os itens verificando se eh mobile
      let boolMovel = false;
      for (let i = 0; i <= vUmobile.length; i++) {
        if (ua.indexOf(vUmobile[i]) !== -1) {
          boolMovel = true;
        }
      }
      return boolMovel;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isMobile', error.message);
    }
    return null;
  }

  workday_count(start, end) {
    try {
      const first = start.clone().endOf('week'); // end of first week
      const last = end.clone().startOf('week'); // start of last week
      const days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7
      let wfirst = first.day() - start.day(); // check first week
      if (start.day() === 0) { --wfirst; } // -1 if start with sunday
      let wlast = end.day() - last.day(); // check last week
      if (end.day() === 6) { --wlast; } // -1 if end with saturday
      return wfirst + days + wlast; // get the total
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'workday_count', error.message);
    }
    return null;
  }

  clickLogOn() {
    this.clearSessionStorage();
  }

  clickLogOff() {
    this.clearSessionStorage();
  }

  clearSessionStorage() {
    try {
      if (this.arrSessionStorage) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.arrSessionStorage.length; i++) {
          sessionStorage.removeItem(this.arrSessionStorage[i]);
          // console.log('sessionStorage.removeItem: ' + item);
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearSessionStorage', error.message);
    }
    return null;
  }

}
