import { createAction, props } from '@ngrx/store';
import { ICustomer } from '../states/icustomer';

// TODO: Each model need to have its specific Action, Reducer, Effects.
export enum CustomerActionTypes {
  LoadCustomer = '[Customer Component] Load Customer',
  FetchSuccessCustomer = '[Customer API] Fetch Success Customer', // Set the Customer to the Store, after Effects
  FetchErrorCustomer = '[Customer API] Fetch Error Customer',
  SetCustomer = '[Customer Component] Set Customer',
  SelectCustomer = '[Customer Component] Select Customer'
}

export const loadCustomer = createAction(CustomerActionTypes.LoadCustomer);
export const setCustomer = createAction(CustomerActionTypes.SetCustomer, props<{ allCustomers: ICustomer[] }>());
// tslint:disable: max-line-length
export const fetchSuccessCustomer = createAction(CustomerActionTypes.FetchSuccessCustomer, props<{ allCustomers: ICustomer[] }>());
export const selectCustomer = createAction(CustomerActionTypes.SelectCustomer, props<{ selectedCustomer: ICustomer }>());
export const fetchErrorCustomer = createAction(CustomerActionTypes.FetchErrorCustomer);
