import { LibService } from '@medlogic/shared/geform';
import { Injectable } from '@angular/core';
import { InternacoesService } from './internacoes.service';
import {
  EnRequestType, LogService,
  ConfigJsonService, GlobalService, IInternacoes, EnContentType,
} from '@medlogic/shared/shared-interfaces';
import { Observable, map, of, tap, toArray } from 'rxjs';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';

@Injectable({
  providedIn: 'root'
})
export class InternacoesCustomService extends InternacoesService {

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnfJson: ConfigJsonService,
    log: LogService,
    lib: LibService,
    protected basepage: BasePageService
  ) {
    super(cadastroSrv, glb, cnfJson, log, lib);
  }

  /* Retorna todos os itens do histórico de compras através do id do item. */
  getByCodigoPaciente(ano: number, codigoPaciente: string, dtStart: Date = null, dtEnd: Date = null): Observable<IInternacoes> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_2230:${codigoPaciente}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCodigoPaciente', error.message);
    }
    return of(null);
  }

  getByIdAndPeriod(
    ano: number,
    patientId: string,
    dtStart: Date = null,
    dtEnd: Date = null
  ): Observable<any> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filterStr = `V_28051:${patientId}`;
      return this.getFiltered(this.cadastroNo, filterStr, startDate, endDate).pipe(
        toArray(),
        map((evolutions: IInternacoes[]) => {
          let currentEvo = evolutions[0] as IInternacoes;
          for (let i = 0; i < evolutions.length; i += 1) {
            const date = new Date(evolutions[i].dataRetorno2);
            if (new Date(currentEvo.dataRetorno2) < date) {
              currentEvo = evolutions[i];
            }
          }
          return currentEvo;
        }),
      );
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'getByIdAndPeriod',
        error.message
      );
    }
    return of(null);
  }


}
