import { Directive, Input, ElementRef, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
/* Ocultará o elemento caso a largura passada como parâmetro seja menor que o mínimo. */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appHideElemIfWidth]'
})
export class HideElemIfWidthDirective {
  // Esse é o elemento o qual se deseja monitorar a variação da largura.
  // tslint:disable-next-line: variable-name
  private _monitoredElem: HTMLElement;

  @Input('appHideElemIfWidth')
  public get monitoredElem(): HTMLElement {
    return this._monitoredElem;
  }
  public set monitoredElem(v: HTMLElement) {
    this._monitoredElem = v;
  }

  @Input() minWidth = 0;

  constructor(private el: ElementRef, private renderer: Renderer2, private log: LogService) { }

  // Para resolver o problema do - na atualização, procurar um evento que não seja o Init
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit(): void {
    try {
      this.applyStyles(this.monitoredElem, this.minWidth);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /*Se a largura do elemento monitorado for menor que o mínimo, ocultará o elemento que recebeu a diretiva. */
  applyStyles(monitoredElement: HTMLElement, minWidth: number): void {
    try {
      if (+monitoredElement.offsetWidth <= minWidth) {
        this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyStyles', error.message);
    }
  }
}
