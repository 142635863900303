import { TutorialService } from '@medlogic/medlogic/medlogic-data-access';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';
import { of } from 'rxjs';

import * as TutorialActions from './tutorial.actions';

@Injectable()
export class TutorialEffects {

  loadTutorials$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TutorialActions.loadTutorials),
        mergeMap(() => this.tutorialSrv.getAll()),
        switchMap((videos) => [
          TutorialActions.loadTutorialsSuccess({ videos }),
          setIsLoading({ isLoading: false })
        ]),
        catchError(error => of(TutorialActions.loadTutorialsFailure({ error })))
      );
  });

  constructor(
    private actions$: Actions,
    private tutorialSrv: TutorialService
  ) { }


}
