<div class="ibox-content">
  <!-- <p-messages [value]="msgs" [style]="{'background-color': 'white'}"></p-messages> -->
  <div *ngIf="formErrors && cnfJson.showFormErrors" class="alert alert-danger">
    {{ formErrors | json }}
  </div>
  <div class="row">
    <div *ngIf="hasPrevious" class="col-sm-4 text-left">
      <button class="btn btn-default" type="button" id="Previous" (click)="navigateNextBack($event, false)">
        <i class="fa fa-arrow-circle-o-left"></i>&nbsp;{{ titlePrevious }}</button>
    </div>
    <div *ngIf="hasNext" class="col-sm-4">
      <button class="btn btn-info" type="submit" id="Next" (click)="navigateNextBack($event, true)">
        {{ titleNext }}&nbsp;
        <i class="fa fa-arrow-circle-o-right"></i>
      </button>
    </div>
    <div *ngIf="hasConfirm && !isLoading" class="col-sm-4">
      <button class="btn btn-primary" type="button" id="confirm" (click)="onConfirm($event, saveInList)">
        <i class="fa fa-check"></i>&nbsp;Confirmar</button>
    </div>
  </div>
</div>
