import { ConfigStateService } from '@medlogic/shared/state-config';
import { ICadastro } from '../interface/icadastro';
import { IForm } from '../interface/iform';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { WebService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/* Serviço para interação com o cadastro.
 * O uso é mais simples que o DADO-DAL, pois, a estrutura de envio é mais orientada para
 * a necessidade e não para a estrutura de dados do GE.
 */
@Injectable()
export class FormDALService {

  constructor(
    protected webService: WebService,
    protected log: LogService,
    protected global: GlobalService,
    private cnf: ConfigStateService
  ) { }

  /* No campo identificador, é possível informar a variável e o valor que representam
   * o identificador único. Desta forma, se o registro for encontrado, atualiza, senão cria.
   */
  setAll(
    ano: number,
    uno: number,
    identificador: IForm,
    forms: Array<IForm>
  ): Observable<any> {
    try {

      const cadastro = {
        Indice: 1,
        CampoIdentificador: identificador,
        LstItensCadastro: forms
      } as ICadastro;
      const method = 'setSaveCadastro';
      const result = this.webService.connect(method,
        [
          { name: 'CadastroNo', value: ano },
          { name: 'UsuarioNo', value: uno },
          { name: 'LstDados', value: JSON.stringify([cadastro]) }
        ]);
      // result
      //   .subscribe(c =>
      //     c,
      //   (er) => this.log.Registrar(this.constructor.name, '', er),
      //   () => { }
      //   );
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setAll', error.message);
    }
  }

  setAllList(
    ano: number,
    uno: number,
    lstCadastro: Array<ICadastro>
  ): Observable<any> {
    try {

      const method = 'setSaveCadastro';
      const result = this.webService.connect(method,
        [
          { name: 'CadastroNo', value: ano },
          { name: 'UsuarioNo', value: uno },
          { name: 'LstDados', value: JSON.stringify(lstCadastro) }
        ]);

      return result;

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setAll', error.message);
    }
  }
}
