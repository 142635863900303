import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';

@Injectable({
  providedIn: 'root'
})
export class AuthChildGuardService implements CanActivateChild {

  constructor(
    private log: LogService,
    private cnf: ConfigStateService) { }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean | UrlTree {
    try {
      // Autorizar a página de login
      if (route.url[0].path === 'login' || (route.url[0].path === 'user' && route.url[1].path === 'login')) {
        return true;
      }
      return this.cnf.isLogged(); // ? true : this.router.parseUrl('/pwa');
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'canActivateChild', error.message);
    }
    return false;
  }


}
