import { IScheduledTimingRepeat } from './ischeduled-timing-repeat';
import { IScheduled } from './ischeduled';

export interface IScheduledTiming {
    scheduledtimingIdentifier: string;
    event: Date;
    repeatIdentifier: string;
    scheduledTimingRepeat: IScheduledTimingRepeat[];
    code: String;
    tenantId: number;
    language: String;
    scheduled: IScheduled[];
}