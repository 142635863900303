import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFrameDialog } from '../../interface/iframe-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.css']
})
export class IFrameDialogComponent implements OnInit {

  url = '';
  attachmentsCount = 0;

  constructor(
    public dialogRef: MatDialogRef<IFrameDialogComponent>,
    router: Router,
    route: ActivatedRoute,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: IFrameDialog,
  ) {
    this.url = this.data?.url; // sanitizer.sanitize(SecurityContext.URL, this.data.url);
    // router.navigate(['rpt', 'screening-adhd']);
    // router.navigate([{ outlets: { iframe: ['rpt', 'screening-adhd'] } }], { skipLocationChange: true, relativeTo: route.parent });
    // router.navigateByUrl('/(iframe:rpt/screening-adhd)');
  }

  onNoClick(): void {
    this.dialogRef.close(this.attachmentsCount);
  }

  ngOnInit() {
  }

}
