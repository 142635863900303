import { EnSortBy, EnViewMode, ISplash } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';

export enum MedlogicActionTypes {
    LoadApp = '[All Page Components] LOAD_APP',
    SetSearch = '[Page Person List] SET_SEARCH',
    SuccessLoadApp = '[App Effect] SUCCESS_LOAD_APP',
    FailLoadApp = '[App Effect] FAIL_LOAD_APP',
    SetViewMode = '[Page Splash Component] SET_VIEW_MODE',
    SetIsLoading = '[Page Welcome] APP_SET_IS_LOADING',
    SetIsMobile = '[Page Welcome] APP_SET_IS_MOBILE',
    DetectIsMobile = '[Page Welcome] APP_DETECT_IS_MOBILE',
}

export const loadApp = createAction(MedlogicActionTypes.LoadApp, props<{ title: string, isLoading: boolean, enViewMode: EnViewMode }>());
export const setIsLoading = createAction(MedlogicActionTypes.SetIsLoading, props<{ isLoading: boolean }>());
export const setIsMobile = createAction(MedlogicActionTypes.SetIsMobile, props<{ isMobile: boolean }>());
export const detectIsMobile = createAction(MedlogicActionTypes.DetectIsMobile);
export const setViewMode = createAction(MedlogicActionTypes.SetViewMode, props<{ enViewMode: EnViewMode }>());
export const setSearch = createAction(MedlogicActionTypes.SetSearch, props<{ search: string, showInactivated?: boolean, enSortBy?: EnSortBy }>());
export const successLoadApp = createAction(MedlogicActionTypes.SuccessLoadApp, props<{ splashes: ISplash[] }>());
export const failLoadApp = createAction(MedlogicActionTypes.FailLoadApp);
