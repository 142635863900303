import { IDialogData } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-multiline-dialog',
  templateUrl: './multiline-dialog.component.html',
  styleUrls: ['./multiline-dialog.component.css']
})
export class MultilineDialogComponent implements OnInit {

  error = new Array<{ id: string, titulo: string }>();
  sucesso = new Array<{ newId: number, id: string, titulo: string }>();
  value: string;
  isReadOnly = false;

  constructor(
    public dialogRef: MatDialogRef<MultilineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: IDialogData) {
    this.value = data?.item?.value || '';
    this.isReadOnly = data?.item?.isReadOnly || false;
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onConfirmClick($event: any): void {
    this.dialogRef.close(this.value);
  }

  ngOnInit() {
  }


}
