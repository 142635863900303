import { ConfigStateService } from '@medlogic/shared/state-config';
import { LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { CalculadoraConditionService } from '../shared/service/calculadora-condition.service';

@Directive({
  selector: '[libStudioLayout]'
})
export class StudioLayoutDirective implements OnInit {

  private el: HTMLElement;
  private topMargin = 100;

  constructor(
    el: ElementRef,
    private log: LogService,
    private cnf: ConfigStateService,
    private calc: CalculadoraConditionService
  ) { this.el = el.nativeElement; }

  @Input('libStudioLayout') ctrl: IAtividadeComponenteDAL;
  @Input() isMobile: boolean;

  ngOnInit(): void {
    try {
      if (this.isMobile) {
      }
      const className = this.ctrl?.StyleText;
      this.el.className = className;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }


}
