import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { BaseNavigationService } from '@medlogic/shared/shared-data-access';

@Injectable()
export class NavigationService extends BaseNavigationService {


  constructor(router: Router, protected glb: GlobalService, protected log: LogService) {
    super(router);
  }

  /* Navega para uma rota fornecida como parâmetro.
   * Iniciar com / para que seja uma rota relativa.
   */
  gotoRoute(route: any[]): void {
    this.router.navigate(route);
  }

  /* Retornará para uma rota já navegada, removendo todo o histórico após essa rota.
  */
  goBackTo(routeTitle: string): void {
    try {
      const findIndex = BaseNavigationService.history.findIndex((f) => this.glb.isEqual(f.title, routeTitle));
      if (findIndex >= 0) {
        const route = BaseNavigationService.history[findIndex].route;
        BaseNavigationService.history.splice(findIndex, 99999);
        this.router.navigate(route);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'goBackTo', error.message);
    }
  }

  /* ATIVIDADE */

  getRouteActivityEdit(ano: number, ono: number, readOnly: boolean): any[] {
    return [`/ge/activity/${ano}/${ono}/${readOnly}`];
  }

  getRouteActivityNew(ano: number): any[] {
    return [`/ge/activity/${ano}`];
  }

  // EDIT/READD
  gotoActivity(ano: number, ono: number, readOnly: boolean): void {
    this.router.navigate(this.getRouteActivityEdit(ano, ono, readOnly));
  }

  // NEW
  gotoActivityNew(ano: number): void {
    this.router.navigate(this.getRouteActivityNew(ano));
  }

  /* ATIVIDADE PROCESSO */
  gotoProcessActivityListNew(uno: number, pno: number, saveInList: boolean): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteProcessActivityListNew(uno, pno, saveInList));
  }

  getRouteProcessActivityListNew(uno: number, pno: number, saveInList: boolean): any[] {
    return [`/ge/processactivity/${uno}/${pno}/${saveInList}`];
  }

  gotoProcessActivityListEdit(
    uno: number,
    pno: number,
    ono: number,
    tno: number,
    readOnly: boolean,
    saveInList: boolean
  ): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteProcessActivityListEdit(uno, pno, ono, tno, readOnly, saveInList));
  }

  getRouteProcessActivityListEdit(
    uno: number,
    pno: number,
    ono: number,
    tno: number,
    saveInList: boolean,
    readOnly: boolean
  ): any[] {
    return [`/ge/processactivity/${uno}/${pno}/${ono}/${tno}/${saveInList}/${readOnly}`];
  }

  /* MULTIATIVIDADE PROCESSO */
  gotoMultiActivityListNew(uno: number, pno: number, ano: number, tno: number): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteMultiActivityListNew(uno, pno, ano, tno));
  }

  getRouteMultiActivityListNew(uno: number, pno: number, ano: number, tno: number): any[] {
    return [`/ge/multiactivitylist/${uno}/${pno}/${ano}/${tno}`];
  }

  gotoMultiActivityListEdit(uno: number, pno: number, ano: number, tno: number, ono: number): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteMultiActivityListEdit(uno, pno, ano, tno, ono));
  }

  getRouteMultiActivityListEdit(uno: number, pno: number, ano: number, tno: number, ono: number): any[] {
    return [`/ge/multiactivitylist/${uno}/${pno}/${ano}/${tno}/${ono}`];
  }

  // gotoMultiActivityNew(uno: number, pno: number, ano: number, tno: number): void {
  // 	// , readOnly: boolean, saveInList: boolean
  // 	this.router.navigate(this.getRouteMultiActivityNew(uno, pno, ano, tno));
  // }

  // getRouteMultiActivityNew(uno: number, pno: number, ano: number, tno: number): any[] {
  // 	return [ `/ge/multiactivity/${uno}/${pno}/${ano}/${tno}` ];
  // }

  gotoMultiActivityEdit(ano: number, pno: number, mano: number, ono: number): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteMultiActivityEdit(ano, pno, mano, ono));
  }

  getRouteMultiActivityEdit(ano: number, pno: number, mano: number, ono: number): any[] {
    return [`/ge/multiactivity/${ano}/${pno}/${mano}/${ono}`];
  }

  /* CADASTRO */

  // EDIT/READD

  gotoListEdit(ano: number, ono: number, readOnly: boolean, saveInList: boolean): void {
    this.router.navigate(this.getRouteListEdit(ano, ono, readOnly, saveInList));
  }

  // NEW
  gotoListNew(ano: number, saveInList: boolean): void {
    this.router.navigate(this.getRouteListNew(ano, saveInList));
  }

  // Atividade Cadastro
  getRouteListEdit(ano: number, ono: number, readOnly: boolean, saveInList: boolean): any[] {
    return [`/ge/list/${ano}/${ono}/${readOnly}/${saveInList}`];
  }

  getRouteListNew(ano: number, saveInList: boolean): any[] {
    return [`/ge/list/${ano}/${saveInList}`];
  }

  /* CADASTRO DETALHE */

  // EDIT/READ

  gotoListDetailEdit(ano: number, ono: number, listvno: number, readOnly: boolean, saveInList: boolean): void {
    this.router.navigate(this.getRouteListDetailEdit(ano, ono, listvno, readOnly, saveInList));
  }

  // NEW
  gotoListDetailNew(ano: number, listvno: number, saveInList: boolean): void {
    this.router.navigate(this.getRouteListDetailNew(ano, listvno, saveInList));
  }

  // Atividade Cadastro
  getRouteListDetailEdit(ano: number, ono: number, listvno: number, readOnly: boolean, saveInList: boolean): any[] {
    return [`/ge/listdetail/${ano}/${ono}/${listvno}/${readOnly}/${saveInList}`];
  }

  getRouteListDetailNew(ano: number, listvno: number, saveInList: boolean): any[] {
    return [`/ge/listdetail/${ano}/${listvno}/${saveInList}`];
  }

  // TAREFA
  getRouteSchedule(): any[] {
    return [`ge/schedule`];
  }

  gotoSchedule(): void {
    this.router.navigate(this.getRouteSchedule());
  }

  // AGENDA
  getRouteTask(date: string, time: string): any[] {
    date = date.replace(/\//gi, '-');
    return [`ge/task/${date}/${time}`];
  }

  gotoTask(date: string, time: string): void {
    date = date.replace(/\//gi, '-');
    this.router.navigate(this.getRouteTask(date, time));
  }

  // ASSIST
  // CADASTRO Sinais Vitais - Single Page
  getRouteVitalSign(vitalsignNo: number): any[] {
    return [`assist/vitalsign/${vitalsignNo}`];
  }

  gotoVitalSign(vitalsignNo: number): void {
    this.router.navigate(this.getRouteVitalSign(vitalsignNo));
  }

  getRouteCardMedication(modeloConfigNo: number): any[] {
    return [`/assist/medication/${modeloConfigNo}`];
  }

  gotoCardMedication(modeloConfigNo: number): void {
    this.router.navigate(this.getRouteCardMedication(modeloConfigNo));
  }

  getRouteLongitudinalHistory(vitalsignNo: number, patientCod: number, patientName: string, YYYYMMdd: string): any[] {
    return [`assist/history/${vitalsignNo}/${patientCod}/${patientName}/${YYYYMMdd}`];
  }

  gotoLongitudinalHistory(vitalsignNo: number, patientCod: number, patientName: string, YYYYMMdd: string): void {
    this.router.navigate(this.getRouteLongitudinalHistory(vitalsignNo, patientCod, patientName, YYYYMMdd));
  }

  getRouteCareMap(modeloConfigNo: number = null): any[] {
    if (modeloConfigNo) {
      return [`assist/caretable/${modeloConfigNo}`];
    } else {
      return [`assist/caretable`];
    }
  }

  gotoCareMap(modeloConfigNo: number = null): void {
    this.router.navigate(this.getRouteCareMap(modeloConfigNo));
  }

  gotoTimeline(): void {
    this.router.navigate(this.getRouteTimeline());
  }
  getRouteTimeline(): any[] {
    return [`/rpt/Timeline`];
  }

  // ERP

  gotoErpGrid(modeloConfigNo: number): void {
    this.router.navigate(this.getRouteErpGrid(modeloConfigNo));
  }

  getRouteErpGrid(modeloConfigNo: number): any[] {
    return [`/erp/grid/${modeloConfigNo}`];
  }

  getRouteErpMatrix(modeloConfigNo: number): any[] {
    return [`matrix/${modeloConfigNo}`];
  }

  getRouteErpInvoice(modeloConfigNo: number): any[] {
    return [`/invoice/${modeloConfigNo}`];
  }

  getRouteErpStock(modeloConfigNo: number): any[] {
    return [`/erp/stock/${modeloConfigNo}`];
  }

  getRouteErpBank(modeloConfigNo: number): any[] {
    return [`/erp/bank/${modeloConfigNo}`];
  }

  getRouteErpPurchase(modeloConfigNo: number): any[] {
    return [`/erp/purchase/${modeloConfigNo}`];
  }

  gotoErpAddSupplier(modeloConfigNo: number): void {
    this.router.navigate(this.getErpAddSupplier(modeloConfigNo));
  }

  getErpAddSupplier(modeloConfigNo: number): any[] {
    return this.getRouteListNew(modeloConfigNo, true);
  }

  // RPT
  gotoRptSeparationLabel(modeloConfigNo: number): void {
    this.router.navigate(this.getRouteRptSeparationLabel(modeloConfigNo));
  }
  getRouteRptSeparationLabel(modeloConfigNo: number): any[] {
    return ['/rpt', 'separation', 'label', modeloConfigNo];
  }

  // SEARCH

  // gotoSearchModule(moduleName: ): void {
  // 	this.router.navigate([moduleName]);
  // }

  /* Vai para a página inicial da aplicação. */
  gotoRoot(cadConfigNo: number, forceRefresh: boolean = false): void {
    this.cadRootConfigNo = cadConfigNo;
    if (forceRefresh) {
      const rnd = Math.round(Math.random() * 100);
      this.router.navigate(this.getRouteRoot(cadConfigNo), { queryParams: { refresh: rnd } });
      // Adiciona um parâmetro qualquer para forçar refresh.
    } else {
      this.router.navigate(this.getRouteRoot(cadConfigNo));
    }
  }

  /* Retorna a rota inicial da medicação, que é a página com os pacientes.
* Para direcionamento, utilizar gotoRoot.
*/
  getRouteRoot(cadConfigNo: number): any[] {
    return this.getRouteMedicationHome(cadConfigNo);
  }

  /* Retorna a rota inicial da medicação, que é a página com os pacientes.
  * Para direcionamento, utilizar gotoMedicationHome.
  */
  getRouteMedicationHome(cadConfigNo: number): any[] {
    return [`assist/carecard/${cadConfigNo}`];
  }


  getRouteEvolution(evolutionNo: number): any[] {
    return [`assist/evolution/${evolutionNo}`];
  }

  gotoEvolution(evolutionNo: number): void {
    this.router.navigate(this.getRouteEvolution(evolutionNo));
  }

  getRouteEmergencyData(ResFamilyNo: number): any[] {
    return [`assist/emergencydata/${ResFamilyNo}`];
  }

  gotoEmergencyData(ResFamilyNo: number): void {
    this.router.navigate(this.getRouteEmergencyData(ResFamilyNo));
  }


  getRouteCardPatient(modeloConfigNo: number): any[] {
    return [`assist/carecard/${modeloConfigNo}`];
  }

  gotoCardPatient(modeloConfigNo: number): void {
    this.router.navigate(this.getRouteCardPatient(modeloConfigNo));
  }

  getRouteLogin(): any[] {
    return [`assist/login`];
  }

  // EDIT/READD
  gotoLogin(): void {
    this.router.navigate(this.getRouteLogin());
  }

}
