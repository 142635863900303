<div class="row show-grid" [class.black]="enTheme === 'black'">
  <div>
    <label class="font-bold no-margins">Ocorrência:</label>
    <span>{{getOcorrencia()}}</span>
    <span *ngIf="showVariable"> ({{cnfOno}})</span>
  </div>
  <div>
    <label class="font-bold no-margins">Atividade:</label>
    <span>{{ atividadeNo }} - {{ atividadeNome }}</span>
  </div>
</div>
