import { IWebsocketState } from './iwebsocket-state';
import { IAppState, EnViewMode, IQueueState, EnSortBy } from '@medlogic/shared/shared-interfaces';
import { ILoginState, ITenantState } from '@medlogic/shared/shared-interfaces';
import { IExameResultadoState } from './iexame-resultado-state';
import { IPersonState } from './iperson-state';
import { IHorariosState } from './ihorarios-state';
import { ITutorialState } from './itutorial-state';
import { IExameState } from './iexame-state';
import { IFrailtyState } from './ifrailty-state';
import { IPatientState } from './ipatient-state';
import { IMedlogicEvolutionState } from './ievolution-state';
import { IMedicationState } from './imedication-state';
import { IStockState } from './istock-state';
import { IVitalSignState } from './ivital-sign-state';
import { IIvcfState } from './iivcf-state';
import { IPrescriptionState } from './iprescription-state';

export interface IAppMedlogicState extends IAppState {
    title: string;
    isLoading: boolean;
    isMobile: boolean;
    enViewMode: EnViewMode;
    search: string;
    showInactivated: boolean;
    enSortBy: EnSortBy;
    exame: IExameState;
    exameResultado: IExameResultadoState;
    login: ILoginState;
    loginPWA: ILoginState; // O pwa está com outro state de login. FIXME: Analisar possibilidade de unificação.
    person: IPersonState;
    tenant: ITenantState;
    splashes: any;
    appMedlogic: IAppMedlogicState;
    frailty: IFrailtyState;
    patient: IPatientState;
    evolutions: IMedlogicEvolutionState;
    medication: IMedicationState;
    stock: IStockState;
    vitalSign: IVitalSignState;
    queue: IQueueState;
    ivcf: IIvcfState;
    tutorial: ITutorialState;
    prescription: IPrescriptionState;
    horarios: IHorariosState;
    isLoadingStack?: boolean[];
    websocket: IWebsocketState;
}
