import { createAction, props } from '@ngrx/store';
import { IAccount } from '../../interface/iaccount';

export enum AccountActionTypes {
  LoadAccount = '[Account Component] Load Account',
  FetchSuccessAccount = '[Account API] Fetch Success Account', // Set the Account to the Store, after Effects
  FetchErrorAccount = '[Account API] Fetch Error Account',
  SetAccount = '[Account Component] Set Account',
  SelectAccount = '[Account Component] Select Account',
  UpdateAccount = '[Account Component] Update Account',
  GetByCode = '[Code Validation Component] Get By Code',
  FetchSuccessAccountByCode = '[Account API] Fetch Success Account By Code', // Set the Account to the Store, after Effects
}

export const loadAccount = createAction(AccountActionTypes.LoadAccount);
export const setAccount = createAction(AccountActionTypes.SetAccount, props<{ allAccounts: IAccount[] }>());
export const fetchSuccessAccount = createAction(AccountActionTypes.FetchSuccessAccount, props<{ allAccounts: IAccount[] }>());
export const selectAccount = createAction(AccountActionTypes.SelectAccount, props<{ selectedAccount: IAccount }>());
export const fetchErrorAccount = createAction(AccountActionTypes.FetchErrorAccount);
export const updateAccount = createAction(AccountActionTypes.UpdateAccount, props<{ account: IAccount }>());
export const getAccountByCode = createAction(AccountActionTypes.GetByCode, props<{ validationCode: string }>());
export const fetchSuccessAccountByCode = createAction(AccountActionTypes.FetchSuccessAccountByCode, props<{ selectedAccount: IAccount }>());

