import { IExameResultado } from './iexame-resultado';
export interface IExame {
    ocorrenciaNo: number;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    descricao: any;
    valorMaximoPASistolica: any;
    categoria: string;
    mENSAGEMDEALERTAMaior: string;
    mENSAGEMDEALERTAMenor: string;
    cadNo: any;
    mENSAGEMDEALERTAEXAMENORMAL: string;
	valorMINPASistolica:  any;
	valorMAXPASistolica:  any;
    valorMINPADiastolica:  any;
	valorMAXPADiastolica:  any;
	unidademedida:  string;
	valorMinimoReferencia:  any;
	valorMaximoReferencia:  any;
    // calculados
    resultados?: IExameResultado[];
}
