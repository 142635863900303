import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LibService } from '@medlogic/shared/geform';
import { ProfessionalService } from './professional.service';
import { of } from 'rxjs';
import { GlobalService, IMedlogicProfessional, LogService } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../pwa/service/config-pwa-medlogic.custom.service';

@Injectable()
export class ProfessionalCustomService extends ProfessionalService {

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnf: ConfigPwaMedLogicService,
    lib: LibService,
    log: LogService
  ) {
    super(cadastroSrv, glb, cnf, lib, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna um funcionário pelo login (V_101674). */
  getOne(cadastroNo: number, login: number): Observable<IMedlogicProfessional> {
    try {
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const filter = `V_101674:${login}`;
      return this.getFiltered(cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getOne', error.message);
    }
    return of(null);
  }


}
