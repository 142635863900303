export interface IFamily {
    ocorrenciaNo: number;
    nomeHospede: any;
    ruaAv: any;
    complemento: any;
    numero: any;
    bairro: any;
    estado: string;
    idade: any;
    sexo: string;
    cEP: any;
    receberNotificacoesPorSMS: boolean;
    receberNotificacoesPormail: boolean;
    cidade: string;
    telefoneFixo: any;
    celular: any;
    endereco: any;
    prontuarioHosp: any;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    nome: any;
    parentesco: string;
    status: any;
    descreva: any;
    status2: any;
    eMail: any;
    rG: any;
    cPF: any;
    observacoes: string;
    endereco2: any;
    identificacao: any;
    tipoRG: string;
    uF: string;
    familiarReferencia: boolean;
    enderecoRespInternacao: any;
    cURADOR: any;
    rESPINTERN: any;
    rESPINTERN2: any;
    rESPINTERN3: any;
    motivo: string;
    visitaProibida: boolean;
    loginFamiliar: any;
    testeCampoCheckbox: any;
}
