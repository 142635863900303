
export interface IListItem<T = any> {
  id: any;
  identification1?: any;
  identification2?: any;
  topLeft?: string;
  bottomLeft?: string;
  topRight?: string;
  bottomRight?: string;
  color?: any;
  imgUrl?: string;

  // opcionais
  obj?: T; // objeto original
  ono?: number;
  tno?: number;
  ano?: number;
  title?: string;
  relationshipId?: number;
}
