import { selectHasMedicationsByPatient, selectNumOfMedicationsByPatient } from '@medlogic/medlogic/medlogic-state';
import { Component, OnInit, Input } from '@angular/core';
import { AppLogService, IPatient } from '@medlogic/shared/shared-interfaces';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { swapImagePerson } from '../../service/pwa-util';

@Component({
  selector: 'ml-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

  @Input() patient: IPatient = null;
  @Input() showMiniImg: boolean;

  numOfMedications$: Observable<number>;
  hasMedication$: Observable<boolean>;

  constructor(
    private log: AppLogService,
    private glb: GlobalService,
    private store: Store<IAppMedlogicState>
  ) { }

  ngOnInit() {
    // Necessário carregar aqui, pois o patient precisa estar populado
    this.numOfMedications$ = this.store.select(selectNumOfMedicationsByPatient(this.patient?.codigo));
    this.hasMedication$ = this.store.select(selectHasMedicationsByPatient(this.patient?.codigo, this.patient?.calcMedicationsCount));
    // this.numOfMedications$ = this.store.select(selectNumOfMedicationsByPatient(this.patient?.codigo));
    // this.store.select(selectNumOfMedicationsByPatient(this.patient?.codigo)).pipe(take(1)).subscribe(res => this.numMedication = res);
    // this.store.select(selectHasMedicationsByPatient(this.patient?.codigo, this.patient?.calcMedicationsCount)).pipe(take(1)).subscribe(res => this.hasMedication = res);
  }

  /* Retorna a idade baseado na data de nascimento. */
  getAge(birthDate: Date): number {
    try {
      if (birthDate !== undefined) {
        return this.glb.GetYearsOld(this.glb.getTypedValue(birthDate).value);
      } else {
        return 0;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAge', error.message);
    }
  }

  // /* Retorna o número total de medicamentos cadastrados para o paciente */
  // getNumOfMedications(patient: IPatient): number {
  //   try {
  //     return (patient && patient.medications) ?
  //       new MedicationPendingPipe(this.log).transform(patient.medications).length :
  //       0;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getNumOfMedications', error.message);
  //   }
  // }
  swapImage(img: HTMLImageElement | EventTarget, patient: IPatient): void {
    try {
      swapImagePerson(img, patient?.sexo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'swapImage', error.message);
    }
  }

}
