import { ICostCenter, ICostCenterState } from '@medlogic/shared/shared-interfaces';

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as CostCenterActions from './cost-center.actions';

export const costCentersFeatureKey = 'costCenter';

export const adapterCostCenter: EntityAdapter<ICostCenter> = createEntityAdapter<ICostCenter>({
  selectId: instance => instance ? +instance.codigo : undefined,
});

export const initialStateCostCenter: ICostCenterState = adapterCostCenter.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: undefined,
});

export const reducer = createReducer(
  initialStateCostCenter,
  on(CostCenterActions.setCostCenter,
    (state, action) => adapterCostCenter.addOne(action.costCenter, state)
  ),
  on(CostCenterActions.fetchSuccessSetCostCenter,
    (state, action) => adapterCostCenter.upsertOne(action.costCenter, state)
  ),
  on(CostCenterActions.fetchSuccessSetAllCostCenter,
    (state, action) => adapterCostCenter.setAll(action.costCenters, state)
  ),
  on(CostCenterActions.fetchSuccessCostCenter,
    (state, action) => ({
      ...state,
      selectedId: action?.selectedId,
      error: undefined
    })
  ),
  on(CostCenterActions.fetchErrorCostCenter,
    (state, action) => ({
      ...state,
      selectedId: undefined,
      error: action?.error
    })
  ),
  on(CostCenterActions.cleanCostCenter, (state, payload) => ({
    ...initialStateCostCenter
  })),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterCostCenter.getSelectors();