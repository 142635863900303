import { createAction, props } from '@ngrx/store';
import { IListItem } from '../../interface/ilist-item';

export enum GenericActionTypes {
  LoadGeneric = '[Generic Component] Load Generic',
  FetchSuccessGeneric = '[Generic API] Fetch Success Generic', // Set the Generic to the Store, after Effects
  FetchErrorGeneric = '[Generic API] Fetch Error Generic',
  SetGeneric = '[Generic Component] Set Generic',
  SelectGeneric = '[Generic Component] Select Generic',
  UpdateGeneric = '[Generic Component] Update Generic',
  GetByCode = '[Code Validation Component] Get By Code',
  FetchSuccessGenericByCode = '[Generic API] Fetch Success Generic By Code', // Set the Generic to the Store, after Effects
}

export const loadGeneric = createAction(GenericActionTypes.LoadGeneric, props<{ genericId: number }>());
export const setGeneric = createAction(GenericActionTypes.SetGeneric, props<{ allGenerics: IListItem[] }>());
export const fetchSuccessGeneric = createAction(GenericActionTypes.FetchSuccessGeneric, props<{ allGenerics: IListItem[] }>());
export const selectGeneric = createAction(GenericActionTypes.SelectGeneric, props<{ selectedGeneric: IListItem }>());
export const fetchErrorGeneric = createAction(GenericActionTypes.FetchErrorGeneric);
export const updateGeneric = createAction(GenericActionTypes.UpdateGeneric, props<{ generic: IListItem }>());
export const getGenericByCode = createAction(GenericActionTypes.GetByCode, props<{ validationCode: string }>());
export const fetchSuccessGenericByCode = createAction(GenericActionTypes.FetchSuccessGenericByCode,
  props<{ selectedGeneric: IListItem }>());
