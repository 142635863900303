import { SvgIconService } from '@medlogic/shared/material';
import { Component, OnInit } from '@angular/core';
import { selectSelectedLogin } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { AppMedlogicPwaCuidadoState } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { select, Store } from '@ngrx/store';
import { IPractitioner } from '@medlogic/shared/shared-interfaces';
import { FhirPractitionerService } from '@medlogic/fhir';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'ml-ui-care-plan-practitioner',
  templateUrl: './ui-care-plan-practitioner.component.html',
  styleUrls: ['./ui-care-plan-practitioner.component.css']
})
export class UiCarePlanPractitionerComponent implements OnInit {

  selectedPractitioner$ = this.store.pipe(select(selectSelectedLogin));

  fhirPerformerPractitioner: IPractitioner;

  constructor(
    svgIconSrv: SvgIconService,
    private pracFhrSrv: FhirPractitionerService,
    private store: Store<AppMedlogicPwaCuidadoState>
    ) { }

  async ngOnInit() {

    const selectedLogin = await firstValueFrom(this.selectedPractitioner$).then((res) => {
      console.log("selectedPractitioner: ", res);
      return res.usuarioLogadoNome; }
      );
    this.fhirPerformerPractitioner = await firstValueFrom(this.pracFhrSrv.getFhirPractitionerByUserLogin(selectedLogin));
  }


}
