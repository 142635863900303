import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(
    private log: LogService,
    private domSanitizer: DomSanitizer) { }

  transform(value: any, args?: any): any {
    try {
      if (value) {
        return this.domSanitizer.bypassSecurityTrustUrl(value);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return null;
  }

}
