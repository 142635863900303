import { IScheduledPeriod } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const setScheduledPeriod = createAction(
  '[ScheduledPeriod/API] Set ScheduledPeriod',
  props<{ selectedId: number }>()
);

export const loadScheduledPeriods = createAction(
  '[ScheduledPeriod/API] Load ScheduledPeriods'
);

export const loadScheduledPeriodsSuccess = createAction(
  '[ScheduledPeriod/API] Load ScheduledPeriods Success',
  props<{ scheduledPeriods: IScheduledPeriod[] }>()
);

export const scheduledPeriodFail = createAction(
  '[ScheduledPeriod/API] ScheduledPeriod Fail',
  props<{ error: string }>()
);

export const addScheduledPeriod = createAction(
  '[ScheduledPeriod/API] Add ScheduledPeriod',
  props<{ scheduledPeriod: IScheduledPeriod }>()
);

export const addScheduledPeriods = createAction(
  '[ScheduledPeriod/API] Add ScheduledPeriods',
  props<{ scheduledPeriods: IScheduledPeriod[] }>()
);

export const upsertScheduledPeriod = createAction(
  '[ScheduledPeriod/API] Upsert ScheduledPeriod',
  props<{ scheduledPeriod: IScheduledPeriod }>()
);

export const upsertScheduledPeriods = createAction(
  '[ScheduledPeriod/API] Upsert ScheduledPeriods',
  props<{ scheduledPeriods: IScheduledPeriod[] }>()
);

export const updateScheduledPeriod = createAction(
  '[ScheduledPeriod/API] Update ScheduledPeriod',
  props<{ scheduledPeriod: Update<IScheduledPeriod> }>()
);

export const updateScheduledPeriods = createAction(
  '[ScheduledPeriod/API] Update ScheduledPeriods',
  props<{ scheduledPeriods: Update<IScheduledPeriod>[] }>()
);

export const deleteScheduledPeriod = createAction(
  '[ScheduledPeriod/API] Delete ScheduledPeriod',
  props<{ id: string }>()
);

export const deleteScheduledPeriods = createAction(
  '[ScheduledPeriod/API] Delete ScheduledPeriods',
  props<{ ids: string[] }>()
);

export const clearScheduledPeriods = createAction(
  '[ScheduledPeriod/API] Clear ScheduledPeriods'
);
export const clearSelectedScheduledPeriod = createAction(
  '[Card ScheduledPeriod] Clear Selected ScheduledPeriods'
);
