import { Injectable } from '@angular/core';
import { routeHumanName } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirHumanNameService extends FhirGenericService {

  protected url = routeHumanName;

}
