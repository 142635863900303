import { Injectable } from '@angular/core';
import { routeCarePlanIntent } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';


@Injectable({ providedIn: 'root' })
export class FhirCarePlanIntentService extends FhirGenericService {

  protected url = routeCarePlanIntent;

}
