import { createReducer, on, Action } from '@ngrx/store';
import {
  loadIvcf, setIvcf, fetchSuccessIvcf, fetchErrorIvcf,
  saveSuccessIvcf, saveFailIvcf, saveAllIvcf, saveIvcf,
  resetIvcf, loadIvcfsAndResultados, cleanIvcf,
} from './ivcf.actions';
import { IIvcfState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IIvcf } from '@medlogic/shared/shared-interfaces';
import { update } from '@medlogic/shared/utils';

export const initialState: IIvcfState = {
  ivcf: null,
  ivcfs: [],
  error: null,
  ivcfId: null
};

const ivcfReducerObj = createReducer(initialState,
  on(loadIvcf, (state, payload) => ({
    ...state,
    bannerTitle: 'IVCF-20',
    ivcf: null,
    ivcfs: [],
    ivcfId: payload?.ivcfId
  })),
  on(loadIvcfsAndResultados, (state, payload) => ({
    ...state,
    bannerTitle: 'IVCF-20',
    ivcf: null,
    ivcfs: []
  })),
  on(resetIvcf, (state, payload) => initialState),
  on(fetchSuccessIvcf, (state, payload) => ({
    ...state,
    ivcf: payload?.ivcf,
    error: null
  })),
  on(setIvcf, (state, payload) => ({
    ...state,
    ivcf: payload?.ivcf,
    error: null
  })),
  on(saveIvcf, (state, payload) => ({
    ...state,
    ivcf: payload?.ivcf,
    error: null
  })),
  on(fetchErrorIvcf, (state, payload) => ({
    ...state,
    ivcfs: null,
    error: 'invalid-token'
  })),
  on(saveAllIvcf, (state, payload) => ({
    ...state, // Aproximação otimista, ou seja, altera o estado mesmo antes do retorno do serviço.
    ivcfs: update<IIvcf>(state?.ivcfs, payload?.ivcf),
    ivcf: payload?.ivcf,
    error: null
  })),
  on(saveSuccessIvcf, (state, payload) => {
    return ({
      ...state,
      ivcfs: update<IIvcf>(state?.ivcfs, payload?.ivcf),
      ivcf: payload?.ivcf,
      error: null
    });
  }),
  on(saveFailIvcf, (state, payload) => ({
    ...state,
    ivcfs: update<IIvcf>(state?.ivcfs, payload?.ivcf),
    ivcf: payload?.ivcf,
    error: 'fail-on-save--ivcf'
  })),
  on(cleanIvcf, () => ({ ...initialState }))
);

export function ivcfReducer(state: IIvcfState | undefined, action: Action) {
  return ivcfReducerObj(state, action);
}
