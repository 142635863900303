import { ConfigJsonService, ICostCenterState } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CostCenterActionTypes, fetchErrorCostCenter,
  fetchSuccessSetAllCostCenter, fetchSuccessSetCostCenter
} from './cost-center.actions';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { CostCenterCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { ICostCenter } from '@medlogic/shared/shared-interfaces';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';

@Injectable()
export class CostCenterEffects {

  constructor(
    private cnfJson: ConfigJsonService,
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<ICostCenterState | any>,
    private costCenterSrv: CostCenterCustomService
  ) { }

  loadCostCenter$ = createEffect(() => this.actions$
    .pipe(
      ofType(CostCenterActionTypes.LoadCostCenter),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.costCenterSrv.getAll(state?.tenant?.selectedTenant?.cadCentroCustoNo)
          .pipe(
            toArray(),
            switchMap((costCenters: ICostCenter[]) => [
              costCenters ? fetchSuccessSetAllCostCenter({ costCenters }) : fetchErrorCostCenter({ error: 'falha loadCostCenter'}),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              this.store.dispatch(setIsLoading({ isLoading: false }));
              return of(fetchErrorCostCenter({ error: 'falha loadCostCenter'}));
            })
          );
      })
    )
  );

  checkCostCenterAndInsert$ = createEffect(() => this.actions$
    .pipe(
      ofType(CostCenterActionTypes.CheckCostCenterAndInsert),
      mergeMap((action: any) => {
        return of(action)
          .pipe(
            withLatestFrom(this.store),
            mergeMap(([never, state]) => {
              return this.costCenterSrv.getByPatientId(state?.tenant?.selectedTenant?.cadCentroCustoNo, state?.person?.person?.codigo)
                .pipe(
                  switchMap((costCenter: any) => {
                    if (costCenter) {
                      return [
                        fetchSuccessSetCostCenter({ costCenter }),
                        setIsLoading({ isLoading: false })
                      ];
                    }
                    return this.insertCostCenter$(
                      state?.tenant?.selectedTenant?.cadCentroCustoNo,
                      state?.tenant?.login?.usuarioLogadoNo,
                      state?.person?.person?.codigo,
                      state?.person?.person?.nomeResidente
                    ).pipe(
                      mergeMap(() => [fetchSuccessSetCostCenter({ costCenter }), setIsLoading({ isLoading: false })])
                    )
                  }),
                  catchError((e: any) => {
                    console.log(e);
                    this.store.dispatch(setIsLoading({ isLoading: false }));
                    return of(fetchErrorCostCenter({ error: 'falha checkCostCenterAndInsert' }));
                  })
                );
            })
          )
      })
    )
  );

  insertCostCenter$ = (ano: number, uno: number, codigoHospede: string, costCenterName: string) => {
    const costCenter = {
      titulo: costCenterName,
      habilitado: true,
      codigoHospede
    } as ICostCenter;
    return this.costCenterSrv.save<ICostCenter>(ano, costCenter, uno);
  }


}
