import { IPractitioner } from './ipractitioner';
import { IActivityDetail } from './iactivity-detail';
import { IContributorOnActivity } from './icontributor-on-activity';

export interface IActivityContributor {
  activityContributorIdentifier: string;
  contributorIdentifier: string;
	practitioner: IPractitioner[];
  activityDetailIdentifier: string;
	// activityDetail: IActivityDetail[];
  tenantId: number;
  language: string;
  contributorOnActivity: IContributorOnActivity[];
}
