import { Injectable } from '@angular/core';
import { routeActivityType } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirActivityTypeService extends FhirGenericService {

  protected url = routeActivityType;

  // private data = [
  //   {
  //     activityTypeCodeableConceptIdentify: '1',
  //     activityIdentifier: '1',
  //     activity: '1',
  //     type: EnActivityType.Alimentacao,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  //   {
  //     activityTypeCodeableConceptIdentify: '2',
  //     activityIdentifier: '2',
  //     activity: '2',
  //     type: EnActivityType.Banho,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  //   {
  //     activityTypeCodeableConceptIdentify: '3',
  //     activityIdentifier: '3',
  //     activity: '3',
  //     type: EnActivityType.Alimentacao,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  //   {
  //     activityTypeCodeableConceptIdentify: '4',
  //     activityIdentifier: '4',
  //     activity: '4',
  //     type: EnActivityType.MudancaDecubito,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  //   {
  //     activityTypeCodeableConceptIdentify: '5',
  //     activityIdentifier: '5',
  //     activity: '5',
  //     type: EnActivityType.HigieneOral,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  //   {
  //     activityTypeCodeableConceptIdentify: '5',
  //     activityIdentifier: '5',
  //     activity: '5',
  //     type: EnActivityType.HigieneIntima,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  //   {
  //     activityTypeCodeableConceptIdentify: '7',
  //     activityIdentifier: '7',
  //     activity: '7',
  //     type: EnActivityType.Vestuario,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  //   {
  //     activityTypeCodeableConceptIdentify: '8',
  //     activityIdentifier: '8',
  //     activity: '8',
  //     type: EnActivityType.Fralda,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  //   {
  //     activityTypeCodeableConceptIdentify: '9',
  //     activityIdentifier: '9',
  //     activity: '9',
  //     type: EnActivityType.Banho,
  //     tenantId: 1,
  //     language: 'pt-BR',
  //   } as unknown as IActivityTypeCodeableConcept,
  // ];


}
