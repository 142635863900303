import { IPatientState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';

import * as fromPatient from './patient.reducer';

export const selectPatientState = createFeatureSelector<IPatientState>(fromPatient.patientsFeatureKey);
export const selectPatientAll = createSelector(selectPatientState, fromPatient.selectAll);

export const selectPatientAllActive = createSelector(
  selectPatientAll,
  patients => patients
    .filter(patient => patient.situacaoPaciente && !patient.pacienteFalecido)
    .sort((a, b) => a?.nomeHospede?.localeCompare(b?.nomeHospede))
);

export const selectPatientAllActiveWithPendingMedications = createSelector(
  selectPatientAllActive,
  patients => patients
    .filter(patient => patient?.medications?.filter(f => !f.took).length > 0 || false)
);

export const isPatientLoading = createSelector(
  selectPatientAll,
  patients => patients?.length <= 0,
);

export const selectedPatient = createSelector(selectPatientState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const selectedPatientId = createSelector(selectPatientState, state => {
  return state?.selectedId > 0 ? state?.selectedId : null;
});
