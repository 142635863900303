import { map } from 'rxjs/operators';
import {
  EnExpirationType, IValidateToken, IValidateTokenResult,
  routeGenerateToken, routeValidateToken
} from '@medlogic/shared/shared-interfaces';
import { BasePageService } from './base-page.service';
import { Observable } from 'rxjs';
import { LogService, EnRequestType, EnContentType } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  // protected urlTokenGenerate = `Token/Generate?expiration={0}&enExpirationType={1}`;
  protected urlTokenGenerate = `${routeGenerateToken}?expiration={0}&enExpirationType={1}`;
  // protected urlTokenValidate = `Token/Validate?token={0}`;
  protected urlTokenValidate = `${routeValidateToken}?token={0}`;

  constructor(
    private log: LogService,
    private basepage: BasePageService,
  ) { }

  /* POST: Gera um token com um período específico de validação.
  * É possível embutir um objeto do tipo { key1: value1, key2: value2 ...}
  * que poderá ser resgatado no momento de validação do token.
  * Atenção: expiration e enExpirationType devem ser enviados como query params.
  * o objeto deve ser enviado no body como x-www-form-urlencode e poderá
  * conter as propriedades e seus respectivos valores, conforme desejado.
  */
  generateToken(obj: any, expiration: number, enExpirationType: EnExpirationType): Observable<IValidateToken> {
    try {
      const url = this.basepage.format(this.urlTokenGenerate, expiration, enExpirationType);
      return this.basepage.baseDados(EnRequestType.Post, url, obj, 2, EnContentType.Json)
        .pipe(
          map(m => (m as IValidateToken))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'generateToken', error.message);
    }
    return of(null);
  }

  /* GET: Se o token for inválido ou estiver expirado, retorna mensagem de erro.
  * Se for válido, retorna o objeto gerado no token, caso exista.
  * Enviar o query param: token
  */
  validateToken(token: string): Observable<IValidateTokenResult> {
    try {
      const url = this.basepage.format(this.urlTokenValidate, token);
      return this.basepage.baseDadosReplay(EnRequestType.Get, url, {}, 2, EnContentType.Json)
        .pipe(
          map(m => ({ ...m, token } as IValidateTokenResult))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'validateToken', error.message);
    }
    return of(null);
  }


}
