import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IActivityDetailState } from './activityDetail-state';

import * as fromActivityDetail from './activityDetail.reducer';

export const selectActivityDetailState = createFeatureSelector<IActivityDetailState>(fromActivityDetail.activityDetailFeatureKey); //TODO: antes estava activitiesFeatureKey (Validar) 
export const selectActivityDetailAll = createSelector(selectActivityDetailState, fromActivityDetail.selectAll);

//TODO: Validar este trecho de código. Não tenho certeza se está correto
export const selectActivityDetailAllActive = createSelector(
  selectActivityDetailAll,
  activityDetails => activityDetails
    .filter(activityDetail => ['43A6FD48-3429-ED11-AB4B-0AF88D461817' ,'C1E76F51-3429-ED11-AB4B-0AF88D461817'].includes(activityDetail.activityStatusIdentifier) /*&& !activityDetail.pacienteFalecido*/)
    .sort((a, b) => a?.activityStatusIdentifier?.localeCompare(b?.activityStatusIdentifier))
);

export const selectedActivityDetail = createSelector(selectActivityDetailState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const isActivityDetailLoading = createSelector(
  selectActivityDetailAll,
  activityDetails => activityDetails?.length <= 0,
);

export const selectedActivityDetailIdentifier = createSelector(selectActivityDetailState, state => {
  return (state?.selectedId || 0) > 0 ? (state?.selectedId || 0) : null;
});
