export interface ICustomerConfig {
    cADBANCO: string;
    cADBENEFICIARIO: string;
    cadIntervecoesMedicamentos: string;
    cadEstoqueMateriais: string;
    cadPaciente: string;
    cadCheckMedicamentos: string;
    cadIntervencoesHorario: string;
    cadCheckSinaisVitais: string;
    cadRotinasCuidadoHospede: string;
    cadCheckinRotinaCuidado: string;
    cadSinaisVitaisSinglePage: string;
    cADENTRADAFLUXODECAIXA: string;
    cADESTOQUEDEMATERIAIS: string;
    cADESCALAS: string;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    cadBeneficiario: string;
    cadCentroCusto: string;
    cadUnidade: string;
    cadUnidadeNegocio: string;
    cadItemEstoque: string;
    cADENTRADAFLUXODECAIXA2: string;
    cadDescricao: string;
    cadCategoria: string;
    cliente: string;
    fornecedor: string;
    funcionario: string;
    produto: string;
    cadEvolucaoSinglePage: string;
    cADFONTE: string;
    cADMEIOPAGAMENTO: string;
    cadTipoMaterial:  string;
    cADCLASSE: string;
    cADPERIODICIDADE: string;
}