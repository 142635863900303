export interface IAutorizacaoCuidado {
    ocorrenciaNo: number;
    nomePaciente: string;
    dtInicioDtFim: any;
    prontuarioIdoso: any;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    nomeCuidador: any;
    loginCuidador: string;
    dataCadastro: any;
    inicioCuidados: Date;
    fimCuidados: Date;
}
