import { ConfigStateService } from '@medlogic/shared/state-config';
import { ICadastroListaDAL } from '../../interface/icadastro-lista-dal';
import { ObjectDAL } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { WebService } from '@medlogic/shared/shared-data-access';

@Injectable()
export class CadastroListaDAL extends ObjectDAL {

  constructor(webService: WebService, log: LogService, global: GlobalService,
    public cnf: ConfigStateService
    ) {
    super(webService, log, global);
  }

  getAll(
    cadastroNo: number,
    usuarioNo: number,
    variavelRetornoNo: number,
    type: string,
    atividadeComponenteNo: number
  ): Observable<ICadastroListaDAL> {
    try {
      return this.getWithCache<ICadastroListaDAL>(
        this.getCadastroLista,
        cadastroNo,
        usuarioNo,
        variavelRetornoNo,
        type,
        atividadeComponenteNo
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  getFiltered(
    cadastroNo: number,
    variavelComparacaoNo: number,
    valorFiltro: string,
    variavelNoRetorno: number,
    atividadeComponenteNo: number
  ): Observable<ICadastroListaDAL> {
    try {
      if (this.global.IsNullOrEmptyGE(valorFiltro, true, true)) {
        return of(null);
      }

      return this.getWithCache<ICadastroListaDAL>(
        this.getCadastroFiltrado,
        cadastroNo,
        variavelComparacaoNo,
        valorFiltro,
        variavelNoRetorno,
        atividadeComponenteNo
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  protected getCadastroLista(
    cadastroNo: number,
    usuarioNo: number,
    variavelRetornoNo: number,
    type: string,
    atividadeComponenteNo: number
  ): Observable<ICadastroListaDAL[]> {
    try {
      const method = 'getCadastroLista';
      return this.webService.connectWithCache<ICadastroListaDAL[]>(method, [
        { name: 'CadastroNo', value: cadastroNo },
        { name: 'UsuarioNo', value: usuarioNo },
        { name: 'VariavelRetornoNo', value: variavelRetornoNo },
        { name: 'Type', value: type },
        { name: 'AtividadeComponenteNo', value: atividadeComponenteNo }
      ]);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadastroLista', error.message);
    }
  }

  /* Para cascata. */
  protected getCadastroFiltrado(
    cadastroNo: number,
    variavelComparacaoNo: number,
    valorFiltro: string,
    variavelNoRetorno: string,
    atividadeComponenteNo: number
  ): Observable<ICadastroListaDAL[]> {
    try {
      const method = 'getCadastroFiltrado';
      return this.webService.connectWithCache<ICadastroListaDAL[]>(method, [
        { name: 'cadastroNo', value: cadastroNo },
        { name: 'variavelComparacaoNo', value: variavelComparacaoNo },
        { name: 'valorFiltro', value: valorFiltro },
        { name: 'variavelNoRetorno', value: variavelNoRetorno },
        { name: 'AtividadeComponenteNo', value: atividadeComponenteNo }
      ]);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCadastroFiltrado', error.message);
    }
  }

  /* A princípio, não há como saber se o método foi getCadastroFiltrado ou getCadastroLista.
  * Por esse motivo, tentará excluir o cache de ambos os métodos, com os respectivos parâmetros.
  */
  cleanCacheWithParams(params: Array<any>): void {
    try {
      try {
        this.cleanCache('getCadastroLista', params);
      } catch (e) { }
      try {
        this.cleanCache('getCadastroFiltrado', params);
      } catch (e) { }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCache', error.message);
    }
  }

}
