// import { IPersonRelationship } from './addhere/iperson-relationship';
export interface IPerson {
	ocorrenciaNo: number;
	dtCadastro: Date;
	codigo: any;
	nomeCompleto: any;
	habilitado: boolean;
	dataNascimento: Date;
	partoPrematuro: boolean;
	nasceutermo: boolean;
	vacinacao: boolean;
	nomeresponsavelLegal: string;
	grauparentesco: string;
	nomeeducador: string;
	funcaoprofissionalResponsavel: string;
	nomeescola: string;
	concordoComusoimagem: boolean;
	idEscola: any;
	eMailcontato: any;
	usuarioExecutor: any;
	titulo: any;
	anoEscolar: any;
	genero: boolean;
	idade: any;
	inicioAntes12Anos: any;
	profissionalResponsavel: string;
	nomeEducadorProfissionalResponsavel: any;
	imgUrl: string;
	// calculados
	// idade: number;

	// responsaveis: IPersonRelationship[];
	// profissionais: IPersonRelationship[];
	// educadores: IPersonRelationship[];
}
