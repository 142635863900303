import { IProcess } from './iprocess';
import { EnModuleMode } from './../enum/en-module-mode.enum';
import { EnModulo } from '../enum/en-modulo.enum';
import { IModuleInfo } from './imodule-info';

export interface IModule {
  UrlIcon: string;
  Label: string;
  Type: EnModulo;
  ObjInfo?: IModuleInfo;
  LabelMiniInfo?: string;
  enModuleMode?: EnModuleMode;
  IsActive?: boolean;
  ComplementaryVar1?: number;
  ComplementaryVar2?: number;
  ComplementaryVar3?: number;
  ComplementaryVar4?: number;
  ComplementaryVar5?: number;
  ObjProcess?: IProcess;
}
