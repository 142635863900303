import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessService } from '@medlogic/shared/shared-data-access';
import { MsgPtBR, LogService, IPatient, IModule } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { of } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { IAtividade } from '../../../shared/interface/iatividade';
import { mergeMap } from 'rxjs/operators';
import { ConfigStateService } from '@medlogic/shared/state-config';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-user-process',
  templateUrl: './user-process.component.html',
  styleUrls: ['./user-process.component.css']
})
export class UserProcessComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() patient: IPatient;
  @Input() token: string;
  @Output() clickProcessEmitter = new EventEmitter<any>();
  usrProcess$: Observable<IModule[]>;

  constructor(
    private cnf: ConfigStateService,
    private log: LogService,
    private msg: MsgPtBR,
    private processSrv: ProcessService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.subs.sink = this.refresh(this.token).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /** Carregamento principal. */
  protected refresh(token: string): Observable<IAtividade> {
    try {
      this.usrProcess$ = this.getUserProcess(token);
      return this.usrProcess$
        .pipe(
          mergeMap(p => of(null))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /** Resgata todos os processos que o usuário tem permissão para acessar. */
  protected getUserProcess(token: string): Observable<IModule[]> {
    try {
      const process$ = this.processSrv.getDataProcess().pipe(toArray());
      return process$;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUserProcess', error.message);
    }
    return of(null);
  }

  /** Clique em um dos processos, que são carregados dinamicamente. */
  onClickProcess(process: IModule, patient: IPatient): void {
    try {
      this.cnf.selectedPatient = patient;
      this.clickProcessEmitter.emit(process.ObjInfo);
      // this.nav.gotoGenericActivity(process.ObjInfo.AtividadeNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClickProcess', error.message);
    }
  }


}
