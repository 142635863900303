import { IPerson } from './iperson';
import { EnUserRole } from '../enum/en-user-role.enum';
import { EnInviteStatus } from '../enum/en-invite-status.enum';
export interface IPersonState {
    person: IPerson;
    persons: IPerson[];
    error?: any;
    relationshipOno?: number;
    enUserRole?: EnUserRole;
    enInviteStatus?: EnInviteStatus;
    relationshipName?: string;
    relationshipEmail?: string;
    relationshipUno?: number;
}
