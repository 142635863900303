import { ConfigStateService } from '@medlogic/shared/state-config';
import { Control } from '../../model/control';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, ConfigJsonService, IAtividadeComponenteDAL, EnTheme } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraService } from '../../service/calculadora.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { FormGroup, FormControl } from '@angular/forms';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';
import * as moment from 'moment';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-date',
  templateUrl: './ctr-date.component.html',
  styleUrls: ['./ctr-date.component.css']
})
export class CtrDateComponent extends Control implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  ptBr: any;
  date: FormControl;

  // get date(): AbstractControl {
  //   const control = this.getFormControlFrom(this.ctrl);
  //   control?.setValue(this.ctrl.ValorData);
  //   const typed = this.global.getTypedValue(control?.value);
  //   if (typed?.type === EnTypedValue.Date && control?.value !== typed?.value) {
  //     const td = new Date();
  //     const value = typed?.value as Date;
  //     const dt = (value.getFullYear(), value.getMonth(), value.getDate(), td.getHours(), td.getMinutes(), td.getSeconds());
  //     control?.setValue(dt);
  //   }
  //   return control;
  // }

  // get date(): AbstractControl {
  //   const control = this.getFormControlFrom(this.ctrl);
  //   const typed = this.global.getTypedValue(control?.value);
  //   if (typed?.type === EnTypedValue.Date && control?.value !== typed?.value) {
  //     const td = new Date();
  //     const value = typed?.value as Date;
  //     const dt = (value.getFullYear(), value.getMonth(), value.getDate(), td.getHours(), td.getMinutes(), td.getSeconds());
  //     control?.setValue(dt);
  //   }
  //   return control;
  // }

  // set date(v: FormControl) {
  // 	const control = this.getFormControlFrom(this.ctrl);
  // 	const typed = this.global.getTypedValue(v.value);
  // 	if (typed.type === EnTypedValue.Date && control?.value !== typed.value) {
  // 		control?.setValue(typed.value);
  // 	}
  // }

  /*Ao invés de utilizar formControlName está sendo utilizada essa propriedade, pois o carregamento do valor não está funcionando.
   * É um problema com esse componente de Date do Google Material.
   */
  // private _dtValue: Date;
  // public get dtValue(): Date {
  // 	return this._dtValue;
  // }
  // public set dtValue(v: Date) {
  // 	try {
  // 		const typed = this.global.getTypedValue(v);
  // 		this._dtValue = typed.value;
  // 		if (isDate(this._dtValue)) {
  // 			this.ctrl.ValorData = this._dtValue;
  // 			// this.ctrl.ValorTexto = this.global.DateToddMMYYYY(v);
  // 			this.ctrl.ValorDataMMddyyyy = this.global.DateToddMMYYYY(this._dtValue);
  // 			this.ctrl.Valor = this.global.dateToYYYYMMddThhmmss(this._dtValue);
  // 			// Para disparar o evento de change necessário marcar o controle como dirty (AtividadeComponente.subscribeFormChanges)
  // 			const dtPicker = this.formGroup.get(this.getId(this.ctrl));
  // 			dtPicker.markAsDirty();
  // 			dtPicker.setValue(this._dtValue);
  // 		}
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'set.dtValue', error.message);
  // 	}
  // }

  public mask2 = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR
  ) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }

  ngOnInit(): void {
    try {
      super.ngOnInit();
      // const dt = this.global.getTypedValue(this.ctrl.ValorData).value;
      this.date = new FormControl(this.insertCurrentTime(this.ctrl.ValorData));
      this.subs.sink = this.date.valueChanges
        .subscribe(changed => {
          // const control = this.getFormControlFrom(this.ctrl);
          this.setValue(this.insertCurrentTime(changed));
          // control.setValue(changed, { onlySelf: true, emitEvent: true });
        });
      // this.dtValue = this.ctrl?.ValorData || this.global.getTypedValue(this.ctrl?.ValorTexto).value || new Date();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  protected insertCurrentTime(date: any): Date {
    try {
      if (date) {
        moment.locale('pt-br');
        const _ = moment();
        date = moment(date);
        if (date.hours() === 0 && date.minutes() === 0 && date.seconds() === 0) {
          date.add({ hours: _.hour(), minutes: _.minute(), seconds: _.second() });
        }
        return date?.toDate();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertCurrentTime', error.message);
    }
    return null;
  }

  /*Usuário selecionou uma data.  */
  // currentPeriodClicked($event: any, dtInput: any): void {
  // 	try {
  // 		this.dtValue = dtInput.nativeControl.value;
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'currentPeriodClicked', error.message);
  // 	}
  // }

  /*override
   * Largura fixa, uma vez que se trata de componente de data.
   */
  getWidth(ctrl: IAtividadeComponenteDAL, ...args): string {
    try {
      if (this.global.isMobile()) {
        return `100%`;
      } else {
        return `100px`;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWidth', error.message);
    }
    return '';
  }

  /*override
   * Resgata o valor do formControl */
  // protected getValueFrom(variavelNo: number): any {
  // 	try {
  // 		return this.global.dateToddMMYYYY(this.formGroup.controls[this.lib.getId(variavelNo)].value);
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'getValueFrom', error.message);
  // 	}
  // }

  /*Evento de keyUp do input de data. Será aplicada máscara de data. */
  // onKeyUp(event: any): void {
  // 	try {
  // 		const value = event.target.value;
  // 		if (event.code !== 'Backspace') {
  // 			if (value.length === 2 || value.length === 5) {
  // 				event.target.value += '/';
  // 			}
  // 		}
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'onkeyUp', error.message);
  // 	}
  // 	return null;
  // }

  /*Evento do input de data. Ajustes para apagar o número antes da barra se a barra for o próximo caractere. */
  // onBackspace(event: any): void {
  // 	try {
  // 		const value = event.target.value;
  // 		if (value.endsWith('/')) {
  // 			event.target.value = value.slice(0, value.length - 1);
  // 		}
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'onBackspace', error.message);
  // 	}
  // }
}
