
import { AppLogService, IFhirPatient, IPatient } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { select, Store } from '@ngrx/store';
import { selectedPatient } from '@medlogic/medlogic/medlogic-state';
// TODO: risco de circular dependencia
import { swapImagePerson } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { map, mergeMap, of } from 'rxjs';
import { FhirPatientService } from '@medlogic/fhir';
import { MovimentacaoDeLeitoCustomService } from '@medlogic/medlogic/medlogic-data-access';

@Component({
  selector: 'ml-ui-care-plan-avatar',
  templateUrl: './ui-care-plan-avatar.component.html',
  styleUrls: ['./ui-care-plan-avatar.component.css']
})
export class UiCarePlanAvatarComponent implements OnInit {

  selectedPatient$ = this.store.pipe(select(selectedPatient));
  selectedFhirPatient$ = this.store.pipe(select(selectedPatient),
    mergeMap(patient => this.fhirPatientSrv.getOne<IFhirPatient>(patient.codigo)));
  showMiniImg = true;
  selectedPatientsLocation$ = this.store.pipe(select(selectedPatient),
  mergeMap(patient => this.movimentacaoLeitoSrv.getLocationByMedlogicId(patient.codigo)));

  constructor(
    private log: AppLogService,
    private store: Store<IAppMedlogicState>,
    private fhirPatientSrv: FhirPatientService,
    private movimentacaoLeitoSrv: MovimentacaoDeLeitoCustomService
  ) { }

  ngOnInit() {}

  swapImage(img: EventTarget, patient: IPatient): void {
    try {
      swapImagePerson(img, patient?.sexo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'swapImage', error.message);
    }
  }


}
