import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { metaReducers } from './+state/storage.metareducer';
import * as fromHorarios from './+state/horarios.reducer';
import { HorariosEffects } from './+state/horarios.effects';



const SERVICES = [
];

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromHorarios.horariosFeatureKey, fromHorarios.reducer, { metaReducers }),
  EffectsModule.forFeature([HorariosEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})
export class StateHorariosModule { }
