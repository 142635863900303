import { QueueService } from '@medlogic/shared/shared-data-access';
import { GlobalService, IQueueState } from '@medlogic/shared/shared-interfaces';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LogService } from '@medlogic/shared/shared-interfaces';
import {
  catchError,
  concatMap,
  flatMap,
  map,
  mergeMap,
  reduce,
  switchMap,
  tap,
  withLatestFrom,
  finalize,
} from 'rxjs/operators';
import {
  dequeue,
  executeQueue,
  queueFail,
  setQueueIsExecuting,
} from './queue.actions';
import { EMPTY, of } from 'rxjs';

import * as fromQueue from './queue.reducer';
import { queue } from '@medlogic/shared/state-queue';
import { selectQueueAll } from './queue.selectors';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';

@Injectable()
export class QueueEffects {
  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private storeQueue: Store<IQueueState>,
    private store: Store<IAppMedlogicState>,
    private queueSrv: QueueService
  ) { }

  executeQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(executeQueue),
      withLatestFrom(this.store),
      tap(() =>
        this.store.dispatch(setQueueIsExecuting({ isExecuting: true }))
      ),
      concatMap(([action, state]) => {
        if (state?.queue?.isExecuting) {
          const { ids, entities } = state.queue;
          return of([...ids].map((id) => entities[id]));
        } else {
          return EMPTY;
        }
      }),
      flatMap((queue) => queue),
      concatMap((queue) => {
        // tem a intenção de garantir a ordem de execução e saída dos itens
        return this.queueSrv.executeQueue(queue);
      }),
      switchMap((key: string) => {
        return [dequeue()]
      }),
      catchError((e: any) => {
        console.log(e);
        this.store.dispatch(setQueueIsExecuting({ isExecuting: false }));
        return of(queueFail());
      }),
      // finalize(() => {
      //   return this.store.dispatch(setQueueIsExecuting({ isExecuting: false }))
      // }),
    )
  );

}
