<div class="grid">
  <h1>{{ title }}</h1>
  <mat-form-field class="search">
    <input type="text" matInput placeholder="Search..." [(ngModel)]="search" (keydown.esc)="search = ''">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  <app-generic-list [items$]="items$" [search]="search"
    (itemClick)="onItem_click($event, genericId, process, enTheme, readOnly)">
  </app-generic-list>
</div>
<button mat-fab class="btn-add" aria-label="Novo" (click)="onAdd_click(genericId, process, enTheme)">
  <mat-icon>add</mat-icon>
</button>
