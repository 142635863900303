import { refCount, publishReplay, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IIntervecoesMedicamentosHorarios } from '@medlogic/shared/shared-interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';


@Injectable()
export class IntervencoesMedicamentosHorariosService {
  private lstVariaveis = 'V_30392,V_30393,V_30394,V_30395,V_30401';

  recurrences: IIntervecoesMedicamentosHorarios[] = new Array<IIntervecoesMedicamentosHorarios>();

  cadastroNo = 4892;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService
  ) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IIntervecoesMedicamentosHorarios> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(): Observable<any> {
    try {
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(this.cadastroNo), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(
    cadastroNo: number,
    startDate: Date,
    endDate: Date
  ): Observable<IIntervecoesMedicamentosHorarios> {
    if (
      startDate.getTime() !== this.currentDtInicial.getTime() ||
      endDate.getTime() !== this.currentDtFinal.getTime() ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastro(cadastroNo, this.lstVariaveis, startDate, endDate).pipe(
        map(
          (c) => ({
            codigo: c.V_30392,
            titulo: c.V_30393,
            habilitado:
              c.V_30394.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_30394) ? true : false,
            hora: c.V_30395,
            codigoMedicamentoPaciente: c.V_30401
          } as IIntervecoesMedicamentosHorarios)
        ),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  protected getFiltered(
    cadastroNo: number,
    startDate: Date,
    endDate: Date
  ): Observable<IIntervecoesMedicamentosHorarios> {
    try {
      return this.getWithCache(cadastroNo, startDate, endDate);
      // .filter(f => !f.isRecurrent)
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  save(intervecoesMedicamentosHorarios: IIntervecoesMedicamentosHorarios, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(intervecoesMedicamentosHorarios).filter((f) => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(intervecoesMedicamentosHorarios: IIntervecoesMedicamentosHorarios): IForm[] {
    try {
      return [
        { VariavelNo: 30392, ValorDado: intervecoesMedicamentosHorarios.codigo || '' },
        { VariavelNo: 30393, ValorDado: intervecoesMedicamentosHorarios.titulo || '' },
        { VariavelNo: 30394, ValorDado: intervecoesMedicamentosHorarios.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 30395, ValorDado: intervecoesMedicamentosHorarios.hora || '' },
        { VariavelNo: 30401, ValorDado: intervecoesMedicamentosHorarios.codigoMedicamentoPaciente || '' }
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return [];
  }


}
