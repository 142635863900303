import { metaReducers } from './+state/exame-resultado.metareducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ExameResultadoEffects } from './+state/exame-resultado.effects';
import { exameResultadoReducer } from './+state/exame-resultado.reducer';

const MODULES = [
  CommonModule
];

const STATE_MODULES = [
  StoreModule.forFeature('exameResultado', exameResultadoReducer, { metaReducers }),
  EffectsModule.forFeature([ExameResultadoEffects]),
];

const SERVICES = [
]

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})


export class StateExameResultadoModule { }
