import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { LoginActionTypes } from './login.actions';
import { initialStateLogin } from './login.reducer';

const KEY = 'MEDLOGIC_USER_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  LoginActionTypes.DoLoginAndLoadRoot,
  LoginActionTypes.DoWebUserLogin,
  LoginActionTypes.SetLoginState,
  LoginActionTypes.SuccessLogin,
  LoginActionTypes.SetCustomerId
];

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialStateLogin);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
