import { Injectable } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';

@Injectable()
export class DisplayObject extends UnsubscribeOnDestroyAdapter {

  constructor() {
    super();
  }
}
