import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Pipe, PipeTransform } from '@angular/core';

/* Corrige a formatação de entrada de horas, por exemplo 8 vira 08:00 */
@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  constructor(
    private glb: GlobalService
  ) { }

  transform(value: string | number): string {
    try {
      return this.glb.formatTime(value?.toString());
    } catch {
    }
    return value?.toString();
  }


}
