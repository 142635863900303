import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  localStorageKey = 'GE_LOCAL_STORAGE_KEY';

  constructor(
  ) { }

  setSavedState(state: any, localStorageKey: string = null): void {
    try {
      localStorageKey = localStorageKey || this.localStorageKey;
      localStorage.setItem(localStorageKey, JSON.stringify(state));
    } catch (error) {
      console.log(this.constructor.name, 'setSavedState', error.message);
    }
  }

  getSavedState(localStorageKey: string = null): any {
    try {
      localStorageKey = localStorageKey || this.localStorageKey;
      const state = JSON.parse(localStorage.getItem(localStorageKey));
      return state;
    } catch (error) {
      console.log(this.constructor.name, 'getSavedState', error.message);
    }
    return null;
  }

  clean(localStorageKey: string = null): void {
    try {
      localStorageKey = localStorageKey || this.localStorageKey;
      localStorage.removeItem(localStorageKey);
    } catch (error) {
      console.log(this.constructor.name, 'clean', error.message);
    }
  }


}
