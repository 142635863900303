import { Injectable } from '@angular/core';
import { FhirGenericService } from './fhir-generic.service';
import { routeActivityDetailKind } from '@medlogic/shared/shared-interfaces';

@Injectable({ providedIn: 'root' })
export class FhirActivityDetailKindService extends FhirGenericService {

  protected url = routeActivityDetailKind;


}
