import { JsStoreService } from './js-store.service';
import { Injectable } from '@angular/core';
import { LogService, IKeyValue } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { TbStoreService } from './tb-store.service';
import { from } from 'rxjs';
import { Observable } from 'rxjs';
import { ITable, DATA_TYPE } from 'jsstore';
import { IColumnOption } from 'jsstore/dist/ts/common';

@Injectable({
    providedIn: 'root'
})
export class TbKeyValueService extends TbStoreService {

    constructor(
        jsStoreSrv: JsStoreService,
        glb: GlobalService,
        log: LogService,
    ) {
        super(jsStoreSrv, log, 'KeyValue', glb);
    }

    /* A inicialização do Banco de Dados depende que haja o esquema de cada tabela. */
    getTableSchema(): ITable {
        try {
            return {
                name: 'KeyValue',
                columns: {
                    key: {
                        notNull: true,
                        primaryKey: true,
                        dataType: DATA_TYPE.String
                    } as IColumnOption,
                    value: {
                        notNull: true,
                        dataType: DATA_TYPE.Object
                    } as IColumnOption
                }
            } as ITable;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getTableSchema', error.message);
        }
    }

    /* Override
    * Além do add, atualiza o contador de pendências.
    */
    add(item: IKeyValue): Promise<any> {
        try {
            const addItem = super.add(item);
            return addItem;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'add', error.message);
        }
    }

    /* Override
    * Além do delete, atualiza o contador de pendências.
    */
    deleteByKey(key: string): Promise<any> {
        try {
            const deleteItem = super.deleteByKey(key);
            return deleteItem;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'deleteByKey', error.message);
        }
    }

    /* Retorna o número de itens pendentes na fila (queue). */
    protected getNumOfPending(): Observable<number> {
        try {
            const count = this.get()
                .then(items => items && items.length > 0 ? items.length : 0);
            return from(count);
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getNumOfPending', error.message);
        }
    }


}
