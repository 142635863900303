
/*
* Public API Surface of ge-form
*/
export * from './ge-form.module';
export * from './ge-form-routing.module';

// ATENÇÃO: A exportação de alguns componentes aqui prejudica o uso, enquando outros são necessários (talvez referencia em rota vs. em html)
export * from './view/page-not-found-view/page-not-found-view.component';
// export * from './view/generic-activity-view/generic-activity-view.component';
// export * from './view/atividade-cadastro-view/atividade-cadastro-view.component';
// export * from './view/cadastro-list-view/cadastro-list-view.component';
// ENUM

export * from './shared/enum/en-bubble-event.enum';
export * from './shared/enum/en-currency.enum';
export * from './shared/enum/en-language.enum';
export * from './shared/enum/enum-atividade-tipo.enum';
export * from './shared/enum/enum-atribuicao-tipo.enum';
export * from './shared/enum/enum-ctr-type.enum';
export * from './shared/enum/enum-formula-element-type.enum';
export * from './shared/enum/enum-tipo-lista.enum';
export * from './shared/enum/input-mask-type';
// interfaces
export * from './shared/interface/ialert-message';
export * from './shared/interface/iatividade-sequencial';
export * from './shared/interface/iatividade';
export * from './shared/interface/ibubble';
export * from './shared/interface/icadastro-filter';
export * from './shared/interface/icadastro-list';
export * from './shared/interface/icadastro-lista-dal';
export * from './shared/interface/icadastro';
export * from './shared/interface/icampo-adicional';
export * from './shared/interface/icontrol';
export * from './shared/interface/idado';
export * from './shared/interface/iexecucao-tarefa';
export * from './shared/interface/iflash-obj';
export * from './shared/interface/ilist-detail-param';
export * from './shared/interface/ipasso';
export * from './shared/interface/ireplace';
export * from './shared/interface/iroute';
export * from './shared/interface/itarefa';
export * from './shared/interface/ivalidator';
export * from './shared/interface/ivariable';

// PIPE
export * from './shared/pipe/ctrl-grid-search.pipe';
export * from './shared/pipe/ctrl-mat-grid-search.pipe';
export * from './shared/pipe/filter-componentes-by-tab.pipe';
export * from './shared/pipe/task-search.pipe';
export * from './shared/pipe/visible-tabs.pipe';

// Exportação dos providers
export { ActionService } from './shared/service/action.service';
export { CalculadoraConditionService } from './shared/service/calculadora-condition.service';
export { CalculadoraService } from './shared/service/calculadora.service';
export { CalculatorService } from './shared/service/calculator.service';
export { AtividadeComponenteDAL } from './shared/model/dal/atividade-componente-dal';
export { CadastroListaDAL } from './shared/model/dal/cadastro-lista-dal';
export { DadoDAL } from './shared/model/dal/dado-dal';
export { OcorrenciaDAL } from './shared/model/dal/ocorrencia-dal';
export { AtividadeDAL } from './shared/model/dal/atividade-dal';
export { ExecucaoTarefaDAL } from './shared/model/dal/execucao-tarefa-dal';
export { ProcessoDAL } from './shared/model/dal/processo-dal';
export { ValidatorService } from './shared/service/validator.service';
export { LibService } from './shared/service/lib.service';
export { JavascriptLib } from './shared/service/javascript-lib.service';
export { ExpressionService } from './shared/service/expression.service';
export { ExpressionFunctionService } from './shared/service/expression-function.service';
export { ExpressionGridFunctionService } from './shared/service/expression-grid-function.service';
export { ExpressionServerFunctionService } from './shared/service/expression-server-function.service';
export { TokenService } from './shared/service/token.service';
export { SendEmailService } from './shared/service/send-email.service';
// Components
// export { AgendaViewComponent } from './view/agenda-view/agenda-view.component';
// export { AtividadeCadastroDetailDialogComponent } from './view/atividade-cadastro-detail-dialog/atividade-cadastro-detail-dialog.component';
// export { AtividadeCadastroViewComponent } from './view/atividade-cadastro-view/atividade-cadastro-view.component';
// export { AtividadeMultiProcessoComponent } from './view/atividade-multi-processo/atividade-multi-processo.component';
// export { AtividadeProcessoComponent } from './view/atividade-processo/atividade-processo.component';
// export { AtividadeStandAloneComponent } from './view/atividade-stand-alone/atividade-stand-alone.component';
// export { AtividadeViewComponent } from './view/atividade-view/atividade-view.component';
// export { BlueprintComponent } from './view/blueprint/blueprint.component';
// export { CadastroListViewComponent } from './view/cadastro-list-view/cadastro-list-view.component';
export { GenericActivityViewComponent } from './view/generic-activity-view/generic-activity-view.component';
export { PageNotFoundViewComponent } from './view/page-not-found-view/page-not-found-view.component';
// export { TarefaViewComponent } from './view/tarefa-view/tarefa-view.component';
// Commentados por estarem com erro de compilação ou serem desnecessários
// export { NavigationViewComponent } from './view/navigation-view/navigation-view.component';
