import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { IAppMedlogicState, IWebSocketContract } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WebSocketService } from '@medlogic/shared/utils';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import * as WebsocketActions from './websocket.actions';

@Injectable()
export class WebsocketEffects extends UnsubscribeOnDestroyAdapter {

  // FIXME: Websockets desativados até novo servidor.
  // listenWebsockets$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(WebsocketActions.listenWebsockets),
  //       withLatestFrom(this.store),
  //       mergeMap(([action, { prescription }]) => {
  //         return this.wsSrv.listen(prescription.selectedPrescription.ocorrenciaNo.toString());
  //       }),
  //       tap(t => console.log('listenWebsockets', t)),
  //       switchMap((connection: IWebSocketContract) => [
  //         ...[
  //           (connection ? WebsocketActions.listenWebsocketsSuccess({ connection }) : WebsocketActions.listenWebsocketsFailure({ error: status })),
  //           // WebsocketActions.openAlert({ messageHtml: `Prescrição salva com sucesso ${connection.id}. ${connection.message}` })
  //         ],
  //       ]),
  //       catchError(error => of(WebsocketActions.listenWebsocketsFailure({ error })))
  //     );
  // });

  // emitWebsockets$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(WebsocketActions.emitWebsockets),
  //       withLatestFrom(this.store),
  //       mergeMap(([action, { websocket, prescription }]) => this.wsSrv.emit(action.key, websocket.ids?.reverse()[0].toString(), action.message)),
  //       switchMap((connection: IWebSocketContract) => [
  //         ...[(status ? WebsocketActions.emitWebsocketsSuccess({ connection }) : WebsocketActions.emitWebsocketsFailure({ error: status }))],
  //       ]),
  //       catchError(error => of(WebsocketActions.emitWebsocketsFailure({ error })))
  //     );
  // });

  constructor(
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private wsSrv: WebSocketService,
  ) {
    super();
  }


}
