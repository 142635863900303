import { SnackbarNotificationService } from '@medlogic/shared/ui/ui-snackbar-notification';
import { setIsLoading } from './../../state-medlogic/+state/medlogic.actions';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { MedLogicNavigationService } from '@medlogic/medlogic/medlogic-navigation';
import { MedlogicTenantService } from '@medlogic/medlogic/medlogic-data-access';
import { ILoginWithUserResponse, EnUserRole, EnTheme, ITenantState, EnLoginState, ITenant } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, tap, retryWhen, concatMap, delay, defaultIfEmpty, switchMap } from 'rxjs/operators';
import { of, throwError, iif } from 'rxjs';
import { CadTenantService, LoginService } from '@medlogic/shared/shared-data-access';
import { Observable } from 'rxjs';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ILoginState, ILogin } from '@medlogic/shared/shared-interfaces';
import { failLogin, successLogOut, successLogin, failLogOut, LoginActionTypes, setLoginState } from './login.actions';
// import { setTenantToken, setTenant } from '@medlogic/medlogic/medlogic-state';
import { EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { setTenant, setTenantToken } from '../../medlogic-state-tenant/+state/medlogic-tenant.actions';
import { PersonCustomService } from '@medlogic/medlogic/medlogic-data-access';

// export interface ITenantSchool {
//   school?: ISchool,
//   selectedTenant: ITenant,
//   customerId?: number,
//   identification1?: any,
//   identification2?: any
//   selectedLogin?: any;
// }


// Nota: Esse componente teve que reunir effects relacionados a Login, mas também
// Tenant e School. Foi necessário desse modo, pois, caso contrário deveria
// disparar ações de tenant que por sua vez disparariam ações de login ao final,
// ou seja, gerando dependência recursiva.
@Injectable()
export class LoginEffects {

  /* Faz o login, baseado em usuário em senha.
  * O token do config será gravado durante a chamada de getLogin.
  * Retornará true se login positivo.
  */
  private login$ = () => mergeMap((state: ILoginState): Observable<ILogin | { message: string }> => {
    if (!this.glb.isNullOrEmpty(state?.userName) && !this.glb.isNullOrEmpty(state?.password)) {
      // Clean cached data, because new credentials will be set.
      this.cnf.reset();
      const ifInvalidStop = () => mergeMap((res: ILoginWithUserResponse | any) => {
        if (res.message === 'Unauthorized') {
          this.snackNotification.open('Usuário ou senha inválidos.', '', 3);
        } else if (res?.UsuarioToken) {
          return of({
            customerId: res?.customerId,
            token: res?.UsuarioToken,
            usuarioLogadoNo: res?.UsuarioNo,
            usuarioLogadoNome: state?.userName,
            tenantId: undefined,
            isAdm: res?.isAdm,
            roles: res.roles
          } as ILogin);
        }
        this.store.dispatch(setIsLoading({ isLoading: false }));
        this.store.dispatch(setLoginState({ enLoginState: EnLoginState.LoginFail }));
        return EMPTY;
      });
      return this.loginSrv.getLoginWithUser(state.userName || '', state.password || '')
        .pipe(
          ifInvalidStop(),
          tap((login) => this.store.dispatch(setLoginState({ enLoginState: EnLoginState.Loading }))),
          catchError((err, obs) => of({ message: 'error' }))
        );
    }
    return of({ message: 'Login fail!' });
  });


  private dispatchTenantToken$ = () => map((login: ILogin | { message: string }) => {
    if (!login.message) {
      this.store.dispatch(setTenantToken({ login, token: (login as ILogin)?.token || '' }));
    }
    return login;
  });

  private loadRoot = () => map((action: any) => {
    this.nav.navigateToRoot();
    // return successLogin({ selectedLogin });
    return action;
  })

  private loadPrivacy$ = () => map((action: any) => {
    this.nav.navigateToPrivacy();
    return action;
  })

  setTenantToken$ = () => mergeMap((resLogin: ILogin | { message: string }) =>
    of(resLogin)
      .pipe(
        this.getTenantAtividadeNo$(),
        this.fillCnf(),
        this.mapToITenantState$(),
        this.getCadTenant$(),
        this.setTenantAndInstitution(),
      )
  );

  private getTenantAtividadeNo$ = (maxRetries: number = 3, delayMs: number = 500) => mergeMap((login: ILogin | { message: string }) => {
    // Atenção: essa chamada, depende que o state tenant.token esteja preenchido,
    // o que ocorre na chamada anterior, mas faz a redução no tenant state.
    // Por isso é necessário o retry, para que dê tempo da redução ocorrer.
    return this.cadTenant.getCadTenantConfig()
      .pipe(
        delay(delayMs),
        mergeMap(tenant => {
          return tenant?.AtividadeNo ? of(tenant) : throwError('empty-result');
        }),
        retryWhen(errors => {
          return errors.pipe(
            concatMap((e, i) =>
              iif(() => i > maxRetries,
                throwError({ message: 'Número máximo de tentativas excedido!' }),
                of(e).pipe(delay(delayMs))
              )
            )
          )
        }),
        map(tenant =>
        ({
          ...login,
          tenantId: tenant?.AtividadeNo,
        } as ILogin)
        ),
      )
  });

  /* It's necessary because some lib services, such as ProcessService, try
  * to read from cnf properties.
  */
  private fillCnf = () => mergeMap((login: ILogin) => {
    if (!login?.message) {
      this.cnf.baseUsuarioToken = login?.token;
      this.cnf.usuarioLogadoNo = login?.usuarioLogadoNo;
      this.cnf.usuarioLogadoNome = login?.usuarioLogadoNome;
      this.cnf.tenantId = login?.tenantId;
      return of(login);
    } else {
      return EMPTY;
    }
  });

  private mapToITenantState$ = () => map((login: ILogin | { message: string }) => {
    if (!login?.message) {
      const userRole = (login as ILogin)?.isAdm ? EnUserRole.administrator : EnUserRole.customer;
      const tenantState = ({
        tenantId: (login as ILogin)?.tenantId,
        customerId: (login as ILogin)?.customerId,
        loggedUserName: (login as ILogin)?.usuarioLogadoNome,
        enTheme: EnTheme.black,
        userRole,
        token: (login as ILogin)?.token,
        userId: (login as ILogin)?.usuarioLogadoNo
      } as ITenantState);
      return { tenantState, login };
    }
    return { tenantState: undefined, login };
  })

  private getCadTenant$ = () => mergeMap<{ tenantState: ITenantState | undefined, login: ILogin | { message: string } }, Observable<ITenant | null>>(({ tenantState, login }) => {
    return tenantState ? this.tenantService.get(tenantState, false) : of(null);
    // .pipe(
    //   tap(t => {
    //     console.log(t);
    //   })
    // );
    // .pipe(map(m => login));
    // .pipe(this.fillUserRole$(false, login),);
  });

  // private fillUserRole$ = (isDebug: boolean, selectedLogin: any) => mergeMap((selectedTenant: ITenant) => {
  //   // FIXME: *** ATENÇÃO: A determinação do cliente não é mais ditada pela Escola que o próprio
  //   // usuário cadastrou, mas pelo relacionamento que o usuário tem com a escola CAD-ESCOLA-USUARIO
  //   // Se o usuário for associado a mais de uma escola, escolher a primeira.
  //   // .getByUser(selectedTenant?.schoolAno, selectedTenant?.userName, isDebug)
  //   return this.schoolUserSrv.getByUser(selectedTenant?.schoolUserAno, selectedTenant?.userName)
  //     .pipe(
  //       take(1), // Se houver mais de um cadastro, que seria irregular, retorna apenas o primeiro
  //       mergeMap(schoolUser => {
  //         return this.schoolSrv.getById(selectedTenant?.schoolAno, schoolUser?.idEscola);
  //       }),
  //       map((school: ISchool) => {
  //         return school ?
  //           ({ selectedLogin, school, selectedTenant, customerId: +school.codigo, identification1: school.codigo, identification2: school.nomeescola }) :
  //           ({ selectedLogin, selectedTenant });
  //       }),
  //     );
  // })

  private setTenantAndInstitution = () => switchMap((tenantInstitution: ITenant | null) => {

    if (!tenantInstitution) {
      return EMPTY;
    }

    const selectedLogin = tenantInstitution?.selectedLogin;
    const actionFailSuccess = !tenantInstitution?.selectedLogin?.message ? successLogin({ selectedLogin }) : failLogin({ selectedLogin });
    return [
      setTenant({
        customerId: +tenantInstitution?.customerId || 0,
        selectedTenant: tenantInstitution,
        identification1: tenantInstitution?.identification1,
        identification2: tenantInstitution?.identification2
      }),
      // setSchool({ school: tenantSchool?.school }), // ATENÇÃO: para manipular outro estado, nesse caso schoolState, é necessário importar o módulo correspondente.
      actionFailSuccess
    ];
  })

  constructor(
    private actions$: Actions,
    private loginSrv: LoginService,
    private glb: GlobalService,
    private cnf: ConfigStateService,
    private nav: MedLogicNavigationService,
    private store: Store<IAppMedlogicState>,
    private tenantService: MedlogicTenantService,
    private cadTenant: CadTenantService,
    private personSrv: PersonCustomService,
    private snackNotification: SnackbarNotificationService
  ) { }

  doLoginAndLoadRoot$ = createEffect(() => this.actions$
    .pipe(
      ofType(LoginActionTypes.DoLoginAndLoadRoot),
      this.login$(),
      this.dispatchTenantToken$(), // Necessário atualizar o tenant em função do token do qual depende cadTenant$
      this.setTenantToken$(),
      this.loadRoot(),
      defaultIfEmpty(failLogin({ selectedLogin: { message: 'Login fail!' } })),
      catchError((e) => {
        console.log(e);
        this.store.dispatch(setIsLoading({ isLoading: false }));
        return of(failLogin({ selectedLogin: { message: 'Login fail!' } }));
      })
    )
  );

  doWebUserLogin$ = createEffect(() => {
    return this.actions$
      .pipe(ofType(LoginActionTypes.DoWebUserLogin),
        this.login$(),
        this.dispatchTenantToken$(), // Necessário atualizar o tenant em função do token do qual depende cadTenant$
        this.setTenantToken$(),
        // this.loadPrivacy$(), O load será disparado em paralelo, uma vez que a privacidade não depende do token
        defaultIfEmpty(failLogin({ selectedLogin: { message: 'Login fail!' } })),
        catchError((e: any) => {
          console.log(e);
          return of(failLogin({ selectedLogin: { message: 'Login fail!' } }));
        }));
  });

  private cleanCacheSrv$ = () => tap((action: any) => this.personSrv.clearCache());

  // ATENÇÃO: a ação LogOut disparará um clean do state persistido em TODOS os metareducers. ver utilsmetareduces
  logOut$ = createEffect(() => this.actions$
    .pipe(
      ofType(LoginActionTypes.LogOut),
      map(() => successLogOut()),
      tap(() => this.loginSrv.logoff()),
      this.cleanCacheSrv$(),
      catchError((e) => {
        console.log(e);
        return of(failLogOut());
      })
    )
  );


}
