import { Injectable } from '@angular/core';
import { FhirGenericService } from './fhir-generic.service';
import { routeAddress } from '@medlogic/shared/shared-interfaces';

@Injectable({ providedIn: 'root' })
export class FhirAddressService extends FhirGenericService {

  protected url = routeAddress;


}
