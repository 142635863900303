import { IssuerIdentification } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, publishReplay, refCount, toArray, mergeMap } from 'rxjs/operators';
import { LibService } from '@medlogic/shared/geform';
import {
    LogService,
    ConfigJsonService, GlobalService, IForm, error, IServiceProvider
} from '@medlogic/shared/shared-interfaces';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';


export abstract class IssuerIdentificationService implements IServiceProvider {

    private lstVariaveis = 'V_104588,V_104589,V_104590,V_104591,V_104592,V_104593,V_104594,V_104595,V_104596,V_104597,V_104598,V_104599,V_104600,V_104601';
    private variavelGrid = '';
    private lstVariaveisGrid = '';

    recurrences: Array<IssuerIdentification> = new Array<IssuerIdentification>();

    codigoVariavelNo = 104588; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
    cadastroNo = 20242;
    currentDtInicial = new Date();
    currentDtFinal = new Date();
    cadastrosCache: Observable<any>;

    constructor(
        protected cadastroSrv: CadastroService,
        protected glb: GlobalService,
        protected cnf: ConfigJsonService,
        protected lib: LibService,
        protected log: LogService) {
        try {
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'constructor', error.message);
        }
    }

    /* Retorna somente as variáveis desejadas.
   * lstVariables do tipo: 'V_3332,V_32223'
   */
    getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IssuerIdentification> {
        try {
            this.cadastroNo = ano;
            startDate = startDate || new Date(1900, 0, 1);
            endDate = endDate || new Date(2500, 0, 1);
            return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getSome', error.message);
        }
        return of(null);
    }

    getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IssuerIdentification> {
        try {
            this.cadastroNo = ano;
            startDate = startDate || new Date(1900, 0, 1);
            endDate = endDate || new Date(2500, 0, 1);
            return this.getWithCache(this.cadastroNo, startDate, endDate);
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getAll', error.message);
        }
        return of(null);
    }

    /* Método utilizado para popular uma lista com os itens ativos. */
    loadArray(ano: number): Observable<any> {
        try {
            this.cadastroNo = ano;
            const propLabel = 'titulo';
            const propValue = 'codigo';
            const propEnabled = 'habilitado';
            return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'loadArray', error.message);
        }
    }

    /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
    clearCache(): void {
        try {
            this.cadastrosCache = null;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'clearCache', error.message);
        }
    }

    protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IssuerIdentification> {
        try {
            if (
                (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
                || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
                || (!this.cadastrosCache)
                // (startDate.getTime() !== this.currentDtInicial.getTime())
                // || (endDate.getTime() !== this.currentDtFinal.getTime())
                // || (!this.cadastrosCache)
            ) {
                this.currentDtInicial = startDate;
                this.currentDtFinal = endDate;
                this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
            } else {
                console.log('retorno do cache');
            }
            return this.cadastrosCache;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
        }
        return of(null);
    }

    protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
        try {
            this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
            this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
            lstVariables = lstVariables || this.lstVariaveis;
            console.log('Recarregando dados...');
            // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
            return this.cadastroSrv
                .getCadastro(cadastroNo, lstVariables, startDate, endDate)
                .pipe(
                    this.mapTo(),
                    publishReplay(),
                    refCount()
                );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
        }
        return of(null);
    }

    protected mapTo = () => map((c: any) => {
        return {
            ocorrenciaNo: c.OcorrenciaNo,
            codigo: c.V_104588,
            titulo: c.V_104589,
            habilitado: this.lib.getBoolean(c.V_104590),
            razaoSocial: c.V_104591,
            cNPJ: c.V_104592,
            descricao: c.V_104593,
            ruaAvenida: c.V_104594,
            numero: c.V_104595,
            cidade: c.V_104596,
            cEP: c.V_104597,
            telefone1: c.V_104598,
            estado: c.V_104599,
            telefone2: c.V_104600,
            bairro: c.V_104601,

        } as IssuerIdentification;
    })

    /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
    * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
    */
    protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date = null, endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IssuerIdentification> {
        try {
            this.cadastroSrv.dtInicial = startDate ? this.glb.dateToYYYYMMddThhmmss(startDate) : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
            this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
            lstVariables = lstVariables || this.lstVariaveis;
            return this.cadastroSrv
                .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd, startDate, endDate)
                .pipe(
                    this.mapTo(),
                    publishReplay(),
                    refCount()
                );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
        }
        return of(null);
    }

    /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
    save<T>(ano: number, medication: T, uno: number, id?: number): Observable<any> {
        try {
            this.cadastroNo = ano;
            const forms: IForm[] = this.mapToForm(medication as unknown as IssuerIdentification).filter(f => f.ValorDado);
            return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo, true)
                .pipe(
                    map(newId => ({ ...medication, codigo: newId, ocorrenciaNo: newId } as T)),
                    error()
                );

        } catch (error) {
            this.log.Registrar(this.constructor.name, 'save', error.message);
        }
        return of(null);
    }

    protected mapToForm(issuerIdentification: IssuerIdentification): IForm[] {
        try {
            return [
                { VariavelNo: 104588, ValorDado: issuerIdentification.codigo || '' },
                { VariavelNo: 104589, ValorDado: issuerIdentification.titulo || '' },
                { VariavelNo: 104590, ValorDado: issuerIdentification.habilitado ? 'SIM' : 'NÃO' },
                { VariavelNo: 104591, ValorDado: issuerIdentification.razaoSocial || '' },
                { VariavelNo: 104592, ValorDado: issuerIdentification.cNPJ || '' },
                { VariavelNo: 104593, ValorDado: issuerIdentification.descricao || '' },
                { VariavelNo: 104594, ValorDado: issuerIdentification.ruaAvenida || '' },
                { VariavelNo: 104595, ValorDado: issuerIdentification.numero || '' },
                { VariavelNo: 104596, ValorDado: issuerIdentification.cidade || '' },
                { VariavelNo: 104597, ValorDado: issuerIdentification.cEP || '' },
                { VariavelNo: 104598, ValorDado: issuerIdentification.telefone1 || '' },
                { VariavelNo: 104599, ValorDado: issuerIdentification.estado || '' },
                { VariavelNo: 104600, ValorDado: issuerIdentification.telefone2 || '' },
                { VariavelNo: 104601, ValorDado: issuerIdentification.bairro || '' },

            ];
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
        }
        return null;
    }

    /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
    insertIfNotExist<T>(ano: number, medication: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
        try {
            return this.getFromCadastro(ano, null, null)
                .pipe(
                    toArray(),
                    mergeMap(items => {
                        const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], medication[compareFieldName])) >= 0);
                        if (!founded) {
                            return this.save(ano, medication as unknown as IssuerIdentification, uno);
                        }
                        return of(founded);
                    })
                );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
        }
        return of(null);
    }


}
