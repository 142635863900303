import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService, LogService, ITarefaComponente } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'filterByIncludeNormal'
})
export class FilterByIncludeNormalPipe implements PipeTransform {

  constructor(
    private glb: GlobalService,
    private log: LogService) { }

  transform(items: ITarefaComponente[], includeNormal: boolean): ITarefaComponente[] {
    try {
      if (includeNormal) { return items; }
      const arr = ['NÃO', 'AUSENTE', 'NORMAL', '0 NÃO OU NÃO FAZ POR OUTROS MOTIVOS QUE NÃO A SAÚDE'];
      return items.filter(item => !this.glb.EstaNoVetor(arr, item.ValorTexto));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return items;
  }


}
