import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Injectable()
export class MedlogicEffects {

  constructor(
    private store: Store<IAppMedlogicState>,
    private actions$: Actions,
  ) { }

  // loadApp$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(AppActionTypes.LoadApp),
  //     withLatestFrom(this.store),
  //     mergeMap(([never, state]) => this.splashSrv.getAll(-1)),
  //     mergeMap((splashes: any) => {
  //       return of(splashes ? successLoadApp({ splashes }) : failLoadApp());
  //     }),
  //     catchError((e: any) => {
  //       console.log(e);
  //       return of(failLoadApp());
  //     })
  //   ));



  // listSearch$ = createEffect(() => this.actions$
  // .pipe(
  //   ofType(AppActionTypes.LoadApp),
  //   withLatestFrom(this.store),
  //   mergeMap(([never, state]) => this.splashSrv.getAll(-1)),
  //   mergeMap((splashes: any) => {
  //     return of(splashes ? successLoadApp({ splashes }) : failLoadApp());
  //   }),
  //   catchError((e: any) => {
  //     console.log(e);
  //     return of(failLoadApp());
  //   })
  // ));



}
