//import { IaCT } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ICarePlan, IFhirPatient } from '@medlogic/shared/shared-interfaces';
import * as CarePlanActions from './carePlan.actions';
import { ICarePlanState } from './carePlan-state';

export const carePlanFeatureKey = 'carePlan';


export const adapterCarePlan: EntityAdapter<ICarePlan> = createEntityAdapter<ICarePlan>({
  selectId: instance => instance.carePlanIdentifier,
  // sortComparer: sortOnOcurrencyAndNames
});

export const initialStateCarePlan: ICarePlanState = adapterCarePlan.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
});

// FIXME: Falta incluir regras de negócio de ordenação para os alertas
function UsersSortFn(a: ICarePlan, b: ICarePlan) {
  return a?.status.localeCompare(b?.status);
}

function sortOnOcurrencyAndNames(a: ICarePlan, b: IFhirPatient) {
  if (a.subjectPatientIdentifier === b.patientIdentifier) {
    return a.carePlanIdentifier < b.patientName ? -1 : 1;
  } else {
    return a.carePlanIdentifier ? -1 : 1;
  }
}

export const reducer = createReducer(
  initialStateCarePlan,
  on(CarePlanActions.addCarePlan,
    (state, action) => adapterCarePlan.addOne(action.carePlan, state)
  ),
  on(CarePlanActions.setCarePlan,
    (state, action) => ({ ...state, selectedIdentifier: action.selectedId })
  ),
  on(CarePlanActions.upsertCarePlan,
    (state, action) => adapterCarePlan.upsertOne(action.carePlan, state)
  ),
  on(CarePlanActions.addCarePlans,
    (state, action) => adapterCarePlan.addMany(action.carePlans, state)
  ),
  on(CarePlanActions.upsertCarePlans,
    (state, action) => adapterCarePlan.upsertMany(action.carePlans, state)
  ),
  on(CarePlanActions.updateCarePlan,
    (state, action) => adapterCarePlan.updateOne(action.carePlan, state)
  ),
  on(CarePlanActions.updateCarePlans,
    (state, action) => adapterCarePlan.updateMany(action.carePlans, state)
  ),
  on(CarePlanActions.deleteCarePlan,
    (state, action) => adapterCarePlan.removeOne(action.id, state)
  ),
  on(CarePlanActions.deleteCarePlans,
    (state, action) => adapterCarePlan.removeMany(action.ids, state)
  ),
  on(CarePlanActions.loadCarePlansSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterCarePlan.upsertMany(action.carePlans, state)
    (state, action) => adapterCarePlan.setAll(action.carePlans, state)
  ),
  on(CarePlanActions.carePlanFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(CarePlanActions.clearCarePlans,
    state => initialStateCarePlan
  ),
  on(CarePlanActions.clearSelectedCarePlan,
    state => ({ ...state, selectedIdentifier: null })
  ),
  on(CarePlanActions.loadCarePlans,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterCarePlan.getSelectors();


export const getSelectedId = (state: ICarePlan) => state.carePlanIdentifier;