import { BehaviorSubject } from 'rxjs';
import { ListControl } from '../model/list-control';

export class BehaviorSubjectCadastro extends BehaviorSubject<{ [cadastroNo: number]: ListControl[] }> {

  private localValue: { [cadastroNo: number]: ListControl[] };

  /** ATTENTION: after create a instance of this class, it is necessary define the unique name. */
  constructor(
    _value: { [cadastroNo: number]: ListControl[] }
  ) {
    super(_value);
  }

  /* override **/
  next(value: { [cadastroNo: number]: ListControl[] }): void {
    try {
      const key = Object.keys(value)[0];
      if (!this.localValue) {
        this.localValue = value;
      } else if (this.localValue.hasOwnProperty(key)) {
        this.localValue[key] = this.localValue[key].concat(value[key]);
      } else {
        this.localValue[key] = value[key];
      }
    } catch (error) {
      console.log(this.constructor.name, 'next', error.message);
    }
    super.next(this.localValue);
  }

  // getValue(): { [cadastroNo: number]: ListControl[] } {
  //   try {
  //     return this.localValue;
  //   } catch (error) {
  //     console.log(this.constructor.name, 'getValue', error.message);
  //   }
  //   return super.getValue();
  // }


}
