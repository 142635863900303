import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ActivityDetailEffects } from './+state/activityDetail.effects';

import * as fromActivityDetail from './+state/activityDetail.reducer';


const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromActivityDetail.activityDetailFeatureKey, fromActivityDetail.reducer),
  EffectsModule.forFeature([ActivityDetailEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
})
export class StateActivityDetailModule { }
