import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IWebsocketState, IWebSocketContract } from '@medlogic/medlogic/medlogic-shared-interfaces';

import * as WebsocketActions from './websocket.actions';

export const websocketFeatureKey = 'websocket';

export const adapterWebsocket: EntityAdapter<IWebSocketContract> = createEntityAdapter<IWebSocketContract>({
  selectId: instance => instance?.id,
});

export const initialStateWebsocket: IWebsocketState = adapterWebsocket.getInitialState({
  // additional entity state properties
  error: undefined,
  key: undefined,
  message: undefined
});


export const reducer = createReducer(
  initialStateWebsocket,

  on(WebsocketActions.listenWebsockets, state => state),
  on(WebsocketActions.listenWebsocketsSuccess, (state, action) => adapterWebsocket.addOne(action.connection, { ...state })),
  on(WebsocketActions.listenWebsocketsFailure, (state, action) => state),
  on(WebsocketActions.emitWebsockets, state => state),
  on(WebsocketActions.emitWebsocketsSuccess, (state, action) => adapterWebsocket.addOne(action.connection, { ...state })),
  on(WebsocketActions.emitWebsocketsFailure, (state, action) => state),
  on(WebsocketActions.clearWebsockets, () => ({ ...initialStateWebsocket }))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterWebsocket.getSelectors();