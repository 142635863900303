import { ConfigStateService } from '@medlogic/shared/state-config';
import { Observable } from 'rxjs';
import { error } from '@medlogic/shared/shared-interfaces';
import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { EnCurrency } from '../enum/en-currency.enum';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EnLanguage } from '../enum/en-language.enum';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import * as numero from 'numero-por-extenso';
import * as converter from 'number-to-words';
import { of } from 'rxjs';


@Injectable()
export class CurrencyService {

  // private OPEN_EXCHANGERATES_API_BASE_URL = 'https://openexchangerates.org';
  // private OPEN_EXCHANGERATES_API_APPID = 'def61a2b39c64dffbd665419e52273b9';
  private OPEN_EXCHANGERATES_API_BASE_URL = 'HttpExchange?code=';

  constructor(private log: LogService, private glb: GlobalService,
    private cnf: ConfigStateService,
    private cnfJson: ConfigJsonService, private http: HttpClient) { }

  /* Convert Real time currencies.
  * It uses: https://docs.openexchangerates.org/docs/
  * from and to must follow:
  * https://docs.openexchangerates.org/docs/supported-currencies
  * sample =CURRENCY(1000, "BRL", "USD")
  */
  convert(value: number, from: EnCurrency, to: EnCurrency, token: string = null): Observable<number> {
    try {
      // const url = this.glb.urlJoin([
      //   this.OPEN_EXCHANGERATES_API_BASE_URL,
      //   'api',
      //   'latest.json',
      //   `?app_id=${this.OPEN_EXCHANGERATES_API_APPID}`
      // ]);

      //   $.get('https://openexchangerates.org/api/latest.json', {app_id: 'YOUR_APP_ID'}, function(data) {
      //     console.log("1 US Dollar equals " + data.rates.GBP + " British Pounds");
      // });
      // return this.http.get(url)
      //   .pipe(
      //     this.getCurrencyRate(from, to),
      //     map((currencyRate: number) => value * currencyRate),
      //     error()
      //   );
      // FIXME: Serviço temporáriamente desativado, pois não há créditos na API
      // return of(value);
      if (value && !isNaN(value)) {
        token = token || this.cnf.baseUsuarioToken;
        const urlExchange = this.glb.urlJoin([this.cnfJson.baseUrlDocGen, `${this.OPEN_EXCHANGERATES_API_BASE_URL}${this.cnfJson.apiDocGenCode}`]);
        const formData = new FormData();
        formData.append('value', value.toString());
        formData.append('from', from);
        formData.append('to', to);
        formData.append('token', token);
        return this.http
          .post(urlExchange, formData)
          .pipe(
            this.getCurrencyRate(from, to),
            map((currencyRate: number) => value * currencyRate),
            error()
          );
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convert', error.message);
    }
    return of(null);
  }

  getCurrencyRate = (from: EnCurrency, to: EnCurrency) => map((data: any) => {
    const toValuePerDollar = data.rates[to];
    const fromValuePerDollar = data.rates[from];
    return toValuePerDollar / fromValuePerDollar;
  })

  /* Retorna o número escrito por extenso.
  * https://www.npmjs.com/package/number-to-words
  * https://www.npmjs.com/package/numero-por-extenso
  */
  numberToWords(value: number, enLanguage: EnLanguage): string {
    try {
      if (isNaN(value) || !value) {
        return '';
      }
      switch (enLanguage) {
        case EnLanguage.en:
          return converter.toWords(value);
        default:
        case EnLanguage.ptBR:
          return numero.porExtenso(value, numero.estilo.monetario);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'numberToWords', error.message);
    }
    return null;
  }


}
