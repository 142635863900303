import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MedicationEffects } from './+state/medication.effects';
import { metaReducers } from './+state/storage.metareducer';

import * as fromMedication from './+state/medication.reducer';


const SERVICES = [
];

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromMedication.medicationsFeatureKey, fromMedication.reducer, { metaReducers }),
  EffectsModule.forFeature([MedicationEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})
export class StateMedicationModule { }
