import { EnClipType } from './../enum/en-clip-type';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IListItem, ISmartList, LogService } from '@medlogic/shared/shared-interfaces';
import { fadeAnimation } from '@medlogic/shared/utils';
import { ISmartListImage } from '../interface/ismart-list-image';

@Component({
  selector: 'ui-smart-list-image',
  templateUrl: './smart-list-image.component.html',
  styleUrls: ['./smart-list-image.component.css'],
  animations: [fadeAnimation]
})
export class SmartListImageComponent implements OnInit {

  @Input() groups: ISmartList<ISmartListImage>[];
  @Input() search: string;
  @Input() enClipType = EnClipType.circle;

  @Output() itemClick = new EventEmitter<any>();

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  onItemClick(item: IListItem<any>): void {
    try {
      this.itemClick.emit(item);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onItemClick', error.message);
    }
  }


}
