import { IServiceProvider, IForm, error } from '@medlogic/shared/shared-interfaces';
import { IFrailty } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Observable } from 'rxjs';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { LocalLibService } from '@medlogic/shared/utils';
import { publishReplay } from 'rxjs/operators';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import { ConfigStateService } from '@medlogic/shared/state-config';
import {
    GlobalService,
    LogService,
} from '@medlogic/shared/shared-interfaces';

export abstract class FrailtyService implements IServiceProvider {

    private lstVariaveis = 'V_104224,V_104225,V_104226,V_104227,V_104228,V_104234,V_104235,V_104236,V_104237,V_104238,V_104240,V_104241,V_104242,V_104243,V_104244,V_104245,V_104246,V_104247,V_104248,V_104249,V_104256,V_104298,V_104299,V_104300,V_104301,V_104302,V_104303,V_104304,V_104305,V_104306,V_104307,V_104308,V_104309,V_104310,V_104311,V_104312,V_104313,V_104314,V_104315,V_104316,V_104317,V_104318,V_104322,V_104323,V_104324,V_104325,V_104326,V_104327,V_104328,V_104329,V_104331,V_104332,V_104333,V_104334,V_104335,V_104336,V_104337,V_104338,V_104339,V_104340,V_104341,V_104342,V_104343,V_104345,V_104346,V_104347,V_104348,V_104349,V_104350,V_104351,V_104352,V_104353,V_104355,V_104356,V_104357,V_104358,V_104359,V_104360,V_104361,V_104362,V_104363,V_104364,V_104365,V_104366,V_104367,V_104368,V_104369,V_104370,V_104371,V_104372,V_104373,V_104374,V_104377,V_104378,V_104379,V_104380,V_104381,V_104382,V_104383,V_104388,V_104389,V_104390,V_104391,V_104392,V_104393,V_104394,V_104395,V_104396,V_104397,V_104398,V_104399,V_104400,V_104401,V_104402,V_104403';
    private variavelGrid = '';
    private lstVariaveisGrid = '';

    recurrences: IFrailty[] = new Array<IFrailty>();

    codigoVariavelNo = 104224; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
    cadastroNo = 19634;
    currentDtInicial = new Date();
    currentDtFinal = new Date();
    cadastrosCache: Observable<any>;

    constructor(
        protected cadastroSrv: CadastroService,
        protected glb: GlobalService,
        protected cnf: ConfigStateService,
        protected lib: LocalLibService,
        protected log: LogService) { }

    /* Retorna somente as variáveis desejadas.
     * lstVariables do tipo: 'V_3332,V_32223'
     */
    getSome(
        ano: number,
        lstVariables: string,
        startDate?: Date,
        endDate?: Date
    ): Observable<IFrailty> {
        try {
            this.cadastroNo = ano;
            startDate = startDate || new Date(1900, 0, 1);
            endDate = endDate || new Date(2500, 0, 1);
            return this.getWithCache(
                this.cadastroNo,
                startDate,
                endDate,
                lstVariables
            );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getSome', error.message);
        }
        return of(null);
    }

    getAll(
        ano: number,
        startDate?: Date,
        endDate?: Date
    ): Observable<IFrailty[]> {
        try {
            this.cadastroNo = ano;
            startDate = startDate || new Date(1900, 0, 1);
            endDate = endDate || new Date(2500, 0, 1);
            return this.getWithCache(this.cadastroNo, startDate, endDate)
                .pipe(
                    toArray(),
                    error()
                );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getAll', error.message);
        }
        return of(null);
    }

    /* Método utilizado para popular uma lista com os itens ativos. */
    loadArray(ano: number): Observable<any> {
        try {
            this.cadastroNo = ano;
            const propLabel = 'titulo';
            const propValue = 'codigo';
            const propEnabled = 'habilitado';
            return this.cadastroSrv.loadArray(
                this.getAll(ano),
                propLabel,
                propValue,
                propEnabled
            );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'loadArray', error.message);
        }
    }

    /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
    clearCache(): void {
        try {
            this.cadastrosCache = null;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'clearCache', error.message);
        }
    }

    protected getWithCache(
        cadastroNo: number,
        startDate: Date,
        endDate: Date,
        lstVariables: string = null
    ): Observable<IFrailty> {
        try {
            if (
                !this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial) ||
                !this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal) ||
                !this.cadastrosCache
            ) {
                this.currentDtInicial = startDate;
                this.currentDtFinal = endDate;
                this.cadastrosCache = this.getFromCadastro(
                    cadastroNo,
                    startDate,
                    endDate,
                    lstVariables
                );
            } else {
                console.log('retorno do cache');
            }
            return this.cadastrosCache;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
        }
        return of(null);
    }

    protected getFromCadastro(
        cadastroNo: number,
        startDate: Date,
        endDate: Date,
        lstVariables: string = null
    ): Observable<any> {
        try {
            this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
            this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
            lstVariables = lstVariables || this.lstVariaveis;
            console.log('Recarregando dados...');
            // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
            return this.cadastroSrv
                .getCadastro(cadastroNo, lstVariables, startDate, endDate)
                .pipe(this.mapTo(), publishReplay(), refCount());
        } catch (error) {
            this.log.Registrar(
                this.constructor.name,
                'getFromCadatro',
                error.message
            );
        }
        return of(null);
    }

    protected mapTo = () => map((c: any) => {
        return {
            ocorrenciaNo: c.OcorrenciaNo,
            codigo: c.V_104224,
            titulo: c.V_104225,
            habilitado: this.lib.getBoolean(c.V_104226),
            idade: c.V_104227,
            dtNascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_104228),
            algumFamiliarOuAmigoFalouQueVoceEstaFicandoEsquecido: this.lib.getBoolean(c.V_104234),
            esteEsquecimentoEstaPiorandoNosUltimosMeses: this.lib.getBoolean(c.V_104235),
            esteEsquecimentoEstaImpedindorealizacaoalgumaAtividadecotidiano: this.lib.getBoolean(c.V_104236),
            noUltimoMesVoceFicouComDesanimoTristezaOuDesesperanca: this.lib.getBoolean(c.V_104237),
            noUltimoMesVocePerdeuinteresseOuPrazerEmAtividadesAnteriormentePrazerosas: this.lib.getBoolean(c.V_104238),
            voceincapazelevarOsBracosAcimanivelombro: this.lib.getBoolean(c.V_104240),
            voceincapazmanusearOuSegurarPequenosObjetos: this.lib.getBoolean(c.V_104241),
            voceTemAlgumaquatroCondicoesAbaixoRelacionadas: c.V_104242,
            voceTemDificuldadecaminharCapazimpedirrealizacaoalgumaAtividadecotidiano: this.lib.getBoolean(c.V_104243),
            voceTeveDuasOuMaisQuedasNoUltimoAno: this.lib.getBoolean(c.V_104244),
            vocePerdeUrinaOuFezesSemQuererEmAlgumMomento: this.lib.getBoolean(c.V_104245),
            voceTemProblemasvisaoCapazesimpedirrealizacaoalgumaAtividadecotidianoEPermitidousooculosOuLentescontato: this.lib.getBoolean(c.V_104246),
            voceTemProblemasaudicaoCapazesimpedirrealizacaoalgumaAtividadecotidianoEPermitidousoaparelhosaudicao: this.lib.getBoolean(c.V_104247),
            cincoOuMaisDoencasCronicas: this.lib.getBoolean(c.V_104248),
            usoRegularcincoOuMaisMedicamentosDiferentesTodoDia: this.lib.getBoolean(c.V_104249),
            internacaoRecenteNosUltimos6Meses: this.lib.getBoolean(c.V_104256),
            historiahipertensaoArterial: c.V_104298,
            historiadiabetesMellitus: c.V_104299,
            historiadoencaAterotromboticaDoencaCerebrovascularAVEOuAITIAMOuDoencaArterialCoronarianaDACOuDoencaArterialPerifericaDAP: c.V_104300,
            historiainsuficienciaCardiacaArritmiasGravesFibrilacaoAtrialOuValvulopatiasSintomaticas: c.V_104301,
            historiaparkinsonismoOuPresencatremoresRepousoAcaoOuIntencional: c.V_104302,
            diagnosticoquadroDemencial: c.V_104303,
            diagnosticotranstornoMentalOuPsiquiatrico: c.V_104304,
            presencadoencaRespiratoriaCronicaDPOCOuAsma: c.V_104305,
            presencaosteoatriteDegenerativaArtriteReumatoideOuOutrasDoencasReumatologicasLimitantes: c.V_104306,
            presencaalgumTipocancerExcetoCutaneoNaoMelanoma: c.V_104307,
            presencadoencaRenalCronicaInsuficienciaRenal: c.V_104308,
            diagnosticoosteoporoseAtualOuPregresso: c.V_104309,
            presencaalgumDisturbiosonoComoInsoniaApneiasonoOuHipersonia: c.V_104310,
            presencaproblemasNaMastigacaoOuFeridasOuLesoesNaMucosaOralOuLingua: c.V_104311,
            presencaproblemastuboDigestivoComoDRGEDoencaCloridopepticaDiarreiaCronicaConstipacaoIntestinalOuFecaloma: c.V_104312,
            presencaanemiaOuOutrasDoencassistemaHematopoietica: c.V_104313,
            presencadorCronica: c.V_104314,
            aoalimentarPaciente: c.V_104315,
            paraSairumaCadeiraseLocomoverAtecamaTransferenciaPaciente: c.V_104316,
            trocarroupaVertir: c.V_104317,
            usobanheironecessidadesBasicasComoMiccaoevacuacao: c.V_104318,
            saircasaSozinholugaresDistantes: c.V_104322,
            preparorefeicoes: c.V_104323,
            tomarRemediosNaDosehorarioCorretos: c.V_104324,
            emGeralComparandoComOutrasPessoassuaIdadeVoceDiriaQueSuaSaude: this.lib.getBoolean(c.V_104325),
            porCausasuaSaudeOuCondicaoFisicaVoceDeixoufazerCompras: this.lib.getBoolean(c.V_104326),
            porCausasuaSaudeOuCondicaoFisicaVoceDeixoucontrolarSeuDinheiroGastosOuPagarAsContassuaCasa: this.lib.getBoolean(c.V_104327),
            porCausasuaSaudeOuCondicaoFisicaVoceDeixourealizarPequenosTrabalhosDomesticosComoLavarLoucaArrumarcasaOuFazerLimpezaLeve: this.lib.getBoolean(c.V_104328),
            porCausasuaSaudeOuCondicaoFisicaVoceDeixoutomarBanhoSozinho: this.lib.getBoolean(c.V_104329),
            q1CalcIdade: c.V_104331,
            q1CalcIdade2: c.V_104332,
            q1CalcIdadeFim: c.V_104333,
            q2AutoPercepcao: c.V_104334,
            q3AvdInst1: c.V_104335,
            q4AvdInst2: c.V_104336,
            q5AvdInst3: c.V_104337,
            q6AvdBasica: c.V_104338,
            q7: c.V_104339,
            q8: c.V_104340,
            q9: c.V_104341,
            q10: c.V_104342,
            q11: c.V_104343,
            q12: c.V_104345,
            q13: c.V_104346,
            q14: c.V_104347,
            q18: c.V_104348,
            q15: c.V_104349,
            q19: c.V_104350,
            q16: c.V_104351,
            q20: c.V_104352,
            q17: c.V_104353,
            q21: c.V_104355,
            q22: c.V_104356,
            q23: c.V_104357,
            q24: c.V_104358,
            q25: c.V_104359,
            q26: c.V_104360,
            q262: c.V_104361,
            q263: c.V_104362,
            q27: c.V_104363,
            q272: c.V_104364,
            q273: c.V_104365,
            q28: c.V_104366,
            q282: c.V_104367,
            q283: c.V_104368,
            q29: c.V_104369,
            q292: c.V_104370,
            q293: c.V_104371,
            q30: c.V_104372,
            q302: c.V_104373,
            q303: c.V_104374,
            q31: c.V_104377,
            q32: c.V_104378,
            q33: c.V_104379,
            q332: c.V_104380,
            q333: c.V_104381,
            q34: c.V_104382,
            q35: c.V_104383,
            q36: c.V_104388,
            q362: c.V_104389,
            q363: c.V_104390,
            q37: c.V_104391,
            q372: c.V_104392,
            q373: c.V_104393,
            q38: c.V_104394,
            q382: c.V_104395,
            q383: c.V_104396,
            q39: c.V_104397,
            q40: c.V_104398,
            q42: c.V_104399,
            q41: c.V_104400,
            presencadoencaTireoideanaHiperOuHipotireoidismo: c.V_104401,
            audioV104234: c.V_104402,
            audioV104235V104236: c.V_104403
        } as IFrailty;
    });

    /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
     * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
     */
    protected getFiltered(
        cadastroNo: number,
        strFilter: string,
        startDate: Date = null,
        endDate: Date = null,
        isFilterAnd: boolean = true,
        lstVariables: string = null
    ): Observable<IFrailty> {
        try {
            this.cadastroSrv.dtInicial = startDate
                ? this.glb.dateToYYYYMMddThhmmss(startDate)
                : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
            this.cadastroSrv.dtFinal = endDate
                ? this.glb.dateToYYYYMMddThhmmss(endDate)
                : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
            lstVariables = lstVariables || this.lstVariaveis;
            return this.cadastroSrv
                .getCadastroComFiltro(
                    cadastroNo,
                    lstVariables,
                    strFilter,
                    isFilterAnd,
                    startDate,
                    endDate
                )
                .pipe(this.mapTo(), publishReplay(), refCount());
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
        }
        return of(null);
    }

    /* Insere ou atualiza o item.
     * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
     */
    save<T>(
        ano: number,
        Frailty: T,
        uno: number,
        id?: number
    ): Observable<any> {
        try {
            this.cadastroNo = ano;
            const forms: IForm[] = this.mapToForm(
                (Frailty as unknown) as IFrailty
            ).filter((f) => f.ValorDado);
            return this.cadastroSrv.save(
                forms,
                uno,
                this.cadastroNo,
                id,
                true,
                this.codigoVariavelNo
            );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'save', error.message);
        }
        return of(null);
    }

    protected mapToForm(frailty: IFrailty): IForm[] {
        try {
            return [
                { VariavelNo: 104224, ValorDado: frailty.codigo || '' },
                { VariavelNo: 104225, ValorDado: frailty.titulo || '' },
                { VariavelNo: 104226, ValorDado: frailty.habilitado ? 'SIM' : 'NÃO' },
                { VariavelNo: 104227, ValorDado: frailty.idade || '' },
                { VariavelNo: 104228, ValorDado: this.glb.ddMMYYYYThhmmssToDate(frailty.dtNascimento) },
                { VariavelNo: 104234, ValorDado: frailty.algumFamiliarOuAmigoFalouQueVoceEstaFicandoEsquecido ? 'SIM' : 'NÃO' },
                { VariavelNo: 104235, ValorDado: frailty.esteEsquecimentoEstaPiorandoNosUltimosMeses ? 'SIM' : 'NÃO' },
                { VariavelNo: 104236, ValorDado: frailty.esteEsquecimentoEstaImpedindorealizacaoalgumaAtividadecotidiano ? 'SIM' : 'NÃO' },
                { VariavelNo: 104237, ValorDado: frailty.noUltimoMesVoceFicouComDesanimoTristezaOuDesesperanca ? 'SIM' : 'NÃO' },
                { VariavelNo: 104238, ValorDado: frailty.noUltimoMesVocePerdeuinteresseOuPrazerEmAtividadesAnteriormentePrazerosas ? 'SIM' : 'NÃO' },
                { VariavelNo: 104240, ValorDado: frailty.voceincapazelevarOsBracosAcimanivelombro ? 'SIM' : 'NÃO' },
                { VariavelNo: 104241, ValorDado: frailty.voceincapazmanusearOuSegurarPequenosObjetos ? 'SIM' : 'NÃO' },
                { VariavelNo: 104242, ValorDado: frailty.voceTemAlgumaquatroCondicoesAbaixoRelacionadas || '' },
                { VariavelNo: 104243, ValorDado: frailty.voceTemDificuldadecaminharCapazimpedirrealizacaoalgumaAtividadecotidiano ? 'SIM' : 'NÃO' },
                { VariavelNo: 104244, ValorDado: frailty.voceTeveDuasOuMaisQuedasNoUltimoAno ? 'SIM' : 'NÃO' },
                { VariavelNo: 104245, ValorDado: frailty.vocePerdeUrinaOuFezesSemQuererEmAlgumMomento ? 'SIM' : 'NÃO' },
                { VariavelNo: 104246, ValorDado: frailty.voceTemProblemasvisaoCapazesimpedirrealizacaoalgumaAtividadecotidianoEPermitidousooculosOuLentescontato ? 'SIM' : 'NÃO' },
                { VariavelNo: 104247, ValorDado: frailty.voceTemProblemasaudicaoCapazesimpedirrealizacaoalgumaAtividadecotidianoEPermitidousoaparelhosaudicao ? 'SIM' : 'NÃO' },
                { VariavelNo: 104248, ValorDado: frailty.cincoOuMaisDoencasCronicas ? 'SIM' : 'NÃO' },
                { VariavelNo: 104249, ValorDado: frailty.usoRegularcincoOuMaisMedicamentosDiferentesTodoDia ? 'SIM' : 'NÃO' },
                { VariavelNo: 104256, ValorDado: frailty.internacaoRecenteNosUltimos6Meses ? 'SIM' : 'NÃO' },
                { VariavelNo: 104298, ValorDado: frailty.historiahipertensaoArterial || '' },
                { VariavelNo: 104299, ValorDado: frailty.historiadiabetesMellitus || '' },
                { VariavelNo: 104300, ValorDado: frailty.historiadoencaAterotromboticaDoencaCerebrovascularAVEOuAITIAMOuDoencaArterialCoronarianaDACOuDoencaArterialPerifericaDAP || '' },
                { VariavelNo: 104301, ValorDado: frailty.historiainsuficienciaCardiacaArritmiasGravesFibrilacaoAtrialOuValvulopatiasSintomaticas || '' },
                { VariavelNo: 104302, ValorDado: frailty.historiaparkinsonismoOuPresencatremoresRepousoAcaoOuIntencional || '' },
                { VariavelNo: 104303, ValorDado: frailty.diagnosticoquadroDemencial || '' },
                { VariavelNo: 104304, ValorDado: frailty.diagnosticotranstornoMentalOuPsiquiatrico || '' },
                { VariavelNo: 104305, ValorDado: frailty.presencadoencaRespiratoriaCronicaDPOCOuAsma || '' },
                { VariavelNo: 104306, ValorDado: frailty.presencaosteoatriteDegenerativaArtriteReumatoideOuOutrasDoencasReumatologicasLimitantes || '' },
                { VariavelNo: 104307, ValorDado: frailty.presencaalgumTipocancerExcetoCutaneoNaoMelanoma || '' },
                { VariavelNo: 104308, ValorDado: frailty.presencadoencaRenalCronicaInsuficienciaRenal || '' },
                { VariavelNo: 104309, ValorDado: frailty.diagnosticoosteoporoseAtualOuPregresso || '' },
                { VariavelNo: 104310, ValorDado: frailty.presencaalgumDisturbiosonoComoInsoniaApneiasonoOuHipersonia || '' },
                { VariavelNo: 104311, ValorDado: frailty.presencaproblemasNaMastigacaoOuFeridasOuLesoesNaMucosaOralOuLingua || '' },
                { VariavelNo: 104312, ValorDado: frailty.presencaproblemastuboDigestivoComoDRGEDoencaCloridopepticaDiarreiaCronicaConstipacaoIntestinalOuFecaloma || '' },
                { VariavelNo: 104313, ValorDado: frailty.presencaanemiaOuOutrasDoencassistemaHematopoietica || '' },
                { VariavelNo: 104314, ValorDado: frailty.presencadorCronica || '' },
                { VariavelNo: 104315, ValorDado: frailty.aoalimentarPaciente || '' },
                { VariavelNo: 104316, ValorDado: frailty.paraSairumaCadeiraseLocomoverAtecamaTransferenciaPaciente || '' },
                { VariavelNo: 104317, ValorDado: frailty.trocarroupaVertir || '' },
                { VariavelNo: 104318, ValorDado: frailty.usobanheironecessidadesBasicasComoMiccaoevacuacao || '' },
                { VariavelNo: 104322, ValorDado: frailty.saircasaSozinholugaresDistantes || '' },
                { VariavelNo: 104323, ValorDado: frailty.preparorefeicoes || '' },
                { VariavelNo: 104324, ValorDado: frailty.tomarRemediosNaDosehorarioCorretos || '' },
                { VariavelNo: 104325, ValorDado: frailty.emGeralComparandoComOutrasPessoassuaIdadeVoceDiriaQueSuaSaude ? 'SIM' : 'NÃO' },
                { VariavelNo: 104326, ValorDado: frailty.porCausasuaSaudeOuCondicaoFisicaVoceDeixoufazerCompras ? 'SIM' : 'NÃO' },
                { VariavelNo: 104327, ValorDado: frailty.porCausasuaSaudeOuCondicaoFisicaVoceDeixoucontrolarSeuDinheiroGastosOuPagarAsContassuaCasa ? 'SIM' : 'NÃO' },
                { VariavelNo: 104328, ValorDado: frailty.porCausasuaSaudeOuCondicaoFisicaVoceDeixourealizarPequenosTrabalhosDomesticosComoLavarLoucaArrumarcasaOuFazerLimpezaLeve ? 'SIM' : 'NÃO' },
                { VariavelNo: 104329, ValorDado: frailty.porCausasuaSaudeOuCondicaoFisicaVoceDeixoutomarBanhoSozinho ? 'SIM' : 'NÃO' },
                { VariavelNo: 104331, ValorDado: frailty.q1CalcIdade || '' },
                { VariavelNo: 104332, ValorDado: frailty.q1CalcIdade2 || '' },
                { VariavelNo: 104333, ValorDado: frailty.q1CalcIdadeFim || '' },
                { VariavelNo: 104334, ValorDado: frailty.q2AutoPercepcao || '' },
                { VariavelNo: 104335, ValorDado: frailty.q3AvdInst1 || '' },
                { VariavelNo: 104336, ValorDado: frailty.q4AvdInst2 || '' },
                { VariavelNo: 104337, ValorDado: frailty.q5AvdInst3 || '' },
                { VariavelNo: 104338, ValorDado: frailty.q6AvdBasica || '' },
                { VariavelNo: 104339, ValorDado: frailty.q7 || '' },
                { VariavelNo: 104340, ValorDado: frailty.q8 || '' },
                { VariavelNo: 104341, ValorDado: frailty.q9 || '' },
                { VariavelNo: 104342, ValorDado: frailty.q10 || '' },
                { VariavelNo: 104343, ValorDado: frailty.q11 || '' },
                { VariavelNo: 104345, ValorDado: frailty.q12 || '' },
                { VariavelNo: 104346, ValorDado: frailty.q13 || '' },
                { VariavelNo: 104347, ValorDado: frailty.q14 || '' },
                { VariavelNo: 104348, ValorDado: frailty.q18 || '' },
                { VariavelNo: 104349, ValorDado: frailty.q15 || '' },
                { VariavelNo: 104350, ValorDado: frailty.q19 || '' },
                { VariavelNo: 104351, ValorDado: frailty.q16 || '' },
                { VariavelNo: 104352, ValorDado: frailty.q20 || '' },
                { VariavelNo: 104353, ValorDado: frailty.q17 || '' },
                { VariavelNo: 104355, ValorDado: frailty.q21 || '' },
                { VariavelNo: 104356, ValorDado: frailty.q22 || '' },
                { VariavelNo: 104357, ValorDado: frailty.q23 || '' },
                { VariavelNo: 104358, ValorDado: frailty.q24 || '' },
                { VariavelNo: 104359, ValorDado: frailty.q25 || '' },
                { VariavelNo: 104360, ValorDado: frailty.q26 || '' },
                { VariavelNo: 104361, ValorDado: frailty.q262 || '' },
                { VariavelNo: 104362, ValorDado: frailty.q263 || '' },
                { VariavelNo: 104363, ValorDado: frailty.q27 || '' },
                { VariavelNo: 104364, ValorDado: frailty.q272 || '' },
                { VariavelNo: 104365, ValorDado: frailty.q273 || '' },
                { VariavelNo: 104366, ValorDado: frailty.q28 || '' },
                { VariavelNo: 104367, ValorDado: frailty.q282 || '' },
                { VariavelNo: 104368, ValorDado: frailty.q283 || '' },
                { VariavelNo: 104369, ValorDado: frailty.q29 || '' },
                { VariavelNo: 104370, ValorDado: frailty.q292 || '' },
                { VariavelNo: 104371, ValorDado: frailty.q293 || '' },
                { VariavelNo: 104372, ValorDado: frailty.q30 || '' },
                { VariavelNo: 104373, ValorDado: frailty.q302 || '' },
                { VariavelNo: 104374, ValorDado: frailty.q303 || '' },
                { VariavelNo: 104377, ValorDado: frailty.q31 || '' },
                { VariavelNo: 104378, ValorDado: frailty.q32 || '' },
                { VariavelNo: 104379, ValorDado: frailty.q33 || '' },
                { VariavelNo: 104380, ValorDado: frailty.q332 || '' },
                { VariavelNo: 104381, ValorDado: frailty.q333 || '' },
                { VariavelNo: 104382, ValorDado: frailty.q34 || '' },
                { VariavelNo: 104383, ValorDado: frailty.q35 || '' },
                { VariavelNo: 104388, ValorDado: frailty.q36 || '' },
                { VariavelNo: 104389, ValorDado: frailty.q362 || '' },
                { VariavelNo: 104390, ValorDado: frailty.q363 || '' },
                { VariavelNo: 104391, ValorDado: frailty.q37 || '' },
                { VariavelNo: 104392, ValorDado: frailty.q372 || '' },
                { VariavelNo: 104393, ValorDado: frailty.q373 || '' },
                { VariavelNo: 104394, ValorDado: frailty.q38 || '' },
                { VariavelNo: 104395, ValorDado: frailty.q382 || '' },
                { VariavelNo: 104396, ValorDado: frailty.q383 || '' },
                { VariavelNo: 104397, ValorDado: frailty.q39 || '' },
                { VariavelNo: 104398, ValorDado: frailty.q40 || '' },
                { VariavelNo: 104399, ValorDado: frailty.q42 || '' },
                { VariavelNo: 104400, ValorDado: frailty.q41 || '' },
                { VariavelNo: 104401, ValorDado: frailty.presencadoencaTireoideanaHiperOuHipotireoidismo || '' },
                { VariavelNo: 104402, ValorDado: frailty.audioV104234 || '' },
                { VariavelNo: 104403, ValorDado: frailty.audioV104235V104236 || '' },
            ];
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
        }
        return null;
    }

    /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
    insertIfNotExist<T>(
        ano: number,
        Frailty: T,
        uno: number,
        compareFieldName: string = 'titulo'
    ): Observable<boolean> {
        try {
            return this.getFromCadastro(ano, null, null).pipe(
                toArray(),
                mergeMap((items) => {
                    const founded =
                        items &&
                        items.findIndex((f) =>
                            this.glb.isEqual(f[compareFieldName], Frailty[compareFieldName])
                        ) >= 0;
                    if (!founded) {
                        return this.save(ano, Frailty, uno);
                    }
                    return of(founded);
                })
            );
        } catch (error) {
            this.log.Registrar(
                this.constructor.name,
                'insertIfNotExist',
                error.message
            );
        }
        return of(null);
    }


}
