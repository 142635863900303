import { createReducer, on } from '@ngrx/store';
import { IAccountState } from '../states/iaccount-state';
import {
  loadAccount,
  setAccount,
  selectAccount,
  fetchSuccessAccount,
  updateAccount,
  fetchSuccessAccountByCode,
  getAccountByCode,
} from '../actions/account.actions';

export const initialState = {};

// tslint:disable-next-line: variable-name
const _accountReducer = createReducer(initialState,
  on(loadAccount, (state, payload) => ({
    ...state,
    bannerTitle: 'CONTA',
    selectedAccount: null
  })),
  on(fetchSuccessAccount, (state, payload) => ({
    ...state,
    allAccounts: payload.allAccounts
  })),
  on(selectAccount, (state, payload) => ({
    ...state,
    selectedAccount: payload.selectedAccount
  })),
  on(setAccount, (state, payload) => ({
    ...state,
    allAccounts: payload.allAccounts
  })),
  on(updateAccount, (state: IAccountState, payload) => {  // Note: the updateAccount will not update the service, but just the state
    const find = state.allAccounts.find(f => f.ocorrenciaNo === payload.account.ocorrenciaNo);
    if (find) {
      Object.assign(find, payload.account);
      return ({
        ...state,
        allAccounts: state.allAccounts
      });
    }
    return state;
  }),
  on(getAccountByCode, (state: IAccountState, payload) => ({
    ...state,
    validationCode: payload.validationCode
  })),
  on(fetchSuccessAccountByCode, (state: IAccountState, payload) => ({
    ...state,
    selectedAccount: payload.selectedAccount
  })),
);

export function accountReducer(state, action) {
  return _accountReducer(state, action);
}
