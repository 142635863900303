// import { IBrand } from './ibrand';

export interface ITherapeuticSafe {
    ocorrenciaNo: number;
    codigo: any;
    nomeFarmaco: any;
    habilitado: boolean;
    grupo: string;
    disponibilidade: any;
    descricao: string;
    indicacao: string;
    posologia: string;
    reacoesAdversasContraindicacoes: string;
    interacoesImportantes: string;
    usodoencaRenal: string;
    cuidadosComAdministracaoesquecimentodose: string;
    seguranca: string;
    adesaoEfetividade: string;
    referenciasBibliograficas: string;
    riscohipotensaoOrtostatica: string;
    riscoaumentoQTc: string;
    usodoencaHepatica: string;
    cargaAnticolinergicaCAch: string;
    cargaSedativa: string;
    riscoconfusaoMental: string;
    cargaAntidopaminergica: string;
    riscohiponatremia: string;
    riscoquedas: string;
    estrategiaDesprescricao: string;
    referenciasBibliograficas2: string;
    criteriosExplicitosMPIMAO: string;
    precoMaximoreferenciaANVISA: any;
    subGrupo: string;
    classe: string;
    gSGC: any;
    especificarFormulacoesDisponiveis: string;
    cor: string;
    marcas: string;
    // Calculados
    isFavorite?: boolean;
}
