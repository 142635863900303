<div [formGroup]="formGroup" class="form-group" [class.has-error]="!isValid(formGroup, ctrl)" [libCtrConfigUI]="ctrl" [altura]="ctrl?.Altura" [isMobile]="isMobile" [class.black]="enTheme === ENTHEME.black">

  <lib-title-label [formGroup]="formGroup" [isMobile]="isMobile" [enTheme]="enTheme" [ctrl]="ctrl" #lblTitle></lib-title-label>

  <div #table class="table-responsive">

    <!-- | libCtrlGridSearch:search : columns -->
    <table [id]="getId(ctrl)" mat-table [dataSource]="dataSource | libCtrlMatGridSearch:search">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">

        <th mat-header-cell *matHeaderCellDef [style.width]="'60px'">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let row" [style.width]="'60px'">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>

        <!-- Rodapé -->
        <!-- <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"></td> -->
      </ng-container>


      <!-- Footer -->
      <ng-container matColumnDef="actionBar">

        <td #footer mat-footer-cell *matFooterCellDef
          [attr.colSpan]="getDisplay(displayedColumns, table.offsetWidth).length + 1">
          <!-- Botões e busca -->
          <!-- [style.width]="(ctrl.Largura || 160)+'px'" -->
          <!-- *ngIf="dataSource && dataSource.data && dataSource.data.length > 0" -->
          <div class="flex-container">

            <div class="td-buttons">

              <button mat-raised-button color="primary" [class.btn-sm]="table?.offsetWidth < 300"
                *ngIf="ctrl?.CanAddItem && !isReadOnly(ctrl)"
                (click)="onNewItem($event, ctrl.AtividadeNo, ctrl.CadastroNo, ctrl.CanAddInCadastro, ctrl.VariavelNo)">
                <mat-icon>add</mat-icon>
                <ng-container *ngIf="table?.offsetWidth >= 300">{{ textBtnNew }}</ng-container>
              </button>

              <button mat-raised-button color="warn" [class.btn-sm]="table?.offsetWidth < 300"
                *ngIf="ctrl?.CanExcludeItem && !isReadOnly(ctrl)"
                (click)="onDeleteItems($event, ctrl.CanAddInCadastro)">
                <mat-icon>delete</mat-icon>
                <ng-container *ngIf="table?.offsetWidth >= 300">{{ textBtnDelete }}</ng-container>
              </button>

            </div>


            <div class="td-search" *ngIf="table?.offsetWidth && (table.offsetWidth >= 350)">

              <mat-form-field>

                <input matInput type="text" class="search" [value]="search" #inputSearch
                  (keyup)="onSearchChange(inputSearch.value)" (keyup.esc)="onSearchEsc($event)">

                <button mat-button matSuffix mat-icon-button aria-label="Procurar" type="button">
                  <mat-icon class="search-icn">search</mat-icon>
                </button>

              </mat-form-field>

            </div>

          </div>
        </td>

      </ng-container>


      <!-- Generated Column -->
      <ng-container [matColumnDef]="col.ColumnName" *ngFor="let col of columns">

        <th mat-header-cell *matHeaderCellDef> {{ col.ColumnName }} </th>
        <!-- <td mat-cell *matCellDef="let element"> {{element.position}} </td> -->
        <!-- [style.mwidth]="(col.Largura || 60)+'px'" -->

        <td mat-cell *matCellDef="let item"
          (click)="onEditItem($event, ctrl.AtividadeNo, ctrl.CadastroNo, item, ctrl.CanAddInCadastro, ctrl.VariavelNo, isReadOnly(ctrl), isReadOnlyGrid(ctrl))">{{
          getValue(col, item) }}</td>

      </ng-container>


      <tr mat-header-row *matHeaderRowDef="getDisplay(displayedColumns, table.offsetWidth)"></tr>
      <tr mat-row class="row" *matRowDef="let row; columns: getDisplay(displayedColumns, table.offsetWidth);"></tr>
      <tr mat-footer-row *matFooterRowDef="['actionBar']; sticky: true"></tr>

    </table>




    <!-- TODO: Converter para material datatable -->
    <!-- <table class="table table-striped table-bordered" [mfData]="lstCadastroAdicional | libCtrlGridSearch:search : columns"
      #mf="mfDataTable" [tabIndex]="getTabIndex(ctrl)" [style.height]="getHeight(ctrl, lblTitle, footer)">
      <thead #head>
        <tr>
          <th *ngIf="ctrl?.CanExcludeItem && !isReadOnly(ctrl) && hasItems()" class="ckb-clm"><input type="checkbox" id="checkboxAll1"
              #checkboxAll (click)="onCheckAll(mf.data, checkboxAll)"></th>
          <th *ngFor="let col of columns" [libCtrlGridColumn]="col" [style.width]="(col.Largura || 60)+'px'">
            <mfDefaultSorter [by]="getClmName(col)">{{ col ? col.ColumnName : "" }}</mfDefaultSorter>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="hasItems()" [libCtrlGridTBody]="ctrl" [head]="head" [footer]="footer" [lblTitle]="lblTitle">
        <tr class="tr-item" *ngFor="let item of mf.data">
          <td *ngIf="ctrl?.CanExcludeItem && !isReadOnly(ctrl) && hasItems()" class="ckb-clm"><input type="checkbox" id="checkbox1"
              #checkbox [checked]="item.isSelected || false" (click)="onCheck(item, checkbox)"></td>
          <td *ngFor="let col of columns" [style.width]="(col.Largura || 60)+'px'" (click)="onEditItem($event, ctrl.AtividadeCadastroNo, item, ctrl.CanAddInCadastro, ctrl.VariavelNo, isReadOnly(ctrl), isReadOnlyGrid(ctrl))">{{
            getValue(col, item) }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="!hasItems()" [libCtrlGridTBody]="ctrl" [head]="head" [footer]="footer">
        <tr>
          <td [attr.colspan]="getColumnsLength()" class="no-item">
            <mat-spinner [diameter]="30" *ngIf="isLoading"></mat-spinner>
            <span *ngIf="!isLoading">Nenhum registro encontrado.</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div #footer class="flex-container" [style.width]="(ctrl.Largura || 160)+'px'">
              <div class="td-buttons">
                <button mat-stroked-button color="primary" [class.btn-sm]="footer.offsetWidth < 300" *ngIf="ctrl?.CanAddItem && !isReadOnly(ctrl)"
                  (click)="onNewItem($event, ctrl.AtividadeCadastroNo, ctrl.CanAddInCadastro, ctrl.VariavelNo)">
                  <mat-icon>add</mat-icon>
                  <ng-container *ngIf="footer.offsetWidth >= 300">{{ textBtnNew }}</ng-container>
                </button>
                <button mat-stroked-button color="warn" [class.btn-sm]="footer.offsetWidth < 300" *ngIf="ctrl?.CanExcludeItem && !isReadOnly(ctrl)"
                  (click)="onDeleteItems($event, ctrl.CanAddInCadastro)">
                  <mat-icon>delete</mat-icon>
                  <ng-container *ngIf="footer.offsetWidth >= 300">{{ textBtnDelete }}</ng-container>
                </button>
              </div>
              <div class="td-search">
                <input type="text" class="search" [value]="search" #inputSearch *ngIf="footer.offsetWidth >= 350"
                  (keyup)="onSearchChange(inputSearch.value)" (keyup.esc)="onSearchEsc($event)" />
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table> -->
  </div>
</div>