import { ConfigStateService } from '@medlogic/shared/state-config';
import { NavigationService } from '../../shared/service/navigation.service';
import { Params, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ITarefa } from '../../shared/interface/itarefa';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { DisplayObject } from '@medlogic/shared/gecore';
import { IAtividade } from '../../shared/interface/iatividade';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-tarefa-view',
  templateUrl: './tarefa-view.component.html',
  styleUrls: ['./tarefa-view.component.css']
})
export class TarefaViewComponent extends DisplayObject implements OnInit {

  keyword: Event | string = '';
  date = '';
  time = '';
  search = '';
  tasks: Array<ITarefa>;
  title = 'Tarefas';

  constructor(
    protected log: LogService,
    protected config: ConfigStateService,
    protected global: GlobalService,
    protected navigation: NavigationService,
    protected route: ActivatedRoute,
    protected msg: MsgPtBR,
    // protected store: Store<AppState>
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.getUrlParams();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Extrai os parâmetros passados na url/rota */
  protected getUrlParams(): void {
    try {// Os parametros estão sendo passados diretamente aos componentes
      this.subs.sink = this.route.params
        .pipe(
          mergeMap((params: Params) => {
            if (params.key) {
              this.date = params.key;
            }
            if (params.time) {
              this.time = params.time;
            }
            // this.store.dispatch(loadApp({ title: this.title }));
            this.navigation.addToHistory(this.navigation.getRouteTask(this.date, this.time), this.msg.MODULE_NAME_TAREFA);
            // O refresh não pode ser no ngOnInit, pois, quando a rota não muda, mas apenas os parâmetros da rota,
            // esse método não é chamado novamente.
            // Se o parâmetro for atualizado, atualiza o carregamento da tela
            return this.refresh();
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /*  */
  refresh(): Observable<IAtividade> {
    try {
      return of(null);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Voltar */
  onBack(): void {
    try {
      this.navigation.goBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

}
