import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';

@Injectable()
export class OcorrenciaService {
  urlGetPesquisaInteligente = 'Data/GetDataSmartSearch?FraseBusca={0}';
  urlGetTarefaByOcorrenciaNo = 'Data/GetTask?ono={0}&ano={1}';
  // tslint:disable-next-line: max-line-length
  urlGetDataOccurrence = 'Data/GetDataOccurrence?ProcessoNo={0}&OcorrenciaNo={1}&LstVariaveisRetorno={2}&VariavelGrid={3}&LstVariaveisGridRetorno={4}&VariavelMapa={5}&LstVariaveisMapa={6}&FiltroExtra={7}';

  constructor(private basepage: BasePageService, private log: LogService) { }

  getPesquisaInteligente(fraseBusca: string): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGetPesquisaInteligente, fraseBusca);
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getPesquisaInteligente', error.message);
    }
    return of(null);
  }

  getTarefaByOcorrenciaNo(ocorrenciaNo: number, atividadeNo: number): Observable<any> {
    try {
      const urlGet = this.basepage.format(
        this.urlGetTarefaByOcorrenciaNo,
        ocorrenciaNo,
        atividadeNo
      );
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getTarefaByOcorrenciaNo', error.message);
    }
    return EMPTY;
  }

  GetDataOccurrence(
    ProcessoNo: number,
    OcorrenciaNo: number,
    LstVariaveisRetorno,
    VariavelGrid = '',
    LstVariaveisGridRetorno = '',
    VariavelMapa = '',
    LstVariaveisMapa = '',
    FiltroExtra = ''
  ): Observable<any> {
    try {
      const urlGet = this.basepage.format(
        this.urlGetDataOccurrence,
        ProcessoNo,
        OcorrenciaNo,
        LstVariaveisRetorno,
        VariavelGrid,
        LstVariaveisGridRetorno,
        VariavelMapa,
        LstVariaveisMapa,
        FiltroExtra
      );
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'GetDataOccurrence', error.message);
    }
    return EMPTY;
  }
}
