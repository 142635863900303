<mat-toolbar color="basic">
  <span class="example-spacer"></span>
  <mat-icon class="example-icon" (click)="onCloseClick()">close</mat-icon>
</mat-toolbar>

<div>
  <h1>{{ data.title }}</h1>
  <form [formGroup]="fg">
    <mat-form-field>
      <input matInput placeholder="Escolha um período" [satDatepicker]="picker" formControlName="date">
      <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
      <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
    </mat-form-field>
  </form>
  <mat-toolbar class="bottom-bar">
    <button mat-raised-button (click)="onYesClick($event)" [disabled]="disabledButton">{{ data.btnYes }}</button>
  </mat-toolbar>
</div>
