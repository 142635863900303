import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { IListItem } from '@medlogic/shared/shared-interfaces';

import * as stringSimilarity from 'string-similarity';

/** Pesquisa no nome do medicamento ou em uma das marcas.
 * Tanto verifica se a keyword está contida, quando aplica algorítimo de similaridade.
 */
@Pipe({
  name: 'itemSearch'
})
export class ItemSearchPipe implements PipeTransform {

  constructor(
    private log: LogService,
    private glb: GlobalService,
  ) { }

  transform(items: IListItem<any>[] | any[], keyword: string, NUM_MIN_OF_CHAR_SEARCH: number = 3, SIMILARITY_MIN: number = 0.6): any {
    try {
      if (items?.length > 0 && keyword?.length >= NUM_MIN_OF_CHAR_SEARCH) {
        return items.filter(item => {
          return Object.keys(item)
            .reduce(
              (a, b) => a || this.isSimilar(item[b], keyword, SIMILARITY_MIN),
              false);
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return items;
  }

  /** Check if one of the items property is similar to the keyword. */
  protected isSimilar(itemPropertyValue: string, keyword: string, SIMILARITY_MIN: number): boolean {
    try {
      keyword = this.prepareStr(keyword);
      const item = this.prepareStr(itemPropertyValue);
      const similarity = stringSimilarity.compareTwoStrings(keyword, item);
      const has = item.includes(keyword);
      return (similarity >= SIMILARITY_MIN) || has;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isSimilar', error.message);
    }
    return false;
  }

  /** Prepara a string para comparação, deixa maiúscula, remove y, ç. */
  protected prepareStr(str: string): string {
    try {
      return this.glb.RemoverAcentos(str.toString()).toUpperCase();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'prepare', error.message);
    }
    return '';
  }

}
