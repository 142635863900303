import { LogService } from '@medlogic/shared/shared-interfaces';
import { Component, Input } from '@angular/core';
import { EnTheme, IListItem } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'ui-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent {

  @Input() listItem: IListItem<any>;
  @Input() enTheme: EnTheme;

  ENTHEME = EnTheme;

  constructor(
    private log: LogService
  ) { }

  getLetter(txt: string): string {
    try {
      return txt?.substr(0, 1) || '';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLetter', error.message);
    }
    return '';
  }


}
