import { Injectable } from '@angular/core';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { GlobalService, LogService, ConfigJsonService, error } from '@medlogic/shared/shared-interfaces';
import { IExameResultado } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { LocalLibService } from '@medlogic/shared/shared-interfaces';
import { defaultIfEmpty, toArray, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { ExameResultadoService } from './exame-resultado.service';

@Injectable()
export class ExameResultadoCustomService extends ExameResultadoService {

    constructor(
        cadastroSrv: CadastroService,
        glb: GlobalService,
        cnf: ConfigJsonService,
        lib: LocalLibService,
        log: LogService) {
        super(cadastroSrv, glb, cnf, lib, log);
        try {
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'constructor', error.message);
        }
    }

    getbyExame(ano: number, idExame: number): Observable<IExameResultado> {
        try {
            if (ano && ano > 0) {
                this.cadastroNo = ano;
                const startDate = new Date(1900, 0, 1);
                const endDate = new Date(2500, 0, 1);
                const strFilter = `V_28598:${idExame}`;
                return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate)
                    .pipe(
                        defaultIfEmpty(null)
                    );
            }
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getbyExame', error.message);
        }
        return of(null);
    }

    getByPerson(ano: number, idPerson: number): Observable<IExameResultado[]> {
        try {
            if (ano && ano > 0) {
                this.cadastroNo = ano;
                const startDate = new Date(1900, 0, 1);
                const endDate = new Date(2500, 0, 1);
                const strFilter = `V_2230:${idPerson}`;
                return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate)
                    .pipe(
                        toArray(),
                        this.filterSameTestByNewerDateTime(),
                        defaultIfEmpty(null),
                        error()
                    );
            }
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getbyPerson', error.message);
        }
        return of(null);
    }

    filterSameTestByNewerDateTime = () => map((resultados: IExameResultado[]) => {
        const filtered = new Array<IExameResultado>();
        resultados?.forEach(e => {
            const idx = filtered?.findIndex(f =>
                this.glb.isEqualIgnoreTime(f?.dataExame, e?.dataExame) &&
                e?.idExame === f?.idExame
            );
            if (idx < 0) {
                filtered?.push(e);
            } else if (this.glb.compareDates(filtered[idx]?.dataExame, e?.dataExame) <= 0) {
                filtered[idx] = e;
            }
        });
        return filtered;
    });


}

