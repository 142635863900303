import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[libCtrlGridTBody]'
})
export class CtrlGridTBodyDirective implements OnInit {

  // tslint:disable-next-line: variable-name
  private _ctrl: any;
  @Input('libCtrlGridTBody') public get ctrl(): any {
    return this._ctrl;
  }

  public set ctrl(v: any) {
    this._ctrl = v;
  }

  @Input() head: any;
  @Input() footer: any;
  @Input() lblTitle: any;

  constructor(private el: ElementRef, private renderer: Renderer2, private log: LogService) {
  }

  // Para resolver o problema do - na atualização, procurar um evento que não seja o Init
  ngOnInit(): void {
    try {
      this.applyStyles(this.ctrl, this.head, this.footer, this.lblTitle);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  // O tamanho precisa ser fixado para que a rolagem funcione.
  // Necessita descontar as alturas do rodapé e cabeçalho
  applyStyles(ctrl: IAtividadeComponenteDAL, head: any, footer: any, lblTitle: any): void {
    try {
      if (ctrl) {
        const altura = ctrl.Altura - head.clientHeight - footer.clientHeight - (lblTitle ? lblTitle.clientHeight || 60 : 60);
        this.renderer.setStyle(this.el.nativeElement, 'max-height', altura + 'px');
        this.renderer.setStyle(this.el.nativeElement, 'height', altura + 'px');
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyStyles', error.message);
    }
  }

}
