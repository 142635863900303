import { Injectable } from '@angular/core';
import { EnContentType, EnRequestType, error, LogService, routeDeleteRedisAllByCustomerId, routeDeleteRedisAllByTenantId, routeRedisDeleteTenantKeys, routeRedisPostLogPrescription } from '@medlogic/shared/shared-interfaces';
import { map, Observable, of } from 'rxjs';
import { BasePageService } from './base-page.service';

@Injectable({
  providedIn: 'root'
})
export class RedisService {

  // readonly urlRedisDeleteTenantKeys = routeRedisDeleteTenantKeys;
  readonly urlRedisDeleteTenantKeys = routeDeleteRedisAllByTenantId;
  readonly urlRedisDeleteCustomerKeys = routeDeleteRedisAllByCustomerId;
  readonly urlRedisPostLogPrescription = routeRedisPostLogPrescription;
  constructor(
    private basepage: BasePageService,
    private log: LogService,
  ) {
  }

  deleteTenantKeys(): Observable<boolean> {
    try {
      const url = this.basepage.format(this.urlRedisDeleteTenantKeys);
      return this.basepage.baseDadosReplay(EnRequestType.Delete, url, {}, 2, EnContentType.Json)
        .pipe(
          map(res => res || (['OK', 'true'].includes(res))),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteTenantKeys', error.message);
    }
    return of(null);
  }

  deleteCustomerKeys(): Observable<boolean> {
    try {
      const url = this.basepage.format(this.urlRedisDeleteCustomerKeys);
      return this.basepage.baseDadosReplay(EnRequestType.Delete, url, {}, 2, EnContentType.Json)
        .pipe(
          map(res => res || (['OK', 'true'].includes(res))),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteCustomerKeys', error.message);
    }
    return of(null);
  }

  postLogPrescription(prescriptionId: number, message: string): Observable<boolean> {
    try {
      if (prescriptionId && prescriptionId > 0) {
        const url = this.basepage.format(this.urlRedisPostLogPrescription);
        const payload = {
          prescriptionId,
          message
        };
        return this.basepage.baseDadosReplay(EnRequestType.Post, url, payload, 2, EnContentType.Json)
          .pipe(
            map(res => res === 'OK'),
            error()
          );
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'postLogPrescription', error.message);
    }
    return of(null);
  }


}
