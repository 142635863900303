import { IPersonRelationship } from './iperson-relationship';

export interface IPersonProfessional extends IPersonRelationship {
  idProfissional: number;
  // ocorrenciaNo: number;
  // idPessoa: number;
  // codigo: any;
  // titulo: string;
  // habilitado: boolean;
  // status: string;
  // idUsuario: number;
}
