export interface IEvolucaoFono {
  ocorrenciaNo: number;
	nomeIdoso:  any;
	nascimento:  Date;
	nomeMae:  any;
	identificacao1:  any;
	identificacao2:  any;
	prontuario:  any;
	dataAniversario:  any;
	dtEvolucao:  Date;
	admissao:  string;
	idade:  any;
	endereco:  string;
	telefone:  string;
	celular:  string;
	email:  string;
	pai:  string;
	quarto:  string;
	local:  string;
	tipo:  string;
	usoespessanteTablet:  boolean;
	tipoConsistenciaTablet:  string;
	utensiliosingestaoliquidos:  string;
	seOutrosDescreva:  string;
	utensiliosalimentacao:  string;
	seOutrosDescreva1:  string;
	suspenderDietaTablet:  boolean;
	motivo:  string;
	dataInicio:  Date;
	horaInicio:  any;
	dataFim:  Date;
	horaFim:  any;
	observacoes:  string;
	sigla:  any;
	ofertaVO:  string;
	seOutrosDescreva2:  string;
	consistencia:  string;
	tipo2:  string;
	localRefeicao:  string;
	descreva:  string;
	escalaFOIS:  string;
	proporcaoEspessanteMedida:  string;
	oRIENTACAOFONOAUDIOLOGAESPESSANTE:  string;
	usoBandagem:  boolean;
	local3:  string;
	trocaBandagem:  Date;
	pilha:  string;
	tipo3:  string;
	modelo:  any;
	observacoes2:  string;
	proteseAuditiva:  boolean;
	proteseDentaria:  boolean;
	observacoes3:  string;
	nome:  any;
	cRF:  any;
	comunicacaoForma:  string;
	descreva2:  string;
	dataOcorrencia:  any;
	codigo:  any;
	titulo:  any;
	habilitado:  boolean;
	conduta:  any;
	houveMudancadietaTablet:  boolean;
	refeicaoComAdaptacaoTablet:  boolean;
  }
