import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { IListItem } from '@medlogic/shared/shared-interfaces';


/* Caso o parâmetro esteja ativado, retorna apenas a primeira ocorrência.
 */
@Pipe({
  name: 'firstOno'
})
export class FirstOnoPipe implements PipeTransform {

  constructor(
    private log: LogService,
    private glb: GlobalService,
  ) { }

  transform(items: IListItem<any>[] | any[], showOnlyFirst: boolean, isSingleRegister: boolean = false, fieldName: string = 'ono'): any {
    try {
      if (items?.length > 0 && (showOnlyFirst || isSingleRegister)) {
        items = items.sort((a, b) => Number(b[fieldName]) - Number(a[fieldName]));
        return [items[0]];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return items;
  }


}
