import { IIntervecoesMedicamentosHorarios } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigStateService } from '@medlogic/shared/state-config';

@Injectable()
export class IntervencoesMedicamentosHorariosService {

  private lstVariaveis = 'V_30392,V_30393,V_30394,V_30395,V_30401,V_29828,V_30263';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IIntervecoesMedicamentosHorarios[] = new Array<IIntervecoesMedicamentosHorarios>();
  codigoVariavelNo = 30392;
  cadastroNo = 4892;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IIntervecoesMedicamentosHorarios> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(this.cadastroNo), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
    return of(null);
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IIntervecoesMedicamentosHorarios> {
    try {
      if (
        (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
        || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
        || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
      } else {
        // console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis)
        .pipe(
          map((c: any) => ({
            ocorrenciaNo: c.OcorrenciaNo,
            codigo: c.V_30392,
            titulo: c.V_30393,
            habilitado: this.glb.getBoolean(c.V_30394), // c.V_30394.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_30394) ? true : false,
            Id_Paciente: c.V_29828,
            Id_Medicamento: c.V_30263,
            IdMedicamento_IdPaciente: c.V_30401,
            hora: c.V_30395,
            // Segundo levantamento do Rony em 29/jan esses campos não existem mais
            // codigoMedicamentoPaciente: c.V_30401,
            // ***
          } as IIntervecoesMedicamentosHorarios)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadastro', error.message);
    }
    return of(null);
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}` */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IIntervecoesMedicamentosHorarios> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Conversão para o tipo de objeto principal. */
  protected toAttribute(c: any): IIntervecoesMedicamentosHorarios {
    try {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
        codigo: c.V_30392,
        titulo: c.V_30393,
        habilitado: c.V_30394.toUpperCase() === 'SIM' || this.glb.IsNullOrEmpty(c.V_30394) ? true : false,
        hora: c.V_30395,
        Id_Paciente: c.V_29828,
        Id_Medicamento: c.V_30263,
        IdMedicamento_IdPaciente: c.V_30401,
        // Segundo levantamento do Rony em 29/jan esses campos não existem mais
        // codigoMedicamentoPaciente: c.V_30401
        // ***
      } as IIntervecoesMedicamentosHorarios
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  save<T>(ano: number, intervecoesMedicamentosHorarios: T, uno: number, id?: number): Observable<T> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(intervecoesMedicamentosHorarios as unknown as IIntervecoesMedicamentosHorarios).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo, true)
        .pipe(
          map(ocorrenciaNo => ({ ...intervecoesMedicamentosHorarios, ocorrenciaNo, codigo: ocorrenciaNo } as T))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(intervecoesMedicamentosHorarios: IIntervecoesMedicamentosHorarios): IForm[] {
    try {
      return [
        { VariavelNo: 30392, ValorDado: intervecoesMedicamentosHorarios.codigo || '' },
        { VariavelNo: 30393, ValorDado: intervecoesMedicamentosHorarios.titulo || '' },
        { VariavelNo: 30394, ValorDado: intervecoesMedicamentosHorarios.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 30395, ValorDado: intervecoesMedicamentosHorarios.hora || '' },
        { VariavelNo: 30401, ValorDado: intervecoesMedicamentosHorarios.IdMedicamento_IdPaciente || '' },
        { VariavelNo: 29828, ValorDado: intervecoesMedicamentosHorarios.Id_Paciente || '' },
        { VariavelNo: 30263, ValorDado: intervecoesMedicamentosHorarios.Id_Medicamento || '' },
        // { VariavelNo: 30401, ValorDado: intervecoesMedicamentosHorarios.IdMedicamento_IdPaciente || '' }
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }


}
