import { FirstOnoPipe } from './pipes/first-ono.pipe';
import { PdfGeneratorService } from './service/pdf-generator.service';
import { GrowthDirective } from './directives/growth.directive';

import { CustomCookieService } from './service/custom-cookie.service';
import { StoreProviderService } from './service/store.provider';
import { ItemSearchPipe } from './pipes/item-search.pipe';
import { LocalStorageService } from './service/local-storage.service';
import { LocalLibService } from './service/lib.service';
import { KzCnpjPipe } from './pipes/kz-cnpj.pipe';
import { KzCpfCnpjPipe } from './pipes/kz-cpf-cnpj.pipe';
import { KzCpfPipe } from './pipes/kz-cpf.pipe';
import { MinusSignToParens } from './pipes/minus-signs-to-parens.pipe';

import { SharedInterfacesNgModule } from '@medlogic/shared/shared-interfaces';
import { IndexedDbStorageService } from './service/indexed-db-storage.service';
import { RandomBgDirective } from './directives/random-bg-color.directive';
import { AppearDirective } from './directives/appear.directive';
import { TimePipe } from './pipes/time.pipe';
import { WebSocketService } from './service/websocket.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtcDatePipe } from './pipes/utc-date.pipe';

const SERVICES = [
  LocalLibService,
  LocalStorageService,
  StoreProviderService,
  CustomCookieService,
  IndexedDbStorageService,
  PdfGeneratorService,
  WebSocketService
]

const PIPES = [
  ItemSearchPipe,
  KzCnpjPipe,
  KzCpfCnpjPipe,
  KzCpfPipe,
  MinusSignToParens,
  FirstOnoPipe,
  TimePipe,
  UtcDatePipe
];

const DIRECTIVES = [
  GrowthDirective,
  RandomBgDirective,
  AppearDirective
]

const MODULES = [
  CommonModule,
  SharedInterfacesNgModule,
];

@NgModule({
  declarations: [PIPES, DIRECTIVES],
  imports: [MODULES],
  exports: [PIPES, DIRECTIVES],
  providers: [SERVICES]
})
export class SharedUtilsModule { }
