import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { ICadastroListaDAL } from '@medlogic/shared/shared-interfaces';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'libCtrlMatGridSearch'
})
export class CtrlMatGridSearchPipe implements PipeTransform {
  constructor(
    private global: GlobalService,
    private log: LogService,
  ) { }

  transform(
    dataSource: MatTableDataSource<ICadastroListaDAL>,
    keyword: string
  ): MatTableDataSource<ICadastroListaDAL> {
    try {
      if (!dataSource || !dataSource.data || dataSource.data.length <= 0) { return dataSource; }
      dataSource.filter = keyword;
      return dataSource;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return dataSource;

    // ((item) => {
    //   try {
    //     let isVisible = false;
    //     columns.every((c) => {
    //       try {
    //         const clmName = `V_${c.VariavelNo}`;
    //         const value = typeof item[clmName] === 'string' ? item[clmName] : item[clmName][0];
    //         if (this.global.ContemSemAcentos(keyword, value)) {
    //           isVisible = true;
    //           return false;
    //         }
    //       } catch (error) {
    //         this.log.Registrar(this.constructor.name, 'transform.filter.every', error.message);
    //       }
    //       return true;
    //     });
    //     return isVisible;
    //   } catch (error) {
    //     this.log.Registrar(this.constructor.name, 'transform.filter', error.message);
    //   }
    //   return false;
    // });
  }


}
