import { IOutcomeCodeableConcept } from './ioutcome-codeable-concept';

export interface ICoding {
    codeGuid: string;
    system: string;
    version: string;
    code: string;
    display: string;
    userSelected: string;
    tenantId: number;
    language: string;
    outcomeCodeableConcept: IOutcomeCodeableConcept[];
}