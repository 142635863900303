import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { TenantActionTypes } from './medlogic-tenant.actions';
import { initialStateTenant } from './medlogic-tenant.reducer';

const KEY = 'MEDLOGIC_TENANT_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  TenantActionTypes.LoadTenant,
  TenantActionTypes.SetTenant,
  TenantActionTypes.SetTenantId,
  TenantActionTypes.SetTenantToken,
  TenantActionTypes.SetTenantOno,
  TenantActionTypes.SetTenantCustomerId,
  TenantActionTypes.SuccessTenant,
  TenantActionTypes.SetTenantTheme,
];

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialStateTenant);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
