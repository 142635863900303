import { BehaviorSubject } from 'rxjs';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { ListControl } from '../../model/list-control';
import { CalculadoraService } from '../../service/calculadora.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, IAtividadeComponenteDAL, ConfigJsonService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { CadastroListaDAL } from '../../model/dal/cadastro-lista-dal';
import { Router } from '@angular/router';
import { NavigationService } from '../../service/navigation.service';
import { ExecucaoTarefaDAL } from '../../model/dal/execucao-tarefa-dal';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { GeFormProviderService } from '../../service/ge-form-provider.service';
import { FormGroup } from '@angular/forms';
import { IBubble } from '../../interface/ibubble';
import { IVariable } from '../../interface/ivariable';
import { ICadastro } from '../../interface/icadastro';

export interface IChecklistItem {
  label: string;
  checked: boolean;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-checkbox-list',
  templateUrl: './ctr-checkbox-list.component.html',
  styleUrls: ['./ctr-checkbox-list.component.scss']
})
export class CtrCheckboxListComponent extends ListControl implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  SEPARATOR_CHAR = ';';

  checklistItems$ = new BehaviorSubject<IChecklistItem[]>(null);
  allComplete: boolean = false;

  ENTHEME = EnTheme;

  constructor(
    global: GlobalService,
    log: LogService,
    lib: LibService,
    cnf: ConfigStateService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    cadastroListaDAL: CadastroListaDAL,
    cnfJson: ConfigJsonService,
    router: Router,
    navigation: NavigationService,
    execucaoTarefa: ExecucaoTarefaDAL,
    dialog: MatDialog,
    msg: MsgPtBR,
    geFormPrv: GeFormProviderService
  ) {
    super(
      global,
      log,
      lib,
      cnf,
      calc,
      calcCond,
      dialog,
      msg,
      cadastroListaDAL,
      cnfJson,
      router,
      navigation,
      execucaoTarefa,
      geFormPrv
    );
  }

  ngOnInit() {
    try {
      this.subs.sink = this.getCadastros(+this.cnf.usuarioLogadoNo, this.ctrl, this.valorFiltro)
        .subscribe((s) => {
          this.cadastros = s;
          this.checklistItems$.next(this.fillChecklistItems(this.cadastros, this.ctrl));
          this.isLoading = false;
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  protected fillChecklistItems(cadastros: ICadastro[], ctrl: IAtividadeComponenteDAL): IChecklistItem[] {
    try {
      return cadastros.map(cadastro => ({ label: cadastro.Valor, checked: this.isChecked(ctrl, cadastro.Valor) } as IChecklistItem));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillChecklistItems', error.message);
    }
    return null;
  }

  isChecked(ctrl: IAtividadeComponenteDAL, item: string): boolean {
    try {
      // return ctrl?.ValorTexto && this.global.ContemSemAcentos(item, ctrl.ValorTexto);
      return ctrl?.ValorTexto &&
        !!ctrl.ValorTexto.replace(/;\s*/g, ';').split(this.SEPARATOR_CHAR).find(f => this.global.isEqual(item, f));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isChecked', error.message);
    }
    return false;
  }

  // onClick(ctrl: IAtividadeComponenteDAL, item: string): void {
  //   try {
  //     if (this.global.ContemSemAcentos(item, ctrl.ValorTexto)) {
  //       ctrl.ValorTexto = ctrl.ValorTexto.replace(item, '').replace(';;', ';');
  //       ctrl.Valor = ctrl.ValorTexto;
  //     } else {
  //       ctrl.ValorTexto += item + this.SEPARATOR_CHAR;
  //       ctrl.Valor = ctrl.ValorTexto;
  //     }
  //     this.global.removeLastOcurrence(ctrl.ValorTexto, this.SEPARATOR_CHAR);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'onClick', error.message);
  //   }
  // }

  updateAllComplete(ctrl: IAtividadeComponenteDAL): void {
    try {
      this.allComplete = this.checklistItems$.value != null && this.checklistItems$.value.every(t => t.checked);
      this.updateValorTexto(ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateAllComplete', error.message);
    }
  }

  someComplete(): boolean {
    try {
      if (this.checklistItems$.value === null) {
        return false;
      }
      return this.checklistItems$.value.filter(t => t.checked).length > 0 && !this.allComplete;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'someComplete', error.message);
    }
    return null;
  }

  setAll(checked: boolean, ctrl: IAtividadeComponenteDAL): void {
    try {
      this.allComplete = checked;
      if (this.checklistItems$.value === null) {
        return;
      }
      this.checklistItems$.next(this.checklistItems$.value.map(t => ({ ...t, checked })));
      this.updateValorTexto(ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setAll', error.message);
    }
  }

  protected updateValorTexto(ctrl: IAtividadeComponenteDAL): void {
    try {
      ctrl.ValorTexto = ctrl.Valor = this.checklistItems$.value?.filter(f => f.checked)?.map(m => m.label)?.join(this.SEPARATOR_CHAR);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateValorTexto', error.message);
    }
  }


}
