import { EnActivityType, routeActivity } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FhirGenericService } from './fhir-generic.service';
import { IActivity } from '@medlogic/shared/shared-interfaces';

@Injectable({ providedIn: 'root' })
export class FhirActivityService extends FhirGenericService {

  protected url = routeActivity;

  // CUSTOM API INTERACTION

  getApiVersion(): Observable<string | null> {
    const url = `${this.getUrl()}version`;
    try {
      const $result = this.http.get<string>(url);
      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getApiVersion', error.message);
    }
    return of(null);
  }

  getActivitiesByPatientId<T>(medlogicId: number | null): Observable<T | null> {
    const url = `${this.getUrl()}getAll?medlogicId=${medlogicId}`;
    try {
      const $result = this.http.get<T>(url);
      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(null);
  }

  getCurrentActivity(progressIdentifier: string): Observable<IActivity | null> {
    const url = `${this.getUrl()}/getCurrentActivity?id=${progressIdentifier}`;
    try {
      const $result = this.http.get<IActivity>(url);
      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getCurrentActivity', error.message);
    }
    return of(null);
  }

  getActivityDetailByType(type: EnActivityType): Observable<IActivity | null> {
    const url = `${this.getUrl()}/getCurrentActivity?type=${type}`;
    try {
       const $result = this.http.get<IActivity>(url);
       return $result;
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'getActivityDetailByType', error.message);
    }
    return of(null);
  }

}
