<div class="activity-container" [class.done]="item?.status == 'done'">

  <div class="clm-1" [class.done]="item?.status == 'done'" [class.late]="item?.status == 'late'"
    [class.pending]="item?.status == 'pending'" [class.todo]="item?.status == 'todo'">

    <mat-checkbox class="example-margin" [checked]="false">
      <!-- (change)="setAll($event.checked)" -->
    </mat-checkbox>

  </div>

  <div class="clm-2">
    <mat-icon aria-label="Almoco/jantar" svgIcon="almoco-jantar"></mat-icon>
  </div>

  <div class="clm-3">

    <span>Acompanhamento</span>

  </div>

  <div class="clm-4">

    <div class="v-divider"></div>

    <div class="colaboration">
      <span>Teve ajuda?</span>
      <mat-slide-toggle></mat-slide-toggle>
    </div>

  </div>

</div>
