<div class="top-bar">
  <app-btn-back (backButtonClicked)="onBack($event)"></app-btn-back>
</div>

<div class="card-medication-container">
  <h1>Checkin de Medicamentos do Residente</h1>

  <ng-container *ngIf="patient$ | async as patient">
    <div class="wrapper wrapper-content animated fadeInRight scroll">
      <div class="row">
        <ml-patient-header
          [patient]="patient"
          [showChangePatient]="true"
          [patients]="patients$ | async"
          (patientSelected)="onPatientSelected($event)"
          [showSelect]="true"
          (select)="functionSelect($event)"
        >
        </ml-patient-header>

        <!-- Medicamentos -->
        <div
          id="vertical-timeline"
          class="vertical-container dark-timeline"
          *ngIf="medicationPeriods$ | async as medicationPeriods; else loading"
        >
          <div
            *ngFor="let period of medicationPeriods"
            class="vertical-timeline-block"
            [class]="getPeriodClass(period)"
          >
            <ng-container
              *ngLet="isUpdateStockOnApp$ | async as isUpdateStockOnApp"
            >
              <div
                *ngIf="
                  getMedicationsByType(showMedication, period).length > 0 &&
                  getMedicationsByType(showMedication, period) as medications
                "
                class="headerMedication"
              >
                <h2 class="title">{{ period?.title }}</h2>
                <button
                  class="checkinAll"
                  mat-raised-button
                  *ngIf="allMedicationsHaveBeenChecked(medications)"
                  type="button"
                  (click)="onCheckinAll(medications, patient, isUpdateStockOnApp)"
                >
                  <mat-icon>checklist</mat-icon>Marcar Todos
                </button>
              </div>
              <div *ngIf="showMedication == 0">
                <div
                  *ngFor="let medication of period?.medications"
                  class="vertical-timeline-block"
                >
                  <div
                    *ngIf="medication?.stopMedication"
                    class="vertical-timeline-content"
                  >
                    <div>
                      {{ medication.medicationName }} -
                      {{ getDosage(medication) }}
                    </div>
                    <div>via: {{ medication.access }}</div>
                    <div>
                      {{ getInstructions(medication) }}
                    </div>
                  </div>

                  <!-- <a href="javascript:void(null)" -->
                  <a
                    *ngIf="medication?.status != '-'"
                    href="javascript:void(null)"
                    (click)="
                      showDialog(medication, patient, isUpdateStockOnApp)
                    "
                  >
                    <ng-container [ngSwitch]="wasChecked(medication)">
                      <div
                        *ngSwitchCase="true"
                        class="vertical-timeline-icon gray-bg"
                      >
                        <i class="fa fa-check" *ngIf="wasTaken(medication)"></i>
                        <i class="fa fa-ban" *ngIf="!wasTaken(medication)"></i>
                      </div>

                      <div
                        *ngSwitchCase="false"
                        class="vertical-timeline-icon red-bg"
                      >
                        <i
                          class="fa fa-heart"
                          *ngIf="!checkIsSaving(medication)"
                        ></i>
                        <i
                          class="fa fa-asterisk"
                          *ngIf="checkIsSaving(medication)"
                        ></i>
                      </div>
                    </ng-container>

                    <div
                      *ngIf="medication?.status != '-'"
                      class="vertical-timeline-content"
                    >
                      <h2>
                        {{ medication?.medicationName }}
                        {{ getDosage(medication) }}
                      </h2>
                      <h2>Via: {{ medication?.access }}</h2>
                      <h2>
                        Qtd: {{ getQtd(medication) }} -
                        {{ getApresentacao(medication) }}
                      </h2>

                      <h2 *ngIf="period?.title != 'SOS'">
                        {{ medication?.prescribedTime | time }}
                      </h2>
                      <!-- <span class="text-muted"> Prescrição: {{ medication?.prescription }} </span>
                    <br />
                    <br /> -->
                      <span class="text-muted instruction">
                        {{ getInstructions(medication) }}
                      </span>

                      <h5 *ngIf="wasChecked(medication)" class="text-success">
                        {{
                          wasTaken(medication) ? 'Tomou em:' : 'Rejeitou em:'
                        }}
                        {{ medication?.medicationDate | date: 'short' }}
                      </h5>

                      <h6 *ngIf="wasChecked(medication)" class="text-success">
                        {{ getCheckinObservation(medication) }}
                      </h6>
                    </div>
                  </a>
                </div>
              </div>
              <div *ngIf="showMedication == 1">
                <div
                  *ngFor="let medication of period?.exceptCuidados"
                  class="vertical-timeline-block"
                >
                  <div
                    *ngIf="medication?.stopMedication"
                    class="vertical-timeline-content"
                  >
                    <div>
                      {{ medication.medicationName }} -
                      {{ getDosage(medication) }}
                    </div>
                    <div>via: {{ medication.access }}</div>
                    <div>
                      {{ getInstructions(medication) }}
                    </div>
                  </div>

                  <!-- <a href="javascript:void(null)" -->
                  <a
                    *ngIf="medication?.status != '-'"
                    href="javascript:void(null)"
                    (click)="
                      showDialog(medication, patient, isUpdateStockOnApp)
                    "
                  >
                    <ng-container [ngSwitch]="wasChecked(medication)">
                      <div
                        *ngSwitchCase="true"
                        class="vertical-timeline-icon gray-bg"
                      >
                        <i class="fa fa-check" *ngIf="wasTaken(medication)"></i>
                        <i class="fa fa-ban" *ngIf="!wasTaken(medication)"></i>
                      </div>

                      <div
                        *ngSwitchCase="false"
                        class="vertical-timeline-icon red-bg"
                      >
                        <i
                          class="fa fa-heart"
                          *ngIf="!checkIsSaving(medication)"
                        ></i>
                        <i
                          class="fa fa-asterisk"
                          *ngIf="checkIsSaving(medication)"
                        ></i>
                      </div>
                    </ng-container>

                    <div
                      *ngIf="medication?.status != '-'"
                      class="vertical-timeline-content"
                    >
                      <h2>
                        {{ medication?.medicationName }}
                        {{ getDosage(medication) }}
                      </h2>
                      <h2>Via: {{ medication?.access }}</h2>
                      <h2>
                        Qtd: {{ getQtd(medication) }} -
                        {{ getApresentacao(medication) }}
                      </h2>

                      <h2 *ngIf="period?.title != 'SOS'">
                        {{ medication?.prescribedTime | time }}
                      </h2>
                      <!-- <span class="text-muted"> Prescrição: {{ medication?.prescription }} </span>
                    <br />
                    <br /> -->
                      <span class="text-muted instruction">
                        {{ getInstructions(medication) }}
                      </span>

                      <h5 *ngIf="wasChecked(medication)" class="text-success">
                        {{
                          wasTaken(medication) ? 'Tomou em:' : 'Rejeitou em:'
                        }}
                        {{ medication?.medicationDate | date: 'short' }}
                      </h5>

                      <h6 *ngIf="wasChecked(medication)" class="text-success">
                        {{ getCheckinObservation(medication) }}
                      </h6>
                    </div>
                  </a>
                </div>
              </div>
              <div *ngIf="showMedication == 2">
                <div
                  *ngFor="let medication of period?.cuidadosMedication"
                  class="vertical-timeline-block"
                >
                  <div
                    *ngIf="medication?.stopMedication"
                    class="vertical-timeline-content"
                  >
                    <div>
                      {{ medication.medicationName }} -
                      {{ getDosage(medication) }}
                    </div>
                    <div>via: {{ medication.access }}</div>
                    <div>
                      {{ getInstructions(medication) }}
                    </div>
                  </div>

                  <!-- <a href="javascript:void(null)" -->
                  <a
                    *ngIf="medication?.status != '-'"
                    href="javascript:void(null)"
                    (click)="
                      showDialog(medication, patient, isUpdateStockOnApp)
                    "
                  >
                    <ng-container [ngSwitch]="wasChecked(medication)">
                      <div
                        *ngSwitchCase="true"
                        class="vertical-timeline-icon gray-bg"
                      >
                        <i class="fa fa-check" *ngIf="wasTaken(medication)"></i>
                        <i class="fa fa-ban" *ngIf="!wasTaken(medication)"></i>
                      </div>

                      <div
                        *ngSwitchCase="false"
                        class="vertical-timeline-icon red-bg"
                      >
                        <i
                          class="fa fa-heart"
                          *ngIf="!checkIsSaving(medication)"
                        ></i>
                        <i
                          class="fa fa-asterisk"
                          *ngIf="checkIsSaving(medication)"
                        ></i>
                      </div>
                    </ng-container>

                    <div
                      *ngIf="medication?.status != '-'"
                      class="vertical-timeline-content"
                    >
                      <h2>
                        {{ medication?.medicationName }}
                        {{ getDosage(medication) }}
                      </h2>
                      <h2>Via: {{ medication?.access }}</h2>
                      <h2>
                        Qtd: {{ getQtd(medication) }} -
                        {{ getApresentacao(medication) }}
                      </h2>

                      <h2 *ngIf="period?.title != 'SOS'">
                        {{ medication?.prescribedTime | time }}
                      </h2>
                      <!-- <span class="text-muted"> Prescrição: {{ medication?.prescription }} </span>
                    <br />
                    <br /> -->
                      <span class="text-muted instruction">
                        {{ getInstructions(medication) }}
                      </span>

                      <h5 *ngIf="wasChecked(medication)" class="text-success">
                        {{
                          wasTaken(medication) ? 'Tomou em:' : 'Rejeitou em:'
                        }}
                        {{ medication?.medicationDate | date: 'short' }}
                      </h5>

                      <h6 *ngIf="wasChecked(medication)" class="text-success">
                        {{ getCheckinObservation(medication) }}
                      </h6>
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <ng-template #loading>
          <ui-loading [isLoading]="true"> </ui-loading>
          <span>Carregando...</span>
        </ng-template>

        <!-- Fim Medicamentos -->
      </div>
    </div>
  </ng-container>
</div>

<!-- <app-medication-dialog #modalDialog [patient]="patient" [msgs]="msgs" [medication]="medication" (cancelMedication)="cancelMedication($event)"
  (confirmMedication)="confirmMedication($event)"></app-medication-dialog> -->
