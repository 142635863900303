import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IActivityDetail } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { withLatestFrom, mergeMap, map, catchError, of } from 'rxjs';
import { loadActivityDetails, loadActivityDetailsSuccess, loadActivityDetailSuccess, activityDetailFail, upsertActivityDetail } from './activityDetail.actions';
import { FhirActivityDetailService } from '../../service/fhir-activity-detail.service';


@Injectable()
export class ActivityDetailEffects {

  constructor(
    private actions$: Actions, private store: Store<IAppMedlogicState>,
    private activityDetailSrv: FhirActivityDetailService,
  ) { }

  loadActivityDetails$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadActivityDetails),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => this.activityDetailSrv.getAll<IActivityDetail[]>()),
      map((activityDetails: IActivityDetail[] | null) =>
        activityDetails ? loadActivityDetailsSuccess({ activityDetails }) : activityDetailFail({ error: '' })
        // setIsLoading({ isLoading: false })
      ),
      catchError((e: any) => {
        console.log(e);
        return of(activityDetailFail({ error: '' }));
      })
    )
  );

  upsertActivityDetail$ = createEffect(() => this.actions$
    .pipe(
      ofType(upsertActivityDetail),
      withLatestFrom(this.store),
      mergeMap(([action, state]) => this.activityDetailSrv.create<IActivityDetail>(action.activityDetail)),
      map((activityDetail: IActivityDetail | null) => {
        console.warn(activityDetail);
        return activityDetail ? loadActivityDetailSuccess({ activityDetail }) : activityDetailFail({ error: '' })// chamei o método do loadActivity aqui, talvez precisará do setIsLoading como a função acima?
      }

      ),
      catchError((e: any) => {
        console.log(e);
        return of(activityDetailFail({error: ''}));
      })
    ),
  )


}
