import { IScheduledPeriod } from '@medlogic/shared/shared-interfaces';
//import { IScheduledPeriodState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as ScheduledPeriodActions from './scheduledPeriod.actions';
import { IScheduledPeriodState } from './scheduledPeriod-state';

export const scheduledPeriodsFeatureKey = 'scheduledPeriod';


export const adapterScheduledPeriod: EntityAdapter<IScheduledPeriod> = createEntityAdapter<IScheduledPeriod>({
  selectId: instance => instance.scheduledPeriodIdentifier,
  sortComparer: sortOnOcurrencyAndNames
});

export const initialStateScheduledPeriod: IScheduledPeriodState = adapterScheduledPeriod.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
});

// FIXME: Falta incluir regras de negócio de ordenação para os alertas
function usersSortFn(a: IScheduledPeriod, b: IScheduledPeriod) {
  return a?.scheduledPeriodIdentifier.localeCompare(b?.scheduledPeriodIdentifier);
}

function sortOnOcurrencyAndNames(a: IScheduledPeriod, b: IScheduledPeriod) {
  if (a.scheduledPeriodIdentifier === b.scheduledPeriodIdentifier) {
    return a.scheduledPeriodIdentifier < b.scheduledPeriodIdentifier ? -1 : 1;
  } else {
    return a.scheduledPeriodIdentifier ? -1 : 1;
  }
}

export const reducer = createReducer(
  initialStateScheduledPeriod,
  on(ScheduledPeriodActions.addScheduledPeriod,
    (state, action) => adapterScheduledPeriod.addOne(action.scheduledPeriod, state)
  ),
  on(ScheduledPeriodActions.setScheduledPeriod,
    (state, action) => ({ ...state, selectedIdentifier: action.selectedId })
  ),
  on(ScheduledPeriodActions.upsertScheduledPeriod,
    (state, action) => adapterScheduledPeriod.upsertOne(action.scheduledPeriod, state)
  ),
  on(ScheduledPeriodActions.addScheduledPeriods,
    (state, action) => adapterScheduledPeriod.addMany(action.scheduledPeriods, state)
  ),
  on(ScheduledPeriodActions.upsertScheduledPeriods,
    (state, action) => adapterScheduledPeriod.upsertMany(action.scheduledPeriods, state)
  ),
  on(ScheduledPeriodActions.updateScheduledPeriod,
    (state, action) => adapterScheduledPeriod.updateOne(action.scheduledPeriod, state)
  ),
  on(ScheduledPeriodActions.updateScheduledPeriods,
    (state, action) => adapterScheduledPeriod.updateMany(action.scheduledPeriods, state)
  ),
  on(ScheduledPeriodActions.deleteScheduledPeriod,
    (state, action) => adapterScheduledPeriod.removeOne(action.id, state)
  ),
  on(ScheduledPeriodActions.deleteScheduledPeriods,
    (state, action) => adapterScheduledPeriod.removeMany(action.ids, state)
  ),
  on(ScheduledPeriodActions.loadScheduledPeriodsSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterScheduledPeriod.upsertMany(action.scheduledPeriods, state)
    (state, action) => adapterScheduledPeriod.setAll(action.scheduledPeriods, state)
  ),
  on(ScheduledPeriodActions.scheduledPeriodFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(ScheduledPeriodActions.clearScheduledPeriods,
    state => initialStateScheduledPeriod
  ),
  on(ScheduledPeriodActions.clearSelectedScheduledPeriod,
    state => ({ ...state, selectedIdentifier: null })
  ),
  on(ScheduledPeriodActions.loadScheduledPeriods,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterScheduledPeriod.getSelectors();


export const getSelectedId = (state: IScheduledPeriod) => state.scheduledPeriodIdentifier;