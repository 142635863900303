<mat-toolbar color="basic">
  <span class="example-spacer"></span>
  <mat-icon class="example-icon" (click)="onNoClick($event)">close</mat-icon>
</mat-toolbar>

<div>
  <h1>{{ data.title }}</h1>
  <mat-toolbar>
    <button mat-raised-button (click)="onYesClick($event)">{{ data.btnYes }}</button>
    <button mat-raised-button (click)="onNoClick($event)">{{ data.btnNo }}</button>
  </mat-toolbar>
</div>
