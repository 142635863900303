import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PractitionerEffects } from './+state/practitioner.effects';
import * as fromPractitioner from './+state/practitioner.reducer';

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromPractitioner.practitionersFeatureKey, fromPractitioner.reducer),
  EffectsModule.forFeature([PractitionerEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
})
export class StatePractitionerModule { }
