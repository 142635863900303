import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LogService, IListItem, EnTheme } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';

@Component({
  selector: 'ui-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss']
})
export class GenericListComponent {

  @Input() items$: Observable<IListItem<any>[]>;
  @Input() enTheme: EnTheme;
  @Input() search: string;
  @Input() showOnlyFirst = false;
  @Input() isSingleRegister: boolean = false

  @Output() itemClick = new EventEmitter<any>();
  @Output() showAllClick = new EventEmitter<any>();

  ENTHEME = EnTheme;

  constructor(
    private log: LogService
  ) { }


  onItemClick(item: IListItem<any>): void {
    try {
      this.itemClick.emit(item);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onItemClick', error.message);
    }
  }

  showAll($event: any): void {
    try {
      this.showAllClick.emit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showAll', error.message);
    }
  }

  getShowOnlyFirst(items: IListItem<any>[], showOnlyFirst: boolean): boolean {
    try {
      return items?.length > 1 && showOnlyFirst;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getShowOnlyFirst', error.message);
    }
    return false;
  }


}
