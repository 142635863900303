import { ICampoAdicional } from '../interface/icampo-adicional';
import { Directive, Input, ElementRef, Renderer2 } from '@angular/core'

@Directive({
  selector: '[libCtrlGridColumn]'
})
export class CtrlGridColumnDirective {

  private _col: ICampoAdicional;
  @Input('libCtrlGridColumn') public get col(): ICampoAdicional {
    return this._col;
  }

  public set col(v: ICampoAdicional) {
    this._col = v;
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  // Para resolver o problema do - na atualização, procurar um evento que não seja o Init
  ngOnInit(): void {
    this.applyStyles(this.col);
  }

  applyStyles(col: ICampoAdicional): void {
    if (col) {
      this.renderer.setStyle(this.el.nativeElement, 'width', col.Largura + 'px');
      this.renderer.setStyle(this.el.nativeElement, 'max-width', col.Largura + 'px');
      this.renderer.setStyle(this.el.nativeElement, 'padding-right', '0');
    }
  }
}
