import { Injectable } from '@angular/core';
import { IDataBase, Connection } from 'jsstore';

import * as JsStore from 'jsstore';
import { LogService } from '@medlogic/shared/shared-interfaces';
// import * as workerPath from 'file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js';
declare var require: any;

const getWorkerPath = () => {
  // if (environment.production) {
  return require('file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.min.js');
  // } else {
  //   return require('file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.js');
  // }
};
/** Utiliza o indexedDB para persistir dados em forma de tabelas num bd.
 * Chamar o createDB na inicialização do projeto.
 * Herdar as tabelas de tb-store.service.
 */
@Injectable()
export class JsStoreService {

  // tslint:disable: variable-name
  _connection: Connection;
  _dbName = '';

  constructor(
    private log: LogService,
  ) {
    // this._connection = new JsStore.Instance();
    // This will ensure that we are using only one instance.
    // Otherwise due to multiple instance multiple worker will be created.
    // this._connection = new JsStore.Instance(new Worker(getWorkerPath().default));
    this._connection = new JsStore.Connection(new Worker(getWorkerPath().default));
  }

  /** Cria nova instância do banco de dados. */
  createDb(dbSchema: IDataBase): void {
    try {
      this._dbName = dbSchema.name;
      this._connection.initDb(dbSchema);

      // JsStore.isDbExist(this._dbName,
      //     (isExist) => {
      //         try {
      //             if (isExist) {
      //                 this._connection.openDb(this._dbName);
      //             } else {
      //                 const DataBase = dbSchema;
      //                 this._connection.initDb(DataBase);
      //             }
      //         } catch (error) {
      //             this.log.Registrar(this.constructor.name, 'createDb.isDbExist', error.message);
      //         }
      //     }).catch(err => {
      //         // this will be fired when indexedDB is not supported.
      //         console.error(err);
      //         this.matDialog.openDialogAlert(err._message);
      //     });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'createDb', error.message);
    }
  }


}
