import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LogEffects } from './+state/log.effects';
import * as fromLog from './+state/log.reducer';

const SERVICES = [
];

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromLog.logsFeatureKey, fromLog.reducer),
  EffectsModule.forFeature([LogEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})
export class StateLogModule { }
