import { ConfigStateService } from '@medlogic/shared/state-config';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { error } from './error';
import { IEmail } from '@medlogic/shared/shared-interfaces';
import { GlobalService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  URL_SEND_EMAIL = 'HttpSendEmail?code=';


  constructor(
    private http: HttpClient,
    private log: LogService,
    private glb: GlobalService,
    private cnf: ConfigStateService,
    private cnfJson: ConfigJsonService
  ) { }


  /* Sends a email message.
  * It uses the python API. */
  send(email: IEmail, token: string = null): Observable<boolean> {
    try {
      token = token || this.cnf.baseUsuarioToken;
      const urlSendEmail = this.glb.urlJoin([this.cnfJson.baseUrlDocGen, `${this.URL_SEND_EMAIL}${this.cnfJson.apiDocGenCode}`]);
      const formData = new FormData();
      formData.append('from_email', email.fromEmail);
      formData.append('to_emails', email.toEmails);
      formData.append('subject', email.subject);
      formData.append('html_content', email.htmlContent);
      formData.append('token', token);
      return this.http
        .post(urlSendEmail, formData)
        .pipe(
          map(response => {
            return response === 202;
          }),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'send', error.message);
    }
  }


}
