import { Injectable } from '@angular/core';
import { routeScheduledPeriod } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirScheduledPeriodService extends FhirGenericService {

  protected url = routeScheduledPeriod;

}
