import { IStockState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IEstoqueMateriais } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as StockActions from './stock.actions';

export const stocksFeatureKey = 'stock';

export const adapterStock: EntityAdapter<IEstoqueMateriais> = createEntityAdapter<IEstoqueMateriais>({
  selectId: instance => instance.codigo,
});

export const initialStateStock: IStockState = adapterStock.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
  changeItem: null,
  changeItems: null
});

export const reducer = createReducer(
  initialStateStock,
  on(StockActions.addStock,
    (state, action) => adapterStock.addOne(action.stock, state)
  ),
  on(StockActions.loadStocks,
    (state, action) => ({ ...state, numOfDaysToCheckOcurrency: action.numOfDaysToCheckOcurrency })
  ),
  on(StockActions.upsertStock,
    (state, action) => adapterStock.upsertOne(action.stock, state)
  ),
  on(StockActions.addStocks,
    (state, action) => adapterStock.addMany(action.stocks, state)
  ),
  on(StockActions.upsertStocks,
    (state, action) => adapterStock.upsertMany(action.stocks, state)
  ),
  on(StockActions.updateStock,
    (state, action) => adapterStock.updateOne(action.stock, state)
  ),
  on(StockActions.updateStocks,
    (state, action) => adapterStock.updateMany(action.stocks, state)
  ),
  on(StockActions.deleteStock,
    (state, action) => adapterStock.removeOne(action.id, state)
  ),
  on(StockActions.deleteStocks,
    (state, action) => adapterStock.removeMany(action.ids, state)
  ),
  on(StockActions.loadStocksSuccess,
    (state, action) => adapterStock.setAll(action.stocks, { ...state })
  ),

  on(StockActions.stockChange,
    (state, action) => ({
      ...state,
      changeItem: action,
      error: null
    })
  ),
  on(StockActions.stockChanges,
    (state, action) => ({
      ...state,
      changeItems: action.stock,
      error: null
    })
  ),
  on(StockActions.stockChangeSuccess,
    (state, action) => ({
      ...state,
      changeItem: null,
      error: null
    })
  ),
  on(StockActions.stockFail,
    (state, action) => ({
      ...state,
      changeItem: null,
      error: action?.error
    })
  ),
  on(StockActions.clearStocks,
    state => adapterStock.removeAll(state)
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterStock.getSelectors();
