import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { IDocumento } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'lib-cmp-img-preview',
  templateUrl: './cmp-img-preview.component.html',
  styleUrls: ['./cmp-img-preview.component.css']
})
export class CmpImgPreviewComponent implements OnInit {

  @Input() doc: IDocumento;
  @Input() showBack = true;

  @Output() back = new EventEmitter();
  @Output() download = new EventEmitter<IDocumento>();

  get title(): string {
    return this.doc ? this.doc.DocumentoNomeExibicao : '';
  }

  get imgUrl(): string {
    return this.doc ? this.doc.CaminhoFisico : '';
  }

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
  }

  onBack($event: any): void {
    try {
      this.back.emit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  onDownload(doc: IDocumento): void {
    try {
      this.download.emit(doc);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDownload', error.message);
    }
  }


}
