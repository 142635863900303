import { IScheduledTiming } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const setScheduledTiming = createAction(
  '[ScheduledTiming/API] Set ScheduledTiming',
  props<{ selectedId: number }>()
);
export const loadScheduledTimings = createAction(
  '[ScheduledTiming/API] Load ScheduledTimings'
);

export const loadScheduledTimingsSuccess = createAction(
  '[ScheduledTiming/API] Load ScheduledTimings Success',
  props<{ scheduledTimings: IScheduledTiming[] }>()
);

export const scheduledTimingFail = createAction(
  '[ScheduledTiming/API] ScheduledTiming Fail',
  props<{ error: string }>()
);

export const addScheduledTiming = createAction(
  '[ScheduledTiming/API] Add ScheduledTiming',
  props<{ scheduledTiming: IScheduledTiming }>()
);

export const addScheduledTimings = createAction(
  '[ScheduledTiming/API] Add ScheduledTimings',
  props<{ scheduledTimings: IScheduledTiming[] }>()
);

export const upsertScheduledTiming = createAction(
  '[ScheduledTiming/API] Upsert ScheduledTiming',
  props<{ scheduledTiming: IScheduledTiming }>()
);

export const upsertScheduledTimings = createAction(
  '[ScheduledTiming/API] Upsert ScheduledTimings',
  props<{ scheduledTimings: IScheduledTiming[] }>()
);

export const updateScheduledTiming = createAction(
  '[ScheduledTiming/API] Update ScheduledTiming',
  props<{ scheduledTiming: Update<IScheduledTiming> }>()
);

export const updateScheduledTimings = createAction(
  '[ScheduledTiming/API] Update ScheduledTimings',
  props<{ scheduledTimings: Update<IScheduledTiming>[] }>()
);

export const deleteScheduledTiming = createAction(
  '[ScheduledTiming/API] Delete ScheduledTiming',
  props<{ id: string }>()
);

export const deleteScheduledTimings = createAction(
  '[ScheduledTiming/API] Delete ScheduledTimings',
  props<{ ids: string[] }>()
);

export const clearScheduledTimings = createAction(
  '[ScheduledTiming/API] Clear ScheduledTimings'
);
export const clearSelectedScheduledTiming = createAction(
  '[Card ScheduledTiming] Clear Selected ScheduledTimings'
);
