<div class="activities-container">

    <span>{{ activityModeTitle }}</span>

    <div class="shift">

        <span class="subtitle">Turno Manhã</span>

        <ml-ui-care-plan-activity-extra [item]="{ 'status': 'done' }"></ml-ui-care-plan-activity-extra>
        <ml-ui-care-plan-activity-extra [item]="{ 'status': 'late' }"></ml-ui-care-plan-activity-extra>
        <ml-ui-care-plan-activity-extra [item]="{ 'status': 'todo' }"></ml-ui-care-plan-activity-extra>
        <ml-ui-care-plan-activity-extra [item]="{ 'status': 'pending' }"></ml-ui-care-plan-activity-extra>

    </div>

</div>