
import { NgModule } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from './service/unsubscribe-ondestroy.adapter';
import { MsgPtBR } from './service/msg-ptBR.service';
import { LocalLibService } from './service/lib.service';
import { LogService } from './service/log.service';
import { GlobalService } from './service/global.service';
import { AppLogService } from './service/app-log.service';
import { ApiLogService } from './service/api-log.service';

const SERVICES = [
  ApiLogService,
  AppLogService,
  LogService,
  LocalLibService,
  GlobalService,
  MsgPtBR,
  UnsubscribeOnDestroyAdapter,
  // *** ATENÇÃO: *** ConfigJsonService deve ser provisionado apenas no app-addhere.module e NÃO no SharedInterfacesNgModule,
  // pois, dele dependem vários módulos e o arquivo de json precisa ser lido uma única vez,
  // antes da inicialização do app (bootstrap).
  // ConfigJsonService,
];

@NgModule({
  imports: [],
  exports: [
  ],
  providers: [
    SERVICES,
  ],
})
export class SharedInterfacesNgModule { }
