import { IMedlogicEvolution } from '@medlogic/shared/shared-interfaces';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface IMedlogicEvolutionState extends EntityState<IMedlogicEvolution> {
    error: any;
    selectedId: number | null;
    dtStart: Date;
    dtEnd: Date;
}




export const adapterMedlogicEvolution: EntityAdapter<IMedlogicEvolution> = createEntityAdapter<IMedlogicEvolution>();

export const initialStateEvolution = adapterMedlogicEvolution.getInitialState({
    error: undefined
});



