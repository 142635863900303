import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ml-ui-care-plan-row',
  templateUrl: './ui-care-plan-row.component.html',
  styleUrls: ['./ui-care-plan-row.component.css']
})
export class UiCarePlanRowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
