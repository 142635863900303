import { createReducer, on, Action } from '@ngrx/store';
import { IGenericState } from '@medlogic/shared/shared-interfaces';
import {
  loadGeneric,
  setAllGenerics,
  setGeneric,
  fetchSuccessGeneric,
  updateGeneric,
  fetchSuccessGenericById,
  setGenericById,
  cleanGeneric,
  loadGenericByPerson,
  cleanGenericId,
} from './generic.actions';

// TODO: Remember to import the Effects and Reducer at app.module
// EffectsModule.forRoot([ContaEffects]),
// StoreModule.forFeature({  app: appReducer, conta: contaReducer, conta: contaReducer }),
export const initialState: IGenericState = {
  selectedGeneric: null,
  allGenerics: undefined,
  Id: null,
  genericAno: null,
  identification: null,
  tno: null,
  genericLabel: null
};

const genericReducerObj = createReducer(initialState,
  on(loadGeneric, (state, payload) => {
    return ({
      ...state,
      bannerTitle: '',
      selectedGeneric: null,
      genericAno: payload?.genericAno,
      identification: payload?.identification
    });
  }),
  on(loadGenericByPerson, (state, payload) => {
    return ({
      ...state,
      bannerTitle: '',
      selectedGeneric: null,
      genericAno: payload?.genericAno,
      genericLabel: payload?.genericLabel
    });
  }),
  on(fetchSuccessGeneric, (state, payload) => ({
    ...state, // FIXME: necessário, pois o retorno vazio está trazendo um array com um elemento null
    allGenerics: payload.allGenerics[0] ? payload.allGenerics : []
  })),
  on(setGeneric, (state, payload) => ({
    ...state,
    selectedGeneric: payload.selectedGeneric
  })),
  on(cleanGeneric, (state, payload) => ({
    ...initialState
  })),
  on(cleanGenericId, (state, payload) => ({
    ...state,
    Id: null
  })),
  on(setGenericById, (state, payload) => ({
    ...state,
    tno: +payload.tno,
    Id: +payload.id,
    selectedGeneric: state?.allGenerics?.find(f => +f.id === +payload.id)
  })),
  on(setAllGenerics, (state, payload) => ({
    ...state,
    allGenerics: payload.allGenerics
  })),
  on(updateGeneric, (state, payload) => {  // Note: the updateGeneric will not update the service, but just the state
    let items = [...state.allGenerics];
    const find = payload?.generic ? items.find(f => +f.ono === +payload.generic.ono) : null;
    if (find) {
      items = items.map(m => {
        if (+m.ono === +payload.generic.ono) {
          return payload.generic;
        }
        return m;
      });
    } else {
      items = state.allGenerics.concat(payload.generic);
    }
    return ({
      ...state,
      allGenerics: items
    });
  }),
  // on(getGenericById, (state: IGenericState, payload) => ({
  //   ...state,
  //   Id: payload.id
  // })),
  on(fetchSuccessGenericById, (state: IGenericState, payload) => ({
    ...state,
    allGenerics: payload.allGenerics
  }))
);

export function genericReducer(state: IGenericState | undefined, action: Action) {
  return genericReducerObj(state, action);
}
