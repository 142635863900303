import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { PrescriptionActionTypes } from './prescription.actions';
import { initialState } from './prescription.reducer';

const KEY = 'MEDLOGIC_PRESCRIPTION_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  PrescriptionActionTypes.LoadPrescription,
  PrescriptionActionTypes.SavePrescription,
  PrescriptionActionTypes.UpdatePrescription,
  PrescriptionActionTypes.UpdatePrescriptionMedications,
  PrescriptionActionTypes.SetPrescription,
];

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialState);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
