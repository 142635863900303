export class Queue<T> {

    _store: T[] = [];

    public get length() : number {
        return this._store?.length || 0;
    }

    public push(val: T): Queue<T> {
        this._store.push(val);
        return this;
    }

    public pop(): { queue: Queue<T>, item: T | undefined } {
        return { queue: this, item: this._store.shift() };
    }

}