<div class="text-center header">

  <br />
  <img alt="image" class="img-circle" [class.miniImg]="showMiniImg" width="80" src="{{ patient.foto }}"
    (error)="swapImage($event.target, patient)">

  <h2 class="m-b-xs" [class.miniBox]="showMiniImg">
    <strong [class.miniFont]="showMiniImg">{{ patient.nomeHospede }}</strong>
  </h2>

  <div class="font-bold" *ngIf="!showMiniImg">{{ getAge(patient.nascimento) }} anos</div>

  <div class="inputs">

    <button mat-raised-button class="btnResidente" *ngIf="showChangePatient" type="button" (click)="selectPatient()">Residentes</button>
    <div *ngIf="showSelect">
      <mat-form-field class="selectMedicamento">
        <mat-select [(value)]="selectedValue" class="fontMedicamento"  (selectionChange)="functionSelect($event)">
          <mat-option value="Todos">Todos</mat-option>
          <mat-option value="Medicamentos">Medicamentos</mat-option>
          <mat-option value="Cuidados">Cuidados</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    
  </div>
  


</div>