import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PatientEffects } from './+state/patient.effects';
import * as fromPatient from './+state/patient.reducer';

const SERVICES = [
];

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromPatient.patientsFeatureKey, fromPatient.reducer),
  EffectsModule.forFeature([PatientEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})
export class StatePatientModule { }
