export interface IAddress {
    use: string;
    type: string;
    text: string;
    line: string;
    city: string;
    district: string;
    state: string;
    postalCode: string;
    country: string;
    period: string;
    tenantId: number;
    language: string;
}