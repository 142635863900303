export const fieldsMapTherapeuticSafe = {
    therapeuticSafeId: { name: 'therapeuticSafeId', type: 'number' },
    OcorrenciaNo: { name: 'therapeuticSafeId', type: 'number' },

    V_101827: { name: 'codigo', type: 'string' },
    // V_101828: { name: 'nomeFarmaco', type: 'string' },
    V_101830: { name: 'nomeFarmaco', type: 'string' },
    V_101829: { name: 'habilitado', type: 'boolean' },
    V_101833: { name: 'grupo', type: 'string' },
    V_101844: { name: 'disponibilidade', type: 'string' },
    V_101846: { name: 'descricao', type: 'string' },
    V_101859: { name: 'indicacao', type: 'string' },
    V_101860: { name: 'posologia', type: 'string' },
    V_101861: { name: 'reacoesAdversasContraindicacoes', type: 'string' },
    V_101862: { name: 'interacoesImportantes', type: 'string' },
    V_101863: { name: 'usodoencaRenal', type: 'string' },
    V_101865: { name: 'cuidadosComAdministracaoesquecimentodose', type: 'string' },
    V_101866: { name: 'seguranca', type: 'string' },
    V_101867: { name: 'adesaoEfetividade', type: 'string' },
    V_101869: { name: 'referenciasBibliograficas', type: 'string' },
    V_101894: { name: 'riscohipotensaoOrtostatica', type: 'string' },
    V_101895: { name: 'riscoaumentoQTc', type: 'string' },
    V_101896: { name: 'usodoencaHepatica', type: 'string' },
    V_101897: { name: 'cargaAnticolinergicaCAch', type: 'string' },
    V_101898: { name: 'cargaSedativa', type: 'string' },
    V_101899: { name: 'riscoconfusaoMental', type: 'string' },
    V_101900: { name: 'cargaAntidopaminergica', type: 'string' },
    V_101901: { name: 'riscohiponatremia', type: 'string' },
    V_101902: { name: 'riscoquedas', type: 'string' },
    V_101903: { name: 'estrategiaDesprescricao', type: 'string' },
    V_101904: { name: 'referenciasBibliograficas', type: 'string' },
    V_101905: { name: 'criteriosExplicitosMPIMAO', type: 'string' },
    V_101907: { name: 'precoMaximoreferenciaANVISA', type: 'string' },
    V_101922: { name: 'subGrupo', type: 'string' },
    V_101923: { name: 'classe', type: 'string' },
    V_101929: { name: 'gSGC', type: 'string' },
    V_101832: { name: 'cor', type: 'string' },
    V_101851: { name: 'marcas', type: 'string' }
}
