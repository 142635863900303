import { InputMaskType } from '../enum/input-mask-type';
import { IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { Directive, Input, ElementRef, Renderer2, RendererStyleFlags2, OnInit } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../service/lib.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[libCtrConfigUI]'
})
export class CtrConfigUIDirective implements OnInit {

  // tslint:disable-next-line: variable-name
  private _ctrl: IAtividadeComponenteDAL;

  @Input('libCtrConfigUI') public get ctrl(): IAtividadeComponenteDAL {
    return this._ctrl;
  }
  public set ctrl(v: IAtividadeComponenteDAL) {
    this._ctrl = v;
  }

  @Input() altura: number;
  @Input() minWidth = 0;
  @Input() isMobile: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private log: LogService,
    private global: GlobalService,
    private lib: LibService) {
  }

  // Para resolver o problema do - na atualização, procurar um evento que não seja o Init
  ngOnInit(): void {
    try {
      this.applyStyles(this.ctrl, this.altura);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  applyStyles(ctrl: IAtividadeComponenteDAL, altura: number): void {
    try {
      let width = Math.max(this.minWidth, ctrl?.Largura);
      // Restringir a largura ao tamanho da tela devido aos dispositivos móveis
      width = Math.min(window?.innerWidth, width);
      const isMobile = this.isMobile !== undefined ? this.isMobile : this.global.isMobile();
      if (isMobile) {
        this.renderer.setStyle(this.el?.nativeElement, 'width', '100%', RendererStyleFlags2.Important);
        this.renderer.setStyle(this.el?.nativeElement, 'margin-top', '0.2em', RendererStyleFlags2.Important);
        // this.renderer.setStyle(this.el?.nativeElement, 'margin', '0.2em', RendererStyleFlags2.Important);
        // this.renderer.setStyle(this.el?.nativeElement, 'padding-bottom', '1em', RendererStyleFlags2.Important);
        // this.renderer.setStyle(this.el?.nativeElement, 'border-style', 'solid', RendererStyleFlags2.Important);
        // this.renderer.setStyle(this.el?.nativeElement, 'border-width', 'thin', RendererStyleFlags2.Important);
        // this.renderer.setStyle(this.el?.nativeElement, 'border-color', '#eee', RendererStyleFlags2.Important);
        if (altura && (ctrl?.Type.toUpperCase() === this.lib.CTRMULTILINETEXTBOXLABELED)) {
          this.renderer.setStyle(this.el?.nativeElement, 'min-height', altura + 'px');
        }
      } else {
        this.renderer.setStyle(this.el?.nativeElement, 'width', width + 'px');
        this.renderer.setStyle(this.el?.nativeElement, 'max-width', width + 'px');
        if (altura) {
          this.renderer.setStyle(this.el?.nativeElement, 'min-height', altura + 'px');
        }
      }
      const type = this.getType(ctrl);
      this.renderer.setAttribute(this.el?.nativeElement, 'type', type);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyStyles', error.message);
    }
  }

  protected getType(ctrl: IAtividadeComponenteDAL): string {
    try {
      switch (ctrl?.Type?.toUpperCase()) {
        case this.lib.CTRDATE: // EnumCtrType.CtrDate:
          return 'date';
        case this.lib.CTRBUTTON: // EnumCtrType.CtrButton:
          return 'button';
        case this.lib.CTRCHECKBOXLIST: // EnumCtrType.CtrCheckBoxList:
          return 'checkbox';
        case this.lib.CTRTEXTBOXLABELED: // EnumCtrType.CtrTextBoxLabeled:
          switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
            case InputMaskType.Numeric:
            case InputMaskType.Numeric2:
            case InputMaskType.Numeric3:
            case InputMaskType.Numeric4:
            case InputMaskType.Integer:
            case InputMaskType.Dolar:
            case InputMaskType.Real:
            case InputMaskType.Percent:
            case InputMaskType.Percent2:
              return 'number';
            case InputMaskType.Password:
              return 'password';
            case InputMaskType.PhoneWithDDDInternational:
            case InputMaskType.Phone:
            case InputMaskType.PhoneWithDDD:
            case InputMaskType.PhoneWithDDDNoPrefix:
            case InputMaskType.PhoneWithDDDNoPrefixInternational:
              return 'tel';
            case InputMaskType.TimeHHMMSS:
            case InputMaskType.TimeHHHMM:
            case InputMaskType.TimeHHMM:
            case InputMaskType.TimeHHMMSS:
              return 'time';
            // case InputMaskType.Url:
            // return "url";
            // return "week";
            case InputMaskType.Date:
              return 'date';
            case InputMaskType.DateTime:
              return 'datetime';
            // return "datetime-local";
            // return "email";
            // return "month";
            // return "color"
          }
          return 'text';
        case this.lib.CTRIMAGELIST: // EnumCtrType.CtrImageList:
          return 'image';
        case this.lib.CTRRADIOBUTTON: // EnumCtrType.CtrRadioButton:
          return 'radio';
      }
      return 'text';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getType', error.message);
    }
  }


}
