import { IPrescriptionMedication } from './../medlogic/iprescription-medication';
import { IInterventionMedication } from '../medlogic/iintervention-medication';
import { IPerson } from '../iperson';

export interface IPostCheckMedicationStockRequest {
    medications: IInterventionMedication[];
    cadEstoqueMateriaisNo: number;
    cadIntervencoesMedicamentosNo: number;
    cadItensEstoqueNo: number;
    cadPrescriptionNo: number;
    selectedPrescription: IPrescriptionMedication;
    person: IPerson;
    wasChanged: boolean;
    message: string;
}
