import { createReducer, on, Action } from '@ngrx/store';
import {
  loadFrailtys, setFrailty, fetchSuccessFrailty, fetchErrorFrailty,
  saveSuccessFrailty, saveFailFrailty, saveAllFrailty, saveFrailty,
  resetFrailty, loadFrailtysAndResultados, pushAudio, popAudio, playAudio, stopAudio, pauseAudio, cleanFrailty
} from './frailty.actions';
import { IFrailty, IFrailtyState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { update } from '@medlogic/shared/utils';

export const initialState: IFrailtyState = {
  frailty: null,
  frailtys: [],
  error: null,
  categoria: null,
  audios: [],
  audio: null,
  isPlaying: false
};

const frailtyReducerObj = createReducer(initialState,
  on(loadFrailtys, (state, payload) => ({
    ...state,
    bannerTitle: 'Frailtys',
    frailty: null,
    frailtys: []
  })),
  on(loadFrailtysAndResultados, (state, payload) => ({
    ...state,
    bannerTitle: 'Frailtys',
    frailty: null,
    frailtys: []
  })),
  on(resetFrailty, (state, payload) => initialState),
  on(fetchSuccessFrailty, (state, payload) => ({
    ...state, // FIXME: necessário, pois o retorno vazio está trazendo um array com um elemento null
    frailtys: payload?.frailtys[0] ? payload?.frailtys : [],
    error: null
  })),
  on(setFrailty, (state, payload) => ({
    ...state,
    frailty: payload?.frailty,
    error: null
  })),
  on(saveFrailty, (state, payload) => ({
    ...state,
    frailty: payload?.frailty,
    error: null
  })),
  on(fetchErrorFrailty, (state, payload) => ({
    ...state,
    frailtys: null,
    error: 'invalid-token'
  })),
  on(saveAllFrailty, (state, payload) => ({
    ...state, // Aproximação otimista, ou seja, altera o estado mesmo antes do retorno do serviço.
    frailtys: update<IFrailty>(state?.frailtys, payload?.frailty),
    frailty: payload?.frailty,
    error: null
  })),
  on(saveSuccessFrailty, (state, payload) => {
    return ({
      ...state,
      frailtys: update<IFrailty>(state?.frailtys, payload?.frailty),
      frailty: payload?.frailty,
      error: null
    });
  }),
  on(saveFailFrailty, (state, payload) => ({
    ...state,
    frailtys: update<IFrailty>(state?.frailtys, payload?.frailty),
    frailty: payload?.frailty,
    error: 'fail-on-save--frailty'
  })),
  on(pushAudio, (state, payload) => {
    const audios = [...state?.audios, payload?.audio];
    return ({
      ...state,
      audios,
      audio: payload?.audio
    });
  }),
  // on(popAudio, (state, payload) => {
  //   const audios = [...state?.audios];
  //   audios?.pop();
  //   const audio = audios ? audios[audios.length - 1] : null;
  //   return ({
  //     ...state,
  //     audios,
  //     audio
  //   });
  // }),
  on(popAudio, (state, payload) => {
    let audios = [...state?.audios];
    const fromColor = audios?.filter(f => f.enColor === payload?.enColor);
    const lastFromColor = fromColor?.length > 0 ? fromColor[fromColor.length - 1] : null;
    audios = audios?.filter(f => f.url !== lastFromColor?.url);
    const audio = audios ? audios[audios.length - 1] : null;
    return ({
      ...state,
      audios,
      audio
    });
  }),
  on(playAudio, (state, payload) => ({
    ...state,
    isPlaying: true
  })),
  on(stopAudio, (state, payload) => ({
    ...state,
    audios: null,
    audio: null,
    isPlaying: false
  })),
  on(pauseAudio, (state, payload) => ({
    ...state,
    isPlaying: false
  })),
  on(cleanFrailty, () => ({ ...initialState }))
);

export function frailtyReducer(state: IFrailtyState | undefined, action: Action) {
  return frailtyReducerObj(state, action);
}
