import { IAccountState } from './iaccount-state';
import { IProcessState } from './iprocess-state';
import { ICustomerState } from './icustomer-state';
import { IGenericState } from './igeneric-state';
import { IState } from '../../interface/istate';
import { ILogin, ITenantState } from '@medlogic/shared/shared-interfaces';

export interface AppMedlogicPwaCuidadoState {
  loginPWA: ILogin;
  tenant: ITenantState;
  //
  account: IAccountState;
  customer: ICustomerState;
  // TODO: Module will be renamed to Process? But there are impacts at Search and others already existing components.
  process: IProcessState;
  generic: IGenericState;
  app: IState;
}
