import { ConfigStateService } from '@medlogic/shared/state-config';
import { ActionBarComponent } from './../action-bar/action-bar.component';
import { Component, OnInit, Input } from '@angular/core';
import { IBubble } from '../../../shared/interface/ibubble';
import { EnBubbleEvent } from '../../../shared/enum/en-bubble-event.enum';
import { DadoDAL } from '../../../shared/model/dal/dado-dal';
import { CalculatorService } from '../../../shared/service/calculator.service';
import { LibService } from '../../../shared/service/lib.service';
import { NavigationService } from '../../../shared/service/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { EnActivityType } from '@medlogic/shared/gecore';
import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { IBasic } from '@medlogic/shared/shared-interfaces';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'lib-next-action-bar',
  templateUrl: './next-action-bar.component.html',
  styleUrls: ['./next-action-bar.component.css']
})
export class NextActionBarComponent extends ActionBarComponent implements OnInit {

  @Input() tabs: Array<IBasic>;
  @Input() enTheme = EnTheme.default;

  // tslint:disable-next-line: variable-name
  private _tabActivedId: number;
  @Input()
  public get tabActivedId(): number {
    return this._tabActivedId;
  }
  public set tabActivedId(v: number) {
    this._tabActivedId = v;
    this.configUI();
  }

  titlePrevious = 'Anterior';
  titleNext = 'Próximo';
  hasPrevious = true;
  hasNext = true;

  constructor(
    config: ConfigStateService,
    cnfJson: ConfigJsonService,
    log: LogService,
    dadoDAL: DadoDAL,
    global: GlobalService,
    calc: CalculatorService,
    lib: LibService,
    nav: NavigationService,
    matDialog: MatDialog,
    msg: MsgPtBR,
    snackBar: MatSnackBar,
  ) {
    super(config, cnfJson, log, dadoDAL, global, calc, lib, nav, matDialog, msg, snackBar);
  }

  ngOnInit() {
    try {
      this.configUI();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* override  */
  protected configUI(): void {
    try {
      if (this.tabs.length <= 1) {
        // Não há mais de uma aba
        this.hasPrevious = false;
        this.hasNext = false;
        this.hasConfirm = true;
      } else {
        const lastTabIndex = this.tabs.length - 1;
        this.hasPrevious = this.tabActivedId > 0;
        this.hasNext = this.tabActivedId < lastTabIndex;
        this.hasConfirm = this.tabActivedId === lastTabIndex;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'configUI', error.message);
    }
  }

  /* Navega para frento ou para trás.  */
  navigateNextBack($event: any, isNext: boolean): void {
    try {
      const step = isNext ? 1 : -1;
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.navigateNextBack,
        params: { step }
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'navigateNextFoward', error.message);
    }
  }

  /* override
   * A diferença é que não fará o history back após completar.
   * Botão de confirmação, que fará o historyBack, e salvará os dados apenas se o controle estiver configurado
   * para salvar os dados em Cadastro.
   * Na verdade, a AtividadeCadastro (list) está preparada para alterar o objeto gridItem, na memória,
   * que será utilizado para devolução dos dados à atividade principal, que chamou essa atividade.
   */
  onConfirm($event: any, saveInList: any): void {
    try {
      const backAfterComplete = this.activityType !== EnActivityType.ListDetail;
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.activityComplete,
        params: { backAfterComplete }
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onConfirm', error.message);
    }
  }
}
