import { SvgIconService } from '@medlogic/shared/material';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ml-ui-care-plan-activity-extra',
  templateUrl: './ui-care-plan-activity-extra.component.html',
  styleUrls: ['./ui-care-plan-activity-extra.component.css']
})
export class UiCarePlanActivityExtraComponent implements OnInit {

  @Input() item = {
    status: 'done'
  };

  icons = [
    { name: 'residente', label: 'residente', enabled: false },
    { name: 'isolamento-contato', label: 'contato', enabled: false },
    { name: 'cadeira-rodas', label: 'rodas', enabled: false },
  ];

  constructor(svgIcon: SvgIconService) { }

  ngOnInit() {
  }

}
