import { MedlogicUiMedlogicUiCarePlanModule } from '@medlogic/medlogic/ui/medlogic-ui-care-plan';
import { SharedUtilsModule } from '@medlogic/shared/utils';
import { NgLetModule } from '@ngrx-utils/store';
import { UiLoadingModule } from '@medlogic/shared/ui/ui-loading';
import { MaterialModule } from '@medlogic/shared/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppKitchenModule } from './app-kitchen.module';
import { GeCoreModule } from '@medlogic/shared/gecore';
import { LocalCoreModule } from './module/core/local-core.module';
// ROUTING
import { AppAssistRoutingModule } from './app-assist-routing.module';
// PWA
import { AppAssistModule } from './app-assist.module';
import { AppKitchenRoutingModule } from './app-kitchen-routing.module';
import { AppPwaModule } from './app-pwa.module';
import { AppPwaRoutingModule } from './app-pwa-routing.module';
import { GeFormModule } from '@medlogic/shared/geform';

@NgModule({
  declarations: [
  ],
  providers: [
  ],
  imports: [
    CommonModule,
    MaterialModule,
    // GE
    GeCoreModule,
    GeFormModule,
    // AppGeRoutingModule,
    // Assist
    AppAssistModule,
    AppAssistRoutingModule,
    AppKitchenModule,
    AppKitchenRoutingModule,
    LocalCoreModule,
    // PWA
    AppPwaModule,
    AppPwaRoutingModule,
    UiLoadingModule,
    NgLetModule,
    SharedUtilsModule,

  ]
})
export class MedlogicMedlogicPwaCuidadoFullModule { }
