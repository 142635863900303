import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'app-window-dialog',
  templateUrl: './window-dialog.component.html',
  styleUrls: ['./window-dialog.component.css']
})
export class WindowDialogComponent implements OnInit {
  /* Icon do Material: https://material.io/tools/icons/?search=check&style=outline */
  icon = 'error_outline';

  constructor(
    public dialogRef: MatDialogRef<WindowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {
    this.icon = data.icon || this.icon;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() { }
}
