import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ScheduledEffects } from './+state/scheduled.effects';

import * as fromScheduled from './+state/scheduled.reducer';

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromScheduled.scheduledsFeatureKey, fromScheduled.reducer),
  EffectsModule.forFeature([ScheduledEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
})
export class StateScheduledModule { }
