export interface IExecucaoTarefa {
    TarefaNo: number;
    OcorrenciaNo: number;
    TarefaNome: string;
    AtividadeNo: number;
    TarefaStatusNo: number;
    UsuarioExecutorNo: number;
    PapelExecutorNo: number;
    DtInicio: any;
    DtConclusao: any;
    IdContent: number;
    ValueContent: string;
    BlHabilitado: string;
    VariavelNo: number;
    TarefaClassificacaoNo: number;
    TarefaNoOld: number;
    ParalelismoId: number;
    IsUrgent: boolean;
    DtUrgent: any;
    TarefaNoGuid: string;
    calcDiaInicio: number;
    calcHoraInicio: string;
    CalcUsuarioNome: string;
    CalcVariavelIdentificacao1Valor: string;
    CalcVariavelIdentificacao2Valor: string;
    CalcVariavelIdentificacao3Valor: string;
    CalcVariavelIdentificacao1No: number;
    CalcVariavelIdentificacao2No: number;
    CalcVariavelIdentificacao3No: number;
    CalcUsuarioSolicitante: string;
    CalcDataSolicitacao: any;
    CalcProcessoNome: string;
    ProcessoNo: number;
    UrgenciaStatus: string;
    UrgenciaColor: string;
    CalcAtividadeType: string;
    PodeIniciar: boolean;
    UsuarioSolicitanteNo: number;
    TarefaAnteriorNo: number;
    DtEnvioTarefaAnteriorNo: any;
    UsuarioNomeTarefaAnteriorNo: string;
    UsuarioFotoAnterior: string;
    TarefaStatusNomeAnterior: string;
    TarefaStatusNome: string;
    UsuarioFotoOcorrencia: string;
    LeituraTarefa: string;
    IsUrgentOcorrencia: boolean;
    DtUrgentOcorrencia: any;
    DtLimiteAlerta: any;
    DtValidacao: any;
}
