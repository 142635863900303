import { IInterventionMedication, IMedication, IPatient } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const loadMedications = createAction(
  '[card Patient] Load Medications',
  props<{ numOfDaysToCheckOcurrency: number }>()
);

export const loadRawMedications = createAction(
  '[SISTEMA Prescription Component] Load a raw list of medications'
);

export const loadHistoryMedications = createAction(
  '[SISTEMA Prescription Component] Load the history of medications'
);

export const loadMedicationsBySelectedPatient = createAction(
  '[PWA Card Medication] Load Medications by Selected Patient',
  props<{ numOfDaysToCheckOcurrency: number }>()
);

export const loadMedicationsByPrescription = createAction(
  '[SISTEMA Page Prescription] Load Medications by Prescription Id',
  props<{ prescriptionId: number }>()
);

export const loadMedicationsSuccess = createAction(
  '[Medication Effects] Load Medications Success',
  props<{ medications: IMedication[] }>()
);

export const loadRawMedicationsSuccess = createAction(
  '[SISTEMA Medication Effect] Load Raw Medications Success',
  props<{ rawMedications: IMedication[] }>()
);

export const loadHistoryMedicationsSuccess = createAction(
  '[SISTEMA Medication Effect] Load History of Medications Success',
  props<{ historyMedications: IMedication[] }>()
);

export const medicationFail = createAction(
  '[Medication Effects] Medication Fail',
  props<{ error: string | null }>()
);

export const rawMedicationFail = createAction(
  '[Medication Effects] Raw Medication Fail',
  props<{ error: string }>()
);

export const historyMedicationFail = createAction(
  '[Medication Effects] History of Medication Fail',
  props<{ error: string }>()
);

export const addMedication = createAction(
  '[Medication Effects] Add Medication',
  props<{ medication: IMedication }>()
);

export const upsertMedication = createAction(
  '[Medication Effects] Upsert Medication',
  props<{ medication: IMedication }>()
);

export const addMedications = createAction(
  '[Medication Effects] Add Medications',
  props<{ medications: IMedication[] }>()
);

export const addInterventionMedication = createAction(
  '[Medication Effects] Add Medications and Insert Stock',
  props<{ medication: IInterventionMedication }>()
);

export const upsertMedications = createAction(
  '[Medication Effects] Upsert Medications',
  props<{ medications: IMedication[] }>()
);

export const updateMedication = createAction(
  '[Medication Effects] Update Medication',
  props<{ medication: Update<IMedication>, saveToDb: boolean }>()
);

export const updateMedications = createAction(
  '[Medication Effects] Update Medications',
  props<{ medications: Update<IMedication>[] }>()
);

export const deleteMedication = createAction(
  '[Medication Effects] Delete Medication',
  props<{ medication: IMedication }>()
);

export const deleteMedications = createAction(
  '[SISTEMA Medication Effects] Delete Medications',
  props<{ medications: IMedication[] }>()
);

export const clearMedications = createAction(
  '[SISTEMA Medication Effects] Clear Medications'
);
export const cleanWasChangedMedications = createAction(
  '[SISTEMA Medication Effects] Turn was changed false to all Medications'
);

export const confirmMedication = createAction(
  '[Card Medication] Confirm Medication',
  props<{
    medication: IMedication, patient: IPatient, observation: string, updateStock: boolean
  }>()
);

export const confirmAllMedications = createAction(
  '[Card Medication] Confirm All Medications',
  props<{
    medications: IMedication[], patient: IPatient, observation: string, updateStock: boolean
  }>()
);

export const cancelMedication = createAction(
  '[Card Medication] Cancel Medication',
  props<{
    medication: IMedication
  }>()
);

export const saveMedicationSuccess = createAction(
  '[Stock/API] Save Medication Success',
  props<{ id: number, guid?: string, cor?: string }>() // guid deve ser usado no caso de novo e o id receber o novo id.
);

export const checkMedicationIntervention = createAction(
  '[Stock/API] Check Medication Intervetion',
  props<{ medication: IInterventionMedication }>()
);

export const setIsLoadingMedication = createAction(
  '[Patient Effects] Set IsLoading Medication',
  props<{ isLoading: boolean }>()
);

export const setMedicationsWasChanged = createAction(
  '[Prescription Effects] Set Medications Was Changed',
  props<{ wasChanged: boolean }>()
);

export const setMedicationsKeyword = createAction(
  '[Prescription Page] Set Medication Keyword',
  props<{ keyword: string }>()
);
