import { LibService } from './lib.service';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';


// Alguns metodos de Calculadora foram transferidos para cá, para que possam
// ser acessados tanto por calculadora, expressionfunction ou outro sem dar problema de DI.
@Injectable()
export class CalculatorService {

  constructor(private log: LogService, private global: GlobalService, private lib: LibService) { }

  evalFormula(formula: string): any {
    try {
      // Eval é perigoso, pois, permitiria a execução de qualquer código javascript
      // Uma alternativa seria usar o parse do angular (não consegui). Pesquisar se é mais seguro
      const resultado = eval(formula);
      return resultado;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'evalFormula', error.message);
      return formula;
    }
    return null;
  }

  evalCodition(formula: string): boolean {
    try {
      // Eval é perigoso, pois, permitiria a execução de qualquer código javascript
      // Uma alternativa seria usar o parse do angular (não consegui). Pesquisar se é mais seguro
      this.lib.arrayConditionSimbol.forEach(item => {
        formula = this.global.ReplaceAll(formula, item.search, item.replace);
      });
      const resultado = eval(formula);
      return resultado;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'evalFormula', error.message);
    }
    return null;
  }


}
