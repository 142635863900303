import { ConfigStateService } from '@medlogic/shared/state-config';
import { Component, OnInit, Input } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.css']
})
export class DebugComponent implements OnInit {

  @Input() msg: string;


  public get msgFormula(): string {
    return this.cnf.listFormulaRendering.join('<br /><br />');
  }

  constructor(
    protected log: LogService,
    protected cnf: ConfigStateService
  ) { }

  ngOnInit() {
  }
  /* Clique na lixeira, para limpar as mensagens.  */
  onTrashClick($event: any): void {
    try {
      this.msg = '';
      this.cnf.listFormulaRendering = [];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onTrashClick', error.message);
    }
  }
}
