import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { JsStoreService } from './js-store.service';
import { Injectable, Inject } from '@angular/core';
import { ITable, Connection } from 'jsstore';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';

@Injectable()
export abstract class TbStoreService extends UnsubscribeOnDestroyAdapter {
  // tslint:disable-next-line: variable-name
  _connection: Connection;
  tbName: string;

  constructor(
    jsStoreSrv: JsStoreService,
    protected log: LogService,
    @Inject('tbName') tbName: string,
    protected glb: GlobalService
  ) {
    super();
    this.tbName = tbName;
    this._connection = jsStoreSrv._connection;
  }

  get(): Promise<any> {
    try {
      // jsstore returns promise, when you dont specify OnSuccess
      return this._connection.select({
        from: this.tbName
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'get', error.message);
    }
  }

  add(item: any): Promise<any> {
    try {
      const values = (item instanceof Array) ? item : [item];
      return this._connection.insert({
        into: this.tbName,
        values
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'add', error.message);
    }
  }

  delete(id: number): Promise<any> {
    try {
      return this._connection.remove({
        from: this.tbName,
        where: {
          id
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'delete', error.message);
    }
  }

  deleteByKey(key: string): Promise<any> {
    try {
      return this._connection.remove({
        from: this.tbName,
        where: {
          key
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteByKey', error.message);
    }
  }

  update(id: any, updateValue: any): Promise<any> {
    try {
      return this._connection.update({
        in: this.tbName,
        where: {
          Id: id
        },
        set: updateValue
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'update', error.message);
    }
  }

  /**
   *  Get item by id
   *
   */
  getById(id: any): Promise<any> {
    try {
      return this._connection.select({
        from: this.tbName,
        where: {
          Id: id
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getById', error.message);
    }
  }

  /**
   * clear  table
   *
   */
  clear(): Promise<void> {
    try {
      return this._connection.clear(this.tbName);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clear', error.message);
    }
  }

  /* Retorna o schema da tabela que é necessário na criação do db. */
  abstract getTableSchema(): ITable;
  // Exemplo:
  // {
  //     name: 'Queue',
  //     columns: {
  //         'id': <IColumn>{
  //             notNull: true,
  //             primaryKey: true,
  //             autoIncrement: true,
  //             dataType: DATA_TYPE.Number
  //         },
  //         'key': <IColumn>{
  //             notNull: true,
  //             dataType: DATA_TYPE.String
  //         },
  //         'ano': <IColumn>{
  //             notNull: true,
  //             dataType: DATA_TYPE.Number
  //         },
  //         'uno': <IColumn>{
  //             notNull: true,
  //             dataType: DATA_TYPE.Number
  //             // Default: 'male'
  //         },
  //         'cadastro': <IColumn>{
  //             notNull: true,
  //             dataType: DATA_TYPE.Array // | JsStore.Data_Type.Array
  //         }
  //     }
  // } as ITable;
}
