import { IMedlogicProfessional } from './imedlogic-professional';

export interface IMedlogicEvolution {
	desidratacao: boolean;
	residente: string;
	dtNascimento: any;
	nomemae: any;
	codigoPaciente: any;
	identificacao1: any;
	identificacao2: any;
	prontuario: any;
	intensidade: string;
	senteDor: boolean;
	intensidadeDorEvaURL: any;
	dataAvaliacao: Date;
	titulo: any;
	codigo: any;
	oCORRENCIA: any;
	obito: boolean;
	ulceraDecubito: boolean;
	queixaPaciente: string;
	realizouConduta: boolean;
	houveIntercorrencias: boolean;
	oPacienteEvacuaRegularmente: boolean;
	ultimaEvacuacaoOcorreurmais2Dias: boolean;
	oPacienteEstaIngerindoUmaQuantidadeAdequadafibras: boolean;
	oPacienteEstahidratandoAdequadamente: boolean;
	oPacienteUtilizaLaxante: boolean;
	qual: any;
	condutaEvolucao: string;
	diarreia: boolean;
	tomouBanho: boolean;
	horario: any;
	diurese: boolean;
	higieneBucal: boolean;
	executorAvaliacao: any;
	tecnicoResponsavel: any;
	glicose: any;
	temperaturaTax: any;
	evolucaoDescritiva: string;
	hospede: string;
	habilitado: boolean;
	ingestao: boolean;
	pASistolica: any;
	pADiastolica: any;
	quedaComLesao: boolean;
	quedaSemLesao: boolean;
	tentativaSuicidio: boolean;
	escabiose: boolean;
	desnutricao: boolean;
	dataHoraEvolucao: Date;
	// calculado
	descricaoHtml?: string;
	turno: string;
	professional?: IMedlogicProfessional;
	calcHasOccurrence?: any;
	// 13jul2022
	executorAvaliacaoLogin: string;
	executorAvaliacaoNo: number;
}
