<ng-container *ngIf="cadastros$ | async as cadastros; else carregamento">
  <div class="grid-container" *ngIf="cadastros && cadastros.length > 0; else empty">
    <div *ngFor="let cadastro of cadastros" (click)="onClick(cadastro)">
      <!-- [class.grid-item-image]="cadastro.hasImage" -->
      <div class="grid-item grid-item-image">
        <div class="circle icon" *ngIf="cadastro.hasImage">
          <img class="img" [src]="imgUrl" />
        </div>
        <div class="circle-grad icon" *ngIf="!cadastro.hasImage">
          <span class="letter">{{ cadastro.field1.substr(0, 1) }}</span>
        </div>
        <span class="row1 field1">{{ cadastro.field1 }}</span>
        <span class="row1 clm-right field2">{{ cadastro.field2 }}</span>
        <span class="row2 field3">{{ cadastro.field3 }}</span>
        <span class="row2 clm-right field4">{{ cadastro.field4 }}</span>
        <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #empty>
  <h2>Nenhum registro encontrado.</h2>
</ng-template>
<ng-template #carregamento>
  <ui-loading-spinner></ui-loading-spinner>
  <span>Carregando...</span>
</ng-template>
