import { ConfigStateService } from '@medlogic/shared/state-config';
import { Observable } from 'rxjs';
import { ObjectDAL } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { IAtividade } from '../../interface/iatividade';
import { IPasso } from '../../interface/ipasso';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { WebService } from '@medlogic/shared/shared-data-access';

@Injectable()
export class ProcessoDAL extends ObjectDAL {

    constructor(
        webService: WebService,
        log: LogService,
        global: GlobalService,
        private cnf: ConfigStateService
   ) {
       super(webService, log, global);
   }

    /* Resgata o próximo passo do processo, após conclusão de uma atividade. */
    getProximoPasso(
        atividade: IAtividade,
        pno: number,
        uno: number,
        tno: number,
        ono: number
    ): Observable<IPasso> {
        try {
            const method = 'getProximoPasso';
            const result = this.webService.connect<IPasso>(method,
                [
                    { name: 'processoNo', value: pno },
                    { name: 'xmlPasso', value: this.toXml(atividade, uno, tno, ono) },
                ]);
            return result;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getProximoPasso', error.message);
        }
    }

    /* Cria o xml de Passos. */
    protected toXml(atividade: IAtividade, uno: number, tno: number, ono: number): string {
        try {
            return `<Passos>
                      <Passo>
                        <TarefaNo>${tno}</TarefaNo>
                        <OcorrenciaNo>${ono}</OcorrenciaNo>
                        <DtInicio>${atividade.DtInicio}</DtInicio>
                        <AtividadeNo>${atividade.AtividadeNo}</AtividadeNo>
                        <TarefaComplemento1>${atividade.CalcVariavelIdentificacao1Valor}</TarefaComplemento1>
                        <TarefaComplemento2>${atividade.CalcVariavelIdentificacao2Valor}</TarefaComplemento2>
                        <UsuarioNo>${uno}</UsuarioNo>
                        <HoraInicio>${atividade.HoraInicio}</HoraInicio>
                        <TarefaNome>${atividade.TarefaNome}</TarefaNome>
                        <blAtividadeContinua>${atividade.blAtividadeContinua}</blAtividadeContinua>
                        <CalcblDataTarefa>${atividade.CalcblDataTarefa}</CalcblDataTarefa>
                        <CalcblDestinatario>${atividade.CalcblDestinatario}</CalcblDestinatario>
                        <CalcblHoraTarefa>${atividade.CalcblHoraTarefa}</CalcblHoraTarefa>
                      </Passo>
                    </Passos>`;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'toXml', error.message);
        }
    }
}
