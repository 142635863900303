<div class="debug">
  <div class="ibox-title">
    <div class="col-sm-10">
      <h4>Mensagens de Debug:</h4>
    </div>
    <div class="col-sm-2 float-left">
      <a class="btn btn-white btn-bitbucket" (click)="onTrashClick($event)">
        <i class="fa fa-trash"></i>
      </a>
    </div>
  </div>
  <div class="msg">
    <div [innerHTML]="msgFormula"></div>
    <div [innerHTML]="msg"></div>
  </div>
</div>
