<ng-container [ngSwitch]="enFileUploadMode">

  <!-- WEBCAM CAPTURE -->
  <ng-container *ngSwitchCase="'webcam'">
    <lib-cmp-webcam (dadosOut)="uploadFileFromWebcam($event)" (back)="onPreviewBack($event)"></lib-cmp-webcam>
  </ng-container>

  <ng-container *ngSwitchDefault>

    <div class="upload-options" *ngIf="!isLoading">

      <div class="webcam">

        <button mat-button (click)="onWebcamClick($event)" type="button">
          <mat-icon>photo_camera</mat-icon>
          <span>USAR CAMERA</span>
        </button>

      </div>

      <div class="file">
        <!-- <label for="fileUpload">Selecione um documento:</label> -->
        <input type="file" id="fileUpload" name="fileUpload" class="custom-file-input" [accept]="acceptFiles"
          (change)="handleFileInput($event, data)">
        <!-- <button *ngIf="fileToUpload && !isLoading" mat-raised-button
          (click)="uploadFileToActivity($event, data)">Enviar</button> -->
      </div>

    </div>

    <mat-progress-bar mode="determinate" *ngIf="fileToUpload && progress > 0 && progress <= 100" [value]="progress">
    </mat-progress-bar>

    <ui-loading [isLoading]="isLoading"></ui-loading>

  </ng-container>