import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { BaseNavigationService } from '@medlogic/shared/shared-data-access';
import { GlobalService, LogService, EnComponentsView, EnTheme } from '@medlogic/shared/shared-interfaces';

@Injectable({ providedIn: 'root' })
export class LocalNavigationService extends BaseNavigationService {

  constructor(
    private route: ActivatedRoute,
    router: Router,
    protected glb: GlobalService,
    protected log: LogService) {
    super(router);
  }

  /* Navega para uma rota fornecida como parâmetro.
   * Iniciar com / para que seja uma rota relativa.
   */
  gotoRoute(route: any[]): void {
    this.router.navigate(route);
  }

  /* Retornará para uma rota já navegada, removendo todo o histórico após essa rota.
   */
  goBackTo(routeTitle: string): void {
    try {
      const findIndex = BaseNavigationService.history.findIndex((f) => this.glb.isEqual(f.title, routeTitle));
      if (findIndex >= 0) {
        const route = BaseNavigationService.history[findIndex].route;
        BaseNavigationService.history.splice(findIndex, 99999);
        this.router.navigate(route);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'goBackTo', error.message);
    }
  }

  /* Vai para a página inicial da aplicação. */
  gotoRoot(forceRefresh: boolean = false): void {
    if (forceRefresh) {
      const rnd = Math.round(Math.random() * 100);
      this.router.navigate(
        this.getRouteRoot(),
        { relativeTo: this.route, queryParams: { refresh: rnd } }
      ); // Adiciona um parâmetro qualquer para forçar refresh.
    } else {
      this.router.navigate(this.getRouteRoot(), { relativeTo: this.route });
    }
  }

  /* Retorna a rota inicial da medicação.
  * Para direcionamento, utilizar gotoRoot.
  */
  getRouteRoot(): any[] {
    return this.getRoutePatient();
  }

  getRouteProcessList(enTheme: EnTheme = EnTheme.black): any[] {
    return ['/bbfm', 'process', 'list', enTheme];
  }

  gotoRouteContaHome(load: boolean = false): void {
    // this.gotoRoute(this.getRouteContaHome(load));
    this.gotoRoute(this.getRouteList(load));
  }

  getRoutePatient(enTheme: EnTheme = EnTheme.black): any[] {
    return ['/pwa', 'carecard'];
  }

  gotoRoutePatient(): void {
    this.gotoRoute(this.getRoutePatient());
  }

  /* Retorna a rota inicial da lista de pessoas, que é a página com os pacientes.
  * Para direcionamento, utilizar gotoMedicationHome.
  */
  getRouteContaHome(load: boolean = false): any[] {
    return ['/bbfm', 'account', 'list', load];
  }

  gotoRouteList(load: boolean = false): void {
    this.gotoRoute(this.getRouteList(load));
  }

  getRouteList(load: boolean = false): any[] {
    return ['/bbfm', 'list', load];
  }

  gotoRouteLogin(): void {
    this.gotoRoute(this.getRouteLogin());
  }

  /* Essa é uma rota especial, que deve abrir no <router-outlet name="loginRoute">. */
  getRouteLogin(): any[] {
    return ['/login'];
  }


  getRouteContaActionEdit(ano: number, pno: number, ono: number, enTheme: EnTheme, readOnly: boolean): any[] {
    return ['/bbfm', 'account', 'edit', ano, pno, ono, enTheme, readOnly];
  }

  gotoRouteContaActionEdit(ano: number, pno: number, ono: number, enTheme: EnTheme, readOnly: boolean): void {
    this.gotoRoute(this.getRouteContaActionEdit(ano, pno, ono, enTheme, readOnly));
  }

  gotoRouteAccountNew(ano: number, pno: number, enTheme: EnTheme): void {
    this.gotoRoute(this.getRouteAccountNew(ano, pno, enTheme));
  }

  getRouteAccountNew(ano: number, pno: number, enTheme: EnTheme): any[] {
    return ['bbfm', 'account', 'new', ano, pno, enTheme];
  }

  /* ATIVIDADE */

  getRouteActivityEdit(ano: number, ono: number, readOnly: boolean): any[] {
    return [`/ge/activity/${ano}/${ono}/${readOnly}`];
  }

  getRouteActivityNew(ano: number): any[] {
    return [`/ge/activity/${ano}`];
  }

  // EDIT/READD
  gotoActivity(ano: number, ono: number, readOnly: boolean): void {
    this.router.navigate(this.getRouteActivityEdit(ano, ono, readOnly));
  }

  // NEW
  gotoActivityNew(ano: number): void {
    this.router.navigate(this.getRouteActivityNew(ano));
  }

  /* ATIVIDADE PROCESSO */
  gotoProcessActivityListNew(uno: number, pno: number, saveInList: boolean): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteProcessActivityListNew(uno, pno, saveInList));
  }

  getRouteProcessActivityListNew(uno: number, pno: number, saveInList: boolean): any[] {
    return [`/ge/processactivity/${uno}/${pno}/${saveInList}`];
  }

  gotoProcessActivityListEdit(
    uno: number,
    pno: number,
    ono: number,
    tno: number,
    readOnly: boolean,
    saveInList: boolean
  ): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteProcessActivityListEdit(uno, pno, ono, tno, readOnly, saveInList));
  }

  getRouteProcessActivityListEdit(
    uno: number,
    pno: number,
    ono: number,
    tno: number,
    saveInList: boolean,
    readOnly: boolean
  ): any[] {
    return [`/ge/processactivity/${uno}/${pno}/${ono}/${tno}/${saveInList}/${readOnly}`];
  }

  /* MULTIATIVIDADE PROCESSO */
  gotoMultiActivityListNew(uno: number, pno: number, ano: number, tno: number): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteMultiActivityListNew(uno, pno, ano, tno));
  }

  getRouteMultiActivityListNew(uno: number, pno: number, ano: number, tno: number): any[] {
    return [`/ge/multiactivitylist/${uno}/${pno}/${ano}/${tno}`];
  }

  gotoMultiActivityListEdit(uno: number, pno: number, ano: number, tno: number, ono: number): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteMultiActivityListEdit(uno, pno, ano, tno, ono));
  }

  getRouteMultiActivityListEdit(uno: number, pno: number, ano: number, tno: number, ono: number): any[] {
    return [`/ge/multiactivitylist/${uno}/${pno}/${ano}/${tno}/${ono}`];
  }

  // gotoMultiActivityNew(uno: number, pno: number, ano: number, tno: number): void {
  // 	// , readOnly: boolean, saveInList: boolean
  // 	this.router.navigate(this.getRouteMultiActivityNew(uno, pno, ano, tno));
  // }

  // getRouteMultiActivityNew(uno: number, pno: number, ano: number, tno: number): any[] {
  // 	return [ `/ge/multiactivity/${uno}/${pno}/${ano}/${tno}` ];
  // }

  gotoMultiActivityEdit(ano: number, pno: number, mano: number, ono: number): void {
    // , readOnly: boolean, saveInList: boolean
    this.router.navigate(this.getRouteMultiActivityEdit(ano, pno, mano, ono));
  }

  getRouteMultiActivityEdit(ano: number, pno: number, mano: number, ono: number): any[] {
    return [`/ge/multiactivity/${ano}/${pno}/${mano}/${ono}`];
  }

  /* CADASTRO */

  // EDIT/READD

  gotoListEdit(ano: number, ono: number, readOnly: boolean, saveInList: boolean): void {
    this.router.navigate(this.getRouteListEdit(ano, ono, readOnly, saveInList));
  }

  // NEW
  gotoListNew(ano: number, saveInList: boolean): void {
    this.router.navigate(this.getRouteListNew(ano, saveInList));
  }

  // Atividade Cadastro
  getRouteListEdit(ano: number, ono: number, readOnly: boolean, saveInList: boolean): any[] {
    return [`/ge/list/${ano}/${ono}/${readOnly}/${saveInList}`];
  }

  getRouteListNew(ano: number, saveInList: boolean): any[] {
    return [`/ge/list/${ano}/${saveInList}`];
  }

  /* CADASTRO DETALHE */

  // EDIT/READ

  gotoListDetailEdit(ano: number, ono: number, listvno: number, readOnly: boolean, saveInList: boolean): void {
    this.router.navigate(this.getRouteListDetailEdit(ano, ono, listvno, readOnly, saveInList));
  }

  // NEW
  gotoListDetailNew(ano: number, listvno: number, saveInList: boolean): void {
    this.router.navigate(this.getRouteListDetailNew(ano, listvno, saveInList));
  }

  // Atividade Cadastro
  getRouteListDetailEdit(ano: number, ono: number, listvno: number, readOnly: boolean, saveInList: boolean): any[] {
    return [`/ge/listdetail/${ano}/${ono}/${listvno}/${readOnly}/${saveInList}`];
  }

  getRouteListDetailNew(ano: number, listvno: number, saveInList: boolean): any[] {
    return [`/ge/listdetail/${ano}/${listvno}/${saveInList}`];
  }

  // TAREFA
  getRouteSchedule(): any[] {
    return [`ge/schedule`];
  }

  gotoSchedule(): void {
    this.router.navigate(this.getRouteSchedule());
  }

  // AGENDA
  getRouteTask(date: string, time: string): any[] {
    date = date.replace(/\//gi, '-');
    return [`ge/task/${date}/${time}`];
  }

  gotoTask(date: string, time: string): void {
    date = date.replace(/\//gi, '-');
    this.router.navigate(this.getRouteTask(date, time));
  }

  gotoTimeline(): void {
    this.router.navigate(this.getRouteTimeline());
  }
  getRouteTimeline(): any[] {
    return [`/rpt/Timeline`];
  }

  // ERP

  gotoErpGrid(modeloConfigNo: number): void {
    this.router.navigate(this.getRouteErpGrid(modeloConfigNo));
  }

  getRouteErpGrid(modeloConfigNo: number): any[] {
    return [`/erp/grid/${modeloConfigNo}`];
  }

  getRouteErpMatrix(modeloConfigNo: number): any[] {
    return [`matrix/${modeloConfigNo}`];
  }

  getRouteErpInvoice(modeloConfigNo: number): any[] {
    return [`/invoice/${modeloConfigNo}`];
  }

  getRouteErpStock(modeloConfigNo: number): any[] {
    return [`/erp/stock/${modeloConfigNo}`];
  }

  getRouteErpBank(modeloConfigNo: number): any[] {
    return [`/erp/bank/${modeloConfigNo}`];
  }

  getRouteErpPurchase(modeloConfigNo: number): any[] {
    return [`/erp/purchase/${modeloConfigNo}`];
  }

  gotoErpAddSupplier(modeloConfigNo: number): void {
    this.router.navigate(this.getErpAddSupplier(modeloConfigNo));
  }

  getErpAddSupplier(modeloConfigNo: number): any[] {
    return this.getRouteListNew(modeloConfigNo, true);
  }

  // RPT
  gotoRptSeparationLabel(modeloConfigNo: number): void {
    this.router.navigate(this.getRouteRptSeparationLabel(modeloConfigNo));
  }
  getRouteRptSeparationLabel(modeloConfigNo: number): any[] {
    return ['/rpt', 'separation', 'label', modeloConfigNo];
  }

  // SEARCH

  gotoSearchModule(moduleName: EnComponentsView): void {
    this.router.navigate([moduleName]);
  }

  /* Vai para a página inicial da aplicação. */
  // gotoRoot(cadConfigNo: number, forceRefresh: boolean = false): void {
  //   this.cadRootConfigNo = cadConfigNo;
  //   if (forceRefresh) {
  //     const rnd = Math.round(Math.random() * 100);
  //     this.router.navigate(this.getRouteRoot(cadConfigNo),
  // { queryParams: { 'refresh': rnd } }); // Adiciona um parâmetro qualquer para forçar refresh.
  //   } else {
  //     this.router.navigate(this.getRouteRoot(cadConfigNo));
  //   }
  // }

  /* Retorna a rota inicial da medicação, que é a página com os pacientes.
    * Para direcionamento, utilizar gotoRoot.
    */
  // getRouteRoot(cadConfigNo: number): any[] {
  //   return this.getRouteMedicationHome(cadConfigNo);
  // }

  /* Retorna a rota inicial da medicação, que é a página com os pacientes.
    * Para direcionamento, utilizar gotoMedicationHome.
    */
  getRouteMedicationHome(cadConfigNo: number): any[] {
    return [`assist/carecard/${cadConfigNo}`];
  }

  getRouteVitalSignList(): any[] {
    return [`pwa/vitalsign/list`];
  }

  getRouteEvolutionList(): any[] {
    return [`pwa/evolution/list`];
  }


  gotoVitalSignList(): void {
    this.router.navigate(this.getRouteVitalSignList());
  }

  gotoEvolutionList(): void {
    this.router.navigate(this.getRouteEvolutionList());
  }


}
