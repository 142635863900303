import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { IUrl } from '../../interface/iurl';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'lib-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css']
})
export class BottomSheetComponent {

  urls: IUrl[];

  constructor(
    private log: LogService,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any // { urls: Array<IUrl> }
  ) {
    this.urls = data.urls;
  }

  openLink(event: MouseEvent, url: IUrl): void {
    try {
      this.bottomSheetRef.dismiss(url);
      event.preventDefault();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'openLink', error.message);
    }
  }


}
