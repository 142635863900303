<label class="control-label" [class.is-mobile]="isMobile" [class.black]="enTheme === ENTHEME.black"
  [class.black-error]="isBlackError()" [for]="getId(ctrl)" [class.required]="isControlInvalid(ctrl, formGroup)">

  <span *ngIf="ctrl?.IsShowToolTipMessage" class="fa fa-info-circle" [pTooltip]="ctrl?.ToolTipMessage"
    tooltipPosition="right"></span>

  <!--Rótulo normal-->
  <span *ngIf="!showAlert && !showCustomValidation(ctrl, formGroup)">{{ ctrl?.Rotulo }}</span>

  <!--Alerta condicional-->
  <span *ngIf="showAlert" [style.color]="alertColor">{{ ctrl?.Rotulo }}</span>
  <div *ngIf="showAlert" #container style="display:inline-block;position:relative">
    <span class="fa fa-warning" [pTooltip]="ctrl?.AlertMessage" tooltipPosition="right" [appendTo]="container"></span>
  </div>

  <!--Validação condicional-->
  <span *ngIf="showCustomValidation(ctrl, formGroup)" [style.color]="customValidationColor">{{ ctrl?.Rotulo }}</span>
  <div *ngIf="showCustomValidation(ctrl, formGroup)" #container2 style="display:inline-block;position:relative">
    <span class="fa fa-warning" [pTooltip]="ctrl?.ValidationMessage" tooltipPosition="right"
      [appendTo]="container2"></span>
  </div>

</label>