import { Component, Input } from '@angular/core';
import { IListItem } from '../../interface/ilist-item';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent {

  @Input() listItem: IListItem;

}
