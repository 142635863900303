import { ITestResult } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { LogService, EnUserRole, GlobalService, ILogin } from '@medlogic/shared/shared-interfaces';
import { IExameResultadoState, IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { ItemSearchPipe } from '@medlogic/shared/utils';

export const selectExameResultado = (state: IExameResultadoState) => state?.resultado;
export const selectAllExameResultados = (state: IExameResultadoState) => state?.resultados;
export const selectExameResultadoError = (state: IExameResultadoState) => state?.error;

const glb = new GlobalService();
const log = new LogService();

export const selectExameResultadosAsIMedicalTest = (state: IExameResultadoState): ITestResult[] => {
  const resultadosByDate = state?.resultados?.map(resultado => {
    return ({
      resultId: resultado?.codigo,
      testId: resultado?.idExame,
      patientId: resultado?.codigoPaciente,
      dtTest: resultado?.dataExame,
      dtRegister: resultado?.dataExame,
      value: resultado?.resultado,
      notes: resultado?.laudo
    } as ITestResult);
  });
  return resultadosByDate?.sort((a, b) => glb.compareDates(a?.dtTest, b?.dtTest));
}

function uniqueDates(itens: any[]) {
  const datas: Date[] = [];
  if (itens.length > 1) {
    itens.forEach(item => {
      if (!datas.find(f => glb.isEqualIgnoreTime(f, glb.YYYYMMddThhmmssToDate(item.obj.data)))) {
        datas.push(glb.YYYYMMddThhmmssToDate(item.obj.data));
      }
    });
    return datas;
  }
}

const filterBySearch = (items: any[], keyword: string) => { // ISmartExameResultado<IModule>
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

export const selectExameResultadoDefaultFormControls = (state: IAppMedlogicState): any => {
  const isEducator = [EnUserRole.customer, EnUserRole.educator, EnUserRole.professional].includes(state?.tenant?.userRole);
  const isSpecialist = [EnUserRole.professional].includes(state?.tenant?.userRole);
  const isParent = [EnUserRole.responsible].includes(state?.tenant?.userRole);
  const userName = state?.login?.userName;
  const patient = state?.person?.person;
  return {
    V_104052: userName,
    V_102283: state?.person?.person?.codigo,
    V_104049: glb.dateToYYYYMMddThhmmss(new Date()),
    // V_102725: isEducator ? userName : patient?.nomeEducadorProfissionalResponsavel,
    // V_102727: isParent ? userName : patient?.nomeresponsavelLegal,
    // V_102728: patient?.grauparentesco,
    // V_102303: state?.school?.selectedSchool?.codigo || (state?.login?.selectedLogin as ILogin)?.customerId,
    V_103860: isEducator ? (state?.login?.selectedLogin as ILogin)?.usuarioLogadoNome : '',
    V_103865: isSpecialist ? (state?.login?.selectedLogin as ILogin)?.usuarioLogadoNome : '',
  };
}
