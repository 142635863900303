import { addLog, openAlert } from '@medlogic/shared/state-log';
import { ILog, LogService } from '@medlogic/shared/shared-interfaces';
import { IAppState, UnsubscribeOnDestroyAdapter, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { IWebSocketContract } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { Store } from '@ngrx/store';
// import { openAlert } from '@medlogic/medlogic/medlogic-state'; ATENÇÃO: dependência cruzada com medlogic-state, faz a aplicação parar de rodar (compila mas não roda)

import { io, Socket } from "socket.io-client";

@Injectable()
export class WebSocketService extends UnsubscribeOnDestroyAdapter {

    private socket$: Observable<Socket> = this.createAsync();

    constructor(
        private log: LogService,
        private store: Store<IAppState>,
        private cnf: ConfigJsonService,
    ) {
        super();
    }

    private createAsync(): Observable<Socket> {
        return of(null)
            .pipe(
                withLatestFrom(this.store),
                map(([never, state]) => io(`${this.cnf.baseUrl.replace('https', 'ws').replace('http', 'ws')}`, {
                // map(([never, state]) => io(`${this.cnf.baseUrl}`, {
                    reconnectionDelayMax: 10000,
                    extraHeaders: {
                        Authorization: `Bearer ${state?.tenant?.token}`
                    }
                    // auth: {
                    //     token: `Bearer ${state?.tenant?.token}`
                    // },
                    // query: {
                    //     "my-key": "my-value"
                    // }
                })),
                tap((socket) => socket.connected ? socket.connect() : null),
                catchError((err, obs) => { this.log.Registrar('websocket', 'createAsync', err); return obs; })
            );
    }

    listen(key: string) {
        const id = Guid.create().toString();
        return this.socket$
            .pipe(
                this.joinRoom$(key),
                tap((socket: Socket) => socket.on('msgFromServer', (message: any) => {
                    // Esse método será chamado apenas se o servidor disparar esse evento
                    this.store.dispatch(addLog({ log: { msg: '*********** CONCLUSÃO Prescrição ***********', showMessage: true } as ILog }));
                    this.store.dispatch(addLog({ log: { msg: message, showMessage: true } as ILog }));
                    this.store.dispatch(openAlert({ messageHtml: message, title: 'Prescrição salva' }));
                })),
                map((socket) => { // nesse evento entra de imediato, após o servidor conformar o join
                    return ({ id, message: '', room: key } as IWebSocketContract);
                }),
                catchError((err, obs) => { this.log.Registrar('websocket', 'listen', err); return obs; })
            );
    }

    emit(eventName: string, id: string, message: string): Observable<any> {
        const data = {
            name: eventName,
            text: message,
            room: id
        };
        return this.socket$
            .pipe(
                mergeMap((socket) => of(socket.emit(eventName, data))),
                map(() => ({ id, message: '', room: id } as IWebSocketContract)),
                catchError((err, obs) => { this.log.Registrar('websocket', 'emit', err); return obs; })
            );
    }

    private joinRoom$ = (roomId: string) => mergeMap((socket: Socket) => {
        return of(socket.emit('joinRoom', roomId))
            .pipe(
                tap(() => socket.on('joinedRoom', (message: any) => {
                    // Esse método será chamado apenas se o servidor disparar esse evento
                    console.log('websocket joinedRoom: ${message}', message);
                })),
                map(() => socket),
                catchError((err, obs) => { this.log.Registrar('websocket', 'joinRoom', err); return obs; })
            );
    });

    private leaveRoom = (roomId: string) => mergeMap((socket: Socket) => {
        return of(socket.emit('leaveRoom', roomId)).pipe(map(() => socket));
    });


}
