import { UiBottomSheetComponent } from './ui-bottom-sheet/ui-bottom-sheet.component';
import { MaterialModule } from '@medlogic/shared/material';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const ENTRY_COMPONENTS = [
  DialogConfirmComponent,
  UiBottomSheetComponent
];

const MODULES = [
  CommonModule,
  MaterialModule
];

@NgModule({
  declarations: [ENTRY_COMPONENTS],
  imports: [MODULES],
  entryComponents: [
    ENTRY_COMPONENTS
  ]
})
export class UiDialogConfirmModule {}
