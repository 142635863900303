import { createAction, props } from '@ngrx/store';
import { IPerson } from '@medlogic/medlogic/medlogic-shared-interfaces';

// TODO: Each model need to have its specific Action, Reducer, Effects.
export enum PersonActionTypes {
  LoadPersons = '[Person Component] Load Person',
  SetPerson = '[PagePersonList, PageCareCoreList  Component] Set Person',
  ClearPerson = '[Home Component] Clear Person',

  LoadAllPersonRelationships = '[PagecareCoreList Component] Load All Relationships of the Person',

  FetchSuccessPersons = '[Person Effects] Fetch Success Person', // Set the Person to the Store, after Effects
  FetchErrorPersons = '[Person Effects] Fetch Error Person',

  FetchSuccessRelationships = '[Relationships Effects] Fetch Success Relationships', // Set the Relationships to the Store, after Effects
  FetchErrorRelationships = '[Relationships Effects] Fetch Error Relationships',
}

export const loadPersons = createAction(PersonActionTypes.LoadPersons);
export const setPerson = createAction(PersonActionTypes.SetPerson, props<{ person: IPerson }>());
export const clearPerson = createAction(PersonActionTypes.ClearPerson);
export const fetchSuccessPersons = createAction(PersonActionTypes.FetchSuccessPersons, props<{ persons: IPerson[] }>());
export const fetchErrorPersons = createAction(PersonActionTypes.FetchErrorPersons, props<{ persons: IPerson[] }>());

export const fetchSuccessPersonRelationships = createAction(PersonActionTypes.FetchSuccessRelationships, props<{ person: IPerson }>());
export const fetchErrorPersonRelationships = createAction(PersonActionTypes.FetchErrorRelationships, props<{ person: IPerson }>());
