import { IPractitioner } from './ipractitioner';

export interface IIdentifier {
    identifierIdentifier: string;
    identifierUse: string;
    practitioner: IPractitioner[];
    type: string;
    system: string;
    value: string;
    period: string;
    assigner: string;
    tenantId: number;
    language: string;
}