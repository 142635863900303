import { IAtividadeComponenteDAL, IOcorrencia } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { EnBubbleEvent } from '../../shared/enum/en-bubble-event.enum';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { AtividadeProcessoComponent } from '../atividade-processo/atividade-processo.component';
import { Params } from '@angular/router';
import { IAtividade } from '../../shared/interface/iatividade';
import { EnumAtividadeTipo } from '../../shared/enum/enum-atividade-tipo.enum';
import { IPasso } from '../../shared/interface/ipasso';
import { iif, Observable } from 'rxjs';
import { of } from 'rxjs';
import { EnMaterialIcon } from '@medlogic/shared/gecore';
import { EnActivityType } from '@medlogic/shared/gecore';
import { FormGroup } from '@angular/forms';

/* Essa é a atividade tradicional do GE, associada a um ou mais processos. */
@Component({
  selector: 'lib-atividade-multi-processo',
  templateUrl: './atividade-multi-processo.component.html',
  styleUrls: ['./../atividade-view/atividade-view.component.css']
})
export class AtividadeMultiProcessoComponent extends AtividadeProcessoComponent implements OnInit {
  /*override  */
  ngOnInit(): void {
    try {
      super.ngOnInit();
      this.activityType = EnActivityType.AtividadeMultipla;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  // override
  /*Extrai os parâmetros passados na url/rota */
  protected getUrlParams(): void {
    try {
      // TODO: ATENÇÃO: Houve atualização em AtividadeView.getUrlParams avaliar se deve ser atualizad
      this.subs.sink = this.route.params
        .pipe(
          mergeMap((params: Params) => {
            if (params.ano) {
              this.config.ModeloAtividadeNo = +params.ano;
            }
            if (params.pno) {
              this.config.processoNo = +params.pno;
            }
            if (params.mano) {
              this.config.MultiAtividadeNo = +params.mano;
            }
            if (params.uno) {
              this.config.usuarioLogadoNo = +params.uno;
            }
            // Necessário zerar a ocorrencia se não for fornecida, pois, é um requisito para se criar nova.
            this.config.OcorrenciaNo.next(params.ono ? +params.ono : -1);
            return this.refresh(
              this.activityType,
              this.config.ModeloAtividadeNo,
              this.config.OcorrenciaNo.value,
              this.config.usuarioLogadoNo,
              null,
              true,
              this.config.processoNo,
              this.config.TarefaNo,
              this.config.MultiAtividadeNo
            );
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /*override
   * mano: é o id da MultiAtividade a qual as atividades pertencem.
  */
  protected refresh(
    enAtividadeTipo: EnumAtividadeTipo,
    ano: number,
    ono: number,
    uno: number,
    defaultFormControls: any,
    addToHistory: boolean = true,
    pno: number = -1,
    tno: number = -1,
    mano: number = -1
  ): Observable<IAtividade> {
    try {
      return this.getAtividade(ano, mano, pno, ono)
        .pipe(
          mergeMap((atividade: IAtividade) => {
            this.atividade = atividade;
            return super.refresh(this.activityType, ano, ono, uno, null);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /*Acrescenta ao histórico de navegação, o que faz com que o botão de voltar seja exibido. */
  protected addToHistory(ano: number, pno: number, mano: number, ono: number): void {
    try {
      const route = this.navigation.getRouteMultiActivityEdit(ano, pno, mano, ono);
      this.navigation.addToHistory(route, this.msg.MODULE_NAME_MULTIATIVIDADE);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addToHistory', error.message);
    }
  }

  /*Retorna o objeto referente a atividade selecionada. */
  protected getAtividade(ano: number, atividadeMultiplaNo: number, pno: number, ono: number): Observable<IAtividade> {
    try {
      return this.atividadeDAL.getAtividadeByAtividadeMultipla(
        this.config.usuarioLogadoNo,
        ano,
        atividadeMultiplaNo,
        pno,
        ono
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAtividade', error.message);
    }
    return of(null);
  }

  /*override
  * Evento chamado após a conclusão do salvamento da atividade. Poderá ser sobrescrito. */
  protected afterSave(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    fg: FormGroup,
    isEditMode: boolean
  ): Observable<IOcorrencia> {
    try {
      if (enBubbleEvent === EnBubbleEvent.activityComplete) {
        if (this.formGroup.invalid) {
          // Formulário inválido
          return this.showInvalidFormMessage()
            .pipe(
              mergeMap((res) => {
                if (res) {
                  return of({ OcorrenciaNo: ono, TarefaNo: tno, AtividadeNo: ano, fg, isEditMode } as IOcorrencia);
                } else {
                  return of(null);
                }
              })
            );
        } else {
          // Concluir a atividade
          return this.doActivityComplete(tno, ano, ono, uno, pno).pipe(
            mergeMap((passo) => super.afterSave(enBubbleEvent, tno, ano, ono, uno, pno, componentes, fg, isEditMode))
          );
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave override', error.message);
    }
  }

  /*Realiza a conclusão da Atividade. */
  protected doActivityComplete(tno: number, ano: number, ono: number, uno: number, pno: number): Observable<any> { // { passo: IPasso, ocorrencia: IOcorrencia | any }
    try {
      this.isLoadingAfterComplete = true;
      // TODO: checar porque Variaveis de identificação não estão sendo preenchidas
      const varId1 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao1No}`);
      const varId2 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao2No}`);
      this.atividade.CalcVariavelIdentificacao1Valor = varId1 ? varId1.value : -1;
      this.atividade.CalcVariavelIdentificacao2Valor = varId2 ? varId2.value : -1;
      this.atividade.OcorrenciaNo = ono;
      this.atividade.TarefaNo = tno;

      const openDialog$ = (passo: IPasso) =>   // Significa que há uma mensagem configurada após a tarefa
        this.onWindowDialog(
          this.atividade.CalcAtribuicaoTitulo,
          this.atividade.CalcAtribuicaoMensagemPublico,
          '600px',
          EnMaterialIcon.check
        ).afterClosed().pipe(map(() => passo));

      const emitAfterCompleted$ = (passo: IPasso) => {
        this.isLoadingAfterComplete = false;
        // FIXME: POTENCIALMENTE IMPACTANTE NA CONCLUSÃO E RISCO DE DUPLICAÇÃO
        const obj = {
          values: this.formGroup.getRawValue(),
          tno,
          ano,
          ono,
          uno,
          pno
        };
        this.eventAfterCompleted.emit(obj);
        return of(passo);
      }

      const error$ = () => catchError((err, obs) => {
        console.log(err);
        return of(err);
      });

      return this.ocorrenciaDAL.setOcorrenciaNotificarConclusaoSalvamento(pno, ono, uno, ano)
        .pipe(
          mergeMap(() => this.processoDAL.getProximoPasso(this.atividade, pno, uno, tno, ono)
            .pipe(
              mergeMap(passo => iif(
                () => !!this.atividade.CalcAtribuicaoMensagemPublico,
                openDialog$(passo),
                emitAfterCompleted$(passo)
              )),
              map(passo => ({ passo, ocorrencia: null })),
              error$()
            )
          ),
          error$()
        );
      // this.isLoadingAfterComplete = true;
      // return this.ocorrenciaDAL.setOcorrenciaNotificarConclusaoSalvamento(pno, ono, uno, ano)
      //   .pipe(
      //     mergeMap(() => {
      //       try {
      //         // TODO: checar porque Variaveis de identificação não estão sendo preenchidas
      //         const varId1 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao1No}`);
      //         const varId2 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao2No}`);
      //         this.atividade.CalcVariavelIdentificacao1Valor = varId1 ? varId1.value : -1;
      //         this.atividade.CalcVariavelIdentificacao2Valor = varId2 ? varId2.value : -1;
      //         this.atividade.OcorrenciaNo = ono;
      //         this.atividade.TarefaNo = tno;
      //         return this.processoDAL.getProximoPasso(this.atividade, pno, uno, tno, ono)
      //           .pipe(
      //             map((passo) => {
      //               if (this.atividade.CalcAtribuicaoMensagemPublico) {
      //                 // Significa que há uma mensagem configurada após a tarefa
      //                 return this.onWindowDialog(
      //                   this.atividade.CalcAtribuicaoTitulo,
      //                   this.atividade.CalcAtribuicaoMensagemPublico,
      //                   '600px',
      //                   EnMaterialIcon.check
      //                 )
      //                   .afterClosed()
      //                   .pipe(map(__ => ({ passo, ocorrencia: null })));
      //               }
      //               this.isLoadingAfterComplete = false;
      //               this.emitAfterCompleted(tno, ano, ono, uno, pno);
      //               return { passo, ocorrencia: null };
      //             })
      //           );
      //       } catch (error) {
      //         this.log.Registrar(this.constructor.name, 'afterSave.mergeMap', error.message);
      //       }
      //       return of(null);
      //     })
      //   );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'doActivityComplete', error.message);
    }
    return of(null);
  }
}
