import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ScheduledPeriodEffects } from './+state/scheduledPeriod.effects';
import * as fromScheduledPeriod from './+state/scheduledPeriod.reducer';

const SERVICES = [
];

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromScheduledPeriod.scheduledPeriodsFeatureKey, fromScheduledPeriod.reducer),
  EffectsModule.forFeature([ScheduledPeriodEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})
export class StateScheduledPeriodModule { }
