<ui-loading [isLoading]="isLoadingAfterComplete"> </ui-loading>
<form #form [formGroup]="formGroup" *ngIf="actived" (ngSubmit)="onSubmit()">
    <lib-tab [attr.data-ono]="ocorrenciaNo" [isActionBarVisible]="false" [activityType]="activityType" #tabComponent [saveInList]="saveInList" [addToHistory]="true" [componentes]="componentes" [atividade]="atividade" [tabs]="tabs" [isMobile]="isMobile" [tabActivedId]="tabActivedId"
        [saved]="saved" [formGroup]="formGroup" [isEditMode]="isEditMode" [isReadOnly]="isReadOnly" [isLoading]="isLoadingAfterComplete" [formErrors]="formErrors" (eventBubble)="onEventBubble($event)" [atividadeNo]="atividadeNo" [atividadeNome]="atividadeNome" [enTheme]="enTheme"></lib-tab>
</form>
<!--Mensagem de confirmação de exclusão -->
<p-confirmDialog header="Confirmação" icon="fa fa-question-circle" [rejectIcon]="message.icon" [rejectLabel]="message.firstButtonLabel"
  acceptLabel="Sim" [style]="{ 'width':'425px' }">
</p-confirmDialog>
<lib-debug *ngIf="isDebug" [msg]="debugMsg"></lib-debug>
