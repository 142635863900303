export interface IssuerIdentification {
  ocorrenciaNo: number;
  codigo: any;
  titulo: any;
  habilitado: boolean;
  razaoSocial: any;
  cNPJ: any;
  descricao: any;
  ruaAvenida: any;
  numero: any;
  cidade: any;
  cEP: any;
  telefone1: any;
  estado: string;
  telefone2: any;
  bairro: any;
}
