import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebService } from '@medlogic/shared/shared-data-access';
import { Observable } from 'rxjs';
import { error } from './error';
import {
  GlobalService, IFileUploadResponse, ConfigJsonService,
  IFileDeleteResponse, IFileDeleteRequest, IAttachmentHeadersResponse, routePostAttachment, routeDeleteAttachment, routeGetAttachmentHeaders
} from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  // private readonly routePostAttachment = 'File/PostAttachment';
  private readonly routePostAttachment = `${routePostAttachment}`;
  // private readonly routeGetAttachmentHeaders = 'File/GetAttachmentHeaders';
  private readonly routeGetAttachmentHeaders = `${routeGetAttachmentHeaders}`;
  // private readonly routeDeleteAttachment = 'File/DeleteAttachment';
  private readonly routeDeleteAttachment = `${routeDeleteAttachment}`;

  constructor(
    private ws: WebService,
    private log: LogService,
    private glb: GlobalService,
    private cnfJson: ConfigJsonService,
    private http: HttpClient
  ) { }

  /* Save the physical file to the Storage and/or file system.
  * The new API also saves the header at GE database.
  * It uses the node API. */
  postFile(fileToUpload: File, ano: number, ono: number, vno: number, containerTag: string = null): Observable<IFileUploadResponse> {
    try {
      const queryParams = `?ano=${ano}&ono=${ono}&vno=${vno}&container_tag=${containerTag}`;
      const urlFileSave = this.glb.urlJoin([this.cnfJson.baseUrlAPI, `${this.routePostAttachment}${queryParams}`]);
      const data = new FormData();
      data.append('file', fileToUpload, fileToUpload.name);
      return this.http.post<IFileUploadResponse>(urlFileSave, data)
        .pipe(
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'postFile', error.message);
    }
  }

  /* It doesn't get the physical files, but their database reference from the service. */
  getDocumentHeaders(ono: number, tno: number = -1): Observable<IAttachmentHeadersResponse> {
    try {
      tno = tno || -1;
      const queryParams = `?ono=${ono}&tno=${tno}`;
      const urlFileSave = this.glb.urlJoin([this.cnfJson.baseUrlAPI, `${this.routeGetAttachmentHeaders}${queryParams}`]);
      return this.http.get<IFileUploadResponse>(urlFileSave)
        .pipe(
          error()
        );
      // const method = 'getDocumentos';
      // return this.ws
      //   .connect(method, [
      //     { name: 'tarefaNo', value: tno },
      //     { name: 'ocorrenciaNo', value: ono }
      //   ], null, false)
      //   .pipe(
      //     map((data: any) => {
      //       if (data && data.Documento) {
      //         const docs = this.glb.alwaysReturnArray(data.Documento);
      //         return docs.map(m => ({ ...m, DocumentoNomeExibicao: this.getDocName(m.DocumentoNomeExibicao) }));
      //       }
      //       return null;
      //     }),
      //     error()
      //   );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDocumentHeaders', error.message);
    }
  }

  getDocName(originalName: string): string {
    try {
      const idx = originalName.lastIndexOf('_') + 1;
      return originalName.substr(idx);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDocName', error.message);
    }
    return null;
  }

  /** It doesn't get neither saves the physical file, but create a database reference to the document. */
  // The postFile already saves the header.
  // setDocument(
  //   ono: number,
  //   ano: number,
  //   uno: number,
  //   caminhoFisicoSemArquivo: string,
  //   nomeArquivoSemExtensao: string,
  //   extensao: string,
  //   tamanho: number,
  //   urlSemArquivo: string
  // ): Observable<any> {
  //   try {
  //     const method = 'setDocumento';
  //     return this.ws
  //       .connect<any>(method, [
  //         { name: 'ocorrenciaNo', value: ono },
  //         { name: 'atividadeNo', value: ano },
  //         { name: 'usuarioNo', value: uno },
  //         { name: 'caminhoFisicoSemArquivo', value: caminhoFisicoSemArquivo },
  //         { name: 'nomeArquivoSemExtensao', value: nomeArquivoSemExtensao },
  //         { name: 'extensao', value: extensao },
  //         { name: 'tamanho', value: tamanho },
  //         { name: 'urlSemArquivo', value: urlSemArquivo }
  //       ])
  //       .pipe(error());
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'setDocumento', error.message);
  //   }
  // }

  deleteDocument(deleteObj: IFileDeleteRequest): Observable<IFileDeleteResponse> {
    try {
      const { dno, filename, container_tag } = deleteObj;
      const params = new HttpParams()
        .set('dno', dno.toString())
        .set('filename', filename)
        .set('container_tag', container_tag)
      const urlFileSave = this.glb.urlJoin([this.cnfJson.baseUrlAPI, `${this.routeDeleteAttachment}`]);
      return this.http.delete<IFileDeleteResponse>(urlFileSave, { params })
        .pipe(
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'deleteDocument', error.message);
    }
  }


}
