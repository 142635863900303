import { BasePageService } from './base-page.service';
import { Observable } from 'rxjs';
import { LogService, EnRequestType, EnContentType, routeSendEmail, IEmailMessage, ISendEmailResponse } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  protected urlEmailSend = `${routeSendEmail}`;
  // protected urlEmailSend = `Email/Send`;

  constructor(
    private log: LogService,
    private basepage: BasePageService,
  ) { }

  send(email: IEmailMessage): Observable<IEmailMessage> {
    try {
      const url = this.basepage.format(this.urlEmailSend);
      return this.basepage.baseDados<ISendEmailResponse>(EnRequestType.Post, url, email, 2, EnContentType.Json)
        .pipe(
          toArray(),
          map(f => f?.filter(f => f.success).length > 0 ? email : null)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'send', error.message);
    }
    return of(null);
  }


}
