import { Injectable } from '@angular/core';
import { IssuerIdentificationService } from './issuer-identification.service';
import { LibService } from '@medlogic/shared/geform';
import {
    LogService,
    ConfigJsonService, GlobalService,
} from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';


@Injectable()
export class IssuerIdentificationCustomService extends IssuerIdentificationService {

    constructor(
        cadastroSrv: CadastroService,
        glb: GlobalService,
        cnf: ConfigJsonService,
        lib: LibService,
        log: LogService) {
        super(cadastroSrv, glb, cnf, lib, log);
        try {
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'constructor', error.message);
        }
    }


}
