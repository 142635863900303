<mat-list *ngIf="groups?.length > 0; else empty">
  <ng-container *ngFor="let group of groups">

    <div mat-subheader class="title">
      <span>{{ group?.title }}</span>
      <button mat-raised-button (click)="onAdd($event, group)">
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <ng-container *ngIf="group?.items?.length > 0; else empty">

      <mat-list-item *ngFor="let item of group?.items" [@fadeAnimation]="'in'" (click)="onItemClick($event, item)">

        <mat-icon mat-list-icon class="icn" [style.color]="item?.iconColor">{{ item?.iconName }}</mat-icon>

        <div mat-line class="top">
          {{ item?.topLeft }}
        </div>

        <div mat-line class="topRight">
          {{ item?.topRight }}
        </div>

        <div mat-line class="bottom">
          <mat-icon *ngIf="item?.statusIconName" [style.color]="item?.statusIconColor" class="icn-status">
            {{ item?.iconName }}
          </mat-icon>
          <span class="statusName">{{ item?.bottomLeft }}</span>
        </div>

        <mat-icon class="remove" (click)="onItemRemove($event, item)">delete</mat-icon>

        <mat-divider></mat-divider>

      </mat-list-item>

    </ng-container>

  </ng-container>
</mat-list>


<ng-template #empty>
  <ui-empty [showImage]="false" [title]="emptyMessage"></ui-empty>
</ng-template>