import { LibService } from '@medlogic/shared/geform';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { IFamily } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { GlobalService, LogService, IForm, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';


export abstract class FamilyService {

  // tslint:disable: max-line-length
  private readonly lstVariaveis = 'V_387,V_394,V_395,V_396,V_397,V_399,V_402,V_405,V_2193,V_2197,V_2198,V_2614,V_3292,V_3293,V_3335,V_28051,V_28936,V_28937,V_28938,V_28942,V_28943,V_28944,V_28945,V_28946,V_28947,V_29014,V_29015,V_29016,V_29018,V_29019,V_31965,V_31966,V_34274,V_101228,V_101235,V_101237,V_101240,V_101241,V_101600,V_101601,V_103060,V_104901';
                          // V_387,V_391,V_1547,V_1608,V_2230,V_2231,V_2232,V_2261,V_2262,V_2263,V_2265,V_2267,V_2268,V_2269,V_2270,V_2271,V_2273,V_2274,V_2275,V_2276,V_2277,V_2278,V_2279,V_2280,V_2282,V_2283,V_2285,V_2286,V_2287,V_2418,V_2498,V_2548,V_2592,V_3230,V_3280,V_3328,V_3329,V_3330,V_3481,V_3482,V_3483,V_3487,V_3488,V_3491,V_3492,V_3911,V_28065,V_28660,V_28661,V_28662,V_28755,V_28756,V_28757,V_28758,V_28759,V_28760,V_28761,V_28762,V_28763,V_28764,V_28765,V_28766,V_29006,V_29007,V_29008,V_29009,V_29010,V_29011,V_29012,V_29013,V_29065,V_29197,V_29293,V_29294,V_3304,V_29792,V_29793,V_29818,V_1259,V_757,V_762,V_764,V_28051
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IFamily[] = new Array<IFamily>();

  cadastroNo = -1; // 2243;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnfJson: ConfigJsonService,
    protected log: LogService,
    protected lib: LibService,
  ) { }

  getAll(cadastroNo: number, startDate: Date = null, endDate: Date = null): Observable<IFamily> {
    try {
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      this.cadastroNo = cadastroNo;
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IFamily> {
    if (
      !this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial) ||
      !this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal) ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastro(cadastroNo, this.lstVariaveis).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Mapeia para o objeto principal. */
  protected toAttribute(c: any): IFamily {
    try {
      const family = {
        ocorrenciaNo: c.OcorrenciaNo,
        nomeHospede: c.V_387,
        ruaAv: c.V_394,
        complemento: c.V_395,
        numero: c.V_396,
        bairro: c.V_397,
        estado: c.V_399,
        idade: c.V_402,
        sexo: c.V_405,
        cEP: c.V_2193,
        receberNotificacoesPorSMS: this.lib.getBoolean(c.V_2197),
        receberNotificacoesPormail: this.lib.getBoolean(c.V_2198),
        cidade: c.V_2614,
        telefoneFixo: c.V_3292,
        celular: c.V_3293,
        endereco: c.V_3335,
        prontuarioHosp: c.V_28051,
        codigo: c.V_28936,
        titulo: c.V_28937,
        habilitado: this.lib.getBoolean(c.V_28938),
        nome: c.V_28942,
        parentesco: c.V_28943,
        status: c.V_28944,
        descreva: c.V_28945,
        status2: c.V_28946,
        eMail: c.V_28947,
        rG: c.V_29014,
        cPF: c.V_29015,
        observacoes: c.V_29016,
        endereco2: c.V_29018,
        identificacao: c.V_29019,
        tipoRG: c.V_31965,
        uF: c.V_31966,
        familiarReferencia: this.lib.getBoolean(c.V_34274),
        enderecoRespInternacao: c.V_101228,
        cURADOR: c.V_101235,
        rESPINTERN: c.V_101237,
        rESPINTERN2: c.V_101240,
        rESPINTERN3: c.V_101241,
        motivo: c.V_101600,
        visitaProibida: this.lib.getBoolean(c.V_101601),
        loginFamiliar: c.V_103060,
        testeCampoCheckbox: c.V_104901,
      } as IFamily;
      return family;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  protected getFromCadastroFiltro(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${familyId}` */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IFamily> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  protected getWithCacheFilter(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<IFamily> {
    if (
      startDate.getTime() !== this.currentDtInicial.getTime() ||
      endDate.getTime() !== this.currentDtFinal.getTime() ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastroFiltro(cadastroNo, filtro, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save(family: IFamily, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(family).filter((f) => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(family: IFamily): IForm[] {
    try {
      return [
        { VariavelNo: 387, ValorDado: family.nomeHospede || '' },
        { VariavelNo: 394, ValorDado: family.ruaAv || '' },
        { VariavelNo: 395, ValorDado: family.complemento || '' },
        { VariavelNo: 396, ValorDado: family.numero || '' },
        { VariavelNo: 397, ValorDado: family.bairro || '' },
        { VariavelNo: 399, ValorDado: family.estado || '' },
        { VariavelNo: 402, ValorDado: family.idade || '' },
        { VariavelNo: 405, ValorDado: family.sexo || '' },
        { VariavelNo: 2193, ValorDado: family.cEP || '' },
        { VariavelNo: 2197, ValorDado: family.receberNotificacoesPorSMS ? 'SIM' : 'NÃO' },
        { VariavelNo: 2198, ValorDado: family.receberNotificacoesPormail ? 'SIM' : 'NÃO' },
        { VariavelNo: 2614, ValorDado: family.cidade || '' },
        { VariavelNo: 3292, ValorDado: family.telefoneFixo || '' },
        { VariavelNo: 3293, ValorDado: family.celular || '' },
        { VariavelNo: 3335, ValorDado: family.endereco || '' },
        { VariavelNo: 28051, ValorDado: family.prontuarioHosp || '' },
        { VariavelNo: 28936, ValorDado: family.codigo || '' },
        { VariavelNo: 28937, ValorDado: family.titulo || '' },
        { VariavelNo: 28938, ValorDado: family.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 28942, ValorDado: family.nome || '' },
        { VariavelNo: 28943, ValorDado: family.parentesco || '' },
        { VariavelNo: 28944, ValorDado: family.status || '' },
        { VariavelNo: 28945, ValorDado: family.descreva || '' },
        { VariavelNo: 28946, ValorDado: family.status2 || '' },
        { VariavelNo: 28947, ValorDado: family.eMail || '' },
        { VariavelNo: 29014, ValorDado: family.rG || '' },
        { VariavelNo: 29015, ValorDado: family.cPF || '' },
        { VariavelNo: 29016, ValorDado: family.observacoes || '' },
        { VariavelNo: 29018, ValorDado: family.endereco2 || '' },
        { VariavelNo: 29019, ValorDado: family.identificacao || '' },
        { VariavelNo: 31965, ValorDado: family.tipoRG || '' },
        { VariavelNo: 31966, ValorDado: family.uF || '' },
        { VariavelNo: 34274, ValorDado: family.familiarReferencia ? 'SIM' : 'NÃO' },
        { VariavelNo: 101228, ValorDado: family.enderecoRespInternacao || '' },
        { VariavelNo: 101235, ValorDado: family.cURADOR || '' },
        { VariavelNo: 101237, ValorDado: family.rESPINTERN || '' },
        { VariavelNo: 101240, ValorDado: family.rESPINTERN2 || '' },
        { VariavelNo: 101241, ValorDado: family.rESPINTERN3 || '' },
        { VariavelNo: 101600, ValorDado: family.motivo || '' },
        { VariavelNo: 101601, ValorDado: family.visitaProibida ? 'SIM' : 'NÃO' },
        { VariavelNo: 103060, ValorDado: family.loginFamiliar || '' },
        { VariavelNo: 104901, ValorDado: family.testeCampoCheckbox || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }


}
