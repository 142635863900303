import { EnClipType } from './../enum/en-clip-type';
export interface ISmartListImage<T = any> {
    imgUrl: string;
    enClipType: EnClipType;
    topLeft: string;
    bottomLeft: string;
    iconName: string;
    iconColor: string;
    id: number;
    obj: T;
}