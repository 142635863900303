import { of, forkJoin } from 'rxjs';
import { IAppMedlogicState, IExame } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ExameCustomService, ExameResultadoCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { ExameActionTypes, fetchErrorExame, fetchSuccessExame } from './exame.actions';
import { fetchSuccessExameResultado, fetchErrorExameResultado } from '../../state-exame-resultado/+state/exame-resultado.actions';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';

@Injectable()
export class ExameEffects {

  constructor(
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private exameSrv: ExameCustomService,
    private exameResultadoSrv: ExameResultadoCustomService,
  ) { }

  loadExames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExameActionTypes.LoadExames),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.exameSrv.getAll(
          state?.tenant?.selectedTenant?.exameAno
        );
      }),
      switchMap((exames: IExame[]) => [
        exames
          ? fetchSuccessExame({ exames })
          : fetchErrorExame(null),
      ]),
      catchError((e: any) => {
        console.log(e);
        return of(fetchErrorExame(null));
      })
    )
  );

  /* Foi necessário um método unificado, pois
  * a montagem da matriz de exames (cells) depende
  * de ambos os estados preenchidos. */
  loadExamesAndResultados$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExameActionTypes.LoadExamesAndResultados),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return forkJoin([
          this.exameSrv.getAll(
            state?.tenant?.selectedTenant?.exameAno
          ),
          this.exameResultadoSrv.getByPerson(
            state?.tenant?.selectedTenant?.exameResultadoAno,
            state?.person?.person?.codigo
          )
        ]);
      }),
      switchMap(([exames, resultados]) => [
        exames
          ? fetchSuccessExame({ exames })
          : fetchErrorExame(null),
        resultados
          ? fetchSuccessExameResultado({ resultados })
          : fetchErrorExameResultado({ resultados }),
        setIsLoading({ isLoading: false })
      ]),
      catchError((e: any) => {
        console.log(e);
        return of(fetchErrorExame(null));
      })
    )
  );


}
