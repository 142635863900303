import { Injectable } from '@angular/core';
import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Subject } from 'rxjs';

interface CacheContent {
  tag: string;
  expiry: number;
  value: any;
}

// tslint:disable: max-line-length
/*
 * https://gist.githubusercontent.com/ashwin-sureshkumar/4e86617ab3757e075de160748e3ea132/raw/8a5f54bfea39193b05f59cf2fbbd274aaa4c029a/angular-cache-service.js
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
@Injectable()
export class CacheService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  readonly DEFAULT_MAX_AGE: number = 300000;

  constructor(
    private log: LogService,
    private glb: GlobalService,
    private cnf: ConfigJsonService
  ) { }

  /**
   * Gets the value from cache if the key is provided.
   * If no value exists in cache, then check if the same call exists
   * in flight, if so return the subject. If not create a new
   * Subject inFlightObservable and return the source observable.
   */
  get(key: string, fallback?: Observable<any>, maxAge?: number): Observable<any> | Subject<any> {
    try {
      if (this.hasValidCachedValue(key)) {
        if (this.cnf.isDebug) {
          console.log(`%cGetting from cache ${key}`, 'color: green');
        }
        return of(this.cache.get(key).value);
      }
      if (!maxAge) {
        maxAge = this.DEFAULT_MAX_AGE;
      }
      if (this.inFlightObservables.has(key)) {
        return this.inFlightObservables.get(key);
      } else if (fallback && fallback instanceof Observable) {
        this.inFlightObservables.set(key, new Subject());
        // console.log(`%c Calling api for ${key}`, 'color: purple');
        const arTagGroup = key.split('___');
        const tagGroup = (arTagGroup.length > 1) ? arTagGroup[0] : null;
        return fallback.pipe(tap((value) => { this.set(key, value, tagGroup, maxAge); }));
      } else {
        // console.log('Requested key is not available in Cache');
        // return Observable.throw('Requested key is not available in Cache');
        // return empty();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'get', error.message);
    }
    return of(null);
  }

  /* Remove todas as chaves armazenadas no cache. */
  removeAll(): boolean {
    try {
      this.cache.clear();
      return true;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'removeAll', error.message);
    }
    return false;
  }

  /* Remove uma chave específica. */
  remove(key: string): boolean {
    try {
      return this.cache.delete(key);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'remove', error.message);
    }
    return false;
  }

  /* Remove uma todas as chaves de um tagGroup específico. */
  removeByTag(tagGroup: string): boolean {
    try {
      this.cache.forEach((value: CacheContent, key: string) => {
        if (this.glb.isEqual(value.tag, tagGroup)) {
          this.cache.delete(key);
        }
      });
      return true;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'removeTag', error.message);
    }
    return false;
  }

  /**
   * Sets the value with key in the cache
   * Notifies all observers of the new value
   * tagGroup: is an identifier that will be added to the begginning of the key and make it possible to delete a group os keys by the tag
   * está convencionado em ser o nome do método
   */
  set(key: string, value: any, tagGroup: string = null, maxAge: number = this.DEFAULT_MAX_AGE): void {
    try {
      this.cache.set(key, { value, expiry: Date.now() + maxAge, tag: tagGroup });
      this.notifyInFlightObservers(key, value);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'set', error.message);
    }
  }

  /**
   * Checks if the a key exists in cache
   */
  has(key: string): boolean {
    try {
      return this.cache.has(key);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'has', error.message);
    }
    return false;
  }

  /**
   * Publishes the value to all observers of the given
   * in progress observables if observers exist.
   */
  private notifyInFlightObservers(key: string, value: any): void {
    try {
      if (this.inFlightObservables.has(key)) {
        const inFlight = this.inFlightObservables.get(key);
        const observersCount = inFlight.observers.length;
        if (observersCount) {
          // console.log(`%cNotifying ${inFlight.observers.length} flight subscribers for ${key}`, 'color: blue');
          inFlight.next(value);
        }
        inFlight.complete();
        this.inFlightObservables.delete(key);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'notifyInFlightObservers', error.message);
    }
  }

  /**
   * Checks if the key exists and   has not expired.
   */
  private hasValidCachedValue(key: string): boolean {
    try {
      if (this.cache.has(key)) {
        if (this.cache.get(key).expiry < Date.now()) {
          this.cache.delete(key);
          return false;
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasValidCachedValue', error.message);
    }
    return false;
  }


}
