export class EnumAtividadeTipo {

    // Constants:
    public static Consultar: number = 1;
    public static Editar: number = 2;
    public static Criar: number = 3;
    public static CriarSubsequente: number = 4; //Criação de uma atividade subsequente, para o mesmo usuário
    public static FinalizacaoSubsequente: number = 5; //Chamará uma atividade subsequente
    public static FinalizacaoImprimir: number = 6; //Chamará a tela de visualização de relatórios
    public static FinalizacaoProximo: number = 7; //Navegará para a próxima tarefa subsequente
    public static FinalizacaoAnterior: number = 8;//Navegará para a tarefa subsequente anterior
    public static FinalizacaoVoltar: number = 9;//Apenas retornará, ou para a seleção de sub ou agenda
    public static SalvarPermanecer: number = 10;//Apenas salvará os dados, mas ficará na mesma atividade
    public static CriarCadastro: number = 11;//Criará dados para Atividade do Tipo Cadastro
    public static EditarCadastro: number = 12;//Editará dados para Atividade do Tipo Cadastro
    public static FinalizacaoSubsequenteAutomatica: number = 13; //Chamará uma atividade subsequente que é uma atividade AUTOMÁTICA
    public static CriarSemGerarOcorrencia: number = 14; //Gera a atividade mas não cria a ocorrência
    public static SalvarENovo: number = 15; //Atividade Cadastro: Salva os dados e cria novo
    public static MultiplosPassos: number = 16; //MultiplosPassos mapeado do Serviço
    public static WebApiRest: number = 17; //WebApiRest mapeado do Serviço
    public static CadastroPesquisa: number = 18; //CadastroPesquisa mapeado do Serviço
    public static CadastroPesquisaEditar: number = 19; //CadastroPesquisa mapeado do Serviço


    // Public Properties:
    // Private Properties:

    // Initialization:
    constructor() {
    }

    public static toString(_valor: number): string {
        switch (_valor) {
            case 1:
                return "Consultar";
            case 2:
                return "Editar";
            case 3:
                return "Criar";
            case 4:
                return "CriarSubsequente";
            case 5:
                return "FinalizacaoSubsequente";
            case 6:
                return "FinalizacaoImprimir";
            case 7:
                return "FinalizacaoProximo";
            case 8:
                return "FinalizacaoAnterior";
            case 9:
                return "FinalizacaoVoltar";
            case 10:
                return "SalvarPermanecer";
            case 11:
                return "CriarCadastro";
            case 12:
                return "EditarCadastro";
            case 13:
                return "FinalizacaoSubsequenteAutomatica";
            case 14:
                return "CriarSemGerarOcorrencia";
            case 15:
                return "SalvarENovo";
            case 16:
                return "MultiplosPassos";
            case 17:
                return "WebApiRest";
            case 18:
                return "CadastroPesquisa";
            case 19:
                return "CadastroPesquisaEditar";
        }


        return "Tipo inválido!";
    }
    // Public Methods:
    // public static toEnumAtividadeModo(_enAtividadeTipo: number): number {
    //     switch (_enAtividadeTipo) {
    //         case 1:
    //             return EnumAtividadeModo.Consultar;
    //         case 2:
    //         case 12:
    //         case 19:
    //             return EnumAtividadeModo.Editar;
    //         case 3:
    //         case 11:
    //         case 14:
    //         case 18:
    //             return EnumAtividadeModo.Nova;
    //         case 4:
    //             return EnumAtividadeModo.NovaSubsequente;
    //     }
    //     return -1;//Tipo Inválido
    // }
    // Protected Methods:
}