import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IScheduledPeriodState } from './scheduledPeriod-state';

import * as fromScheduledPeriod from './scheduledPeriod.reducer';

export const selectScheduledPeriodState = createFeatureSelector<IScheduledPeriodState>(fromScheduledPeriod.scheduledPeriodsFeatureKey);
export const selectScheduledPeriodAll = createSelector(selectScheduledPeriodState, fromScheduledPeriod.selectAll);

// export const selectScheduledPeriodAllActive = createSelector(
//   selectScheduledPeriodAll, selectScheduledPeriodAll,
//   scheduledPeriods => scheduledPeriods,
//   activityDetails => activityDetails
//     .filter(scheduledPeriods => scheduledPeriods.situacaoscheduledPeriod === activityDetails.situacaoscheduledPeriod)
//     //.filter(scheduledPeriod => scheduledPeriod.situacaoScheduledPediod /*&& !scheduledPeriod.pacienteFalecido*/)
//     .sort((a, b) => a?.activityStatusIdentifier?.localeCompare(b?.activityStatusIdentifier))
// );

export const selectedScheduledPeriod = createSelector(selectScheduledPeriodState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const isScheduledPeriodLoading = createSelector(
  selectScheduledPeriodAll,
  scheduledPeriods => scheduledPeriods?.length <= 0,
);

export const selectedScheduledPeriodIdentifier = createSelector(selectScheduledPeriodState, state => {
  return (state?.selectedId || 0) > 0 ? state?.selectedId : null;
});
