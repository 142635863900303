import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigStateService } from './service/config-state.service';

const MODULES = [
  CommonModule
];

const SERVICES = [
  ConfigStateService
];

@NgModule({
  imports: [MODULES],
  providers: [SERVICES]
})
export class StateConfigModule { }
