import { of } from 'rxjs';
import { IAppMedlogicState, IFrailty } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { FrailtyCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { FrailtyActionTypes, fetchErrorFrailty, fetchSuccessFrailty } from './frailty.actions';


@Injectable()
export class FrailtyEffects {

  constructor(
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private frailtySrv: FrailtyCustomService,
  ) { }

  loadFrailtys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FrailtyActionTypes.LoadFrailtys),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.frailtySrv.getAll(
          state?.tenant?.selectedTenant?.frailtyAno
        );
      }),
      switchMap((frailtys: IFrailty[]) => [
        frailtys
          ? fetchSuccessFrailty({ frailtys })
          : fetchErrorFrailty(null),
      ]),
      catchError((e: any) => {
        console.log(e);
        return of(fetchErrorFrailty(null));
      })
    )
  );



}
