import { LibService } from './lib.service';
import { CalculadoraConditionService } from './calculadora-condition.service';
import { IValidator } from '../interface/ivalidator';
import { AbstractControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { GlobalService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { InputMaskType } from '../enum/input-mask-type';

@Injectable()
export class ValidatorService {
  constructor(
    private global: GlobalService,
    private calcCond: CalculadoraConditionService,
    private log: LogService,
    private lib: LibService
  ) { }

  /*Retorna um Array com todos os validadores aplicáveis ao controle
   * O validador é configurado inicialmente, no formBuild, mas também
   * deve ser reaplicado no change, pois, as condições dos controles mudam.
   * No evento de change, em AtividadeComponent.setFormControlValues o valor
   * do ctrl.IsVisible é recalculado.
   * Controles invisíveis não devem ser validados.
   * Somente leitura deve ser validado, pois, o valor pode ser modificado por fórmula.
  */
  getValidators(ctrl: IAtividadeComponenteDAL): IValidator[] {
    const validators = new Array<IValidator>();
    const isValidationCond = this.lib.isCondition(ctrl?.Validation);
    let name = 'libVisibleAndRequired';
    try {
      if (ctrl?.RequiredField) {
        // A propriedade isEnable se refere a somente leitura. Apesar do usuário não poder modificar, o valor pode ser alterado por fórmula.
        // Validação de campo obrigatório
        validators.push({
          name,
          validator: (control: AbstractControl) => this.libVisibleAndRequired(control, ctrl?.IsVisible),
          message: `O campo ${ctrl?.Rotulo} é obrigatório!`,
          id: this.lib.getId(ctrl?.VariavelNo)
        });
        // Validação de número máximo de caracteres
        switch (ctrl?.Type.toUpperCase()) {
          case this.lib.CTRMULTILINETEXTBOXHTMLLABELED:
          case this.lib.CTRTEXTBOXLABELED:
            validators.push({
              name: 'maxlength',
              validator: Validators.maxLength(ctrl?.MaxLength),
              message: `Esse campo deve conter no máximo ${ctrl?.MaxLength} caracteres.`,
              id: this.lib.getId(ctrl?.VariavelNo)
            });
            break;
        }
      }
      // Validação personalizada, baseada em fórmula
      if (isValidationCond && ctrl?.IsVisible) {
        name = 'libVisibleAndRequired';
        validators.push({
          name,
          validator: (control: AbstractControl) => this.conditionalValidation(control, ctrl, name),
          message: ctrl?.ValidationMessage,
          id: this.lib.getId(ctrl?.VariavelNo)
        });
      }
      // Validação de CPF/CNPJ
      switch (InputMaskType.toEnum(ctrl?.InputMaskType)) {
        case InputMaskType.CPF:
          name = 'cpf';
          validators.push({
            name,
            validator: (control: AbstractControl) => this.validarCpf(control, name),
            message: ctrl?.ValidationMessage,
            id: this.lib.getId(ctrl?.VariavelNo)
          });
          break;
        case InputMaskType.CNPJ:
          name = 'cnpj';
          validators.push({
            name,
            validator: (control: AbstractControl) => this.validarCnpj(control, name),
            message: ctrl?.ValidationMessage,
            id: this.lib.getId(ctrl?.VariavelNo)
          });
          break;
      }
    } catch (error) {
      console.log(this.constructor.name, 'getValidators', error.message);
    }
    return validators;
  }

  /*Realiza uma validação de campo não informado, vazio, mas desconsidera campo invisível.  */
  libVisibleAndRequired(control: AbstractControl, isVisible: boolean): any {
    try {
      // Desconsiderar os controles invisíveis
      if (!control?.enabled || !isVisible) { return null; }
      // Valida se é não informado
      if (this.global.IsNullOrEmptyGE(control?.value, true)) {
        return { libVisibleAndRequired: { name } };
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'libVisibleAndRequired', error.message);
    }
    return null;
  }

  /*Validação condicional baseada em fórmula */
  conditionalValidation(control: AbstractControl, ctrl: IAtividadeComponenteDAL, name: string): any {
    try {
      if (!this.lib.isCondition(ctrl?.Validation)) { return null; }
      const evalute = this.calcCond.calculate(
        ctrl,
        ctrl?.lstControlesReferenciadosCustomValidation,
        ctrl?.Validation
      );
      if (evalute) {
        return null;
      } else {
        return { conditionalValidation: { name } };
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'conditionalValidation', error.message);
    }
    return null;
  }

  validarCpf(ctrl: AbstractControl, name: string): any {
    try {
      const evalute = this.global.validarCPF(ctrl?.value);
      if (evalute) {
        return null;
      } else {
        return { conditionalValidation: { name } };
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'validarCpf', error.message);
    }
    return null;
  }

  validarCnpj(ctrl: AbstractControl, name: string): any {
    try {
      const evalute = this.global.validarCNPJ(ctrl?.value);
      if (evalute) {
        return null;
      } else {
        return { conditionalValidation: { name } };
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'validarCnpj', error.message);
    }
    return null;
  }

  /*Retorna um array de erros inicialmente vazio */
  getFormErrors(componentes: IAtividadeComponenteDAL[]): any {
    try {
      const errors = {};
      try {
        componentes.forEach((c) => {
          try {
            errors[this.lib.getId(c.VariavelNo)] = '';
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'getFormsErrors.forEach', error.message);
          }
        });
      } catch (error) {
        this.log.Registrar(this.constructor.name, 'getFormErrors', error.message);
      }
      return errors;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFormErrors', error.message);
    }
  }

  /*retorna a lista de mensagens de erro, para cada tipo de validação, para cada componente */
  getValidationMessages(componentes: IAtividadeComponenteDAL[]): any {
    const validations = {};
    try {
      componentes?.forEach((c) => {
        try {
          validations[this.lib.getId(c.VariavelNo)] = this.getValidators(c)
            .filter((f) => f.id === this.lib.getId(c.VariavelNo))
            .map((m) => {
              const obj = {};
              obj[m.name] = m.message;
              return obj;
            });
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getValidationMessages.forEach', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getValidationMessages', error.message);
    }
    return validations;
  }
}
