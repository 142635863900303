import { Injectable } from '@angular/core';
import { routeCarePlan } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';


@Injectable({ providedIn: 'root' })
export class FhirCarePlanService extends FhirGenericService {

  protected url = routeCarePlan;

}
