import { LogService } from '@medlogic/shared/shared-interfaces';
import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appGrowth]',
  host: {
    '(click)': 'onClick()',
    // '(mouseenter)': 'onMouseEnter()',
    // '(mouseleave)': 'onMouseLeave()'
  }
})
export class GrowthDirective {

  private el: HTMLElement;
  private _defaultFontSize = 'initial';
  private _growthFontSize = 'x-large';

  constructor(el: ElementRef,
    private log: LogService) { this.el = el.nativeElement; }

  @Input('appGrowth') fontSize: string;

  // onMouseEnter() { this.growth(this.fontSize || this._growthFontSize); }
  // onMouseLeave() { this.growth(this._defaultFontSize); }

  onClick() {
    try {
      let fontSize: string = this.fontSize || this._growthFontSize;
      fontSize = this.el.style.fontSize === fontSize ? this._defaultFontSize : fontSize;
      this.growth(fontSize);
    } catch (_error) {
      this.log.Registrar(this.constructor.name, 'onClick', _error.message);
    }
  }

  private growth(fontSize: string) {
    try {
      this.el.style.fontSize = fontSize;
    } catch (_error) {
      this.log.Registrar(this.constructor.name, 'growth', _error.message);
    }
  }


}
