import { GlobalService } from '@medlogic/shared/shared-interfaces';

type Writable<T> = { -readonly [k in keyof T]: T[k] };

const glb = new GlobalService();

export function update<T>(
  currentItems: T[],
  updateItem: T,
  keyProp: string = 'ocorrenciaNo',
  guid: string | number = null
): T[] {
  const updatedItems = [...currentItems.map((m) => ({ ...m } as Writable<T>))];
  const idx = updatedItems?.findIndex((f) =>
    guid && glb.isGUID(guid.toString())
      ? f[keyProp] === guid
      : +f[keyProp] === +updateItem[keyProp]
  );
  if (idx >= 0) {
    Object.assign(updatedItems[idx], updateItem);
  } else {
    updatedItems.push(updateItem);
  }
  return updatedItems;
}

export function deleteById<T>(
  currentItems: T[],
  id: number,
  keyProp: string = 'ocorrenciaNo'
): T[] {
  const updatedItems = [...currentItems.map((m) => ({ ...m } as Writable<T>))];
  const idx = updatedItems?.findIndex((f) => +f[keyProp] === +id);
  if (idx >= 0) {
    updatedItems.splice(idx, 1);
  }
  return updatedItems;
}

export function crc32(r: string) {
  for (var a, o = [], c = 0; c < 256; c++) {
    a = c;
    for (var f = 0; f < 8; f++) a = 1 & a ? 3988292384 ^ (a >>> 1) : a >>> 1;
    o[c] = a;
  }
  for (var n = -1, t = 0; t < r.length; t++)
    n = (n >>> 8) ^ o[255 & (n ^ r.charCodeAt(t))];
  return (-1 ^ n) >>> 0;
};
