<div [formGroup]="formGroup" class="form-group" [class.has-error]="!isValid(formGroup, ctrl)" [libCtrConfigUI]="ctrl"
  [style.width]="getWidth(ctrl)">

  <lib-title-label [formGroup]="formGroup" [enTheme]="enTheme" [isMobile]="isMobile" [ctrl]="ctrl" #lblTitle>
  </lib-title-label>
  <!-- <p-rating iconCancelClass="pi pi-times"
    [formControlName]="getId(ctrl)" stars="5" (onRate)="onRate(ctrl.VariavelNo, rating.value)" #rating [libCtrConfigUI]="ctrl"
    [altura]="ALTURACONTROLE" [tabIndex]="getTabIndex(ctrl)"></p-rating> -->

  <!-- <lib-rating (ratingClick)="onRate(ctrl.VariavelNo, rating.value)" #rating [libCtrConfigUI]="ctrl"
    [altura]="ALTURACONTROLE" [tabIndex]="getTabIndex(ctrl)"></lib-rating> -->
  <bar-rating [id]="getId(ctrl)" [(rate)]="rate" [theme]="'stars'" [max]="5" #rating [libCtrConfigUI]="ctrl"
  [altura]="ctrl?.Altura" [tabIndex]="getTabIndex(ctrl)" [readOnly]="isReadOnly(ctrl)"
    [style.width]="getWidth(ctrl)" [titles]="['Péssimo', 'Ruim' , 'Regular', 'Bom', 'Excelente']" [showText]="false"
    class="br"></bar-rating>

</div>