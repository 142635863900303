import { EnActivityType } from "../../enum/fhir/en-activity-type.enum";
import { EnFieldType } from "../../enum/fhir/en-field-type.enum";

export interface IField {
    activityType: EnActivityType;
    fieldType: EnFieldType;
    label: string;
    description: string;
    fieldName: string;
    options?: { [key: string]: string };
    value: any | null;
    conditionalVisibility?: Array<{
      fieldName: string;
      value: any;
    }>;
  }