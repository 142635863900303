import { Injectable } from '@angular/core';
import { IHistoricoCompraEstoque } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { HistoricoCompraEstoqueService } from './historico-compra-estoque.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoricoCompraEstoqueCustomService extends HistoricoCompraEstoqueService {

  /* Retorna todos os itens do histórico de compras através do id do item. */
  getById(ano: number, estoqueMateriaisId: number): Observable<IHistoricoCompraEstoque> {
    try {
      this.cadastroNo = ano;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const filter = `V_27987:${estoqueMateriaisId}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getById', error.message);
    }
    return of(null);
  }


}
