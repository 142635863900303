import { ConfigStateService } from '@medlogic/shared/state-config';
import { Directive, Input, ElementRef, Renderer2, AfterViewChecked } from '@angular/core';
import { GlobalService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../service/lib.service';

// ATENÇÃO: A POSIÇÃO QUE A DIRETIVA É APLICADA NO HTML REPRESENTA A ORDEM QUE SERÁ PROCESSADA.
// NESSE CASO, DEVE SER APLICADA SOMENTE APÓS O CALCULO DO VALOR, SENÃO, TENTA PEGAR A FÓRMULA DA CONDIÇÃO, POIS, NÃO FOI AINDA PROCESSADA.
// ATENÇÃO, ATENÇÃO: O uso desse controle como diretiva promove o recalculo
//  perpétuo das fórmulas que gera lentidão nas telas. Ao invés disso,
// A visibilidade deve ser calculada na inicialização e no
// change dos controles. Nunca como diretiva, ou ao menos que a diretiva não renderize fórmulas.
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[libCtrlIsVisible]'
})
export class CtrlIsVisibleDirective implements AfterViewChecked {
  // private MAX_LINHA: number = 450;
  // private MAX_COLUNA: number = 950;

  // tslint:disable-next-line: variable-name
  private _ctrl: any;
  @Input('libCtrlIsVisible')
  public get ctrl(): any {
    return this._ctrl;
  }

  public set ctrl(v: any) {
    this._ctrl = v;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private global: GlobalService,
    private cnf: ConfigStateService,
    private log: LogService,
    private lib: LibService
  ) { }

  // Para resolver o problema do - na atualização, procurar um evento que não seja o Init
  // ngAfterViewInit(): void {
  //   // this.applyStyles(this.ctrl);
  // }

  /*Como ctrl não é alterado e sim um valor da propriedade, o angular considera que não houve mudança
   * portanto, é necessário criar uma rotina de checagem da mudança personalizada/
   * Esse método é chamado sempre. Depois investigar métodos mais eficientes, pois, será chamado todas as vezes para todos os controles
   * Uma alternativa seria fazer com que o ctrl fosse associado a outro objeto, toda vez que houvesse o OnChanges
   * Nesse caso, seria possível capturar o ngOnChanges apenas o controle que mudou
  */
  ngAfterViewChecked(): void {
    // ngDoCheck
    try {
      this.applyStyles(this.ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngAfterViewChecked', error.message);
    }
  }

  /*Aplica o estilo que modifica a visibilidade do controle. No entanto,
  // se o hash de modificação da lista de controles relacionados a visibilidade,
   * existir e não houver modificação em nenhum dos valores, então, fará nada.
   */
  applyStyles(ctrl: IAtividadeComponenteDAL): void {
    try {
      // if (
      // 	this.calc.wasChanged(
      // 		ctrl.hashLstControlesReferenciadosVisibility,
      // 		ctrl.lstControlesReferenciadosVisibility
      // 	)
      // ) {
      //   ctrl.hashLstControlesReferenciadosVisibility = this.calc.getHash(ctrl.lstControlesReferenciadosVisibility);
      // TODO: A lógica foi transferida para o calculadora-condition para que sequer tente recalcular a fórmula
      this.applyIsVisible(ctrl);
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyStyles', error.message);
    }
  }

  /*Aplica o estilo que modifica a visibilidade do controle.
   */
  applyIsVisible(ctrl: IAtividadeComponenteDAL): void {
    try {
      // // const isVisible = this.calc.isVisibleCtrl(ctrl);
      // const isVisible = ctrl.IsVisible;
      // // Se o controle estiver numa posição fora da área útil da Atividade, também não será exibido
      // // isVisible = isVisible && (ctrl.Linha <= this.MAX_LINHA && ctrl.Coluna <= this.MAX_COLUNA);
      // const showInMobile = this.global.isMobile() ? ctrl.ShowInMobile : true;
      // if ((isVisible && showInMobile) || this.cnf.showInvisible) {
      if (this.lib.isCtrlVisible(ctrl)) {
        // this.renderer.setStyle(this.el.nativeElement, 'visibility', 'visible');
        this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
      } else {
        // this.renderer.setStyle(this.el.nativeElement, 'visibility', 'hidden');
        // TODO: Checar se interfere nas fórmulas.
        // display none é necessário para a remoção do elemento do DOM.
        this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyIsVisible', error.message);
    }
  }
}
