import { ConfigStateService } from '@medlogic/shared/state-config';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { CalculadoraConditionService } from '../shared/service/calculadora-condition.service';
import { LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[libZeraMargin]'
})
export class ZeraMarginDirective implements OnInit {

  private el: HTMLElement;
  private topMargin = 500;

  constructor(
    el: ElementRef,
    private log: LogService,
    private cnf: ConfigStateService,
    private calc: CalculadoraConditionService
  ) { this.el = el.nativeElement; }

  @Input('libZeraMargin') componentes: Array<IAtividadeComponenteDAL>;
  @Input() isMobile: boolean;

  ngOnInit(): void {
    try {
      // if (this.isMobile) {
      this.el.style.padding = '0';
      this.el.style.margin = '0';
      this.el.style.fontSize = '16px';
      const len = this.el.children.length;
      if (this.el.children && len > 0) {
        for (let i = 0; i < len; i++) {
          const c = this.el.children.item(i);
          // c.style.padding = '0';
          // c.style.margin = '0';
          // c.style.fontSize = '16px';
        }
      }
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

}
