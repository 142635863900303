
import { refCount, publishReplay, map, filter, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IProfissional } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class ProfissionalService {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_101671,V_101672,V_101674,V_101675,V_105036,V_105037,V_105038,V_105039,V_105042,V_105043,V_105044,V_105045,V_105046,V_105047,V_105051,V_105052,V_105082,V_105428,V_108351,V_108352,V_108353,V_108361,V_108362,V_108367,V_108375,V_109280';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: Array<IProfissional> = new Array<IProfissional>();

  cadastroNo = 24659;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IProfissional> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IProfissional> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IProfissional {
    try {
      return  {
        ocorrenciaNo: c.OcorrenciaNo,
eMail: c.V_101671,
dataNascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_101672),
nomeCompleto: c.V_101674,
contatosFones: c.V_101675,
enderecoCompleto: c.V_105036,
cep: c.V_105037,
bairro: c.V_105038,
cidade: c.V_105039,
nomeMae: c.V_105042,
nomePai: c.V_105043,
naturalidade: c.V_105044,
nFilhos: c.V_105045,
nDependentes: c.V_105046,
grauInstrucao: c.V_105047,
cargoFuncao: c.V_105051,
dRT: c.V_105052,
numeroConselho: c.V_105082,
loginProfissional: c.V_105428,
codigo: c.V_108351,
titulo: c.V_108352,
habilitado: this.glb.getBoolean(c.V_108353),
religiao: c.V_108361,
estadoCivil: c.V_108362,
estado: c.V_108367,
dtNasc: c.V_108375,
executor: c.V_109280,

     } as IProfissional;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(movimentacao: IProfissional, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(profissional: IProfissional): Array<IForm> {
    try {
        return  [
          { VariavelNo: 101671, ValorDado: profissional.eMail || '' },
{ VariavelNo: 101672, ValorDado: this.glb.ddMMYYYYThhmmssToDate(profissional.dataNascimento) },
{ VariavelNo: 101674, ValorDado: profissional.nomeCompleto || '' },
{ VariavelNo: 101675, ValorDado: profissional.contatosFones || '' },
{ VariavelNo: 105036, ValorDado: profissional.enderecoCompleto || '' },
{ VariavelNo: 105037, ValorDado: profissional.cep || '' },
{ VariavelNo: 105038, ValorDado: profissional.bairro || '' },
{ VariavelNo: 105039, ValorDado: profissional.cidade || '' },
{ VariavelNo: 105042, ValorDado: profissional.nomeMae || '' },
{ VariavelNo: 105043, ValorDado: profissional.nomePai || '' },
{ VariavelNo: 105044, ValorDado: profissional.naturalidade || '' },
{ VariavelNo: 105045, ValorDado: profissional.nFilhos || '' },
{ VariavelNo: 105046, ValorDado: profissional.nDependentes || '' },
{ VariavelNo: 105047, ValorDado: profissional.grauInstrucao || '' },
{ VariavelNo: 105051, ValorDado: profissional.cargoFuncao || '' },
{ VariavelNo: 105052, ValorDado: profissional.dRT || '' },
{ VariavelNo: 105082, ValorDado: profissional.numeroConselho || '' },
{ VariavelNo: 105428, ValorDado: profissional.loginProfissional || '' },
{ VariavelNo: 108351, ValorDado: profissional.codigo || '' },
{ VariavelNo: 108352, ValorDado: profissional.titulo || '' },
{ VariavelNo: 108353, ValorDado: profissional.habilitado ? 'SIM' : 'NÃO' },
{ VariavelNo: 108361, ValorDado: profissional.religiao || '' },
{ VariavelNo: 108362, ValorDado: profissional.estadoCivil || '' },
{ VariavelNo: 108367, ValorDado: profissional.estado || '' },
{ VariavelNo: 108375, ValorDado: profissional.dtNasc || '' },
{ VariavelNo: 109280, ValorDado: profissional.executor || '' },

       ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Retorna um título bem formatado para cada campo.  */
  getRotulo(field: string): string {
    try {
      const mapR = {
        codigoPaciente: '#NAOEXIBIR#',
        dataavaliacao: '#NAOEXIBIR#',
        codigo: '#NAOEXIBIR#',
        titulo: '#NAOEXIBIR#',
        habilitado: '#NAOEXIBIR#',
        laudo: 'Laudo',
        oCORRENCIA: '#NAOEXIBIR#',
        codPacCAT: '#NAOEXIBIR#',
        codPacOcorrenciaCAT: '#NAOEXIBIR#',
        executorAvaliacao: '#NAOEXIBIR#',
        executorMedicaoData: '#NAOEXIBIR#',
        codPacPressao: '#NAOEXIBIR#',
        cODHOSPDATA: '#NAOEXIBIR#',
        tecnicoResponsavel: 'Profissional',
        frequenciaCardiacaBpm: 'Frequência Cardíaca (BPM)',
        frequenciaRespiratoriaICP: 'Frequência Respiratória (ICP)',
        glicemiaCapilarEmJejumMlDl: '(Glicemia Capilar em Jejum (ml/dl)',
        posPrandialMlDl: 'Glicemia pós-prandial (ml/dl)',
        glicose: 'Glicose',
        pADiastolicaRef80: 'Pressão Diastólica (ref: 80)',
        pASistolicaRef120: 'Pressão Sistólica (ref: 120)',
        saturacaoOxigenioSO: 'Saturação de Oxigênio',
        temperaturaTax: 'Temperatura'
      };
      if (mapR.hasOwnProperty(field)) {
        return mapR[field];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRotulo', error.message);
    }
    return '';
  }

}
