import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'patientKeywordFilterPipe'
})
export class PatientKeywordFilterPipe implements PipeTransform {

  constructor(
    private glb: GlobalService,
    private log: LogService) { }

  /* Filtrará o paciente através do código e as medicações do paciente conforme a palavra-chave fornecida. */
  transform(items: any[], keyword: string, selectedPatientCodigo: number): any[] {
    try {
      // if (!selectedPatientCodigo && this.glb.isNullOrEmpty(keyword)) return items;
      let filtered = items
        .filter(item => {
          if (item && item.data && item.data.name) {
            return (item.id === selectedPatientCodigo);
          } else {
            return true;
          }
        });
      filtered = filtered
        .map(m => {
          if (m.children) {
            m.children = m.children.filter(f => f.data.name ? this.glb.contem(f.data.name, keyword) : true);
          }
          return m;
        });
      return filtered;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return items;
  }


}
