import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { INewApiUserRole, ICheckUser } from '@medlogic/shared/shared-interfaces';
import { INewApiLogin } from '@medlogic/shared/shared-interfaces';
import { map } from 'rxjs/operators';
import {
  LogService, routeRegister, routeUserExist,
  routeLogin, routeUserRoles, routeEnableUser,
  EnRequestType, EnContentType
} from '@medlogic/shared/shared-interfaces';
import { BasePageService } from './base-page.service';

@Injectable()
export class RegisterService {

  // urlRegister = '/Register';
  // urlUserExist = '/UserExist';
  // urlLogin = '/Login';
  // urlUserRoles = '/UserRoles';
  // urlEnableUser = '/EnableUser';
  urlRegister = routeRegister;
  urlUserExist = routeUserExist;
  urlLogin = routeLogin;
  urlUserRoles = routeUserRoles;
  urlEnableUser = routeEnableUser;

  constructor(
    private log: LogService,
    private basepage: BasePageService
  ) { }

  /* Verifica se existem usuário e e-mail.  */
  checkUserExists(userName: string, email: string): Observable<ICheckUser> {
    try {
      const url = this.basepage.format(this.urlUserExist);
      const data = { Login: userName, Email: email };
      return this.basepage.baseDados(EnRequestType.Post, url, data, 2, EnContentType.Json)
        .pipe(
          map(m => {
            return m as ICheckUser;
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'checkUserExists', error.message);
    }
    return of(null);
  }

  /* Cria no novo usuário no GE.  */
  registerUser(userLogin: string, userName: string, email: string, senha: string): Observable<boolean> {
    try {
      const url = this.basepage.format(this.urlRegister);
      const data = {
        Login: userLogin,
        Email: email,
        UserName: userName,
        Password: senha
      };
      return this.basepage.baseDados(EnRequestType.Post, url, data, 2, EnContentType.Json)
        .pipe(
          map(m => !m?.message && !!m)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'registerUser', error.message);
    }
    return of(null);
  }

  /* Valida a existência do usuário e gera o token necessário para autorizar operações nessa api.  */
  login(userName: string, password: string): Observable<INewApiLogin> {
    try {
      const url = this.basepage.format(this.urlLogin);
      const data = JSON.stringify({ Login: userName, Password: password });
      return this.basepage.baseDados(EnRequestType.Post, url, data, 2, EnContentType.Json)
        .pipe(
          map(m => m as INewApiLogin)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'login', error.message);
    }
    return of(null);
  }

  /* Retorna os papéis do usuário, realizando antes o login.  */
  getUserRoles(userName: string, email: string, password: string): Observable<INewApiUserRole> {
    try {
      // const getRoles = () => mergeMap((login: INewApiLogin) => {
      //   const url = this.basepage.format(
      //     `${this.cnfJson.baseUrlAPINew}${this.urlAuthWebApiBase}${this.urlUserRoles}`
      //   );
      //   if (login && login.token) {
      //     const headers = new HttpHeaders();
      //     headers.set('Authorization', `Bearer ${login.token}`);
      //     return this.http.post<INewApiUserRole>(url, {}, { headers });
      //   } else {
      //     return of(null);
      //   }
      // });
      // return this.login(userName, email)
      //   .pipe(
      //     getRoles(),
      //     error()
      //   );
      const url = this.basepage.format(this.urlUserRoles);
      const data = JSON.stringify({ Login: userName, Password: password });
      return this.basepage.baseDados(EnRequestType.Post, url, data, 2, EnContentType.Json)
        .pipe(
          map(m => m as INewApiUserRole)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUserRoles', error.message);
    }
    return of(null);
  }

  /* Ativa o usuário e o acrescenta a papeis complementares.  */
  enableUser(userName: string, password: string): Observable<boolean> {
    try {
      // const enableUser$ = () => mergeMap((login: INewApiLogin) => {
      //   const url = this.basepage.format(
      //     `${this.cnfJson.baseUrlAPINew}${this.urlAuthWebApiBase}${this.urlEnableUser}`
      //   );
      //   if (login && login.token) {
      //     const headers = new HttpHeaders()
      //       .append('Authorization', `Bearer ${login.token}`);
      //     return this.http.post<boolean>(url, {}, { headers });
      //   } else {
      //     return of(false);
      //   }
      // });
      // return this.login(userName, password)
      //   .pipe(
      //     enableUser$(),
      //     error()
      //   );
      const url = this.basepage.format(this.urlEnableUser);
      const data = JSON.stringify({ Login: userName, Password: password });
      return this.basepage.baseDados(EnRequestType.Post, url, data, 2, EnContentType.Json)
        .pipe(
          map(m => !!m)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'enableUser', error.message);
    }
    return of(false);
  }

  protected getHeaders(): HttpHeaders {
    try {
      const headers = new HttpHeaders({
        Accept: 'application/json, text/plain, */*; charset=utf-8',
        'Content-Type': 'application/json',
        'Data-Type': 'json'
      });
      return headers;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getHeadears', error.message);
    }
    return null;
  }


}
