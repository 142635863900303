export enum EnumFormulaElementType {
    None = -1,
    IF = 1,
    DataAdd = 2,
    DataDiff = 3,
    YO = 4,
    Number = 5,
    String = 6,
    Operator = 7,
    ServerSum = 8,
    ServerWs = 9,
    ServerWsList = 10,
    PROC = 11,
    MIN = 12,
    MAX = 13,
    OCORRENCIA = 14,
    HourAdd = 15,
    DayOfWeek = 16,
    SCRIPT = 17,
    SumHours = 18,
    MinDate = 19,
    MaxDate = 20,
    MinHour = 21,
    MaxHour = 22,
    Overtime = 23,
    Absent = 24,
    NightHour = 25,
    LastDayOfMonth = 26,
    CountSelected = 27,
    ServerGetValue = 28,
    ServerCheckExist = 29,
    WeekOfYear = 30,
    GridSum = 31,
    GridCount = 32,
    GridMean = 33,
    GridProduct = 34,
    Javascript = 35,
    Today = 36,
    FormatDateTime = 37,
    ServerGetIdentity = 38,
    CountNotes = 39,
    CountFiles = 40,
    ServerCountFilesFromUser = 41,
    ServerCountNotesFromUser = 42,
    NoteChanged = 43,
    FileChanged = 44,
    CountStr = 45,
    Left = 46,
    Right = 47,
    Middle = 48,
    GridSumIf = 49,
    GridGreater = 50,
    GridLower = 51,
    GridFirst = 52,
    GridLast = 53
}
