import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { onNavigate } from '../ngrx/actions/app.action';

@Injectable({
  providedIn: 'root'
})
export class NgRxNavigationService {

  constructor(private store: Store<any>, private router: Router) {
  }

  subscribeToRouteChange(): void {
    this.store.pipe(select(state => state.app ? state.app.previousPath : null))
      .subscribe(pathList => {
        pathList.push(this.router.url);
        this.store.dispatch(onNavigate({ previousPath: pathList }));
      });
  }


}
