import { ConfigStateService } from '@medlogic/shared/state-config';
import { Component, OnInit, Inject } from '@angular/core';
import { AtividadeCadastroViewComponent } from '../atividade-cadastro-view/atividade-cadastro-view.component';
import { ActivatedRoute } from '@angular/router';
import { OcorrenciaDAL } from '../../shared/model/dal/ocorrencia-dal';
import { AtividadeDAL } from '../../shared/model/dal/atividade-dal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AtividadeComponenteDAL } from '../../shared/model/dal/atividade-componente-dal';
import { CalculadoraService } from '../../shared/service/calculadora.service';
import { DadoDAL } from '../../shared/model/dal/dado-dal';
import { CalculatorService } from '../../shared/service/calculator.service';
import { ValidatorService } from '../../shared/service/validator.service';
import { ActionService } from '../../shared/service/action.service';
import { CalculadoraConditionService } from '../../shared/service/calculadora-condition.service';
import { NavigationService } from '../../shared/service/navigation.service';
import { LibService } from '../../shared/service/lib.service';
import { ExecucaoTarefaDAL } from '../../shared/model/dal/execucao-tarefa-dal';
import { UsuarioDAL } from '@medlogic/shared/shared-data-access';
import { ProcessoDAL } from '../../shared/model/dal/processo-dal';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnBubbleEvent } from '../../shared/enum/en-bubble-event.enum';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { IListDetailParam } from '../../shared/interface/ilist-detail-param';
import { EnActivityType } from '@medlogic/shared/gecore';
import { LogService, IAtividadeComponenteDAL, IOcorrencia, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { WsTrackerService } from '@medlogic/shared/shared-data-access';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { IAtividadeCadastroDetailDialog } from '../../shared/interface/iatividade-cadastro-detail-dialog';
import { IPasso } from '../../shared/interface/ipasso';

/* Essa classe é executada quando ocorre a edição/nova atividade de Grid e ou ComboBox. */
/* Tentativa de descrever um componente que herda de um componente completo que
 * possui html e css, sem sobrescrever esses últimos elementos, mas apenas alterar
 * regras de negócio no código.
 * O objetivo dessa classe é representar a Atividade quando vem da edição
 * de um item de grid ou combobox. */
@Component({
  selector: 'lib-atividade-cadastro-detail-dialog',
  templateUrl: './atividade-cadastro-detail-dialog.component.html',
  styleUrls: [
    './../atividade-view/atividade-view.component.css',
    './atividade-cadastro-detail-dialog.component.css'
  ]
})
export class AtividadeCadastroDetailDialogComponent extends AtividadeCadastroViewComponent implements OnInit {
  lstCadastroAdicional = new Array<any>();
  ctrl: IAtividadeComponenteDAL;

  constructor(
    global: GlobalService,
    config: ConfigStateService,
    cnfJson: ConfigJsonService,
    route: ActivatedRoute,
    log: LogService,
    ocorrenciaDAL: OcorrenciaDAL,
    atividadeDAL: AtividadeDAL,
    fb: FormBuilder,
    atividadeComponenteDAL: AtividadeComponenteDAL,
    calc: CalculadoraService,
    dadoDAL: DadoDAL,
    calculator: CalculatorService,
    validator: ValidatorService,
    action: ActionService,
    calcCond: CalculadoraConditionService,
    navigation: NavigationService,
    lib: LibService,
    confirmationService: ConfirmationService,
    wsTracker: WsTrackerService,
    execucaoTarefa: ExecucaoTarefaDAL,
    usuarioDAL: UsuarioDAL,
    processoDAL: ProcessoDAL,
    matDialog: MatDialog,
    msg: MsgPtBR,
    public dialogRef: MatDialogRef<AtividadeCadastroDetailDialogComponent>,
    messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: IListDetailParam
  ) {
    super(
      global,
      config,
      cnfJson,
      route,
      log,
      ocorrenciaDAL,
      atividadeDAL,
      fb,
      atividadeComponenteDAL,
      calc,
      dadoDAL,
      calculator,
      validator,
      action,
      calcCond,
      navigation,
      lib,
      confirmationService,
      wsTracker,
      execucaoTarefa,
      usuarioDAL,
      processoDAL,
      matDialog,
      msg,
      messageService
    );
    this.defaultFormControls = data.defaultFormControls;
    this.atividadeNo = data.ano;
    this.ocorrenciaNo = data.ono;
    this.isReadOnly = data.isReadOnly;
    this.saveInList = data.saveInList;
    if (data.lstCadastroAdicional && data.lstCadastroAdicional.length > 0) {
      this.lstCadastroAdicional = data.lstCadastroAdicional;
    }
    this.enTheme = data.enTheme;
    this.isMobile = data.isMobile;
    this.activityType = EnActivityType.ListDetail;
    this.ctrl = data.ctrl;
  }

  /* override  */
  ngOnInit(): void {
    try {
      // Nesse caso, não se deve chamar o ngOnInit, pois os parâmetros serão lidos por Url
      super.ngOnInit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Click no botão de confirmação. */
  onYesClick(value: any): void {
    try {
      // if (this.isValidForm()) {
      this.dialogRef.close({
        listvno: this.data.listvno,
        componentes: this.componentes,
        formGroup: this.formGroup,
        changedFormControl: this.config.getChangedFormControl(this.data.ano),
        lstCadastroAdicional: this.lstCadastroAdicional,
        ono: this.config.OcorrenciaNo.value,
        values: this.formGroup.getRawValue()
        // gridItem: this.config.getGridItems(this.data.ano)
      } as IAtividadeCadastroDetailDialog);
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onYesClick', error.message);
    }
  }

  /* Click no botão de fechar. */
  onCloseClick(): void {
    try {
      this.dialogRef.close(null);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onCloseClick', error.message);
    }
  }

  /* override
   * Ao invés de extrair os parâmetros da rota,
   * resgatará das propriedades locais passadas
   * como parâmetro para o dialog. */
  // protected getUrlParams(): void {
  //   try {
  //     this.config.fillActivityParams(
  //       this.atividadeNo,
  //       this.ocorrenciaNo,
  //       this.data.listvno,
  //       this.readOnly,
  //       this.saveInList
  //     );
  //     // // Os parametros estão sendo passados diretamente aos componentes
  //     // if (this.atividadeNo) {
  //     // 	this.config.ModeloAtividadeNo = +this.atividadeNo;
  //     // }
  //     // if (this.data.listvno) {
  //     // 	this.config.listVno = +this.data.listvno;
  //     // }
  //     // if (this.readOnly) {
  //     // 	this.config.isReadOnly = this.readOnly;
  //     // }
  //     // if (this.saveInList) {
  //     // 	this.saveInList = this.saveInList;
  //     // }
  //     // // Necessário zerar a ocorrencia se não for fornecida, pois, é um requisito para se criar nova.
  //     // this.config.OcorrenciaNo.next(this.ocorrenciaNo);
  //     // O refresh não pode ser no ngOnInit, pois, quando a rota não muda,
  //     // mas apenas os parâmetros da rota, esse método não é chamado novamente.
  //     // Se o parâmetro for atualizado, atualiza o carregamento da tela
  //     this.subs.sink = this.refresh(
  //       EnumAtividadeTipo.Editar,
  //       this.config.ModeloAtividadeNo,
  //       this.config.OcorrenciaNo.value,
  //       this.config.usuarioLogadoNo,
  //       this.defaultFormControls || this.config.getDefaultFormControls(this.config.ModeloAtividadeNo)
  //     ).subscribe();
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
  //   }
  // }

  /* override
   * Recarrega a tela  */
  // protected refresh(
  //   enAtividadeTipo: EnumAtividadeTipo,
  //   ano: number,
  //   ono: number,
  //   uno: number,
  //   defaultFormControls: any,
  //   addToHistory: boolean = true,
  //   pno: number = -1
  // ): Observable<IAtividade> {
  //   try {
  //     // Para evitar que seja criado uma rota no histórico.
  //     return super.refresh(enAtividadeTipo, ano, ono, uno, defaultFormControls, false);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'refresh', error.message);
  //   }
  // }

  /* Evento chamado após a conclusão do salvamento da atividade.
   * override
   * Atenção: utiliza a propriedade this.config.listVno
  */
  protected afterSave(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    fg: FormGroup,
    isEditMode: boolean
  ): Observable<IOcorrencia> {
    try {
      this.fillGridItemToTransportValues(ano, ono, componentes, this.ctrl.VariavelNo); // this.config.listVno
      // this.updateChangedFormControl(tno, ano, ono, uno, pno, componentes, 'SAVE');
      this.isLoading.next(false);
      this.data.ono = ono;
      if (fg?.valid) {
        this.onYesClick(null);
      }
      // TODO: Deveria haver passagens de tno e pno já que essa é uma edição de um detalhe, portanto,
      // NÃO podem ser ligados a tarefas e ou processos diretamente?
      return of({ OcorrenciaNo: ono, AtividadeNo: ano, TarefaNo: tno, ProcessoNo: pno, fg } as IOcorrencia);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave', error.message);
    }
    return of(null);
  }

  /** override */
  // protected afterComplete(
  //   ocorrencia: IOcorrencia
  // ): Observable<IOcorrencia> {
  //   try {
  //     this.onYesClick(null);
  //     return super.afterComplete(ocorrencia);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'afterSave', error.message);
  //   }
  //   return of(null);
  // }

  /** override */
  protected doActivityComplete(tno: number, ano: number, ono: number, uno: number, pno: number): Observable<{ passo: IPasso, ocorrencia: IOcorrencia }> {
    this.emitAfterCompleted(tno, ano, ono, uno, pno);
    return of(null);
  }

  /*
   * Preenche a propriedade que será utilizada para modificar o valor
   * do grid da atividade chamadora (pai). */
  protected fillGridItemToTransportValues(
    ano: number,
    ono: number,
    componentes: IAtividadeComponenteDAL[],
    listvno: number
  ): void {
    try {
      // index é referente a um item de grid enquanto OcorrenciaNo de combobox.
      let currentGridItem = this.lstCadastroAdicional.find((f) => (f.index || f.OcorrenciaNo) === ono);
      if (!currentGridItem) {
        currentGridItem = {};
        this.lstCadastroAdicional.push(currentGridItem);
      }
      // const gridItems = this.config.getGridItems(ano, false);
      // const gridItem = gridItems && gridItems.length > 0 ? gridItems.find((f) => f.index === ono) || {} : {};
      // já deve existir e conter como id a variavelNo do Grid que está sendo editado/novo.
      // Atenção esse preenchimento levará outras variáveis, além daquela
      // que é a original do Grid em edição para serem atualizadas na atividade chamadorao(pai).
      componentes?.forEach((e) => {
        try {
          const typed = this.global.getTypedValue(e.Valor);
          currentGridItem[this.lib.getId(e.VariavelNo)] = typed.value;
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'fillGridItemToTransportValues.forEach', error.message);
        }
      });
      currentGridItem.index = ono;
      currentGridItem.gridVariavelNo = listvno;
      // É necessário estabelecer o valor que retornará para preencher
      // uma combobox, quando se tratar de um novo item e não houver coincidência entre o variavelNo do título no Cadastro e na combobox.
      const cadastroTitulo = componentes.find((f) => f.TypeRegister === 2);
      currentGridItem.value = cadastroTitulo ? cadastroTitulo.ValorTexto : '';
      // this.config.setGridItem(ano, ono, listvno, gridItem);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillGridItemsToTransportValues', error.message);
    }
  }

  /* override
  * O original retorna para a página anterior conforme o histórico.
  * nessa lógica de diálogo, fechará e retornará os parâmetros. */
  protected historyBack(position: number): void {
    try {
      this.onCloseClick();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'historyBack', error.message);
    }
  }

  /* override
  * Irá zerar o defaultFormControl para impedir
  * carregamento dos valores.
  * Além de salvar a atividade atual, promoverá um
  * refresh da mesma atividade e cria nova ocorrencia. */
  protected listDetailSaveAndNew(
    ano: number,
    saveInList: boolean,
    addToHistory: boolean = true,
    preserveValues: boolean = true
  ): void {
    try {
      if (!preserveValues) {
        const dfc = this.cleanAllValuesInvisibleOrReadOnly(
          this.componentes,
          this.config.getDefaultFormControls(ano)
        );
        this.config.setDefaultFormControls(ano, dfc);
      }
      super.listDetailSaveAndNew(ano, saveInList, addToHistory);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listDetailSaveAndNew', error.message);
    }
  }

  /* Zera todos os valores que estão visíveis e são editáveis. */
  protected cleanAllValuesInvisibleOrReadOnly(componentes: IAtividadeComponenteDAL[], defaultFormControls: any): any {
    try {
      componentes.forEach((ctrl) => {
        try {
          if (ctrl.IsVisible && ctrl.IsEnable) {
            defaultFormControls[this.lib.getId(ctrl.VariavelNo)] = null;
          }
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'cleanAllValuesInvisibleOrReadOnly', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanAllValuesInvisibleOrReadOnly', error.message);
    }
    return defaultFormControls;
  }


}
