export interface IProfissional {
  ocorrenciaNo: number;
eMail:  any;
dataNascimento:  Date;
nomeCompleto:  any;
contatosFones:  any;
enderecoCompleto:  any;
cep:  any;
bairro:  any;
cidade:  any;
nomeMae:  any;
nomePai:  any;
naturalidade:  any;
nFilhos:  any;
nDependentes:  any;
grauInstrucao:  string;
cargoFuncao:  string;
dRT:  any;
numeroConselho:  any;
loginProfissional:  any;
codigo:  any;
titulo:  any;
habilitado:  boolean;
religiao:  string;
estadoCivil:  string;
estado:  string;
dtNasc:  any;
executor:  any;
  }
