export interface IGetRelationshipsByPostRequest {
    token?: string;
    ocorrenciaNo: number;
    idPessoa: number;
    postAno: number;
    viewAno: number;
    commentAno: number;

}

// export interface IGetSubProcessByUserResponse {
//     ProcessoNo: number;
//     ProcessoNome: string;
//     ClienteNo: number;
//     dtCadastro: string;
//     dtModificacao: string;
//     dtUltimoAcesso: string;
//     TotalAcesso: number;
//     blInativo: boolean;
//     VariavelComplementar1No?: any;
//     VariavelComplementar2No?: any;
//     VariavelComplementar3No?: any;
//     VariavelComplementar4No?: any;
//     VariavelComplementar5No?: any;
//     OrderNo: number;
//     _Lane?: any;
//     TotalUsuarioAssoc: number;
//     Prazo?: any;
//     PrazoNo: number;
//     VariavelIdentificacao1Valor?: any;
//     VariavelIdentificacao2Valor?: any;
//     ProcessoNoOld?: any;
//     Versao: number;
//     IconNo: number;
//     TempoConclusao?: any;
//     UnidadeTempoConclusao?: any;
//     CalcTotalExecucao: number;
//     PrimeiraAtividadeNo: number;
//     urlIcon: string;
//     AgrupamentoProcessosNo: number;
//     // Utilizados no caso da tela de lista de processos para armazenar o id da Atividade
//     atividadeNo?: number;
//     ocorrenciaNo?: number;
//     tarefaNo?: number;
//     multiAtividadeNo?: number;
//     // Calculado
//     isModule?: boolean;
//     showOnMobile?: boolean;
// }
