import { Injectable } from '@angular/core';

// FIXME: Gera referencia circular
// import {
//   TbKeyValueService
// } from '@medlogic/shared/shared-jsstore';

import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class IndexedDbStorageService {

  private encryptValues = true;
  // FIXME: This tecnhique is not totally efficency, because the key will be presented at js code.
  staticKey = 'kiPl-7338-3kdf-K33@';

  // FIXME: Localstorage tem limite de 5Mb e tranca a thread. Considerar migrar para indexDb com algo do tipo: https://github.com/jakearchibald/idb-keyval

  public get key(): string {
    // FIXME: this is a potential source of bug, because if the token is changed it will end up in bad decrypt.
    return this.staticKey;
  }

  constructor(
    private tbKeyValue: any, // FIXME: gera referencia circular TbKeyValueService,
  ) { }

  async setSavedState(state: any, key: string): Promise<any> {
    try {
      let value = JSON.stringify(state);
      value = this.encryptValues ? this.encrypt(value) : value;
      const exists = await this.tbKeyValue.getById(key);
      return (!!exists) ?
        await this.tbKeyValue.update(key, value) :
        await this.tbKeyValue.add({ key, value });
      // localStorage.setItem(localStorageKey, value);
    } catch (error) {
      console.log(this.constructor.name, 'setSavedState', error.message);
    }
    return null;
  }

  async getSavedState(key: string): Promise<any> {
    try {
      // let state = localStorage.getItem(key);
      let state = await this.tbKeyValue.getById(key);
      state = this.encryptValues ? this.decrypt(state) : state;
      return state ? JSON.parse(state) : null;
    } catch (error) {
      console.log(this.constructor.name, 'getSavedState', error.message);
    }
    return null;
  }

  async clean(key: string): Promise<any> {
    try {
      // if (localStorage && (typeof localStorage.removeItem === 'function')) {
      //   localStorage.removeItem(localStorageKey);
      //   return true;
      // }
      return await this.tbKeyValue.deleteByKey(key);
    } catch (error) {
      console.log(this.constructor.name, 'clean', error.message);
    }
    return false;
  }

  protected encrypt(message: string): string {
    try {
      if (message) {
        return CryptoJS.AES.encrypt(message, this.key.trim()).toString();
      }
    } catch (error) {
      console.log(this.constructor.name, 'encrypt', error.message);
    }
    return null;
  }

  protected decrypt(encryptedMsg: string): string {
    try {
      if (encryptedMsg) {
        return CryptoJS.AES.decrypt(encryptedMsg, this.key.trim()).toString(CryptoJS.enc.Utf8);
      }
    } catch (error) {
      console.log(this.constructor.name, 'decrypt', error.message);
    }
    return null;
  }


}
