export interface IMovimentacaoDeLeito {
  ocorrenciaNo: number;
residente:  any;
prontuarioMEDLOGIC:  any;
prontuarioEinstein:  any;
codigo:  any;
titulo:  any;
habilitado:  boolean;
bloco:  string;
andarBlocoPROC:  any;
quarto:  string;
tipoQuarto:  any;
leito:  string;
ramalPROC:  any;
ramal:  string;
justificativa:  string;
dataMovimentacao:  Date;
andar:  string;
andarMaisRecente:  any;
  }
