import { ConfigStateService } from '@medlogic/shared/state-config';
import { LibService } from '../../../shared/service/lib.service';
import { IBubble } from '../../../shared/interface/ibubble';
import { ValidatorService } from '../../../shared/service/validator.service';
import { CalculadoraConditionService } from '../../../shared/service/calculadora-condition.service';
import { CalculadoraService } from '../../../shared/service/calculadora.service';
import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { EnBubbleEvent } from '../../../shared/enum/en-bubble-event.enum';
import { of, Subject } from 'rxjs';
import { EnMessageSeverity } from '@medlogic/shared/gecore';
import { IMessage } from '@medlogic/shared/gecore';
import { UnsubscribeOnDestroyAdapter, IAtividadeComponenteDAL, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { IBasic } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { flatMap, mergeMap, tap } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-atividade',
  templateUrl: './atividade.component.html',
  styleUrls: ['./atividade.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtividadeComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() tab: IBasic;
  @Input() componentes: IAtividadeComponenteDAL[]; // Lista de componentes da mesma aba
  @Input() componentesAllTabs: IAtividadeComponenteDAL[]; // Lista de todos os componentes
  @Input() formErrors: any;
  @Input() formGroup: FormGroup;
  @Input() isMobile = false;
  @Input() isLoading = false;
  @Input() enTheme = EnTheme.black;

  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble: EventEmitter<IBubble> = new EventEmitter<IBubble>();

  /* Propriedade alimentada no valueChanges para ser possível comparar se determinado valor já foi processado, ou se mudou. */
  formGroupOldValues: { [key: string]: any } = {};

  isRecalculating = false;
  // @ViewChildren('ctrlField') ctrlFields;
  isFirstLoading = true;
  // ** Não estão sendo utilizados, mas mantidos como uma referência para alguma regra de negócio que possa ter sido esquecida. */
  // public lstComponente: Array<any> = new Array<any>();
  // Fará um cache de componentes. Antes estava em AtividadeScreen,
  // mas migrado para evitar conflitos de DI
  // private lstComponenteAutoCalculavel: any[] = new Array();
  // private lstComponenteVisibilidadeControladaPorCondicao: any[] = new Array();
  // private lstComponenteSomenteLeituraControladoPorCondicao: any[] = new Array();
  // private lstComponenteVinculadoEmCascata: any[] = new Array();
  // private lstComponenteVinculadoAListaCadastro: any[] = new Array();
  // private lstComponenteVinculadoAListaColaborador: any[] = new Array();
  // private lstComponenteVinculadoAListaWebservice: any[] = new Array();
  // private lstComponenteAlert: any[] = new Array();

  @Output() errorNotify: EventEmitter<IMessage> = new EventEmitter<IMessage>();
  @Output() changesNotify: EventEmitter<IAtividadeComponenteDAL> = new EventEmitter<IAtividadeComponenteDAL>();

  message: any = {
    firstButtonLabel: 'Não',
    title: 'Confirmação',
    icon: 'fa-times',
    text: '',
    acceptFunc: () => {
      // método de exclusão
    }
  };

  public get isShowVariableNo(): boolean {
    return this.cnfJson.showVariavelNo;
  }

  public get isShowTabOrder(): boolean {
    return this.cnfJson.showTabOrder;
  }

  protected cachedDependentCtrl: { variavelNo: number; ctrls: IAtividadeComponenteDAL[] }[] = [];
  protected cachedValidationDependentCtrl: { variavelNo: number; ctrls: IAtividadeComponenteDAL[] }[] = [];

  hasConfirmButton = true;
  wasLoaded = false;

  onEventBubble($event: IBubble) {
    this.eventBubble.emit($event);
  }

  constructor(
    private log: LogService,
    public config: ConfigStateService,
    public cnfJson: ConfigJsonService,
    private global: GlobalService,
    private calc: CalculadoraService,
    private calcCond: CalculadoraConditionService,
    private validator: ValidatorService,
    public lib: LibService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.subscribeForAsyncValue(this.componentesAllTabs, this.formGroup);
      this.subscribeFormChanges();
      // this.forceAllReferencedCtrlChange();
      // this.fillId(this.formGroup, this.componentes);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /*Chamará um método do componente filho no momento que todos os componentes estiverem de fato carregados.
   * No entanto, essa ciencia só existe na classe atividade componente view, que deverá portanto chamar esse método.
  */
  onAllComponentsLoaded(): void {
    try {
      // TODO: A ativação dessa linha faz com que a edição de uma lançamento fique processando recursivamente
      // this.forceAllReferencedCtrlChange(this.formGroup, this.componentesAllTabs);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAllComponentsLoaded', error.message);
    }
  }

  /* Se o campo for o Id de um Cadastro, preencherá o valor com o número da Ocorrencia criada. */
  protected fillId(formGroup: FormGroup, componentes: IAtividadeComponenteDAL[]): void {
    try {
      if (this.config.OcorrenciaNo.value && this.config.OcorrenciaNo.value > 0) {
        componentes.every((c) => {
          try {
            if (c.TypeRegister === 1) {
              c.ValorTexto = this.config.OcorrenciaNo.value;
              formGroup.get(this.lib.getId(c.VariavelNo)).markAsDirty();
              return true;
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'fillId.fillId', error.message);
          }
          return false;
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillId', error.message);
    }
  }

  /* No primeiro carregamento, após todos os controles terem sido carregados,
   * forçará a mudança de todos os controles que são referenciados por fórmula.
   * Isso assegurará que os valores iniciais dos controles serão considerado
   * no processamento das fórmulas na inicialização. Caso contrário, somente
   * se o usuário modificar o controle é que a fórmula será calculada.
   */
  protected forceAllReferencedCtrlChange(): void {
    try {
      // this.wsTracker
      //   .callCount()
      //   .subscribe(s => //A checagem dos valores default é importante, pois, ela ocorrerá após o carregamento completo dos dados
      //   {
      //     try {
      //       if ((s <= 0) && (!this.wasLoaded)) {
      //         this.wasLoaded = true;
      //         let refCtrls = this.getAllCtrlReferenced(componentes);
      //         if (refCtrls) {
      //           refCtrls.forEach(f => {
      //             try {
      //               let control = formGroup.get(this.lib.getId(f.VariavelNo));
      //               control?.markAsDirty();
      //               control?.markAsTouched();
      //               control?.updateValueAndValidity({ emitEvent: true });
      //             } catch (error) {
      //               this.log.Registrar(this.constructor.name, 'forceAllReferencedCtrlChange.callCount.forEach', error.message);
      //             }
      //           });
      //         }
      //       }
      //     } catch (error) {
      //       this.log.Registrar(this.constructor.name, 'forceAllReferencedCtrlChange.callCount', error.message);
      //     }
      //   });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'forceAllReferencedCtrlChange', error.message);
    }
  }

  /* Retorna uma lista de todos os controles que foram referenciado em alguma fórmula. */
  protected getAllCtrlReferenced(componentes: IAtividadeComponenteDAL[]): IAtividadeComponenteDAL[] {
    try {
      let referenced = new Array<IAtividadeComponenteDAL>();
      componentes.forEach((f) => {
        try {
          const ctrls = this.getListOfReferenciados(f);
          referenced = referenced.concat(ctrls);
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getAllCtrlReferenced.forEach', error.message);
        }
      });
      return this.global.Distinct(referenced);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAllCtrlReferenced', error.message);
    }
    return null;
  }

  /*Todos os componentes irão popular e subscrever para uma possível atualização assíncrona de valores.
   * Depois de modificar os componentes, necessário propagar aos pais para que os componentes sejam atualizados.
   * Importante receber os componentes de todas as abas, pois, irá dar update nos componentes da atividade pai.
   */
  subscribeForAsyncValue(componentesAllTabs: IAtividadeComponenteDAL[], fg: FormGroup): void {
    try {
      componentesAllTabs.forEach((ctrl) => {
        try {
          if (!ctrl.AsyncValue) {
            ctrl.AsyncValue = new Subject();
          }
          const scopedCtrl = ctrl;
          // FIXME: A atribuição do valor em AtividadeView.updateComponentesFromFormControls
          // está sendo emitida (next) antes de entrar nesse subscribe.
          this.subs.sink = ctrl.AsyncValue.subscribe((newValue) => {
            try {
              // this.lib.setGEValue(newValue, scopedCtrl);
              // const type = this.global.getTypedValue(newValue).type;
              // switch (type) {
              // 	case EnTypedValue.Date:
              // 		scopedCtrl.ValorData = newValue;
              // 		scopedCtrl.ValorDataMMddyyyy = this.global.DateToMMddYYYY(newValue);
              // 		scopedCtrl.Valor = scopedCtrl.ValorDataMMddyyyy;
              // 		break;
              // 	default:
              // 		scopedCtrl.ValorTexto = newValue;
              // 		scopedCtrl.Valor = newValue;
              // 		break;
              // }
              const field = fg.get(this.getId(scopedCtrl));
              this.onChangeRecursive(field, this.getId(ctrl));
              field.markAsDirty();
              field.patchValue(newValue, { onlySelf: false, emitEvent: false });
              field?.setValidators(this.validator.getValidators(ctrl).map<ValidatorFn>((m) => m.validator));
            } catch (error) {
              this.log.Registrar(
                this.constructor.name,
                'subscribeForAsyncValue.subscribe',
                error.message
              );
            }
          });
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'subscribeForAsyncValue', error.message);
        }
      });
      // this.eventBubble.emit(<IBubble>{
      // 	bubbleEvent: EnBubbleEvent.componentesChanged,
      // 	params: { componentes: componentesAllTabs }
      // });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'subscribeForAsyncValue', error.message);
    }
  }

  // beforeChangeValues = {};
  /*Subscreve-se às alterações do formulário. Qualquer mudança aciona esse método, mesmo que não
   * tenha qualquer tipo de tratamento de evento change nos controls
   * é possível ter vários subscribers, em vários locais do código para capturar essas mudanças.
   */
  // subscribeFormChanges(): void {
  //   try {
  //     this.subs.sink = this.formGroup.valueChanges.subscribe((values) => {
  //       try {
  //         // const different = Object.keys(values).filter(key => {
  //         //   return this.lib.beforeChangeValues.hasOwnProperty(key) ?
  //         // !this.global.isEqual(this.lib.beforeChangeValues[key], values[key]) : true;
  //         // });
  //         // Parece uma repetição, mas como há chamadas recursivas, é necessário atualizar
  //         // todos os valores que mudaram na propriedade de controle antes de continuar.
  //         // different.forEach(clm => {
  //         //   this.lib.beforeChangeValues[clm] = values[clm];
  //         // });
  //         // different.forEach(clm => {
  //         //   try {
  //         //     const control = this.formGroup.get(clm);
  //         //     // Entrará se foi modificado pelo usuário, ou se for o primeiro carregamento.
  //         //     this.onChangeRecursive(control, clm);
  //         //     control?.markAsPristine();
  //         //     this.formGroup.markAsUntouched();
  //         //     this.checkValidationOfValidationDependents(
  //         //       clm,
  //         //       this.formGroup,
  //         //       this.componentesAllTabs
  //         //     );
  //         //   } catch (error) {
  //         //     this.log.Registrar(this.constructor.name, 'subscribeFormChanges.forEach', error.message);
  //         //   }
  //         // });
  //         // necessário pegar o RawValue, pois fc corresponde ao value, ou seja, apenas controles visíveis
  //         const formControls = this.formGroup.getRawValue();
  //         for (const clm in formControls) {
  //           if (clm) {
  //             const control = this.formGroup.get(clm);
  //             // if (this.wasChanged(clm, control)) {
  //             if (control?.dirty || this.isFirstLoading) {
  //               // Entrará se foi modificado pelo usuário, ou se for o primeiro carregamento.
  //               this.onChangeRecursive(control, clm, formControls);
  //               control?.markAsPristine();
  //               this.formGroup.markAsUntouched();
  //               this.checkValidationOfValidationDependents(
  //                 clm,
  //                 this.formGroup,
  //                 this.componentesAllTabs
  //               );
  //             }
  //           }
  //         }
  //         this.isFirstLoading = false;
  //       } catch (error) {
  //         this.log.Registrar(this.constructor.name, 'subscribeFormChanges.valueChanges', error.message);
  //       }
  //     });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'subscribeFormChanges', error.message);
  //   }
  // }

  // beforeChangeValues = {};
  /*Subscreve-se às alterações do formulário. Qualquer mudança aciona esse método, mesmo que não
   * tenha qualquer tipo de tratamento de evento change nos controls
   * é possível ter vários subscribers, em vários locais do código para capturar essas mudanças.
   */
  subscribeFormChanges(): void {
    try {
      // necessário pegar o RawValue, pois fc corresponde ao value, ou seja, apenas controles visíveis
      const formControls = this.formGroup.getRawValue();
      const changeRecursive$ = (control: AbstractControl, clm: string, fg: FormGroup, componentesAllTabs: IAtividadeComponenteDAL[], forceChange: boolean) => tap(values => {
        this.onChangeRecursive(control, clm, forceChange);
        control?.markAsPristine();
        fg.markAsUntouched();
        this.checkValidationOfValidationDependents(clm, fg, componentesAllTabs);
      })
      // Pipe principal
      this.subs.sink = of(Object.keys(formControls))
        .pipe(
          flatMap(clm => clm),
          mergeMap(clm => {
            const control = this.formGroup.get(clm);
            return control?.valueChanges
              .pipe(
                changeRecursive$(control, clm, this.formGroup, this.componentesAllTabs, false)
              )
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'subscribeFormChanges', error.message);
    }
  }

  /* Verifica se o valor armazenado no controle mudou.
   * Se o valor não estiver registrado na propriedade de controle, fará o registro, e/ou atualização do valor.
   * TODO: verificar se esse comportamento funciona em atividades dentro de atividades, ou se será necessário criar chave com atividadeNo.
  */
  protected wasChanged(clm: string, control: AbstractControl): boolean {
    try {
      const oldValue = this.formGroupOldValues[clm];
      let wasChanged = false;
      if (oldValue) {
        wasChanged = !this.global.isEqual(oldValue, control?.value);
        this.formGroupOldValues[clm] = control?.value;
        return wasChanged;
      } else {
        this.formGroupOldValues[clm] = control?.value;
        return true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'wasChanged', error.message);
    }
    return false;
  }

  /*Verifica os controles que possuem alerta ou validação por fórmula,
   * que dependem (fazem referência) do controle que se modificou.
   * Tem uma lógica parecida com onChangeRecursive, no entanto, não precisa ser
   * recursivo, pois, a validação não modifica o valor do controle validado e, portanto,
   * não gera impacto para controles que dependam desse último.
   * É necessário estar separado do onChangeRecursive, pois, os validadores fazem, normalmente, em suas
   * fórmulas referência ao próprio controle, o que geraria loop infinito em onChangeRecursive.
   * ATENÇÃO: esse método precisa ser executado após o onChangeRecursive, pois, depende que a atualização
   * dos valores dos controles alterados pelos usuários, mas também os dependentes, tenham sido modificados.
  */
  protected checkValidationOfValidationDependents(
    ctrlId: string,
    formGroup: FormGroup,
    componentesAllTabs: IAtividadeComponenteDAL[]
  ): void {
    try {
      const dependents = this.getListOfValidationDependents(componentesAllTabs, ctrlId);
      dependents.forEach((d) => {
        const control = formGroup.get(this.lib.getId(d.VariavelNo));
        // Necessário recalcular, pois, a condição de visibilidade do controle validado pode ter sido modificada
        control?.setValidators(this.validator.getValidators(d).map<ValidatorFn>((m) => m.validator));
        try {
          control?.updateValueAndValidity();
        } catch (error) {
          this.log.Registrar(
            this.constructor.name,
            `checkValidationOfValidationDependents.dependents.forEach: ${ctrlId}`,
            error.message
          );
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'checkValidationOfValidationDependents', error.message);
    }
  }

  /*Método recursivo para, a partir do controle que foi alterado pelo usuário, modificar todos os demais dependentes.
   * É muito importante que a primeira chamada tenha sido testada se o controle foi "touched (dirty)".
   * forceChange fará com que o valor seja modificado independente se está vazio ou considerado inalterado.
   * Útil, por exemplo, para forçar a mudança de valor no caso do primeiro carregamento de uma dropdown.
   * // FIXME: Problema com as fórmulas que não dependem de nenhuma variável, pois,
   * // não entrarão nessa rotina e portanto não serão calculadas. (Exemplo: OCORRENCIANO()
  */
  protected onChangeRecursive(
    changedControl: any,
    clm: string,
    forceChange: boolean = false
  ): void {
    let variavelNo = -1;
    try {
      let dependents: IAtividadeComponenteDAL[];
      variavelNo = this.lib.getVariavelNoFromId(clm);
      const value = changedControl.value;
      // **** Passo 1: Identifica todos os controles que dependem desse valor que foi modificado
      // depender significa que os controles dependentes possuem fórmula, readonly ou visibilidade
      // que se refiram ao controle modificado. As validações e alertas não entram, pois, o controle
      // pode se referir a ele mesmo, gerando loop infinito.
      dependents = this.updateValorTextoERetornaListaDosDependentes(
        this.componentesAllTabs,
        variavelNo,
        value,
        forceChange
      );
      if (dependents && dependents.length > 0) {
        // Condição de parada da recursão: não existir dependentes
        this.debugShowDependenceList(clm, dependents);
        // **** Passo 2: Uma vez que o changedControl foi modificado, atualiza o valor de cada um dos dependentes
        dependents.forEach((d) => {
          let newClm;
          try {
            // Atualiza o valor de lstCadastroAdicional, necessário para as fórmulas de grid
            // TODO: tentativa de desativar esse item, pois está parecendo redundante. Observar impacto no cálculo das fórmulas de grid.
            // this.updateGridItems(dependents, this.componentesAllTabs);
            // Calcula e atualiza o valor no formControls
            newClm = this.lib.getId(d.VariavelNo);
            // Lembrando que a configuração de visibilidade é aplicada na diretiva libCtrlIsVisible
            const isVisible = this.calcCond.isVisibleCtrl(d);
            d.IsVisible = isVisible;
            // Recalcula o somente leitura
            const isReadOnly = this.calcCond.isReadOnly(d);
            d.IsEnable = !isReadOnly;
            // O controle somente leitura é configurado de maneira diferente
            const control = this.formGroup.get(newClm);
            if (isReadOnly) {
              control?.disable({ onlySelf: true, emitEvent: false });
            } else {
              control?.enable({ onlySelf: true, emitEvent: false });
            }
            // Notifica a tab, pois, pode influir na visibilidade da aba
            this.notifyChangesToTab(d);
            // Atualiza os valores no formGroup, desligando o disparo do change
            // Lógica necessária para atualização assíncrona.
            // As funções SERVER, por exemplo, retornam um valor apenas de controle, pois, atualizam o AsyncValue de forma assíncrona.
            const calculatedValue = this.getCalculatedValue(value, d) || '';
            if ((calculatedValue !== this.global.NAO_RECALCULAR_ITEM) &&
              d.hasValueDependence
            ) { // Somente os valores que tiverem dependência de fórmula devem ter o valor atualizado, visibilidade e edição condicional não
              // Recalcula a visibilidade condicional do controle
              // TODO: Verificar se o setValue funciona bem para
              // fórmulas em cascata e se está atualizando todos os valores
              // necessários. O uso de todo o formControls está interferindo no funcionamento do ctrDate (input manual)
              // this.formGroup.patchValue(formControls, { emitEvent: false });
              // PatchValue apenas do controle parece estar funcionando.
              // Não impactou no componente de data e está atualizando a visibilidade de abas de imediato.
              // FIXME: Os controles que são dependentes devido a
              // visibilidade condicional e/ou edição estão atualizando os valores dos campos
              control?.patchValue(calculatedValue, { emitEvent: false });
            }
            // Recalcula os validadores, que inclui a visibilidade condicional
            control?.setValidators(this.validator.getValidators(d).map<ValidatorFn>((m) => m.validator));
            // **** Passo 3: Chamada recursiva - como a modificação desse valor pode impactar nos controles dependentes, usa-se a recursão.
            // Talvez desnecessários, uma vez que o setGEValue emite evento de alteração.
            this.onChangeRecursive(control, newClm);
          } catch (error) {
            this.log.Registrar(
              this.constructor.name,
              'onChangeRecursive.dependents.forEach',
              error.message + 'Id:' + newClm
            );
          }
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onChangeRecursive', error.message);
    }
  }

  /* Exibe uma lista de dependências que auxiliará a debugar as fórmulas  */
  protected debugShowDependenceList(clm: string, dependents: IAtividadeComponenteDAL[]): void {
    try {
      const deps = dependents.map((d) => d.VariavelNo.toString()).join(', ');
      const strDep = `Variavel: ${clm} Dependentes: ${deps}`;
      this.showDebug(strDep);
      if (this.cnfJson.showFormulasDependenceList) {
        this.errorNotify.emit({
          severity: EnMessageSeverity.warning,
          summary: 'Debug Fórmulas',
          detail: strDep
        } as IMessage);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showDependenceList', error.message);
    }
  }

  /* Propaga uma mensagem de debug, mas condicionada às configurações de exibição ou não de debug.  */
  protected showDebug(msg: string): void {
    try {
      this.eventBubble.emit({
        bubbleEvent: EnBubbleEvent.debug,
        params: { msg }
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showDebug', error.message);
    }
  }

  // TODO: O ideal é que os controles apontassem para a mesma instância de memória o que dispensaria esse método de atualização.
  // Para fazer isso seria necessário restruturar a
  // AtividadeComponenteDAL, trazendo a criação das referências para essa classe, pois, aqui se cria a lista de componentes.
  /*Necessário pois, os controles referenciados, como a lstControlesReferenciadosPorFormula, são preenchidos em IAtividadeComponenteDAL e
  * apontam para uma instância diferente dos componentes, portanto os respectivos valores não são atualizados.
  */
  protected updateGridItems(dependentes: IAtividadeComponenteDAL[], componentes: IAtividadeComponenteDAL[]): void {
    try {
      dependentes.forEach((d) => {
        try {
          const referenciados = this.getListOfReferenciados(d);
          referenciados.forEach((r) => {
            try {
              if (
                r.Type.toUpperCase() === this.lib.CTRGRID ||
                r.Type.toUpperCase() === this.lib.CTRCOMBOBOX
              ) {
                const find = componentes.find((f) => f.VariavelNo === r.VariavelNo);
                if (find) {
                  find.lstCadastroAdicional = r.lstCadastroAdicional;
                }
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'udateGridItems.forEach.forEach', error.message);
            }
          });
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'updateGridItems.forEach', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateGridItems', error.message);
    }
  }

  /*O valor calculado dependede das listas de controles referenciados presentes na estrutura de cada componente
   * Nesse método, são identificados todos os controles que tiveram o valor alterado, depois, retorna-se uma lista
   * de todos os controles que dependem do valor do componente modificado.
   * Também faz a modificação do ValorTexto e Valor do componente, que serão necessários em visibilidade condicional e salvamento.
   * Se for um componente Grid, preenche a lstCadastroAdicional necessário para fórmulas de Grid.
   */
  // Portanto, a atualização do valor promoverá o recálculo de todos os Component visuais que dependerem dessa propriedade
  protected updateValorTextoERetornaListaDosDependentes(
    componentes: IAtividadeComponenteDAL[],
    variavelNo: number,
    value: any,
    forceChange: boolean = false
  ): IAtividadeComponenteDAL[] {
    try {
      const ctrlToBeChanged = componentes.find((f) => f.VariavelNo === variavelNo);
      if (ctrlToBeChanged) {
        // TODO: Essa validação está impedindo que o primeiro
        // carregamento dispare os eventos. Uma fórmula dependente
        // de proc, por exemplo, não é atualizada quando a tela é aberta, mas apenas na mudança.
        if (
          forceChange ||
          (ctrlToBeChanged.ValorTexto !== value &&
            (ctrlToBeChanged.Valor === undefined || ctrlToBeChanged.Valor !== value))
        ) {
          // Identificado o controle que realmente mudou, modificado o valor
          this.lib.setGEValue(value, ctrlToBeChanged);
          // Checa quais os controles que referenciam (dependem) desse que mudou
          const dependents = this.getListOfDependentCtrl(componentes, ctrlToBeChanged);
          return dependents;
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateValorTextoComponenteModificado', error.message);
    }
    return null;
  }

  /* Exibe a mensagem na tela. */
  protected showMessage(msg: IMessage): void {
    try {
      this.errorNotify.emit(msg);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showMessage', error.message);
    }
  }

  /*Gera uma lista de controles que dependem do refCtrl
   * TODO: Checar se, para o processo de mudança, justifica a checagem das outras listas além de lstControlesReferenciadosPorFormula
   * pois, pelo menos por hora, o foco é a atualização somente valor dos campos dependentes.
   * ATENÇÃO: lstControlesReferenciadosCustomValidation e lstControlesReferenciadosCustomAlert NÃO devem
   * ser relacionados, primeiro porque as validações são calculadas em outro lugar e depois porque será comum
   * que o controle nessas listas se referencie a si mesmo, pois, são regras de validação do controle.
   */
  protected getListOfDependentCtrl(
    componentes: IAtividadeComponenteDAL[],
    refCtrl: IAtividadeComponenteDAL
  ): IAtividadeComponenteDAL[] {
    try {
      const findFromCache = this.cachedDependentCtrl
        ? this.cachedDependentCtrl.find((f) => f.variavelNo === refCtrl.VariavelNo)
        : null;
      if (findFromCache) {
        return findFromCache.ctrls;
      } else {
        // Necessário marcar os componentes que terão impacto no valor, pois, somente esses podem ter seus respectivos valores atualizados.
        componentes
          .filter(c => this.someCtrlHasVariable(c.lstControlesReferenciadosPorFormula, refCtrl.VariavelNo))
          .forEach(e => e.hasValueDependence = true);
        const ctrlsThanContainVno = this.lstCtrlsThatContainVariable(componentes, refCtrl.VariavelNo);
        if (ctrlsThanContainVno && ctrlsThanContainVno.length > 0 && !this.isRecursiveDependence(refCtrl, ctrlsThanContainVno)) {
          this.cachedDependentCtrl.push({ variavelNo: refCtrl.VariavelNo, ctrls: ctrlsThanContainVno });
        }
        return ctrlsThanContainVno;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getListOfDependentCtrl', error.message);
    }
    return null;
  }

  private lstCtrlsThatContainVariable(ctrls: IAtividadeComponenteDAL[], variavelNo: number): IAtividadeComponenteDAL[] {
    try {
      return ctrls.filter((c) => this.someCtrlHasVariable(c.lstControlesReferenciadosPorFormula, variavelNo) ||
        this.someCtrlHasVariable(c.lstControlesReferenciadosReadOnly, variavelNo) ||
        this.someCtrlHasVariable(c.lstControlesReferenciadosVisibility, variavelNo));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'lstCtrlsThatContainRefCtrl', error.message);
    }
    return [];
  }

  /* O refCtrl não pode depender de nenhum dos dependentCtrls, pois geraria loop infinito. */
  isRecursiveDependence(
    refCtrl: IAtividadeComponenteDAL,
    dependentCtrls: IAtividadeComponenteDAL[]
  ): boolean {
    try {
      if (!dependentCtrls || dependentCtrls.length <= 0) {
        return false;
      }
      const res = dependentCtrls
        .reduce((isRecursive, ctrl) =>
          isRecursive && this.lstCtrlsThatContainVariable([refCtrl], ctrl.VariavelNo).length > 0
          , true);
      if (res) {
        console.log(`ATENÇÃO: dependência recursiva para: ${refCtrl.VariavelNo}, ${refCtrl.Rotulo || ''}`);
      }
      return res;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isNotRecursiveDependence', error.message);
    }
    return false;
  }

  /* Gera uma lista dos controles que possuem fórmulas de validação (CustomValidation) e/ou alerta */
  protected getListOfValidationDependents(
    componentes: IAtividadeComponenteDAL[],
    ctrlId: string
  ): IAtividadeComponenteDAL[] {
    try {
      const findFromCache = this.cachedValidationDependentCtrl
        ? this.cachedValidationDependentCtrl.find((f) => this.global.isEqual(f.variavelNo, ctrlId))
        : null;
      if (findFromCache) {
        return findFromCache.ctrls;
      } else {
        const filtered = componentes.filter(
          (c) =>
            (c.lstControlesReferenciadosCustomAlert &&
              c.lstControlesReferenciadosCustomAlert.length > 0 &&
              this.hasControl(c.lstControlesReferenciadosCustomAlert, ctrlId)) ||
            (c.lstControlesReferenciadosCustomValidation &&
              c.lstControlesReferenciadosCustomValidation.length > 0 &&
              this.hasControl(c.lstControlesReferenciadosCustomValidation, ctrlId))
        );
        this.cachedValidationDependentCtrl.push({ variavelNo: parseInt(ctrlId, 10), ctrls: filtered });
        return filtered;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getListOfValidationDependents', error.message);
    }
    return [];
  }

  /*Retorna uma lista concatenada de todos os controles referenciados pelo ctrl.
  */
  protected getListOfReferenciados(ctrl: IAtividadeComponenteDAL): IAtividadeComponenteDAL[] {
    try {
      let references = new Array<IAtividadeComponenteDAL>();
      if (ctrl.lstControlesReferenciadosCustomAlert) {
        references = references.concat(ctrl.lstControlesReferenciadosCustomAlert);
      }
      if (ctrl.lstControlesReferenciadosCustomValidation) {
        references = references.concat(ctrl.lstControlesReferenciadosCustomValidation);
      }
      if (ctrl.lstControlesReferenciadosPorFormula) {
        references = references.concat(ctrl.lstControlesReferenciadosPorFormula);
      }
      if (ctrl.lstControlesReferenciadosReadOnly) {
        references = references.concat(ctrl.lstControlesReferenciadosReadOnly);
      }
      if (ctrl.lstControlesReferenciadosVisibility) {
        references = references.concat(ctrl.lstControlesReferenciadosVisibility);
      }
      return references;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getListOfDependentCtrl', error.message);
    }
    return [];
  }

  /* Identifica se um ou mais componentes tem a mesma variavelNo fornecida. */
  protected someCtrlHasVariable(componentes: IAtividadeComponenteDAL[], variavelNo: number): boolean {
    try {
      return !componentes ? false : componentes.findIndex((f) => f.VariavelNo === variavelNo) >= 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasCtrl', error.message);
    }
    return false;
  }

  /* Identifica se refControl está presente em componentes comparando VariavelNo */
  protected hasControl(componentes: IAtividadeComponenteDAL[], ctrlId: string): boolean {
    try {
      let has = false;
      has = !componentes
        ? false
        : componentes.findIndex((f) => f.VariavelNo === this.lib.getVariavelNoFromId(ctrlId)) >= 0;
      if (has) {
        return true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasCtrl', error.message);
    }
    return false;
  }

  /*Além de retornar o valor, compara com o valor atual e dispara um novo change se diferente.
   * Há um hash que controla se houve modificação nos valores de um ou mais controles referenciados por fórmula.
   * Se não houver mudança, retornará o currentValue.
   */
  getCalculatedValue(currentValue: string, ctrl: IAtividadeComponenteDAL): string {
    try {
      // FIXME: Não é possível utilizar por enquanto, pois, as funções
      // assíncronas (como o PROC), podem resultar em primeiro calculo em branco e o após o retorno ser ignorado.
      // MUDAR TODAS AS FUNÇÕES ASSÍNCRONAS PARA O PADRAO ASYNCVALUE, desta forma, será possível retornar com essa lógica.
      // if (
      // 	this.calc.wasChanged(
      // 		ctrl.hasLstControlesReferenciadosPorFormula,
      // 		ctrl.lstControlesReferenciadosPorFormula
      // 	)
      // ) {
      // 	ctrl.hasLstControlesReferenciadosPorFormula = this.calc.getHash(
      // 		ctrl.lstControlesReferenciadosPorFormula
      // 	);
      // Operação necessária para que quando o change for acionado e o controle
      // tentar recalcular a si, o valor entre aspas retornará o mesmo resultado
      const valorOuFormula = this.calc.getValorOuFormula(ctrl, currentValue);
      const newValue = this.calc.calculate(ctrl, ctrl.lstControlesReferenciadosPorFormula, valorOuFormula);
      return this.lib.getGEValue(newValue, ctrl, null, 'en-US');
      // }
    } catch (error) {
      if (ctrl) {
        const strError = `erro: ${error.message} formula:${currentValue} variavelNo: ${ctrl.VariavelNo} rotulo: ${ctrl.Rotulo}`;
        this.log.Registrar(this.constructor.name, 'getCalculatedValue', strError);
        this.errorNotify.emit({
          severity: EnMessageSeverity.error,
          summary: 'Erro de fórmula',
          detail: strError
        } as IMessage);
      } else {
        this.log.Registrar(this.constructor.name, 'getCalculatedValue', `erro: ${error.message} `);
      }
    }
    return currentValue;
  }

  /* Identifica os controles que estão com o Cascata ativado e aplica o filtro dos dados. */
  protected applyCascade(): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyCascade', error.message);
    }
  }

  /* retorna um id para o controle baseado na variavelNo */
  getId(ctrl: IAtividadeComponenteDAL): string {
    try {
      return this.lib.getId(ctrl?.VariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getId', error.message);
    }
    return '';
  }

  /* Necessário comunicar as mudanças para o Tab para controle de visibilidade das abas. */
  protected notifyChangesToTab(ctrl: IAtividadeComponenteDAL): void {
    try {
      this.changesNotify.emit(ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'notifyChangesToTab', error.message);
    }
  }

  /*Será chamado por um controle quando ele quiser salvar.
   * É a AtividadeComponent ou então AtividadeView que conhecem todos os parâmetros necessários para
   * salvar a Atividade atual.
   */
  callSave(): void {
    try {
      // DESATIVADO
      // this.action.salvar(this.componentes, -1, this.config.OcorrenciaNo.value, this.config.UsuarioLogadoNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'callSave', error.message);
    }
  }

  /*Chamado pelos controles do tipo ListControl para notificar que o dado terminou de carregar.
   * Será disparado o evento que recalcula os controles dependentes.
   * O Grid necessita notificar, pois, as fórmulas de grid são baseados nos itens do Grid.
   * A Combobox não precisaria, pois, o valor inicial do controle não depende do carregamento dos itens.
   * No entanto, a fórmula de proc, por exemplo, depende do item carregado.
  */
  onDataLoaded(changedCtrl: IAtividadeComponenteDAL | any): void {
    try {
      // this.updateAllListCtrlFromSameCadastroNo(changedCtrl, this.componentes);
      const clm = this.lib.getId(changedCtrl.VariavelNo);
      const control = this.formGroup.get(clm);
      this.onChangeRecursive(control, clm, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDataLoaded', error.message);
    }
  }

  // protected updateAllListCtrlFromSameCadastroNo(changedCtrl: IAtividadeComponenteDAL, ctrls: IAtividadeComponenteDAL[]): void {
  //   try {
  //     const ctrlToUpdate = this.componentes
  // .filter(f => f.CadastroNo === changedCtrl.CadastroNo && f.VariavelNo !== changedCtrl.VariavelNo);
  //     if (ctrlToUpdate && ctrlToUpdate.length > 0) {
  //       ctrlToUpdate
  //         .forEach(ctrl => {
  //           // Force the controls update, to dispach the refresh method that will reload the Cadastro.
  //           const clm = this.lib.getId(ctrl.VariavelNo);
  //           const control = this.formGroup.get(clm);
  //           // TODO: Tentativa de fazer com que listControl.subscribeToCascade dispare o refresh para reload do cadastro desse item
  //           control?.setValue(control?.value, { onlySelf: true, emitEvent: true });
  //           control?.markAsDirty();
  //           control?.markAsTouched();
  //           this.formGroup.updateValueAndValidity({ onlySelf: true, emitEvent: true });
  //         });
  //     }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'updateAllListCtrlFromSameCadastroNo', error.message);
  //   }
  // }

  /*Verifica todos os controles que possuem dependentes e dispara o change.
      * Desnecessário chamar na inicialização, pois, AtividadeView já calcula valores iniciais.
      */
  // protected dispatchChangeAllCtrlThatHasDependents(): void {
  //   try {
  //     let formControls = this.formGroup.getRawValue();
  //     this.componentesAllTabs.forEach(ctrl => {
  //       let dependents = this.getListOfDependentCtrl(this.componentesAllTabs, ctrl);
  //       if (dependents && dependents.length > 0) {
  //         let clm = this.global.getId(ctrl.VariavelNo);
  //         let control = this.formGroup.get(clm);
  //         this.onChangeRecursive(control, clm, formControls);
  //       }
  //     });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'dispatchChangeAllCtrlThatHasDependents', error.message);
  //   }
  // }

  /*método a ser chamado no change dos controles
   * Atenção: O change do Model Driven From não é acionado na primeira carga dos controles.
   * Para que fórmulas em cascata (A depende de B que depende de C) funcionem, atentar para o TabOrder
   * dos controles, pois, isso fará com que os campos dependentes sejam calculados depois da atualizaçào
   * do valor daqueles outros aos quais dependem.
   */
  // protected onFormControlChanged(formControls: any, formGroup: FormGroup): void {
  //   let variavelNo = -1;
  //   try {
  //     // if (!this.isRecalculating && formGroup.touched) {//Touched necessário para apenas realizar os recálculos no blur
  //     //   this.isRecalculating = true;//Impede que o recalculo das fórmulas dispare novo change, o que geraria recursividade infinita
  //     //   console.log("countChanges", this.config.countChanges++);
  //     //   let dependents = new Array<IAtividadeComponenteDAL>();
  //     //   for (let clm in formControls) {
  //     //     variavelNo = this.global.getVariavelNoFromId(clm);
  //     //     dependents = dependents.concat(this.updateValorTextoERetornaListaDosDependentes
  // (this.componentesAllTabs, variavelNo, formControls[clm], formControls));
  //     //     //Atualiza o valor de lstCadastroAdicional
  //     //     this.updateGridItems(dependents, this.componentesAllTabs);
  //     //     //Recalcula os valores desses controles e injeta o resultado no form
  //     //     this.setFormControlValues(dependents, formControls);
  //     //   }
  //     //   this.isRecalculating = false;
  //     // }
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'onFormControlChanged', `erro: ${error.message} variavelNo: ${variavelNo}`);
  //   }
  // }

  /* Nesse modelo de reactive, os valores dos controles e também todos os validadores
  // precisam ser definidos novamente no change, com os valores atualizados.
   * Mas o objeto é completo e, portanto, trará novamente os valores que não se modificaram.
   * Além disso, nesse método o valor de ctrl.IsVisible é atualizado antes de
   * // recriar os validadores, é o que permite não validar os controles invisíveis.
   * O change do formGroup pode ser sobrescrito em vários lugares, mas o principal fica em AtividadeComponent.
   */
  // protected setFormControlValues(componentes: Array<IAtividadeComponenteDAL>, formControls: any): void {
  //   let currentVariavelNo;
  //   let formula = "";
  //   try {
  //     if (componentes) {
  //       let validators: IValidator[] = [];
  //       componentes.forEach(c => {
  //         if (c) {
  //           currentVariavelNo = c.VariavelNo;
  //           //Recalcula a visibilidade condicional do controle
  //           c.IsVisible = this.calcCond.isVisibleCtrl(c);
  //           //Notifica a tab, pois, pode influir na visibilidade da aba
  //           this.notifyChangesToTab(c);
  //           let clm = this.global.getId(currentVariavelNo);
  //           formula = c.ValorDefault;//usado apenas para gerar uma mensagem de erro mais descritiva
  //           formControls[clm] = this.getCalculatedValue(formControls[clm], c);
  //           this.formGroup.controls[clm].setValidators(this.validator.getValidators(c).map<ValidatorFn>(m => m.validator));
  //         }
  //       });
  //       //O uso do patchValue ao invés do setValue é porque nem todos os controles estarão presentes nessa relação de atualização
  //       this.formGroup.patchValue(formControls,
  // { emitEvent: false });//Necessário desligar o emitEvent ou disparará change novamente, recursivamente
  //       // this.formGroup.updateValueAndValidity();
  //     }
  //   } catch (error) {
  //     let detail = `erro: ${error.message} variavelNo: ${currentVariavelNo} formula: ${formula}`;
  //     this.showMessage(<IMessage>{ severity: EnMessageSeverity.error, summary: "Erro de fórmula", detail: detail });
  //     this.log.Registrar(this.constructor.name, 'setFormControlValues', detail);
  //   }
  // }
}
