import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IProgressState } from './progress-state';

import * as fromProgress from './progress.reducer';


export const selectProgressState = createFeatureSelector<IProgressState>(fromProgress.progressesFeatureKey);
export const selectProgressAll = createSelector(selectProgressState, fromProgress.selectAll);

export const selectedProgress = createSelector(selectProgressState, state => {
  return state?.selectedIdentifier ? state?.entities[state?.selectedIdentifier] : null;
});

export const isProgressLoading = createSelector(
  selectProgressAll,
  progresses => progresses?.length <= 0,
);

export const selectedProgressIdentifier = createSelector(selectProgressState, state => {
  return (state?.selectedIdentifier || 0) > 0 ? state?.selectedIdentifier : null;
});
