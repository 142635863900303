import { LibService } from '@medlogic/shared/geform';
import { filter, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { IInternacoes } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { GlobalService, LogService, IForm, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';


export abstract class InternacoesService {

  // tslint:disable: max-line-length
  private lstVariaveis = 'V_3328,V_3329,V_101305,V_105988,V_108793,V_108794,V_108795,V_108796,V_108798,V_108799,V_109179,V_109180,V_109280,V_109285,V_109291,V_109292,V_109293,V_109294,V_109295,V_109297,V_109319,V_109322,V_109323,V_109324,V_109325,V_109327,V_109352';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IInternacoes[] = new Array<IInternacoes>();

  cadastroNo = -1; // 2243;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnfJson: ConfigJsonService,
    protected log: LogService,
    protected lib: LibService,
  ) { }

  getAll(cadastroNo: number, startDate: Date = null, endDate: Date = null): Observable<IInternacoes> {
    try {
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      this.cadastroNo = cadastroNo;
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IInternacoes> {
    if (
      !this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial) ||
      !this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal) ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastro(cadastroNo, this.lstVariaveis).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Mapeia para o objeto principal. */
  protected toAttribute(c: any): IInternacoes {
    try {
      const Internacoes = {
        ocorrenciaNo: c.OcorrenciaNo,
	iDentificacao1: c.V_3328,
	iDentificacao2: c.V_3329,
	dataAlta: this.glb.ddMMYYYYThhmmssToDate(c.V_101305),
	dataInternacao: this.glb.ddMMYYYYThhmmssToDate(c.V_105988),
	motivo: c.V_108793,
	local: c.V_108794,
	leito: c.V_108795,
	dataAlta2: c.V_108796,
	diagnosticoFinal: c.V_108798,
	desfecho: c.V_108799,
	retornoHospitalar: this.lib.getBoolean(c.V_109179),
	dataRetorno: c.V_109180,
	executor: c.V_109280,
	diasPermanenciaHospitalar: c.V_109285,
	codigo: c.V_109291,
	titulo: c.V_109292,
	habilitado: this.lib.getBoolean(c.V_109293),
	idoso: c.V_109294,
	prontuarioEinstein: c.V_109295,
	prontuarioMEDLOGIC: c.V_109297,
	prontuarioEinstein2: c.V_109319,
	testeCalcData: c.V_109322,
	dTENTRADA: c.V_109323,
	dTALTA: c.V_109324,
	recebeuAlta: this.lib.getBoolean(c.V_109325),
	dataRetorno2: this.glb.ddMMYYYYThhmmssToDate(c.V_109327), //
	retornoMaisRecente: c.V_109352,

      } as IInternacoes;
      // É o código calculado. Há inconsistência na codificação, hora no prontuário, hora no código, hora apenas na identificacao2 esse campo calculado visa corrigir isso.
      return Internacoes;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Define se o valor é diferente de nulo, é numérico e diferente de -1. */
  protected isValid(value: any): boolean {
    try {
      return !this.glb.isNullOrEmpty(value) && value.toString() !== '-1' && this.glb.isNumeric(value);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isValid', error.message);
    }
    return false;
  }

  /* Extrai o código da identificação 2. */
  protected extractCode(identificacao2: string): number {
    try {
      const split = identificacao2.indexOf('__') >= 0 ? identificacao2.split('__') : identificacao2.split('_');
      if (split && split.length > 1) {
        return +split[1];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractCode', error.message);
    }
    return -1;
  }

  protected getFromCadastroFiltro(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${InternacoesId}` */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IInternacoes> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          tap(c => console.log("c", c)),
          map(c => this.toAttribute(c)),
          filter((f: IInternacoes) =>
          this.glb.isBetweenIgnoreTime(f.dataRetorno2, startDate, endDate)
        ),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  protected getWithCacheFilter(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<IInternacoes> {
    if (
      startDate.getTime() !== this.currentDtInicial.getTime() ||
      endDate.getTime() !== this.currentDtFinal.getTime() ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastroFiltro(cadastroNo, filtro, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save(Internacoes: IInternacoes, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(Internacoes).filter((f) => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(internacoes: IInternacoes): IForm[] {
    try {
      return [
        { VariavelNo: 3328, ValorDado: internacoes.iDentificacao1 || '' },
        { VariavelNo: 3329, ValorDado: internacoes.iDentificacao2 || '' },
        { VariavelNo: 101305, ValorDado: this.glb.ddMMYYYYThhmmssToDate(internacoes.dataAlta) },
        { VariavelNo: 105988, ValorDado: this.glb.ddMMYYYYThhmmssToDate(internacoes.dataInternacao) },
        { VariavelNo: 108793, ValorDado: internacoes.motivo || '' },
        { VariavelNo: 108794, ValorDado: internacoes.local || '' },
        { VariavelNo: 108795, ValorDado: internacoes.leito || '' },
        { VariavelNo: 108796, ValorDado: this.glb.ddMMYYYYThhmmssToDate(internacoes.dataAlta2) || '' },
        { VariavelNo: 108798, ValorDado: internacoes.diagnosticoFinal || '' },
        { VariavelNo: 108799, ValorDado: internacoes.desfecho || '' },
        { VariavelNo: 109179, ValorDado: internacoes.retornoHospitalar ? 'SIM' : 'NÃO' },
        { VariavelNo: 109180, ValorDado: internacoes.dataRetorno || '' },
        { VariavelNo: 109280, ValorDado: internacoes.executor || '' },
        { VariavelNo: 109285, ValorDado: internacoes.diasPermanenciaHospitalar || '' },
        { VariavelNo: 109291, ValorDado: internacoes.codigo || '' },
        { VariavelNo: 109292, ValorDado: internacoes.titulo || '' },
        { VariavelNo: 109293, ValorDado: internacoes.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 109294, ValorDado: internacoes.idoso || '' },
        { VariavelNo: 109295, ValorDado: internacoes.prontuarioEinstein || '' },
        { VariavelNo: 109297, ValorDado: internacoes.prontuarioMEDLOGIC || '' },
        { VariavelNo: 109319, ValorDado: internacoes.prontuarioEinstein2 || '' },
        { VariavelNo: 109322, ValorDado: internacoes.testeCalcData || '' },
        { VariavelNo: 109323, ValorDado: internacoes.dTENTRADA || '' },
        { VariavelNo: 109324, ValorDado: internacoes.dTALTA || '' },
        { VariavelNo: 109325, ValorDado: internacoes.recebeuAlta ? 'SIM' : 'NÃO' },
        { VariavelNo: 109327, ValorDado: this.glb.ddMMYYYYThhmmssToDate(internacoes.dataRetorno2) },
        { VariavelNo: 109352, ValorDado: internacoes.retornoMaisRecente || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /*  Retorna uma lista com todos os pacientes e seus respectivos medicamentos
   * Atenção: A frequencia não será um campo. Caso esteja prescrito duas vezes ao dia, deverão ser criados dois registros por dia. E assim, sucessivamente.
   */
  /*  getAll(): Array<IInternacoes> {
      try {
        return [
          <IInternacoes>{
            InternacoesId: 1,
            InternacoesName: "Albert Einstein",
            birthDate: new Date(1940, 1, 17),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Em caso de dor",
                dosage: "10 mg",
                took: false,
                prescribedTime: "-",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 2,
            InternacoesName: "Cora Coralina",
            birthDate: new Date(1932, 3, 4),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 1,
            InternacoesName: "Anita Malfati",
            birthDate: new Date(1960, 1, 22),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: true,
                prescribedTime: "13:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 2,
            InternacoesName: "Martin Lutero",
            birthDate: new Date(1922, 3, 1),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: true,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 1,
            InternacoesName: "Albert Einstein",
            birthDate: new Date(1940, 1, 17),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 2,
            InternacoesName: "Cora Coralina",
            birthDate: new Date(1932, 3, 4),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 1,
            InternacoesName: "Anita Malfati",
            birthDate: new Date(1960, 1, 22),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: true,
                prescribedTime: "13:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 2,
            InternacoesName: "Martin Lutero",
            birthDate: new Date(1922, 3, 1),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: true,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 1,
            InternacoesName: "Albert Einstein",
            birthDate: new Date(1940, 1, 17),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "11:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "13:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 2,
            InternacoesName: "Martin Lutero",
            birthDate: new Date(1922, 3, 1),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "18:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: true,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "18:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "08:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "18:00",
              },
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: true,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "10:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 2,
            InternacoesName: "Cora Coralina",
            birthDate: new Date(1932, 3, 4),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "10:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: false,
                prescribedTime: "08:00",
              },
            ]
          },
          <IInternacoes>{
            InternacoesId: 1,
            InternacoesName: "Anita Malfati",
            birthDate: new Date(1960, 1, 22),
            medications: [
              <IMedication>{
                medicationId: 22,
                medicationName: 'Rosuvastatina',
                prescription: "Uma vez ao dia",
                dosage: "10 mg",
                took: false,
                prescribedTime: "13:00",
              },
              <IMedication>{
                medicationId: 20,
                medicationName: 'Aspirina',
                prescription: "Uma vez ao dia",
                dosage: "5 mg",
                took: true,
                prescribedTime: "13:00",
              },
            ]
          },
        ];
      } catch (error) {
        this.log.Registrar(this.constructor.name, 'getAll', error.message);
      }
    }*/
}
