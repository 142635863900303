import { IServiceProvider, IForm, error } from '@medlogic/shared/shared-interfaces';
import { IExame } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Observable } from 'rxjs';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { LocalLibService } from '@medlogic/shared/utils';
import { publishReplay, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import { ConfigStateService } from '@medlogic/shared/state-config';
import {
  GlobalService,
  LogService,
} from '@medlogic/shared/shared-interfaces';

export abstract class ExameService implements IServiceProvider {

  private lstVariaveis = 'V_28598,V_28599,V_28600,V_28601,V_28602,V_28603,V_28604,V_28609,V_28623,V_28650,V_28692,V_28805,V_28806,V_104198,V_104209,V_104210';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IExame[] = new Array<IExame>();

  codigoVariavelNo = 28598; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 2274;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected lib: LocalLibService,
    protected log: LogService) { }

  /* Retorna somente as variáveis desejadas.
   * lstVariables do tipo: 'V_3332,V_32223'
   */
  getSome(
    ano: number,
    lstVariables: string,
    startDate?: Date,
    endDate?: Date
  ): Observable<IExame> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(
        this.cadastroNo,
        startDate,
        endDate,
        lstVariables
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(
    ano: number,
    startDate?: Date,
    endDate?: Date
  ): Observable<IExame[]> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate)
        .pipe(
          toArray(),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(
        this.getAll(ano),
        propLabel,
        propValue,
        propEnabled
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(
    cadastroNo: number,
    startDate: Date,
    endDate: Date,
    lstVariables: string = null
  ): Observable<IExame> {
    try {
      if (
        !this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial) ||
        !this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal) ||
        !this.cadastrosCache
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastro(
          cadastroNo,
          startDate,
          endDate,
          lstVariables
        );
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(
    cadastroNo: number,
    startDate: Date,
    endDate: Date,
    lstVariables: string = null
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables, startDate, endDate)
        .pipe(this.mapTo(), publishReplay(), refCount());
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'getFromCadatro',
        error.message
      );
    }
    return of(null);
  }

  protected mapTo = () =>
    map((c: any) => {
      return {
        ocorrenciaNo: c.OcorrenciaNo,
        codigo: c.V_28598,
        titulo: c.V_28599,
        habilitado: this.lib.getBoolean(c.V_28600),
        valorMINPASistolica: c.V_28601,
        descricao: c.V_28602,
        valorMAXPASistolica: c.V_28603,
        categoria: c.V_28604,
        mENSAGEMDEALERTAMaior: c.V_28609,
        mENSAGEMDEALERTAMenor: c.V_28623,
        cadNo: c.V_28650,
        mENSAGEMDEALERTAEXAMENORMAL: c.V_28692,
        valorMINPADiastolica: c.V_28805,
        valorMAXPADiastolica: c.V_28806,
        unidademedida: c.V_104198,
        valorMinimoReferencia: c.V_104209,
        valorMaximoReferencia: c.V_104210
      } as IExame;
    });

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
   * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
   */
  protected getFiltered(
    cadastroNo: number,
    strFilter: string,
    startDate: Date = null,
    endDate: Date = null,
    isFilterAnd: boolean = true,
    lstVariables: string = null
  ): Observable<IExame> {
    try {
      this.cadastroSrv.dtInicial = startDate
        ? this.glb.dateToYYYYMMddThhmmss(startDate)
        : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate
        ? this.glb.dateToYYYYMMddThhmmss(endDate)
        : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(
          cadastroNo,
          lstVariables,
          strFilter,
          isFilterAnd,
          startDate,
          endDate
        )
        .pipe(this.mapTo(), publishReplay(), refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save<T>(
    ano: number,
    Exame: T,
    uno: number,
    id?: number
  ): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(
        (Exame as unknown) as IExame
      ).filter((f) => f.ValorDado);
      return this.cadastroSrv.save(
        forms,
        uno,
        this.cadastroNo,
        id,
        true,
        this.codigoVariavelNo
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(exame: IExame): IForm[] {
    try {
      return [
        { VariavelNo: 28598, ValorDado: exame.codigo || '' },
        { VariavelNo: 28599, ValorDado: exame.titulo || '' },
        { VariavelNo: 28600, ValorDado: exame.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 28601, ValorDado: exame.valorMINPASistolica || '' },
        { VariavelNo: 28602, ValorDado: exame.descricao || '' },
        { VariavelNo: 28603, ValorDado: exame.valorMAXPASistolica || '' },
        { VariavelNo: 28604, ValorDado: exame.categoria || '' },
        { VariavelNo: 28609, ValorDado: exame.mENSAGEMDEALERTAMaior || '' },
        { VariavelNo: 28623, ValorDado: exame.mENSAGEMDEALERTAMenor || '' },
        { VariavelNo: 28650, ValorDado: exame.cadNo || '' },
        { VariavelNo: 28692, ValorDado: exame.mENSAGEMDEALERTAEXAMENORMAL || '' },
        { VariavelNo: 28805, ValorDado: exame.valorMINPADiastolica || '' },
        { VariavelNo: 28806, ValorDado: exame.valorMAXPADiastolica || '' },
        { VariavelNo: 104198, ValorDado: exame.unidademedida || '' },
        { VariavelNo: 104209, ValorDado: exame.valorMinimoReferencia || '' },
        { VariavelNo: 104210, ValorDado: exame.valorMaximoReferencia || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist<T>(
    ano: number,
    Exame: T,
    uno: number,
    compareFieldName: string = 'titulo'
  ): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null).pipe(
        toArray(),
        mergeMap((items) => {
          const founded =
            items &&
            items.findIndex((f) =>
              this.glb.isEqual(f[compareFieldName], Exame[compareFieldName])
            ) >= 0;
          if (!founded) {
            return this.save(ano, Exame, uno);
          }
          return of(founded);
        })
      );
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'insertIfNotExist',
        error.message
      );
    }
    return of(null);
  }


}
