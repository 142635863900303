import { IExecucaoTarefa } from './iexecucao-tarefa';
import { IAtividadeSequencial } from './iatividade-sequencial';
import { IPermission } from '@medlogic/shared/gecore';
import { IUsuario, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';

export interface IAtividade {
  AtividadeNo: number;
  Nome: string;
  Width: number;
  Height: number;
  ClienteNo: number;
  Type: string;
  blInativo: boolean;
  Versao: number;
  VersaoConteudo: number;
  TotalAcesso: number;
  CustomProperty: string;
  IconNo: number;
  NumExecucao: number;
  TabName: string;
  NomeCampo: string;
  TabIndex: number;
  CadastroPapelNo: number;
  ShowPrint: boolean;
  TextPrint: string;
  ShowHistory: boolean;
  TextHistory: string;
  ShowBack: boolean;
  TextBack: string;
  TextBackFormula: string;
  ShowSave: boolean;
  TextSave: string;
  ShowComplete: boolean;
  TextComplete: string;
  AskConfirmation: boolean;
  ConfirmationTitle: string;
  ConfirmationMessage: string;
  ConfirmationBtnOK: string;
  ConfirmationBtnCancel: string;
  ShowAttachment: boolean;
  TextAttachment: string;
  ShowQRCode: boolean;
  TextQRCode: string;
  ShowMessage: boolean;
  TextMessage: string;
  ShowSaveAndNew: boolean;
  TextSaveAndNew: string;
  TextSaveAndNewTime: number;
  ShowSaveModel: boolean;
  TextSaveModel: string;
  IsEncrypted: boolean;
  EncryptedValidationKeyword: string;
  ShowName: boolean;
  ShowUrgent: boolean;
  IsUrgent: boolean;
  IsOpenFind: boolean;
  IsSaveRegister: boolean;
  VariableIdentify: string;
  VariablesFind: string;
  VariablesSet: string;
  ShowPrevious: boolean;
  TextPrevious: string;
  SetPreviousTabName: boolean;
  ShowNext: boolean;
  TextNext: string;
  SetNextTabName: boolean;
  UrlCustomReport: string;
  CalcTarefaNo: number;
  _AtividadeComponente: Array<IAtividadeComponenteDAL>;
  CalcLstAtividadeSequencial: Array<IAtividadeSequencial>;
  CalcblDestinatario: boolean;
  CalcblDataTarefa: boolean;
  CalcblHoraTarefa: boolean;
  CalcAtribuicaoTitulo: string;
  CalcAtribuicaoDestinatarioTitulo: string;
  CalcEnAtribuicaoTipo: number;
  CalcAgendamentoListaUsuarioPapelNo: number;
  CalcVariavelIdentificacao1No: number;
  CalcVariavelIdentificacao2No: number;
  CalcVariavelIdentificacao1Valor: string;
  CalcVariavelIdentificacao2Valor: string;
  CalcProcessoNoBaseadoTarefa: number;
  CalcAtribuicaoPermitirUso: string;
  CalcAtribuicaoUsuarioPublico: number;
  CalcAtribuicaoMensagemPublico: string;
  UrlPrint: string;
  LstAtividadeComponenteExecTaref: Array<IExecucaoTarefa>;
  LstAtividadeComponenteUsuario: Array<IUsuario>;
  permission: IPermission;
  ProcessoNo: number;
  TarefaNo: number;
  OcorrenciaNo: number;
  TarefaNome: string;
  AgendamentoListaUsuarioPapelNo: any;
  AgendamentoUsuarioNo: any;
  blAtividadeContinua: boolean;
  blDataHoraTarefa: boolean;
  CalcVariavelIdentificacao3No: number;
  DtInicio: string;
  EnAtribuicaoTipo: number;
  enPassoTipo: number;
  HoraInicio: string;
  OcorrenciaStatusNo: number;
  PapelNo: number;
  ParalelismoId: any;
  UsuarioNo: number;
  // Adicionados no app
  ShowDelete?: boolean;
  ShowDuplicate?: boolean;
}
