import { IAppMedlogicState, IExameResultado } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { withLatestFrom } from 'rxjs/operators';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { ExameResultadoCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { ExameResultadoActionTypes, fetchErrorExameResultado, fetchSuccessExameResultado, saveSuccessExameResultado, saveFailExameResultado, deleteSuccessExameResultado, deleteFailExameResultado } from './exame-resultado.actions';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';

export const TAG_PACIENT = '#PACIENTE#';

@Injectable()
export class ExameResultadoEffects {

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private exameResultadoSrv: ExameResultadoCustomService,
  ) { }


  loadExameResultado$ = createEffect(() => this.actions$
    .pipe(
      ofType(ExameResultadoActionTypes.LoadExameResultados),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.exameResultadoSrv.getByPerson(state?.tenant?.selectedTenant?.exameResultadoAno, state?.person?.person?.codigo)
          .pipe(
            switchMap((resultados: IExameResultado[]) => [
              resultados ? fetchSuccessExameResultado({ resultados }) : fetchErrorExameResultado({ resultados }),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              this.store.dispatch(setIsLoading({ isLoading: false }));
              return of(fetchErrorExameResultado(null));
            })
          );
      })
    )
  );

  saveExameResultado$ = createEffect(() => this.actions$
    .pipe(
      ofType(ExameResultadoActionTypes.SaveExameResultado),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        this.store.dispatch(setIsLoading({ isLoading: true }));
        const result = this.fillPatient(state);
        // Muito importante capturar esse valor, pois, o state pode ser modificado enquanto os dados retornam.
        const guid = state?.exameResultado?.guid || result?.codigo;
        const id = this.glb.isGUID(result?.codigo) ? null : +result?.codigo;
        return this.exameResultadoSrv.save(
          state?.tenant?.selectedTenant?.exameResultadoAno,
          result,
          state?.tenant?.login?.usuarioLogadoNo,
          id
        )
          .pipe(
            switchMap((resultado: IExameResultado) => [
              resultado ? saveSuccessExameResultado({ resultado, guid }) : saveFailExameResultado({ resultado }),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              this.store.dispatch(setIsLoading({ isLoading: false }));
              return of(fetchErrorExameResultado(null));
            })
          );
      })
    )
  );

  deleteExameResultado$ = createEffect(() => this.actions$
    .pipe(
      ofType(ExameResultadoActionTypes.DeleteExameResultado),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        this.store.dispatch(setIsLoading({ isLoading: true }));
        const guid = state?.exameResultado?.guid?.toString();
        const id = this.glb.isGUID(guid) ? null : +guid;
        return !id ? EMPTY : this.exameResultadoSrv.deleteById(
          state?.tenant?.selectedTenant?.exameResultadoAno,
          id
        ).pipe(
          switchMap((deletedId: number) => [
            deletedId && deletedId > 0 ? deleteSuccessExameResultado({ deletedId }) : deleteFailExameResultado({ deletedId }),
            setIsLoading({ isLoading: false })
          ]),
          catchError((e: any) => {
            console.log(e);
            this.store.dispatch(setIsLoading({ isLoading: false }));
            return of(fetchErrorExameResultado(null));
          })
        );
      })
    )
  );

  private fillPatient(state: IAppMedlogicState) {
    const result = { ...state?.exameResultado?.resultado };
    const patient = state?.person?.person;
    result.codigoPaciente = patient?.ocorrenciaNo;
    result.codPacCAT = result?.codPacCAT?.replace(TAG_PACIENT, result.codigoPaciente);
    result.codPacOcorrenciaCAT = result?.codPacOcorrenciaCAT?.replace(TAG_PACIENT, result.codigoPaciente);
    result.titulo = result?.titulo?.replace(TAG_PACIENT, patient?.nomeResidente);
    return result;
  }


}
