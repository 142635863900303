import { LogService } from '@medlogic/shared/shared-interfaces';
import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appRandomBgColor]',
})
export class RandomBgDirective {

  @Input('appRandomBgColor') color: string;

  private el: HTMLElement;

  colors = [
    '#ffadad',
    '#ffd6a5',
    'rgb(222 227 73)',
    'rgb(181 227 172)',
    '#9bf6ff',
    '#a0c4ff',
    '#bdb2ff',
    '#ffc6ff',
  ];

  constructor(el: ElementRef,
    private log: LogService) {
    this.el = el.nativeElement;
    this.randomOrDefinedColor(this.color, this.colors);
  }

  private randomOrDefinedColor(color: string, colors: string[]): void {
    try {
      const rnd = Math.round(Math.random() * colors?.length);
      const randomColor = colors[rnd];
      this.el.style.backgroundColor = color || randomColor;
    } catch (_error) {
      this.log.Registrar(this.constructor.name, 'randomOrDefinedColor', _error.message);
    }
  }


}
