import { IFhirPatient } from './ifhir-patient';
import { ICarePlan } from './icare-plan';

export interface IPatientOnCarePlan {
    patientOnCareplanIdentifier: string;
    patientIdentifier: string;
    patient: IFhirPatient[];
    carePlanIdentifier: string;
    carePlan: ICarePlan[];
}
