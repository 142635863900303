<ng-container *ngIf="usrProcess$ | async as usrProcess; else loading">
  <ng-container *ngIf="usrProcess && usrProcess.length > 0; else empty">
    <button *ngFor="let process of usrProcess" type="button" class="btn btn-white btn-block"
      (click)="onClickProcess(process, patient)">
      <img [src]="process?.UrlIcon" class="icn" />
      <span class="bold">{{ process?.Label }}</span>
    </button>
  </ng-container>
</ng-container>
<ng-template #empty>
  <h2>Nenhum registro encontrado.</h2>
</ng-template>
<ng-template #loading>
  <ui-loading-spinner></ui-loading-spinner>
  <span>Carregando...</span>
</ng-template>
