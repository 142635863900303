import { IActivityDetailState } from './activityDetail-state';
import { IActivityDetail } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as ActivityDetailActions from './activityDetail.actions';


export const activityDetailFeatureKey = 'activityDetail';


export const adapterActivityDetail: EntityAdapter<IActivityDetail> = createEntityAdapter<IActivityDetail>({
  selectId: instance => instance.activityDetailIdentifier,
  sortComparer: sortOnIdentifier
});

export const initialStateActivityDetail: IActivityDetailState = adapterActivityDetail.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
});

function sortOnIdentifier(a: IActivityDetail, b: IActivityDetail) {
  if (a.activityIdentifier === b.activityIdentifier) {
    return a.activityIdentifier < b.activityIdentifier ? -1 : 1; //TODO: Validar se estes parâmetros estão corretos
  } else {
    return a.activityIdentifier ? -1 : 1;
  }
}
// function sortOnDateTime(a: IActivityDetail, b: IActivityDetail) {
//   if (a.scheduled[0].scheduledTiming. === b.activityDetailDateTime) {
//     return a.activityDetailDateTime < b.activityDetailDateTime ? -1 : 1;
//   } else {
//     return a.activityDetailDateTime ? -1 : 1;
//   }
// }

export const reducer = createReducer(
  initialStateActivityDetail,
  on(ActivityDetailActions.addActivityDetail,
    (state, action) => adapterActivityDetail.addOne(action.activityDetail, state)
  ),
  on(ActivityDetailActions.setActivityDetail,
    (state, action) => ({ ...state, selectedIdentifier: action.selectedId })
  ),
  on(ActivityDetailActions.upsertActivityDetail,
    (state, action) => adapterActivityDetail.upsertOne(action.activityDetail, state)
  ),
  on(ActivityDetailActions.addActivityDetails,
    (state, action) => adapterActivityDetail.addMany(action.activityDetails, state)
  ),
  on(ActivityDetailActions.upsertActivityDetails,
    (state, action) => adapterActivityDetail.upsertMany(action.activityDetails, state)
  ),
  on(ActivityDetailActions.updateActivityDetail,
    (state, action) => adapterActivityDetail.updateOne(action.activityDetail, state)
  ),
  on(ActivityDetailActions.updateActivityDetails,
    (state, action) => adapterActivityDetail.updateMany(action.activityDetails, state)
  ),
  on(ActivityDetailActions.deleteActivityDetail,
    (state, action) => adapterActivityDetail.removeOne(action.id, state)
  ),
  on(ActivityDetailActions.deleteActivityDetails,
    (state, action) => adapterActivityDetail.removeMany(action.ids, state)
  ),
  on(ActivityDetailActions.loadActivityDetailsSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterActivityDetail.upsertMany(action.activities, state)
    (state, action) => adapterActivityDetail.upsertMany(action.activityDetails, state)
  ),
  on(ActivityDetailActions.loadActivityDetailSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterActivityDetail.upsertMany(action.activities, state)
    (state, action) => adapterActivityDetail.upsertOne(action.activityDetail, state)
  ),
  on(ActivityDetailActions.activityDetailFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(ActivityDetailActions.clearActivityDetails,
    state => initialStateActivityDetail
  ),
  on(ActivityDetailActions.clearSelectedActivityDetail,
    state => ({ ...state, selectedIdentifier: null })
  ),
  on(ActivityDetailActions.loadActivityDetails,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterActivityDetail.getSelectors();


export const getSelectedId = (state: IActivityDetail) => state.activityStatusIdentifier; //TODO: Validar se este parâmetro está correto.
