import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppMedlogicPwaCuidadoState } from '../states/app-state';
import { EnLoginState, ILogin } from '@medlogic/shared/shared-interfaces';

import * as fromLoginPWA from '../reducers/login.reducer';

export const selectLoginPWAState = createFeatureSelector<ILogin>(fromLoginPWA.loginPWAFeatureKey);

export const selectLogin = (state: AppMedlogicPwaCuidadoState) => state?.loginPWA;
export const selectSelectedLogin = (state: AppMedlogicPwaCuidadoState) => state?.loginPWA?.selectedLogin;
export const selectIsLoading = (state: any) => {
    return state?.appMedlogic?.isLoading;
};
export const selectUserName = createSelector(
    selectSelectedLogin,
    state => state?.usuarioLogadoNome
);

export const selectUserId = createSelector(
    selectSelectedLogin,
    state => state?.usuarioLogadoNo
);

export const selectEnLoginState = (state: any) => {
    if (state?.appMedlogic?.isLoading) { return EnLoginState.Loading; }
    return state?.loginPWA?.selectedLogin?.usuarioLogadoNo ? EnLoginState.Logged : EnLoginState.Login;
};
