import { LogService } from '@medlogic/shared/shared-interfaces';
import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

/* Irá redefinir o zoom de uma div baseado numa largura mínima.
 * Ou seja, se a largura mínima não for comportada dentro da largura da tela,
 * um zoom será automaticamente aplicado, proporcionamente.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[minZoom]',
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    // '(click)': 'onClick()',
    // '(mouseenter)': 'onMouseEnter()',
    // '(mouseleave)': 'onMouseLeave()'
    '(window:resize)': 'onResize()'
  }
})
// @HostListener('window:resize', ['$event'])

export class MinZoomDirective implements OnInit {

  MARGIN = 30;
  MIN_ZOOM = 0.80;
  @Input('minZoom') minWidthPx: number;
  private el: HTMLElement;
  // private _defaultFontSize: string = 'initial';
  // private _growthFontSize: string = 'x-large';

  constructor(el: ElementRef,
              private renderer: Renderer2,
              private log: LogService) {
    this.el = el.nativeElement;
  }

  ngOnInit() {
    this.applyZoom(this.el, +this.minWidthPx);
  }

  onResize(event: any): void {
    this.applyZoom(this.el, +this.minWidthPx);
  }

  // onMouseEnter() { this.growth(this.fontSize || this._growthFontSize); }
  // onMouseLeave() { this.growth(this._defaultFontSize); }

  // onClick() {
  //   try {
  //     let fontSize: string = this.fontSize || this._growthFontSize;
  //     fontSize = this.el.style.fontSize == fontSize ? this._defaultFontSize : fontSize;
  //     this.growth(fontSize);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'onClick', error.message);
  //   }
  // }

  private applyZoom(el: HTMLElement, minWidthPx: number): void {
    try {
      const screenWidth: number = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let zoom = minWidthPx <= screenWidth ? 1 : screenWidth / minWidthPx;
      zoom = Math.max(this.MIN_ZOOM, zoom);
      // this.el.style.zoom = zoom.toString();
      // this.renderer.setElementStyle(this.el, 'zoom', `${zoom}`);
      this.renderer.setStyle(this.el, '-moz-transform', `scale(${zoom})`);
      this.renderer.setStyle(this.el, '-moz-transform-origin', `0 0`);
      this.renderer.setStyle(this.el, '-o-transform', `scale(${zoom})`);
      this.renderer.setStyle(this.el, '-o-transform-origin', `0 0`);
      this.renderer.setStyle(this.el, '-webkit-transform', `scale(${zoom})`);
      this.renderer.setStyle(this.el, '-webkit-transform-origin', `0 0`);
      this.renderer.setStyle(this.el, 'transform', `scale(${zoom})`);
      this.renderer.setStyle(this.el, 'transform-origin', `0 0`);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyZoom', error.message);
    }
  }
}
