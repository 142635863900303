import { IExame } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { Injectable } from '@angular/core';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LocalLibService } from '@medlogic/shared/utils';
import { ExameService } from './exame.service';
import { Observable, of } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';

@Injectable()
export class ExameCustomService extends ExameService {

    constructor(
        cadastroSrv: CadastroService,
        glb: GlobalService,
        cnf: ConfigStateService,
        lib: LocalLibService,
        log: LogService) {
        super(cadastroSrv, glb, cnf, lib, log);
    }


    getByPerson(ano: number, idPerson: number): Observable<IExame> {
        try {
            if (ano && ano > 0) {
                this.cadastroNo = ano;
                const startDate = new Date(1900, 0, 1);
                const endDate = new Date(2500, 0, 1);
                const strFilter = `V_2230:${idPerson}`;
                return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate)
                    .pipe(
                        defaultIfEmpty(null)
                    );
            }
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getbyPerson', error.message);
        }
        return of(null);
    }


}
