import { EnStockChangeMode } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { EnViewMode } from '../enum/en-view-mode.enum';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { Subject, BehaviorSubject } from 'rxjs';

/* Pelo padrão estabelecido, providers são mecanismos de comunicação
* entre os componentes. */
@Injectable()
export class AppProvider {

  search$ = new Subject<string>();
  selectedAccount$ = new BehaviorSubject<any>(null);

  // tslint:disable-next-line: variable-name
  private _enViewMode = EnViewMode.browse;
  public get enViewMode(): EnViewMode {
    if (!this.cnf.isLogged()) {
      return EnViewMode.login;
    }
    return this._enViewMode;
  }

  public set enViewMode(v: EnViewMode) {
    this._enViewMode = v;
  }

  enStockChangeMode: EnStockChangeMode;

  constructor(
    private cnf: ConfigPwaMedLogicService
  ) {

  }


}
