import { EvolutionCustomService, InterventionMedicationCustomService, VitalSignCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { IPatient, IMedlogicEvolution, IMedlogicProfessional, ITenant, AppLogService } from '@medlogic/shared/shared-interfaces';
import { ActivatedRoute } from '@angular/router';
import { ResponsibleFamilyService } from '../../service/responsible-family.service';
import { IEmergencyData } from '../../interface/iemergencydata';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { ProfessionalCustomService } from '../../../assist/service/professional.custom.service';
import { defaultIfEmpty, filter, tap } from 'rxjs/operators';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import {
  HomeView,
  CustomerConfigService
} from '@medlogic/shared/gecore';
import { LocalMsgPtBR } from '../../../module/core/service/local-msg-ptBR.service';
import { firstValueFrom, forkJoin } from 'rxjs';
import { AppMedlogicPwaCuidadoState } from '../../../module/core/ngrx/states/app-state';
import { Store, select } from '@ngrx/store';
import { GlobalService, ICadParam } from '@medlogic/shared/shared-interfaces';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { MaterialDialogService } from '@medlogic/shared/gecore';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { Observable, of } from 'rxjs';
import { selectSelectedLogin } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';

@Component({
  selector: 'app-emergency-data',
  templateUrl: './emergency-data.component.html',
  styleUrls: ['./emergency-data.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EmergencyDataComponent extends HomeView implements OnInit {

  cadParams: ICadParam[];
  dataEmergency: IEmergencyData;
  interMed: any = [];
  resFamily: any = [];
  signVital: any = [];
  isLoading = true;
  MAX_DAYS_VITAL_SIGN = 30;
  NUM_OF_EVOLUTIONS = 5;
  today = new Date();
  clientLogo: string;
  selectedPractitioner$ = this.store.select(selectSelectedLogin);
  public get cadResponsavelFamiliaNo(): number {
    // return this.getParam('cadResponsavelFamiliaNo') || -1;
    return this.tenant ? this.tenant.cadResponsavelFamiliaNo : -1;
  }

  public get cadIntervecoesMedicamentosNo(): number {
    // return this.getParam('cadIntervecoesMedicamentosNo') || -1;
    return this.tenant ? this.tenant.cadIntervecoesMedicamentosNo : -1;
  }

  public get cadVitalSignsNo(): number {
    // return this.getParam('cadVitalSignsNo') || -1;
    return this.tenant ? this.tenant.cadVitalSignsNo : -1;
  }

  public get cadEvolutionsNo(): number {
    // return this.getParam('cadEvolutionsNo') || -1;
    return this.tenant ? this.tenant.cadEvolutionsNo : -1;
  }

  public get cadProfessionalsNo(): number {
    // return this.getParam('cadProfessionalsNo') || -1;
    return this.tenant ? this.tenant.cadProfessionalsNo : -1;
  }

  tenant: ITenant;

  constructor(
    protected cnf: ConfigPwaMedLogicService,
    protected log: AppLogService,
    protected glb: GlobalService,
    protected nav: NavigationPwaService,
    protected route: ActivatedRoute,
    protected matDialog: MaterialDialogService,
    protected modelComponent: ModelComponent,
    private resFamilySrv: ResponsibleFamilyService,
    private intervMedSrv: InterventionMedicationCustomService,
    private vitalSignSrv: VitalSignCustomService,
    private evolucaoSrv: EvolutionCustomService,
    private professionalSrv: ProfessionalCustomService,
    msg: LocalMsgPtBR,
    custCnf: CustomerConfigService,
    private store: Store<AppMedlogicPwaCuidadoState>
  ) {
    super(log, glb, cnf, route, nav, modelComponent, custCnf, msg);
  }

  ngOnInit() {
    try {
      this.isLoading = true;

      this.nav.addToHistory(this.route.snapshot.url.map(m => m.path), '[emergencydata] EmergencyData');

      firstValueFrom(this.selectedPractitioner$).then(client => this.clientLogo = client?.imgUrl);

      this.subs.sink = this.store.select(state => state.tenant.selectedTenant)
        .subscribe(tenant => {
          this.tenant = tenant;
          this.refresh(this.cnf.selectedPatient);
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  protected setCadParams(): void {
    try {
      // this.cadParams = [
      //   { idName: 'cadResponsavelFamiliaNo', label: 'Cad_Responsavel_Familia' } as ICadParam,
      //   { idName: 'cadIntervecoesMedicamentosNo', label: 'Cad_Intervecoes_Medicamentos' } as ICadParam,
      //   { idName: 'cadVitalSignsNo', label: 'Cad_SinaisVitais_SinglePage' } as ICadParam,
      //   { idName: 'cadEvolutionsNo', label: 'Cad_Evolucao_SinglePage' } as ICadParam,
      //   { idName: 'cadProfessionalsNo', label: 'Cad_Profissionais' } as ICadParam
      // ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
  }

  protected setCurrentRoute(): void {
    try {
      this.currentRoute = this.nav.getRouteCardPatient(this.cnf.modeloConfigNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCurrentRoute', error.message);
    }
  }

  /* Carregamento inicial da pãgina. */
  protected refresh(patient: IPatient): void {
    try {
      this.subs.sink = this.getAll(patient)
        .subscribe(emergencyData => {
          this.dataEmergency = emergencyData;
          this.isLoading = false;
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Resgata todos os cadastros necessários (CAD - Responsável Família, Intervenção Medicamento, Sinais Vitais)
    * em paralelo e retorna o objeto final.
    */
  getAll(patient: IPatient): Observable<IEmergencyData> {
    try {
      const filtro = `V_28051:${patient.codigo}`;
      const resFam$ = this.resFamilySrv.getFromCadastroFiltro(this.cadResponsavelFamiliaNo, filtro, null, null)
        .pipe(
          this.cleanEmptySimbol(),
          toArray()
        );
      const filtro1 = `V_29828:${patient.codigo}`;
      const interMed$ = this.intervMedSrv
        .getActiveMedicationFiltered(this.cadIntervecoesMedicamentosNo, filtro1, null, null)
        .pipe(
          this.cleanEmptySimbol(),
          toArray()
        );
      const filtro2 = `V_2230:${patient.codigo}`;
      const endDate = this.glb.addDays(new Date(), 1);
      const startDate = this.glb.addDays(endDate, -this.MAX_DAYS_VITAL_SIGN);
      const sigVit$ = this.vitalSignSrv.getFromCadastroFiltro(this.cadVitalSignsNo, filtro2, startDate, endDate)
        .pipe(
          this.cleanEmptySimbol(),
          toArray(),
          map(items =>
            items.sort((a, b) => this.glb.compareDates(b.dataavaliacao, a.dataavaliacao,))
          )
        );
      const evolutions$ = this.evolucaoSrv
        .getByCodigoPaciente(this.cadEvolutionsNo, patient.codigo, startDate, endDate)
        .pipe(
          this.fillProfessional(),
          this.evolucaoSrv.fillEvolucaoDescricaoHtml(true, false),
          filter(f => !this.glb.isNullOrEmpty(f.descricaoHtml)),
          //  takeLast(this.NUM_OF_EVOLUTIONS),
          toArray(),
          this.evolucaoSrv.sortByDate()
        );
      return forkJoin([resFam$, interMed$, sigVit$, evolutions$])
        .pipe(
          mergeMap(([res, med, sig, ev]) => {
            const emergency = {
              nomePaciente: patient.nomeHospede,
              idade: patient.idade,
              foto: patient.foto,
              planosaude: patient.planoSaude,
              medicoresponsavel: patient.medicoReferencia,
              telefone: patient.telefone,
              alergia: (patient.seSimQual === '') ? 'Não possui alergia.' : patient.seSimQual,
              deficiencia: (patient.seSimQual2 === '') ? 'Não possui deficiência.' : patient.seSimQual2,
              perfilCliente: patient.perfilCliente,
              diagnostico: patient.diagnostico,
              info: patient.informacoesImportante,
              familiares: res,
              medicamentos: med,
              sinaisvitais: sig,
              evolucoes: ev
            } as IEmergencyData;
            return of(emergency);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, ' getAllFamilyOfPatient', error.message);
    }
    return of(null);
  }

  /* Irá popular uma propriedade na Evolução com os dados completos do profissional que evoluiu.  */
  protected fillProfessional = () => mergeMap((evolution: IMedlogicEvolution) => {
    return this.professionalSrv.getOne(this.cadProfessionalsNo, evolution.executorAvaliacao)
      .pipe(
        defaultIfEmpty(null),
        mergeMap((professional: IMedlogicProfessional) => of({ ...evolution, professional } as IMedlogicEvolution))
      );
  })

  /* Operador rxjs para Limpar símbolos de #VAZIO#. */
  protected cleanEmptySimbol = () => map((m: any) => {
    try {
      if (m) {
        for (const clm in m) {
          if (clm) {
            m[clm] = this.glb.IsNullOrEmptyGE(m[clm]) ? '' : m[clm];
          }
        }
      }
      return m;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanEmptySimbol', error.message);
    }
    return null;
  })

  /* Click no botão voltar. */
  onBack(): void {
    try {
      this.nav.gotoRoot(this.cnf.tenantId);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  /* Impressão da tela. */
  onPrintClick($event: any): void {
    try {
      window.print();
      window.close();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onPrintClick', error.message);
    }
  }


}
