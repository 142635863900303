export interface IGetPersonAuthorizedRequest {
    personAno: number;
    personEducatorAno: number;
    personResponsibleAno: number;
    personProfessionalAno: number;
    idSchool: number;
    startDate?: string;
    endDate?: string;
    isFilterAnd?: boolean;
    lstVariables?: string;
}
