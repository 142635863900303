import { Subject } from 'rxjs';
import { IKeyValue } from './ikey-value';
import { EnumCtrType } from '../enum/enum-ctr-type.enum';
import { EnumTipoLista } from '../enum/enum-tipo-lista.enum';

export interface IAtividadeComponenteDAL {
  AtividadeComponenteNo: number;
  AtividadeNo: number;
  Type: string;
  EnCtrType: EnumCtrType;
  Linha: number;
  Coluna: number;
  Largura: number;
  Altura: number;
  Nome: string;
  TabIndex: number;
  TabOrder: number;
  TabName: string;
  CustomProperty: string;
  PropriedadeNome: string;
  EstruturaGrid: any[];
  lstCadastroAdicional: Array<any>;
  Rotulo: string;
  ValorDefault: string;
  lstValue: any; // string[];deveria ser um array mas tem uma propriedade lstValue?.string (esse é o array)
  lstKey: any; // string[];deveria ser um array mas tem uma propriedade lstValue?.string (esse é o array)
  Posicao: number;
  CadastroNo: number; // Número do Cadastro que contém a lista de itens. Deve ser retornado na chamada do método
  TipoLista: string; // Na especificação do Wsdl está com esse nome
  EnTipoLista: EnumTipoLista; // Armazena o tipo de lista associada ao controle
  VariavelRetornoNo: number; // Número da variável que retornará com o valor preenchido do Cadastro
  VariavelAssociadaNo: number; // Número da variável referenciada (deverá estar na mesma Atividade) e que deve ter o change monitorado
  VariavelComparacaoNo: number;
  // Variável do próprio cadastro que alimenta a lista do controle, que será usada para comparar com o valor para fazer o filtro
  DadoNo: number;
  OcorrenciaNo: number;
  VariavelNo: number;
  Versao: number;
  IsEnable: boolean;
  IsVisible: any;
  ConditionVisible: string;
  AtividadeCadastroNo: number;
  TypeRegister: number; // Indica se o campo é uma das propriedades obrigatórios num cadastro 1 código, 2 título, ou 3 habilitado
  RequiredField: boolean;
  InputMaskType: string;
  MaxLength: number;
  ShowRotulo: boolean;
  ConditionReadOnly: string;
  wasDecripted: boolean;
  Valor: string;
  ValorTexto: any;
  ValorData: Date;
  ValorDataMMddyyyy: string;
  ValorNumero: number;
  ValorInteiro: number;
  lstItems: IKeyValue[];
  LstCamposAdicionais: any;
  // Será populada apenas no caso de Grid ou Combobox com propriedades como Largura e visibilidade para cada coluna
  LstAtividadeComponente: any[];
  ShowSearch: boolean;
  ShowAttachmentButton: boolean;
  ShowAdvancedGridButton: boolean;
  // Modificar para iniciar em false quando a propriedade for criada no Studio e retornada no getComponentes
  // propriedades Mapa
  MapaIsEnable: boolean;
  ShowZoomControl: boolean;
  ShowStreetViewControl: boolean;
  ShowScaleControl: boolean;
  ShowMapTypeControl: boolean;
  // Propriedades SearchCombobox
  AutoComplete: boolean;
  CanAddItem: boolean;
  CanAddInCadastro: boolean;
  PartialSearch: boolean;
  MainSearchFieldName: string;
  SearchFields: any[];
  RowHeight: number;
  ColumnWidth: number;
  // Propriedades de CheckboxList
  NumberOfColumn: number;
  // Propridades do Grid
  CanExcludeItem: boolean;
  CanEditItem: boolean;
  CanReadItem: boolean;
  ShowInMobile: boolean;
  CanEditMultLines: boolean;
  // Propriedades de alerta e validação customizada
  Validation: string; // Fórmula de CustomValidation
  ValidationMessage: string; // Mensagem a ser exibida caso não passe na validação
  IsShowValidationMessage: boolean;
  IsShowToolTipMessage: boolean;
  ToolTipMessage: string; // Mensagem de explicação do campo
  Alert: string; // Fórmula de CustomAlert
  AlertMessage: string; // Mensagem a ser exibida caso não passe na validação
  AlertColor: string; // Fórmula de CustomAlert
  IsShowAlertMessage: boolean; // Mensagem a ser exibida caso não passe na validação
  // Propriedade de controle do tipo lista
  UrlWebApiRest: string;
  isEncrypted: boolean; // Indica se os campos foram criptografados.
  encryptedKeyword: string;
  // Campos calculados
  lstControlesReferenciadosPorFormula: Array<IAtividadeComponenteDAL>;
  lstControlesReferenciadosCustomValidation: Array<IAtividadeComponenteDAL>;
  lstControlesReferenciadosCustomAlert: Array<IAtividadeComponenteDAL>;
  lstControlesReferenciadosReadOnly: Array<IAtividadeComponenteDAL>;
  lstControlesReferenciadosVisibility: Array<IAtividadeComponenteDAL>;
  TypeCadastro: string;
  // A ser preenchido com o controle html relacionado a esse item
  refCtrl?: any;
  StyleText: string;
  AsyncValue: Subject<any>; // Utilizado para permitir a modificação assíncrona do ValorTexto.
  hashLstControlesReferenciadosVisibility?: number;
  hashLstControlesReferenciadosReadOnly?: number;
  hasLstControlesReferenciadosCustomAlert?: number;
  hasLstControlesReferenciadosPorFormula?: number;
  hasValueDependence: boolean;
  // utilizado para determinar se o componente depende de outro para
  // ter o Valor definido e não apenas a condição de visibilidade e/ou edição.
  // Calculados dinamicamente
  isAlwaysUpdate?: boolean;
}
