export interface IIvcf {
    ocorrenciaNo: number;
    nomePaciente: string;
    dataNascIdoso: Date;
    campoHumorConcatenaV2835_e_V2836: string;
    nomeMaeIdoso: string;
    CalculaidadeIdoso: string;
    ptIdade2VerificaSeIdadeEntre75e84: string;
    ptIdade3VerificaSeIdadeMaior85: string;
    pontuacaoIdade_V2368_e_V2369: number;
    EmGeralComparandoComOutrasPessoasSuaIdadeVoceDiriaQueSuaSaude_ExcelenteOuRegular: boolean;
    pontuacaoAutoPercepcao: number;
    PorCausasuaSaudeOuCondicaoFisicaVoceDeixoufazerCompras_SimOuNao: boolean;
    pt3CalcPontuacaoV_2379: string;
    pt4CalcPontuacaoV_2382: string;
    PorCausasuaSaudeOuCondicaoFisicaVoceDeixoucontrolarSeuDinheiroGastosOuPagarAsContassuaCasa_SimOuNao: boolean;
    pt5CalcPontuacaoV_2384: string;
    PorCausasuaSaudeOuCondicaoFisicaVoceDeixourealizarPequenosTrabalhosDomesticosComoLavarLoucaArrumarcasaOuFazerLimpezaLeve_SimOuNao: boolean;
    pontuacaoAVDInstrumental_V_2380V_2381V_2383: number;
    entendaMelhorEstaPerguntaVulnerabilidadeClinicoFuncional: string;
    entendaMelhorEstaPerguntaAutopercepcaoSaude: string;
    entendaMelhorEstasPerguntasEnvelhecimento: string;
    ExibeplanoSaude: string;
    PorCausasuaSaudeOuCondicaoFisicaVoceDeixoutomarBanhoSozinho_SimOuNao: boolean;
    pontuacaoAVDBasica_V_2419: number;
    entendaMelhorEstaPerguntaEnvelhecimento: string;
    pt7CalculaPontuacaoV_2429: string;
    pt8CalculaPontuacaoV_2426: string;
    EsteEsquecimentoEstaPiorandoNosUltimosMeses_SimOuNao: boolean;
    pt9CalculaPontuacaoV_2428: string;
    EsteEsquecimentoEstaImpedindorealizacaoalgumaAtividadecotidiano_SimOuNao: boolean;
    AlgumFamiliarOuAmigoFalouQueVoceEstaFicandoEsquecido_SimOuNao: boolean;
    entendaMelhorEstasPerguntasEsquecimento: string;
    pt10CalculaPontuacaoV_2437: string;
    pt11CalculaPontuacaoV_2434: string;
    NoUltimoMesVocePerdeuinteresseOuPrazerEmAtividadesAnteriormentePrazerosas_SimOuNao: boolean;
    NoUltimoMesVoceFicouComDesanimoTristezaOuDesesperanca_SimOuNao: boolean;
    entendaMelhorEstasPerguntasTranstornoDepressivo: string;
    pt12CalculaPontuacaoV_2442: string;
    pt13CalculaPontuacaoV_2441: string;
    VoceincapazmanusearOuSegurarPequenosObjetos_SimOuNao: boolean;
    VoceincapazelevarOsBracosAcimanivelombro_SimOuNao: boolean;
    entendaMelhorEstasPerguntasMobilidadeDeslocamento: string;
    pt141CalculaPontuacaoV_2449: string;
    PerdapesoNaoIntencional45KgOu5PesoCorporalNoUltimoAnoOu6KgNosUltimos6MesesOu3KgNoUltimoMes_SimOuNao: boolean;
    entendaMelhorEstasPerguntasCapacidadeAerobica: string;
    pt142CalculaPontuacaoV_2458: string;
    IndiceMassaCorporalIMCMenorQue22KgM2_SimOuNao: boolean;
    pt143CalculaPontuacaoV_2460: string;
    Circunferenciapanturrilha31Cm_SimOuNao: boolean;
    pt144CalculaPontuacaoV_2462: string;
    TempoGastoNoTestevelocidademarcha4m5Segundos_SimOuNao: boolean;
    pontuacaoSarcopenia: number;
    pt15CalculaPontuacaoV_2468: string;
    pt16CalculaPontuacaoV_2467: string;
    VoceTeveDuasOuMaisQuedasNoUltimoAno_SimOuNao: boolean;
    VoceTemDificuldadecaminharCapazimpedirrealizacaoalgumaAtividadecotidiano_SimOuNao: boolean;
    entendaMelhorEstasPerguntasInstabilidadePostural: string;
    pontuacaoContinencia: number;
    VocePerdeUrinaOuFezesSemQuererEmAlgumMomento_SimOuNao: boolean;
    entendaMelhorEstaPerguntaIncontinencia: string;
    pontuacaoVisao: number;
    VoceTemProblemasvisaoCapazesimpedirrealizacaoAlgumaAtividadeCotidianoEPermitidousooculosOuLentescontato_SimOuNao: boolean;
    entendaMelhorEstaPerguntaPerdaVisao: string;
    VoceTemProblemasaudicaoCapazesimpedirrealizacaoAlgumaAtividadecotidianoEPermitidousoaparelhosaudicao_SimOuNao: boolean;
    pontuacaoAudicao: number;
    entendaMelhorEstaPerguntaPerdaAuditiva: string;
    CincoOuMaisDoencasCronicas_SimOuNao: boolean;
    UsoRegularcincoOuMaisMedicamentosDiferentesTodoDia_SimOuNao: boolean;
    InternacaoRecenteNosUltimos6Meses_SimOuNao: boolean;
    pt201CalculaPontuacaoV_2486: string;
    pt202CalculaPontuacaoV_2487: string;
    pt203CalculaPontuacaoV_2488: string;
    pontuacaoComorbidade: number;
    entendaMelhorEstasPerguntasComorbidadesMultiplas: string;
    sistemasaudequeEstaVinculado_AtualmenteNaoUtilizado: string;
    pontuacaoFinalIVCF: number;
    andamentoIdade_NaoUsadoAtualmente: string;
    andamentoAutoPercepcaoSaude_NaoUsadoAtualmente: string;
    andamentoAVDInstrumental_NaoUsadoAtualmente: string;
    andamentoAVDBasica_NaoUsadoAtualmente: string;
    andamentoCognicao_NaoUsadoAtualmente: string;
    andamentoHumor_NaoUsadoAtualmente: string;
    andamentoMenbrosSuperiores_NaoUsadoAtualmente: string;
    andamentoSarcopenia_NaoUsadoAtualmente: string;
    andamentoMarcha_NaoUsadoAtualmente: string;
    andamentoContinencia_NaoUsadoAtualmente: string;
    andamentoComunicacaoVisual_NaoUsadoAtualmente: string;
    andamentoComuncacaoAudicao_NaoUsadoAtualmente: string;
    andamentoComorbidade_NaoUsadoAtualmente: string;
    planoSaudeRazaoSocial: string;
    esteQuestionarioEstaSendoPreenchidoPor: boolean;
    operadora: boolean;
    token: string;
    integracao: string;
    preenchimentoteste: boolean;
    executorIVCF: string;
    preFixoExecutor: string;
    formula10: string;
    formula11: string;
    descricao: string;
    identificacao1: string;
    identificacao2: string;
    pontuacaoCognicao: number;
    pontuacaoHumor: number;
    exibirAbaAvdinstrumentalAutoPercepcao_NaoUsadoAtualmente: string;
    exibirAbaAvdBasica_NaoUsadoAtualmente: string;
    exibirAbaCognicao_NaoUsadoAtualmente: string;
    exibirAbaHumor_NaoUsadoAtualmente: string;
    exibirAbaMembrosSuperiores_NaoUsadoAtualmente: string;
    exibirAbaAvdinstrumentalMembrosSuperiores_NaoUsadoAtualmente: string;
    exibirAbaAvdinstrumentalSarcopenia_NaoUsadoAtualmente: string;
    exibirAbaAvdinstrumentalMarcha_NaoUsadoAtualmente: string;
    exibirAbaComunicacaoVisual_NaoUsadoAtualmente: string;
    exibirAbaComunicacaoAudicao_NaoUsadoAtualmente: string;
    exibirAbaComorbidade_NaoUsadoAtualmente: string;
    urlImgPontuacaoFinal: string;
    altaVulnerabilidade: string;
    vulneModerada: string;
    baixaVulnerabilidade: string;
    IfQueSelecionaURL1ou2BV: string;
    urlImgBaixaBulnerabilidadeBranco: string;
    urlImgBaixaBulnerabilidadeVerde: string;
    urlImgBaixaVulnerabilidadeModeradaBranco: string;
    urlImgBaixaVulnerabilidadeModeradaAmarelo: string;
    IfQueSelecionaURL1ou2VM: string;
    urlImgAltaVulnerabilidadeBranco: string;
    urlImgAltaVulnerabilidadeVermelho: string;
    IfQueSelecionaURL1ou2AV: string;
    rOTULO: string;
    urlImgIdadeMenor85: string;
    urlImgIdadeMaior85: string;
    IfQueSelecionaURL1ou2Idade: string;
    IfQueSelecionaURL1ou2AutoPercepcao: string;
    urlImgAutoPercepcaoSaudeBoa: string;
    urlImgAutoPercepcaoSaudeRuim: string;
    url1Ou2: string;
    urlImgAvdInstrumentalBoa: string;
    urlImgAvdInstrumentalRuim: string;
    IfQueSelecionaURL1ou2AVDBasica: string;
    urlImgAvdBasicaBoa: string;
    urlImgAvdBasicaRuim: string;
    urlImgCognicaoRuim: string;
    IfQueSelecionaURL1ou2Cognicao: string;
    urlImgCognicaoBoa: string;
    urlImgHumorRuim: string;
    IfQueSelecionaURL1ou2Humor: string;
    urlImgHumorBoa: string;
    pontuacaoMembrosSuperiores: number;
    urlImgComunicacaoRuim: string;
    IfQueSelecionaURL1ou2MembrosSuperiores: string;
    urlImgComunicacaoBom: string;
    urlImgSarcopeniaNutricaoRuim: string;
    sarcopenia: string;
    urlImgSarcopeniaNutricaoBom: string;
    urlImgMobilidadeRuim: string;
    IfQueSelecionaURL3ou4MembrosSuperiores: string;
    urlImgMobilidadeBoa: string;
    pontuacaoMarcha: number;
    IfQueSelecionaURL1ou2Visao: string;
    urlImgComunicacaoVisaoBom: string;
    urlImgComunicacaoVisaoRuim: string;
    comorbidade: string;
    urlImgComorbidadeBranco: string;
    urlImgComorbidadeVermelho: string;
    urlImgMMSSRuim: string;
    url5Ou6: string;
    urlImgMMSSBom: string;
    urlImgMarchaQuedasRuim: string;
    IfQueSelecionaURL1ou2Marcha: string;
    urlImgMarchaQuedasBoa: string;
    urlImgContinenciaEsfincterRuim: string;
    IfQueSelecionaURL1ou2Continencia: string;
    urlImgContinenciaEsfincterBom: string;
    IfQueSelecionaURL3ou4Visao: string;
    urlImgVisaoBom: string;
    urlImgVisaoRuim: string;
    IfQueSelecionaURL3ou4Audicao: string;
    urlImgAudicaoBom: string;
    urlImgAudicaoRuim: string;
    urlImgComorbidadePoliPatologiaBranco: string;
    urlImgComorbidadePoliPatologiaVermelho: string;
    pontuacaoPolipatologia: number;
    urlImgComorbidadePolifarmaciaBranco: string;
    urlImgComorbidadePolifarmaciaVermelho2: string;
    pontuacaoPolifarmacia: number;
    urlImgComorbidadeInternacaoRecenteBranco: string;
    urlImgComorbidadeInternacaoRecenteVermelho: string;
    pontuacaoInternacao: number;
    prontuario: string;
    dtConsulta: Date;
    codigo: string;
    titulo: string;
    habilitado: boolean;
    nomePacienteOLD: string;
    cADASTRODOHOSPEDE: string;
    sexo: string;
    eNDERECOCOMPLETO: string;
    endereco: string;
    escolaridade: string;
    medicoReferencia: string;
    telMedicoReferencia: string;
    nomeMaePegaValorDaVariavelV_1608: string;
}
