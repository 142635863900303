export const fieldsMapEstoqueMaterial = {
    estoqueMaterialId: { name: 'estoqueMaterialId', type: 'number' },
    // tenantId
    tenantId: { name: 'tenantId', type: 'number' },
    ocorrenciaNo: { name: 'ocorrenciaNo', type: 'string' },
    V_832: { name: 'dosagem', type: 'string' }, // Concatena V_100853 com V_100854
    V_27987: { name: 'codigo', type: 'number', ignoreOnSave: true },
    V_27988: { name: 'titulo', type: 'string' },
    V_27989: { name: 'habilitado', type: 'boolean' },
    V_27994: { name: 'estoque', type: 'number' },
    V_27995: { name: 'estoqueMinimo', type: 'number' },
    V_27998: { name: 'dataUltimaCompra', type: 'Date' },

    V_101098: { name: 'consumoDiario', type: 'number' },
    // V_28019: { name: 'consumoDiario', type: 'string' }, // Deve ser utilizado como Consumo diário -> Exibe o valor do campo V_101099 ou V_101098

    V_28020: { name: 'dataProximaCompra', type: 'Date' },
    V_28021: { name: 'hoje', type: 'Date' },
    V_28022: { name: 'duracaoTotal', type: 'number' },
    V_29858: { name: 'idPaciente', type: 'number' }, //Contem proc ativado pelo campo V_27993

    // A informação de medicamento controlado é obitida na tela de Intervenções Medicamentos V2 V_32857 e não na Estoque de Materiais
    //V_30268: { name: 'medicamentoControlado', type: 'booblean' }, // Atualmente sem função na tela de estoque

    V_30321: { name: 'codPacienteNomeDoPacienteCodMedicamento', type: 'string' },

    V_34718: { name: 'quantidadeEmbalagem', type: 'number' }, //Contem proc ativado pelo campo V_30350 
    V_100306: { name: 'idMedicamento', type: 'number' }, // Recebe o valor do campo V_27987
    V_100851: { name: 'ultimoLote', type: 'string' },
    V_100852: { name: 'dataUltimaValidade', type: 'Date' },
    // V_101099: { name: 'gotasPml', type: 'string' }, // Contem formula para converter o valor informado no campo V_101098 para gotas
    V_101213: { name: 'dataAtual', type: 'Date' },
    
    // V_100853: { name: 'dose', type: 'string' },
    V_100853: { name: 'dosageValue', type: 'number' },

    // isSelected
    // isHistoryVisible
    // totalPrice
    // lastPurchaseId
    // lastPrice
    // isMinimum
    // purchaseQuantity
    // daysToExpire
    // createdBy
    // updatedBy
    // createdAt
    // updatedAt

    V_27993: { name: 'centroCusto', type: 'string', relationshipField: 'name', relationshipFieldId: 'centroCustoId' },
    V_28023: { name: 'unidadeNegocio', type: 'string', relationshipField: 'name', relationshipFieldId: 'unidadeNegocioId' },
    
    // V_100854: { name: 'unidadeDose', type: 'string', relationshipField: 'name', relationshipFieldId: 'unidadeDoseId' },
    V_100854: { name: 'dosageUnity', type: 'string', relationshipField: 'name', relationshipFieldId: 'dosageUnityId' },
    
    // pfpm
    // StatusEstoque
    V_30350: { name: 'itemEstoque', type: 'string', relationshipField: 'name', relationshipFieldId: 'itemEstoqueId' }, //Contem proc ativado pelo campo V_28018 
    V_28018: { name: 'tipoMaterial', type: 'string', relationshipField: 'name', relationshipFieldId: 'tipoMaterialId' },

    V_27997: { name: 'apresentacao', type: 'string', relationshipField: 'name', relationshipFieldId: 'apresentacaoId' },
    V_28014: { name: 'fornecedorNome', type: 'string' },
    // Não existe no esquema do prisma

    // V_30412: { name: 'tipoMaterialId', type: 'string' }, //Contem proc ativado pelo campo V_28018
    V_28008: { name: 'HistoricoCompraEstoque', type: 'array' }, 

    V_28012: { name: 'lastPrice', type: 'number' },
    V_28452: { name: 'lastInvoiceNumber', type: 'string' },
    
    // Os campos abaixo existem no BD, mas não existem na tela de Estoque de Materiais
    //V_: { name: 'isSelected', type: 'string' },
    //V_: { name: 'isHistoryVisible', type: 'string' },


    //V_: { name: 'centroCustoId', type: 'string' }, // Equivalente ao campo V_29858
    //V_: { name: 'unidadeNegocioId', type: 'string' },
    //V_: { name: 'unidadeDoseId', type: 'string' },
    //V_: { name: 'statusEstoqueStatusEstoqueId', type: 'string' },
    //V_: { name: 'itensEstoqueItemEstoqueId', type: 'string' },
    //V_: { name: 'tipoMaterialTipoMaterialId', type: 'string' },
    //V_: { name: 'pfpm', type: 'string' },



    // Os campos abaixo existem no BD, mas não existem na tela de Estoque de Materiais
    // Possivelmente são da tela de Histórico de Compras
    //V_: { name: 'totalPrice', type: 'string' },
    //V_: { name: 'lastPurchaseId', type: 'string' },
    //V_: { name: 'lastPrice', type: 'string' },
    //V_: { name: 'isMinimum', type: 'string' },
    //V_: { name: 'purchaseQuantity', type: 'string' },
    //V_: { name: 'daysToExpire', type: 'string' }    

}
