<div class="shift-container">

  <!-- <button type="button" (click)="onClick($event)" mat-button>Tudo</button>
  <button type="button" (click)="onClick($event)" mat-button>Manhã</button>
  <button type="button" (click)="onClick($event)" mat-button>Tarde</button>
  <button type="button" (click)="onClick($event)" mat-button>Noite</button> -->

  <mat-button-toggle-group name="shift" aria-label="Turno" (click)="onClick(group.value)" #group="matButtonToggleGroup">
    <mat-button-toggle [value]="ENSHIFT.all" checked="true">Tudo</mat-button-toggle>
    <mat-button-toggle [value]="ENSHIFT.morning">Manhã</mat-button-toggle>
    <mat-button-toggle [value]="ENSHIFT.afternoon">Tarde</mat-button-toggle>
    <mat-button-toggle [value]="ENSHIFT.night">Noite</mat-button-toggle>
  </mat-button-toggle-group>

  <div class="spacer"></div>

  <ml-ui-care-plan-practitioner></ml-ui-care-plan-practitioner>

</div>