import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { publishReplay } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import { IListItem } from '../interface/ilist-item';
import { LocalLibService } from './local-lib.service';
import { GlobalService, LogService, IForm, IServiceProvider } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';

export abstract class GenericService implements IServiceProvider {
  // tslint:disable: max-line-length
  // private lstVariaveis = 'V_387,V_391,V_2230,V_28051,V_103152';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  private get lstVariaveis(): string {
    return this.mapArray.reduce((a, b) => a.concat(`V_${b},`), '');
  }

  // FIXME: Temporary mapping solution. The search variables must be get
  // from tenant.getSmartSearch.
  private get mapArray(): number[] {
    switch (this.cadastroNo) {
      case 17958: // Abertura de Contas
        return [103245, 103244, 103141, 29013, 2278];
      case 17959: // Abertura de Custódia
        return [103247, 387, 103094, 103158, 103096];
      case 17953: // Cad - Consultor
        return [103166, 103169, 103170, 103173, 103171];
      case 17954: // Cad - PPA
        return [103204, 103212, 103209, 103213, 103209];
      case 17734: // Cadastro de Clientes
        return [2230, 387, 2278, 1608, 391];
    }
  }

  recurrences: IListItem[] = new Array<IListItem>();

  codigoVariavelNo = 2230;
  cadastroNo: number;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigPwaMedLogicService,
    protected lib: LocalLibService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IListItem> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IListItem> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IListItem> {
    try {
      // if (
      //   (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
      //   || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
      //   || (!this.cadastrosCache)
      // ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
      // } else {
      //   console.log('retorno do cache');
      // }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      this.cadastroNo = cadastroNo;
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables)
        .pipe(
          this.mapTo(cadastroNo),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected mapTo = (ano: number) => map((c: any) => {
    this.cadastroNo = ano;
    return {
      ocorrenciaNo: c.OcorrenciaNo,
      id: c[`V_${this.mapArray[0]}`], // c.V_2230, // codigo: c.V_2230,
      topLeft: c[`V_${this.mapArray[1]}`], // c.V_387, // nomeEmpresa: c.V_387,
      bottomLeft: c[`V_${this.mapArray[2]}`], // c.V_28051, // conta: c.V_28051,
      topRight: c[`V_${this.mapArray[3]}`], // c.V_103152, // bENEFICIARIO: c.V_103152,
      bottomRight: c[`V_${this.mapArray[4]}`], // c.V_391, // dataAberturaConta: c.V_391,
    } as IListItem;
  })

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
  * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date = null, endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IListItem> {
    try {
      this.cadastroSrv.dtInicial = startDate ? this.glb.dateToYYYYMMddThhmmss(startDate) : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      this.cadastroNo = cadastroNo;
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd)
        .pipe(
          this.mapTo(cadastroNo),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  // save(ano: number, account: IListItem, id?: number): Observable<any> {
  save<T>(ano: number, item: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      uno = uno || this.cnf.usuarioLogadoNo;
      const forms: IForm[] = this.mapToForm(item as unknown as IListItem).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(account: IListItem): IForm[] {
    try {
      return [
        { VariavelNo: 387, ValorDado: account.topLeft || '' },
        { VariavelNo: 391, ValorDado: account.bottomRight || '' },
        { VariavelNo: 2230, ValorDado: account.id || '' },
        { VariavelNo: 28051, ValorDado: account.bottomLeft || '' },
        { VariavelNo: 103152, ValorDado: account.topRight || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  // insertIfNotExist(ano: number, account: IListItem, compareFieldName: string = 'titulo'): Observable<boolean> {
  insertIfNotExist<T>(ano: number, item: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], item[compareFieldName])) >= 0);
            if (!founded) {
              return this.save<IListItem>(ano, item as unknown as IListItem, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }


  protected convertToValorTexto(item: IListItem[]): string {
    try {
      let valorTexto = '<![CDATA[<Items>';
      if (item) {
        item.forEach(f => {
          valorTexto += `<Item><index>${f.id}</index>`;
          if (f.topLeft) { valorTexto += `<V_387>${f.topLeft}</V_387>`; }
          if (f.bottomLeft) { valorTexto += `<V_391>${f.bottomLeft}</V_391>`; }
          if (f.id) { valorTexto += `<V_2230>${f.id}</V_2230>`; }
          if (f.topRight) { valorTexto += `<V_103152>${f.topRight}</V_103152>`; }
          if (f.bottomRight) { valorTexto += `<V_391>${f.bottomRight}</V_391>`; }
          valorTexto += '</Item>';
        });
      }
      valorTexto += '</Items>]]>';
      return valorTexto;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertToValorTexto', error.message);
    }
    return '';
  }


}

// ABERTURA DE CONTAS
// Cliente: 103244
// Document: 2278
// idCliente: 103245
// Numero da conta: 103141
// Email: 29013


// ABERTURA DE CUSTODIA
// CLiente: 387
// Tipo do Item: 103096
// Valor em dolar: 103094
// Codigo: 103247
// issue date: 103158


// CAD - CONSULTOR
// Nome 103169
// Document 103170
// Email 103173
// Codigo 103166
// Number Identification Company: 103171


// CAD - PPA
// PPA Código da operação: 103207
// Valor: 103209
// Data: 103213
// Nome do vendedor: 103212
// Código: 103204


// CADASTRO DE CLIENTES
// Nome da Empresa: 387
// Document: 2278
// Nome Consultor: 1608
// Código: 2230
// Data da Abertura da Conta: 391
// Selecionar representante: 103129

