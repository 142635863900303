import { IPrescriptionMedication } from '../medlogic/iprescription-medication';
import { IPerson } from '../iperson';
import { IMedicationCheckin } from '../medlogic/imedication-checkin';

export interface IPostMedicationCheckinRequest {
    checkins: IMedicationCheckin[];
    cadEstoqueMateriaisNo: number;
    cadIntervencoesMedicamentosNo: number;
    cadItensEstoqueNo: number;
    cadPrescriptionNo: number;
    selectedPrescription: IPrescriptionMedication;
    person: IPerson;
    wasChanged: boolean;
    message: string;
}
