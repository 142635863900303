import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { initialStateVitalSign } from './vital-sign.reducer';
import {
  addVitalSign, addVitalSigns, clearVitalSigns,
  deleteVitalSign, deleteVitalSigns, loadVitalSigns,
  loadVitalSignsByPeriod,
  loadVitalSignsSuccess,
  updateVitalSign, updateVitalSigns, upsertVitalSign, upsertVitalSigns
} from './vital-sign.actions';

const KEY = 'MEDLOGIC_VITAL_SIGN_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  loadVitalSigns,
  loadVitalSignsByPeriod,
  loadVitalSignsSuccess,
  addVitalSign,
  upsertVitalSign,
  addVitalSigns,
  upsertVitalSigns,
  updateVitalSign,
  updateVitalSigns,
  deleteVitalSign,
  deleteVitalSigns,
  clearVitalSigns
].map(m => m.type.toString());

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialStateVitalSign);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
