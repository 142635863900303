export interface ITarefa {
    date: string;
    numOfTasks: number;
    time: string;
    name?: string;
    identificacao1?: string;
    identificacao2?: string;
    executorNome?: string;
    processoNome?: string;
    remetenteNome?: string;
    status?: string;
}
