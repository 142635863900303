import { IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { IBasic } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'libFilterComponentesByTab'
})
export class FilterComponentesByTabPipe implements PipeTransform {
  constructor(private log: LogService) { }

  transform(componentes: Array<IAtividadeComponenteDAL>, tab: IBasic): Array<IAtividadeComponenteDAL> {
    try {
      if (tab === undefined || !componentes) {
        return componentes;
      }
      const cmps = componentes.filter((f) => f.TabIndex === tab.id);
      if (cmps && cmps.length > 0) {
        return cmps.sort((a, b) => a.TabOrder - b.TabOrder);
      } else {
        return cmps;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return null;
  }
}
