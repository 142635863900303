export enum EnMaterialIcon {
    check = 'check',
    warning = 'warning',
    error = 'error',
    error_outline = 'error_outline',
    lock = 'lock',
    account_circle = 'account_circle',
    contact_support = 'contact_support',
    contact_phone = 'contact_phone'
}
