import { ItemSearchPipe } from '@medlogic/shared/utils';
import {
  GlobalService, LogService, IVitalSign,
  IListItem, ISmartList, IPatient, ILogin
} from '@medlogic/shared/shared-interfaces';
import { IVitalSignState, IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { of } from 'rxjs';

import * as fromVitalSign from './vital-sign.reducer';
import * as fromPatient from '../../state-patient/+state/patient.selectors';

const glb = new GlobalService();
const log = new LogService();

/* Operador vitalSignalizado que converte os vitalSignos em módulos. */
const toIListItem = ((vitalSign: IVitalSign) => {
  return ({
    id: vitalSign?.codigo,
    identification1: vitalSign?.codigo,
    identification2: glb.getTypedValue(vitalSign?.dataavaliacao).value,
    topLeft: `Profissional: ${vitalSign?.executorAvaliacao}`,
    bottomLeft: glb.dateTohhmmss(vitalSign?.dataavaliacao),
    topRight: glb.DateToddMMYYYY(vitalSign?.dataavaliacao),
    bottomRight: vitalSign?.calcOcurrences ? 'missed' : 'checked',
    color: 'green',
    imgUrl: '',
    obj: vitalSign
  } as IListItem<IVitalSign>);
})

export const isVitalSignLoading = createSelector(
  fromVitalSign.selectAll,
  state => !!state,
);

export const selectVitalSignState = createFeatureSelector<IVitalSignState>(fromVitalSign.vitalSignsFeatureKey);
export const selectLoginPWAStateVital = createFeatureSelector<{ selectedLogin: ILogin }>('loginPWA');
export const selectSearchStateVital = createFeatureSelector<IAppMedlogicState>('appMedlogic');
export const selectVitalSignAlls = createSelector(selectVitalSignState, fromVitalSign.selectAll);

export const selectVitalSignSelectedId = createSelector(selectVitalSignState,
  state => state?.selectedId || -1
);

export const selectVitalSignSelected = createSelector(
  selectVitalSignState,
  selectVitalSignSelectedId,
  (state, vitalSignId) => vitalSignId > 0 ? state?.entities[vitalSignId] : null
);

export const selectVitalSignsByPatient = (patientId: number) => createSelector(
  selectVitalSignAlls,
  (vitalSigns) => vitalSigns?.filter(f => +f.codigoPaciente === +patientId)
);


export const selectVitalSignsByPatientSelected = createSelector(
  selectVitalSignAlls,
  fromPatient.selectedPatientId,
  (vitalSigns, selectedPatientId) => vitalSigns?.filter(f => +f.codigoPaciente === +selectedPatientId)
);

export const selectVitalSignsAsIListItems = createSelector(
  selectVitalSignsByPatientSelected,
  vitalSigns => vitalSigns?.map(toIListItem)
);

export const selectVitalSignsAsSmartList = createSelector(
  selectVitalSignsAsIListItems,
  selectSearchStateVital,
  (items, appMedlogic) => {
    items = filterBySearch(items, appMedlogic?.search);
    items = items.sort((a, b) => glb.compareDates(b.identification2, a.identification2));
    if (items?.length > 0) {
      return uniqueDates(items, 'dataavaliacao')
        .map(dt =>
          ({
            title: `${glb.FormatDateHour(dt, "M")}, ${glb.FormatDateHour(dt, "y")}`,
            items: items
              .filter(f =>
                glb.getTypedValue(f?.obj?.dataavaliacao).value && dt &&
                glb.getTypedValue(f?.obj?.dataavaliacao).value.getMonth() === dt.getMonth() &&
                glb.getTypedValue(f?.obj?.dataavaliacao).value.getFullYear() === dt.getFullYear()
              )
          } as ISmartList)
        );
    }
    return of(null);
  });

const filterBySearch = (items: IListItem<IVitalSign>[], keyword: string) => {
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

function uniqueDates(itens: any[], dateFieldName: string) {
  const datas: Date[] = [];
  if (itens?.length > 0) {
    itens?.forEach(item => {
      const value = glb.getTypedValue(item?.obj[dateFieldName]).value;
      if (!datas.find(f => f.getMonth() === value.getMonth() &&
        f.getFullYear() === value.getFullYear())) {
        datas.push(value);
      }
    });
    return datas;
  }
}

export const selectVitalSignsDefaultControls = createSelector(
  selectLoginPWAStateVital,
  selectVitalSignSelectedId,
  fromPatient.selectedPatient,
  (loginPWA, selectedId, patient) => getDefaultFormControls(
    selectedId,
    patient,
    loginPWA?.selectedLogin?.usuarioLogadoNo,
    loginPWA?.selectedLogin?.usuarioLogadoNome
  )
)

const getDefaultFormControls = (ono: number, patient: IPatient, professionalNo: number, professionalName: string): { [key: string]: any } => {
  try {
    const dfc = {
      V_28626: ono,
      V_29977: professionalName,
      V_28841: professionalNo,
      V_387: patient?.nomeHospede,
      V_2230: patient?.codigo,
      V_28051: patient?.prontuario,
      V_1608: patient?.nomemae,
      V_3328: patient?.identificacao1,
      V_3329: patient?.identificacao2,
      V_391: patient?.nascimento,
      V_3304: patient?.foto,
      V_28611: `${patient?.codigo}_${glb.dateToYYYYMMddThhmmss(new Date())}`, // Título
    };
    return dfc;
  } catch (error) {
    log.Registrar('vital-sign.selector', 'getDefaultFormControls', error.message);
  }
}
