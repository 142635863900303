import { ConfigStateService } from '@medlogic/shared/state-config';
import { ListControl } from '../../model/list-control';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { GlobalService, ConfigJsonService, IAtividadeComponenteDAL, EnTheme } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraService } from '../../service/calculadora.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { CadastroListaDAL } from '../../model/dal/cadastro-lista-dal';
import { Router } from '@angular/router';
import { NavigationService } from '../../service/navigation.service';
import { ExecucaoTarefaDAL } from '../../model/dal/execucao-tarefa-dal';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { ICadastro } from '../../interface/icadastro';
import { EnListControlType } from '@medlogic/shared/gecore';
import { GeFormProviderService } from '../../service/ge-form-provider.service';
import { FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-combobox',
  templateUrl: './ctr-combobox.component.html',
  styleUrls: ['./ctr-combobox.component.css']
})
export class CtrComboboxComponent extends ListControl implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @ViewChild('dropdown') dropdown: any; 
  
  ENTHEME = EnTheme;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  filteredOptions$: Observable<string[]>;

  forceSelection(items: string[], ctrl: IAtividadeComponenteDAL, isSearchMode: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        let isOption = true;
        if (control?.value === undefined || !items || items?.length <= 0) {
          return null;
        }
        isOption = !isSearchMode || items.findIndex((value) => {
          return value?.toUpperCase() === control?.value?.toUpperCase();
        }) >= 0;
        const isRequiredNotInformed = ctrl?.RequiredField && control?.value?.toUpperCase() === this.global.GE_NOT_INFORMED.toUpperCase();
        return !isOption || isRequiredNotInformed ? { forceSelection: { value: control?.value } } : null;
      } catch (error) {
        console.log('forceSelection', 'forceSelection', error.message);
      }
      return null;
    };
  }

  constructor(
    global: GlobalService,
    log: LogService,
    lib: LibService,
    cnf: ConfigStateService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    cadastroListaDAL: CadastroListaDAL,
    cnfJson: ConfigJsonService,
    router: Router,
    navigation: NavigationService,
    execucaoTarefa: ExecucaoTarefaDAL,
    dialog: MatDialog,
    msg: MsgPtBR,
    geFormPrv: GeFormProviderService,
    private ref: ChangeDetectorRef
  ) {
    super(
      global,
      log,
      lib,
      cnf,
      calc,
      calcCond,
      dialog,
      msg,
      cadastroListaDAL,
      cnfJson,
      router,
      navigation,
      execucaoTarefa,
      geFormPrv
    );
  }

  isSearchMode = false;
  isMenuOpened = false;
  isOver = false;
  btnAction: any; // na verdade se refere a um alias, mas na geração do build de produção

  /*Filtro dos itens por palavra-chave. */
  search(event: any): void {
    try {
      this.items = this.items?.filter((f) => this.global.ContemSemAcentos(event.query, f.value.name));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'search', error.message);
    }
  }

  ngOnInit(): void {
    try {
      this.isSearchMode = this.ctrl?.ShowSearch || this.ctrl?.AutoComplete;
      this.adjusteWidthForBeCompatibleFlashDesign(this.ctrl);
      this.enableFilter(this.ctrl);
      super.ngOnInit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  protected setCustomValidators(items: string[], ctrl: IAtividadeComponenteDAL): void {
    try {
      if (this.isSearchMode) {
        const control = this.getFormControlFrom(ctrl);
        control.setValidators(this.forceSelection(items, ctrl, this.isSearchMode));
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCustomValidators', error.message);
    }
  }

  protected enableFilter(ctrl: IAtividadeComponenteDAL): void {
    try {
      const control = this.getFormControlFrom(ctrl);
      this.filteredOptions$ = control.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'enableFilter', error.message);
    }
  }

  /*Realiza um ajuste na largura do controle, pois, no Flash, o controle + botão são um pouco menores.  */
  protected adjusteWidthForBeCompatibleFlashDesign(ctrl: IAtividadeComponenteDAL): void {
    try {
      if (ctrl) {
        ctrl.Largura -= 20;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'adjusteWidthForBeCompatibleFlashDesign', error.message);
    }
  }

  /*Override  */
  isReadOnly(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return super.isReadOnly(ctrl) || !ctrl?.AutoComplete;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isReadOnly', error.message);
    }
    return false;
  }

  /*Edita o item selecionado
  *  Preencherá this.config.defaultFormControls com os valores do item selecionado para preencher a atividade.
  */
  onEditSelected(
    $event: any,
    anoMain: number,
    anoChild: number,
    dropdown: any,
    selectedItem: any,
    readOnly: boolean,
    saveInList: boolean
  ): void {
    try {
      const ono = dropdown?.selectedOption?.id;
      if (ono) {
        // Preenche a propriedade que passará os valores preenchidos para a próxima Atividade.
        let formControl = this.formGroup.getRawValue();
        formControl = this.lib.getDefaultFormControls(selectedItem, formControl);
        this.cnf.setDefaultFormControls(anoChild, formControl);
        // Muito importante para a criação do item que será usado em AtividadeView para retornar com o valor editado
        // this.config.listItem[this.ctrl.AtividadeCadastroNo] = {
        // 	id: this.lib.getId(this.ctrl?.VariavelNo),
        // 	index: ono
        // };
        this.subs.sink = this.onEdit(
          $event,
          anoMain,
          anoChild,
          ono,
          this.ctrl?.AtividadeCadastroNo,
          readOnly,
          saveInList).subscribe();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onEditSelected', error.message);
    }
  }

  /* */
  onNewItem($event: any, anoMain: number, anoChild: number, listvno: number, saveInList: boolean = true): void {
    try {
      // Preenche a propriedade que passará os valores preenchidos para a próxima Atividade.
      const formControl = this.formGroup?.getRawValue();
      this.cnf.setDefaultFormControls(anoChild, formControl);

      // Será usado para, no retorno, selecionar o item criado
      // this.config.listItem[this.ctrl.AtividadeCadastroNo] = {
      // 	id: this.lib.getId(this.ctrl?.VariavelNo),
      // 	index: -1
      // };
      this.subs.sink = this.onNew($event, anoMain, anoChild, listvno, saveInList)
        .subscribe(res => {
          this.updateComboboxUI(this.ctrl, res.values);
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onNewItem', error.message);
    }
  }

  /*Após confirmação do usuário, exclui o item da lista e também do cadastro caso esteja associado */
  onDeleteItem($event: any, ano: number, dropdown: any, saveInList: boolean) {
    try {
      const ono = dropdown?.selectedOption?.id;
      const selectedItem = !this.items ? null : this.items?.filter((f: any) => (!f.id ? false : f.id === ono));
      if (selectedItem && selectedItem?.length > 0) {
        const esse = dropdown?.selectedOption?.label;
        this.hasConfirmButton = true;
        this.message = {
          firstButtonLabel: 'Não',
          title: 'Confirmação',
          icon: 'fa-times',
          text: `Você tem certeza que quer excluir '${esse}'. Essa exclusão NÃO poderá ser desfeita!`,
          acceptFunc: () => {
            try {
              // método de exclusão
              this.ctrl.lstCadastroAdicional = this.removeDeleteItems(
                this.items,
                selectedItem,
                'id',
                saveInList,
                ano
              );
              if (dropdown) {
                dropdown.selectedOption = null;
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'onDeleteItem.acceptFunc', error.message);
            }
          }
        };
      } else {
        this.hasConfirmButton = false;
        this.message = {
          firstButtonLabel: 'OK',
          title: 'Atenção',
          icon: 'fa-times',
          text: `Nenhum item selecionado!`,
          // acceptFunc: () => { }
        };
      }
      this.onAlertDialog($event, this.message, this.hasConfirmButton);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDelete', error.message);
    }
  }

  /*override
   * Realiza a atualização das propriedades de lista e valor.
   * ATENÇÃO: Também atualizará o valor do formControl correspondente.
   * É diferente na combobox em relação ao grid.
  */
  protected updateValorTextoAndLstCadastroAdicional(
    items: any[],
    ctrl: IAtividadeComponenteDAL,
    formGroupValue: any = null,
    canBeEmpty: boolean = false
  ): void {
    try {
      const custProp = ctrl.CustomProperty.split(';');
      const titleProp = custProp && custProp[1] ? `V_${custProp[1]}` : null;
      const newValue = formGroupValue && formGroupValue[titleProp] ? formGroupValue[titleProp] : null;
      // items.forEach((item) => {
      // 	try {
      // 		// #region Cenário 2) Retorno dos valores para a Atividade Chamadora
      // 		if (item && item[titleProp] && ctrl.lstCadastroAdicional) {
      // 			// #region Preencher lstCadastroAdicional (necessário para funcionamento de fórmulas como PROC)
      // 			const cadAdicional = ctrl.lstCadastroAdicional.find(
      // 				(f) => +item.OcorrenciaNo === +f.OcorrenciaNo
      // 			);
      // 			if (cadAdicional) {
      // 				// Edição de um item de cadastro adicional existente
      // 				for (const clm in cadAdicional) {
      // 					if (item.hasOwnProperty(clm)) {
      // 						cadAdicional[clm] = item[clm];
      // 					}
      // 				}
      // 			} else if (ctrl.lstCadastroAdicional.length > 0) {
      // 				// Criação de novo item, pois, o elemento é recém criado.
      // usará o primeiro elemento de lstCadastroAdicional como modelo de campos
      // 				const camposAdicionais = ctrl.LstCamposAdicionais.CamposAdicionais;
      // 				const newCadAdicional = {};
      // 				camposAdicionais.forEach((f) => {
      // 					const id2 = this.lib.getId(f.VariavelNo);
      // 					if (item.hasOwnProperty(id2)) {
      // 						newCadAdicional[id2] = item[id2];
      // 					}
      // 				});
      // 				ctrl.lstCadastroAdicional.push(newCadAdicional);
      // 			}
      // 			//#endregion
      // 			// #region Seleciona o item da Combobox, conforme modificação.
      // 			// const control = formGroup.get(selItem.id);
      // 			// // Necessário zerar o valor e depois gravá-lo novamente para
      // forçar o change mesmo se o valor não tiver mudado (necessário para fórmulas de proc que usam variáveis adicionais)
      // 			// const newValue = !this.global.isNullOrEmpty(selItem.value) ? selItem.value : selItem[id] || '';
      // 			// control?.markAsDirty();
      // 			// control?.setValue('', { onlySelf: true, emitEvent: true });
      // 			// this.formGroup.updateValueAndValidity({ emitEvent: true });
      // 			// // Grava o valor correto
      // 			// control?.setValue(newValue, { onlySelf: true, emitEvent: true });
      // 			// control?.markAsDirty();
      // 			// this.formGroup.updateValueAndValidity({ emitEvent: true });
      // 			// this.config.listItem[cadastroAssociadoNo] = null;
      // 			// this.selectTab(this.ctrl);
      // 			// #endregion
      // 		}
      // 	} catch (error) {
      // 		this.log.Registrar(
      // 			this.constructor.name,
      // 			'umdateValorTextoAndLstCadastroAdicional.forEach',
      // 			error.message
      // 		);
      // 	}
      // 	// #endregion
      // });

      ctrl.lstCadastroAdicional = items;
      // Para forçar o change que recalculará eventuais formulas de grid (que dependem dessa lista carregada)
      ctrl.Valor = newValue; // Necessário para que o change funcione
      ctrl.ValorTexto = newValue;
      // Necessário zerar o valor texto, ou se forem excluídos
      // todos os itens, a lógica em lib.getValorTextoOrData fará com que o ValorTexto seja novamente carregado.
      const cadastros = items.map((m) => {
        return {
          CampoNome: m[titleProp],
          Valor: m[titleProp],
          id: m.OcorrenciaNo
        } as ICadastro;
      });
      this.fillItems(cadastros);
      const formCtrl = this.formGroup?.get(this.getId(ctrl));
      if (formCtrl) {
        formCtrl.markAsDirty();
        formCtrl.setValue(ctrl?.ValorTexto, { onlySelf: true, emitEvent: true });
      }
      // Para forçar o change que recalculará eventuais formulas de grid (que dependem dessa lista carregada)
      this.onDataLoaded.emit(ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateValorTextoAndLstCadastroAdicional', error.message);
    }
  }

  /* override. */
  protected fillItems(cadastros: any[]): void {
    try {
      super.fillItems(cadastros);
      // *** Personalização importante para validar os campos do tipo search ***
      this.setCustomValidators(this.items?.map(m => m?.label), this.ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillItems', error.message);
    }
  }

  /* Define se é possível acrescentar um item */
  canCreate(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return ctrl?.CanAddItem && ctrl?.CadastroNo > 0 && ctrl?.IsEnable;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'canCreate', error.message);
    }
    return false;
  }

  /* Define se é possível editar um item.
   * Atualmente está seguindo a propriedade  CanAddInCadastro, pois as opções específicas de edição e exclusão só existem no Grid (Studio).
   */
  canEdit(ctrl: IAtividadeComponenteDAL, dropdown: any): boolean {
    try {
      const value = dropdown?.selectedOption ? dropdown?.selectedOption?.label : null;
      // TODO: No Studio não há uma configuração específica de edição ou exclusão, da forma que existe para o Grid.
      // Por tanto, campo AutoComplete está sendo usado provisioriamente até que haja um adequado
      return (
        ctrl?.AutoComplete &&
        ctrl?.CadastroNo > 0 &&
        !this.global.IsNullOrEmptyGE(value, true) &&
        ctrl?.IsEnable &&
        ctrl?.CanAddInCadastro
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'canEdit', error.message);
    }
    return false;
  }

  /* Define se é possível excluir um item.
  * Atualmente está seguindo a propriedade  CanAddInCadastro, pois as opções específicas de edição e exclusão só existem no Grid (Studio).
  */
  canDelete(ctrl: IAtividadeComponenteDAL, dropdown: any): boolean {
    try {
      const value = dropdown?.selectedOption ? dropdown?.selectedOption?.label : null;
      // TODO: No Studio não há uma configuração específica de edição ou exclusão, da forma que existe para o Grid.
      // Por tanto, campo PartialSearch está sendo usado provisioriamente até que haja um adequado
      return (
        ctrl?.PartialSearch &&
        ctrl?.CadastroNo > 0 &&
        !this.global.IsNullOrEmptyGE(value, true) &&
        ctrl?.IsEnable &&
        ctrl?.CanAddInCadastro
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'canDelete', error.message);
    }
    return false;
  }

  /* Quando o mouse deixar o menu */
  onMouseLeave(): void {
    try {
      this.isMenuOpened = false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onMouseOut', error.message);
    }
  }

  /* Define se o menu deve ou não ser exibido.
  * Se for uma lista personalizada, Direct, não exibir.
  */
  showMenu(ctrl: IAtividadeComponenteDAL, dropdown: any): boolean {
    try {
      return (
        (this.canEdit(ctrl, dropdown) || this.canCreate(ctrl) || this.canDelete(ctrl, dropdown)) &&
        this.getListControlType(ctrl) !== EnListControlType.Direct
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showMenu', error.message);
    }
    return false;
  }

  /* Altura da linha da dropdown.  */
  getDropdownStyle(ctrl: any, btnAction: any): any {
    try {
      if (this.isMobile) {
        return {
          width: this.getWidth(ctrl, btnAction),
          'z-index': 'auto',
          'line-height': this.getLineHeight(),
          height: this.getStandardHeight(),
          'font-size': this.getFontSize(),
          'padding-top': '4px',
          'padding-left': '4px',
          border: 0
        };
      } else {
        return {
          width: this.getWidth(ctrl, btnAction),
          'z-index': 'auto',
          'line-height': this.getLineHeight(),
          height: this.getStandardHeight(),
          'font-size': this.getFontSize(),
          'padding-top': '3px',
          'padding-left': '2px'
        };
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLineHeight', error.message);
    }
    return null;
  }

  /* Altura da linha da dropdown. */
  protected getLineHeight(): string {
    try {
      if (this.isMobile) {
        return '16px';
      } else {
        return '8px';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLineHeight', error.message);
    }
    return null;
  }

  /* Tamanho da fonte da dropdown. */
  protected getFontSize(): string {
    try {
      if (this.isMobile) {
        return '16px';
      } else {
        return '10px';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFontSize', error.message);
    }
    return null;
  }

  private _filter(value: any): string[] {
    return this.items?.filter(f => this.global.isNullOrEmpty(value) || this.global.ContemSemAcentos(value, f.label)).map(m => m.label);
  }

  resetValue(ctrl: IAtividadeComponenteDAL): void {
    try {
      this.getFormControlFrom(ctrl).setValue('');
      ctrl.ValorTexto = '';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'resetValue', error.message);
    }
  }

  private updateComboboxUI(ctrl: IAtividadeComponenteDAL, values: { [key: string]: any }): void {
    try {
      this.ref.detectChanges();
      this.updateCadastroDependends(ctrl, values);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateComboboxUI', error.message);
    }
  }


}
