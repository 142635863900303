import { StateScheduledModule } from './state-scheduled/state-scheduled.module';
import { StatePractitionerModule } from './state-practitioner/state-practitioner.module';
import { StatePatientModule } from './state-patient/state-patient.module';
import { StateCarePlanModule } from './state-carePlan/state-carePlan.module';
import { StateActivityDetailModule } from './state-activityDetail/state-activityDetail.module';
import { StateActivityModule } from './state-activity/state-activity.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateScheduledPeriodModule } from './state-scheduledPeriod/state-scheduledPeriod.module';
import { StateScheduledTimingModule } from './state-scheduledTiming/state-scheduledTiming.module';
import { StateProgressModule } from './state-progress/state-progress.module';

const MODULES = [
  CommonModule,
  StateActivityModule,
  StateActivityDetailModule,
  StateCarePlanModule,
  StatePatientModule,
  StatePractitionerModule,
  StateScheduledModule,
  StateScheduledPeriodModule,
  StateScheduledTimingModule,
  StateProgressModule
];



@NgModule({
  imports: [MODULES],
  providers: []
})
export class FhirStateModule { }
