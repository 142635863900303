import { createReducer, on, Action } from '@ngrx/store';
import { loadExameResultados, setExameResultado, fetchErrorExameResultado, saveSuccessExameResultado, saveFailExameResultado, fetchSuccessExameResultado, saveExameResultado, resetExameResultado, deleteSuccessExameResultado, deleteExameResultado, cleanExameResultado, deleteFailExameResultado } from './exame-resultado.actions';
import { IExameResultado, IExameResultadoState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { update, deleteById } from '@medlogic/shared/utils';

export const initialState: IExameResultadoState = {
  resultado: null,
  resultados: [],
  error: null,
  guid: null
};

const exameResultadoReducerObj = createReducer(initialState,
  on(loadExameResultados, (state, payload) => ({
    ...state,
    bannerTitle: 'Resultados de Exames',
    resultado: null,
    resultados: []
  })),
  on(fetchSuccessExameResultado, (state, payload) => ({
    ...state, // FIXME: necessário, pois o retorno vazio está trazendo um array com um elemento null
    resultados: payload?.resultados[0] ? payload?.resultados : [],
    error: null
  })),
  on(setExameResultado, (state, payload) => ({
    ...state,
    resultado: payload?.resultado,
    error: null
  })),
  on(saveExameResultado, (state, payload) => {
    return ({
      ...state,
      resultados: update<IExameResultado>(state?.resultados, payload?.resultado, 'ocorrenciaNo', payload?.guid),
      resultado: payload?.resultado,
      error: null,
      guid: payload?.guid
    });
  }),
  on(fetchErrorExameResultado, (state, payload) => ({
    ...state,
    resultados: null,
    error: 'invalid-token'
  })),
  on(saveSuccessExameResultado, (state, payload) => {
    return ({
      ...state,
      resultados: update<IExameResultado>(state?.resultados, payload?.resultado, 'ocorrenciaNo', payload?.guid),
      resultado: payload?.resultado,
      error: null,
      guid: null
    });
  }),
  on(saveFailExameResultado, (state, payload) => ({
    ...state,
    resultados: update<IExameResultado>(state?.resultados, payload?.resultado),
    resultado: payload?.resultado,
    error: 'fail-on-save--resultado'
  })),
  on(resetExameResultado, (state, payload) => initialState),
  on(deleteExameResultado, (state, payload) => {
    return ({
      ...state,
      resultados: deleteById<IExameResultado>(state?.resultados, payload?.resultadoId, 'ocorrenciaNo'), // atualização otimista, antes de ir ao bd
      resultado: null,
      error: null,
      guid: payload?.resultadoId
    });
  }),
  on(deleteSuccessExameResultado, (state, payload) => {
    return ({
      ...state,// Já foi atualizado em deleteExameResultado
      resultado: null,
      error: null,
      guid: null
    });
  }),
  on(deleteFailExameResultado, (state, payload) => ({
    ...state,
    resultado: null,
    guid: null,
    error: 'fail-on-save--resultado'
  })),
  on(cleanExameResultado, (state, payload) => ({ ...initialState }))
);

export function exameResultadoReducer(state: IExameResultadoState | undefined, action: Action) {
  return exameResultadoReducerObj(state, action);
}
