<mat-toolbar color="basic">
  <span class="example-spacer"></span>
  <mat-icon class="example-icon" (click)="onCloseClick()">close</mat-icon>
</mat-toolbar>

<div>
  <h1>{{ data.title }}</h1>
  <form [formGroup]="fg">
    <mat-radio-group formControlName="paperOrientation">
      <mat-radio-button value="landscape">
        <mat-icon>crop_landscape</mat-icon> Paisagem
      </mat-radio-button>
      <mat-radio-button value="portrait">
        <mat-icon>crop_portrait</mat-icon> Retrato
      </mat-radio-button>
    </mat-radio-group>
  </form>
  <mat-toolbar class="bottom-bar">
    <button mat-raised-button (click)="onYesClick($event)" >OK</button>
  </mat-toolbar>
</div>
