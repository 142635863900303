<mat-list *ngIf="groups?.length > 0; else empty">
  <ng-container *ngFor="let group of groups">

    <div mat-subheader class="title">{{ group?.title }}</div>

    <ng-container *ngIf="group?.items?.length > 0; else empty">

      <mat-list-item *ngFor="let item of group?.items" [@fadeAnimation]="'in'" (click)="onItemClick(item)">

        <ui-smart-list-badge-score mat-list-icon [score]="item?.score" [enClipType]="item?.enClipType" badge>
        </ui-smart-list-badge-score>
        <div mat-line class="top">{{ item?.topLeft }}</div>
        <div mat-line class="bottom">{{ item?.bottomLeft }}</div>
        <mat-icon *ngIf="item?.iconName" [style.color]="item?.iconColor" class="checked">{{ item?.iconName }}</mat-icon>
        <mat-icon>chevron_right</mat-icon>
        <mat-divider></mat-divider>

      </mat-list-item>

    </ng-container>

  </ng-container>
</mat-list>

<ng-template #empty>
  <ui-empty></ui-empty>
</ng-template>