import { IPrescription } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { IServiceProvider, IForm } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { publishReplay } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { LocalLibService } from '@medlogic/shared/shared-interfaces';

export abstract class PrescriptionService implements IServiceProvider {

  private lstVariaveis = 'V_828,V_830,V_832,V_2949,V_2952,V_3530,V_27993,V_28018,V_29828,V_29834,V_29838,V_30212,V_30213,V_30214,V_30263,V_30264,V_30266,V_30296,V_30320,V_30330,V_30339,V_30356,V_30367,V_30401,V_31571,V_31967,V_31968,V_31969,V_32857,V_32858,V_32859,V_32860,V_32861,V_32862,V_32910,V_32911,V_32913,V_32939,V_34286,V_34465,V_34709,V_35051,V_101099,V_101103';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IPrescription[] = new Array<IPrescription>();

  codigoVariavelNo = 30212; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 4318;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected lib: LocalLibService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IPrescription> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IPrescription> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IPrescription> {
    try {
      if (
        (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
        || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
        || (!this.cadastrosCache)
        // (startDate.getTime() !== this.currentDtInicial.getTime())
        // || (endDate.getTime() !== this.currentDtFinal.getTime())
        // || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables, startDate, endDate)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected mapTo = () => map((c: any) => {
    return {
      ocorrenciaNo: c.OcorrenciaNo,
      medicamentoTerapia: c.V_828,
      tempoUso: c.V_830,
      dosagem: c.V_832,
      vIA: c.V_2949,
      orientacoes: c.V_2952,
      posologia: c.V_3530,
      centrocusto: c.V_27993,
      tipoMaterial: c.V_28018,
      idPaciente: c.V_29828,
      apresentacao: c.V_29834,
      quantPorHorario: c.V_29838,
      codigo: c.V_30212,
      titulo: c.V_30213,
      habilitado: this.lib.getBoolean(c.V_30214),
      idMedicamento: c.V_30263,
      cascataCheckIdMedicamentoIdPacienteDataEVOLUCAO: c.V_30264,
      centroCustoIDCentroCusto: c.V_30266,
      codigoPacienteNomePaciente: c.V_30296,
      codPacienteNomedoPacienteCodMedicamento: c.V_30320,
      dataPrescricao: this.glb.ddMMYYYYThhmmssToDate(c.V_30330),
      tipoMedicamentosCodPaciente: c.V_30339,
      medicamentoPrescrito: c.V_30356,
      horaprimeiraDose: c.V_30367,
      idMedicamentoIdPaciente: c.V_30401,
      dataEvolucao: this.glb.ddMMYYYYThhmmssToDate(c.V_31571),
      dataInicio: this.glb.ddMMYYYYThhmmssToDate(c.V_31967),
      dataFim: this.glb.ddMMYYYYThhmmssToDate(c.V_31968),
      codPAcienteNOVO: c.V_31969,
      medicamentoControlado: this.lib.getBoolean(c.V_32857),
      profissional: c.V_32858,
      interromperMedicacaoTerapia: this.lib.getBoolean(c.V_32859),
      motivoOrientacoes: c.V_32860,
      tempoEstimado: c.V_32861,
      quantTEstimado: c.V_32862,
      mEDICAMENTOINAPROPRIADOPARAIDOSOS: c.V_32910,
      exame: c.V_32911,
      mEDICAMENTOINAPROPRIADOPELOCLCR: c.V_32913,
      codHospedeTelaPrescricao: c.V_32939,
      sCRIPTPARAVERIFICARQUALOTIPODAPOSOLOGIAESELECIONARQUALHORARIOEXIBIRSEOHORARIODA1DOSEOUOPERSONALIZADO: c.V_34286,
      validade: this.glb.ddMMYYYYThhmmssToDate(c.V_34465),
      materialID: c.V_34709,
      iNTERVALODS: c.V_35051,
      gOTASPMl: c.V_101099,
      consumoDiario: c.V_101103,

    } as IPrescription;
  })

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
  * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date = null, endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IPrescription> {
    try {
      this.cadastroSrv.dtInicial = startDate ? this.glb.dateToYYYYMMddThhmmss(startDate) : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd, startDate, endDate)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  save<T>(ano: number, prescription: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(prescription as unknown as IPrescription).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(prescription: IPrescription): IForm[] {
    try {
      return [
        { VariavelNo: 828, ValorDado: prescription.medicamentoTerapia || '' },
        { VariavelNo: 830, ValorDado: prescription.tempoUso || '' },
        { VariavelNo: 832, ValorDado: prescription.dosagem || '' },
        { VariavelNo: 2949, ValorDado: prescription.vIA || '' },
        { VariavelNo: 2952, ValorDado: prescription.orientacoes || '' },
        { VariavelNo: 3530, ValorDado: prescription.posologia || '' },
        { VariavelNo: 27993, ValorDado: prescription.centrocusto || '' },
        { VariavelNo: 28018, ValorDado: prescription.tipoMaterial || '' },
        { VariavelNo: 29828, ValorDado: prescription.idPaciente || '' },
        { VariavelNo: 29834, ValorDado: prescription.apresentacao || '' },
        { VariavelNo: 29838, ValorDado: prescription.quantPorHorario || '' },
        { VariavelNo: 30212, ValorDado: prescription.codigo || '' },
        { VariavelNo: 30213, ValorDado: prescription.titulo || '' },
        { VariavelNo: 30214, ValorDado: prescription.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 30263, ValorDado: prescription.idMedicamento || '' },
        { VariavelNo: 30264, ValorDado: prescription.cascataCheckIdMedicamentoIdPacienteDataEVOLUCAO || '' },
        { VariavelNo: 30266, ValorDado: prescription.centroCustoIDCentroCusto || '' },
        { VariavelNo: 30296, ValorDado: prescription.codigoPacienteNomePaciente || '' },
        { VariavelNo: 30320, ValorDado: prescription.codPacienteNomedoPacienteCodMedicamento || '' },
        { VariavelNo: 30330, ValorDado: this.glb.ddMMYYYYThhmmssToDate(prescription.dataPrescricao) },
        { VariavelNo: 30339, ValorDado: prescription.tipoMedicamentosCodPaciente || '' },
        { VariavelNo: 30356, ValorDado: prescription.medicamentoPrescrito || '' },
        { VariavelNo: 30367, ValorDado: prescription.horaprimeiraDose || '' },
        { VariavelNo: 30401, ValorDado: prescription.idMedicamentoIdPaciente || '' },
        { VariavelNo: 31571, ValorDado: this.glb.ddMMYYYYThhmmssToDate(prescription.dataEvolucao) },
        { VariavelNo: 31967, ValorDado: this.glb.ddMMYYYYThhmmssToDate(prescription.dataInicio) },
        { VariavelNo: 31968, ValorDado: this.glb.ddMMYYYYThhmmssToDate(prescription.dataFim) },
        { VariavelNo: 31969, ValorDado: prescription.codPAcienteNOVO || '' },
        { VariavelNo: 32857, ValorDado: prescription.medicamentoControlado ? 'SIM' : 'NÃO' },
        { VariavelNo: 32858, ValorDado: prescription.profissional || '' },
        { VariavelNo: 32859, ValorDado: prescription.interromperMedicacaoTerapia ? 'SIM' : 'NÃO' },
        { VariavelNo: 32860, ValorDado: prescription.motivoOrientacoes || '' },
        { VariavelNo: 32861, ValorDado: prescription.tempoEstimado || '' },
        { VariavelNo: 32862, ValorDado: prescription.quantTEstimado || '' },
        { VariavelNo: 32910, ValorDado: prescription.mEDICAMENTOINAPROPRIADOPARAIDOSOS || '' },
        { VariavelNo: 32911, ValorDado: prescription.exame || '' },
        { VariavelNo: 32913, ValorDado: prescription.mEDICAMENTOINAPROPRIADOPELOCLCR || '' },
        { VariavelNo: 32939, ValorDado: prescription.codHospedeTelaPrescricao || '' },
        { VariavelNo: 34286, ValorDado: prescription.sCRIPTPARAVERIFICARQUALOTIPODAPOSOLOGIAESELECIONARQUALHORARIOEXIBIRSEOHORARIODA1DOSEOUOPERSONALIZADO || '' },
        { VariavelNo: 34465, ValorDado: this.glb.ddMMYYYYThhmmssToDate(prescription.validade) },
        { VariavelNo: 34709, ValorDado: prescription.materialID || '' },
        { VariavelNo: 35051, ValorDado: prescription.iNTERVALODS || '' },
        { VariavelNo: 101099, ValorDado: prescription.gOTASPMl || '' },
        { VariavelNo: 101103, ValorDado: prescription.consumoDiario || '' },

      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist<T>(ano: number, prescription: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], prescription[compareFieldName])) >= 0);
            if (!founded) {
              return this.save<IPrescription>(ano, prescription as unknown as IPrescription, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }


}
