import { Injectable } from '@angular/core';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';

@Injectable({
	providedIn: 'root'
})
export class LocalMsgPtBR extends MsgPtBR {
	MODULE_NAME_AGENDA = 'Agenda';
	MODULE_NAME_ATIVIDADE = 'Atividade';
	MODULE_NAME_HISTORICO = 'Histórico';
	MODULE_NAME_MULTIATIVIDADE = 'Multiatividade';
	MODULE_NAME_MULTIATIVIDADE_LISTA = 'Lista Multiatividade';
	MODULE_NAME_PROCESSO = 'Processo';
	MODULE_NAME_TAREFA = 'Tarefa';
	MODULE_NAME_HOME = 'Home';
	DEVICE_INTERNET_CONECTION_NOT_AVAILABLE = 'Sua conexão com a Internet não está disponível. Cheque sua rede e tente novamente!';
	SYNC_DATA_BEING_SAVED = 'Seus dados estão sendo sincronizados!';
	MEDICATION_REJECTED = 'Medicação foi rejeitada';
	MEDICATION_SUCCESS = 'Medicação registrada com sucesso!';
	MEDICATION_EMPTY = 'Nenhuma medicação cadastrada para esse residente, ou todos os checkins já realizados!';
	MEDICATION_CHECKIN_FAIL = 'Houve falha na tentativa de registrar a medicação!';
	LOGIN_FAIL = 'Usuário ou senha inválido!';
	LOGIN_FAIL_TITLE = 'Falha no login';
	STOP_MEDICATION = 'ATENÇÃO: ESSA MEDICAÇÃO ESTÁ COM INSTRUÇÕES PARA SER INTERROMPIDA! EM CASO DE DÚVIDAS, CONVERSAR COM O MÉDICO/ENFERMEIRO RESPONSÁVEL!';
	CONFIRM_ALL_MEDICATION = 'Tem certeza que deseja realizar o checkin de TODAS as medicações desse período?';
	STOP_NO_ADDITIONAL_INSTRUCTIONS = 'INTERROMPER. SEM INSTRUÇÕES ADICIONAIS';
	NO_OBSERVATION = 'Nenhuma observação relatada.';
	TEMPLATE_EDITION_CONFIRM = 'ATENÇÃO: Você editará o modelo, utilizado para lembrar você dos lançamentos recorrentes. Tem certeza que quer continuar?';
	NEW_FROM_TEMPLATE = 'Você deseja criar um novo lançamento a partir desse modelo que foi selecionado? Além de criar o lançamento, esse modelo será reagendado para o próximo período';
	REQUIRED_FIELD_NOT_FILLED = 'Há campos obrigatórios nessa tela que ainda não foram preenchidos!';
	BUTTON_OK = 'OK';
	BUTTON_CONFIRM = 'Confirmar';
	BUTTON_DELETE = 'Excluir';
	BTN_SAVE_AND_NEW = 'Salvar e Novo';
	BTN_SAVE = 'Salvar';
	BTN_DUPLICATE = 'Duplicar';
	DIALOG_TITLE_EXECUTE_NEXT_STEP = 'Deseja abrir a próxima tarefa?';
	DIALOG_TITLE_FORM_INVALID = 'Dados incompletos ou inválidos!';
	DIALOG_MESSAGE_FORM_INVALID = 'Existem campos que não foram preenchidos, ou estão incorretos. Gentileza observar os itens em vermelho.';
	BTN_NEW = 'Novo';
	BTN_YES = 'SIM';
	BTN_NO = 'NÃO';
	REQUIRED_FIELD_NOT_FILLED_TITLE = 'PREENCHIMENTO INVÁLIDO';
	ALERT_CONFIRMATION_TITLE = 'Confirmação';
	ALERT_WARNING_TITLE = 'Atenção';
	ALERT_ACTIVITY_SAVED = 'Atividade salva com sucesso!';
	ALERT_ACTIVITY_SAVE_FAILED = 'Houve falha na tentativa de salvar a Atividade. Cheque sua conexão com a Internet e tente novamente!';
	DIALOG_INVOICE_GENERATE_TITLE = 'Pedido de Compra';
	DIALOG_INVOICE_GENERATE_NOT_SELECTED = 'É necessário selecionar pelo menos um item!';
	DIALOG_NO_PURCHASE_FINDED = 'Não existem pedidos de compra pendentes!';
	TITLE_FINANTIAL_REPORT = `Relatório Financeiro`;
	TITLE_JSREPORT_SEPARATION_LABEL_REPORT = `Separação de Medicamentos`;
	TITLE_PERIOD_DIALOG = `Selecione um intervalo de datas:`;
	RPT_SEPARATION_STOCK_CHANGE_CONFIRM = `Tem certeza que quer dar baixa no estoque de todos os itens relacionados?`;
	CTR_GRID_EDIT_INVALID_INDEX_TITLE = `Há um problema com esse item:`;
	CTR_GRID_EDIT_INVALID_INDEX = `O item atual não pode ser editado, pois, está com um índice inválido!`;
	VIEW_STOCK_NO_ITEMS_TITLE = `Nenhum item encontrado.`;
	VIEW_STOCK_NO_ITEMS_SUBTITLE = `Vamos começar?`;
	VIEW_STOCK_SHOW_VALUE = `A exibição dos valores de estoque em Reais, como também a Geração de Pedidos, necessita que todos os históricos de compra sejam carregados ao mesmo tempo... Poderá levar alguns segundos...`;
	NO_EXIST = 'INEXISTENTE';
	PROFESSIONAL_LABEL = `RESPONSÁVEL`;
	STOCK_UPDATE_KITCHEN_SUCCESS = 'Os itens foram baixados do estoque com sucesso!';
	STOCK_UPDATE_KITCHEN_FAIL = 'Houve falha na tentativa de salvar o estoque!';
	CONFIRM_TITLE_CLEAN_DIALOG = 'Tem certeza que quer limpar todo o conteúdo?';
	TITLE_SELECT_A_DATE = 'Por gentileza, escolha uma data!';
	SELECT_DATA_VALID = 'Por favor escolha a data de hoje!';
	INVALID_DATE = 'A data escolhida não é válida!';
	STOCK_CHANGE_BY_APP = 'Baixa via App';
	EMAIL_ENVIADO_PARA = 'E-mail de notificação enviado para:';
}
