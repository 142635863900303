import { LocalLibService } from '@medlogic/shared/shared-interfaces';
import { IServiceProvider } from '@medlogic/shared/shared-interfaces';
import { IForm } from '@medlogic/shared/shared-interfaces';
import { IPerson } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { GlobalService, LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';

export abstract class PersonService implements IServiceProvider {

  private lstVariaveis = 'V_387,V_391,V_757,V_762,V_764,V_1259,V_1608,V_2230,V_2231,V_2232,V_2261,V_2262,V_2263,V_2265,V_2267,V_2268,V_2269,V_2273,V_2274,V_2275,V_2276,V_2277,V_2278,V_2279,V_2280,V_2282,V_2283,V_2287,V_2418,V_2689,V_3280,V_3328,V_3329,V_3487,V_3488,V_3491,V_3492,V_3911,V_4644,V_4645,V_4647,V_4648,V_4649,V_4651,V_4656,V_4657,V_4658,V_4659,V_4663,V_4664,V_4665,V_4666,V_27875,V_27877,V_27892,V_27897,V_27907,V_27908,V_27909,V_27911,V_27913,V_27914,V_27917,V_27918,V_27919,V_27920,V_27982,V_27983,V_27993,V_28051,V_28065,V_28452,V_28660,V_28661,V_28662,V_28755,V_28756,V_28757,V_28758,V_28759,V_28760,V_28761,V_28762,V_28763,V_28764,V_28765,V_28766,V_28767,V_28768,V_28769,V_28770,V_28774,V_28868,V_28954,V_28958,V_28960,V_28970,V_28982,V_29006,V_29007,V_29008,V_29009,V_29010,V_29011,V_29012,V_29013,V_29065,V_29166,V_29197,V_29293,V_29294,V_29792,V_29793,V_29818,V_30005,V_30006,V_30028,V_32656,V_33080,V_34273,V_34723,V_100965,V_100966,V_100967,V_100971,V_101052,V_101053,V_101064,V_101065,V_101066,V_101171,V_101175,V_101196,V_101226,V_101255,V_101256,V_101260,V_101709,V_101795,V_101796,V_101797,V_101798,V_101799,V_101800,V_101801,V_101802,V_101803,V_101804,V_101808,V_101809,V_102007,V_102009,V_103070,V_3304';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IPerson[] = new Array<IPerson>();

  codigoVariavelNo = 2230; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 2243;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigJsonService,
    protected lib: LocalLibService,
    protected log: LogService) { }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IPerson> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IPerson> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IPerson> {
    try {
      if (
        (!this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial))
        || (!this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal))
        || (!this.cadastrosCache)
        // (startDate.getTime() !== this.currentDtInicial.getTime())
        // || (endDate.getTime() !== this.currentDtFinal.getTime())
        // || (!this.cadastrosCache)
      ) {
        this.currentDtInicial = startDate;
        this.currentDtFinal = endDate;
        this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
      } else {
        console.log('retorno do cache');
      }
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables, startDate, endDate)
        .pipe(
          this.mapTo(),
          // publishReplay(),
          // refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected mapTo = () => map((c: any) => {
    return {
      ocorrenciaNo: c?.OcorrenciaNo,
      nomeResidente: c?.V_387,
      nascimento: c?.V_391,
      pesoAtualKg: c?.V_757,
      alturaM: c?.V_762,
      iMC: c?.V_764,
      dIAGNOSTICODASDOENCASOUCONDICOESCRONICASDESAUDE: c?.V_1259,
      nomemae: c?.V_1608,
      codigo: c?.V_2230,
      titulo: c?.V_2231,
      habilitado: this.lib.getBoolean(c?.V_2232),
      sexo: c?.V_2261,
      estadoCivil: c?.V_2262,
      estado: c?.V_2263,
      cor: c?.V_2265,
      possuiBeneficio: this.lib.getBoolean(c?.V_2267),
      religiao: c?.V_2268,
      data: this.glb.ddMMYYYYThhmmssToDate(c?.V_2269),
      profissao: c?.V_2273,
      idade: c?.V_2274,
      naturalidade: c?.V_2275,
      paisNascimento: c?.V_2276,
      nomeSocial: c?.V_2277,
      cPF: c?.V_2278,
      rG: c?.V_2279,
      escolaridade: c?.V_2280,
      enfermeiroReferencia: c?.V_2282,
      medicoReferencia: c?.V_2283,
      telefone: c?.V_2287,
      planoSaude: c?.V_2418,
      alturaQuadrado: c?.V_2689,
      classificacao: c?.V_3280,
      identificacao1: c?.V_3328,
      identificacao2: c?.V_3329,
      temAlgumaAlergiamaiorGravidade: this.lib.getBoolean(c?.V_3487),
      seSimQual: c?.V_3488,
      temAlgumaDeficienciaVisualAuditivaFisicaOuOutra: this.lib.getBoolean(c?.V_3491),
      seSimQual2: c?.V_3492,
      nomepai: c?.V_3911,
      unidadeNegocio: c?.V_4644,
      vencimento: this.glb.ddMMYYYYThhmmssToDate(c?.V_4645),
      categoria: c?.V_4647,
      fonte: c?.V_4648,
      pagamento: c?.V_4649,
      valor: c?.V_4651,
      descricao: c?.V_4656,
      status: c?.V_4657,
      banco: c?.V_4658,
      observacao: c?.V_4659,
      parcela: c?.V_4663,
      totalParcelas: c?.V_4664,
      tipo: c?.V_4665,
      pago: this.lib.getBoolean(c?.V_4666),
      titulo2: c?.V_27875,
      dataRegistro: this.glb.ddMMYYYYThhmmssToDate(c?.V_27877),
      meioPagamento: c?.V_27892,
      classe: c?.V_27897,
      beneficiario: c?.V_27907,
      recorrente: this.lib.getBoolean(c?.V_27908),
      dataInicio2: this.glb.ddMMYYYYThhmmssToDate(c?.V_27909),
      periodicidade: c?.V_27911,
      tiporecorrencia: this.lib.getBoolean(c?.V_27913),
      diaVencimento: c?.V_27914,
      bancoPadrao: c?.V_27917,
      categoriaPadrao: c?.V_27918,
      fontePadrao: c?.V_27919,
      beneficiarioPadrao: c?.V_27920,
      criptografarValor: this.lib.getBoolean(c?.V_27982),
      valorCriptografado: c?.V_27983,
      centroCusto: c?.V_27993,
      prontuario: c?.V_28051,
      situacaoHospede: ['Ativo', 'Inativo'].includes(c?.V_28065) ? this.lib.getBoolean(c?.V_28065, 'Ativo') : this.lib.getBoolean(c?.V_28065),
      nF: c?.V_28452,
      pacienteFalecido: this.lib.getBoolean(c?.V_28660),
      dataObito: c?.V_28661,
      anoObito: c?.V_28662,
      usaFralda: this.lib.getBoolean(c?.V_28755),
      comeSozinho: this.lib.getBoolean(c?.V_28756),
      cadeirante: this.lib.getBoolean(c?.V_28757),
      traqueostomizado: this.lib.getBoolean(c?.V_28758),
      ulceradecubito: this.lib.getBoolean(c?.V_28759),
      sondaGastrica: this.lib.getBoolean(c?.V_28760),
      sondaNaso: this.lib.getBoolean(c?.V_28761),
      comUlceraPosHospitalizacao: this.lib.getBoolean(c?.V_28762),
      hospital: c?.V_28763,
      fraturaColofemur: c?.V_28764,
      diabetico: this.lib.getBoolean(c?.V_28765),
      usaInsulina: this.lib.getBoolean(c?.V_28766),
      menores60: c?.V_28767,
      dataAdmissao: c?.V_28768,
      anoAdmissao: c?.V_28769,
      admissaoAntes2000: c?.V_28770,
      hospitalSim: c?.V_28774,
      agendamento: c?.V_28868,
      urlLogo: c?.V_28954,
      prontuarioSoma: c?.V_28958,
      totalCadastro: c?.V_28960,
      pRTO: c?.V_28970,
      identificacoes: c?.V_28982,
      endereco: c?.V_29006,
      numero: c?.V_29007,
      bairro: c?.V_29008,
      cidade: c?.V_29009,
      telefone2: c?.V_29010,
      estado2: c?.V_29011,
      celular: c?.V_29012,
      eMail: c?.V_29013,
      quarto: c?.V_29065,
      getUsuarioToken: c?.V_29166,
      regiao: c?.V_29197,
      trato: c?.V_29293,
      educacao: c?.V_29294,
      referencia: c?.V_29792,
      perfilCliente: c?.V_29793,
      informacoesImportantes: c?.V_29818,
      retornoWebApiCentroCusto: c?.V_30005,
      indicacaoQuemIndicouempresa: c?.V_30006,
      integradoFinanceiro: c?.V_30028,
      ofxId: c?.V_32656,
      dataInicio: c?.V_33080,
      complemento: c?.V_34273,
      eNDERECOCOMPLETO: c?.V_34723,
      dataDesligamento: c?.V_100965,
      dataInativacao: this.glb.ddMMYYYYThhmmssToDate(c?.V_100966),
      motivodesligamento: c?.V_100967,
      graudependencia: c?.V_100971,
      tipoBeneficio: c?.V_101052,
      nBeneficio: c?.V_101053,
      livro: c?.V_101064,
      folha: c?.V_101065,
      termo: c?.V_101066,
      mesaniversario: c?.V_101171,
      dataAniversario: c?.V_101175,
      mesStatus: c?.V_101196,
      eInterditado: this.lib.getBoolean(c?.V_101226),
      equipeatendimento: c?.V_101255,
      motivoencaminhamentoaInstituicao: c?.V_101256,
      cNPJ: c?.V_101260,
      integradoFinanceiro2: c?.V_101709,
      cliente: c?.V_101795,
      dataAniversarioContrato: this.glb.ddMMYYYYThhmmssToDate(c?.V_101796),
      pagaDecimoTerceiro: this.lib.getBoolean(c?.V_101797),
      numeroParcelas2: c?.V_101798,
      dataInicioPagamento2: this.glb.ddMMYYYYThhmmssToDate(c?.V_101799),
      dataFimPagamento2: c?.V_101800,
      pagaEnxoval: this.lib.getBoolean(c?.V_101801),
      numeroParcelas: c?.V_101802,
      dataInicioPagamento: this.glb.ddMMYYYYThhmmssToDate(c?.V_101803),
      dataFimPagamento: c?.V_101804,
      calcDataFimPagamento2: c?.V_101808,
      calcDataFimPagamento3: c?.V_101809,
      dtNascimento: this.glb.ddMMYYYYThhmmssToDate(c?.V_102007),
      dtAdmissao: this.glb.ddMMYYYYThhmmssToDate(c?.V_102009),
      classificacao2: c?.V_103070,
      imgUrl: c?.V_3304,
    } as IPerson;
  });

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
  * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date = null, endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IPerson> {
    try {
      this.cadastroSrv.dtInicial = startDate ? this.glb.dateToYYYYMMddThhmmss(startDate) : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd, startDate, endDate)
        .pipe(
          this.mapTo(),
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  save<T>(ano: number, person: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(person as unknown as IPerson).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(person: IPerson): IForm[] {
    try {
      return [
        { VariavelNo: 387, ValorDado: person?.nomeResidente || '' },
        { VariavelNo: 391, ValorDado: person?.nascimento || '' },
        { VariavelNo: 757, ValorDado: person?.pesoAtualKg || '' },
        { VariavelNo: 762, ValorDado: person?.alturaM || '' },
        { VariavelNo: 764, ValorDado: person?.iMC || '' },
        { VariavelNo: 1259, ValorDado: person?.dIAGNOSTICODASDOENCASOUCONDICOESCRONICASDESAUDE || '' },
        { VariavelNo: 1608, ValorDado: person?.nomemae || '' },
        { VariavelNo: 2230, ValorDado: person?.codigo || '' },
        { VariavelNo: 2231, ValorDado: person?.titulo || '' },
        { VariavelNo: 2232, ValorDado: person?.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 2261, ValorDado: person?.sexo || '' },
        { VariavelNo: 2262, ValorDado: person?.estadoCivil || '' },
        { VariavelNo: 2263, ValorDado: person?.estado || '' },
        { VariavelNo: 2265, ValorDado: person?.cor || '' },
        { VariavelNo: 2267, ValorDado: person?.possuiBeneficio ? 'SIM' : 'NÃO' },
        { VariavelNo: 2268, ValorDado: person?.religiao || '' },
        { VariavelNo: 2269, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.data) },
        { VariavelNo: 2273, ValorDado: person?.profissao || '' },
        { VariavelNo: 2274, ValorDado: person?.idade || '' },
        { VariavelNo: 2275, ValorDado: person?.naturalidade || '' },
        { VariavelNo: 2276, ValorDado: person?.paisNascimento || '' },
        { VariavelNo: 2277, ValorDado: person?.nomeSocial || '' },
        { VariavelNo: 2278, ValorDado: person?.cPF || '' },
        { VariavelNo: 2279, ValorDado: person?.rG || '' },
        { VariavelNo: 2280, ValorDado: person?.escolaridade || '' },
        { VariavelNo: 2282, ValorDado: person?.enfermeiroReferencia || '' },
        { VariavelNo: 2283, ValorDado: person?.medicoReferencia || '' },
        { VariavelNo: 2287, ValorDado: person?.telefone || '' },
        { VariavelNo: 2418, ValorDado: person?.planoSaude || '' },
        { VariavelNo: 2689, ValorDado: person?.alturaQuadrado || '' },
        { VariavelNo: 3280, ValorDado: person?.classificacao || '' },
        { VariavelNo: 3328, ValorDado: person?.identificacao1 || '' },
        { VariavelNo: 3329, ValorDado: person?.identificacao2 || '' },
        { VariavelNo: 3487, ValorDado: person?.temAlgumaAlergiamaiorGravidade ? 'SIM' : 'NÃO' },
        { VariavelNo: 3488, ValorDado: person?.seSimQual || '' },
        { VariavelNo: 3491, ValorDado: person?.temAlgumaDeficienciaVisualAuditivaFisicaOuOutra ? 'SIM' : 'NÃO' },
        { VariavelNo: 3492, ValorDado: person?.seSimQual2 || '' },
        { VariavelNo: 3911, ValorDado: person?.nomepai || '' },
        { VariavelNo: 4644, ValorDado: person?.unidadeNegocio || '' },
        { VariavelNo: 4645, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.vencimento) },
        { VariavelNo: 4647, ValorDado: person?.categoria || '' },
        { VariavelNo: 4648, ValorDado: person?.fonte || '' },
        { VariavelNo: 4649, ValorDado: person?.pagamento || '' },
        { VariavelNo: 4651, ValorDado: person?.valor || '' },
        { VariavelNo: 4656, ValorDado: person?.descricao || '' },
        { VariavelNo: 4657, ValorDado: person?.status || '' },
        { VariavelNo: 4658, ValorDado: person?.banco || '' },
        { VariavelNo: 4659, ValorDado: person?.observacao || '' },
        { VariavelNo: 4663, ValorDado: person?.parcela || '' },
        { VariavelNo: 4664, ValorDado: person?.totalParcelas || '' },
        { VariavelNo: 4665, ValorDado: person?.tipo || '' },
        { VariavelNo: 4666, ValorDado: person?.pago ? 'SIM' : 'NÃO' },
        { VariavelNo: 27875, ValorDado: person?.titulo2 || '' },
        { VariavelNo: 27877, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.dataRegistro) },
        { VariavelNo: 27892, ValorDado: person?.meioPagamento || '' },
        { VariavelNo: 27897, ValorDado: person?.classe || '' },
        { VariavelNo: 27907, ValorDado: person?.beneficiario || '' },
        { VariavelNo: 27908, ValorDado: person?.recorrente ? 'SIM' : 'NÃO' },
        { VariavelNo: 27909, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.dataInicio2) },
        { VariavelNo: 27911, ValorDado: person?.periodicidade || '' },
        { VariavelNo: 27913, ValorDado: person?.tiporecorrencia ? 'SIM' : 'NÃO' },
        { VariavelNo: 27914, ValorDado: person?.diaVencimento || '' },
        { VariavelNo: 27917, ValorDado: person?.bancoPadrao || '' },
        { VariavelNo: 27918, ValorDado: person?.categoriaPadrao || '' },
        { VariavelNo: 27919, ValorDado: person?.fontePadrao || '' },
        { VariavelNo: 27920, ValorDado: person?.beneficiarioPadrao || '' },
        { VariavelNo: 27982, ValorDado: person?.criptografarValor ? 'SIM' : 'NÃO' },
        { VariavelNo: 27983, ValorDado: person?.valorCriptografado || '' },
        { VariavelNo: 27993, ValorDado: person?.centroCusto || '' },
        { VariavelNo: 28051, ValorDado: person?.prontuario || '' },
        { VariavelNo: 28065, ValorDado: person?.situacaoHospede ? 'SIM' : 'NÃO' },
        { VariavelNo: 28452, ValorDado: person?.nF || '' },
        { VariavelNo: 28660, ValorDado: person?.pacienteFalecido ? 'SIM' : 'NÃO' },
        { VariavelNo: 28661, ValorDado: person?.dataObito || '' },
        { VariavelNo: 28662, ValorDado: person?.anoObito || '' },
        { VariavelNo: 28755, ValorDado: person?.usaFralda ? 'SIM' : 'NÃO' },
        { VariavelNo: 28756, ValorDado: person?.comeSozinho ? 'SIM' : 'NÃO' },
        { VariavelNo: 28757, ValorDado: person?.cadeirante ? 'SIM' : 'NÃO' },
        { VariavelNo: 28758, ValorDado: person?.traqueostomizado ? 'SIM' : 'NÃO' },
        { VariavelNo: 28759, ValorDado: person?.ulceradecubito ? 'SIM' : 'NÃO' },
        { VariavelNo: 28760, ValorDado: person?.sondaGastrica ? 'SIM' : 'NÃO' },
        { VariavelNo: 28761, ValorDado: person?.sondaNaso ? 'SIM' : 'NÃO' },
        { VariavelNo: 28762, ValorDado: person?.comUlceraPosHospitalizacao ? 'SIM' : 'NÃO' },
        { VariavelNo: 28763, ValorDado: person?.hospital || '' },
        { VariavelNo: 28764, ValorDado: person?.fraturaColofemur || '' },
        { VariavelNo: 28765, ValorDado: person?.diabetico ? 'SIM' : 'NÃO' },
        { VariavelNo: 28766, ValorDado: person?.usaInsulina ? 'SIM' : 'NÃO' },
        { VariavelNo: 28767, ValorDado: person?.menores60 || '' },
        { VariavelNo: 28768, ValorDado: person?.dataAdmissao || '' },
        { VariavelNo: 28769, ValorDado: person?.anoAdmissao || '' },
        { VariavelNo: 28770, ValorDado: person?.admissaoAntes2000 || '' },
        { VariavelNo: 28774, ValorDado: person?.hospitalSim || '' },
        { VariavelNo: 28868, ValorDado: person?.agendamento || '' },
        { VariavelNo: 28954, ValorDado: person?.urlLogo || '' },
        { VariavelNo: 28958, ValorDado: person?.prontuarioSoma || '' },
        { VariavelNo: 28960, ValorDado: person?.totalCadastro || '' },
        { VariavelNo: 28970, ValorDado: person?.pRTO || '' },
        { VariavelNo: 28982, ValorDado: person?.identificacoes || '' },
        { VariavelNo: 29006, ValorDado: person?.endereco || '' },
        { VariavelNo: 29007, ValorDado: person?.numero || '' },
        { VariavelNo: 29008, ValorDado: person?.bairro || '' },
        { VariavelNo: 29009, ValorDado: person?.cidade || '' },
        { VariavelNo: 29010, ValorDado: person?.telefone2 || '' },
        { VariavelNo: 29011, ValorDado: person?.estado2 || '' },
        { VariavelNo: 29012, ValorDado: person?.celular || '' },
        { VariavelNo: 29013, ValorDado: person?.eMail || '' },
        { VariavelNo: 29065, ValorDado: person?.quarto || '' },
        { VariavelNo: 29166, ValorDado: person?.getUsuarioToken || '' },
        { VariavelNo: 29197, ValorDado: person?.regiao || '' },
        { VariavelNo: 29293, ValorDado: person?.trato || '' },
        { VariavelNo: 29294, ValorDado: person?.educacao || '' },
        { VariavelNo: 29792, ValorDado: person?.referencia || '' },
        { VariavelNo: 29793, ValorDado: person?.perfilCliente || '' },
        { VariavelNo: 29818, ValorDado: person?.informacoesImportantes || '' },
        { VariavelNo: 30005, ValorDado: person?.retornoWebApiCentroCusto || '' },
        { VariavelNo: 30006, ValorDado: person?.indicacaoQuemIndicouempresa || '' },
        { VariavelNo: 30028, ValorDado: person?.integradoFinanceiro2 || '' },
        { VariavelNo: 32656, ValorDado: person?.ofxId || '' },
        { VariavelNo: 33080, ValorDado: person?.dataInicio || '' },
        { VariavelNo: 34273, ValorDado: person?.complemento || '' },
        { VariavelNo: 34723, ValorDado: person?.eNDERECOCOMPLETO || '' },
        { VariavelNo: 100965, ValorDado: person?.dataDesligamento || '' },
        { VariavelNo: 100966, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.dataInativacao) },
        { VariavelNo: 100967, ValorDado: person?.motivodesligamento || '' },
        { VariavelNo: 100971, ValorDado: person?.graudependencia || '' },
        { VariavelNo: 101052, ValorDado: person?.tipoBeneficio || '' },
        { VariavelNo: 101053, ValorDado: person?.nBeneficio || '' },
        { VariavelNo: 101064, ValorDado: person?.livro || '' },
        { VariavelNo: 101065, ValorDado: person?.folha || '' },
        { VariavelNo: 101066, ValorDado: person?.termo || '' },
        { VariavelNo: 101171, ValorDado: person?.mesaniversario || '' },
        { VariavelNo: 101175, ValorDado: person?.dataAniversario || '' },
        { VariavelNo: 101196, ValorDado: person?.mesStatus || '' },
        { VariavelNo: 101226, ValorDado: person?.eInterditado ? 'SIM' : 'NÃO' },
        { VariavelNo: 101255, ValorDado: person?.equipeatendimento || '' },
        { VariavelNo: 101256, ValorDado: person?.motivoencaminhamentoaInstituicao || '' },
        { VariavelNo: 101260, ValorDado: person?.cNPJ || '' },
        { VariavelNo: 101709, ValorDado: person?.integradoFinanceiro || '' },
        { VariavelNo: 101795, ValorDado: person?.cliente || '' },
        { VariavelNo: 101796, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.dataAniversarioContrato) },
        { VariavelNo: 101797, ValorDado: person?.pagaDecimoTerceiro ? 'SIM' : 'NÃO' },
        { VariavelNo: 101798, ValorDado: person?.numeroParcelas2 || '' },
        { VariavelNo: 101799, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.dataInicioPagamento) },
        { VariavelNo: 101800, ValorDado: person?.dataFimPagamento || '' },
        { VariavelNo: 101801, ValorDado: person?.pagaEnxoval ? 'SIM' : 'NÃO' },
        { VariavelNo: 101802, ValorDado: person?.numeroParcelas || '' },
        { VariavelNo: 101803, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.dataInicioPagamento) },
        { VariavelNo: 101804, ValorDado: person?.dataFimPagamento || '' },
        { VariavelNo: 101808, ValorDado: person?.calcDataFimPagamento2 || '' },
        { VariavelNo: 101809, ValorDado: person?.calcDataFimPagamento3 || '' },
        { VariavelNo: 102007, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.dtNascimento) },
        { VariavelNo: 102009, ValorDado: this.glb.ddMMYYYYThhmmssToDate(person?.dtAdmissao) },
        { VariavelNo: 103070, ValorDado: person?.classificacao || '' },
        { VariavelNo: 3304, ValorDado: person?.imgUrl || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist<T>(ano: number, person: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], person[compareFieldName])) >= 0);
            if (!founded) {
              return this.save(ano, person, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }


}
