import { ICarePlanIntent } from './icareplan-intent';
import { ICarePlanCategory } from './icareplan-category';
import { IFhirPatient } from './ifhir-patient';
import { IActivity } from './iactivity';
import { ICarePlanActivity } from './icareplan-activity';
import { IPatientOnCarePlan } from './ipatient-on-careplan';

export interface ICarePlan {
    carePlanIdentifier: string;
    instantiatesCanonical: string;
    instantiatesUri: string;
    basedOn: string;
    carePlan: ICarePlan[];
    replaces: string;
    carePlanReplace: ICarePlan[];
    partOf: string;
    carePlanPartOf: ICarePlan[];
    status: string;
    carePlanIntentIdentifier: string;
    carePlanIntent: ICarePlanIntent[]
    carePlanCategoryIdentifier: string;
    carePlanCategory: ICarePlanCategory[];
    carePlanTitle: string;
    description: string;
    subjectPatientIdentifier: string;
    patient: IFhirPatient[];
    encounter: string;
    period: string;
    created: Date;
    author: string;
    contributor: string;
    careTeam: string;
    addresses: string;
    supportingInfo: string;
    goal: string;
    periodStart: Date;
    periodEnd: Date;
    system: string;
    activityidentifier: string;
    activity: IActivity[];
    note: string;
    tenantId: number;
    language: string;
    patientOnCarePlan: IPatientOnCarePlan[];
    carePlanActivity: ICarePlanActivity[];
}
