import { OnDestroy, Injectable } from '@angular/core';
import { SubSink } from 'subsink';

@Injectable()
export class UnsubscribeOnDestroyAdapter implements OnDestroy {

  protected subs = new SubSink();

   /* Chamado na destruição do componente. Necessário desiscrever de todos os observables. */
  ngOnDestroy() {
    try {
      // Automaticamente realiza unsubscribe de todos os observables adicionados ao SubSink.
      this.subs.unsubscribe();
    } catch (error) {
      console.log(this.constructor.name, 'ngOnDestroy', error.message);
    }
  }

}
