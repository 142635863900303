import { EmployeeCustomService } from './employee.custom.service';
import { StockItemCustomService } from './stock-item.custom.service';
import { PrescriptionCustomService } from './prescription.custom.service';
import { IntervencoesMedicamentosService } from './intervercoes-medicamentos.service';
import { SharedUtilsModule } from '@medlogic/shared/utils';
import { ExameCustomService } from './exame.custom.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExameResultadoCustomService } from './exame-resultado.custom.service';
import { MedlogicTenantService } from './medlogic-tenant.service';
import { FrailtyCustomService } from './frailty.custom.service';
import { IntervencoesMedicamentosHorariosService } from './intervecoes-medicamentos.serviceHorario';
import { InterventionMedicationCustomService } from './intervention-medication.custom.service';
import { MedicationCheckinCustomService } from './medication-checkin.custom.service';
import { VitalSignCustomService } from './vital-sign.custom.service';
import { PrescriptionMedicationCustomService } from './prescription-medication.custom.service';
import { IntervencoesMedicamentosHorariosCustomService } from './intervercoes-medicamentos-horario.custom.service';
import { MedicationCustomService } from './medication.custom.service';
import { BusinessUnitCustomService } from './business-unit.custom.service';
import { CostCenterCustomService } from './cost-center.custom.service';
import { PresentationCustomService } from './presentation.custom.service';
import { FamilyCustomService } from './family.custom.service';
import { IssuerIdentificationCustomService } from './issuer-identification.custom.service';

const SERVICES = [
  ExameCustomService,
  ExameResultadoCustomService,
  FrailtyCustomService,
  SharedUtilsModule,
  MedlogicTenantService,
  IntervencoesMedicamentosService,
  IntervencoesMedicamentosHorariosService,
  InterventionMedicationCustomService,
  MedicationCheckinCustomService,
  VitalSignCustomService,
  PrescriptionCustomService,
  PrescriptionMedicationCustomService,
  IntervencoesMedicamentosHorariosCustomService,
  MedicationCustomService,
  BusinessUnitCustomService,
  CostCenterCustomService,
  PresentationCustomService,
  StockItemCustomService,
  FamilyCustomService,
  EmployeeCustomService,
  IssuerIdentificationCustomService
];

@NgModule({
  imports: [CommonModule],
  providers: [SERVICES]
})
export class MedlogicDataAccessModule { }
