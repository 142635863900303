import { cleanEmails } from '@medlogic/shared/state-utils';
import { error } from '@medlogic/shared/shared-interfaces';
import { RedisService } from '@medlogic/shared/shared-data-access';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { clearSelectedPatient } from './../state-patient/+state/patient.actions';
import { cleanCostCenter } from './../state-cost-center/+state/cost-center.actions';
import { cleanGeneric } from '@medlogic/shared/ui/ui-generic-list';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { cleanExame } from '../state-exame/+state/exame.actions';
import { cleanExameResultado } from '../state-exame-resultado/+state/exame-resultado.actions';
import { cleanFrailty } from '../state-frailty/+state/frailty.actions';
import { cleanHorarios } from '../state-horarios/+state/horarios.actions';
import { cleanIvcf } from '../state-ivcf/+state/ivcf.actions';
import { clearPatients } from '../state-patient/+state/patient.actions';
import { clearPerson } from '../state-person/+state/person.actions';
import { clearPrescription } from '../state-prescription/+state/prescription.actions';
import { clearStocks } from '../state-stock/+state/stock.actions';
import { clearTutorials } from '../state-tutorial/+state/tutorial.actions';
import { clearVitalSigns } from '../state-vital-sign/+state/vital-sign.actions';
import { clearEvolutions } from '../state-evolution/+state/evolution.actions';
import { clearMedications } from '../state-medication/+state/medication.actions';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatesManagementService {

  constructor(
    private log: LogService,
    private redis: RedisService,
    private store: Store<IAppMedlogicState>
  ) { }

  cleanAllSelectedStates = () => {
    try {
      this.store.dispatch(cleanGeneric());
      this.store.dispatch(cleanCostCenter());
      this.store.dispatch(clearEvolutions());
      this.store.dispatch(cleanExame());
      this.store.dispatch(cleanExameResultado());
      this.store.dispatch(cleanFrailty());
      this.store.dispatch(cleanHorarios());
      this.store.dispatch(cleanIvcf());
      this.store.dispatch(clearMedications());
      this.store.dispatch(clearSelectedPatient());
      this.store.dispatch(clearPerson());
      this.store.dispatch(clearPrescription());
      this.store.dispatch(clearStocks());
      this.store.dispatch(clearTutorials());
      this.store.dispatch(clearVitalSigns());
      this.store.dispatch(cleanEmails());
      // Alguns states não serão apagados  como o de login e o de cliente para que não caia a sessão.
      // this.store.dispatch(cleanIntervention()); Não está em uso
      // this.store.dispatch(clearProcess()); Não é possível acessar o state process pois gera referencia circular
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanAllSelectedStates', error.message);
    }
  }

  cleanAllStates = () => {
    try {
      this.cleanAllSelectedStates();
      this.store.dispatch(clearPatients());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanAllStates', error.message);
    }
  }

  cleanAllStatesAndCache$ = () => {
    try {
      return this.redis.deleteCustomerKeys()
        .pipe(
          tap(() => this.cleanAllStates()),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanAllStatesAndCache$', error.message);
    }
  }


}
