
import { refCount, publishReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TarefaService  } from '@medlogic/shared/gecore';
import { LibErpService } from '../../erp/service/lib-erp.service';
import { LogService, ITarefaComponente } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../pwa/service/config-pwa-medlogic.custom.service';

@Injectable()
export class LongitudinalHistoryService {

  // ono: number = 50170;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  tarefasCache: Observable<any>;

  constructor(
    private cfg: ConfigPwaMedLogicService,
    private log: LogService,
    private lib: LibErpService,
    private tarefaSrv: TarefaService) { }


  getAll(ocorrenciaNo: number, startDate: Date, endDate: Date): Observable<ITarefaComponente> {
    try {
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(ocorrenciaNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.tarefasCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(ono: number, startDate: Date, endDate: Date): Observable<ITarefaComponente> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.tarefasCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.tarefasCache = this.getFromTarefa(ono, startDate, endDate);
    } else {
      // console.log('cache return');
    }
    return this.tarefasCache;
  }

  protected getFromTarefa(ono: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.tarefaSrv.dtInicial = this.lib.dateToYYYYMMddThhmmss(startDate);
      this.tarefaSrv.dtFinal = this.lib.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando getFromTarefa...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.tarefaSrv
        .getTarefa(ono).pipe(
          map(c => c.lstComponente as ITarefaComponente), // também há lstTarefa
          publishReplay(),
          refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromTarefa', error.message);
    }
    return null;
  }

}
