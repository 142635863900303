import { ISplash } from './ui/isplash';
import { ISnapIvState } from './isnapiv-state';
import { IPersonState } from './iperson-state';
import { ISchoolState } from './ischool-state';
import { IPersonResponsibleState } from './addhere/iperson-responsible-state';
import { IAppState } from './iapp-state';
import { EnViewMode } from '../enum/en-view-mode.enum';
import { IEvolutionState } from './addhere/ievolution-state';
import { IPersonProfessionalState } from './addhere/iperson-professional-state';
import { IPersonEducatorState } from './addhere/iperson-educator-state';
import { ISchoolUserState } from './addhere/ischool-user-state';
import { IPostState } from './addhere/ipost-state';
import { ICommentState } from './addhere/icomment-state';
import { IViewState } from './addhere/iview-state';
import { IPostViewState } from './addhere/ipost-view-state';
import { IPostCommentState } from './addhere/ipost-comment-state';
import { ILoginState } from './ilogin-state';

export interface IAppAddhereState extends IAppState {
    title: string;
    isLoading: boolean;
    enViewMode: EnViewMode;
    search: string;
    school: ISchoolState;
    person: IPersonState;
    snapiv: ISnapIvState;
    evolution: IEvolutionState;
    personResponsible: IPersonResponsibleState;
    personProfessional: IPersonProfessionalState;
    personEducator: IPersonEducatorState;
    schoolUser: ISchoolUserState;
    splashes: ISplash[];
    appAddhere: IAppAddhereState;
    post: IPostState;
    view: IViewState;
    comment: ICommentState;
    postComment: IPostCommentState;
    postView: IPostViewState;
    login: ILoginState;
    bag?: any; // General purpoise object.
}
