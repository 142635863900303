import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { initialStateWebsocket } from './websocket.reducer';


const KEY = 'MEDLOGIC_WEBSOCKET_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
].map(m => m.type.toString());

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialStateWebsocket);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
