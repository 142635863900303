import { Injectable } from '@angular/core';
import { PrismaClient } from '@prisma/client';

/* @classdesc Classe que centralizará os registros de Log.
 * Foi criada como um serviço injetável para permitir uma possível
 * utilização de herança, uma vez que com métodos estáticos isso não seria possível.
*/
@Injectable({
  providedIn: 'root',
})

export class ApiLogService {
  // public webService: WebService; //NÃO PODE USAR WEBSERVICE, UMA VEZ QUE WEBSERVICE USA ApiLogService


  constructor() {}

  private prisma = new PrismaClient();

  protected static MAX_REPETITION_SAME_LOG = 3;
  protected static MAX_TOLERANCE_TO_REPETITION_SEG = 30;
  protected static arrayLog: Array<any> = [];
  protected static lastLog: ApiLogService = null;
  protected static repetitionCount = 0;
  public static UsuarioLogadoNo = -1;
  public static ShowLogMessage = false;
  public static ShowConsoleLog = true;
  public static PersistLog = true;
  public static WsdlUrl = '';
  public static InterfaceMobileInstance: any = null;
  protected data: Date = null;
  protected classe = '';
  protected metodo = '';
  protected usuarioNo = -1;
  protected descricao = '';

  Registrar(classe: string, metodo: string, descricao: string): void {
    const msg: string = 'Classe: ' + classe + ' Método: ' + metodo + ' Descrição: ' + descricao;
    try {
      this.classe = classe;
      this.data = new Date();
      this.descricao = descricao;
      this.metodo = metodo;
      this.usuarioNo = ApiLogService.UsuarioLogadoNo; // this.config.UsuarioLogadoNo;
      // _arrayLog.push(this); Pode provocar grande consumo de memória
      ApiLogService.lastLog = this;
      if (ApiLogService.ShowLogMessage) {
        // this.config.SHOWthis_MESSAGE
        this.showMessage(msg);
      }
      if (ApiLogService.ShowConsoleLog) {
        console.log('ApiLogService.showMessage:', msg);
      }
      if (ApiLogService.PersistLog) {
        // this.config.PERSIST_LOG
        this.Salvar();
      }
    } catch (error) {
      console.log(msg + ' Log: ' + error.message);
      // showMessage(_msg + " Log: " + error.message);
    }
  }

  protected showMessage(msg: string): void {
    alert(msg); // ApiLogService.InterfaceMobileInstance
  }

  /*
	 * Consumirá um serviço para carregamento dos dados
	 * @param _log
	 */
  public Salvar(): void {
    try {
      if (!this.checkRepetition()) {
        // Necessário achar uma solução sem chamar webservice, pois, o mesmo depende de logserviceÍ
        // this.done_SetLog(this.webService.connect("setLog", [
        // 	{ name: "classeNome", value: this._classe },
        // 	{ name: "yyyyMMddThhmmss", value: this.global.RetornarAllXsdDateTime(this._data) },
        // 	{ name: "descricao", value: this.global.PrepareToXML(this._descricao) },
        // 	{ name: "metodoNome", value: this._metodo },
        // 	{ name: "interfaceNo", value: this._usuarioNo }
        // ]));//1 é o Id da InterfaceWeb

        const data = {
          class: this.classe,
          message: this.descricao,
          method: this.metodo,
          usuarioNo: this.usuarioNo,
        };

        this.prisma.log.create({ data });

      }
    } catch (error) {
      this.showMessage(' Log: ' + error.message);
    }
  }

  /*
	*Check if the Log is the same as the previous, within the same tolerance interval.
	* @param _log
	* @return
	*/
  public checkRepetition(): boolean {
    try {
      if (
        this.data <= this.DateAdd('s', ApiLogService.MAX_TOLERANCE_TO_REPETITION_SEG, ApiLogService.lastLog.data)
      ) {
        if (this.isTheSame(ApiLogService.lastLog, this)) {
          ApiLogService.repetitionCount++;
          if (ApiLogService.repetitionCount > ApiLogService.MAX_REPETITION_SAME_LOG) {
            return true;
          }
        }
      } else {
        ApiLogService.repetitionCount = 0;
      }
    } catch (error) {
      this.showMessage('checkRepetition: ' + error.message);
    }
    return false;
  }

  protected isTheSame(lastLog: ApiLogService, log: ApiLogService): boolean {
    return (
      lastLog.descricao === log.descricao &&
      lastLog.metodo === log.metodo &&
      lastLog.classe === log.classe &&
      lastLog.usuarioNo === log.usuarioNo
    );
  }

  public done_SetLog(): void { }

  /*
* Adiciona um tempo a uma data
* @param datepart y adicionará anos, m meses, d dias
* @param number
* @param date
* @return
*/
  DateAdd(datepart: string = '', nmb: number = 0, date: Date = null): Date | null {
    try {
      if (date == null) {
        date = new Date();
      }
      const returnDate = new Date(date.valueOf());
      switch (datepart.toLowerCase()) {
        case 'y':
          returnDate.setFullYear(+returnDate.getFullYear() + +nmb);
          // returnDate["fullYear"] += number;
          break;
        case 'm':
          returnDate.setMonth(+returnDate.getMonth() + +nmb);
          // returnDate["month"] += number;
          break;
        case 'w':
          returnDate.setDate(+returnDate.getDate() + +nmb * 6);
          // returnDate["date"] += number * 6;
          break;
        case 'd':
          returnDate.setDate(+returnDate.getDate() + +nmb);
          break;
        case 's':
          returnDate.setSeconds(+returnDate.getSeconds() + +nmb)
        default:
          /*Unknown date part, do nothing. */
          break;
      }
      return returnDate;
    } catch (error) {
      console.log(this.constructor.name, 'DateAdd', error.message);
    }
  }


}
