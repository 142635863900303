import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';
import {
  GlobalService, LogService, IListItem,
  IAtividadeComponenteDAL,
  LocalLibService,
  IGeneric,
  ConfigJsonService
} from '@medlogic/shared/shared-interfaces';
import { CadastroService } from './cadastro.service';


@Injectable()
export class GenericCustomService extends GenericService {

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnf: ConfigJsonService,
    lib: LocalLibService,
    log: LogService) {
    super(cadastroSrv, glb, cnf, lib, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  map(ono: number, ano: number, componentes: IAtividadeComponenteDAL[]): Observable<IListItem<IGeneric>> {
    try {
      let cs = { ono, index: ono, OcorrenciaNo: ono };
      if (componentes?.length > 0) {
        cs = {
          ...cs, ...componentes.reduce((obj, ctrl) => {
            obj[`V_${ctrl.VariavelNo}`] = ctrl.Valor;
            return obj;
          }, {})
        };
      }
      return of(cs).pipe(this.mapTo(ano));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'map', error.message);
    }
    return of(null);
  }

  /* Retorna os dados pelo ID da instituição/empresa cadastrada.
  */
  getById(ano: number, Id: number, idvno: number = 2230, isDebug: boolean = false): Observable<IListItem<IGeneric>> {
    try {
      if (isDebug) {
        return this.getAllMockup();
      }
      this.cadastroNo = ano;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const strFilter = `V_${idvno}:${Id}`;
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getById', error.message);
    }
    return of(null);
  }

  getByPerson(ano: number, genericLabel: string, personId: number, prontuarioVno: number = 28051): Observable<IListItem<IGeneric>> {
    try {
      this.cadastroNo = ano;
      this.label = genericLabel;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const strFilter = `V_${prontuarioVno}:${personId}`;
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByPerson', error.message);
    }
    return of(null);
  }

  getAllWithLabel(ano: number, genericLabel: string): Observable<IListItem<IGeneric>> {
    try {
      this.cadastroNo = ano;
      this.label = genericLabel;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAllWithLabel', error.message);
    }
    return of(null);
  }


}
