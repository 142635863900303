import { LibService } from '@medlogic/shared/geform';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { IEmployee } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { GlobalService, LogService, IForm, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';


export abstract class EmployeeService {

  // tslint:disable: max-line-length
  private lstVariaveis = 'V_101668,V_101669,V_101670,V_101671,V_101672,V_101673,V_101674,V_101675,V_101678,V_104987,V_105036,V_105037,V_105038,V_105039,V_105041,V_105042,V_105043,V_105044,V_105045,V_105046,V_105047,V_105048,V_105049,V_105050,V_105051,V_105052,V_105053,V_105054,V_105055,V_105057,V_105058,V_105059,V_105060,V_105061,V_105062,V_105063,V_105064,V_105065,V_105066,V_105067,V_105068,V_105069,V_105070,V_105071,V_105072,V_105073,V_105074,V_105075,V_105076,V_105077,V_105078,V_105079,V_105080,V_105081,V_105082,V_105084,V_105428,V_106003';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: Array<IEmployee> = new Array<IEmployee>();

  codigoVariavelNo = 101668; // TODO: [Substituir pelo numero da variavel do codigo principal do cadastro, pode estar errado]
  cadastroNo = 16250;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnfJson: ConfigJsonService,
    protected log: LogService,
    protected lib: LibService,
  ) { }

  getAll(cadastroNo: number, startDate: Date = null, endDate: Date = null): Observable<IEmployee> {
    try {
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      this.cadastroNo = cadastroNo;
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IEmployee> {
    if (
      !this.glb.isEqualIgnoreTime(startDate, this.currentDtInicial) ||
      !this.glb.isEqualIgnoreTime(endDate, this.currentDtFinal) ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastro(cadastroNo, this.lstVariaveis).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Mapeia para o objeto principal. */
  protected toAttribute(c: any): IEmployee {
    try {
      const employee = {
        ocorrenciaNo: c.OcorrenciaNo,
        codigo: c.V_101668,
        titulo: c.V_101669,
        habilitado: this.lib.getBoolean(c.V_101670),
        email: c.V_101671,
        dataNascimento: this.glb.ddMMYYYYThhmmssToDate(c.V_101672),
        cPF: c.V_101673,
        nome: c.V_101674,
        contatosFones: c.V_101675,
        regiao: c.V_101678,
        dtNasc: c.V_104987,
        endereco: c.V_105036,
        cep: c.V_105037,
        bairro: c.V_105038,
        cidade: c.V_105039,
        estadoCivil: c.V_105041,
        pai: c.V_105042,
        mae: c.V_105043,
        naturalidade: c.V_105044,
        nFilhosMenores14Anos: c.V_105045,
        nDependentes: c.V_105046,
        grauInstrucao: c.V_105047,
        valeTransporte: this.lib.getBoolean(c.V_105048),
        tarifaR: c.V_105049,
        carteiraIdentidade: c.V_105050,
        cargo: c.V_105051,
        numeroRegistro: c.V_105052,
        cTPS: c.V_105053,
        orgaoEmissor: c.V_105054,
        dataEmissao: this.glb.ddMMYYYYThhmmssToDate(c.V_105055),
        raca: c.V_105057,
        pisPasep: c.V_105058,
        serie: c.V_105059,
        emissao: c.V_105060,
        certMilitar: c.V_105061,
        tituloEleitor: c.V_105062,
        secao: c.V_105063,
        zona: c.V_105064,
        carteiraHabilitacao: c.V_105065,
        categoria: c.V_105066,
        salarioR: c.V_105067,
        dataAdmissao: this.glb.ddMMYYYYThhmmssToDate(c.V_105068),
        eFumante: this.lib.getBoolean(c.V_105069),
        fazUsobebidaAlcoolica: this.lib.getBoolean(c.V_105070),
        religiao: c.V_105071,
        eHipertenso: this.lib.getBoolean(c.V_105072),
        eDiabetico: this.lib.getBoolean(c.V_105073),
        temPlanosaude: this.lib.getBoolean(c.V_105074),
        fazTratamentoalgumaDoenca: this.lib.getBoolean(c.V_105075),
        seSimQuaisDoencas: c.V_105076,
        temCursoCuidador: this.lib.getBoolean(c.V_105077),
        anoConclusao: c.V_105078,
        temCursoTecnicoOuAuxiliarEnfermagem: this.lib.getBoolean(c.V_105079),
        seSimQualOnde: c.V_105080,
        temCOREN: this.lib.getBoolean(c.V_105081),
        numeroCOREN: c.V_105082,
        referenciasCitarLocaisOndeTrabalhouTelefonesporQuantoTempo: c.V_105084,
        loginFuncionario: c.V_105428,
        enviarEmailoFamiliar: this.lib.getBoolean(c.V_106003),
      } as IEmployee;
      return employee;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  protected getFromCadastroFiltro(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true).pipe(
        map(c => this.toAttribute(c)),
        publishReplay(),
        refCount()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${employeeId}` */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IEmployee> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return null;
  }

  protected getWithCacheFilter(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<IEmployee> {
    if (
      startDate.getTime() !== this.currentDtInicial.getTime() ||
      endDate.getTime() !== this.currentDtFinal.getTime() ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastroFiltro(cadastroNo, filtro, startDate, endDate);
    } else {
      // console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  /* Insere ou atualiza o item.
   * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
   */
  save(employee: IEmployee, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(employee).filter((f) => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
  }

  protected mapToForm(employee: IEmployee): IForm[] {
    try {
      return [
        { VariavelNo: 101668, ValorDado: employee.codigo || '' },
        { VariavelNo: 101669, ValorDado: employee.titulo || '' },
        { VariavelNo: 101670, ValorDado: employee.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 101671, ValorDado: employee.email || '' },
        { VariavelNo: 101672, ValorDado: this.glb.ddMMYYYYThhmmssToDate(employee.dataNascimento) },
        { VariavelNo: 101673, ValorDado: employee.cPF || '' },
        { VariavelNo: 101674, ValorDado: employee.nome || '' },
        { VariavelNo: 101675, ValorDado: employee.contatosFones || '' },
        { VariavelNo: 101678, ValorDado: employee.regiao || '' },
        { VariavelNo: 104987, ValorDado: employee.dtNasc || '' },
        { VariavelNo: 105036, ValorDado: employee.endereco || '' },
        { VariavelNo: 105037, ValorDado: employee.cep || '' },
        { VariavelNo: 105038, ValorDado: employee.bairro || '' },
        { VariavelNo: 105039, ValorDado: employee.cidade || '' },
        { VariavelNo: 105041, ValorDado: employee.estadoCivil || '' },
        { VariavelNo: 105042, ValorDado: employee.pai || '' },
        { VariavelNo: 105043, ValorDado: employee.mae || '' },
        { VariavelNo: 105044, ValorDado: employee.naturalidade || '' },
        { VariavelNo: 105045, ValorDado: employee.nFilhosMenores14Anos || '' },
        { VariavelNo: 105046, ValorDado: employee.nDependentes || '' },
        { VariavelNo: 105047, ValorDado: employee.grauInstrucao || '' },
        { VariavelNo: 105048, ValorDado: employee.valeTransporte ? 'SIM' : 'NÃO' },
        { VariavelNo: 105049, ValorDado: employee.tarifaR || '' },
        { VariavelNo: 105050, ValorDado: employee.carteiraIdentidade || '' },
        { VariavelNo: 105051, ValorDado: employee.cargo || '' },
        { VariavelNo: 105052, ValorDado: employee.numeroRegistro || '' },
        { VariavelNo: 105053, ValorDado: employee.cTPS || '' },
        { VariavelNo: 105054, ValorDado: employee.orgaoEmissor || '' },
        { VariavelNo: 105055, ValorDado: this.glb.ddMMYYYYThhmmssToDate(employee.dataEmissao) },
        { VariavelNo: 105057, ValorDado: employee.raca || '' },
        { VariavelNo: 105058, ValorDado: employee.pisPasep || '' },
        { VariavelNo: 105059, ValorDado: employee.serie || '' },
        { VariavelNo: 105060, ValorDado: employee.emissao || '' },
        { VariavelNo: 105061, ValorDado: employee.certMilitar || '' },
        { VariavelNo: 105062, ValorDado: employee.tituloEleitor || '' },
        { VariavelNo: 105063, ValorDado: employee.secao || '' },
        { VariavelNo: 105064, ValorDado: employee.zona || '' },
        { VariavelNo: 105065, ValorDado: employee.carteiraHabilitacao || '' },
        { VariavelNo: 105066, ValorDado: employee.categoria || '' },
        { VariavelNo: 105067, ValorDado: employee.salarioR || '' },
        { VariavelNo: 105068, ValorDado: this.glb.ddMMYYYYThhmmssToDate(employee.dataAdmissao) },
        { VariavelNo: 105069, ValorDado: employee.eFumante ? 'SIM' : 'NÃO' },
        { VariavelNo: 105070, ValorDado: employee.fazUsobebidaAlcoolica ? 'SIM' : 'NÃO' },
        { VariavelNo: 105071, ValorDado: employee.religiao || '' },
        { VariavelNo: 105072, ValorDado: employee.eHipertenso ? 'SIM' : 'NÃO' },
        { VariavelNo: 105073, ValorDado: employee.eDiabetico ? 'SIM' : 'NÃO' },
        { VariavelNo: 105074, ValorDado: employee.temPlanosaude ? 'SIM' : 'NÃO' },
        { VariavelNo: 105075, ValorDado: employee.fazTratamentoalgumaDoenca ? 'SIM' : 'NÃO' },
        { VariavelNo: 105076, ValorDado: employee.seSimQuaisDoencas || '' },
        { VariavelNo: 105077, ValorDado: employee.temCursoCuidador ? 'SIM' : 'NÃO' },
        { VariavelNo: 105078, ValorDado: employee.anoConclusao || '' },
        { VariavelNo: 105079, ValorDado: employee.temCursoTecnicoOuAuxiliarEnfermagem ? 'SIM' : 'NÃO' },
        { VariavelNo: 105080, ValorDado: employee.seSimQualOnde || '' },
        { VariavelNo: 105081, ValorDado: employee.temCOREN ? 'SIM' : 'NÃO' },
        { VariavelNo: 105082, ValorDado: employee.numeroCOREN || '' },
        { VariavelNo: 105084, ValorDado: employee.referenciasCitarLocaisOndeTrabalhouTelefonesporQuantoTempo || '' },
        { VariavelNo: 105428, ValorDado: employee.loginFuncionario || '' },
        { VariavelNo: 106003, ValorDado: employee.enviarEmailoFamiliar ? 'SIM' : 'NÃO' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }


}
