import { ActionReducer, MetaReducer } from '@ngrx/store';
import { merge } from 'lodash-es';
import { LocalStorageService } from '../../service/local-storage.service';

const storageService = new LocalStorageService();

// console.log all actions
export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  let onInit = true; // after load/refresh…
  return (state, action) => {
    // console.log('state', state);
    // console.log('action', action);
    const nextState = reducer(state, action);
    // init the application state.
    if (onInit) {
      onInit = false;
      const savedState = storageService.getSavedState();
      const merged = merge(nextState, savedState);
      return merged;
    }
    const saveKeys = [state, action];
    // save the next state to the application storage.
    // const stateToSave = pick(nextState, saveKeys);
    storageService.setSavedState(nextState);
    return nextState;
  };
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
