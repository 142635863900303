import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ml-ui-care-plan-timer',
  templateUrl: './ui-care-plan-timer.component.html',
  styleUrls: ['./ui-care-plan-timer.component.css']
})
export class UiCarePlanTimerComponent implements OnInit {

  timer$ = new Observable<string>(observer => {
    setInterval(() => observer.next(new Date().toString()), 1000);
  });

  constructor() { }

  ngOnInit() {
  }

}
