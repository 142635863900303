export interface IMedlogicProfessional {
  ocorrenciaNo: number;
  sexo: string;
  getUsuarioToken: any;
  codigo: any;
  titulo: any;
  habilitado: boolean;
  dataAvaliacao: Date;
  avaliador: any;
  observacoesPositivasMelhorias: string;
  perfilProfissional: string;
  experienciaProfissional: string;
  informacoesImportantes: string;
  indicacaoQuemIndicouempresa: string;
  tipoRegistro: string;
  regiaoEpecialidade: any;
  retorno: string;
  nomeCPF: any;
  cumprimentotarefas: string;
  uniforme: string;
  pontualidade: string;
  tratamentoComIdoso: string;
  comportamentolideranca: string;
  conhecimentoTeorico: string;
  temProatividade: string;
  conhecimentoPratico: string;
  numeroRegistro: any;
  login: any;
  telefone: any;
  regiao: string;
  especialidade: string;
  nome: string;
  dataNascimento: any;
  eMail: any;
}
