<div [formGroup]="formGroup" class="form-group" [class.has-error]="!isValid(formGroup, ctrl)" [libCtrConfigUI]="ctrl" [altura]="ctrl?.Altura" [isMobile]="isMobile">
  
  <lib-title-label [formGroup]="formGroup" [isMobile]="isMobile"  [enTheme]="enTheme" [ctrl]="ctrl" #lblTitle></lib-title-label>
  
  <p-editor [id]="getId(ctrl)" class="form-group" [formControlName]="getId(ctrl)" [style]="{'height':'320px'}" [libCtrConfigUI]="ctrl"
    [tabIndex]="getTabIndex(ctrl)">
    <!--<p-header>
          <span class="ql-formats">
            <button class="ql-bold" aria-label="Negrito"></button>
            <button class="ql-italic" aria-label="Itálico"></button>
            <button class="ql-underline" aria-label="Grifado"></button>
            <button class="ql-strike" aria-label="Riscado"></button>
            <button class="ql-size" aria-label="Tamanho"></button>
            <button class="ql-color" aria-label="Cor"></button>
            <button class="ql-align" aria-label="Alinhamento"></button>
            <button class="ql-list" aria-label="Alinhamento"></button>
        </span>
    </p-header>-->
  </p-editor>

</div>
