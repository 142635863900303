import { IDepartamento } from './idepartamento';

export interface IPapel {
  PapelNo: number;
  PapelNome: string;
  DepartamentoNo: number;
  Departamento: IDepartamento;
  blAdm: boolean;
  TotalProcAssoc: number;
  TotalUsuarioAssoc: number;
}
