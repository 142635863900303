import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { IPeriodDialog } from '../../interface/iperiod-dialog';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-period-dialog',
  templateUrl: './period-dialog.component.html',
  styleUrls: ['./period-dialog.component.css']
})
export class PeriodDialogComponent {
  lng: any;
  disabledButton = false;
  fg: FormGroup;
  window: any = window;

  constructor(
    protected log: LogService,
    protected fb: FormBuilder,
    public dialogRef: MatDialogRef<PeriodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPeriodDialog
  ) {
    try {
      if (data.begin && data.end) {
        this.fg = fb.group({
          date: [{ begin: data.begin, end: data.end }]
        });
      } else {
        const today = new Date();
        this.fg = fb.group({
          date: [{ begin: new Date(today.getFullYear(), today.getMonth(), 1), end: today }]
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Click no botão de confirmação. */
  onYesClick(value: any): void {
    try {
      this.dialogRef.close(this.fg.get('date').value);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onYesClick', error.message);
    }
  }
  /* Click no botão de fechar. */
  onCloseClick(): void {
    try {
      this.dialogRef.close(null);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onYesClick', error.message);
    }
  }
}
