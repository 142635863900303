import { IActivity } from '@medlogic/shared/shared-interfaces';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ml-ui-care-plan-activities-extra',
  templateUrl: './ui-care-plan-activities-extra.component.html',
  styleUrls: ['./ui-care-plan-activities-extra.component.css']
})
export class UiCarePlanActivitiesExtraComponent implements OnInit {

  @Input() activityModeTitle: string;
  @Input() activitiesByShift: { shiftName: string, activities: IActivity[] }[] = [];

  constructor() { }

  ngOnInit() {
  }

}
