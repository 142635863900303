import { ActionReducer } from '@ngrx/store';
import { LocalStorageService } from './local-storage.service';

const storageService = new LocalStorageService();

export function utilLocalStorageReducer(reducer: ActionReducer<any>, PERSISTED_ACTIONS: string[], KEY: string, initialState: any): ActionReducer<any> {
    let onInit = true; // after load/refresh…
    return (state, action) => {
        let nextState = reducer(state, action);
        try {
            if (action.type === '[Login effects] LOGOUT_SUCCESS') { //
                nextState = utilCleanState(state, KEY, initialState);
            } else {
                if (onInit) {
                    onInit = false;
                    return utilLoadState(nextState, KEY);
                }
                if (PERSISTED_ACTIONS.includes(action.type.toString()) && nextState) {
                    utilSaveState(nextState, KEY);
                }
            }
        } catch (error) {
            console.log('storage.metareducer', 'utilLocalStorageReducer', error.message);
        }
        return nextState;
    };
}

function utilLoadState(nextState: any, KEY: string): any {
    try {
        const savedState = storageService.getSavedState(KEY);
        const merged = savedState ? { ...nextState, ...savedState } : nextState;
        return merged;
    } catch (error) {
        this.log.Registrar(this.constructor.name, 'utilLoadState', error.message);
    }
    return null;
}

function utilCleanState(state: any, KEY: string, initialState: any): any {
    try {
        // state = undefined;
        state = initialState;
        storageService.clean(KEY);
        return state;
    } catch (error) {
        this.log.Registrar(this.constructor.name, 'utilCleanState', error.message);
    }
    return null;
}

function utilSaveState(nextState: any, KEY: string): void {
    try {
        storageService.setSavedState(nextState, KEY);
    } catch (error) {
        this.log.Registrar(this.constructor.name, 'utilSaveState', error.message);
    }
}