import { IRelationship } from './irelationship';

export interface IEducator extends IRelationship {
  // ocorrenciaNo: number;
  // idEscola: any;
  // codigo: any;
  // titulo: any;
  // habilitado: boolean;
  // nome: any;
  // email: string;
  // // calculados
  // status?: string;
}

