import { routePostCheckMedicationStock } from '@medlogic/shared/shared-interfaces';
import { IInterventionMedication } from '@medlogic/shared/shared-interfaces';
import { IPrescriptionMedication } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { LocalLibService, LogService, GlobalService, routeGetWebsocketRoomMessage, EnRequestType, EnContentType } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { PrescriptionMedicationService } from './prescription-medication.service';
import { map, of } from 'rxjs';
import { IPerson } from '@medlogic/medlogic/medlogic-shared-interfaces';

@Injectable()
export class PrescriptionMedicationCustomService extends PrescriptionMedicationService {

    private readonly urlRoutePostCheckMedicationStock = `${routePostCheckMedicationStock}`;
    private readonly urlRouteGetWebsocketRoomMessage = `${routeGetWebsocketRoomMessage}?roomId={0}&message={1}`;
    constructor(
        private basepage: BasePageService,
        cadastroSrv: CadastroService,
        glb: GlobalService,
        cnf: ConfigStateService,
        lib: LocalLibService,
        log: LogService) {
        super(cadastroSrv, glb, cnf, lib, log);
        try {
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'constructor', error.message);
        }
    }

    saveServerSide(
        medications: IInterventionMedication[],
        cadEstoqueMateriaisNo: number,
        selectedPrescription: IPrescriptionMedication,
        cadIntervencoesMedicamentosNo: number,
        cadItensEstoqueNo: number,
        cadPrescriptionNo: number,
        person: IPerson,
        wasChanged: boolean,
        message: String = null): Observable<IPrescriptionMedication> {
        try {
            const urlPost = this.basepage.format(this.urlRoutePostCheckMedicationStock);
            return this.basepage
                .baseDadosReplay(EnRequestType.Post, urlPost,
                    {
                        medications,
                        cadEstoqueMateriaisNo,
                        selectedPrescription,
                        cadIntervencoesMedicamentosNo,
                        cadItensEstoqueNo,
                        cadPrescriptionNo,
                        person,
                        wasChanged,
                        message
                    },
                    2, EnContentType.Json)
                .pipe(
                    map(() => selectedPrescription)
                );
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'saveServerSide', error.message);
        }
        return of(null);
    }

    // testSocketServerSide(prescription: IPrescriptionMedication, message: String = ''): Observable<IPrescriptionMedication> {
    //     try {
    //         const urlGet = this.basepage.format(this.urlRouteGetWebsocketRoomMessage, prescription.ocorrenciaNo, message);
    //         return this.basepage
    //             .baseDadosReplay(EnRequestType.Get, urlGet, {}, 2, EnContentType.Json)
    //             .pipe(
    //                 map(() => prescription)
    //             );
    //     } catch (error) {
    //         this.log.Registrar(this.constructor.name, 'testSocketServerSide', error.message);
    //     }
    //     return of(null);
    // }


}
