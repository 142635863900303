import { LogService } from '@medlogic/shared/shared-interfaces';
import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-btn-back',
  templateUrl: './btn-back.component.html',
  styleUrls: ['./btn-back.component.css'],
})

export class BtnBackComponent {
  @Output() backButtonClicked = new EventEmitter<MouseEvent>();

  constructor(
    protected log: LogService
  ) { }

  onBack(event: MouseEvent): void {
    try {
      this.backButtonClicked.emit(event);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBackClick', error.message);
    }
  }
}
