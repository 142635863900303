import { LibService } from '@medlogic/shared/geform';
import { Injectable } from '@angular/core';
import { EmployeeService } from './employee.service';
import {
  ConfigJsonService,
  GlobalService,
  IEmployee, LogService,
} from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';

@Injectable({
  providedIn: 'root'
})
export class EmployeeCustomService extends EmployeeService {


  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnfJson: ConfigJsonService,
    log: LogService,
    lib: LibService,
    protected basepage: BasePageService
  ) {
    super(cadastroSrv, glb, cnfJson, log, lib);
  }

  getByEnviarEmailFamiliar(ano: number): Observable<IEmployee> {
    try {
      this.cadastroNo = ano;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const filter = `V_106003:SIM`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByEnviarEmailFamiliar', error.message);
    }
    return of(null);
  }


}
