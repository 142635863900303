import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppMedlogicPwaCuidadoState } from '../states/app-state';
import { Observable } from 'rxjs';
import {
  AccountActionTypes, fetchErrorAccount,
  fetchSuccessAccount, fetchSuccessAccountByCode,
} from '../actions/account.actions';
import { IAccount } from '../../interface/iaccount';
import { AccountCustomService } from '../../service/account.custom.service';


@Injectable()
export class AccountEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppMedlogicPwaCuidadoState>,
    private accountService: AccountCustomService // TODO: ContaService changed to the API
  ) { }

  // TODO: There is an specific id, for each Model, according with the logged user.
  ano$ = this.store.select(state => this.getId(state));
  balance$ = this.store.select(state => this.getId(state, 'balanceId'));

  loadAccountList$ = createEffect(() => this.actions$
    .pipe(
      ofType(AccountActionTypes.LoadAccount),
      mergeMap(() => this.ano$
        .pipe( // First get the contaId from the logged user. This store is populated at app.component.
          this.getAndTransform$(),
          catchError((e) => {
            console.log(e);
            return of(fetchErrorAccount());
          })
        )
      ),
    )
  );

  getAccountByCode$ = createEffect(() => this.actions$
    .pipe(
      ofType(AccountActionTypes.GetByCode),
      mergeMap((action: any) => {
        return this.ano$
          .pipe(
            this.getByCodeAndTransform$(action.validationCode),
            catchError((e) => {
              console.log(e);
              return of(fetchErrorAccount());
            })
          );
      })
    )
  );

  private getAndTransform$ = () => mergeMap((accountId: number) => {
    return this.accountService.getAll(accountId)
      .pipe(
        toArray(),
        map((allAccounts: IAccount[]) => fetchSuccessAccount({ allAccounts })),
      );
  })

  private getByCodeAndTransform$ = (validationCode: string) => mergeMap((accountId: number) => {
    return this.accountService.getByCode(accountId, validationCode)
      .pipe(
        toArray(),
        map((allAccounts: IAccount[]) => fetchSuccessAccountByCode({ selectedAccount: allAccounts[0] })),
      );
  })


  private getId(state: AppMedlogicPwaCuidadoState, propName: string = 'accountId'): number {
    // TODO: Attention: the property must be changed accordinly the desired Id (in this case contaId).
    return (state && state.tenant && state.tenant.selectedTenant && state.tenant.selectedTenant[propName]) ?
      state.tenant.selectedTenant[propName] :
      -1;
  }

  private error = () => catchError((e): Observable<Action> => {
    console.log(e);
    return of(fetchErrorAccount());
  })

  // Account Balance
  // loadAccountBalance$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(AccountActionTypes.LoadAccountBalance),
  //     mergeMap((action: any) => {
  //       return this.balance$
  //         .pipe(
  //           this.getBalanceByAccount$(),
  //           catchError((e) => {
  //             console.log(e);
  //             return of(fetchErrorAccount());
  //           })
  //         );
  //     })
  //   )
  // );

  // private getBalanceByAccount$ = () => mergeMap((accountId: number) => {
  //   return this.accountService.getByCode(accountId)
  //     .pipe(
  //       map((balance: IBalance) => fetchSuccessAccountBalance({ balance })),
  //     );
  // })

}
