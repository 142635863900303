export interface IVitalSign {
    codigoPaciente: any;
    dataavaliacao: Date;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    laudo: string;
    oCORRENCIA: any;
    codPacCAT: any;
    codPacOcorrenciaCAT: any;
    executorAvaliacao: any;
    executorMedicaoData: any;
    codPacPressao: any;
    cODHOSPDATA: any;
    tecnicoResponsavel: any;
    frequenciaCardiacaBpm: any;
    frequenciaRespiratoriaICP: any;
    glicemiaCapilarEmJejumMlDl: any;
    posPrandialMlDl: any;
    glicose: any;
    pADiastolicaRef80: any;
    pASistolicaRef120: any;
    saturacaoOxigenioSO: any;
    temperaturaTax: any;
    isChecked: boolean;
    //
    calcOcurrences?: any;
}
