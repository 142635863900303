export enum EnumCtrType {
    CtrDate = 1,
    CtrLabel = 2,
    CtrTextFieldReadOnly = 3,
    CtrTextBoxLabeled = 4,
    CtrMultilineTextBoxLabeled = 5,
    CtrComboBox = 6,
    CtrImagePlayer = 7,
    CtrImageList = 8,
    CtrRadioButton = 9,
    CtrCheckBoxList = 10,
    CtrButton = 11,
    CtrRatingStar = 12,
    CtrRatingProgress = 13,
    CtrVideo = 14,
    CtrGrid = 15,
    CtrMapa = 16,
    CtrMultiLineTextBoxHtmlLabeled = 17
}
