import { ILog } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const setLog = createAction(
  '[Log/API] Set Log',
  props<{ selectedId: number }>()
);
export const loadLogs = createAction(
  '[Log/API] Load Logs'
);
export const loadLogsSuccess = createAction(
  '[Log/API] Load Logs Success',
  props<{ logs: ILog[] }>()
);

export const logFail = createAction(
  '[Log/API] Log Fail',
  props<{ error: string }>()
);

export const addLog = createAction(
  '[Log/API] Add Log',
  props<{ log: ILog }>()
);

export const upsertLog = createAction(
  '[Log/API] Upsert Log',
  props<{ log: ILog }>()
);

export const addLogs = createAction(
  '[Log/API] Add Logs',
  props<{ logs: ILog[] }>()
);

export const upsertLogs = createAction(
  '[Log/API] Upsert Logs',
  props<{ logs: ILog[] }>()
);

export const updateLog = createAction(
  '[Log/API] Update Log',
  props<{ log: Update<ILog> }>()
);

export const updateLogs = createAction(
  '[Log/API] Update Logs',
  props<{ logs: Update<ILog>[] }>()
);

export const deleteLog = createAction(
  '[Log/API] Delete Log',
  props<{ id: string }>()
);

export const deleteLogs = createAction(
  '[Log/API] Delete Logs',
  props<{ ids: string[] }>()
);

export const clearLogs = createAction(
  '[Log/API] Clear Logs'
);
export const clearSelectedLog = createAction(
  '[Card Log] Clear Selected Logs'
);

export const openAlert = createAction(
  '[Page Prescription] Open Alert',
  props<{ messageHtml: string, title: string }>()
);

export const openAlertSuccess = createAction(
  '[Websocket Effect] Open Alert Success',
  props<{ res: any }>()
);

export const addLogSuccess = createAction(
  '[Log Effect] Add Log Success'
);

export const openAlertFailure = createAction(
  '[Websocket Effect] Open Alert Failure',
  props<{ error: any }>()
);
