<div class="grid" *ngLet="numOfMedications$ | async as numOfMedications">
  <button
    type="button"
    class="btn btn-white btn-block"
    (click)="timelineClick(patient)"
  >
    <mat-icon class="warning" *ngIf="patient?.calcHasEvolutionOccurrence"
      >announcement</mat-icon
    >
    <i class="fa fa-list mr"> </i>
    <span class="bold">Linha do Tempo </span>
  </button>

  <div *ngIf="showMedicationCheckin">
    <ng-container *ngLet="hasMedication$ | async as hasMedication">
      <button
        type="button"
        class="btn btn-white btn-block"
        (click)="medications(patient, numOfMedications, hasMedication)"
      >
        <i class="fa fa-medkit mr"> </i>
        <span class="bold"
          >Registrar Medicação
          <span *ngIf="numOfMedications > 0"
            >({{ numOfMedications }})</span
          ></span
        >
      </button>
    </ng-container>
  </div>

  <button
    *ngIf="showVitalSigns"
    type="button"
    class="btn btn-white btn-block"
    (click)="vitalSigns(patient)"
  >
    <mat-icon class="alert" *ngIf="patient?.calcHasVitalSignOccurrence"
      >report_problem</mat-icon
    >
    <i class="fa fa-heart-o mr"> </i>
    <span class="bold">Registrar Sinais Vitais </span>
  </button>

  <button
    *ngIf="showDailyEvaluation"
    type="button"
    class="btn btn-white btn-block"
    (click)="evolution(patient)"
  >
    <mat-icon class="alert" *ngIf="patient?.calcHasEvolutionOccurrence"
      >report_problem</mat-icon
    >
    <i class="fa fa-check mr"> </i>
    <span class="bold">Anotação Diária</span>
  </button>

  <button
    *ngIf="showPatientReport"
    type="button"
    class="btn btn-white btn-block"
    (click)="emergencyData(patient)"
  >
    <mat-icon class="warning" *ngIf="patient?.calcHasOccurrence"
      >announcement</mat-icon
    >
    <i class="fa fa-plus-square mr"> </i>
    <span class="bold">Resumo do Paciente </span>
  </button>

  <button
    *ngIf="showCarePlan"
    type="button"
    class="btn btn-white btn-block"
    (click)="activities(patient)"
  >
    <mat-icon class="default">elderly_woman</mat-icon>
    <span class="bold">Atividade de Cuidado</span>
  </button>

  <button
    *ngIf="showCarePlanReport"
    type="button"
    class="btn btn-white btn-block"
    (click)="carePlanReport(patient)"
  >
    <mat-icon class="default">health_and_safety</mat-icon>
    <span class="bold">Plano de Cuidados</span>
  </button>

  <!-- Menus personalizados -->
  <ng-container *ngIf="showCustomModules">
    <ng-container *ngIf="usrProcess$ | async as usrProcess; else carregamento">
      <ng-container *ngIf="usrProcess?.length > 0">
        <button
          *ngFor="let process of usrProcess"
          type="button"
          class="btn btn-white btn-block"
          (click)="onClickProcess(process, patient)"
        >
          <img
            title="icon"
            *ngIf="showImg(process)"
            [src]="process?.UrlIcon"
            class="icn"
            (error)="swapImage($event.target)"
          />
          <span class="bold">{{ process?.Label }}</span>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #carregamento>
    <br />
    <span>Processos do Usuário em Carregamento...</span>
  </ng-template>
</div>
