
import { refCount, publishReplay, map, filter, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IRotina } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { GlobalService, LogService, IForm } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';

export abstract class RotinaService {

  // tslint:disable-next-line: max-line-length
  public lstVariaveis = 'V_108718,V_108719,V_108720,V_108721,V_108722,V_108723,V_108724,V_108725,V_108726,V_108727,V_108728,V_108729,V_108730,V_108731,V_108732,V_108733,V_108734,V_108735,V_108736,V_108737,V_108738,V_108739,V_108746,V_108748,V_108749,V_108751,V_109143,V_109144,V_109159,V_109163,V_109164,V_109279,V_109296,V_109319,V_109333,V_109490,V_109491,V_109492';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: Array<IRotina> = new Array<IRotina>();

  cadastroNo = 24679;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;


  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  // TODO: Atenção: o correto não é retornar todos os dados, de todas as datas e depois filtrar, mas enviar a data como parâmetro.
  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IRotina> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return null;
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date): Observable<IRotina> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, this.lstVariaveis).pipe(
          map(m => this.toAttribute(m)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return null;
  }

  /* Converte o objeto que vem do serviço para IVitalSign. */
  protected toAttribute(c: any): IRotina {
    try {
      return  {
        ocorrenciaNo: c.OcorrenciaNo,
codigo: c.V_108718,
titulo: c.V_108719,
habilitado: this.glb.getBoolean(c.V_108720),
tipoAtividade: c.V_108721,
nomeAtividade: c.V_108722,
codigoAtividade: c.V_108723,
descricaoAtividade: c.V_108724,
observacoes: c.V_108725,
nomeProfissional: c.V_108726,
inicio: this.glb.ddMMYYYYThhmmssToDate(c.V_108727),
dataAtividade: c.V_108728,
horarios: c.V_108729,
publicoAlvo: c.V_108730,
periodo: c.V_108731,
domingo: this.glb.getBoolean(c.V_108732),
segundaFeira: this.glb.getBoolean(c.V_108733),
tercaFeira: this.glb.getBoolean(c.V_108734),
quartaFeira: this.glb.getBoolean(c.V_108735),
quintaFeira: this.glb.getBoolean(c.V_108736),
sextaFeira: this.glb.getBoolean(c.V_108737),
sabado: this.glb.getBoolean(c.V_108738),
local: c.V_108739,
oCorrenciaAtvTablet: c.V_108746,
residente: c.V_108748,
dRT: c.V_108749,
profOcorrencia: c.V_108751,
tipo: c.V_109143,
fim: this.glb.ddMMYYYYThhmmssToDate(c.V_109144),
informeAndar: c.V_109159,
medlogicId: c.V_109163,
oCorrenciaPaciente: c.V_109164,
aCadaXDiasXaQuantidadediasseremPulados: c.V_109279,
alerta: c.V_109296,
prontuarioEinstein: c.V_109319,
medlogicId2: c.V_109333,
jsonBottonLeft2: c.V_109490,
jsonBottonLeft1: c.V_109491,
jsonBottonLeftAndarSelecionado: c.V_109492,

     } as IRotina;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(movimentacao: IRotina, uno: number, id?: number): Observable<any> {
    try {
      const forms: IForm[] = this.mapToForm(movimentacao).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return EMPTY;
  }

  protected mapToForm(rotina: IRotina): Array<IForm> {
    try {
        return  [
          { VariavelNo: 108718, ValorDado: rotina.codigo || '' },
          { VariavelNo: 108719, ValorDado: rotina.titulo || '' },
          { VariavelNo: 108720, ValorDado: rotina.habilitado ? 'SIM' : 'NÃO' },
          { VariavelNo: 108721, ValorDado: rotina.tipoAtividade || '' },
          { VariavelNo: 108722, ValorDado: rotina.nomeAtividade || '' },
          { VariavelNo: 108723, ValorDado: rotina.codigoAtividade || '' },
          { VariavelNo: 108724, ValorDado: rotina.descricaoAtividade || '' },
          { VariavelNo: 108725, ValorDado: rotina.observacoes || '' },
          { VariavelNo: 108726, ValorDado: rotina.nomeProfissional || '' },
          { VariavelNo: 108727, ValorDado: this.glb.ddMMYYYYThhmmssToDate(rotina.inicio) },
          { VariavelNo: 108728, ValorDado: rotina.dataAtividade || '' },
          { VariavelNo: 108729, ValorDado: rotina.horarios || '' },
          { VariavelNo: 108730, ValorDado: rotina.publicoAlvo || '' },
          { VariavelNo: 108731, ValorDado: rotina.periodo || '' },
          { VariavelNo: 108732, ValorDado: rotina.domingo ? 'SIM' : 'NÃO' },
          { VariavelNo: 108733, ValorDado: rotina.segundaFeira ? 'SIM' : 'NÃO' },
          { VariavelNo: 108734, ValorDado: rotina.tercaFeira ? 'SIM' : 'NÃO' },
          { VariavelNo: 108735, ValorDado: rotina.quartaFeira ? 'SIM' : 'NÃO' },
          { VariavelNo: 108736, ValorDado: rotina.quintaFeira ? 'SIM' : 'NÃO' },
          { VariavelNo: 108737, ValorDado: rotina.sextaFeira ? 'SIM' : 'NÃO' },
          { VariavelNo: 108738, ValorDado: rotina.sabado ? 'SIM' : 'NÃO' },
          { VariavelNo: 108739, ValorDado: rotina.local || '' },
          { VariavelNo: 108746, ValorDado: rotina.oCorrenciaAtvTablet || '' },
          { VariavelNo: 108748, ValorDado: rotina.residente || '' },
          { VariavelNo: 108749, ValorDado: rotina.dRT || '' },
          { VariavelNo: 108751, ValorDado: rotina.profOcorrencia || '' },
          { VariavelNo: 109143, ValorDado: rotina.tipo || '' },
          { VariavelNo: 109144, ValorDado: this.glb.ddMMYYYYThhmmssToDate(rotina.fim) },
          { VariavelNo: 109159, ValorDado: rotina.informeAndar || '' },
          { VariavelNo: 109163, ValorDado: rotina.medlogicId || '' },
          { VariavelNo: 109164, ValorDado: rotina.oCorrenciaPaciente || '' },
          { VariavelNo: 109279, ValorDado: rotina.aCadaXDiasXaQuantidadediasseremPulados || '' },
          { VariavelNo: 109296, ValorDado: rotina.alerta || '' },
          { VariavelNo: 109319, ValorDado: rotina.prontuarioEinstein || '' },
          { VariavelNo: 109333, ValorDado: rotina.medlogicId2 || '' },
          { VariavelNo: 109490, ValorDado: rotina.jsonBottonLeft2 || '' },
          { VariavelNo: 109491, ValorDado: rotina.jsonBottonLeft1 || '' },
          { VariavelNo: 109492, ValorDado: rotina.jsonBottonLeftAndarSelecionado || '' },
        ];;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Retorna um título bem formatado para cada campo.  */
  getRotulo(field: string): string {
    try {
      const mapR = {
        codigoPaciente: '#NAOEXIBIR#',
        dataavaliacao: '#NAOEXIBIR#',
        codigo: '#NAOEXIBIR#',
        titulo: '#NAOEXIBIR#',
        habilitado: '#NAOEXIBIR#',
        laudo: 'Laudo',
        oCORRENCIA: '#NAOEXIBIR#',
        codPacCAT: '#NAOEXIBIR#',
        codPacOcorrenciaCAT: '#NAOEXIBIR#',
        executorAvaliacao: '#NAOEXIBIR#',
        executorMedicaoData: '#NAOEXIBIR#',
        codPacPressao: '#NAOEXIBIR#',
        cODHOSPDATA: '#NAOEXIBIR#',
        tecnicoResponsavel: 'Rotina',
        frequenciaCardiacaBpm: 'Frequência Cardíaca (BPM)',
        frequenciaRespiratoriaICP: 'Frequência Respiratória (ICP)',
        glicemiaCapilarEmJejumMlDl: '(Glicemia Capilar em Jejum (ml/dl)',
        posPrandialMlDl: 'Glicemia pós-prandial (ml/dl)',
        glicose: 'Glicose',
        pADiastolicaRef80: 'Pressão Diastólica (ref: 80)',
        pASistolicaRef120: 'Pressão Sistólica (ref: 120)',
        saturacaoOxigenioSO: 'Saturação de Oxigênio',
        temperaturaTax: 'Temperatura'
      };
      if (mapR.hasOwnProperty(field)) {
        return mapR[field];
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getRotulo', error.message);
    }
    return '';
  }

}
