export interface IFrailty {
    ocorrenciaNo: number;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    idade: any;
    dtNascimento: Date;
    algumFamiliarOuAmigoFalouQueVoceEstaFicandoEsquecido: boolean;
    esteEsquecimentoEstaPiorandoNosUltimosMeses: boolean;
    esteEsquecimentoEstaImpedindorealizacaoalgumaAtividadecotidiano: boolean;
    noUltimoMesVoceFicouComDesanimoTristezaOuDesesperanca: boolean;
    noUltimoMesVocePerdeuinteresseOuPrazerEmAtividadesAnteriormentePrazerosas: boolean;
    voceincapazelevarOsBracosAcimanivelombro: boolean;
    voceincapazmanusearOuSegurarPequenosObjetos: boolean;
    voceTemAlgumaquatroCondicoesAbaixoRelacionadas: any;
    voceTemDificuldadecaminharCapazimpedirrealizacaoalgumaAtividadecotidiano: boolean;
    voceTeveDuasOuMaisQuedasNoUltimoAno: boolean;
    vocePerdeUrinaOuFezesSemQuererEmAlgumMomento: boolean;
    voceTemProblemasvisaoCapazesimpedirrealizacaoalgumaAtividadecotidianoEPermitidousooculosOuLentescontato: boolean;
    voceTemProblemasaudicaoCapazesimpedirrealizacaoalgumaAtividadecotidianoEPermitidousoaparelhosaudicao: boolean;
    cincoOuMaisDoencasCronicas: boolean;
    usoRegularcincoOuMaisMedicamentosDiferentesTodoDia: boolean;
    internacaoRecenteNosUltimos6Meses: boolean;
    historiahipertensaoArterial: string;
    historiadiabetesMellitus: string;
    historiadoencaAterotromboticaDoencaCerebrovascularAVEOuAITIAMOuDoencaArterialCoronarianaDACOuDoencaArterialPerifericaDAP: string;
    historiainsuficienciaCardiacaArritmiasGravesFibrilacaoAtrialOuValvulopatiasSintomaticas: string;
    historiaparkinsonismoOuPresencatremoresRepousoAcaoOuIntencional: string;
    diagnosticoquadroDemencial: string;
    diagnosticotranstornoMentalOuPsiquiatrico: string;
    presencadoencaRespiratoriaCronicaDPOCOuAsma: string;
    presencaosteoatriteDegenerativaArtriteReumatoideOuOutrasDoencasReumatologicasLimitantes: string;
    presencaalgumTipocancerExcetoCutaneoNaoMelanoma: string;
    presencadoencaRenalCronicaInsuficienciaRenal: string;
    diagnosticoosteoporoseAtualOuPregresso: string;
    presencaalgumDisturbiosonoComoInsoniaApneiasonoOuHipersonia: string;
    presencaproblemasNaMastigacaoOuFeridasOuLesoesNaMucosaOralOuLingua: string;
    presencaproblemastuboDigestivoComoDRGEDoencaCloridopepticaDiarreiaCronicaConstipacaoIntestinalOuFecaloma: string;
    presencaanemiaOuOutrasDoencassistemaHematopoietica: string;
    presencadorCronica: string;
    aoalimentarPaciente: string;
    paraSairumaCadeiraseLocomoverAtecamaTransferenciaPaciente: string;
    trocarroupaVertir: string;
    usobanheironecessidadesBasicasComoMiccaoevacuacao: string;
    saircasaSozinholugaresDistantes: string;
    preparorefeicoes: string;
    tomarRemediosNaDosehorarioCorretos: string;
    emGeralComparandoComOutrasPessoassuaIdadeVoceDiriaQueSuaSaude: boolean;
    porCausasuaSaudeOuCondicaoFisicaVoceDeixoufazerCompras: boolean;
    porCausasuaSaudeOuCondicaoFisicaVoceDeixoucontrolarSeuDinheiroGastosOuPagarAsContassuaCasa: boolean;
    porCausasuaSaudeOuCondicaoFisicaVoceDeixourealizarPequenosTrabalhosDomesticosComoLavarLoucaArrumarcasaOuFazerLimpezaLeve: boolean;
    porCausasuaSaudeOuCondicaoFisicaVoceDeixoutomarBanhoSozinho: boolean;
    q1CalcIdade: any;
    q1CalcIdade2: any;
    q1CalcIdadeFim: any;
    q2AutoPercepcao: any;
    q3AvdInst1: any;
    q4AvdInst2: any;
    q5AvdInst3: any;
    q6AvdBasica: any;
    q7: any;
    q8: any;
    q9: any;
    q10: any;
    q11: any;
    q12: any;
    q13: any;
    q14: any;
    q18: any;
    q15: any;
    q19: any;
    q16: any;
    q20: any;
    q17: any;
    q21: any;
    q22: any;
    q23: any;
    q24: any;
    q25: any;
    q26: any;
    q262: any;
    q263: any;
    q27: any;
    q272: any;
    q273: any;
    q28: any;
    q282: any;
    q283: any;
    q29: any;
    q292: any;
    q293: any;
    q30: any;
    q302: any;
    q303: any;
    q31: any;
    q32: any;
    q33: any;
    q332: any;
    q333: any;
    q34: any;
    q35: any;
    q36: any;
    q362: any;
    q363: any;
    q37: any;
    q372: any;
    q373: any;
    q38: any;
    q382: any;
    q383: any;
    q39: any;
    q40: any;
    q42: any;
    q41: any;
    presencadoencaTireoideanaHiperOuHipotireoidismo: string;
    audioV104234: any;
    audioV104235V104236: any;
}
