import { MedLogicNavigationService } from './service/medlogic-navigation.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const SERVICES = [
  MedLogicNavigationService
];

@NgModule({
  imports: [CommonModule],
  providers: [SERVICES]
})
export class MedlogicNavigationModule { }
