import { ConfigStateService } from '@medlogic/shared/state-config';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ConfigPwaMedLogicService extends ConfigStateService {

    modeloConfigNo: number;
    OcorrenciaNo = new BehaviorSubject<number>(null);
    selectedPatient: IPatient;
    vitalSignCadastroNo: number;
    entryCadastroNo: number;
    showMenu = false;
    evolutionCadastroNo: number;
    kitchenCategoryName: string;
    imgBasePath = '';


        /* Substitui a url do arquivo, pelo caminho padrão, mantendo apenas o nome do arquivo.
    * O caminho padrão é definido em this.imgBasePath.
    */
   replaceImgPath(urlToBeChanged: string): string {
    try {
        const fileName = this.extractFilenameFromUrl(urlToBeChanged);
        const base = this.imgBasePath.endsWith('/') ? this.imgBasePath : `${this.imgBasePath}/`;
        return `${base}${fileName}`;
    } catch (error) {
        console.log(this.constructor.name, 'replaceImgPath', error.message);
    }
}


    /* Separa uma url que aponta para um arquivo em partes. */
    protected extractFilenameFromUrl(url: string): string {
      try {
          const res = /(?=[\w-]+\.\w{3,4}$).+/gi.exec(url);
          if (!res) {
              return url;
          }
          return res[0];
      } catch (error) {
          console.log(this.constructor.name, 'extractFilenameFromUrl', error.message);
      }
  }

}
