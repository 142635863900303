import { ItemSearchPipe } from '@medlogic/shared/utils';
import {
  GlobalService, LogService,
  IListItem, ISmartList, IPatient, ILogin
} from '@medlogic/shared/shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IMedlogicEvolution } from '@medlogic/shared/shared-interfaces';
import { IAppMedlogicState, IMedlogicEvolutionState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { of } from 'rxjs';

import * as fromEvolution from './evolution.reducer';
import * as fromPatient from '../../state-patient/+state/patient.selectors';

const glb = new GlobalService();
const log = new LogService();

/* Operador evolutionalizado que converte os evolutionos em módulos. */
const toIListItem = ((evolution: IMedlogicEvolution) => {
  const profissionalNo = evolution?.executorAvaliacaoNo ?  ` [${evolution?.executorAvaliacaoNo}]` : '';
  const profissional = evolution?.executorAvaliacaoLogin ? `Profissional: ${evolution?.executorAvaliacaoLogin}${profissionalNo}` : '';
  return ({
    id: evolution?.codigo,
    identification1: evolution?.codigoPaciente,
    identification2: glb.getTypedValue(evolution?.dataAvaliacao).value,
    topLeft: profissional,
    bottomLeft: glb.dateTohhmmss(evolution?.dataAvaliacao),
    topRight: glb.DateToddMMYYYY(evolution?.executorAvaliacao),
    bottomRight: evolution?.calcHasOccurrence ? 'missed' : 'checked',
    color: 'green',
    imgUrl: '',
    obj: evolution
  } as IListItem<IMedlogicEvolution>);
});

export const isEvolutionLoading = createSelector(
  fromEvolution.selectAll,
  state => !!state,
);

export const selectEvolutionState = createFeatureSelector<IMedlogicEvolutionState>(fromEvolution.evolutionsFeatureKey);
export const selectLoginPWAState = createFeatureSelector<{ selectedLogin: ILogin }>('loginPWA');
export const selectSearchState = createFeatureSelector<IAppMedlogicState>('appMedlogic');
export const selectEvolutionAlls = createSelector(selectEvolutionState, fromEvolution.selectAll);

export const selectEvolutionSelectedId = createSelector(selectEvolutionState,
  state => state?.selectedId || -1
);

export const selectEvolutionSelected = createSelector(
  selectEvolutionState,
  selectEvolutionSelectedId,
  (state, evolutionId) => evolutionId > 0 ? state?.entities[evolutionId] : null
);

export const selectEvolutionsByPatient = (patientId: number) => createSelector(
  selectEvolutionAlls,
  (evolutions) => evolutions?.filter(f => f.codigoPaciente && +f.codigoPaciente === +patientId)
);

export const selectEvolutionsByPatientSelected = createSelector(
  selectEvolutionAlls,
  fromPatient.selectedPatientId,
  (evolutions, selectedPatientId) => evolutions?.filter(f => f.codigoPaciente && +f.codigoPaciente === +selectedPatientId)
);

export const selectEvolutionsAsIListItems = createSelector(
  selectEvolutionsByPatientSelected,
  evolutions => evolutions?.map(toIListItem)
);

export const selectEvolutionsAsSmartList = createSelector(
  selectEvolutionsAsIListItems,
  selectSearchState,
  (items, appMedlogic) => {
    items = filterBySearch(items, appMedlogic?.search);
    items = items.sort((a, b) => glb.compareDates(b.identification2, a.identification2));
    if (items?.length > 0) {
      return uniqueDates(items, 'dataAvaliacao')
        .map(dt =>
        ({
          title: `${glb.FormatDateHour(dt, "M")}, ${glb.FormatDateHour(dt, "y")}`,
          items: items
            .filter(f =>
              glb.getTypedValue(f?.obj?.dataAvaliacao).value && dt &&
              glb.getTypedValue(f?.obj?.dataAvaliacao).value.getMonth() === dt.getMonth() &&
              glb.getTypedValue(f?.obj?.dataAvaliacao).value.getFullYear() === dt.getFullYear()
            )
        } as ISmartList)
        );
    }
    return of(null);
  });

const filterBySearch = (items: IListItem<IMedlogicEvolution>[], keyword: string) => {
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

function uniqueDates(itens: any[], dateFieldName: string) {
  const datas: Date[] = [];
  if (itens?.length > 0) {
    itens?.forEach(item => {
      const value = glb.getTypedValue(item?.obj[dateFieldName]).value;
      if (!datas.find(f => f.getMonth() === value.getMonth() &&
        f.getFullYear() === value.getFullYear())) {
        datas.push(value);
      }
    });
    return datas;
  }
}

export const selectEvolutionsDefaultControls = createSelector(
  selectLoginPWAState,
  selectEvolutionSelectedId,
  fromPatient.selectedPatient,
  (loginPWA, selectedId, patient) => getDefaultFormControls(
    selectedId,
    patient,
    loginPWA?.selectedLogin?.usuarioLogadoNo,
    loginPWA?.selectedLogin?.usuarioLogadoNome,
    loginPWA?.selectedLogin?.usuario?.Login || '',
  )
)

const getDefaultFormControls = (ono: number, patient: IPatient, professionalNo: number, professionalName: string, profissionalLogin: string): { [key: string]: any } => {
  try {
    const dfc = {
      V_28626: ono,
      V_29977: professionalName, // esta gravando o login e nao o nome
      V_28841: professionalNo, // esta gravando o no corretamente
      //34340 -  //=executorposicao(1;nome) ja vem preenchido no xml retornado pelo GE com o nome do profissional
      V_387: patient?.nomeHospede,
      V_2230: patient?.codigo,
      V_28051: patient?.prontuario,
      V_1608: patient?.nomemae,
      V_3328: patient?.identificacao1,
      V_3329: patient?.identificacao2,
      V_391: patient?.nascimento,
      V_3304: patient?.foto,
      V_28611: `${patient?.codigo}_${glb.dateToYYYYMMddThhmmss(new Date())}`, // Título
    };
    return dfc;
  } catch (error) {
    log.Registrar('evolution.selector', 'getDefaultFormControls', error.message);
  }


}
