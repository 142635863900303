export interface IDado {
    VariavelNo: number;
    TarefaNo: number;
    UsuarioNo: number;
    DtRegistro: string;
    OcorrenciaNo: number;
    Versao: number;
    ValorTexto: string;
    ValorData: Date | any;
    ValorInteiro: number;
    ValorNumero: number;
    ValorBit: boolean;
    ValorXml: any;
    AtividadeNo: number;
    Calculos: string;
}
