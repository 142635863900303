// https://github.com/text-mask/text-mask/tree/master/addons/#readme
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { GlobalService } from '../service/global.service';
import { LogService } from '../service/log.service';

// @Injectable()
export class InputMaskType {
  static MAX_MASK_SIZE = 25;

  constructor(private global: GlobalService, private log: LogService) { }

  public static None = 0; // Sem máscara
  public static Numeric = 1; // Número com 2 casas decimais
  public static Integer = 2; // Inteiro
  public static Date = 3; // Data dd/mm/yyyy
  public static DateTime = 4; // Data e Hora dd/mm/yyyy hh:mm:ss
  public static TimeHHMMSS = 5; // Hora hh:mm:ss
  public static TimeHHMM = 6; // Hora hh:mm
  public static CPF = 7; // CPF
  public static CNPJ = 8; // CNPJ
  public static CEP = 9; // CEP
  public static Phone = 10; // Telefone 9999-0000
  public static PhoneWithDDD = 11; // Telefone com DDD (31) 9999-0000
  public static PhoneWithDDDInternational = 12; // Telefone com DDD e prefixo internacional +55 (31) 9999-0000
  public static PhoneWithDDDNoPrefix = 13; // Telefone com DDD sem divisão dos digitos e aceita um numero a mais +55 (31) 999990000
  public static PhoneWithDDDNoPrefixInternational = 14;
  // Telefone com DDD e prefixo internacional sem divisão dos digitos e aceita um numero a mais +55 (31) 999990000
  public static Percent = 15; // Percentual, sem casas e adiciona o símbolo %
  public static Real = 16; // Acrescenta o símbolo de Reais no início R$ e trava em duas casas decimais
  public static Dolar = 17; // Acrescenta o símbolo de Dólares no início $ e trava em duas casas decimais
  public static Numeric2 = 18; // numérico mas com limite de 2 casas decimais
  public static Percent2 = 19; // Percentual mas com limite de 2 casas decimais e adiciona o símbolo %
  public static Numeric3 = 20; // numérico mas com limite de 3 casas decimais
  public static Numeric4 = 21; // numérico mas com limite de 4 casas decimais
  public static TimeHHHMM = 22; // Tempo em horas hhh:mm
  public static Email = 23; // TODO: Falta implementar no Studio.
  public static Password = 24; // TODO: Falta implementar no Studio.

  public static repeatSymbol(symbol: RegExp, numOfTimes: number): any[] {
    try {
      numOfTimes = numOfTimes <= InputMaskType.MAX_MASK_SIZE ? numOfTimes : InputMaskType.MAX_MASK_SIZE;
      const repeated = [];
      for (let i = 0; i < numOfTimes; i++) {
        repeated.push(symbol);
      }
      return repeated;
    } catch (error) {
      console.log(this.constructor.name, 'repeatSymbol', error.message);
    }
    return [];
  }

  public static getMask(value: string, maxLength: number = 15): any {
    try {
      const mask = InputMaskType.toEnum(value);
      switch (mask) {
        default:
        case InputMaskType.None:
          return null;
        case InputMaskType.Numeric:
          return this.createNumber(maxLength, 2);
        case InputMaskType.Integer:
          return this.createNumber(maxLength, 0, '', '', '.', ',');
        case InputMaskType.Date:
          return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        case InputMaskType.DateTime:
          return [
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            ':',
            /\d/,
            /\d/
          ];
        case InputMaskType.TimeHHMMSS:
          return [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/];
        case InputMaskType.TimeHHMM:
          return [/\d/, /\d/, ':', /\d/, /\d/];
        case InputMaskType.CPF:
          // return "999.999.999-99";
          return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
        case InputMaskType.CNPJ:
          // return "99.999.999/9999-99";
          return [
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '.',
            /\d/,
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/
          ];
        case InputMaskType.CEP:
          // return "99999-999";
          return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
        case InputMaskType.Phone:
          // return "999?9-9999";
          return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        case InputMaskType.PhoneWithDDD:
          // return "(99)999?9-9999";
          return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        case InputMaskType.PhoneWithDDDInternational:
          // return "+99?9(99)999?9-9999";
          return [
            '+',
            /\d/,
            /\d/,
            /\d/,
            '(',
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ];
        case InputMaskType.PhoneWithDDDNoPrefix:
          // return "(99)999?9-9999";
          return ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        case InputMaskType.PhoneWithDDDNoPrefixInternational:
          // return "+99?9(99)999?9-9999";
          return [
            '+',
            /\d/,
            /\d/,
            /\d/,
            '(',
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ];
        case InputMaskType.Percent:
          // return "999%";
          return this.createNumber(maxLength, 0, '', '%');
        case InputMaskType.Percent2:
          return this.createNumber(maxLength, 2, '', '%');
        case InputMaskType.Real:
          // return "R$9?9";
          return this.createNumber(maxLength, 2, 'R$', '');
        case InputMaskType.Dolar:
          // return "$9?9";
          return this.createNumber(maxLength, 2, '$', '');
        case InputMaskType.Numeric2:
          return this.createNumber(maxLength, 2, '', '');
        case InputMaskType.Numeric3:
          return this.createNumber(maxLength, 3, '', '');
        case InputMaskType.Numeric4:
          return this.createNumber(maxLength, 4, '', '');
        case InputMaskType.TimeHHHMM:
          // return "999:99";
          return [/\d?/, /\d/, /\d/, ':', /\d/, /\d/];
        case InputMaskType.Integer:
          return [/^\d+$/];
        case InputMaskType.Email:
          return emailMask;
        case InputMaskType.Password:
          return null;
      }
    } catch (error) {
      console.log(this.constructor.name, 'getMask', error.message);
    }
  }

  /* */
  static createNumber(
    maxLength: number,
    decimalPlaces: number = 0,
    prefix: string = '',
    suffix: string = '',
    decimalSymbol: string = ',',
    thousandsSeparatorSymbol: string = '.'
  ): any {
    try {
      const requireDecimal: boolean = decimalPlaces > 0;
      return createNumberMask({
        prefix,
        suffix, // This will put the dollar sign at the end, with a space.
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol,
        allowDecimal: requireDecimal,
        decimalSymbol,
        decimalLimit: decimalPlaces,
        integerLimit: maxLength,
        requireDecimal,
        allowNegative: true,
        allowLeadingZeroes: false
      });
    } catch (error) {
      console.log(this.constructor.name, 'createNumber', error.message);
    }
  }

  public static toString(value: number): string {
    try {
      switch (value) {
        default:
        case 0:
          return 'None';
        case 1:
          return 'Numeric';
        case 2:
          return 'Integer';
        case 3:
          return 'Date';
        case 4:
          return 'DateTime';
        case 5:
          return 'TimeHHMMSS';
        case 6:
          return 'TimeHHMM';
        case 7:
          return 'CPF';
        case 8:
          return 'CNPJ';
        case 9:
          return 'CEP';
        case 10:
          return 'Phone';
        case 11:
          return 'PhoneWithDDD';
        case 12:
          return 'PhoneWithDDDInternational';
        case 13:
          return 'PhoneWithDDDNoPrefix';
        case 14:
          return 'PhoneWithDDDNoPrefixInternational';
        case 15:
          return 'Percent';
        case 16:
          return 'Real';
        case 17:
          return 'Dolar';
        case 18:
          return 'Numeric2';
        case 19:
          return 'Percent2';
        case 20:
          return 'Numeric3';
        case 21:
          return 'Numeric4';
        case 22:
          return 'TimeHHHMM';
        case 23:
          return 'Email';
        case 24:
          return 'Password';
      }
    } catch (error) {
      console.log(this.constructor.name, 'toString', error.message);
    }
  }

  public static toEnum(value: string): number {
    try {
      switch (value?.toUpperCase()) {
        default:
        case 'NONE':
          return InputMaskType.None;
        case 'NUMERIC':
          return InputMaskType.Numeric;
        case 'INTEGER':
          return InputMaskType.Integer;
        case 'DATE':
          return InputMaskType.Date;
        case 'DATETIME':
          return InputMaskType.DateTime;
        case 'TIMEHHMMSS':
          return InputMaskType.TimeHHMMSS;
        case 'TIMEHHMM':
          return InputMaskType.TimeHHMM;
        case 'CPF':
          return InputMaskType.CPF;
        case 'CNPJ':
          return InputMaskType.CNPJ;
        case 'CEP':
          return InputMaskType.CEP;
        case 'PHONE':
          return InputMaskType.Phone;
        case 'PHONEWITHDDD':
          return InputMaskType.PhoneWithDDD;
        case 'PHONEWITHDDDINTERNATIONAL':
          return InputMaskType.PhoneWithDDDInternational;
        case 'PHONEWITHDDDNOPREFIX':
          return InputMaskType.PhoneWithDDDNoPrefix;
        case 'PHONEWITHDDDNOPREFIXINTERNATIONAL':
          return InputMaskType.PhoneWithDDDNoPrefixInternational;
        case 'PERCENT':
          return InputMaskType.Percent;
        case 'REAL':
          return InputMaskType.Real;
        case 'DOLAR':
          return InputMaskType.Dolar;
        case 'NUMERIC2':
          return InputMaskType.Numeric2;
        case 'NUMERIC3':
          return InputMaskType.Numeric3;
        case 'NUMERIC4':
          return InputMaskType.Numeric4;
        case 'PERCENT2':
          return InputMaskType.Percent2;
        case 'TIMEHHHMM':
          return InputMaskType.TimeHHHMM;
        case 'EMAIL':
          return InputMaskType.Email;
        case 'ALPHANUMERIC':
        case 'PASSWORD':
          return InputMaskType.Password;
      }
    } catch (error) {
      console.log(this.constructor.name, 'toEnum', error.message);
    }
  }

  public static cleanMaskSymbol(str: string, enInputMaskType: string): string {
    try {
      if (!str) {
        return null;
      }
      const inputMaskType = InputMaskType.toEnum(enInputMaskType);
      switch (inputMaskType) {
        case InputMaskType.Dolar:
          return str?.replace('$', '');
        case InputMaskType.Percent2:
        case InputMaskType.Percent:
          return str?.replace('%', '');
        case InputMaskType.Real:
          return str?.replace('R$', '');
        default:
          return str;
      }
    } catch (error) {
      console.log(this.constructor.name, 'cleanMaskSymbol', error.message);
    }
  }

  public static cleanMaskSymbolAndConvertToNumber(str: string, enInputMaskType: string): string {
    try {
      if (!str) {
        return null;
      }
      const isEnNumber = /^\d{1,}(\.\d{1,})$/;
      if (isEnNumber.test(str)) {
        return str;
      }
      const inputMaskType = InputMaskType.toEnum(enInputMaskType);
      switch (inputMaskType) {
        case InputMaskType.Dolar: // Já estará no padrão americano
          return str?.toString().replace('$', '').replace(/\,/g, '');
        // Padrão brasileiro xx.xxx,xx
        case InputMaskType.Percent2:
          return str?.toString().replace('%', '').replace(/\./g, '').replace(/\,/g, '.');
        case InputMaskType.Percent:
          return str?.toString().replace('%', '').replace(/\./g, '');
        case InputMaskType.Real:
          return str?.toString().replace('R$', '').replace(/\./g, '').replace(/\,/g, '.');
        case InputMaskType.Numeric3:
        case InputMaskType.Numeric4:
        case InputMaskType.Numeric2:
          return str?.toString().replace(/\./g, '').replace(/\,/g, '.');
        default:
          return str;
      }
    } catch (error) {
      console.log(this.constructor.name, 'cleanMaskSymbolAndCOnvertToNumber', error.message);
    }
  }

  /*Faz a operação inversa do cleanMaskSymbolAndConvertToNumber. Removerá o símbolo,
     * no entanto, transformará um número en em ptBr para exibição ou salvar.
     * OBS: Os números são salvos na execucaodados (serviço) mantendo o padrão da máscara.
     * ATENÇÃO: _str SEMPRE deverá chegar no padrão en.
     */
  public static cleanMaskSymbolAndConvertToPtBr(str: string, enInputMaskType: string): string {
    try {
      if (!str) {
        return null;
      }
      const isEnNumber = /^\d{1,}(\.\d{1,})$/;
      if (!isEnNumber.test(str)) {
        return str;
      }
      const inputMaskType = InputMaskType.toEnum(enInputMaskType);
      switch (inputMaskType) {
        case InputMaskType.Dolar: // Já estará no padrão americano
          return str?.toString().replace('$', '').replace(/\./g, '').replace(/\,/g, '.');
        // Padrão brasileiro xx.xxx,xx
        case InputMaskType.Percent2:
          return str?.toString().replace('%', '').replace(/\,/g, '').replace(/\./g, ',');
        case InputMaskType.Percent:
          return str?.toString().replace('%', '').replace(/\,/g, '');
        case InputMaskType.Real:
          return str?.toString().replace('R$', '').replace(/\,/g, '').replace(/\./g, ',');
        case InputMaskType.Numeric3:
          const num3 = Number.parseFloat(str?.toString()).toFixed(3);
          return num3.toString().replace(/\,/g, '').replace(/\./g, ',');
        case InputMaskType.Numeric4:
          const num4 = Number.parseFloat(str?.toString()).toFixed(4);
          return num4.toString().replace(/\,/g, '').replace(/\./g, ',');
        case InputMaskType.Numeric2:
          const num2 = Number.parseFloat(str?.toString()).toFixed(2);
          return num2.toString().replace(/\,/g, '').replace(/\./g, ',');
        default:
          return str;
      }
    } catch (error) {
      console.log(this.constructor.name, 'cleanMaskSymbolAndConvertToPtBr', error.message);
    }
  }

  /*Retorna um pipe que valida a máscara de data.  */
  static getDatePipe(dateFormat: string = 'dd/mm/yyyy'): any {
    try {
      return createAutoCorrectedDatePipe(dateFormat);
    } catch (error) {
      console.log(this.constructor.name, 'getDatePipe', error.message);
    }
  }

  /*Retorna um pipe para transformar uma entrada do tipo xx,xxx.xx em xx.xxx,xx
     * expectedFormat "pt-br"
     */
  // tslint:disable-next-line: ban-types
  static getNumberEnToPtBr(expectedFormat: string): Function {
    try {
      return (conformedValue: string) => {
        const isEnNumberExp = /^[0-9]{1,3}(?:\,[0-9]{3})*(?:\.[0-9]{1,2})?$/;
        const isEnNumber = isEnNumberExp.test(conformedValue);
        let indexesOfPipedChars: number[];
        if (expectedFormat === 'pt-br') {
          if (isEnNumber) {
            // Esperado chegar em pt-br, mas chegou en
            const index = conformedValue?.indexOf('.');
            indexesOfPipedChars = index >= 0 ? [index] : [];
            conformedValue = conformedValue?.replace(/\,/g, '').replace('.', ',');
          } else {
            // Esperado chegar em pt-br e chegou corretamente.
            return conformedValue;
          }
        } else {
          if (!isEnNumber) {
            // Esperado chegar em en, mas chegou pt-br
            const index = conformedValue?.indexOf(',');
            indexesOfPipedChars = index >= 0 ? [index] : [];
            conformedValue = conformedValue?.replace(/\./g, '').replace(',', '.');
          } else {
            // Esperado chegar em en e chegou corretamente.
            return conformedValue;
          }
        }
        if (conformedValue?.endsWith(',')) {
          conformedValue += '00';
          indexesOfPipedChars?.push(conformedValue?.length - 1, conformedValue?.length - 2);
        }
        return {
          value: conformedValue,
          indexesOfPipedChars
        };
      };
    } catch (error) {
      console.log(this.constructor.name, 'getNumberEnToPtBr', error.message);
    }
    return null;
  }

  /*Retorna um pipe que processa um número inteiro.
   * Irá truncar, ou seja, descartar, as casas decimais. Sem arredondamento.
   */
  // tslint:disable-next-line: ban-types
  static getIntegerPipe(expectedFormat: string = 'pt-br'): Function {
    try {
      return (conformedValue: string) => {
        const obj = InputMaskType.getNumberEnToPtBr(expectedFormat);
        let newValue = 0;
        try {
          newValue = Math.trunc(obj(conformedValue));
        } catch (e) { }
        return {
          value: newValue,
          indexesOfPipedChars: []
        };
      };
    } catch (error) {
      console.log(this.constructor.name, 'getInteger', error.message);
    }
    return null;
  }
}
