import { Injectable } from '@angular/core';
import { GlobalService, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { WebService } from './web.service';
import { Observable } from 'rxjs';
import { refCount } from 'rxjs/operators';
import { publishReplay } from 'rxjs/operators';


@Injectable()
export class ObjectDAL extends UnsubscribeOnDestroyAdapter {

  // Cache
  protected itemsCache: Observable<any>;
  protected currentKeys: any[] = new Array<any>();

  constructor(
    protected webService: WebService,
    protected log: LogService,
    protected global: GlobalService,
  ) { super(); }


  // tslint:disable-next-line: ban-types
  protected getWithCache<T>(getItemsMethod: Function, ...keys): Observable<T> {
    try {
      if (
        this.isDiferentKey(keys) ||
        (!this.itemsCache)
      ) {
        this.itemsCache =
          getItemsMethod.apply(this, keys)
            .pipe(
              publishReplay(),
              refCount()
            )
      } else {
        // console.log("cache return");
      }
      return this.itemsCache;
    } catch (error) {
      this.log.Registrar('ObjectDAL', 'getWithCache', error.message);
    }
    return null;
  }

  protected isDiferentKey(...keys): boolean {
    try {
      let isEqual = true;
      if (keys.length !== this.currentKeys.length) {
        return true;
      }
      keys.forEach((k, i, ar) => {
        if (this.currentKeys[i] !== k) {
          isEqual = false;
        }
      });
      return !isEqual;
    } catch (error) {
      this.log.Registrar('ObjectDAL', 'isDiferentKey', error.message);
    }
  }

  /*Apaga o cache específico.
  * A princípio, não há como saber se o método foi getCadastroFiltrado ou getCadastroLista.
 */
  cleanCache(method: string, params: Array<any>): void {
    try {
      this.webService.cleanCacheFromKey(method, params);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCache', error.message);
    }
  }


}
