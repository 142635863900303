import { metaReducers } from './+state/medlogic.metareducer';
import { DataAccessModule } from '@medlogic/shared/shared-data-access';
import { StoreProviderService } from '@medlogic/shared/utils';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MedlogicEffects } from './+state/medlogic.effects';
import { medlogicReducer } from './+state/medlogic.reducers';

const STATE_MODULES = [
  EffectsModule.forFeature([
    MedlogicEffects,
  ]),
  // NgrxRouterStoreModule,
  StoreModule.forFeature('appMedlogic', medlogicReducer, { metaReducers })
];


const MODULES = [
  CommonModule,
  DataAccessModule
];

const SERVICES = [
  StoreProviderService,
];

@NgModule({
  imports: [
    STATE_MODULES,
    MODULES
  ],
  providers: [
    SERVICES
  ]
})
export class StateMedlogicModule { }
