import { EnPersonType } from '../../enum/en-type-person.enum';

export interface IRelationship {
    ocorrenciaNo: number;
    idEscola: any;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    nome: any;
    email: string;
    enPersonType: EnPersonType;
    // calculados
    status?: string;
}
