import { IActivityContributor } from './iactivity-contributor';
import { IActivityDetail } from './iactivity-detail';
import { IIdentifier } from './iidentifier';
import { IProgress } from './iprogress';


export interface IPractitioner {
    practitionerIdentifier: string;
    active: boolean;
    practitionerName: string;
    telecom: string;
    system: string;
    address: string;
    gender:  string;
    birthDate: Date;
    photo: string;
    qualification: string;
    communication: string;
    drt: number;
    practitionerLogin: string;
    tenantId: number;
    language: string;
    progress: IProgress[];
    ActivityContributor: IActivityContributor[];
    // ActivityDetail: IActivityDetail[];
    Identifier: IIdentifier[];
}
