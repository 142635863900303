export interface ICustomer {
  ocorrenciaNo: number;
  iDINSTITUICAO: any;
  nomeInstituicao: any;
  dataRegistro: any;
  responsavelLegal: any;
  codigo: any;
  titulo: any;
  habilitado: boolean;
  ramoatividade: string;
  numeroColaboradores: any;
  numeroResidentesGrauII: any;
  especifique: any;
  nResidentessexoMasculino: any;
  registroConselhoClasseResponsavelTecnicoRT: any;
  numeroResidentesGrauI: any;
  eMailResponsavelLegal: any;
  responsavelTecnico: any;
  telefoneResponsavelLegal: any;
  gastoMedioManutencaoMensalInstituicao: string;
  identificacao1: any;
  identificacao2: any;
  numeroTotalResidentes: any;
  tipo: boolean;
  anoAdmissao: any;
  admissaoAntes2000: any;
  agendamento: any;
  urlLogo: any;
  prontuarioSoma: any;
  totalCadastro: any;
  pRTO: any;
  identificacoes: any;
  endereco: any;
  numero: any;
  bairro: any;
  cidade: any;
  telefone: any;
  estado: string;
  celular: any;
  eMail: any;
  getUsuarioToken: any;
  retornoWebApiCentroCusto: any;
  eMailResponsavelTecnico: any;
  eNDERECOCOMPLETO: any;
  seSimQualfonteorigem: string;
  mascara: any;
  natureza: boolean;
  cNPJ: any;
  numeroResidentesGrauIII: any;
  nResidentessexoFeminino: any;
  recebeTransferenciarecursogoverno: boolean;
  eMailCRASQueILPIEstaVinculada: any;
  comentariosHouver: string;
  luvas: any;
  gorro: any;
  termometroInfravermelho: any;
  alcoolGel: any;
  avental: any;
  seOutrosEspecifique: string;
}
