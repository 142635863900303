<div class="ng-container text-center">
  <div class="top-bar">
    <app-btn-back (backButtonClicked)="onBack($event)"></app-btn-back>
  </div>

  <h1>Plano de Cuidados</h1>

  <div class="scroll">
    <ml-patient-header [patient]="selectedPatient"> </ml-patient-header>

    <p *ngIf="!isLoading">
      <b>Quarto:</b> {{ quarto }} | <b>Bloco:</b> {{ bloco }}
    </p>
    <p *ngIf="!isLoading"><b>Prontuário: </b> {{ prontuario }}</p>

    <mat-icon
      class="icons"
      *ngFor="let icon of patientIcons"
      aria-hidden="false"
      [class.enabled]="true"
      [svgIcon]="icon?.display"
      (click)="alerta(icon)"
    >
    </mat-icon>
  </div>

  <ui-loading [isLoading]="isLoading"></ui-loading>

  <ng-container *ngIf="!isLoading">
    <div class="care-plan-content">
      <div class="care-plan-container" *ngIf="usoOxigenio === 'Sim'">
        <h2>USO DE OXIGÊNIO</h2>
        <div>
          <p>
            <b>Uso:</b> {{ usoOxigenio }} | <b>Fluxo:</b> {{ fluxoOxigenio }}
          </p>
          <p>
            <b>Período de utilização:</b> {{ periodoutilizacaoAlimentacao }}
            {{ periodoutilizacaoDeambulacao }} {{ periodoutilizacaoNoturno }}
            {{ periodoutilizacaoBanho }} {{ periodoutilizacaoContinuo }}
          </p>
        </div>
        <p *ngIf="cpap === 'Sim'"><b>CPAP:</b> {{ cpap }}</p>
        <p>{{ campoAbertoOxi }}</p>
      </div>

      <div class="care-plan-container">
        <h2>TRANSFERÊNCIA</h2>
        <p *ngIf="todosOsMomentos !== 'Não informado'">
          <b>Todos os momentos:</b> {{ todosOsMomentos }}
          <ng-container *ngIf="auxilioDispositivo !== 'Não informado'">
            | <b>Auxílio/dispositivo:</b> {{ auxilioDispositivo }}</ng-container
          >
        </p>
        <p *ngIf="sairEntrarNoLeito !== 'Não informado'">
          <b>Sair/Entrar no leito:</b> {{ sairEntrarNoLeito }}
          <ng-container *ngIf="auxilioDispositivo2 !== 'Não informado'">
            | <b>Auxílio/dispositivo:</b>
            {{ auxilioDispositivo2 }}</ng-container
          >
        </p>
        <p *ngIf="cadeiraCadeira !== 'Não informado'">
          <b>Cadeira/Cadeira:</b> {{ cadeiraCadeira }}
          <ng-container *ngIf="auxilioDispositivo3 !== 'Não informado'">
            | <b>Auxílio/dispositivo:</b>
            {{ auxilioDispositivo3 }}</ng-container
          >
        </p>
        <p>{{ campoAbertoTransf }}</p>
      </div>
      <div class="care-plan-container">
        <h2>LOCOMOÇÃO</h2>
        <p *ngIf="todasAsDependencias !== 'Não informado'">
          <b>Todas as dependências do RIAE:</b> {{ todasAsDependencias
          }}<ng-container *ngIf="marchas[0] !== 'Não informado'">
            - {{ marchas[0] }}</ng-container
          >
        </p>

        <p *ngIf="areasExternas !== 'Não informado'">
          <b>Áreas externas do RIAE:</b> {{ areasExternas
          }}<ng-container *ngIf="marchas[2] !== 'Não informado'">
            - {{ marchas[2] }}</ng-container
          >
        </p>

        <p *ngIf="areasInternas !== 'Não informado'">
          <b>Áreas internas do RIAE:</b> {{ areasInternas
          }}<ng-container *ngIf="marchas[3] !== 'Não informado'">
            - {{ marchas[3] }}</ng-container
          >
        </p>

        <p *ngIf="andarMoradia !== 'Não informado'">
          <b>Andar de moradia:</b> {{ andarMoradia }}
          <ng-container *ngIf="marchas[4] !== 'Não informado'">{{
            marchas[4]
          }}</ng-container>
        </p>

        <p *ngIf="foraAndarMoradia !== 'Não informado'">
          <b>Fora do andar de moradia:</b> {{ foraAndarMoradia }}
          <ng-container *ngIf="marchas[5] !== 'Não informado'">{{
            marchas[5]
          }}</ng-container>
        </p>

        <p *ngIf="dentroQuarto !== 'Não informado'">
          <b>Dentro do quarto:</b> {{ dentroQuarto
          }}<ng-container *ngIf="marchas[6] !== 'Não informado'">
            - {{ marchas[6] }}</ng-container
          >
        </p>
        <p>{{ campoAbertoLoc }}</p>
      </div>
      <div class="care-plan-container">
        <h2>VASO SANITÁRIO</h2>
        <p *ngIf="usoFralda === 'Sim'">
          <b>Uso de fraldas:</b> {{ usoFralda }} | <b>Período: </b
          >{{ periodoFralda }}
        </p>
        <p>
          <b *ngIf="trocas">Trocas: </b>{{ trocas
          }}<b *ngIf="vasoSanitario"> | Uso do vaso sanitário: </b
          >{{ vasoSanitario }}
        </p>
        <p>{{ campoAbertoEnf }}</p>
      </div>
      <div class="care-plan-container">
        <h2>BANHO</h2>
        <p *ngIf="cadeiraHigienica === 'Sim'">
          <b>Uso de cadeira higiênica: </b>{{ cadeiraHigienica }} |
          <b>Auxílio: </b>{{ auxilioBanho }}
        </p>
        <p>{{ campoAbertoBanho }}</p>
      </div>
      <div class="care-plan-container">
        <h2>HIGIENE PESSOAL</h2>
        <p *ngIf="higieneOralUsoProtese">
          <b>Higiene pessoal e uso de prótese: </b>{{ higieneOralUsoProtese }}
        </p>
        <p>
          <b *ngIf="pentear">Pentear-se: </b>{{ pentear
          }}<b *ngIf="barbaMaquiagem"> | Barbear-se/Maquiar-se: </b
          >{{ barbaMaquiagem }}
        </p>
      </div>

      <div class="care-plan-container">
        <h2>VESTIR/CALÇAR</h2>
        <p *ngIf="auxilioTO"><b>Auxílio: </b>{{ auxilioTO }}</p>
        <p>
          <b *ngIf="superiores">Partes Superiores: </b>{{ superiores
          }}<b *ngIf="inferiores"> | Partes Inferiores: </b>{{ inferiores }}
        </p>
        <p>{{ campoAbertoTO }}</p>
      </div>
      <div class="care-plan-container">
        <h2>COMUNICAÇÃO</h2>
        <p *ngIf="comunicacao"><b>Idoso comunicativo: </b>{{ comunicacao }}</p>
        <p>{{ campoAbertoFono3 }}</p>
      </div>
      <div class="care-plan-container">
        <h2>COMPORTAMENTO</h2>
        <p>{{ comportamento }}</p>
      </div>
      <div class="care-plan-container">
        <h2>ALIMENTAÇÃO</h2>
        <p *ngIf="ofertaOral"><b>Oferta via oral: </b>{{ ofertaOral }}</p>
        <p *ngIf="consistencia"><b>Consistência: </b>{{ consistencia }}</p>
        <p *ngIf="tipo"><b>Tipo: </b>{{ tipo }}</p>
        <p *ngIf="localRefeicao || descrevaRefeicao">
          <b>Local de refeição: </b>{{ localRefeicao }} - {{ descrevaRefeicao }}
        </p>
        <p *ngIf="suspender === 'Sim'">
          <b>Suspender dieta: </b>{{ suspender }}
        </p>
        <p *ngIf="refeicaoAdaptada === 'Sim'">
          <b>Refeição com adaptação: </b> {{ refeicaoAdaptada }}
        </p>
        <div *ngIf="usoEspessante === 'Sim'">
          <p><b>Uso do espessante: </b>{{ usoEspessante }}</p>
          <p><b>Tipo de consistência: </b>{{ consistenciaEspessante }}</p>
          <p><b>Proporção do Espessante: </b>{{ proporcaoEspessante }}</p>
        </div>
        <p *ngIf="ingestaoLiquidos !== 'Outro'">
          <b>Ingestão de Líquidos: </b>{{ ingestaoLiquidos }}
        </p>
        <p *ngIf="ingestaoLiquidos === 'Outro'">
          <b>Ingestão de Líquidos: </b>{{ ingestaoDescricao }}
        </p>
        <p>{{ campoAbertoFono }}</p>
      </div>

      <div class="care-plan-container">
        <h2>PREVENÇÃO QUEDAS</h2>
        <p>{{ prevencaoQuedas }}</p>
      </div>

      <div class="care-plan-container" *ngIf="obsComportamento">
        <h2>OUTRAS ORIENTAÇÕES</h2>
        <p>{{ obsComportamento }}</p>
      </div>
      <div class="care-plan-container frase">
        <p>
          Seguir orientações sobre medidas preventivas de LESÃO POR PRESSÃO na
          pasta de registro diário.
        </p>
      </div>
    </div>
  </ng-container>
</div>
