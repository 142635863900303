import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { publishReplay } from 'rxjs/operators';
import { refCount } from 'rxjs/operators';
import {
  IForm, IServiceProvider, IListItem, LocalLibService,
  GlobalService, LogService, ConfigJsonService, IGeneric
} from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { CadastroService } from './cadastro.service';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class GenericService implements IServiceProvider {
  // tslint:disable: max-line-length
  // private lstVariaveis = 'V_387,V_391,V_2230,V_28051,V_103152';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  private get lstVariaveis(): string {
    return this.mapArray?.reduce((a, b) => a.concat(`V_${b},`), '');
  }

  private get mapArray(): number[] {
    const find: any = this.cnf.modules?.find(f => this.glb.isEqual(f.name, this.label?.toUpperCase()));
    if (!find) {
      return null;
    }
    const m = find.identification;
    return [m.id, m.identification1, m.identification2, m.topLeft, m.bottomLeft, m.topRight, m.bottomRight];
  }

  recurrences: IListItem<IGeneric>[] = new Array<IListItem<IGeneric>>();

  codigoVariavelNo = 2230;
  cadastroNo: number;
  label: string;
  currentDtInicial = new Date();
  currentDtFinal = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected cnf: ConfigJsonService,
    protected lib: LocalLibService,
    protected log: LogService) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna somente as variáveis desejadas.
  * lstVariables do tipo: 'V_3332,V_32223'
  */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IListItem<IGeneric>> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date, isDebug: boolean = false): Observable<IListItem<IGeneric>> {
    try {
      if (isDebug) {
        return this.getAllMockup();
      }
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  protected getAllMockup(): Observable<IListItem<IGeneric>> {
    try {
      return of([
        {
          id: 1,
          identification1: 'identification1',
          identification2: 'identification2',
          topLeft: 'topLeft1',
          bottomLeft: 'bottomLeft1',
          topRight: 'topRight1',
          bottomRight: 'bottomRight1',
          color: 'black',
          imgUrl: 'imgUrl'

        } as IListItem<IGeneric>,
        {
          id: 2,
          identification1: 'identification1.2',
          identification2: 'identification2.2',
          topLeft: 'topLeft1.2',
          bottomLeft: 'bottomLeft1.2',
          topRight: 'topRight1.2',
          bottomRight: 'bottomRight1.2',
          color: 'black2',
          imgUrl: 'imgUrl2'

        } as IListItem<IGeneric>,
      ]).pipe(flatMap(f => f));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAllMockup', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo';
      const propValue = 'codigo';
      const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IListItem<IGeneric>> {
    try {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
      return this.cadastrosCache;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IListItem<IGeneric>> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      this.cadastroNo = cadastroNo;
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables)
        .pipe(
          this.mapTo(cadastroNo),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  protected mapTo = (ano: number) => map((c: any) => {
    this.cadastroNo = ano;
    if (!this.mapArray) {
      return null;
    }
    return {
      // ocorrenciaNo: c.OcorrenciaNo,
      ono: c.OcorrenciaNo || c.ono, // Função Map utilizada tanto para retorno do serviço quanto do GenericCustom.
      tno: c.TarefaNo,
      ano,
      title: c.Titulo,
      relationshipId: +c[`V_${this.mapArray[0]}`],
      identification1: c[`V_${this.mapArray[1]}`],
      identification2: c[`V_${this.mapArray[2]}`],
      topLeft: c[`V_${this.mapArray[3]}`],
      bottomLeft: c[`V_${this.mapArray[4]}`],
      topRight: c[`V_${this.mapArray[5]}`],
      bottomRight: c[`V_${this.mapArray[6]}`],
    } as IListItem<IGeneric>;
  })

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`
  * lstVariables do tipo: 'V_3332,V_32223' e é capaz de trazer apenas esses campos solicitados.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date = null, endDate: Date = null, isFilterAnd: boolean = true, lstVariables: string = null): Observable<IListItem<IGeneric>> {
    try {
      this.cadastroSrv.dtInicial = startDate ? this.glb.dateToYYYYMMddThhmmss(startDate) : this.glb.dateToYYYYMMddThhmmss(new Date(1900, 0, 1));
      this.cadastroSrv.dtFinal = endDate ? this.glb.dateToYYYYMMddThhmmss(endDate) : this.glb.dateToYYYYMMddThhmmss(new Date(3000, 0, 1));
      this.cadastroNo = cadastroNo;
      lstVariables = lstVariables || this.lstVariaveis;
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables, strFilter, isFilterAnd)
        .pipe(
          this.mapTo(cadastroNo),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
  * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
  */
  save<T>(ano: number, item: T, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(item as unknown as IListItem<IGeneric>).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id, true, this.codigoVariavelNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(item: IListItem<IGeneric>): IForm[] {
    try {
      return [
        { VariavelNo: 387, ValorDado: item.topLeft || '' },
        { VariavelNo: 391, ValorDado: item.bottomRight || '' },
        { VariavelNo: 2230, ValorDado: item.id || '' },
        { VariavelNo: 28051, ValorDado: item.bottomLeft || '' },
        { VariavelNo: 103152, ValorDado: item.topRight || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist<T>(ano: number, item: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return this.getFromCadastro(ano, null, null)
        .pipe(
          toArray(),
          mergeMap(items => {
            const founded = items && (items.findIndex(f => this.glb.isEqual(f[compareFieldName], item[compareFieldName])) >= 0);
            if (!founded) {
              return this.save(ano, item, uno);
            }
            return of(founded);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }

  protected convertToValorTexto(item: IListItem<IGeneric>[]): string {
    try {
      let valorTexto = '<![CDATA[<Items>';
      if (item) {
        item.forEach(f => {
          valorTexto += `<Item><index>${f.id}</index>`;
          if (f.topLeft) { valorTexto += `<V_387>${f.topLeft}</V_387>`; }
          if (f.bottomLeft) { valorTexto += `<V_391>${f.bottomLeft}</V_391>`; }
          if (f.id) { valorTexto += `<V_2230>${f.id}</V_2230>`; }
          if (f.topRight) { valorTexto += `<V_103152>${f.topRight}</V_103152>`; }
          if (f.bottomRight) { valorTexto += `<V_391>${f.bottomRight}</V_391>`; }
          valorTexto += '</Item>';
        });
      }
      valorTexto += '</Items>]]>';
      return valorTexto;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'convertToValorTexto', error.message);
    }
    return '';
  }


}
