import { ConfigStateService } from '@medlogic/shared/state-config';
import { NavigationService } from '../../../shared/service/navigation.service';
import { IBubble } from '../../../shared/interface/ibubble';
import { EnBubbleEvent } from '../../../shared/enum/en-bubble-event.enum';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { DisplayObject } from '@medlogic/shared/gecore';
import { EnTheme } from '@medlogic/shared/shared-interfaces';


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.css']
})
export class StatusBarComponent extends DisplayObject implements OnInit {

  @Input() backAtividadeNo: number;
  @Input() backOcorrenciaNo: number;
  @Input() ocorrenciaNo: number;
  @Input() atividadeNome: string;
  @Input() atividadeNo: number;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  showVariable = this.cnfJson.showVariavelNo;
  cnfOno = this.config.OcorrenciaNo.value;

  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble: EventEmitter<IBubble> = new EventEmitter<IBubble>();
  onEventBubble($event) {
    this.eventBubble.emit($event);
  }

  constructor(
    private log: LogService,
    private config: ConfigStateService,
    private cnfJson: ConfigJsonService,
    private global: GlobalService,
    protected navigation: NavigationService
  ) {
    super();
  }

  /* Retorna o número da Ocorrência ou 'NOVA'. */
  getOcorrencia(): string {
    try {
      return this.ocorrenciaNo > 0 ? this.ocorrenciaNo.toString() : 'NOVA';
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getOcorrencia', error.message);
    }
    return '-1';
  }

  ngOnInit() { }

  // FIXME: Atenção brecha de segurança: o parâmetro se a atividade anterior é editável
  // deve ser fornecido. Desta forma, se o usuário voltar, desbloqueará a atividade.
  onBackClick($event: any, ano: number, ono: number): void {
    try {
      // Zera os itens preenchidos, pois o usuário cancelou a mudança.
      this.config.cleanAllDefault();
      this.eventBubble.emit({
        $event,
        bubbleEvent: EnBubbleEvent.historyBack,
        params: {}
      } as IBubble);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBackClick', error.message);
    }
  }

  /* Define se o botão voltar estará ativo.
   * A regra é checar se existe pelo menos uma posição no histórico.
    */
  enableBack(): boolean {
    try {
      // Desativado o recurso de cancelamento. Não faz mais sentido nesse projeto integrado
      return false;
      // return this.navigation.hasHistory();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'enableBack', error.message);
    }
  }

  /** Verifica se o tema black deve ser aplicado. */
  // isBlack(): boolean {
  //   try {
  //     return this.enTheme === EnTheme.black;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'isBlack', error.message);
  //   }
  //   return false;
  // }

}
