<lib-ge-blueprint [isLoading]="isLoading | async" [isMobile]="isMobile" [enTheme]="enTheme" module="ATIVIDADE"></lib-ge-blueprint>

<!-- A Ocorrencia Existe ou é uma Nova -->
<div class="container" *ngIf="newOrExists()">
  <!-- <ui-loading [isLoading]="isLoading | async"></ui-loading> -->
  <form [formGroup]="formGroup" *ngIf="actived" (ngSubmit)="onSubmit()">
    <lib-tab [attr.data-ono]="ocorrenciaNo" [activityType]="activityType" [saveInList]="saveInList" [addToHistory]="true" [componentes]="componentes"
      [atividade]="atividade" [tabs]="tabs" [isMobile]="isMobile" [tabActivedId]="tabActivedId" [saved]="saved"
      [isLoading]="isLoading | async" [formGroup]="formGroup" [formErrors]="formErrors" [backAtividadeNo]="backAtividadeNo"
      [backOcorrenciaNo]="backOcorrenciaNo" [ocorrenciaNo]="ocorrenciaNo" [atividadeNome]="atividadeNome" [isAndroid]="isAndroid"
      [enTheme]="enTheme" [atividadeNo]="atividadeNo" [isEditMode]="isEditMode" [isReadOnly]="isReadOnly" [printOnly]="printOnly" (eventBubble)="onEventBubble($event)"></lib-tab>
  </form>
  <!-- <lib-status-bar [backAtividadeNo]="backAtividadeNo" [backOcorrenciaNo]="backOcorrenciaNo" [ocorrenciaNo]="ocorrenciaNo" [atividadeNome]="atividadeNome"
    (eventBubble)="onEventBubble($event)"></lib-status-bar> -->
</div>
<!--A Ocorrencia que se tenta editar não existe-->
<div *ngIf="!newOrExists()">
  <!--Antes de exibir essa mensagem é necessário criar uma maneira de realmente saber se a ocorrencia não existe-->
  <!--<h1>Desculpe, mas você tentou editar a Ocorrência {{ ocorrenciaNo }}, mas ela não existe!</h1>-->
</div>

<p-toast position="bottom-center"></p-toast>
<!--Mensagem de confirmação de exclusão -->
<p-confirmDialog header="Confirmação" icon="fa fa-question-circle" [rejectIcon]="message.icon"
  [rejectLabel]="message.firstButtonLabel" acceptLabel="Sim" [style]="{ 'width': '425px'}">
</p-confirmDialog>
<lib-debug *ngIf="isDebug" [msg]="debugMsg"></lib-debug>
