import { IPrimeiraAtividade } from '../interface/iprimeira-atividade';
import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ActivityService {
  uriDefault = 'Activity';
  urlGetGetListActivityEnabledByProccess = `${this.uriDefault}/GetListActivityEnabledByProccess?strListAtividade={0}`;
  urlGetFirstActivityByProccess = `${this.uriDefault}/GetFirstActivityByProccess?processoNo={0}`;
  urlGetActivityMultiByTaskNo = `${this.uriDefault}/GetActivityMultiByTaskNo?processoNo={0}&atividadeNo={1}&tarefaNo={2}`;

  constructor(private basepage: BasePageService, private log: LogService) { }

  GetListActivityEnabledByProccess(lstAtividadeNo: string): Observable<any> {
    try {
      const urlGet = this.basepage.format(
        this.urlGetGetListActivityEnabledByProccess,
        lstAtividadeNo
      );
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'GetListActivityEnabledByProccess', error.message);
    }
  }

  GetFirstActivityByProccess(processoNo: number): Observable<IPrimeiraAtividade> {
    try {
      const urlGet = this.basepage.format(
        this.urlGetFirstActivityByProccess,
        processoNo
      );
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'GetFirstActivityByProccess', error.message);
    }
  }

  GetActivityMultiByTaskNo(atividadeNo: number, processoNo: number, tarefaNo = -1) {
    try {
      const urlGet = this.basepage.format(
        this.urlGetActivityMultiByTaskNo,
        processoNo,
        atividadeNo,
        tarefaNo
      );
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'GetActivityMultiByTaskNo', error.message);
    }
  }


}
