import { Injectable } from '@angular/core';
import { routeIdentifier } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirIdentifierService extends FhirGenericService {

  protected url = routeIdentifier;

}
