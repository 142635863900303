import { Guid } from 'guid-typescript';
import { EnStockChange } from '../../enum/medlogic/en-stock-change.enum';

/** Interface criada para comunicar uma mudança na posição de estoque. */
export interface IStockChange {
	medicationId: number;
	codPacienteNomedoPacienteCodMedicamento: string;
	medicationName: string;
	costCenter: string;
	unity: string;
	businessUnit: string;
	material: string;
	dosage: string;
	guid?: Guid;
	stockValue?: number;
	changedStockValue?: number;
	sourceCostCenter?: string;
	sourceBatch?: string;
	sourceExpirationDate?: Date;
	enStockChange?: EnStockChange;
	sourceStockValue?: number;
	price?: number;
	packageQuantity: number;
}

export class IStockChangeClass {
	public static factory(obj: IStockChange | any = null): IStockChange {
		const stockChange = {
			medicationId: null,
			codPacienteNomedoPacienteCodMedicamento: null,
			medicationName: null,
			costCenter: null,
			unity: null,
			businessUnit: null,
			material: null,
			dosage: null,
			guid: null,
			stockValue: null,
			sourceCostCenter: null,
			sourceBatch: null,
			changedStockValue: 0,
			enStockChange: null,
			packageQuantity: 0
		} as IStockChange;
		if (obj) {
			for (const clm in obj) {
				if (clm) {
					stockChange[clm] = obj[clm];
				}
			}
		}
		return stockChange;
	}


}
