import { ICadParam } from '@medlogic/shared/shared-interfaces';

/* Interface para o Cadastro do IDOSO BEM CUIDADO,
*/
export const cadParams = [
  { idName: 'cadPacienteNo', label: 'Cad_Paciente' } as ICadParam,
  { idName: 'cadResponsavelFamiliaNo', label: 'Cad_Responsavel_Familia' } as ICadParam,
  { idName: 'cadIntervecoesMedicamentosNo', label: 'Cad_Intervecoes_Medicamentos' } as ICadParam,
  { idName: 'cadIntervecoesHorariosNo', label: 'Cad_Intervencoes_Horario' } as ICadParam,
  { idName: 'cadCheckVitalSignsNo', label: 'Cad_Check_SinaisVitais' } as ICadParam,
  { idName: 'cadCheckMedicamentosNo', label: 'Cad_Check_Medicamentos' } as ICadParam,
  { idName: 'cadEstoqueMateriaisNo', label: 'Cad_Estoque_Materiais' } as ICadParam,
  { idName: 'cadCareRoutineNo', label: 'Cad_Rotinas_Cuidado_Hóspede' } as ICadParam,
  { idName: 'cadCheckinCareRoutineNo', label: 'Cad_Checkin_Rotina_Cuidado' } as ICadParam,
  { idName: 'cadVitalSignsNo', label: 'Cad_SinaisVitais_SinglePage' } as ICadParam,
  { idName: 'cadEvolutionNo', label: 'Cad_Evolucao_SinglePage' } as ICadParam,
  { idName: 'cadResponsavelFamiliaNo', label: 'Cad_Responsavel_Familia' } as ICadParam,
  { idName: 'cadEvolutionsNo', label: 'Cad_Evolucao_SinglePage' } as ICadParam,
  { idName: 'cadProfessionalsNo', label: 'Cad_Profissionais' } as ICadParam,
  // Sistema novo
  { idName: 'cadIvcfNo', label: 'cadIvcfNo' } as ICadParam,
  { idName: 'cadProspectIvcfNo', label: 'CAD_PROSPECT_IVCF' } as ICadParam,
  { idName: 'personAno', label: 'Cad_Paciente' } as ICadParam,
  { idName: 'personPno', label: 'PNO_Paciente' } as ICadParam,
  { idName: 'exameAno', label: 'CAD_EXAME' } as ICadParam,
  { idName: 'examePno', label: 'PNO_EXAME' } as ICadParam,
  { idName: 'frailtyAno', label: 'Cad_indice_fragilidade_atencao_pri' } as ICadParam,
  { idName: 'frailtyPno', label: 'PNO_indice_fragilidade_atencao_pri' } as ICadParam,
  { idName: 'exameResultadoAno', label: 'CAD_EXAME_RESULTADO' } as ICadParam,
  { idName: 'exameResultadoPno', label: 'PNO_EXAME_RESULTADO' } as ICadParam,
  { idName: 'cadPrescriptionNo', label: 'cad_prescription_ano' } as ICadParam,
  { idName: 'cadItensEstoquenNo', label: 'Cad_Itens_Estoque' } as ICadParam,
  { idName: 'cadApresentacaoNo', label: 'Cad_Apresentacao' } as ICadParam,
  { idName: 'cadCentroCustoNo', label: 'Cad_Centro_Custo' } as ICadParam,
  { idName: 'cadUnidadeNegocio', label: 'Cad_Unidade_Negocio' } as ICadParam,
  { idName: 'cadAutorizacaoCuidadoNo', label: 'CAD_AUTORIZACAO_CUIDADO' } as ICadParam,
  { idName: 'cadFamilyNo', label: 'CAD_FAMILIA' } as ICadParam,
  { idName: 'emailFrom', label: 'EMAIL_DE' } as ICadParam,
  { idName: 'cadEmployeeNo', label: 'Cad_DadosProfissionais' } as ICadParam,
  { idName: 'centralStock', label: 'CAD_ESTOQUE_CENTRALIZADO' } as ICadParam,
];
