import { IHorarios, GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { IHorariosState} from '@medlogic/medlogic/medlogic-shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromHorarios from './horarios.reducer';

const glb = new GlobalService();
const log = new LogService();

export const isHorariosLoading = createSelector(
  fromHorarios.selectAll,
  state => !!state,
);


// TODO: Descobrir se há outra forma de criar um "namespace" para que os selectors não colidam


export const selectHorariosState = createFeatureSelector<IHorariosState>(fromHorarios.horariosFeatureKey);
export const selectHorariosAlls = createSelector(selectHorariosState, fromHorarios.selectAll);


