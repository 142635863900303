import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CarePlanEffects } from './+state/carePlan.effects';
import * as fromCarePlan from './+state/carePlan.reducer';

const SERVICES = [
];

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromCarePlan.carePlanFeatureKey, fromCarePlan.reducer),
  EffectsModule.forFeature([CarePlanEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})
export class StateCarePlanModule { }
