import { Component, OnInit, Input } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'app-pain-scale',
  templateUrl: './pain-scale.component.html',
  styleUrls: ['./pain-scale.component.css']
})
export class PainScaleComponent implements OnInit {

  @Input() painLevel: number;

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
  }

  /* Verifica se o número corresponde ao nível de dor selecionado. */
  isSelected(i: number): boolean {
    try {
      return +i === +this.painLevel;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isSelected', error.message);
    }
    return false;
  }


}
