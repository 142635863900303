import { createAction, props } from '@ngrx/store';
import { IExame } from '@medlogic/medlogic/medlogic-shared-interfaces';

// TODO: Each model need to have its specific Action, Reducer, Effects.
export enum ExameActionTypes {
  LoadExames = '[Exame Component] Load Exame ',
  FetchSuccessExame = '[Exame API] Fetch Success Exame ', // Set the Exame  to the Store, after Effects
  FetchErrorExame = '[Exame API] Fetch Error Exame ',
  SetExame = '[Page Exam Component] Set Exame ',
  SaveExame = '[Page Exam Component] Save Exame',
  SaveSuccessExame = '[Page Exam Component] Save Success Exame',
  SaveFailExame = '[Page Exam Component] Save Fail Exame',
  UpdateExame = '[Page Exam Component] Update Exame',
  SaveAllExame = '[Page Exam Component] Save All Exame',
  ResetExame = '[Page Exam Component] Reset Exame',
  CleanExame = '[Page Exam Component] Clean Exame',
  // Category
  SetCategoriaExame = '[Page Exam Component] Set Categoria Exame',
  // Other states
  LoadExamesAndResultados = '[Page Exam Component] Load Exames and Resultados',
}

export const loadExames = createAction(ExameActionTypes.LoadExames);
export const loadExamesAndResultados = createAction(ExameActionTypes.LoadExamesAndResultados);
export const setExame = createAction(ExameActionTypes.SetExame, props<{ exame: IExame }>());
export const fetchSuccessExame = createAction(ExameActionTypes.FetchSuccessExame, props<{ exames: IExame[] }>());
export const fetchErrorExame = createAction(ExameActionTypes.FetchErrorExame, props<{ exames: IExame[] }>());
export const saveExame = createAction(ExameActionTypes.SaveExame, props<{ exame: IExame }>());
export const saveSuccessExame = createAction(ExameActionTypes.SaveSuccessExame, props<{ exame: IExame }>());
export const saveFailExame = createAction(ExameActionTypes.SaveFailExame, props<{ exame: IExame }>());
export const updateExame = createAction(ExameActionTypes.UpdateExame, props<{ exame: IExame }>());
export const saveAllExame = createAction(ExameActionTypes.SaveAllExame, props<{ exame: IExame }>());
export const resetExame = createAction(ExameActionTypes.ResetExame);
export const setCategoriaExame = createAction(ExameActionTypes.SetCategoriaExame, props<{ categoria: string }>());
export const cleanExame = createAction(ExameActionTypes.CleanExame);
