import { Injectable } from '@angular/core';
import { routeFrequency } from '@medlogic/shared/shared-interfaces';
import { FhirGenericService } from './fhir-generic.service';


@Injectable({ providedIn: 'root' })
export class FhirFrequencyService extends FhirGenericService {

  protected url = routeFrequency;

}
