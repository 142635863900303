import { Injectable } from '@angular/core';
import { FhirGenericService } from './fhir-generic.service';
import { routActivityStatus } from '@medlogic/shared/shared-interfaces';

@Injectable({ providedIn: 'root' })
export class FhirActivityStatusService extends FhirGenericService {

  protected url = routActivityStatus;


}
