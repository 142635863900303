export interface ICampoAdicional {
    IsVisible: boolean;
    IsReadOnly: boolean;
    IsReadOnlyCadastro: boolean;
    Largura: number;
    Order: number;
    ColumnName: string;
    InputMaskType: number;
    VariavelNo: number;
}
