import { Observable } from 'rxjs';

export interface IServiceProvider {
    // Properties
    cadastroNo: number;
    currentDtInicial: Date;
    currentDtFinal: Date;
    cadastrosCache: Observable<any>;
    // Methods
    getAll(ano: number, startDate?: Date, endDate?: Date): Observable<any>;
    loadArray(ano: number): Observable<any>;
    clearCache(): void;
    insertIfNotExist(ano: number, item: any): Observable<any>;
    save(ano: number, item: any, id?: number): Observable<any>;
}