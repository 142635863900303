import { EnSortBy, ICostCenter, ILogin } from '@medlogic/shared/shared-interfaces';
import { ISmartPost, ISmartPostView, ISmartPostComment } from '@medlogic/shared/ui/ui-smart-post';
import { ISmartHeader } from '@medlogic/shared/ui/ui-smart-header';
import { ISmartListImage, EnClipType, ISmartListScore, ISmartListAction, EnStatusAction } from '@medlogic/shared/ui/ui-smart-list';
import { GlobalService, IResponsible, IProfessional, IEducator, IModule, LogService } from '@medlogic/shared/shared-interfaces';
import { ISmartList } from '@medlogic/shared/shared-interfaces';
import { IListItem } from '@medlogic/shared/shared-interfaces';
import { ItemSearchPipe } from '@medlogic/shared/utils';
import { IPerson, IAppMedlogicState, IPersonState } from '@medlogic/medlogic/medlogic-shared-interfaces';

export const selectPerson = (state: IPersonState) => state?.person;
export const selectPersons = (state: IAppMedlogicState) => {
  return [...state?.person?.persons]
    .filter(f => f.nomeResidente && (!state?.appMedlogic?.search || glb.contem(f.nomeResidente, state?.appMedlogic?.search)))
    .sort((a, b) => a?.nomeResidente?.localeCompare(b?.nomeResidente));
};

export const selectCostCenter = (state: IPersonState) =>
({
  ocorrenciaNo: state?.person?.ocorrenciaNo,
  codigo: state?.person?.ocorrenciaNo,
  titulo: state?.person?.nomeResidente,
  habilitado: true,
  codigoHospede: state?.person?.codigo,
} as ICostCenter);

export const selectActivedPersons = (state: IAppMedlogicState) => {
  try {
    let res = [...(state?.person?.persons || [])]
      .filter(f =>
        f.nomeResidente &&
        (!state?.appMedlogic?.search || glb.contem(f.nomeResidente, state?.appMedlogic?.search)) &&
        (
          (state?.appMedlogic?.showInactivated && (f.pacienteFalecido || !f.situacaoHospede)) ||
          (!state?.appMedlogic?.showInactivated && (!f.pacienteFalecido && f.situacaoHospede))
        )
      );
    switch (state?.appMedlogic?.enSortBy) {
      case EnSortBy.CreationDate:
      case EnSortBy.Id:
        res = res.sort((a, b) => Number(a?.prontuario || 0) - Number(b?.prontuario || -1));
        break;
      default:
        res = res.sort((a, b) => a?.nomeResidente?.localeCompare(b?.nomeResidente));
        break;
    }
    return res?.length > 0 ? res : [];
  } catch (error: any) {
    console.log('person.selectors', 'selectActivedPersons', error.message);
  }
  return [];
};
export const selectError = (state: IPersonState) => state?.error;

const glb = new GlobalService();
const log = new LogService();

export function getAge(dtBirthday: Date | string): string | null {
  try {
    const dt = glb.getTypedValue(dtBirthday).value;
    return glb.GetYearsOld(dt)?.toFixed(0).padStart(2, '0');
  } catch (error: any) {
    console.log('person?.selectors', 'getAge', error.message);
  }
  return null;
}

/** Operador personalizado que converte os processos em módulos. */
const toIListItem = ((person: IPerson) => {
  const age = getAge(person?.nascimento);
  return ({
    id: person?.codigo,
    identification1: person?.codigo,
    identification2: person?.nascimento,
    topLeft: person?.nomeResidente,
    bottomLeft: `${age} anos`,
    topRight: glb.DateToddMMYYYY(person?.nascimento),
    bottomRight: (Math.floor(Math.random() * 100) % 2) === 0 ? 'checked' : 'missed',
    color: 'green',
    imgUrl: person?.urlLogo,
    obj: person
  } as IListItem<IPerson>);
})

const toISmartListImage = ((person: IPerson) => {
  const age = getAge(person?.nascimento);
  return ({
    id: person?.codigo,
    imgUrl: person?.urlLogo,
    enClipType: EnClipType.square,
    topLeft: person?.nomeResidente,
    bottomLeft: `${age} anos`,
    iconName: 'child_care',
    iconColor: 'green',
    obj: person
  } as ISmartListImage<IPerson>);
});

const toISmartHeader = ((person: IPerson) => {
  const age = getAge(person?.nascimento);
  return ({
    name: person?.nomeResidente,
    age: `${age} anos`,
    class: person?.nomeSocial,
    teacher: person?.profissao,
    code: 'qrcode',
    imgUrl: person?.urlLogo,
    obj: person,
    id: person?.codigo
  } as ISmartHeader<IPerson>);
});

const toISmartListScore = ((person: IPerson) => {
  const age = getAge(person?.nascimento);
  return ({
    id: person?.codigo,
    score: Math.random() * 10,
    enClipType: EnClipType.circle,
    topLeft: person?.nomeResidente,
    bottomLeft: `${age} anos`,
    iconName: 'list_alt',
    iconColor: 'white',
    obj: person
  } as ISmartListScore<IPerson>);
});

/** Ícones baseados em: https://material.io/resources/icons/?search=delete&style=baseline */
const toISmartListAction = ((person: IPerson) => {
  const st = [
    EnStatusAction.sent,
    EnStatusAction.accepted,
    EnStatusAction.expired,
    EnStatusAction.rejected,
  ];
  const enStatus = st[Math.floor(Math.random() * 3)];
  let iconName: string;
  let status: string;
  let statusColor: string;
  switch (enStatus) {
    case EnStatusAction.accepted:
      iconName = 'check';
      status = 'aceito';
      statusColor = 'green';
      break;
    case EnStatusAction.expired:
      iconName = 'check';
      status = 'convite expirado';
      statusColor = 'gray';
      break;
    case EnStatusAction.rejected:
      iconName = 'check';
      status = 'convite rejeitado';
      statusColor = 'red';
      break;
    case EnStatusAction.sent:
      iconName = 'check';
      status = 'convite enviado';
      statusColor = 'blue';
      break;
  }
  return ({
    id: person?.codigo,
    topLeft: person?.nomeResidente,
    bottomLeft: status,
    iconName: 'school',
    iconColor: 'white',
    statusIconColor: statusColor,
    statusIconName: status,
    enStatusAction: enStatus,
    actionTitle: '',
    obj: person
  } as ISmartListAction<IPerson>);
});

export const selectPersonsAsIListItems = (state: any) => {
  return state?.person?.persons?.map(toIListItem);
};

export const selectPersonsAsISmartLists = (state: any) => {
  return [
    { title: 'Smartlist', items: selectPersonsAsIListItems(state) } as ISmartList
  ];
};

export const selectPersonsAsISmartListImages = (state: any) => {
  let items = state?.person?.persons?.map(toISmartListImage);
  items = filterBySearch(items, state?.appMedlogic?.search);
  return [
    { title: 'Lista de Alunos', items } as ISmartList<ISmartListImage>
  ];
};

export const selectPersonsAsISmartListScores = (state: any) => {
  return [
    { title: 'Smartlist score', items: state?.person?.persons?.map(toISmartListScore) } as ISmartList<ISmartListScore>
  ];
};

export const selectPersonsAsISmartListActions = (state: any) => {
  return [
    { title: 'Opções', items: state?.person?.persons?.map(toISmartListAction) } as ISmartList<ISmartListAction>
  ];
};

export const selectPersonAsISmartHeader = (state: any) => {
  const person = state?.person?.person;
  if (!person) {
    return null;
  }
  return [person].map(toISmartHeader)[0];
};

const toISmartPost = ((person: IPerson) => {
  const comments = [
    { autorName: 'Michel Smith', dtCreated: new Date(), content: 'Aliquip elit qui adipisicing cillum in eiusmod aliquip. Velit anim laboris ea exercitation non. Tempor commodo cupidatat et ipsum occaecat velit.' } as ISmartPostComment,
    { autorName: 'Sara Blanket', dtCreated: new Date(), content: 'Aliquip elit qui adipisicing cillum in eiusmod aliquip. Velit anim laboris ea exercitation non. Tempor commodo cupidatat et ipsum occaecat velit.' } as ISmartPostComment,
    { autorName: 'Michel Smith', dtCreated: new Date(), content: 'Aliquip elit qui adipisicing cillum in eiusmod aliquip. Velit anim laboris ea exercitation non. Tempor commodo cupidatat et ipsum occaecat velit.' } as ISmartPostComment,
    { autorName: 'Cours Couch', dtCreated: new Date(), content: 'Aliquip elit qui adipisicing cillum in eiusmod aliquip. Velit anim laboris ea exercitation non. Tempor commodo cupidatat et ipsum occaecat velit.' } as ISmartPostComment,
    { autorName: 'Michel Smith', dtCreated: new Date(), content: 'Aliquip elit qui adipisicing cillum in eiusmod aliquip. Velit anim laboris ea exercitation non. Tempor commodo cupidatat et ipsum occaecat velit.' } as ISmartPostComment,
  ];
  const views = [
    { name: 'Michel Smith', dtCreated: new Date() } as ISmartPostView,
    { name: 'Sara Blanket', dtCreated: new Date() } as ISmartPostView,
  ];
  return ({
    autorName: person?.nomeResidente,
    autorImgUrl: person?.urlLogo,
    content: 'Ipsum aliqua magna ex quis. Quis eu consequat adipisicing nostrud. Elit nostrud id esse aute aute aliqua cupidatat incididunt deserunt anim sint esse excepteur. Ipsum veniam esse officia voluptate velit id. Ullamco est magna quis amet est irure tempor fugiat pariatur et et occaecat veniam sint.',
    dtCreated: new Date(),
    imgUrl: 'https://picsum.photos/400/250',
    imgAlt: 'Foto de uma criança',
    obj: person,
    id: person?.codigo,
    comments,
    views
  } as ISmartPost<IPerson>);
});

const toISmartListActionResponsible = ((responsible: IResponsible): ISmartListAction<IResponsible> => {
  const st = [
    EnStatusAction.sent,
    EnStatusAction.accepted,
    EnStatusAction.expired,
    EnStatusAction.rejected,
  ];
  // const enStatus = st[Math.floor(Math.random() * 3)];
  const enStatus = responsible.status;
  let iconName: string;
  let status = '';
  let statusColor = '';
  switch (enStatus) {
    case EnStatusAction.accepted:
      iconName = 'check';
      status = 'aceito';
      statusColor = 'green';
      break;
    case EnStatusAction.expired:
      iconName = 'check';
      status = 'convite expirado';
      statusColor = 'gray';
      break;
    case EnStatusAction.rejected:
      iconName = 'check';
      status = 'convite rejeitado';
      statusColor = 'red';
      break;
    case EnStatusAction.sent:
      iconName = 'check';
      status = 'convite enviado';
      statusColor = 'blue';
      break;
  }
  return ({
    id: responsible.codigo,
    topLeft: responsible.nome,
    bottomLeft: status,
    iconName: 'school',
    iconColor: 'white',
    statusIconColor: statusColor,
    statusIconName: status,
    enStatusAction: enStatus,
    actionTitle: '',
    obj: responsible
  } as ISmartListAction<IResponsible>);
});

const toISmartListActionProfessional = ((professional: IProfessional): ISmartListAction<IProfessional> => {
  const st = [
    EnStatusAction.sent,
    EnStatusAction.accepted,
    EnStatusAction.expired,
    EnStatusAction.rejected,
  ];
  // const enStatus = st[Math.floor(Math.random() * 3)];
  const enStatus = professional.status;
  let iconName: string;
  let status = '';
  let statusColor = '';
  switch (enStatus) {
    case EnStatusAction.accepted:
      iconName = 'check';
      status = 'aceito';
      statusColor = 'green';
      break;
    case EnStatusAction.expired:
      iconName = 'check';
      status = 'convite expirado';
      statusColor = 'gray';
      break;
    case EnStatusAction.rejected:
      iconName = 'check';
      status = 'convite rejeitado';
      statusColor = 'red';
      break;
    case EnStatusAction.sent:
      iconName = 'check';
      status = 'convite enviado';
      statusColor = 'blue';
      break;
  }
  return ({
    id: professional.codigo,
    topLeft: professional.nome,
    bottomLeft: status,
    iconName: 'school',
    iconColor: 'white',
    statusIconColor: statusColor,
    statusIconName: status,
    enStatusAction: enStatus,
    actionTitle: '',
    obj: professional
  } as ISmartListAction<IProfessional>);
});

const toISmartListActionEducator = ((educator: IEducator): ISmartListAction<IEducator> => {
  const st = [
    EnStatusAction.sent,
    EnStatusAction.accepted,
    EnStatusAction.expired,
    EnStatusAction.rejected,
  ];
  //  const enStatus = st[Math.floor(Math.random() * 3)];
  const enStatus = educator.status;
  let iconName: string;
  let status = '';
  let statusColor = '';
  switch (enStatus) {
    case EnStatusAction.accepted:
      iconName = 'check';
      status = 'aceito';
      statusColor = 'green';
      break;
    case EnStatusAction.expired:
      iconName = 'check';
      status = 'convite expirado';
      statusColor = 'gray';
      break;
    case EnStatusAction.rejected:
      iconName = 'check';
      status = 'convite rejeitado';
      statusColor = 'red';
      break;
    case EnStatusAction.sent:
      iconName = 'check';
      status = 'convite enviado';
      statusColor = 'blue';
      break;
  }
  return ({
    id: educator.codigo,
    topLeft: educator.nome,
    bottomLeft: status,
    iconName: 'school',
    iconColor: 'white',
    statusIconColor: statusColor,
    statusIconName: status,
    enStatusAction: enStatus,
    actionTitle: '',
    obj: educator
  } as ISmartListAction<IEducator>);
});

export const selectPersonAsISmartPost = (state: any) => {
  const person = state?.person?.person;
  if (!person) {
    return null;
  }
  return [person].map(toISmartPost)[0];
};

export const selectResponsiblesAsISmartListActions = (state: any): ISmartList<ISmartListAction<IResponsible>>[] => {
  let items = state?.person?.person?.responsaveis?.map(toISmartListActionResponsible);
  items = filterBySearchSmartList(items, state?.appMedlogic?.search);
  const res = [
    { title: 'Responsáveis', items } as ISmartList<ISmartListAction>
  ];
  return res;
};

export const selectProfessionalsAsISmartListActions = (state: any): ISmartList<ISmartListAction<IProfessional>>[] => {
  let items = state?.person?.person?.profissionais?.map(toISmartListActionProfessional)
  items = filterBySearchSmartList(items, state?.appMedlogic?.search);
  const res = [
    { title: 'Profissionais', items } as ISmartList<ISmartListAction>
  ];
  return res;
};

export const selectEducatorsAsISmartListActions = (state: any): ISmartList<ISmartListAction<IEducator>>[] => {
  let items = state?.person?.person?.educadores?.map(toISmartListActionEducator);
  items = filterBySearchSmartList(items, state?.appMedlogic?.search);
  const res = [
    { title: 'Educadores', items } as ISmartList<ISmartListAction>
  ];
  return res;
};

const filterBySearch = (items: ISmartListImage<IModule>[], keyword: string) => {
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

const filterBySearchSmartList = (items: ISmartList<IModule>[], keyword: string) => {
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

export const selectPersonDefaultFormControls = (state: IAppMedlogicState): any => {
  // return {
  //   V_102303: (state?.login?.selectedLogin as ILogin)?.customerId,
  //   V_103635: state?.tenant?.login?.usuarioLogadoNome || '',
  //   V_391: glb.dateToYYYYMMddThhmmss(new Date()),
  // };
  return {
    V_387: state?.person?.person?.nomeResidente,
    V_28051: state?.person?.person?.prontuario,
    V_102303: (state?.login?.selectedLogin as ILogin)?.customerId,
    V_103635: state?.tenant?.login?.usuarioLogadoNome || '',
    V_391: glb.DateToddMMYYYY(glb.getTypedValue(state?.person?.person?.nascimento).value),
    V_1608: state?.person?.person?.nomemae,
    V_28830: glb.dateToYYYYMMddThhmmss(new Date()),
    V_28831: glb.dateTohhmmss(new Date()),
    V_32939: state?.person?.person?.prontuario,
    // V_29828: state?.person?.person?.prontuario,
  };
};

