<div [class.isMobile]="isMobile" [class.black]="enTheme === 'black'">

  <button mat-button type="button" class="btn btn-block" (click)="onBack($event)">
    < Voltar</button>

</div>

<div class="wrapper wrapper-content animated fadeInRight" [class.isMobile]="isMobile">

  <lib-atividade-cadastro-view [atividadeNo]="atividadeNo" [ocorrenciaNo]="ocorrenciaNo" [processoNo]="processoNo"
    [defaultFormControls]="defaultFormControls" [enTheme]="enTheme" [isReadOnly]="false" [saveInList]="true"
    [isMobile]="isMobile" (onErpRefresh)="onRefresh($event)" (onBack)="onGeBack($event)"
    (eventAfterCompleted)="onAfterCompleted($event)">
  </lib-atividade-cadastro-view>

</div>