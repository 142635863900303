import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ui-empty-items',
  templateUrl: './empty-items.component.html',
  styleUrls: ['./empty-items.component.css'],
})
export class EmptyItemsComponent implements OnInit {

  @Input() numOfItems = 5;

  emptyItems: number[] = [];

  ngOnInit() {
    for (let i = 0; i < this.numOfItems; i++) {
      this.emptyItems.push(i);
    }
  }

}
