import { ConfigStateService } from '@medlogic/shared/state-config';
import { EnumAtividadeTipo } from '../../shared/enum/enum-atividade-tipo.enum';
import { AtividadeViewComponent } from '../atividade-view/atividade-view.component';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EnBubbleEvent } from '../../shared/enum/en-bubble-event.enum';
import { ActivatedRoute } from '@angular/router';
import { AtividadeDAL } from '../../shared/model/dal/atividade-dal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AtividadeComponenteDAL } from '../../shared/model/dal/atividade-componente-dal';
import { CalculadoraService } from '../../shared/service/calculadora.service';
import { DadoDAL } from '../../shared/model/dal/dado-dal';
import { CalculatorService } from '../../shared/service/calculator.service';
import { ValidatorService } from '../../shared/service/validator.service';
import { ActionService } from '../../shared/service/action.service';
import { CalculadoraConditionService } from '../../shared/service/calculadora-condition.service';
import { NavigationService } from '../../shared/service/navigation.service';
import { LibService } from '../../shared/service/lib.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UsuarioDAL } from '@medlogic/shared/shared-data-access';
import { ProcessoDAL } from '../../shared/model/dal/processo-dal';
import { MatDialog } from '@angular/material/dialog';
import { mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { GlobalService, ConfigJsonService, IAtividadeComponenteDAL, IOcorrencia } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { OcorrenciaDAL } from '../../shared/model/dal/ocorrencia-dal';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { WsTrackerService } from '@medlogic/shared/shared-data-access';
import { ExecucaoTarefaDAL } from '../../shared/model/dal/execucao-tarefa-dal';
import { EnActivityType } from '@medlogic/shared/gecore';
import { IAtividade } from '../../shared/interface/iatividade';

/* Essa classe é executada quando ocorre a Atividade é do tipo Cadastro.
 * A princípio todas as atividades do ERP são deste tipo, ou Cadastro-detail.
 * Atividades não associadas a cadastro existirão apenas quando houver a possibilidade de execução
 * de um fluxo completo do GE.
 */
// Comentado, pois herdará da AtividadeViewComponent
// remover o comentário apenas se necessário personalizar html e/ou css
// se precisar apenas um dos dois, aponte o outro para o arquivo físico do pai
@Component({
  selector: 'lib-atividade-cadastro-view',
  templateUrl: './atividade-cadastro-view.component.html',
  styleUrls: ['./../atividade-view/atividade-view.component.css'], // atividade-cadastro-view.component.css']
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtividadeCadastroViewComponent extends AtividadeViewComponent implements OnInit {

  backOcorrenciaNo: number;
  backAtividadeNo: number;

  // tslint:disable-next-line: variable-name
  private _enableBack: boolean;
  public get enableBack(): boolean {
    if (this._enableBack) {
      return this._enableBack;
    }
  }
  public set enableBack(v: boolean) {
    this._enableBack = v;
  }

  constructor(
    global: GlobalService,
    config: ConfigStateService,
    cnfJson: ConfigJsonService,
    route: ActivatedRoute,
    log: LogService,
    ocorrenciaDAL: OcorrenciaDAL,
    atividadeDAL: AtividadeDAL,
    fb: FormBuilder,
    atividadeComponenteDAL: AtividadeComponenteDAL,
    calc: CalculadoraService,
    dadoDAL: DadoDAL,
    calculator: CalculatorService,
    validator: ValidatorService,
    action: ActionService,
    calcCond: CalculadoraConditionService,
    navigation: NavigationService,
    lib: LibService,
    confirmationService: ConfirmationService,
    wsTracker: WsTrackerService,
    execucaoTarefa: ExecucaoTarefaDAL,
    usuarioDAL: UsuarioDAL,
    processoDAL: ProcessoDAL,
    matDialog: MatDialog,
    msg: MsgPtBR,
    messageService: MessageService,
  ) {
    super(
      global,
      config,
      cnfJson,
      route,
      log,
      ocorrenciaDAL,
      atividadeDAL,
      fb,
      atividadeComponenteDAL,
      calc,
      dadoDAL,
      calculator,
      validator,
      action,
      calcCond,
      navigation,
      lib,
      confirmationService,
      wsTracker,
      execucaoTarefa,
      usuarioDAL,
      processoDAL,
      matDialog,
      msg,
      messageService,
    );
    this.activityType = EnActivityType.List;
  }

  /* override  */
  ngOnInit(): void {
    try {
      super.ngOnInit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* override
   * Recarrega a tela  */
  protected refresh(
    enAtividadeTipo: EnumAtividadeTipo,
    ano: number,
    ono: number,
    uno: number,
    defaultFormControls: any,
    addToHistory: boolean = true,
    pno: number = -1,
    tno: number = -1
  ): Observable<IAtividade> {
    try {
      this.isEditMode = ono > 0;
      this.enableBack = this.isEditMode;
      this.trackLoading(ano);
      // Permitirá que fórmulas, controles, etc se inscrevam para serem notificadas sobre o momento da criação de uma ocorrência.
      this.config.observableNewOcorrenciaNo = this.criarOcorrenciaSeNecessario(ono);
      const superRefresh = (
        parEnAtividadeTipo: EnumAtividadeTipo,
        parAno: number,
        parUno: number,
        parDefaultFormControls: any,
        parAddToHistory: boolean,
        parPno: number) => mergeMap((newOno: number) => {
          this.config.OcorrenciaNo.next(newOno);
          this.ocorrenciaNo = newOno; // Necessário para atualizar o rodapé com a nova ocorrência
          // Disparará o load dos controles somente após a ocorrencia ter sido criada e atividade carregada.
          return super.refresh(parEnAtividadeTipo, parAno, newOno, parUno, parDefaultFormControls, parAddToHistory, parPno);
        });
      const afterRefresh = () => tap((atividade: IAtividade) => {
        this.atividade = atividade;
        this.atividadeNome = atividade.Nome;
        if (addToHistory) {
          const route = this.navigation.getRouteListEdit(
            atividade.AtividadeNo,
            atividade.OcorrenciaNo,
            false,
            this.saveInList
          );
          this.navigation.addToHistory(
            route,
            atividade && atividade.Nome ? atividade.Nome : this.msg.MODULE_NAME_ATIVIDADE
          );
        }
      });
      // Pipe principal. Se o processoNo fornecido, descobrirá a atividade por ele.
      // A atividade, quando descoberta por meio do processo, possui propriedades adicionais
      return this.config.observableNewOcorrenciaNo
        .pipe(
          superRefresh(enAtividadeTipo, ano, uno, defaultFormControls, addToHistory, pno),
          afterRefresh(),
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  // override
  /* Exclui um item do cadastro */
  protected listDelete(
    $event: any,
    ano: number,
    ono: number,
    tno: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[]
  ): void {
    try {
      this.subs.sink = this.execucaoTarefa
        .deleteCadastro(ono, ano)
        .pipe(
          mergeMap((deleteItem) => {
            this.afterDelete(
              EnBubbleEvent.listDelete,
              this.tarefaNo,
              ano,
              ono,
              this.config.usuarioLogadoNo,
              -1,
              this.componentes
            );
            return of(deleteItem);
          })
        )
        .subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listDelete', error.message);
    }
  }

  // override
  /* Emite um evento para a edição, por sua vez, será aberta a janela de diálogo com a edição.
  */
  protected listDetailEdit(
    $event: any,
    ano: number,
    ono: number,
    listvno: number,
    isReadOnly: boolean,
    saveInList: boolean
  ): void {
    try {
      this.subs.sink = this.salvar(
        EnBubbleEvent.activitySave,
        this.componentes,
        this.tarefaNo,
        this.config.OcorrenciaNo.value,
        this.config.usuarioLogadoNo,
        this.config.processoNo
      ).subscribe((result) => {
        this.tabActivedId = 0;
        // this.openListDetailEditDialog(ano, ono, listvno, isReadOnly, saveInList, this.componentes);
        // this.navigation.gotoListDetailEdit(ano, ono, listvno, isReadOnly, saveInList);
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listEdit', error.message);
    }
  }

  // /* Abre a edição da atividade filha (detail), num dialog. */
  // openListDetailEditDialog(
  // 	ano: number,
  // 	ono: number,
  // 	listvno: number,
  // 	isReadOnly: boolean,
  // 	saveInList: boolean,
  // 	componentes: IAtividadeComponenteDAL[]
  // ): any {
  // 	try {
  // 		const gridCtrl = componentes.find((f) => f.VariavelNo === listvno);
  // 		const width = '100%';
  // 		const height = '650px';
  // 		const dialogRef = this.matDialog.open(AtividadeCadastroDetailViewComponent, {
  // 			width,
  // 			height,
  // 			data: {
  // 				ano,
  // 				ono: ono,
  // 				listvno: listvno,
  // 				isReadOnly: isReadOnly,
  // 				saveInList: saveInList,
  // 				defaultFormControls: this.config.getDefaultFormControls(ano),
  // 				lstCadastroAdicional: gridCtrl.lstCadastroAdicional
  // 			}
  // 		});
  // 		dialogRef.afterClosed().subscribe((result) => {
  // 			try {
  //         // Necessário atualizar os parâmetros de config novamente.
  //         // TODO: Nesse ponto, necessário identificar como que o change será disparado para atualização de fórmulas.
  //         this.config.OcorrenciaNo.next(this.ocorrenciaNo);
  // 				// if (result) {
  // 				// 	const editedCtrl = this.componentes.find((f) => f.VariavelNo === result.listvno);
  // 				// 	if (editedCtrl && result.lstCadastroAdicional) {
  // 				// 		switch (editedCtrl.Type) {
  // 				// 			case 'EditorAtividade.ctrComboBox':
  // 				// 			case 'EditorAtividade.ctrGrid':
  // 				// 				editedCtrl.lstCadastroAdicional = this.updateGridItems(
  // 				// 					editedCtrl,
  // 				// 					editedCtrl.lstCadastroAdicional,
  // 				// 					result.lstCadastroAdicional
  // 				// 				);
  // 				// 				break;
  // 				// 		}
  // 				// 	}
  // 				// }
  // 			} catch (error) {
  // 				this.log.Registrar(this.constructor.name, 'openListDetailEditDialog.afterClosed', error.message);
  // 			}
  // 		});
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'openListDetailEditDialog', error.message);
  // 	}
  // }

  /*Verifica se existe um item modificado (retorno de edição de item de grid) e atualiza o valor caso haja
  * Quando um item de grid é acrescentado ou editado, a propriedade config.gridItems é preenchida.
  * A partir dela é que se deve criar ou modificar o grid.
  * atividadeCadastroAssociadaNo: É o cadastro associado ao grid.
  * gridItemsFromChildActivity: são os itens de transporte de edição/inserção de item, que estão retornando para o grid principal.
  */
  protected updateGridItems(
    ctrl: IAtividadeComponenteDAL,
    currentItems: any[],
    gridItemsComponentes: IAtividadeComponenteDAL[]
  ): any[] {
    try {
      // ctrl.Valor = null; // Necessário para que o change funcione
      // ctrl.ValorTexto = '<Items></Items>';
      // Necessário zerar o valor texto, ou se forem excluídos
      // todos os itens, a lógica em lib.getValorTextoOrData fará com que o ValorTexto seja novamente carregado.
      // if (!this.global.IsArrayNullOrEmpty(gridItemsComponentes)) {
      // 	gridItemsComponentes.forEach((gridItem) => {
      // 		try {
      // 			// gridItem['label'] = this.lib.extractLabel(ctrl, gridItem);
      // 			// Novo item
      // 			currentItems = currentItems || [];
      // 			const find = currentItems.find(
      // 				// tslint:disable: no-bitwise
      // 				// tslint:disable-next-line: triple-equals
      // 				(f) => (f.index | f.OcorrenciaNo) == (gridItem.OcorrenciaNo | gridItem.index)
      // 			);
      // 			if (!find) {
      // 				// Novo item
      // 				currentItems.push(gridItem);
      // 			} else {
      // 				// Edição de item
      // 				const index = gridItem.OcorrenciaNo | gridItem.index;
      // 				currentItems.forEach((f) => {
      // 					try {
      // 						if ((f.index | f.OcorrenciaNo) === index) {
      // 							f.index = f.OcorrenciaNo = index;
      // 							for (const clm in f) {
      // 								if (clm && isNaN(f)) {
      // 									// f Não pode ser um campo puramente numérico, pois irá compor uma tag xml no futuro, que não pode iniciar com número.
      // 									const value = gridItem[clm];
      // 									f[clm] = this.global.getTypedValue(value).string;
      // 								}
      // 							}
      // 						}
      // 					} catch (error) {
      // 						this.log.Registrar(
      // 							this.constructor.name,
      // 							'addGridItemFromMemory.forEach.forEach',
      // 							error.message
      // 						);
      // 					}
      // 				});
      // 			}
      // 		} catch (error) {
      // 			this.log.Registrar(this.constructor.name, 'addGridItemFromMemory.forEach', error.message);
      // 		}
      // 	});
      // 	// O problema é que o item que retorna da atividade nova
      // não é reacrescentado no ValorTexto de imediato.
      // Se o item for limpo, o novo substituirá o anterior.
      // Se nao for limpo, mesmo apos uma exclusao, um novo item retornará com os excluídos.
      // 	// this.config.getGridItems(this.ctrl.AtividadeCadastroNo) = null;
      // Não pode ser zerado, ou somente o mais recente item é incorporado
      // }
      // return currentItems;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addGridItemFromMemory', error.message);
    }
    return null;
  }

  // override
  /* Roteia para a criação de um novo item do tipo Cadastro. Salva os dados da Atividade atual antes. */
  protected listDetailNew(ano: number, listvno: number, saveInList: boolean): void {
    try {
      this.subs.sink = this.salvar(
        EnBubbleEvent.activitySave,
        this.componentes,
        this.tarefaNo,
        this.config.OcorrenciaNo.value,
        this.config.usuarioLogadoNo,
        this.config.processoNo
      ).subscribe((result) => {
        this.tabActivedId = 0;
        // this.config.OcorrenciaNo.next(-1); // Zera a Ocorrência para que uma nova seja criada.
        // this.openListDetailEditDialog(ano, this.config.OcorrenciaNo, listvno, false, saveInList, this.componentes);
        // this.navigation.gotoListDetailNew(ano, listvno, saveInList);
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listNew', error.message);
    }
  }

  /* Além de salvar a atividade atual, promoverá um refresh da mesma atividade e cria nova ocorrencia. */
  protected listDetailSaveAndNew(
    ano: number,
    saveInList: boolean,
    addToHistory: boolean = true,
    preserveValues: boolean = true
  ): void {
    try {
      this.subs.sink = this.salvar(
        EnBubbleEvent.activitySave,
        this.componentes,
        this.tarefaNo,
        this.config.OcorrenciaNo.value,
        this.config.usuarioLogadoNo,
        this.config.processoNo
      ).pipe(
        mergeMap((s) => {
          this.tabActivedId = 0;
          this.config.OcorrenciaNo.next(-1); // Zera a Ocorrência para que uma nova seja criada.
          // this.navigation.gotoListDetailNew(ano, saveInList);
          return this.refresh(
            // Chamar o roteamento não é suficiente, pois, há casos que a rota é a mesma e, portanto, os parâmetros não são atualizados.
            EnumAtividadeTipo.Editar,
            this.config.ModeloAtividadeNo,
            this.config.OcorrenciaNo.value,
            this.config.usuarioLogadoNo,
            this.config.getDefaultFormControls(ano),
            addToHistory,
            this.config.processoNo
          );
        })
      ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'listDetailSaveAndNew', error.message);
    }
  }

  /*Evento chamado após a conclusão do salvamento da atividade.
   * override
  */
  // protected afterSave(
  //   enBubbleEvent: EnBubbleEvent,
  //   tno: number,
  //   ano: number,
  //   ono: number,
  //   uno: number,
  //   pno: number,
  //   componentes: Array<IAtividadeComponenteDAL>
  // ): Observable<IOcorrencia> {
  //   try {
  //     // this.fillListItemToTransportValues(ano, ono, componentes);
  //     return super.afterSave(enBubbleEvent, tno, ano, ono, uno, pno, componentes);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'afterSave', error.message);
  //   }
  //   return of(null);
  // }

  /* override
  * Evento chamado após a o salvamento da atividade. Poderá ser sobrescrito.
  */
  protected afterSave(
    enBubbleEvent: EnBubbleEvent,
    tno: number,
    ano: number,
    ono: number,
    uno: number,
    pno: number,
    componentes: IAtividadeComponenteDAL[],
    fg: FormGroup,
    isEditMode: boolean
  ): Observable<IOcorrencia> {
    try {
      return super.afterSave(enBubbleEvent, tno, ano, ono, uno, pno, componentes, fg, isEditMode)
        .pipe(
          this.error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSave', error.message);
    }
    return of(null);
  }

  // protected afterComplete(
  //   ocorrencia: IOcorrencia
  // ): Observable<IOcorrencia> {
  //   return super.afterComplete(ocorrencia)
  //     .pipe(
  //       tap((o: any) => {
  //         return this.emitAfterCompleted(o.TarefaNo, o.AtividadeNo, o.OcorrenciaNo, this.config.usuarioLogadoNo, o.ProcessoNo);
  //       }),
  //       this.error()
  //     );
  // }

  /*Preenche a propriedade que será utilizada para modificar o valor da combobox chamadora. */
  // protected fillListItemToTransportValues(
  // 	ano: number,
  // 	ono: number,
  // 	componentes: Array<IAtividadeComponenteDAL>
  // ): void {
  // 	try {
  // 		if (this.config.listItem && this.config.listItem[ano]) {
  // 			// já deve existir e conter como id a variavelNo da Combobox que está sendo editada/nova
  // 			// Atenção esse preenchimento levará outras variáveis,
  // além daquela que é a original da combobox em edição para serem atualizadas na atividade chamadora (pai)
  // 			componentes.forEach((e) => (this.config.listItem[ano][this.lib.getId(e.VariavelNo)] = e.ValorTexto));
  // 			this.config.listItem[ano].index = ono;
  // 			// É necessário estabelecer o valor que retornará para
  //  preencher uma combobox, quando se tratar de um novo item
  // e não houver coincidência entre o variavelNo do título no Cadastro e na combobox.
  // 			const cadastroTitulo = componentes.find((f) => f.TypeRegister === 2);
  // 			this.config.listItem[ano].value = cadastroTitulo ? cadastroTitulo.ValorTexto : '';
  // 		}
  // 	} catch (error) {
  // 		this.log.Registrar(this.constructor.name, 'fillListItemToTransportValues', error.message);
  // 	}
  // }

  /*Verifica se a Ocorrencia é -1 (nova), ou então se é existente */
  newOrExists(): boolean {
    try {
      // return (ono === -1) || (this.componentes && this.componentes.length > 0);
      // O ocorrenciaNo é iniciado com -1 mas essa resposta não pode ser verdadeira até que os componentes sejam carregados
      return this.componentes && this.componentes.length > 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'newOrExists', error.message);
    }
  }


  //    /* FIXME: conceitualmente esse método não deveria existir aqui que é Atividade Cadastro e não processo
  //   * Realiza a conclusão da Atividade.
  //   * Nesse componente, deverá emitir um evento a ser capturado por quem utilizar esse componente. */
  //  protected doActivityComplete(tno: number, ano: number, ono: number, uno: number, pno: number): Observable<IPasso> {
  //   try {
  //     this.isLoadingAfterComplete = false;
  //     this.isLoading = false;
  //     return this.ocorrenciaDAL
  //       .setOcorrenciaNotificarConclusaoSalvamento(pno, ono, uno, ano)
  //       .pipe(
  //         mergeMap((result) => {
  //           // TODO: checar porque Variaveis de identificação não estão sendo preenchidas
  //           const varId1 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao1No}`);
  //           const varId2 = this.formGroup.get(`V_${this.atividade.CalcVariavelIdentificacao2No}`);
  //           this.atividade.CalcVariavelIdentificacao1Valor = varId1 ? varId1.value : -1;
  //           this.atividade.CalcVariavelIdentificacao2Valor = varId2 ? varId2.value : -1;
  //           this.atividade.OcorrenciaNo = ono;
  //           this.atividade.TarefaNo = tno;
  //           return this.processoDAL
  //             .getProximoPasso(this.atividade, pno, uno, tno, ono)
  //             .pipe(
  //               map((proximoPasso) => {
  //                 // FIXME: Idealmente, essa deveria ser uma chamada assíncrona, retornando também observable
  //                 // this.executeNextStep(this.atividade, proximoPasso);
  //                 this.isLoadingAfterComplete = false;
  //                 const obj = {
  //                   values: this.formGroup.getRawValue(),
  //                   tno,
  //                   ano,
  //                   ono,
  //                   uno,
  //                   pno
  //                 };
  //                 this.afterCompleted.emit(obj);
  //                 return proximoPasso;
  //               })
  //             );
  //         })
  //       );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'doActivityComplete', error.message);
  //   }
  //   return of(null);
  // }
}
