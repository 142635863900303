import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UtilsEmailEffects } from './+state-email/state-utils-email.effects';
import * as fromUtilsEmail from './+state-email/state-utils-email.reducer';


const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromUtilsEmail.utilsEmailFeatureKey, fromUtilsEmail.reducer),
  EffectsModule.forFeature([UtilsEmailEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
})
export class StateUtilsModule { }
