import { IPersonRelationship } from './iperson-relationship';

export interface IPersonResponsible extends IPersonRelationship {
  idResponsavel: number;
  // ocorrenciaNo: number;
  // idPessoa:  number;
  // codigo:  any;
  // titulo:  string;
  // habilitado:  boolean;
  // idStatus:  string;
  // idUsuario: number;
}
