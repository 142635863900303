
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  LocalLibService, IEstoqueMaterial, EnContentType, EnRequestType, GlobalService, LogService,
  routePostManyMedticationsOnStock, IPostDropMedicationStockRequest
} from '@medlogic/shared/shared-interfaces';
import {
  map, publishReplay, refCount, filter, toArray
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CadastroService, ModelComponent, BasePageService } from '@medlogic/shared/shared-data-access';
import { NovoEstoqueDeMateriaisService } from './novo-estoque-de-materiais.service';
@Injectable({
  providedIn: 'root'
})
export class NovoEstoqueDeMateriaisCustomService extends NovoEstoqueDeMateriaisService {

  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    log: LogService,
    protected lib: LocalLibService,
    protected modelComponent: ModelComponent,
    private basepage: BasePageService
  ) {
    super(http, cadastroSrv, glb, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  protected readonly urlPostManyMedticationsOnStock = `${routePostManyMedticationsOnStock}`;

  /**
   * Retorna os dados do estoque de materiais de um paciente em um período.
   * @param cadastroNo Número do cadastro.
   * @param strFilter Filtro.
   * @param dtStart Data de inicio do período.
   * @param dtEnd Data de fim do período.
   * @returns Observable com os dados do estoque de materiais.
   */
  getByFilterAndPeriod(cadastroNo: number, strFilter: string, lstVariables: string = null, dtStart: Date = null, dtEnd: Date = null): Observable<IEstoqueMaterial> {
    try {
      this.cadastroNo = cadastroNo;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);

      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByIdAndPeriod', error.message);
    }
    return of(null);
  }

  /**
   * Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`.
   * Também filtra especificamente a dataavaliacao dentro do período.
   * @param {number} cadastroNo Número do cadastro.
   * @param {string} strFilter Query para filtrar os dados.
   * @param {Date} startDate Data inicial. Se não for fornecida, será 01/01/1900.
   * @param {Date} endDate Data final. Se não for fornecida, será 01/01/2500.
   * @returns {Observable<IEstoqueMaterial>} - Observable com os dados.
   */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IEstoqueMaterial> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);

      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, lstVariables || this.lstVariaveis, strFilter, false)
        .pipe(
          map(c => this.toAttribute(c)),
          filter((f: IEstoqueMaterial) => this.glb.isBetweenIgnoreTime(f.dataAtual, startDate, endDate)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /**
   * Salva todos os medicamentos que serão atualizados no estoque.
   * @param {IEstoqueMaterial[]} movimentacao - Lista dos medicamentos.
   * @returns {Observable<any>} - Observable com o resultado da operação.
   */
  saveAll(
    movimentacao: IEstoqueMaterial[]
  ): Observable<any> {
    try {
      const url = this.basepage.format(this.urlPostManyMedticationsOnStock);
      const forms: any[] = [];

      movimentacao.map(m => {
        forms.push({
          id: m.codigo,
          form: this.mapToForm(m).filter(f => f.ValorDado)
        });
      });

      const data = {
        ano: this.cadastroNo,
        forms,
      } as IPostDropMedicationStockRequest

      return this.basepage
        .baseDadosReplay(EnRequestType.Post, url, data, 2, EnContentType.Json)
        .pipe(
          map(m => m?.count || -1)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'saveAll', error.message);
    }
    return of(-1);
  }

  sortByDate = () => map((v: IEstoqueMaterial[]) =>
    v.sort(
      (a, b) =>
        b.dataAtual && a.dataAtual
          ? this.glb.compareDates(a.dataAtual, b.dataAtual)
          : 0
    )
  )

  /* OBS: Usado apenas pelo card-prescription. */
  getFromCadastroFiltro(cadastroNo: number, filtro: string, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);

      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true, startDate, endDate)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  /* Check se está fora da referência. */
  protected isOutOfRef(value: number, minRef: number, maxRef: number): boolean {
    try {
      return value < minRef || value > maxRef;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isOutOfRef', error.message);
    }
    return false;
  }

  mapObjToMovimentacaoDeLeito(obj: { [key: string]: string | Date | number }): Observable<IEstoqueMaterial> {
    try {
      return of(obj).pipe(this.mapTo());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapObjToVitalSign', error.message);
    }
    return of(null);
  }

  private mapTo = () => map((c: any) => this.toAttribute(c));

  getLocationByMedlogicId(medlogicId): Observable<any> {
    try {
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const filter = `V_28051:${medlogicId}`
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate)
        .pipe(
          map(a => a),
          toArray(),
          map((mov: any) => {
            let latestMov = {};

            for (let i = 0; i < mov.length; i += 1) {
              const prontuarioMEDLOGIC = mov[i].prontuarioMEDLOGIC;
              const date = new Date(mov[i].dataMovimentacao);
              const andar = mov[i].andar;
              const bloco = mov[i].bloco;
              const quarto = mov[i].quarto;

              if ((!latestMov[prontuarioMEDLOGIC] || latestMov[prontuarioMEDLOGIC].date < date)) {
                latestMov[prontuarioMEDLOGIC] = { prontuarioMEDLOGIC, date, andar, bloco, quarto };
              }
            }

            return Object.values(latestMov);
          }),
          map(array => array[0]),
          publishReplay(),
          refCount()
        );
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getByFloor', error.message);
    }
  }

  getByMedicationIdPatientIdDosagem(ano: number, codPacienteNomedoPacienteCodMedicamento, dosagem): Observable<any> {
    try {
      this.cadastroNo = ano;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const strFilter = `V_832:${dosagem} `;

      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByMedicationId', error.message);
    }
    return of(null);
  }


}
