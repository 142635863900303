import { InterventionMedicationCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { IMedication, LogService, IPatient } from '@medlogic/shared/shared-interfaces';

export interface ISelectPatientDialog {
  patients: IPatient[];
  showMiniImg: boolean;
}

@Component({
  selector: 'select-patient-dialog',
  templateUrl: './select-patient-dialog.component.html',
  styleUrls: ['./select-patient-dialog.component.css']
})
export class SelectPatientDialogComponent implements OnInit {

  patients: IPatient[];

  icon: string;
  keyword = '';
  showMiniImg = false;

  constructor(
    private log: LogService,
    private interventionSrv: InterventionMedicationCustomService,
    public dialogRef: MatDialogRef<SelectPatientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ISelectPatientDialog) {
    this.patients = data?.patients || [];
    this.showMiniImg = data?.showMiniImg || false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  changeData() {
  }

  isColor(medication: IMedication | any, color: string): boolean {
    try {
      return this.interventionSrv.isColor(medication, color);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'isColor', error.message);
    }
    return false;
  }

  /* Paciente selecionado. */
  onPatientClick(patient: IPatient): void {
    try {
      this.dialogRef.close(patient);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onPatientClick', error.message);
    }
  }


}
