import { metaReducers } from './+state/ivcf.metareducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IvcfCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { IvcfEffects } from './+state/ivcf.effects';
import { ivcfReducer } from './+state/ivcf.reducer';

const MODULES = [
  CommonModule
];

const STATE_MODULES = [
  StoreModule.forFeature('ivcf', ivcfReducer, { metaReducers }),
  EffectsModule.forFeature([IvcfEffects]),
];

const SERVICES = [
  IvcfCustomService
]

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})


export class StateIvcfModule { }
