import { ConfigStateService } from '@medlogic/shared/state-config';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { AtividadeDAL } from '../../shared/model/dal/atividade-dal';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { ICadastroList } from '../../shared/interface/icadastro-list';
import { ICadastroFilter } from '../../shared/interface/icadastro-filter';
import { iif } from 'rxjs';
import { MsgPtBR, IProcess, LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { InputMaskType } from '../../shared/enum/input-mask-type';
import { IAtividade } from '../../shared/interface/iatividade';
import { tap } from 'rxjs/operators';
import { EnModuleMode } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import {
  CadastroService,
  ProcessService,
  ModelService
} from '@medlogic/shared/shared-data-access';

@Component({
  selector: 'lib-cmp-cadastro-list',
  templateUrl: './cmp-cadastro-list.component.html',
  styleUrls: ['./cmp-cadastro-list.component.css']
})
export class CmpCadastroListComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() enTheme = EnTheme.default;
  @Input() enModuleMode: EnModuleMode;
  @Input() ano = -1;
  @Input() token: string;
  @Input() filters: ICadastroFilter[];
  @Output() itemClick = new EventEmitter<any>();

  cadastros$: Observable<ICadastroList[]>;
  imgUrl: string;

  constructor(
    protected log: LogService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected msg: MsgPtBR,
    protected atividadeDAL: AtividadeDAL,
    protected processoSrv: ProcessService,
    protected cadastroDAL: CadastroService,
    protected modelSrv: ModelService,
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.subs.sink = this.refresh(this.ano, this.filters).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /** Carregamento principal. */
  protected refresh(ano: number, filters: ICadastroFilter[]): Observable<IAtividade> {
    try {
      this.cadastros$ = this.loadCadastro(ano, filters);
      return this.cadastros$
        .pipe(
          this.goToFirstIf(),
          mergeMap((listCad: ICadastroList[]) => of(null))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /** If single record it will jump to the first record edition. */
  protected goToFirstIf = () => tap((listCad: ICadastroList[]) => {
    if (listCad && listCad.length > 0 && this.enModuleMode === EnModuleMode.singleRecord) {
      this.onClick(listCad[0]);
    }
  })

  /** Carrega todos os cadastros. Também populará imagem - caso haja - e modificará o template. */
  protected loadCadastro(ano: number, filters: ICadastroFilter[]): Observable<ICadastroList[]> {
    try {
      const error = () => catchError((err, obs) => {
        console.log(err);
        return of(err);
      });
      // Faz o mapeamento de cada dado do cadastro para ICadastroList
      const mapTo = (fields: IAtividadeComponenteDAL[]) => map((item: any) => {
        let index = 0;
        // Se o primeiro elemento for uma imagem, mudará o visual para incluir a imagem
        const firstItem = this.getValueByVariavel(item, fields, index);
        let hasImage = false;
        if (this.isImage(firstItem)) {
          hasImage = true;
          this.imgUrl = firstItem;
          index++;
        }
        return ({
          ono: item.OcorrenciaNo,
          field1: this.getValueByVariavel(item, fields, index),
          field2: this.getValueByVariavel(item, fields, index + 1),
          field3: this.getValueByVariavel(item, fields, index + 2),
          field4: this.getValueByVariavel(item, fields, index + 3),
          field5: this.getValueByVariavel(item, fields, index + 4),
          hasImage
        } as ICadastroList);
      });
      // Se houver filtros especificados, irá filtrar os cadastros
      const filterCad = (parFilters: ICadastroFilter[]) =>
        mergeMap((item: any) =>
          iif(
            () => !this.glb.IsArrayNullOrEmpty(parFilters),
            of(item).pipe(
              filter((f: any) => {
                return parFilters.reduce(
                  (a, b) =>
                    a &&
                    (this.glb.isEqual(f[`V_${b.variavelNo}`], b.value) || this.glb.isEqualIgnoreTime(f[`V_${b.variavelNo}`], b.value))
                  , true);
              })
            ),
            of(item)
          )
        );
      // Resgata os dados do cadastro, mapeando para ICadastroList
      const getCad = () => mergeMap((ctrls: IAtividadeComponenteDAL[]) => {
        const lstVariavel = ctrls.reduce((a, b) => a.concat(`V_${b.VariavelNo},`), '');
        // Ordenará pelo taborder, mas, se for uma imagem, irá para o topo.
        const fields = ctrls
          .map(m => (
            {
              ...m,
              TabOrder: this.isImage(m.Rotulo) ? 0 : +m.TabOrder
            } as IAtividadeComponenteDAL))
          .sort(
            (a, b) => a.TabOrder - b.TabOrder
          );
        return this.cadastroDAL
          .getCadastro(ctrls[0].AtividadeNo, lstVariavel)
          .pipe(
            filterCad(filters),
            mapTo(fields),
            toArray()
          );
      });
      // Resgata dados do modelo para filtrar controles específicos (principais ou ShowInMobile)
      const getActModel = () => mergeMap((processo: IProcess) => {
        return this.modelSrv
          .GetModelComponent(processo.PrimeiraAtividadeNo, -1, -1)
          .pipe(
            filter((ctrl: IAtividadeComponenteDAL) => ctrl.ShowInMobile || ctrl.TypeRegister > 0),
            toArray()
          );
      });
      // Identifica a atividade por meio do processo
      const getProc = this.processoSrv
        .getSubProcessByUser(-1)
        .pipe(
          filter((processo: IProcess) => +processo.PrimeiraAtividadeNo === +ano)
        );
      // Pipe principal
      return getProc.pipe(
        getActModel(),
        getCad(),
        error()
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadCadastro', error.message);
    }
    return of(null);
  }

  /** Checa se a string é um nome de arquivo e de imagem. */
  protected isImage(fileName: string): boolean {
    try {
      if (!fileName || fileName.length <= 5) {
        return false;
      }
      const fileNameExtension = fileName.trim().substr(-4);
      const ext = ['.jpg', '.png', '.svg', '.gif'];
      const res = ext.reduce((a, b) => a || this.glb.isEqual(b, fileNameExtension), false);
      return res;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isImage', error.message);
    }
    return false;
  }

  /** Resgata o valor, baseado na propriedade da variável. */
  protected getValueByVariavel(item: any, fields: IAtividadeComponenteDAL[], position: number): string {
    try {
      if (position <= fields.length - 1) {
        const field = fields[position];
        let value = item[`V_${field.VariavelNo}`];
        if ((InputMaskType.toEnum(field?.InputMaskType) === InputMaskType.Date) ||
          (this.glb.isEqual(field.Type, 'EditorAtividade.ctrDate'))
        ) {
          value = value.substr(0, 10);
        }
        return value;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getVariavel', error.message);
    }
    return '';
  }

  /** Click no item. */
  onClick(item: ICadastroList): void {
    try {
      this.itemClick.emit(item);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClick', error.message);
    }
  }


}
