import { LibService } from '@medlogic/shared/geform';
import { Injectable } from '@angular/core';
import { FamilyService } from './family.service';
import {
  ConfigJsonService,
  GlobalService,
  IFamily, LogService,
} from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';

@Injectable({
  providedIn: 'root'
})
export class FamilyCustomService extends FamilyService {


  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnfJson: ConfigJsonService,
    log: LogService,
    lib: LibService,
    protected basepage: BasePageService
  ) {
    super(cadastroSrv, glb, cnfJson, log, lib);
  }

  /* Retorna todos os itens do histórico de compras através do id do item. */
  getByCodigoPaciente(ano: number, codigoPaciente: string, dtStart: Date = null, dtEnd: Date = null): Observable<IFamily> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filter = `V_28051:${codigoPaciente}`;
      return this.getFiltered(this.cadastroNo, filter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCodigoPaciente', error.message);
    }
    return of(null);
  }


}
