import { MedLogicNavigationService } from './medlogic-navigation.service';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { LogService, ITenantState, EnLoginState } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedlogicAuthChildGuardService implements CanActivateChild {

  constructor(
    private store: Store<IAppMedlogicState>,
    private nav: MedLogicNavigationService,
    private log: LogService) { }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean | UrlTree {
    try {
      const currentUrl = state?.url;
      const isLoginRoute = (currentUrl === '/exam');
      const isUserLogged$ = () => map((storeState: IAppMedlogicState) => {
        if (storeState?.login?.enLoginState !== EnLoginState.Logged) {
          this.nav.navigateToLogin();
          return { state: null };
        }
        return { state: storeState?.tenant };
      });
      // TODO: Falta implementar a validação do token via API. Medir se não penalizará o acesso a todas as rotas em demasia.
      const isTokenValid$ = () => map((validState: { state: ITenantState }) => !!validState?.state?.token);

      // Pipe principal
      return isLoginRoute || this.store.pipe(isUserLogged$(), isTokenValid$());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'canActivateChild', error.message);
    }
    return false;
  }


}
