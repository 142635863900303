import { IListItem } from './ilist-item';
import { IGeneric } from './igeneric';

export interface IGenericState {
  selectedGeneric: IListItem<IGeneric>;
  allGenerics: IListItem<IGeneric>[];
  Id: number;
  genericAno: number;
  identification: IListItem<IGeneric>;
  tno?: number;
  genericLabel?: string;
}
