<div class="container" *ngIf="!behaviour">

  <h3>{{ title }}</h3>

  <div class="message" *ngIf="message" [innerHTML]="message"></div>

  <mat-toolbar>

    <button mat-raised-button (click)="onNoClick()">
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>{{ btnOK }}
    </button>

  </mat-toolbar>

</div>

<div class="container" *ngIf="behaviour">

  <h3>{{ title }}</h3>

  <div class="message" *ngIf="message" [innerHTML]="message"></div>

  <mat-toolbar>

      <button *ngFor="let b of behaviours" mat-raised-button (click)="changeBehaviour(b.display)">
        {{b.description}}
      </button>

  </mat-toolbar>

</div>
