import {
    ElementRef, Output, Directive, AfterViewInit, OnDestroy, EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs';
import { fromEvent } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Directive({
    selector: '[appAppear]'
})
export class AppearDirective implements AfterViewInit, OnDestroy {

    

    @Output() appear: EventEmitter<void>;

    elementPos: number;
    elementHeight: number;

    scrollPos: number;
    windowHeight: number;

    subscriptionScroll: Subscription;
    subscriptionResize: Subscription;

    constructor(private element: ElementRef) {
        this.appear = new EventEmitter<void>();
    }

    saveDimensions() {
        try {
            this.elementPos = this.getOffsetTop(this.element.nativeElement);
            this.elementHeight = this.element.nativeElement.offsetHeight;
            this.windowHeight = window.innerHeight;
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'saveDimensions', error.message);
        }
    }

    saveScrollPos() {
        try {
            this.scrollPos = window.scrollY;
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'saveScrollPos', error.message);
        }
    }

    getOffsetTop(element: any) {
        try {
            let offsetTop = element.offsetTop || 0;
            if (element.offsetParent) {
                offsetTop += this.getOffsetTop(element.offsetParent);
            }
            return offsetTop;
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'getOffsetTop', error.message);
        }
        return null;
    }

    checkVisibility() {
        try {
            if (this.isVisible()) {
                // double check dimensions (due to async loaded contents, e.g. images)
                this.saveDimensions();
                if (this.isVisible()) {
                    this.unsubscribe();
                    this.appear.emit();
                }
            }
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'checkVisibility', error.message);
        }
        return null;
    }

    isVisible() {
        try {
            return this.scrollPos >= this.elementPos || (this.scrollPos + this.windowHeight) >= (this.elementPos + this.elementHeight);
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'isVisible', error.message);
        }
        return null;
    }

    subscribe() {
        try {
            this.subscriptionScroll = fromEvent(window, 'scroll').pipe(startWith(null))
                .subscribe(() => {
                    this.saveScrollPos();
                    this.checkVisibility();
                });
            this.subscriptionResize = fromEvent(window, 'resize').pipe(startWith(null))
                .subscribe(() => {
                    this.saveDimensions();
                    this.checkVisibility();
                });
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'subscribe', error.message);
        }
    }

    unsubscribe() {
        try {
            if (this.subscriptionScroll) {
                this.subscriptionScroll.unsubscribe();
            }
            if (this.subscriptionResize) {
                this.subscriptionResize.unsubscribe();
            }
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'unsubscribe', error.message);
        }
    }

    ngAfterViewInit() {
        try {
            this.subscribe();
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'ngAfterViewInit', error.message);
        }
    }

    ngOnDestroy() {
        try {
            this.unsubscribe();
        } catch (error) {
            // this.log.Registrar(this.constructor.name, 'ngOnDestroy', error.message);
        }
    }


}