<div class="container" [class.black]="enTheme === 'black'">
  <mat-toolbar color="basic">
    <span class="example-spacer"></span>
    <mat-icon class="example-icon" (click)="onCloseClick()">close</mat-icon>
  </mat-toolbar>

  <div class="spacer">
    <!-- Esse arquivo é uma cópia de atividade-cadastro-view/atividade-cadastro-view.component.html -->
    <!-- A Ocorrencia Existe ou é uma Nova -->
    <div *ngIf="newOrExists()">
      <ui-loading [isLoading]="isLoading | async"></ui-loading>
      <form [formGroup]="formGroup" *ngIf="actived || true" (ngSubmit)="onSubmit()">
        <lib-tab [attr.data-ono]="ocorrenciaNo" [activityType]="activityType" [saveInList]="saveInList" [addToHistory]="false" [enTheme]="enTheme"
          [componentes]="componentes" [atividade]="atividade" [tabs]="tabs" [isMobile]='isMobile'
          [tabActivedId]="tabActivedId" [backAtividadeNo]="backAtividadeNo" [backOcorrenciaNo]="backOcorrenciaNo"
          [ocorrenciaNo]="ocorrenciaNo" [atividadeNome]="atividadeNome" [isLoading]="isLoading | async"
          [atividadeNo]="atividadeNo" [saved]="saved" [formGroup]="formGroup" [isEditMode]="isEditMode" [isReadOnly]="isReadOnly" [formErrors]="formErrors"
          (eventBubble)="onEventBubble($event)" [showBackButton]="false"></lib-tab>
      </form>
      <!-- <lib-status-bar [backAtividadeNo]="backAtividadeNo" [backOcorrenciaNo]="backOcorrenciaNo" [ocorrenciaNo]="ocorrenciaNo" [atividadeNome]="atividadeNome"
      (eventBubble)="onEventBubble($event)"></lib-status-bar> -->
    </div>
    <!--A Ocorrencia que se tenta editar não existe-->
    <div *ngIf="!newOrExists()">
      <!--Antes de exibir essa mensagem é necessário criar uma maneira de realmente saber se a ocorrencia não existe-->
      <!--<h1>Desculpe, mas você tentou editar a Ocorrência {{ ocorrenciaNo }}, mas ela não existe!</h1>-->
    </div>
    <!-- <mat-toolbar class="bottom-bar">
      <button mat-raised-button (click)="onYesClick($event)">OK</button>
    </mat-toolbar> -->
  </div>

</div>
<p-toast position="bottom-center"></p-toast>
<!--Mensagem de confirmação de exclusão -->
<p-confirmDialog header="Confirmação" icon="fa fa-question-circle" [rejectIcon]="message.icon"
  [rejectLabel]="message.firstButtonLabel" acceptLabel="Sim" [style]="{ 'width':'425px' }">
</p-confirmDialog>

<lib-debug *ngIf="isDebug" [msg]="debugMsg"></lib-debug>
