import { addLog } from '@medlogic/shared/state-log';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import {
  GlobalService, IIntervecoesMedicamentosHorarios, ILog
} from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { IntervencoesMedicamentosHorariosCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, forkJoin, of } from 'rxjs';
import { addHorariosandInsert, checkHorarios, deleteHorariosBy, horariosFail, saveHorariosSuccess } from './horarios.actions';

@Injectable()
export class HorariosEffects {

  // tslint:disable: variable-name

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private horariosSrv: IntervencoesMedicamentosHorariosCustomService, // TODO: ContaService changed to the API
  ) { }

  // Carrega os medicamentos considerando o dia de HOJE (referenceDate)
  // loadHorariossByPatient$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(loadHorariossBySelectedPatient),
  //     withLatestFrom(this.store),
  //     mergeMap(([never, state]) => {
  //       // const dtEnd = this.glb.addDays(new Date(), 1); // Necessário para incluir a data do dia
  //       // const dtStart = this.glb.addDays(dtEnd, -state?.horarios?.numOfDaysToCheckOcurrency);
  //       const dtEnd = null;
  //       const dtStart = null;
  //       const td = new Date();
  //       const referenceDate = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 23, 59, 59);
  //       const { cadIntervecoesMedicamentosNo, cadCheckMedicamentosNo, cadIntervecoesHorariosNo } = state?.tenant?.selectedTenant;
  //       return this.interventionSrv.getHorariossForPatient(
  //         cadIntervecoesMedicamentosNo,
  //         cadCheckMedicamentosNo,
  //         cadIntervecoesHorariosNo,
  //         state?.patient.entities[state?.patient?.selectedId],
  //         dtStart,
  //         dtEnd,
  //         referenceDate)
  //         .pipe(
  //           first(),
  //           switchMap((horarioss: IHorarios[]) => [
  //             horarioss ? loadHorariossSuccess({ horarioss }) : horariosFail(null),
  //             setIsLoading({ isLoading: false })
  //           ]),
  //           catchError((e: any) => {
  //             console.log(e);
  //             return of(horariosFail(null));
  //           })
  //         );
  //     })
  //   )
  // );

  // loadHorariossByPrescription$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(loadHorariossByPrescription),
  //     withLatestFrom(this.store),
  //     mergeMap(([never, state]) => {
  //       // const dtEnd = this.glb.addDays(new Date(), 1); // Necessário para incluir a data do dia
  //       // const dtStart = this.glb.addDays(dtEnd, -state?.horarios?.numOfDaysToCheckOcurrency);
  //       const dtEnd = null;
  //       const dtStart = null;
  //       const td = new Date();
  //       const referenceDate = new Date(td.getFullYear(), td.getMonth(), td.getDate(), 23, 59, 59);
  //       const { cadIntervecoesMedicamentosNo, cadIntervecoesHorariosNo } = state?.tenant?.selectedTenant;
  //       return this.interventionSrv.getHorariossByPrescription(
  //         cadIntervecoesMedicamentosNo,
  //         cadIntervecoesHorariosNo,
  //         state?.horarios?.prescriptionId,
  //         this.personSrv.mapToIPatient(state?.person?.person),
  //         dtStart,
  //         dtEnd)
  //         .pipe(
  //           first(),
  //           switchMap((horarioss: IHorarios[]) => [
  //             horarioss ? loadHorariossSuccess({ horarioss }) : horariosFail(null),
  //             setIsLoading({ isLoading: false })
  //           ]),
  //           catchError((e: any) => {
  //             console.log(e);
  //             return of(horariosFail(null));
  //           })
  //         );
  //     })
  //   )
  // );

  // loadHorarioss$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(loadHorarioss),
  //     withLatestFrom(this.store),
  //     mergeMap(([never, state]) => {
  //       return this.horariosSrv.getAll(
  //         state?.tenant?.selectedTenant?.cadIntervecoesMedicamentosNo)
  //         .pipe(
  //           map(intervMed => this.interventionSrv.mapIntervToHorarios(intervMed, intervMed?.horaprimeiraDose, null)),
  //           toArray(),
  //           this.checkHorariosThatIsPending$(new Date()),
  //           switchMap((horarioss: IHorarios[]) => [
  //             horarioss ? loadHorariossSuccess({ horarioss }) : horariosFail(null),
  //             setIsLoading({ isLoading: false })
  //           ]),
  //           catchError((e: any) => {
  //             console.log(e);
  //             return of(horariosFail(null));
  //           })
  //         );
  //     })
  //   )
  // );

  addHorariosandInsert$ = createEffect(() => this.actions$
    .pipe(
      ofType(addHorariosandInsert),
      mergeMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            return this.horariosSrv.save<IIntervecoesMedicamentosHorarios>(
              state?.tenant?.selectedTenant?.cadIntervecoesHorariosNo,
              action?.horario,
              state?.tenant?.login?.usuarioLogadoNo,
              null
            );
          }),
          switchMap((prescribeTime: IIntervecoesMedicamentosHorarios) => {
            return !!prescribeTime ?
              [
                addLog({ log: { msg: `Horário acrescentado (${prescribeTime?.ocorrenciaNo}): ${prescribeTime?.hora} medicamento: ${prescribeTime?.codigoMedicamentoPaciente}`, showMessage: true } as ILog }),
                saveHorariosSuccess(),
              ] :
              [horariosFail({ error: 'error-addHorariosandInsert' })];
          }),

          catchError((e: any) => {
            console.log(e);
            return of(horariosFail({ error: 'error-addHorariosandInsert' }));
          })
        )
      }),
    )
  );

  deleteHorariosBy$ = createEffect(() => this.actions$
    .pipe(
      ofType(deleteHorariosBy),
      mergeMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            const { patientId, medicationId } = action;
            return this.horariosSrv
              .deleteAllBy(state?.tenant?.selectedTenant?.cadIntervecoesHorariosNo, patientId, medicationId)
              .pipe(
                switchMap((wasAllDeleted: boolean) => {
                  return wasAllDeleted ?
                    [
                      addLog({ log: { msg: `Horários excluídos para paciente: ${patientId} e medicamento: ${medicationId}`, showMessage: true } as ILog })
                    ] :
                    [
                      addLog({ log: { msg: `Houve falha na exclusão dos horários do paciente: ${patientId} e medicamento: ${medicationId}`, showMessage: true } as ILog })
                    ];
                }),
                catchError((e: any) => {
                  console.log(e);
                  return of(horariosFail(null));
                })
              )
          }),
        )
      }))
  );

  /* para cada medicamento checa se já está cadastrado na INTERVENÇÃO HORÁRIOS, se não estiver, insere (addHorariosandInsert)  */
  // checkHorarios$ = createEffect(() => this.actions$
  //   .pipe(
  //     ofType(checkHorarios),
  //     mergeMap((action: any) => {
  //       return of(action).pipe(
  //         withLatestFrom(this.store),
  //         mergeMap(([never, state]) => {
  //           // const codMedicamento = action?.medication?.codigo;
  //           // FIXME: Talvez, toda vez que for modificar ou acrescentar um horário, tenha que excluir todos os o horários do medicamento e paciente antes
  //           // FIXME: ATENÇÃO: E se houver tentativa de cadastrar o mesmo medicamento para o mesmo paciente mais de uma vez, mas com algum item modificado que não interfira no Id?
  //           const codigoMedicamentoPaciente = `${action?.medication?.codigo}_${action?.medication?.codigoPaciente}`;
  //           return this.horariosSrv.getByCodigoMedicationPaciente(state?.tenant?.selectedTenant?.cadIntervecoesHorariosNo, codigoMedicamentoPaciente)
  //             .pipe(
  //               toArray(),
  //               switchMap((horarios: IIntervecoesMedicamentosHorarios[]) => {
  //                 const horarios$ = action?.medication?.lsthorariosGrid
  //                   .map(horario => {
  //                     const titulo = `${horario.hora} - ${action?.medication?.codigo}_${action?.medication?.codigoPaciente}`;
  //                     const codigoMedicamentoPaciente = `${action?.medication?.codigo}_${action?.medication?.codigoPaciente}`;
  //                     const Id_Medicamento = action?.medication?.codigo;
  //                     const IdMedicamento_IdPaciente = `${action?.medication?.codigo}_${action?.medication?.codigoPaciente}`
  //                     const newHorario: IIntervecoesMedicamentosHorarios = { ...horario, codigoMedicamentoPaciente, Id_Medicamento, IdMedicamento_IdPaciente };
  //                     this.hasHours(horarios, horario?.hora) ?
  //                       this.store.dispatch(saveHorariosSuccess()) :
  //                       this.store.dispatch(addHorariosandInsert({ horario: newHorario }));
  //                     return of(horario);

  //                   });

  //                 return forkJoin(horarios$)
  //                   .pipe(
  //                     map((res: any) => {
  //                       return !!res ? saveHorariosSuccess() : horariosFail({ error: 'error-addMedicaton-Stock' })
  //                     })
  //                   );

  //               }),
  //               catchError((e: any) => {
  //                 console.log(e);
  //                 return of(horariosFail(null));
  //               })
  //             )
  //           //
  //         }),
  //       )
  //     }))
  // );

  /* Sempre excluirá todos os medicamentos para o mesmo paciente, mesmo id medicamento e depois inserir os horários novamente.
  * para cada medicamento checa se já está cadastrado na INTERVENÇÃO HORÁRIOS, se não estiver, insere (addHorariosandInsert)  */
  checkHorarios$ = createEffect(() => this.actions$
    .pipe(
      ofType(checkHorarios),
      mergeMap(action => {
        return of(action).pipe(
          withLatestFrom(this.store),
          mergeMap(([never, state]) => {
            const { Id_Paciente, Id_Medicamento, codigoPaciente, Id_Prescricao, lsthorariosGrid, wasChanged } = action?.medication;
            if (!wasChanged || lsthorariosGrid?.length <= 0) {
              return EMPTY;
            }

            return this.horariosSrv.deleteAllBy(state?.tenant?.selectedTenant?.cadIntervecoesHorariosNo, +Id_Paciente, +Id_Medicamento)
              .pipe(
                mergeMap((wasAllDeleted: boolean) => {
                  if (wasAllDeleted) {
                    this.store.dispatch(addLog({ log: { msg: `Horários excluídos para paciente: ${Id_Paciente} e medicamento: ${Id_Medicamento}`, showMessage: true } as ILog }));
                  }
                  const horarios$ = lsthorariosGrid?.map((horario: IIntervecoesMedicamentosHorarios) => {
                    const titulo = `${horario.hora} - ${Id_Medicamento}_${codigoPaciente}${Id_Prescricao ? `_${Id_Prescricao}` : ''}`;
                    const codigoMedicamentoPaciente = `${Id_Medicamento}_${codigoPaciente}`;
                    const IdMedicamento_IdPaciente = `${Id_Medicamento}_${codigoPaciente}`;
                    const newHorario: IIntervecoesMedicamentosHorarios = { ...horario, titulo, codigoMedicamentoPaciente, Id_Medicamento: +Id_Medicamento, IdMedicamento_IdPaciente };
                    this.store.dispatch(addHorariosandInsert({ horario: newHorario }));
                    return of(newHorario);
                  });
                  return forkJoin(horarios$);
                })
              )
          }),
          switchMap((res: IIntervecoesMedicamentosHorarios[]) => {
            const horarios = res?.length > 0 ? `${res[0].Id_Paciente} - ${res?.map(m => m.hora)}` : '';
            return !!res ?
              [
                // A mensagem é acrescentada do addHorariosandInsert
                // addLog({ log: { msg: `Horários acrescentados: ${horarios}`, showMessage: true } as ILog }),
                saveHorariosSuccess(),
              ] :
              [
                addLog({ log: { msg: `Houve falha na tentativa de acrescentar o(s) horário(s): ${horarios}`, showMessage: true } as ILog }),
                horariosFail({ error: 'error-addMedicaton-Stock' }),
              ];
          }),
          catchError((e: any) => {
            console.log(e);
            return of(horariosFail(null));
          })
        )
      }))
  );

  hasHours(horarios: IIntervecoesMedicamentosHorarios[], hora: string) {
    try {
      return horarios.find(f => this.glb.isEqual(f.hora, hora));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasHours', error.message);
    }
    return false;
  }


}
