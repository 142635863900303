import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IListItem, ISmartList, LogService } from '@medlogic/shared/shared-interfaces';
import { fadeAnimation } from '@medlogic/shared/utils';
import { ISmartListAction } from '../interface/ismart-list-action';

@Component({
  selector: 'ui-smart-list-action',
  templateUrl: './smart-list-action.component.html',
  styleUrls: ['./smart-list-action.component.css'],
  animations: [fadeAnimation]
})
export class SmartListActionComponent implements OnInit {

  @Input() groups: ISmartList<ISmartListAction>[];
  @Input() search: string;
  @Input() emptyMessage: string;

  @Output() itemClick = new EventEmitter<any>();
  @Output() itemRemove = new EventEmitter<any>();
  @Output() itemAdd = new EventEmitter<any>();

  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  onItemClick($event: any, item: IListItem<any>): void {
    try {
      $event.stopPropagation();
      this.itemClick.emit(item);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onItemClick', error.message);
    }
  }

  onItemRemove($event: any, item: ISmartListAction<any>): void {
    try {
      $event.stopPropagation();
      this.itemRemove.emit(item);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onItemRemove', error.message);
    }
  }

  onAdd($event: any, group: ISmartList<ISmartListAction>): void {
    try {
      $event.stopPropagation();
      this.itemAdd.emit(group);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAdd', error.message);
    }
  }


}
