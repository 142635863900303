<div [formGroup]="formGroup" class="form-group" [class.has-error]="!isValid(formGroup, ctrl)" [libCtrConfigUI]="ctrl" [altura]="ctrl?.Altura" [isMobile]="isMobile">

  <lib-title-label [formGroup]="formGroup" [isMobile]="isMobile" [enTheme]="enTheme" [ctrl]="ctrl" #lblTitle>
  </lib-title-label>

  <p-slider [id]="getId(ctrl)" [formControlName]="getId(ctrl)" [min]="0" [max]="100" [step]="5" orientation="horizontal"
    (onSlideEnd)="onSlideEnd(ctrl.VariavelNo, rating.value)" #rating [libCtrConfigUI]="ctrl" [altura]="ctrl?.Altura" [isMobile]="isMobile"
    [tabIndex]="getTabIndex(ctrl)"></p-slider>

  <h3>{{ ctrl?.ValorTexto }}</h3>

</div>