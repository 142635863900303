import { ConfigStateService } from '@medlogic/shared/state-config';
import { Control } from '../../model/control';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, ConfigJsonService, IAtividadeComponenteDAL, EnTheme } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraService } from '../../service/calculadora.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { FormGroup } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-mapa',
  templateUrl: './ctr-mapa.component.html',
  styleUrls: ['./ctr-mapa.component.css']
})
export class CtrMapaComponent extends Control implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  options: any;

  overlays: any[];
  lblTittle = '';

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }


  ngOnInit() {
    try {
      this.options = {
        center: { lat: 36.890257, lng: 30.707417 },
        zoom: 12
      };
      // this.overlays = [
      //   new google.maps.Marker({ position: { lat: 36.879466, lng: 30.667648 }, title: "Konyaalti" }),
      //   new google.maps.Marker({ position: { lat: 36.883707, lng: 30.689216 }, title: "Ataturk Park" }),
      //   new google.maps.Marker({ position: { lat: 36.885233, lng: 30.702323 }, title: "Oldtown" }),
      //   new google.maps.Polygon({
      //     paths: [
      //       { lat: 36.9177, lng: 30.7854 }, { lat: 36.8851, lng: 30.7802 },
      // { lat: 36.8829, lng: 30.8111 }, { lat: 36.9177, lng: 30.8159 }
      //     ], strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35
      //   }),
      //   new google.maps.Circle({ center: { lat: 36.90707, lng: 30.56533 },
      // fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500 }),
      //   new google.maps.Polyline({ path: [{ lat: 36.86149, lng: 30.63743 },
      // { lat: 36.86341, lng: 30.72463 }], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2 })
      // ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  handleMapClick(event) {
    // event: MouseEvent of Google Maps api
  }

  handleOverlayClick(event) {
    // event.originalEvent: MouseEvent of Google Maps api
    // event.overlay: Clicked overlay
    // event.map: Map instance
  }

}
