import { Directive, Input, ElementRef, Renderer2 } from '@angular/core'

@Directive({
  selector: '[libTable]'
})
export class TableDirective {

  private _elements: any[];
  @Input('libTable') public get elements(): any[] {
    return this._elements;
  }

  public set elements(v: any[]) {
    this._elements = v;
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  //Para resolver o problema do - na atualização, procurar um evento que não seja o Init
  ngOnInit(): void {
    this.applyStyles(this.elements);
  }

  applyStyles(elements: any[]): void {
    let discount = 0;
    if (elements) {
      discount = this.elements.map(m => m.clientHeight ? m.clientHeight : m).reduce((a, b) => a + b, 0);
    }
    let height = window.innerHeight - discount;
    this.renderer.setStyle(this.el.nativeElement, 'height', height + 'px');
  }
}
