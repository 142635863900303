export interface ITarefaComponente {
    ComponenteNo: number,
    AtividadeNo: number,
    TabIndex: number,
    TabName: string,
    TabOrder: number,
    Grupo: number,
    Rotulo: string,
    ValorTexto: string,
    IsAlerta: boolean,
    AlertaCor: string,
    AlertaDescricao: string,
    GrupoCor: string,
    AlertaCorNum: number
}