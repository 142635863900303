import { IVitalSign } from '@medlogic/shared/shared-interfaces';
import { EntityState } from '@ngrx/entity';

export interface IVitalSignState extends EntityState<IVitalSign> {
    // additional entities state properties
    error: any;
    selectedId: number | null;
    dtStart: Date;
    dtEnd: Date;
}
