import { IHorariosState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { IIntervecoesMedicamentosHorarios, GlobalService } from '@medlogic/shared/shared-interfaces';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as HorariosActions from './horarios.actions';

export const horariosFeatureKey = 'horario';

const glb = new GlobalService();

export const adapterHorario: EntityAdapter<IIntervecoesMedicamentosHorarios> = createEntityAdapter<IIntervecoesMedicamentosHorarios>({
  selectId: instance => instance?.codigo
});

export const initialStateHorario: IHorariosState = adapterHorario.getInitialState({
  // additional entity state properties
  error: null,
  selectedId: null,
  selectedHorario: null,
  allHorarios: null
});


export const reducer = createReducer(
  initialStateHorario,
  on(HorariosActions.addHorarios,
    (state, action) => adapterHorario.addOne(getItem(action.horarios), state)
  ),
  on(HorariosActions.loadHorarios,
    (state, action) => ({ ...state, numOfDaysToCheckOcurrency: action.numOfDaysToCheckOcurrency })
  ),

  on(HorariosActions.loadHorariosBySelectedPatient,
    (state, action) => ({ ...state, numOfDaysToCheckOcurrency: action.numOfDaysToCheckOcurrency })
  ),
  on(HorariosActions.loadHorariosByPrescription,
    (state, action) => ({ ...state, prescriptionId: action.prescriptionId })
  ),
  on(HorariosActions.upsertHorario,
    (state, action) => adapterHorario.upsertOne(action.horario, state)
  ),
  on(HorariosActions.addHorariosandInsert,
    (state, action) => adapterHorario.addOne(getItem(action.horario), state)
  ),
  // on(HorariosActions.addHorariosandInsertStock,
  //   (state, action) => adapterHorario.addOne(action.horario, state)
  // ),
  on(HorariosActions.upsertHorarios,
    (state, action) => adapterHorario.upsertMany(action.horarios, state)
  ),
  on(HorariosActions.updateHorario,
    (state, action) => adapterHorario.updateOne(action.horario, state)
  ),
  on(HorariosActions.updateHorarios,
    (state, action) => adapterHorario.updateMany(action.horarios, state)
  ),
  on(HorariosActions.deleteHorario,
    (state, action) => adapterHorario.removeOne(action.id, state)
  ),
  on(HorariosActions.deleteHorarios,
    (state, action) => adapterHorario.removeMany(action.ids, state)
  ),
  on(HorariosActions.loadHorariosSuccess,
    (state, action) => adapterHorario.setAll(action.horarios, state)
  ),

  on(HorariosActions.horariosFail,
    (state, action) => ({
      ...state,
      horarios: null,
      error: action?.error
    })
  ),


  on(HorariosActions.saveHorariosSuccess,
    (state, action) => ({
      ...state,
      changeItem: null,
      error: null
    })
  ),
  on(HorariosActions.cleanHorarios, () => ({ ...initialStateHorario }))
);

const getItem = (horario: IIntervecoesMedicamentosHorarios) => ({ ...horario, codigo: horario?.codigo || glb.getGUID() });

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterHorario.getSelectors();
