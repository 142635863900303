import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  localStorageKey = 'ML_LOCAL_STORAGE_KEY';
  private encryptValues = true;
  // FIXME: This tecnhique is not totally efficency, because the key will be presented at js code.
  staticKey = 'dk83-dkcD-3kdf-3dflgh';

  // FIXME: Localstorage tem limite de 5Mb e tranca a thread. Considerar migrar para indexDb com algo do tipo: https://github.com/jakearchibald/idb-keyval

  public get key(): string {
    // FIXME: this is a potential source of bug, because if the token is changed it will end up in bad decrypt.
    // return `${this.staticKey}${this.cnf.baseUsuarioToken}`;
    return this.staticKey;
  }

  setSavedState(state: any, localStorageKey: string = null) {
    try {
      localStorageKey = localStorageKey || this.localStorageKey;
      // console.log('save', state, localStorageKey);
      let value = JSON.stringify(state);
      value = this.encryptValues ? this.encrypt(value) : value;
      localStorage.setItem(localStorageKey, value);
    } catch (error) {
      console.log(this.constructor.name, 'setSavedState', error.message);
    }
    return null;
  }

  getSavedState(localStorageKey: string = null): any {
    try {
      localStorageKey = localStorageKey || this.localStorageKey;
      let state = localStorage.getItem(localStorageKey);
      // console.log('load', localStorageKey, state);
      state = this.encryptValues ? this.decrypt(state) : state;
      return state ? JSON.parse(state) : null;
    } catch (error) {
      console.log(this.constructor.name, 'getSavedState', error.message);
    }
    return null;
  }

  clean(localStorageKey: string = null): any {
    try {
      localStorageKey = localStorageKey || this.localStorageKey;
      if (localStorage && typeof localStorage.removeItem === 'function') {
        localStorage.removeItem(localStorageKey);
        return true;
      }
    } catch (error) {
      console.log(this.constructor.name, 'clean', error.message);
    }
    return false;
  }

  /**  */
  protected encrypt(message: string): string {
    try {
      if (message) {
        return CryptoJS.AES.encrypt(message, this.key.trim()).toString();
      }
    } catch (error) {
      console.log(this.constructor.name, 'encrypt', error.message);
    }
    return null;
  }

  protected decrypt(encryptedMsg: string): string {
    try {
      if (encryptedMsg) {
        return CryptoJS.AES.decrypt(encryptedMsg, this.key.trim()).toString(
          CryptoJS.enc.Utf8
        );
      }
    } catch (error) {
      console.log(this.constructor.name, 'decrypt', error.message);
    }
    return null;
  }

  getItem(key: string): string | number {
    try {
      const encryptedValue = localStorage.getItem(key);
      if (!encryptedValue) {
        return null;
      }
      const value = this.decrypt(encryptedValue);
      return value;
    } catch (error) {
      console.log(this.constructor.name, 'getItem', error.message);
    }
    return null;
  }

  setItem(key: string, value: string | number): boolean {
    try {
      if (value) {
        const encryptedValue = this.encrypt(value.toString());
        localStorage && localStorage.setItem(key, encryptedValue);
        return true;
      }
    } catch (error) {
      console.log(this.constructor.name, 'setItem', error.message);
    }
    return false;
  }

  setJsonItem<T>(key: string, value: T): boolean {
    try {
      if (value) {
        const encryptedValue = this.encrypt(JSON.stringify(value));
        localStorage && localStorage.setItem(key, encryptedValue);
        return true;
      }
    } catch (error) {
      console.log(this.constructor.name, 'setJsonItem', error.message);
    }
    return false;
  }

  getJsonItem<T>(key: string): T {
    try {
      const encryptedValue = localStorage.getItem(key);
      if (!encryptedValue) {
        return null;
      }
      const value = this.decrypt(encryptedValue);
      return JSON.parse(value);
    } catch (error) {
      console.log(this.constructor.name, 'getJsonItem', error.message);
    }
    return null;
  }

  setArrayItem<T>(key: string, value: T[]): boolean {
    try {
      if (value) {
        const encryptedValue = this.encrypt(JSON.stringify(value));
        localStorage && localStorage.setItem(key, encryptedValue);
        return true;
      }
    } catch (error) {
      console.log(this.constructor.name, 'setArrayItem', error.message);
    }
    return false;
  }

  getArrayItem<T>(key: string): T[] {
    try {
      const encryptedValue = localStorage.getItem(key);
      if (!encryptedValue) {
        return null;
      }
      const value = this.decrypt(encryptedValue);
      return JSON.parse(value);
    } catch (error) {
      console.log(this.constructor.name, 'getArrayItem', error.message);
    }
    return null;
  }
}
