import { Params, NavigationExtras } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { EnViewMode } from '../enum/en-view-mode.enum';

export interface IRouterState {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface RouterState {
  router: fromRouter.RouterReducerState<IRouterState>;
}

export interface INgrxRoute {
  path: any[];
  query?: object;
  extras?: NavigationExtras;
  enViewMode?: EnViewMode;
}
