import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDecisionDialog } from '../../interface/idecision-dialog';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-decision-dialog',
  templateUrl: './decision-dialog.component.html',
  styleUrls: ['./decision-dialog.component.css']
})
export class DecisionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DecisionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDecisionDialog
  ) { }

  onNoClick(value: any): void {
    this.dialogRef.close(this.data.noResult);
  }

  onYesClick(value: any): void {
    this.dialogRef.close(this.data.yesResult);
  }

}
