<div [formGroup]="formGroup" class="form-group" [class.has-error]="!isValid(formGroup, ctrl)" [libCtrConfigUI]="ctrl"
[altura]="ctrl?.Altura" [isMobile]="isMobile" [class.black]="enTheme === ENTHEME.black">

  <div class="col-sm-10">

    <section class="section" [class.is-desktop-section]="!isMobile">

      <span class="list-section" [ngSwitch]="isMobile">

        <ng-container *ngSwitchCase="false">
          <lib-title-label [formGroup]="formGroup" [isMobile]="isMobile" [enTheme]="enTheme" [ctrl]="ctrl">
          </lib-title-label>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <mat-checkbox class="margin" [checked]="allComplete" [indeterminate]="someComplete()"
            (change)="setAll($event.checked, ctrl)" [disabled]="!ctrl.IsEnable">

            <lib-title-label [formGroup]="formGroup" [isMobile]="isMobile" [enTheme]="enTheme" [ctrl]="ctrl">
            </lib-title-label>

          </mat-checkbox>
        </ng-container>

      </span>

      <span class="list-section">

        <ul *ngIf="(checklistItems$ | async) as items" [class.is-desktop-ul]="!isMobile">
          <li *ngFor="let item of items">

            <mat-checkbox [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" color="primary"
              (ngModelChange)="updateAllComplete(ctrl)" [class.is-mobile]="isMobile" [disabled]="!ctrl.IsEnable">
              {{ item?.label }}
            </mat-checkbox>

          </li>
        </ul>

      </span>

    </section>

    <!-- <div *ngFor="let cadastro of cadastros" class="i-checks">

      <label class="lbl">

        <div class="icheckbox_square-green" [class.checked]="isChecked(ctrl, cadastro.CampoNome)">

          <input [id]="getId(ctrl)" type="checkbox" class="checkbox" [value]="cadastro.Valor"
            [attr.checked]="isChecked(ctrl, cadastro.CampoNome)" (click)="onClick(ctrl, cadastro.CampoNome)"
            [tabIndex]="getTabIndex(ctrl)">

          <ins class="iCheck-helper"></ins>

        </div> <i (click)="onClick(ctrl, cadastro.CampoNome)"></i> <span class="text">{{ cadastro.CampoNome }}</span>

      </label>

    </div> -->

  </div>

</div>