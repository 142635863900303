import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BasePageService } from './base-page.service';
import { EnRequestType, routeGetLabelValueComboBoxByForm } from '@medlogic/shared/shared-interfaces';
import { of } from 'rxjs';

@Injectable()
export class ModelComponentService {
  constructor(private basepage: BasePageService) { }

  // TODO: API
  // urlGetModelComponent = `Model/GetLabelValueComboBoxByForm?_atividadeNo={0}`;
  urlGetModelComponent = `${routeGetLabelValueComboBoxByForm}?_atividadeNo={0}`;
  urlGetConfigModel = 'Model/GetConfigModel';

  getModelComponent(atividadeNo: number): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGetModelComponent, atividadeNo);
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {});
    } catch (error) {
      console.log(this.constructor.name, 'getModelComponent', error.message);
      return of(null);
    }
  }

  /* Retorna os dados de configuração do Idoso Bem Cuidado, baseado no token do usuário logado. */
  getGetConfigModel(): Observable<any> {
    try {
      const urlGet = this.basepage.format(this.urlGetConfigModel);
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {});
    } catch (error) {
      console.log(this.constructor.name, 'getGetConfigModel', error.message);
      return of(null);
    }
  }


}
