import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IProfissional,
  LocalLibService,
} from '@medlogic/shared/shared-interfaces';
import {
  map,
  publishReplay,
  refCount,
  filter,
  catchError,
  toArray,
  tap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import {
  CadastroService,
  ModelComponent,
} from '@medlogic/shared/shared-data-access';
import { ProfissionalService } from './profissional.service';

const error = () =>
  catchError((err, obs) => {
    console.log(err);
    return of(err);
  });

@Injectable({
  providedIn: 'root',
})
export class ProfissionalCustomService extends ProfissionalService {
  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    log: LogService,
    protected lib: LocalLibService,
    protected modelComponent: ModelComponent
  ) {
    super(http, cadastroSrv, glb, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IProfissional> {
    try {
       this.cadastroNo = ano;
       startDate = startDate || new Date(1900, 0, 1);
       endDate = endDate || new Date(2500, 0, 1);
       return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
        this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
}


public getCadastro(cadastroNo) {
  return this.cadastroSrv.getCadastro(cadastroNo, this.lstVariaveis).pipe(
    map((c) => this.toAttribute(c)),
    publishReplay(),
    refCount()
  );
}


  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`.
   * Também filtra especificamente a dataavaliacao dentro do período.
   */
  protected getFiltered(
    cadastroNo: number,
    strFilter: string,
    startDate: Date,
    endDate: Date
  ): Observable<IProfissional> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map((c) => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }


  /* OBS: Usado apenas pelo card-prescription. */
  getFromCadastroFiltro(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastroComFiltro(
          cadastroNo,
          this.lstVariaveis,
          filtro,
          true,
          startDate,
          endDate
        )
        .pipe(
          map((c) => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'getFromCadatro',
        error.message
      );
    }
    return of(null);
  }

  /* Check se está fora da referência. */
  protected isOutOfRef(value: number, minRef: number, maxRef: number): boolean {
    try {
      return value < minRef || value > maxRef;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isOutOfRef', error.message);
    }
    return false;
  }

  mapObjToProfissional(obj: {
    [key: string]: string | Date | number;
  }): Observable<IProfissional> {
    try {
      return of(obj).pipe(this.mapTo());
    } catch (error) {
      this.log.Registrar(
        this.constructor.name,
        'mapObjToVitalSign',
        error.message
      );
    }
    return of(null);
  }

  private mapTo = () => map((c: any) => this.toAttribute(c));

}
