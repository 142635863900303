import { ConfigStateService } from '@medlogic/shared/state-config';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LibService, DadoDAL } from '@medlogic/shared/geform';
import { IDado, LogService, GlobalService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { TbQueueService } from '@medlogic/shared/shared-jsstore';
import { WebService } from '@medlogic/shared/shared-data-access';
import { from } from 'rxjs';
import { forkJoin } from 'rxjs';
import { of } from 'rxjs';
import { concat } from 'rxjs';

@Injectable()
export class DadoDALCustom extends DadoDAL {

  method = 'setSaveCadastro';

  constructor(
    webService: WebService,
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    private tbQueue: TbQueueService,
  ) {
    super(webService, log, global, lib, cnf);
  }

  /* Ao invés de salvar de imediato, enfileira para posterior atualização. */
  saveToQueue(dados: IDado[], isVersion: boolean, uno: number): Observable<any> {
    try {
      return this.setAll(dados, isVersion, uno, false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'saveToQueue', error.message);
    }
  }

  /* Poderá enfileirar ou enviar para o serviço, conforme parâmetro executeNow. */
  setAll(
    dados: IDado[],
    isVersion: boolean,
    uno: number,
    executeNow: boolean = true) {
    try {
      if (executeNow) { // Executa a chamada no serviço
        return this.executeSetAll(dados, isVersion);
      } else { // enfileirar a chamada
        const item = {
          key: dados[0].OcorrenciaNo.toString(),
          cadastro: dados,
          ano: dados[0].AtividadeNo,
          uno
        };
        const queue = this.tbQueue.add(item);
        return from(queue);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setAll', error.message);
    }
  }

  /* Executa o envio do dado para o serviço. */
  executeSetAll<T>(
    dados: IDado[],
    isVersion: boolean
  ): Observable<any> {
    try {
      const method = 'setDado';
      const result = this.webService.connect<T>(method,
        [
          { name: '_strXml', value: this.toXml(dados) },
          { name: '_isVersion', value: isVersion }
        ]);
      // Necessário notificar o serviço sobre a finalização do salvamento para geração do XML
      result
        .subscribe(c =>
          c,
          (er) => this.log.Registrar(this.constructor.name, '', er),
          () => this.notificarConclusaoSalvamento(dados[0].AtividadeNo, dados[0].OcorrenciaNo)
        );
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setAll', error.message);
    }
  }

  /* Executa a fila de setSaveCadatro.
  * Também removerá o item da fila, caso o retorno seja positivo.
  * Para cada item executado e removido da fila, emitirá um observer.
  */
  executeQueue(): Observable<number> {
    try {
      return new Observable(observer => {
        this.tbQueue
          .get()
          .then((items) => {
            try {
              if (items && items.length > 0) {
                const observables =
                  items.map(e => {
                    try {
                      // Chamada em paralelo para permitir tando o retorno do Id criado, quando da key
                      const obs = forkJoin([
                        this.executeSetAll<number>(e.cadastro, true),
                        of(e.key)
                      ]);
                      return obs;
                    } catch (error) {
                      this.log.Registrar(this.constructor.name, 'executeQueue.forEach', error.message);
                    }
                  });
                // Concat garante a execução em série das chamadas
                const concatObservables = concat(observables);
                concatObservables
                  .subscribe((obs: any) => { // ([insertedId, key])
                    try {
                      const key = obs.sources[1].value;
                      obs.sources[0].subscribe(insertedId => {
                        if (insertedId && insertedId > 0) {
                          this.tbQueue.deleteByKey(key);
                          observer.next(insertedId);
                        }
                      }, (e) => console.log(e),
                        () => {
                          observer.complete();
                        });
                    } catch (error) {
                      this.log.Registrar(this.constructor.name, 'executeQueue.forEach.subscribe', error.message);
                    }
                  },
                    (e) => console.log(e),
                    () => {
                      // observer.complete();
                    });
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'executeQueue.then', error.message);
            }
          });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'executeQueue', error.message);
    }
  }

  /* Atenção: Ele é apenas um método de transformação e não vai, portanto, a um serviço. */
  mapToDados(componentes: IAtividadeComponenteDAL[], tarefaNo: number, ocorrenciaNo: number, usuarioNo: number): IDado[] {
    try {
      return componentes
        .map<IDado>(c =>
          ({
            VariavelNo: c.VariavelNo,
            TarefaNo: tarefaNo,
            UsuarioNo: usuarioNo,
            DtRegistro: this.global.RetornarAllXsdDateTime(new Date()),
            OcorrenciaNo: ocorrenciaNo,
            Versao: c.Versao,
            ValorTexto: this.lib.getValorTextoOrData(c, true),
            ValorData: this.lib.getValorData(c.ValorData, true),
            ValorInteiro: c.ValorInteiro || '',
            ValorNumero: c.ValorNumero || '',
            // ValorBit: c.ValorBit,
            // ValorXml: c.ValorT,
            AtividadeNo: c.AtividadeNo,
            // Calculos: c.Calcu,
          } as IDado));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToDados', error.message);
    }
    return null;
  }

  /* Cria a tag de uma propriedade do dado, mas somente se o valor não for nulo ou vazio. */
  protected addElement(dado: IDado, tagName: string): any {
    try {
      const elem = dado[tagName];
      if (this.global.IsNullOrEmpty(elem)) { return ''; }
      return `<${tagName}>${elem}</${tagName}>`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addElement', error.message);
    }
  }

  /* Cria o XML no formato necessário para envio ao serviço, tag ExecucaoDados. */
  protected toXml(dados: IDado[]): string {
    try {
      let strXml = '<ExecucaoDados>';
      // percorre a lista de dados
      dados.forEach(d => {
        try {
          // Somente adicionará o elemento caso tenha havido modificação no dado (seja texto ou data)
          if (!this.global.IsNullOrEmpty(d.ValorTexto) || !this.global.IsNullOrEmpty(d.ValorData)) {
            // forma um dado
            // tslint:disable-next-line: max-line-length
            let add = '<ExecucaoDado xmlns:xsi=\'http://www.w3.org/2001/XMLSchema-instance\' xmlns:xsd=\'http://www.w3.org/2001/XMLSchema\'>';
            for (const propertyName in d) { // para percorrer as propriedades do objeto
              if (propertyName) {
                const newData = this.addElement(d, propertyName);
                if (!this.global.IsNullOrEmpty(newData)) {
                  if (typeof newData === 'string') {
                    add += newData;
                  }
                }
              }
            }
            add += '</ExecucaoDado>';
            strXml += add;
          }
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'toXml.forEach', error.message);
        }
      });
      strXml += '</ExecucaoDados>';
      return strXml;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toXml', error.message);
    }
    return null;
  }

  /* Necessário para geração do XML */
  notificarConclusaoSalvamento(cadastroNo: number, ocorrenciaNo: number): void {
    try {
      const method = 'CadastroNotificarConclusaoSalvamento';
      this.webService.connect(method,
        [
          { name: 'CadastroNo', value: cadastroNo },
          { name: 'OcorrenciaNo', value: ocorrenciaNo }
        ])
        .subscribe(notificacao => console.log('notificacao', notificacao));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'notificarConclusaoSalvamento', error.message);
    }
  }


}
