import { ICampoAdicional } from '../interface/icampo-adicional';
import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'libCtrlGridSearch'
})
export class CtrlGridSearchPipe implements PipeTransform {
  constructor(private global: GlobalService, private log: LogService) { }

  transform(items: any[], keyword: string, columns: ICampoAdicional[]): any[] {
    if (this.global.IsNullOrEmpty(keyword)) { return items; }
    return items.filter((item) => {
      try {
        let isVisible = false;
        columns.every((c) => {
          try {
            const clmName = `V_${c.VariavelNo}`;
            const value = typeof item[clmName] === 'string' ? item[clmName] : item[clmName][0];
            if (this.global.ContemSemAcentos(keyword, value)) {
              isVisible = true;
              return false;
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'transform.filter.every', error.message);
          }
          return true;
        });
        return isVisible;
      } catch (error) {
        this.log.Registrar(this.constructor.name, 'transform.filter', error.message);
      }
      return false;
    });
  }
}
