// export enum EnUserRole {
//     customer = 'customer',
//     administrator = 'administrator'
// }

export enum EnCustomerAction {
    FirstAccess,
    CustomerRegistration,
    CustomerAction
}
