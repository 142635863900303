<mat-toolbar color="basic">

  <span class="spacer"></span>

  <mat-icon class="close-icon" (click)="onCloseClick()">close</mat-icon>

  <span class="bar-title">
    Baixa no Estoque
  </span>

</mat-toolbar>

<div class="grid-medication">

  <ng-container *ngIf="queues$ | async as queues; else empty">

    <h2>
      Os seguintes itens serão enviados para baixa no estoque:
    </h2>

    <ul>
      <li *ngFor="let queue of queues">{{ queue?.message }}</li>
    </ul>

  </ng-container>

  <ng-template #empty>
    <div class="empty">
      <h2>Não há itens de estoque para serem sincronizados!</h2>
      <img src="assets/img/stock-empty.png" />
    </div>
  </ng-template>

  <mat-toolbar class="bottom-bar">
    <button mat-raised-button (click)="onCloseClick()">OK</button>
  </mat-toolbar>

</div>