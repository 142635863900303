import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'numberPtbr'
})
export class NumberPtbrPipe implements PipeTransform {

  constructor(private global: GlobalService, private log: LogService) { }

  transform(value: any, decimalPlaces: number = 2): string {
    try {
      if (!value) {
        return value;
      }
      // Necessário pois o usuário pode ter deixado a máscara incompleta
      // TODO: validar as máscaras de forma que fiquem inválidas caso incompletas.
      value = value.toString().replace(',_', '');
      const typed = this.global.getTypedValue(value);
      const fixed = (typed.value as number)
        .toLocaleString(
          'pt-BR',
          {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces
          });
      // return fixed.toString().replace(".", ",");
      return fixed.toString();
      // return this.global.ConvertToBrazilianNumber(fixed, decimalPlaces, decimalPlaces);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
  }

}
