import { withLatestFrom, mergeMap, catchError } from 'rxjs/operators';
import { Injectable, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { IAppState } from '@medlogic/shared/shared-interfaces';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { of } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(
    private store: Store<IAppState>,
    private log: LogService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      return of(req)
        .pipe(
          withLatestFrom(this.store),
          mergeMap(([req2, state]) => {
            // Tenant key that will influence the cache key
            let reqWithUrl = req2.clone();
            if (state?.tenant?.login?.customerId && req2.method === 'GET') {
              const separator = req2.url.indexOf('?') >= 0 ? '&' : '?';
              reqWithUrl = req2.clone({
                url: `${req2.url}${separator}cacheCustomerId=${state?.tenant?.login.customerId}` // A inclusão do usuário na chave do cache faria com que o cache fosse muito maior e um usuário não se beneficiaria da nutrição por outros. &cacheUserId=${state?.tenant?.login.usuarioLogadoNo}
              });
            }
            // Authentication inject
            if ((req2.method === 'GET' || req2.method === 'POST' || req2.method === 'PUT' || req2.method === 'DELETE') && (state?.tenant?.token)) {
              const dupReq = reqWithUrl.clone({
                setHeaders: { Authorization: `Bearer ${state?.tenant?.token}` }
              });
              return next.handle(dupReq);
            }
            return next.handle(req2);
          }),
          catchError((err, obs) => {
            console.log('error()', err);
            const errorMsg = err?.error?.message || err?.statusText;
            return throwError(errorMsg);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'intercept', error?.message);
    }
    return next.handle(req);
  }


}

// tslint:disable-next-line: max-classes-per-file
@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
  ],
})
export class HttpTokenInterceptorModule { }
