export const prescriptionMaterialType = [
    { materialNome: "MEDICAMENTO" },
    { materialNome: "MANIPULADOS" },
    { materialNome: "EXAMES" },
    { materialNome: "DIETAS/SUPLEMENTOS" },
    { materialNome: "ALIMENTO" },
    { materialNome: "CUIDADOS" },
    { materialNome: "TERAPIA" },
    { materialNome: "HIGIENE PESSOAL" },
    { materialNome: "HOMEOPATIA" },
    { materialNome: "MATERIAL DE LIMPEZA" },
    { materialNome: "MATERIAL HOSPITALAR" },
];
