import { ConfigStateService } from '@medlogic/shared/state-config';
import { Control } from '../../model/control';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, ConfigJsonService, IAtividadeComponenteDAL, EnTheme } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraService } from '../../service/calculadora.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { FormGroup } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';
import { IBubble } from '../../interface/ibubble';

// https://murhafsousli.github.io/ngx-bar-rating/
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-ctr-rating-star',
  templateUrl: './ctr-rating-star.component.html',
  styleUrls: ['./ctr-rating-star.component.css']
})
export class CtrRatingStarComponent extends Control implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  /*Ao invés de utilizar formControlName está sendo utilizada essa propriedade, pois o carregamento do valor não está funcionando.
  * É um problema com esse componente de Date do Google Material.
  */
  // tslint:disable-next-line: variable-name
  private _rate: number;
  public get rate(): number {
    return this._rate;
  }
  public set rate(v: number) {
    try {
      this._rate = v;
      this.ctrl.ValorTexto = v;
      this.ctrl.Valor = v ? v.toString() : '';
      // Para disparar o evento de change necessário marcar o controle como dirty (AtividadeComponente.subscribeFormChanges)
      const fieldRate = this.formGroup.get(this.getId(this.ctrl));
      fieldRate.markAsDirty();
      fieldRate.setValue(v);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'set.rate', error.message);
    }
  }

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    dialog: MatDialog,
    msg: MsgPtBR
  ) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, dialog, msg);
  }

  // ngOnInit() { }

  onRate(variavelNo: number, ratingValue: number) {
    console.log(ratingValue);
  }

  /*override
   * Largura fixa, uma vez que se trata de componente pré-determinado.
   */
  getWidth(ctrl: IAtividadeComponenteDAL, ...args): string {
    try {
      if (this.global.isMobile()) {
        return `100%`;
      } else {
        return `120px`;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWidth', error.message);
    }
    return '';
  }
}
