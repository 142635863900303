import { MaterialModule } from '@medlogic/shared/material';
import { AppAssistModule } from './app-assist.module';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { InventoryViewComponent } from './kitchen/view/inventory/inventory.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { NumberInputDirective } from './kitchen/directive/number-input.directive';
import { GeCoreModule } from '@medlogic/shared/gecore';
import { LocalCoreModule } from './module/core/local-core.module';

@NgModule({
  declarations: [
    InventoryViewComponent,
    NumberInputDirective
  ],
  exports: [
    // InventoryViewComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    GeCoreModule,
    LocalCoreModule,
    AppAssistModule,
    TextMaskModule,
  ],
  providers: [
  ]
})
export class AppKitchenModule {

 /* Para previnir de multiplas importações desse módulo, que deve ser singleton */
  constructor( @Optional() @SkipSelf() parentModule: AppKitchenModule) {
    if (parentModule) {
      throw new Error(
        'AppKitchenModule is already loaded. Import it in the AppModule only');
    }
  }
}
