
import { map, flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ModelComponentService } from '../service/model-component.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Injectable()
export class ModelComponent {

    constructor(
        protected log: LogService,
        protected modelComponentSrv: ModelComponentService
    ) {

    }

    public getDados(atividadeNo: number): Observable<any> {
        try {
            return this.modelComponentSrv
                .getModelComponent(atividadeNo)
                .pipe(
                    flatMap(f => f),
                    map((m: any) => {
                        return { Rotulo: m.Rotulo, AtividadeCadastroNo: m.Valor, DefaultValue: m.DefaultValue };
                    }));
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getDados', error.message);
        }
        return of(null);
    }


}
