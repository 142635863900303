<div class="container-indicator">
  <span>Dados Gerais</span>

  <div class="container-icons" *ngIf="patientsIcons; else loading">
    <ng-container *ngIf="patientsIcons.length; else empty">
      <div class="flex" *ngFor="let icon of patientsIcons">
        <mat-icon
          aria-hidden="false"
          [class.enabled]="true"
          [svgIcon]="icon.display"
          (click)="alertaIcone(icon)"
        >
        </mat-icon>

        <div class="v-divider"></div>
      </div>
    </ng-container>
  </div>

  <div class="divider"></div>
</div>

<ng-template #loading>
  <br />
  <span>Carregando dados gerais...</span>
</ng-template>

<ng-template #empty>
  <br />
  <span>Não possui dados gerais cadastrados</span>
</ng-template>
