export interface IInteracao {
  realizadaCom: string; //  28323
  date: Date; // 27654
  desfecho: string; // 27655
  status: string; // 28320
  nomeCliente: string; // 3963
  idCliente: any; // 3962
  dtRetorno: Date; // 28324
  dtProximoContato: Date; // 28329
  dtVisita: Date;
  dtNegocioFechado: Date; // 28326
  dtRejeitado: Date; // 28327
}
