import { Injectable } from '@angular/core';
import { FhirGenericService } from './fhir-generic.service';


@Injectable({ providedIn: 'root' })
export class FhirScheduledService extends FhirGenericService {
  protected url = 'Scheduled';


}
