import { SmartListScoreComponent } from './smart-list-score/smart-list-score.component';
import { SmartListCalendarComponent } from './smart-list-calendar/smart-list-calendar.component';
import { MaterialModule } from '@medlogic/shared/material';
import { SharedUtilsModule } from '@medlogic/shared/utils';
import { UiLoadingModule } from '@medlogic/shared/ui/ui-loading';
import { SmartListComponent } from './smart-list/smart-list.component';
import { SmartListBadgeCalendarComponent } from './smart-list-badge-calendar/smart-list-badge-calendar.component';
import { SmartListBadgeScoreComponent } from './smart-list-badge-score/smart-list-badge-score.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartListBadgeImageComponent } from './smart-list-badge-image/smart-list-badge-image.component';
import { ClipTypeDirective } from './directive/clip-type.directive';
import { SmartListImageComponent } from './smart-list-image/smart-list-image.component';
import { SmartListActionComponent } from './smart-list-action/smart-list-action.component';
// import { PushPipe, LetDirective } from '@ngrx/component';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);
const COMPONENTS = [
  SmartListComponent,
  SmartListBadgeCalendarComponent,
  SmartListBadgeScoreComponent,
  SmartListCalendarComponent,
  SmartListBadgeImageComponent,
  SmartListImageComponent,
  SmartListScoreComponent,
  SmartListActionComponent
];

const PIPES_DIRECTIVES = [
  ClipTypeDirective
  // PushPipe,
  // LetDirective
];

const MODULES = [
  CommonModule,
  UiLoadingModule,
  SharedUtilsModule,
  MaterialModule
];

@NgModule({
  declarations: [COMPONENTS, PIPES_DIRECTIVES],
  imports: [MODULES],
  exports: [COMPONENTS]
})
export class UiSmartListModule { }
