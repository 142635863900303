
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEvolucaoFono, LocalLibService } from '@medlogic/shared/shared-interfaces';
import {
  map, publishReplay, refCount, filter,
  catchError,
  toArray,
  tap
} from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { CadastroService, ModelComponent } from '@medlogic/shared/shared-data-access';
import { EvolucaoFonoService } from './evolucao-fono.service';

const error = () => catchError((err, obs) => {
  console.log(err);
  return of(err);
});

@Injectable({
  providedIn: 'root'
})
export class EvolucaoFonoCustomService extends EvolucaoFonoService {

  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    log: LogService,
    protected lib: LocalLibService,
    protected modelComponent: ModelComponent,
  ) {
    super(http, cadastroSrv, glb, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna todos os itens dos sinais vitais através do id do paciente. */
  getByIdAndPeriod(ano: number, patientId: string, dtStart: Date = null, dtEnd: Date = null): Observable<any> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filterStr = `V_28051:${patientId}`;
      return this.getFiltered(this.cadastroNo, filterStr, startDate, endDate).pipe(
        toArray(),
         map((evolutions: IEvolucaoFono[]) => {
           let currentEvo = evolutions[0] as IEvolucaoFono;
           for (let i = 0; i < evolutions.length; i += 1) {
             const date = evolutions[i].dtEvolucao;
             if (currentEvo.dtEvolucao < date) {
               currentEvo = evolutions[i];
             }
           }
           return currentEvo
         }),
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByIdAndPeriod', error.message);
    }
    return of(null);
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`.
  * Também filtra especificamente a dataavaliacao dentro do período.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IEvolucaoFono> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)),
          filter((f: IEvolucaoFono) => this.glb.isBetweenIgnoreTime(f.dtEvolucao, startDate, endDate)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  sortByDate = () => map((v: IEvolucaoFono[]) =>
    v.sort(
      (a, b) =>
        b.dtEvolucao && a.dtEvolucao
          ? this.glb.compareDates(a.dtEvolucao, b.dtEvolucao)
          : 0
    )
  )

  /* OBS: Usado apenas pelo card-prescription. */
  getFromCadastroFiltro(cadastroNo: number, filtro: string, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, false, startDate, endDate)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  /* Check se está fora da referência. */
  protected isOutOfRef(value: number, minRef: number, maxRef: number): boolean {
    try {
      return value < minRef || value > maxRef;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isOutOfRef', error.message);
    }
    return false;
  }

  mapObjToEvolucaoFono(obj: { [key: string]: string | Date | number }): Observable<IEvolucaoFono> {
    try {
      return of(obj).pipe(this.mapTo());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapObjToVitalSign', error.message);
    }
    return of(null);
  }

  private mapTo = () => map((c: any) => this.toAttribute(c));

  // getLocationByMedlogicId(medlogicId): Observable<any> {
  //   try {
  //     // console.log('Recarregando dados...');
  //     // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
  //     const startDate = new Date(1900, 0, 1);
  //     const endDate = new Date(2500, 0, 1);
  //     const filter = `V_28051:${medlogicId}`
  //     return this.getFiltered(this.cadastroNo, filter, startDate, endDate)
  //       .pipe(
  //         map(a => a),
  //         toArray(),
  //         map((mov: any) => {
  //           let latestMov = {};
  //           for (let i = 0; i < mov.length; i += 1) {
  //             const prontuarioMEDLOGIC = mov[i].prontuarioMEDLOGIC;
  //             const date = new Date(mov[i].data);
  //             const andar = mov[i].andar;
  //             const bloco = mov[i].bloco;
  //             const quarto = mov[i].quarto;
  //             if ((!latestMov[prontuarioMEDLOGIC] || latestMov[prontuarioMEDLOGIC].date < date)) {
  //               latestMov[prontuarioMEDLOGIC] = { prontuarioMEDLOGIC, date, andar, bloco, quarto };
  //             }
  //           }
  //           return Object.values(latestMov);
  //         }),
  //         map(array => array[0]),
  //         publishReplay(),
  //         refCount()
  //       );
  //   } catch (error: any) {
  //      this.log.Registrar(this.constructor.name, 'getByFloor', error.message);
  //   }
  //   // return null;
  // }

}
