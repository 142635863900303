<fieldset class="rating">
  <input type="radio" [name]="inputName" value="5" [checked]="rating===5" />
  <label title="Excelente" (click)='onClick(5)'>5 stars</label>

  <input type="radio" [name]="inputName" value="4" [checked]="rating===4" />
  <label title="Bom" (click)='onClick(4)'>4 stars</label>

  <input type="radio" [name]="inputName" value="3" [checked]="rating===3" />
  <label title="Regular" (click)='onClick(3)'>3 stars</label>

  <input type="radio" [name]="inputName" value="2" [checked]="rating===2" />
  <label title="Ruim" (click)='onClick(2)'>2 stars</label>

  <input type="radio" [name]="inputName" value="1" [checked]="rating===1" />
  <label title="Péssimo" (click)='onClick(1)'>1 star</label>
</fieldset>
