export interface IEmployee {
    ocorrenciaNo: number;
    codigo: any;
    titulo: any;
    habilitado: boolean;
    email: any;
    dataNascimento: Date;
    cPF: any;
    nome: any;
    contatosFones: any;
    regiao: string;
    dtNasc: any;
    endereco: any;
    cep: any;
    bairro: any;
    cidade: any;
    estadoCivil: string;
    pai: any;
    mae: any;
    naturalidade: any;
    nFilhosMenores14Anos: any;
    nDependentes: any;
    grauInstrucao: any;
    valeTransporte: boolean;
    tarifaR: any;
    carteiraIdentidade: any;
    cargo: string;
    numeroRegistro: any;
    cTPS: any;
    orgaoEmissor: string;
    dataEmissao: Date;
    raca: any;
    pisPasep: any;
    serie: any;
    emissao: any;
    certMilitar: any;
    tituloEleitor: any;
    secao: any;
    zona: any;
    carteiraHabilitacao: any;
    categoria: any;
    salarioR: any;
    dataAdmissao: Date;
    eFumante: boolean;
    fazUsobebidaAlcoolica: boolean;
    religiao: any;
    eHipertenso: boolean;
    eDiabetico: boolean;
    temPlanosaude: boolean;
    fazTratamentoalgumaDoenca: boolean;
    seSimQuaisDoencas: any;
    temCursoCuidador: boolean;
    anoConclusao: any;
    temCursoTecnicoOuAuxiliarEnfermagem: boolean;
    seSimQualOnde: any;
    temCOREN: boolean;
    numeroCOREN: any;
    referenciasCitarLocaisOndeTrabalhouTelefonesporQuantoTempo: string;
    loginFuncionario: any;
    enviarEmailoFamiliar: boolean;
}
