import { createReducer, on } from '@ngrx/store';
import { loadProcesses, setProcess, selectProcess, fetchSuccessProcess } from '../actions/process.actions';

export const initialState = {};

// tslint:disable-next-line: variable-name
const _processReducer = createReducer(initialState,
  on(loadProcesses, (state, payload) => ({
    ...state,
    bannerTitle: 'PROCESSOS',
    selectedProcess: null
  })),
  on(fetchSuccessProcess, (state, payload) => ({
    ...state,
    allProcesses: payload.allProcesses
  })),
  on(selectProcess, (state, payload) => ({
    ...state,
    selectedProcess: payload.selectedProcess
  })),
  on(setProcess, (state, payload) => ({
    ...state,
    allProcesses: payload.allProcesses
  })),
);

export function processReducer(state, action) {
  return _processReducer(state, action);
}
