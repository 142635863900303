export enum EnPosology {
	p12in12h = '12 EM 12 HORAS',
	p8in8h = '8 EM 8 HORAS',
	p6in6h = '6 EM 6 HORAS',
	p1perDay = '1 POR DIA',
	perDay = 'INTERVALO DIAS',
	perWeek = 'INTERVALO SEMANAS',
	sos = 'SOS',
	customized = 'PERSONALIZADO',
	hourInterval = 'INTERVALO DE HORAS'
}
