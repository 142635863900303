import { AtividadeStandAloneComponent } from './view/atividade-stand-alone/atividade-stand-alone.component';
import { TarefaViewComponent } from './view/tarefa-view/tarefa-view.component';
import { AgendaViewComponent } from './view/agenda-view/agenda-view.component';
import { AtividadeCadastroViewComponent } from './view/atividade-cadastro-view/atividade-cadastro-view.component';
import { AtividadeViewComponent } from './view/atividade-view/atividade-view.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundViewComponent } from './view/page-not-found-view/page-not-found-view.component';
import { AtividadeCadastroDetailDialogComponent } from './view/atividade-cadastro-detail-dialog/atividade-cadastro-detail-dialog.component';
import { AtividadeProcessoComponent } from './view/atividade-processo/atividade-processo.component';
// import { MultiActivityListComponent } from '../module/search/view/multi-activity-list/multi-activity-list.component';
import { AtividadeMultiProcessoComponent } from './view/atividade-multi-processo/atividade-multi-processo.component';
// import { AuthChildGuardService } from '@medlogic/shared/gecore';
import { CadastroListViewComponent } from './view/cadastro-list-view/cadastro-list-view.component';

// Rota teste da dimensão Funcionalidade: http://localhost:4200/ge/list/2184/56194/false/true
const appRoutes: Routes = [
  {
    path: 'ge',
    // canActivateChild: [AuthChildGuardService],
    children: [
      // PADRONIZADAS
      { path: 'activity/new/:key/:theme', component: AtividadeViewComponent }, // Nova atividade
      { path: 'activity/new/:key/:pno/:theme', component: AtividadeViewComponent }, // Nova atividade
      { path: 'activity/edit/:key/:pno/:ono/:theme/:readonly', component: AtividadeViewComponent }, // Editar atividade


      // Processo, etapa de acesso web
      { path: 'standalone/:uno/:pno', component: AtividadeStandAloneComponent }, // Abre uma atividade isolada
      // Atividade Convencional, associada a Processo
      { path: 'processactivity/:uno/:pno/:saveInList', component: AtividadeProcessoComponent },
      {
        path: 'processactivity/:uno/:pno/:ono/:tno/:readonly/:saveInList',
        component: AtividadeProcessoComponent
      },
      // Multi-atividade, associada a Processo
      { path: 'multiactivity/:ano/:pno/:mano', component: AtividadeMultiProcessoComponent },
      { path: 'multiactivity/:ano/:pno/:mano/:ono', component: AtividadeMultiProcessoComponent },
      // Atividade
      { path: 'activity/:key/:ono/:readonly', component: AtividadeViewComponent }, // Editar atividade
      // Cadastro lista
      { path: 'activity/list/:key/:theme/:readonly', component: CadastroListViewComponent },
      { path: 'activity/list/:key/:readonly', component: CadastroListViewComponent },
      // Cadastro
      { path: 'list/:key/:saveinlist', component: AtividadeCadastroViewComponent }, // Novo cadastro
      {
        path: 'list/:key/:ono/:readonly/:saveinlist',
        component: AtividadeCadastroViewComponent,
        data: { animation: 'Activity' }
      }, // Editar/Consultar cadastro
      // Cadastro Detalhe (edição/novo item de grid ou combobox)
      {
        path: 'listdetail/:key/:listvno/:saveinlist',
        component: AtividadeCadastroDetailDialogComponent,
        data: { animation: 'ActivityDetail' }
      }, // Novo cadastro
      {
        path: 'listdetail/:key/:ono/:listvno/:readonly/:saveinlist',
        component: AtividadeCadastroDetailDialogComponent
      }, // Editar/Consultar cadastro
      // Agenda
      { path: 'schedule', component: AgendaViewComponent },
      // Tarefa
      { path: 'task/:key/:time', component: TarefaViewComponent },
      // tslint:disable: max-line-length
      { path: '', redirectTo: '/ge/list/2243/222280/false/true', pathMatch: 'prefix' },
      { path: '**', component: PageNotFoundViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule]
})
export class GeFormRoutingModule { }
