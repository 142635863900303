import { NativeDateAdapter } from '@angular/material/core';
import { Directive, Inject } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
const SUPPORTS_INTL_API = typeof Intl !== 'undefined';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'date-format',
})
// tslint:disable-next-line: directive-class-suffix
export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;

  constructor(@Inject('MAT_DATE_LOCALE') matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }

  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}
