export interface IPrimeiraAtividade {
    AtividadeNo: number;
    Nome: string;
    Width: number;
    Height: number;
    ClienteNo: number;
    Type: string;
    blInativo: boolean;
    Versao: number;
    VersaoConteudo: number;
    TotalAcesso: number;
    CustomProperty: string;
    IconNo: number;
    NumExecucao?: any;
    AtividadeNoOld?: any;
    TabName: string;
    NomeCampo: string;
    TabIndex: number;
    CadastroPapelNo: number;
    ShowPrint: boolean;
    TextPrint: string;
    ShowHistory: boolean;
    TextHistory: string;
    ShowBack: boolean;
    TextBack: string;
    TextBackFormula?: any;
    ShowSave: boolean;
    TextSave: string;
    ShowComplete: boolean;
    TextComplete: string;
    AskConfirmation: boolean;
    ConfirmationTitle: string;
    ConfirmationMessage: string;
    ConfirmationBtnOK: string;
    ConfirmationBtnCancel: string;
    ShowAttachment: boolean;
    TextAttachment: string;
    ShowQRCode: boolean;
    TextQRCode: string;
    ShowMessage: boolean;
    TextMessage: string;
    ShowSaveAndNew: boolean;
    TextSaveAndNew: string;
    TextSaveAndNewTime: number;
    ShowSaveModel: boolean;
    TextSaveModel: string;
    IsEncrypted: boolean;
    EncryptedValidationKeyword?: any;
    ShowName: boolean;
    ShowUrgent: boolean;
    IsUrgent: boolean;
    IsOpenFind: boolean;
    IsSaveRegister: boolean;
    VariableIdentify: string;
    VariablesFind: string;
    VariablesSet: string;
    ShowPrevious: boolean;
    TextPrevious: string;
    SetPreviousTabName: boolean;
    ShowNext: boolean;
    TextNext: string;
    SetNextTabName: boolean;
    UrlCustomReport?: any;
    CalcTarefaNo: number;
    _AtividadeComponente?: any;
    CalcLstAtividadeSequencial?: any;
    CalcblDestinatario: boolean;
    CalcblDataTarefa: boolean;
    CalcblHoraTarefa: boolean;
    CalcAtribuicaoTitulo: string;
    CalcAtribuicaoDestinatarioTitulo: string;
    CalcEnAtribuicaoTipo: number;
    CalcAgendamentoListaUsuarioPapelNo: number;
    CalcVariavelIdentificacao1No: number;
    CalcVariavelIdentificacao2No: number;
    CalcVariavelIdentificacao1Valor?: any;
    CalcVariavelIdentificacao2Valor?: any;
    CalcProcessoNoBaseadoTarefa?: any;
    CalcAtribuicaoPermitirUso: string;
    CalcAtribuicaoUsuarioPublico: number;
    CalcAtribuicaoMensagemPublico: string;
    UrlPrint: string;
    LstAtividadeComponenteExecTaref?: any;
    LstAtividadeComponenteUsuario?: any;
    permission?: any;
    ProcessoNo: number;
    TarefaNo: number;
    OcorrenciaNo: number;
}