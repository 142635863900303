import { IMedlogicEvolution } from '@medlogic/shared/shared-interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as EvolutionActions from './evolution.actions';
import { adapterMedlogicEvolution } from '@medlogic/medlogic/medlogic-shared-interfaces';

export const evolutionsFeatureKey = 'evolutions';

export interface State extends EntityState<IMedlogicEvolution> {
  // additional entities state properties
  error: string;
}

export const adapter: EntityAdapter<IMedlogicEvolution> = createEntityAdapter<IMedlogicEvolution>({
  selectId: instance => instance.codigo
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  error: undefined
});


export const reducer = createReducer(
  initialState,
  on(EvolutionActions.addEvolution,
    (state, action) => adapter.addOne(action.evolution, state)
  ),
  on(EvolutionActions.setEvolution,
    (state, action) => ({ ...state, selectedId: action?.selectedId })
  ),
  on(EvolutionActions.loadEvolutionsByPeriod,
    (state, action) => ({ ...state, dtStart: action?.dtStart, dtEnd: action?.dtEnd })
  ),
  on(EvolutionActions.loadEvolutionsBySelectedPatient,
    (state, action) => ({ ...state, dtStart: action?.dtStart, dtEnd: action?.dtEnd })
  ),
  on(EvolutionActions.upsertEvolution,
    (state, action) => adapter.upsertOne(action.evolution, state)
  ),
  on(EvolutionActions.addEvolutions,
    (state, action) => adapter.addMany(action.evolutions, state)
  ),
  on(EvolutionActions.upsertEvolutions,
    (state, action) => adapter.upsertMany(action.evolutions, state)
  ),
  on(EvolutionActions.updateEvolution,
    (state, action) => adapter.updateOne(action.evolution, state)
  ),
  on(EvolutionActions.updateEvolutions,
    (state, action) => adapter.updateMany(action.evolutions, state)
  ),
  on(EvolutionActions.deleteEvolution,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(EvolutionActions.deleteEvolutions,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(EvolutionActions.loadEvolutionsSuccess,
    (state, action) => adapter.upsertMany(action.evolutions, state)
  ),
  on(EvolutionActions.evolutionFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(EvolutionActions.clearEvolutions,
    state => initialState
  ),
);
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterMedlogicEvolution.getSelectors();

