import { IQueueState } from '@medlogic/shared/shared-interfaces';
import { IQueue } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as QueueActions from './queue.actions';

export const queuesFeatureKey = 'queue';


export const adapterQueue: EntityAdapter<IQueue> = createEntityAdapter<IQueue>({
  selectId: instance => instance.key,
  // sortComparer: usersSortFn
});

export const initialStateQueue: IQueueState = adapterQueue.getInitialState({
  // additional entity state properties
  isExecuting: false,
  error: undefined,
});

// function usersSortFn(a: IQueue, b: IQueue) {
//   return a?.id - b?.id;
// }

export const reducer = createReducer(
  initialStateQueue,
  on(QueueActions.queue,
    (state, action) => adapterQueue.addOne(action?.queue, state)
  ),
  on(QueueActions.dequeue,
    (state, action) => {
      const size = state?.ids?.length;
      const isExecuting = size > 1;
      if (size > 0) {
        const firstId = state?.ids[0];
        return adapterQueue.removeOne(firstId?.toString(), {...state, isExecuting});
      }

      return { ...state, isExecuting};
    }),
  on(QueueActions.clearQueue,
    state => adapterQueue.removeAll({ ...state, error: undefined, isExecuting: false })
  ),
  on(QueueActions.setQueueIsExecuting,
    (state, action) => ({ ...state, isExecuting: action.isExecuting })
  ),
  on(QueueActions.queueFail,
    (state, action) => ({ ...state, isExecuting: false })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterQueue.getSelectors();
