
import { Injectable } from '@angular/core';
import { VitalSignService } from './vital-sign.service';
import { Observable } from 'rxjs';
import { IVitalSign, LocalLibService } from '@medlogic/shared/shared-interfaces';
import {
  map, publishReplay, refCount, filter,
  catchError, toArray, defaultIfEmpty, mergeMap
} from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { CadastroService, ModelComponent } from '@medlogic/shared/shared-data-access';

const error = () => catchError((err, obs) => {
  console.log(err);
  return of(err);
});

@Injectable({
  providedIn: 'root'
})
export class VitalSignCustomService extends VitalSignService {

  constructor(
    http: HttpClient,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    log: LogService,
    protected lib: LocalLibService,
    protected modelComponent: ModelComponent,
  ) {
    super(http, cadastroSrv, glb, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Retorna todos os itens dos sinais vitais através do id do paciente. */
  getByIdAndPeriod(ano: number, patientId: string, dtStart: Date = null, dtEnd: Date = null): Observable<IVitalSign> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filterStr = `V_2230:${patientId}`;
      return this.getFiltered(this.cadastroNo, filterStr, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByIdAndPeriod', error.message);
    }
    return of(null);
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`.
  * Também filtra especificamente a dataavaliacao dentro do período.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IVitalSign> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)),
          filter((f: IVitalSign) => this.glb.isBetweenIgnoreTime(f.dataavaliacao, startDate, endDate)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  sortByDate = () => map((v: IVitalSign[]) =>
    v.sort(
      (a, b) =>
        b.dataavaliacao && a.dataavaliacao
          ? this.glb.compareDates(a.dataavaliacao, b.dataavaliacao)
          : 0
    )
  )

  /* OBS: Usado apenas pelo card-prescription. */
  getFromCadastroFiltro(cadastroNo: number, filtro: string, startDate: Date, endDate: Date): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true, startDate, endDate)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  /* Checa se o paciente possui o registro mais recente de sinal vital fora das
* referências definidas no cadastro do idoso bem cuidado, na coleta mais recente.
*/
  getByIdAndPeriodWithOcorrences(cadVitalSignNo: number, codigoPaciente: string, tenantId: number, dtStart: Date = null, dtEnd: Date = null): Observable<any> {
    try {
      this.cadastroNo = cadVitalSignNo;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const cnf$ = this.modelComponent.getDados(tenantId).pipe(toArray());
      const filtered$ = this.getByIdAndPeriod(cadVitalSignNo, codigoPaciente, startDate, endDate).pipe(toArray());
      const join$ = forkJoin([cnf$, filtered$])
        .pipe(
          mergeMap(([cnf, vitalSigns]) => of([cnf, vitalSigns])
            .pipe(
              this.calcHasOccurrence(),
              map(calcOcurrences => ({ vitalSigns, calcOcurrences }))
            )
          ),
          error()
        );
      return join$;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByIdAndPeriodWithOcorrences', error.message);
    }
    return of(null);
  }

  /* Checa se o paciente possui o registro mais recente de sinal vital fora das
  * referências definidas no cadastro do idoso bem cuidado, na coleta mais recente.
  */
  getHasOccurrence(cadVitalSignNo: number, codigoPaciente: string, tenantId: number, dtStart: Date = null, dtEnd: Date = null): Observable<any> {
    try {
      this.cadastroNo = cadVitalSignNo;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const cnf$ = this.modelComponent.getDados(tenantId).pipe(toArray());
      const filtered$ = this.getByIdAndPeriod(cadVitalSignNo, codigoPaciente, startDate, endDate)
        .pipe(
          toArray(),
          this.sortByDate(),
          defaultIfEmpty(null)
        );
      const join$ = forkJoin([cnf$, filtered$])
        .pipe(
          this.calcHasOccurrence(),
          error()
        );
      return join$;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getHasOccurrence', error.message);
    }
    return of(null);
  }

  /* Operador personalizado que determina se o primeiro sinal vital do array está fora da referência. */
  calcHasOccurrence = () => map(([cnf, vitalSigns]) => {
    if (vitalSigns?.length <= 0 || !cnf) {
      return null;
    }
    const vitalSign = this.glb.isArray(vitalSigns) ? vitalSigns[0] as IVitalSign : vitalSigns;
    // tslint:disable: variable-name
    const Cardio_minRef = this.lib.getDefault(cnf, 'Cardio_minRef');
    const Cardio_maxRef = this.lib.getDefault(cnf, 'Cardio_maxRef');
    const Pressure_minRef = this.lib.getDefault(cnf, 'Pressure_minRef');
    const Pressure_maxRef = this.lib.getDefault(cnf, 'Pressure_maxRef');
    const Breath_minRef = this.lib.getDefault(cnf, 'Breath_minRef');
    const Breath_maxRef = this.lib.getDefault(cnf, 'Breath_maxRef');
    const Glicemia_minRef = this.lib.getDefault(cnf, 'Glicemia_minRef');
    const Glicemia_maxRef = this.lib.getDefault(cnf, 'Glicemia_maxRef');
    const Glucose_minRef = this.lib.getDefault(cnf, 'Glucose_minRef');
    const Glucose_maxRef = this.lib.getDefault(cnf, 'Glucose_maxRef');
    const O2_minRef = this.lib.getDefault(cnf, 'O2_minRef');
    const O2_maxRef = this.lib.getDefault(cnf, 'O2_maxRef');
    const Temperature_minRef = this.lib.getDefault(cnf, 'Temperature_minRef');
    const Temperature_maxRef = this.lib.getDefault(cnf, 'Temperature_maxRef');
    const Dor_minRef = this.lib.getDefault(cnf, 'Dor_minRef');
    const Dor_maxRef = this.lib.getDefault(cnf, 'Dor_maxRef');
    const hasCardio = this.isOutOfRef(+vitalSign.frequenciaCardiacaBpm, Cardio_minRef, Cardio_maxRef);
    const hasPressure = this.isOutOfRef(+vitalSign.pADiastolicaRef80, Pressure_minRef, Pressure_maxRef);
    const hasBreath = this.isOutOfRef(+vitalSign.frequenciaRespiratoriaICP, Breath_minRef, Breath_maxRef);
    const hasGlicemia = this.isOutOfRef(+vitalSign.glicemiaCapilarEmJejumMlDl, Glicemia_minRef, Glicemia_maxRef);
    const hasGlucose = this.isOutOfRef(+vitalSign.glicose, Glucose_minRef, Glucose_maxRef);
    const hasO2 = this.isOutOfRef(+vitalSign.saturacaoOxigenioSO, O2_minRef, O2_maxRef);
    const hasTemperature = this.isOutOfRef(this.glb.getTypedValue(vitalSign.temperaturaTax).value, Temperature_minRef, Temperature_maxRef);
    return {
      codigoPaciente: +vitalSign.codigoPaciente,
      hasCardio,
      hasPressure,
      hasBreath,
      hasGlicemia,
      hasGlucose,
      hasO2,
      hasTemperature,
      calcHasOccurrence: hasCardio || hasPressure || hasBreath || hasGlicemia || hasGlucose || hasO2 || hasTemperature
    } as any;
  })

  /* Check se está fora da referência. */
  protected isOutOfRef(value: number, minRef: number, maxRef: number): boolean {
    try {
      return value < minRef || value > maxRef;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isOutOfRef', error.message);
    }
    return false;
  }

  mapObjToVitalSign(obj: { [key: string]: string | Date | number }): Observable<IVitalSign> {
    try {
      return of(obj).pipe(this.mapTo());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapObjToVitalSign', error.message);
    }
    return of(null);
  }

  private mapTo = () => map((c: any) => this.toAttribute(c));

}
