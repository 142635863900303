import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { LibService } from '@medlogic/shared/geform';
import { IAccount } from '../interface/iaccount';
import { GlobalService, LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';

@Injectable()
export class AccountCustomService extends AccountService {

  constructor(
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnf: ConfigPwaMedLogicService,
    lib: LibService,
    log: LogService) {
    super(cadastroSrv, glb, cnf, lib, log);
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  map(componentes: IAtividadeComponenteDAL[]): Observable<IAccount> {
    try {
      let cs = { OcorrenciaNo: componentes[0].OcorrenciaNo, index: componentes[0].OcorrenciaNo };
      cs = {
        ...cs, ...componentes.reduce((obj, ctrl) => {
          obj[`V_${ctrl.VariavelNo}`] = ctrl.Valor;
          return obj;
        }, {})
      };
      return of(cs).pipe(this.mapTo());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'map', error.message);
    }
    return of(null);
  }

  /* Get the account by the verification code.
  */
  getByCode(ano: number, verificationCode: string): Observable<IAccount> {
    try {
      this.cadastroNo = ano;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const strFilter = `V_103185:BARCODE;${verificationCode}`;
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCode', error.message);
    }
    return of(null);
  }


}
