import { IEmailMessage } from '@medlogic/shared/shared-interfaces';
import { createAction, props } from '@ngrx/store';

export const sendEmail = createAction(
  '[Evolution] Send Email',
  props<{ message: IEmailMessage }>()
);

export const sendEmailFail = createAction(
  '[Evolution] Send Email Fail',
  props<{ error: string }>()
);

export const sendEmailSuccess = createAction(
  '[Evolution] Send Email Success',
  props<{ message: IEmailMessage }>()
);

export const cleanEmails = createAction(
  '[Evolution] Clean Email'
);
