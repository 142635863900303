
import { toArray, map, filter, distinct } from 'rxjs/operators';
import { CustomerConfigService } from '../service/customer-config.service';
import { AppLogService, ICadParam } from '@medlogic/shared/shared-interfaces';
import { ActivatedRoute, Params } from '@angular/router';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { IServiceProvider } from '../interface/iservice-provider';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { OnDestroy } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { BaseNavigationService } from '@medlogic/shared/shared-data-access';

/** ATENÇÃO: Essa classe precisa estar no projeto principal e não na library, pois,
 * realiza acesso a session o que é proibido para uma biblioteca.
 */
import * as sessionstorage from 'sessionstorage';

/* Define os métodos para resgate de parâmetros de configuração dos cadastros. */
export abstract class HomeView extends UnsubscribeOnDestroyAdapter implements OnDestroy {

  cadParams: ICadParam[];
  lstConfig: any[] = [];
  currentRoute: any[];

  constructor(
    protected log: AppLogService,
    protected glb: GlobalService,
    protected cnf: ConfigStateService,
    protected route: ActivatedRoute,
    protected nav: BaseNavigationService,
    protected modelComponent: ModelComponent,
    protected customerCnf: CustomerConfigService,
    protected msg: MsgPtBR
  ) {
    super();
    this.setCadParams();
    this.cnf.showMenu = true;
  }

  /* Deve ser definido em cada classe herdeira para especificar os parâmetros que devem ser resgatados do Cadastro de Configurações.
   * Preencher this.cadParams = [{ idName: 'CadNoNome', label: 'Rótulo' }, ...]
  */
  protected abstract setCadParams(): void;

  /* Nesse método, a rota que será armazenada no histório deverá ser definida */
  protected abstract setCurrentRoute(): void;

  /* Primeiro Zera o histórico, pois, como é uma página HomeView, deve ser a raiz de uma hierarquia de navegação.
   * Adiciona a rota ao histórico, caso tenha sido definida. */
  protected addToHistory(): void {
    try {
      this.nav.removeAllHistory();
      if (this.currentRoute) {
        this.nav.addToHistory(this.currentRoute, this.msg.MODULE_NAME_HOME);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addToHistory', error.message);
    }
  }
  /* Valida se todas as chaves dos cadastros necessários já estão armazenados na session. */
  protected isAlreadSavedInSession(): boolean {
    try {
      if (!sessionstorage || !sessionstorage.getItem('modeloConfigNo')) { return false; }
      return this.cadParams.reduce((a, b) => sessionstorage.getItem(a) && sessionstorage.getItem(b), false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isAlreadSavedInSession', error.message);
    }
    return false;
  }

  /* Retorna o id do CadParam */
  protected getParam(idName: string, cadParams: ICadParam[] = null): number {
    try {
      cadParams = cadParams || this.cadParams;
      return cadParams.find(f => this.glb.isEqual(f.idName, idName)).id;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getParam', error.message);
    }
  }

  /* Retorna o valor default, conforme cadastrado no CAD - CONFIG IDOSO BEM CUIDADO (Registro e não apenas modelo). */
  protected getDefaultValue(idName: string, cadParams: ICadParam[] = null): any {
    try {
      cadParams = cadParams || this.cadParams;
      const find = cadParams ? cadParams.find(f => this.glb.isEqual(f.idName, idName)) : null;
      return find ? find.defaultValue : null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getParam', error.message);
    }
  }

  /* Extrai os parâmetros passados na url/rota */
  protected getCadParams(route: ActivatedRoute): Observable<ICadParam[]> {
    try { // Os parametros estão sendo passados diretamente aos componentes
      return new Observable(observer => {
        try {
          if (this.isAlreadSavedInSession()) { // Se essa session já foi armazenada, todos os demais parâmetros também
            this.cnf.modeloConfigNo = sessionstorage.getItem('modeloConfigNo');
            const cadParams = this.fillParamsFromSession();
            this.fillConfigParams(cadParams);
            observer.next(cadParams);
            this.setCurrentRoute();
            this.addToHistory();
            observer.complete();
          }
          // Para aguardar o carregamento do config
          this.subs.sink = route.params
            .subscribe((params: Params) => {
              try {
                if (params.modeloConfigNo) {
                  this.cnf.modeloConfigNo = +params.modeloConfigNo;
                  sessionstorage.setItem('modeloConfigNo', this.cnf.modeloConfigNo);
                }
                if (sessionstorage.getItem('modeloConfigNo') != null) {
                  this.cnf.modeloConfigNo = sessionstorage.getItem('modeloConfigNo');
                  this.subs.sink = this.setSessionAndGetCadParams(this.cnf.modeloConfigNo)
                    .subscribe(cadParams => {
                      try {
                        this.fillConfigParams(cadParams);
                        this.setCurrentRoute();
                        this.addToHistory();
                        observer.next(cadParams);
                        observer.complete();
                      } catch (error) {
                        this.log.Registrar(this.constructor.name, 'getUrlParms.setSessionAngGetCadParams.subscribe', error.message);
                      }
                    });
                } else {
                  alert('Erro ao buscar configurações!');
                  observer.next();
                  observer.complete();
                }
              } catch (error) {
                this.log.Registrar(this.constructor.name, 'getUrlParams.subscribe', error.message);
              }
            });
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getUrlParams.Observer', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /* O config que é injetado em várias classes possui parâmetros de configuração que precisam ser preenchidos.
   * TODO: Modificar o resgate do parâmetro de config para extrair de session, senão ficam dois mecanismos que contém os mesmos parâmetros.
   */
  protected fillConfigParams(cadParams: ICadParam[]): void {
    try {
      cadParams.forEach(e => {
        try {
          this.cnf[e.idName] = e.id;
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'fillConfigParams.forEach', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillConfigParams', error.message);
    }
  }

  /* Resgata o Cadastro de Configurações que contém os ids dos demais cadastros, do serviço
   * As sessionstorage, uma para cada cadastro, será armazenada nesse método.
  */
  protected setSessionAndGetCadParams(modeloConfigNo: number): Observable<ICadParam[]> {
    try {
      if (modeloConfigNo > 0) {
        // Buscar dados de configuração
        return forkJoin([
          this.modelComponent
            .getDados(modeloConfigNo).pipe(
              toArray()),
          // FIXME: Não funciona, pois, na verdade, esse cadastro não possui
          // registros salvos. esse método retornaria os registros e não o
          // valor default do modelo.
          this.customerCnf
            .getAll(modeloConfigNo).pipe(
              toArray())
        ]).pipe(
          map(([lstCadastroConfig, customerCnfs]) => {
            this.cadParams
              .forEach(e => {
                try {
                  const itemCadastro = lstCadastroConfig.find(x => this.glb.isEqual(x.Rotulo, e.label));
                  const cnf = customerCnfs[0];
                  e.id = parseInt(itemCadastro.AtividadeCadastroNo, 10);
                  if (cnf && e.hasOwnProperty('property') && e.property) {
                    e.defaultValue = cnf[e.property];
                  }
                  sessionstorage.setItem(e.idName, e.id);
                } catch (error) {
                  this.log.Registrar(this.constructor.name, 'getConfiguracao.forEach', error.message);
                }
              });
            return this.cadParams;
          }));
      } else {
        alert('Erro ao buscar configurações!');
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getConfiguracao', error.message);
    }
    return null;
  }

  protected fillParamsFromSession(): ICadParam[] {
    try {
      this.cadParams.forEach(e => {
        try {
          e.id = parseInt(sessionstorage.getItem(e.idName), 10);
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'fillParamsFromSession.forEach', error.message);
        }
      });
      return this.cadParams;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillParamsFromSession', error.message);
    }
  }

  /* Carrega o array de propriedades, a partir de uma chamada de um Service.
   * É uma forma de generalizar a chamada do método getAll e já abastercer uma coleção de items (propertyArray).
  */
  // tslint:disable-next-line: ban-types
  protected loadProperties(service: IServiceProvider, idName: string, propertyArray: any[], filterFunc: Function = null): void {
    try {
      let selectedProperty;
      const func = () => true;
      filterFunc = filterFunc || func;
      this.subs.sink = service
        .loadArray(this.getParam(idName))
        .pipe(
          filter(f => filterFunc(f))
        )
        .subscribe(s => {
          try {
            propertyArray.push(s);
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'loadProperties.subscribe', error.message);
          }
        },
          (er) => console.log('HomeView.loadArray:', er),
          () => selectedProperty = propertyArray && propertyArray.length > 0 ? propertyArray[0] : ''
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadProperties', error.message);
    }
  }

  /*
   * É uma forma de generalizar a chamada do método getAll e já abastercer uma coleção de strings contendo apenas o título (propertyArray).
   * Carrega o array de propriedades, mas apenas a label como string,
   * a partir de uma chamada de um Service. */
  protected loadPropertiesLabel(
    service: IServiceProvider,
    idName: string,
    propertyArray: any[],
    // tslint:disable-next-line: ban-types
    filterFunc: Function = null): Observable<any> {
    try {
      const func = (f) => !this.glb.isNullOrEmpty(f.label);
      filterFunc = filterFunc || func;
      return new Observable(observer => {
        try {
          this.subs.sink = service
            .loadArray(this.getParam(idName))
            .pipe(
              filter(f => filterFunc(f)),
              map(m => m.label || ''),
              // O uso de caixa alta interfere no reconhecimento do campo nas combos do GE. ? m.label.toString().toUpperCase() :
              distinct()
            )
            .subscribe(s => {
              try {
                observer.next(s);
                propertyArray.push(s);
              } catch (error) {
                this.log.Registrar(this.constructor.name, 'HomeView.loadPropertiesLabel.subscribe', error.message);
              }
            },
              (er) => console.log('HomeView.loadPropertiesLabel:', er),
              () => observer.complete()
            );
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'loadPropertiesLabel.Observable', error.message);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadPropertiesLabel', error.message);
    }
  }


}
