import { ILogin, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Injectable } from '@angular/core';
import { go, back, forward } from '@medlogic/shared/ngrx-router';
import { Store } from '@ngrx/store';
import { tap, take, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MedLogicNavigationService extends UnsubscribeOnDestroyAdapter {

  constructor(
    private store: Store<IAppMedlogicState>
  ) {
    super();
  }

  navigateToRoot(): void {
    this.subs.sink = this.store
      .pipe(
        take(1),
        tap(state => {
          const customerId = (state?.login?.selectedLogin as ILogin)?.customerId || state?.tenant?.customerId;
          return customerId ?
            this.navigateToPersonList() :
            this.navigateToWelcome();
        }),
      ).subscribe();
  }

  navigateTo(path: string[]): void {
    path[0] = path[0].startsWith('/') ? path[0] : `/${path[0]}`;
    this.store.dispatch(go({ to: { path } }));
  }

  navigateToFrailty(): void {
    this.store.dispatch(go({ to: { path: ['/exam/frailty'] } }));
  }

  navigateToLogin(): void {
    this.store.dispatch(go({ to: { path: ['/exam'] } }));
  }

  navigateToPrivacy(): void {
    this.store.dispatch(go({ to: { path: ['/new-user/privacy'] } }));
  }

  navigateToRegister(): void {
    this.store.dispatch(go({ to: { path: ['/new-user/register'] } }));
  }

  navigateToWelcome(): void {
    // this.store.dispatch(go({ to: { path: ['/new-user/welcome'] } }));
    this.store.dispatch(go({ to: { path: ['/exam/person/list'] } }));
  }

  navigateToPersonList(): void {
    this.store.dispatch(go({ to: { path: ['/exam/person/list'] } }));
  }

  // navigateToPersonAction(): void {
  //   this.store.dispatch(go({ to: { path: ['/person/action'] } }));
  // }

  // navigateToPersonEdit(): void {
  //   this.store.dispatch(go({ to: { path: ['/person/edit'] } }));
  // }

  navigateToExamList(): void {
    this.store.dispatch(go({ to: { path: ['/exam/list'] } }));
  }

  navigateToProcess(): void {
    this.store.dispatch(go({ to: { path: ['/exam/process'] } }));
  }

  navigateByProcess(processId: number): void {
    let path = '';
    switch (processId) {
      case 1058: // snapiv
        path = '/person/snapiv/list';
        break;
      case 1059: // eventos
        path = '/person/event/list';
        break;
      case 1231:
        path = 'person/evolution/list';
        break;
      case 1293:
        path = 'person/history';
        break;
      case 3:
        path = '';
      case 999:
        path = '/person/carecore/list';
        break;
      default:
        path = 'generic/list';
        break;
    }
    this.store.dispatch(go({ to: { path: [path] } }));
  }

  // navigateToGenericActionEdit(
  //   ano: number,
  //   pno: number,
  //   ono: number,
  //   tno: number,
  //   enTheme: EnTheme,
  //   readOnly: boolean
  // ): void {
  //   this.store.dispatch(go({ to: { path: ['/generic/edit', ano, pno, ono, tno, enTheme, readOnly] } }));
  // }

  navigateToGenericList(): void {
    this.store.dispatch(go({ to: { path: ['/exam/generic/list'] } }));
  }

  navigateToGenericEdit(): void {
    this.store.dispatch(go({ to: { path: ['/exam/generic/edit'] } }));
  }

  navigateToGenericNew(): void {
    this.store.dispatch(go({ to: { path: ['/exam/generic/new'] } }));
  }

  navigateBack(): void {
    this.store.dispatch(back());
  }

  navigateForward(): void {
    this.store.dispatch(forward());
  }

  // Custom Modules
  navigateToCustomModule(ono: number, pno: number, isNew: boolean): void {
    switch (pno) {
      case 1000:
        if (isNew) {
          this.navigateToPrescriptionNew();
        } else {
          this.navigateToPrescriptionEdit(ono);
        }
        break;
    }
  }

  navigateToPrescriptionNew(): void {
    this.store.dispatch(go({ to: { path: ['/exam/prescription/new'] } }));
  }

  navigateToPrescriptionEdit(prescriptionId: number): void {
    this.store.dispatch(go({ to: { path: ['/exam/prescription/edit', prescriptionId] } }));
  }


}
