import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IScheduledTimingState } from './scheduledTiming-state';
import * as fromScheduledTiming from './scheduledTiming.reducer';

export const selectScheduledTimingState = createFeatureSelector<IScheduledTimingState>(fromScheduledTiming.scheduledTimingsFeatureKey);
export const selectScheduledTimingAll = createSelector(selectScheduledTimingState, fromScheduledTiming.selectAll);

//TODO: Validar este trecho
// export const selectScheduledTimingAllActive = createSelector(
//   selectScheduledTimingAll,
//   scheduledTimings => scheduledTimings
//     .filter(scheduledTiming => scheduledTiming. /*&& !scheduledTiming.pacienteFalecido*/)
//     .sort((a, b) => a?.active?.localeCompare(b?.active))
// );

export const selectedScheduledTiming = createSelector(selectScheduledTimingState, state => {
  return state?.selectedIdentifier ? state?.entities[state?.selectedIdentifier] : null;
});

export const isScheduledTimingLoading = createSelector(
  selectScheduledTimingAll,
  scheduledTimings => scheduledTimings?.length <= 0,
);

export const selectedScheduledTimingIdentifier = createSelector(selectScheduledTimingState, state => {
  return (state?.selectedIdentifier || 0) > 0 ? state?.selectedIdentifier : null;
});
