import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { WebService } from './web.service';
import { ObjectDAL } from './object-dal';
import { IUsuario } from '@medlogic/shared/shared-interfaces';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class UsuarioDAL extends ObjectDAL {

  constructor(
    webService: WebService,
    log: LogService,
    global: GlobalService
  ) {
    super(webService, log, global);
  }

  /* Efetua o login do usuário, criando seu token e retornar a versão para que se compare a versão da interface com da API. */
  getDoLoginUsuarioNoChecarVersao(
    usuarioNo: number,
    versaoInterfaceWeb: string
  ): Observable<IUsuario> {
    try {
      return this.getWithCache<IUsuario>(
        this.getDoLoginUsuarioNoChecarVersaoCall$,
        usuarioNo,
        versaoInterfaceWeb
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWithCache', error.message);
    }
    return of(null);
  }


  protected getDoLoginUsuarioNoChecarVersaoCall$(
    usuarioNo: number,
    versaoInterfaceWeb: string,
  ): Observable<IUsuario> {
    try {
      const method = 'getDoLoginUsuarioNoChecarVersao';
      const usuario$ = this.webService.connectWithCache<IUsuario>(method,
        [
          { name: 'UsuarioNo', value: usuarioNo },
          { name: 'versaoInterfaceWeb', value: versaoInterfaceWeb }
        ]);
      return usuario$;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDoLoginUsuarioNoChecarVersaoCall', error.message);
    }
    return of(null);
  }

  getUsuario(
    usuarioNo: number,
  ): Observable<IUsuario> {
    try {
      const method = 'getUsuario';
      const usuario$ = this.webService
        .connectWithCache<IUsuario>(
          method,
          [{ name: 'usuarioNo', value: usuarioNo }],
        ).pipe(
          map((usuario: any) => ({ ...usuario, ColPapel: usuario.ColPapel.Papel }))
        );
      return usuario$;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUsuario', error.message);
    }
    return of(null);
  }


  resetSenha(
    login: string
  ): Observable<boolean> {
    try {
      const method = 'ResetSenha';
      return this.webService
        .connectWithCache<boolean>(
          method,
          [{ name: 'login', value: login }],
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'resetSenha', error.message);
    }
    return of(null);
  }

  alterarSenha(
    login: string,
    senhaAtual: string,
    novaSenha: string,
  ): Observable<boolean> {
    try {
      const method = 'AlterarSenha';
      return this.webService
        .connectWithCache<boolean>(
          method,
          [
            { name: 'login', value: login },
            { name: 'senhaAtual', value: senhaAtual },
            { name: 'novaSenha', value: novaSenha },
          ]
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'alterarSenha', error.message);
    }
    return of(null);
  }


  recoverPassword(
    usuarioNo: number,
    password: string,
  ): Observable<boolean> {
    try {
      const method = 'RecoverPassword';
      return this.webService
        .connectWithCache<boolean>(
          method,
          [
            { name: 'usuarioNo', value: usuarioNo },
            { name: 'password', value: password }
          ]
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'recoverPassword', error.message);
    }
    return of(null);
  }


}
