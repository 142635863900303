<div class="avatar-container">

  <ng-container *ngrxLet="selectedPatient$ as patient">
    <ng-container *ngrxLet="selectedFhirPatient$ as fhirPatient">
      <ng-container *ngrxLet="selectedPatientsLocation$ as location">
        <div class="title">{{ patient.nomeHospede }} | {{ location?.andar || "Andar não definido" }}-{{ location?.bloco }}-{{ location?.quarto || "Quarto não definido" }}</div>

        <div class="avatar">
          <img alt="image" class="img-circle" [class.miniImg]="showMiniImg" width="76" src="{{ patient.foto }}"
            (error)="swapImage($event.target, patient)">
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

</div>
