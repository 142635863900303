import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';

@Pipe({
  name: 'libFilterCtrGridByKeyword'
})
export class FilterCtrGridByKeywordPipe implements PipeTransform {
  constructor(protected log: LogService) { }

  /*Filtrará todas as colunas pela keyword. */
  transform(items: Array<any>, keyword: string): Array<any> {
    try {
      if (!items || !keyword) { return items; }

      return items.filter((f) => {
        let hasItem = false;
        for (const clm in f) {
          if (f[clm].indexOf(keyword) >= 0) {
            hasItem = true;
            return;
          }
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'transform', error.message);
    }
    return items;
  }
}
