import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { LogService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[libCtrMobileConfigUI]'
})
export class CtrMobileConfigUIDirective implements OnInit {

  // tslint:disable-next-line: variable-name
  private _ctrl: any;
  @Input('libCtrMobileConfigUI') public get ctrl(): any {
    return this._ctrl;
  }
  public set ctrl(v: any) {
    this._ctrl = v;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private log: LogService,
    private glb: GlobalService) {
  }

  /* Para resolver o problema do - na atualização, procurar um evento que não seja o Init. */
  ngOnInit(): void {
    try {
      this.applyStyles(this.ctrl);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  applyStyles(ctrl: IAtividadeComponenteDAL): void {
    try {
      if (ctrl) {
        if (this.glb.isMobile()) {
          this.renderer.addClass(this.el.nativeElement, 'is-mobile');
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'applyStyles', error.message);
    }
  }

}
