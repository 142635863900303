import { createAction, props } from '@ngrx/store';
import { ICostCenter } from '@medlogic/shared/shared-interfaces';

export enum CostCenterActionTypes {
  LoadCostCenter = '[Page CostCenter Component] Load CostCenter',
  CheckCostCenterAndInsert = '[Page CheckCostCenterAndInsert Component] Load CheckCostCenterAndInsert',
  FetchSuccessCostCenter = '[CostCenter API] Fetch Success CostCenter',
  FetchSuccessSetCostCenter = '[CostCenter API] Fetch Success Set CostCenter',
  FetchSuccessSetCostCenters = '[CostCenter API] Fetch Success Set CostCenters',
  FetchErrorCostCenter = '[CostCenter API] Fetch Error CostCenter',
  SetCostCenter = '[CostCenterListView Component] Set CostCenter',
  CleanCostCenter = '[Page Generic List Component] CleanCostCenter',
}

export const loadCostCenters = createAction(CostCenterActionTypes.LoadCostCenter);
export const checkCostCenterAndInsert = createAction(CostCenterActionTypes.CheckCostCenterAndInsert);
export const setCostCenter = createAction(CostCenterActionTypes.SetCostCenter, props<{ costCenter: ICostCenter }>());

export const fetchSuccessCostCenter = createAction(CostCenterActionTypes.FetchSuccessCostCenter, props<{ selectedId: number }>());
export const fetchSuccessSetCostCenter = createAction(CostCenterActionTypes.FetchSuccessSetCostCenter, props<{ costCenter: ICostCenter }>());
export const fetchSuccessSetAllCostCenter = createAction(CostCenterActionTypes.FetchSuccessSetCostCenters, props<{ costCenters: ICostCenter[] }>());
export const fetchErrorCostCenter = createAction(CostCenterActionTypes.FetchErrorCostCenter, props<{ error: string }>());
export const cleanCostCenter = createAction(CostCenterActionTypes.CleanCostCenter);
