import { IScheduledTiming } from './ischeduled-timing';
import { IScheduledPeriod } from './ischeduled-period';
import { IActivityDetail } from './iactivity-detail';

export interface IScheduled {
    scheduledIdentifier: string;
    scheduledTimingIdentifier: string;
    ScheduledTiming: IScheduledTiming[];
    scheduledPeriodIDentifier: string;
    ScheduledPeriod: IScheduledPeriod;
    scheduledString: string;
    tenantId: number;
    language: string;
    // activityDetail: IActivityDetail[];
}
