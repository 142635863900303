import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StockEffects } from './+state/stock.effects';
import { metaReducers } from './+state/storage.metareducer';

import * as fromStock from './+state/stock.reducer';


const SERVICES = [
];

const MODULES = [
  CommonModule,
];

const STATE_MODULES = [
  StoreModule.forFeature(fromStock.stocksFeatureKey, fromStock.reducer, { metaReducers }),
  EffectsModule.forFeature([StockEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
  providers: [SERVICES]
})
export class StateStockModule { }
