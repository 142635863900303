<div class="container">

  <div class="main">

    <ng-container *ngFor="let patient of patients | patientPipe:keyword">

      <ml-patient-list [patient]="patient" [showMiniImg]="showMiniImg" (click)="onPatientClick(patient)">
      </ml-patient-list>

    </ng-container>

  </div>

  <mat-toolbar>

    <button mat-raised-button (click)="onNoClick()">
      <mat-icon>close</mat-icon>Fechar
    </button>

  </mat-toolbar>

</div>