<div class="top-bar">
  <app-btn-back (backButtonClicked)="onBack($event)"></app-btn-back>
</div>

<div class="scroll">
  <h1>Evolução Histórica do Residente</h1>
  <ml-patient-header [patient]="patient"></ml-patient-header>

  <div class="grid-timeline" *ngFor="let evolutionItem of evolution$ | async">
    <div
      class="grid-day"
      [class.item-left]="evolutionItem[1] % 2 !== 0"
      [class.item-right]="evolutionItem[1] % 2 === 0"
    >
      <h1>{{ evolutionItem[0] }}</h1>

      <div *ngFor="let groupItem of evolutionItem[2]">
        <h2>{{ groupItem.hora }}</h2>

        <h3 *ngIf="groupItem.profissional">
          Registrado por: {{ groupItem.profissional }}
        </h3>

        <app-pain-scale
          *ngIf="groupItem.intensidade && groupItem.intensidade > 0"
          [painLevel]="groupItem.intensidade"
        ></app-pain-scale>

        <div class="grid-msg">
          <mat-icon
            class="icn-alert"
            *ngIf="
              groupItem.blIntercorrencia ||
              groupItem.ultimaEvacuacaoOcorreurmais2Dias
            "
            title="Atenção: houve intercorrência nesse horário!"
          >
            add_alert
          </mat-icon>

          <span
            *ngIf="groupItem.ultimaEvacuacaoOcorreurmais2Dias"
            class="warning constipation"
            >Atenção: Evacuação há mais de dois dias (risco de fecaloma)</span
          >

          <span class="evolution">{{ groupItem.evolucao }}</span>

          <span class="intervention">{{ groupItem.conduta }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
