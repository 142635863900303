import { JsStoreService } from './js-store.service';
import { Injectable } from '@angular/core';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ConfigStateService } from '@medlogic/shared/state-config';
import { TbStoreService } from './tb-store.service';
import { IQueueItem } from '@medlogic/shared/shared-interfaces';
import { from } from 'rxjs';
import { Observable } from 'rxjs';
import { ITable, DATA_TYPE } from 'jsstore';
import { IColumnOption } from 'jsstore/dist/ts/common';


@Injectable()
export class TbQueueService extends TbStoreService {

    constructor(
        jsStoreSrv: JsStoreService,
        glb: GlobalService,
        log: LogService,
        private cnf: ConfigStateService
    ) {
        super(jsStoreSrv, log, 'Queue', glb);
    }

    /* A inicialização do Banco de Dados depende que haja o esquema de cada tabela. */
    getTableSchema(): ITable {
        try {
            return {
                name: 'Queue',
                columns: {
                    id: {
                        notNull: true,
                        primaryKey: true,
                        autoIncrement: true,
                        dataType: DATA_TYPE.Number
                    } as IColumnOption,
                    key: {
                        notNull: true,
                        dataType: DATA_TYPE.String
                    } as IColumnOption,
                    ano: {
                        notNull: true,
                        dataType: DATA_TYPE.Number
                    } as IColumnOption,
                    uno: {
                        notNull: true,
                        dataType: DATA_TYPE.Number
                        // Default: 'male'
                    } as IColumnOption,
                    cadastro: {
                        notNull: true,
                        dataType: DATA_TYPE.Array // | JsStore.Data_Type.Array
                    } as IColumnOption
                }
            } as ITable;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getTableSchema', error.message);
        }
    }

    /* Override
    * Além do add, atualiza o contador de pendências.
    */
    add(item: IQueueItem): Promise<any> {
        try {
            const addItem = super.add(item);
            addItem
                .then(res => {
                    try {
                        this.updatePending();
                    } catch (error) {
                        this.log.Registrar(this.constructor.name, 'deleteByKey.then', error.message);
                    }
                });
            return addItem;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'add', error.message);
        }
    }

    /* Override
     * Além do delete, atualiza o contador de pendências.
     */
    deleteByKey(key: string): Promise<any> {
        try {
            const deleteItem = super.deleteByKey(key);
            deleteItem
                .then(res => {
                    try {
                        this.updatePending();
                        this.updateSaved();
                    } catch (error) {
                        this.log.Registrar(this.constructor.name, 'deleteByKey.then', error.message);
                    }
                });
            return deleteItem;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'deleteByKey', error.message);
        }
    }

    /* Atualiza a propriedade no config que armazena o número de itens pendentes de sincronismo.  */
    updatePending(): void {
        try {
            this.subs.sink = this.getNumOfPending()
                .subscribe(s => this.cnf.pending = s);
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'updatePending', error.message);
        }
    }

    /* Atualiza o contador de itens sincronizados com sucesso.
     * count: número de itens atualizados a serem acrescentados.
    */
    updateSaved(count: number = 1): void {
        try {
            this.cnf.saved++;
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'updateSaved', error.message);
        }
    }

    /* Retorna o número de itens pendentes na fila (queue). */
    protected getNumOfPending(): Observable<number> {
        try {
            const count = this.get()
                .then(items => items && items.length > 0 ? items.length : 0);
            return from(count);
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'getNumOfPending', error.message);
        }
    }

}
